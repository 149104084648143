import styled from "styled-components";

export const ActivitySubmissionFileWrapper = styled.div`
    display: block;
    margin-top: 24px;
    max-height: calc(100svh - 150px);
    overflow-y: scroll;

    @media (min-width: 1024px) {
        max-width: calc(100vw - 428px);
    }

    &::after {
        content: "";
        margin-right: 16px;
    }

    img {
        width: auto;
    }
`;
