import React from "react";

// Components
import Button from "components/Button";
import RadioGroup from "components/RadioGroup";

// Helpers
import { ISelectOption } from "interfaces/ISelectOption";

// Assets
import * as C from "./styles";

interface IComplaintProps {
    active: any;
    onCancel: () => void;
    onSelectComplaint: (value: any) => void;
    onSubmit: () => void;
    options: ISelectOption[];
}

const Complaint: React.FC<IComplaintProps> = ({ active, onCancel, onSelectComplaint, onSubmit, options }) => {
    return (
        <C.ComplaintModalContainer>
            <C.ComplaintModalHeader>
                <C.ComplaintModalTitle>O que temos de errado aqui?</C.ComplaintModalTitle>
            </C.ComplaintModalHeader>
            <C.ComplaintModalContent>
                <RadioGroup activeItem={active} items={options} radioVariant="brand" onClick={onSelectComplaint} />
            </C.ComplaintModalContent>
            <C.ComplaintModalFooter>
                <Button disabled={!active} size="medium" variant="primary" block={true} onClick={onSubmit}>
                    Enviar denúncia
                </Button>
                <Button size="medium" variant="outline-primary" block={true} onClick={onCancel}>
                    Cancelar
                </Button>
            </C.ComplaintModalFooter>
        </C.ComplaintModalContainer>
    );
};

export default Complaint;
