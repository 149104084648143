import { call, put, select, takeLatest } from "redux-saga/effects";

import api from "services/api";
import { Creators as alertActions } from "store/ducks/alert";
import { IMentorshipAnswer } from "store/interfaces/IMentorship";
import { Creators as lessonPlanActions } from "store/ducks/lesson-plan/index";
import { Creators as forumActions, Types as ForumTypes } from "store/ducks/mentorship";

const getCredentials = (state: any) => state.auth.credentials;
const getMentorship = (state: any) => state.forum;
const getEvent = (state: any) => state.lessonPlan.event;

function* mentorshipGetAll(action: any) {
    try {
        const { code, isOpen } = action.payload;

        const { data } = yield call(api.get, `/forumquestionlo/learningobject/${code}`, {
            headers: {
                chave: "proenem"
            }
        });

        yield put(
            forumActions.getForumSuccess({
                code,
                openForum: isOpen,
                items: data
            })
        );
    } catch (error) {
        console.log(error);
        yield put(alertActions.showAlert("Poxa, não conseguimos obter a monitoria! :(", "danger"));
        yield put(forumActions.getForumFailure(error?.response?.data));
    }
}

function* mentorshipCreateOrEditAnswer(action: any) {
    try {
        const credentials = yield select(getCredentials);
        const forum = yield select(getMentorship);

        const formAnswer = {
            ...(!action.payload.id && {
                learningObject: {
                    id: forum.code
                }
            }),
            content: action.payload.content
        };

        const { data } = yield call(
            action.payload.id ? api.patch : api.post,
            `/forumquestionlo${!!action.payload.id ? `/${action.payload.id}` : ""}`,
            formAnswer
        );

        const formAnswerData = {
            ...(!!!data.answers && { answers: [] }),
            ...data
        };

        yield put(forumActions.createOrEditForumSuccess(formAnswerData));

        if (action.payload.id) {
            if (data.user.id === credentials.id) {
                yield put(alertActions.showAlert("Pergunta Editada!", "success"));
            }
        } else {
            yield put(alertActions.showAlert("Pergunta Adicionada!", "success"));
        }
    } catch (error) {
        console.log("error", error);
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.createOrEditForumFailure(error?.response?.data));
    }
}

function* mentorshipRemoveAnswer(action: any) {
    try {
        yield put(forumActions.deleteForumSuccess(action.payload));

        yield call(api.delete, `/forumquestionlo/${action.payload.id}`);

        yield put(alertActions.showAlert("Pergunta Apagada!", "success"));
    } catch (error) {
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.deleteForumFailure(error?.response?.data));
    }
}

function* mentorshipLikeAnswer(action: any) {
    try {
        const { answer, comment } = action.payload;

        if (!answer) {
            throw new Error("Resposta não informada.");
        }

        yield put(forumActions.likeForumSuccess(action.payload));

        yield call(api.post, `/forumquestionlo/${answer}/${!!comment ? `answer/${comment}/` : ""}like`);
    } catch (error) {
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.likeForumFailure(error?.response?.data));
    }
}

function* mentorshipCreateOrEditComment(action: any) {
    try {
        const { comment, answer } = action.payload;

        const endpoint = !!comment.id ? `/forumanswerlo/${comment.id}` : `/forumquestionlo/${answer}/answer`;

        const { data } = yield call(!!comment.id ? api.patch : api.post, endpoint, {
            content: comment.content
        });

        const formatAnswer: IMentorshipAnswer = comment.id
            ? {
                  ...action.payload
              }
            : {
                  answer,
                  comment: {
                      ...action.payload.comment,
                      ...data,
                      likesCount: 0
                  }
              };

        yield put(forumActions.createOrEditForumCommentsSuccess(formatAnswer));
    } catch (error) {
        console.log("error", error);
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.createOrEditForumCommentsFailure(error?.response?.data));
    }
}

function* mentorshipRemoveComment(action: any) {
    try {
        yield put(forumActions.deleteForumCommentsSuccess(action.payload));

        yield put(alertActions.showAlert("Comentário Apagada!", "success"));
    } catch (error) {
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.deleteForumCommentsFailure());
    }
}

function* openMentorship(action: any) {
    try {
        const event = yield select(getEvent);
        const forum = yield select(getMentorship);

        yield call(api.patch, `/video/${forum.code}/forum/status/${action.payload.openForum}`);

        yield put(forumActions.openForumSuccess(action.payload));

        const eventPayload = {
            ...event,
            video: {
                ...event.video,
                learningObject: {
                    ...event.video.learningObject,
                    ...action.payload
                }
            }
        };

        yield put(lessonPlanActions.getLessonPlanEventSuccess(eventPayload));
    } catch (error) {
        console.log("error", error);
        yield put(alertActions.showAlert("Poxa, algo deu errado! :(", "danger"));
        yield put(forumActions.openForumFailure(error?.response?.data));
    }
}

export default [
    takeLatest(ForumTypes.GET_MENTORSHIP_REQUEST, mentorshipGetAll),
    takeLatest(ForumTypes.CREATE_OR_EDIT_MENTORSHIP_REQUEST, mentorshipCreateOrEditAnswer),
    takeLatest(ForumTypes.DELETE_MENTORSHIP_REQUEST, mentorshipRemoveAnswer),
    takeLatest(ForumTypes.LIKE_MENTORSHIP_REQUEST, mentorshipLikeAnswer),
    takeLatest(ForumTypes.CREATE_OR_EDIT_MENTORSHIP_COMMENTS_REQUEST, mentorshipCreateOrEditComment),
    takeLatest(ForumTypes.DELETE_MENTORSHIP_COMMENTS_REQUEST, mentorshipRemoveComment),
    takeLatest(ForumTypes.OPEN_MENTORSHIP_REQUEST, openMentorship)
];
