import { theme } from "config/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Micro } from "assets/styles/global";
import ChevronLeft from "prodigio-icons/web/outlined/ChevronLeft";

export const BackToContainer = styled(Link)`
    ${Micro};
    align-items: center;
    color: ${theme.colors.base[500]};
    display: flex;
    line-height: 1;
`;

export const BackToIcon = styled(ChevronLeft)`
    color: ${theme.colors.base[500]};
    margin-right: ${theme.spacing.smallX};
`;
