// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    hasBorderTop: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    background-color: #fff;
    border: 4px solid #f0f1f7;
    padding: 8px 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    ${({ hasBorderTop }) =>
        !hasBorderTop &&
        css`
            border-top: none;
        `}
`;

export const FixedContent = styled.div`
    width: 100%;
    border-radius: 4px;

    display: flex;
    align-items: center;
    gap: 12px;

    @media (min-width: 1024px) {
        gap: 16px;
    }
`;

export const IndicatorLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #232326;

    @media (min-width: 1024px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    flex: 1 0 0;
`;

export const HeaderLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #232326;

    @media (min-width: 1024px) {
        color: #83899e;
    }
`;

export const Title = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #232326;

    @media (min-width: 1024px) {
        font-weight: 400;
    }

    img {
        max-width: 100%;
    }
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    cursor: pointer;
`;

export const OpenedContent = styled.div`
    width: 100%;
    border-radius: 4px;

    @media (min-width: 1024px) {
        font-weight: 400;
        padding-left: 32px;
    }
`;
