// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ActivityAnswerSheetContainer,
    AnswerSheetItemCompositionsWrapper,
    AnswerSheetItemContainer,
    AnswerSheetItemLabel,
    AnswerSheetItemContentWrapper,
    AnswerSheetItemAnswers,
    AnswerSheetItemAnswerWrapper,
    AnswerSheetItemAnswerSeparator
} from "./activity-answer-sheet.styles";

// Types
import { ActivityAnswerSheetItemProps, ActivityAnswerSheetProps } from "./activity-answer-sheet.types";

export const ActivityAnswerSheet: FunctionComponent<ActivityAnswerSheetProps> = ({ answerItemCompositions, tableTitleElement, titleElement }) => {
    return (
        <ActivityAnswerSheetContainer>
            {titleElement}

            <AnswerSheetItemCompositionsWrapper>
                {tableTitleElement}

                {answerItemCompositions}
            </AnswerSheetItemCompositionsWrapper>
        </ActivityAnswerSheetContainer>
    );
};

export const ActivityAnswerSheetItem: FunctionComponent<ActivityAnswerSheetItemProps> = ({
    indicator,
    statementElement,
    firstAnswerLabel,
    firstAnswerIndicatorElement,
    secondAnswerLabel,
    secondAnswerIndicatorElement
}) => {
    return (
        <AnswerSheetItemContainer>
            <AnswerSheetItemLabel>{indicator}</AnswerSheetItemLabel>

            <AnswerSheetItemContentWrapper>
                {statementElement}

                <AnswerSheetItemAnswers>
                    <AnswerSheetItemAnswerWrapper>
                        <AnswerSheetItemLabel>{firstAnswerLabel}</AnswerSheetItemLabel>

                        {firstAnswerIndicatorElement}
                    </AnswerSheetItemAnswerWrapper>

                    <AnswerSheetItemAnswerSeparator />

                    <AnswerSheetItemAnswerWrapper>
                        <AnswerSheetItemLabel>{secondAnswerLabel}</AnswerSheetItemLabel>

                        {secondAnswerIndicatorElement}
                    </AnswerSheetItemAnswerWrapper>
                </AnswerSheetItemAnswers>
            </AnswerSheetItemContentWrapper>
        </AnswerSheetItemContainer>
    );
};
