import React, { memo, useCallback, useState, useRef, useMemo } from "react";

// Components
import Switcher from "components/Switcher";
import EssayAttach from "./components/attach";
import EssayCanvas from "./components/canvas";

// Helpers
import { KeyboardKeys } from "enums/Keys";

// Assets
import { Col } from "components/Grid";
import { MakeEssayCanvasWrapper, MakeEssaySwitcherWrapper } from "./styles";

const ESSAY_MAX_COLS = 78;
const ESSAY_MAX_ROWS = 30;

interface IMakeEssayProps {
    handleSend(file: File[]): void;
    handleDiscard(): void;
    handleDraft(content: string, image: string, status: number): void;
    handleCorrection(content: string, image: string, status: number): void;
    content?: string;
}

const MakeEssay = ({ handleSend, handleDiscard, handleDraft, handleCorrection, content = "" }: IMakeEssayProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [activeIndex, setActiveIndex] = useState(content ? 1 : 0);
    const [canvasValue, setCanvasValue] = useState(content);

    const validateContent = useMemo(
        () => (event: any): boolean => {
            const { value } = event.currentTarget;
            const lines = value.match(/.{1,78}/g);

            if (textareaRef.current) {
                const height = textareaRef.current.offsetHeight;
                const scrollHeight = textareaRef.current.scrollHeight;

                if (scrollHeight > height) {
                    return false;
                }
            }

            if (lines && lines.length >= ESSAY_MAX_ROWS) {
                const last = lines[lines.length - 1];

                if (last.length >= ESSAY_MAX_COLS || (lines && lines.length > ESSAY_MAX_ROWS) || event.keyCode === KeyboardKeys.KEY_ENTER) {
                    return false;
                }
            }

            return true;
        },
        []
    );

    const handleKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            const { value } = event.currentTarget;
            const canWrite = validateContent(event);

            if (!canWrite && event.keyCode !== KeyboardKeys.KEY_ENTER) {
                return setCanvasValue(value.substr(0, value.length - 1));
            }

            return setCanvasValue(value);
        },
        [validateContent]
    );

    const handleSwitcherChange = useCallback((index: number) => setActiveIndex(index), []);

    const handleCanvasChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = event.currentTarget;
            const canWrite = validateContent(event);

            if (canWrite) {
                setCanvasValue(value);
            }
        },
        [validateContent]
    );

    const handleUpload = useCallback((files: File[]) => setFiles(files), []);

    const handleClearFiles = useCallback(() => setFiles([]), []);

    const MakeEssayMode = useMemo(() => {
        if (activeIndex) {
            return (
                <EssayCanvas
                    value={canvasValue}
                    onChange={handleCanvasChange}
                    onKeyUp={handleKeyUp}
                    ref={textareaRef}
                    handleDiscard={handleDiscard}
                    handleDraft={handleDraft}
                    handleCorrection={handleCorrection}
                />
            );
        }

        return <EssayAttach files={files} onClearFiles={handleClearFiles} onUpload={handleUpload} onSend={handleSend} />;
    }, [
        activeIndex,
        canvasValue,
        files,
        handleCanvasChange,
        handleClearFiles,
        handleCorrection,
        handleDiscard,
        handleDraft,
        handleKeyUp,
        handleSend,
        handleUpload
    ]);

    return (
        <Col sm={12}>
            <MakeEssayCanvasWrapper>
                <MakeEssaySwitcherWrapper>
                    <Switcher activeIndex={activeIndex} options={["Anexar", "Escrever"]} onChange={handleSwitcherChange} />
                </MakeEssaySwitcherWrapper>
                {MakeEssayMode}
            </MakeEssayCanvasWrapper>
        </Col>
    );
};

export default memo(MakeEssay);
