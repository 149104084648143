import React, { useState, useMemo, useCallback, memo, useRef, useEffect } from "react";

// Dependencies
import Reaptcha from "reaptcha";
import validate from "validate.js";
import { Link } from "react-router-dom";

// Components
import Arrow from "components/Arrow";
import Button from "components/Button";
import PageLoader from "components/PageLoader";
import RightContent from "components/SignScreen/RightContent";
import TextField from "components/Form/TextField";

// Helpers
import { theme } from "config/theme";
import { IAuthActions } from "store/interfaces/IAuth";
import { MASK_PHONE } from "helpers";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as AuthActions } from "store/ducks/auth";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import {
    SignBackToHome,
    SignButtonsWrap,
    SignForm,
    GeneralSignLeftContent,
    SignSubTitle,
    SignTitle,
    SignTitlesWrap,
    SignWrapContent,
    SignCopyright,
    FieldWrapper,
    CheckboxContainer,
    CheckboxHelper,
    CheckboxItem,
    CheckboxLabel,
    CheckboxWrapper,
    SignActionsItem,
    SignActionsWrap
} from "components/SignScreen/styles";

// Interfaces
export interface IProps {
    isLoading: boolean;
    authActions: IAuthActions;
    hasError: boolean;
}

const isOurProject = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

const SignupScreen = ({ authActions, hasError, isLoading }: IProps) => {
    const captchaRef = useRef<Reaptcha>(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined);
    const [captchaReady, setCaptchaReady] = useState(false);

    useEffect(() => {
        if (captchaRef.current && isOurProject) {
            const interval = setInterval(() => {
                setRecaptchaToken(undefined);
            }, 90000);

            return () => interval && clearInterval(interval);
        }
    }, [setRecaptchaToken]);

    useEffect(() => {
        authActions.clearErrors();

        return () => {
            authActions.clearErrors();
        };
    }, [authActions]);

    useEffect(() => {
        if (hasError) {
            setRecaptchaToken(undefined);
        }
    }, [hasError]);

    useEffect(() => {
        if (!recaptchaToken && captchaRef.current && captchaReady) {
            captchaRef.current.reset();

            captchaRef.current.execute();

            authActions.clearErrors();
        }
    }, [recaptchaToken, authActions, captchaReady]);

    const handleVerify = useCallback((token) => setRecaptchaToken(token), [setRecaptchaToken]);

    const handleRecaptchaLoad = useCallback(() => {
        if (isOurProject && captchaRef && captchaRef.current) {
            setCaptchaReady(true);
            captchaRef.current.execute();
        }
    }, []);

    // Event handlers
    const handleNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setName(event.currentTarget.value), []);
    const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value), []);
    const handleConfirmEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setConfirmEmail(event.currentTarget.value), []);
    const handlePasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.currentTarget.value), []);
    const handleTermsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setIsTermsAccepted(event.currentTarget.checked), []);
    const handlePhoneChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.currentTarget.value), []);

    // Validations
    const isNameValid = useMemo(() => {
        return !validate.single(name, {
            presence: {
                allowEmpty: false
            }
        });
    }, [name]);

    const isEmailValid = useMemo(() => {
        return !validate.single(email, {
            email: true
        });
    }, [email]);

    const isConfirmEmailValid = useMemo(() => {
        return (
            confirmEmail === email &&
            !validate.single(confirmEmail, {
                email: true
            })
        );
    }, [confirmEmail, email]);

    const isPasswordValid = useMemo(() => {
        return !validate.single(password, {
            presence: {
                allowEmpty: false
            }
        });
    }, [password]);

    const isPhoneValid = useMemo(() => {
        return !validate.single(phone, {
            format: {
                pattern: /^\(\d{2}\) \d{5}-\d{4}$/
            }
        });
    }, [phone]);

    // Validation Form
    const isValidForm = useMemo(() => {
        return !!isNameValid && !!isEmailValid && !!isPasswordValid && !!isConfirmEmailValid && !!isPhoneValid && !!isTermsAccepted;
    }, [isNameValid, isEmailValid, isPasswordValid, isConfirmEmailValid, isPhoneValid, isTermsAccepted]);

    // Does not allow the user to paste the email in the confirmation field.
    const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => event.preventDefault(), []);

    // Login submit
    const handleSignupSubmit = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            if (!isValidForm) {
                return;
            }

            const payload = {
                email: email.trim(),
                password: password.trim(),
                name: name.trim(),
                phoneNumber: phone.trim(),
                isTermsAccepted,
                ...(isOurProject && { recaptchaToken })
            };

            authActions.signUpRequest(payload);
        },
        [isValidForm, email, password, name, phone, isTermsAccepted, recaptchaToken, authActions]
    );

    const termsUrl =
        theme.project.slug === "promilitares" ? "https://www.promilitares.com.br/termos-de-uso" : "https://www.proenem.com.br/termos-de-uso";

    const title = theme.project.slug === "promilitares" ? "O primeiro passo no caminho para sua farda" : "A sua nota 1000 está ao seu alcance";

    return (
        <SignWrapContent>
            {isLoading && <PageLoader />}
            <GeneralSignLeftContent>
                <Link className="back-to" to="/">
                    <SignBackToHome>
                        <Arrow width={20} height={15} />
                        Voltar
                    </SignBackToHome>
                </Link>
                <SignTitlesWrap>
                    <SignTitle>{title}</SignTitle>
                    <SignSubTitle>Seja parte da nossa incrível comunidade!</SignSubTitle>
                </SignTitlesWrap>
                <SignForm onSubmit={handleSignupSubmit}>
                    <FieldWrapper>
                        <TextField autoComplete="off" autoFocus={true} name="name" label="Seu nome" value={name} onChange={handleNameChange} />
                    </FieldWrapper>
                    <FieldWrapper>
                        <TextField autoComplete="off" name="email" label="Seu e-mail" value={email} onChange={handleEmailChange} />
                    </FieldWrapper>
                    <FieldWrapper>
                        <TextField
                            autoComplete="off"
                            name="confirmEmail"
                            label="Confirme o seu e-mail"
                            value={confirmEmail}
                            onChange={handleConfirmEmailChange}
                            onPaste={handlePaste}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <TextField
                            autoComplete="off"
                            name="password"
                            type="password"
                            label="Sua senha"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <TextField name="phone" label="Celular" mask={MASK_PHONE} value={phone} onChange={handlePhoneChange} />
                    </FieldWrapper>
                    {isOurProject && (
                        <FieldWrapper>
                            <Reaptcha
                                ref={captchaRef}
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                                size="invisible"
                                onRender={handleRecaptchaLoad}
                                onVerify={handleVerify}
                            />
                        </FieldWrapper>
                    )}
                    <SignActionsWrap>
                        <SignActionsItem>
                            <CheckboxContainer>
                                <CheckboxWrapper>
                                    <CheckboxItem checked={isTermsAccepted} type="checkbox" required={true} onChange={handleTermsChange} />
                                    <CheckboxHelper />
                                </CheckboxWrapper>
                                <CheckboxLabel>
                                    Li e aceito os{" "}
                                    <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                                        <u>termos de uso</u>
                                    </a>
                                    .
                                </CheckboxLabel>
                            </CheckboxContainer>
                        </SignActionsItem>
                    </SignActionsWrap>
                    <SignButtonsWrap>
                        <Button disabled={!isValidForm || !recaptchaToken || isLoading} type="submit" variant="primary" block={true} size="medium">
                            Cadastrar
                        </Button>
                    </SignButtonsWrap>
                </SignForm>

                {isOurProject && (
                    <SignCopyright>
                        <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                            Termos de uso
                        </a>
                    </SignCopyright>
                )}
            </GeneralSignLeftContent>
            <RightContent />
        </SignWrapContent>
    );
};

const MemoizedSignupScreen = memo(SignupScreen);

const mapStateToProps = ({ auth }: IReduxStore) => ({
    isLoading: auth.isLoading,
    hasError: auth.hasError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedSignupScreen);
