// Dependencies
import React, { memo, useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import validate from "validate.js";
import Reaptcha from "reaptcha";

// Components
import Button from "components/Button";
import PageLoader from "components/PageLoader";
import TextField from "components/Form/TextField";

// Helpers
import { theme } from "config/theme";
import { IAuthActions } from "store/interfaces/IAuth";
import { GetWidth } from "utils/windowsSize";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";

// Assets
import RightContent from "components/SignScreen/RightContent";
import {
    SignButtonsWrap,
    SignCopyright,
    SignForm,
    GeneralSignLeftContent,
    SignSubTitle,
    SignTitle,
    SignTitlesWrap,
    SignWrapContent,
    FieldWrapper,
    LogoWrapper,
    HelpCenterButtonWrapper,
    HelpCenterButton
} from "components/SignScreen/styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as AuthActions } from "store/ducks/auth";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    authActions: IAuthActions;
    isLoading: boolean;
    hasError: boolean;
}

const isOurProject = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

const RegisterPasswordScreen = ({ authActions, isLoading, hasError }: IProps) => {
    const captchaRef = useRef<Reaptcha>(null);

    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const email = params.get("email");
    const token = params.get("token");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined);
    const [captchaReady, setCaptchaReady] = useState(false);

    const isSmallScreen = GetWidth < 600;
    const termsUrl =
        theme.project.slug === "promilitares" ? "https://www.promilitares.com.br/termos-de-uso" : "https://www.proenem.com.br/termos-de-uso";

    useEffect(() => {
        authActions.clearLoading();
    }, [authActions]);

    useEffect(() => {
        if (captchaRef.current && isOurProject) {
            const interval = setInterval(() => {
                setRecaptchaToken(undefined);
            }, 90000);

            return () => interval && clearInterval(interval);
        }
    }, [setRecaptchaToken]);

    useEffect(() => {
        if (hasError) {
            setRecaptchaToken(undefined);
        }
    }, [hasError]);

    useEffect(() => {
        if (!recaptchaToken && captchaRef.current && captchaReady) {
            captchaRef.current.reset();

            captchaRef.current.execute();

            authActions.clearErrors();
        }
    }, [recaptchaToken, authActions, captchaReady]);

    const isPasswordValid = useMemo(() => {
        return !validate.single(password, {
            length: {
                minimum: 5
            }
        });
    }, [password]);

    const isConfirmPasswordValid = useMemo(() => {
        return confirmPassword === password;
    }, [confirmPassword, password]);

    const handleSetPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value), []);

    const handleSetConfirmPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value), []);

    const isFormValid = useMemo(() => !!isPasswordValid && !!isConfirmPasswordValid, [isPasswordValid, isConfirmPasswordValid]);

    const handleRegisterPasswordSubmit = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            if (email && token) {
                const data = {
                    password: password.trim(),
                    email,
                    token,
                    ...(isOurProject && { recaptchaToken })
                };

                authActions.registerPasswordRequest(data);
            }
        },
        [authActions, email, password, recaptchaToken, token]
    );

    const handleVerify = useCallback((token) => setRecaptchaToken(token), [setRecaptchaToken]);

    const handleRecaptchaLoad = useCallback(() => {
        if (isOurProject && captchaRef && captchaRef.current) {
            setCaptchaReady(true);

            captchaRef.current.execute();
        }
    }, []);

    const handleHelpCenterButtonClick = () => {
        openLinkInBlankPage(`https://atendimento.${theme.project.brand}.com.br/`);
    };

    const HelpCenterLogo = () => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.1724 11.5071C23.1724 5.15183 17.9852 0 11.5865 0C5.18777 0 0 5.15109 0 11.5071C0 14.7993 1.39426 17.7661 3.62542 19.8635L3.61892 19.868C8.74242 24.6678 16.3287 25.2624 22.059 21.7748L19.8588 19.5612C21.9076 17.4852 23.1724 14.6435 23.1724 11.5071ZM11.5752 18.4784C11.3258 18.4784 11.082 18.405 10.8746 18.2674C10.6672 18.1298 10.5056 17.9342 10.4101 17.7054C10.3146 17.4766 10.2896 17.2247 10.3383 16.9818C10.3869 16.7389 10.507 16.5157 10.6833 16.3405C10.8596 16.1654 11.0843 16.0461 11.3289 15.9977C11.5735 15.9494 11.827 15.9742 12.0575 16.069C12.2879 16.1637 12.4848 16.3243 12.6234 16.5302C12.7619 16.7362 12.8359 16.9783 12.8359 17.226C12.8359 17.5581 12.7031 17.8766 12.4666 18.1115C12.2302 18.3463 11.9095 18.4783 11.5752 18.4784ZM14.6829 11.8744C14.0869 12.469 13.3299 12.8791 12.5038 13.055V14.1629C12.5038 14.4075 12.406 14.6421 12.2318 14.8151C12.0576 14.9881 11.8214 15.0853 11.5752 15.0853C11.3289 15.0853 11.0927 14.9881 10.9185 14.8151C10.7444 14.6421 10.6465 14.4075 10.6465 14.1629V12.2302C10.6465 11.9856 10.7444 11.751 10.9185 11.578C11.0927 11.4051 11.3289 11.3079 11.5752 11.3079C12.9746 11.3079 14.1129 10.1771 14.1129 8.78732C14.1129 7.39754 12.9746 6.26675 11.5752 6.26675C10.3846 6.26675 9.38356 7.08524 9.11054 8.18522C9.06185 8.38221 9.03728 8.58431 9.03736 8.78714C9.03736 9.03176 8.93952 9.26636 8.76537 9.43933C8.59121 9.61231 8.35501 9.70948 8.10872 9.70948C7.86243 9.70948 7.62622 9.61231 7.45207 9.43933C7.27792 9.26636 7.18008 9.03176 7.18008 8.78714C7.18017 8.06933 7.35848 7.36261 7.69923 6.72959C8.03997 6.09657 8.53263 5.55677 9.13357 5.158C9.73451 4.75924 10.4252 4.51381 11.1444 4.44346C11.8637 4.37312 12.5893 4.48001 13.257 4.75469C13.9247 5.02937 14.5139 5.46335 14.9724 6.0182C15.4309 6.57304 15.7446 7.23164 15.8857 7.93564C16.0268 8.63965 15.9909 9.36734 15.7812 10.0543C15.5715 10.7412 15.1945 11.3662 14.6835 11.8738L14.6829 11.8744Z"
                fill={isSmallScreen ? theme.colors.white : theme.colors.brand[300]}
            />
        </svg>
    );

    return (
        <SignWrapContent>
            {isLoading && <PageLoader />}

            <GeneralSignLeftContent>
                <LogoWrapper>
                    <img alt={theme.project.name} src={theme.project.logo} />
                </LogoWrapper>

                <div>
                    <SignTitlesWrap>
                        <SignTitle>O primeiro passo aqui é mais fácil</SignTitle>

                        <SignSubTitle>Para iniciar, você precisa criar uma senha de no mínimo 5 dígitos.</SignSubTitle>
                    </SignTitlesWrap>

                    <SignForm onSubmit={handleRegisterPasswordSubmit}>
                        <FieldWrapper>
                            <TextField autoFocus={true} type="password" name="password" label="Senha" value={password} onChange={handleSetPassword} />
                        </FieldWrapper>

                        <FieldWrapper>
                            <TextField
                                type="password"
                                name="confirmPassword"
                                label="Confirme sua senha"
                                value={confirmPassword}
                                onChange={handleSetConfirmPassword}
                            />
                        </FieldWrapper>

                        {isOurProject && (
                            <FieldWrapper>
                                <Reaptcha
                                    ref={captchaRef}
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                                    size="invisible"
                                    onRender={handleRecaptchaLoad}
                                    onVerify={handleVerify}
                                />
                            </FieldWrapper>
                        )}

                        <SignButtonsWrap>
                            <Button
                                data-test-id="submit-button"
                                disabled={!isFormValid || (isOurProject && !recaptchaToken) || isLoading || !token || !email}
                                type="submit"
                                variant="primary"
                                block={true}
                                size="medium"
                            >
                                Salvar e continuar
                            </Button>
                        </SignButtonsWrap>
                    </SignForm>
                </div>

                {isOurProject && (
                    <>
                        {isSmallScreen && (
                            <HelpCenterButtonWrapper>
                                <HelpCenterButton onClick={handleHelpCenterButtonClick}>
                                    <HelpCenterLogo />
                                    Central de ajuda!
                                </HelpCenterButton>
                            </HelpCenterButtonWrapper>
                        )}

                        <SignCopyright>
                            <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                                Termos de uso
                            </a>
                        </SignCopyright>
                    </>
                )}
            </GeneralSignLeftContent>

            <RightContent />

            {isOurProject && !isSmallScreen && (
                <HelpCenterButton onClick={handleHelpCenterButtonClick}>
                    <HelpCenterLogo />
                    Central de ajuda!
                </HelpCenterButton>
            )}
        </SignWrapContent>
    );
};

const MemoizedRegisterPasswordScreen = memo(RegisterPasswordScreen);

const mapStateToProps = ({ auth }: IReduxStore) => ({
    isLoading: auth.isLoading,
    hasError: auth.hasError
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedRegisterPasswordScreen);
