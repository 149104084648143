import styled from "styled-components";
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    align-items: baseline;
    gap: 24px;

    padding: 0 16px;
`;

export const Label = styled.p`
    color: ${theme.colors.base[300]};
    font-size: ${theme.typography.sizes.small};
    line-height: ${theme.typography.sizes.small};
    white-space: nowrap;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4px 12px;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.brand[300]};

    :hover {
        background-color: ${theme.colors.brand[100]};
    }
`;

export const ButtonLabel = styled.button`
    color: ${theme.colors.brand[300]};
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.08px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
`;
