// Dependencies
import { call, put, takeLatest, select, all, take } from "redux-saga/effects";
import axios from "axios";
import { eventChannel, END } from "redux-saga";

// Services
import api from "services/api";
import history from "services/history";

// Redux
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as activityActions } from "store/ducks/activity";
import { Types as ActivityTypes } from "store/ducks/activity";
import { IReduxStore } from "interfaces/IReduxStore";

// Utils
import { ternary } from "utils/ternary";
import removeTimezoneFromDate from "utils/removeTimezoneFromDate";
import splitDateFromHumanReadableToIsoStringFormat from "utils/splitDateFromHumanReadableToIsoStringFormat";

function* getStudentDashboardActivitiesRequest() {
    try {
        const { data } = yield call(api.get, "/activities/me");

        yield put(activityActions.getStudentDashboardActivitiesSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de atividades.", "danger"));
        yield put(activityActions.getStudentDashboardActivitiesFailure(error?.response?.data));
    }
}

function* getStudentExecutionActivityDetailsRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload}`);

        yield put(activityActions.getStudentExecutionActivityDetailsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a atividade.", "danger"));
        yield put(activityActions.getStudentExecutionActivityDetailsFailure(error?.response?.data));
    }
}

function* getStudentExecutionAnswerCardIdRequest(action: any) {
    try {
        const { data } = yield call(api.post, `/activities/${action.payload}/submissions`);

        yield put(activityActions.getStudentExecutionAnswerCardIdSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao criar cartão resposta.", "danger"));
        yield put(activityActions.getStudentExecutionAnswerCardIdFailure(error?.response?.data));
    }
}

function* createStudentExecutionActivitySubmissionRequest(action: any) {
    try {
        const { activityId, submissionId, comment, compose, files, shouldFinishAnswer } = action.payload;

        const body = {
            ...(!!comment && { comment }),
            ...(!!compose && { compose }),
            ...(!!files && { files }),
            ...(!!shouldFinishAnswer && { finish: shouldFinishAnswer })
        };

        let localSubmissionId = submissionId;

        if (!!shouldFinishAnswer && !localSubmissionId) {
            const { data } = yield call(api.post, `/activities/${activityId}/submissions`, body);

            localSubmissionId = data.id;
        }

        const { data } = yield call(
            !!localSubmissionId ? api.put : api.post,
            `/activities/${activityId}/submissions${!!localSubmissionId ? `/${localSubmissionId}` : ""}`,
            body
        );

        yield put(activityActions.createStudentExecutionActivitySubmissionSuccess(data));

        yield put(alertActions.showAlert(shouldFinishAnswer ? "Resposta salva com sucesso." : "Rascunho salvo com sucesso", "success"));

        if (data.status === "WaitingCorrection") {
            const courseSlug = yield select((state: IReduxStore) => state.course.slug);
            yield call(history.push, {
                pathname: `/app/curso/${courseSlug}/atividades`
            });
        }
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao enviar a resposta.", "danger"));
        yield put(activityActions.createStudentExecutionActivitySubmissionFailure(error?.response?.data));
    }
}

function* createStudentExecutionActivitySubmissionFileRequest(action: any) {
    try {
        const filesFormData: { file: File; formData: FormData; cancelTokenSource: any; activityId: number }[] = [];
        const { activityId, files } = action.payload;

        for (const file of files) {
            const formData = new FormData();
            formData.append("uploadFile", file);
            const cancelTokenSource = axios.CancelToken.source();

            filesFormData.push({ file, formData, cancelTokenSource, activityId });
        }

        const data = yield all(
            filesFormData.map(({ file, formData, cancelTokenSource, activityId }) => call(uploadFile, file, formData, cancelTokenSource, activityId))
        );

        const formattedData = data.filter((dataItem: any) => dataItem !== undefined).map((dataItem: any) => dataItem?.data);

        yield put(activityActions.createStudentExecutionActivitySubmissionFileSuccess(formattedData));
    } catch (error) {
        if (error === true) {
            yield put(alertActions.showAlert("Upload cancelado pelo usuário.", "warning"));
        } else {
            yield put(alertActions.showAlert("Ocorreu um erro ao enviar o arquivo.", "danger"));
            yield put(activityActions.createStudentExecutionActivitySubmissionFileFailure(error?.response?.data));
        }
    }
}

function* deleteStudentExecutionActivitySubmissionFileRequest(action: any) {
    try {
        const { activityId, fileId } = action.payload;

        yield call(api.delete, `/activities/${activityId}/submissions/upload/${fileId}`);

        yield put(activityActions.deleteStudentExecutionActivitySubmissionFileSuccess(fileId));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao deletar o arquivo.", "danger"));
        yield put(activityActions.deleteStudentExecutionActivitySubmissionFileFailure(error?.response?.data));
    }
}

function* getTeacherCreateActivityDetailsRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload}`);

        yield put(activityActions.getTeacherCreateActivityDetailsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a atividade.", "danger"));
        yield put(activityActions.getTeacherCreateActivityDetailsFailure(error?.response?.data));
    }
}

function* getTeacherCreateEnrollmentsRequest() {
    try {
        const { data } = yield call(api.get, `/activities/teacher/enrollments`);

        yield put(activityActions.getTeacherCreateEnrollmentsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as inscrições do professor.", "danger"));
        yield put(activityActions.getTeacherCreateEnrollmentsFailure(error?.response?.data));
    }
}

function* getTeacherCreateSubjectsRequest() {
    try {
        const { data } = yield call(api.get, `/student/subject/description`);

        yield put(activityActions.getTeacherCreateSubjectsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as matérias.", "danger"));
        yield put(activityActions.getTeacherCreateSubjectsFailure(error?.response?.data));
    }
}

function* getTeacherCreateQuestionsBankExerciseListsRequest() {
    try {
        const { data } = yield call(api.get, `/questionengine/exerciselist`, {
            params: {
                order: 3,
                page: 1,
                perPage: 1000
            }
        });

        yield put(activityActions.getTeacherCreateQuestionsBankExerciseListsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as listas de exercícios do banco de questões.", "danger"));
        yield put(activityActions.getTeacherCreateQuestionsBankExerciseListsFailure(error?.response?.data));
    }
}

function* createTeacherCreateActivityRequest(action: any) {
    try {
        const {
            mainData,
            activityType,
            generalInformations,
            exerciseListInformations,
            exerciseList,
            discursiveInformations,
            sendFileInformations,
            supportMaterials,
            id
        } = action.payload;

        const type = ternary([
            [activityType.data === "exercise-list", "ExerciseList"],
            [activityType.data === "discursive", "Composition"],
            [activityType.data === "send-file", "Upload"],
            [true, "ExerciseList"]
        ]);

        let splittedMainDataSendDate = mainData?.sendDate?.split("/");
        let splittedMainDataReleaseDate = mainData?.releaseDate?.split("/");

        const formattedPayload = {
            title: generalInformations?.title,
            enunciation: generalInformations?.enunciation,
            score: generalInformations?.score,
            type,
            ...(!!mainData?.sendDate &&
                (!id || (!!id && splittedMainDataSendDate.length !== 3)) && {
                    dueDate: removeTimezoneFromDate(mainData?.sendDate)
                }),
            ...(!!mainData?.releaseDate &&
                (!id || (!!id && splittedMainDataReleaseDate.length !== 3)) && {
                    releaseDate: removeTimezoneFromDate(mainData?.releaseDate)
                }),
            ...(!!mainData?.sendDate &&
                !!id &&
                splittedMainDataSendDate.length === 3 && {
                    dueDate: splitDateFromHumanReadableToIsoStringFormat(mainData?.sendDate)
                }),
            ...(!!mainData?.releaseDate &&
                !!id &&
                splittedMainDataReleaseDate.length === 3 && {
                    releaseDate: splitDateFromHumanReadableToIsoStringFormat(mainData?.releaseDate)
                }),
            classes: mainData?.classes,
            ownedByAuthor: activityType.exerciseListType === "custom",
            ...(activityType.data === "exercise-list" &&
                activityType.exerciseListType === "pre-created" && {
                    releaseResult: !!exerciseListInformations?.releaseResult,
                    reference: `${exerciseListInformations?.questionsBankExerciseListId}`
                }),
            ...(activityType.data === "exercise-list" &&
                activityType.exerciseListType === "custom" && {
                    releaseResult: !!exerciseListInformations?.releaseResult,
                    exerciseList: {
                        title: generalInformations?.title,
                        id: exerciseList.listId,
                        items: (exerciseList.questions ?? []).map((questionItem: any) => {
                            return {
                                order: questionItem.order,
                                ...(!!id && {
                                    id: questionItem.exerciseListItemId
                                }),
                                question: {
                                    ...(!!id && {
                                        id: questionItem.questionId
                                    }),
                                    enunciation: questionItem.statement,
                                    subject: questionItem.subject.value,
                                    alternatives: questionItem.alternatives.map((questionItemAlternative: any) => {
                                        return {
                                            ...(!!id && {
                                                id: questionItemAlternative.id
                                            }),
                                            order: questionItemAlternative.order.charCodeAt(0) - 64,
                                            isCorrection: questionItemAlternative.isCorrect,
                                            enunciation: questionItemAlternative.statement
                                        };
                                    })
                                }
                            };
                        })
                    }
                }),
            ...(activityType.data === "send-file" && {
                allowedFileTypes: sendFileInformations?.allowedFileTypes
            }),
            ...(activityType.data === "discursive" && {
                wordsLimit: discursiveInformations?.wordsLimit
            }),
            supportMaterials: (supportMaterials ?? []).map((supportMaterials: any) => supportMaterials?.id)
        };

        if (!id) {
            yield call(api.post, `/activities`, formattedPayload);
        } else {
            yield call(api.put, `/activities/${id}`, formattedPayload);
        }

        yield put(activityActions.createTeacherCreateActivitySuccess());

        yield put(alertActions.showAlert(`Atividade ${!id ? "criada" : "editada"} com sucesso`, "success"));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);

        yield call(history.push, {
            pathname: `/app/curso/${courseSlug}/atividades`
        });
    } catch (error) {
        yield put(alertActions.showAlert(`Ocorreu um erro ao ${!action?.payload?.id ? "criar" : "editar"} a atividade.`, "danger"));
        yield put(activityActions.createTeacherCreateActivityFailure(error?.response?.data));
    }
}

function* getTeacherCreateFileTypesRequest() {
    try {
        const { data } = yield call(api.get, `/activities/file-types`);

        yield put(activityActions.getTeacherCreateFileTypesSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar os tipos de arquivos.", "danger"));
        yield put(activityActions.getTeacherCreateFileTypesFailure(error?.response?.data));
    }
}

function createUploadChannel(file: any, fileFormData: any, cancelTokenSource: any, activityId?: number) {
    return eventChannel((emitter: any) => {
        const config = {
            onUploadProgress: (event: any) => {
                emitter({
                    progress: Math.round((event.loaded * 100) / event.total),
                    fileName: file.name
                });
            },
            cancelToken: cancelTokenSource.token
        };

        const route = !!activityId ? `/activities/${activityId}/submissions/upload` : "/activities/suport-materials";

        api.post(route, fileFormData, config)
            .then((response) => {
                emitter({ response });
                emitter(END);
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    emitter({ cancelled: true, fileName: file.name });
                } else {
                    emitter({ error });
                }
                emitter(END);
            });

        return () => {};
    });
}

function* uploadFile(file: any, fileFormData: any, cancelTokenSource: any, activityId?: number) {
    const uploadChannel = createUploadChannel(file, fileFormData, cancelTokenSource, activityId);

    try {
        while (true) {
            const { progress, fileName, response, error, cancelled } = yield take(uploadChannel);
            if (progress !== undefined) {
                !!activityId
                    ? yield put(
                          activityActions.createStudentExecutionActivitySubmissionFileSuccess([{ progress, name: fileName, cancelTokenSource }])
                      )
                    : yield put(activityActions.createTeacherCreateSupportMaterialsSuccess([{ progress, name: fileName, cancelTokenSource }]));
            } else if (response) {
                return response;
            } else if (cancelled) {
                return;
            } else if (error) {
                throw error;
            }
        }
    } catch (e) {
        console.error(e);
        return { error: e, fileName: file.name };
    }
}

function* createTeacherCreateSupportMaterialsRequest(action: any) {
    try {
        const filesFormData: { file: File; formData: FormData; cancelTokenSource: any }[] = [];

        for (const file of action.payload) {
            const formData = new FormData();
            formData.append("uploadFile", file);
            const cancelTokenSource = axios.CancelToken.source();

            filesFormData.push({ file, formData, cancelTokenSource });
        }

        const data = yield all(filesFormData.map(({ file, formData, cancelTokenSource }) => call(uploadFile, file, formData, cancelTokenSource)));

        const errorFile = data.find((dataItem: any) => !!dataItem.error);

        if (!!errorFile) {
            yield put(alertActions.showAlert("Ocorreu um problema ao enviar arquivos desse tipo", "danger"));
            yield put(activityActions.cancelTeacherCreateSupportMaterialUpload(errorFile.fileName));
        }

        const formattedData = data.filter((dataItem: any) => dataItem !== undefined && !dataItem.error).map((dataItem: any) => dataItem?.data);

        yield put(activityActions.createTeacherCreateSupportMaterialsSuccess(formattedData));
    } catch (error) {
        if (error === true) {
            yield put(alertActions.showAlert("Upload cancelado pelo usuário.", "warning"));
        } else {
            yield put(alertActions.showAlert("Ocorreu um erro ao enviar o material de suporte.", "danger"));
            yield put(activityActions.createTeacherCreateSupportMaterialsFailure(error?.response?.data));
        }
    }
}

function* deleteTeacherCreateSupportMaterialRequest(action: any) {
    try {
        yield call(api.delete, `/activities/suport-materials/${action.payload}`);

        yield put(activityActions.deleteTeacherCreateSupportMaterialSuccess(action.payload));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao remover o material de suporte.", "danger"));
        yield put(activityActions.deleteTeacherCreateSupportMaterialFailure(error?.response?.data));
    }
}

function* getTeacherDashboardActivitiesRequest(action: any) {
    try {
        const { data } = yield call(api.get, "/activities", {
            params: {
                ...action?.payload,
                classes: action?.payload?.classes?.join(","),
                statuses: action?.payload?.statuses?.join(",")
            }
        });

        yield put(activityActions.getTeacherDashboardActivitiesSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de atividades.", "danger"));
        yield put(activityActions.getTeacherDashboardActivitiesFailure(error?.response?.data));
    }
}

function* deleteTeacherDashboardActivityRequest(action: any) {
    try {
        yield call(api.delete, `/activities/${action.payload}`);

        yield put(activityActions.deleteTeacherDashboardActivitySuccess(action.payload));

        yield put(alertActions.showAlert("Atividade excluída com sucesso.", "success"));
    } catch (error) {
        yield put(alertActions.showAlert(error?.response?.data?.message ?? "Ocorreu um erro ao deletar a atividade.", "danger"));
        yield put(activityActions.deleteTeacherDashboardActivityFailure(error?.response?.data));
    }
}

function* getTeacherDashboardEnrollmentsRequest() {
    try {
        const { data } = yield call(api.get, `/activities/teacher/enrollments`);

        yield put(activityActions.getTeacherDashboardEnrollmentsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as inscrições do professor.", "danger"));
        yield put(activityActions.getTeacherDashboardEnrollmentsFailure(error?.response?.data));
    }
}

function* getTeacherActivitySubmissionsDataRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload}/submissions`);

        yield put(activityActions.getTeacherActivitySubmissionsDataSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as entregas da atividade.", "danger"));
        yield put(activityActions.getTeacherActivitySubmissionsDataFailure(error?.response?.data));
    }
}

function* getTeacherActivitySubmissionsActivityDetailsRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload}`);

        yield put(activityActions.getTeacherActivitySubmissionsActivityDetailsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a atividade.", "danger"));
        yield put(activityActions.getTeacherActivitySubmissionsActivityDetailsFailure(error?.response?.data));
    }
}

function* getActivitySubmissionDetailsRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload}`);

        yield put(activityActions.getActivitySubmissionDetailsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a atividade.", "danger"));
        yield put(activityActions.getActivitySubmissionDetailsFailure(error?.response?.data));
    }
}

function* getActivitySubmissionSubmissionRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/activities/${action.payload?.activityId}/submissions/${action.payload?.submissionId}`);

        yield put(activityActions.getActivitySubmissionSubmissionSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar os detalhes da entrega da atividade.", "danger"));
        yield put(activityActions.getActivitySubmissionSubmissionFailure(error?.response?.data));
    }
}

function* createActivitySubmissionTeacherCorrectionRequest(action: any) {
    try {
        yield call(api.put, `/activities/${action.payload?.activityId}/submissions/${action.payload?.submissionId}/evaluate`, {
            score: action.payload?.score,
            comment: action.payload?.comment
        });

        yield put(
            activityActions.createActivitySubmissionTeacherCorrectionSuccess({
                score: action.payload?.score,
                teacherComment: action.payload?.comment,
                status: "Finished"
            })
        );

        yield put(alertActions.showAlert("Atividade corrigida com sucesso.", "success"));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao corrigir a atividade.", "danger"));
        yield put(activityActions.createActivitySubmissionTeacherCorrectionFailure(error?.response?.data));
    }
}

export default [
    takeLatest(ActivityTypes.GET_STUDENT_DASHBOARD_ACTIVITIES_REQUEST, getStudentDashboardActivitiesRequest),
    takeLatest(ActivityTypes.GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_REQUEST, getStudentExecutionActivityDetailsRequest),
    takeLatest(ActivityTypes.GET_STUDENT_EXECUTION_ANSWER_CARD_ID_REQUEST, getStudentExecutionAnswerCardIdRequest),
    takeLatest(ActivityTypes.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_REQUEST, createStudentExecutionActivitySubmissionRequest),
    takeLatest(ActivityTypes.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST, createStudentExecutionActivitySubmissionFileRequest),
    takeLatest(ActivityTypes.DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST, deleteStudentExecutionActivitySubmissionFileRequest),
    takeLatest(ActivityTypes.GET_TEACHER_CREATE_ACTIVITY_DETAILS_REQUEST, getTeacherCreateActivityDetailsRequest),
    takeLatest(ActivityTypes.GET_TEACHER_CREATE_ENROLLMENTS_REQUEST, getTeacherCreateEnrollmentsRequest),
    takeLatest(ActivityTypes.GET_TEACHER_CREATE_SUBJECTS_REQUEST, getTeacherCreateSubjectsRequest),
    takeLatest(ActivityTypes.GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_REQUEST, getTeacherCreateQuestionsBankExerciseListsRequest),
    takeLatest(ActivityTypes.CREATE_TEACHER_CREATE_ACTIVITY_REQUEST, createTeacherCreateActivityRequest),
    takeLatest(ActivityTypes.GET_TEACHER_CREATE_FILE_TYPES_REQUEST, getTeacherCreateFileTypesRequest),
    takeLatest(ActivityTypes.CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_REQUEST, createTeacherCreateSupportMaterialsRequest),
    takeLatest(ActivityTypes.DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_REQUEST, deleteTeacherCreateSupportMaterialRequest),
    takeLatest(ActivityTypes.GET_TEACHER_DASHBOARD_ACTIVITIES_REQUEST, getTeacherDashboardActivitiesRequest),
    takeLatest(ActivityTypes.DELETE_TEACHER_DASHBOARD_ACTIVITY_REQUEST, deleteTeacherDashboardActivityRequest),
    takeLatest(ActivityTypes.GET_TEACHER_DASHBOARD_ENROLLMENTS_REQUEST, getTeacherDashboardEnrollmentsRequest),
    takeLatest(ActivityTypes.GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_REQUEST, getTeacherActivitySubmissionsDataRequest),
    takeLatest(ActivityTypes.GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_REQUEST, getTeacherActivitySubmissionsActivityDetailsRequest),
    takeLatest(ActivityTypes.GET_ACTIVITY_SUBMISSION_DETAILS_REQUEST, getActivitySubmissionDetailsRequest),
    takeLatest(ActivityTypes.GET_ACTIVITY_SUBMISSION_SUBMISSION_REQUEST, getActivitySubmissionSubmissionRequest),
    takeLatest(ActivityTypes.CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_REQUEST, createActivitySubmissionTeacherCorrectionRequest)
];
