import React, { useMemo } from "react";
import { IExamComposition } from "interfaces/IExam";

import * as S from "./styles";
import { KnowledgeAreasResultsItemTitle } from "screens/ExamDayResult/components/KnowledgeAreasResults/styles";
import Badge from "components/Badge";

import ChevronIcon from "prodigio-icons/web/outlined/ChevronRight";
import { EssayStatus } from "enums/EssayStatus";
import { ExamResultKnowledgeAreasCardItemNotes } from "screens/ExamResult/components/KnowledgeAreasCard/styles";
import isValidValue from "utils/isValidValue";

interface IProps {
    composition?: IExamComposition;
    onClickEssay(): void;
    rankingPosition?: number;
    totalRankUsers?: number;
}

const ESSAY_COLOR = "#663A21";

const ExamDayResultEssay: React.FC<IProps> = ({ composition, onClickEssay, rankingPosition, totalRankUsers }) => {
    const statusBadge = useMemo(() => {
        switch (composition?.status) {
            case EssayStatus.Draft:
                return (
                    <Badge variant="info" backgroundWeight={100}>
                        Rascunho
                    </Badge>
                );

            case EssayStatus.Pending:
                return (
                    <Badge variant="warning" backgroundWeight={100}>
                        Aguardando correção
                    </Badge>
                );

            case EssayStatus.CorrectorPending:
                return (
                    <Badge variant="warning" backgroundWeight={100}>
                        Em correção
                    </Badge>
                );

            case EssayStatus.Corrected:
                return (
                    <Badge variant="success" backgroundWeight={100}>
                        Corrigida
                    </Badge>
                );

            default:
                return (
                    <Badge variant="danger" backgroundWeight={100}>
                        Descartada
                    </Badge>
                );
        }
    }, [composition]);

    if (!composition) {
        return null;
    }

    return (
        <S.ExamDayResultEssayContainer onClick={onClickEssay}>
            <S.ExamDayResultEssayLine>
                <KnowledgeAreasResultsItemTitle color={ESSAY_COLOR}>Redação</KnowledgeAreasResultsItemTitle>

                {composition?.points && (
                    <S.ExamDayResultEssayNote>
                        nota: <strong>{composition?.points}</strong>
                    </S.ExamDayResultEssayNote>
                )}
            </S.ExamDayResultEssayLine>

            {isValidValue(rankingPosition) && (
                <ExamResultKnowledgeAreasCardItemNotes>
                    <p>
                        Ranking: <strong>{rankingPosition}°</strong>/{totalRankUsers}
                    </p>
                </ExamResultKnowledgeAreasCardItemNotes>
            )}
            <S.ExamDayResultEssayLine>
                {statusBadge}

                <S.ExamDayResultEssayButton onClick={onClickEssay}>
                    ver redação
                    <ChevronIcon width={8} height={8} />
                </S.ExamDayResultEssayButton>
            </S.ExamDayResultEssayLine>
        </S.ExamDayResultEssayContainer>
    );
};

export default ExamDayResultEssay;
