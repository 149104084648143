import api from "services/api";
import differenceBy from "lodash/differenceBy";
import { Creators as alertActions } from "../ducks/alert";
import { ICredentials } from "store/interfaces/ICredentials";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { Creators as credentialsActions } from "../ducks/credentials";
import { Creators as studentAreaActions, Types, IStudentAreaState, IConfirmChangePassword } from "../ducks/student-area";
import { IReduxStore } from "interfaces/IReduxStore";
import { ReduxAction } from "store";
import history from "services/history";
import { showAlertError } from "utils/showAlertError";

const getStudentArea = (state: IReduxStore) => state.studentArea;

function* getStudent() {
    try {
        const studentId = yield select((s: { credentials: ICredentials }) => s.credentials.id);

        const { data } = yield call(api.get, `/person/${studentId}`);

        yield put(studentAreaActions.studentSuccessed(data));
    } catch (error) {
        console.error("Não foi possível obter os dados do aluno.", error);

        yield put(studentAreaActions.studentFailure(error?.response?.data));
    }
}

function* saveStudent({ payload }: any) {
    try {
        const studentAreaInitialState = yield select((s: { studentArea: IStudentAreaState }) => s.studentArea);

        const personId = studentAreaInitialState.id;

        const { data } = yield call(api.patch, `/person/${personId}`, payload);

        yield put(studentAreaActions.studentSubscriptionsSuccessed(data));

        // Sobrescreve a valores no credentials.
        yield put(credentialsActions.getCredentialsSuccess(payload));
        yield put(alertActions.showAlert("Suas informações foram salvas", "success"));
    } catch (error) {
        console.error("Não foi possível salvar os dados do aluno.", error);

        yield put(studentAreaActions.studentSaveFailure(error?.response?.data));
        yield put(alertActions.showAlert("Ocorreu um erro ao salvar seu cadastro", "danger"));
    }
}

function* getStudentSubscriptions() {
    try {
        const { data } = yield call(api.get, `/student/subscription`);

        yield put(studentAreaActions.studentSubscriptionsSuccessed(data));
    } catch (error) {
        yield put(studentAreaActions.studentSubscriptionsFailure(error?.response?.data));
    }
}

function* getStudentFinancialData() {
    try {
        const { data } = yield call(api.get, `/student/order`);

        yield put(studentAreaActions.studentFinancialDataSuccessed(data));
    } catch (error) {
        console.error("Não foi possível obter os dados financeiros.", error);

        yield put(studentAreaActions.studentFinancialDataFailure(error?.response?.data));
    }
}

function* uploadStudentPicture({ payload }: any) {
    try {
        const uploadResponse = yield call(api.post, "/person/imageprofile", payload);

        yield put(studentAreaActions.studentPhotoUploadSuccessed(uploadResponse));
        // Sobrescreve a imagem no credentials.
        yield put(credentialsActions.getCredentialsSuccess(uploadResponse.data));
    } catch (error) {
        console.error("Não foi possível enviar a imagem.", error);

        yield put(studentAreaActions.studentPhotoUploadFailure(error?.response?.data));
        yield put(alertActions.showAlert("Ocorreu um erro ao salvar a imagem", "danger"));
    }
}

function* saveStudentPassword({ payload }: any) {
    try {
        const { oldPassword, newPassword, passwordConfirmation } = payload;

        const body = {
            oldPassword,
            newPassword,
            passwordConfirmation
        };

        const { data } = yield call(api.patch, "/student/user/password", body);

        if (!data?.email) {
            throw new Error();
        }

        yield put(studentAreaActions.studentSavePasswordSuccessed(data.email));
    } catch (error) {
        console.error("Não foi possível salvar os dados do aluno.", error);
        yield put(studentAreaActions.studentSavePasswordFailure(error?.response?.data));
        yield showAlertError(error, "Ocorreu um erro com sua solicitação. Tente novamente.");
    }
}

function* confirmChangeStudentPassword({ payload }: ReduxAction<IConfirmChangePassword>) {
    try {
        const { email, code } = payload;

        const body = {
            email,
            code
        };

        if (!email || !code) {
            throw new Error();
        }

        yield call(api.patch, "student/user/password/confirm", body);
        yield put(studentAreaActions.studentSavePasswordConfirmSuccess(body));
        yield put(credentialsActions.clearCredentials());
    } catch (error) {
        yield put(studentAreaActions.studentSavePasswordConfirmFailure(error?.response?.data));
        yield showAlertError(error, "Ocorreu um erro com sua solicitação. Tente novamente.");
        yield call(history.push, { pathname: `/` });
    }
}

function* getOrderAddressLess({ payload }: any) {
    try {
        const { data } = yield call(api.get, "/student/order/addressless");

        yield put({ type: "GET_ORDER_ADDRESSLESS_SUCCESS", payload: data });
    } catch (error) {
        console.error("error", error);

        yield put({ type: "GET_ORDER_ADDRESSLESS_FAILURE", payload: error?.response?.data });
    }
}

function* createOrderAddress(action: any) {
    try {
        const { code, address } = action.payload;

        yield call(api.post, `/student/order/${code}/address`, address);

        const { orderAddressLess, ordersFixeds } = yield select(getStudentArea);
        const formattedOrderAddressLess = orderAddressLess.map((item: any) => item.code);

        const result = differenceBy(formattedOrderAddressLess, [...ordersFixeds, code]);

        if (!Boolean(result.length)) {
            yield put({ type: "CLEAR_ORDER_PROBLEMS" });
        }

        yield put({ type: "CREATE_ORDER_ADDRESS_SUCCESS", payload: { code } });
    } catch (error) {
        console.error("error", error);

        yield put({ type: "CREATE_ORDER_ADDRESS_FAILURE", payload: error?.response?.data });
    }
}

export default [
    takeLatest(Types.STUDENT_REQUEST, getStudent),
    takeLatest(Types.STUDENT_SAVE_REQUEST, saveStudent),
    takeLatest(Types.STUDENT_SUBSCRIPTIONS_REQUEST, getStudentSubscriptions),
    takeLatest(Types.STUDENT_FINANCIAL_DATA_REQUEST, getStudentFinancialData),
    takeLatest(Types.STUDENT_SAVE_PASSWORD_REQUEST, saveStudentPassword),
    takeLatest(Types.STUDENT_SAVE_PASSWORD_CONFIRM_REQUEST, confirmChangeStudentPassword),
    takeLatest(Types.STUDENT_PHOTO_UPLOAD_REQUEST, uploadStudentPicture),
    takeLatest(Types.GET_ORDER_ADDRESSLESS_REQUEST, getOrderAddressLess),
    takeLatest(Types.CREATE_ORDER_ADDRESS_REQUEST, createOrderAddress)
];
