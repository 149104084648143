// Dependencies
import React, { FunctionComponent, ReactNode, useState, useEffect } from "react";

// Styles
import { Container } from "./styles";

// Components
import { WebpInput } from "webapp-components/dist/libs/webp-components-react";

type WebpSearchInputProps = {
    id?: string;
    searchInputValue?: string;
    noBorder?: boolean;
    searchInputComposition?: ReactNode;
    onWebpDropdownSearchChanged?: (ev: any) => void;
};

export const WebpSearchInput: FunctionComponent<WebpSearchInputProps> = ({ noBorder, onWebpDropdownSearchChanged, searchInputComposition }) => {
    const [searchInputValue, setSearchInputValue] = useState<string>();

    const handleSearchChange = (e: any) => {
        e.preventDefault();

        setSearchInputValue(e.detail.value);

        if (!!onWebpDropdownSearchChanged) {
            onWebpDropdownSearchChanged({
                detail: {
                    value: e.detail.value
                }
            });
        }
    };

    useEffect(() => {
        window.addEventListener("clearDropdownFilterSearchBox", () => {
            setSearchInputValue("");
        });
    }, []);

    return (
        <Container className="webp-search-input">
            <WebpInput
                className="search-input"
                type="search"
                placeholder="Buscar"
                noBorder={noBorder}
                onWebpInputChange={(e) => handleSearchChange(e)}
                value={searchInputValue}
            />

            {searchInputComposition}
        </Container>
    );
};
