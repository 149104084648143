import styled from "styled-components";
import { theme } from "config/theme";

export const ErrorUIContainer = styled.div`
    width: 100%;
    height: 100%;
    font-family: ${theme.typography.family.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h2,
    p,
    svg {
        margin-bottom: ${theme.spacing.small};
    }
`;
