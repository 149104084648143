export function openLinkInBlankPage(href: string) {
    if (!href) {
        return;
    }

    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = href;
    anchor.rel = "noopener noreferrer";
    anchor.text = new Date().toISOString();
    anchor.id = new Date().toISOString();

    anchor.click();
}
