import { theme } from "config/theme";
import styled from "styled-components";

export const BookletModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    padding: 0 30px 20px;
    width: calc(100vw - 40px);
`;

export const BookletModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: calc(100vw - 100px);
    padding: 16px 0;
    background-color: #fff;
`;

export const Divider = styled.div`
    margin-bottom: 64px;
`;

export const BookletModalTitle = styled.strong`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.medium};
    font-style: italic;
    display: flex;
    align-items: center;
    font-weight: 500;
`;

export const BookletModalContent = styled.div`
    width: 100%;
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;
    height: 100%;

    ::before {
        padding-top: 70%;
        width: 100%;
        display: block;
        content: "";
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
`;
