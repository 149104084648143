import { createActions, createReducer } from "reduxsauce";
import { ILessonFreeCTAActionsCreators, LessonFreeCTAActionsType, ILessonFreeCTAState } from "store/interfaces/ILessonFreeCTA";

export const { Creators, Types } = createActions<Record<LessonFreeCTAActionsType, LessonFreeCTAActionsType>, ILessonFreeCTAActionsCreators>({
    getLessonFreeRequest: [],
    getLessonFreeSuccess: ["payload"],
    getLessonFreeFailure: []
});

export const INITIAL_STATE: ILessonFreeCTAState = {
    isLoading: true,
    content: {
        cta: "",
        bancoQuestoes: "",
        modulo: "",
        redacoes: "",
        duvidas: ""
    }
};

const getLessonFreeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getLessonFreeSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        content: action.payload.content
    };
};

const getLessonFreeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_LESSON_FREE_REQUEST]: getLessonFreeRequest,
    [Types.GET_LESSON_FREE_SUCCESS]: getLessonFreeSuccess,
    [Types.GET_LESSON_FREE_FAILURE]: getLessonFreeFailure
});
