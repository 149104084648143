import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { FlatVariant } from "utils/types/Variant";
import { MediaQueries } from "assets/styles/settings";

interface ISwitchRectProps {
    active: boolean;
    variant: FlatVariant;
}

export const SwitchCircle = styled.span<ISwitchRectProps>`
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: ${({ variant, active }) => (active ? theme.colors.system[variant][300] : theme.colors.base[100])};
    position: absolute;

    ${({ active }) =>
        active
            ? css`
                  right: -3px;
              `
            : css`
                  left: -3px;
              `}

    top: -3px;

    transition: all 0.3s;
`;

export const SwitchRect = styled.span<ISwitchRectProps>`
    width: 34px;
    height: 14px;
    background: ${({ variant, active }) => (active ? theme.colors.system[variant][200] : theme.colors.base[200])};
    border-radius: 10px;
    display: inline-block;
    position: relative;
    transition: all 0.25s;
`;

export const SwitchButton = styled.button<ISwitchRectProps>`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        ${SwitchRect} {
            background: ${({ variant, active }) => (active ? theme.colors.system[variant][200] : theme.colors.base[200])};
            box-shadow: ${theme.shadow[1]};
        }

        ${SwitchCircle} {
            background: ${({ variant, active }) => (active ? theme.colors.system[variant][400] : theme.colors.base[100])};
            box-shadow: ${theme.shadow[1]};
        }
    }
`;

export const SwitchLabel = styled.label`
    margin-left: ${theme.spacing.small};

    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;
    font-size: ${theme.typography.sizes.smallX};
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.smallX};
    }
`;
