import React from "react";

import Skeleton from "components/Skeleton";

import * as S from "./styles";

const EssayCardDetailsAttachmentsPlaceholder = () => (
    <>
        <Skeleton width="20%" height="14px" style={{ marginBottom: 16 }} />
        <S.EssayCardDetailsFooterList>
            <S.EssayCardDetailsFooterListItem>
                <S.EssayCardDetailsFooterListItemWrapper>
                    <Skeleton width="16px" height="16px" style={{ borderRadius: 4, marginLeft: 0 }} />
                    <Skeleton width="100px" height="14px" style={{ marginLeft: 16 }} />
                </S.EssayCardDetailsFooterListItemWrapper>
                <Skeleton width="16px" height="16px" style={{ borderRadius: 4 }} />
            </S.EssayCardDetailsFooterListItem>
            <S.EssayCardDetailsFooterListItem>
                <S.EssayCardDetailsFooterListItemWrapper>
                    <Skeleton width="16px" height="16px" style={{ borderRadius: 4, marginLeft: 0 }} />
                    <Skeleton width="100px" height="14px" style={{ marginLeft: 16 }} />
                </S.EssayCardDetailsFooterListItemWrapper>
                <Skeleton width="16px" height="16px" style={{ borderRadius: 4 }} />
            </S.EssayCardDetailsFooterListItem>
        </S.EssayCardDetailsFooterList>
    </>
);

export default EssayCardDetailsAttachmentsPlaceholder;
