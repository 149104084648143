// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    PerformanceTopicWrapper,
    PerformanceTopicInfoWrapper,
    PerformanceTopicNameSkeleton,
    PerformanceTopicContentWrapper,
    PerformanceTopicQuestionsWrapper,
    PerformanceTopicQuestionSkeleton,
    PerformanceTopicQuestionDivider,
    PerformanceTopicAssertivenessWrapper,
    PerformanceTopicAssertivenessSkeleton,
    Border
} from "./performance-topic.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceTopicSkeleton: FunctionComponent = () => {
    return (
        <>
            <PerformanceTopicWrapper>
                <PerformanceTopicInfoWrapper>
                    <Skeleton width="30px" height="30px" borderRadius="4px" />

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={4} />} />

                    <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="horizontal" size={24} />} />

                    <PerformanceTopicNameSkeleton>
                        <Skeleton width="200px" height="100%" borderRadius="4px" />
                    </PerformanceTopicNameSkeleton>
                </PerformanceTopicInfoWrapper>

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={12} />} />

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="horizontal" size={32} />} />

                <PerformanceTopicContentWrapper>
                    <PerformanceTopicQuestionsWrapper>
                        <PerformanceTopicQuestionSkeleton>
                            <Skeleton width="83px" height="100%" borderRadius="4px" />
                        </PerformanceTopicQuestionSkeleton>

                        <Spacing direction="horizontal" size={16} />

                        <PerformanceTopicQuestionDivider />

                        <Spacing direction="horizontal" size={16} />

                        <PerformanceTopicQuestionSkeleton>
                            <Skeleton width="72px" height="100%" borderRadius="4px" />
                        </PerformanceTopicQuestionSkeleton>

                        <Spacing direction="horizontal" size={16} />
                    </PerformanceTopicQuestionsWrapper>

                    <PerformanceTopicAssertivenessWrapper>
                        <Skeleton width="4px" height="16px" borderRadius="8px" />

                        <Spacing direction="horizontal" size={8} />

                        <PerformanceTopicAssertivenessSkeleton>
                            <Skeleton width="139px" height="100%" borderRadius="4px" />
                        </PerformanceTopicAssertivenessSkeleton>
                    </PerformanceTopicAssertivenessWrapper>
                </PerformanceTopicContentWrapper>
            </PerformanceTopicWrapper>

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={12} />} />

            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="vertical" size={16} />} />

            <Border />
        </>
    );
};
