import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { BreadcrumbContainer } from "components/BreadCrumb/styles";
import { DropdownButton, DropdownContainer, DropdownContent } from "components/Dropdown/styles";
import { BodyText1, BodyText2, Button3, Micro, TitleH2, TitleH4 } from "assets/styles/global";

export const PageHeaderContainer = styled.header`
    padding: ${theme.spacing.medium} 0;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        background: ${theme.colors.white};

        ${BreadcrumbContainer} {
            margin-bottom: ${theme.spacing.smallX};
        }
    }
`;

export const SubjectNameWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.small};
    margin-top: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: baseline;
        margin: 0;

        ${DropdownButton} {
            color: ${theme.colors.base[200]};
        }
    }
`;

export const PageHeaderWrapper = styled(SubjectNameWrapper)``;

export const PageHeaderTitle = styled.h1<{ variant?: "simple" | "complex" }>`
    ${({ variant }) =>
        variant === "complex"
            ? css`
                  color: ${theme.colors.base[500]};
                  ${TitleH2};
              `
            : css`
                  color: ${theme.colors.brand[300]};
                  ${TitleH4}
              `}
`;

export const PageHeaderButton = styled.button`
    ${Button3};
    align-items: center;
    color: ${theme.colors.system.info[400]};
    display: flex;
`;

export const PageHeaderButtonIcon = styled.div`
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 50%;
    box-shadow: ${theme.shadow[2]};
    display: flex;
    height: 24px;
    margin-left: ${theme.spacing.smallX};
    justify-content: center;
    width: 24px;
`;

export const PageHeaderText = styled.p`
    ${Micro};
    align-items: baseline;
    color: ${theme.colors.base[300]};
    display: flex;

    span {
        ${Button3};
        color: ${theme.colors.brand[300]};
        margin: 0 ${theme.spacing.smallXX};
        font-weight: 600;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};

        .ico-chevron-right {
            margin-left: ${theme.spacing.smallX};
        }
    }
`;

export const PageHeaderRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${PageHeaderText},
        ${PageHeaderButton} {
            ${BodyText1};
        }
    }
`;

export const PageHeaderContainerComplex = styled.header`
    padding: ${theme.spacing.medium} 0;

    ${PageHeaderText} {
        margin-top: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.large} 0;
    }
`;

export const PageHeaderButtons = styled.div`
    align-items: center;
    display: flex;

    ${ButtonWrapper} {
        margin-right: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${ButtonWrapper} {
            margin-right: 0;
        }
    }
`;

export const PageHeaderBack = styled.div`
    margin-bottom: ${theme.spacing.small};
`;

export const PageHeaderCreditWrapper = styled.div<{ isHorizontal?: boolean }>`
    display: flex;
    flex-direction: column;

    ${({ isHorizontal }: any) =>
        !!isHorizontal &&
        css`
            align-items: flex-end;
        `}
`;

export const PageHeaderCreditContent = styled.div`
    display: flex;
    flex-direction: row;

    ${DropdownContent} {
        &:before {
            right: 3px;
        }
    }

    ${DropdownContainer} {
        margin-top: 6px;
    }

    > div {
        margin-left: 5px;
    }
`;

export const PageHeaderContent = styled.div<{ isHorizontal?: boolean }>`
    ${({ isHorizontal }: any) =>
        !!isHorizontal &&
        css`
            display: flex;
            align-items: center;
            justify-content: space-between;
        `}
`;
