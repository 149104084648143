import { createActions, createReducer } from "reduxsauce";
import { IAuthActionsCreators, IAuthState, AuthActionsType } from "store/interfaces/IAuth";
import { ReduxAction } from "store";
import { INavigation } from "interfaces/INavigation";
import { navigation } from "config/theme";

export const { Creators, Types } = createActions<Record<AuthActionsType, AuthActionsType>, IAuthActionsCreators>({
    loginRequest: ["payload"],
    loginSuccess: ["payload"],
    loginFailure: [],

    layersRequest: ["payload"],
    layersSuccess: ["payload"],
    layersFailure: [],

    loginFacebook: ["payload"],

    refreshTokenRequest: ["payload"],
    refreshTokenSuccess: ["payload"],
    refreshTokenFailure: [],

    registerPasswordRequest: ["payload"],
    registerPasswordSuccess: [],
    registerPasswordFailure: [],

    signUpRequest: ["payload"],
    signUpFailure: [],

    recoverPasswordRequest: ["payload"],
    recoverPasswordSuccess: [],
    recoverPasswordFailure: [],

    resetPasswordRequest: ["payload"],
    resetPasswordSuccess: [],
    resetPasswordFailure: [],

    logout: ["payload"],

    clearLoading: [],
    clearErrors: [],

    getMenuRequest: [],
    getMenuSuccess: ["payload"],

    platformSearchRequest: ["payload"],
    platformSearchSuccess: ["payload"],
    platformSearchFailure: [],
    clearPlatformSearch: [],

    setRedirectPath: ["payload"],
    clearRedirectPath: []
});

export const INITIAL_STATE: IAuthState = {
    isLoading: false,
    hasError: false,
    token: "",
    refreshed: false,
    tokenLegacy: "",
    expires_in: 0,
    refresh_expires_in: 0,
    refresh_token: "",
    credentials: {
        id: 0,
        email: "",
        name: "",
        slug: "",
        imageProfile: null,
        teacher: null,
        student: null
    },
    menu: navigation || [],
    platformSearch: {
        hasSearch: false,
        hasAnswered: false
    },
    isAuthenticatedByLayers: false
};

const loginSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    hasError: false
});
const layersLoginSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    hasError: false,
    isAuthenticatedByLayers: true
});

const signUpRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, hasError: false });
const signUpFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, hasError: true });

const registerPasswordRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, hasError: false });
const registerPasswordSuccess = (state = INITIAL_STATE) => ({ ...state, isLoading: false, hasError: false });
const registerPasswordFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, hasError: true });

const actionRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, hasError: false });
const actionSuccess = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearLoading = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const authResetState = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const refreshTokenRequest = (state = INITIAL_STATE) => ({ ...state, refreshed: true });
const refreshTokenSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.payload, refreshed: false, hasError: false });
const refreshTokenFailure = (state = INITIAL_STATE) => ({ ...state, refreshed: false });

const clearErrors = (state = INITIAL_STATE) => ({ ...state, hasError: false });

const getMenuRequest = (state = INITIAL_STATE) => ({ ...state });
const getMenuSuccess = (state = INITIAL_STATE, action: ReduxAction<INavigation[]>) => ({ ...state, menu: action?.payload || [] });

const platformSearchRequest = (state = INITIAL_STATE) => ({ ...state });
const platformSearchSuccess = (state = INITIAL_STATE, action: any) => ({ ...state, platformSearch: action.payload });
const platformSearchFailure = (state = INITIAL_STATE) => ({ ...state, platformSearch: INITIAL_STATE.platformSearch });
const clearPlatformSearch = (state = INITIAL_STATE) => ({ ...state, platformSearch: INITIAL_STATE.platformSearch });

const setRedirectPath = (state = INITIAL_STATE, action: any) => ({ ...state, redirectPath: action.payload });
const clearRedirectPath = (state = INITIAL_STATE) => ({ ...state, redirectPath: undefined });

export default createReducer<IAuthState>(INITIAL_STATE, {
    [Types.LOGIN_REQUEST]: actionRequest,
    [Types.LOGIN_SUCCESS]: loginSuccess,
    [Types.LOGIN_FAILURE]: authResetState,

    [Types.LAYERS_REQUEST]: actionRequest,
    [Types.LAYERS_SUCCESS]: layersLoginSuccess,
    [Types.LAYERS_FAILURE]: authResetState,

    [Types.LOGIN_FACEBOOK]: actionRequest,
    [Types.LOGOUT]: authResetState,

    [Types.REFRESH_TOKEN_REQUEST]: refreshTokenRequest,
    [Types.REFRESH_TOKEN_SUCCESS]: refreshTokenSuccess,
    [Types.REFRESH_TOKEN_FAILURE]: refreshTokenFailure,

    [Types.SIGN_UP_REQUEST]: signUpRequest,
    [Types.SIGN_UP_FAILURE]: signUpFailure,

    [Types.REGISTER_PASSWORD_REQUEST]: registerPasswordRequest,
    [Types.REGISTER_PASSWORD_SUCCESS]: registerPasswordSuccess,
    [Types.REGISTER_PASSWORD_FAILURE]: registerPasswordFailure,

    [Types.RECOVER_PASSWORD_REQUEST]: actionRequest,
    [Types.RECOVER_PASSWORD_SUCCESS]: actionSuccess,
    [Types.RECOVER_PASSWORD_FAILURE]: authResetState,

    [Types.RESET_PASSWORD_REQUEST]: actionRequest,
    [Types.RESET_PASSWORD_SUCCESS]: actionSuccess,
    [Types.RESET_PASSWORD_FAILURE]: authResetState,

    [Types.CLEAR_LOADING]: clearLoading,
    [Types.CLEAR_ERRORS]: clearErrors,

    [Types.GET_MENU_REQUEST]: getMenuRequest,
    [Types.GET_MENU_SUCCESS]: getMenuSuccess,

    [Types.PLATFORM_SEARCH_REQUEST]: platformSearchRequest,
    [Types.PLATFORM_SEARCH_SUCCESS]: platformSearchSuccess,
    [Types.PLATFORM_SEARCH_FAILURE]: platformSearchFailure,
    [Types.CLEAR_PLATFORM_SEARCH]: clearPlatformSearch,

    [Types.SET_REDIRECT_PATH]: setRedirectPath,
    [Types.CLEAR_REDIRECT_PATH]: clearRedirectPath
});
