// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 1.333;
        font-family: "Nunito", sans-serif;
        font-style: normal;
        color: ${theme.colors.black};
        display: flex;
        width: fit-content;
        width: -moz-fit-content;
        white-space: nowrap;
    }
`;
