import React, { useEffect, useCallback } from "react";
import { ExerciseListEssayThemeContainer, ExerciseListEssayThemeDownloadButtons, ExerciseListEssayThemeInfoCard } from "./styles";
import ApoioIcon from "prodigio-icons/web/filled/Apoio";
import TemplateSheetIcon from "prodigio-icons/web/filled/TemplateSheet";
import Button from "components/Button";
import EssayPaperModel from "static/folha-modelo-redacao.pdf";
import { IExerciseListEssayTheme } from "interfaces/IExerciseList";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as examActions } from "store/ducks/exam";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { useParams } from "react-router";
interface IProps {
    essayTheme: IExerciseListEssayTheme;
}

const ExerciseListEssayTheme: React.FC<IProps> = ({ essayTheme }) => {
    const dispatch = useDispatch();

    const { examId, examDayId, answerCardId } = useParams<{ examId?: string; examDayId?: string; answerCardId?: string }>();
    const { dayResult } = useSelector((state: IReduxStore) => state.exam);

    const requestResult = useCallback(() => {
        if (!examDayId || !answerCardId) {
            return;
        }

        const payload: IPayloadRequest = {
            endpoint: `/student/examday/${examDayId}/realization/${answerCardId}`,
            method: "GET",
            body: {
                examId,
                examDayId
            }
        };

        dispatch(examActions.getExamDayResultRequest(payload));
    }, [answerCardId, dispatch, examDayId, examId]);

    useEffect(() => {
        requestResult();
    }, [requestResult]);

    return (
        <ExerciseListEssayThemeContainer>
            {dayResult?.examDay?.exam?.isAvailable && (
                <ExerciseListEssayThemeInfoCard>
                    <span>
                        Você está fazendo o simulado fora de uma janela de aplicação, por isso sua redação não será enviada automaticamente para
                        correção ao finalizar. Se desejar que sua redação seja corrigida, basta navegar até a opção "Redações" do menu. Sua redação
                        estará salva como rascunho e poderá ser visualizada na opção "Minhas redações". Nesta tela você poderá enviar sua redação para
                        correção.
                    </span>
                </ExerciseListEssayThemeInfoCard>
            )}

            <strong>Tema: </strong>

            <p>{essayTheme.title}</p>

            <strong>Downloads:</strong>

            <ExerciseListEssayThemeDownloadButtons>
                {!!essayTheme?.pathFile && (
                    <Button variant="outline-info" size="small" as="a" target="_blank" href={essayTheme.pathFile}>
                        <ApoioIcon />
                        <span>Texto de apoio</span>
                    </Button>
                )}
                <Button variant="outline-info" size="small" as="a" target="_blank" href={EssayPaperModel}>
                    <TemplateSheetIcon />
                    <span>Folha modelo</span>
                </Button>
            </ExerciseListEssayThemeDownloadButtons>
        </ExerciseListEssayThemeContainer>
    );
};

export default ExerciseListEssayTheme;
