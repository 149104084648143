// Dependencies
import styled, { css } from "styled-components/macro";

type ElementStyleProps = {
    active: boolean;
};

export const Label = styled.p<ElementStyleProps>`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #505462;

    ${({ active }) =>
        active &&
        css`
            color: #ffffff;
        `}
`;

export const Container = styled.div<ElementStyleProps>`
    width: 100%;
    padding: 8px 12px;
    background-color: #ffffff;
    border-radius: 4px;
    transition: 0.2s all ease-in-out;

    ${({ active }) =>
        active &&
        css`
            background-color: #794a7d;
        `}

    ${({ active }) =>
        !active &&
        css`
            cursor: pointer;

            :hover {
                background-color: #794a7d;

                > ${Label} {
                    color: #ffffff;
                }
            }
        `}
`;
