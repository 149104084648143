import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const ExerciseListLetterContainer = styled.button<{
    isCorrect: boolean;
    isWrong: boolean;
    isMarked: boolean;
    currentCursor: boolean;
    isCorrectNotMarked: boolean;
}>`
    border: 0;
    outline: none;
    width: 30px;
    height: 30px;
    text-transform: uppercase;
    border-radius: 100%;
    font-weight: bold;
    background-color: ${theme.colors.base[200]};
    color: ${theme.colors.base[400]};

    ${({ currentCursor }) => {
        if (currentCursor) {
            return css`
                cursor: pointer;

                ${MediaQueries.BIGGER_THAN_SMALL} {
                    :active {
                        background: ${theme.colors.system.info[200]};
                        color: ${theme.colors.base[500]};
                    }
                }
            `;
        }

        return css`
            cursor: initial;
        `;
    }};

    ${({ isCorrect, isWrong, isMarked, isCorrectNotMarked }) => {
        if (isCorrect) {
            return css`
                background-color: ${theme.colors.system.success[200]};
                color: ${theme.colors.base[500]};
            `;
        }

        if (isWrong) {
            return css`
                background-color: ${theme.colors.system.danger[200]};
                color: ${theme.colors.base[500]};
            `;
        }

        if (isMarked) {
            return css`
                background-color: ${theme.colors.system.info[200]};
                color: ${theme.colors.base[500]};
            `;
        }

        if (isCorrectNotMarked) {
            return css`
                box-shadow: inset 0px 0px 0px 3px ${theme.colors.system.success[200]};
            `;
        }
    }};
`;
