import React from "react";

import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";
import { CloseButton, ContainerModal, PositionTitle } from "./styles";

interface IProps {
    title: string;
    children: any;
    onClose(): void;
}

const ModalContentSearch = ({ children, title, onClose }: IProps) => {
    const handleClose = (event: any) => {
        event.stopPropagation();
        onClose();
    };

    return (
        <ContainerModal>
            <CloseButton onClick={handleClose}>
                <ChevronDownIcon />
            </CloseButton>
            <PositionTitle>
                <h3>{title}</h3>
            </PositionTitle>
            {children}
        </ContainerModal>
    );
};

export default ModalContentSearch;
