import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// Assets
import {
    ExerciseListDashboardAnswerCardResultLink,
    ExerciseListDashboardAnswerCard,
    ExerciseListDashboardAnswerCardResultHeader,
    ExerciseListDashboardAnswerCardResultExerciseDataFooter,
    ExerciseListDashboardAnswerCardResultLegendBadge,
    ExerciseListDashboardAnswerCardResultLegendItem,
    ExerciseListDashboardAnswerCardResultTime,
    ExerciseListDashboardAnswerCardResultTitle,
    ExerciseListDashboardAnswerCardResultPoints,
    ExerciseListDashboardAnswerCardResultNote,
    ExerciseListDashboardAnswerCardResultButton
} from "./styles";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// Components
import Icon from "components/Icons/icon";

// Helpers
import { DateTime } from "luxon";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import AnswerCardProgress from "components/AnswerCardProgress";
import { getInterval } from "utils/getInterval";
import { IAnswerCard } from "store/ducks/answerCard";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    answerCard: IAnswerCard;
    exerciseListId: number;
    questionsTotal: number;
    isExtra?: boolean;
    isExam?: boolean;
    subjectSlug?: string;
    moduleId?: string;
    examId?: string;
    examDayId?: string;
    projectId?: string;
    onClickResult?(): void;
}

const ExerciseListDashboardAnswerCardResult = ({
    answerCard,
    exerciseListId,
    isExtra = false,
    subjectSlug,
    moduleId,
    examId = "",
    examDayId = "",
    questionsTotal = 0,
    projectId,
    isExam = false,
    onClickResult
}: IProps) => {
    const { realizationMode, status, endDate, startDate, answersCorrect = 0, points = 0, totalAnswered = 0, answersWrong = 0 } = answerCard;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isFinished = useMemo(() => status >= AnswerCardStatus.Done, [status]);

    const isStudy = useMemo(() => realizationMode === AnswerCardRealizationMode.Study, [realizationMode]);
    const isAwaitingCorrection = status !== AnswerCardStatus.Corrected;

    const formatedDate = useMemo(() => {
        try {
            const date = isFinished ? (!!endDate ? endDate : new Date().toString()) : startDate;

            if (!DateTime.fromISO(date).isValid) {
                throw new Error("Invalid date");
            }

            return `em ${DateTime.fromISO(date).toFormat("dd/MM/yyyy")}`;
        } catch (error) {
            return null;
        }
    }, [endDate, startDate, isFinished]);

    const memoInterval = useMemo(() => {
        try {
            if (!endDate) {
                throw new Error();
            }

            const currentInterval = getInterval({ endDate, startDate });

            if (!currentInterval) {
                throw new Error();
            }

            return (
                <ExerciseListDashboardAnswerCardResultTime>
                    <Icon width="15px" height="16px" idIcon="ico-timer" viewBox="0 0 15 17" />
                    <span>
                        <strong>{currentInterval}</strong>
                    </span>
                </ExerciseListDashboardAnswerCardResultTime>
            );
        } catch (error) {
            return null;
        }
    }, [endDate, startDate]);

    const CTAButton = useMemo(() => {
        try {
            if (isExam) {
                return (
                    <ExerciseListDashboardAnswerCardResultButton onClick={() => onClickResult && onClickResult()}>
                        <span>Ver desempenho</span>
                        <ChevronRightIcon width={14} height={8} />
                    </ExerciseListDashboardAnswerCardResultButton>
                );
            }

            if (isExtra) {
                if (isAwaitingCorrection) {
                    throw new Error();
                }

                return (
                    <ExerciseListDashboardAnswerCardResultLink
                        to={`/app/curso/${courseSlug}/lista-de-exercicios/${exerciseListId}/prova/${answerCard.id}`}
                    >
                        <span>Visualizar</span>
                        <ChevronRightIcon width={14} height={8} />
                    </ExerciseListDashboardAnswerCardResultLink>
                );
            }

            if (!!examId && !!examDayId) {
                return (
                    <ExerciseListDashboardAnswerCardResultLink
                        to={`/app/curso/${courseSlug}/provas-anteriores/${examId}/dia/${examDayId}/${isStudy ? "estudo" : "prova"}/${answerCard.id}`}
                    >
                        <span>Minhas Respostas</span>
                        <ChevronRightIcon width={14} height={8} />
                    </ExerciseListDashboardAnswerCardResultLink>
                );
            }

            const getUrlPath = () => {
                if (!!projectId) {
                    return `/app/curso/${courseSlug}/projetos/${projectId}/lista-de-exercicios/${exerciseListId}/${isStudy ? "estudo" : "prova"}/${
                        answerCard.id
                    }`;
                }

                if (!!subjectSlug) {
                    // AQUI
                    return `/app/curso/${courseSlug}/materias/${subjectSlug}/${moduleId}/lista-de-exercicios/${exerciseListId}/${
                        isStudy ? "estudo" : "prova"
                    }/${answerCard.id}`;
                }

                return `/app/curso/${courseSlug}/lista-de-exercicios/${exerciseListId}/${isStudy ? "estudo" : "prova"}/${answerCard.id}`;
            };

            return (
                <ExerciseListDashboardAnswerCardResultLink data-test-id="answer-card-see-list" to={getUrlPath()}>
                    {isExam ? <span>Ver desempenho</span> : !isFinished ? <span>Continuar fazendo</span> : <span>Ver gabarito</span>}
                    <ChevronRightIcon width={14} height={8} />
                </ExerciseListDashboardAnswerCardResultLink>
            );
        } catch (error) {
            return null;
        }
    }, [
        isExam,
        isExtra,
        examId,
        examDayId,
        isFinished,
        onClickResult,
        isAwaitingCorrection,
        courseSlug,
        exerciseListId,
        answerCard.id,
        isStudy,
        projectId,
        subjectSlug,
        moduleId
    ]);

    return (
        <ExerciseListDashboardAnswerCard className={isFinished ? "answer-card-finished-item" : "answer-card-item"}>
            <ExerciseListDashboardAnswerCardResultHeader>
                <ExerciseListDashboardAnswerCardResultTitle>Seu desempenho</ExerciseListDashboardAnswerCardResultTitle>
                <ExerciseListDashboardAnswerCardResultLegendBadge>
                    <span>
                        {realizationMode === AnswerCardRealizationMode.Study && "Modo estudo"}
                        {realizationMode === AnswerCardRealizationMode.Proof && "Modo prova"}
                        {realizationMode === AnswerCardRealizationMode.Exam && "Simulado"}
                    </span>
                </ExerciseListDashboardAnswerCardResultLegendBadge>
            </ExerciseListDashboardAnswerCardResultHeader>
            <dd>
                <ExerciseListDashboardAnswerCardResultLegendItem>
                    Lista {isFinished ? "finalizada" : "iniciada"} {formatedDate}
                </ExerciseListDashboardAnswerCardResultLegendItem>
                {!isExtra && (
                    <AnswerCardProgress
                        answersCorrect={answersCorrect}
                        answersWrong={answersWrong}
                        questionsTotal={questionsTotal}
                        totalAnswered={totalAnswered}
                        isFinished={isFinished}
                    />
                )}

                {isExtra && (
                    <ExerciseListDashboardAnswerCardResultNote>
                        {isAwaitingCorrection ? (
                            <ExerciseListDashboardAnswerCardResultPoints>Aguardando correção</ExerciseListDashboardAnswerCardResultPoints>
                        ) : (
                            <>
                                <p>Sua nota:</p>
                                <ExerciseListDashboardAnswerCardResultPoints>{points || 0}</ExerciseListDashboardAnswerCardResultPoints>
                            </>
                        )}
                    </ExerciseListDashboardAnswerCardResultNote>
                )}

                <ExerciseListDashboardAnswerCardResultExerciseDataFooter>
                    {memoInterval}
                    {CTAButton}
                </ExerciseListDashboardAnswerCardResultExerciseDataFooter>
            </dd>
        </ExerciseListDashboardAnswerCard>
    );
};

export default React.memo(ExerciseListDashboardAnswerCardResult);
