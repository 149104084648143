import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { CardContainer } from "components/Card/styles";
import { WizardNavContainer } from "components/Wizard/styles";
import { SwitcherContainer } from "components/Switcher/styles";
import { EssaySendedWrapper } from "components/essayCorrection/components/sended/styles";

export const EssayStatusContainer = styled.section`
    padding-bottom: ${theme.spacing.medium};

    ${SwitcherContainer} {
        margin-bottom: ${theme.spacing.large};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.medium} 0;

        ${WizardNavContainer} {
            margin-bottom: 53px;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${EssaySendedWrapper} {
            max-width: 100%;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${CardContainer} {
            padding: ${theme.spacing.medium};
        }
    }
`;

export const EssaySpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`;
