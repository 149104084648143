import { IExerciseListState } from "interfaces/IExerciseList";
import { IQuestion } from "interfaces/IQuestion";
import { createActions, createReducer } from "reduxsauce";
import { ReduxAction } from "store";
import { paginationInitialData } from "utils/paginationInitialData";
import { ExerciseListTypes, IExerciseListActionsCreators, ISaveExerciseListEssayPayload } from "./types";

export const INITIAL_STATE: IExerciseListState = {
    isLoading: false,
    error: false,
    id: 0,
    code: "",
    title: "",
    type: 1,
    slug: "",
    durationDefault: null,
    durationInMinutes: null,
    description: "",
    realizationsTotal: 0,
    realizationsAverageHitRate: 0,
    realizationsPerStudent: 0,
    questionsTotal: 0,
    items: [],
    blockContent: false,
    realizations: [],
    extraListByStatus: paginationInitialData,
    knowledgeAreas: [],
    isAnswerReleased: false,
    extras: [],
    isLoadingSubject: false,
    isLoadingAvailableExtra: false,
    errorAvailableExtra: false,
    availableExtras: paginationInitialData,
    isLoadingExtra: false,
    errorExtra: false,
    isLoadingEssay: false,
    currentQuestion: undefined,
    showEssay: false,
    showAnswerCard: false,
    isExam: false,
    showResult: false
};

export const { Creators, Types } = createActions<Record<ExerciseListTypes, ExerciseListTypes>, IExerciseListActionsCreators>({
    getExerciseListRequest: ["payload"],
    getExerciseListSuccess: ["payload"],
    getExerciseListFailure: ["payload"],

    getExtraExerciseListByStatusRequest: ["payload"],
    getExtraExerciseListByStatusSuccess: ["payload"],
    getExtraExerciseListByStatusFailure: ["payload"],

    getExerciseListByExamDayRequest: ["payload"],
    getExerciseListByExamDaySuccess: ["payload"],
    getExerciseListByExamDayFailure: ["payload"],

    getAllExtrasRequest: [],
    getAllExtrasSuccess: ["payload"],
    getAllExtrasFailure: ["payload"],

    getExerciseListSubjectRequest: ["payload"],
    getExerciseListSubjectSuccess: ["payload"],
    getExerciseListSubjectFailure: [],

    getExerciseListExamRequest: ["payload"],
    getExerciseListExamSuccess: ["payload"],
    getExerciseListExamFailure: [],

    getAvailableExtraExerciseListsBySlugRequest: ["payload"],
    getAvailableExtraExerciseListsBySlugSuccess: ["payload"],
    getAvailableExtraExerciseListsBySlugFailure: ["payload"],

    saveExerciseListEssayRequest: ["payload"],
    saveExerciseListEssaySuccess: ["payload"],
    saveExerciseListEssayFailure: [""],

    setCurrentQuestion: ["payload"],
    goToQuestionNumber: ["payload"],
    showEssay: ["payload"],
    showAnswerCard: ["payload"],
    showResult: ["payload"],

    goToNextQuestion: [],
    goToPrevQuestion: [],
    goToEssay: [],

    setOpenQuestionAnswerRequest: ["payload"],
    setOpenQuestionAnswerFailure: ["payload"],
    setOpenQuestionAnswerSuccess: ["payload"],

    clearExerciseList: []
});

const request = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false, blockContent: false });

const failure = (state = INITIAL_STATE) => ({
    ...state,
    blockContent: true,
    isLoading: false,
    error: true
});

const getExerciseListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        ...action.payload,
        isLoading: false,
        blockContent: false,
        error: false
    };
};

const clearExerciseList = () => INITIAL_STATE;

const getExerciseListByExamDay = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    blockContent: false,
    error: false
});

const getAllExtrasSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    extras: action.payload
});

const getSubjectRequest = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingSubject: true
});

const getSubjectSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    subject: action.payload,
    isLoadingSubject: false
});

const getSubjectFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingSubject: false
});

const getExamSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    exam: action.payload,
    // isLoading: false,
    isLoadingSubject: false
});

const getAvailableExtraExerciseListBySlugRequest = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingAvailableExtra: true,
    errorAvailableExtra: false
});

const getAvailableExtraExerciseListBySlugSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingAvailableExtra: false,
    errorAvailableExtra: false,
    availableExtras: {
        ...state.availableExtras,
        ...action.payload,
        items: [...(state.availableExtras?.items || []), ...(action.payload?.items || [])]
    }
});

const getAvailableExtraExerciseListBySlugFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingAvailableExtra: false,
    errorAvailableExtra: true
});

const getExerciseListByStatusRequest = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingExtra: true,
    errorExtra: false
});

const getExerciseListByStatusSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingExtra: false,
    errorExtra: false,
    extraListByStatus: action.payload
});

const getExerciseListByStatusFailure = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingExtra: false,
    errorExtra: true
});

const saveEssayRequest = (state = INITIAL_STATE, _: ReduxAction<ISaveExerciseListEssayPayload>) => ({
    ...state,
    isLoadingEssay: true
});

const stopEssayLoading = (state = INITIAL_STATE, _: any) => ({
    ...state,
    isLoadingEssay: false
});

const setCurrentQuestion = (state = INITIAL_STATE, action: ReduxAction<IQuestion | undefined>) => ({
    ...state,
    currentQuestion: action.payload
});

const showEssay = (state = INITIAL_STATE, action: ReduxAction<boolean>) => ({
    ...state,
    showEssay: !!action?.payload
});

const showAnswerCard = (state = INITIAL_STATE, action: ReduxAction<boolean>) => ({
    ...state,
    showAnswerCard: !!action?.payload
});

const showResult = (state = INITIAL_STATE, action: ReduxAction<boolean>) => ({
    ...state,
    showResult: !!action?.payload
});

const chore = (state = INITIAL_STATE) => state;

export default createReducer(INITIAL_STATE, {
    [Types.GET_EXERCISE_LIST_REQUEST]: request,
    [Types.GET_EXERCISE_LIST_SUCCESS]: getExerciseListSuccess,
    [Types.GET_EXERCISE_LIST_FAILURE]: failure,

    [Types.GET_EXTRA_EXERCISE_LIST_BY_STATUS_REQUEST]: getExerciseListByStatusRequest,
    [Types.GET_EXTRA_EXERCISE_LIST_BY_STATUS_SUCCESS]: getExerciseListByStatusSuccess,
    [Types.GET_EXTRA_EXERCISE_LIST_BY_STATUS_FAILURE]: getExerciseListByStatusFailure,

    [Types.CLEAR_EXERCISE_LIST]: clearExerciseList,

    [Types.GET_EXERCISE_LIST_BY_EXAM_DAY_REQUEST]: request,
    [Types.GET_EXERCISE_LIST_BY_EXAM_DAY_SUCCESS]: getExerciseListByExamDay,
    [Types.GET_EXERCISE_LIST_BY_EXAM_DAY_FAILURE]: failure,

    [Types.GET_ALL_EXTRAS_REQUEST]: request,
    [Types.GET_ALL_EXTRAS_SUCCESS]: getAllExtrasSuccess,
    [Types.GET_ALL_EXTRAS_FAILURE]: failure,

    [Types.GET_EXERCISE_LIST_SUBJECT_REQUEST]: getSubjectRequest,
    [Types.GET_EXERCISE_LIST_SUBJECT_SUCCESS]: getSubjectSuccess,
    [Types.GET_EXERCISE_LIST_SUBJECT_FAILURE]: getSubjectFailure,

    [Types.GET_EXERCISE_LIST_EXAM_REQUEST]: getSubjectRequest,
    [Types.GET_EXERCISE_LIST_EXAM_SUCCESS]: getExamSuccess,
    [Types.GET_EXERCISE_LIST_EXAM_FAILURE]: getSubjectFailure,

    [Types.GET_AVAILABLE_EXTRA_EXERCISE_LISTS_BY_SLUG_REQUEST]: getAvailableExtraExerciseListBySlugRequest,
    [Types.GET_AVAILABLE_EXTRA_EXERCISE_LISTS_BY_SLUG_SUCCESS]: getAvailableExtraExerciseListBySlugSuccess,
    [Types.GET_AVAILABLE_EXTRA_EXERCISE_LISTS_BY_SLUG_FAILURE]: getAvailableExtraExerciseListBySlugFailure,

    [Types.SAVE_EXERCISE_LIST_ESSAY_REQUEST]: saveEssayRequest,
    [Types.SAVE_EXERCISE_LIST_ESSAY_SUCCESS]: stopEssayLoading,
    [Types.SAVE_EXERCISE_LIST_ESSAY_FAILURE]: stopEssayLoading,

    [Types.SET_CURRENT_QUESTION]: setCurrentQuestion,
    [Types.GO_TO_QUESTION_NUMBER]: chore,
    [Types.SHOW_ESSAY]: showEssay,
    [Types.SHOW_ANSWER_CARD]: showAnswerCard,
    [Types.SHOW_RESULT]: showResult,

    [Types.GO_TO_NEXT_QUESTION]: chore,
    [Types.GO_TO_PREV_QUESTION]: chore,
    [Types.GO_TO_ESSAY]: chore
});
