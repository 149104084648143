// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityChipProps } from "./activity-chip.types";

// Styles
import { Container, Label } from "./activity-chip.styles";

// Utils
import { ternary } from "../../utils/ternary";

export const ActivityChip: FunctionComponent<ActivityChipProps> = ({
    variant = "default",
    size = "small",
    label,
    firstIconElement,
    lastIconElement,
    handleClick
}) => {
    const labelColor = ternary([
        [variant === "disabled", "#4D4D63"],
        [variant === "info", "#0B1F53"],
        [variant === "success", "#045332"],
        [variant === "danger", "#840000"],
        [variant === "warning", "#794A07"],
        [variant === "cta", "#F0F1F7"],
        [variant === "default", "#232326"]
    ]);

    return (
        <Container
            className="webapp-chip"
            variant={variant}
            size={size}
            hasCursorPointer={!!handleClick}
            onClick={() => !!handleClick && handleClick()}
        >
            {firstIconElement}

            <Label color={labelColor!} size={size}>
                {label}
            </Label>

            {lastIconElement}
        </Container>
    );
};
