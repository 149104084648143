import React, { useEffect, useState, useMemo, useCallback } from "react";
import useIsMobile from "hooks/use-is-mobile";
import { WebpCalendarV2 } from "webapp-components/dist/libs/webp-components-react";
import { WebpTotalNumber } from "components/WebpTotalNumber";

import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import history from "services/history";
import { theme } from "config/theme";

import { DateTime } from "luxon";

import { LessonPlanCustomStudyPlanWebpCard, LessonPlanCustomStudyHeader } from "./styles";
import api from "services/api";
import formatDate from "utils/formatDate";

interface IProps {
    dateStart: string | undefined;
    dateEnd: string | undefined;
    setDateStart: (date: string) => void;
    setDateEnd: (date: string) => void;
    weekTotal: number;
    setWeekTotal: (weekTotal: number) => void;
    onClearCalendar: (ev: any) => void;
    clearFullCalendar: boolean;
}

const LessonPlanCustomStudyPlan = ({
    dateStart,
    dateEnd,
    weekTotal,
    setDateStart,
    setDateEnd,
    setWeekTotal,
    onClearCalendar,
    clearFullCalendar
}: IProps) => {
    const [currentDate, setCurrentDate] = useState<any>();
    const isBrandProenem = theme.project.brand === "proenem";

    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const { career, minimumHoursRecommended, courseSlug } = useSelector(({ lessonPlanCustom, course }: IReduxStore) => ({
        ...lessonPlanCustom,
        courseSlug: course.slug
    }));

    const getWeeksTotal = useMemo(() => {
        if (dateStart && dateEnd) {
            const startDateFormatted = DateTime.fromFormat(dateStart, "dd/LL/yyyy").toFormat("yyyy-MM-dd");
            const endDateFormatted = DateTime.fromFormat(dateEnd, "dd/LL/yyyy").toFormat("yyyy-MM-dd");
            const { weeks } = DateTime.fromISO(endDateFormatted).diff(DateTime.fromISO(startDateFormatted), "week");

            const weekTotal = Math.ceil(weeks);

            setWeekTotal(weekTotal);
            return Math.ceil(weekTotal);
        }
        setWeekTotal(0);
        return 0;
    }, [dateEnd, dateStart, setWeekTotal]);

    const handleClearCalendar = useCallback(
        (ev: any) => {
            onClearCalendar(ev);
        },
        [onClearCalendar]
    );

    const getData = useCallback(async () => {
        const { data } = await api.get<string>("/util/unixtime");
        const dataServer = new Date(data);
        const formatter = new Date(dataServer).toISOString();

        setCurrentDate(formatDate(formatter, "yyyy-LL-dd"));
    }, []);

    const getMinimumHoursRecommended = useCallback(() => {
        dispatch(lessonPlanCustomActions.getLessonPlanCustomMinimumHoursRecommendedRequest());
    }, [dispatch]);

    useEffect(() => {
        getData();
        getMinimumHoursRecommended();
    }, [getData, getMinimumHoursRecommended]);

    useEffect(() => {
        if (!career && isBrandProenem) {
            history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-sua-carreira` });
        }
    }, [career, courseSlug, isBrandProenem]);

    const handleDateStart = useCallback(
        (date: any) => {
            setDateStart(date);
            localStorage.setItem("date-start", date);
        },
        [setDateStart]
    );

    const handleDateEnd = useCallback(
        (date: any) => {
            setDateEnd(date);
            localStorage.setItem("date-end", date);
        },
        [setDateEnd]
    );

    return (
        <LessonPlanCustomStudyPlanWebpCard
            contentAlignment="center"
            headerComposition={
                <LessonPlanCustomStudyHeader>
                    {isMobile && <p className="labelLessonPlanCustomStudyPlan">Configure seu plano</p>}
                    <p className="lessonPlan">Escolha a duração do seu plano</p>
                    <WebpTotalNumber
                        type="text"
                        stringMain="Semanas do plano:"
                        stringSecondary="Mínimo requerido:"
                        recommendedNumber={minimumHoursRecommended}
                        currentNumber={getWeeksTotal}
                    />
                </LessonPlanCustomStudyHeader>
            }
            contentComposition={
                <WebpCalendarV2
                    labelAlert={`Selecione o mínimo recomendado: ${minimumHoursRecommended} semanas`}
                    labelButtonAlert="Limpar"
                    setMessageAlert={getWeeksTotal >= minimumHoursRecommended ? false : true}
                    // initialDate={""}
                    currentDate={String(currentDate)}
                    onClickDateInitialCalendar={(ev) => handleDateStart(ev.detail)}
                    onClickDateFinalCalendar={(ev) => handleDateEnd(ev.detail)}
                    onClearCalendar={(ev) => handleClearCalendar(ev.detail)}
                    clearFullCalendar={clearFullCalendar}
                />
            }
        ></LessonPlanCustomStudyPlanWebpCard>
    );
};

export { LessonPlanCustomStudyPlan };
