import styled from "styled-components";
import { TitleH3, TitleH4 } from "assets/styles/global";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";

export const ConfirmEssayImageModalContainer = styled.div`
    width: 800px;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small} ${theme.spacing.medium};
    flex-direction: column;

    > img {
        display: block;
        height: auto;
        width: 350px;
        max-width: 100%;
        margin-bottom: 20px;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            margin-bottom: 40px;
        }
    }
`;

export const ConfirmEssayImageModalTitle = styled.h3`
    ${TitleH3};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;

export const ConfirmEssayImageModalLegend = styled.p`
    ${TitleH4};
    color: ${theme.colors.base[400]};

    margin-bottom: ${theme.spacing.small};
`;

export const ConfirmEssayImageModalButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_SMALLX} {
        flex-direction: row;
    }

    ${ButtonWrapper} {
        :first-child {
            margin-bottom: ${theme.spacing.small};

            ${MediaQueries.BIGGER_THAN_SMALLX} {
                margin-bottom: unset;
                margin-right: ${theme.spacing.small};
            }
        }
    }
`;
