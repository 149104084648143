import Icon from "components/Icons/icon";
import React, { useRef, useState } from "react";

import If from "components/If";
import ReactPlayer from "react-player";
import { Arrow, BackButton, MenuButton, VideoPlayerWrapper } from "./styles";

import VideoConfig from "./VideoConfig";

export interface IProps {
    src: string;
    playing?: boolean;
    thumbnail?: string | boolean;
    playbackRate?: number;
    onChangePlaybackRate?: any;
    hasVideoConfig?: boolean;
    hasMenu?: boolean;
    hasBackButton?: boolean;
    hasArrows?: boolean;
    hasPrevious?: boolean;
    hasNext?: boolean;
    onMenuClick?: any;
    onPreviousArrowClick?: any;
    onNextArrowClick?: any;
    onBackButtonClick?: any;
    height?: string;
    pip?: boolean;
}

const VideoPlayer = ({
    src,
    playing = true,
    hasMenu = false,
    hasArrows = false,
    hasBackButton = false,
    hasVideoConfig = false,
    hasPrevious = true,
    hasNext = true,
    onMenuClick,
    onBackButtonClick,
    onPreviousArrowClick,
    onNextArrowClick,
    height = "auto",
    playbackRate = 1,
    pip = false,
    thumbnail = true,
    onChangePlaybackRate
}: IProps) => {
    const [showButtons, setShowButtons] = useState(true);

    const playerRef = useRef<ReactPlayer>(null);

    return (
        <VideoPlayerWrapper>
            <If test={showButtons}>
                {hasBackButton && (
                    <BackButton onClick={onBackButtonClick}>
                        <Icon idIcon="ico-caret" width="12" height="12" viewBox="0 0 307.054 307.054" />
                    </BackButton>
                )}
                {hasMenu && (
                    <MenuButton onClick={onMenuClick}>
                        <Icon idIcon="ico-caret" width="10" height="10" viewBox="0 0 307.054 307.054" />
                        Menu
                    </MenuButton>
                )}
                {hasArrows && (
                    <>
                        {hasPrevious && (
                            <Arrow left onClick={onPreviousArrowClick}>
                                <Icon idIcon="ico-arrow-video" width="13" height="24" viewBox="0 0 13 24" />
                            </Arrow>
                        )}
                        {hasNext && (
                            <Arrow onClick={onNextArrowClick}>
                                <Icon idIcon="ico-arrow-video" width="13" height="24" viewBox="0 0 13 24" />
                            </Arrow>
                        )}
                    </>
                )}
                {hasVideoConfig && <VideoConfig active={playbackRate} onChangeActive={onChangePlaybackRate} />}
            </If>
            <ReactPlayer
                ref={playerRef}
                url={src}
                playing={playing}
                controls
                width="100%"
                height={height}
                playbackRate={playbackRate}
                light={thumbnail}
                onPlay={() => setShowButtons(false)}
                onPause={() => setShowButtons(true)}
                onContextMenu={(e: MouseEvent) => e.preventDefault()}
                pip={pip}
                config={{
                    file: {
                        attributes: {
                            controlsList: "nodownload"
                        }
                    }
                }}
            />
        </VideoPlayerWrapper>
    );
};

export default VideoPlayer;
