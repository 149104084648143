// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ActionButtonsCompositionWrapper,
    ActivitiesContentCompositionsWrapper,
    Container,
    ContentWrapper,
    Description,
    Title,
    TitleAndActionButtonsWrapper,
    TitleWrapper,
    EmptyStateWrapper,
    FixedMobileContentWrapper,
    BreadcrumbAndTitleWrapper
} from "./activity-board.styles";

// Types
import { ActivityBoardProps } from "./activity-board.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const ActivityBoard: FunctionComponent<ActivityBoardProps> = ({
    sectionTitle,
    sectionDescription,
    shouldDisplayEmptyState,
    emptyStateComposition,
    actionButtonsComposition,
    activitiesContentCompositions,
    fixedMobileContent,
    breadcrumbComposition
}) => {
    return (
        <Container>
            <ContentWrapper hasFixedMobileContent={!!fixedMobileContent}>
                <BreadcrumbAndTitleWrapper>
                    <ConditionallyRender shouldRender={!!breadcrumbComposition} content={breadcrumbComposition} />

                    <TitleAndActionButtonsWrapper hasFixedMobileContent={!!fixedMobileContent}>
                        <TitleWrapper>
                            <Title>{sectionTitle}</Title>

                            <ConditionallyRender shouldRender={!!sectionDescription} content={<Description>{sectionDescription}</Description>} />
                        </TitleWrapper>

                        <ActionButtonsCompositionWrapper>{actionButtonsComposition}</ActionButtonsCompositionWrapper>
                    </TitleAndActionButtonsWrapper>
                </BreadcrumbAndTitleWrapper>

                <ConditionallyRender
                    shouldRender={!!shouldDisplayEmptyState}
                    content={<EmptyStateWrapper hasFixedMobileContent={!!fixedMobileContent}>{emptyStateComposition}</EmptyStateWrapper>}
                />

                <ConditionallyRender
                    shouldRender={!shouldDisplayEmptyState}
                    content={
                        <ActivitiesContentCompositionsWrapper hasFixedMobileContent={!!fixedMobileContent}>
                            {activitiesContentCompositions}
                        </ActivitiesContentCompositionsWrapper>
                    }
                />

                <ConditionallyRender
                    shouldRender={!!fixedMobileContent}
                    content={
                        <ScreenWidthRender
                            renderingWidth={768}
                            actionAfterRenderingWidth="hide"
                            content={<FixedMobileContentWrapper>{fixedMobileContent}</FixedMobileContentWrapper>}
                        />
                    }
                />
            </ContentWrapper>
        </Container>
    );
};
