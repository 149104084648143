import ColorTaggedItem from "components/ColorTaggedExerciseList";
import { getExamDayStatus } from "helpers/getExamDayStatus";
import React from "react";
import { IExamDayRelated } from "store/ducks/exam/types";
import RelatedDaysSkeleton from "./skeleton";

import * as S from "./styles";

interface IProps {
    isLoading: boolean;
    relatedDays: IExamDayRelated[];
    onClickRelatedDay(examDaySlug: string): void;
}

const ExamDayResultRelatedDays = ({ isLoading, relatedDays = [], onClickRelatedDay }: IProps) => {
    if (isLoading) {
        return <RelatedDaysSkeleton />;
    }

    if (!relatedDays?.length) {
        return null;
    }

    return (
        <S.ExamDayResultRelatedDaysContainer>
            <S.ExamDayResultRelatedDaysLegend>Realize a outra prova deste concurso</S.ExamDayResultRelatedDaysLegend>

            {relatedDays.map(({ id, slug, name, status, startDate, endDate }) => {
                const currentStatus = getExamDayStatus({ status, startDate, endDate });

                return (
                    <ColorTaggedItem
                        key={id}
                        listHeading={name}
                        listDescription={currentStatus?.text}
                        onClick={() => onClickRelatedDay(slug)}
                        isExamsPage
                    />
                );
            })}
        </S.ExamDayResultRelatedDaysContainer>
    );
};

export default ExamDayResultRelatedDays;
