import { DateTime } from "luxon";

export function getInterval(data: { endDate: string | null; startDate: string }) {
    try {
        const { endDate, startDate } = data;

        if (!endDate) {
            throw new Error();
        }

        const diff = DateTime.fromISO(endDate).diff(DateTime.fromISO(startDate));
        const currentInterval = diff.isValid && diff.toFormat("hh:mm:ss");

        if (!currentInterval) {
            throw new Error();
        }

        return currentInterval;
    } catch (error) {
        return null;
    }
}
