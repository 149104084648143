import React, { memo, Suspense, lazy } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import Empty from "components/Empty";
import Spinner from "components/Spinner";
import PageHeader from "components/PageHeader";
import { Grid, Row, Col } from "components/Grid";
import RadioGroupPlaceholder from "components/RadioGroup/placeholder";
import FilterGroupPlaceholder from "components/FilterGroup/placeholder";
import EssayCardPlaceholder from "./components/cardPlaceholder";

import useIsMobile from "hooks/use-is-mobile";
import { IEssayState } from "store/interfaces/IEssay";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { IReduxStore } from "interfaces/IReduxStore";
import { ordererOptions } from "../mock";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";

import * as S from "./styles";

const RadioGroup = lazy(() => import("components/RadioGroup"));
const EssayThemeCard = lazy(() => import("./components/card"));
const FilterGroup = lazy(() => import("components/FilterGroup"));

interface IEssayThemesProps extends Pick<IEssayState, "themes" | "isLoading" | "balance" | "isResetedRequest"> {
    isFetched: boolean;
    sort?: string | string[] | null;
    filterOptions: IFilterGroup[];
    onFetchThemes: () => void;
    onSort: (value: string) => void;
    onSelectFilter: (options: IFilterGroup[]) => void;
}

const EssayThemes = ({
    isFetched,
    isLoading,
    balance,
    themes,
    sort,
    filterOptions,
    isResetedRequest,
    onFetchThemes,
    onSort,
    onSelectFilter
}: IEssayThemesProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isMobile = useIsMobile();

    const isSpinner = (isLoading && !themes.all.count) || isResetedRequest;

    // const dropdownInfo = useMemo(() => {
    //     return (
    //         <S.EssayThemeDropdown>
    //             <ul>
    //                 <li>
    //                     <strong>4/4</strong> Créditos mensais
    //                 </li>
    //                 <li>
    //                     <strong>1/1</strong> Créditos diários
    //                 </li>
    //                 <li>
    //                     <strong>10/10</strong> Créditos extras
    //                 </li>
    //             </ul>
    //         </S.EssayThemeDropdown>
    //     );
    // }, []);

    return (
        <>
            <PageHeader.Complex
                title="Redações"
                credit={{
                    amount: balance
                    // onClickBuy: () => {}
                }}
                modalOptions={filterOptions}
                onFilter={onSelectFilter}
                // dropdown={{
                //     content: dropdownInfo
                // }}
                isHorizontal={true}
            />
            <S.EssayThemesContainer>
                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={12} md={8}>
                            {isMobile && (
                                <S.EssayThemesButton to={`/app/curso/${courseSlug}/redacoes/envios`}>
                                    Ver minhas redações
                                    <ChevronRight width="4" height="8" />
                                </S.EssayThemesButton>
                            )}
                            <S.EssayThemesContent>
                                <S.EssayThemesRow>
                                    <S.EssayThemesContentTitle>Temas</S.EssayThemesContentTitle>
                                </S.EssayThemesRow>
                            </S.EssayThemesContent>
                            <S.EssayThemesList>
                                {isFetched && !isLoading && !themes.all.count ? (
                                    <Empty message="Nenhum tema encontrado" />
                                ) : (
                                    <>
                                        {isSpinner ? (
                                            <Spinner fixed={false} size={20} />
                                        ) : (
                                            <InfiniteScroll
                                                scrollableTarget="scrollable"
                                                scrollThreshold={1}
                                                dataLength={themes.all.items.length}
                                                next={onFetchThemes}
                                                hasMore={themes.all.totalPages > 1 && themes.all.totalPages > themes.all.page}
                                                loader={<Spinner fixed={false} size={20} />}
                                            >
                                                {themes.all.items.map((theme) => (
                                                    <S.EssayThemesListItem key={theme.idTheme}>
                                                        <Suspense fallback={<EssayCardPlaceholder />}>
                                                            <EssayThemeCard theme={theme} />
                                                        </Suspense>
                                                    </S.EssayThemesListItem>
                                                ))}
                                            </InfiniteScroll>
                                        )}
                                    </>
                                )}
                            </S.EssayThemesList>
                        </Col>
                        {!isMobile && (
                            <Col md={4}>
                                <S.EssayThemesButton to={`/app/curso/${courseSlug}/redacoes/envios`}>
                                    Ver minhas redações
                                    <ChevronRight width="4" height="8" />
                                </S.EssayThemesButton>
                                <Suspense fallback={<RadioGroupPlaceholder />}>
                                    <RadioGroup
                                        activeItem={sort || ordererOptions[0].value}
                                        items={ordererOptions}
                                        onClick={onSort}
                                        title="Ordenar por:"
                                    />
                                </Suspense>
                                <Suspense fallback={<FilterGroupPlaceholder />}>
                                    <FilterGroup options={filterOptions} onChange={onSelectFilter} withHeader={true} />
                                </Suspense>
                            </Col>
                        )}
                    </Row>
                </Grid>
            </S.EssayThemesContainer>
        </>
    );
};

export default memo(EssayThemes);
