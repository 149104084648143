import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";

export const CardStyles = css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 4px;
    padding: ${theme.spacing.small};
    display: flex;
    position: relative;
`;

const CardContainer = styled.dl<{ column?: boolean }>`
    ${CardStyles};

    ${({ column }) =>
        !!column &&
        css`
            flex-direction: column;
        `}
`;

CardContainer.defaultProps = {
    column: false
};

export { CardContainer };
