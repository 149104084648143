import React from "react";
import { FlatVariant } from "utils/types/Variant";
import { BadgeContainer } from "./styles";

interface IBadgeProps {
    children: React.ReactNode;
    variant: FlatVariant | "secondary";
    style?: React.CSSProperties;
    backgroundWeight?: number;
}

const Badge = ({ children, variant, style, backgroundWeight = 300 }: IBadgeProps) => (
    <BadgeContainer variant={variant} style={style} backgroundWeight={backgroundWeight}>
        {children}
    </BadgeContainer>
);

export default Badge;
