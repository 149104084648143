import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import React, { memo } from "react";
import {
    ExamDetailsDayTexts,
    ExamDetailsDayContainer,
    ExamDetailsDayHeader,
    ExamDetailsDayContentKnowledgeAreaWrapp,
    ExamDetailsDayContent,
    ExamDetailsDayContentKnowledgeArea,
    ExamDetailsDayAppliedPeriodChart,
    ExamDetailsDayAppliedPeriod
} from "./styles";

const ExamDayDetailsSkeleton = memo(() => {
    return (
        <ExamDetailsDayContainer>
            <ExamDetailsDayHeader>
                <ExamDetailsDayTexts>
                    <h2 style={{ marginBottom: "2px" }}>
                        <Skeleton width="35%" height="16px" />
                    </h2>

                    <p>
                        <Skeleton width="45%" height="18px" />
                    </p>
                </ExamDetailsDayTexts>

                <div style={{ display: "flex" }}>
                    <Skeleton width="13px" height="13px" style={{ marginRight: theme.spacing.smallXX }} />
                    <Skeleton width="36px" height="13px" />
                </div>
            </ExamDetailsDayHeader>

            <ExamDetailsDayContent>
                <ExamDetailsDayContentKnowledgeAreaWrapp>
                    <ExamDetailsDayContentKnowledgeArea>
                        <span>
                            <Skeleton width="8px" height="17px" />
                        </span>{" "}
                        <p>
                            <Skeleton width="35%" height="17px" />
                        </p>
                    </ExamDetailsDayContentKnowledgeArea>
                </ExamDetailsDayContentKnowledgeAreaWrapp>

                <ExamDetailsDayAppliedPeriod>
                    <strong>
                        <Skeleton width="50%" height="18px" />
                    </strong>

                    <ExamDetailsDayAppliedPeriodChart>
                        <Skeleton width="80%" height="18px" style={{ marginBottom: theme.spacing.smallX }} />
                        <Skeleton width="80%" height="18px" />
                    </ExamDetailsDayAppliedPeriodChart>
                </ExamDetailsDayAppliedPeriod>
            </ExamDetailsDayContent>

            <Skeleton width="100%" height="40px" />
        </ExamDetailsDayContainer>
    );
});

export default ExamDayDetailsSkeleton;
