import api from "services/api";
import { call, put, takeLatest } from "redux-saga/effects";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as fileActions, Types } from "store/ducks/file";

function* getFiles(action: { type: string; payload: { page: number; category: number } }) {
    try {
        const { page, category } = action.payload;

        const { data } = yield call(api.get, "/student/document/list", {
            headers: {
                "X-Page": page,
                ...(!!category && { "X-Where": `category.id=${category}` })
            }
        });

        if (!data) {
            return;
        }

        yield put(fileActions.getFilesSuccess({ data, page }));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter os temas disponíveis.", "danger"));
        yield put(fileActions.getFilesFailure(error?.response?.data));
    }
}

function* getFilesCategories() {
    try {
        const { data } = yield call(api.get, "/student/category/document", {
            headers: {
                "x-no-pagination": true
            }
        });

        if (!Array.isArray(data.items)) {
            throw new Error();
        }

        const categories = data.items.map((item: any) => ({
            value: item.id,
            label: item.name
        }));

        yield put(fileActions.getFilesCategoriesSuccess(categories));
    } catch (error) {
        // yield put(alertActions.showAlert("Não foi possível obter a lista de categorias.", "danger"));
        yield put(fileActions.getFilesCategoriesFailure(error?.response?.data));
    }
}

export default [takeLatest(Types.GET_FILES_REQUEST, getFiles), takeLatest(Types.GET_FILES_CATEGORIES_REQUEST, getFilesCategories)];
