import styled from "styled-components";
import { theme } from "config/theme";
import { ButtonWrapper } from "components/Button/styles";

export const ModalImageContainer = styled.figure`
    overflow: hidden;
    transition: transform 0.2s;
    max-width: 100%;
    min-width: 70vw;
    max-height: 93vh;
    text-align: center;
    padding: ${theme.spacing.smallX};

    img {
        max-width: 100%;
        max-height: 100%;
    }

    ${ButtonWrapper} {
        position: absolute;
        top: ${theme.spacing.smallX};
        right: ${theme.spacing.smallX};
    }
`;
