import styled, { css } from "styled-components";
import { theme } from "config/theme";

export const AttentionTitleWrapper = styled.div`
    width: 100%;
    margin-bottom: ${theme.spacing.smallX};

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const AttentionCloseIcon = styled.img`
    width: 32px;
    height: 32px;

    cursor: pointer;
`;

export const AttentionModalTitle = styled.h1`
    width: 100%;

    color: ${theme.colors.brand[300]};
    font-family: "Nunito", sans-serif;
    font-size: ${theme.spacing.medium};
    font-weight: 700;
    line-height: 36px;
`;

export const AttentionModalLegend = styled.div`
    width: 100%;

    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.medium};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    line-height: 22px;
`;

type AttentionModalContainerStyleProps = {
    width?: string;
    alignText: string;
};

export const AttentionModalContainer = styled.div<AttentionModalContainerStyleProps>`
    width: ${({ width }) => `${width}px` || "100%"};
    max-width: 100%;
    padding: ${theme.spacing.medium};
    border-radius: 4px;
    background-color: ${theme.colors.white};

    display: flex;
    flex-direction: column;
    align-items: center;

    ${({ alignText }) =>
        !!alignText &&
        css`
            ${AttentionModalLegend}, ${AttentionModalTitle} {
                text-align: ${alignText};
            }
        `};
`;

export const AttentionModalButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
