import styled from "styled-components";

export const ExportExerciseListPDFContainer = styled.div`
    padding: 25px;

    h3 {
        color: #000;
        font-size: 15px;
    }
`;
