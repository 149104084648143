// Dependecies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    cursor: pointer;
    padding: 8px 0;
    align-items: center;
    justify-content: space-between;
    border-width: 0px 0px 1px;
    border-style: solid;
    border-color: #ced0dd;

    &:before {
        content: "";
        display: flex;
        width: 4px;
        height: 20px;
        background-color: ${theme.colors.brand[300]};
        margin-right: 12px;
        border-radius: 8px;

        @media (min-width: 1366px) {
            height: 30px;
        }
    }

    @media (min-width: 768px) {
        padding: 16px 0;
    }

    .content-text {
        width: 100%;
        display: flex;
    }

    .text {
        font-style: normal;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 1.333;
        width: 100%;
        height: 100%;
        align-items: center;
        color: ${theme.colors.base[400]};
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
    }

    .button {
        padding: 3.5px 6px;
        border: none;
        background-color: transparent;
    }
`;
