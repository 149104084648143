// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    width?: number;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    height: 100dvh;
    background-color: #fff;
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    z-index: 9999;

    @media (min-width: 768px) {
        height: unset;

        ${({ width }) =>
            !!width &&
            css`
                width: ${width}px;
            `}

        ${({ width }) =>
            !width &&
            css`
                width: unset;
                min-width: 640px;
            `}
    }
`;

export const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 24px;

    display: flex;
    flex-direction: column;

    overflow-y: auto;
`;

export const Title = styled.p`
    color: #000;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;

type TitleWrapperStyleProps = {
    alignment: "start" | "center";
};

export const TitleWrapper = styled.div<TitleWrapperStyleProps>`
    width: 100%;

    display: flex;
    gap: 32px;
    align-items: center;

    ${({ alignment }) =>
        alignment === "start" &&
        css`
            justify-content: space-between;

            > p {
                flex: 1;
            }
        `}

    ${({ alignment }) =>
        alignment === "center" &&
        css`
            justify-content: center;
        `}
`;

export const ActionButtonCompositionsWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    gap: 32px;
    align-items: center;
    margin-top: 24px;
`;
