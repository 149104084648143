// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isActive: boolean;
    navType: "square" | "step";
};

export const Container = styled.div<ContainerStyleProps>`
    ${({ navType }) =>
        navType === "square" &&
        css`
            width: 37px;
            height: 41px;
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.base[200]};
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));

            @media (min-width: 768px) {
                width: 60px;
                height: 70px;
            }

            span {
                font-size: 0.75rem;
                font-weight: 400;
                letter-spacing: 0.01em;
                line-height: 1.333;
                font-family: "Nunito", sans-serif;
                font-style: normal;
                color: ${theme.colors.base[300]};
                text-transform: uppercase;
                padding: 20px 8px;

                @media (min-width: 768px) {
                    font-size: 0.875rem;
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    line-height: 1.571;
                    font-family: "Nunito", sans-serif;
                    font-style: normal;
                    color: ${theme.colors.base[300]};

                    span {
                        font-size: 1rem;
                        font-weight: 600;
                        letter-spacing: 0;
                        line-height: 1.375;
                        font-family: "Nunito", sans-serif;
                        font-style: normal;
                        color: ${theme.colors.white};
                    }
                }
            }
        `}

    ${({ navType, isActive }) =>
        navType === "square" &&
        !!isActive &&
        css`
            background-color: ${theme.colors.brand[300]};

            span {
                color: ${theme.colors.white};
            }

            &:after {
                content: "";
                position: absolute;
                width: 18px;
                height: 18px;
                border: none;
                border-radius: 2px;
                background-color: ${theme.colors.brand[300]};
                z-index: -1;
                transform: translateY(-50%) rotate(45deg);
                top: 77%;
                right: 24%;

                @media (min-width: 768px) {
                    right: 17px;
                    top: 84%;
                    width: 24px;
                    height: 24px;
                }
            }
        `}

    ${({ navType }) =>
        navType === "step" &&
        css`
            cursor: pointer;
            display: block;
            width: fit-content;
            width: -moz-fit-content;
            min-height: 52px;
            background: ${theme.colors.white};
            border-radius: 4px;
            padding: 16px 24px 20px 24px;
            position: relative;
            margin: 18px 0px 0px;

            &:before {
                content: "";
                border: 1px solid rgb(198, 224, 254);
                box-sizing: border-box;
                box-shadow: rgb(75, 158, 255) 0px 0px 4px;
                width: 16px;
                height: 16px;
                position: absolute;
                border-radius: 50%;
                left: -27px;
                top: 0px;
                background: ${theme.colors.white};
            }

            &:nth-child(1) {
                margin: 0px;
            }

            p {
                font-size: 0.875rem;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.428;
                font-family: "Nunito", sans-serif;
                font-style: normal;
                color: ${theme.colors.base[300]};
            }
        `}

    ${({ navType, isActive }) =>
        navType === "step" &&
        !!isActive &&
        css`
            filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));

            &:after {
                content: "";
                display: none;
            }

            @media (min-width: 768px) {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border: none;
                    right: -5px;
                    top: 50%;
                    background: ${theme.colors.white};
                    z-index: -1;
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            @media (min-width: 1366px) {
                &:after {
                    content: "";
                    width: 16px;
                    height: 16px;
                    right: -7px;
                }
            }
        `}
`;
