import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

// Redux
import { Creators as AuthActions } from "store/ducks/auth";
import { Creators as CourseActions } from "store/ducks/course";
import { Creators as LessonPlanActions } from "store/ducks/lesson-plan";
import { Creators as WeekPlansActions } from "store/ducks/weekPlans";
import { Creators as SubjectActions } from "store/ducks/subject";
import { Creators as LessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { IAuthActions } from "store/interfaces/IAuth";
import { ICourseActions } from "store/interfaces/ICourse";
import { ILessonPlanActions } from "store/interfaces/ILessonPlan";
import { ISubjectActions } from "store/interfaces/ISubject";
import { ILessonPlanCustomActions } from "store/interfaces/ILessonPlanCustom";
import { INavigation } from "interfaces/INavigation";

// Helpers
import { IReduxStore } from "interfaces/IReduxStore";
import { ICourseState } from "store/ducks/course";
import history from "services/history";
import { getRedirectPath } from "helpers/getRedirectPath";

export interface IAuthorizeProps {
    token: string;
    menu: INavigation[];
    courseSlug: string;
    courses: ICourseState[];
    authActions: IAuthActions;
    courseActions: ICourseActions;
    lessonPlanActions: ILessonPlanActions;
    weekPlansActions: any;
    subjectActions: ISubjectActions;
    lessonPlanCustomActions: ILessonPlanCustomActions;
}

const AuthHOC = (Component: any) => {
    const Authenticate = (props: IAuthorizeProps) => {
        const { pathname, search } = useLocation();

        const handleChangeCourse = useCallback(
            (course: ICourseState) => {
                props.courseActions.setCourse({ course, shouldPreventRedirect: true });
                props.courseActions.getExternalSchoolClassRequest();

                props.authActions.clearPlatformSearch();
                props.weekPlansActions.getWeekPlansIsPublishedRequest();
                props.weekPlansActions.clearWeekPlans();
                props.lessonPlanActions.clearLessonPlan();
                props.lessonPlanActions.clearLastVisitedDateUrl();
                props.lessonPlanCustomActions.clearLessonPlanCustom();

                if (pathname.includes("/materias")) {
                    props.subjectActions.clearSubject();

                    const payload = {
                        endpoint: "/student/subject-v2"
                    };

                    props.subjectActions.getSubjectsRequest(payload);
                    props.subjectActions.getSubjectProgressRequest();
                }

                localStorage.removeItem("date-start");
                localStorage.removeItem("date-end");
            },
            [
                pathname,
                props.authActions,
                props.courseActions,
                props.lessonPlanActions,
                props.lessonPlanCustomActions,
                props.subjectActions,
                props.weekPlansActions
            ]
        );

        const checkAndLogout = useCallback(() => {
            if (!props.token) {
                props.authActions.logout(`${pathname}${search}`);
            }
        }, [props.token, props.authActions, pathname, search]);

        const checkAndRedirect = useCallback(() => {
            if (!props.token || !props.courseSlug || !props.courses.length) {
                return;
            }

            if (pathname === "/app" || pathname === "/app/redirect" || !pathname.includes("/app")) {
                return;
            }

            const pathHasCourse = pathname.includes("/curso");

            if (!pathHasCourse) {
                const [, ...routePath] = pathname.split("/app/");

                history.push(`/app/curso/${props.courseSlug}/${routePath.join("/")}`);

                return;
            }

            if (pathHasCourse) {
                const course = pathname.split("/curso/")[1].split("/")[0];

                if (course === props.courseSlug) {
                    return;
                }

                const userHasCourse = props.courses.find((courseItem) => courseItem.slug === course);

                if (userHasCourse) {
                    handleChangeCourse(userHasCourse);

                    return;
                }

                const routePath = getRedirectPath(props.menu).split("/app/")[1];

                handleChangeCourse(props.courses[0]);

                history.push(`/app/curso/${props.courses[0].slug}/${routePath}`);
            }
        }, [props.token, props.courseSlug, props.courses, props.menu, pathname, handleChangeCourse]);

        useEffect(() => {
            checkAndLogout();
        }, [checkAndLogout]);

        useEffect(() => {
            checkAndRedirect();
        }, [checkAndRedirect]);

        const course = pathname?.split("/curso/")[1]?.split("/")[0];

        if (
            (!props.token || !props.courseSlug || !props.courses.length || (pathname !== "/app" && props.courseSlug !== course)) &&
            pathname !== "/app/redirect"
        ) {
            return null;
        }

        return <Component {...props} />;
    };

    const mapStateToProps = ({ auth, course, credentials }: IReduxStore) => ({
        token: auth.token,
        menu: auth.menu,
        courseSlug: course.slug,
        courses: credentials.courses
    });

    const mapDispatchToProps = (dispatch: Dispatch) => ({
        authActions: bindActionCreators(AuthActions, dispatch),
        courseActions: bindActionCreators(CourseActions, dispatch),
        lessonPlanActions: bindActionCreators(LessonPlanActions, dispatch),
        weekPlansActions: bindActionCreators(WeekPlansActions, dispatch),
        subjectActions: bindActionCreators(SubjectActions, dispatch),
        lessonPlanCustomActions: bindActionCreators(LessonPlanCustomActions, dispatch)
    });

    return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
};

export default AuthHOC;
