import React from "react";

// Dependencies
import CheckIcon from "prodigio-icons/web/outlined/Check";

// Helpers
import { IRelatedVideo } from "interfaces/IRelatedContent";

// Assets
import {
    WatchScreenRelatedVideoContainer,
    WatchScreenRelatedVideoDetails,
    WatchScreenRelatedVideoItem,
    WatchScreenRelatedVideoTitle,
    WatchScreenRelatedVideoName,
    WatchScreenRelatedSubjectName,
    WatchScreenRelatedVideoImage,
    WatchScreenRelatedVideoWrapper
} from "./styles";

interface IWatchScreenRelatedContentProps {
    playlist: IRelatedVideo[];
    videoId: number;
    subject: {
        color: string;
        name: string;
        slug: string;
    };
    onClickVideo(videoId: number): void;
}

const WatchScreenRelatedVideo = ({ playlist, videoId, subject, onClickVideo }: IWatchScreenRelatedContentProps) => {
    // Trava a exibiçao do componente se não existir conteúdo
    if (!Array.isArray(playlist) || playlist.length === 0) {
        return null;
    }

    return (
        <WatchScreenRelatedVideoContainer>
            <WatchScreenRelatedVideoTitle>Vídeos Relacionados</WatchScreenRelatedVideoTitle>
            <WatchScreenRelatedVideoWrapper>
                {playlist
                    .filter((item) => item.id !== videoId)
                    .map((videoPlaylist) => (
                        <WatchScreenRelatedVideoItem
                            data-test-id="related-video"
                            key={videoPlaylist.id}
                            onClick={() => onClickVideo(videoPlaylist.eventId || videoPlaylist.id)}
                        >
                            <WatchScreenRelatedVideoImage>
                                {videoPlaylist.image ? <img src={videoPlaylist.image} alt={videoPlaylist.title} /> : <span />}
                            </WatchScreenRelatedVideoImage>
                            <WatchScreenRelatedVideoDetails>
                                {!!subject.name && <WatchScreenRelatedSubjectName>{subject.name}</WatchScreenRelatedSubjectName>}
                                {videoPlaylist.viewed && <CheckIcon />}
                                <WatchScreenRelatedVideoName data-test-id="related-video-name">{videoPlaylist.title}</WatchScreenRelatedVideoName>
                            </WatchScreenRelatedVideoDetails>
                        </WatchScreenRelatedVideoItem>
                    ))}
            </WatchScreenRelatedVideoWrapper>
        </WatchScreenRelatedVideoContainer>
    );
};

export default React.memo(WatchScreenRelatedVideo);
