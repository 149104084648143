import React, { useState, useMemo } from "react";
import { ExerciseListResultQuestionsContainer, ExerciseListResultQuestionFeedback, ExerciseListResultQuestionDropdownButton } from "./styles";
import Letter from "components/Letter";
import Dropdown from "components/Dropdown";

import AddOutlinedIcon from "prodigio-icons/web/outlined/Add";
import getLetterByNumber from "utils/getLetterByNumber";

import CommentsIcon from "prodigio-icons/web/filled/Comments";
import TemplateSheetIcon from "prodigio-icons/web/filled/TemplateSheet";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";
import { IQuestion } from "interfaces/IQuestion";
import QuestionType from "screens/ExerciseList/enums/QuestionType";
import { IAnswerCardItem } from "store/ducks/answerCard";
import Badge from "components/Badge";
import useIsMobile from "hooks/use-is-mobile";

interface IProps {
    question: IQuestion;
    answer?: IAnswerCardItem;
    number: number;
    resolutions: IExerciseListQuestionResolution[];
    showAnsers: boolean;
    isExtra: boolean;
    onClickShowQuestion(): void;
    onClickSeeResolution(): void;
}

const ExerciseListResultQuestion = ({
    question,
    answer,
    number,
    onClickShowQuestion,
    resolutions,
    onClickSeeResolution,
    showAnsers = false,
    isExtra = false
}: IProps) => {
    const [activeDropdown, setActiveDropdown] = useState(false);

    const isMobile = useIsMobile();

    const { questionType = QuestionType.Objective } = question;

    const questionLegend = useMemo(() => {
        try {
            if (questionType === QuestionType.Discursive) {
                if (answer === undefined) return "Você não respondeu.";

                if (answer && answer.hit) return "Você acertou!";

                return "Você errou";
            }

            if (!answer || (!answer.objectiveAnswer && !answer.discursiveAnswer)) {
                throw new Error();
            }

            if (!!answer.hit && showAnsers) {
                return "Você acertou!";
            }

            if (!question || !question.alternatives) {
                throw new Error();
            }

            const correctAlternativeIndex = question.alternatives.findIndex((alternative: any) => !!alternative.isCorrection);
            const markedAlternativeIndex = question.alternatives.findIndex((alternative: any) => alternative.id === answer.objectiveAnswer);

            if (!showAnsers && questionType === QuestionType.Objective) {
                return `Você respondeu ${getLetterByNumber(markedAlternativeIndex)}.`;
            }

            if (!answer.hit && isMobile) {
                return `Você errou. Resposta correta: ${getLetterByNumber(correctAlternativeIndex)}.`;
            }

            if (!answer.hit) {
                return (
                    <>
                        Você respondeu {getLetterByNumber(markedAlternativeIndex)}, mas a correta é{" "}
                        <strong>{getLetterByNumber(correctAlternativeIndex)}</strong>.
                    </>
                );
            }
        } catch (error) {
            return "Você não respondeu.";
        }
    }, [answer, question, showAnsers, isMobile, questionType]);

    const letter = useMemo(() => {
        try {
            const letterObjectiveQuestionProps = !!answer && {
                isCorrect: answer.hit,
                isWrong: !answer.hit
            };

            return (
                <Letter
                    letter={String(number)}
                    {...(showAnsers && letterObjectiveQuestionProps)}
                    {...(showAnsers && !!letterObjectiveQuestionProps && !!letterObjectiveQuestionProps.isCorrect && { id: "correct" })}
                    {...(showAnsers && !!letterObjectiveQuestionProps && !!letterObjectiveQuestionProps.isWrong && { id: "incorrect" })}
                />
            );
        } catch (error) {
            return <Letter letter={String(number) || ""} />;
        }
    }, [number, answer, showAnsers]);

    return (
        <ExerciseListResultQuestionsContainer data-test-id={!!answer ? "exerciselist-result-question-marked" : "exerciselist-result-question"}>
            {letter}

            <ExerciseListResultQuestionFeedback>{questionLegend}</ExerciseListResultQuestionFeedback>

            {showAnsers && isExtra && !!answer && (
                <Badge variant="info" backgroundWeight={100}>
                    Nota: {answer.points || 0}
                </Badge>
            )}

            <Dropdown
                onClick={() => setActiveDropdown(!activeDropdown)}
                label={
                    <ExerciseListResultQuestionDropdownButton active={activeDropdown}>
                        <AddOutlinedIcon />
                    </ExerciseListResultQuestionDropdownButton>
                }
            >
                {!!showAnsers && !!resolutions && !!resolutions.length && (
                    <Dropdown.DropdownItem onClick={onClickSeeResolution} data-test-id="exercise-list-result-see-resolution">
                        <CommentsIcon width={16} height={16} />
                        Ver resolução
                    </Dropdown.DropdownItem>
                )}

                <Dropdown.DropdownItem onClick={onClickShowQuestion} data-test-id="exercise-list-result-see-question">
                    <TemplateSheetIcon width={16} height={16} />
                    Ver questão
                </Dropdown.DropdownItem>
            </Dropdown>
        </ExerciseListResultQuestionsContainer>
    );
};

export default React.memo(ExerciseListResultQuestion);
