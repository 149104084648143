import React, { memo } from "react";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import * as S from "./styles";

import SubjectScreenDetailsContentPerType from "../ContentPerType";
import SubjectScreenDetailsActiveModuleSkeleton from "./skeleton";
import ErrorUI from "components/ErrorUI";
import { IBookletContentPerType } from "store/interfaces/IBookletModule";
import { isCourseFree } from "helpers/isCourseFree";

interface IProps {
    contentPerType?: IBookletContentPerType[];
    name: string;
    isLoading?: boolean;
    withBlur?: boolean;
    error: boolean;
    imageSrc?: string;
    linkHref?: string;
}

const SubjectScreenDetailsActiveModule = memo(
    ({ contentPerType = [], name = "", isLoading = true, withBlur = false, error = false, imageSrc = "", linkHref = "" }: IProps) => {
        const { courseSlug } = useSelector(({ course }: IReduxStore) => ({ courseSlug: course?.slug }));
        const hasSources = imageSrc && linkHref;

        if (error) {
            return (
                <S.SubjectScreenDetailsActiveModuleContainer xs={12} sm={6}>
                    <S.SubjectScreenDetailsActiveModuleCard withBlur={withBlur}>
                        <ErrorUI withButton={false} message="Ocorreu um erro ao carregar esse módulo." />
                    </S.SubjectScreenDetailsActiveModuleCard>
                </S.SubjectScreenDetailsActiveModuleContainer>
            );
        }

        if (isLoading) {
            return <SubjectScreenDetailsActiveModuleSkeleton />;
        }

        return (
            <S.SubjectScreenDetailsActiveModuleContainer xs={12} sm={6}>
                <S.SubjectScreenDetailsActiveModuleCard withBlur={withBlur}>
                    {!!contentPerType.length && (
                        <div style={{ padding: "16px 8px" }}>
                            <S.SubjectScreenDetailsActiveModuleHeader>
                                <S.SubjectScreenDetailsActiveModuleLegend>Conteúdo do módulo</S.SubjectScreenDetailsActiveModuleLegend>
                                <S.SubjectScreenDetailsActiveModuleTitle>{name}</S.SubjectScreenDetailsActiveModuleTitle>
                            </S.SubjectScreenDetailsActiveModuleHeader>
                            <SubjectScreenDetailsContentPerType contentPerType={contentPerType} />
                        </div>
                    )}
                    {!contentPerType.length && isCourseFree(courseSlug) && hasSources && (
                        <a target="_blank" href={linkHref} rel="noopener noreferrer" style={{ padding: "10px" }} className="pricecard_plataforma">
                            <img loading="lazy" src={imageSrc} alt="imagem" style={{ width: "100%", borderRadius: "4px" }}></img>
                        </a>
                    )}
                </S.SubjectScreenDetailsActiveModuleCard>
            </S.SubjectScreenDetailsActiveModuleContainer>
        );
    }
);

export default SubjectScreenDetailsActiveModule;
