import { call, put, select, takeLatest } from "redux-saga/effects";

import api from "services/api";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as bookletModulesActions } from "store/ducks/booklet-module";
import { Types as BookletModuleTypes } from "store/ducks/booklet-module";
import history from "services/history";
import { IReduxStore } from "interfaces/IReduxStore";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";
import { Creators as modalActions } from "store/ducks/modal";
import { ICurrentBookletModule } from "store/interfaces/IBookletModule";
import { LinkOpenApproach } from "interfaces/ILinkOpenApproach";

const getCourseSlug = (state: any) => state.course.slug;

function* getAllBookletModules(action: any) {
    try {
        const courseSlug = yield select(getCourseSlug);
        const { payload } = action;

        const { data } = yield call(api.get, payload.endpoint, {
            headers: {
                "X-Course": courseSlug
            }
        });

        yield put(bookletModulesActions.getBookletModulesSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao obter a lista de módulos da disciplina.", "danger"));
        yield put(bookletModulesActions.getBookletModulesFailure(error?.response?.data));
    }
}

function* getCurrentModule(action: any) {
    try {
        const { payload } = action;

        if (!payload?.id) {
            return;
        }

        const subjectSlug: string | undefined = yield select((state: IReduxStore) => state.subject?.slug);

        if (!subjectSlug) {
            return;
        }

        const { data } = yield call(api.get, `/student/subject/${subjectSlug}/module/${payload.id}`);

        if (!data) {
            throw new Error("Cannot get subject infos");
        }

        yield put(bookletModulesActions.getCurrentModuleSuccess(data));
    } catch (error) {
        yield put(bookletModulesActions.getCurrentModuleFailure(error?.response?.data));
    }
}

function* markTextMaterialDone(lOId: number) {
    try {
        const currentModule: ICurrentBookletModule = yield select((state: IReduxStore) => state?.bookletModule?.currentModule);

        if (!currentModule?.id || !currentModule?.contentPerType) {
            return;
        }

        const updateContentPerType = currentModule.contentPerType.map((contentPerTypeItem) => {
            const contentPerTypeItemChildrenIndex = contentPerTypeItem.items.findIndex((item) => item.material?.lOId === lOId);

            if (contentPerTypeItemChildrenIndex === -1) {
                return contentPerTypeItem;
            }

            const currentItem = contentPerTypeItem.items[contentPerTypeItemChildrenIndex];

            if (!!currentItem.done) {
                return contentPerTypeItem;
            }

            const { completedItems, totalItems } = contentPerTypeItem.progress;

            return {
                ...contentPerTypeItem,
                items: contentPerTypeItem.items.map((item, index) => (contentPerTypeItemChildrenIndex === index ? { ...item, done: true } : item)),
                progress: {
                    totalItems,
                    completedItems: completedItems + 1,
                    percent: Math.round(((completedItems + 1) * 100) / totalItems)
                }
            };
        });

        yield put(
            bookletModulesActions.getCurrentModuleSuccess({
                ...currentModule,
                contentPerType: updateContentPerType
            })
        );
    } catch (error) {
        console.log(error);
    }
}

function* markTextMaterialDoneRequest(lOId: number) {
    try {
        yield call(api.patch, `/learningobject/${lOId}/view`, {
            viewed: true
        });

        yield markTextMaterialDone(lOId);
    } catch (error) {
        console.log(error);
    }
}

function* handleClickModuleContent(action: any) {
    try {
        if (!action?.payload) {
            return;
        }

        const { subjectSlug, currentModuleId, courseSlug, hasDispatchedClickModuleContent } = yield select((state: IReduxStore) => ({
            subjectSlug: state.subject?.slug,
            currentModuleId: state.bookletModule?.currentModule?.id,
            courseSlug: state.course.slug,
            hasDispatchedClickModuleContent: state.bookletModule.hasDispatchedClickModuleContent
        }));

        if (action.payload.type !== "Material" && (!subjectSlug || !currentModuleId)) {
            return;
        }

        const { video, exerciseList, material, event, isDispatchingClickModuleContent, isMaterialsPage } = action.payload;

        if (!!isDispatchingClickModuleContent && !hasDispatchedClickModuleContent) {
            yield put(bookletModulesActions.setHasDispatchedClickModuleContent(isDispatchingClickModuleContent));

            if (!!isMaterialsPage) {
                return;
            }
        }

        switch (true) {
            case !!video?.id:
                return yield call(history.push, {
                    pathname: `/app/curso/${courseSlug}/materias/${subjectSlug}/${currentModuleId}/video/${video.id}`
                });

            case !!exerciseList?.id:
                return yield call(history.push, {
                    pathname: `/app/curso/${courseSlug}/materias/${subjectSlug}/${currentModuleId}/lista-de-exercicios/${exerciseList.id}`
                });

            // DEPRECATED
            case !!material?.url:
                if (action?.payload?.done === false && !!material?.lOId) {
                    yield markTextMaterialDoneRequest(material.lOId);
                }

                return yield call(openLinkInBlankPage, material.url);

            case !!material?.linkHTML:
                // MARK TEXT MATERIAL VIEWED
                if (action?.payload?.done === false && !!material?.lOId) {
                    yield markTextMaterialDoneRequest(material.lOId);
                }

                if (material.openApproach !== undefined && material.openApproach === LinkOpenApproach.Self) {
                    const isPlatformLink =
                        material.linkHTML.includes("proenem") ||
                        material.linkHTML.includes("promilitares") ||
                        material.linkHTML.includes("promedicina");

                    if (isPlatformLink) {
                        const platformLink = material.linkHTML.split("/app/")[1];

                        if (!!platformLink) {
                            return yield call(history.push, {
                                pathname: "/app/".concat(platformLink)
                            });
                        }
                    }

                    return yield call(window.open, material.linkHTML, "_self");
                }

                if (material.openApproach !== undefined && material.openApproach === LinkOpenApproach.Blank) {
                    return yield call(openLinkInBlankPage, material.linkHTML);
                }

                return yield put(modalActions.openModal("booklet", { linkHTML: material?.linkHTML, title: material.title ?? "Visualizar Material" }));

            case !!material?.content:
                // MARK TEXT MATERIAL VIEWED
                if (action?.payload?.done === false && !!material?.lOId) {
                    yield markTextMaterialDoneRequest(material.lOId);
                }

                return yield put(
                    modalActions.openModal("booklet", { content: material?.content, title: material.title ?? "Visualizar Material", id: material.id })
                );

            case !!event:
                return yield call(history.push, {
                    pathname: `/app/curso/${courseSlug}/materias/${subjectSlug}/${currentModuleId}/ao-vivo/${event.id}`
                });

            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeLatest(BookletModuleTypes.GET_BOOKLET_MODULES_REQUEST, getAllBookletModules),
    takeLatest(BookletModuleTypes.GET_CURRENT_MODULE_REQUEST, getCurrentModule),
    takeLatest(BookletModuleTypes.CLICK_MODULE_CONTENT, handleClickModuleContent)
];
