import React, { memo } from "react";

// Assets
import { CallToActionContainer, CallToActionContent } from "./styles";

interface ICallToActionProps {
    action: {
        widthContainer?: string;
        alignText?: string;
        content?: string | React.ReactNode;
        linkHref?: string;
        imageUrl?: string;
    };
}

const CallToActionModal = ({ action }: ICallToActionProps) => {
    const { content, imageUrl, linkHref, widthContainer = "500", alignText = "center" } = action;

    return (
        <CallToActionContainer maxWidth={widthContainer}>
            {!!content && <CallToActionContent alignText={alignText}>{content}</CallToActionContent>}
            {!!imageUrl && !!linkHref && (
                <a target="_blank" href={linkHref} rel="noopener noreferrer" className="pricecard_plataforma">
                    <img src={imageUrl} loading="lazy" alt="imagem" />
                </a>
            )}
        </CallToActionContainer>
    );
};

export default memo(CallToActionModal);
