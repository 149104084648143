import styled from "styled-components/macro";
import { theme } from "config/theme";
import { TitleH2 } from "assets/styles/global";

export const ScannerQrCodeContainer = styled.div`
    max-width: 1120px;
    height: 100%;
    margin: auto;
`;

export const ScannerQrCodeWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 22px;
    padding-left: 4px;
    padding-right: 4px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-content: space-between;
    }
`;

export const ScannerQrCodeContent = styled.div`
    width: 100%;
    max-width: 500px;

    border-radius: 4px;

    @media (min-width: 768px) {
        width: 565px;
        height: 286px;
    }
`;

export const ScannerQrCodeSidebar = styled.div`
    width: 530px;
    height: 280px;
    display: flex;
    flex-direction: column;
    justify-content: start;

    @media (max-width: 768px) {
        height: 243px;
        width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        justify-content: end;
    }
`;

export const ScannerQrCodeTitle = styled.h2`
    ${TitleH2};
    font-weight: 700;
    font-size: ${theme.typography.sizes.large};
    line-height: 36px;
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const ScannerQrCodeSubTitle = styled.h2`
    ${TitleH2};
    font-weight: 400;
    font-size: ${theme.typography.sizes.medium};
    line-height: 22px;
    color: ${theme.colors.base[400]};
`;

export const ScannerQrCodeInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: ${theme.spacing.large};
    margin-bottom: ${theme.spacing.large};
`;

export const ScannerQrCodeFooter = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ScannerQrCodeFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};
    flex: 1;
`;

export const ScannerQrCodeFooterItem = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;

    span {
        margin-right: ${theme.spacing.smallX};
    }
`;
