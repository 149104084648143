import { useState, useCallback } from "react";

type IUseFormReturnArray<T> = [
    T,
    (e: React.ChangeEvent<HTMLInputElement>) => void,
    (obj: Partial<T>) => void,
    [boolean, React.Dispatch<React.SetStateAction<boolean>>]
];
/**
 * Hook que controla estado de multiplos valores.
 * @param initialValues Qualquer objeto plano
 * @return [state, setState, onChangeFunction, isFormTouched]
 */
export const useForm = <TState>(initialValues: any): IUseFormReturnArray<TState> => {
    const [values, setValues] = useState<TState>(initialValues);
    const [pristine, setPristine] = useState(true);

    const updateValues = useCallback(
        (newValues: TState) => {
            setValues({ ...values, ...newValues });
        },
        [values]
    );

    return [
        // Valores dentro do estado.
        values,
        // Função de setState.
        (e) => {
            if (pristine) {
                setPristine(false);
            }

            return setValues({
                ...values,
                [e.currentTarget.name]: e.currentTarget.value
            });
        },
        // Função de onChange
        (obj) => {
            return updateValues({ ...values, ...obj });
        },
        // Valor que diz se form já foi modificado diretamente.
        [pristine, setPristine]
    ];
};
