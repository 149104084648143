import React, { useState, useCallback, useMemo } from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

import Error from "prodigio-icons/web/filled/Error";

import * as S from "./styles";

interface IEssaySendedProps {
    mode?: "draft" | "correction";
    onResizeEssay?: () => void;
    composition: IEssayComposition;
    setActiveIndex?: number;
    onChange?(index: number, comment: number): void;
}

const SWITCHER_CHANGE = 1;

const EssaySended = ({ mode = "draft", onResizeEssay, onChange, composition }: IEssaySendedProps) => {
    const [hasImageError, setHasImageError] = useState(false);

    const handleClick = useCallback(
        (index: number) => {
            if (onChange) {
                onChange(SWITCHER_CHANGE, index);
            }
        },
        [onChange]
    );

    const handleImageError = useCallback(() => {
        setHasImageError(true);
    }, []);

    const isMobile = useIsMobile(BreakPoints.small);
    const isLaptop = useIsMobile(BreakPoints.wide);
    const isTablet = useIsMobile();

    const hasImage = !!composition?.pathOriginal && !!composition?.path;

    const content = useMemo(() => {
        if (hasImage) {
            return <img src={composition?.pathOriginal} onError={handleImageError} alt="Imagem da redação enviada" />;
        }

        return <S.EssaySendedContentText>{composition?.content}</S.EssaySendedContentText>;
    }, [composition, handleImageError, hasImage]);

    return (
        <S.EssaySendedContainer>
            <S.EssaySendedWrapper>
                {hasImageError ? (
                    <S.EssaySendedErrorWrapper>
                        <Error />
                        <S.EssaySendedErrorText>Não foi possível carregar a imagem</S.EssaySendedErrorText>
                    </S.EssaySendedErrorWrapper>
                ) : (
                    <>
                        {content}

                        {mode === "correction" && hasImage && (
                            <svg>
                                {composition?.comments?.map((item, indexComment) => {
                                    return item?.selections?.map((selector, index) => {
                                        const selectorWidth = isMobile || isTablet || isLaptop ? selector.w : selector.w - (selector.w * 18) / 100;
                                        const selectorHeight = isMobile || isTablet || isLaptop ? selector.h : selector.h - (selector.h * 18) / 100;
                                        const selectorX = isMobile || isTablet || isLaptop ? selector.x : selector.x - (selector.x * 18) / 100;
                                        const selectorY = isMobile || isTablet || isLaptop ? selector.y : selector.y - (selector.y * 18) / 100;

                                        return (
                                            <svg key={index} onClick={() => handleClick(indexComment)}>
                                                <rect
                                                    width={selectorWidth}
                                                    height={selectorHeight}
                                                    fillOpacity="0.2"
                                                    fill={item?.competence?.color}
                                                    x={selectorX}
                                                    y={selectorY}
                                                ></rect>
                                                <circle
                                                    r="15"
                                                    cx={selectorX + 6}
                                                    cy={selectorY - 4}
                                                    fillOpacity="0.5"
                                                    fill={item?.competence?.color}
                                                ></circle>
                                                <text fill="#ffffff" x={selectorX} y={selectorY}>
                                                    {item?.competence?.title?.slice(-1) || 0}
                                                </text>
                                            </svg>
                                        );
                                    });
                                })}
                            </svg>
                        )}
                    </>
                )}
            </S.EssaySendedWrapper>
        </S.EssaySendedContainer>
    );
};

export default EssaySended;
