import styled from "styled-components";
import { theme } from "config/theme";

interface IProgressBarStyleProps {
    variant: "base" | "info" | "success" | "danger" | "warning";
    value: number;
}

const colors = {
    base: theme.colors.base[100],
    info: theme.colors.system.info[200],
    success: theme.colors.system.success[200],
    warning: theme.colors.system.warning[200],
    danger: theme.colors.system.danger[200]
};

export const AnswerCardProgressContainer = styled.div``;

export const ExerciseListDashboardAnswerCardResultTooltip = styled.span`
    padding: ${theme.spacing.smallXX} ${theme.spacing.smallX};
    background-color: ${theme.colors.base[400]};
    border-radius: 40px;

    color: ${theme.colors.white};
    font-family: "Nunito Sans";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
    letter-spacing: 0.2px;
    text-align: center;

    white-space: nowrap;

    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    z-index: 2;

    &:after {
        content: "";

        width: 0;
        height: 0;

        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 5px solid ${theme.colors.base[400]};

        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: rotate(180deg) translateX(50%);
    }
`;

export const ExerciseListDashboardAnswerCardResultTooltipContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${theme.spacing.largeX};
    margin-bottom: ${theme.spacing.smallXX};
    justify-content: space-between;
    position: relative;

    ${ExerciseListDashboardAnswerCardResultTooltip} {
        top: -30px;
    }

    ${ExerciseListDashboardAnswerCardResultTooltip}:first-child {
        left: 30px;
    }

    ${ExerciseListDashboardAnswerCardResultTooltip}:last-child {
        left: auto;
        right: -30px;
    }
`;

export const ExerciseListDashboardAnswerCardResultProgress = styled.div`
    margin-top: ${theme.spacing.smallXX};
    background-color: ${theme.colors.system.danger[200]};
    height: 10px;
    border-radius: 10px;
`;

export const ExerciseListDashboardAnswerCardResultProgressBar = styled.div<IProgressBarStyleProps>`
    background: ${({ variant }) => colors[variant] || colors["base"]};
    height: 100%;
    width: ${({ value }) => `${value}%`};
    border-radius: 10px;
`;

export const ExerciseListDashboardAnswerCardResultUnfinishedLabel = styled.p`
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[400]};
    margin-top: ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.smallX};
    text-align: right;
`;
