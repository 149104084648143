import React from "react";

// Assets
import { ProgressBadgeContainer } from "./styles";

const ProgressBadge = () => (
    <ProgressBadgeContainer>
        <span>Concluído</span>
    </ProgressBadgeContainer>
);

export default ProgressBadge;
