import { TitleH5 } from "assets/styles/global";
import Filter from "components/Filter";
import { ISelectOption } from "interfaces/ISelectOption";
import React, { memo, useMemo } from "react";

import * as S from "./styles";

interface IProps {
    groupOptions: ISelectOption[];
    onChangeFilter(option: number): void;
    currentFilter: number;
    isMobile: boolean;
}

const PreviousExamScreenHeader = memo(({ groupOptions = [], onChangeFilter, currentFilter, isMobile = false }: IProps) => {
    const currentFilterOption = useMemo(() => groupOptions.find((option) => option.value === currentFilter), [currentFilter, groupOptions]);

    return (
        <S.PreviousExamScreenHeaderContainer>
            <S.PreviousExamScreenHeaderTitle>Provas anteriores</S.PreviousExamScreenHeaderTitle>

            {isMobile && (
                <S.PreviousExamScreenHeaderSearch>
                    <TitleH5>Ordenar por:</TitleH5>

                    <Filter
                        options={groupOptions}
                        onChange={(option) => onChangeFilter(option?.value === undefined ? currentFilter : option?.value!)}
                        withIcon={false}
                        label={currentFilterOption?.label}
                    />
                </S.PreviousExamScreenHeaderSearch>
            )}
        </S.PreviousExamScreenHeaderContainer>
    );
});

export default PreviousExamScreenHeader;
