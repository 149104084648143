// Dependencies
import React, { useEffect, useState, useMemo, Fragment } from "react";
import DislikeIcon from "prodigio-icons/web/filled/Dislike";
import IconLike from "prodigio-icons/web/filled/Like";
import DislikeIconOutline from "prodigio-icons/web/outlined/Dislike";
import IconLikeOutline from "prodigio-icons/web/outlined/Like";

// Styles
import {
    WatchScreenVideoContainer,
    WatchScreenVideoContent,
    WatchScreenVideoContentContainer,
    WatchScreenVideoContentHeader,
    WatchScreenVideoContentHeaderAction,
    WatchScreenVideoContentSwitch,
    WatchScreenVideoContentActions,
    WatchScreenVideoContentHeaderActionsWrapper
} from "./styles";

// Components
import Switch from "components/Switch";
import Video from "components/Video";
import VideoLightRedirector from "components/VideoLightRedirector";

// Helpers
import { LikeStatus } from "enums/LikeStatus";
import { LikeStatusType } from "utils/types/LikeStatus";

// Redux
import { IWatchScreenVideo } from "store/ducks/watch";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IWatchScreenVideoProps {
    eventStartDate?: string;
    video: IWatchScreenVideo;
    blockContent: boolean;
    zoneName?: string;
    eventTitle: string;
    subject: {
        color: string;
        name: string;
        slug: string;
    };
    videoId: number;
    eventEndDate?: string;
    onClickLike(id: number, status: LikeStatusType): void;
    onClickViewed(id: number, status: boolean): void;
    onBlockContent(): void;
}

const WatchScreenVideoDoubt = ({
    eventStartDate,
    video,
    blockContent,
    zoneName,
    subject,
    videoId,
    eventEndDate,
    eventTitle = "",
    onClickLike,
    onClickViewed,
    onBlockContent
}: IWatchScreenVideoProps) => {
    const [like, setLike] = useState<LikeStatusType>(LikeStatus.Initial);

    const { isVideoViewed } = useSelector((state: IReduxStore) => ({
        isVideoViewed: !!state.video?.learningObject?.viewed
    }));

    useEffect(() => {
        setLike(video.learningObject?.isLiked || LikeStatus.Initial);
    }, [video.learningObject]);

    const handleClickLike = (status: LikeStatusType) => {
        try {
            const newStatus = status === like ? LikeStatus.Initial : status;
            setLike(newStatus);
            onClickLike(video.learningObject.id, newStatus);
        } catch (error) {
            console.log(error);
        }
    };

    const handleClickWatched = (status: boolean) => {
        try {
            onClickViewed(video.learningObject.id, status);
        } catch (error) {
            console.log(error);
        }
    };

    const videoTitle = useMemo(() => eventTitle || video?.title, [eventTitle, video]);

    return (
        <Fragment>
            <WatchScreenVideoContainer fixed={true}>
                <Video
                    eventStartDate={eventStartDate}
                    idVideo={videoId}
                    onBlockContent={onBlockContent}
                    zoneName={zoneName}
                    onMarkViewed={() => handleClickWatched(true)}
                    eventEndDate={eventEndDate}
                />
            </WatchScreenVideoContainer>
            <WatchScreenVideoContent>
                <WatchScreenVideoContentContainer>
                    <WatchScreenVideoContentHeader>
                        <p data-test-id="video-subject">{subject.name}</p>
                        {!blockContent && (
                            <WatchScreenVideoContentHeaderActionsWrapper>
                                <WatchScreenVideoContentHeaderAction onClick={() => handleClickLike(LikeStatus.Like)}>
                                    {like === LikeStatus.Like ? (
                                        <IconLike width="26px" height="22px" data-test-id="like-icon" />
                                    ) : (
                                        <IconLikeOutline width="26px" height="22px" />
                                    )}
                                </WatchScreenVideoContentHeaderAction>
                                <WatchScreenVideoContentHeaderAction onClick={() => handleClickLike(LikeStatus.Dislike)}>
                                    {like === LikeStatus.Dislike ? (
                                        <DislikeIcon width="26px" height="22px" data-test-id="dislike-icon" />
                                    ) : (
                                        <DislikeIconOutline width="26px" height="22px" />
                                    )}
                                </WatchScreenVideoContentHeaderAction>
                            </WatchScreenVideoContentHeaderActionsWrapper>
                        )}
                    </WatchScreenVideoContentHeader>

                    <h1 data-test-id="video-title">{videoTitle}</h1>

                    <WatchScreenVideoContentActions>
                        {!blockContent && (
                            <WatchScreenVideoContentSwitch>
                                <Switch
                                    initialValue={isVideoViewed}
                                    variant="success"
                                    onChange={handleClickWatched}
                                    defaultLabel="Vídeo não assistido"
                                    activeLabel="Vídeo assistido"
                                />
                            </WatchScreenVideoContentSwitch>
                        )}

                        <VideoLightRedirector videoId={videoId} />
                    </WatchScreenVideoContentActions>
                </WatchScreenVideoContentContainer>
            </WatchScreenVideoContent>
        </Fragment>
    );
};

export default WatchScreenVideoDoubt;
