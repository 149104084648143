import styled from "styled-components";
import { BodyText2 } from "assets/styles/global";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { FilterDropDown } from "components/Filter/styles";
import { ExerciseListAnswerCardQuestionAlternatives } from "components/AnswerCardQuestion/styles";

export const ExerciseListAnswerCardModalBackground = styled.div`
    position: fixed;
    background: rgba(37, 39, 45, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 9999999;
    display: flex;
    justify-content: flex-end;
`;

export const ExerciseListAnswerCardModalContainer = styled.div`
    border-radius: 0;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    padding: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 40%;
    }

    ${MediaQueries.BIGGER_THAN_TABLET} {
        max-width: 304px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        max-width: 400px;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        max-width: 500px;
    }
`;

export const ExerciseListAnswerCardModalHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.medium};
    justify-content: space-between;

    ${FilterDropDown} {
        max-width: 50%;
    }
`;

export const ExerciseListAnswerCardModalTitle = styled.div`
    display: flex;
    align-items: center;
    flex: 1;

    svg {
        margin-right: ${theme.spacing.small};
    }

    p {
        ${BodyText2};
    }
`;

export const ExerciseListAnswerCardModalCloseButton = styled.button`
    background: transparent;
    outline: none;
    border: none;
`;

export const ExerciseListAnswerCardModalCloseHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: ${theme.spacing.small} 0;
`;

export const ExerciseListAnswerCardModalEssayStatus = styled(ExerciseListAnswerCardQuestionAlternatives)`
    > svg {
        margin-right: 10px;
    }
`;
