import styled from "styled-components/macro";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { MediaQueriesManual } from "assets/styles/settings";
import { TitleH2, BodyText1 } from "assets/styles/global";
import { Row } from "components/Grid";

export const LessonPlanCustomHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LessonPlanCustomHeader = styled.header`
    display: flex;
    margin-bottom: ${theme.spacing.small};
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacing.medium};
`;

export const LessonPlanCustomTitle = styled.h2`
    ${TitleH2};
    color: ${theme.colors.base[500]};
`;

export const LessonPlanCustomSubTitle = styled.h2`
    ${BodyText1};
    color: ${theme.colors.base[400]};
    padding-bottom: ${theme.spacing.medium};
`;

export const LessonPlanCustomColumn = styled(Row)`
    ${MediaQueries.SMALL} {
        margin-top: ${theme.spacing.medium};
        margin-bottom: 120px;
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        margin-top: ${theme.spacing.medium}; // 24px
        margin-bottom: 120px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1090px) {
        webp-step {
            min-width: 200px;
        }
    }

    @media only screen and (min-width: 1191px) and (max-width: 1290px) {
        webp-step {
            min-width: 190px;
        }
    }

    ${MediaQueriesManual(1024)} {
        margin-bottom: 100px;
    }
`;

export const LessonPlanCustomFooterContainer = styled.div`
    background: ${theme.colors.white};
    padding: ${theme.spacing.small};
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
`;

export const LessonPlanCustomFooterContent = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: ${theme.spacing.small};
    }
`;

export const LessonPlanCustomWebpFilterList = styled.div`
    display: flex;
    height: 100%;
    width: 100%;

    .webp-filter-item {
        margin-top: 16px;

        &:nth-child(1) {
            margin-top: 0;
        }
    }
`;

export const LessonPlanCustomCalendarContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: ${theme.spacing.smallX};
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: ${theme.spacing.medium};
    background: ${theme.colors.system.info[400]};
`;

export const LessonPlanCustomCalendar = styled.div`
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 11px;
    height: 48px;
    width: 48px;
    background: ${theme.colors.white};
`;

export const LessonPlanCustomCalendarInfo = styled.div`
    margin-left: ${theme.spacing.smallX};

    span {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: ${theme.colors.white};
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            border-left: 1px solid ${theme.colors.base[100]};
            margin-left: 15px;
            padding-left: 16px;
        }
    }
`;

export const ExerciseCardTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: ${theme.colors.white};
`;
