import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "../../interfaces/IReduxStore";
import { Types } from "../../store/ducks/exerciseListCustom";
import CustomExerciseListCards from "./CustomExerciseListCards";
import { CustomExerciseListWelcome } from "./CustomExerciseListWelcome";

function ExerciseListCustom() {
    const userHasList = useSelector((state: IReduxStore) => state.exerciseListCustom.userHasList);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: Types.CHECK_IF_USER_HAS_LIST_REQUEST });
        return () => {
            dispatch({ type: Types.CLEAR_STATE });
        };
    }, [dispatch]);

    return <>{userHasList ? <CustomExerciseListCards /> : <CustomExerciseListWelcome />}</>;
}

export default ExerciseListCustom;
