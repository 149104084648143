import { call, put, select, takeLatest } from "redux-saga/effects";
import history from "services/history";
import { DropdownFilterControls } from "../../../enums/DropdownFilterControls";
import {
    ContractApi,
    CustomListFilterCheckbox,
    CustomListFilterFormData,
    IExerciseListCustom,
    IExerciseListCustomState
} from "../../../interfaces/IExerciseListCustom";
import { IReduxStore } from "../../../interfaces/IReduxStore";
import api from "../../../services/api";
import { Creators as alertActions } from "../../ducks/alert";
import { Creators as exerciseListCustomActions, Types } from "../../ducks/exerciseListCustom";
import {
    buildContractObject,
    buildQueryString,
    getCustomListRequestEndpoint,
    getFilteredOptionsIds,
    transformDataToCustomListState,
    transformDataToFilterOptions,
    transformPhaseDataToFilterOptions,
    transformYearDataToFilterOptions
} from "./helpers";

function* checkIfUserHasList() {
    try {
        const { data } = yield call(api.get, "questionengine/exerciselist/has_lists");

        yield put(exerciseListCustomActions.checkIfUserHasListSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao verificar se o usuário possui alguma lista.", "danger"));
        yield put(exerciseListCustomActions.checkIfUserHasListFailure(error?.response?.data));
    }
}

function* getCustomExercisesList(action?: any) {
    try {
        const perPage = !!action.payload?.perPage ? action.payload.perPage : 6;
        const page = !!action.payload?.page ? action.payload.page : 1;
        const title = !!action.payload?.title ? action.payload.title : "";
        const order = !!action.payload?.order ? action.payload.order : 3;

        const endpoint = getCustomListRequestEndpoint(title, order, page, perPage);
        const { data } = yield call(api.get, endpoint);

        const customListData: IExerciseListCustom = transformDataToCustomListState(data);

        const currentState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        const defineItems = (currentState: IExerciseListCustomState, customListData: IExerciseListCustom) => {
            // When title exists, return only the customListData with the filtered cards
            if (!!title) return customListData.items;

            if (!title && page === 1) return customListData.items;

            // Returns the new data and previous current state data, so that pagination can work.
            return [...(!!currentState.customList?.items ? currentState.customList?.items : []), ...customListData.items];
        };

        const combinedStateAndNewData = {
            page: customListData.page,
            perPage: customListData.perPage,
            total: customListData.total,
            items: defineItems(currentState, customListData)
        };

        yield put(exerciseListCustomActions.getCustomExercisesListSuccess(combinedStateAndNewData));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar as listas de exercícios.", "danger"));
        yield put(exerciseListCustomActions.getCustomExercisesListFailure(error?.response?.data));
    }
}

function* getAllSubjects() {
    try {
        const { data } = yield call(api.get, "questionengine/subject");

        const subjectData = transformDataToFilterOptions(data);

        yield put(exerciseListCustomActions.getAllSubjectsSuccess(subjectData));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de matérias.", "danger"));
        yield put(exerciseListCustomActions.getAllSubjectsFailure(error?.response?.data));
    }
}

function* getTopics() {
    try {
        const currentState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        const subjectOptions: any = currentState.subjects?.options;

        if (!!subjectOptions) {
            const selectedSubjects = getFilteredOptionsIds(subjectOptions);

            const topicsOptions: any = currentState?.topics?.options;

            let selectedTopics: any = [];
            if (!!topicsOptions) {
                topicsOptions.forEach((item: any) => {
                    const arrayItem = getFilteredOptionsIds(item.options as CustomListFilterCheckbox[]);
                    if (arrayItem.length > 0) {
                        selectedTopics = [...selectedTopics, ...arrayItem];
                    }
                });
            }

            const { data } = yield call(api.get, `questionengine/topic?subject=${selectedSubjects.toString()}`);

            const topicData = transformDataToFilterOptions(data, selectedTopics);

            yield put(exerciseListCustomActions.getTopicsSuccess(topicData));
        }
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de tópicos.", "danger"));
        yield put(exerciseListCustomActions.getTopicsFailure(error?.response?.data));
    }
}

function* getSubtopics() {
    try {
        const currentState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        const topicsOptions: any = currentState.topics?.options;

        if (!!topicsOptions) {
            const subtopicsOptions: any = currentState.subtopics?.options;

            let selectedTopics: any = [];
            topicsOptions.forEach((item: CustomListFilterFormData) => {
                const arrayItem = getFilteredOptionsIds(item.options as CustomListFilterCheckbox[]);
                if (arrayItem.length > 0) {
                    selectedTopics = [...selectedTopics, ...arrayItem];
                }
            });

            const { data } = yield call(api.get, `questionengine/subtopic?topic=${selectedTopics.toString()}`);

            let selectedSubtopics: any = [];
            if (subtopicsOptions && subtopicsOptions.length > 0) {
                subtopicsOptions.forEach((subject: any) => {
                    subject.options.forEach((topic: any) => {
                        const arrayItem = getFilteredOptionsIds(topic.options as CustomListFilterCheckbox[]);
                        if (arrayItem.length > 0) {
                            selectedSubtopics = [...selectedSubtopics, ...arrayItem];
                        }
                    });
                });
            }

            const subtopicData = transformDataToFilterOptions(data, selectedSubtopics);

            yield put(exerciseListCustomActions.getSubtopicsSuccess(subtopicData));
        }
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de subtópicos.", "danger"));
        yield put(exerciseListCustomActions.getSubtopicsFailure(error?.response?.data));
    }
}

function* getQuestionType() {
    try {
        const questionTypeData: CustomListFilterFormData = {
            type: DropdownFilterControls.list,
            options: [
                { id: 0, label: "Objetiva", type: DropdownFilterControls.checkbox, checked: false },
                { id: 1, label: "Discursiva", type: DropdownFilterControls.checkbox, checked: false }
            ]
        };

        yield put(exerciseListCustomActions.getQuestionTypeSuccess(questionTypeData));
    } catch (error) {
        yield put(exerciseListCustomActions.getQuestionTypeFailure(error?.response?.data));
    }
}

function* getContestWithAuthoredQuestions() {
    try {
        const { data } = yield call(api.get, "questionengine/contest/elective");

        const authoredQuestions = transformDataToFilterOptions(data);

        yield put(exerciseListCustomActions.getContestWithAuthoredQuestionsSuccess(authoredQuestions));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de concursos com questões autorais.", "danger"));
        yield put(exerciseListCustomActions.getContestWithAuthoredQuestionsFailure(error?.response?.data));
    }
}

function* getContest() {
    try {
        const { data } = yield call(api.get, "questionengine/contest");

        const contestData = transformDataToFilterOptions(data);

        yield put(exerciseListCustomActions.getContestSuccess(contestData));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de concursos.", "danger"));
        yield put(exerciseListCustomActions.getContestSuccess(error?.response?.data));
    }
}

function* getContestYear() {
    try {
        const currentState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        const contestOptions: any = currentState.contest?.options;

        if (!!contestOptions) {
            const selectedContests = getFilteredOptionsIds(contestOptions);

            const yearsOptions = currentState.contestYear?.options;

            let selectedYears: any = [];
            if (!!yearsOptions?.length) {
                yearsOptions.forEach((item: any) => {
                    const arrayItem = getFilteredOptionsIds(item.options as CustomListFilterCheckbox[]);
                    if (arrayItem.length > 0) {
                        selectedYears = [...selectedYears, ...arrayItem];
                    }
                });
            }

            const { data } = yield call(api.get, `questionengine/contest/year?contest=${selectedContests.toString()}`);

            const yearData = transformYearDataToFilterOptions(data, selectedYears);

            yield put(exerciseListCustomActions.getContestYearSuccess(yearData));
        }
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de matérias.", "danger"));
        yield put(exerciseListCustomActions.getContestYearFailure(error?.response?.data));
    }
}

function* getContestPhase() {
    try {
        const currentState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        const yearsOptions: any = currentState.contestYear?.options;

        if (!!yearsOptions) {
            let selectedYearsPerContest: any = [];
            yearsOptions.forEach((item: any) => {
                const arrayItem = getFilteredOptionsIds(item.options as CustomListFilterCheckbox[]);
                if (arrayItem.length > 0) {
                    selectedYearsPerContest = [...selectedYearsPerContest, ...arrayItem];
                }
            });

            const { data } = yield call(api.get, `questionengine/contestphase?contestYear=${selectedYearsPerContest.toString()}`);

            const phasesOptions = currentState.contestPhase?.options;

            let selectedPhases: any = [];
            if (!!phasesOptions?.length) {
                phasesOptions.forEach((contest: any) => {
                    contest.options.forEach((year: any) => {
                        const arrayItem = getFilteredOptionsIds(year.options as CustomListFilterCheckbox[]);
                        if (arrayItem.length > 0) {
                            selectedPhases = [...selectedPhases, ...arrayItem];
                        }
                    });
                });
            }

            const contestPhaseData = transformPhaseDataToFilterOptions(data, selectedPhases);
            yield put(exerciseListCustomActions.getContestPhaseSuccess(contestPhaseData));
        }
    } catch (error) {
        yield put(alertActions.showAlert(error.message, "danger"));
        yield put(exerciseListCustomActions.getContestPhaseSuccess(error?.response?.data));
    }
}

function* setUserListSize(action: any) {
    try {
        const { value } = action.payload.dropdownData;

        /** The maximum list size. Defined by contract with the backend team */
        const listLimit: number = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom.listSizeLimit);

        /** The total number of questions for that filtering set */
        const listSize: number = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom.listSize);

        const listMax = listSize > listLimit ? listLimit : listSize;

        if (value > listMax) {
            yield put(alertActions.showAlert(`Sua lista não poderá conter mais de ${listMax} questões.`, "danger"));
            yield put(exerciseListCustomActions.setUserListSizeSuccess(listMax));
        } else {
            yield put(exerciseListCustomActions.setUserListSizeSuccess(value));
        }
    } catch (error) {
        yield put(alertActions.showAlert(error.message, "danger"));
        yield put(exerciseListCustomActions.setUserListSizeFailure(error?.response?.data));
    }
}

function* getListSize() {
    try {
        const exerciseListCustomState: IExerciseListCustomState = yield select(({ exerciseListCustom }: IReduxStore) => exerciseListCustom);

        if (!exerciseListCustomState.subjects) return;

        const contract: ContractApi = buildContractObject(exerciseListCustomState);
        const queryString = buildQueryString(contract);

        const { data } = yield call(api.get, `questionengine/question?${queryString}`);

        yield put(exerciseListCustomActions.getListSizeSuccess(data.count));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao retornar a quantidade de questões", "danger"));
        yield put(exerciseListCustomActions.getListSizeSuccess(error?.response?.data));
    }
}

function* saveCustomExerciseList(action: any) {
    try {
        const { exerciseListCustom: exerciseListCustomState, courseSlug } = yield select(({ exerciseListCustom, course }: IReduxStore) => ({
            exerciseListCustom,
            courseSlug: course.slug
        }));

        const userListSize = exerciseListCustomState.userListSize;
        const listName = action.payload.listName;

        if (userListSize && userListSize > 0 && listName) {
            const contract: ContractApi = buildContractObject(exerciseListCustomState);

            const newList = {
                title: listName,
                quantity: userListSize,
                filter: contract
            };

            const { data } = yield call(api.post, "questionengine/exerciselist", newList);

            yield put(exerciseListCustomActions.saveCustomExerciseListSuccess(data));
            yield put(alertActions.showAlert("Lista criada com sucesso!", "success"));
            yield call(history.push, {
                pathname: `/app/curso/${courseSlug}/banco-de-questoes`
            });
        } else {
            throw new Error("Erro ao salvar lista de exercícios.");
        }
    } catch (error) {
        yield put(alertActions.showAlert("Erro ao salvar lista de exercícios.", "danger"));
        yield put(exerciseListCustomActions.saveCustomExerciseListSuccess(error?.response?.data));
    }
}

function* deleteExerciseList(action: any) {
    try {
        const listId = action.payload;

        yield call(api.delete, `questionengine/exerciselist/${listId}`);

        yield put(exerciseListCustomActions.deleteListSuccess(listId));

        yield put(exerciseListCustomActions.getCustomExercisesListRequest());
        yield put(exerciseListCustomActions.checkIfUserHasListRequest());
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar ao deletar a lista.", "danger"));
        yield put(exerciseListCustomActions.deleteListFailure(error?.response?.data));
    }
}

function* createAnswerCard(action: any) {
    try {
        const listId = action.payload;

        const { data } = yield call(api.post, `questionengine/answercard`, { idExerciseList: listId });

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield put(exerciseListCustomActions.createAnswerCardSuccess());
        history.push(`/app/curso/${courseSlug}/banco-de-questoes/lista-de-exercicios/${data.idExerciseList}/estudo/${data.id}`);
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar ao deletar a lista.", "danger"));
        yield put(exerciseListCustomActions.createAnswerCardFailure(error?.response?.data));
    }
}

export default [
    takeLatest(Types.DELETE_LIST_REQUEST, deleteExerciseList),
    takeLatest(Types.GET_ALL_SUBJECTS_REQUEST, getAllSubjects),
    takeLatest(Types.GET_CONTEST_PHASE_REQUEST, getContestPhase),
    takeLatest(Types.GET_CONTEST_REQUEST, getContest),
    takeLatest(Types.GET_CONTEST_WITH_AUTHORED_QUESTIONS_REQUEST, getContestWithAuthoredQuestions),
    takeLatest(Types.GET_CONTEST_YEAR_REQUEST, getContestYear),
    takeLatest(Types.GET_CUSTOM_EXERCISES_LIST_REQUEST, getCustomExercisesList),
    takeLatest(Types.GET_LIST_SIZE_REQUEST, getListSize),
    takeLatest(Types.GET_QUESTION_TYPE_REQUEST, getQuestionType),
    takeLatest(Types.GET_SUBTOPICS_REQUEST, getSubtopics),
    takeLatest(Types.GET_TOPICS_REQUEST, getTopics),
    takeLatest(Types.SAVE_CUSTOM_EXERCISE_LIST_REQUEST, saveCustomExerciseList),
    takeLatest(Types.SET_USER_LIST_SIZE_REQUEST, setUserListSize),
    takeLatest(Types.CREATE_ANSWER_CARD_REQUEST, createAnswerCard),
    takeLatest(Types.CHECK_IF_USER_HAS_LIST_REQUEST, checkIfUserHasList)
];
