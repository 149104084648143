import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import {
    FilterGroupItems,
    FilterGroupContainer,
    FilterGroupWrapper,
    FilterGroupTitle,
    FilterGroupHeader,
    FilterModalTitle,
    FilterGroupHeaderButton,
    FilterGroupItemContainer
} from "./styles";

const FilterGroupPlaceholder = () => {
    return (
        <FilterGroupContainer>
            <FilterGroupHeader>
                <FilterModalTitle>
                    <Skeleton width="88px" height="12px" />
                </FilterModalTitle>
                <FilterGroupHeaderButton type="button">
                    <Skeleton width="45px" height="12px" />
                </FilterGroupHeaderButton>
            </FilterGroupHeader>
            <FilterGroupItems>
                {Array.from(Array(5)).map((_, index) => (
                    <FilterGroupItemContainer key={index}>
                        <FilterGroupTitle style={{ marginBottom: 14 }}>
                            <Skeleton width="100px" height="12px" />
                        </FilterGroupTitle>
                        <FilterGroupWrapper>
                            {Array.from(Array(4)).map((_, ix) => (
                                <Skeleton key={ix} width="48px" height="24px" style={{ borderRadius: 40, marginRight: 8 }} />
                            ))}
                        </FilterGroupWrapper>
                    </FilterGroupItemContainer>
                ))}
            </FilterGroupItems>
        </FilterGroupContainer>
    );
};

export default FilterGroupPlaceholder;
