// Dependencies
import { createActions, createReducer } from "reduxsauce";

// Types
import {
    ActivityState,
    ActivityActionsType,
    ActivityActionsCreators,
    TeacherCreateActivityExerciseListQuestionAlternative,
    ActivityType,
    ActivityExerciseListType
} from "store/interfaces/activity";

// Utils
import formatDate from "utils/formatDate";
import { ternary } from "utils/ternary";

export const { Creators, Types } = createActions<Record<ActivityActionsType, ActivityActionsType>, ActivityActionsCreators>({
    clearStudentDashboard: [],
    getStudentDashboardActivitiesRequest: [],
    getStudentDashboardActivitiesSuccess: ["payload"],
    getStudentDashboardActivitiesFailure: ["payload"],
    clearStudentExecution: [],
    getStudentExecutionActivityDetailsRequest: ["payload"],
    getStudentExecutionActivityDetailsSuccess: ["payload"],
    getStudentExecutionActivityDetailsFailure: ["payload"],
    getStudentExecutionAnswerCardIdRequest: ["payload"],
    getStudentExecutionAnswerCardIdSuccess: ["payload"],
    getStudentExecutionAnswerCardIdFailure: ["payload"],
    createStudentExecutionActivitySubmissionRequest: ["payload"],
    createStudentExecutionActivitySubmissionSuccess: ["payload"],
    createStudentExecutionActivitySubmissionFailure: ["payload"],
    createStudentExecutionActivitySubmissionFileRequest: ["payload"],
    createStudentExecutionActivitySubmissionFileSuccess: ["payload"],
    createStudentExecutionActivitySubmissionFileFailure: ["payload"],
    deleteStudentExecutionActivitySubmissionFileRequest: ["payload"],
    deleteStudentExecutionActivitySubmissionFileSuccess: ["payload"],
    deleteStudentExecutionActivitySubmissionFileFailure: ["payload"],
    setStudentExecutionActivitySubmissionFileModal: ["payload"],
    cancelStudentExecutionActivitySubmissionFile: ["payload"],
    clearTeacherCreate: [],
    getTeacherCreateActivityDetailsRequest: ["payload"],
    getTeacherCreateActivityDetailsSuccess: ["payload"],
    getTeacherCreateActivityDetailsFailure: ["payload"],
    getTeacherCreateEnrollmentsRequest: [],
    getTeacherCreateEnrollmentsSuccess: ["payload"],
    getTeacherCreateEnrollmentsFailure: ["payload"],
    getTeacherCreateSubjectsRequest: [],
    getTeacherCreateSubjectsSuccess: ["payload"],
    getTeacherCreateSubjectsFailure: ["payload"],
    getTeacherCreateQuestionsBankExerciseListsRequest: [],
    getTeacherCreateQuestionsBankExerciseListsSuccess: ["payload"],
    getTeacherCreateQuestionsBankExerciseListsFailure: ["payload"],
    createTeacherCreateActivityRequest: ["payload"],
    createTeacherCreateActivitySuccess: [],
    createTeacherCreateActivityFailure: ["payload"],
    getTeacherCreateFileTypesRequest: [],
    getTeacherCreateFileTypesSuccess: ["payload"],
    getTeacherCreateFileTypesFailure: ["payload"],
    createTeacherCreateSupportMaterialsRequest: ["payload"],
    createTeacherCreateSupportMaterialsSuccess: ["payload"],
    createTeacherCreateSupportMaterialsFailure: ["payload"],
    deleteTeacherCreateSupportMaterialRequest: ["payload"],
    deleteTeacherCreateSupportMaterialSuccess: ["payload"],
    deleteTeacherCreateSupportMaterialFailure: ["payload"],
    cancelTeacherCreateSupportMaterialUpload: ["payload"],
    setTeacherCreateMobileStep: ["payload"],
    setTeacherCreateActivityType: ["payload"],
    setTeacherCreateCurrentActivity: ["payload"],
    setTeacherCreateIsCurrentQuestionModalOpened: ["payload"],
    setTeacherCreateIsCurrentAlternativeBottomSheetOpened: ["payload"],
    setTeacherCreateIsCurrentAlternativeAccordionOpened: ["payload"],
    setTeacherCreateIsCurrentAlternativeCorrectAnswer: ["payload"],
    setTeacherCreateCurrentQuestion: [],
    setTeacherCreateSaveAlternative: ["payload"],
    setTeacherCreateShowUndoRemoveAlternativeAlert: ["payload"],
    setTeacherCreateIntervalToUndoRemoveAlternative: ["payload"],
    setTeacherCreateCurrentAlternativeStatementChange: ["payload"],
    setTeacherCreateCurrentQuestionSubjectAndStatement: ["payload"],
    setTeacherCreateCurrentQuestionCorrectAlternative: ["payload"],
    setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit: ["payload"],
    cancelTeacherCreateCurrentQuestionAlternativeEdit: ["payload"],
    setTeacherCreateSaveCurrentQuestion: ["payload"],
    editTeacherCreateCurrentQuestionAlternative: ["payload"],
    editTeacherCreateCurrentQuestion: ["payload"],
    undoTeacherCreateRemoveAlternative: [],
    deleteTeacherCreateCurrentQuestionAlternative: ["payload"],
    deleteTeacherCreateCurrentQuestion: ["payload"],
    clearTeacherCreateCurrentQuestion: [],
    clearTeacherDashboard: [],
    getTeacherDashboardActivitiesRequest: ["payload"],
    getTeacherDashboardActivitiesSuccess: ["payload"],
    getTeacherDashboardActivitiesFailure: ["payload"],
    deleteTeacherDashboardActivityRequest: ["payload"],
    deleteTeacherDashboardActivitySuccess: ["payload"],
    deleteTeacherDashboardActivityFailure: ["payload"],
    getTeacherDashboardEnrollmentsRequest: [],
    getTeacherDashboardEnrollmentsSuccess: ["payload"],
    getTeacherDashboardEnrollmentsFailure: ["payload"],
    setTeacherDashboardFilterIsOpened: ["payload"],
    setTeacherDashboardFilterData: ["payload"],
    setTeacherDashboardCurrentActivityToBeDeleted: ["payload"],
    clearTeacherDashboardFilterData: [],
    clearTeacherActivitySubmissions: [],
    getTeacherActivitySubmissionsDataRequest: ["payload"],
    getTeacherActivitySubmissionsDataSuccess: ["payload"],
    getTeacherActivitySubmissionsDataFailure: ["payload"],
    getTeacherActivitySubmissionsActivityDetailsRequest: ["payload"],
    getTeacherActivitySubmissionsActivityDetailsSuccess: ["payload"],
    getTeacherActivitySubmissionsActivityDetailsFailure: ["payload"],
    clearActivitySubmission: ["payload"],
    getActivitySubmissionDetailsRequest: ["payload"],
    getActivitySubmissionDetailsSuccess: ["payload"],
    getActivitySubmissionDetailsFailure: ["payload"],
    getActivitySubmissionSubmissionRequest: ["payload"],
    getActivitySubmissionSubmissionSuccess: ["payload"],
    getActivitySubmissionSubmissionFailure: ["payload"],
    createActivitySubmissionTeacherCorrectionRequest: ["payload"],
    createActivitySubmissionTeacherCorrectionSuccess: ["payload"],
    createActivitySubmissionTeacherCorrectionFailure: ["payload"]
});

export const INITIAL_STATE: ActivityState = {
    studentDashboard: {
        activities: {
            isLoading: true,
            data: []
        }
    },
    studentExecution: {
        activityDetails: {
            isLoading: true
        },
        answerCardId: {
            isLoading: false
        },
        isCreating: false,
        isRequestingFile: false
    },
    teacherCreate: {
        subjects: {
            isLoading: false,
            data: []
        },
        details: {
            isLoading: true
        },
        enrollments: {
            isLoading: true,
            data: []
        },
        questionsBankExerciseLists: {
            isLoading: true,
            data: []
        },
        mobileStep: "activity-class-data",
        isCreating: false,
        fileTypes: {
            isLoading: true,
            data: []
        },
        supportMaterials: {
            data: [],
            isLoading: false
        },
        activityType: {
            data: "exercise-list",
            exerciseListType: "pre-created"
        },
        currentQuestion: {
            isCurrentQuestionModalOpened: false,
            isCurrentAlternativeBottomSheetOpened: false,
            isCurrentAlternativeCorrectAnswer: false,
            undoRemoveAlternativeInSeconds: 5
        }
    },
    teacherDashboard: {
        activities: {
            isLoading: true,
            data: []
        },
        enrollments: {
            isLoading: true,
            data: []
        },
        filter: {
            data: {
                classes: [],
                statuses: []
            },
            isOpened: false
        }
    },
    teacherActivitySubmissions: {
        data: {
            isLoading: true,
            data: []
        },
        activityDetails: {
            isLoading: true
        }
    },
    activitySubmission: {
        details: {
            isLoading: true
        },
        submission: {
            isLoading: true
        },
        isTeacherCorrecting: false
    }
};

const clearStudentDashboard = (state = INITIAL_STATE) => ({
    ...state,
    studentDashboard: INITIAL_STATE.studentDashboard
});
const getStudentDashboardActivitiesRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentDashboard: {
        ...state.studentDashboard,
        activities: {
            ...state.studentDashboard.activities,
            isLoading: true
        }
    }
});
const getStudentDashboardActivitiesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentDashboard: {
        ...state.studentDashboard,
        activities: {
            isLoading: false,
            data: action.payload.map((activity: any) => ({
                ...activity,
                ...(!!activity.dueDate && { dueDate: formatDate(activity.dueDate, "dd/MM/yyyy 'às' HH:mm'h'") }),
                releaseDate: formatDate(activity.releaseDate, "dd/MM/yyyy 'às' HH:mm'h'")
            }))
        }
    }
});
const getStudentDashboardActivitiesFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentDashboard: {
        ...state.studentDashboard,
        activities: { ...state.studentDashboard.activities, isLoading: false }
    }
});

const clearStudentExecution = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: INITIAL_STATE.studentExecution
});
const getStudentExecutionActivityDetailsRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: { ...state.studentExecution.activityDetails, isLoading: true }
    }
});
const getStudentExecutionActivityDetailsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: {
            isLoading: false,
            data: {
                ...action.payload,
                ...(!!action.payload.dueDate && { dueDate: formatDate(action.payload.dueDate, "dd/MM/yyyy 'às' HH:mm'h'") }),
                releaseDate: formatDate(action.payload.releaseDate, "dd/MM/yyyy 'às' HH:mm'h'")
            }
        }
    }
});
const getStudentExecutionActivityDetailsFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: { ...state.studentExecution.activityDetails, isLoading: false }
    }
});
const getStudentExecutionAnswerCardIdRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        answerCardId: { ...state.studentExecution.answerCardId, isLoading: true }
    }
});
const getStudentExecutionAnswerCardIdSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: {
            ...state.studentExecution.activityDetails,
            data: {
                ...state.studentExecution.activityDetails.data!,
                submission: action.payload
            }
        },
        answerCardId: {
            isLoading: false,
            data: action.payload?.reference
        }
    }
});
const getStudentExecutionAnswerCardIdFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        answerCardId: { ...state.studentExecution.answerCardId, isLoading: false }
    }
});
const createStudentExecutionActivitySubmissionRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isCreating: true
    }
});
const createStudentExecutionActivitySubmissionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: {
            ...state.studentExecution.activityDetails,
            data: {
                ...state.studentExecution.activityDetails.data,
                submission: action.payload
            }
        },
        isCreating: false
    }
});
const createStudentExecutionActivitySubmissionFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isCreating: false
    }
});
const createStudentExecutionActivitySubmissionFileRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isRequestingFile: false
    }
});
const createStudentExecutionActivitySubmissionFileSuccess = (state = INITIAL_STATE, action: any) => {
    const submissionFilesTemp = state.studentExecution.activityDetails.data?.submission?.files ?? [];

    const updatedData = submissionFilesTemp.map((existingFile: any) => {
        const newFile = action.payload.find((file: any) => file.name === existingFile.name);
        return newFile ? { ...existingFile, ...newFile } : existingFile;
    });

    const newFiles = action.payload.filter((newFile: any) => !submissionFilesTemp.some((existingFile: any) => existingFile.name === newFile.name));

    return {
        ...state,
        studentExecution: {
            ...state.studentExecution,
            activityDetails: {
                ...state.studentExecution.activityDetails,
                data: {
                    ...state.studentExecution.activityDetails.data,
                    submission: {
                        ...state.studentExecution.activityDetails.data?.submission,
                        files: [...updatedData, ...newFiles]
                    }
                }
            },
            isRequestingFile: false
        }
    };
};
const createStudentExecutionActivitySubmissionFileFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isRequestingFile: false
    }
});
const deleteStudentExecutionActivitySubmissionFileRequest = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isRequestingFile: true
    }
});
const deleteStudentExecutionActivitySubmissionFileSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: {
            ...state.studentExecution.activityDetails,
            data: {
                ...state.studentExecution.activityDetails.data,
                submission: {
                    ...state.studentExecution.activityDetails.data?.submission,
                    files: state.studentExecution.activityDetails.data?.submission?.files?.filter((file) => file.id !== action.payload) ?? []
                }
            }
        },
        isRequestingFile: false
    }
});
const deleteStudentExecutionActivitySubmissionFileFailure = (state = INITIAL_STATE) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        isRequestingFile: false
    }
});
const setStudentExecutionActivitySubmissionFileModal = (state = INITIAL_STATE, action: any) => ({
    ...state,
    studentExecution: {
        ...state.studentExecution,
        activityDetails: {
            ...state.studentExecution.activityDetails,
            data: {
                ...state.studentExecution.activityDetails.data,
                submission: {
                    ...state.studentExecution.activityDetails.data?.submission,
                    selectedFileModal: action.payload
                }
            }
        }
    }
});
const cancelStudentExecutionActivitySubmissionFile = (state = INITIAL_STATE, action: any) => {
    const tokenSource = state.studentExecution.activityDetails.data?.submission?.files?.find((file) => file.name === action.payload)
        ?.cancelTokenSource;
    if (tokenSource) {
        tokenSource.cancel("Upload cancelled by user.");
    }
    return {
        ...state,
        studentExecution: {
            ...state.studentExecution,
            activityDetails: {
                ...state.studentExecution.activityDetails,
                data: {
                    ...state.studentExecution.activityDetails.data,
                    submission: {
                        ...state.studentExecution.activityDetails.data?.submission,
                        files: state.studentExecution.activityDetails.data?.submission?.files?.filter((file) => file.name !== action.payload)
                    }
                }
            },
            isRequestingFile: false
        }
    };
};

const clearTeacherCreate = (state = INITIAL_STATE) => ({ ...state, teacherCreate: INITIAL_STATE.teacherCreate });
const getTeacherCreateActivityDetailsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        activityDetails: { ...state.teacherCreate.details, isLoading: true }
    }
});
const getTeacherCreateActivityDetailsSuccess = (state = INITIAL_STATE, action: any) => {
    const { ownedByAuthor, exerciseList, type } = action.payload;

    const activityType = ternary([
        [type === "Upload", "send-file"],
        [type === "Composition", "discursive"],
        [type === "ExerciseList", "exercise-list"]
    ]) as ActivityType;

    const exerciseListType: ActivityExerciseListType = !!ownedByAuthor ? "custom" : "pre-created";

    const questions = exerciseList?.items?.map((exerciseListItem: any) => {
        return {
            exerciseListItemId: exerciseListItem.id,
            questionId: exerciseListItem.question.id,
            order: exerciseListItem.order,
            statement: exerciseListItem.question.enunciation,
            subject: { value: exerciseListItem.question.subject.id },
            alternatives: exerciseListItem.question.alternatives.map((exerciseListItemAlternative: any) => {
                return {
                    id: exerciseListItemAlternative.id,
                    order: String.fromCharCode(exerciseListItemAlternative.order + 64),
                    isCorrect: exerciseListItemAlternative.isCorrection,
                    statement: exerciseListItemAlternative.enunciation
                };
            })
        };
    });

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            activityType: {
                ...state.teacherCreate.activityType,
                data: activityType,
                ...(activityType === "exercise-list" && { exerciseListType })
            },
            currentActivity: {
                ...state.teacherCreate.currentActivity,
                exerciseList: {
                    ...state.teacherCreate.currentActivity?.exerciseList,
                    ...(activityType === "exercise-list" &&
                        exerciseListType === "custom" && {
                            listId: exerciseList.id,
                            questions
                        })
                }
            },
            details: {
                isLoading: false,
                data: {
                    ...action.payload,
                    ...(!!action.payload.dueDate && { dueDate: formatDate(action.payload.dueDate, "dd/MM/yyyy HH:mm") }),
                    releaseDate: formatDate(action.payload.releaseDate, "dd/MM/yyyy HH:mm")
                }
            }
        }
    };
};
const getTeacherCreateActivityDetailsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        activityDetails: { ...state.teacherCreate.details, isLoading: false }
    }
});
const getTeacherCreateEnrollmentsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        enrollments: { ...state.teacherCreate.enrollments, isLoading: true }
    }
});
const getTeacherCreateEnrollmentsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        enrollments: {
            isLoading: false,
            data: action.payload.map((enrollment: any) => ({
                label: enrollment?.class?.name,
                value: enrollment?.class?.id
            }))
        }
    }
});
const getTeacherCreateEnrollmentsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        enrollments: { ...state.teacherCreate.enrollments, isLoading: false }
    }
});
const getTeacherCreateSubjectsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        subjects: { ...state.teacherCreate.subjects, isLoading: true }
    }
});
const getTeacherCreateSubjectsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        subjects: {
            isLoading: false,
            data: action.payload.map((subject: any) => ({
                label: subject?.name,
                value: subject?.id
            }))
        }
    }
});
const getTeacherCreateSubjectsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        subjects: { ...state.teacherCreate.subjects, isLoading: false }
    }
});
const getTeacherCreateQuestionsBankExerciseListsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        questionsBankExerciseLists: { ...state.teacherCreate.questionsBankExerciseLists, isLoading: true }
    }
});
const getTeacherCreateQuestionsBankExerciseListsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        questionsBankExerciseLists: {
            isLoading: false,
            data: action.payload.items.map((exerciseList: any) => ({
                label: exerciseList?.title,
                value: exerciseList?.id
            }))
        }
    }
});
const getTeacherCreateQuestionsBankExerciseListsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        questionsBankExerciseLists: { ...state.teacherCreate.questionsBankExerciseLists, isLoading: false }
    }
});
const createTeacherCreateActivityRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        isCreating: true
    }
});
const createTeacherCreateActivitySuccess = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        isCreating: false
    }
});
const createTeacherCreateActivityFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        isCreating: false
    }
});
const getTeacherCreateFileTypesRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        fileTypes: { ...state.teacherCreate.fileTypes, isLoading: true }
    }
});
const getTeacherCreateFileTypesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        fileTypes: {
            isLoading: false,
            data: action.payload
        }
    }
});
const getTeacherCreateFileTypesFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        fileTypes: { ...state.teacherCreate.fileTypes, isLoading: false }
    }
});
const createTeacherCreateSupportMaterialsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        supportMaterials: { ...state.teacherCreate.supportMaterials, isLoading: false }
    }
});
const createTeacherCreateSupportMaterialsSuccess = (state = INITIAL_STATE, action: any) => {
    const supportMaterialsTemp = state.teacherCreate.supportMaterials.data ?? [];

    const updatedData = supportMaterialsTemp.map((existingFile: any) => {
        const newFile = action.payload.find((file: any) => file.name === existingFile.name);
        return newFile ? { ...existingFile, ...newFile } : existingFile;
    });

    const newFiles = action.payload.filter((newFile: any) => !supportMaterialsTemp.some((existingFile: any) => existingFile.name === newFile.name));

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            supportMaterials: {
                isLoading: false,
                data: [...updatedData, ...newFiles]
            }
        }
    };
};
const cancelTeacherCreateSupportMaterialUpload = (state = INITIAL_STATE, action: any) => {
    const tokenSource = state.teacherCreate.supportMaterials.data?.find((file) => file.name === action.payload)?.cancelTokenSource;
    if (tokenSource) {
        tokenSource.cancel("Upload cancelled by user.");
    }
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            supportMaterials: {
                ...state.teacherCreate.supportMaterials,
                isLoading: false,
                data: state.teacherCreate.supportMaterials.data?.filter((file) => file.name !== action.payload)
            }
        }
    };
};
const createTeacherCreateSupportMaterialsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        supportMaterials: { ...state.teacherCreate.supportMaterials, isLoading: false }
    }
});
const deleteTeacherCreateSupportMaterialRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        supportMaterials: { ...state.teacherCreate.supportMaterials, isLoading: true }
    }
});
const deleteTeacherCreateSupportMaterialSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        supportMaterials: {
            isLoading: false,
            data: state.teacherCreate.supportMaterials.data?.filter((supportMaterial) => supportMaterial.id !== action.payload)
        }
    }
});
const deleteTeacherCreateSupportMaterialFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        supportMaterials: { ...state.teacherCreate.supportMaterials, isLoading: false }
    }
});
const setTeacherCreateMobileStep = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        mobileStep: action.payload
    }
});
const setTeacherCreateActivityType = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        activityType: action.payload
    }
});
const setTeacherCreateCurrentActivity = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentActivity: {
            ...state.teacherCreate.currentActivity,
            exerciseList: {
                ...state.teacherCreate.currentActivity?.exerciseList,
                shouldReleaseResultForStudent: action.payload
            }
        }
    }
});
const setTeacherCreateIsCurrentQuestionModalOpened = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            isCurrentQuestionModalOpened: action.payload
        }
    }
});
const setTeacherCreateIsCurrentAlternativeBottomSheetOpened = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            isCurrentAlternativeBottomSheetOpened: action.payload
        }
    }
});
const setTeacherCreateIsCurrentAlternativeCorrectAnswer = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            isCurrentAlternativeCorrectAnswer: action.payload
        }
    }
});
const setTeacherCreateIsCurrentAlternativeAccordionOpened = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestionAccordion: action.payload
        }
    };
};
const setTeacherCreateShowUndoRemoveAlternativeAlert = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            isShownUndoRemoveAlternative: action.payload
        }
    }
});
const setTeacherCreateIntervalToUndoRemoveAlternative = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            undoRemoveAlternativeInSeconds: action.payload
        }
    }
});
const setTeacherCreateCurrentQuestion = (state = INITIAL_STATE) => {
    const currentQuestion = state.teacherCreate.currentQuestion;

    const alternatives = (currentQuestion?.alternatives || []).map((alternative) => ({
        ...alternative,
        isEditing: false,
        isCreating: false
    }));
    const newAlternative = {
        order: String.fromCharCode(65 + alternatives.length),
        statement: "",
        isCorrect: false,
        isEditing: true,
        isCreating: true
    };
    const newAlternatives = [...alternatives, newAlternative];
    const newQuestion = {
        ...currentQuestion,
        alternatives: newAlternatives,
        currentAlternative: newAlternative
    };

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const setTeacherCreateSaveAlternative = (state = INITIAL_STATE, action: any) => {
    const { currentQuestion } = state.teacherCreate;
    const alternatives = (currentQuestion?.alternatives || []).map((item) =>
        item.order === action.payload.order
            ? {
                  ...action.payload,
                  isEditing: false,
                  isCorrect: currentQuestion?.isCurrentAlternativeCorrectAnswer
              }
            : {
                  ...item,
                  isCorrect: currentQuestion?.isCurrentAlternativeCorrectAnswer ? false : item.isCorrect
              }
    );
    const newQuestion = {
        ...currentQuestion,
        alternatives
    };
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const setTeacherCreateCurrentAlternativeStatementChange = (state = INITIAL_STATE, action: any) => {
    const alternatives = (state.teacherCreate.currentQuestion?.alternatives || []).map((item, index) =>
        action.payload.alternativeIndex === index
            ? {
                  ...item,
                  statement: action.payload.value
              }
            : item
    ) as TeacherCreateActivityExerciseListQuestionAlternative[];
    const newQuestion = {
        ...state.teacherCreate.currentQuestion,
        alternatives
    };

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const undoTeacherCreateRemoveAlternative = (state = INITIAL_STATE) => {
    const alternativeToBeAdded = state.teacherCreate.currentQuestion?.alternativeRemoved || {};
    const position = (alternativeToBeAdded.order || "").charCodeAt(0) - 65;

    const currentAlternatives = state.teacherCreate.currentQuestion?.alternatives || [];

    const newAlternatives = [...currentAlternatives.slice(0, position), alternativeToBeAdded, ...currentAlternatives.slice(position)];

    const updatedAlternatives = newAlternatives.map((alt, index) => ({
        ...alt,
        order: String.fromCharCode(65 + index)
    }));

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: {
                ...state.teacherCreate.currentQuestion,
                alternatives: updatedAlternatives
            }
        }
    };
};

const deleteTeacherCreateCurrentQuestionAlternative = (state = INITIAL_STATE, action: any) => {
    const currentQuestion = state.teacherCreate.currentQuestion;

    let alternatives = (currentQuestion?.alternatives || []).filter((item) => item.order !== action.payload.order);
    alternatives = alternatives.map((alternative, index) => ({
        ...alternative,
        order: String.fromCharCode(65 + index)
    }));

    const alternativeRemoved = (currentQuestion?.alternatives || []).find((item) => item.order === action.payload.order);

    const questionTemp = {
        ...state.teacherCreate.currentQuestion,
        alternatives,
        alternativeRemoved
    };

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: questionTemp
        }
    };
};
const deleteTeacherCreateCurrentQuestion = (state = INITIAL_STATE, action: any) => {
    const questions = state.teacherCreate.currentActivity?.exerciseList.questions;

    let questionsTemp = (questions || []).filter((question) => question.order !== action.payload);
    questionsTemp = questionsTemp.map((question, index) => ({
        ...question,
        order: index
    }));

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentActivity: {
                ...state.teacherCreate.currentActivity,
                exerciseList: {
                    ...state.teacherCreate.currentActivity?.exerciseList,
                    questions: questionsTemp
                }
            }
        }
    };
};
const editTeacherCreateCurrentQuestion = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: {
                ...state.teacherCreate.currentQuestion,
                ...action.payload,
                isEditing: true
            }
        }
    };
};
const editTeacherCreateCurrentQuestionAlternative = (state = INITIAL_STATE, action: any) => {
    let alternatives = (state.teacherCreate.currentQuestion?.alternatives || []).map((item) => ({
        ...item,
        isEditing: !action.payload.shouldCancel && item.order === action.payload.alternative.order,
        isCreating: false
    }));
    const newQuestion = {
        ...state.teacherCreate.currentQuestion,
        alternatives
    };
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const cancelTeacherCreateCurrentQuestionAlternativeEdit = (state = INITIAL_STATE, action: any) => {
    let alternatives = state.teacherCreate.currentQuestion?.alternatives || [];

    if (!!action.payload.isCreating && !!action.payload.isEditing) {
        alternatives = alternatives.filter((item) => item.order !== action.payload.order);
    } else {
        alternatives = (state.teacherCreate.currentQuestion?.alternatives || []).map((item) => {
            if (action.payload.order === item.order) {
                return { ...action.payload, isEditing: false };
            }
            return { ...item };
        });
    }
    const newQuestion = {
        ...state.teacherCreate.currentQuestion,
        alternatives
    };
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const setTeacherCreateCurrentQuestionSubjectAndStatement = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            [action.payload.field]: action.payload.value
        }
    }
});
const setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: {
            ...state.teacherCreate.currentQuestion,
            currentAlternative: action.payload
        }
    }
});
const setTeacherCreateCurrentQuestionCorrectAlternative = (state = INITIAL_STATE, action: any) => {
    const { alternative } = action.payload;
    const { currentQuestion } = state.teacherCreate;

    const alternatives = (currentQuestion?.alternatives || []).map((item) => ({
        ...item,
        isCorrect: item.order === alternative.order
    }));
    const newQuestion = {
        ...currentQuestion,
        alternatives
    };
    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestion: newQuestion
        }
    };
};
const setTeacherCreateSaveCurrentQuestion = (state = INITIAL_STATE, action: any) => {
    const questionsTemp = state.teacherCreate.currentActivity?.exerciseList?.questions ?? [];

    const newOrder = action.payload.order ?? questionsTemp.length + 1;
    const newQuestion = {
        ...action.payload,
        order: newOrder
    };

    let newQuestions;

    const existingIndex = questionsTemp.findIndex((question) => question.order === newQuestion.order);

    if (newQuestion.isEditing && existingIndex !== -1) {
        newQuestions = [
            ...questionsTemp.slice(0, existingIndex),
            {
                ...newQuestion,
                isEditing: false
            },
            ...questionsTemp.slice(existingIndex + 1)
        ];
    } else {
        newQuestions = [...questionsTemp, newQuestion];
    }

    return {
        ...state,
        teacherCreate: {
            ...state.teacherCreate,
            currentQuestionAccordion: newQuestion,
            currentActivity: {
                ...state.teacherCreate.currentActivity,
                exerciseList: {
                    ...(state.teacherCreate.currentActivity?.exerciseList ?? {}),
                    questions: newQuestions
                }
            }
        }
    };
};
const clearTeacherCreateCurrentQuestion = (state = INITIAL_STATE) => ({
    ...state,
    teacherCreate: {
        ...state.teacherCreate,
        currentQuestion: INITIAL_STATE.teacherCreate.currentQuestion
    }
});

const clearTeacherDashboard = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: INITIAL_STATE.teacherDashboard
});
const getTeacherDashboardActivitiesRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: {
            ...state.teacherDashboard.activities,
            isLoading: true
        },
        filter: {
            ...state.teacherDashboard.filter,
            isOpened: false
        }
    }
});
const getTeacherDashboardActivitiesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: {
            isLoading: false,
            data: action.payload.map((activity: any) => ({
                ...activity,
                ...(!!activity.dueDate && { dueDate: formatDate(activity.dueDate, "dd/MM/yyyy 'às' HH:mm'h'") }),
                releaseDate: formatDate(activity.releaseDate, "dd/MM/yyyy 'às' HH:mm'h'")
            }))
        }
    }
});
const getTeacherDashboardActivitiesFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: { ...state.teacherDashboard.activities, isLoading: false }
    }
});
const deleteTeacherDashboardActivityRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: { ...state.teacherDashboard.activities, isLoading: true },
        currentActivityToBeDeleted: undefined
    }
});
const deleteTeacherDashboardActivitySuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: {
            ...state.teacherDashboard.activities,
            isLoading: false,
            data: state.teacherDashboard.activities.data?.filter((activity) => activity.id !== action.payload)
        }
    }
});
const deleteTeacherDashboardActivityFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        activities: { ...state.teacherDashboard.activities, isLoading: false }
    }
});
const getTeacherDashboardEnrollmentsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        enrollments: { ...state.teacherDashboard.enrollments, isLoading: true }
    }
});
const getTeacherDashboardEnrollmentsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        enrollments: {
            isLoading: false,
            data: action.payload.map((enrollment: any) => ({
                label: enrollment?.class?.name,
                value: enrollment?.class?.id
            }))
        }
    }
});
const getTeacherDashboardEnrollmentsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        enrollments: { ...state.teacherDashboard.enrollments, isLoading: false }
    }
});
const setTeacherDashboardFilterIsOpened = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        filter: {
            ...state.teacherDashboard.filter,
            isOpened: action.payload
        }
    }
});
const setTeacherDashboardFilterData = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        filter: {
            ...state.teacherDashboard.filter,
            data: action.payload
        }
    }
});
const setTeacherDashboardCurrentActivityToBeDeleted = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        currentActivityToBeDeleted: action.payload
    }
});
const clearTeacherDashboardFilterData = (state = INITIAL_STATE) => ({
    ...state,
    teacherDashboard: {
        ...state.teacherDashboard,
        filter: INITIAL_STATE.teacherDashboard.filter
    }
});

const clearTeacherActivitySubmissions = (state = INITIAL_STATE) => ({
    ...state,
    teacherActivitySubmissions: INITIAL_STATE.teacherActivitySubmissions
});
const getTeacherActivitySubmissionsDataRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        data: {
            ...state.teacherActivitySubmissions.data,
            isLoading: true
        }
    }
});
const getTeacherActivitySubmissionsDataSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        data: {
            isLoading: false,
            data: action.payload
        }
    }
});
const getTeacherActivitySubmissionsDataFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        data: { ...state.teacherActivitySubmissions.data, isLoading: false }
    }
});
const getTeacherActivitySubmissionsActivityDetailsRequest = (state = INITIAL_STATE) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        activityDetails: { ...state.teacherActivitySubmissions.activityDetails, isLoading: true }
    }
});
const getTeacherActivitySubmissionsActivityDetailsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        activityDetails: {
            isLoading: false,
            data: {
                ...action.payload,
                ...(!!action.payload.dueDate && { dueDate: formatDate(action.payload.dueDate, "dd/MM/yyyy 'às' HH:mm'h'") }),
                releaseDate: formatDate(action.payload.releaseDate, "dd/MM/yyyy 'às' HH:mm'h'")
            }
        }
    }
});
const getTeacherActivitySubmissionsActivityDetailsFailure = (state = INITIAL_STATE) => ({
    ...state,
    teacherActivitySubmissions: {
        ...state.teacherActivitySubmissions,
        activityDetails: { ...state.teacherActivitySubmissions.activityDetails, isLoading: false }
    }
});

const clearActivitySubmission = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: INITIAL_STATE.activitySubmission
});
const getActivitySubmissionDetailsRequest = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        details: {
            ...state.activitySubmission.details,
            isLoading: true
        }
    }
});
const getActivitySubmissionDetailsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        details: {
            isLoading: false,
            data: {
                ...action.payload,
                ...(!!action.payload.dueDate && { dueDate: formatDate(action.payload.dueDate, "dd/MM/yyyy 'às' HH:mm'h'") }),
                releaseDate: formatDate(action.payload.releaseDate, "dd/MM/yyyy 'às' HH:mm'h'")
            }
        }
    }
});
const getActivitySubmissionDetailsFailure = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        details: { ...state.activitySubmission.details, isLoading: false }
    }
});
const getActivitySubmissionSubmissionRequest = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        submission: { ...state.activitySubmission.submission, isLoading: true }
    }
});
const getActivitySubmissionSubmissionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        submission: {
            isLoading: false,
            data: {
                ...action.payload,
                ...(!!action.payload.answerCard && {
                    answerCard: {
                        ...action.payload.answerCard,
                        questions: action.payload.answerCard.questions.map((item: any) => ({
                            ...item,
                            answerOrder: item.answerOrder ? String.fromCharCode(item.answerOrder + 64).toUpperCase() : undefined,
                            correctionOrder: String.fromCharCode(item.correctionOrder + 64).toUpperCase()
                        }))
                    }
                })
            }
        }
    }
});
const getActivitySubmissionSubmissionFailure = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        submission: { ...state.activitySubmission.submission, isLoading: false }
    }
});
const createActivitySubmissionTeacherCorrectionRequest = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        isTeacherCorrecting: true
    }
});
const createActivitySubmissionTeacherCorrectionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        submission: {
            isLoading: false,
            data: {
                ...state.activitySubmission.submission.data,
                ...action.payload
            }
        },
        isTeacherCorrecting: false
    }
});
const createActivitySubmissionTeacherCorrectionFailure = (state = INITIAL_STATE) => ({
    ...state,
    activitySubmission: {
        ...state.activitySubmission,
        isTeacherCorrecting: false
    }
});

export default createReducer(INITIAL_STATE, {
    [Types.CLEAR_STUDENT_DASHBOARD]: clearStudentDashboard,
    [Types.GET_STUDENT_DASHBOARD_ACTIVITIES_REQUEST]: getStudentDashboardActivitiesRequest,
    [Types.GET_STUDENT_DASHBOARD_ACTIVITIES_SUCCESS]: getStudentDashboardActivitiesSuccess,
    [Types.GET_STUDENT_DASHBOARD_ACTIVITIES_FAILURE]: getStudentDashboardActivitiesFailure,
    [Types.CLEAR_STUDENT_EXECUTION]: clearStudentExecution,
    [Types.GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_REQUEST]: getStudentExecutionActivityDetailsRequest,
    [Types.GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_SUCCESS]: getStudentExecutionActivityDetailsSuccess,
    [Types.GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_FAILURE]: getStudentExecutionActivityDetailsFailure,
    [Types.GET_STUDENT_EXECUTION_ANSWER_CARD_ID_REQUEST]: getStudentExecutionAnswerCardIdRequest,
    [Types.GET_STUDENT_EXECUTION_ANSWER_CARD_ID_SUCCESS]: getStudentExecutionAnswerCardIdSuccess,
    [Types.GET_STUDENT_EXECUTION_ANSWER_CARD_ID_FAILURE]: getStudentExecutionAnswerCardIdFailure,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_REQUEST]: createStudentExecutionActivitySubmissionRequest,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_SUCCESS]: createStudentExecutionActivitySubmissionSuccess,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FAILURE]: createStudentExecutionActivitySubmissionFailure,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST]: createStudentExecutionActivitySubmissionFileRequest,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_SUCCESS]: createStudentExecutionActivitySubmissionFileSuccess,
    [Types.CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_FAILURE]: createStudentExecutionActivitySubmissionFileFailure,
    [Types.DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST]: deleteStudentExecutionActivitySubmissionFileRequest,
    [Types.DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_SUCCESS]: deleteStudentExecutionActivitySubmissionFileSuccess,
    [Types.DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_FAILURE]: deleteStudentExecutionActivitySubmissionFileFailure,
    [Types.SET_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_MODAL]: setStudentExecutionActivitySubmissionFileModal,
    [Types.CANCEL_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE]: cancelStudentExecutionActivitySubmissionFile,
    [Types.CLEAR_TEACHER_CREATE]: clearTeacherCreate,
    [Types.GET_TEACHER_CREATE_ACTIVITY_DETAILS_REQUEST]: getTeacherCreateActivityDetailsRequest,
    [Types.GET_TEACHER_CREATE_ACTIVITY_DETAILS_SUCCESS]: getTeacherCreateActivityDetailsSuccess,
    [Types.GET_TEACHER_CREATE_ACTIVITY_DETAILS_FAILURE]: getTeacherCreateActivityDetailsFailure,
    [Types.GET_TEACHER_CREATE_ENROLLMENTS_REQUEST]: getTeacherCreateEnrollmentsRequest,
    [Types.GET_TEACHER_CREATE_ENROLLMENTS_SUCCESS]: getTeacherCreateEnrollmentsSuccess,
    [Types.GET_TEACHER_CREATE_ENROLLMENTS_FAILURE]: getTeacherCreateEnrollmentsFailure,
    [Types.GET_TEACHER_CREATE_SUBJECTS_REQUEST]: getTeacherCreateSubjectsRequest,
    [Types.GET_TEACHER_CREATE_SUBJECTS_SUCCESS]: getTeacherCreateSubjectsSuccess,
    [Types.GET_TEACHER_CREATE_SUBJECTS_FAILURE]: getTeacherCreateSubjectsFailure,
    [Types.GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_REQUEST]: getTeacherCreateQuestionsBankExerciseListsRequest,
    [Types.GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_SUCCESS]: getTeacherCreateQuestionsBankExerciseListsSuccess,
    [Types.GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_FAILURE]: getTeacherCreateQuestionsBankExerciseListsFailure,
    [Types.CREATE_TEACHER_CREATE_ACTIVITY_REQUEST]: createTeacherCreateActivityRequest,
    [Types.CREATE_TEACHER_CREATE_ACTIVITY_SUCCESS]: createTeacherCreateActivitySuccess,
    [Types.CREATE_TEACHER_CREATE_ACTIVITY_FAILURE]: createTeacherCreateActivityFailure,
    [Types.GET_TEACHER_CREATE_FILE_TYPES_REQUEST]: getTeacherCreateFileTypesRequest,
    [Types.GET_TEACHER_CREATE_FILE_TYPES_SUCCESS]: getTeacherCreateFileTypesSuccess,
    [Types.GET_TEACHER_CREATE_FILE_TYPES_FAILURE]: getTeacherCreateFileTypesFailure,
    [Types.CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_REQUEST]: createTeacherCreateSupportMaterialsRequest,
    [Types.CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_SUCCESS]: createTeacherCreateSupportMaterialsSuccess,
    [Types.CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_FAILURE]: createTeacherCreateSupportMaterialsFailure,
    [Types.DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_REQUEST]: deleteTeacherCreateSupportMaterialRequest,
    [Types.DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_SUCCESS]: deleteTeacherCreateSupportMaterialSuccess,
    [Types.DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_FAILURE]: deleteTeacherCreateSupportMaterialFailure,
    [Types.CANCEL_TEACHER_CREATE_SUPPORT_MATERIAL_UPLOAD]: cancelTeacherCreateSupportMaterialUpload,
    [Types.SET_TEACHER_CREATE_MOBILE_STEP]: setTeacherCreateMobileStep,
    [Types.SET_TEACHER_CREATE_ACTIVITY_TYPE]: setTeacherCreateActivityType,
    [Types.SET_TEACHER_CREATE_CURRENT_ACTIVITY]: setTeacherCreateCurrentActivity,
    [Types.SET_TEACHER_CREATE_IS_CURRENT_QUESTION_MODAL_OPENED]: setTeacherCreateIsCurrentQuestionModalOpened,
    [Types.SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_BOTTOM_SHEET_OPENED]: setTeacherCreateIsCurrentAlternativeBottomSheetOpened,
    [Types.SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_ACCORDION_OPENED]: setTeacherCreateIsCurrentAlternativeAccordionOpened,
    [Types.SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_CORRECT_ANSWER]: setTeacherCreateIsCurrentAlternativeCorrectAnswer,
    [Types.SET_TEACHER_CREATE_SHOW_UNDO_REMOVE_ALTERNATIVE_ALERT]: setTeacherCreateShowUndoRemoveAlternativeAlert,
    [Types.SET_TEACHER_CREATE_INTERVAL_TO_UNDO_REMOVE_ALTERNATIVE]: setTeacherCreateIntervalToUndoRemoveAlternative,
    [Types.SET_TEACHER_CREATE_CURRENT_QUESTION]: setTeacherCreateCurrentQuestion,
    [Types.SET_TEACHER_CREATE_SAVE_ALTERNATIVE]: setTeacherCreateSaveAlternative,
    [Types.SET_TEACHER_CREATE_CURRENT_ALTERNATIVE_STATEMENT_CHANGE]: setTeacherCreateCurrentAlternativeStatementChange,
    [Types.SET_TEACHER_CREATE_CURRENT_QUESTION_SUBJECT_AND_STATEMENT]: setTeacherCreateCurrentQuestionSubjectAndStatement,
    [Types.SET_TEACHER_CREATE_CURRENT_QUESTION_CORRECT_ALTERNATIVE]: setTeacherCreateCurrentQuestionCorrectAlternative,
    [Types.SET_TEACHER_CREATE_SAVE_CURRENT_QUESTION]: setTeacherCreateSaveCurrentQuestion,
    [Types.EDIT_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE]: editTeacherCreateCurrentQuestionAlternative,
    [Types.EDIT_TEACHER_CREATE_CURRENT_QUESTION]: editTeacherCreateCurrentQuestion,
    [Types.UNDO_TEACHER_CREATE_REMOVE_ALTERNATIVE]: undoTeacherCreateRemoveAlternative,
    [Types.SET_TEACHER_CREATE_CURRENT_QUESTION_SET_CURRENT_ALTERNATIVE_TO_EDIT]: setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit,
    [Types.CANCEL_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE_EDIT]: cancelTeacherCreateCurrentQuestionAlternativeEdit,
    [Types.DELETE_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE]: deleteTeacherCreateCurrentQuestionAlternative,
    [Types.DELETE_TEACHER_CREATE_CURRENT_QUESTION]: deleteTeacherCreateCurrentQuestion,
    [Types.CLEAR_TEACHER_CREATE_CURRENT_QUESTION]: clearTeacherCreateCurrentQuestion,
    [Types.CLEAR_TEACHER_DASHBOARD]: clearTeacherDashboard,
    [Types.GET_TEACHER_DASHBOARD_ACTIVITIES_REQUEST]: getTeacherDashboardActivitiesRequest,
    [Types.GET_TEACHER_DASHBOARD_ACTIVITIES_SUCCESS]: getTeacherDashboardActivitiesSuccess,
    [Types.GET_TEACHER_DASHBOARD_ACTIVITIES_FAILURE]: getTeacherDashboardActivitiesFailure,
    [Types.DELETE_TEACHER_DASHBOARD_ACTIVITY_REQUEST]: deleteTeacherDashboardActivityRequest,
    [Types.DELETE_TEACHER_DASHBOARD_ACTIVITY_SUCCESS]: deleteTeacherDashboardActivitySuccess,
    [Types.DELETE_TEACHER_DASHBOARD_ACTIVITY_FAILURE]: deleteTeacherDashboardActivityFailure,
    [Types.GET_TEACHER_DASHBOARD_ENROLLMENTS_REQUEST]: getTeacherDashboardEnrollmentsRequest,
    [Types.GET_TEACHER_DASHBOARD_ENROLLMENTS_SUCCESS]: getTeacherDashboardEnrollmentsSuccess,
    [Types.GET_TEACHER_DASHBOARD_ENROLLMENTS_FAILURE]: getTeacherDashboardEnrollmentsFailure,
    [Types.SET_TEACHER_DASHBOARD_FILTER_IS_OPENED]: setTeacherDashboardFilterIsOpened,
    [Types.SET_TEACHER_DASHBOARD_FILTER_DATA]: setTeacherDashboardFilterData,
    [Types.SET_TEACHER_DASHBOARD_CURRENT_ACTIVITY_TO_BE_DELETED]: setTeacherDashboardCurrentActivityToBeDeleted,
    [Types.CLEAR_TEACHER_DASHBOARD_FILTER_DATA]: clearTeacherDashboardFilterData,
    [Types.CLEAR_TEACHER_ACTIVITY_SUBMISSIONS]: clearTeacherActivitySubmissions,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_REQUEST]: getTeacherActivitySubmissionsDataRequest,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_SUCCESS]: getTeacherActivitySubmissionsDataSuccess,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_FAILURE]: getTeacherActivitySubmissionsDataFailure,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_REQUEST]: getTeacherActivitySubmissionsActivityDetailsRequest,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_SUCCESS]: getTeacherActivitySubmissionsActivityDetailsSuccess,
    [Types.GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_FAILURE]: getTeacherActivitySubmissionsActivityDetailsFailure,
    [Types.CLEAR_ACTIVITY_SUBMISSION]: clearActivitySubmission,
    [Types.GET_ACTIVITY_SUBMISSION_DETAILS_REQUEST]: getActivitySubmissionDetailsRequest,
    [Types.GET_ACTIVITY_SUBMISSION_DETAILS_SUCCESS]: getActivitySubmissionDetailsSuccess,
    [Types.GET_ACTIVITY_SUBMISSION_DETAILS_FAILURE]: getActivitySubmissionDetailsFailure,
    [Types.GET_ACTIVITY_SUBMISSION_SUBMISSION_REQUEST]: getActivitySubmissionSubmissionRequest,
    [Types.GET_ACTIVITY_SUBMISSION_SUBMISSION_SUCCESS]: getActivitySubmissionSubmissionSuccess,
    [Types.GET_ACTIVITY_SUBMISSION_SUBMISSION_FAILURE]: getActivitySubmissionSubmissionFailure,
    [Types.CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_REQUEST]: createActivitySubmissionTeacherCorrectionRequest,
    [Types.CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_SUCCESS]: createActivitySubmissionTeacherCorrectionSuccess,
    [Types.CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_FAILURE]: createActivitySubmissionTeacherCorrectionFailure
});
