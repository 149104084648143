import { keyframes } from "styled-components";

export const fadein = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const pulse = keyframes`
    from { transform: scale3d(1, 1, 1); }
    50% { transform: scale3d(1.1, 1.1, 1.1); }
    to { transform: scale3d(1, 1, 1); }
`;

export const fadePulse = keyframes`
    from { opacity: 1; }
    50% { opacity: 0.3; }
    to { opacity: 1; }
`;

export const bounceInRight = keyframes`
    from,
    60%,
    75%,
    90%,
    to { animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

    from {
        opacity: 0;
        transform: translate3d(3000px, 0, 0);
    }

    60% {
        opacity: 1;
        transform: translate3d(-25px, 0, 0);
    }

    75% { transform: translate3d(10px, 0, 0); }
    90% { transform: translate3d(-5px, 0, 0); }
    to { transform: translate3d(0, 0, 0); }
`;

export const bounceInUp = keyframes`
    from,
    60%,
    75%,
    90%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0) scaleY(5);
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0) scaleY(0.9);
    }

    75% {
        transform: translate3d(0, 10px, 0) scaleY(0.95);
    }

    90% {
        transform: translate3d(0, -5px, 0) scaleY(0.985);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
`;

export const bounceOutRight = keyframes`
    20% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0) scaleX(0.9);
        transform: translate3d(-20px, 0, 0) scaleX(0.9);
    }
  
    to {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0) scaleX(2);
        transform: translate3d(2000px, 0, 0) scaleX(2);
    }
`;

export const fadeInDown = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, -10%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 10%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const slideLeft = keyframes`
    from { transform: translateX(150%); }
	to { transform: translateX(0%); }
`;

export const slideRight = keyframes`
    from { transform: translateX(-150%); }
	to { transform: translateX(0%); }
`;

export const slideBottomToUp = keyframes`
    from { transform: translateY(100%); }
	to { transform: translateY(0%); }
`;

export const bounce = keyframes`
  from,                                                                                                                                                                                 
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
`;

export const rotateAnimation = keyframes`
    from { transform: rotate(0deg); } 
    to { transform: rotate(360deg); }
`;
