// Dependencies
import styled from "styled-components";

// Types
import { MediaQueries } from "assets/styles/settings";

export const Container = styled.div`
    width: 100%;

    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        border-radius: 8px;
        padding: 24px;
        gap: 30px;
        border: 1px solid #ffffff;
    }
`;

export const TitleActionButtonWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ContentWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 24px;
`;
