import { AxiosResponse } from "axios";
import { all, call, put, takeLatest } from "redux-saga/effects";
import api from "services/api";

import { Creators as alertActions } from "store/ducks/alert";
import { Creators as postActions, Types as postTypes } from "store/ducks/doubtPost";
import {
    IGetTopicPayload,
    IGetPostsPayload,
    IGetHasNewPostsPayload,
    IGetSpecificPostPayload,
    ISendPostPayload,
    IEditPostPayload,
    IDeletePostPayload,
    IGetHasUpdatedPostsPayload,
    IGetUpdatedPostsPayload
} from "store/interfaces/IDoubtPost";

interface IAction<T> {
    payload: T;
    type: string;
}

export function* getTopicRequest({ payload }: IAction<IGetTopicPayload>) {
    try {
        const { learningObjectId } = payload;

        const { data } = yield call(api.get, `/student/forum/topic/lo/${learningObjectId}`);

        yield put(postActions.getTopicSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(postActions.getTopicFailure(error?.response?.data));
        yield put(alertActions.showAlert("Dúvidas indisponíveis no momento", "warning"));
    }
}

export function* getPostsRequest({ payload }: IAction<IGetPostsPayload>) {
    try {
        const { learningObjectId, quantity, justmine, bookmark, isRestarting } = payload;

        const { data } = yield call(api.get, `/student/forum/topic/lo/${learningObjectId}/posts`, {
            params: {
                order: 1,
                quantity,
                justmine,
                ...(!!bookmark && !isRestarting && { bookmark })
            }
        });

        const { data: serverDate } = yield call(api.get, "/util/unixtime");

        yield put(postActions.getPostsSuccess({ response: data, variables: { justmine, isRestarting, serverDate } }));
    } catch (error) {
        yield put(postActions.getPostsFailure(error?.response?.data));
        yield put(alertActions.showAlert("Dúvidas indisponíveis no momento.", "warning"));
    }
}

export function* getHasNewPostsRequest({ payload }: IAction<IGetHasNewPostsPayload>) {
    try {
        const { learningObjectId, quantity } = payload;

        const { data } = yield call(api.get, `/student/forum/topic/lo/${learningObjectId}/posts`, {
            params: {
                order: 1,
                quantity
            }
        });

        yield put(postActions.getHasNewPostsSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(postActions.getHasNewPostsFailure(error?.response?.data));
    }
}

export function* getHasUpdatedPostsRequest({ payload }: IAction<IGetHasUpdatedPostsPayload>) {
    try {
        const { learningObjectId, posts } = payload;

        const { data } = yield call(api.post, `/student/forum/topic/lo/${learningObjectId}/posts/updates`, { Posts: posts });

        yield put(postActions.getHasUpdatedPostsSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(postActions.getHasUpdatedPostsFailure(error?.response?.data));
    }
}

export function* getUpdatedPostsRequest({ payload }: IAction<IGetUpdatedPostsPayload>) {
    try {
        const { posts } = payload;

        const updatedPosts: Array<AxiosResponse> = yield all(posts.map((post) => call(api.get, `/student/forum/topic/post/${post}`)));

        const formattedUpdatedPosts = updatedPosts.map((post) => post.data);

        const { data: serverDate } = yield call(api.get, "/util/unixtime");

        yield put(postActions.getUpdatedPostsSuccess({ response: formattedUpdatedPosts, variables: { serverDate } }));
    } catch (error) {
        yield put(postActions.getUpdatedPostsFailure(error?.response?.data));
    }
}

export function* getSpecificPostRequest({ payload }: IAction<IGetSpecificPostPayload>) {
    try {
        const { specificPostId } = payload;

        const { data } = yield call(api.get, `/student/forum/topic/post/${specificPostId}`);

        yield put(postActions.getSpecificPostSuccess({ response: data, variables: {} }));
    } catch (error) {
        yield put(postActions.getSpecificPostFailure(error?.response?.data));
        yield put(alertActions.showAlert("Dúvidas indisponíveis no momento.", "warning"));
    }
}

export function* sendPostRequest({ payload }: IAction<ISendPostPayload>) {
    try {
        const { postMessage, topicReference } = payload;

        const formattedContent = postMessage.split("\n").join("<br/>");

        const { data } = yield call(api.post, `/student/forum/topic/${topicReference}/post`, { content: formattedContent });

        yield put(postActions.sendPostSuccess({ response: data, variables: {} }));

        yield put(alertActions.showAlert("Dúvida enviada com sucesso.", "success"));
    } catch (error) {
        yield put(postActions.sendPostFailure(error?.response?.data));
        yield put(alertActions.showAlert("Erro ao enviar sua dúvida, tente novamente mais tarde.", "warning"));
    }
}

export function* editPostRequest({ payload }: IAction<IEditPostPayload>) {
    try {
        const { postId, postMessage } = payload;

        const formattedContent = postMessage.split("\n").join("<br/>");

        yield call(api.patch, `/student/forum/topic/post/${postId}`, {
            content: formattedContent
        });

        yield put(postActions.editPostSuccess({ response: {}, variables: payload }));

        yield put(alertActions.showAlert("Dúvida editada com sucesso.", "success"));
    } catch (error) {
        yield put(postActions.editPostFailure(error?.response?.data));
        yield put(alertActions.showAlert("Erro ao editar sua dúvida, tente novamente mais tarde.", "warning"));
    }
}

export function* deletePostRequest({ payload }: IAction<IDeletePostPayload>) {
    try {
        const { postId } = payload;

        yield call(api.delete, `/student/forum/topic/post/${postId}`);

        yield put(postActions.deletePostSuccess({ response: {}, variables: payload }));

        yield put(alertActions.showAlert("Dúvida excluída com sucesso.", "success"));
    } catch (error) {
        yield put(postActions.deletePostFailure(error?.response?.data));
        yield put(alertActions.showAlert("Erro ao excluir sua duvida, tente novamente mais tarde.", "warning"));
    }
}

export default [
    takeLatest(postTypes.GET_TOPIC_REQUEST, getTopicRequest),
    takeLatest(postTypes.GET_POSTS_REQUEST, getPostsRequest),
    takeLatest(postTypes.GET_HAS_NEW_POSTS_REQUEST, getHasNewPostsRequest),
    takeLatest(postTypes.GET_HAS_UPDATED_POSTS_REQUEST, getHasUpdatedPostsRequest),
    takeLatest(postTypes.GET_UPDATED_POSTS_REQUEST, getUpdatedPostsRequest),
    takeLatest(postTypes.GET_SPECIFIC_POST_REQUEST, getSpecificPostRequest),
    takeLatest(postTypes.SEND_POST_REQUEST, sendPostRequest),
    takeLatest(postTypes.EDIT_POST_REQUEST, editPostRequest),
    takeLatest(postTypes.DELETE_POST_REQUEST, deletePostRequest)
];
