import React, { useMemo } from "react";
import {
    ExerciseListFooterContainer,
    ExerciseListFooterNavigationButton,
    ExerciseListFooterNavigation,
    ExerciseListFooterContent,
    ExerciseListFooterAnswerCardButton
} from "./styles";
import Button from "components/Button";

// Assets
import NextIcon from "prodigio-icons/web/filled/Next";
import PreviousIcon from "prodigio-icons/web/filled/Previous";
import AnswerCardIcon from "prodigio-icons/web/filled/CartaoResposta";

import ExerciseListFooterKeyboardDropdown from "./keyboardDropdown";
import { ExerciseListContentContainer } from "screens/ExerciseList/styles";

interface IExerciseListFooterProps {
    isFinished: boolean;
    isMobile: boolean;
    activeQuestionNumber: number;
    totalQuestions: number;
    isFeedBack: boolean;
    onClickSeeQuestions(): void;
    onClickSeeResult(): void;
    onClickFinish(): void;
    onClickAnswerCard(): void;
    onClickPrevQuestion?(): void;
    onClickNextQuestion?(): void;
    hasEssay?: boolean;
    showEssay: boolean;
    showResult: boolean;
}

const ExerciseListFooter = ({
    isFinished,
    onClickFinish,
    onClickNextQuestion,
    onClickPrevQuestion,
    onClickAnswerCard,
    isMobile,
    onClickSeeQuestions,
    activeQuestionNumber,
    totalQuestions,
    onClickSeeResult,
    isFeedBack,
    hasEssay,
    showEssay,
    showResult
}: IExerciseListFooterProps) => {
    const memoAnswerCardButton = useMemo(
        () => (
            <ExerciseListFooterAnswerCardButton type="button" onClick={onClickAnswerCard} data-test-id="exercise-list-answer-card-button">
                <AnswerCardIcon width={24} height={24} />
                Cartão resposta
            </ExerciseListFooterAnswerCardButton>
        ),
        [onClickAnswerCard]
    );

    const memoNavigationButtons = useMemo(() => {
        const disableNextButton = activeQuestionNumber === totalQuestions || activeQuestionNumber <= 0;

        const disablePrevButton = showEssay ? false : activeQuestionNumber <= 1;

        return (
            <ExerciseListFooterNavigation>
                <ExerciseListFooterNavigationButton
                    type="button"
                    onClick={onClickPrevQuestion}
                    disabled={disablePrevButton}
                    data-test-id="exercise-list-prev-question-button"
                >
                    <PreviousIcon width={24} height={24} /> Anterior
                </ExerciseListFooterNavigationButton>
                <ExerciseListFooterNavigationButton
                    type="button"
                    onClick={onClickNextQuestion}
                    disabled={showEssay || disableNextButton}
                    data-test-id="exercise-list-next-question-button"
                >
                    Próxima
                    <NextIcon width={24} height={24} />
                </ExerciseListFooterNavigationButton>
            </ExerciseListFooterNavigation>
        );
    }, [activeQuestionNumber, onClickNextQuestion, onClickPrevQuestion, showEssay, totalQuestions]);

    const memoResultButtons = useMemo(() => {
        if (!isFinished || isFeedBack) {
            return null;
        }

        if (showResult) {
            return (
                <Button
                    type="button"
                    variant="primary"
                    size="medium"
                    onClick={onClickSeeQuestions}
                    block={isMobile}
                    data-test-id="exercise-list-back-to-questions"
                >
                    Rever todas questões
                </Button>
            );
        }

        return (
            <Button
                type="button"
                variant="primary"
                size="medium"
                onClick={onClickSeeResult}
                block={isMobile}
                data-test-id="exercise-list-back-to-results"
            >
                Rever gabarito
            </Button>
        );
    }, [isFeedBack, isFinished, isMobile, onClickSeeQuestions, onClickSeeResult, showResult]);

    return (
        <ExerciseListFooterContainer id="ExerciseListFooterContainer">
            <ExerciseListContentContainer>
                <ExerciseListFooterContent>
                    {!showEssay && <ExerciseListFooterKeyboardDropdown />}

                    {memoAnswerCardButton}

                    {!isFinished && (
                        <Button
                            type="button"
                            variant="primary"
                            size="medium"
                            onClick={onClickFinish}
                            block={isMobile}
                            data-test-id="exercise-list-finish-button"
                        >
                            Finalizar lista
                        </Button>
                    )}

                    {memoResultButtons}

                    {memoNavigationButtons}
                </ExerciseListFooterContent>
            </ExerciseListContentContainer>
        </ExerciseListFooterContainer>
    );
};

export default React.memo(ExerciseListFooter);
