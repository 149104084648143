import React, { memo } from "react";
import Card from "components/Card";
import {
    ExamResultKnowledgeAreasCardTitle,
    ExamResultKnowledgeAreasCardItem,
    ExamResultKnowledgeAreasCardItemName,
    ExamResultKnowledgeAreasCardItemNotes,
    ExamResultKnowledgeAreasCardItemProgress,
    ExamResultKnowledgeAreasCardItemProgressLabel,
    ExamResultKnowledgeAreasCardItemNote
} from "./styles";

import { theme } from "config/theme";
import ProgressBar from "components/ProgressBar";
import { IExamResultKnowledgeArea } from "interfaces/IExam";
import formatPercent from "utils/formatPercent";
import isValidValue from "utils/isValidValue";

interface IProps {
    knowledgeAreas: IExamResultKnowledgeArea[];
}

const ExamResultKnowledgeAreasCard = memo(({ knowledgeAreas = [] }: IProps) => {
    return (
        <Card column style={{ marginBottom: theme.spacing.medium }}>
            <ExamResultKnowledgeAreasCardTitle>Resultado por área de conhecimento</ExamResultKnowledgeAreasCardTitle>

            {knowledgeAreas
                .filter(({ knowledgeArea }) => !!knowledgeArea?.id)
                .map(({ knowledgeArea, ranking, points, totalQuestions = 0, totalCorrects = 0 }) => {
                    const percentage = formatPercent((100 * totalCorrects) / totalQuestions);

                    return (
                        <ExamResultKnowledgeAreasCardItem key={knowledgeArea?.id}>
                            <ExamResultKnowledgeAreasCardItemName>
                                <p>{knowledgeArea?.name}</p>
                            </ExamResultKnowledgeAreasCardItemName>

                            <ExamResultKnowledgeAreasCardItemNotes>
                                {ranking && (
                                    <p>
                                        Ranking: <strong>{ranking?.position || "-"}°</strong>/{ranking?.total || "-"}
                                    </p>
                                )}

                                {isValidValue(points) && (
                                    <ExamResultKnowledgeAreasCardItemNote>
                                        Sua nota: <strong>{points}</strong>
                                    </ExamResultKnowledgeAreasCardItemNote>
                                )}
                            </ExamResultKnowledgeAreasCardItemNotes>

                            <ExamResultKnowledgeAreasCardItemProgress>
                                <ProgressBar
                                    value={percentage}
                                    color={theme.colors.system.info[200]}
                                    label={
                                        <ExamResultKnowledgeAreasCardItemProgressLabel>
                                            <p>Percentual de rendimento</p>
                                            <p>{percentage}%</p>
                                        </ExamResultKnowledgeAreasCardItemProgressLabel>
                                    }
                                    responsive={true}
                                />
                            </ExamResultKnowledgeAreasCardItemProgress>
                        </ExamResultKnowledgeAreasCardItem>
                    );
                })}
        </Card>
    );
});

export default ExamResultKnowledgeAreasCard;
