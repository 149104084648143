import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { CardContainer } from "components/Card/styles";
import { BodyText1, BodyText2, Button2, Micro, Label, TitleH3, Button3 } from "assets/styles/global";

export const EssayGradesContainer = styled.div`
    ${CardContainer} {
        padding: 0;
    }
`;

export const EssayGradesHeader = styled.header`
    align-items: baseline;
    border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.small};

    strong {
        color: ${theme.colors.base[500]};
    }

    .grades__text {
        ${BodyText2};
        color: ${theme.colors.base[300]};
        display: block;

        strong {
            ${Button2};
        }
    }

    .grades__comments {
        ${Micro};
        color: ${theme.colors.base[300]};
        display: block;

        strong {
            ${Label};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        .grades__text {
            ${BodyText1};

            strong {
                ${TitleH3};
            }
        }

        .grades__comments {
            ${BodyText2};

            strong {
                ${Button3};
            }
        }
    }
`;
