import React, { useMemo, useCallback, useEffect, useState } from "react";

// Dependencies
import { Col, Grid, Row } from "components/Grid";

// Components
import Spinner from "components/Spinner";
import RadioGroup from "components/RadioGroup";
import PreviousExamCard from "./components/Card";
import Header from "./components/Header";

// Helpers
import { ExamFilter } from "enums/ExamFilter";
import useIsMobile from "hooks/use-is-mobile";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { IPreviousExamState, IPreviousExamActions } from "store/interfaces/IPreviousExam";

// Assets
import { BreakPoints } from "assets/styles/settings";
import { PreviousExamScreenContent, PreviousExamScreenContainer, PreviousExamScreenLoading } from "./styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as answerCardActions } from "store/ducks/answerCard";
import { Creators as previousExamActions } from "store/ducks/previousExam";
import { IReduxStore } from "interfaces/IReduxStore";

interface IExamScreenProps {
    previousExamActions: IPreviousExamActions;
    previousExam: IPreviousExamState;
}

const PreviousExamScreen = ({ previousExamActions, previousExam }: IExamScreenProps) => {
    const [filter, setFilter] = useState<ExamFilter>(ExamFilter.Contest);

    const { isLoading } = previousExam;

    const isMobile = useIsMobile(BreakPoints.medium);

    const groupOptions = useMemo(
        () => [
            {
                value: ExamFilter.Contest,
                label: "Por concurso"
            },
            {
                value: ExamFilter.Year,
                label: "Por ano"
            }
        ],
        []
    );

    const requestExams = useCallback(() => {
        const payload: IPayloadRequest<any> = {
            method: "GET",
            endpoint: filter === ExamFilter.Contest ? `/student/exam/previous/contest` : `/student/exam/previous/year`
        };

        previousExamActions.getPreviousExamRequest(payload);
    }, [previousExamActions, filter]);

    useEffect(() => requestExams(), [requestExams]);

    const memoListExams = useMemo(() => {
        try {
            if (!previousExam.items.length) {
                return <p data-test-id="empty-previous-exams">Não existem provas cadastradas.</p>;
            }

            return (
                <PreviousExamScreenContent>
                    {previousExam.items.map(({ name, items, year, id }, index) => {
                        return <PreviousExamCard id={id} key={`previous_group_${index}`} year={year} name={name} items={items} filterBy={filter} />;
                    })}
                </PreviousExamScreenContent>
            );
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [previousExam.items, filter]);

    const memoLoading = useMemo(
        () => (
            <PreviousExamScreenLoading>
                <Spinner fixed={false} />
            </PreviousExamScreenLoading>
        ),
        []
    );

    const memoDesktopFilter = useMemo(() => {
        if (isMobile) {
            return null;
        }

        return (
            <Col xs={3}>
                <RadioGroup items={groupOptions} onClick={setFilter} activeItem={filter} title="Ordenar por:" />
            </Col>
        );
    }, [filter, groupOptions, isMobile]);

    return (
        <PreviousExamScreenContainer>
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <Header groupOptions={groupOptions} currentFilter={filter} onChangeFilter={setFilter} isMobile={isMobile} />
                    </Col>

                    <Col xs={12} md={9}>
                        {isLoading ? memoLoading : memoListExams}
                    </Col>
                    {memoDesktopFilter}
                </Row>
            </Grid>
        </PreviousExamScreenContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    previousExamActions: bindActionCreators(previousExamActions, dispatch),
    answerCardActions: bindActionCreators(answerCardActions, dispatch)
});

const mapStateToProps = ({ previousExam }: IReduxStore) => ({
    previousExam
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(PreviousExamScreen);
