import styled from "styled-components";
import { BadgeContainer } from "components/Badge/styles";
import { theme } from "config/theme";

export const ExerciseListQuestionDiscursiveAnswerDivider = styled.span`
    background: ${theme.colors.base[100]};
    height: 1px;
    align-self: stretch;
    margin-bottom: ${theme.spacing.small};
`;

export const ExerciseListQuestionDiscursiveAnswerContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${BadgeContainer} {
        align-self: flex-start;
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ExerciseListQuestionDiscursiveAnswerText = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
    white-space: break-spaces;

    span {
        color: ${theme.colors.base[300]};
    }

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExerciseListQuestionDiscursiveAnswerTeacherFeedback = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.base[300]};
    margin-bottom: ${theme.spacing.small};

    font-size: ${theme.typography.sizes.smallX};

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;
