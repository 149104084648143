// Dependencies
import React, { FunctionComponent, Fragment } from "react";

// Types
import { ActivitySelectorProps, ActivitySelectorItemProps } from "./activity-selector.types";

// Styles
import {
    SelectorContainer,
    SelectorContent,
    SelectorContentWrapper,
    SelectorItemContainer,
    SelectorItemDescription,
    SelectorItemIcon,
    SelectorItemTitle,
    UnderSelectMessage,
    UnderSelectMessageIcon,
    UnderSelectMessageWrapper
} from "./activity-selector.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";

// Assets
import fail from "assets/img/fail.svg";

// Utils
import { isThisBrowser } from "helpers/is-this-browser";

export const ActivitySelector: FunctionComponent<ActivitySelectorProps> = ({ itemsCount, errorMessage, selectorItemsCompositions }) => (
    <SelectorContainer>
        <SelectorContent itemsCount={itemsCount}>{selectorItemsCompositions}</SelectorContent>

        <ConditionallyRender
            shouldRender={!!errorMessage}
            content={
                <Fragment>
                    <Spacing size={4} direction="vertical" />

                    <UnderSelectMessageWrapper>
                        <UnderSelectMessageIcon src={fail} />

                        <Spacing size={4} direction="horizontal" />

                        <UnderSelectMessage>{errorMessage}</UnderSelectMessage>
                    </UnderSelectMessageWrapper>
                </Fragment>
            }
        />
    </SelectorContainer>
);

export const ActivitySelectorItem: FunctionComponent<ActivitySelectorItemProps> = ({
    headerIcon,
    isActive,
    title,
    description,
    alignment = "start",
    variant = "neutral",
    guidance = "vertical",
    inputComposition,
    additionalContentComposition,
    handleClick
}) => {
    const isSafari = isThisBrowser("Safari");

    return (
        <SelectorItemContainer
            variant={variant}
            alignment={alignment}
            guidance={guidance}
            isActive={!!isActive}
            hasAction={!!handleClick}
            onClick={handleClick}
            hasFullyAdaptativeHeight={!isSafari}
        >
            {inputComposition}

            <SelectorItemIcon src={headerIcon} />

            <SelectorContentWrapper>
                <SelectorItemTitle>{title}</SelectorItemTitle>

                <ConditionallyRender shouldRender={!!description} content={<SelectorItemDescription>{description}</SelectorItemDescription>} />
            </SelectorContentWrapper>

            {additionalContentComposition}
        </SelectorItemContainer>
    );
};
