// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ButtonDaySelectStyleProps = {
    isSelected: boolean;
    svgSize: string;
};

export const Container = styled.div`
    display: block;
    width: 63px;
    height: 70px;
`;

export const ButtonDaySelect = styled.button<ButtonDaySelectStyleProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${theme.colors.base[300]};

    &:hover {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

    .periodDay {
        font-size: 12px;
        font-weight: 400;
        font-style: normal;
        font-family: "Nunito", sans-serif;

        line-height: 1.333;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: ${theme.colors.base[300]};
    }

    svg {
        margin-bottom: 4px;
    }


    ${({ isSelected }) =>
        !!isSelected &&
        css`
            background-color: ${theme.colors.base[200]};

            .periodDay {
                color: ${theme.colors.base[400]};
            }
        `}

    ${({ svgSize }) =>
        svgSize === "manhã" &&
        css`
            svg {
                width: 28px;
                height: 21px;
            }
        `}

    ${({ svgSize }) =>
        svgSize === "tarde" &&
        css`
            svg {
                width: 28px;
                height: 28px;
            }
        `}

    ${({ svgSize }) =>
        svgSize === "noite" &&
        css`
            svg {
                width: 25px;
                height: 28px;
            }
        `}
    `;
