// Dependencies
import React, { FunctionComponent, useEffect } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as activityActions } from "store/ducks/activity";

// Services
import history from "services/history";

// Components
import { ActivityBoard } from "components/ActivityBoard";
import { ActivityChip } from "components/ActivityChip";
import { ActivityStudentItem, ActivityStudentItemSkeleton, ActivityStudentHeaderItem } from "components/ActivityStudentItem";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Styles
import {
    StudentEmptyStateWrapper,
    StudentEmptyStateImage,
    StudentEmptyStateTitleWrapper,
    StudentEmptyStateTitle,
    StudentEmptyStateSubtitle,
    ActionButtonIcon,
    ActivityStudentItemsWrapper
} from "./activity-student-dashboard.styles";

// Types
import { ActivityTypeEnum, Activity } from "store/interfaces/activity";

// Assets
import emptyStateImage from "assets/img/activity-empty-state.png";
import chevronLeft from "assets/img/chevron-left.svg";

// Utils
import { getActivityStatus } from "./activity-student-dashboard.helpers";
import { ternary } from "utils/ternary";

export const ActivityStudentDashboard: FunctionComponent = () => {
    const { activities, courseSlug } = useSelector(({ activity, course }: IReduxStore) => ({
        activities: activity.studentDashboard.activities,
        courseSlug: course.slug
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(activityActions.getStudentDashboardActivitiesRequest());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearStudentDashboard());
        };
    }, [dispatch]);

    const handleActivityClick = (activity: Activity, shouldRedirectToSubmissionDetails: boolean, shouldRedirectToActivityExecution: boolean) => {
        const activityType = ternary([
            [activity.type === "ExerciseList", "lista-de-exercicios"],
            [activity.type === "Composition", "discursiva"],
            [activity.type === "Upload", "envio-de-arquivo"],
            [true, "lista-de-exercicios"]
        ]);

        if (shouldRedirectToSubmissionDetails && !!activity.submission?.id) {
            history.push({
                // TODO
                pathname: `/app/curso/${courseSlug}/atividades/${activityType}/${activity.id}/entrega/${activity.submission.id}`
            });
        }

        if (shouldRedirectToActivityExecution) {
            history.push({
                pathname: `/app/curso/${courseSlug}/atividades/${activityType}/${activity.id}/execucao`
            });
        }
    };

    return (
        <ActivityBoard
            sectionTitle="Atividades"
            sectionDescription="Acompanhe sua lista de atividades e entregas"
            shouldDisplayEmptyState={!activities.isLoading && !activities.data?.length}
            emptyStateComposition={
                <StudentEmptyStateWrapper>
                    <StudentEmptyStateImage src={emptyStateImage} />

                    <StudentEmptyStateTitleWrapper>
                        <StudentEmptyStateTitle>Não encontramos nenhuma atividade!</StudentEmptyStateTitle>

                        <StudentEmptyStateSubtitle>
                            Assim que novas atividades forem incluídas, você receberá uma notificação.
                        </StudentEmptyStateSubtitle>
                    </StudentEmptyStateTitleWrapper>
                </StudentEmptyStateWrapper>
            }
            activitiesContentCompositions={
                <ActivityStudentItemsWrapper>
                    <ActivityStudentHeaderItem />

                    <ConditionallyRender
                        shouldRender={activities.isLoading}
                        content={Array.from({ length: 12 }).map((_, index) => (
                            <ActivityStudentItemSkeleton
                                key={`activity-student-item-skeleton-${index}`}
                                actionIconElement={<ActionButtonIcon src={chevronLeft} />}
                            />
                        ))}
                    />

                    <ConditionallyRender
                        shouldRender={!activities.isLoading}
                        content={activities.data?.map((activity) => {
                            const { shouldRedirectToActivityExecution, shouldRedirectToSubmissionDetails, label, variant } = getActivityStatus(
                                activity
                            );

                            return (
                                <ActivityStudentItem
                                    key={`activity-student-item-${activity.id}`}
                                    title={activity.title}
                                    deadline={activity.dueDate ?? "-"}
                                    type={ActivityTypeEnum[activity.type]}
                                    score={`${activity.score ?? "-"}`}
                                    hasAction={shouldRedirectToSubmissionDetails || shouldRedirectToActivityExecution}
                                    statusChipComposition={<ActivityChip variant={variant} label={label ?? ""} />}
                                    handleClick={() =>
                                        handleActivityClick(activity, shouldRedirectToSubmissionDetails, shouldRedirectToActivityExecution)
                                    }
                                    actionIconElement={<ActionButtonIcon src={chevronLeft} />}
                                />
                            );
                        })}
                    />
                </ActivityStudentItemsWrapper>
            }
        />
    );
};
