// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, ContentLabel } from "./activity-empty-state.styles";

// Types
import { ActivityEmptyStateProps } from "./activity-empty-state.types";

// Utils
export const ActivityEmptyState: FunctionComponent<ActivityEmptyStateProps> = ({ content }) => {
    return (
        <Container>
            <ContentLabel>{content}</ContentLabel>
        </Container>
    );
};
