// Dependencies
import React, { FunctionComponent, Fragment } from "react";

// Styles
import {
    ActivityStudentGridCellContainer,
    ActivityStudentGridCellText,
    ActivityStudentGridContainer,
    ActivityStudentGridRowContainer,
    RowWrapper,
    Divider
} from "./activity-student-table.styles";

// Types
import { ActivityStudentGridCellProps, ActivityStudentGridProps, ActivityStudentGridRowProps } from "./activity-student-table.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const ActivityStudentGrid: FunctionComponent<ActivityStudentGridProps> = ({
    headerComposition,
    rowHeaderComposition,
    rowItemCompositions
}) => {
    return (
        <ActivityStudentGridContainer>
            {headerComposition}

            <ScreenWidthRender
                actionAfterRenderingWidth="show"
                renderingWidth={1024}
                haveFullyAdaptativeWidth
                content={
                    <Fragment>
                        {rowHeaderComposition}

                        <Divider />
                    </Fragment>
                }
            />

            <RowWrapper>{rowItemCompositions}</RowWrapper>
        </ActivityStudentGridContainer>
    );
};

export const ActivityStudentGridRow: FunctionComponent<ActivityStudentGridRowProps> = ({ hasBorderBottom, cellCompositions, handleClick }) => {
    return (
        <ActivityStudentGridRowContainer hasBorderBottom={!!hasBorderBottom} hasCursorPointer={!!handleClick} onClick={handleClick}>
            {cellCompositions}
        </ActivityStudentGridRowContainer>
    );
};

export const ActivityStudentGridCell: FunctionComponent<ActivityStudentGridCellProps> = ({ text, element, isFullyAdaptative, renderingWidth }) => {
    return (
        <ActivityStudentGridCellContainer isFullyAdaptative={!!isFullyAdaptative} renderingWidth={renderingWidth}>
            <ConditionallyRender shouldRender={!!element} content={element} />

            <ConditionallyRender shouldRender={!element && !!text} content={<ActivityStudentGridCellText>{text}</ActivityStudentGridCellText>} />
        </ActivityStudentGridCellContainer>
    );
};
