import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText2 } from "assets/styles/global";
import { MenuProfileDropdown } from "screens/Shell/components/Header/components/MenuProfileButton/styles";
import { MediaQueries } from "assets/styles/settings";

export const ForumQuestionDetailsContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
`;

export const ForumQuestionDetailsWrapper = styled.div`
    width: calc(100% - 21px);
`;

export const ForumQuestionDetailsHelper = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    display: block;
    font-size: ${theme.typography.sizes.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 22px;
`;

export const ForumQuestionDetailsNavWrapper = styled.div`
    margin-right: -${theme.spacing.smallX};
    position: relative;

    > button {
        padding: 0 ${theme.spacing.smallX};
    }
`;

export const ForumQuestionDetailsNav = styled(MenuProfileDropdown)`
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    border: unset;
    padding: ${theme.spacing.smallX};
    width: unset !important;

    button {
        align-items: center;
        color: ${theme.colors.base[300]};
        display: flex;
        justify-content: space-between;
        max-width: 190px;

        .ico-wrapper {
            display: flex;
            justify-content: center;
            margin-right: ${theme.spacing.smallX};
            width: 18px;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #e2e5ef;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        min-width: 190px;
    }
`;
