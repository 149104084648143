import styled from "styled-components/macro";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const SubjectScreenDetailsModulesContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-size: 2px 8px;
    background-image: linear-gradient(to bottom, ${theme.colors.base[200]} 50%, #ffffff 50%);
    background-repeat: repeat-y;
    background-position-x: 7px;
    position: relative;
    margin-bottom: 100px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 60vh;
    }
`;
