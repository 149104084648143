import { BodyText1, BodyText2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import { ProjectTagsContainer } from "screens/Projects/components/Tags/styles";
import styled from "styled-components";

export const ProjectFeaturesContainer = styled.div`
    padding: 0 ${theme.spacing.smallX};

    ${ProjectTagsContainer} {
        border-bottom: 1px solid ${theme.colors.base[100]};
        margin-bottom: ${theme.spacing.small};
        padding: 0;
        padding-bottom: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 0;
    }
`;

export const ProjectFeaturesTitle = styled.strong`
    ${BodyText2};
    display: block;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText1};
    }
`;
