// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityAlertProps } from "./activity-alert.types";

// Styles
import { Container, Label, LabelWrapper } from "./activity-alert.styles";

export const ActivityAlert: FunctionComponent<ActivityAlertProps> = ({ firstLabel, secondLabel, actionButtonComposition }) => {
    return (
        <Container>
            <LabelWrapper>
                <Label>{firstLabel}</Label>

                <Label>{secondLabel}</Label>
            </LabelWrapper>

            {actionButtonComposition}
        </Container>
    );
};
