import { theme } from "config/theme";
import styled from "styled-components";

export const ProgressBarContainer = styled.div``;

export const ProgressBarLine = styled.div`
    background: ${({ color }) => `${color};`};
    color: ${theme.colors.white};
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    height: 13px;
    text-align: right;
    transition: width 0.45s ease;
`;

export const ProgressBarMobileWrapper = styled.div`
    bottom: 0px;
    height: 13px;
    left: 0px;
    position: absolute;
    width: 100%;
`;

export const ProgressBarTabletWrapper = styled.div`
    background-color: ${theme.colors.base[200]};
    border-radius: 8px;
    height: 8px;
    overflow: hidden;
    width: 100%;

    ${ProgressBarLine} {
        border-radius: 8px;
        height: 100%;
    }
`;

export const ProgressBarLabel = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;

    p {
        color: ${theme.colors.base[400]};
        font-weight: bold;
        font-size: ${theme.typography.sizes.small};
        line-height: 1;
    }
`;
