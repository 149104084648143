// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: #ddd2df;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        padding: 20px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const TitleIndicator = styled.div`
    display: flex;
    width: 4px;
    height: calc(100% - 8px);
    flex-shrink: 0;
    border-radius: 5px;
    background-color: #571d5d;

    @media (min-width: 1024px) {
        height: calc(100% - 12px);
    }
`;

export const Title = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #571d5d;

    @media (min-width: 1024px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const Subtitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #505462;

    @media (min-width: 1024px) {
        font-size: 16px;
    }
`;
