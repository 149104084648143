// Dependencies
import React, { FunctionComponent, ReactNode, useState } from "react";

// Styles
import { Container } from "./styles";

// Assets
import vector from "./assets/vector.svg";

type WebpDropdownFullProps = {
    headerComposition: ReactNode;
    contentComposition: ReactNode;
};

export const WebpDropdownFull: FunctionComponent<WebpDropdownFullProps> = ({ contentComposition, headerComposition }) => {
    const [isActive, setIsActive] = useState(true);

    const handleClickOpen = () => {
        setIsActive(!isActive);
    };

    return (
        <Container isOpen={isActive}>
            <div className="conent-header" onClick={handleClickOpen}>
                {headerComposition}

                <button className="header-button">
                    <img src={vector} alt="vector icon" />
                </button>
            </div>

            <div className="content">{contentComposition}</div>
        </Container>
    );
};
