// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./loader.styles";

export const Loader: FunctionComponent = () => {
    return (
        <Container className="webapp-loader">
            <div className="wrapper">
                <div />

                <div />

                <div>
                    <div />
                </div>

                <div>
                    <div />
                </div>
            </div>
        </Container>
    );
};
