import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { ProjectFeaturesContainer, ProjectFeaturesTitle } from "./styles";
import { ProjectTagsContainer } from "screens/Projects/components/Tags/styles";
import { ProjectTagsItem, ProjectResume } from "screens/Projects/Details/styles";
import { ProjectResumeContainer, ProjectResumeWrapper, ProjectResumeTitle } from "screens/Projects/components/Resumes/styles";

const ProjectFeaturesPlaceholder = () => (
    <ProjectFeaturesContainer>
        <ProjectFeaturesTitle>
            <Skeleton width="80%" height="20px" />
        </ProjectFeaturesTitle>
        <ProjectTagsContainer>
            {Array.from(Array(3)).map((_, index) => (
                <ProjectTagsItem key={index}>
                    <Skeleton width="21px" height="21px" style={{ marginRight: 6 }} />
                    <Skeleton width="70px" height="15px" />
                </ProjectTagsItem>
            ))}
        </ProjectTagsContainer>
        <ProjectResumeContainer>
            <ProjectResumeWrapper>
                <ProjectResumeTitle>
                    <Skeleton width="50%" height="20px" />
                </ProjectResumeTitle>
                <ProjectResume className="ck-content">
                    {Array.from(Array(3)).map((_, index) => (
                        <Skeleton width="60%" height="20px" key={index} style={{ marginBottom: 10 }} />
                    ))}
                </ProjectResume>
            </ProjectResumeWrapper>
        </ProjectResumeContainer>
    </ProjectFeaturesContainer>
);

export default ProjectFeaturesPlaceholder;
