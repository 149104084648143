import { EssayComplexity } from "store/interfaces/IEssay";
import { FlatVariant } from "utils/types/Variant";

export const themeComplexity = new Map<EssayComplexity, string>([
    [1, "Baixa"],
    [2, "Média"],
    [3, "Alta"]
]);

export const EssayStatusColor: Array<{ color: FlatVariant | "secondary"; label: string }> = [
    { color: "secondary", label: "Rascunho" },
    { color: "info", label: "Enviada" },
    { color: "warning", label: "Em Correção" },
    { color: "success", label: "Corrigida" }
];
