import styled from "styled-components";

export const RadioGroupWrapper = styled.div`
    .radio-answer-hit {
        margin-top: 1.5rem;
        justify-content: flex-start;
        align-items: center;

        webp-radio-input + webp-radio-input {
            margin-left: 3rem;
        }
    }
`;
