import React, { memo } from "react";

// Components
import WizardNav, { WizardNavProps } from "./nav";

// Assets
import { WizardContainer, WizardContent } from "./styles";

export interface WizardProps<T> extends WizardNavProps<T> {
    children: React.ReactNode;
    hideNavigation?: boolean;
}

const Wizard = <T,>(props: WizardProps<T>) => {
    return (
        <WizardContainer>
            {!props.hideNavigation && (
                <WizardNav completeds={props.completeds} current={props.current} options={props.options} onChangeStep={props.onChangeStep} />
            )}
            <WizardContent>{props.children}</WizardContent>
        </WizardContainer>
    );
};

const typedMemo: <T>(c: T) => T = memo;

export default typedMemo(Wizard);
