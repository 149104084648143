import React, { useRef, useState, useCallback, lazy, Suspense, useEffect, memo } from "react";

// Components
import TabRow from "components/TabRow";
import Forbidden from "../components/Forbidden";
import ModulesListPlaceholder from "./placeholder";
import ErrorBoundary from "components/ErrorBoundary";
import OverviewPlaceholder from "./components/Overview/placeholder";
import ProjectFeaturesPlaceholder from "./components/Features/placeholder";
import ProjectStudyingContentPlaceholder from "./components/Content/placeholder";
import PageLoader from "components/PageLoader";

// Helpers
import retry from "utils/retryLoad";
import useIsMobile from "hooks/use-is-mobile";
import { IProjectDetails } from "interfaces/IProjectDetails";
import { IProjectNavigator } from "interfaces/IProjectNavigator";
import { IProjectWatchContentPayload } from "interfaces/IProject";
import { IProjectModuleSectionContentChange } from "interfaces/IProjectModuleSectionContentChange";
import { IReduxStore } from "interfaces/IReduxStore";
import { RouteComponentProps } from "react-router-dom";
import { IProjectActions } from "store/interfaces/IProject";

// Assets
import { Col, Grid, Row } from "components/Grid";
import { NotFoundContent } from "./components/Content/styles";
import { ProjectModulesListContainer } from "./components/ModulesList/styles";
import { ProjectContentWrapper, ProjectStudyingContainer, ProjectTabWrapper } from "./styles";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as ProjectActions } from "store/ducks/project";

interface IProjectStudyingScreenProps {
    project: IProjectDetails;
    navigator: IProjectNavigator;
    onContentChange({ module, section, content }: IProjectModuleSectionContentChange): void;
    onCompleteContent(idProject: number, payload: IProjectWatchContentPayload): void;
}

const Overview = lazy(() => retry(() => import("./components/Overview")));
const ModulesList = lazy(() => retry(() => import("./components/ModulesList")));
const ProjectFeatures = lazy(() => retry(() => import(`./components/Features`)));
const ProjectStudyingContent = lazy(() => retry(() => import("./components/Content")));

const tabOptions = [
    { id: 0, value: "Módulos" },
    { id: 1, value: "Visão Geral" }
];

const ProjectStudyingScreen = ({ project, navigator, onContentChange, onCompleteContent }: IProjectStudyingScreenProps) => {
    const isMobileOrTablet = useIsMobile();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [isContentLoading, setIsContentLoading] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isContentLoading) {
            setIsContentLoading(false);
        }
    }, [isContentLoading, navigator.currentContent]);

    const handleContentChange = useCallback(
        ({ module, section, content }: IProjectModuleSectionContentChange) => {
            if (containerRef.current) {
                containerRef.current.scrollTop = 0;
            }

            setIsContentLoading(true);
            onContentChange({ module, section, content });
        },
        [onContentChange]
    );

    const handleCompleteContent = useCallback((payload) => onCompleteContent(project.id, payload), [onCompleteContent, project.id]);

    return (
        <ProjectStudyingContainer ref={containerRef}>
            {isMobileOrTablet && (
                <ErrorBoundary
                    component={
                        <NotFoundContent bg={project.urlImage}>
                            <p>Ocorreu um erro ao carregar o conteúdo</p>
                        </NotFoundContent>
                    }
                >
                    <Suspense fallback={<ProjectStudyingContentPlaceholder />}>
                        <ProjectStudyingContent isLoading={isContentLoading} />
                    </Suspense>
                </ErrorBoundary>
            )}
            {!project.modules.length ? (
                <Forbidden message="Não existe conteúdo cadastrado para este projeto ainda." />
            ) : (
                <ProjectContentWrapper>
                    <Grid fluid>
                        <Row>
                            <Col xs={12} md={8}>
                                {!isMobileOrTablet && (
                                    <ErrorBoundary
                                        component={
                                            <NotFoundContent bg={project.urlImage}>
                                                <p>Ocorreu um erro ao carregar o conteúdo</p>
                                            </NotFoundContent>
                                        }
                                    >
                                        <Suspense fallback={<ProjectStudyingContentPlaceholder />}>
                                            <ProjectStudyingContent isLoading={isContentLoading} />
                                        </Suspense>
                                    </ErrorBoundary>
                                )}
                                <ErrorBoundary component={<p>Ocorreu um erro</p>}>
                                    <Suspense fallback={<OverviewPlaceholder />}>
                                        <Overview name={project.name} progress={project.stats.percent} />
                                    </Suspense>
                                </ErrorBoundary>
                                {!isMobileOrTablet && (
                                    <ErrorBoundary
                                        component={
                                            <ProjectModulesListContainer>
                                                Um erro aconteceu. Recarregue a página para tentar novamente.
                                            </ProjectModulesListContainer>
                                        }
                                    >
                                        <Suspense fallback={<ProjectFeaturesPlaceholder />}>
                                            <ProjectFeatures resumes={project.resumes} tags={project.tags} />
                                        </Suspense>
                                    </ErrorBoundary>
                                )}
                            </Col>
                            <Col xs={12} md={4}>
                                <ProjectTabWrapper>
                                    {isMobileOrTablet && <TabRow activeIndex={activeTabIndex} items={tabOptions} onChangeTab={setActiveTabIndex} />}
                                    {activeTabIndex > 0 ? (
                                        <ErrorBoundary
                                            component={
                                                <ProjectModulesListContainer>
                                                    Um erro aconteceu. Recarregue a página para tentar novamente.
                                                </ProjectModulesListContainer>
                                            }
                                        >
                                            <Suspense fallback={<ModulesListPlaceholder />}>
                                                <ProjectFeatures resumes={project.resumes} tags={project.tags} />
                                            </Suspense>
                                        </ErrorBoundary>
                                    ) : (
                                        <ErrorBoundary component={<ProjectModulesListContainer>Ocorreu um erro</ProjectModulesListContainer>}>
                                            <Suspense fallback={<ModulesListPlaceholder />}>
                                                <ModulesList
                                                    modules={project.modules}
                                                    navigator={navigator}
                                                    onContentChange={handleContentChange}
                                                    onCompleteContent={handleCompleteContent}
                                                />
                                            </Suspense>
                                        </ErrorBoundary>
                                    )}
                                </ProjectTabWrapper>
                            </Col>
                        </Row>
                    </Grid>
                </ProjectContentWrapper>
            )}
        </ProjectStudyingContainer>
    );
};

const MemoizedProjectStudyingScreen = memo(ProjectStudyingScreen);

interface IProjectStudyingScreenWrapperProps {
    brandSlug: string;
    courseSlug: string;
    errorMessage: string;
    isLoading: boolean;
    project: IProjectDetails;
    projectActions: IProjectActions;
    match: any;
    navigator: IProjectNavigator;
    onContentChange({ module, section, content }: IProjectModuleSectionContentChange): void;
    onCompleteContent(idProject: number, payload: IProjectWatchContentPayload): void;
}

const ProjectStudyingScreenWrapper = (props: IProjectStudyingScreenWrapperProps & RouteComponentProps<{ id?: string }>) => {
    const { brandSlug, courseSlug, errorMessage, isLoading, match, projectActions, ...rest } = props;

    useEffect(() => {
        if (match.params.id) {
            projectActions.getProjectForStudyRequest({ id: match.params.id });
        }

        return () => projectActions.clearProjects();
    }, [match.params.id, projectActions]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!props.project.isPublished) {
        return <Forbidden message="Este projeto ainda não foi publicado." />;
    }

    if (errorMessage?.length > 0) {
        return <Forbidden message={errorMessage} />;
    }

    return <MemoizedProjectStudyingScreen {...rest} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    const projectActions = bindActionCreators(ProjectActions, dispatch);

    return {
        projectActions,
        onCompleteContent: (idProject: number, payload: IProjectWatchContentPayload) => {
            projectActions.setContentAsCompletedRequest({ idProject, bodyRequest: payload });
        },
        onContentChange: ({ module, section, content }: IProjectModuleSectionContentChange) => {
            projectActions.setNavigator({
                currentModule: module,
                currentSection: section,
                currentContent: content
            });
        }
    };
};

const mapStateToProps = ({ credentials, course, project }: IReduxStore) => ({
    brandSlug: credentials.student.brand.slug,
    courseSlug: course.slug,
    navigator: project.navigator,
    project: project.currentProject,
    isLoading: project.isLoading,
    errorMessage: project.error.message
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStudyingScreenWrapper);
