import React, { memo } from "react";
import {
    ResolutionExerciseListQuestionCol,
    ResolutionExerciseListQuestionTitle,
    ResolutionExerciseListQuestionEnunciation,
    ResolutionExerciseListQuestionAlternative,
    ResolutionExerciseListQuestionAlternativeEnunciation,
    ResolutionExerciseListQuestionWrapp
} from "../../styles";
import Enunciation from "components/Enunciation";
import Letter from "components/Letter";
import { IQuestionAlternative } from "interfaces/IQuestionAlternative";
import getLetterByNumber from "utils/getLetterByNumber";

interface IProps {
    enunciation: string;
    correctAnswerIndex: number;
    correctAnswer?: IQuestionAlternative;
    showAnswer: boolean;
}

const ResolutionExerciseListQuestion = memo(({ enunciation = "", correctAnswerIndex, correctAnswer, showAnswer = false }: IProps) => {
    return (
        <ResolutionExerciseListQuestionCol md={5} xs={12}>
            <ResolutionExerciseListQuestionWrapp id="ResolutionExerciseListQuestion">
                <ResolutionExerciseListQuestionTitle>Questão:</ResolutionExerciseListQuestionTitle>
                <ResolutionExerciseListQuestionEnunciation>
                    <Enunciation enunciation={enunciation} />
                </ResolutionExerciseListQuestionEnunciation>

                {showAnswer && (
                    <>
                        <ResolutionExerciseListQuestionTitle>Resposta correta:</ResolutionExerciseListQuestionTitle>
                        <ResolutionExerciseListQuestionAlternative>
                            {correctAnswer && (
                                <>
                                    <Letter letter={getLetterByNumber(correctAnswerIndex)} isCorrect={true} />
                                    <ResolutionExerciseListQuestionAlternativeEnunciation>
                                        <Enunciation enunciation={correctAnswer.enunciation} />
                                    </ResolutionExerciseListQuestionAlternativeEnunciation>
                                </>
                            )}
                        </ResolutionExerciseListQuestionAlternative>
                    </>
                )}
            </ResolutionExerciseListQuestionWrapp>
        </ResolutionExerciseListQuestionCol>
    );
});

export default ResolutionExerciseListQuestion;
