import React from "react";

// Dependencies
import { useDispatch } from "react-redux";

// Helpers
import { IMaterial } from "interfaces/IMaterial";
import { IModalActions } from "store/interfaces/IModal";

// Assets
import { SelectModalListContainer, SelectModalListWrap } from "assets/styles/global";

interface IProps {
    data: {
        type: string;
        LO: any[];
    };
    modalActions: IModalActions;
}

const SelectBooklet = ({ data, modalActions }: IProps) => {
    const dispatch = useDispatch();

    const handleClick = (material: IMaterial) => {
        const { url, linkHTML } = material;

        dispatch({ type: "CLOSE_MODAL" });

        if (linkHTML) {
            return modalActions.openModal("booklet", { linkHTML, title: material.title });
        }

        return window.open(url, "_blank");
    };

    return (
        <SelectModalListContainer>
            <h3>Escolha uma apostila</h3>
            <SelectModalListWrap>
                {data.LO.map((item) => {
                    return (
                        <button key={item.learningObject.id} onClick={() => handleClick(item.learningObject.material)}>
                            {item.learningObject.material.title}
                        </button>
                    );
                })}
            </SelectModalListWrap>
        </SelectModalListContainer>
    );
};

export default SelectBooklet;
