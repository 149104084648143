import { MediaQueries } from "assets/styles/settings";
import Card from "components/Card";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExerciseMainChartCard = styled(Card)`
    display: flex;
    flex-direction: column;

    padding: 24px;
    background-color: ${theme.colors.white};
    border-radius: 4px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 100%;
    }
`;

export const ExerciseMainHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0;

    h3 {
        font-family: "Nunito Sans", sans-serif;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 20px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const ExerciseDetailsWrap = styled.ul`
    display: none;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
        padding: ${theme.spacing.small};
    }
`;

export const ExerciseDetailItem = styled.li`
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[300]};
    margin-left: ${theme.spacing.small};

    &:first-child {
        margin-left: 0px;
    }
`;

export const ExerciseMainLegends = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 100%;
        width: fit-content;

        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-left: auto;
    }
`;

export const ExerciseMainLegendItem = styled.span<{ variant: "success" | "danger" | "warning" }>`
    display: flex;
    width: 100%;
    align-items: center;
    margin: ${theme.spacing.smallXX} ${theme.spacing.smallX};
    font-family: "Nunito Sans", sans-serif;
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[400]};
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;

    &::before {
        content: " ";
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: ${theme.spacing.smallXX};

        background: ${({ variant }) => {
            if (variant === "success") {
                return theme.colors.system.success[200];
            }

            if (variant === "danger") {
                return theme.colors.system.danger[200];
            }

            return theme.colors.secondary[300];
        }};
    }
`;

export const ExerciseMainWrap = styled.div<{ isMobile: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const ExerciseMainChartBar = styled.div`
    display: flex;
    padding: 0;
    margin: 0;

    .VictoryContainer {
        margin: 0 auto;
        font-size: ${theme.typography.sizes.smallX};
    }
`;

export const ExerciseSeparator = styled.span`
    width: 100%;
    height: 0.5px;
    background-color: ${theme.colors.base[100]};
    margin: ${theme.spacing.small} 0px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: none;
    }
`;

export const ExerciseContentListContainer = styled.ul`
    margin-top: ${theme.spacing.smallXX};
`;

export const ExerciseContentListItem = styled.li`
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.sizes.smallX};
    line-height: 22px;
    color: ${theme.colors.base[300]};
`;

export const ExerciseListDashboardInfo = styled.h3`
    display: flex;

    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.colors.base[400]};

    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
    }
`;
