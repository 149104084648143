// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isOpen: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;
    position: relative;

    .dropdown {
        position: absolute;
        width: 100%;
        transform: translateY(-4px);
        background-color: ${theme.colors.white};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        z-index: 1;
        max-height: 256px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        ${({ isOpen }) =>
            !!isOpen &&
            css`
                width: 13.313em;
                right: -5px;
                opacity: 1;
                pointer-events: all;
                transform: translateY(4px);
            `}

        .webp-search-input {
            --webp-search-input-radius: 4px 4px 0 0;
        }
    }
`;
