import React from "react";

import bell from "./assets/bell.svg";
import bellActive from "./assets/bellActive.svg";

import { Wrapper } from "./styles";
interface IProps {
    onToggleModal: () => void;
    hasNotificationsNotViewed: boolean;
}

const Notification = ({ onToggleModal, hasNotificationsNotViewed = false }: IProps) => {
    const classNameToImage = hasNotificationsNotViewed ? "bell bell-active" : "bell";

    return (
        <Wrapper>
            <button className="button" onClick={onToggleModal}>
                <img className={classNameToImage} src={hasNotificationsNotViewed ? bellActive : bell} alt="bell" />
            </button>
        </Wrapper>
    );
};

export default Notification;
