import React from "react";

// Assets
import * as S from "./styles";
import { BreakPoints } from "assets/styles/settings";

// Components
import ExerciseListDashboardPreviousExamChart from "./components/PreviousExamChart";
import ExerciseListDashboardHeader from "./components/Header";
import { Grid, Row, Col } from "components/Grid";
import Button from "components/Button";
import Typography from "components/Typography";

// Helpers
import pluralize from "utils/pluralize";
import { theme } from "config/theme";
import useIsMobile from "hooks/use-is-mobile";
import { IAnswerCard } from "store/ducks/answerCard";

interface IProps {
    onClickStart(): void;
    listAnswerCards: React.ReactNode;
    blockContent: boolean;
    isLoading: boolean;
    previousExam?: { name: string; id: number };
    hasRealizations: boolean;
    questionsTotal: number;
    previousExamDay: { id: number; title: string };
    userRealizations: IAnswerCard[];
    realizationsAverageHitRate: number;
    onClickdownloadPDF(): void;
    disableButtonPDF: boolean;
}

const PreviousExamDashboard = ({
    onClickStart,
    blockContent,
    previousExam,
    isLoading,
    hasRealizations,
    questionsTotal,
    previousExamDay,
    userRealizations,
    listAnswerCards,
    realizationsAverageHitRate,
    onClickdownloadPDF,
    disableButtonPDF
}: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);

    return (
        <>
            <ExerciseListDashboardHeader
                isExtra={false}
                title="Meus Resultados"
                onClickStartList={onClickStart}
                blockContent={blockContent}
                previousExam={previousExam}
                previousExamDay={previousExamDay}
            />

            <Grid>
                <Row>
                    <Col xs={12} sm={hasRealizations ? 12 : 6} style={{ marginBottom: theme.spacing.small }}>
                        {!isLoading && (
                            <ExerciseListDashboardPreviousExamChart
                                questionsTotal={questionsTotal}
                                userRealizations={userRealizations}
                                realizationsAverageHitRate={realizationsAverageHitRate}
                            />
                        )}

                        <S.ContentButtons>
                            {isMobile && !blockContent && (
                                <>
                                    <Button
                                        id="download-pdf-button"
                                        variant="info"
                                        size="medium"
                                        onClick={onClickdownloadPDF}
                                        data-test-id="exercise-list-download-pdf-button"
                                        disabled={disableButtonPDF}
                                    >
                                        {disableButtonPDF ? "Exportando..." : `Imprimir ${!!previousExam ? "prova" : "lista"}`}
                                    </Button>
                                    <Button variant="info" onClick={onClickStart} block size="medium" style={{ marginBottom: theme.spacing.small }}>
                                        {hasRealizations ? "Refazer lista" : "Resolver lista"}
                                    </Button>
                                </>
                            )}
                        </S.ContentButtons>

                        {hasRealizations && (
                            <Typography variant="p1" color={theme.colors.base[500]}>
                                Você fez essa prova {userRealizations.length} {pluralize(userRealizations.length, "vezes", "vez")}
                            </Typography>
                        )}
                    </Col>
                </Row>

                <Row>
                    {!blockContent && listAnswerCards}

                    <Col xs={12}>
                        <S.ExerciseListDashboardTip>
                            <p>Você pode testar ainda mais seus conhecimentos e ter resultados mais detalhados fazendo as Provas.</p>
                        </S.ExerciseListDashboardTip>
                    </Col>
                </Row>
            </Grid>
        </>
    );
};

export default React.memo(PreviousExamDashboard);
