// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Label } from "./back.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

// Types
import { BackProps } from "./back.types";

export const Back: FunctionComponent<BackProps> = ({ label, iconElement, handleClick }) => {
    return (
        <Container onClick={handleClick}>
            {iconElement}

            <Spacing direction="horizontal" size={8} />

            <Label>{label}</Label>
        </Container>
    );
};
