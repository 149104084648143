// Dependencies
import React, { FunctionComponent, useCallback, useState } from "react";

// Types
import { PerformanceResumeCardProps } from "./performance-resume-card.types";

// Styles
import {
    Container,
    IconOverlay,
    IconWrapper,
    Icon,
    ResumeCardWrapper,
    ResumeCardCounter,
    ResumeCardDescription,
    AreaInformationWrapper,
    AreaInformation,
    AreaInformationTooltip,
    AreaInformationIcon
} from "./performance-resume-card.styles";

// Assets
import info from "assets/img/performance-info.svg";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceResumeCard: FunctionComponent<PerformanceResumeCardProps> = ({
    icon,
    hoursStudied,
    areaDescription,
    areaInformationElement
}) => {
    const [isAreaInformationTooltipVisible, setIsAreaInformationTooltipVisible] = useState(false);

    const handleAreaInformationClick = useCallback(() => {
        if (window.innerWidth >= 1024) return;

        setIsAreaInformationTooltipVisible(!isAreaInformationTooltipVisible);
    }, [isAreaInformationTooltipVisible]);

    return (
        <Container>
            <ScreenWidthRender
                renderingWidth={1024}
                actionAfterRenderingWidth="show"
                content={
                    <IconOverlay>
                        <IconWrapper>
                            <Icon src={icon} />
                        </IconWrapper>
                    </IconOverlay>
                }
            />

            <ResumeCardWrapper>
                <ScreenWidthRender
                    renderingWidth={1024}
                    actionAfterRenderingWidth="hide"
                    content={
                        <IconWrapper>
                            <Icon src={icon} />
                        </IconWrapper>
                    }
                />

                <Spacing size={16} direction="horizontal" />

                <div>
                    <ResumeCardCounter>{leftPad(hoursStudied, 3)}</ResumeCardCounter>
                    <ResumeCardDescription>{areaDescription}</ResumeCardDescription>
                </div>

                <ConditionallyRender
                    shouldRender={!!areaInformationElement}
                    content={
                        <AreaInformationWrapper>
                            <AreaInformation>
                                <AreaInformationTooltip isVisible={isAreaInformationTooltipVisible}>{areaInformationElement}</AreaInformationTooltip>
                                <AreaInformationIcon src={info} onClick={handleAreaInformationClick} />
                            </AreaInformation>
                        </AreaInformationWrapper>
                    }
                />
            </ResumeCardWrapper>
        </Container>
    );
};
