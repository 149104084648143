// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

type WebpProgressBarLabelProps = {
    label: string;
    contentProgressBarLabelComposition: ReactNode;
};

export const WebpProgressBarLabel: FunctionComponent<WebpProgressBarLabelProps> = ({ label, contentProgressBarLabelComposition }) => {
    return (
        <Container className="webp-progress-bar-label">
            {contentProgressBarLabelComposition}
            <p>{label}</p>
        </Container>
    );
};
