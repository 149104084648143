import { theme } from "config/theme";
import { BodyText1 } from "assets/styles/global";
import { SelectLabel } from "components/Select/styles";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";

const LabelStyle = css`
    ${BodyText1};
    color: ${theme.colors.base[500]};
    display: block;
    margin: 0 0 ${theme.spacing.smallX} 0;
`;

export const ForumNewContainer = styled.div`
    ${SelectLabel} {
        ${LabelStyle};
    }

    ${MediaQueries.SMALL} {
        padding-bottom: ${theme.spacing.medium};
    }
`;

export const ForumNewContent = styled.div`
    align-items: center;
    background: #f2f2f4;
    border-radius: ${theme.spacing.smallX};
    box-shadow: ${theme.shadow[1]};
    display: flex;
    padding: ${theme.spacing.medium} ${theme.spacing.large};

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.large};
    }

    ${MediaQueries.SMALL} {
        align-items: flex-start;
        flex-direction: column;
        padding: ${theme.spacing.small};
    }
`;

export const ForumNewFieldLabel = styled.label`
    ${LabelStyle};
`;

export const ForumNewFieldWrapper = styled.div`
    width: 100%;

    .react-select__control {
        border: unset;
        border-radius: ${theme.spacing.smallXX};
        min-height: unset;
        padding: 13px 16px;
        z-index: 11;
    }

    .react-select__value-container {
        padding: 0 16px 0 0;
    }

    .react-select__placeholder,
    .react-select__single-value {
        font-size: ${theme.typography.sizes.smallX};
    }

    .react-select__single-value {
        color: ${theme.colors.base[500]};
    }

    .react-select__menu {
        box-shadow: ${theme.shadow[3]};
        margin: 0;
    }

    &:not(:last-child) {
        margin-right: ${theme.spacing.large};
    }

    ${MediaQueries.SMALL} {
        width: 100%;

        &:not(:last-child) {
            margin: 0 0 ${theme.spacing.small};
        }
    }
`;

export const ForumNewEditorWrapper = styled.div`
    margin-bottom: ${theme.spacing.small};
`;

export const ForumNewAttachmentWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    ${MediaQueries.SMALL} {
        flex-direction: column-reverse;
    }
`;
