import React from "react";
import { WebpCard } from "components/WebpCard";
import { WebpNewCardSelected } from "components/WebpNewCardSelected";
import { ContentTitle, Wrapper } from "./styles";
import data from "./assets/data";

interface IProps {
    handleSelectedReleasePlan: (slug: string, resultRestart: boolean) => void;
    selectedPlan?: string;
}

const ScreenZero = ({ handleSelectedReleasePlan, selectedPlan }: IProps) => {
    const handleClickCard = (slug: string, resultRestart: boolean) => {
        handleSelectedReleasePlan(slug, resultRestart);
    };

    return (
        <Wrapper>
            <WebpCard
                headerComposition={
                    <ContentTitle>
                        <h2 className="title-section">Como você prefere fazer?</h2>
                    </ContentTitle>
                }
                contentComposition={
                    <div className="content-card-restart">
                        {data.map((item) => (
                            <WebpNewCardSelected
                                key={item.id}
                                img={item.image}
                                labelTitle={item.labelTitle}
                                desc={item.description}
                                variant={item.variant as any}
                                active={item.slug === selectedPlan}
                                onClickButton={() => handleClickCard(item.slug, item.resultRestart)}
                            />
                        ))}
                    </div>
                }
            ></WebpCard>
        </Wrapper>
    );
};

export default ScreenZero;
