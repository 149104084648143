import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText2 } from "assets/styles/global";

export const TeacherContainer = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

export const TeacherPicture = styled.figure`
    height: 100%;
    width: 100%;

    img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
    }
`;

export const TeacherPictureWrapper = styled.div`
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 50%;
    box-shadow: ${theme.shadow[1]};
    display: flex;
    height: 28px;
    justify-content: center;
    overflow: hidden;
    width: 28px;
`;

export const TeacherName = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[300]};
    line-height: 1;
`;
