import React, { useCallback, useMemo, useRef, useEffect } from "react";

// Components
import Spinner from "components/Spinner";
import PreviousExamDetailsHeader from "./components/Header";
import PreviousExamDetailsExamDay from "./components/ExamDay";
import ColorTaggedItem from "components/ColorTaggedExerciseList";
import PreviousExamDetailsExamDaySkeleton from "./components/ExamDaySkeleton";
import { Grid, Row } from "components/Grid";

// Redux
import { connect, useSelector } from "react-redux";
import { Creators as ModalActions } from "store/ducks/modal";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as previousExamActions } from "store/ducks/previousExam";

// Helpers
import { IModalActions } from "store/interfaces/IModal";
import { IReduxStore } from "interfaces/IReduxStore";
import { RouteComponentProps } from "react-router-dom";
import { IAnswerCardState } from "store/ducks/answerCard";
import { IPreviousExamState, IPreviousExamActions } from "store/interfaces/IPreviousExam";

// Assets
import { PreviousExamDetailsExamDayCol, PreviousExamDetailsContainer, PreviousExamDetailsDescription, PreviousExamDetailsRelateds } from "./styles";

interface IProps {
    modalActions: IModalActions;
    previousExam: IPreviousExamState;
}

const PreviousExamDetails = ({ previousExam, modalActions }: IProps) => {
    const { name, examDays, id, description } = previousExam.item;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const refContainer = useRef<HTMLDivElement>(null);

    const scrollToTop = useCallback(() => {
        try {
            if (!refContainer || !refContainer.current) {
                return;
            }

            refContainer.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        } catch (error) {
            console.log(error);
        }
    }, [refContainer]);

    const handleStartList = useCallback(
        (data: { hasRealizations: boolean; exerciseListId: number; previousExamDayId: number }) => {
            try {
                const { hasRealizations, exerciseListId, previousExamDayId } = data;

                if (!exerciseListId || !previousExamDayId) {
                    throw new Error();
                }

                return modalActions.openModal("startExerciseList", { hasRealizations, exerciseListId, previousExamId: id, previousExamDayId });
            } catch (error) {
                console.log(error);
            }
        },
        [id, modalActions]
    );

    const listExams = useMemo(() => {
        try {
            if (!examDays.length) {
                return <p data-test-id="previous-exam-empty">Nenhuma prova foi encontrada.</p>;
            }

            return examDays.map((examDay) => (
                <PreviousExamDetailsExamDayCol xs={12} sm={6} lg={4} key={examDay.id}>
                    <PreviousExamDetailsExamDay
                        previousExamDayId={examDay.id}
                        previousExamId={id}
                        name={examDay.name}
                        totalQuestions={examDay.questionsTotal}
                        realizations={examDay.exerciseList?.realizations || []}
                        exerciseListId={examDay.exerciseList?.id}
                        onClickStartList={(data) => handleStartList({ ...data, previousExamDayId: examDay.id })}
                        knowledgeAreas={examDay.knowledgeAreas}
                        namePDF={name}
                        examDaysStatus={examDay.status || 0}
                    />
                </PreviousExamDetailsExamDayCol>
            ));
        } catch (error) {
            console.log(error);
            return null;
        }
    }, [examDays, handleStartList, id, name]);

    const listRelated = useMemo(() => {
        if (previousExam.isLoading) {
            return (
                <PreviousExamDetailsRelateds>
                    <Spinner fixed={false} />
                </PreviousExamDetailsRelateds>
            );
        }

        if (!previousExam.relateds || !previousExam.relateds.length) {
            return null;
        }

        return (
            <PreviousExamDetailsRelateds>
                <h3>Concursos similares</h3>

                {previousExam.relateds.map((relatedExam) => (
                    <ColorTaggedItem
                        listHeading={`${relatedExam.name} - ${relatedExam.year}`}
                        key={relatedExam.id}
                        linkTo={`/app/curso/${courseSlug}/provas-anteriores/${relatedExam.id}`}
                        listDescription={``}
                        onClick={scrollToTop}
                    />
                ))}
            </PreviousExamDetailsRelateds>
        );
    }, [courseSlug, previousExam.isLoading, previousExam.relateds, scrollToTop]);

    return (
        <PreviousExamDetailsContainer ref={refContainer}>
            <PreviousExamDetailsHeader name={name} isLoading={previousExam.isLoading} />

            <Grid>
                {!!description && <PreviousExamDetailsDescription>{description}</PreviousExamDetailsDescription>}
                <Row>{previousExam.isLoading ? <PreviousExamDetailsExamDaySkeleton /> : listExams}</Row>
                <Row>{listRelated}</Row>
            </Grid>
        </PreviousExamDetailsContainer>
    );
};

interface IPreviousExamDetailsProps extends RouteComponentProps<{ id: string }> {
    previousExamActions: IPreviousExamActions;
    answerCard: IAnswerCardState;
    previousExam: IPreviousExamState;
    isLoading: boolean;
    modalActions: IModalActions;
}

const PreviousExamDetailsWrapper = ({ previousExamActions, previousExam, answerCard, match, modalActions }: IPreviousExamDetailsProps) => {
    const { id } = match.params;

    const requestExam = useCallback(() => {
        if (id) {
            previousExamActions.getPreviousExamDetailRequest({ id, isPreviousExam: true });
        }
    }, [id, previousExamActions]);

    useEffect(() => {
        requestExam();
    }, [requestExam]);

    useEffect(() => {
        return () => {
            previousExamActions.clearPreviousExam();
        };
    }, [previousExamActions]);

    return <PreviousExamDetails previousExam={previousExam} modalActions={modalActions} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    previousExamActions: bindActionCreators(previousExamActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch)
});

const mapStateToProps = (state: IReduxStore) => ({
    previousExam: state.previousExam,
    answerCard: state.answerCard
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(PreviousExamDetailsWrapper);
