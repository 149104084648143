// Dependencies
import React, { FunctionComponent, useRef, useState } from "react";

// Types
import { ActivityTextareaProps } from "./activity-textarea.types";

// Styles
import {
    Container,
    TextareaWrapper,
    TextareaField,
    TextareaErrorMessageWrapper,
    Label,
    ErrorMessageIcon,
    ErrorMessageLabel
} from "./activity-textarea.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Utils
import { ternary } from "utils/ternary";

// Assets
import fail from "assets/img/fail.svg";

export const ActivityTextarea: FunctionComponent<ActivityTextareaProps> = ({
    register,
    width,
    label,
    variant = "default",
    isHeightAdaptative,
    numberLinesDefault = 5,
    errorMessage,
    ...textareaDefaultProps
}) => {
    const textareaParentRef = useRef(null);
    const textareaValue = (textareaParentRef.current as any)?.querySelector("textarea")?.value;

    const [isFocused, setIsFocused] = useState(false);

    const labelColor = ternary([
        [isFocused, "#24388C"],
        [!isFocused && !!textareaValue, "#4D4D63"],
        [!isFocused && variant === "error", "#e40000"],
        [!isFocused && variant === "default", "#83899E"]
    ]);

    return (
        <Container className="webapp-textarea" width={width} isHeightAdaptative={isHeightAdaptative}>
            <TextareaWrapper
                variant={variant}
                isFilled={!!textareaValue}
                isFocused={isFocused}
                isHeightAdaptative={isHeightAdaptative}
                ref={textareaParentRef}
            >
                <ConditionallyRender shouldRender={!!label && (isFocused || !!textareaValue)} content={<Label color={labelColor!}>{label!}</Label>} />

                <TextareaField
                    {...register}
                    {...textareaDefaultProps}
                    {...(!isHeightAdaptative && { rows: numberLinesDefault })}
                    isHeightAdaptative={isHeightAdaptative}
                    onFocus={(event) => {
                        setIsFocused(true);

                        if (textareaDefaultProps.onFocus) {
                            textareaDefaultProps.onFocus(event);
                        }
                    }}
                    onBlur={(event) => {
                        setIsFocused(false);

                        if (register?.onBlur) {
                            register?.onBlur(event);
                        }

                        if (textareaDefaultProps.onBlur) {
                            textareaDefaultProps.onBlur(event);
                        }
                    }}
                    onChange={(event) => {
                        if (register?.onChange) {
                            register?.onChange(event);
                        }

                        if (textareaDefaultProps.onChange) {
                            textareaDefaultProps.onChange(event);
                        }
                    }}
                />
            </TextareaWrapper>

            <ConditionallyRender
                shouldRender={!!errorMessage}
                content={
                    <TextareaErrorMessageWrapper>
                        <ErrorMessageIcon src={fail} />

                        <ErrorMessageLabel>{errorMessage}</ErrorMessageLabel>
                    </TextareaErrorMessageWrapper>
                }
            />
        </Container>
    );
};
