import React from "react";

// Components
import Attachment from "./components/attachment";

// Helpers
import { IForumUpload } from "store/interfaces/IForum";

// Assets
import * as A from "./styles";

interface IAttachmentsControllerProps {
    attachments: IForumUpload[];
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
}

const AttachmentsController: React.FC<IAttachmentsControllerProps> = ({ attachments, onRemoveAttachment, onRetryUpload }) => {
    return (
        <A.AttachmentsControllerContainer>
            <A.AttachmentsControllerHelper>Anexo(s):</A.AttachmentsControllerHelper>
            <A.AttachmentsControllerWrapper>
                {attachments.map((attachment) => (
                    <Attachment {...attachment} onRetryUpload={onRetryUpload} onRemoveAttachment={onRemoveAttachment} key={attachment.id} />
                ))}
            </A.AttachmentsControllerWrapper>
        </A.AttachmentsControllerContainer>
    );
};

export default AttachmentsController;
