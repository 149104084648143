import React, { memo } from "react";

// Helpers
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

// Assets
import { ProgressBarLine, ProgressBarLabel, ProgressBarMobileWrapper, ProgressBarContainer, ProgressBarTabletWrapper } from "./styles";
import formatPercent from "utils/formatPercent";

export interface IProps {
    color?: string;
    value: number;
    label?: React.ReactElement;
    responsive?: boolean;
}

const ProgressBar = ({ value, label, color = "#222", responsive = false }: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);
    const Wrapper = responsive || !isMobile ? ProgressBarTabletWrapper : ProgressBarMobileWrapper;

    const formatValue = formatPercent(value);

    return (
        <ProgressBarContainer>
            {(responsive || !isMobile) && <ProgressBarLabel>{label}</ProgressBarLabel>}
            <Wrapper>
                <ProgressBarLine color={color} data-test-id="progress-bar-value" style={{ width: `${formatValue}%` || 0 }}>
                    {isMobile && !responsive && formatValue}
                </ProgressBarLine>
            </Wrapper>
        </ProgressBarContainer>
    );
};

export default memo(ProgressBar);
