import styled, { css } from "styled-components";
import { rotateAnimation } from "assets/styles/animations";

export const SpinnerWrap = styled.div<{ fixed: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    ${({ fixed }) =>
        fixed &&
        css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        `};

    svg {
        animation: ${rotateAnimation} 0.7s linear infinite;
    }
`;
