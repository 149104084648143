import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText1 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { ForumMetadataAuthor } from "../metadata/styles";

export const ForumQuestionContentContainer = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .ck-content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;

export const ForumQuestionContentMark = styled.span<{ markColor: string }>`
    background: ${({ markColor }) => markColor};
    border-radius: ${theme.spacing.smallXX};
    margin-right: ${theme.spacing.smallX};
    min-height: 100px;
    width: 4px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-right: 12px;
    }
`;

export const ForumQuestionContentText = styled.div`
    margin-bottom: ${theme.spacing.medium};

    p {
        ${BodyText1};
        color: ${theme.colors.base[500]};

        &:not(:last-child) {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;

export const ForumQuestionContentWrapper = styled.div`
    width: 100%;
`;

export const ForumMetaWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.medium} !important;
    justify-content: space-between;
`;

export const ForumMetaHelper = styled(ForumMetadataAuthor)`
    ${BodyText1};
`;
