import React from "react";

import ExerciseListEssayMake from "./make";
import ExerciseListEssayCorrection from "./correction";
import { useSelector } from "store";
import { EssayStatus } from "enums/EssayStatus";
import ExerciseListEssayDraft from "./draft";
import ExerciseListEssayEmpty from "./empty";

interface IProps {
    showCorrection: boolean;
    isFinished: boolean;
    handleSendEssay(data: { files?: File[]; content?: string }): void;
}

const ExerciseListEssay: React.FC<IProps> = ({ showCorrection, isFinished, handleSendEssay }) => {
    const { waitingCorrection, withoutComposition = false } = useSelector(({ answerCard }) => ({
        waitingCorrection: answerCard?.essayCorrection?.status !== EssayStatus.Corrected,
        withoutComposition: !answerCard?.essayCorrection?.idComposition && !answerCard?.answerCard?.examResolution?.idComposition
    }));

    if (withoutComposition && isFinished) {
        return <ExerciseListEssayEmpty />;
    }

    if (waitingCorrection && isFinished) {
        return <ExerciseListEssayDraft />;
    }

    return showCorrection ? <ExerciseListEssayCorrection /> : <ExerciseListEssayMake handleSendEssay={handleSendEssay} />;
};

export default ExerciseListEssay;
