import * as React from "react";

import { CardContainer } from "./styles";

interface IProps {
    style?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode;
    column?: boolean;
}

const Card = ({ column = false, ...props }: IProps) => {
    return (
        <CardContainer column={column} className={props.className} style={{ ...props.style }}>
            {props.children}
        </CardContainer>
    );
};

export default Card;
