import React, { memo, useMemo } from "react";

// Dependencies
import { Link } from "react-router-dom";
import ErrorIcon from "prodigio-icons/web/filled/Error";

// Components
import Button from "components/Button";

// Assets
import * as A from "./styles";

interface IAnnouncementBarProps {
    text: string;
    buttonLabel: string;
    onAction: string | (() => void);
}

const AnnouncementBar = ({ buttonLabel, text, onAction }: IAnnouncementBarProps) => {
    const ActionButton = useMemo(() => {
        if (typeof onAction !== "string") {
            return (
                <Button size="medium" variant="info" onClick={onAction}>
                    {buttonLabel}
                </Button>
            );
        }

        return (
            <Button as={Link} size="medium" variant="primary" to={onAction}>
                {buttonLabel}
            </Button>
        );
    }, [buttonLabel, onAction]);

    return (
        <A.AnnouncementBarContainer>
            <strong>
                <ErrorIcon width="52" height="52" />
                <span>Ops...</span>
            </strong>
            <A.AnnouncementBarText dangerouslySetInnerHTML={{ __html: text }} />
            {ActionButton}
        </A.AnnouncementBarContainer>
    );
};

export default memo(AnnouncementBar);
