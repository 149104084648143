// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceBestNoteProps } from "./performance-best-essay.types";

// Styles
import { Container, Icon, IconWrapper, NoteWrapper, NoteValue, NoteContent } from "./performance-best-essay.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceBestEssay: FunctionComponent<PerformanceBestNoteProps> = ({ note, icon }) => {
    const formattedNoteValue = leftPad(note.value, 3);
    const content = `Maior nota até aqui<br />Enviada em: ${note.date}`;

    return (
        <Container>
            <IconWrapper>
                <Icon src={icon} />
            </IconWrapper>

            <Spacing size={12} direction="horizontal" />

            <NoteWrapper>
                <NoteValue>{formattedNoteValue}</NoteValue>

                <NoteContent dangerouslySetInnerHTML={{ __html: content }} />
            </NoteWrapper>
        </Container>
    );
};
