import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { Creators } from "store/ducks/modal";

export function useModal() {
    const dispatch = useDispatch();

    const open = useCallback((element: string, action?: any) => dispatch(Creators.openModal(element, action)), [dispatch]);

    const close = useCallback(() => dispatch(Creators.closeModal()), [dispatch]);

    return {
        open,
        close
    };
}
