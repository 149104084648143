// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceOffensiveProps } from "./performance-offensive.types";

// Styles
import { Container, Icon, IconWrapper, OffensiveDaysWrapper, OffensiveDaysDescription, OffensiveDaysContent } from "./performance-offensive.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceOffensive: FunctionComponent<PerformanceOffensiveProps> = ({ dayCount, icon }) => {
    const formattedDayCount = leftPad(dayCount, 2);
    const dayCountMessage = `${formattedDayCount} dias seguidos!`;

    return (
        <Container>
            <IconWrapper>
                <Icon src={icon} />
            </IconWrapper>

            <Spacing size={12} direction="horizontal" />

            <OffensiveDaysWrapper>
                <OffensiveDaysDescription>Você está estudando há:</OffensiveDaysDescription>

                <OffensiveDaysContent>{dayCountMessage}</OffensiveDaysContent>
            </OffensiveDaysWrapper>
        </Container>
    );
};
