// Dependencies
import styled, { css, keyframes } from "styled-components";
import { theme } from "config/theme";

// Types
import { CarouselSlideDirections } from "./carousel.types";

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const CarouselContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    z-index: 4;
`;

export type CarouselContentStyleProps = {
    slideDirection: CarouselSlideDirections;
    isActive: boolean;
};

export const CarouselContent = styled.div<CarouselContentStyleProps>`
    position: relative;
    margin: 24px 16px;

    display: ${({ isActive }) => (!!isActive ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    animation: ${({ slideDirection }) => (slideDirection === "right" ? slideInFromRight : slideInFromLeft)} 0.5s ease;
`;

export const CarouselSliderWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CarouselSlider = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

type CarouselSliderDot = {
    isActive: boolean;
};

export const CarouselSliderDot = styled.div<CarouselSliderDot>`
    height: 8px;
    border-radius: 8px;

    cursor: pointer;

    transition: width 0.5s ease-in-out;

    ${({ isActive }) =>
        !!isActive
            ? css`
                  width: 80px;
                  background-color: ${theme.colors.white};
              `
            : css`
                  width: 32px;
                  background-color: ${theme.colors.brand[500]};
              `}
`;

export const CaroulselIcon = styled.img`
    width: 56px;
    height: 56px;

    cursor: pointer;

    @media (min-width: 1440px) {
        width: 80px;
        height: 80px;
    }
`;
