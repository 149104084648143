import turnImage from "./turn-image";
import getBase64 from "./get-base64";
import getOrientation from "./get-orientation";
import compressor from "browser-image-compression";

const compressFile = async (base64: File) => {
    const options = {
        maxSizeMB: 3,
        useWebWorker: true
    };

    return compressor(base64, options);
};

const formatImage = async (file: File, callback: (base64: string) => void, err?: (error: any) => void) => {
    try {
        const fileCompressed = await compressFile(file);

        getOrientation(fileCompressed as File, (orientation) => {
            getBase64(fileCompressed as File, (base64: any) => {
                turnImage(base64, orientation, (newImage: string) => callback(newImage));
            });
        });
    } catch (error) {
        console.error(error);

        err && err(error);
    }
};

export default formatImage;
