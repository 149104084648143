// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityChipSizes, ActivityChipVariants } from "./activity-chip.types";

export type ContainerStyleProps = {
    variant: ActivityChipVariants;
    hasCursorPointer?: boolean;
    size: ActivityChipSizes;
};

export const Container = styled.div<ContainerStyleProps>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    width: max-content;
    border: 1px solid;
    border-radius: 200px;

    ${({ size }) =>
        size === "small" &&
        css`
            padding: 4px 10px;
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            padding: 8px 16px;
        `}


    ${({ hasCursorPointer }) =>
        !!hasCursorPointer &&
        css`
            cursor: pointer;
        `}

    ${({ variant }) =>
        variant === "disabled" &&
        css`
            background-color: #ced0dd;
            border-color: #ced0dd;
        `}

    ${({ variant }) =>
        variant === "info" &&
        css`
            background-color: #a1b6ff;
            border-color: #a1b6ff;
        `}

    ${({ variant }) =>
        variant === "success" &&
        css`
            background-color: #55dc99;
            border-color: #55dc99;
        `}

    ${({ variant }) =>
        variant === "danger" &&
        css`
            background-color: #ff8888;
            border-color: #ff8888;
        `}

    ${({ variant }) =>
        variant === "warning" &&
        css`
            background-color: #ffbe62;
            border-color: #ffbe62;
        `}

    ${({ variant }) =>
        variant === "cta" &&
        css`
            background-color: #162152;
            border-color: #162152;
        `}

    ${({ variant }) =>
        variant === "default" &&
        css`
            background-color: transparent;
            border-color: #ced0dd;
        `}
`;

type LabelStyleProps = {
    color: string;
    size: ActivityChipSizes;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito", sans-serif;
    letter-spacing: 0em;
    text-align: center;

    ${({ color }) =>
        !!color &&
        css`
            color: ${color};
        `}

    ${({ size }) =>
        size === "small" &&
        css`
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        `}
`;
