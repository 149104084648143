// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { Asset } from "component-library/utilities/asset";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { BottomSheet } from "component-library/elements/bottom-sheet";
import { OptionItem } from "component-library/elements/option-item";
import { IconBadge } from "component-library/elements/icon-badge";
import { Back } from "component-library/elements/back";
import { PerformanceNavigator, PerformanceNavigatorSkeleton } from "component-library/compositions/performance-navigator";
import { OptionList } from "component-library/compositions/option-list";
import { PerformanceTopic, PerformanceTopicSkeleton } from "component-library/compositions/performance-topic";
import { PerformanceTopics } from "component-library/sections/performance-topics";
import { PerformanceSubject } from "component-library/workspaces/performance-subject";

// Assets
import schedule from "assets/img/performance-schedule.svg";
import chevronLeft from "assets/img/chevron-left.svg";
import chevronRight from "assets/img/chevron-right.svg";
import trophy from "assets/img/performance-trophy.svg";
import sadFace from "assets/img/performance-sad-face.svg";

const periods = [
    {
        message: "Hoje",
        slug: "today"
    },
    {
        message: "Esta semana",
        slug: "this-week"
    },
    {
        message: "Este mês",
        slug: "this-month"
    },
    {
        message: "Últimos 12 meses",
        slug: "last-year"
    }
];

export const PerformanceSubjectScreen: FunctionComponent = () => {
    const [isPeriodOptionsOpened, setIsPeriodOptionsOpened] = useState(false);
    const [selectedNavigationPeriodSlug, setSelectedNavigationPeriodSlug] = useState("this-week");

    const handleChangePeriodSelectVisibility = () => {
        setIsPeriodOptionsOpened((previousState) => !previousState);
    };

    const handleChangePeriod = (action: "previous" | "next") => {
        console.log(`navegar entre períodos: ${action}`);
    };

    const handleSelectNavigationPeriodSlug = (periodSlug: string) => {
        console.log(`exibir dados do período: ${periodSlug}`);

        setSelectedNavigationPeriodSlug(periodSlug);
    };

    const handleBack = () => {
        console.log("navega para a tela anterior");
    };

    const selectedNavigationPeriodMessage = periods.find((period) => period.slug === selectedNavigationPeriodSlug)?.message;

    const periodOptionItems = periods.map((item) => (
        <OptionItem
            key={item.slug}
            message={item.message}
            active={item.slug === selectedNavigationPeriodSlug}
            handleClick={() => {
                handleSelectNavigationPeriodSlug(item.slug);
                handleChangePeriodSelectVisibility();
            }}
        />
    ));

    // ----------

    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsSkeletonVisible(false);
        }, 5000);
    }, []);

    // ----------

    return (
        <PerformanceSubject
            pageTitle="Biologia"
            backElement={<Back label="Voltar" iconElement={<Asset source={chevronRight} color="#7C8195" size={20} />} handleClick={handleBack} />}
            navigatorComposition={
                <>
                    <ConditionallyRender
                        shouldRender={!isSkeletonVisible}
                        content={
                            <PerformanceNavigator
                                period={selectedNavigationPeriodMessage!}
                                totalQuestions={80}
                                totalHits={45}
                                hitsPercentage={34}
                                scheduleIcon={schedule}
                                isPeriodOptionsOpened={isPeriodOptionsOpened}
                                handlePeriodClick={handleChangePeriodSelectVisibility}
                                periodOptionsComposition={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <BottomSheet content={periodOptionItems} handleOtherClick={handleChangePeriodSelectVisibility} />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={<OptionList content={periodOptionItems} />}
                                        />
                                    </>
                                }
                                previousColumnIconElement={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <Asset
                                                    source={chevronRight}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={16}
                                                    handleClick={() => {
                                                        handleChangePeriod("previous");
                                                    }}
                                                />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={
                                                <Asset
                                                    source={chevronRight}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={20}
                                                    handleClick={() => {
                                                        handleChangePeriod("previous");
                                                    }}
                                                />
                                            }
                                        />
                                    </>
                                }
                                nextColumnIconElement={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <Asset
                                                    source={chevronLeft}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={16}
                                                    handleClick={() => {
                                                        handleChangePeriod("next");
                                                    }}
                                                />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={
                                                <Asset
                                                    source={chevronLeft}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={20}
                                                    handleClick={() => {
                                                        handleChangePeriod("next");
                                                    }}
                                                />
                                            }
                                        />
                                    </>
                                }
                            />
                        }
                    />
                    <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceNavigatorSkeleton />} />
                </>
            }
            topicsSection={
                <>
                    <PerformanceTopics
                        iconBadgeElement={<IconBadge hasAction={false} icon={trophy} backgroundColor="#28B583" scale="large" />}
                        topicCompositions={
                            <>
                                <ConditionallyRender
                                    shouldRender={!isSkeletonVisible}
                                    content={
                                        <>
                                            <PerformanceTopic
                                                informations={{
                                                    name:
                                                        "Nome do tópico escrito aqui, se meu tópico for muito grande tudo bem posso quebrar em duas linhas",
                                                    position: { backgroundColor: "#28B583", color: "#fff", number: 1 }
                                                }}
                                                totalQuestions={10}
                                                totalHits={2}
                                                hitsPercentage={30}
                                                hitsPercentageIndicatorColor="#28B583"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui, ",
                                                    position: { backgroundColor: "#28B583", color: "#fff", number: 2 }
                                                }}
                                                totalQuestions={10}
                                                totalHits={2}
                                                hitsPercentage={30}
                                                hitsPercentageIndicatorColor="#28B583"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui, ",
                                                    position: { backgroundColor: "#28B583", color: "#fff", number: 3 }
                                                }}
                                                totalQuestions={10}
                                                totalHits={2}
                                                hitsPercentage={30}
                                                hitsPercentageIndicatorColor="#28B583"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#F0F1F7", color: "#3A3E47", number: 4 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#7C8195"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#F0F1F7", color: "#3A3E47", number: 5 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#7C8195"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#F0F1F7", color: "#3A3E47", number: 6 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#7C8195"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#F0F1F7", color: "#3A3E47", number: 7 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#7C8195"
                                            />

                                            <Spacing size={16} direction="vertical" />
                                        </>
                                    }
                                />
                                <ConditionallyRender
                                    shouldRender={isSkeletonVisible}
                                    content={Array.from(Array(7)).map((_, index) => (
                                        <Fragment key={`performance-topic-skeleton-${index}-better`}>
                                            <PerformanceTopicSkeleton />

                                            <Spacing size={16} direction="vertical" />
                                        </Fragment>
                                    ))}
                                />
                            </>
                        }
                    />

                    <PerformanceTopics
                        iconBadgeElement={<IconBadge hasAction={false} icon={sadFace} backgroundColor="#E85153" scale="large" />}
                        topicCompositions={
                            <>
                                <ConditionallyRender
                                    shouldRender={!isSkeletonVisible}
                                    content={
                                        <>
                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#E85153", color: "#fff", number: 8 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#E85153"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#E85153", color: "#fff", number: 9 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#E85153"
                                            />

                                            <Spacing size={16} direction="vertical" />

                                            <PerformanceTopic
                                                informations={{
                                                    name: "Nome do tópico escrito aqui,",
                                                    position: { backgroundColor: "#E85153", color: "#fff", number: 10 }
                                                }}
                                                totalQuestions={0}
                                                totalHits={0}
                                                hitsPercentage={0}
                                                hitsPercentageIndicatorColor="#E85153"
                                            />

                                            <Spacing size={16} direction="vertical" />
                                        </>
                                    }
                                />
                                <ConditionallyRender
                                    shouldRender={isSkeletonVisible}
                                    content={Array.from(Array(3)).map((_, index) => (
                                        <Fragment key={`performance-topic-skeleton-${index}-worse`}>
                                            <PerformanceTopicSkeleton key={`performance-topic-skeleton-${index}-worse`} />

                                            <Spacing size={16} direction="vertical" />
                                        </Fragment>
                                    ))}
                                />
                            </>
                        }
                    />
                </>
            }
        />
    );
};
