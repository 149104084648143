import React, { useCallback, useMemo, useState, useEffect, memo, Fragment } from "react";
import { useSelector } from "react-redux";

//styles and visuals
import { Col, Row, Grid } from "components/Grid";
import { theme } from "config/theme";
import { Wrapper, WatchScreenSidebarVersion2, ContentDoubts, ButtonToReLoadPage, ContentHeaderDoubt, TitleClearDoubt, FilterDoubt } from "./styles";

//components
import PageLoader from "components/PageLoader";
import WatchScreenBreadCrumbDoubt from "./components/BreadCrumbDoubt";
import WatchScreenVideoDoubt from "./components/VideoDoubt";
import WatchScreenTeachersDoubt from "./components/TeachersDoubt";
import WatchScreenRelatedVideo from "./components/RelatedVideoDoubt";
import WatchScreenRelatedContent from "./components/RelatedContentDoubt";
import Doubts from "./components/Doubt";
import ForumDoubt from "./components/ForumDoubt";
import InputPost from "./components/InputDoubt";

//interfaces
import { IRelatedExerciseList, IRelatedMaterial } from "interfaces/IRelatedContent";
import { IRelatedVideo } from "interfaces/IRelatedContent";
import { IWatchBookletModule, IWatchLessonPlanModule, IWatchScreenVideo, IWatchSubjectSlug } from "store/ducks/watch";
import { ITeacher } from "interfaces/ITeacher";
import { IPost, IGetTopicStatus } from "store/interfaces/IDoubtPost";
import { IReduxStore } from "interfaces/IReduxStore";

//utils
import history from "services/history";
import useIsMobile from "hooks/use-is-mobile";
import { LikeStatusType } from "utils/types/LikeStatus";
import { GetWidth } from "utils/windowsSize";
import { WebpChip } from "components/WebpChip";

export type EventType = "ao-vivo" | "monitoria" | "complementar";

export interface IWatchScreenRouteProps {
    videoId: string;
    moduleId: string;
    subjectSlug: string;
    eventId: string;
    moduleSlug: string;
    startDate: string;
    type: EventType;
}

export interface IWatchScreenDoubtProps {
    eventStartDate?: string;
    eventEndDate?: string;
    video: IWatchScreenVideo;
    isLoading: boolean;
    isLoadingAnswerDoubts: boolean;
    relatedExerciseLists: IRelatedExerciseList[] | undefined;
    relatedMaterials: IRelatedMaterial[] | undefined;
    relatedBooklets: IRelatedMaterial[] | undefined;
    relatedVideos: IRelatedVideo[] | undefined;
    teachers: ITeacher[];
    category: number;
    lessonPlanModule?: IWatchLessonPlanModule;
    subjectObj?: IWatchSubjectSlug;
    zoneName?: string;
    hasForum: boolean;
    hasAnswerDoubts: boolean;
    eventTitle: string;
    routeParams: IWatchScreenRouteProps;
    bookletModule: IWatchBookletModule;
    onClickMaterial(material: IRelatedMaterial): void;
    onClickExerciseList(id?: number): void;
    onClickLike(id: number, status: LikeStatusType): void;
    onClickViewed(id: number, status: boolean): void;
    onGetVideo(video: IWatchScreenVideo): void;
    justminePosts: boolean;
    doubtPosts: IPost[];
    handleFetchNextPosts(): void;
    newPostsQuantity: number;
    handleJustmineFilter(justmine: boolean): void;
    handleRefreshPosts(): void;
    topicStatus: IGetTopicStatus;
    answerQuestionsElementRef: React.MutableRefObject<HTMLDivElement | null>;
}

const WatchScreenDoubt = ({
    isLoading,
    isLoadingAnswerDoubts,
    eventStartDate,
    video,
    relatedExerciseLists,
    relatedMaterials,
    relatedBooklets,
    onClickMaterial,
    onClickExerciseList,
    onClickLike,
    onClickViewed,
    relatedVideos = [],
    teachers,
    category,
    lessonPlanModule,
    subjectObj,
    zoneName,
    hasForum,
    hasAnswerDoubts,
    eventTitle,
    routeParams,
    onGetVideo,
    bookletModule,
    eventEndDate,
    justminePosts,
    doubtPosts,
    handleFetchNextPosts,
    newPostsQuantity,
    handleJustmineFilter,
    handleRefreshPosts,
    topicStatus,
    answerQuestionsElementRef
}: IWatchScreenDoubtProps) => {
    const isMobile = useIsMobile();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [blockContent, setBlockContent] = useState(false);
    const [containSecondCol, setContainSecondCol] = useState(false);

    const subject = typeof video.subject === "string" ? video.subjectObj : video.subject;

    const { eventId, moduleId, moduleSlug, startDate, type, subjectSlug } = routeParams;

    const correctSubject: {
        color: string;
        name: string;
        slug: string;
    } = useMemo(() => {
        const defaultColor = theme.colors.base[200];
        const defaultName = "Sem matéria";

        try {
            return {
                name: subjectObj?.name || subject?.name || defaultName,
                color: subjectObj?.color || subject?.color || defaultColor,
                slug: subjectSlug || subject?.slug || ""
            };
        } catch (error) {
            return {
                name: defaultName,
                color: defaultColor,
                slug: ""
            };
        }
    }, [subject, subjectObj, subjectSlug]);

    const handleClickRelatedVideo = useCallback(
        (videoId: number) => {
            try {
                if (!videoId) {
                    return;
                }

                // ao vivo vindo de materias
                if (!!eventId && !!subjectSlug) {
                    return history.push({ pathname: `/app/curso/${courseSlug}/materias/${subjectSlug}/${moduleId}/ao-vivo/${videoId}` });
                }

                // plano de estudos
                if (!!moduleSlug && !!startDate && !!type && !!eventId) {
                    return history.push({
                        pathname: `/app/curso/${courseSlug}/plano-de-estudos/${moduleSlug}/${startDate}/${type}/${eventId}/video/${videoId}`
                    });
                }

                // video da materia
                if (!!subjectSlug && !!moduleId) {
                    return history.push({ pathname: `/app/curso/${courseSlug}/materias/${subjectSlug}/${moduleId}/video/${videoId}` });
                }

                return history.push({ pathname: `/app/curso/${courseSlug}/video/${videoId}` });
            } catch (error) {
                console.log(error);
            }
        },
        [courseSlug, eventId, moduleId, moduleSlug, startDate, subjectSlug, type]
    );

    useEffect(() => {
        setContainSecondCol(!!teachers?.length || !!relatedVideos?.length);
    }, [relatedVideos, teachers]);

    const contentChipFilter = useMemo(() => {
        return (
            <FilterDoubt>
                <WebpChip
                    label="Minhas dúvidas"
                    disabled={justminePosts}
                    selected={justminePosts}
                    variant="neutral"
                    onChipSelected={() => !justminePosts && handleJustmineFilter(true)}
                ></WebpChip>
                <WebpChip
                    label="Todas"
                    disabled={!justminePosts}
                    selected={!justminePosts}
                    variant="neutral"
                    onChipSelected={() => justminePosts && handleJustmineFilter(false)}
                ></WebpChip>
            </FilterDoubt>
        );
    }, [handleJustmineFilter, justminePosts]);

    const newPostsButtonLabel = useMemo(() => {
        if (newPostsQuantity >= 10) {
            return `Existem 10+ novas dúvidas`;
        }

        if (newPostsQuantity <= 1) {
            return `Existe 1 nova dúvida`;
        }

        return `Existem ${newPostsQuantity} novas dúvidas`;
    }, [newPostsQuantity]);

    const isForumOpen = !!video?.learningObject?.openForum;
    const showForum = !blockContent && !!video?.learningObject?.id && hasForum;

    const forumOrDoubts = (
        <Fragment>
            {!hasAnswerDoubts && showForum && !isLoadingAnswerDoubts && <ForumDoubt code={video?.learningObject?.id} isOpen={isForumOpen} />}

            {hasAnswerDoubts && newPostsQuantity > 0 && !justminePosts && (
                <div
                    style={{
                        position: "sticky",
                        top: GetWidth < 1024 ? "calc((100vw / 16) * 9)" : "8px",
                        zIndex: "50",
                        ...(GetWidth < 1024 && { paddingTop: "8px" })
                    }}
                >
                    <ButtonToReLoadPage size="small" variant="primary" onClick={handleRefreshPosts}>
                        {newPostsButtonLabel}
                    </ButtonToReLoadPage>
                </div>
            )}

            {hasAnswerDoubts &&
                ([IGetTopicStatus.Active].includes(topicStatus) ||
                    ([IGetTopicStatus.OnlyReading].includes(topicStatus) && doubtPosts.length > 0)) && (
                    <Fragment>
                        <ContentHeaderDoubt ref={answerQuestionsElementRef}>
                            <TitleClearDoubt>Tira-dúvidas</TitleClearDoubt>
                            {contentChipFilter}
                        </ContentHeaderDoubt>
                        <ContentDoubts>
                            {[IGetTopicStatus.Active].includes(topicStatus) && <InputPost />}
                            <Doubts posts={doubtPosts} handleFetchNextPosts={handleFetchNextPosts} />
                        </ContentDoubts>
                    </Fragment>
                )}
        </Fragment>
    );

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <Fragment>
            <Wrapper>
                <Grid>
                    {!isMobile && (
                        <WatchScreenBreadCrumbDoubt
                            subject={correctSubject}
                            moduleId={Number(moduleId)}
                            {...(!!lessonPlanModule && { lessonPlanModule })}
                            category={category || 0}
                            eventId={Number(eventId)}
                            bookletModuleName={bookletModule.name || ""}
                        />
                    )}

                    <Row between="md">
                        <Col xs={12} md={containSecondCol ? 8 : 12} lg={containSecondCol ? 8 : 12}>
                            <WatchScreenVideoDoubt
                                video={video}
                                videoId={!!routeParams.videoId ? Number(routeParams.videoId) : video.id || 0}
                                eventTitle={!!routeParams.videoId ? "" : eventTitle}
                                eventStartDate={eventStartDate}
                                eventEndDate={eventEndDate}
                                subject={correctSubject}
                                blockContent={blockContent}
                                zoneName={zoneName}
                                onClickLike={onClickLike}
                                onClickViewed={onClickViewed}
                                onBlockContent={() => setBlockContent(true)}
                            />

                            {GetWidth >= 1024 && forumOrDoubts}
                        </Col>

                        {containSecondCol && (
                            <Col xs={12} md={4} lg={4}>
                                <WatchScreenSidebarVersion2>
                                    {!!teachers?.length && <WatchScreenTeachersDoubt teachers={teachers.filter((teacher) => !!teacher.person)} />}

                                    {!blockContent && (
                                        <WatchScreenRelatedContent
                                            relatedExerciseLists={relatedExerciseLists}
                                            onClickExerciseList={onClickExerciseList}
                                            relatedMaterials={relatedMaterials}
                                            relatedBooklets={relatedBooklets}
                                            onClickMaterial={onClickMaterial}
                                        />
                                    )}

                                    <WatchScreenRelatedVideo
                                        playlist={relatedVideos}
                                        videoId={video.id}
                                        subject={correctSubject}
                                        onClickVideo={handleClickRelatedVideo}
                                    />
                                </WatchScreenSidebarVersion2>
                            </Col>
                        )}

                        {GetWidth < 1024 && (
                            <Col xs={12} md={containSecondCol ? 8 : 12} lg={containSecondCol ? 8 : 12}>
                                {forumOrDoubts}
                            </Col>
                        )}
                    </Row>
                </Grid>
            </Wrapper>
        </Fragment>
    );
};

export default memo(WatchScreenDoubt);
