import React, { useCallback, useRef, useState } from "react";
import { Wrapper, Content, AvatarProfile, Textarea } from "./styles";

//components
import { WebpButton } from "components/WebpButton";

//redux
import { useDispatch, useSelector } from "react-redux";
import { Creators as postActions } from "store/ducks/doubtPost";
import { Creators as alertActions } from "store/ducks/alert";
import { IReduxStore } from "interfaces/IReduxStore";

const InputPost = () => {
    const dispatch = useDispatch();
    const [post, setValuePost] = useState<string>();
    const refTextarea = useRef<HTMLTextAreaElement>(null);
    const [valueHeightTextarea, setValueHeightTextarea] = useState<any>(78);

    setTimeout(() => {
        setValueHeightTextarea(refTextarea.current?.scrollHeight);
    }, 0);

    const onTextChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValuePost(e?.target?.value);
    }, []);

    const { doubtPost, auth } = useSelector(({ doubtPost, auth }: IReduxStore) => ({ doubtPost, auth }));

    const requestDoubts = useCallback(() => {
        if (!post?.trim()) {
            return dispatch(alertActions.showAlert("Campo em branco", "warning"));
        }

        dispatch(postActions.sendPostRequest({ postMessage: post, topicReference: doubtPost.topic.data.id }));

        setValuePost("");
    }, [dispatch, doubtPost.topic.data.id, post]);

    return (
        <Wrapper>
            <Content>
                <AvatarProfile avatar={auth?.credentials?.imageProfile!} />
                <Textarea height={valueHeightTextarea} ref={refTextarea} onChange={onTextChange} placeholder="Qual é a sua dúvida?" value={post} />
            </Content>
            <WebpButton variant="primary" size="medium" onClick={requestDoubts} contentButtonComposition={"Perguntar"}></WebpButton>
        </Wrapper>
    );
};

export default InputPost;
