import React, { useEffect } from "react";

// Dependencies
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";

// Components
import ForbiddenMessage from "components/ForbiddenMessage";
import LessonPlansScreen from "screens/LessonPlan/lesson-plan";

// Redux
import { Creators as LessonPlanActions } from "store/ducks/lesson-plan";

// Helpers
import history from "services/history";
import { IReduxStore } from "interfaces/IReduxStore";
import { RouteComponentProps } from "react-router-dom";
import { ILessonPlanState, ILessonPlanActions } from "store/interfaces/ILessonPlan";
import { NotFoundProject as ForbiddenContainer } from "screens/Projects/Details/styles";

interface ILessonPlanContainerProps {
    course: {
        isPublished: boolean;
        forbiddenText?: string;
        slug?: string;
    };
    lessonPlan: ILessonPlanState;
    lessonPlanActions: ILessonPlanActions;
    zoneName: string;
}

const LessonPlanContainer = (props: ILessonPlanContainerProps & RouteComponentProps<{ idModule?: string; day?: string }, {}, { day: string }>) => {
    const { course, ...rest } = props;

    useEffect(() => {
        if (!course.isPublished && !!props.lessonPlan.id) {
            props.lessonPlanActions.clearLessonPlan();
            history.push({ pathname: `/app/curso/${course.slug}/plano-de-estudos` });
        }
    }, [course.isPublished, course.slug, props.lessonPlan.id, props.lessonPlanActions]);

    if (!course.isPublished || props.lessonPlan.hasError) {
        return (
            <ForbiddenContainer>
                <ForbiddenMessage text={course.forbiddenText || "Plano de estudos não encontrado"} />
            </ForbiddenContainer>
        );
    }

    return <LessonPlansScreen {...rest} />;
};

const mapStateToProps = (state: IReduxStore) => ({
    course: {
        isPublished: state.course.isPublished,
        forbiddenText: state.course.helptext,
        slug: state?.course?.slug
    },
    lessonPlan: state.lessonPlan,
    zoneName: state.credentials.timezone.zoneName
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    lessonPlanActions: bindActionCreators(LessonPlanActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlanContainer);
