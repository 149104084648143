import { createActions, createReducer } from "reduxsauce";
import { IVideoState, IVideoActionsCreators, VideoActionsType } from "store/interfaces/IVideo";

export const { Creators, Types } = createActions<Record<VideoActionsType, VideoActionsType>, IVideoActionsCreators>({
    getVideoRequest: ["payload"],
    getVideoSuccess: ["payload"],
    getVideoFailure: ["payload"],

    getVideoByPlayerRequest: ["payload"],
    getVideoByPlayerSuccess: ["payload"],
    getVideoByPlayerFailure: ["payload"],

    createVideoViewRequest: ["payload"],
    createVideoViewSuccess: ["payload"],
    createVideoViewFailure: [],

    setVideoViewedRequest: ["payload"],
    setVideoViewedSuccess: ["payload"],
    setVideoViewedFailure: ["payload"],

    getVideoUserIpRequest: [],
    getVideoUserIpSuccess: ["payload"],

    saveVideoProgressRequest: ["payload"],

    markVideoViewRequest: ["payload"],

    reportVideoErrorRequest: ["payload"],
    reportVideoErrorSuccess: [],
    reportVideoErrorFailure: [],

    clearVideoReportError: [],

    clearVideo: []
});

export const INITIAL_STATE: IVideoState = {
    isLoading: true,
    blockContent: false,
    url: "",
    id: 0,
    title: "",
    provider: 0,
    videoKey: "",
    videoEmbed: "",
    viewId: 0,
    userIp: "",
    error: false,
    isLoadingReportError: false,
    isReportErrorSent: false,
    learningObject: {
        viewed: false
    },
    subject: "",
    teachers: [],
    playlist: []
};

const setVideoViewedSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        learningObject: {
            ...state.learningObject,
            viewed: action.payload.status
        }
    };
};

const getVideoRequest = (state = INITIAL_STATE, action: any) => ({ ...state, isLoading: true });

const getVideoSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    url: action.payload
});

const getVideoFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const getVideoByPlayerSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const genericAction = (state = INITIAL_STATE, action: any) => ({
    ...state
});

const createVideoViewSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    viewId: action.payload
});

const getVideoUserIp = (state = INITIAL_STATE, action: any) => ({
    ...state,
    userIp: action.payload
});

const getVideoByPlayerFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});

const clearVideo = () => INITIAL_STATE;

const reportVideoErrorRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingReportError: true,
    isReportErrorSent: false
});

const reportVideoErrorSuccess = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingReportError: false,
    isReportErrorSent: true
});

const reportVideoErrorFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingReportError: false,
    isReportErrorSent: false
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_VIDEO_REQUEST]: getVideoRequest,
    [Types.GET_VIDEO_SUCCESS]: getVideoSuccess,
    [Types.GET_VIDEO_FAILURE]: getVideoFailure,

    [Types.GET_VIDEO_BY_PLAYER_REQUEST]: getVideoRequest,
    [Types.GET_VIDEO_BY_PLAYER_SUCCESS]: getVideoByPlayerSuccess,
    [Types.GET_VIDEO_BY_PLAYER_FAILURE]: getVideoByPlayerFailure,

    [Types.CREATE_VIDEO_VIEW_REQUEST]: genericAction,
    [Types.CREATE_VIDEO_VIEW_SUCCESS]: createVideoViewSuccess,
    [Types.CREATE_VIDEO_VIEW_FAILURE]: genericAction,

    [Types.GET_VIDEO_USER_IP_REQUEST]: genericAction,
    [Types.GET_VIDEO_USER_IP_SUCCESS]: getVideoUserIp,

    [Types.SAVE_VIDEO_PROGRESS_REQUEST]: genericAction,

    [Types.MARK_VIDEO_VIEW_REQUEST]: genericAction,

    [Types.REPORT_VIDEO_ERROR_REQUEST]: reportVideoErrorRequest,
    [Types.REPORT_VIDEO_ERROR_SUCCESS]: reportVideoErrorSuccess,
    [Types.REPORT_VIDEO_ERROR_FAILURE]: reportVideoErrorFailure,

    [Types.CLEAR_VIDEO_REPORT_ERROR]: reportVideoErrorFailure,

    [Types.CLEAR_VIDEO]: clearVideo,

    // VIDEO VIEWED
    [Types.SET_VIDEO_VIEWED_REQUEST]: genericAction,
    [Types.SET_VIDEO_VIEWED_SUCCESS]: setVideoViewedSuccess,
    [Types.SET_VIDEO_VIEWED_FAILURE]: genericAction
});
