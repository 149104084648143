enum WhiteListRoutes {
    Activities = "atividades",
    SimulatedExam = "simulados",
    Subject = "materias",
    ExerciseList = "lista-de-exercicios",
    ExerciseListCustom = "banco-de-questoes",
    SchoolExam = "avaliacoes",
    PreviousExam = "provas-anteriores",
    Project = "projetos"
}

const WHITE_LIST_ROUTES = Object.values(WhiteListRoutes);

export function getExerciseListDashboardRoute(courseSlug: string): string {
    try {
        const currentPath = window.location.pathname;

        if (!currentPath) {
            throw new Error();
        }

        const arrPath = currentPath.split("/").filter((item) => item);

        const pathHasCourse = arrPath.includes("curso");

        const rootIndex = pathHasCourse ? arrPath.findIndex((item) => item === "curso") + 1 : arrPath.findIndex((item) => item === "app");

        const filteredArrPath = arrPath.filter((item, index) => !!item && index > rootIndex);

        const [entity] = filteredArrPath;

        if (!WHITE_LIST_ROUTES.includes(entity as any)) {
            throw new Error();
        }

        let dashboard = "";

        if (entity === WhiteListRoutes.Activities) {
            dashboard = `/app/curso/${courseSlug}/atividades/`;
        }

        if (entity === WhiteListRoutes.SimulatedExam) {
            dashboard = `/app/curso/${courseSlug}/simulados`;
        }

        if (entity === WhiteListRoutes.Subject) {
            const [, subjectSlug, moduleId, , exerciseListId] = filteredArrPath;

            dashboard = `/app/curso/${courseSlug}/materias/${subjectSlug}/${moduleId}/lista-de-exercicios/${exerciseListId}`;
        }

        if (entity === WhiteListRoutes.ExerciseList) {
            const [, exerciseListId] = filteredArrPath;

            dashboard = `/app/curso/${courseSlug}/lista-de-exercicios/${exerciseListId}`;
        }

        if (entity === WhiteListRoutes.SchoolExam) {
            const [, subjectSlug] = filteredArrPath;

            dashboard = `/app/curso/${courseSlug}/avaliacoes/${subjectSlug}`;
        }

        if (entity === WhiteListRoutes.PreviousExam) {
            const [, examSlug] = filteredArrPath;

            dashboard = `/app/curso/${courseSlug}/provas-anteriores/${examSlug}`;
        }

        if (entity === WhiteListRoutes.Project) {
            const [, projectId, , exerciseListId] = filteredArrPath;

            dashboard = `/app/curso/${courseSlug}/projetos/${projectId}/lista-de-exercicios/${exerciseListId}`;
        }

        if (entity === WhiteListRoutes.ExerciseListCustom) {
            dashboard = `/app/curso/${courseSlug}/banco-de-questoes`;
        }

        return dashboard;
    } catch (error) {
        return "";
    }
}
