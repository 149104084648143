import React, { useState, useMemo, useEffect } from "react";

import { IResolution } from "interfaces/IResolution";

import {
    ResolutionExerciseListContainer,
    ResolutionExerciseListOptions,
    ResolutionExerciseListOptionsWrap,
    ResolutionExerciseListEnunciation,
    ResolutionExternalContentWrapper,
    ResolutionExerciseListAnswer,
    ResolutionExerciseListExtraContentWrapper
} from "./styles";
import Video from "components/Video";
import { IQuestion } from "interfaces/IQuestion";
import { IAnswerCardItem } from "store/ducks/answerCard";

import TemplateIcon from "prodigio-icons/web/filled/TemplateSheet";
import VideoIcon from "prodigio-icons/web/filled/Video";
import { Row, Col } from "components/Grid";

import ResolutionExerciseListQuestion from "./components/question";
import { IModalActions } from "store/interfaces/IModal";
import Enunciation from "components/Enunciation";
import QuestionType from "screens/ExerciseList/enums/QuestionType";
import AnswerHit from "./components/AnswerHit";
import VideoLightRedirector from "components/VideoLightRedirector";

interface IProps {
    data: {
        resolutions: IResolution[];
        question: IQuestion;
        answer: IAnswerCardItem;
    };
    onClose(): void;
    modalActions: IModalActions;
}

const ResolutionExerciseList = ({ data, onClose, modalActions }: IProps) => {
    const { question, resolutions = [], answer } = data;

    const [activeResolution, setActiveResolution] = useState<IResolution>();

    useEffect(() => {
        if (!!resolutions && !!resolutions.length && resolutions.length === 1) {
            setActiveResolution(resolutions[0]);
        }
    }, [resolutions]);

    const correctAnswer = useMemo(
        () => !!question && !!question.alternatives && question.alternatives.find((alternative) => !!alternative.isCorrection),
        [question]
    );

    const correctAnswerIndex = useMemo(
        () => !!question && !!question.alternatives && question.alternatives.findIndex((alternative) => !!alternative.isCorrection),
        [question]
    );

    const hasSomeResolutions = useMemo(() => resolutions.length > 1 && !activeResolution, [activeResolution, resolutions.length]);

    if (hasSomeResolutions) {
        return (
            <ResolutionExerciseListContainer small={true} data-test-id="exercise-list-resolution-modal">
                <h3>Escolha um tipo de resolução</h3>
                <ResolutionExerciseListOptionsWrap>
                    {resolutions.map((resolution) => {
                        const isMaterial = resolution.type === "Material" && !!resolution.material;
                        const isArchiveLink = isMaterial && !!resolution.material?.url;
                        const isSchoolPdf = isMaterial && !!resolution.material?.linkHTML;
                        const materialUrl = !!resolution.material?.linkHTML ? resolution.material?.linkHTML : resolution.material?.url;

                        if (isSchoolPdf) {
                            return (
                                <ResolutionExerciseListOptions key={resolution.id} as="a" href={materialUrl} target="_blank">
                                    <TemplateIcon /> Visualizar
                                </ResolutionExerciseListOptions>
                            );
                        }

                        if (isArchiveLink) {
                            return (
                                <ResolutionExerciseListOptions key={resolution.id} as="a" href={resolution.material?.url} target="_blank">
                                    <TemplateIcon /> Visualizar
                                </ResolutionExerciseListOptions>
                            );
                        }

                        return (
                            <ResolutionExerciseListOptions key={resolution.id} onClick={() => setActiveResolution(resolution)}>
                                {resolution.type === "Video" && (
                                    <>
                                        <VideoIcon /> Vídeo
                                    </>
                                )}
                                {resolution.type === "Material" && (
                                    <>
                                        <TemplateIcon /> Material
                                    </>
                                )}
                            </ResolutionExerciseListOptions>
                        );
                    })}
                </ResolutionExerciseListOptionsWrap>
            </ResolutionExerciseListContainer>
        );
    }

    if (!activeResolution || (!activeResolution.video && !activeResolution.material)) {
        return null;
    }

    const isHTML = !!activeResolution.material?.content;
    const ext = activeResolution.material?.fileName?.split(".").pop();
    const isImg = !!ext && ["jpg", "jpeg", "png", "gif"].includes(ext);
    const materialHref = !!activeResolution.material?.linkHTML ? activeResolution.material?.linkHTML : activeResolution.material?.url;

    return (
        <ResolutionExerciseListContainer data-test-id="exercise-list-resolution-modal">
            <h3>Resolução</h3>

            <Row>
                <ResolutionExerciseListAnswer md={7} xs={12}>
                    {isHTML ? (
                        <ResolutionExerciseListEnunciation>
                            <Enunciation enunciation={activeResolution.material?.content!} />
                        </ResolutionExerciseListEnunciation>
                    ) : !!activeResolution.video ? (
                        <Video idVideo={activeResolution.video.id} />
                    ) : isImg ? (
                        <img src={activeResolution.material.url} style={{ maxWidth: "100%" }} alt={activeResolution.material.title} />
                    ) : (
                        <ResolutionExternalContentWrapper>
                            <p>Acesse o conteúdo da resolução no link abaixo</p>
                            <ResolutionExerciseListOptions as="a" href={materialHref} target="_blank">
                                <TemplateIcon /> Visualizar
                            </ResolutionExerciseListOptions>
                        </ResolutionExternalContentWrapper>
                    )}
                </ResolutionExerciseListAnswer>

                <ResolutionExerciseListQuestion
                    correctAnswerIndex={correctAnswerIndex}
                    enunciation={question.enunciation}
                    correctAnswer={correctAnswer}
                    showAnswer={question.questionType === QuestionType.Objective}
                />

                <ResolutionExerciseListExtraContentWrapper>
                    {!!activeResolution.video && <VideoLightRedirector videoId={activeResolution.video.id} />}

                    {question.questionType === QuestionType.Discursive && (
                        <Col xs={12} md={3}>
                            <AnswerHit answer={answer} question={question} />
                        </Col>
                    )}
                </ResolutionExerciseListExtraContentWrapper>
            </Row>
        </ResolutionExerciseListContainer>
    );
};

export default ResolutionExerciseList;
