import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { ProgressBarContainer } from "components/ProgressBar/styles";
import { MediaQueries } from "assets/styles/settings";
import { ExerciseListLetterContainer } from "components/Letter/styles";

export const ExerciseListAlternativeContainer = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }
`;

export const ExerciseListAlternativeEnunciation = styled.div`
    color: ${theme.colors.base[500]};
    flex: 1;
    transition: color ease 0.2s;

    > * {
        font-size: ${theme.typography.sizes.smallX};
    }
`;

export const ExerciseListAlternativeIgnoreButton = styled.button<{ variant: "danger" | "success" }>`
    background: ${({ variant }) => theme.colors.system[variant][100]};
    border-radius: 8px;
    padding: ${theme.spacing.smallX};
    font-size: ${theme.typography.sizes.small};
    position: absolute;
    right: ${theme.spacing.small};
    display: none;
    align-items: center;

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExerciseListAlternativeItem = styled.div<{ isIgnored: boolean; right?: number; left?: number }>`
    transition: background ease 0.2s;
    position: relative;
    z-index: 1;
    background: ${theme.colors.white};
    border-radius: 4px;
    padding: 0 ${theme.spacing.smallXX};

    transition: -webkit-transform 300ms ease 0s;

    ${({ right }) => !!right && `transform: translate3d(-${right}px, 0px, 0px);`};
    ${({ left }) => !!left && `transform: translate3d(${left}px, 0px, 0px);`};

    ${({ isIgnored }) =>
            isIgnored &&
            css`
                ${ExerciseListAlternativeEnunciation}, .ck-content {
                    color: ${theme.colors.base[200]};
                    text-decoration-line: line-through;
                }

                img {
                    opacity: 0.5;
                }
            `}
        :hover {
        ${MediaQueries.BIGGER_THAN_SMALL} {
            ${ExerciseListAlternativeIgnoreButton} {
                display: flex;
            }
        }
    }
`;

export const ExercuseListAlternativeContent = styled.div`
display: flex;
align-items: center;
padding: ${theme.spacing.medium} 0;

/* ${ExerciseListLetterContainer} {
    flex: 0 0 30px;
    margin-right: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex: unset;
    }
} */
`;

export const ExerciseListAlternativeProgressBar = styled.div`
    display: flex;
    margin-top: ${theme.spacing.small};
    align-items: flex-end;

    ${ProgressBarContainer} {
        width: 100%;
        margin-right: ${theme.spacing.small};

        /* ${MediaQueries.BIGGER_THAN_SMALL} {
            width: 350px;
        } */
    }
`;

export const ExerciseListAlternativeLetterContainer = styled.div`
    padding-right: ${theme.spacing.small};
`;

export const ExerciseListAlternativeRightContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
`;

export const ExerciseListAlternativeProgressPercent = styled.strong`
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[500]};
    line-height: 0.6;
`;

export const ExerciseListAlternativeDiscartLabel = styled.div<{ success: boolean }>`
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: ${({ success }) => (success ? "flex-start" : "flex-end")};
    top: 0;
    padding: ${theme.spacing.small};
    border-radius: 4px;

    background: ${({ success }) => (success ? theme.colors.system.success[100] : theme.colors.system.danger[100])};
    color: ${theme.colors.base[500]};

    svg {
        ${({ success }) => (success ? `margin-right: ${theme.spacing.smallX};` : `margin-left: ${theme.spacing.smallX};`)}
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: none;
    }
`;
