import styled from "styled-components";

import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";

export const Field = styled.input`
    display: flex;
    height: 30px;
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.medium};
    line-height: 22px;
    color: ${theme.colors.base[400]};
    margin-bottom: ${theme.spacing.small};
    background-color: transparent;

    &::placeholder {
        color: ${theme.colors.base[200]};
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        height: 40px;
        font-size: ${theme.typography.sizes.mediumX};
        line-height: 26px;
    }
`;
