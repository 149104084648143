import React, { memo, useMemo } from "react";
import * as S from "./styles";
import { Grid, Row, Col } from "components/Grid";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";
import SelectFront from "components/Subject/SelectFront";
import { ISubjectProgress } from "interfaces/ISubject";
import ProgressBar from "components/ProgressBar";
import { IGenerateColors } from "helpers/generateColors";
import formatPercent from "utils/formatPercent";
import { theme } from "config/theme";
import SubjectScreenDetailsHeaderSkeletonProgress from "./skeleton";
import Skeleton from "components/Skeleton";
import { ISubjectInfo, ISubjectFront } from "store/interfaces/ISubject";
import { IWeekCourses } from "store/ducks/weekPlans/types";
import { IReduxStore } from "interfaces/IReduxStore";
import { useSelector } from "react-redux";
import { ternary } from "utils/ternary";

interface IProps {
    progress: ISubjectProgress;
    name: string;
    color: IGenerateColors;
    isLoading?: boolean;
    isLoadingProgress: boolean;
    onClickInfos(subjectFront: ISubjectFront): void;
    infos?: ISubjectInfo;
    isLoadingInfos: boolean;
    courses?: IWeekCourses[];
}

const SubjectScreenDetailsHeader = ({
    progress,
    color = theme.colors.base,
    name,
    isLoading = true,
    isLoadingProgress = true,
    onClickInfos,
    courses
}: IProps) => {
    const { courseSlug } = useSelector(({ course }: IReduxStore) => ({
        courseSlug: course.slug
    }));

    const haveProgress = useMemo(() => !!progress, [progress]);

    const progressPercentage = useMemo(() => {
        if (!haveProgress) {
            return 0;
        }

        return progress.percent ? formatPercent(progress.percent) : formatPercent((progress.completedItems * 100) / progress.totalItems);
    }, [haveProgress, progress.completedItems, progress.percent, progress.totalItems]);

    const isProenemOrPromedicinaTurmaIntensiva2023 =
        ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(courseSlug) &&
        ["proenem", "promedicina"].includes(theme.project.slug);

    const isPromilitaresFixedNewProducts = ["esa-fx", "espcex-fx", "eear-fx"].includes(courseSlug) && ["promilitares"].includes(theme.project.slug);

    const progressLabel = ternary([
        [isProenemOrPromedicinaTurmaIntensiva2023, " módulos concluídos do plano"],
        [isPromilitaresFixedNewProducts, "Progresso do curso"],
        [true, " semanas concluídas do plano"]
    ]);

    return (
        <S.SubjectScreenDetailsHeaderContainer>
            <Grid fluid>
                <Row>
                    <Col xs={12} sm={4} md={haveProgress ? 5 : 12} lg={haveProgress ? 5 : 12}>
                        <S.SubjectScreenDetailsHeaderWrapTexts>
                            <S.SubjectScreenDetailsHeaderBackButton>
                                {isLoading ? <Skeleton width="75px" height="27px" /> : name}
                            </S.SubjectScreenDetailsHeaderBackButton>
                            <S.SubjectScreenDetailsHeaderEntity>
                                <p>Plano de estudos</p>
                                <ChevronRightIcon className="chevron-right" width="10" height="8" />
                            </S.SubjectScreenDetailsHeaderEntity>
                            <S.SubjectScreenDetailsHeaderName>
                                {isLoading ? <Skeleton width="75px" height="27px" /> : name}
                            </S.SubjectScreenDetailsHeaderName>

                            {courses && courses?.length > 1 && (
                                <S.SelectFrontContainer>
                                    <SelectFront
                                        isMobile={false}
                                        isLoadingFronts={false}
                                        isLoadingProgress={false}
                                        fronts={courses}
                                        onClickFront={onClickInfos}
                                        withFront={true}
                                        subjectProgress={progress}
                                    />
                                </S.SelectFrontContainer>
                            )}
                        </S.SubjectScreenDetailsHeaderWrapTexts>
                    </Col>
                    {haveProgress && (
                        <Col xs={12} sm={8} md={7} lg={7}>
                            {isLoadingProgress ? (
                                <SubjectScreenDetailsHeaderSkeletonProgress />
                            ) : (
                                <ProgressBar
                                    color={theme.colors.system.info[200]}
                                    value={progressPercentage}
                                    responsive={true}
                                    label={
                                        <>
                                            <p>
                                                {!isPromilitaresFixedNewProducts && (
                                                    <strong data-test-id="subject-progress-legend">
                                                        {progress.completedItems}/{progress.totalItems}
                                                    </strong>
                                                )}

                                                {progressLabel}
                                            </p>
                                            <p>
                                                <strong data-test-id="subject-progress-value">{progressPercentage}%</strong>
                                            </p>
                                        </>
                                    }
                                />
                            )}
                        </Col>
                    )}
                </Row>
            </Grid>
        </S.SubjectScreenDetailsHeaderContainer>
    );
};

export default memo(SubjectScreenDetailsHeader);
