import { MigrationManifest, PersistedState } from "redux-persist";

/**
 * To clear store
 * [newVersionNumber]: ({ _persist }: PersistedState) => ({ _persist })
 */
const migrations: MigrationManifest = {
    1: (state: PersistedState) => state,
    2: ({ _persist }: PersistedState) => ({ _persist }),
    3: (state: PersistedState) => state,
    4: ({ _persist }: PersistedState) => ({ _persist }),
    5: ({ _persist }: PersistedState) => ({ _persist })
};

export default migrations;
