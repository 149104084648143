import React, { useEffect, useState } from "react";

import { SwitchButton, SwitchLabel, SwitchCircle, SwitchRect } from "./styles";
import { FlatVariant } from "utils/types/Variant";

interface ISwitchProps {
    defaultLabel?: string;
    activeLabel?: string;
    initialValue: boolean;
    onChange(active: boolean): void;
    variant?: FlatVariant;
}

const Switch = ({ defaultLabel, activeLabel, initialValue, onChange, variant = "success" }: ISwitchProps) => {
    const [active, setActive] = useState(initialValue);

    const handleChange = () => {
        setActive(!active);
        onChange(!active);
    };

    useEffect(() => {
        setActive(initialValue);
    }, [initialValue]);

    return (
        <SwitchButton onClick={handleChange} active={active} variant={variant} data-test-id="switch-button">
            <SwitchRect active={active} variant={variant} id={active ? "active" : "inactive"}>
                <SwitchCircle active={active} variant={variant} />
            </SwitchRect>
            {defaultLabel && activeLabel && (
                <SwitchLabel data-test-id="switch-label">
                    {!active && defaultLabel}
                    {active && activeLabel}
                </SwitchLabel>
            )}
        </SwitchButton>
    );
};

export default Switch;
