// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    colorNumber: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;

    .textHour {
        color: ${theme.colors.black};
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .setHour {
        color: ${theme.colors.system.danger[300]};
        min-width: 48px;
        display: flex;
        justify-content: end;
        font-size: 24px;
        line-height: 36px;
    }

    ${({ colorNumber }) =>
        !!colorNumber &&
        css`
            .setHour {
                color: ${theme.colors.system.success[300]};
            }
        `}

    .textMinHour {
        color: ${theme.colors.base[300]};
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        letter-spacing: 0.01em;
    }

    @media screen and (min-width: 500px) and (max-width: 767px) {
        .textHour,
        .textMinHour {
            justify-content: center;
        }
    }
`;
