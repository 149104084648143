import React, { memo } from "react";

import Dropzone from "components/Dropzone";

import * as S from "./styles";

interface IEssayAttachProps {
    files: File[];
    onSend(files: File[]): void;
    onUpload(files: File[]): void;
    onClearFiles(): void;
}

const EssayAttach = ({ files, onClearFiles, onSend, onUpload }: IEssayAttachProps) => {
    return (
        <S.EssayAttachContainer>
            <S.EssayAttachCol>
                <S.EssayAttachInstrucions>
                    <S.EssayAttachInstrucionsItem>Baixe o modelo da folha de redação;</S.EssayAttachInstrucionsItem>
                    <S.EssayAttachInstrucionsItem>Redija sua redação de acordo com o tema;</S.EssayAttachInstrucionsItem>
                    <S.EssayAttachInstrucionsItem>Digitalize sua redação;</S.EssayAttachInstrucionsItem>
                    <S.EssayAttachInstrucionsItem>Envie de volta.</S.EssayAttachInstrucionsItem>
                </S.EssayAttachInstrucions>
                <Dropzone accept="image/x-png,image/gif,image/jpeg" files={files} onUpload={onUpload} onClearFiles={onClearFiles} onSend={onSend} />
            </S.EssayAttachCol>
        </S.EssayAttachContainer>
    );
};

export default memo(EssayAttach);
