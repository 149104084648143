import { Grid, Row, Col } from "components/Grid";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { ErrorUIContainer } from "components/ErrorUI/styles";
import styled, { css } from "styled-components/macro";
import {
    WeekPlansScreenDetailsDonutsContainer,
    WeekPlansScreenDetailsDonutsRing,
    WeekPlansScreenDetailsDonutsLine,
    WeekPlansScreenDetailsDonutsCompleted
} from "./Donuts/styles";

export const WeekPlansGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        max-height: 100%;
    }
`;

export const WeekPlansScroll = styled(Row)`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex: 1;

        overflow-y: auto;
        position: relative;
    }

    /* total width */
    ::-webkit-scrollbar {
        background-color: transparent;
        width: 0px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
        background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0px;
        border: 0px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
`;

export const WeekPlansActiveModuleContainer = styled(Col)`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: sticky;
    top: 0;
`;

export const WeekPlansActiveModuleCard = styled.div<{ withBlur?: boolean }>`
    background: ${theme.colors.white};
    padding: ${theme.spacing.small} ${theme.spacing.smallX};
    border-radius: 4px;
    box-shadow: ${theme.shadow[1]};
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 20px;
    margin-top: 0;

    overflow-y: auto;
    max-height: 100%;

    ${({ withBlur }) =>
        !!withBlur &&
        css`
            filter: blur(2px);
            /* opacity: 0.7; */
        `};

    ${ErrorUIContainer} {
        padding: ${theme.spacing.small};
    }
`;

export const WeekPlansActiveModuleHeader = styled.header`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.small};
`;

export const WeekPlansActiveModuleLegend = styled.strong`
    font-size: ${theme.typography.sizes.small};
    font-weight: bold;
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const WeekPlansActiveModuleTitle = styled.h3`
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.medium};
`;

export const WeekPlansContainer = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
    }

    /* overflow-y: auto; */
`;

export const WeekPlanScreenDetailsDesktopSelectFrontStats = styled.div<{ bullColor: string }>`
    display: flex;
    align-items: center;
    font-size: ${theme.typography.sizes.small};
    justify-content: flex-end;

    ${WeekPlansScreenDetailsDonutsContainer}, ${WeekPlansScreenDetailsDonutsCompleted} {
        /* margin-right: ${theme.spacing.smallX}; */
    }

    ${WeekPlansScreenDetailsDonutsRing} {
        stroke-width: 3;
    }

    ${WeekPlansScreenDetailsDonutsLine} {
        stroke-width: 5;
    }

    strong {
        font-weight: bold;
        color: ${theme.colors.base[500]};
        margin-right: ${theme.spacing.smallX};
    }

    span {
        color: ${({ bullColor }) => bullColor};
        margin-right: ${theme.spacing.smallX};
    }

    p {
        color: ${theme.colors.base[400]};
    }
`;

export const WeekPlansContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        align-items: center;
        max-height: 100%;
        margin-left: 20px;

        div:first-child {
            padding-right: calc(${theme.spacing.largeXX} + ${theme.spacing.largeX});
            margin-bottom: 0;
        }
    }
`;

export const WrapperContentCardsSectionCareers = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 100px;
`;
