import styled from "styled-components";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import { BreadcrumbContainer } from "components/BreadCrumb/styles";
import Button from "components/Button";

export const Wrapper = styled.div`
    padding: calc((100% * 10) / 16) 0 24px;
    position: relative;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.large} 0;

        ${BreadcrumbContainer} {
            margin-bottom: ${theme.spacing.large};
        }
    }
`;

export const WatchScreenSidebarVersion2 = styled.div`
    padding: 0 ${theme.spacing.smallX};
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 40px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 0;
    }
`;

export const ContentHeaderDoubt = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: 60px;
    }
`;

export const TitleClearDoubt = styled.h3`
    font-weight: 600;
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-style: normal;
    line-height: 26px;
    color: ${theme.colors.base[400]};
`;

export const FilterDoubt = styled.div`
    display: flex;
    gap: 8px;
`;

export const ContentDoubts = styled.div`
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ButtonToReLoadPage = styled(Button)`
    width: 100%;
    margin: 8px auto 0;
`;
