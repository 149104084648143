// Dependecies
import React, { FunctionComponent, useState } from "react";

// Styles
import { Container } from "./styles";

// Components
import { WebpButton } from "components/WebpButton";

type WebpInputBoxProps = {
    defaultValue: string;
    titleBox: string;
    labelButtom: string;
    placeHolder?: string;
    onClickClose: () => void;
    onClickAction: (content: string) => void;
};

export const WebpInputBox: FunctionComponent<WebpInputBoxProps> = ({
    defaultValue,
    titleBox,
    labelButtom,
    placeHolder,
    onClickClose,
    onClickAction
}) => {
    const [content, setContent] = useState(defaultValue ?? "");

    const handleClickAction = () => {
        onClickAction(content);
    };

    const handleClickClose = () => {
        onClickClose();
    };

    return (
        <Container>
            <div className="screen">
                <div className="header" onClick={() => handleClickClose()}>
                    <h2 className="title">{titleBox}</h2>

                    <button className="button-close">X</button>
                </div>

                <div className="textarea-button">
                    <textarea
                        className="textarea"
                        onChange={(ev) => setContent(ev.target.value)}
                        placeholder={placeHolder ? placeHolder : "Escreva aqui..."}
                        value={content}
                        defaultValue={defaultValue}
                    />

                    <WebpButton size={"small"} variant={"primary"} onClick={handleClickAction} contentButtonComposition={labelButtom}></WebpButton>
                </div>
            </div>
        </Container>
    );
};
