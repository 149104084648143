import { createActions, createReducer } from "reduxsauce";
import {
    CustomListFilterCheckbox,
    CustomListFilterFormData,
    IExerciseListCustomState,
    QuestionLevelOptionToEnum,
    QuestionStatusOptionToEnum
} from "../../../interfaces/IExerciseListCustom";
import { ExerciseListCustomActionsTypes, IExerciseListCustomActions } from "./types";

export const INITIAL_STATE: IExerciseListCustomState = {
    isLoading: false,
    error: false,
    isAuthored: false,
    isFromContest: false,
    questionStatus: [],
    questionLevel: [],
    isCancelled: false,
    isFromAnotherList: false,
    listSizeLimit: 100,
    listSize: 0,
    userListSize: 0
};

interface ISetSelectedFilterAction {
    payload: {
        currentState: CustomListFilterFormData;
        dropdownData: {
            item: CustomListFilterCheckbox;
            selectedOptions: CustomListFilterCheckbox[];
        };
        stateName?: string;
    };
}

export const { Creators, Types } = createActions<Record<ExerciseListCustomActionsTypes, ExerciseListCustomActionsTypes>, IExerciseListCustomActions>({
    clearState: ["payload"],

    getAllSubjectsFailure: ["payload"],
    getAllSubjectsRequest: ["payload"],
    getAllSubjectsSuccess: ["payload"],

    getContestRequest: ["payload"],
    getContestSuccess: ["payload"],
    getContestFailure: ["payload"],

    getContestWithAuthoredQuestionsRequest: ["payload"],
    getContestWithAuthoredQuestionsSuccess: ["payload"],
    getContestWithAuthoredQuestionsFailure: ["payload"],

    getQuestionTypeRequest: ["payload"],
    getQuestionTypeSuccess: ["payload"],
    getQuestionTypeFailure: ["payload"],

    getSubtopicsRequest: ["payload"],
    getSubtopicsSuccess: ["payload"],
    getSubtopicsFailure: ["payload"],

    getTopicsRequest: ["payload"],
    getTopicsSuccess: ["payload"],
    getTopicsFailure: ["payload"],

    setUserListSizeRequest: ["payload"],
    setUserListSizeSuccess: ["payload"],
    setUserListSizeFailure: ["payload"],

    saveCustomExerciseListRequest: ["payload"],
    saveCustomExerciseListSuccess: ["payload"],
    saveCustomExerciseListFailure: ["payload"],

    getContestYearRequest: ["payload"],
    getContestYearSuccess: ["payload"],
    getContestYearFailure: ["payload"],

    getContestPhaseRequest: ["payload"],
    getContestPhaseSuccess: ["payload"],
    getContestPhaseFailure: ["payload"],

    getListSizeRequest: ["payload"],
    getListSizeSuccess: ["payload"],
    getListSizeFailure: ["payload"],

    getCustomExercisesListRequest: ["payload"],
    getCustomExercisesListSuccess: ["payload"],
    getCustomExercisesListFailure: ["payload"],

    deleteListRequest: ["payload"],
    deleteListSuccess: ["payload"],
    deleteListFailure: ["payload"],

    createAnswerCardRequest: ["payload"],
    createAnswerCardSuccess: ["payload"],
    createAnswerCardFailure: ["payload"],

    checkIfUserHasListRequest: ["payload"],
    checkIfUserHasListSuccess: ["payload"],
    checkIfUserHasListFailure: ["payload"],

    setBooleanFilterRequest: ["payload"],

    setQuestionStatusRequest: ["payload"],

    setSelectedFilterRequest: ["payload"],

    setToggleAllStateRequest: ["payload"],

    setQuestionLevelRequest: ["payload"]
});

const clearState = () => INITIAL_STATE;

const createAnswerCardRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const createAnswerCardSuccess = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
};
const createAnswerCardFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const deleteListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const deleteListSuccess = (state = INITIAL_STATE, action: any) => {
    const newState = state.customList?.items.filter((list) => list.card.listId !== action.payload);
    const customList = state.customList;

    return {
        ...state,
        isLoading: false,
        error: false,
        customList: { ...customList, items: newState }
    };
};
const deleteListFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getAllSubjectsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getAllSubjectsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        subjects: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getAllSubjectsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getContestPhaseRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getContestPhaseSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        contestPhase: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getContestPhaseFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getContestRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getContestSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        contest: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getContestFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getContestWithAuthoredQuestionsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getContestWithAuthoredQuestionsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        contestsWithAuthoredQuestions: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getContestWithAuthoredQuestionsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getContestYearRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getContestYearSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        contestYear: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getContestYearFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getCustomExercisesListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getCustomExercisesListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        customList: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getCustomExercisesListFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getListSizeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getListSizeSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        listSize: action.payload,
        isLoading: false,
        error: false
    };
};
const getListSizeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getQuestionTypeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getQuestionTypeSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        questionType: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getQuestionTypeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getSubtopicsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getSubtopicsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        subtopics: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getSubtopicsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const getTopicsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const getTopicsSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        topics: { ...action.payload },
        isLoading: false,
        error: false
    };
};
const getTopicsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const saveCustomExerciseListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const saveCustomExerciseListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        isLoading: false,
        error: false
    };
};
const saveCustomExerciseListFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const setUserListSizeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const setUserListSizeSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        userListSize: action.payload,
        isLoading: false,
        error: false
    };
};
const setUserListSizeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const checkIfUserHasListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });
const checkIfUserHasListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        userHasList: action.payload,
        isLoading: false,
        error: false
    };
};
const checkIfUserHasListFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const setSelectedFilterRequest = (state = INITIAL_STATE, action: ISetSelectedFilterAction) => {
    const dropdownDataItem = action.payload.dropdownData.item;
    const currentState = action.payload.currentState;

    const updateFilterOptions = (stateOptions: any): any => {
        return stateOptions.map((item: any) => {
            if (item.options && item.options.length > 0) {
                const itemOptions = updateFilterOptions(item.options);
                const newItem = { ...item, options: itemOptions };
                return newItem;
            }

            return item.id === dropdownDataItem.id ? { ...item, checked: dropdownDataItem.checked } : item;
        });
    };

    const updatedOptions = updateFilterOptions(currentState.options);

    return {
        ...state,
        [action.payload?.stateName as string]: { ...currentState, options: updatedOptions },
        isLoading: false,
        error: false
    };
};

const setBooleanFilterRequest = (state = INITIAL_STATE, action: any) => {
    const item: CustomListFilterCheckbox = action.payload.dropdownData;
    return {
        ...state,
        [action.payload.stateName]: item.checked,
        isLoading: false,
        error: false
    };
};

const setQuestionStatusRequest = (state = INITIAL_STATE, action: any) => {
    const selectedOption = action.payload.dropdownData.label;
    const currentState = action.payload.currentState;
    const mappedOption = QuestionStatusOptionToEnum[selectedOption];

    const newState = currentState.includes(mappedOption)
        ? currentState.filter((item: any) => item !== mappedOption)
        : [...currentState, mappedOption];

    return {
        ...state,
        questionStatus: [...newState],
        isLoading: false,
        error: false
    };
};

const setQuestionLevelRequest = (state = INITIAL_STATE, action: any) => {
    const selectedOption = action.payload.dropdownData.label;
    const currentState = action.payload.currentState;

    const mappedOption = QuestionLevelOptionToEnum[selectedOption];

    const newState = currentState.includes(mappedOption)
        ? currentState.filter((item: any) => item !== mappedOption)
        : [...currentState, mappedOption];

    return {
        ...state,
        questionLevel: [...newState],
        isLoading: false,
        error: false
    };
};

const setToggleAllStateRequest = (state = INITIAL_STATE, action: any) => {
    const { currentState, optionsSet, stateName } = action.payload;
    const newState = currentState.length === optionsSet.length ? [] : [...optionsSet];

    return {
        ...state,
        [stateName]: [...newState],
        isLoading: false,
        error: false
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.CLEAR_STATE]: clearState,

    [Types.CREATE_ANSWER_CARD_REQUEST]: createAnswerCardRequest,
    [Types.CREATE_ANSWER_CARD_SUCCESS]: createAnswerCardSuccess,
    [Types.CREATE_ANSWER_CARD_FAILURE]: createAnswerCardFailure,

    [Types.DELETE_LIST_REQUEST]: deleteListRequest,
    [Types.DELETE_LIST_SUCCESS]: deleteListSuccess,
    [Types.DELETE_LIST_FAILURE]: deleteListFailure,

    [Types.GET_ALL_SUBJECTS_REQUEST]: getAllSubjectsRequest,
    [Types.GET_ALL_SUBJECTS_SUCCESS]: getAllSubjectsSuccess,
    [Types.GET_ALL_SUBJECTS_FAILURE]: getAllSubjectsFailure,

    [Types.GET_CONTEST_PHASE_REQUEST]: getContestPhaseRequest,
    [Types.GET_CONTEST_PHASE_SUCCESS]: getContestPhaseSuccess,
    [Types.GET_CONTEST_PHASE_FAILURE]: getContestPhaseFailure,

    [Types.GET_CONTEST_REQUEST]: getContestRequest,
    [Types.GET_CONTEST_SUCCESS]: getContestSuccess,
    [Types.GET_CONTEST_FAILURE]: getContestFailure,

    [Types.GET_CONTEST_WITH_AUTHORED_QUESTIONS_REQUEST]: getContestWithAuthoredQuestionsRequest,
    [Types.GET_CONTEST_WITH_AUTHORED_QUESTIONS_SUCCESS]: getContestWithAuthoredQuestionsSuccess,
    [Types.GET_CONTEST_WITH_AUTHORED_QUESTIONS_FAILURE]: getContestWithAuthoredQuestionsFailure,

    [Types.GET_CONTEST_YEAR_REQUEST]: getContestYearRequest,
    [Types.GET_CONTEST_YEAR_SUCCESS]: getContestYearSuccess,
    [Types.GET_CONTEST_YEAR_FAILURE]: getContestYearFailure,

    [Types.GET_CUSTOM_EXERCISES_LIST_REQUEST]: getCustomExercisesListRequest,
    [Types.GET_CUSTOM_EXERCISES_LIST_SUCCESS]: getCustomExercisesListSuccess,
    [Types.GET_CUSTOM_EXERCISES_LIST_FAILURE]: getCustomExercisesListFailure,

    [Types.GET_LIST_SIZE_REQUEST]: getListSizeRequest,
    [Types.GET_LIST_SIZE_SUCCESS]: getListSizeSuccess,
    [Types.GET_LIST_SIZE_FAILURE]: getListSizeFailure,

    [Types.GET_QUESTION_TYPE_REQUEST]: getQuestionTypeRequest,
    [Types.GET_QUESTION_TYPE_SUCCESS]: getQuestionTypeSuccess,
    [Types.GET_QUESTION_TYPE_FAILURE]: getQuestionTypeFailure,

    [Types.GET_SUBTOPICS_REQUEST]: getSubtopicsRequest,
    [Types.GET_SUBTOPICS_SUCCESS]: getSubtopicsSuccess,
    [Types.GET_SUBTOPICS_FAILURE]: getSubtopicsFailure,

    [Types.GET_TOPICS_REQUEST]: getTopicsRequest,
    [Types.GET_TOPICS_SUCCESS]: getTopicsSuccess,
    [Types.GET_TOPICS_FAILURE]: getTopicsFailure,

    [Types.SAVE_CUSTOM_EXERCISE_LIST_REQUEST]: saveCustomExerciseListRequest,
    [Types.SAVE_CUSTOM_EXERCISE_LIST_SUCCESS]: saveCustomExerciseListSuccess,
    [Types.SAVE_CUSTOM_EXERCISE_LIST_FAILURE]: saveCustomExerciseListFailure,

    [Types.SET_USER_LIST_SIZE_REQUEST]: setUserListSizeRequest,
    [Types.SET_USER_LIST_SIZE_SUCCESS]: setUserListSizeSuccess,
    [Types.SET_USER_LIST_SIZE_FAILURE]: setUserListSizeFailure,

    [Types.CHECK_IF_USER_HAS_LIST_REQUEST]: checkIfUserHasListRequest,
    [Types.CHECK_IF_USER_HAS_LIST_SUCCESS]: checkIfUserHasListSuccess,
    [Types.CHECK_IF_USER_HAS_LIST_FAILURE]: checkIfUserHasListFailure,

    [Types.SET_BOOLEAN_FILTER_REQUEST]: setBooleanFilterRequest,

    [Types.SET_QUESTION_LEVEL_REQUEST]: setQuestionLevelRequest,

    [Types.SET_QUESTION_STATUS_REQUEST]: setQuestionStatusRequest,

    [Types.SET_SELECTED_FILTER_REQUEST]: setSelectedFilterRequest,

    [Types.SET_TOGGLE_ALL_STATE_REQUEST]: setToggleAllStateRequest
});
