import { BreakPoints, MediaQueries } from "assets/styles/settings";
import { Col } from "components/Grid";
import { ExerciseListLetterContainer } from "components/Letter/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";

export const ResolutionExerciseListContainer = styled.div<{ small?: boolean }>`
    width: ${({ small }) => (small ? "400px" : "1000px")};
    max-width: 100%;
    padding: ${theme.spacing.small};
    overflow-x: hidden;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: ${({ small }) => (small ? "400px" : "1200px")};
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        width: ${({ small }) => (small ? "400px" : "1400px")};
    }

    h3 {
        color: ${theme.colors.brand[300]};
        font-weight: 700;
        line-height: ${theme.typography.sizes.mediumX};
        font-size: ${theme.typography.sizes.mediumX};

        margin-bottom: ${theme.spacing.small};

        ${({ small }) =>
            small &&
            css`
                text-align: center;
            `}
    }
`;

export const ResolutionExerciseListOptionsWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ResolutionExerciseListOptions = styled.button`
    color: ${theme.colors.base[500]};
    padding: ${theme.spacing.smallX};
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 0;
    outline: none;

    :hover {
        background: ${theme.colors.base[100]};
    }

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }
`;

export const ResolutionExerciseListQuestionWrapp = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.small} 0;
    padding: 0 ${theme.spacing.smallX} ${theme.spacing.small} ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: 0;
    }
`;

export const ResolutionExerciseListQuestionCol = styled(Col)`
    position: relative;
    margin-bottom: ${theme.spacing.small};

    ${ResolutionExerciseListQuestionWrapp} {
        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow-y: auto;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.small};

        &::before {
            padding-top: calc((100% * 9) / 16);
        }
    }
`;

export const ResolutionExerciseListQuestionTitle = styled.strong`
    font-size: ${theme.spacing.small};
    font-weight: 700;
    color: ${theme.colors.brand[300]};

    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ResolutionExerciseListQuestionEnunciation = styled.div`
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
    display: block;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.small};
    }

    figure {
        display: none !important;
    }
`;

export const ResolutionExerciseListQuestionAlternative = styled.div`
    display: flex;
    color: ${theme.colors.system.success[300]};
    align-items: center;

    ${ExerciseListLetterContainer} {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ResolutionExerciseListQuestionAlternativeEnunciation = styled.div`
    flex: 1;
    display: flex;
    align-items: center;

    figure {
        display: none !important;
    }
`;

export const ResolutionExerciseListEnunciation = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    :before {
        padding-top: calc((100% * 9) / 16);
        display: block;
        content: "";
    }

    .ck-content {
        padding: 8px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        overflow-y: auto;
    }
`;

export const ResolutionExternalContentWrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    ${ResolutionExerciseListOptions} {
        display: inline-block;
        margin-top: 1rem;
    }
`;

export const ResolutionExerciseListAnswer = styled(Col)`
    @media (min-width: ${BreakPoints.medium}px) {
        min-height: 400px;
    }
`;

export const ResolutionExerciseListExtraContentWrapper = styled.div`
    width: 100%;
    margin-top: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;
