export function isCourseFree(courseSlug: string) {
    const coursesFree = [
        "pe-teste-gratis",
        "pmed-teste-gratis",
        "teste-gratis-esa",
        "teste-gratis-eear",
        "teste-gratis-cn",
        "teste-gratis-espcex",
        "teste-gratis-afa"
    ];

    return coursesFree.some((course) => course === courseSlug);
}
