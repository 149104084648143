import { theme } from "config/theme";
import styled from "styled-components/macro";
import { TitleH4, Button3 } from "assets/styles/global";

export const FilterGroupContainer = styled.div`
    padding: 0 ${theme.spacing.small};
    display: flex;
    flex-direction: column;
`;

export const FilterGroupItemContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FilterGroupTitle = styled.strong`
    ${Button3};
    color: ${theme.colors.base[400]};
    font-weight: 600;
    margin-bottom: ${theme.spacing.smallX};
`;

export const FilterGroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -${theme.spacing.smallXX};
`;

export const FilterButton = styled.button<{ isActive?: boolean; pipeColor?: string }>`
    ${Button3};
    background: ${({ isActive }) => (isActive ? theme.colors.brand[300] : "transparent")};
    border: 1px solid;
    border-color: ${({ pipeColor }) => pipeColor};
    border-radius: 20px;
    color: ${({ pipeColor }) => pipeColor};
    font-weight: 600;
    line-height: 1;
    margin: ${theme.spacing.smallXX};
    padding: ${theme.spacing.smallXX} ${theme.spacing.smallX};
`;

export const FilterGroupItems = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    ${FilterGroupItemContainer} {
        &:not(:last-child) {
            border-bottom: 1px solid ${theme.colors.base[100]};
            margin-bottom: ${theme.spacing.small};
            padding-bottom: ${theme.spacing.small};
        }
    }
`;

export const FilterModalTitle = styled.strong`
    ${TitleH4};
    color: ${theme.colors.base[500]};
    font-weight: 600;
    flex: 1;
`;

export const FilterGroupHeader = styled.div`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.small};
`;

export const FilterGroupHeaderButton = styled.button`
    border: none;
    background: transparent;
    padding: 0%;
    color: ${theme.colors.brand[300]};
    font-size: ${theme.typography.sizes.smallX};
    font-weight: 600;
`;
