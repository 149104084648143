import React from "react";
import { theme } from "config/theme";
import { ExerciseListKnowledgeAreaContainer } from "./styles";

interface IProps {
    color?: string | null;
    name: string;
}

const ExerciseListKnowledgeArea = ({ color, name }: IProps) => {
    return <ExerciseListKnowledgeAreaContainer color={color || theme.colors.secondary[300]}>{name}</ExerciseListKnowledgeAreaContainer>;
};

export default React.memo(ExerciseListKnowledgeArea);
