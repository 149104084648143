import React, { memo, useState, useCallback, useMemo, useEffect } from "react";
import history from "services/history";
import { useLocation } from "react-router";
import { DateTime } from "luxon";
import _delay from "lodash/delay";

import useIsMobile from "hooks/use-is-mobile";
import { theme } from "config/theme";

import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { ICareer, IScheduleDayButtonIcon } from "store/interfaces/ILessonPlanCustom";
import { Types as LessonPlanCustomTypes, Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as modalActions } from "store/ducks/modal";

import Button from "components/Button";
import { Grid, Row, Col } from "components/Grid";
import { LessonPlanCustomCareer } from "./components/Career";
import { LessonPlanCustomSchedules } from "./components/Schedules";
import { LessonPlanCustomStudyPlan } from "./components/StudyPlan";
import Spinner from "components/Spinner";
import ScreenZero from "./components/screenZero";

import { WebpChip } from "components/WebpChip";
import { WebpFilterList } from "components/WebpFilterList";
import { WebpFilterItem } from "components/WebpFilterItem";
import { WebpStep } from "components/WebpStep";
import { WebpStepDot } from "components/WebpStepDot";
import { WebpStepGroup } from "components/WebpStepGroup";
import CalendarIcon from "prodigio-icons/web/filled/Calendar";

import { StepListType } from "./enums/StepListType";
import { WeekDayType, ShiftType } from "enums/WeekDayLessonPlanType";

import {
    LessonPlanCustomHeaderContainer,
    LessonPlanCustomHeader,
    LessonPlanCustomTitle,
    LessonPlanCustomSubTitle,
    LessonPlanCustomColumn,
    LessonPlanCustomFooterContainer,
    LessonPlanCustomFooterContent,
    LessonPlanCustomWebpFilterList,
    LessonPlanCustomCalendarContent,
    LessonPlanCustomCalendar,
    LessonPlanCustomCalendarInfo,
    ExerciseCardTitle
} from "./styles";

import dataMock from "./components/Schedules/dataMock";

const LessonPlanCustomWizard = () => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { pathname } = useLocation();

    const dateStartStore = localStorage.getItem("date-start");
    const dateEndStore = localStorage.getItem("date-end");

    const {
        careers,
        career: careerStore,
        totalHoursWeekTime,
        scheduleDays,
        minimumHoursRecommended,
        resetPlan,
        isDisabledSchedule,
        lessonPlanCustom,
        isLoadingSetTotalHoursWeek,
        isPlanCreated
    } = useSelector(({ lessonPlanCustom }: IReduxStore) => lessonPlanCustom);

    const { isPublished: hasSuggestedLessonPlan, courseSlug } = useSelector(({ weekPlans, course }: IReduxStore) => ({
        ...weekPlans,
        courseSlug: course.slug
    }));

    const careerFormatted = useMemo(() => careers?.map((item) => ({ value: item.id, label: item.name })), [careers]);
    const getCareers = useCallback(() => dispatch(lessonPlanCustomActions.getLessonPlanCustomCareersRequest()), [dispatch]);

    const [career, setCareer] = useState<ICareer | undefined>(careerStore);
    const [totalHours, setTotalHours] = useState(totalHoursWeekTime);
    const [schedulesDataList, setSchedulesDataList] = useState(dataMock);
    const [dateStart, setDateStart] = useState<string | undefined>(dateStartStore || undefined);
    const [dateEnd, setDateEnd] = useState<string | undefined>(dateEndStore || undefined);
    const [weekTotal, setWeekTotal] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
    const [restartPlan, setRestartPlan] = useState<boolean>();
    const [hourRecommended, setHourRecommended] = useState(0);
    const [clearFullCalendar, setClearFullCalendar] = useState(false);

    const dateEnem = "";
    const AddFilterItem = totalHours >= hourRecommended ? true : false;

    const currentPath = window.location.pathname;
    const isBrandProenem = theme.project.brand === "proenem";
    const isBrandPromilitares = theme.project.brand === "promilitares";
    const isBrandPromedicina = theme.project.brand === "promedicina";
    const isCareersPage = useMemo(() => currentPath.includes("escolha-sua-carreira"), [currentPath]);
    const isResetPlanPage = useMemo(() => currentPath.includes("escolha-como-iniciar"), [currentPath]);

    const informativeText =
        isBrandPromilitares || isBrandPromedicina
            ? "Comece informando a duração do seu plano de estudos."
            : "Comece informando que carreira deseja seguir.";

    useEffect(() => {
        if (isBrandProenem && isCareersPage) {
            getCareers();
        }
    }, [isBrandProenem, dispatch, getCareers, isCareersPage]);

    useEffect(() => {
        if (!scheduleDays?.days) {
            setSchedulesDataList(scheduleDays);
        }
    }, [scheduleDays]);

    useEffect(() => {
        _delay(() => {
            setIsLoading(false);
        }, 600);
    }, []);

    useEffect(() => {
        if (clearFullCalendar) {
            _delay(() => {
                setClearFullCalendar(false);
            }, 600);
        }
    }, [clearFullCalendar]);

    useEffect(() => {
        if (!!isPlanCreated) {
            localStorage.removeItem("date-start");
            localStorage.removeItem("date-end");

            dispatch(modalActions.closeModal());
            dispatch(
                modalActions.openModal("confirmAction", {
                    type: "OPEN_MODAL",
                    hasCloseButton: false,
                    title: "Tudo certo!",
                    text: "O seu plano de estudos personalizado foi criado com sucesso!",
                    variant: "outline-success",
                    buttonText: "Ver meu plano",
                    onConfirm: () => {
                        dispatch(lessonPlanCustomActions.postLessonPlanSelectionRequest({ selection: 1 }));

                        dispatch(lessonPlanCustomActions.clearLessonPlanCustom());

                        handleClearScheduleButtons();
                    }
                })
            );
        }
    }, [isPlanCreated, dispatch]);

    // Career
    const handleItemCareerSelected = useCallback(
        (event) => {
            const data = {
                id: event.value,
                name: event.label
            };
            setCareer(data);

            dispatch({ type: LessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_CAREER_REQUEST, payload: data });
        },
        [dispatch]
    );

    // Schedule
    const handleClearScheduleButtons = () => {
        const event = new Event("resetButtonIconStatus");
        window.dispatchEvent(event);

        dataMock.days.map((item) => item.buttonIcon.map((status) => (status.selected = false)));
    };

    const handleRemoveFilterItemHours = useCallback(() => {
        setTotalHours(0);

        handleClearScheduleButtons();

        localStorage.setItem("date-start", "");
        localStorage.setItem("date-end", "");

        const payload = { totalHoursWeekTime: 0, data: dataMock };
        dispatch(lessonPlanCustomActions.setLessonPlanCustomTotalHoursWeekTimeRequest(payload));
    }, [dispatch]);

    const handleRemoveFilterStudyPlan = useCallback(() => {
        setDateStart("");
        setDateEnd("");
        setWeekTotal(0);
        setClearFullCalendar(true);

        localStorage.setItem("date-start", "");
        localStorage.setItem("date-end", "");
    }, []);

    const handleSetHour = useCallback(
        (timer: number) => {
            if (!isDisabledSchedule) {
                setTotalHours(timer);

                const payload = { totalHoursWeekTime: timer, data: dataMock };
                dispatch(lessonPlanCustomActions.setLessonPlanCustomTotalHoursWeekTimeRequest(payload));
            }
        },
        [dispatch, isDisabledSchedule]
    );

    // Global
    const handleSubmit = useCallback(() => {
        const startDate = dateStartStore ? DateTime.fromFormat(dateStartStore, "dd/LL/yyyy").toFormat("yyyy-MM-dd") : null;
        const endDate = dateEndStore ? DateTime.fromFormat(dateEndStore, "dd/LL/yyyy").toFormat("yyyy-MM-dd") : null;

        dispatch(
            modalActions.openModal("confirmAction", {
                type: "OPEN_MODAL",
                hasCloseButton: false,
                hasCancelButton: false,
                hasButton: false,
                title: "Configurando...",
                text: "Aguarde enquanto seu plano de estudos está sendo montado.",
                variant: "info"
            })
        );

        const weekDaysFormatted = schedulesDataList?.days
            .filter((item) => item.buttonIcon.some((status) => status.selected))
            .map((item: any) => {
                const ids = item.buttonIcon.filter((status: IScheduleDayButtonIcon) => status.selected).map((item: any) => ShiftType[item.label]);
                return {
                    day: WeekDayType[item.labelDay],
                    shifts: ids
                };
            });

        const payload = {
            career: career?.id,
            startDate,
            endDate,
            weekDays: weekDaysFormatted,
            contentApproach: resetPlan ? 0 : 1
        };

        dispatch(lessonPlanCustomActions.postLessonPlanCustomRequest(payload));
    }, [career, dateEndStore, dateStartStore, dispatch, resetPlan, schedulesDataList]);

    const handleCheckSchedule = useCallback(() => {
        if (!!dateEnem.length && dateEnd && isBrandProenem) {
            const endDate = DateTime.fromFormat(dateEnd, "dd/LL/yyyy").toFormat("yyyy-MM-dd");
            const diffEnemInDays = DateTime.fromISO(endDate)
                .diff(DateTime.fromISO(dateEnem))
                .as("days");

            if (diffEnemInDays > 0) {
                dispatch(
                    modalActions.openModal("confirmAction", {
                        type: "OPEN_MODAL",
                        hasCloseButton: false,
                        hasCancelButton: true,
                        title: "Data pós ENEM",
                        text: "Você terminará seus estudos depois da data do ENEM, tem certeza disso?",
                        variant: "warning",
                        variantCancelButton: "outline-warning",
                        onConfirm: () => handleSubmit(),
                        buttonText: "Confirmar",
                        cancelButtonText: "Voltar"
                    })
                );
                return;
            }
        }

        handleSubmit();
    }, [dateEnd, dispatch, handleSubmit, isBrandProenem]);

    const handleClearItensSelected = useCallback(() => {
        setCareer(undefined);
        setTotalHours(0);
        getCareers();
        setSelectedPlan(undefined);
        handleRemoveFilterItemHours();

        dispatch({ type: LessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_CAREER_REQUEST, payload: undefined });

        history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-sua-carreira` });
    }, [courseSlug, dispatch, getCareers, handleRemoveFilterItemHours]);

    const getNavigate = useMemo(() => {
        if (currentPath.includes("/meu-plano-de-estudos-wizard/escolha-como-iniciar")) {
            return {
                step: 0,
                next: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/${
                    isBrandPromilitares || isBrandPromedicina ? "configure-seu-plano" : "escolha-sua-carreira"
                }`,
                isValid: !!selectedPlan
            };
        }

        if (currentPath.includes("/meu-plano-de-estudos-wizard/escolha-sua-carreira")) {
            return {
                step: 1,
                next: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/configure-seu-plano`,
                isValid: !!career
            };
        }

        if (currentPath.includes("/meu-plano-de-estudos-wizard/escolha-seus-horarios")) {
            return {
                step: 2,
                next: "submit",
                isValid: AddFilterItem
            };
        }

        if (currentPath.includes("/meu-plano-de-estudos-wizard/configure-seu-plano")) {
            return {
                step: 3,
                next: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-seus-horarios`,
                isValid: weekTotal >= minimumHoursRecommended
            };
        }

        return {
            step: 1,
            next: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-seus-horarios`,
            isValid: !!career
        };
    }, [currentPath, courseSlug, career, isBrandPromilitares, isBrandPromedicina, selectedPlan, AddFilterItem, weekTotal, minimumHoursRecommended]);

    const handleNavigate = useCallback(() => {
        if (getNavigate.next === "submit") {
            return handleCheckSchedule();
        }

        if (isResetPlanPage && !restartPlan) {
            return dispatch(lessonPlanCustomActions.postLessonPlanSelectionRequest({ selection: 1 }));
        }

        return history.push({ pathname: getNavigate.next });
    }, [dispatch, getNavigate.next, handleCheckSchedule, isResetPlanPage, restartPlan]);

    // Career
    const handleRemoveItemCareer = useCallback(() => {
        setCareer(undefined);
        dispatch({ type: LessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_CAREER_REQUEST, payload: undefined });

        if ([2, 3].includes(getNavigate.step) && !career) {
            history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-sua-carreira` });
        }
    }, [career, courseSlug, dispatch, getNavigate.step]);

    const handleSelectedReleasePlan = useCallback(
        (slug: string, resultRestart: boolean) => {
            setSelectedPlan(slug);
            setRestartPlan(resultRestart);

            dispatch({ type: LessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_RESET_PLAN_REQUEST, payload: { resetPlan: resultRestart } });
        },
        [dispatch]
    );

    const shouldHiddenBackButtonInStep0 = getNavigate.step === 0 && !hasSuggestedLessonPlan;
    const shouldHiddenBackButtonInStep1 = getNavigate.step === 1 && !hasSuggestedLessonPlan && !lessonPlanCustom.length;
    const shouldHiddenBackButtonInStep3 = getNavigate.step === 3 && !hasSuggestedLessonPlan && !lessonPlanCustom.length && !isBrandProenem;

    const showBackButton = !(shouldHiddenBackButtonInStep0 || shouldHiddenBackButtonInStep1 || shouldHiddenBackButtonInStep3);

    const shouldDisplayEmptyStateFilterList =
        !!career || ([2, 3].includes(getNavigate.step) && !!dateStart && !!dateEnd) || (AddFilterItem && [2].includes(getNavigate.step));

    return isLoading ? (
        <Spinner />
    ) : (
        <Grid fluid>
            <Row>
                <Col xs={12} sm={12} md={12}>
                    <LessonPlanCustomHeaderContainer>
                        <LessonPlanCustomHeader>
                            <LessonPlanCustomTitle>Monte o seu plano</LessonPlanCustomTitle>
                        </LessonPlanCustomHeader>
                        <LessonPlanCustomSubTitle>Configure um plano de estudos adaptado à sua rotina.</LessonPlanCustomSubTitle>
                    </LessonPlanCustomHeaderContainer>
                </Col>
            </Row>

            <LessonPlanCustomColumn>
                <Col xs={12} sm={12} md={3}>
                    <WebpStepGroup orientationGroup={isMobile ? "horizontal" : "vertical"}>
                        {isResetPlanPage || resetPlan ? (
                            <WebpStepDot
                                status={getNavigate.step === 0 ? "selected" : [1, 2, 3].includes(getNavigate.step) ? "completed" : "disabled"}
                                alignDots="left"
                                orientation={isMobile ? "horizontal" : "vertical"}
                            >
                                {!isMobile && <WebpStep active={getNavigate.step === 0} label="Escolha como começar"></WebpStep>}
                            </WebpStepDot>
                        ) : null}
                        {isBrandProenem && (
                            <WebpStepDot
                                status={getNavigate.step === 1 ? "selected" : [2, 3].includes(getNavigate.step) ? "completed" : "disabled"}
                                alignDots={isResetPlanPage || resetPlan ? "center" : "left"}
                                orientation={isMobile ? "horizontal" : "vertical"}
                            >
                                {!isMobile && <WebpStep active={getNavigate.step === StepListType.Career} label="Escolha sua carreira"></WebpStep>}
                            </WebpStepDot>
                        )}
                        <WebpStepDot
                            status={
                                getNavigate.step === StepListType.StudyPlan
                                    ? "selected"
                                    : [2, 3].includes(getNavigate.step)
                                    ? "completed"
                                    : "disabled"
                            }
                            alignDots={isBrandProenem || isBrandPromilitares || isBrandPromedicina ? "center" : "left"}
                            orientation={isMobile ? "horizontal" : "vertical"}
                        >
                            {!isMobile && <WebpStep active={getNavigate.step === StepListType.StudyPlan} label="Configure seu plano"></WebpStep>}
                        </WebpStepDot>
                        <WebpStepDot
                            status={getNavigate.step === StepListType.Schedules ? "selected" : "disabled"}
                            alignDots="right"
                            orientation={isMobile ? "horizontal" : "vertical"}
                        >
                            {!isMobile && <WebpStep active={getNavigate.step === StepListType.Schedules} label="Escolha seus horários"></WebpStep>}
                        </WebpStepDot>
                    </WebpStepGroup>
                </Col>
                <Col xs={12} sm={12} md={6}>
                    {pathname.includes("/meu-plano-de-estudos-wizard/escolha-como-iniciar") && (
                        <ScreenZero handleSelectedReleasePlan={handleSelectedReleasePlan} selectedPlan={selectedPlan} />
                    )}

                    {pathname.includes("/meu-plano-de-estudos-wizard/escolha-sua-carreira") && (
                        <LessonPlanCustomCareer careers={careerFormatted} career={career} onHandleCareer={handleItemCareerSelected} />
                    )}

                    {pathname.includes("/meu-plano-de-estudos-wizard/configure-seu-plano") && (
                        <LessonPlanCustomStudyPlan
                            dateStart={dateStart}
                            dateEnd={dateEnd}
                            setDateStart={setDateStart}
                            setDateEnd={setDateEnd}
                            weekTotal={weekTotal}
                            setWeekTotal={setWeekTotal}
                            onClearCalendar={handleRemoveFilterStudyPlan}
                            clearFullCalendar={clearFullCalendar}
                        />
                    )}
                    {pathname.includes("/meu-plano-de-estudos-wizard/escolha-seus-horarios") && (
                        <LessonPlanCustomSchedules
                            totalHours={totalHours}
                            onHandleSetHour={handleSetHour}
                            dataOptions={schedulesDataList}
                            hourRecommended={hourRecommended}
                            setHourRecommended={setHourRecommended}
                            disabled={isDisabledSchedule || isLoadingSetTotalHoursWeek}
                        />
                    )}
                </Col>
                <Col xs={12} sm={12} md={3}>
                    {isBrandProenem && [3].includes(getNavigate.step) && !!dateEnem.length && (
                        <LessonPlanCustomCalendarContent>
                            <LessonPlanCustomCalendar>
                                <CalendarIcon width={26} height={26} />
                            </LessonPlanCustomCalendar>
                            <LessonPlanCustomCalendarInfo>
                                <ExerciseCardTitle>ENEM 2022</ExerciseCardTitle>
                                <span>1º dia de prova: 13 de novembro</span>
                            </LessonPlanCustomCalendarInfo>
                        </LessonPlanCustomCalendarContent>
                    )}

                    {getNavigate.step !== 0 && (
                        <LessonPlanCustomWebpFilterList>
                            <WebpFilterList
                                onClearButton={handleClearItensSelected}
                                titleText="Seu plano de estudos:"
                                showClearButton={!!informativeText ? true : false}
                                descriptionText={informativeText}
                                shouldDisplayEmptyState={!!shouldDisplayEmptyStateFilterList}
                                type={"lesson-plan-custom"}
                                filterItemComposition={
                                    <>
                                        {career && (
                                            <WebpFilterItem
                                                titleText="Carreira"
                                                optionsComposition={
                                                    <WebpChip
                                                        selected={false}
                                                        wrapLine
                                                        withCloseButton
                                                        label={career.name}
                                                        onChipCloseButtonClicked={handleRemoveItemCareer}
                                                    />
                                                }
                                            />
                                        )}

                                        {[2, 3].includes(getNavigate.step) && dateStart && dateEnd && (
                                            <WebpFilterItem
                                                titleText="Configure seu plano"
                                                optionsComposition={
                                                    <WebpChip
                                                        selected={false}
                                                        wrapLine
                                                        withCloseButton
                                                        label={`de ${dateStart} até ${dateEnd}`}
                                                        onChipCloseButtonClicked={handleRemoveFilterStudyPlan}
                                                    />
                                                }
                                            />
                                        )}

                                        {AddFilterItem && [2].includes(getNavigate.step) && (
                                            <WebpFilterItem
                                                titleText="Horas por semana"
                                                optionsComposition={
                                                    <WebpChip
                                                        selected={false}
                                                        wrapLine
                                                        withCloseButton
                                                        label={`${totalHours} Horas`}
                                                        onChipCloseButtonClicked={handleRemoveFilterItemHours}
                                                    />
                                                }
                                            />
                                        )}
                                    </>
                                }
                            ></WebpFilterList>
                        </LessonPlanCustomWebpFilterList>
                    )}
                </Col>
            </LessonPlanCustomColumn>

            <Row>
                <Col xs={12} sm={12} md={12}>
                    <LessonPlanCustomFooterContainer>
                        <LessonPlanCustomFooterContent>
                            {showBackButton && (
                                <Button size="medium" variant="outline-primary" onClick={() => history.goBack()}>
                                    Voltar
                                </Button>
                            )}
                            <Button disabled={!getNavigate.isValid} type="button" variant="primary" size="medium" onClick={handleNavigate}>
                                Continuar
                            </Button>
                        </LessonPlanCustomFooterContent>
                    </LessonPlanCustomFooterContainer>
                </Col>
            </Row>
        </Grid>
    );
};

export default memo(LessonPlanCustomWizard);
