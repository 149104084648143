// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    orientationGroup: "horizontal" | "vertical";
};

export const Container = styled.div<ContainerStyleProps>`
    ${({ orientationGroup }) =>
        orientationGroup === "vertical" &&
        css`
            display: block;
        `}

    ${({ orientationGroup }) =>
        orientationGroup === "horizontal" &&
        css`
            display: flex;
        `}
`;
