// Dependencies
import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import { fadein } from "./animations";
import { Link } from "react-router-dom";
import { MediaQueries } from "./settings";
import { CKEditorDefaultStyles } from "./ckeditor";
import styled, { createGlobalStyle, css } from "styled-components";
import { ToastifyStyles } from "./toastify";
import { FlatpickrDefaultStyles } from "./flatpickr";

export const Normalize = css`
    @import url("https://fonts.googleapis.com/css?family=Nunito|Nunito+Sans:400,600,700,900&display=swap");

    ${ToastifyStyles};

    *::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 7px;
        position: absolute;
        right: 0;
    }

    *::-webkit-scrollbar-track {
        border-radius: 6px;
        background-color: transparent;
        background-color: rgba(0, 0, 0, 0.03);
        position: absolute;
        right: 0;
    }

    *::-webkit-scrollbar-track:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }

    *::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 6px;
        border: 5px solid transparent;
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
    }

    *::-webkit-scrollbar-button {
        display: none;
    }

    *:not([slot]) {
        margin: 0;
        box-sizing: border-box;
    }

    *:before,
    *:after {
        box-sizing: border-box;
    }

    html,
    body {
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        margin: 0;
    }

    body {
        overflow-y: hidden;
    }

    html {
        background: ${theme.colors.base[100]};
        font-family: sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    menu,
    nav,
    section,
    summary {
        display: block;
    }

    audio,
    canvas,
    progress,
    video {
        display: inline-block;
        vertical-align: baseline;
    }

    audio:not([controls]) {
        display: none;
        height: 0;
    }

    [hidden],
    template {
        display: none;
    }

    a {
        background-color: transparent;
        text-decoration: none;
    }

    a:active,
    a:hover {
        outline: 0;
        text-decoration: none;
    }

    abbr[title] {
        border-bottom: 1px dotted;
    }

    b,
    strong {
        font-weight: bold;
    }

    dfn {
        font-style: italic;
    }

    mark {
        background: #ff0;
        color: #000;
    }

    small {
        font-size: 80%;
    }

    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    sup {
        top: -0.5em;
    }

    sub {
        bottom: -0.25em;
    }

    img {
        border: 0;
        vertical-align: middle;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    figure {
        margin: 0;
    }

    hr {
        height: 0;
        box-sizing: content-box;
    }

    pre {
        overflow: auto;
    }

    code,
    kbd,
    pre,
    samp {
        font-family: monospace, monospace;
        font-size: 1em;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        background: ${theme.colors.white};
        color: inherit;
        font: inherit;
        margin: 0;
        outline: 0;
        padding: 0;
    }

    button {
        border: none;
        background-color: transparent;
        outline: 0;
        padding: 0;
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    button,
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button;
        cursor: pointer;
        padding: 0;
    }

    button[disabled],
    html input[disabled] {
        cursor: no-drop;
    }

    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    input {
        line-height: normal;
        border: 0;
    }

    input[type="checkbox"],
    input[type="radio"] {
        padding: 0;
        box-sizing: border-box;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        height: auto;
    }

    input[type="search"] {
        -webkit-appearance: textfield;
        box-sizing: content-box;
    }

    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    fieldset {
        padding: 0;
        margin: 0;
        border: 0;
        min-width: 0;
    }

    legend {
        border: 0;
        padding: 0;
    }

    textarea {
        border: none;
        overflow: auto;
    }

    optgroup {
        font-weight: bold;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    td,
    th {
        padding: 0;
    }

    address {
        font-style: normal;
    }

    ul,
    ol,
    li {
        list-style: none;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        line-height: 1;
    }

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
        }
    }
`;

export const GlobalStyles = createGlobalStyle`
    ${Normalize}

    /* CK EDITOR */
    ${CKEditorDefaultStyles}

    /* FLATPICKR */
    ${FlatpickrDefaultStyles}

    html,
    body {
        font-family: ${theme.typography.family.primary};
    }
    
    html,
    body,
    button {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    .open {
        overflow: hidden;
    }

    h2 {
        color: ${theme.colors.brand[300]};
        font-size: ${theme.typography.sizes.mediumX};
        font-weight: 600;
    }


    .Toastify__toast-container {
        font-size: ${theme.typography.sizes.medium};
        z-index: 99999;
    }

    .Toastify__toast {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .ReactModal__Overlay {
		opacity: 0;
		transition: opacity 400ms ease-in-out;

		.modal-content {transform: translate(-50%, -50%) scale(0);}
	}

	.ReactModal__Overlay--after-open{
		opacity: 1;

		.modal-content {transform: translate(-50%, -50%) scale(1);}
	}

	.ReactModal__Overlay--before-close{
		opacity: 0;

		.modal-content {transform: translate(-50%, -50%) scale(0);}
	}

	.overlay {
		background: rgba(0,0,0,0.35);
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 999;
	}

	.modal-content {
        max-width: 833px;
		left: 50%;
		outline: 0;
		overflow: hidden;
		position: absolute;
		top: 50%;
		transform-origin: 50% 50%;
		transition: transform .4s ease;

		> button {
			border-radius: 50%;
			font-size: 2.6875rem;
			height: 60px;
			min-width: unset;
			padding: 0;
			position: absolute;
			right: 40px;
			top: 40px;
			width: 60px;

			&:after {
				left: 4px;
				width: calc(100% - 8px);
			}
		}

		${MediaQueries.SMALL} {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
			max-width: unset;
			overflow-y: auto;
			width: 100%;


			> button {
				font-size: 1.6875rem;
				height: 38px;
				right: 20px;
				top: 20px;
				width: 38px;

				&:after {
					height: calc(100% - 4px);
					left: 2px;
					top: 2px;
					width: calc(100% - 4px);
				}
			}
		}

		${MediaQueries.TABLET_PORTRAIT} {
            align-items: center;
            display: flex;
            justify-content: center;
			max-width: unset;
			overflow-y: auto;
			width: calc(100% - 100px);

			> button {
				font-size: 1.6875rem;
				height: 38px;
				right: 20px;
				top: 20px;
				width: 38px;

				&:after {
					height: calc(100% - 4px);
					left: 2px;
					top: 2px;
					width: calc(100% - 4px);
				}
			}
		}

		${MediaQueries.TABLET_LANDSCAPE} {
			max-width: 100%;
			overflow-y: auto;
		}

		${MediaQueries.NOTEBOOK} {
			overflow-y: auto;
		}

		${MediaQueries.DESKTOP} {
			overflow-y: auto;
		}
	}

    .carousel {
        &.slick-initialized {
            .slick-slide {
                display: inline-block !important;
                float: none;
                vertical-align: middle;
            }
        }
    }

    .scrollarea {
        .scrollbar-container {
            opacity: 1 !important;

            &.vertical {
                border-radius: ${theme.spacing.small};
                overflow: hidden;
                width: 4px !important;

                .scrollbar {
                    background: ${theme.colors.base[200]} !important;
                    border-radius: ${theme.spacing.small};
                    margin-left: 0 !important;
                    width: 100% !important;
                }
            }

            &.active,
            &:hover {
                background: ${theme.colors.base[100]} !important;
                opacity: 1 !important;
            }
        }
    }

    .card-wrapper {
        margin-bottom: ${theme.spacing.small};
    }

    .infinite-scroll-component {
        overflow: hidden !important;
    }

    ${MediaQueries.DESKTOP} {
        h2 {
            font-size: ${theme.typography.sizes.mediumXX};
            line-height: 1.39;
        }
    }

    .md-chat-widget-wrapper {
        visibility: hidden;
    }

    .md-chat-widget-btn-wrapper.md-fade-when-visible.md-fade-to-visible.md-chat-widget-btn-close{
        display: none;
    }

   
`;

export const BodyText1 = css`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.medium};
    font-weight: 400;
    line-height: 22px;
`;

export const BodyText2 = css`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.smallX};
    font-weight: 400;
    line-height: 20px;
`;

export const Button2 = css`
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    line-height: 22px;
`;

export const Button3 = css`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.smallX};
    font-weight: 600;
    line-height: 20px;
`;

export const TitleH0 = css`
    font-size: 40px;
    font-weight: 900;
    line-height: 56px;
`;

export const TitleH2 = css`
    font-size: ${theme.typography.sizes.large};
    font-weight: 700;
    line-height: 36px;
`;

export const TitleH3 = css`
    font-size: ${theme.typography.sizes.mediumXX};
    font-weight: 700;
    line-height: 28px;
`;

export const TitleH4 = css`
    font-size: ${theme.typography.sizes.mediumX};
    font-weight: 600;
    line-height: 26px;
`;

export const Micro = css`
    font-size: ${theme.typography.sizes.small};
    font-weight: 400;
    line-height: 16px;
`;

export const Label = css`
    font-size: ${theme.typography.sizes.small};
    font-weight: bold;
    line-height: 16px;
`;

export const TitleH5 = styled.h5`
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    line-height: 22px;
`;

const ContentListItemCSS = css`
    align-items: center;
    background: ${theme.colors.base[100]};
    display: flex;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};
    transition: background 0.2s ease, color 0.2s ease;

    p {
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: ${theme.colors.base[400]};

        flex: 1;

        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;

        transition: color 0.2s ease;

        strong {
            font-size: 13px;
            margin-right: ${theme.spacing.smallXX};
        }
    }

    span {
        background: transparent;
        border: 1px solid ${theme.colors.base[200]};
        border-radius: 50%;
        width: 6px;
        height: 6px;
        margin-left: ${theme.spacing.smallXX};
        margin-right: 13px; /* MELHORAR ISSO, UI COM ESPAÇAMENTO ERRADO */
        transition: background 0.2s ease;
    }

    &:hover {
        background: ${theme.colors.white};
        color: ${theme.colors.base[500]};

        span {
            background: ${theme.colors.brand[300]};
            border: 1px solid ${theme.project.slug !== "promilitares" ? theme.colors.secondary[300] : theme.colors.brand[300]};
        }
    }
`;

export const ContentList = styled.nav`
    display: flex;
    flex-direction: column;
`;

export const ContentListItemLink = styled(Link)`
    width: 100%;

    ${ContentListItemCSS};
`;

export const ContentListItemLinkExternal = styled.a`
    width: 100%;

    ${ContentListItemCSS};
`;

export const ContentListItemButton = styled.button`
    width: 100%;

    ${ContentListItemCSS};
`;

export const ContentListItemAction = styled.div`
    width: 100%;
    cursor: pointer;

    ${ContentListItemCSS};
`;

export const Divider = styled.hr`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${theme.colors.base[100]};
    text-align: center;
    margin-right: ${theme.spacing.smallX};
    margin-left: ${theme.spacing.smallX};
`;

export const PageContainer = styled.div`
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
`;

export const SelectModalListContainer = styled.div`
    width: 400px;
    max-width: 100%;

    h3 {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        padding: 25px;
    }
`;

export const SelectModalListWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    a,
    button {
        width: 100%;
        padding: 10px 25px;
        color: #9b9b9b;
        text-align: left;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        strong {
            margin-right: ${theme.spacing.smallXX};
        }

        &:hover {
            background: #f2f2f4;
            color: #7c8195;
        }
    }
`;

export const CardContainerStyle = css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadow[2]};
    border-radius: ${theme.spacing.smallX};
`;

export const CardContainerSmallStyle = css`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: ${theme.spacing.smallXX};
`;

export const ErrorMessage = styled.p`
    ${Micro};
    color: ${theme.colors.system.danger[300]};
    letter-spacing: 0.01em;
    text-align: right;
`;

export const Backdrop = styled.div`
    animation: ${fadein} 0.25s linear;
    background: ${hexToRgba(theme.colors.base[500], 0.5)};
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999992;
`;
