import React, { useEffect, useRef, useState } from "react";

// Assets
import { DropdownButton, DropdownContainer, DropdownContent, DropdownItem } from "./styles";

// Helpers
import useOnClickOutside from "hooks/use-on-click-outside";

export interface IProps {
    label: React.ReactNode;
    children: React.ReactNode;
    active?: boolean;
    direction?: "top" | "bottom";
    onClick?(): void;
    onHover?(type: "mouseenter" | "mouseleave"): void;
}

const Dropdown = ({ children, label, active = false, onClick, onHover, direction = "top" }: IProps) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        setVisible(active);
    }, [active]);

    useOnClickOutside(ref, () => {
        if (onHover) {
            return;
        }

        if (visible || active) {
            setVisible(!visible);

            return onClick && onClick();
        }
    });

    const handleClick = () => {
        if (onHover) {
            return;
        }

        setVisible(!visible);

        return onClick && onClick();
    };

    const handleMouseEvent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onClick) {
            return;
        }

        if (!onHover) {
            return;
        }

        if (event.type === "mouseenter") {
            setVisible(true);

            return onHover("mouseenter");
        }

        setVisible(false);

        return onHover("mouseleave");
    };

    return (
        <DropdownContainer ref={ref}>
            <DropdownButton
                data-test-id="dropdown-button"
                type="button"
                onClick={handleClick}
                onMouseEnter={handleMouseEvent}
                onMouseLeave={handleMouseEvent}
            >
                {label}
            </DropdownButton>
            {visible && <DropdownContent direction={direction}>{children}</DropdownContent>}
        </DropdownContainer>
    );
};

Dropdown.DropdownItem = DropdownItem;

export default Dropdown;
