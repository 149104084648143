import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { BodyText1 } from "assets/styles/global";
import { hexToRgba } from "helpers";
import { fadein } from "assets/styles/animations";
import { ButtonWrapper } from "components/Button/styles";

export const MakeEssaySwitcherWrapper = styled.div`
    border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    margin-bottom: ${theme.spacing.large};
    padding-bottom: ${theme.spacing.medium};
`;

export const EssayCanvasContainer = styled.div`
    animation: ${fadein} 0.25s linear;
    position: relative;
`;

export const EssayCanvasWrapper = styled.div`
    background: repeating-linear-gradient(${theme.colors.base[100]} 0 1px, transparent 1px 100%);
    background-size: 100% 30px;
    background-position-y: -1px;
    height: 900px;
    margin: 0 auto;
    position: relative;
    width: 740px;
`;

export const EssayCanvasLines = styled.ul`
    left: -${theme.spacing.large};
    position: absolute;
    top: 1px;
    height: 100%;
`;

export const EssayCanvasLinesItem = styled.li`
    color: ${theme.colors.base[200]};
    font-size: 12px;
    line-height: 30px;
    text-align: right;
`;

export const EssayCanvasHelper = styled.textarea`
    appearance: none;
    background: transparent;
    color: ${theme.colors.base[200]};
    font-family: "Droid Sans Mono", monospace;
    font-size: 15px;
    line-height: 30px;
    height: 100%;
    outline: none;
    overflow: hidden;
    resize: none;
    word-break: break-word;
    white-space: pre-wrap;
    width: 100%;
`;

export const EssayCanvasPrintStyles = `
    appearance: none;
    background: transparent;
    color: ${theme.colors.base[200]};
    font-family: "Droid Sans Mono", monospace;
    font-size: 15px;
    line-height: 30px;
    outline: none;
    overflow: hidden;
    resize: none;
    word-break: break-word;
    white-space: pre-wrap;
    width: 780px;
    height: 896px;
    position: relative;
    padding-left: 30px;
    padding-right: 10px;
    top: -906px;
    left: -30px;
    z-index: -1;
`;

export const EssayCanvasPrint = styled.div`
    ${EssayCanvasPrintStyles};
`;

export const EssayCanvasNav = styled.nav`
    align-items: center;
    display: flex;
    margin-top: ${theme.spacing.large};

    ${ButtonWrapper} {
        &:first-child {
            margin-right: ${theme.spacing.small};
        }

        &:last-child {
            margin-left: auto;
        }
    }
`;

export const EssayAttachContainer = styled.div`
    animation: ${fadein} 0.25s linear;
    display: flex;
`;

export const EssayAttachCol = styled.div`
    flex: 1;
    padding: ${theme.spacing.small} ${theme.spacing.large};

    &:not(:last-child) {
        border-right: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    }
`;

export const EssayAttachInstructions = styled.ul`
    list-style: disc;
    margin-bottom: ${theme.spacing.large};
`;

const EssayAttachTextStyles = css`
    ${BodyText1};
    color: ${theme.colors.base[400]};
`;

export const EssayAttachInstructionsItem = styled.li`
    ${EssayAttachTextStyles};
    list-style: disc;
    list-style-position: inside;
`;

export const MakeEssayForbiddenContainer = styled.div`
    background: ${theme.colors.white};

    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    p {
        margin-bottom: ${theme.spacing.smallX};
        text-align: center;
    }
`;

export const EssayAppStoresContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.large};

    a {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }
`;

export const EssayAttachButtonGroup = styled.div`
    display: flex;
    flex-direction: column;

    ${ButtonWrapper} {
        :first-child {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;
