// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    QuestionsContainer,
    QuestionOptionContainer,
    QuestionOptionLabel,
    QuestionOptionActionButtonCompositionsWrapper,
    QuestionOptionContentCompositionWrapper
} from "./activity-questions.styles";

// Types
import { ActivityQuestionsProps, ActivityQuestionOptionProps } from "./activity-questions.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityQuestions: FunctionComponent<ActivityQuestionsProps> = ({ questionOptionCompositions }) => {
    return <QuestionsContainer>{questionOptionCompositions}</QuestionsContainer>;
};

export const ActivityQuestionOption: FunctionComponent<ActivityQuestionOptionProps> = ({
    label,
    actionButtonAlignment = "side",
    radioComposition,
    contentComposition,
    actionButtonCompositions,
    questionIndicatorElement
}) => {
    return (
        <QuestionOptionContainer>
            {radioComposition}

            {questionIndicatorElement}

            <QuestionOptionLabel
                dangerouslySetInnerHTML={{
                    __html: label!
                }}
            />

            <QuestionOptionContentCompositionWrapper>
                {contentComposition}

                <ConditionallyRender
                    shouldRender={actionButtonAlignment === "under"}
                    content={
                        <QuestionOptionActionButtonCompositionsWrapper alignment={actionButtonAlignment}>
                            {actionButtonCompositions}
                        </QuestionOptionActionButtonCompositionsWrapper>
                    }
                />
            </QuestionOptionContentCompositionWrapper>

            <ConditionallyRender
                shouldRender={actionButtonAlignment === "side"}
                content={
                    <QuestionOptionActionButtonCompositionsWrapper alignment={actionButtonAlignment}>
                        {actionButtonCompositions}
                    </QuestionOptionActionButtonCompositionsWrapper>
                }
            />
        </QuestionOptionContainer>
    );
};
