// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    EssaySubmissionGradeWrapper,
    EssaySubmissionThemeSkeleton,
    EssaySubmissionDateWrapper,
    EssaySubmissionDateSkeleton,
    EssaySumissionCardFullWidthElement,
    EssaySubmissionSkillWrapper,
    EssaySubmissionSkillNameSkeleton,
    EssaySubmissionSkillGradeSkeleton,
    EssaySubmissionContentSkeleton
} from "./performance-essay-submission-card.styles";

// Utilities
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceEssaySubmissionCardSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <EssaySubmissionGradeWrapper>
                <Skeleton width="104px" height="104px" borderRadius="50%" />
            </EssaySubmissionGradeWrapper>

            <Spacing direction="vertical" size={16} />

            <EssaySubmissionContentSkeleton>
                <EssaySubmissionThemeSkeleton>
                    <Skeleton width="100%" height="100%" borderRadius="4px" />
                </EssaySubmissionThemeSkeleton>

                <EssaySubmissionThemeSkeleton>
                    <Skeleton width="180px" height="100%" borderRadius="4px" />
                </EssaySubmissionThemeSkeleton>

                <Spacing direction="vertical" size={8} />

                <EssaySubmissionDateWrapper>
                    <Skeleton width="20px" height="20px" borderRadius="4px" />

                    <Spacing direction="horizontal" size={8} />

                    <EssaySubmissionDateSkeleton>
                        <Skeleton width="180px" height="100%" borderRadius="4px" />
                    </EssaySubmissionDateSkeleton>
                </EssaySubmissionDateWrapper>
            </EssaySubmissionContentSkeleton>

            <Spacing direction="vertical" size={16} />

            <EssaySumissionCardFullWidthElement>
                {Array.from(Array(5)).map((_, index) => (
                    <EssaySubmissionSkillWrapper key={`performance-essay-submission-card-skill-skeleton-${index}`}>
                        <EssaySubmissionSkillNameSkeleton>
                            <Skeleton width="82px" height="100%" borderRadius="4px" />
                        </EssaySubmissionSkillNameSkeleton>

                        <Spacing direction="horizontal" size={8} />

                        <EssaySubmissionSkillGradeSkeleton>
                            <Skeleton width="29px" height="100%" borderRadius="4px" />
                        </EssaySubmissionSkillGradeSkeleton>

                        <Spacing direction="horizontal" size={24} />

                        <Skeleton width="100%" height="8px" borderRadius="4px" />
                    </EssaySubmissionSkillWrapper>
                ))}
            </EssaySumissionCardFullWidthElement>

            <Spacing direction="vertical" size={16} />

            <Skeleton width="100%" height="34px" borderRadius="4px" />
        </Container>
    );
};
