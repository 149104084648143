import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ForumNewAttachmentWrapper } from "screens/Forum/new/styles";
import { AttachmentsControllerContainer } from "screens/Forum/components/attachments-controller/styles";

export const ForumAnswerReplyWrapper = styled.div<{ color?: string; withSpacing?: boolean }>`
    flex: 1;

    ${ForumNewAttachmentWrapper} {
        align-items: flex-end;
        flex-direction: column;
    }

    ${AttachmentsControllerContainer} {
        margin-top: ${theme.spacing.small};
    }

    ${({ color, withSpacing }) => {
        if (withSpacing) {
            return css`
                background: ${color ? hexToRgba(color, 0.15) : theme.colors.base[100]};
                border-radius: ${theme.spacing.smallX};
                box-shadow: ${theme.shadow[1]};
                padding: ${theme.spacing.medium};
            `;
        }

        return css`
            box-shadow: unset;
            padding: 0;
        `;
    }}

    ${MediaQueries.SMALL} {
        width: calc(100% - 40px);
    }
`;

export const ForumAnswerReplyContainer = styled.div<{ withPadding?: boolean }>`
    align-items: flex-start;
    display: flex;
    padding-left: ${({ withPadding }) => withPadding && theme.spacing.small};

    .ico-reply {
        margin-right: ${theme.spacing.small};
    }

    ${MediaQueries.SMALL} {
        flex-wrap: wrap;

        ${({ withPadding }) => {
            if (withPadding) {
                return css`
                    padding-left: 0;
                    margin-left: -28px;
                `;
            }
        }}
    }
`;
