// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Styles
import { Container } from "./styles";

export const ColorWeight = {
    100: 100,
    200: 200,
    300: 300,
    400: 400,
    500: 500
} as const;
export type ColorWeights = typeof ColorWeight[keyof typeof ColorWeight];

export const TextStylesVariant = {
    TITLE_XSS: "title-xss",
    BODY_TEXT_01: "body-text-01",
    BODY_TEXT_02: "body-text-02",
    TITLE_SM: "title-sm",
    TITLE_MD: "title-md",
    MICROCOPY: "microcopy"
} as const;
export type TextStylesVariant = typeof TextStylesVariant[keyof typeof TextStylesVariant];

export const FlatVariants = {
    NEUTRAL: "neutral"
} as const;
export type FlatVariant = typeof FlatVariants[keyof typeof FlatVariants];

type WebpTextProps = {
    id?: string;
    className?: string;
    color?: FlatVariant;
    xs: TextStylesVariant;
    sm?: TextStylesVariant;
    md?: TextStylesVariant;
    lg?: TextStylesVariant;
    colorWeight?: ColorWeights;
};

export const WebpText: FunctionComponent<WebpTextProps> = ({ color = "neutral", colorWeight = 300, xs, sm, md, lg, children, id }) => {
    const [breakpointClass, setBreakpointClass] = useState<TextStylesVariant>();

    useEffect(() => {
        const smTemp = sm ?? xs;
        const mdTemp = md ?? smTemp;
        const lgTemp = lg ?? mdTemp;

        const winWidth = window.innerWidth;

        if (winWidth < 768) {
            setBreakpointClass(xs);
        } else if (winWidth >= 768 && winWidth < 1366) {
            setBreakpointClass(smTemp);
        } else if (winWidth >= 1366 && winWidth < 1920) {
            setBreakpointClass(mdTemp);
        } else if (winWidth >= 1920) {
            setBreakpointClass(lgTemp);
        }
    }, [lg, md, sm, xs]);

    return (
        <Container id={id} className="webp-text" colorVariant={`${color}-${colorWeight}`} textFormat={!!breakpointClass ? breakpointClass : xs}>
            {children}
        </Container>
    );
};
