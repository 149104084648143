import React, { Suspense, lazy } from "react";
import Spinner from "components/Spinner";
import ErrorBoundary from "components/ErrorBoundary";
import WatchScreenForumError from "./error";

const Forum = lazy(() => import("components/Forum"));

interface IProps {
    code?: number;
    isOpen: boolean;
}

const WatchScreenForum: React.FC<IProps> = ({ code, isOpen }) => {
    if (!code) {
        return null;
    }

    return (
        <ErrorBoundary component={<WatchScreenForumError />}>
            <Suspense fallback={<Spinner />}>
                <Forum code={code} isOpen={isOpen} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default WatchScreenForum;
