import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText1 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";

export const AttachmentsControllerContainer = styled.div`
    ${MediaQueries.SMALL} {
        margin-bottom: ${theme.spacing.small};
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex: 1;
        overflow: hidden;
    }
`;

export const AttachmentsControllerWrapper = styled.div``;

export const AttachmentsControllerHelper = styled.span`
    ${BodyText1};
    color: ${theme.colors.base[400]};
    display: block;
    padding-right: 20px;
    margin-bottom: ${theme.spacing.smallX};
    text-align: right;

    ${MediaQueries.SMALL} {
        margin-bottom: ${theme.spacing.smallXX};
        padding: 0;
        text-align: left;
    }
`;
