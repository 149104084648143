// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: flex-start;
    }
`;

export const TopicsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 16px;

    @media (min-width: 1024px) {
        margin: 0 0 0 48px;
    }
`;
