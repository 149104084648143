import React, { memo } from "react";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import * as S from "./styles";

import SubjectScreenDetailsContentPerType from "../ContentPerType";
import SubjectScreenDetailsActiveModuleSkeleton from "./skeleton";
import ErrorUI from "components/ErrorUI";
import { ILessonPlanWeeksSectionWeekListItems } from "store/interfaces/ILessonPlanCustom";
import { isCourseFree } from "helpers/isCourseFree";

interface IProps {
    contentPerType: ILessonPlanWeeksSectionWeekListItems;
    name: string;
    isLoading?: boolean;
    withBlur?: boolean;
    error: boolean;
    imageSrc?: string;
    linkHref?: string;
}

const LessonPlanCustomDetailsActiveModule = memo(
    ({ contentPerType, name = "", isLoading = true, withBlur = false, error = false, linkHref = "", imageSrc = "" }: IProps) => {
        const { courseSlug } = useSelector(({ course }: IReduxStore) => ({ courseSlug: course?.slug }));
        const hasSources = linkHref && imageSrc;
        const hasContent = contentPerType.contentPerType.some((item) => !!item.items.length);

        if (error) {
            return (
                <S.SubjectScreenDetailsActiveModuleCard withBlur={withBlur}>
                    <ErrorUI withButton={false} message="Ocorreu um erro ao carregar esse módulo." />
                </S.SubjectScreenDetailsActiveModuleCard>
            );
        }

        if (isLoading) {
            return <SubjectScreenDetailsActiveModuleSkeleton />;
        }

        return (
            <S.SubjectScreenDetailsActiveModuleCard withBlur={withBlur}>
                {hasContent && (
                    <>
                        <S.SubjectScreenDetailsActiveModuleHeader>
                            <S.SubjectScreenDetailsActiveModuleLegend>Conteúdo do módulo</S.SubjectScreenDetailsActiveModuleLegend>
                            <S.SubjectScreenDetailsActiveModuleTitle>{name}</S.SubjectScreenDetailsActiveModuleTitle>
                        </S.SubjectScreenDetailsActiveModuleHeader>

                        <SubjectScreenDetailsContentPerType contentPerType={contentPerType} />
                    </>
                )}
                {!hasContent && isCourseFree(courseSlug) && hasSources && (
                    <a target="_blank" href={linkHref} rel="noopener noreferrer" style={{ padding: "10px" }}>
                        <img loading="lazy" src={imageSrc} alt="imagem" style={{ width: "100%", borderRadius: "4px" }}></img>
                    </a>
                )}
            </S.SubjectScreenDetailsActiveModuleCard>
        );
    }
);

export default LessonPlanCustomDetailsActiveModule;
