// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

// Assets
import photoDefault from "./assets/photoDefault.svg";

type WebpImageDoubtProps = {
    photoUser?: string;
    badge?: string;
};

export const WebpImageDoubt: FunctionComponent<WebpImageDoubtProps> = ({ photoUser, badge }) => {
    return (
        <Container isPhotoUser={!!photoUser} style={{ backgroundImage: `url(${photoUser ?? photoDefault})` }}>
            {badge ? <img className="tag-user" src={badge} alt="tag" /> : null}
        </Container>
    );
};
