// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isNew: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    ${({ isNew }) =>
        !!isNew &&
        css`
            box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 16px;
        `}

    display: block;

    .wrapper-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5em;

        .left {
            width: calc(100% - 112px);

            > * {
                font-size: 0.875rem;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 1.428;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .subname {
            font-size: 0.75rem;
            font-weight: 400;
            letter-spacing: 0.01em;
            line-height: 1.333;
            color: ${theme.colors.base[300]};
        }

        .right {
            display: flex;
            align-items: center;

            .badge {
                padding: 0.25em 0.5em;
                border-radius: 1em;
                color: ${theme.colors.system.info[500]};
                background-color: ${theme.colors.system.info[100]};
                margin-right: 8px;

                font-family: "Nunito", sans-serif;
                font-style: normal;
                font-size: 0.75rem;
                font-weight: 700;
                letter-spacing: 0;
                line-height: 1.333;
            }

            .wrapper-button-dropdown {
                position: relative;
                button {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    background: ${theme.colors.base[100]};
                    border: none;
                }

                .webp-dropdown {
                    min-width: 213px;
                    right: 0;
                    position: absolute;
                }
            }
        }
    }

    .wrapper-body {
        &:after,
        &:before {
            content: "";
            width: 100%;
            height: 0.5px;
            background: ${theme.colors.base[100]};
            display: block;
        }
    }

    .wrapper-footer {
        margin-top: 0.5em;
    }

    @media (min-width: 768px) {
        .wrapper-header {
            .left {
                .name {
                    font-size: 1rem;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 1.375;
                }
            }
        }

        .wrapper-footer {
            display: flex;
            justify-content: flex-end;
            button {
                width: 7.813em;
            }
        }
    }
`;

export const WebpDropDownCompositionWrapper = styled.div`
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.428;
    background: ${theme.colors.base[100]};
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
`;
