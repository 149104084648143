import React from "react";

// Components
import Dropzone from "components/Dropzone";

// Assets
import * as U from "./styles";

interface IUploadProps {
    files: File[];
    onClearFiles: () => void;
    onUpload: (files: File[]) => void;
    onSubmit: (files: File[]) => void;
}

const Upload = ({ files, onClearFiles, onUpload, onSubmit }: IUploadProps) => {
    return (
        <U.UploadModalContainer>
            <U.UploadModalHeader>
                <U.UploadModalTitle>Upload de Anexo</U.UploadModalTitle>
            </U.UploadModalHeader>
            <U.UploadModalContent>
                <Dropzone accept="image/*" files={files} multiple={true} onUpload={onUpload} onClearFiles={onClearFiles} onSend={onSubmit} />
            </U.UploadModalContent>
        </U.UploadModalContainer>
    );
};

export default Upload;
