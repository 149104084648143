import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import React, { Fragment, memo } from "react";

export const ExamResultCardsSkeleton = memo(() => {
    return (
        <>
            {Array.from(Array(2)).map((_, index) => (
                <Skeleton
                    width="100%"
                    height="auto"
                    style={{ borderRadius: "4px", flexDirection: "column", padding: theme.spacing.small, marginBottom: theme.spacing.small }}
                    light
                    key={index}
                >
                    <Skeleton width="50%" height="28px" style={{ marginBottom: theme.spacing.smallX }} />
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: theme.spacing.small }}>
                        <Skeleton width="40%" height="26px" />
                        <Skeleton width="35%" height="26px" />
                    </div>

                    <div style={{ display: "flex" }}>
                        <Skeleton width="17px" height="16px" style={{ marginRight: theme.spacing.smallX }} />

                        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                            <Skeleton width="30%" height="22px" style={{ marginBottom: theme.spacing.smallXX }} />
                            <Skeleton width="80%" height="20px" />
                        </div>
                    </div>
                </Skeleton>
            ))}
        </>
    );
});

export const ExamResultKnowledgeAreasSkeleton = memo(() => {
    return (
        <Skeleton
            width="100%"
            height="auto"
            style={{ borderRadius: "4px", flexDirection: "column", padding: theme.spacing.small, marginBottom: theme.spacing.small }}
            light
        >
            <Skeleton width="80%" height="19px" style={{ marginBottom: theme.spacing.small }} />

            {Array.from(Array(2)).map((_, index) => (
                <Fragment key={index}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: theme.spacing.smallX }}>
                        <Skeleton width="70%" height="19px" />

                        <Skeleton width="16px" height="19px" />
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: theme.spacing.smallX }}>
                        <Skeleton width="45%" height="16px" />

                        <Skeleton width="35%" height="16px" />
                    </div>

                    <Skeleton width="100%" height="26px" style={index < 1 ? { marginBottom: theme.spacing.small } : undefined} />

                    {index < 1 && <Skeleton width="100%" height="2px" style={{ marginBottom: theme.spacing.small }} />}
                </Fragment>
            ))}
        </Skeleton>
    );
});
