// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityCheckboxProps } from "./activity-checkbox.types";

// Styles
import { Container, ActivityCheckboxWrapper, ActivityCheckboxActiveContent, Icon, Label } from "./activity-checkbox.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Assets
import check from "assets/img/check.svg";

export const ActivityCheckbox: FunctionComponent<ActivityCheckboxProps> = ({ isChecked, isDisabled, label, handleClick }) => {
    return (
        <Container className="webapp-checkbox" {...(!!handleClick && { onClick: handleClick })}>
            <ActivityCheckboxWrapper isChecked={!!isChecked} isDisabled={!!isDisabled}>
                <ActivityCheckboxActiveContent isChecked={!!isChecked} isDisabled={!!isDisabled}>
                    <ConditionallyRender shouldRender={!!isChecked} content={<Icon src={check} />} />
                </ActivityCheckboxActiveContent>
            </ActivityCheckboxWrapper>

            <ConditionallyRender shouldRender={!!label} content={<Label isChecked={!!isChecked}>{label}</Label>} />
        </Container>
    );
};
