import React, { memo } from "react";
import { useSelector } from "react-redux";

// Components
import Button from "components/Button";

// Helpers
import history from "services/history";

// Assets
import { NotFoundProject } from "screens/Projects/Details/styles";

// Types
import { IReduxStore } from "interfaces/IReduxStore";

interface IForbiddenProps {
    message?: string;
    hasButton?: boolean;
}

const Forbidden = ({ message, hasButton = true }: IForbiddenProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <NotFoundProject>
            <p>{message || "Acesso negado ao projeto."}</p>
            {hasButton && (
                <Button variant="info" onClick={() => history.push(`/app/curso/${courseSlug}/projetos`)}>
                    Voltar para Projetos
                </Button>
            )}
        </NotFoundProject>
    );
};

const memoFn = (prev: IForbiddenProps, next: IForbiddenProps) => {
    if (prev.message === next.message) {
        return true;
    }

    return false;
};

export default memo(Forbidden, memoFn);
