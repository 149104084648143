import { useEffect, useCallback } from "react";
import { KeyboardKeys } from "enums/Keys";

const BLOCK_EVENTS = ["contextmenu", "copy", "cut"];

export function useBlockCopy() {
    const disableEvent = useCallback((e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        e.preventDefault();

        return false;
    }, []);

    const disableKeys = useCallback(
        (e) => {
            // "I"
            if (e.ctrlKey && e.shiftKey && e.keyCode === KeyboardKeys.KEY_I) {
                disableEvent(e);
            }

            // "J"
            if (e.ctrlKey && e.shiftKey && e.keyCode === KeyboardKeys.KEY_J) {
                disableEvent(e);
            }

            // "S" + macOS
            if (e.keyCode === KeyboardKeys.KEY_S && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                disableEvent(e);
            }

            // "U"
            if (e.ctrlKey && e.keyCode === KeyboardKeys.KEY_U) {
                disableEvent(e);
            }

            // "F12"
            if (e.keyCode === KeyboardKeys.KEY_F12) {
                disableEvent(e);
            }
        },
        [disableEvent]
    );

    const disableAllEventCopys = useCallback(() => {
        BLOCK_EVENTS.map((event) => window.addEventListener(event, disableEvent, false));
        window.addEventListener("keydown", disableKeys, false);

        return () => {
            BLOCK_EVENTS.map((event) => window.removeEventListener(event, disableEvent, false));
            window.removeEventListener("keydown", disableKeys, false);
        };
    }, [disableKeys, disableEvent]);

    useEffect(() => disableAllEventCopys(), [disableAllEventCopys]);
}
