import React, { HTMLAttributes } from "react";

import { SkeletonItem } from "./styles";

interface IProps {
    height: string;
    width?: string;
    light?: boolean;
}

const Skeleton = (props: IProps & HTMLAttributes<HTMLSpanElement>) => <SkeletonItem {...props} />;

Skeleton.defaultProps = {
    width: "100%",
    light: false
};

export default Skeleton;
