import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { Button2 } from "assets/styles/global";

export const SwitcherContainer = styled.nav`
    align-items: center;
    display: flex;
    justify-content: center;
`;

export const SwitcherItem = styled.button<{ isActive: boolean }>`
    ${Button2};
    border-radius: ${theme.spacing.smallX};
    font-weight: 700;
    min-width: 128px;
    padding: 6px;
    position: relative;
    transform: scale(1);
    transition: all 0.2s ease;

    ${({ isActive }) => {
        if (isActive) {
            return css`
                background: ${theme.colors.white};
                box-shadow: ${theme.shadow[3]};
                color: ${theme.colors.system.info[400]};
                transform: scale(1.1764);
                z-index: 1;
            `;
        }

        return css`
            background: ${theme.colors.base[100]};
            color: ${theme.colors.base[200]};
        `;
    }}
`;
