// Dependencies
import React from "react";

// Styles
import { IconWrapper } from "components/SignScreen/styles";

interface IArchivesProps {
    color: string;
}

export const Archives = ({ color }: IArchivesProps) => (
    <IconWrapper>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.069 26.9843C19.0846 26.3757 19.112 25.7928 19.1127 25.21C19.117 22.2034 19.1176 19.1962 19.1089 16.1897C19.1083 15.9476 19.1769 15.8231 19.3925 15.7118C20.1204 15.3359 20.4326 14.6833 20.4631 13.8904C20.4831 13.3793 20.4812 12.8625 20.432 12.3538C20.3229 11.2328 19.4828 10.4859 18.348 10.4796C16.9047 10.4714 15.4613 10.4777 14.018 10.4777C13.9052 10.4777 13.7924 10.4777 13.6378 10.4777C13.6378 10.3048 13.6378 10.152 13.6378 9.99925C13.6378 9.06685 13.6335 8.13445 13.6391 7.20205C13.6453 6.10681 14.2118 5.33473 15.2183 5.06753C15.4351 5.00968 15.6701 5.00402 15.8963 5.0034C19.853 4.99962 23.8091 4.99899 27.7658 5.00151C29.1618 5.00277 29.9975 5.84274 29.9981 7.24983C30.0006 13.075 30.0006 18.8995 29.9981 24.7246C29.9975 26.1191 29.1431 26.9931 27.7696 26.9962C24.9452 27.0031 22.1202 26.9981 19.2959 26.9975C19.2061 26.9975 19.117 26.9868 19.0684 26.9836L19.069 26.9843ZM24.6249 20.4996C24.6249 20.4996 24.6249 20.5015 24.6249 20.5027C25.4974 20.5027 26.3699 20.5084 27.2417 20.5008C27.9235 20.4946 28.2843 20.2475 28.2862 19.8092C28.2881 19.3849 27.8986 19.1044 27.2479 19.1013C25.5173 19.0938 23.7867 19.0931 22.056 19.1051C21.8336 19.1063 21.5874 19.1642 21.3948 19.2509C21.0508 19.4056 20.9249 19.7288 21.0477 20.0211C21.1823 20.3418 21.4977 20.4933 21.9252 20.4971C22.8251 20.5052 23.725 20.4996 24.6249 20.4996ZM24.6249 23.6369C24.6249 23.6369 24.6249 23.6388 24.6249 23.6401C25.4974 23.6401 26.3699 23.6457 27.2417 23.6382C27.9235 23.6319 28.2843 23.3848 28.2862 22.9466C28.2881 22.5222 27.8986 22.2418 27.2479 22.2386C25.5173 22.2311 23.7867 22.2305 22.056 22.2424C21.8336 22.2437 21.5874 22.3015 21.3948 22.3883C21.0508 22.5429 20.9249 22.8661 21.0477 23.1585C21.1823 23.4791 21.4977 23.6306 21.9252 23.6344C22.8251 23.6426 23.725 23.6369 24.6249 23.6369Z"
                fill={color}
            />
            <path
                d="M3.38054 16.0212H17.0006C17.0006 16.1507 17.0006 16.2632 17.0006 16.3751C17.0006 19.3704 17.0025 22.3656 16.9969 25.3609C16.9969 25.5992 16.9788 25.8463 16.9127 26.0732C16.7308 26.6976 16.2684 26.9912 15.4906 26.9968C14.6187 27.0031 13.7469 26.9981 12.8744 26.9981C10.2482 26.9981 7.62143 27 4.99525 26.9949C4.76841 26.9949 4.53284 26.9874 4.31721 26.9252C3.80057 26.7768 3.50331 26.4052 3.40733 25.8777C3.37742 25.7142 3.38116 25.5438 3.38116 25.3766C3.37991 22.3814 3.37991 19.3861 3.37991 16.3909V16.0205L3.38054 16.0212ZM10.1703 20.1217C10.1703 20.1217 10.1703 20.1236 10.1703 20.1249C10.8234 20.1249 11.4772 20.1305 12.1303 20.123C12.6413 20.1167 12.9112 19.8696 12.9124 19.4314C12.9137 19.007 12.622 18.7266 12.1347 18.7234C10.8384 18.7159 9.54214 18.7153 8.24588 18.7272C8.07886 18.7285 7.89439 18.7863 7.75043 18.8731C7.49242 19.0277 7.39832 19.3509 7.49055 19.6433C7.59151 19.9639 7.82708 20.1154 8.14741 20.1192C8.82109 20.1274 9.4954 20.1217 10.1697 20.1217H10.1703Z"
                fill="white"
            />
            <path
                d="M10.2015 11.8747C12.6096 11.8747 15.0182 11.8747 17.4263 11.8747C18.1629 11.8747 18.3698 12.0841 18.3711 12.826C18.3711 13.1297 18.3742 13.4334 18.3711 13.737C18.3642 14.3557 18.1131 14.6103 17.4923 14.6116C15.5305 14.6147 13.5687 14.6128 11.6068 14.6128C8.74196 14.6128 5.87648 14.6128 3.01162 14.6128C2.90816 14.6128 2.80284 14.6185 2.70064 14.6059C2.25318 14.5512 2.04129 14.35 2.01761 13.8999C1.99455 13.461 1.99393 13.0197 2.01699 12.5808C2.04316 12.0759 2.29244 11.8766 2.8527 11.876C5.30251 11.8741 7.75232 11.8754 10.2021 11.8754L10.2015 11.8747Z"
                fill="white"
            />
        </svg>
    </IconWrapper>
);
