import React, { memo } from "react";
import * as S from "./styles";
import Skeleton from "components/Skeleton";

const SubjectScreenDetailsActiveModuleSkeleton = memo(() => {
    return (
        <S.SubjectScreenDetailsActiveModuleContainer xs={12} sm={6}>
            <S.SubjectScreenDetailsActiveModuleCard style={{ padding: "16px 8px" }}>
                <S.SubjectScreenDetailsActiveModuleHeader>
                    <S.SubjectScreenDetailsActiveModuleLegend>
                        <Skeleton width="50%" height="16px" />
                    </S.SubjectScreenDetailsActiveModuleLegend>
                    <S.SubjectScreenDetailsActiveModuleTitle>
                        <Skeleton width="80%" height="18px" style={{ marginBottom: "4px" }} />
                    </S.SubjectScreenDetailsActiveModuleTitle>
                </S.SubjectScreenDetailsActiveModuleHeader>

                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton width="100%" height="45px" style={{ marginBottom: "4px" }} />
                    <Skeleton width="100%" height="45px" />
                </div>
            </S.SubjectScreenDetailsActiveModuleCard>
        </S.SubjectScreenDetailsActiveModuleContainer>
    );
});

export default SubjectScreenDetailsActiveModuleSkeleton;
