import React, { memo, useEffect } from "react";

// Components
import PersonalDataForm from "./PersonalDataForm";
import ChangePasswordForm from "./ChangePasswordForm";

// Helpers
import { IStudentAreaState } from "store/ducks/student-area";
import { StudentAreaScreenTypes } from "..";
import { IOnSubmitActionStudentArea, IHandleRequestAction } from "../index";

// Assets
import { StudentAreaFormsContainer } from "./styles";

export interface IStudentAreaFormsProps {
    screenName: StudentAreaScreenTypes;
    studentArea: IStudentAreaState;
    credentialsEmail: string;
}

const StudentAreaForms = ({
    screenName,
    studentArea,
    credentialsEmail,
    onSubmitAction,
    onRequestAction
}: IStudentAreaFormsProps & IOnSubmitActionStudentArea & IHandleRequestAction) => {
    useEffect(() => {
        if (screenName === "edit-personal" && !studentArea.id) {
            onRequestAction("edit-personal");
        }
    }, [onRequestAction, screenName, studentArea.id]);

    if (!studentArea.id && screenName === "edit-personal") {
        return <p>Carregando...</p>;
    }

    return (
        <StudentAreaFormsContainer>
            {screenName === "edit-personal" && (
                <PersonalDataForm studentArea={studentArea} credentialsEmail={credentialsEmail} onSubmitAction={onSubmitAction} />
            )}
            {screenName === "change-password" && <ChangePasswordForm onSubmitAction={onSubmitAction} />}
        </StudentAreaFormsContainer>
    );
};
export default memo(StudentAreaForms);
