import React from "react";

import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

import { Item, Separator, BreadcrumbContainer } from "./styles";

const BreadCrumb = ({ children, id = "" }: any) => <BreadcrumbContainer id={id}>{children}</BreadcrumbContainer>;

const BreadCrumbItem = ({ active, children, ...rest }: any) => (
    <Item active={active} {...rest}>
        {children}
    </Item>
);

const BreadCrumbSeparator = ({ active, children }: any) => (
    <Separator active={active}>{children || <ChevronRightIcon width="4px" height="8px" />}</Separator>
);

BreadCrumb.Item = BreadCrumbItem;

BreadCrumb.Separator = BreadCrumbSeparator;

export default BreadCrumb;
