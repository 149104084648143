export enum BreakPoints {
    smallX = 320,
    small = 600,
    tablet = 768,
    medium = 1024,
    wide = 1200,
    large = 1366,
    huge = 1440,
    fullhd = 1920
}

export const MediaQueries = {
    SMALL: `@media only screen and (max-width: ${BreakPoints.small - 1}px)`,
    SMALLX: `@media only screen and (max-width: ${BreakPoints.smallX}px)`,
    TABLET_PORTRAIT: `@media only screen and (min-width: ${BreakPoints.small}px) and (max-width: ${BreakPoints.medium - 1}px)`,
    TABLET_LANDSCAPE: `@media only screen and (min-width: ${BreakPoints.medium}px) and (max-width: ${BreakPoints.wide - 1}px)`,
    NOTEBOOK: `@media only screen and (min-width: ${BreakPoints.wide}px) and (max-width: ${BreakPoints.huge - 1}px)`,
    DESKTOP: `@media only screen and (min-width: ${BreakPoints.huge}px)`,

    BIGGER_THAN_SMALLX: `@media only screen and (min-width: ${BreakPoints.smallX}px)`,
    BIGGER_THAN_SMALL: `@media only screen and (min-width: ${BreakPoints.small}px)`,
    BIGGER_THAN_TABLET: `@media only screen and (min-width: ${BreakPoints.tablet + 1}px)`,
    BIGGER_THAN_MEDIUM: `@media only screen and (min-width: ${BreakPoints.medium}px)`,
    BIGGER_THAN_WIDE: `@media only screen and (min-width: ${BreakPoints.wide}px)`,
    BIGGER_THAN_LARGE: `@media only screen and (min-width: ${BreakPoints.large}px)`,
    BIGGER_THAN_HUGE: `@media only screen and (min-width: ${BreakPoints.huge}px)`,
    BIGGER_THAN_FULLHD: `@media only screen and (min-width: ${BreakPoints.fullhd}px)`,

    SMALLER_THAN_MEDIUM: `@media only screen and (max-width: ${BreakPoints.medium - 1}px)`,
    SMALLER_THAN_WIDE: `@media only screen and (max-width: ${BreakPoints.wide - 1}px)`
};

export const MediaQueriesManual = (px: number) => `@media only screen and (min-width: ${px}px)`;
