// Dependencies
import styled from "styled-components";
import { theme } from "config/theme";

// Assets
import { MediaQueries } from "assets/styles/settings";
import { TitleH3, BodyText1, Micro, BodyText2, TitleH2, TitleH0, TitleH4 } from "assets/styles/global";

export const SignWrapContent = styled.div`
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const GeneralSignLeftContent = styled.div`
    background-color: ${theme.colors.base[100]};
    padding: 50px 16px 26px;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    position: relative;

    z-index: 3;

    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 470px;
        padding: 64px 32px 40px;
        border-radius: 16px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 593px;
        padding: 124px 40px 100px;
        border-radius: 0;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        width: 860px;
        padding: 100px 179px 76px 128px;
    }
`;

export const LogoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 40px;

    @media (min-width: 1024px) {
        justify-content: flex-start;
    }

    img {
        height: 56px;
    }
`;

export const SignRightContent = styled.div`
    display: none;
    overflow: hidden;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        background-color: ${theme.colors.brand[300]};
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        position: relative;
    }
`;

export const SignBackToHome = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.brand[300]};
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 24px;

    svg {
        margin-right: 10px;
    }
`;

export const SignTitle = styled.h1`
    ${TitleH3};
    color: ${theme.colors.brand[300]};
    margin-bottom: 16px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${TitleH2};
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        ${TitleH0};
    }
`;

export const SignSubTitle = styled.h2`
    ${BodyText1};
    color: ${theme.colors.base[400]};

    ${MediaQueries.BIGGER_THAN_HUGE} {
        ${TitleH4};
    }
`;

export const SignTitlesWrap = styled.header`
    margin-bottom: 32px;

    ${MediaQueries.BIGGER_THAN_HUGE} {
        margin-bottom: 40px;
    }
`;

export const SignActionsItem = styled.div`
    align-items: baseline;
    display: flex;
    margin-bottom: 16px;

    a {
        ${Micro};
        color: ${theme.colors.system.info[300]};
        text-decoration: underline;

        &:hover {
            color: ${theme.colors.system.info[400]};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 24px;

        a {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
    }
`;

export const SignButtonsWrap = styled.div`
    text-align: center;
`;

export const SignFacebook = styled.div`
    svg {
        margin-right: 8px;
        height: 16px;
        width: 16px;
    }

    .sign-facebook-login {
        ${BodyText2};
        align-items: center;
        color: ${theme.colors.base[300]};
        display: flex;
        justify-content: center;
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        svg {
            margin-right: 16px;
            height: 24px;
            width: 24px;
        }

        .sign-facebook-login {
            ${BodyText1};
        }
    }
`;

export const SignFacebookWrap = styled.div`
    text-align: center;
    width: 100%;

    p {
        align-items: center;
        color: ${theme.colors.base[500]};
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        width: 100%;

        &:before,
        &:after {
            background: ${theme.colors.base[200]};
            content: "";
            display: block;
            height: 1px;
            width: 88px;
        }

        &:before {
            margin-right: 10px;
        }

        &:after {
            margin-left: 10px;
        }
    }
`;

export const SignFormLabel = styled.label`
    font-weight: 500;
    color: #9b9b9b;
`;

export const FieldWrapper = styled.div`
    margin-bottom: 16px;
`;

export const SignForm = styled.form``;

export const ArrowImage = styled.svg`
    position: relative;
    left: 10%;
    top: 30%;
`;

export const DropImage = styled.svg`
    position: absolute;
    width: 826.002px;
    height: 933px;
    /* overflow: hidden; */
`;

export const CircleImage = styled.svg`
    position: absolute;
    bottom: 0;
    right: 0;
`;

export const SignCopyright = styled.footer`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 40px 0 32px;

    a {
        ${Micro};
        color: ${theme.colors.base[300]};
    }

    ${MediaQueries.SMALLER_THAN_MEDIUM} {
        font-size: ${theme.typography.sizes.small};
        text-align: center;
    }

    @media (min-width: 1024px) {
        margin: 56px 0 32px;
    }
`;

export const CheckboxLabel = styled.span`
    margin-left: 10px;

    a {
        color: #9b9b9b;

        &:hover {
            color: #000;
        }
    }
`;

export const CheckboxHelper = styled.span`
    background: ${theme.colors.white};
    border-radius: 3px;
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;

    &:after {
        content: "";
        display: block;
    }
`;

export const CheckboxItem = styled.input`
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;

    &:hover ~ ${CheckboxHelper} {
        background: #f5f5f5;
    }

    &:focus ~ ${CheckboxHelper} {
        border-color: ${theme.colors.brand[300]};
    }

    &:checked ~ ${CheckboxHelper} {
        background: ${theme.colors.brand[300]};
        border-color: ${theme.colors.brand[300]};

        &:after {
            content: url('data:image/svg+xml;charset=UTF-8,<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.18359 4.23438L6.2085 9.25781L14.1836 1.28125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }
    }

    &:disabled ~ ${CheckboxHelper} {
        background: #e1e1e1;
        border-color: #e1e1e1;
        cursor: no-drop;

        &:after {
            content: url('data:image/svg+xml;charset=UTF-8,<svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.18359 4.23438L6.2085 9.25781L14.1836 1.28125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }
    }
`;

export const CheckboxContainer = styled.label<{ hasError?: boolean }>`
    align-items: center;
    cursor: pointer;
    display: flex;

    ${CheckboxHelper} {
        border: 2px solid #e1e1e1;
    }

    ${CheckboxLabel} {
        color: #9b9b9b;
    }
`;

export const CheckboxWrapper = styled.div`
    position: relative;
`;

export const SignActionsWrap = styled.div`
    font-size: 0.9rem;
    margin-bottom: 16px;

    ${MediaQueries.SMALL} {
        font-size: 0.75rem;
    }
`;

export const SuspendedAccessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    max-width: 600px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: flex-start;
    }
`;

export const SuspendedAccessTitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
`;

export const SuspendedAccessTitle = styled.p`
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
`;

export const SuspendedAccessMessage = styled.p`
    text-align: center;
    font-size: 1rem;
    line-height: 1.375rem;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        text-align: start;
    }
`;

export const HelpCenterButtonWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
`;

export const HelpCenterButton = styled.button`
    display: flex;
    align-items: center;
    gap: 4px;

    color: #fff;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    padding: 8px;
    background-color: ${theme.colors.brand[300]};
    border-radius: 22px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        background-color: ${theme.colors.white};
        color: ${theme.colors.brand[300]};

        position: absolute;
        bottom: 30px;
        right: 30px;
        z-index: 2;

        transition: color 200ms ease-in-out;

        &:hover {
            color: ${theme.colors.brand[400]};
        }
    }
`;

export const SignUpWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
`;

export const SignUpLabel = styled.p`
    color: ${theme.colors.base[300]};
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
`;

export const SignUpAction = styled.p`
    color: ${theme.colors.system.info[300]};
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    text-decoration-line: underline;
    cursor: pointer;

    :hover {
        color: ${theme.colors.system.info[400]};
    }
`;

export const CarouselCardItemTitle = styled.p`
    color: ${theme.colors.base[400]};
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    strong {
        color: ${theme.colors.brand[300]};
    }

    @media (min-width: 1440px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }
`;

export const CarouselCardItemContent = styled.img`
    height: 288px;
    background-size: cover;
`;

export const BenefitsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 493px;
    padding: 0 20px;

    @media (min-width: 1440px) {
        width: 640px;
    }
`;

export const BenefitsTitle = styled.p`
    color: ${theme.colors.white};
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    @media (min-width: 1440px) {
        font-size: 32px;
        font-weight: 800;
        letter-spacing: -0.32px;
    }
`;

export const BenefitsSubtitle = styled.p`
    color: ${theme.colors.base[100]};
    font-family: "Nunito", sans-serif;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;

    @media (min-width: 1440px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }
`;

export const BenefitsContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;

    > * + * {
        margin-top: 8px;
    }
`;

export const BenefitsMoreLabel = styled.p`
    color: ${theme.colors.white};
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    text-decoration-line: underline;
`;

export const BenefitItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 4px;
    backdrop-filter: blur(12.5px);
    background: ${theme.colors.brand[500]};
`;

export const BenefitItemLabel = styled.p`
    margin-left: 16px;
    color: ${theme.colors.white};
    font-family: "Nunito", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    @media (min-width: 1440px) {
        font-size: 18px;
        line-height: 26px;
    }

    > span {
        color: ${theme.colors.base[100]};
        font-weight: 900;
    }
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;

    @media (min-width: 1440px) {
        width: 32px;
        height: 32px;
    }

    > svg {
        width: 100%;
        height: 100%;
    }
`;
