import React, { memo } from "react";
import { SubjectGroupContainer, SubjectGroupTitle, SubjectGroupCards, SubjectCardSkeleton, SubjectGroupCard } from "./styles";
import { theme } from "config/theme";
import Skeleton from "components/Skeleton";

const SubjectGroupSkeleton = memo(({ length }: { length: number }) => {
    return (
        <SubjectGroupContainer>
            {theme.project.slug !== "promilitares" && (
                <SubjectGroupTitle color={theme.colors.base[300]}>
                    <strong>
                        <Skeleton height="26px" width={`${110 + Math.floor(Math.random() * 55 + 1)}px`} style={{ maxWidth: "100%" }} />
                    </strong>
                </SubjectGroupTitle>
            )}

            <SubjectGroupCards>
                {Array.from({ length }).map((_, index) => (
                    <SubjectGroupCard key={index}>
                        <SubjectCardSkeleton width="172px" height="148px" style={{ borderRadius: "4px" }} />
                    </SubjectGroupCard>
                ))}
            </SubjectGroupCards>
        </SubjectGroupContainer>
    );
});

export default SubjectGroupSkeleton;
