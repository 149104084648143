import styled from "styled-components";
import { theme } from "config/theme";
import { AnswerCardProgressContainer } from "components/AnswerCardProgress/styles";
import { MediaQueries } from "assets/styles/settings";

export const ExamDayResultBasicInfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.base[100]};
    margin-bottom: ${theme.spacing.small};

    ${AnswerCardProgressContainer} {
        margin-bottom: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        border-bottom: none;
        margin-bottom: unset;
    }
`;

export const ExamDayResultBasicInfosLegend = styled.p`
    color: ${theme.colors.base[300]};
    font-size: ${theme.typography.sizes.small};
    margin-bottom: ${theme.spacing.smallX};
    font-weight: 700;
`;

export const ExamDayResultBasicInfosTime = styled.div`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.smallX};
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    p {
        flex: 1;
    }
`;

export const ExamDayResultBasicInfosKnowledgeAreas = styled.div`
    display: flex;
    flex-direction: column;

    p {
        color: ${theme.colors.base[400]};
        margin-bottom: ${theme.spacing.smallXX};
        font-size: ${theme.typography.sizes.smallX};
    }
`;
