// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

type WebpProgressBarProps = {
    color: string;
    number: number;
    colorCompletelyFilled: string;
};

export const WebpProgressBar: FunctionComponent<WebpProgressBarProps> = ({ color, number = 0, colorCompletelyFilled, children }) => {
    return (
        <Container
            className="webp-progress-bar"
            bg={color}
            bgOnCompletelyFilled={colorCompletelyFilled}
            filled={number >= 100}
            progress={`${number}%`}
        >
            <div className="bar"></div>
        </Container>
    );
};
