import React, { memo } from "react";

import {
    lessonPlanSectionWeekListItemsVideo,
    lessonPlanSectionWeekListItemsExerciseList,
    lessonPlanSectionWeekListItemsMaterial
} from "store/interfaces/ILessonPlanCustom";

import {
    SubjectScreenDetailsContentPerTypeAccordionIcon,
    SubjectScreenDetailsContentPerTypeItemButton,
    SubjectScreenDetailsContentPerTypeItemLink
} from "./styles";

interface IItemProps {
    done: false;
    id: number;
    type: string;
    video?: lessonPlanSectionWeekListItemsVideo[];
    exerciselist?: lessonPlanSectionWeekListItemsExerciseList[];
    material?: lessonPlanSectionWeekListItemsMaterial[];
    booklet?: lessonPlanSectionWeekListItemsMaterial[];
}

interface IProps {
    item: any;
    onClick(item: any): void;
}

const SubjectScreenDetailsContentPerTypeItem = memo(({ item, onClick }: IProps) => {
    const isMaterialLink = !!item?.material?.url;

    if (isMaterialLink) {
        return (
            <SubjectScreenDetailsContentPerTypeItemLink href={item?.material?.url} onClick={() => onClick(item)}>
                <SubjectScreenDetailsContentPerTypeAccordionIcon active={!!item?.done} />

                <strong>{item.material.title}</strong>
            </SubjectScreenDetailsContentPerTypeItemLink>
        );
    }

    return (
        <SubjectScreenDetailsContentPerTypeItemButton onClick={() => onClick(item)}>
            <SubjectScreenDetailsContentPerTypeAccordionIcon active={!!item?.done} />
            <p>
                <strong>{item?.exerciseList?.title || item?.material?.title || item?.video?.title || item?.event?.title || "-"}</strong>
            </p>
        </SubjectScreenDetailsContentPerTypeItemButton>
    );
});

export default SubjectScreenDetailsContentPerTypeItem;
