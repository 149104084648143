import React, { useCallback } from "react";

// Components
import Attachments from "../attachments";
import ForumQuestionActions from "../actions";

// Assets
import * as F from "./styles";
import ForumQuestionDetails, { IForumActions } from "screens/Forum/components/details";

interface IForumQuestionContentProps {
    actions: IForumActions;
    id: string;
    content: string;
    isLiked: boolean;
    created: string;
    likesTotal: number;
    answersTotal?: number;
    attachmentsTotal?: number;
    attachments?: any[];
    color?: string;
    subject?: string;
    user?: string;
    onShowForumPost?: () => void;
    onToggleLike?: (isLiked: boolean, id: string) => void;
    disabledActions?: boolean;
}

const ForumQuestionContent: React.FC<IForumQuestionContentProps> = (props) => {
    const handleToggleLike = useCallback(() => props.onToggleLike && props.onToggleLike(props.isLiked, props.id), [props]);

    return (
        <F.ForumQuestionContentContainer>
            <F.ForumQuestionContentWrapper>
                <ForumQuestionDetails actions={props.actions} id={props.id} date={props.created} user={props.user} subject={props.subject} />
                <F.ForumQuestionContentText className="ck-content" dangerouslySetInnerHTML={{ __html: props.content }} />
                {props.attachments && Boolean(props.attachments.length) && <Attachments attachments={props.attachments} />}
                {props.onShowForumPost && props.onToggleLike && (
                    <ForumQuestionActions
                        color={props.color}
                        id={props.id}
                        isLiked={props.isLiked}
                        likesTotal={props.likesTotal}
                        onShowForumPost={props.onShowForumPost}
                        onToggleLike={handleToggleLike}
                        disabledActions={props.disabledActions}
                    />
                )}
            </F.ForumQuestionContentWrapper>
        </F.ForumQuestionContentContainer>
    );
};

export default ForumQuestionContent;
