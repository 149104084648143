import { IExerciseListQuestion } from "interfaces/IExerciseListQuestion";

export default function getQuestionByNumber(questions: IExerciseListQuestion[], questionNumber: number) {
    try {
        if (!questions || !questionNumber) {
            throw new Error();
        }

        const questionItem = questions
            .filter((questionItem) => !!questionItem.question)
            .find((questionItem) => questionItem.question.number === questionNumber);

        if (!questionItem || !questionItem.question) {
            throw new Error("questionNumber not found");
        }

        return { ...questionItem.question, isCanceled: questionItem.isCanceled || false };
    } catch (error) {
        console.log(error);
        return undefined;
    }
}
