import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as alertActions } from "../ducks/alert";
import { Creators as contentSearchActions } from "../ducks/contentSearch";

export function* getAllContents({ payload }: any) {
    try {
        const { data } = yield call(api.get, `/content?query=${payload}`);

        yield put(contentSearchActions.getContentSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível realizar a busca.", "danger"));
        yield put(contentSearchActions.getContentFailure(error?.response?.data));
    }
}
