import { Button3, Micro } from "assets/styles/global";
import { AccordionButton, AccordionContent } from "components/Accordion/styles";
import { theme } from "config/theme";
import { ProjectDetailsResumes } from "screens/Projects/Details/styles";
import styled from "styled-components";

export const ProjectModulesListContainer = styled(ProjectDetailsResumes)`
    width: 100%;

    ${AccordionButton} {
        .accordion-header {
            width: 100%;

            > * {
                line-height: 1;
            }
        }

        strong {
            ${Button3};
            color: ${theme.colors.base[400]};
            display: block;
        }

        span {
            ${Micro};
            color: ${theme.colors.black};
        }
    }

    ${AccordionContent} {
        padding: ${theme.spacing.smallX};
    }
`;

export const ProjectSectionTitle = styled.h3`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.smallX};
    font-weight: 700;
    line-height: ${theme.spacing.small};
    margin-bottom: ${theme.spacing.smallX};
`;

export const NotFoundSection = styled.p`
    font-style: italic;
    padding: ${theme.spacing.smallX} 0;
`;

export const ProjectModulesWrapper = styled.div``;

export const ProjectModulesIntro = styled.div`
    margin-bottom: ${theme.spacing.small};
`;

export const ProjectSectionItem = styled.div`
    &:not(:last-child) {
        margin-bottom: ${theme.spacing.small};
    }
`;
