import { createActions, createReducer } from "reduxsauce";
import { IBookletModuleState, BookletModuleActionsType, IBookletModuleActionsCreators } from "store/interfaces/IBookletModule";

export const { Creators, Types } = createActions<Record<BookletModuleActionsType, BookletModuleActionsType>, IBookletModuleActionsCreators>({
    getBookletModulesRequest: ["payload"],
    getBookletModulesSuccess: ["payload"],
    getBookletModulesFailure: ["payload"],

    getCurrentModuleRequest: ["payload"],
    getCurrentModuleSuccess: ["payload"],
    getCurrentModuleFailure: ["payload"],

    clickModuleContent: ["payload"],
    setCurrentModule: ["payload"],
    setHasDispatchedClickModuleContent: ["payload"],

    clearBookletModules: []
});

export const INITIAL_STATE: IBookletModuleState = {
    isLoading: true,
    items: [],
    currentModule: undefined,
    isLoadingCurrentModule: false,
    error: false,
    errorCurrentModule: false,
    hasDispatchedClickModuleContent: false
};

const getBookletModulesRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, items: [], error: false });

const getBookletModulesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload,
    error: false
});

const getBookletModulesFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const clearBookletModules = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

const getCurrentModuleRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentModule: true,
    errorCurrentModule: false,
    currentModule: {
        ...action.payload
    }
});

const getCurrentModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentModule: false,
    errorCurrentModule: false,
    currentModule: {
        ...action.payload
    }
});

const getCurrentModuleFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentModule: false,
    currentModule: undefined,
    errorCurrentModule: true
});

const clickModuleContent = (state = INITIAL_STATE) => ({
    ...state
});

const setCurrentModule = (state = INITIAL_STATE, action: any) => ({
    ...state,
    bookletModule: action.payload
});

const setHasDispatchedClickModuleContent = (state = INITIAL_STATE, action: any) => ({
    ...state,
    hasDispatchedClickModuleContent: action.payload
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_BOOKLET_MODULES_REQUEST]: getBookletModulesRequest,
    [Types.GET_BOOKLET_MODULES_SUCCESS]: getBookletModulesSuccess,
    [Types.GET_BOOKLET_MODULES_FAILURE]: getBookletModulesFailure,

    [Types.GET_CURRENT_MODULE_REQUEST]: getCurrentModuleRequest,
    [Types.GET_CURRENT_MODULE_SUCCESS]: getCurrentModuleSuccess,
    [Types.GET_CURRENT_MODULE_FAILURE]: getCurrentModuleFailure,

    [Types.CLICK_MODULE_CONTENT]: clickModuleContent,
    [Types.SET_CURRENT_MODULE]: setCurrentModule,
    [Types.SET_HAS_DISPATCHED_CLICK_MODULE_CONTENT]: setHasDispatchedClickModuleContent,

    [Types.CLEAR_BOOKLET_MODULES]: clearBookletModules
});
