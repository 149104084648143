// Dependencies
import styled from "styled-components";

export const ChartContainer = styled.svg`
    width: 144px;
    height: 144px;
    border-radius: 200px;

    &:not(:root) {
        overflow: visible;
    }
`;

export const ChartSlice = styled.g`
    cursor: pointer;
`;

export const ChartEmptyZone = styled.circle`
    fill: #fff;
    cursor: default;
`;

export const TooltipContainer = styled.div`
    width: fit-content;
    height: 40px;

    padding: 12px;
    border-radius: 200px;
    background-color: #4d4d63;
    border: 1px solid #4d4d63;

    display: flex;
    justify-content: center;
    align-items: center;

    pointer-events: none;
    position: fixed;
    transform: translate(-50%, -100%);

    &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px;

        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-color: #4d4d63 transparent transparent transparent;
    }
`;

export const TooltipLabel = styled.p`
    color: #ffffff;
    white-space: nowrap;
`;
