import { Button3 } from "assets/styles/global";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExamDayResultRelatedDaysContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: ${theme.spacing.medium};
`;

export const ExamDayResultRelatedDaysLegend = styled.div`
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};

    ${Button3};
`;
