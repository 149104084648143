import React, { useMemo } from "react";

// assets
import {
    ExerciseListAnswerCardQuestionContainer,
    ExerciseListAnswerCardQuestionNumber,
    ExerciseListAnswerCardQuestionAlternatives,
    ExerciseListAnswerCardQuestionSeeButton,
    ExerciseListAnswerCardQuestionDetails,
    ExerciseListAnswerCardQuestionNote,
    ExerciseListAnswerCardQuestionDiscursive,
    ExerciseListAnswerCardQuestionAlternativeStatus
} from "./styles";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// components
import ExerciseListLetter from "../Letter";

// helpers
import getLetterByNumber from "utils/getLetterByNumber";
import QuestionType from "screens/ExerciseList/enums/QuestionType";
import useCorrectAlternative from "screens/ExerciseList/utils/useCorrectAlternative";
import { IAnswerCardItem } from "store/ducks/answerCard";
import { IQuestion } from "interfaces/IQuestion";

interface IProps {
    question: IQuestion;
    number: number;
    showAnswer: boolean;
    showNote: boolean;
    isFinished: boolean;
    answer?: IAnswerCardItem;
    onClickSee(): void;
    isFeedBack?: boolean;
    canSeeQuestion: boolean;
}

const ExerciseListAnswerCardQuestion = ({
    question,
    number,
    showAnswer,
    answer,
    showNote,
    onClickSee,
    isFinished,
    isFeedBack,
    canSeeQuestion
}: IProps) => {
    const { alternatives, questionType } = question;

    const correctAlternative = useCorrectAlternative({ alternatives });

    const hitQuestion = useMemo(() => answer?.hit, [answer]);

    const showAnswerStatus = useMemo(() => !!showAnswer && !!answer?.objectiveAnswer, [showAnswer, answer]);

    const discursiveAnswer = useMemo(() => !!answer && answer.discursiveAnswer, [answer]);

    const memoObjectiveAnswer = useMemo(() => {
        try {
            const markedAlternative = alternatives.find((alternative) => alternative.id === answer?.objectiveAnswer);
            const markedAlternativeIndex = alternatives.findIndex((alternative) => alternative.id === markedAlternative?.id);
            const correctAlternativeIndex = alternatives.findIndex((alternative) => alternative.id === correctAlternative?.id);

            const isCorrect = markedAlternative?.id === correctAlternative?.id;

            const correctAnswerLabel = isFeedBack ? "Resposta correta." : "Você acertou!";

            return (
                <ExerciseListAnswerCardQuestionAlternatives>
                    {markedAlternativeIndex < 0 && <p>Você ainda não respondeu.</p>}

                    {showAnswerStatus && markedAlternativeIndex >= 0 && (
                        <>
                            <ExerciseListLetter
                                letter={getLetterByNumber(markedAlternativeIndex)}
                                isCorrect={isCorrect}
                                isWrong={markedAlternative?.id !== correctAlternative?.id}
                            />

                            <p>
                                {isCorrect ? (
                                    correctAnswerLabel
                                ) : (
                                    <>
                                        Você errou. Resposta:{" "}
                                        <ExerciseListAnswerCardQuestionAlternativeStatus variant="success">
                                            {getLetterByNumber(correctAlternativeIndex)}
                                        </ExerciseListAnswerCardQuestionAlternativeStatus>
                                    </>
                                )}
                            </p>
                        </>
                    )}

                    {!showAnswerStatus && markedAlternativeIndex >= 0 && (
                        <>
                            <ExerciseListLetter letter={getLetterByNumber(markedAlternativeIndex)} isMarked={true} />

                            <p>
                                Você marcou:{" "}
                                <ExerciseListAnswerCardQuestionAlternativeStatus variant="info">
                                    {getLetterByNumber(markedAlternativeIndex)}
                                </ExerciseListAnswerCardQuestionAlternativeStatus>
                            </p>
                        </>
                    )}
                </ExerciseListAnswerCardQuestionAlternatives>
            );
        } catch (error) {
            return null;
        }
    }, [alternatives, answer, correctAlternative, isFeedBack, showAnswerStatus]);

    const memoDiscursiveAnswer = useMemo(() => {
        try {
            if (questionType === QuestionType.Discursive) {
                if (answer === undefined)
                    return (
                        <ExerciseListAnswerCardQuestionAlternatives>
                            <p>Você ainda não respondeu.</p>
                        </ExerciseListAnswerCardQuestionAlternatives>
                    );
                if (answer && answer.hit)
                    return (
                        <ExerciseListAnswerCardQuestionAlternatives>
                            <ExerciseListLetter letter={"✓"} isCorrect={true} /> <p>Você acertou.</p>
                        </ExerciseListAnswerCardQuestionAlternatives>
                    );
                return (
                    <ExerciseListAnswerCardQuestionAlternatives>
                        <ExerciseListLetter letter={"X"} isWrong={true} /> <p>Você errou.</p>
                    </ExerciseListAnswerCardQuestionAlternatives>
                );
            }

            if (!!discursiveAnswer) {
                return (
                    <ExerciseListAnswerCardQuestionDiscursive>
                        <p>Sua resposta:</p>

                        <div dangerouslySetInnerHTML={{ __html: discursiveAnswer }} />
                    </ExerciseListAnswerCardQuestionDiscursive>
                );
            }

            return (
                <ExerciseListAnswerCardQuestionAlternatives>
                    <p>Você ainda não respondeu.</p>
                </ExerciseListAnswerCardQuestionAlternatives>
            );
        } catch (error) {
            return null;
        }
    }, [answer, discursiveAnswer, questionType]);

    return (
        <ExerciseListAnswerCardQuestionContainer>
            <ExerciseListAnswerCardQuestionNumber>
                <strong>{number}</strong>
            </ExerciseListAnswerCardQuestionNumber>

            {questionType === QuestionType.Objective && memoObjectiveAnswer}

            {questionType === QuestionType.Discursive && memoDiscursiveAnswer}

            <ExerciseListAnswerCardQuestionDetails>
                {showNote && !!answer && (
                    <ExerciseListAnswerCardQuestionNote isCorrect={!!hitQuestion}>
                        Nota: <span>{answer.points || 0}</span>
                    </ExerciseListAnswerCardQuestionNote>
                )}
                <div>
                    {canSeeQuestion && (
                        <ExerciseListAnswerCardQuestionSeeButton onClick={onClickSee} data-test-id="answer-card-modal-see-question-button">
                            Ver
                            <ChevronRightIcon width={4} height={8} />
                        </ExerciseListAnswerCardQuestionSeeButton>
                    )}
                </div>
            </ExerciseListAnswerCardQuestionDetails>
        </ExerciseListAnswerCardQuestionContainer>
    );
};

export default React.memo(ExerciseListAnswerCardQuestion);
