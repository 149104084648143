// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    status: "completed" | "selected" | "disabled";
    orientation: "horizontal" | "vertical";
};

export const Container = styled.div<ContainerStyleProps>`
        display: block;
        width: 100%;

        .left {
            margin: auto auto 25px 0;
        }

        .center {
            margin: auto auto 25px auto;
        }

        .right {
            margin: auto -15px 25px auto;
        }

        > .dot-container {
            width: 100%;
            padding-right: 15px;
            position: relative;
        }

        .dot-dashed {
            border: 1px dashed ${theme.colors.base[200]};
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0px;
            top: 7px;
            z-index: -1;
        }
        
        ${({ orientation }) =>
            orientation === "vertical" &&
            css`
                .left {
                    margin: 0 8px auto auto;
                }

                .center {
                    margin: auto 8px auto auto;
                }

                .right {
                    margin: auto 8px 0 auto;
                }

                .dot-container {
                    width: fit-content;
                    display: flex;
                    min-height: 60px;
                    align-items: center;
                }

                .dot-dashed {
                    width: 2px;
                    height: 96%;
                    left: 7px;
                    top: 2px;
                }
            `}

        ${({ status }) =>
            status === "completed" &&
            css`
                .dot {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: ${theme.colors.system.success[100]};

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: ${theme.colors.system.success[300]};
                    }
                }
            `}

        ${({ status }) =>
            status === "selected" &&
            css`
                .dot {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: ${theme.colors.system.info[100]};

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: ${theme.colors.system.info[300]};
                    }
                }
            `}

        ${({ status }) =>
            status === "disabled" &&
            css`
                .dot {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: ${theme.colors.base[100]};

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: ${theme.colors.base[200]};
                    }
                }
            `}   
`;
