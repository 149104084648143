import { theme } from "config/theme";
import styled from "styled-components";

interface ICheckboxLabelProps {
    checked: boolean;
}

export const CheckboxLabel = styled.span<ICheckboxLabelProps>`
    font-size: 14px;
    margin-left: ${theme.spacing.smallX};
    font-weight: 500;
    color: ${({ checked }) => (checked ? theme.colors.base[500] : theme.colors.base[400])};

    a {
        color: ${theme.colors.system.info[400]};
        text-decoration: underline;
    }
`;

export const CheckboxHelper = styled.span`
    background: ${theme.colors.white};
    border-radius: 3px;
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    width: 20px;

    svg {
        width: 80%;
        height: 80%;
    }
`;

export const CheckboxItem = styled.input`
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;

    &:hover ~ ${CheckboxHelper} {
        background: ${theme.colors.base[100]};
    }

    &:focus ~ ${CheckboxHelper} {
        border-color: #7c8195;
    }

    &:checked ~ ${CheckboxHelper} {
        border-color: ${theme.colors.system.success[300]};

        svg path {
            stroke: ${theme.colors.system.success[300]};
        }
    }

    &:disabled ~ ${CheckboxHelper} {
        border-color: #e1e1e1;
        cursor: no-drop;
    }

    &:checked:disabled ~ ${CheckboxHelper} {
        svg path {
            stroke: ${theme.colors.base[400]};
        }
    }
`;

export const CheckboxContainer = styled.label<{ hasError?: boolean }>`
    align-items: center;
    cursor: pointer;
    display: flex;

    ${CheckboxHelper} {
        border: 1px solid ${theme.colors.base[300]};
    }
`;

export const CheckboxWrapper = styled.div`
    position: relative;
`;
