import React, { memo, useMemo } from "react";
import {
    KnowledgeAreasResultsContainer,
    KnowledgeAreasResultsTitle,
    KnowledgeAreasResultsWrap,
    KnowledgeAreasResultsItem,
    KnowledgeAreasResultsItemTitle,
    KnowledgeAreasResultsLegends,
    KnowledgeAreasResultsLegendsItem
} from "./styles";
import AnswerCardProgress from "components/AnswerCardProgress";

import LikeIcon from "prodigio-icons/web/outlined/Like";
import AttentionIcon from "prodigio-icons/web/outlined/Attention";
import { IExamDayResultKnowledgeArea } from "interfaces/IExamDays";
import { HitPerformance } from "enums/HitPerformance";
import { formatToNaturalNumber } from "utils/formatToNaturalNumber";
interface IProps {
    knowledgeAreas: IExamDayResultKnowledgeArea[];
}

const KnowledgeAreasResults = memo(({ knowledgeAreas = [] }: IProps) => {
    const canBeBetter = useMemo(() => knowledgeAreas.filter((item) => item.hitPerformance === HitPerformance.CanBeBetter) || [], [knowledgeAreas]);
    const goingWell = useMemo(() => knowledgeAreas.filter((item) => item.hitPerformance === HitPerformance.GoingWell) || [], [knowledgeAreas]);

    const memoList = useMemo(
        () =>
            knowledgeAreas.map((item, index) => {
                const wrongAnswers = formatToNaturalNumber(item.totalQuestions - item.totalCorrects);

                return (
                    <KnowledgeAreasResultsItem key={`knowledgeArea_${index}`}>
                        <KnowledgeAreasResultsItemTitle>{item.knowledgeArea?.name || "-"}</KnowledgeAreasResultsItemTitle>

                        <AnswerCardProgress
                            answersCorrect={item.totalCorrects || 0}
                            answersWrong={wrongAnswers}
                            questionsTotal={item.totalQuestions || 0}
                            totalAnswered={item.totalQuestions || 0}
                            isFinished={true}
                        />
                    </KnowledgeAreasResultsItem>
                );
            }),
        [knowledgeAreas]
    );

    if (!knowledgeAreas?.length) {
        return null;
    }

    return (
        <KnowledgeAreasResultsContainer>
            <KnowledgeAreasResultsTitle>Resultado por área de conhecimento</KnowledgeAreasResultsTitle>

            <KnowledgeAreasResultsWrap>{memoList}</KnowledgeAreasResultsWrap>

            <KnowledgeAreasResultsLegends>
                {!!goingWell?.length && (
                    <KnowledgeAreasResultsLegendsItem variant="success">
                        <LikeIcon width={16} />
                        <p>
                            <strong>Tá indo bem: </strong> {goingWell.map((item) => item.knowledgeArea?.name).join(", ")}
                        </p>
                    </KnowledgeAreasResultsLegendsItem>
                )}

                {!!canBeBetter?.length && (
                    <KnowledgeAreasResultsLegendsItem variant="danger">
                        <AttentionIcon width={16} />
                        <p>
                            <strong>Pode melhorar: </strong> {canBeBetter.map((item) => item.knowledgeArea?.name).join(", ")}
                        </p>
                    </KnowledgeAreasResultsLegendsItem>
                )}
            </KnowledgeAreasResultsLegends>
        </KnowledgeAreasResultsContainer>
    );
});

export default KnowledgeAreasResults;
