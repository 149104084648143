// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: block;
    box-sizing: border-box;

    .filter-item-title {
        margin-bottom: 8px;
    }

    .optionsWrapper {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 6px;
    }
`;

export const OptionCompositionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 4px 8px 4px 0px;
    gap: 8px;
    overflow: hidden;
    box-sizing: border-box;
    text-overflow: ellipsis;
`;
