import { Button3 } from "assets/styles/global";
import { CardStyles } from "components/Card/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const FinishedExamDaysContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.small} 24px 0;
    border-top: 1px solid ${theme.colors.base[100]};

    > p {
        ${Button3};
        color: ${theme.colors.base[400]};
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const FinishedExamDaysButton = styled.button.attrs({ type: "button" })`
    ${CardStyles};
    color: ${theme.colors.base[500]};
    text-align: left;
    align-items: center;
    margin-bottom: ${theme.spacing.small};

    > p {
        flex: 1;
        margin-right: ${theme.spacing.smallX};
    }

    > svg {
        color: ${theme.colors.base[500]};
        height: 8px;
    }
`;
