import { theme } from "config/theme";
import { BodyText2 } from "assets/styles/global";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";

export const ForumMetadataTop = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    span {
        &:not(:last-child) {
            &:after {
                content: "•";
                color: #ccc;
                margin: 0 12px;
            }
        }
    }

    ${MediaQueries.SMALL} {
        display: block;
        width: 100%;

        span {
            display: block;

            &:not(:last-child) {
                &:after {
                    display: none;
                }
            }
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        width: 100%;
    }
`;

export const ForumMetadataBottom = styled.div`
    span {
        ${BodyText2};
        color: #7c8195;

        &:not(:last-child) {
            &:after {
                content: "•";
                color: #ccc;
                margin: 0 12px;
            }
        }

        &:first-child {
            color: ${theme.colors.base[400]};
        }
    }

    ${MediaQueries.SMALL} {
        margin-bottom: ${theme.spacing.smallX};
        width: 100%;

        span {
            display: block;

            &:not(:last-child) {
                margin-bottom: ${theme.spacing.smallXX};

                &:after {
                    display: none;
                }
            }
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        margin-bottom: ${theme.spacing.smallX};
        width: 100%;
    }
`;

export const ForumMetadataAuthor = styled.span`
    ${BodyText2};
    align-items: center;
    color: ${theme.colors.base[400]};
    display: flex;
    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.SMALL} {
        margin-bottom: ${theme.spacing.smallXX};
        justify-content: space-between;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        margin-bottom: 0;
    }
`;

export const ForumMetadataSubject = styled.span<{ colorText: any }>`
    ${BodyText2};
    color: ${({ colorText }) => colorText};
`;

export const ForumMetadataContainer = styled.div<{ isVertical: boolean }>`
    display: flex;

    ${({ isVertical }) => {
        if (isVertical) {
            return css`
                align-items: flex-start;
                flex-direction: column;

                ${ForumMetadataBottom} {
                    margin-top: ${theme.spacing.small};
                }
            `;
        }

        return css`
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        `;
    }}

    ${MediaQueries.SMALL} {
        flex-direction: column;
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        flex-direction: column;
    }
`;
