import styled, { keyframes } from "styled-components";
import { MediaQueriesManual } from "assets/styles/settings";

const animationBell = keyframes`
 0% {transform: rotate(0deg); filter: blur(0px);}
 40% {transform: rotate(0deg); filter: blur(0px);}

 45% {transform: rotate(10deg); filter: blur(1px);}
 50% {transform: rotate(0deg); filter: blur(0px);}
 55% {transform: rotate(-10deg); filter: blur(1px);}

 58% {transform: rotate(0deg); filter: blur(0px);}
 100% {transform: rotate(0deg); filter: blur(0px);}
`;

export const Wrapper = styled.div`
    .bell {
        width: 20px;
        height: 20px;
    }

    .bell-active {
        animation: 2s infinite ${animationBell};
    }

    ${MediaQueriesManual(600)} {
        .bell {
            width: 24px;
            height: 24px;
        }
    }

    ${MediaQueriesManual(1024)} {
        .bell {
            width: 27px;
            height: 27px;
        }
    }
`;
