import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as videoActions } from "../ducks/video";
import { Creators as watchActions } from "../ducks/watch";
import { Creators as modalActions } from "../ducks/modal";
import { Creators as alertActions } from "../ducks/alert";

import { IRequestAction } from "interfaces/IRequestAction";

import HttpStatus from "enums/HttpStatus";
import { ReduxAction } from "store";
import { ReportVideoErrorPayload } from "store/interfaces/IVideo";
import { getUserIp } from "helpers/getUserIP";

export function* getVideo(action: any) {
    try {
        const { data } = yield call(api.get, `/video/${action.payload}`);

        yield put(videoActions.getVideoSuccess(data.url));
        yield put(watchActions.setCurrentVideoData({ url: data.url, viewed: data.learningObject.viewed, isLiked: data.learningObject.isLiked }));
    } catch (error) {
        yield put(videoActions.getVideoFailure(error?.response?.data));
    }
}

export function* getVideoById(action: any) {
    const { id, showPaywall, onBlockContent, brandSlug } = action.payload;

    try {
        if (!id) {
            throw new Error("VIDEO WITHOUT ID");
        }

        const { data } = yield call(api.get, `/video/${id}`);

        if (!data?.id) {
            throw new Error("ERROR ON GET VIDEO REQUEST");
        }

        yield put(videoActions.getVideoByPlayerSuccess(data));
    } catch (error) {
        const blockContent = error?.response?.status === HttpStatus.PAYMENT_REQUIRED || error?.message === "PAYMENT_REQUIRED";

        if (onBlockContent) {
            onBlockContent();
        }

        if (blockContent && showPaywall && !!brandSlug) {
            yield put(
                modalActions.openModal("confirmAction", {
                    title: "Ops!",
                    text: "Este conteúdo está disponível apenas para assinantes. Conheça nossos planos e torne-se um PROALUNO agora mesmo!",
                    onConfirm: () => window.open(`https://${brandSlug}.com.br/#planos`, "_blank"),
                    variant: brandSlug === "proenem" ? "secondary" : "primary",
                    buttonText: "VER PLANOS"
                })
            );
        }

        yield put(videoActions.getVideoByPlayerFailure({ blockContent, error: !blockContent }));
    }
}

export function* getVideoUserIp(action: any) {
    try {
        const DEFAULT_IP = "127.0.0.1";

        const requestIP = yield fetch("https://api64.ipify.org/?format=json");
        const { ip = DEFAULT_IP } = yield requestIP.json();

        yield put(videoActions.getVideoUserIpSuccess(ip));
    } catch (error) {
        yield put(videoActions.createVideoViewFailure());
    }
}

export function* createVideoView(action: IRequestAction<any>) {
    try {
        const { body, endpoint } = action.payload;

        const { data } = yield call(api.post, endpoint, body);

        if (!data.id) {
            throw new Error();
        }

        yield put(videoActions.createVideoViewSuccess(data.id));
        yield put(videoActions.getVideoUserIpRequest());
    } catch (error) {
        //
    }
}

export function* saveVideoProgress(action: IRequestAction<any>) {
    try {
        const { body, endpoint } = action.payload;

        yield call(api.post, endpoint, body);
    } catch (error) {
        //
    }
}

export function* markVideoView(action: any) {
    try {
        const { body, endpoint, onMarkViewed } = action.payload;

        yield call(api.post, endpoint, body);

        if (onMarkViewed) {
            onMarkViewed();
        }
    } catch (error) {
        //
    }
}

function* requestMockFile() {
    try {
        const startTime = new Date().getTime();

        yield fetch("https://cdn-prodigio.b-cdn.net/sample-file.txt");

        const endTime = new Date().getTime();

        return endTime - startTime;
    } catch (error) {
        return null;
    }
}

export function* reportVideoError(action: ReduxAction<ReportVideoErrorPayload>) {
    try {
        const { metadata = [], onSuccess, url, isEmbed } = action.payload;

        metadata.push({
            key: "ip",
            value: yield getUserIp()
        });

        if (isEmbed) {
            const downloadTime = yield requestMockFile();

            if (downloadTime !== null) {
                metadata.push({
                    key: "downloadTime",
                    value: downloadTime
                });
            }
        } else {
            metadata.push({
                key: "bandwidthEstimate",
                value: (window as any)?.hls?.bandwidthEstimate || 0
            });
        }

        yield call(api.post, "/student/error_report", { metadata, url });

        yield put(alertActions.showAlert("Dados enviados, obrigado por nos ajudar!", "success"));

        if (onSuccess) {
            onSuccess();
        }

        yield put(videoActions.reportVideoErrorSuccess());
    } catch (error) {
        console.log(error);
        yield put(videoActions.reportVideoErrorFailure());
    } finally {
        yield put(modalActions.closeModal());
    }
}

export function* setVideoViewed(action: ReduxAction<{ id: number; status: number }>) {
    try {
        const { id, status } = action.payload;

        yield call(api.patch, `/learningobject/${id}/view`, {
            viewed: status
        });

        yield put(videoActions.setVideoViewedSuccess({ id, status }));
    } catch (error) {
        yield put(videoActions.setViewedVideoFailure(error?.response?.data));
    }
}
