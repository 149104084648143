import React, { useCallback, useMemo } from "react";

// assets
import { ExerciseListAlternativeContainer, ExerciseListAlternativeProgressLabel } from "./styles";
import CheckIcon from "prodigio-icons/web/outlined/Check";
import CloseIcon from "prodigio-icons/web/outlined/Close";
import InfoIcon from "prodigio-icons/web/filled/Info";

// components
import ExerciseListQuestionAlternative from "../QuestionAlternative";

// helpers
import getLetterByNumber from "utils/getLetterByNumber";
import useCorrectAlternative from "screens/ExerciseList/utils/useCorrectAlternative";
import getAnswerStatus from "screens/ExerciseList/utils/getAnswerStatus";
import { IQuestionAlternative } from "interfaces/IQuestionAlternative";
import useMarkedAlternative from "screens/ExerciseList/utils/useMarkedAlternative";
import { theme } from "config/theme";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";

interface IExerciseListAlternativesProps {
    answer: any;
    alternatives: IQuestionAlternative[];
    showAnswer: boolean;
    resolutions: IExerciseListQuestionResolution[];
    isFinished: boolean;
    onClickAlternative(alternativeId: number): void;
    onClickSeeResolution(): void;
}

const ExerciseListQuestionListAlternatives = ({
    alternatives,
    answer,
    showAnswer,
    onClickAlternative,
    resolutions,
    onClickSeeResolution,
    isFinished
}: IExerciseListAlternativesProps) => {
    const correctAlternative = useCorrectAlternative({ alternatives });
    const markedAlternative = useMarkedAlternative({ answer, alternatives });

    const handleMarkAlternative = useCallback(
        (alternativeId: number) => {
            if (!alternativeId) {
                return;
            }

            onClickAlternative(alternativeId);
        },
        [onClickAlternative]
    );

    const handleClickSeeResolution = useCallback(() => {
        try {
            if (!resolutions || !resolutions.length) {
                throw new Error();
            }

            onClickSeeResolution();
        } catch (error) {
            console.log(error);
        }
    }, [resolutions, onClickSeeResolution]);

    const progressBarLabel = useCallback(
        (alternativeId: number, answerStatus: any) => {
            try {
                if (!alternativeId || !resolutions) {
                    throw new Error();
                }

                // Mostra o gabarito quando o usuário já finalizou a lista
                if (isFinished && showAnswer && !!answerStatus.isCorrect && !markedAlternative && !!resolutions.length) {
                    return (
                        <ExerciseListAlternativeProgressLabel data-test-id="exercise-list-resolution">
                            <InfoIcon width={11} height={11} /> Você não respondeu...{" "}
                            <span onClick={handleClickSeeResolution} data-test-id="exercise-list-resolution-button">
                                Ver Resolução
                            </span>
                        </ExerciseListAlternativeProgressLabel>
                    );
                }

                if (!markedAlternative || !correctAlternative) {
                    throw new Error();
                }

                if (!!answerStatus.isCorrect && markedAlternative.id === alternativeId) {
                    return (
                        <ExerciseListAlternativeProgressLabel data-test-id="exercise-list-resolution">
                            <CheckIcon width={12} height={11} className="success" /> Resposta correta!{" "}
                            {!!resolutions && !!resolutions.length && (
                                <span onClick={handleClickSeeResolution} data-test-id="exercise-list-resolution-button">
                                    Ver Resolução
                                </span>
                            )}
                        </ExerciseListAlternativeProgressLabel>
                    );
                }

                if (!!answerStatus.isWrong && markedAlternative.id === alternativeId) {
                    return (
                        <ExerciseListAlternativeProgressLabel data-test-id="exercise-list-resolution">
                            <CloseIcon width={10} height={10} color={theme.colors.system.danger[300]} className="error" /> Você errou...{" "}
                            {!!resolutions && !!resolutions.length && (
                                <span onClick={handleClickSeeResolution} data-test-id="exercise-list-resolution-button">
                                    Ver Resolução
                                </span>
                            )}
                        </ExerciseListAlternativeProgressLabel>
                    );
                }

                throw new Error("alternative not marked");
            } catch (error) {
                return null;
            }
        },
        [resolutions, markedAlternative, correctAlternative, isFinished, showAnswer, handleClickSeeResolution]
    );

    const showProgressBar = useMemo(() => (!!answer && !!answer.objectiveAnswer && showAnswer) || (isFinished && showAnswer), [
        answer,
        isFinished,
        showAnswer
    ]);

    return (
        <ExerciseListAlternativeContainer>
            {alternatives.map((alternative, index) => {
                const currentLetter = getLetterByNumber(index);

                const answerStatus = getAnswerStatus({ answer, alternativeId: alternative.id, showAnswer, correctAlternative, isFinished });

                const averageHitRate = alternative.averageHitRate ? alternative.averageHitRate.toFixed(2) || 0 : 0;

                return (
                    <ExerciseListQuestionAlternative
                        currentLetter={currentLetter}
                        answerStatus={answerStatus}
                        averageHitRate={averageHitRate}
                        onMark={() => handleMarkAlternative(alternative.id)}
                        showProgressBar={showProgressBar}
                        key={alternative.id}
                        enunciation={alternative.enunciation}
                        progressBarLabel={progressBarLabel(alternative.id, answerStatus)}
                        isFinished={isFinished}
                        showAnswer={showAnswer}
                        showDiscardLabel={isFinished ? false : !answer}
                    />
                );
            })}
        </ExerciseListAlternativeContainer>
    );
};

export default React.memo(ExerciseListQuestionListAlternatives);
