import { theme } from "config/theme";
import styled from "styled-components/macro";
import { Button2 } from "assets/styles/global";

export const LikeContainer = styled.button`
    align-items: center;
    display: flex;
`;

export const LikeCount = styled.span<{ color?: string }>`
    ${Button2};
    color: ${({ color }) => color || theme.colors.base[400]};
    margin-left: ${theme.spacing.smallX};
`;
