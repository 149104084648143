import React, { useMemo, useCallback, useEffect, useState } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";

import { Creators as modalActions } from "store/ducks/modal";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
// Helpers
import { IReduxStore } from "interfaces/IReduxStore";
import { isCourseFree } from "helpers/isCourseFree";

// Assets
import { Iframe, IframeForLegacyContainer } from "./styles";
import { theme } from "config/theme";

interface IIframeForLegacyProps {
    origin: string;
}

const IframeForLegacy = ({ origin }: IIframeForLegacyProps) => {
    const tokenLegacy = useSelector(({ auth }: IReduxStore) => auth.tokenLegacy);
    const dispatch = useDispatch();
    const { content, isLoading, courseSlug } = useSelector(({ lessonFreeCTA, course }: IReduxStore) => ({
        content: lessonFreeCTA?.content,
        isLoading: lessonFreeCTA?.isLoading,
        courseSlug: course?.slug
    }));
    const [hasModalOpened, setHasModalOpened] = useState(false);

    const src = useMemo(() => {
        switch (theme.project.slug) {
            case "proenem":
                return `${process.env.REACT_APP_PROENEM_LEGACY}/aluno/${origin}?tokenLegacy=${tokenLegacy}`;
            case "promilitares":
                return `${process.env.REACT_APP_PRO_REDACAO_URL}?tokenLegacy=${tokenLegacy}`;
            case "promedicina":
                return `${process.env.REACT_APP_PROMEDICINA_LEGACY}/aluno/${origin}?tokenLegacy=${tokenLegacy}`;
            default:
                return "";
        }
    }, [origin, tokenLegacy]);

    const modalCTA = useCallback(() => {
        dispatch(
            modalActions.openModal("callToAction", {
                type: "OPEN_MODAL",
                isCallToAction: true,
                linkHref: content.cta,
                imageUrl: content.redacoes
            })
        );
    }, [dispatch, content]);

    useEffect(() => {
        if (isCourseFree(courseSlug) && !hasModalOpened) {
            modalCTA();
            setHasModalOpened(true);
        }
    }, [modalCTA, courseSlug, hasModalOpened]);

    useEffect(() => {
        if (isLoading && isCourseFree(courseSlug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, courseSlug, isLoading]);

    return (
        <IframeForLegacyContainer>
            <Iframe
                frameBorder={0}
                src={src}
                allowFullScreen={true}
                // @ts-ignore
                mozallowfullscreen={true}
                msallowfullscreen={true}
                oallowfullscreen={true}
                webkitallowfullscreen={true}
            />
        </IframeForLegacyContainer>
    );
};

export default IframeForLegacy;
