// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Title, TitleIcon, TitleWrapper } from "./activity-title.styles";

// Types
import { ActivityTitleProps } from "./activity-title.types";

export const ActivityTitle: FunctionComponent<ActivityTitleProps> = ({ icon, title }) => {
    return (
        <TitleWrapper>
            <TitleIcon src={icon} />
            <Title>{title}</Title>
        </TitleWrapper>
    );
};
