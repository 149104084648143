import React from "react";

// Assets
import { SubjectGroupContainer, ExtraExerciseListScreenHomeTitle } from "./styles";

// Components
import ColorTaggedItem from "components/ColorTaggedExerciseList";

// Helpers
import generateColors from "helpers/generateColors";
import { IExtraExerciseListGroup } from "interfaces/IExerciseList";
import pluralize from "utils/pluralize";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface ISubjectGroupProps {
    subjectGroup: IExtraExerciseListGroup;
}

const ExtraExerciseListScreenHome = ({ subjectGroup }: ISubjectGroupProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const colors = generateColors(subjectGroup.color);

    return (
        <SubjectGroupContainer>
            {subjectGroup.subjects && subjectGroup.subjects.length > 0 && (
                <>
                    <ExtraExerciseListScreenHomeTitle>{subjectGroup.name}</ExtraExerciseListScreenHomeTitle>
                    {subjectGroup.subjects.map((subject: any) => {
                        return (
                            <ColorTaggedItem
                                key={subject.id}
                                listHeading={subject.name}
                                listDescription={<>{subject.totalExtraExerciseLists || 0} listas de exercícios</>}
                                bulletColor={colors[300]}
                                linkTo={`/app/curso/${courseSlug}/avaliacoes/${subject.slug}`}
                                {...(!!subject.availableExtraExerciseLists && {
                                    badge: {
                                        variant: "success",
                                        text: `${subject.availableExtraExerciseLists} ${pluralize(
                                            subject.availableExtraExerciseLists,
                                            "disponíveis",
                                            "disponível"
                                        )}`
                                    }
                                })}
                            />
                        );
                    })}
                </>
            )}
        </SubjectGroupContainer>
    );
};

export default ExtraExerciseListScreenHome;
