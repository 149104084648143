import { useEffect } from "react";

const useOnClickOutside = (ref: any, handler: any) => {
    useEffect(() => {
        const listener = (event: any) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }

            if (handler) {
                handler(event);
            }
        };

        document.addEventListener("mousedown", listener, false);

        return () => document.removeEventListener("mousedown", listener, false);
    }, [ref, handler]);
};

export default useOnClickOutside;
