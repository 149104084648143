// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEssaySubmissionsProps } from "./performance-essay-submissions.types";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

import {
    Container,
    SectionInformationsAndOrdenatorWrapper,
    InformationsWrapper,
    SactionTitle,
    CorrectedSubmissions,
    SubmissionsWrapper,
    LoadMoreWrapper
} from "./performance-essay-submissions.styles";

export const PerformanceEssaySubmissions: FunctionComponent<PerformanceEssaySubmissionsProps> = ({
    sectionTitle,
    correctedSubmissions,
    ordenatorComposition,
    submissionsCompositions,
    loadMoreElement
}) => {
    const correctedSubmissionsLabel = `${correctedSubmissions} envios corrigidos`;

    const compositionsDivider = (
        <>
            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="vertical" size={24} />} />

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={16} />} />
        </>
    );

    return (
        <Container>
            <SectionInformationsAndOrdenatorWrapper>
                <InformationsWrapper>
                    <SactionTitle>{sectionTitle}</SactionTitle>

                    <Spacing direction="horizontal" size={8} />

                    <CorrectedSubmissions>{correctedSubmissionsLabel}</CorrectedSubmissions>
                </InformationsWrapper>

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={16} />} />

                {ordenatorComposition}
            </SectionInformationsAndOrdenatorWrapper>

            {compositionsDivider}

            <SubmissionsWrapper>{submissionsCompositions}</SubmissionsWrapper>

            {compositionsDivider}

            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<LoadMoreWrapper>{loadMoreElement}</LoadMoreWrapper>} />
        </Container>
    );
};
