// Utils
import { theme } from "config/theme";

// Screens
import Shell from "screens/Shell";
import SignIn from "screens/SignIn";
import Signup from "screens/SignUp";
import RecoverPassword from "screens/RecoverPassword";
import MigrationUser from "screens/MigrationUser";
import ConfirmationPassword from "screens/ConfirmationPassword";
import RegisterPassword from "screens/RegisterPassword";
import Layers from "screens/Layers";

const isB2CBrands = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

const mainRoutes = [
    {
        title: "Login",
        component: SignIn,
        path: "/",
        exact: true
    },
    {
        title: "Layers",
        component: Layers,
        path: "/layers",
        exact: true
    },
    {
        title: "Main",
        component: Shell,
        path: "/app"
    },
    {
        title: "Recadastro",
        component: MigrationUser,
        path: "/recadastro",
        exact: true
    },
    {
        title: "Confirmation Password",
        component: ConfirmationPassword,
        path: "/confirmacao-de-senha",
        exact: true
    },
    {
        title: "Recuperar senha",
        component: RecoverPassword as any,
        path: "/recuperar-senha",
        exact: true
    },
    {
        title: "Recuperar senha",
        component: RecoverPassword as any,
        path: "/recuperar-senha/:code",
        exact: true
    },
    {
        title: "Cadastrar senha",
        component: RegisterPassword,
        path: "/cadastrar-senha",
        exact: true
    }
];

if (isB2CBrands) {
    mainRoutes.push({
        title: "Cadastro",
        component: Signup as any,
        path: "/cadastro",
        exact: true
    });
}

export default mainRoutes;
