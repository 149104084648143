import { createActions, createReducer } from "reduxsauce";
import { IContentSearch } from "interfaces/IContentSearch";
import { IContentSearchAdvancedOption } from "interfaces/IContentSearchAdvancedOption";

export interface IContentSearchState {
    isOpen: boolean;
    advancedOptions: IContentSearchAdvancedOption[];
    data: IContentSearch;
}

export const { Creators, Types } = createActions({
    getContentRequest: ["payload"],
    getContentSuccess: ["data"],
    getContentFailure: ["payload"],

    setAdvancedOption: ["payload"],
    setOpenModal: ["payload"]
});

export const INITIAL_STATE: IContentSearchState = {
    isOpen: false,
    advancedOptions: [
        { key: "lives", label: "Ao Vivo", selected: false },
        { key: "mentorships", label: "Monitoria", selected: false },
        { key: "exercisesList", label: "Listas de Exercícios", selected: false },
        { key: "extraExerciseList", label: "Listas de Exercícios Extras", selected: false },
        { key: "subjects", label: "Matérias", selected: false },
        { key: "projects", label: "Projetos", selected: false },
        { key: "essayThemes", label: "Temas de Redação", selected: false },
        { key: "exam", label: "Simulado", selected: false },
        { key: "previousExams", label: "Provas Anteriores", selected: false },
        { key: "module", label: "Módulos", selected: false }
    ],
    data: {
        projects: [],
        subjects: [],
        exercisesList: [],
        essayThemes: [],
        extraExerciseList: [],
        previousExams: [],
        exams: []
    }
};

const getContentRequest = (state = INITIAL_STATE) => ({
    ...state,
    data: { ...INITIAL_STATE.data }
});

const getContentSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    data: action.data
});

const getContentFailure = (state = INITIAL_STATE) => ({
    ...state
});

const setAdvancedOption = (state = INITIAL_STATE, action: any) => ({
    ...state,
    advancedOptions: [...action.payload]
});

const setOpenModal = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isOpen: action.payload
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_CONTENT_REQUEST]: getContentRequest,
    [Types.GET_CONTENT_SUCCESS]: getContentSuccess,
    [Types.GET_CONTENT_FAILURE]: getContentFailure,
    [Types.SET_ADVANCED_OPTION]: setAdvancedOption,
    [Types.SET_OPEN_MODAL]: setOpenModal
});
