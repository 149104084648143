import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { RadioGroupContainer, RadioGroupItem } from "./styles";

const RadioGroupPlaceholder = () => (
    <RadioGroupContainer data-test-id="radio-group">
        <Skeleton width="100px" height="12px" style={{ marginBottom: 16 }} />
        {Array.from(Array(4)).map((_, index) => (
            <RadioGroupItem active={false} key={index}>
                <Skeleton width="20px" height="20px" style={{ borderRadius: "50%", marginRight: 8 }} />
                <Skeleton width="100px" height="12px" />
            </RadioGroupItem>
        ))}
    </RadioGroupContainer>
);

export default RadioGroupPlaceholder;
