import React, { useCallback, memo, useMemo, forwardRef } from "react";
import html2canvas from "html2canvas";

import Button from "components/Button";

import { EssayStatus } from "enums/EssayStatus";

import * as S from "./styles";

const ESSAY_MAX_COLS = 78;
const ESSAY_MAX_LINES = 30;

interface IEssayCanvasProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    handleDiscard(): void;
    handleDraft(content: string, image: string, status: number): void;
    handleCorrection(content: string, image: string, status: number): void;
}

const EssayCanvas = forwardRef<HTMLTextAreaElement, IEssayCanvasProps>(
    ({ value, onChange, onKeyUp, handleDiscard, handleDraft, handleCorrection }, ref) => {
        const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => event.preventDefault(), []);

        const essayCanvasPrint: HTMLElement | null = document.getElementById("print");

        const handleSendDraft = useCallback(
            (status: number) => () => {
                if (!essayCanvasPrint) {
                    return;
                }

                html2canvas(essayCanvasPrint!).then((canvas) => {
                    const image = canvas.toDataURL("image/png");
                    const content = value || "";
                    handleDraft(content, image, status);
                });
            },
            [essayCanvasPrint, handleDraft, value]
        );

        const handleSendCorrection = useCallback(
            (status: number) => () => {
                if (!essayCanvasPrint) {
                    return;
                }

                html2canvas(essayCanvasPrint!).then((canvas) => {
                    const image = canvas.toDataURL("image/png");
                    const content = value || "";
                    handleCorrection(content, image, status);
                });
            },
            [essayCanvasPrint, handleCorrection, value]
        );

        const valueEngineered = useMemo(() => value.substring(0, ESSAY_MAX_COLS * ESSAY_MAX_LINES), [value]);

        return (
            <S.EssayCanvasContainer>
                <S.EssayCanvasWrapper>
                    <S.EssayCanvasLines>
                        {Array.from(Array(ESSAY_MAX_LINES)).map((_, index) => (
                            <S.EssayCanvasLinesItem key={index}>{index + 1}</S.EssayCanvasLinesItem>
                        ))}
                    </S.EssayCanvasLines>
                    <S.EssayCanvasHelper
                        ref={ref}
                        wrap="hard"
                        value={valueEngineered}
                        id="essay"
                        cols={ESSAY_MAX_COLS}
                        rows={ESSAY_MAX_LINES}
                        maxLength={ESSAY_MAX_COLS * ESSAY_MAX_LINES}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        onPaste={handlePaste}
                    />
                    <S.EssayCanvasPrint id="print">{valueEngineered}</S.EssayCanvasPrint>
                </S.EssayCanvasWrapper>
                <S.EssayCanvasNav>
                    <Button size="medium" variant="outline-info" title="Descartar" onClick={handleDiscard}>
                        Descartar
                    </Button>
                    <Button
                        size="medium"
                        variant="outline-info"
                        disabled={!value}
                        title="Salvar rascunho"
                        onClick={handleSendDraft(EssayStatus.Draft)}
                    >
                        Salvar rascunho
                    </Button>
                    <Button size="medium" variant="info" title="Enviar" disabled={!value} onClick={handleSendCorrection(EssayStatus.Pending)}>
                        Enviar para correção
                    </Button>
                </S.EssayCanvasNav>
            </S.EssayCanvasContainer>
        );
    }
);

export default memo(EssayCanvas);
