import React, { useMemo } from "react";

// Dependencies
import { RouteComponentProps, withRouter } from "react-router-dom";

// import { theme } from "config/theme";

// Components
import IframeForLegacy from "components/IframeForLegacy";
import EssayThemesScreen from "screens/Essay/themes";

const EssayLegacy = (props: RouteComponentProps<{ id: string }>) => {
    const url = useMemo(() => (props.match.params.id ? `pro-redacao#/fazer-redacao/tema/${props.match.params.id}` : "pro-redacao"), [
        props.match.params.id
    ]);

    return <IframeForLegacy origin={url} />;
};

// const withNewEssay = theme.project.slug === "promilitares";
const withNewEssay = false;

export default withNewEssay ? EssayThemesScreen : withRouter(EssayLegacy);
