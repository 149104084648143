import { theme } from "config/theme";
import styled from "styled-components";

export const AnnouncementModalContainer = styled.div`
    width: 700px;
    max-width: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
`;

export const AnnouncementModalHeader = styled.header`
    display: flex;
    align-items: center;
`;

export const AnnouncementModalHeaderTeacher = styled.span`
    color: #7c8195;
    font-size: ${theme.typography.sizes.medium};
    font-style: italic;
    display: flex;
    align-items: center;
    font-weight: 500;
`;

export const AnnouncementModalHeaderTime = styled.span`
    color: #31343c;
    font-size: ${theme.typography.sizes.smallX};
    font-weight: 500;

    small {
        opacity: 0.4;
        margin: 0 5px;
        color: #7c8195;
    }
`;

export const AnnouncementModalContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    span {
        margin-top: 15px;
        color: #31343c;
        font-size: ${theme.typography.sizes.medium};
        font-weight: 300;
    }
`;

export const AnnouncementModalContentTitle = styled.div`
    color: #31343c;
    font-weight: 500;
    font-size: ${theme.typography.sizes.mediumX};
`;
