import React, { useMemo } from "react";

// assets
import { ExerciseListAnswerCardContainer } from "./styles";

// components
import ExerciseListAnswerCardQuestion from "../AnswerCardQuestion";
import ExerciseListAnswerCardKnowledgeArea from "../AnswerCardKnowledgeArea";

// helpers
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { IExerciseListQuestion } from "interfaces/IExerciseListQuestion";
import { IAnswerCardItem } from "store/ducks/answerCard";

interface IProps {
    showAnswers: boolean;
    questions: IExerciseListQuestion[];
    answers: IAnswerCardItem[];
    showNotes: boolean;
    isFinished: boolean;
    knowledgeAreas: IExamKnowledgeAreas[];
    onClickSeeQuestion(questionNumber: number): void;
    isFeedBack: boolean;
    canSeeQuestion: boolean;
    isExam?: boolean;
}

const ExerciseListAnswerCard = ({
    questions,
    showAnswers,
    answers,
    showNotes,
    onClickSeeQuestion,
    isFinished,
    knowledgeAreas,
    isFeedBack = false,
    canSeeQuestion
}: IProps) => {
    const listExerciseListQuestions = useMemo(() => {
        try {
            return questions.map((questionItem, index) => {
                const currentAnswer = answers.filter((answer) => !!answer.question).find((answer) => answer.question.id === questionItem.question.id);

                return (
                    <ExerciseListAnswerCardQuestion
                        key={questionItem.id}
                        question={questionItem.question}
                        number={questionItem.question.number || index + 1}
                        showAnswer={showAnswers}
                        showNote={showNotes}
                        answer={currentAnswer}
                        onClickSee={() => onClickSeeQuestion(questionItem.question?.number || index + 1)}
                        isFinished={isFinished}
                        canSeeQuestion={canSeeQuestion}
                    />
                );
            });
        } catch (error) {
            return null;
        }
    }, [answers, canSeeQuestion, isFinished, onClickSeeQuestion, questions, showAnswers, showNotes]);

    const listExamKnowledgeAreas = useMemo(() => {
        try {
            return knowledgeAreas.map(({ name, id, questions, color }) => (
                <ExerciseListAnswerCardKnowledgeArea
                    questions={questions}
                    name={name}
                    key={id}
                    color={color}
                    showAnswers={showAnswers}
                    showNotes={showNotes}
                    isFinished={isFinished}
                    onClickSeeQuestion={onClickSeeQuestion}
                    answers={answers}
                    isFeedBack={isFeedBack}
                    canSeeQuestion={canSeeQuestion}
                />
            ));
        } catch (error) {
            return null;
        }
    }, [answers, canSeeQuestion, isFeedBack, isFinished, knowledgeAreas, onClickSeeQuestion, showAnswers, showNotes]);

    return (
        <ExerciseListAnswerCardContainer data-test-id="exercise-list-answer-card">
            {listExamKnowledgeAreas}
            {listExerciseListQuestions}
        </ExerciseListAnswerCardContainer>
    );
};

export default React.memo(ExerciseListAnswerCard);
