// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

export type WebpNumberInputPayload = { value: number };

type WebpNumberInputProps = {
    min: number;
    max: number;
    step: number;
    value: number;
    invalid: boolean;
    onWebpNumberInputChange: (payload: any) => void;
};

export const WebpNumberInput: FunctionComponent<WebpNumberInputProps> = ({ invalid = false, max, min, step, value, onWebpNumberInputChange }) => {
    const handleClick = (operation: number) => {
        const newVal = value + operation;

        if (max !== null && newVal > max) {
            return;
        }

        if (max !== null && newVal < min) {
            return;
        }

        onWebpNumberInputChange({ detail: { value: newVal } });
    };

    const handleValueChange = (nextValue: string) => {
        const newVal = Number(nextValue);

        if (value === newVal) return;

        onWebpNumberInputChange({ detail: { value: newVal } });
    };

    return (
        <Container isInvalid={invalid}>
            <span className="button minus" onClick={() => handleClick(-step)}>
                <svg width="8" height="2" viewBox="0 0 8 2" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path
                        d="M1.44099 2C0.960662 2 0.596273 1.91246 0.347826 1.73737C0.115942 1.56229 0 1.31986 0 1.0101C0 0.700336 0.115942 0.457912 0.347826 0.282828C0.596273 0.0942761 0.960662 0 1.44099 0H6.55901C7.03934 0 7.39544 0.0942761 7.62733 0.282828C7.87578 0.457912 8 0.700336 8 1.0101C8 1.31986 7.87578 1.56229 7.62733 1.73737C7.39544 1.91246 7.03934 2 6.55901 2H1.44099Z"
                        fill="currentColor"
                    />
                </svg>
            </span>

            <input
                className="native-input"
                type="number"
                onChange={(e: any) => handleValueChange(e.target.value)}
                min={min}
                max={max}
                step={step}
                value={value}
            />

            <span className="button plus" onClick={() => handleClick(+step)}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path
                        d="M10.918 4.91803C11.2295 4.91803 11.4836 5.02459 11.6803 5.23771C11.8934 5.43443 12 5.68853 12 6C12 6.31148 11.8934 6.57377 11.6803 6.78689C11.4836 6.98361 11.2295 7.08197 10.918 7.08197H7.08197V10.8934C7.08197 11.2049 6.97541 11.4672 6.7623 11.6803C6.56557 11.8934 6.31148 12 6 12C5.68853 12 5.42623 11.8934 5.21311 11.6803C5.01639 11.4672 4.91803 11.2049 4.91803 10.8934V7.08197H1.08197C0.770492 7.08197 0.508197 6.98361 0.295082 6.78689C0.0983607 6.57377 0 6.31148 0 6C0 5.68853 0.0983607 5.43443 0.295082 5.23771C0.508197 5.02459 0.770492 4.91803 1.08197 4.91803H4.91803V1.08197C4.91803 0.770492 5.01639 0.516394 5.21311 0.319672C5.42623 0.106557 5.68853 0 6 0C6.31148 0 6.56557 0.106557 6.7623 0.319672C6.97541 0.516394 7.08197 0.770492 7.08197 1.08197V4.91803H10.918Z"
                        fill="currentColor"
                    />
                </svg>
            </span>
        </Container>
    );
};
