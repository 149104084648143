import styled from "styled-components";
import { MediaQueriesManual } from "assets/styles/settings";
import { theme } from "config/theme";

export const Container = styled.div`
    webp-dropdown-full {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const ContentHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    margin-top: 16px;

    .webp-item-materials:last-child {
        border: none;
    }

    ${MediaQueriesManual(1200)} {
        margin-top: 32px;
    }
`;

export const Icon = styled.div`
    margin-right: ${theme.typography.sizes.medium};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.p`
    font-weight: 700;
    color: ${theme.colors.base[500]};
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;

export const ExerciseListModalTextWrapper = styled.div`
    width: 100%;
    padding: ${theme.typography.sizes.medium} 0;
    margin: ${theme.typography.sizes.medium} 0 ${theme.typography.sizes.large};

    display: flex;
    align-items: center;
    gap: 12px;
`;

export const ExerciseListModalTextIndicator = styled.span`
    width: 4px;
    height: 28px;

    background-color: ${theme.colors.brand[300]};
    border-radius: 8px;
`;

export const ExerciseListModalText = styled.p`
    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;
    font-size: ${theme.typography.sizes.medium};
    font-style: normal;
    font-weight: 400;
    line-height: 22px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
