// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

type WebpStepDotProps = {
    status: "completed" | "selected" | "disabled";
    alignDots: "left" | "center" | "right";
    orientation: "horizontal" | "vertical";
    heightDotDashed?: string;
};

export const WebpStepDot: FunctionComponent<WebpStepDotProps> = ({ status, alignDots, orientation, children, heightDotDashed }) => {
    return (
        <Container orientation={orientation} status={status}>
            <div className="dot-container">
                {heightDotDashed ? (
                    <div style={{ height: heightDotDashed }} className={"dot-dashed heightDotDashed"}>
                        <span></span>
                    </div>
                ) : (
                    <div className="dot-dashed">
                        <span></span>
                    </div>
                )}

                <div className={alignDots + " dot"}>
                    <span></span>
                </div>

                {children}
            </div>
        </Container>
    );
};
