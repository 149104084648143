import React from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import Competence from "./competence";

import * as C from "./styles";

interface ICompetencesProps {
    composition: IEssayComposition;
}

const EssayCompetences = ({ composition }: ICompetencesProps) => {
    return (
        <C.EssayCompetencesContainer>
            <C.EssayCompetencesTitle>Nota por competência</C.EssayCompetencesTitle>
            <C.EssayCompetencesList>
                {composition?.competences?.map((item, index) => {
                    return <Competence key={index} item={item} />;
                })}
            </C.EssayCompetencesList>
        </C.EssayCompetencesContainer>
    );
};

export default EssayCompetences;
