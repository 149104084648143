export enum CreditType {
    Subscription = 1,
    Live = 2,
    Course = 3,
    Essay = 4,
    Exam = 5,
    Project = 6,
    Mentorship = 7,
    Device = 8
}
