// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
`;

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;

    background-color: #571d5d;
    border-radius: 50%;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;

export const Icon = styled.img`
    width: 100%;
    height: 100%;
`;

export const OffensiveDaysWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OffensiveDaysDescription = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #505462;

    white-space: nowrap;

    @media (min-width: 1024px) {
        font-size: 16px;
    }
`;

export const OffensiveDaysContent = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #571d5d;

    @media (min-width: 1024px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
`;

// ----------

export const IconWrapperSkeleton = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;

export const OffensiveDaysDescriptionSkeleton = styled.div`
    height: 22px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        padding: 3px 0;
    }
`;

export const OffensiveDaysContentSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        height: 28px;
        padding: 5px 0;
    }
`;
