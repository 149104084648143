import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { pulse } from "assets/styles/animations";
import { MediaQueries } from "assets/styles/settings";
import { Button2, Button3 } from "assets/styles/global";

export const WizardContainer = styled.div``;

export const WizardNavContainer = styled.nav`
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 208px;
    margin: 0 auto 40px;
    position: relative;

    &:before {
        border-top: 1px dashed ${theme.colors.base[200]};
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 47px;
        max-width: 400px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: 56px;
    }
`;

export const WizardNavBullet = styled.span<{ isCompleted: boolean; isCurrent: boolean }>`
    border-radius: 50%;
    display: block;
    height: 16px;
    padding: 4px;
    position: relative;
    width: 16px;
    z-index: 2;

    &:after {
        border-radius: 50%;
        content: "";
        display: block;
        height: 8px;
        width: 8px;
    }

    ${({ isCurrent, isCompleted }) => {
        if (isCurrent) {
            return css`
                animation: ${pulse} 0.8s infinite;
                background: ${theme.colors.system.info[100]};

                &:after {
                    background: ${theme.colors.system.info[200]};
                }
            `;
        }

        if (isCompleted) {
            return css`
                animation: ${pulse} 0.8s infinite;
                background: ${theme.colors.system.success[100]};

                &:after {
                    background: ${theme.colors.system.success[200]};
                }
            `;
        }

        return css`
            background: ${theme.colors.base[100]};

            &:after {
                background: ${theme.colors.base[200]};
            }
        `;
    }}

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: unset;
    }
`;

export const WizardNavItem = styled.button`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: relative;
        z-index: 2;
    }
`;

export const WizardNavLabel = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
    display: block;
    left: 0;
    position: absolute;
    text-align: center;
    top: 23px;
    white-space: nowrap;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${Button2};
        font-weight: 700;
        left: 50%;
        top: 29px;
        transform: translateX(-50%);
        width: unset;
    }
`;

export const WizardContent = styled.div``;
