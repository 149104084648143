import React, { memo } from "react";

import Skeleton from "components/Skeleton";

const SubjectScreenDetailsHeaderSkeletonProgress = memo(() => {
    return (
        <>
            <div style={{ display: "flex", width: "100%", maxWidth: "100%", marginBottom: "4px" }}>
                <Skeleton width="135px" height="12px" style={{ maxWidth: "100%", marginRight: "auto" }} />

                <Skeleton width="17px" height="12px" style={{ maxWidth: "100%" }} />
            </div>

            <Skeleton width="100%" height="8px" style={{ maxWidth: "100%", borderRadius: "4px" }} />
        </>
    );
});

export default SubjectScreenDetailsHeaderSkeletonProgress;
