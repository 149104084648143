// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const DefaultActivityCard = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    font-family: "Nunito", sans-serif;
    color: #4d4d63;
`;

export const ActivityScoreWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_HUGE} {
        flex-direction: column;
    }
`;

export const ScoreActivityCardWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const ActivityScoreLabelWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        flex-direction: row;
        align-items: center;
    }
`;

export const ActivityScoreLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #232326;
    white-space: nowrap;
`;

export const ActivityProgressWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export const ActivityProgressLabelWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 56px;
`;

export const ActivityProgressLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    color: #4d4d63;
    white-space: nowrap;
`;

export const ActivityExerciseCardSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f0f1f7;
`;

export const ActivitySendFileAnswerWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    > .webapp-file .webapp-progress-bar {
        width: 120px;
    }
`;

export const ActivityExerciseCardTitleWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
`;

export const ActivityExerciseCardTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #000000;
`;

export const ActivityExerciseCardSubTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #83899e;
`;

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ActivityMobileTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #24388c;
`;

export const ActivityDefaultRemoveIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
`;

type ActionButtonIconStyleProps = {
    hasCursorPointer?: boolean;
};

export const ActionButtonIcon = styled.img<ActionButtonIconStyleProps>`
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    ${({ hasCursorPointer }) =>
        !!hasCursorPointer &&
        css`
            cursor: pointer;
        `}
`;
