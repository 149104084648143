import React from "react";
import { WatchScreenForumErrorContainer } from "./styles";
import ErrorUI from "components/ErrorUI";

const WatchScreenForumError: React.FC = () => {
    return (
        <WatchScreenForumErrorContainer>
            <ErrorUI title=" " message="Não conseguimos carregar a monitoria." />
        </WatchScreenForumErrorContainer>
    );
};

export default WatchScreenForumError;
