// Dependencies
import React from "react";

// Assets
import { SignRightContent, CarouselCardItemTitle, CarouselCardItemContent } from "../styles";
import trophy from "assets/img/trophy.svg";

// Helpers
import { theme } from "config/theme";
import { ternary } from "utils/ternary";

// Components
import { Benefits } from "./benefits";
import { Carousel } from "components/Carousel";
import { CarouselCardItem } from "components/CarouselCardItem";
import { GetWidth } from "utils/windowsSize";

// Icons
import { Background } from "./icons/background";

// Assets
import anaRececaImage from "./assets/ana-rebeca.jpeg";
import gabrielBarbosaImage from "./assets/gabriel-barbosa.jpeg";
import isadoraHelenaImage from "./assets/isadora-helena.jpeg";
import guilhermeTelesImage from "./assets/guilherme-teles.jpeg";
import laysllaFerreiraImage from "./assets/layslla-ferreira.jpeg";
import camilaMesquitaImage from "./assets/camila-mesquita.png";
import douglasFernandesImage from "./assets/douglas-fernandes.png";
import laisJulianaImage from "./assets/lais-juliana.png";
import nathanKistenmackerImage from "./assets/nathan-kistenmacker.png";
import sauloCostaImage from "./assets/saulo-costa.png";
import karolinaAndradeImage from "./assets/karolina-andrade.png";
import israelHenriqueImage from "./assets/israel-henrique.png";
import eduardoRodriguesImage from "./assets/eduardo-rodrigues.png";
import filipeVellascoImage from "./assets/filipe-vellasco.png";
import brunoNevesImage from "./assets/bruno-neves.png";

const proenemData = [
    {
        name: "Ana Rebeca",
        image: anaRececaImage,
        course: "Medicina - UFCE",
        gender: "F"
    },
    {
        name: "Gabriel Barbosa",
        image: gabrielBarbosaImage,
        course: "Engenharia Elétrica - IFPA",
        gender: "M"
    },
    {
        name: "Isadora Helena",
        image: isadoraHelenaImage,
        course: "Odontologia - UEPB",
        gender: "F"
    },
    {
        name: "Guilherme Teles Pastro",
        image: guilhermeTelesImage,
        course: "Antropologia - UFPEL",
        gender: "M"
    },
    {
        name: "Layslla Ferreira",
        image: laysllaFerreiraImage,
        course: "Serviço Social - UFAL",
        gender: "F"
    }
];

const promedicinaData = [
    {
        name: "Camila Mesquita",
        image: camilaMesquitaImage,
        course: "Medicina - UNIRIO",
        gender: "F"
    },
    {
        name: "Douglas Fernandes Cordeiro",
        image: douglasFernandesImage,
        course: "Radiologia - UTFPR",
        gender: "M"
    },
    {
        name: "Laís Juliana",
        image: laisJulianaImage,
        course: "Medicina - UEMA",
        gender: "F"
    },
    {
        name: "Nathan Kistenmacker",
        image: nathanKistenmackerImage,
        course: "Medicina - UFRJ",
        gender: "M"
    },
    {
        name: "Saulo da Costa",
        image: sauloCostaImage,
        course: "Medicina - UPE",
        gender: "M"
    }
];

const promilitaresData = [
    {
        name: "Karolina Andrade",
        image: karolinaAndradeImage,
        course: "EEAR",
        gender: "F"
    },
    {
        name: "Israel Henrique de Pádua",
        image: israelHenriqueImage,
        course: "ESA",
        gender: "M"
    },
    {
        name: "Eduardo Rodrigues",
        image: eduardoRodriguesImage,
        course: "EAM",
        gender: "M"
    },
    {
        name: "Filipe Vellasco",
        image: filipeVellascoImage,
        course: "ESA",
        gender: "M"
    },
    {
        name: "Bruno Neves dos Reis",
        image: brunoNevesImage,
        course: "EsPCex",
        gender: "M"
    }
];

const RightContent = () => {
    const isDesktop = GetWidth >= 1024;
    const isB2CBrands = ["proenem", "promedicina", "promilitares"].includes(theme.project.slug);

    const data = ternary([
        [theme.project.slug === "proenem", proenemData],
        [theme.project.slug === "promedicina", promedicinaData],
        [theme.project.slug === "promilitares", promilitaresData],
        [true, proenemData]
    ])!;

    return (
        <SignRightContent>
            <Background />

            {!!isDesktop && !isB2CBrands && <Benefits />}

            {!!isDesktop && !!isB2CBrands && (
                <Carousel
                    autoSlide
                    content={data.map((item, index) => (
                        <CarouselCardItem
                            key={index}
                            icon={trophy}
                            title={
                                <CarouselCardItemTitle>
                                    {`Aprovad${item.gender === "M" ? "o" : "a"} ${theme.project.slug !== "promilitares" ? "em " : ""}`}
                                    <strong>{item.course}</strong>
                                </CarouselCardItemTitle>
                            }
                            subTitle={item.name}
                            content={<CarouselCardItemContent src={item.image} />}
                        />
                    ))}
                />
            )}
        </SignRightContent>
    );
};

export default RightContent;
