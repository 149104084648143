import React, { useMemo } from "react";
import { WebpCard } from "components/WebpCard";
import { WebpNumberInput, WebpNumberInputPayload } from "components/WebpNumberInput";
import { handleFilterChangeType } from "../..";
import Spinner from "../../../../../components/Spinner";
import { WebpText } from "components/WebpText";
import { theme } from "../../../../../config/theme";
import { ExerciseListCustomStateKeys, IExerciseListCustomState } from "../../../../../interfaces/IExerciseListCustom";
import { SummaryContainer, SummaryContainerRow } from "./styles";

interface IFilterSummaryProps {
    handleFilterChange: handleFilterChangeType;
    exerciseListCustomState: IExerciseListCustomState;
}

function FilterSummary({ handleFilterChange, exerciseListCustomState }: IFilterSummaryProps) {
    const { listSize, userListSize, isLoading } = exerciseListCustomState;

    const listLimit = useMemo(() => {
        return listSize && listSize < 100 ? listSize : 100;
    }, [listSize]);

    return (
        <>
            <WebpCard
                headerComposition={
                    <SummaryContainer>
                        <SummaryContainerRow>
                            <WebpText xs="body-text-02" color="neutral" colorWeight={400}>
                                <span>Questões encontradas:</span>
                            </WebpText>

                            <WebpText xs="title-sm" color="neutral" colorWeight={400}>
                                <span>
                                    {isLoading ? (
                                        <Spinner fixed={false} size={28} color={theme.colors.black} />
                                    ) : (
                                        listSize && new Intl.NumberFormat("pt-BR").format(listSize)
                                    )}
                                </span>
                            </WebpText>
                        </SummaryContainerRow>
                        <SummaryContainerRow>
                            <WebpText xs="body-text-02" color="neutral" colorWeight={400}>
                                <span>Quantidade na lista:</span>
                            </WebpText>
                            <WebpNumberInput
                                step={1}
                                max={listLimit}
                                min={0}
                                value={userListSize ?? 0}
                                invalid={!userListSize}
                                onWebpNumberInputChange={(payload: WebpNumberInputPayload) => {
                                    handleFilterChange(ExerciseListCustomStateKeys.USER_LIST_SIZE, payload, userListSize);
                                }}
                            />
                        </SummaryContainerRow>
                    </SummaryContainer>
                }
            ></WebpCard>
        </>
    );
}

export default FilterSummary;
