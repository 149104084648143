// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityResultsProps } from "./activity-results.types";

// Styles
import { Container, HeaderWrapper, HeaderContent, ContentWrapper } from "./activity-results.styles";

export const ActivityResults: FunctionComponent<ActivityResultsProps> = ({ breadcrumbsComposition, content }) => {
    return (
        <Container>
            <HeaderWrapper>
                <HeaderContent>{breadcrumbsComposition}</HeaderContent>
            </HeaderWrapper>

            <ContentWrapper>{content}</ContentWrapper>
        </Container>
    );
};
