import { theme } from "config/theme";
import styled from "styled-components";
import { FlatVariant } from "utils/types/Variant";

const colors: any = {
    ...theme.colors.system,
    secondary: theme.colors.secondary
};

export const BadgeContainer = styled.span<{ variant: FlatVariant | "secondary"; backgroundWeight?: number }>`
    background: ${({ variant, backgroundWeight }) => colors[variant][backgroundWeight || 300]};
    color: ${({ variant }) => colors[variant][500]};
    font-size: ${theme.typography.sizes.small};
    line-height: 1;
    padding: ${theme.spacing.smallXX} ${theme.spacing.small};
    border-radius: 40px;
    font-weight: bold;
`;
