import React from "react";
import { ExamScreenDesktopFilterContainer } from "./styles";

import { IFilterGroup } from "interfaces/IFilterGroup";
import FilterGroup from "components/FilterGroup";

interface IProps {
    onChangeFilter(options: IFilterGroup[]): void;
    categoryOptions: IFilterGroup[];
}

const ExamScreenDesktopFilter = ({ onChangeFilter, categoryOptions }: IProps) => {
    return (
        <ExamScreenDesktopFilterContainer>
            <FilterGroup options={categoryOptions} onChange={onChangeFilter} withHeader={true} />
        </ExamScreenDesktopFilterContainer>
    );
};

export default React.memo(ExamScreenDesktopFilter);
