import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { IEssayComposition } from "store/interfaces/IEssay";
import { IReduxStore } from "interfaces/IReduxStore";
import { EssayStatus } from "enums/EssayStatus";

import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";
import Badge from "components/Badge";
import { PageHeaderButton } from "components/PageHeader/styles";

import { EssayDispatchContainer, EssayDispatchRow, EssayDispatchTxt, EssayDispatchThemeTitle } from "./styles";

import { EssayStatusColor } from "../helpers";
import formatDate from "utils/formatDate";

interface IEssayDispatchProps {
    composition: IEssayComposition;
}

const EssayDispatch = ({ composition }: IEssayDispatchProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const getStatusColor = useMemo(() => EssayStatusColor[composition.status], [composition.status]);

    const creationDate = useMemo(() => formatDate(composition.created, "dd/LL/yyyy"), [composition.created]);

    const getStatsTotal = useMemo(() => Number(composition?.revisions[0]?.score ?? 0).toLocaleString("pt-br", { minimumFractionDigits: 2 }), [
        composition
    ]);

    const showEssayLink = [EssayStatus.Corrected, EssayStatus.Draft].includes(composition.status);

    return (
        <EssayDispatchContainer>
            <EssayDispatchRow>
                <Badge variant={getStatusColor?.color || "secondary"} backgroundWeight={300}>
                    {getStatusColor?.label}
                </Badge>
                {showEssayLink && (
                    <PageHeaderButton type="button" as={Link} to={`/app/curso/${courseSlug}/redacoes/envios/${composition.idComposition}`}>
                        Ver redação
                        <ChevronRightIcon className="ico-chevron-right" width="4" height="8" />
                    </PageHeaderButton>
                )}
            </EssayDispatchRow>
            {composition.theme?.title && <EssayDispatchThemeTitle as="p">{composition.theme?.title}</EssayDispatchThemeTitle>}
            {composition.status >= EssayStatus.Draft && (
                <EssayDispatchRow as="footer">
                    <EssayDispatchTxt>{composition.status === EssayStatus.Draft ? "Não enviado" : `Enviado em ${creationDate}`}</EssayDispatchTxt>
                    {composition.status === EssayStatus.Corrected && (
                        <EssayDispatchTxt>
                            Nota: <strong>{getStatsTotal}</strong>
                        </EssayDispatchTxt>
                    )}
                </EssayDispatchRow>
            )}
        </EssayDispatchContainer>
    );
};

export default EssayDispatch;
