// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ButtonProps } from "./button.types";

// Styles
import { Container, Label } from "./button.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";

export const Button: FunctionComponent<ButtonProps> = ({
    label,
    variant = "contained",
    appearance = "primary",
    size = "large",
    isFullyAdaptative = false,
    icon,
    handleClick
}) => {
    return (
        <Container variant={variant} appearance={appearance} size={size} isFullyAdaptative={isFullyAdaptative} onClick={handleClick}>
            <ConditionallyRender
                shouldRender={!!icon}
                content={
                    <>
                        {icon}

                        <Spacing direction="horizontal" size={8} />
                    </>
                }
            />

            <Label variant={variant} appearance={appearance} size={size}>
                {label}
            </Label>
        </Container>
    );
};
