import styled from "styled-components";
import { theme } from "config/theme";
import Timer from "prodigio-icons/web/filled/Timer";

export const ExerciseListTimerContainer = styled.div`
    display: flex;
    align-items: center;

    color: ${theme.colors.base[400]};
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
`;

export const ExerciseListTimerIcon = styled(Timer)`
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing.smallXX};
`;
