import styled from "styled-components/macro";
import { TitleH4, Micro, BodyText1, BodyText2 } from "assets/styles/global";
import { theme } from "config/theme";

export const BrowserWarningContainer = styled.div`
    max-width: 340px;
    padding: 32px 24px;
    text-align: center;
`;

export const BrowserWarningTitle = styled.h2`
    ${TitleH4};
    color: ${theme.colors.base[500]};
    margin: 16px 0 24px;
`;

export const BrowserWarningContent = styled.div`
    margin-bottom: 16px;

    p {
        ${BodyText1};
        color: ${theme.colors.base[500]};

        strong {
            display: block;
        }

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
`;

export const BrowserWarningSuggestions = styled.div`
    align-items: flex-start;
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

export const BrowserWarningTxt = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[500]};
    display: block;

    span {
        display: block;
    }
`;

export const Browser = styled.figure`
    text-align: center;

    img {
        max-height: 32px;
    }

    &:not(:last-child) {
        margin-right: 32px;
    }
`;

export const BrowserName = styled.figcaption`
    ${Micro};
    color: ${theme.colors.base[400]};
    display: block;
    margin-top: 8px;
`;
