export function createPortalElement(portalId: string) {
    const portalElement = document?.createElement("div");

    portalElement?.setAttribute("id", portalId);

    if (!portalElement) {
        return;
    }

    return document?.getElementById("root")?.appendChild(portalElement);
}
