import React, { useReducer, useCallback } from "react";

// Dependencies
import findIndex from "lodash/findIndex";

// Helpers
import { MigrationUserContextActions, MigrationUserContextState, MigrationUserContextReducerAction, MigrationUserContextType } from "./types";

const INITIAL_STATE: MigrationUserContextState = {
    isLoading: false,
    steps: [
        {
            slug: "START",
            label: "Início"
        },
        {
            slug: "USER_DETAILS",
            label: "Dados pessoais"
        },
        {
            slug: "ADDRESS",
            label: "Endereço"
        },
        {
            slug: "CONFIRM_DATA",
            label: "Confirmação"
        },
        {
            slug: "FINISH",
            label: "Término"
        }
    ],
    completeds: [],
    currentWizardStep: {
        slug: "START",
        label: "Início"
    },
    payload: {}
};

const MigrationUserContext = React.createContext<MigrationUserContextType>({
    state: INITIAL_STATE,
    dispatch: () => null,
    methods: {
        changeStep: () => null
    }
});

const actions = new Map<MigrationUserContextActions, any>([
    ["TOGGLE_LOADER", "isLoading"],
    ["SET_CREDENTIALS", "credentials"],
    ["SET_STEP_COMPLETED", "completeds"],
    ["SET_CURRENT_STEP", "currentWizardStep"],
    ["SET_PAYLOAD", "payload"]
]);

const MigrationUserProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer((state: MigrationUserContextState, stateAction: MigrationUserContextReducerAction) => {
        const action = actions.get(stateAction.type);

        if (!action) {
            throw new Error();
        }

        const newState = {
            ...state,
            [action]: stateAction.payload
        };

        return newState;
    }, INITIAL_STATE);

    const changeStep = useCallback(() => {
        const currentStepIndex = findIndex(state.steps, { slug: state.currentWizardStep.slug });

        const hasStepInCompletedArray = state.completeds.includes(state.currentWizardStep.slug);
        const completedPayload = hasStepInCompletedArray ? state.completeds : [...state.completeds, state.currentWizardStep.slug];

        dispatch({ type: "SET_STEP_COMPLETED", payload: completedPayload });
        dispatch({ type: "SET_CURRENT_STEP", payload: state.steps[currentStepIndex + 1] });
    }, [state.completeds, state.currentWizardStep, state.steps]);

    const values = {
        state,
        dispatch,
        methods: {
            changeStep
        }
    };

    return <MigrationUserContext.Provider value={values}>{children}</MigrationUserContext.Provider>;
};

export { MigrationUserContext, MigrationUserProvider };
