import React from "react";

import Skeleton from "components/Skeleton";

import * as S from "./styles";

const EssayCardPlaceholder = () => (
    <S.EssayCardContainer to="" visible="true">
        <Skeleton width="4px" height="24px" style={{ marginRight: 8 }} />
        <S.EssayCardWrapper>
            <S.EssayCardHeader>
                <S.EssayCardTitle>
                    <Skeleton width="90%" height="12px" style={{ marginBottom: 12 }} />
                    <Skeleton width="70%" height="12px" />
                </S.EssayCardTitle>
                <Skeleton width="4px" height="8px" style={{ borderRadius: 5 }} />
            </S.EssayCardHeader>
            <S.EssayCardFooter style={{ display: "flex" }}>
                <S.EssayCardFooterText style={{ display: "flex" }}>
                    <Skeleton width="80px" height="12px" />
                </S.EssayCardFooterText>
                <S.EssayCardFooterText style={{ display: "flex" }}>
                    <Skeleton width="80px" height="12px" />
                </S.EssayCardFooterText>
                <S.EssayCardFooterText style={{ display: "flex" }}>
                    <Skeleton width="14px" height="12px" />
                </S.EssayCardFooterText>
            </S.EssayCardFooter>
        </S.EssayCardWrapper>
    </S.EssayCardContainer>
);

export default EssayCardPlaceholder;
