import Spinner from "components/Spinner";
import React from "react";
import { PageLoaderContainer, PageLoaderText } from "./styles";

export interface IPageLoaderProps {
    position?: string;
    text?: string;
}

const PageLoader = ({ position = "fixed", text }: IPageLoaderProps) => {
    return (
        <PageLoaderContainer position={position}>
            <Spinner />
            {!!text && <PageLoaderText>{text}</PageLoaderText>}
        </PageLoaderContainer>
    );
};

export default PageLoader;
