import styled from "styled-components";
import { theme } from "config/theme";
import { Grid } from "react-styled-flexboxgrid";

export const PreviousExamDetailsHeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};
    padding-top: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.medium};
    background: ${theme.colors.white};

    h1 {
        color: ${theme.colors.brand[300]};
        font-size: ${theme.typography.sizes.mediumXX};
        font-weight: bold;
    }

    ${Grid} {
        margin: 0;
    }
`;

export const PreviousExamDetailsHeaderBackButton = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    min-width: 8px;
    margin-bottom: ${theme.spacing.small};
    color: ${theme.colors.base[400]};
    font-size: 14px;

    svg {
        color: ${theme.colors.base[300]};
        margin-left: ${theme.spacing.smallX};
    }
`;
