import { ReduxAction } from "store";
import { ActionCreatorsMapObject } from "redux";
import IPagination from "interfaces/IPagination";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { createActions, createReducer } from "reduxsauce";
import {
    ForumActionsType,
    IComplaint,
    IForumComment,
    IForumItem,
    IForumQuestion,
    IForumQuestionAnswer,
    IForumState,
    IForumUpload,
    UploadType
} from "store/interfaces/IForum";

export const { Creators, Types } = createActions<Record<ForumActionsType, ForumActionsType>, ActionCreatorsMapObject>({
    getAllQuestionsRequest: ["payload"],
    getAllQuestionsSuccess: ["payload"],
    getAllQuestionsFailure: ["payload"],

    getForumSubjectsRequest: [],
    getForumSubjectsSuccess: ["payload"],
    getForumSubjectsFailure: [],

    createOrUpdateQuestionRequest: ["payload"],
    createOrUpdateQuestionSuccess: ["payload"],
    createOrUpdateQuestionFailure: [],

    getQuestionRequest: ["payload"],
    getQuestionSuccess: ["payload"],
    getQuestionFailure: [],

    likeRequest: ["payload"],
    likeSuccess: ["payload"],
    likeFailure: [],

    uploadAttachmentsRequest: ["payload"],
    uploadAttachmentsSuccess: ["payload"],
    uploadAttachmentsFailure: [],

    retryUploadAttachmentsRequest: ["payload"],
    retryUploadAttachmentsSuccess: ["payload"],
    retryUploadAttachmentsFailure: ["payload"],

    createOrUpdateAnswerRequest: ["payload"],
    createOrUpdateAnswerSuccess: ["payload"],
    createOrUpdateAnswerFailure: [],

    createOrUpdateCommentRequest: ["payload"],
    createOrUpdateCommentSuccess: ["payload"],
    createOrUpdateCommentFailure: [],

    createComplaintRequest: ["payload"],
    createComplaintSuccess: ["payload"],
    createComplaintFailure: [],

    deleteAttachment: ["payload"],

    deleteQuestionRequest: ["payload"],
    deleteQuestionSuccess: [],
    deleteQuestionFailure: [],

    deleteCommentRequest: ["payload"],
    deleteCommentSuccess: ["payload"],
    deleteCommentFailure: [],

    deleteAnswerRequest: ["payload"],
    deleteAnswerSuccess: ["payload"],
    deleteAnswerFailure: [],

    clearForum: [],
    clearForumCreated: [],
    clearForumQuestion: []
});

export const INITIAL_STATE: IForumState = {
    isAnswerCreated: false,
    isCommentCreated: false,
    isQuestionCreated: false,
    isLoadingForum: false,
    isLoadingAnswer: false,
    isLoadingComment: false,
    isLoadingQuestion: false,
    isLoadingUpload: false,
    isLoadingForumSubjects: false,
    isResetedRequest: false,
    questions: {
        items: [],
        page: 1,
        totalItems: 0,
        count: 0,
        totalPages: 0
    },
    question: {
        _id: "",
        answers: [],
        attachments: [],
        content: "",
        created: "",
        answersTotal: 0,
        attachmentsTotal: 0,
        likesTotal: 0,
        subject: {
            _id: "",
            id: "",
            idExternal: 0,
            name: "",
            color: ""
        },
        subjectFront: {
            _id: "",
            id: "",
            idExternal: 0,
            name: "",
            color: ""
        },
        subjectModule: {
            _id: "",
            id: "",
            idExternal: 0,
            name: "",
            color: ""
        },
        tags: [],
        updated: "",
        user: {
            _id: "",
            id: "",
            name: "",
            username: ""
        },
        isLiked: false
    },
    filters: [],
    uploads: {
        answer: [],
        question: [],
        comment: []
    }
};

const actionForumRequest = (state = INITIAL_STATE): IForumState => ({ ...state, isLoadingForum: true });
const actionForumFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isLoadingForum: false });

const actionForumSubjectRequest = (state = INITIAL_STATE): IForumState => ({ ...state, isLoadingForumSubjects: true });
const actionForumSubjectFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isLoadingForumSubjects: false });

const actionAnswerRequest = (state = INITIAL_STATE): IForumState => ({ ...state, isAnswerCreated: false, isLoadingAnswer: true });
const actionAnswerFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isAnswerCreated: false, isLoadingAnswer: false });

const actionCommentRequest = (state = INITIAL_STATE): IForumState => ({ ...state, isCommentCreated: false, isLoadingComment: true });
const actionCommentFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isCommentCreated: false, isLoadingComment: false });

const deleteRequest = (state = INITIAL_STATE): IForumState => ({ ...state });

const actionLikeRequestOrFailure = (state = INITIAL_STATE): IForumState => ({ ...state });

const actionQuestionRequest = (state = INITIAL_STATE): IForumState => ({ ...state, isQuestionCreated: false, isLoadingQuestion: true });
const actionQuestionFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isQuestionCreated: false, isLoadingQuestion: false });

const getQuestionSuccess = (state = INITIAL_STATE, action: ReduxAction<IForumQuestion>): IForumState => ({
    ...state,
    question: {
        ...action.payload,
        hasError: false
    },
    isLoadingForum: false
});

const getQuestionFailure = (state = INITIAL_STATE, action: ReduxAction<any>): IForumState => ({
    ...state,
    question: {
        ...state.question,
        hasError: true
    },
    isLoadingForum: false
});

const getAllQuestionsRequest = (state = INITIAL_STATE, action: ReduxAction<{ isResetedRequest: boolean }>): IForumState => ({
    ...state,
    isLoadingForum: true,
    isResetedRequest: action.payload.isResetedRequest
});

const getAllQuestionsFailure = (state = INITIAL_STATE): IForumState => ({ ...state, isLoadingForum: false, isResetedRequest: false });

const getAllQuestionsSuccess = (
    state = INITIAL_STATE,
    action: ReduxAction<{ data: IPagination<IForumItem>; isResetedRequest: boolean }>
): IForumState => {
    const { data, isResetedRequest } = action.payload;

    return {
        ...state,
        questions: {
            ...data,
            items: isResetedRequest ? data.items : [...state.questions.items, ...data.items]
        },
        isResetedRequest: false,
        isLoadingForum: false
    };
};

const getForumSubjectsSuccess = (state = INITIAL_STATE, action: ReduxAction<IFilterGroup[]>): IForumState => ({
    ...state,
    filters: action.payload,
    isLoadingForumSubjects: false
});

const uploadAttachmentsRequest = (state = INITIAL_STATE, action: ReduxAction<{ key: UploadType; attachments: IForumUpload[] }>): IForumState => ({
    ...state,
    uploads: {
        ...state.uploads,
        [action.payload.key]: action.payload.attachments.map((item, index) => {
            const attachment = state.uploads[action.payload.key].find((upload) => upload.id === index + 1);

            if (attachment) {
                return attachment;
            }

            if (item.id) {
                return {
                    id: item.id,
                    file: {
                        name: (item as any).name,
                        path: (item as any).path
                    },
                    status: "success"
                };
            }

            return {
                id: index + 1,
                status: "loading",
                file: item,
                url: ""
            };
        })
    },
    isLoadingUpload: true
});

const uploadAttachmentsSuccess = (state = INITIAL_STATE, action: ReduxAction<{ key: UploadType; attachments: string[] }>): IForumState => ({
    ...state,
    uploads: {
        ...state.uploads,
        [action.payload.key]: action.payload.attachments
    },
    isLoadingUpload: false
});

const retryUploadAttachmentsRequest = (state = INITIAL_STATE, action: ReduxAction<{ key: UploadType; attachment: IForumUpload }>): IForumState => {
    const { attachment, key } = action.payload;
    return {
        ...state,
        uploads: {
            ...state.uploads,
            [key]: state.uploads[key].map((item) => ({
                ...item,
                status: item.id === attachment.id ? "loading" : item.status
            }))
        },
        isLoadingUpload: true
    };
};

const retryUploadAttachmentsSuccessOrFailure = (
    state = INITIAL_STATE,
    action: ReduxAction<{ key: UploadType; attachments: IForumUpload[] }>
): IForumState => ({
    ...state,
    uploads: {
        ...state.uploads,
        [action.payload.key]: action.payload.attachments
    },
    isLoadingUpload: false
});

const deleteAttachment = (state = INITIAL_STATE, action: ReduxAction<{ id: number; key: UploadType }>): IForumState => {
    const { id, key } = action.payload;

    return {
        ...state,
        uploads: {
            ...state.uploads,
            [key]: state.uploads[key].filter((item) => item.id !== id)
        }
    };
};

const likeSuccess = (state = INITIAL_STATE, action: ReduxAction<{ value: boolean; id?: string }>): IForumState => {
    const { id, value } = action.payload;

    return {
        ...state,
        question: {
            ...state.question,
            ...(!id && { isLiked: value, likesTotal: value ? state.question.likesTotal + 1 : state.question.likesTotal - 1 }),
            answers: (state.question.answers || []).map((answer) => ({
                ...answer,
                ...(answer._id === id && { isLiked: value, likesTotal: value ? answer.likesTotal + 1 : answer.likesTotal - 1 }),
                comments: (answer.comments || []).map((comment) => ({
                    ...comment,
                    ...(comment._id === id && { isLiked: value, likesTotal: value ? comment.likesTotal + 1 : comment.likesTotal - 1 })
                }))
            }))
        }
    };
};

const createComplaintSuccess = (state = INITIAL_STATE, action: ReduxAction<IComplaint>): IForumState => {
    const { answer, comment, question } = action.payload;
    const id = answer || comment || question;

    return {
        ...state,
        question: {
            ...state.question,
            ...(id === state.question._id && { complaint: action.payload }),
            answers: (state.question.answers || []).map((answer) => ({
                ...answer,
                ...(answer._id === id && { complaint: action.payload }),
                comments: (answer.comments || []).map((comment) => ({
                    ...comment,
                    ...(comment._id === id && { complaint: action.payload })
                }))
            }))
        }
    };
};

const createOrUpdateQuestionSuccess = (state = INITIAL_STATE, action: ReduxAction<IForumQuestion>): IForumState => ({
    ...state,
    question: action.payload,
    uploads: INITIAL_STATE.uploads,
    isLoadingQuestion: false,
    isQuestionCreated: true
});

const createOrUpdateAnswerSuccess = (state = INITIAL_STATE, action: ReduxAction<{ data: IForumQuestionAnswer; id?: string }>): IForumState => {
    const answers = (state.question.answers || []).map((item) => {
        if (item._id === action.payload.data._id) {
            return action.payload.data;
        }

        return item;
    });

    return {
        ...state,
        question: {
            ...state.question,
            answersTotal: state.question.answersTotal + 1,
            answers: action.payload.id ? answers : [action.payload.data, ...(state.question.answers || [])]
        },
        uploads: {
            ...state.uploads,
            answer: []
        },
        isAnswerCreated: true,
        isLoadingAnswer: false
    };
};

const deleteAnswerSuccess = (state = INITIAL_STATE, action: ReduxAction<{ id: string; idArtefact: string }>): IForumState => {
    const { id } = action.payload;

    return {
        ...state,
        question: {
            ...state.question,
            answers: (state.question.answers || []).filter((answer) => answer._id !== id)
        }
    };
};

const deleteQuestionSuccess = (state = INITIAL_STATE): IForumState => ({ ...state });

const deleteCommentSuccess = (state = INITIAL_STATE, action: ReduxAction<{ id: string; idArtefact: string }>): IForumState => {
    const { id, idArtefact } = action.payload;

    return {
        ...state,
        question: {
            ...state.question,
            answers: (state.question.answers || []).map((answer) => {
                if (answer._id !== idArtefact) {
                    return answer;
                }

                return {
                    ...answer,
                    comments: (answer.comments || []).filter((comment) => comment._id !== id)
                };
            })
        }
    };
};

const createOrUpdateCommentSuccess = (state = INITIAL_STATE, action: ReduxAction<{ data: any; answer: string; id?: string }>): IForumState => {
    const formatComments = (comments?: IForumComment[]) => {
        if (!action.payload.id) {
            return [action.payload.data, ...(comments || [])];
        }

        return (comments || []).map((item) => {
            if (item._id === action.payload.id) {
                return action.payload.data;
            }

            return item;
        });
    };

    return {
        ...state,
        question: {
            ...state.question,
            answersTotal: state.question.answersTotal + 1,
            answers: (state.question.answers || []).map((answer) => ({
                ...answer,
                comments: action.payload.answer !== answer._id ? answer.comments : formatComments(answer.comments)
            }))
        },
        uploads: {
            ...state.uploads,
            comment: []
        },
        isCommentCreated: true,
        isLoadingComment: false
    };
};

const clearForumCreated = (state = INITIAL_STATE, action: ReduxAction<{ key: string }>): IForumState => ({
    ...state,
    [action.payload.key]: false
});

const clearForumQuestion = (state = INITIAL_STATE): IForumState => ({
    ...state,
    question: INITIAL_STATE.question,
    uploads: INITIAL_STATE.uploads
});

const clearForum = (state = INITIAL_STATE): IForumState => ({ ...state, ...INITIAL_STATE });

export default createReducer<IForumState>(INITIAL_STATE, {
    [Types.GET_ALL_QUESTIONS_REQUEST]: getAllQuestionsRequest,
    [Types.GET_ALL_QUESTIONS_SUCCESS]: getAllQuestionsSuccess,
    [Types.GET_ALL_QUESTIONS_FAILURE]: getAllQuestionsFailure,

    [Types.GET_QUESTION_REQUEST]: actionForumRequest,
    [Types.GET_QUESTION_SUCCESS]: getQuestionSuccess,
    [Types.GET_QUESTION_FAILURE]: getQuestionFailure,

    [Types.GET_FORUM_SUBJECTS_REQUEST]: actionForumSubjectRequest,
    [Types.GET_FORUM_SUBJECTS_SUCCESS]: getForumSubjectsSuccess,
    [Types.GET_FORUM_SUBJECTS_FAILURE]: actionForumSubjectFailure,

    [Types.UPLOAD_ATTACHMENTS_REQUEST]: uploadAttachmentsRequest,
    [Types.UPLOAD_ATTACHMENTS_SUCCESS]: uploadAttachmentsSuccess,
    [Types.UPLOAD_ATTACHMENTS_FAILURE]: actionForumFailure,

    [Types.RETRY_UPLOAD_ATTACHMENTS_REQUEST]: retryUploadAttachmentsRequest,
    [Types.RETRY_UPLOAD_ATTACHMENTS_SUCCESS]: retryUploadAttachmentsSuccessOrFailure,
    [Types.RETRY_UPLOAD_ATTACHMENTS_FAILURE]: retryUploadAttachmentsSuccessOrFailure,

    [Types.LIKE_REQUEST]: actionLikeRequestOrFailure,
    [Types.LIKE_SUCCESS]: likeSuccess,
    [Types.LIKE_FAILURE]: actionLikeRequestOrFailure,

    [Types.CREATE_OR_UPDATE_QUESTION_REQUEST]: actionQuestionRequest,
    [Types.CREATE_OR_UPDATE_QUESTION_SUCCESS]: createOrUpdateQuestionSuccess,
    [Types.CREATE_OR_UPDATE_QUESTION_FAILURE]: actionQuestionFailure,

    [Types.CREATE_OR_UPDATE_ANSWER_REQUEST]: actionAnswerRequest,
    [Types.CREATE_OR_UPDATE_ANSWER_SUCCESS]: createOrUpdateAnswerSuccess,
    [Types.CREATE_OR_UPDATE_ANSWER_FAILURE]: actionAnswerFailure,

    [Types.CREATE_OR_UPDATE_COMMENT_REQUEST]: actionCommentRequest,
    [Types.CREATE_OR_UPDATE_COMMENT_SUCCESS]: createOrUpdateCommentSuccess,
    [Types.CREATE_OR_UPDATE_COMMENT_FAILURE]: actionCommentFailure,

    [Types.CREATE_COMPLAINT_REQUEST]: actionCommentRequest,
    [Types.CREATE_COMPLAINT_SUCCESS]: createComplaintSuccess,
    [Types.CREATE_COMPLAINT_FAILURE]: actionCommentFailure,

    [Types.DELETE_ATTACHMENT]: deleteAttachment,

    [Types.DELETE_QUESTION_REQUEST]: deleteRequest,
    [Types.DELETE_QUESTION_SUCCESS]: deleteQuestionSuccess,
    [Types.DELETE_QUESTION_FAILURE]: deleteRequest,

    [Types.DELETE_COMMENT_REQUEST]: deleteRequest,
    [Types.DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
    [Types.DELETE_COMMENT_FAILURE]: deleteRequest,

    [Types.DELETE_ANSWER_REQUEST]: deleteRequest,
    [Types.DELETE_ANSWER_SUCCESS]: deleteAnswerSuccess,
    [Types.DELETE_ANSWER_FAILURE]: deleteRequest,

    [Types.CLEAR_FORUM_CREATED]: clearForumCreated,
    [Types.CLEAR_FORUM_QUESTION]: clearForumQuestion,
    [Types.CLEAR_FORUM]: clearForum
});
