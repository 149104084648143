// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    orientation: "vertical" | "horizontal";
    contentLayout: "space-between" | "flex-start";
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;

    ${({ orientation }) =>
        orientation === "vertical" &&
        css`
            flex-direction: column;
        `}

    ${({ orientation }) =>
        orientation === "horizontal" &&
        css`
            flex-direction: row;
        `}

    ${({ contentLayout }) =>
        contentLayout === "space-between" &&
        css`
            justify-content: space-between;
            height: 100%;
        `}

    ${({ contentLayout }) =>
        contentLayout === "flex-start" &&
        css`
            justify-content: flex-start;
        `}
`;
