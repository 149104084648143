import { call, put, select } from "redux-saga/effects";
import { ILessonPlanModule } from "interfaces/ILessonPlan";
import { DateTime } from "luxon";
import api from "services/api";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as lessonPlanActions } from "store/ducks/lesson-plan";
import history from "services/history";
import { isBefore } from "date-fns";
import { matchPath, match } from "react-router";

const getLastVisitedDateUrl = (state: any) => state.lessonPlan.lastVisitedDateUrl;
const getToken = (state: any) => state.auth.token;
const getCourseSlug = (state: any) => state.course.slug;

const findModule = (modules: any[]) => {
    let currentModule = modules.find((module: any) => module.isCurrent);

    let currentDate = DateTime.local()
        .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })
        .toISO();

    if (!currentModule) {
        const today = new Date();

        const currentModuleAfterStarted = (modules as any[])
            .filter((module) => isBefore(new Date(module.endDate), today))
            .sort((moduleA: any, moduleB: any) => new Date(moduleB.endDate).valueOf() - new Date(moduleA.endDate).valueOf())
            .find((module: any) => module !== undefined);

        if (currentModuleAfterStarted) {
            currentModule = currentModuleAfterStarted;
            currentDate = currentModuleAfterStarted.endDate;
        }
    }

    if (!currentModule) {
        const currentModuleBeforeStarted = modules[0];

        currentModule = currentModuleBeforeStarted;
        currentDate = currentModuleBeforeStarted.startDate;
    }

    return {
        module: currentModule,
        date: currentDate
    };
};

export function* getLessonPlan() {
    try {
        const { data } = yield call(api.get, "/student/lessonplan");

        // Set lesson plan on redux
        yield put(lessonPlanActions.setLessonPlan(data));

        // Set lesson plan modules
        yield put(lessonPlanActions.setModuleRequest(data.modules));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanFailure(error?.response?.data));

        const token = yield select(getToken);

        if (!token) {
            return yield put(alertActions.showAlert("Ocorreu um problema ao carregar os planos de estudos.", "danger"));
        }
    }
}

export function* getLessonPlanModule(action: any) {
    try {
        const { data } = yield call(api.get, `/student/lessonplanmodule/${action.payload.id}`);

        yield put(lessonPlanActions.getLessonPlanModuleSuccess(data, action.options));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanModuleFailure(error?.response?.data));
    }
}

export function* getLessonPlanEvent(action: any) {
    try {
        const { data } = yield call(api.get, `/lessonplanevent/${action.payload.id}`, {
            headers: {
                "X-Relations": "module;teachers;bookletModules;bookletModules.content;video;teachers.person"
            }
        });

        yield put(lessonPlanActions.getLessonPlanEventSuccess(data));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanEventFailure(error?.response?.data));
        yield put(alertActions.showAlert("Não foi possível carregar o evento desejado. Tente novamente.", "danger"));
    }
}

export function* setModule(action: { type: string; payload: ILessonPlanModule[] }) {
    try {
        const route: match<{ day: string; idModule: string }> | null = matchPath(window.location.pathname, {
            path: "/app/curso/:courseSlug/plano-de-estudos/:idModule/:day",
            exact: true
        });

        const modules = action.payload;

        const { module, date } = findModule(modules);

        let currentModule = module;
        let currentDay = date;

        if (route?.params.day) {
            const storeDate = DateTime.fromFormat(route?.params.day, "dd-LL-yyyy").toISO();

            currentDay = storeDate;
        }

        if (route?.params.idModule) {
            const selectedModule = modules.find((module) => module.id === +route?.params.idModule);

            if (!!selectedModule) {
                currentModule = selectedModule;
            }
        }

        // Set current module
        yield put(lessonPlanActions.setModuleSuccess(currentModule));

        const lastVisitedDateUrl = yield select(getLastVisitedDateUrl);
        const courseSlug = yield select(getCourseSlug);

        // Redirect to last visited lesson plan page
        if (!!lastVisitedDateUrl) {
            const [, ...routePath] = lastVisitedDateUrl.split("/app/");

            const formattedLastVisitedDateUrl = `/app/curso/${courseSlug}/${routePath.join("/")}`;

            const arr = formattedLastVisitedDateUrl.split("/");
            const day = arr[arr.length - 1];
            const newCurrentDay = DateTime.fromFormat(day, "dd-LL-yyyy").toISO();

            // Set current day
            yield put(lessonPlanActions.setLessonPlanDay(newCurrentDay));

            if (window.location.pathname.includes(formattedLastVisitedDateUrl)) {
                return yield call(history.push, { pathname: formattedLastVisitedDateUrl });
            }
        }

        // Set current day
        yield put(lessonPlanActions.setLessonPlanDay(currentDay));

        const formatCurrentDay = DateTime.fromISO(currentDay).toFormat("dd-LL-yyyy");

        if (window.location.pathname.includes("/plano-de-estudos")) {
            yield call(history.push, { pathname: `/app/curso/${courseSlug}/plano-de-estudos/${currentModule.id}/${formatCurrentDay}` });
        }
    } catch (error) {
        console.log(error);
    }
}

export function* getLessonPlanEventMeetingRoomRequest(action: any) {
    try {
        const { data } = yield call(api.get, `/lessonplanevent/${action.payload.eventId}/meeting`);

        yield put(lessonPlanActions.getLessonPlanEventMeetingRoomSuccess({ link: data.link }));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanEventMeetingRoomFailure(error?.response?.data));

        yield put(alertActions.showAlert(error?.response?.data?.detail || "Reunião indisponível no momento.", "warning"));
    }
}

export function* getLessonPlanServerDateRequest() {
    try {
        const { data } = yield call(api.get, "/util/unixtime");

        yield put(lessonPlanActions.getLessonPlanServerDateSuccess({ serverDate: data }));
    } catch (error) {
        yield put(lessonPlanActions.getLessonPlanServerDateFailure(error?.response?.data));

        yield put(alertActions.showAlert(error?.response?.data?.detail || "Serviço indisponível no momento. Tente novamente.", "warning"));
    }
}
