import React from "react";

// Dependencies
import ProdigioTheme from "prodigio-themes";

// Assets
import logo from "static/geracao2000/logo.svg";
import logoSmall from "static/geracao2000/logoSmall.svg";
import logoWhite from "static/geracao2000/logoWhite.svg";

// Navigation Icons
import SimuladoIcon from "prodigio-icons/web/outlined/Simulado";
import ProjectIcon from "prodigio-icons/web/outlined/Project";
import PreviousExamsIcon from "prodigio-icons/web/outlined/ProvasAnteriores";
import StudyPlanIcon from "prodigio-icons/web/outlined/StudyPlan";
import SubjectIcon from "prodigio-icons/web/outlined/Subject";
import FileIcon from "prodigio-icons/web/outlined/Apoio";
import QRCode from "prodigio-icons/web/outlined/QrCode";
import CustomExerciseListIcon from "prodigio-icons/web/outlined/CustomExerciseList";

// Helpers
import { INavigation } from "interfaces/INavigation";
import { generateThemeData } from "helpers/generate-theme-data";

export const prodigioTheme = new ProdigioTheme({
    project: {
        appUrl: "colegiogeracao2000.prodigioeducacao.com",
        brand: "proraiz",
        name: "Geração 2000",
        slug: "proraiz",
        url: "colegiogeracao2000.prodigioeducacao.com",
        logo,
        logoSmall,
        logoWhite
    },
    mainColors: {
        base: "#83899E",
        brand: "#2E3191",
        secondary: "#FFCA05"
    }
});

export const theme = generateThemeData(prodigioTheme, {
    brand: {
        100: "#b0b1d1",
        200: "#8586b6",
        300: "#2e3191",
        400: "#21257c",
        500: "#141a68"
    },
    secondary: {
        100: "#ffe8b6",
        200: "#ffde94",
        300: "#ffca05",
        400: "#e7ad03",
        500: "#d09101"
    }
});

export const menuColors: any = {};

export const navigation: INavigation[] = [
    {
        name: "Plano de estudos",
        url: "/app/plano-de-estudos",
        icon: <StudyPlanIcon width="22" height="22" />
    },
    {
        name: "Matérias",
        url: "/app/materias",
        icon: <SubjectIcon width="22" height="22" />
    },
    {
        name: "Simulados",
        url: "/app/simulados",
        icon: <SimuladoIcon width="22" height="22" />
    },
    {
        name: "Banco de questões",
        url: "/app/banco-de-questoes",
        icon: <CustomExerciseListIcon width="22" height="22" />
    },
    {
        name: "Projetos",
        url: "/app/projetos",
        icon: <ProjectIcon width="22" height="22" />
    },
    {
        name: "Provas anteriores",
        url: "/app/provas-anteriores",
        icon: <PreviousExamsIcon width="22" height="22" />
    },
    {
        name: "Arquivos",
        url: "/app/arquivos",
        icon: <FileIcon width="22" height="22" />
    },
    {
        name: "QRCode",
        url: "/app/qr-code",
        icon: <QRCode width="22" height="22" />
    }
];
