// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Components
import { WebpText } from "components/WebpText";

// Styles
import { Container, OptionCompositionWrapper } from "./styles";

type WebpFilterItemProps = {
    titleText: string;
    optionsComposition?: ReactNode;
};

export const WebpFilterItem: FunctionComponent<WebpFilterItemProps> = ({ titleText, optionsComposition }) => {
    return (
        <Container className="webp-filter-item">
            <WebpText xs="body-text-02" className="filter-item-title" color="neutral" colorWeight={500}>
                <p>{titleText}</p>
            </WebpText>

            <div className="optionsWrapper">
                <OptionCompositionWrapper>{optionsComposition}</OptionCompositionWrapper>
            </div>
        </Container>
    );
};
