// Dependecies
import styled from "styled-components";

export const Container = styled.div`
    display: block;
`;

export const HeaderCompositionWrapper = styled.div`
    margin-bottom: 10px;
`;
