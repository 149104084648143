import React, { memo, useCallback, useMemo } from "react";

// Dependencies
import { Dispatch } from "redux";
import CheckIcon from "prodigio-icons/web/filled/CheckSuccess";

// Components
import SuccessMessage from "./message";
import Accordion from "components/Accordion";
import OrderAddress, { AddressState } from "./order-address";

// Helpers
import { IOrderAddressLess } from "store/ducks/student-area";

// Assets
import * as O from "./styles";

interface IOrderProblemsProps {
    dispatch: Dispatch<any>;
    orders: IOrderAddressLess[];
    ordersFixeds: string[];
    isLoading: boolean;
}

const OrderProblems = ({ dispatch, isLoading, orders, ordersFixeds }: IOrderProblemsProps) => {
    const getCode = useCallback((code: string) => ordersFixeds.find((item) => item === code), [ordersFixeds]);

    const handleSubmit = useCallback(
        (code: string) => (address: AddressState) => dispatch({ type: "CREATE_ORDER_ADDRESS_REQUEST", payload: { code, address } }),
        [dispatch]
    );

    const options = useMemo(() => {
        return orders.map((order) => {
            const isFixed = getCode(order.code);

            return {
                label: {
                    text: `#${order.code} - ${order.details[0].product.name}`,
                    icon: isFixed ? <CheckIcon width="16" height="16" /> : null
                },
                content: isFixed ? <SuccessMessage /> : <OrderAddress onSubmit={handleSubmit(order.code)} isLoading={isLoading} />
            };
        });
        // eslint-disable-next-line
    }, [orders, getCode, handleSubmit, isLoading, ordersFixeds]);

    return (
        <O.OrderProblemsContainer>
            <O.OrderProblemsTitle>Ação necessária</O.OrderProblemsTitle>
            <O.OrderProblemsText>
                Acesse o seu pedido clicando nele! É bem rapidinho: preencha o formulário e clique no botão “Adicionar endereço”
            </O.OrderProblemsText>
            <O.OrderProblemsList>
                <Accordion options={options} />
            </O.OrderProblemsList>
        </O.OrderProblemsContainer>
    );
};

export default memo(OrderProblems);
