// Dependencies
import React, { FunctionComponent, useState, useEffect } from "react";

// Styles
import { Container } from "./styles";

type WebpTotalNumberProps = {
    type?: "hour" | "text";
    colorNumber?: boolean;
    currentNumber: number;
    recommendedNumber: number;
    stringMain: string;
    stringSecondary: string;
};

export const WebpTotalNumber: FunctionComponent<WebpTotalNumberProps> = ({
    colorNumber = false,
    currentNumber,
    recommendedNumber,
    stringMain,
    stringSecondary,
    type = "hour"
}) => {
    const [colorNumberValue, setColorNumberValue] = useState(colorNumber);

    useEffect(() => {
        setColorNumberValue(currentNumber >= recommendedNumber);
    }, [currentNumber, recommendedNumber, setColorNumberValue]);

    return (
        <Container colorNumber={colorNumberValue} className="webp-total-number">
            <p className="textHour">
                {stringMain}
                <b className="setHour">
                    {currentNumber}
                    {type === "hour" && "h"}
                </b>
            </p>

            {stringSecondary && (
                <p className="textMinHour">
                    {stringSecondary} {recommendedNumber}
                    {type === "hour" && "h"}.
                </p>
            )}
        </Container>
    );
};
