import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IEssayComposition } from "store/interfaces/IEssay";
import { Types as EssayTypes } from "store/ducks/essay";
import history from "services/history";

import Button from "components/Button";
import formatDate from "utils/formatDate";
import { useModal } from "hooks/use-modal";
import { EssayStatus } from "enums/EssayStatus";

import * as S from "./styles";
import { IReduxStore } from "interfaces/IReduxStore";
interface IEssayResumeProps {
    composition: IEssayComposition;
}

const EssayResume = ({ composition }: IEssayResumeProps) => {
    const dispatch = useDispatch();
    const modal = useModal();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const creationDate = useMemo(() => formatDate(composition.created, "dd/LL/yyyy"), [composition.created]);

    const handleDiscarded = useCallback(() => {
        modal.open("confirmAction", {
            title: "Atenção",
            text: (
                <>
                    Tem certeza que deseja <strong>Descartar</strong> sua redação?
                </>
            ),
            variant: "outline-warning",
            buttonText: "Confirmar",
            onConfirm: () =>
                dispatch({ type: EssayTypes.UPDATE_ESSAY_STATUS_REQUEST, payload: { id: composition.idComposition, status: EssayStatus.Discarded } })
        });
    }, [composition.idComposition, dispatch, modal]);

    const handleEdit = useCallback(() => {
        modal.open("confirmAction", {
            title: "Atenção",
            text: (
                <>
                    Tem certeza que deseja <strong>Editar</strong> sua redação?
                </>
            ),
            variant: "outline-warning",
            buttonText: "Confirmar",
            onConfirm: () => history.push({ pathname: `/app/curso/${courseSlug}/redacoes/envios/${composition.idComposition}/editar` })
        });
    }, [composition.idComposition, courseSlug, modal]);

    const handleSend = useCallback(() => {
        modal.open("confirmAction", {
            title: "Atenção",
            text: (
                <>
                    Tem certeza que deseja usar <strong>1 crédito</strong> para enviar sua redação?
                </>
            ),
            variant: "outline-warning",
            buttonText: "Confirmar",
            onConfirm: () =>
                dispatch({ type: EssayTypes.UPDATE_ESSAY_STATUS_REQUEST, payload: { id: composition.idComposition, status: EssayStatus.Pending } })
        });
    }, [composition.idComposition, dispatch, modal]);

    return (
        <S.EssayResumeContainer>
            <S.EssayResumeWrapper>
                <S.EssayResumeText>
                    Recebemos sua redação!{" "}
                    <span role="img" aria-label="Emoji feliz">
                        🙂
                    </span>
                </S.EssayResumeText>
                <S.EssayResumeHelper>Enviada em {creationDate}</S.EssayResumeHelper>
            </S.EssayResumeWrapper>
            <S.EssayResumeActions>
                <Button size="small" variant="outline-info" onClick={handleEdit}>
                    Editar
                </Button>
                <Button size="small" variant="outline-danger" onClick={handleDiscarded}>
                    Descartar
                </Button>
                <Button size="small" variant="info" onClick={handleSend}>
                    Enviar para correção
                </Button>
            </S.EssayResumeActions>
        </S.EssayResumeContainer>
    );
};

export default EssayResume;
