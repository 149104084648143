// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    GraphicFooter,
    SubtitleWrapper,
    SubtitlesContainer,
    GraphicWrapperSkeleton,
    SubtitleSkeleton,
    GraphicYValuesWrapperSkeleton,
    GraphicContainerSkeleton,
    GraphicContentSkeleton,
    GraphicXValuesWrapperSkeleton,
    GraphicXValueContentSkeleton
} from "./performance-graphic.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceGraphicSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <GraphicWrapperSkeleton>
                <GraphicYValuesWrapperSkeleton>
                    <Skeleton width="28px" height="19px" borderRadius="4px" />

                    <Skeleton width="28px" height="19px" borderRadius="4px" />

                    <Skeleton width="28px" height="19px" borderRadius="4px" />

                    <Skeleton width="28px" height="19px" borderRadius="4px" />

                    <Skeleton width="28px" height="19px" borderRadius="4px" />
                </GraphicYValuesWrapperSkeleton>

                <GraphicContainerSkeleton>
                    <GraphicContentSkeleton>
                        <Skeleton width="100%" height="196px" borderRadius="8px" />

                        <Skeleton width="100%" height="84px" borderRadius="8px" />

                        <Skeleton width="100%" height="118px" borderRadius="8px" />

                        <Skeleton width="100%" height="62px" borderRadius="8px" />

                        <Skeleton width="100%" height="152px" borderRadius="8px" />

                        <Skeleton width="100%" height="191px" borderRadius="8px" />

                        <Skeleton width="100%" height="230px" borderRadius="8px" />
                    </GraphicContentSkeleton>

                    <GraphicXValuesWrapperSkeleton>
                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>

                        <GraphicXValueContentSkeleton>
                            <Skeleton width="100%" height="19px" borderRadius="4px" />
                        </GraphicXValueContentSkeleton>
                    </GraphicXValuesWrapperSkeleton>
                </GraphicContainerSkeleton>
            </GraphicWrapperSkeleton>

            <GraphicFooter>
                <SubtitlesContainer>
                    <SubtitleWrapper>
                        <Skeleton width="8px" height="8px" borderRadius="50%" />

                        <Spacing direction="horizontal" size={8} />

                        <SubtitleSkeleton>
                            <Skeleton width="80px" height="100%" borderRadius="4px" />
                        </SubtitleSkeleton>
                    </SubtitleWrapper>

                    <SubtitleWrapper>
                        <Skeleton width="8px" height="8px" borderRadius="50%" />

                        <Spacing direction="horizontal" size={8} />

                        <SubtitleSkeleton>
                            <Skeleton width="80px" height="100%" borderRadius="4px" />
                        </SubtitleSkeleton>
                    </SubtitleWrapper>
                </SubtitlesContainer>
            </GraphicFooter>
        </Container>
    );
};
