import { IExternalSchoolClass } from "interfaces/IExternalSchoolClass";
import { createActions, createReducer } from "reduxsauce";
import api from "services/api";
import { CourseActionsType, ICourseActionsCreators } from "store/interfaces/ICourse";

export interface IModule {
    created: string;
    endDate: string;
    id: number;
    isCurrent: boolean;
    name: string;
    order: number;
    startDate: string;
    updated: string;
}

export interface ILessonPlan {
    id: number;
    updated: null | string;
    created: null | string;
    name: number;
    modules: IModule[];
    isWeekly: number;
}

export interface ICourseState {
    id: number;
    updated: null | string;
    created: null | string;
    name: string;
    slug: string;
    lessonPlans: ILessonPlan[];
    isPublished: boolean;
    helptext?: string;
    externalSchoolClasses: IExternalSchoolClass[];
}

export const { Creators, Types } = createActions<Record<CourseActionsType, CourseActionsType>, ICourseActionsCreators>({
    setCourse: ["payload"],
    clearCourse: [],

    getExternalSchoolClassRequest: [],
    getExternalSchoolClassSuccess: ["payload"],
    getExternalSchoolClassFailure: []
});

export const INITIAL_STATE: ICourseState = {
    id: 0,
    updated: null,
    created: null,
    name: "",
    slug: "",
    lessonPlans: [],
    isPublished: true,
    externalSchoolClasses: []
};

const setCourse = (state = INITIAL_STATE, action: any) => {
    api.defaults.headers.common["X-Course"] = action.payload.course.slug;

    return {
        ...state,
        ...action.payload.course
    };
};

const clearCourse = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

const chore = (state = INITIAL_STATE) => state;

const getExternalSchoolClassSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        externalSchoolClasses: action.payload ?? []
    };
};

const getExternalSchoolClassFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        externalSchoolClasses: []
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.SET_COURSE]: setCourse,
    [Types.CLEAR_COURSE]: clearCourse,

    [Types.GET_EXTERNAL_SCHOOL_CLASS_REQUEST]: chore,
    [Types.GET_EXTERNAL_SCHOOL_CLASS_SUCCESS]: getExternalSchoolClassSuccess,
    [Types.GET_EXTERNAL_SCHOOL_CLASS_FAILURE]: getExternalSchoolClassFailure
});
