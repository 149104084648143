interface IProps {
    answers: any[];
    questionId: number;
}

const getCurrentAnswer = ({ answers, questionId }: IProps) => {
    try {
        if (!answers || !questionId) {
            throw new Error();
        }

        const currentAnswer = !!answers && answers.filter((answer) => !!answer.question).find((answer) => answer.question.id === questionId);

        return currentAnswer;
    } catch (error) {
        return undefined;
    }
};

export default getCurrentAnswer;
