import React from "react";

// Dependencies
import { DateTime } from "luxon";
import DateIcon from "prodigio-icons/web/filled/Calendar";

// Components
import Badge from "components/Badge";
import Button from "components/Button";

// Helpers
import { IFile } from "store/interfaces/IFile";

// Assets
import { Col } from "components/Grid";
import { FilesCardContent, FilesCardContainer, FilesCardHeader, FilesCardHeaderDate, FilesCardTitle, FilesCardSubtitle } from "./styles";

interface IProps {
    item: IFile;
    onOpenModal(linkHTML: string, title: string): void;
}

const FilesCard = ({ item, onOpenModal }: IProps) => {
    const { documentCategory, created, material } = item;

    if (!material) {
        return null;
    }

    const { linkHTML, url, title, resume } = material;

    return (
        <Col xs={12} md={6} lg={4}>
            <FilesCardContainer>
                <FilesCardContent>
                    <FilesCardHeader>
                        {!!documentCategory && (
                            <Badge variant="info" backgroundWeight={100}>
                                {documentCategory.name}
                            </Badge>
                        )}

                        {!!created && DateTime.fromISO(created).isValid && (
                            <FilesCardHeaderDate>
                                <DateIcon width={16} height={16} />
                                <span>{DateTime.fromISO(created).toFormat("dd/MM/yyyy")}</span>
                            </FilesCardHeaderDate>
                        )}
                    </FilesCardHeader>
                    {!!material && (
                        <>
                            <FilesCardTitle>{title}</FilesCardTitle>
                            <FilesCardSubtitle>{resume || ""}</FilesCardSubtitle>
                        </>
                    )}
                </FilesCardContent>
                <Button
                    variant="primary"
                    block
                    onClick={() => !!linkHTML && onOpenModal(linkHTML, title)}
                    size="medium"
                    as={!!linkHTML ? "button" : "a"}
                    {...(!!url && { href: url, target: "_blank" })}
                >
                    {!!linkHTML ? "Visualizar" : "Baixar"}
                </Button>
            </FilesCardContainer>
        </Col>
    );
};

export default React.memo(FilesCard);
