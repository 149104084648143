import { theme } from "config/theme";
import styled from "styled-components/macro";
import { PageContainer } from "assets/styles/global";
import { fadeInUp } from "assets/styles/animations";
import { RadioGroupContainer } from "components/RadioGroup/styles";

export const PreviousExamScreenContainer = styled(PageContainer)`
    padding: ${theme.spacing.large} ${theme.spacing.smallX};

    ${RadioGroupContainer} {
        background: ${theme.colors.white};
        box-shadow: ${theme.shadow[1]};
        border-radius: 8px;
        padding: 16px 24px;
    }
`;

export const PreviousExamScreenContent = styled.div`
    animation: ${fadeInUp} 0.25s linear;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 8px;
    padding: ${theme.spacing.small};
`;

export const PreviousExamScreenLoading = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.medium} 0;
`;
