import Card from "components/Card";
import { theme } from "config/theme";
import styled from "styled-components/macro";

export const StudentAreaFormsContainer = styled(Card)`
    padding: ${theme.spacing.small};
`;

export const StudentAreaFormContainer = styled.form`
    width: 100%;
`;

export const StudentInputRow = styled.div`
    margin: ${theme.spacing.small} 0;
`;
