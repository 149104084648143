import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";

// Dependencies
import CheckIcon from "prodigio-icons/web/outlined/Check";
import CloseIcon from "prodigio-icons/web/outlined/Close";
import FilterIcon from "prodigio-icons/web/filled/Filter";
import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";

// Helpers
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { ISelectOption } from "interfaces/ISelectOption";
import useOnClickOutside from "hooks/use-on-click-outside";

// Assets
import {
    FilterButton,
    FilterOldModal,
    FilterDarkBackground,
    FilterModalTitle,
    FilterGroup,
    FilterDropDown,
    FilterDropDownList,
    FilterButtonLegend,
    FilterModalList
} from "./styles";

interface IProps {
    label?: string;
    withIcon?: boolean;
    options: ISelectOption[];
    onChange(option?: ISelectOption): void;
}

const Filter = ({ options = [], withIcon = true, label = "Filtrar", onChange }: IProps) => {
    const [activeModal, setActiveModal] = useState(false);
    const [activeDropDown, setActiveDropDown] = useState(false);
    const [selectedOption, setSelectedOption] = useState<ISelectOption | undefined>();

    const modalRef = useRef<HTMLDivElement>(null);
    const dropDownRef = useRef<HTMLDivElement>(null);
    const isMobile = useIsMobile(BreakPoints.small);

    useOnClickOutside(modalRef, () => activeModal && setActiveModal(false));
    useOnClickOutside(dropDownRef, () => activeDropDown && setActiveDropDown(false));

    useEffect(() => {
        if (!isMobile) {
            setActiveModal(false);
        }
    }, [isMobile]);

    const handleClickButton = useCallback(() => {
        if (isMobile) {
            return setActiveModal(!activeModal);
        }

        setActiveDropDown(!activeDropDown);
    }, [isMobile, activeDropDown, activeModal]);

    const handleClickSelectOption = useCallback(
        (option: ISelectOption) => () => {
            if (!option || option?.value === null || option?.value === undefined) {
                return;
            }

            if (selectedOption?.value === option.value) {
                setSelectedOption(undefined);
                onChange(undefined);

                return;
            }

            if (isMobile) {
                setActiveModal(false);
            } else {
                setActiveDropDown(false);
            }

            setSelectedOption(option);

            if (option.value === undefined) {
                setSelectedOption(undefined);
            }

            onChange(option);
        },
        [selectedOption, isMobile, onChange]
    );

    const handleCloseModal = useCallback(() => setActiveModal(false), []);

    const listOptions = useMemo(() => {
        try {
            return options.map((option, index) => {
                return (
                    <FilterButton isList={true} key={`option_${index}`} onClick={handleClickSelectOption(option)}>
                        <span>{option.label}</span>
                        {selectedOption?.value === option.value && <CheckIcon width={15} height={10} />}
                    </FilterButton>
                );
            });
        } catch (error) {
            return null;
        }
    }, [options, selectedOption, handleClickSelectOption]);

    return (
        <>
            <FilterDropDown ref={dropDownRef}>
                <FilterButton onClick={handleClickButton}>
                    {withIcon && <FilterIcon className="filterIcon" width={24} height={24} />}
                    <FilterButtonLegend>
                        <span>{!!selectedOption?.label ? selectedOption?.label : label}</span>
                    </FilterButtonLegend>
                    <ChevronDownIcon widh={8} height={4} style={{ ...(activeDropDown && { transform: "rotate(180deg)" }) }} />
                </FilterButton>
                {activeDropDown && <FilterDropDownList>{listOptions}</FilterDropDownList>}
            </FilterDropDown>

            {activeModal && (
                <FilterDarkBackground id="FilterDarkBackground">
                    <FilterOldModal ref={modalRef}>
                        <FilterGroup>
                            <FilterModalTitle>{label} por</FilterModalTitle>
                            <FilterButton onClick={handleCloseModal}>
                                <CloseIcon width={16} height={16} />
                            </FilterButton>
                        </FilterGroup>
                        <FilterModalList>{listOptions}</FilterModalList>
                    </FilterOldModal>
                </FilterDarkBackground>
            )}
        </>
    );
};

export default Filter;
