// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    width: 100%;
    position: relative;

    --webp-search-input-radius: 4px;

    #search-icon {
        position: absolute;
        align-items: center;
        right: 10.62px;
        top: 10px;
    }

    .search-input {
        display: block;
        width: 100%;
        position: relative;

        outline: 0;
        --webp-input-border-radius: 4px;
        background-color: ${theme.colors.base[100]};

        letter-spacing: 0;
        line-height: 1.428;
        font-weight: 400;
        font-style: normal;
        font-size: 0.875rem;
        font-family: "Nunito", sans-serif;
    }

    @media (min-width: 768px) and (max-width: 1365px) {
        width: 70%;
    }
`;
