import { MediaQueries, MediaQueriesManual } from "assets/styles/settings";
import { TooltipContainer } from "components/Tooltip/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export const HeaderContainer = styled.header<{ isMenuExpanded: boolean }>`
    align-items: center;
    background: ${theme.colors.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    height: 50px;
    justify-content: space-between;
    left: 0;
    padding: 0px ${theme.spacing.small};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 60px;
        padding: 0px 20px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        transition: all 0.5s;

        ${({ isMenuExpanded }) => {
            if (isMenuExpanded) {
                return css`
                    left: unset;
                    right: 0;
                    width: calc(100% - 280px);
                `;
            }

            return css`
                left: unset;
                right: 0;
                width: calc(100% - 70px);
            `;
        }}
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        padding: 0px 50px;
    }
`;

export const ActionButtonHeader = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        width: 16px;
        height: 16px;
        color: ${theme.colors.base[400]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        svg {
            width: 20px;
            height: 20px;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    ${TooltipContainer} {
        display: none;
    }

    &:hover {
        ${MediaQueries.BIGGER_THAN_SMALL} {
            ${TooltipContainer} {
                display: unset;
                top: calc(100% + ${theme.spacing.small});
                left: calc(50% - ${theme.spacing.smallXX});
                color: ${theme.colors.white};
                background-color: ${theme.colors.system.info[100]};
            }
        }
    }
`;

export const LeftContentHeader = styled.div`
    display: flex;
    align-items: center;

    ${ActionButtonHeader} {
        margin-right: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${ActionButtonHeader} {
            margin-right: ${theme.spacing.medium};
        }
    }
`;

export const RightContentHeader = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    > * {
        &:not(:last-child) {
            margin-right: ${theme.spacing.medium};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        > * {
            &:not(:last-child) {
                margin-right: ${theme.spacing.small};
            }
        }
    }
`;

export const ModalNotification = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background-color: ${theme.colors.white};
    position: absolute;
    top: 0;
    left: 0;

    ${MediaQueriesManual(768)} {
        width: fit-content;
        width: -moz-fit-content;
        top: 50px;
        left: auto;
        right: 30px;
        z-index: 3;
    }
`;

export const BGModalNotification = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;

    ${MediaQueriesManual(768)} {
        top: 50px;
    }
`;
