import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import Accordion from "components/Accordion";
import dictionary from "helpers/dictionary";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";

import GabaritoIcon from "prodigio-icons/web/filled/Gabarito";
import LiveClassIcon from "prodigio-icons/web/filled/LiveClass";
import MonitoriaIcon from "prodigio-icons/web/filled/Monitoria";
import VideoIcon from "prodigio-icons/web/filled/Video";
import ExerciseListIcon from "prodigio-icons/web/filled/ExerciseList";
import DownloadEbookIcon from "prodigio-icons/web/filled/DownloadEbook";
import ComplementIcon from "prodigio-icons/web/filled/Complement";
import ShareIcon from "prodigio-icons/web/filled/Share";
import { theme } from "config/theme";
import Donuts from "../Donuts";
import history from "services/history";

import * as S from "./styles";
import { Creators as bookletModuleActions } from "store/ducks/booklet-module";
import { useDispatch, useSelector } from "react-redux";
import { IBookletContentPerType, IBookletContentPerTypeItem } from "store/interfaces/IBookletModule";
import SubjectScreenDetailsContentPerTypeItem from "./item";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    contentPerType?: IBookletContentPerType[];
}

const SubjectScreenDetailsContentPerType = memo(({ contentPerType = [] }: IProps) => {
    const dispatch = useDispatch();
    const { idWeek, idModule, slug, moduleId } = useParams();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const getIconByContentType = useCallback((type: string) => {
        const icons: any = {
            material: <ComplementIcon width="16px" />,
            apostila: <DownloadEbookIcon width="16px" />,
            exerciselist: <ExerciseListIcon width="16px" />,
            video: <VideoIcon width="16px" />,
            aovivo: <LiveClassIcon width="16px" />,
            monitoria: <MonitoriaIcon width="16px" />,
            extra: <LiveClassIcon width="16px" />,
            links: <ShareIcon width="16px" />
        };

        return icons[type] || null;
    }, []);

    const handleClickContentItem = useCallback(
        (content: IBookletContentPerTypeItem) => {
            if (!content) {
                return;
            }

            if (!!content.video && !!idWeek) {
                return history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${idWeek}/${idModule}/video/${content.video.id}` });
            }

            if (!!content.exerciseList && !!idWeek) {
                return history.push({
                    pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${idWeek}/${idModule}/lista-de-exercicios/${content.exerciseList.id}`
                });
            }

            if (!!content.isLive && !!content.event && !!idWeek) {
                return history.push({
                    pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${idWeek}/${idModule}/ao-vivo/${content.event.video?.id}`
                });
            }

            if (content.material && !!idWeek) {
                window.history.replaceState(
                    null,
                    "",
                    `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${idWeek}/${idModule}/material/${content.material?.id}`
                );
            }

            if (content.material && !idWeek && !!moduleId) {
                window.history.replaceState(null, "", `/app/curso/${courseSlug}/materias/${slug}/${moduleId}/material/${content.material?.id}`);
            }

            dispatch(bookletModuleActions.clickModuleContent(content));
        },
        [courseSlug, dispatch, idModule, idWeek, moduleId, slug]
    );

    const formatContent = useMemo(() => {
        if (!contentPerType || !Array.isArray(contentPerType)) {
            return [];
        }

        const availableTypes = Object.keys(dictionary.SubjectContentPerTypeTitle);

        const filterContent = contentPerType.filter((content) => availableTypes.some((type) => type === removeSpecialCharacters(content.type)));

        return filterContent.map((content) => {
            let labelText = dictionary.SubjectContentPerTypeTitle[removeSpecialCharacters(content.type)];

            if (content.type === "Material") {
                const areTheyAllDownloadableFiles = content.items.every((item) => item.material?.fileName !== null);

                if (!areTheyAllDownloadableFiles) {
                    labelText = "Material complementar";
                }
            }

            const isLive = content.type === "Ao Vivo";

            return {
                label: {
                    text: labelText,
                    icon: (
                        <S.SubjectScreenDetailsContentPerTypeIconSpace>
                            {(content?.progress?.percent || 0) >= 100 ? (
                                <GabaritoIcon width="16px" />
                            ) : (
                                <Donuts
                                    lineColor={theme.colors.system.info[200]}
                                    ringColor={theme.colors.base[200]}
                                    value={content?.progress?.percent || 0}
                                    size="16px"
                                />
                            )}

                            {getIconByContentType(removeSpecialCharacters(content.type))}
                        </S.SubjectScreenDetailsContentPerTypeIconSpace>
                    )
                },
                content: (
                    <S.SubjectScreenDetailsContentPerTypeContent>
                        {content?.items?.map((item) => (
                            <SubjectScreenDetailsContentPerTypeItem key={item.id} onClick={handleClickContentItem} item={{ ...item, isLive }} />
                        ))}
                    </S.SubjectScreenDetailsContentPerTypeContent>
                )
            };
        });
    }, [contentPerType, getIconByContentType, handleClickContentItem]);

    return (
        <S.SubjectScreenDetailsContentPerTypeContainer>
            <Accordion options={formatContent} activeItem={formatContent?.length === 1 ? 0 : null} />
        </S.SubjectScreenDetailsContentPerTypeContainer>
    );
});

export default SubjectScreenDetailsContentPerType;
