// Dependencies
import styled, { css } from "styled-components";

export const Label = styled.p`
    display: none;

    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #3e68d2;
`;

type ContainerStyleProps = {
    isFilled: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    position: relative;

    > .flatpickr-input {
        width: 100%;
        padding: 10px 12px;
        border: 1px solid;
        border-radius: 4px;
        background-color: #fff;
        border-color: ${({ isFilled }) => (isFilled ? "#3e68d2" : "#7d8396")};
        font-size: 14px;

        display: flex;
        align-items: center;
        gap: 8px;
    }

    ${({ isFilled }) =>
        isFilled &&
        css`
            ${Label} {
                display: block;

                position: absolute;
                left: 12px;
                top: -14px;

                background-color: #fff;
            }
        `}
`;

export const UnderSelectMessageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UnderSelectMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const UnderSelectMessage = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e40000;
`;
