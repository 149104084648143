import React, { useCallback, useEffect, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";
import ReplyIcon from "prodigio-icons/web/outlined/Reply";

// Components
import ForumPost from "../../../post";
import Complaint from "../../../complaint";
import ForumQuestionContent from "screens/Forum/components/question-content";

// Helpers
import { Types } from "store/ducks/forum";
import { validExtensions } from "screens/Forum/new";
import sanitizeString from "helpers/sanitize-string";
import { IForumComment, IForumUpload } from "store/interfaces/IForum";

// Assets
import * as F from "./styles";

interface IForumAnswerReplyProps extends IForumComment {
    idAnswer: string;
    isCreated: boolean;
    uploads: IForumUpload[];
    onAttachments: () => void;
    onToggleLike: (isLiked: boolean, id: string) => void;
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
    color?: string;
    userName?: string;
}

const ForumAnswerReply: React.FC<IForumAnswerReplyProps> = (props) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [isForumPostVisible, setIsForumPostVisible] = useState(false);

    const handleShowForumPost = useCallback(() => {
        setIsForumPostVisible(!isForumPostVisible);
        setIsEditing(false);
    }, [isForumPostVisible]);

    useEffect(() => {
        if (props.isCreated) {
            setIsForumPostVisible(false);
            dispatch({ type: Types.CLEAR_FORUM_CREATED, payload: { key: "isCommentCreated" } });
        }
    }, [dispatch, handleShowForumPost, isForumPostVisible, props.isCreated]);

    const handlePostComment = useCallback(
        (content: string) => {
            const hasInvalidFiles = props.uploads.some((item) => {
                const extension = item.file.name.split(".").pop();

                return !validExtensions.includes(extension as string);
            });

            if (hasInvalidFiles) {
                return alert(`Você possui arquivos não suportados na lista de anexos. Extensões suportadas: ${validExtensions.join(", ")}`);
            }

            const attachments = props.uploads.filter((item) => item.status === "success").map((item) => item.attachment || item.id);

            const payload = {
                content: sanitizeString(content, "&nbsp;"),
                answer: props.idAnswer,
                attachments: attachments || [],
                ...(isEditing && { id: props._id })
            };

            setIsEditing(false);
            setIsForumPostVisible(false);

            return dispatch({ type: Types.CREATE_OR_UPDATE_COMMENT_REQUEST, payload });
        },
        [dispatch, isEditing, props._id, props.idAnswer, props.uploads]
    );

    const handleDelete = useCallback(
        (id: string) => dispatch({ type: Types.DELETE_COMMENT_REQUEST, payload: { id, artefact: "comment", idArtefact: props.idAnswer } }),
        [dispatch, props.idAnswer]
    );

    const handleConfirmDelete = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "confirmAction",
                action: {
                    title: "Atenção",
                    text: "Tem certeza que deseja apagar este conteúdo?",
                    variant: "outline-warning",
                    buttonText: "Confirmar",
                    onConfirm: () => handleDelete(id)
                }
            });
        },
        [dispatch, handleDelete]
    );

    const handleToggleLike = useCallback(() => props.onToggleLike(props.isLiked, props._id), [props]);

    const handleUpdateComment = useCallback(
        (id: string) => {
            const attachments = props.attachments?.map((item) => ({
                id: item._id,
                status: "success",
                file: {
                    name: item.originalFilename,
                    path: item.originalFilename
                },
                attachment: item._id
            }));

            dispatch({ type: Types.UPLOAD_ATTACHMENTS_SUCCESS, payload: { key: "comment", attachments: attachments || [] } });

            setIsForumPostVisible(true);
            setIsEditing(true);
        },
        [dispatch, props.attachments]
    );

    const handleComplaintSubmit = useCallback(
        (id: string) => (value: number) => dispatch({ type: Types.CREATE_COMPLAINT_REQUEST, payload: { complaintType: value, comment: id } }),
        [dispatch]
    );

    const handleComplaint = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "complaint",
                action: {
                    onComplaint: handleComplaintSubmit(id)
                }
            });
        },
        [dispatch, handleComplaintSubmit]
    );

    if (props.complaint) {
        return (
            <F.ForumAnswerReplyContainer>
                <ReplyIcon className="ico-reply" width="23" height="23" color={props.color || "#7C8195"} />
                <F.ForumAnswerReplyWrapper color={props.color} withSpacing={true}>
                    <Complaint {...props.complaint!} />
                </F.ForumAnswerReplyWrapper>
            </F.ForumAnswerReplyContainer>
        );
    }

    return (
        <F.ForumAnswerReplyContainer>
            <ReplyIcon className="ico-reply" width="23" height="23" color={props.color || "#7C8195"} />
            <F.ForumAnswerReplyWrapper color={props.color} withSpacing={true}>
                <ForumQuestionContent
                    actions={{
                        onComplaint: handleComplaint,
                        onUpdate: props.userName === props.user?.username ? handleUpdateComment : undefined,
                        onDelete: props.userName === props.user?.username ? handleConfirmDelete : undefined
                    }}
                    color={props.color}
                    attachments={props.attachments}
                    content={props.content}
                    id={props._id}
                    isLiked={props.isLiked}
                    attachmentsTotal={props.attachmentsTotal}
                    likesTotal={props.likesTotal}
                    created={props.created}
                    user={props.user?.name}
                    onShowForumPost={handleShowForumPost}
                    onToggleLike={handleToggleLike}
                />
                {isForumPostVisible && (
                    <F.ForumAnswerReplyContainer>
                        <ReplyIcon className="ico-reply" width="23" height="23" color={props.color || "#7C8195"} />
                        <F.ForumAnswerReplyWrapper withSpacing={false}>
                            <ForumPost
                                content={props.content}
                                isEditing={isEditing}
                                isCreated={false}
                                isCreatedKey="isCommentCreated"
                                isLoading={false}
                                attachments={props.uploads}
                                onAttachments={props.onAttachments}
                                onCancel={handleShowForumPost}
                                onPost={handlePostComment}
                                onRemoveAttachment={props.onRemoveAttachment}
                                onRetryUpload={props.onRetryUpload}
                            />
                        </F.ForumAnswerReplyWrapper>
                    </F.ForumAnswerReplyContainer>
                )}
            </F.ForumAnswerReplyWrapper>
        </F.ForumAnswerReplyContainer>
    );
};

export default ForumAnswerReply;
