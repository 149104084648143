import React, { memo, useCallback, useMemo } from "react";

import { ExamScreenItemContainer, ExamScreenItemTitle, ExamScreenItemDays, ExamScreenItemHeader, ExamScreenItemSeeResolutionIcon } from "./styles";
import ColorTaggedItem from "components/ColorTaggedExerciseList";
import { IExamDayBasicInfos } from "interfaces/IExam";
import { getExamDayStatus } from "helpers/getExamDayStatus";
import Spinner from "components/Spinner";
import Button from "components/Button";
import history from "services/history";
import { theme } from "config/theme";
import _last from "lodash/last";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
interface IProps {
    name: string;
    slug: string;
    examDays: IExamDayBasicInfos[];
    onClick(examDaySlug: string): void;
    loadingResolutionId: boolean;
    resolutionId?: number;
    isAvailable?: boolean;
}

const ExamScreenItem = memo(({ name, examDays, slug, onClick, loadingResolutionId, resolutionId, isAvailable }: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const handleClickSeeResults = useCallback(() => {
        history.push(`/app/curso/${courseSlug}/simulados/${slug}/resultado`);
    }, [courseSlug, slug]);

    const examTitleFormatted = useMemo(() => {
        if (theme.project.slug === "proraiz" && name.includes("|")) {
            const listNames = name.split("|");
            const lastName = _last(listNames);
            return lastName;
        }

        return name;
    }, [name]);

    return (
        <ExamScreenItemContainer data-test-id="exam-item">
            <ExamScreenItemHeader>
                <ExamScreenItemTitle data-test-id="exam-item-title">{examTitleFormatted}</ExamScreenItemTitle>

                {loadingResolutionId && <Spinner size={15} fixed={false} />}

                {resolutionId && (
                    <Button type="button" variant="outline-base" onClick={handleClickSeeResults}>
                        Ver resultados <ExamScreenItemSeeResolutionIcon width={8} />
                    </Button>
                )}
            </ExamScreenItemHeader>

            <ExamScreenItemDays>
                {examDays.length === 0 && <p data-test-id="exam-item-empty">Sem provas para mostrar.</p>}
                {examDays.map((examDay) => {
                    const status = getExamDayStatus({ status: examDay.status, startDate: examDay.startDate, endDate: examDay.endDate, isAvailable });

                    return (
                        <ColorTaggedItem
                            key={examDay.id}
                            listHeading={examDay.name}
                            listDescription={status?.text}
                            listDescriptionColor={status?.color}
                            onClick={() => onClick(examDay.slug)}
                            isExamsPage
                        />
                    );
                })}
            </ExamScreenItemDays>

            {resolutionId && (
                <Button type="button" variant="outline-info" onClick={handleClickSeeResults}>
                    Ver resultados
                </Button>
            )}
        </ExamScreenItemContainer>
    );
});

export default ExamScreenItem;
