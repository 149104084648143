// Dependencies
import styled, { css } from "styled-components";

// Types
import { ScreenWidthRenderData } from "./screen-width-render.types";

export const Container = styled.div<ScreenWidthRenderData>`
    ${({ actionAfterRenderingWidth, renderingWidth }) => css`
        display: ${actionAfterRenderingWidth === "show" ? "none" : "block"};

        @media (min-width: ${renderingWidth}px) {
            display: ${actionAfterRenderingWidth === "show" ? "block" : "none"};
        }
    `}

    ${({ haveFullyAdaptativeWidth }) =>
        !!haveFullyAdaptativeWidth &&
        css`
            width: 100%;
        `}
    
    ${({ haveFullyAdaptativeHeight }) =>
        !!haveFullyAdaptativeHeight &&
        css`
            height: 100%;
        `}
`;
