// Dependencies
import React, { useCallback, useEffect, useState } from "react";

// Styles
import { CarouselContainer, CarouselContent, CarouselSliderDot, CarouselSlider, CaroulselIcon, CarouselSliderWrapper } from "./carousel.styles";

// Types
import { CarouselProps, CarouselSlideDirections } from "./carousel.types";

// Assets
import chevronRight from "assets/img/chevron-right.svg";
import chevronLeft from "assets/img/chevron-left.svg";

export const Carousel: React.FunctionComponent<CarouselProps> = ({ autoSlide = false, slideTimeDuration = 5000, content }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState<CarouselSlideDirections>("right");

    const handlePrevious = () => {
        setSlideDirection("left");
        setCurrentIndex((prevIndex) => (prevIndex - 1 + content.length) % content.length);
    };

    const handleNext = useCallback(() => {
        setSlideDirection("right");
        setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
    }, [content.length]);

    useEffect(() => {
        if (!autoSlide) return;

        const slideInterval = setInterval(() => {
            handleNext();
        }, slideTimeDuration);

        return () => {
            clearInterval(slideInterval);
        };
    }, [autoSlide, handleNext, slideTimeDuration, content.length]);

    return (
        <CarouselContainer>
            <CarouselSliderWrapper>
                <CaroulselIcon src={chevronRight} onClick={handlePrevious} />

                {content.map((item, index) => (
                    <CarouselContent key={index} slideDirection={slideDirection} isActive={index === currentIndex}>
                        {item}
                    </CarouselContent>
                ))}

                <CaroulselIcon src={chevronLeft} onClick={handleNext} />
            </CarouselSliderWrapper>

            <CarouselSlider>
                {content.map((_, index) => (
                    <CarouselSliderDot key={index} isActive={index === currentIndex} onClick={() => setCurrentIndex(index)} />
                ))}
            </CarouselSlider>
        </CarouselContainer>
    );
};

export default Carousel;
