// Dependencies
import React, { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import VideoIcon from "prodigio-icons/web/filled/Video";
import ExerciseListIcon from "prodigio-icons/web/filled/ExerciseList";
import DownloadEbookIcon from "prodigio-icons/web/filled/DownloadEbook";
import ComplementIcon from "prodigio-icons/web/filled/Complement";
import ShareIcon from "prodigio-icons/web/filled/Share";

// Services
import history from "services/history";

// Redux
import { Creators as bookletModuleActions } from "store/ducks/booklet-module";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { ILessonPlanWeeksSectionWeekListItems } from "store/interfaces/ILessonPlanCustom";
import { IReduxStore } from "interfaces/IReduxStore";

// Components
import Accordion from "components/Accordion";
import Donuts from "components/Subject/Donuts";
import SubjectScreenDetailsContentPerTypeItem from "./item";

// Utils
import dictionary from "helpers/dictionary";
import { removeSpecialCharacters } from "utils/removeSpecialCharacters";
import { theme } from "config/theme";

// Styles
import * as S from "./styles";

interface IProps {
    contentPerType?: ILessonPlanWeeksSectionWeekListItems;
}

const SubjectScreenDetailsContentPerType = memo(({ contentPerType }: IProps) => {
    const dispatch = useDispatch();
    const params = useParams();
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const getIconByContentType = useCallback((type: string) => {
        const icons: any = {
            material: <ComplementIcon width="16px" />,
            booklet: <DownloadEbookIcon width="16px" />,
            exerciselist: <ExerciseListIcon width="16px" />,
            video: <VideoIcon width="16px" />,
            link: <ShareIcon width="16px" />
        };

        return icons[type] || null;
    }, []);

    const handleClickContentItem = useCallback(
        (content) => {
            if (!content) {
                return;
            }

            if (content.type === "Video") {
                const path = Object.values(params)
                    .filter(Boolean)
                    .filter(Number)
                    .map((item) => {
                        return `/${item}`;
                    })
                    .join("");

                return history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/p${path}/video/${content.video.id}` });
            }

            const path = Object.values(params)
                .filter(Boolean)
                .filter(Number)
                .map((item) => {
                    return `/${item}`;
                })
                .join("");

            if (content.type === "ExerciseList") {
                return history.push({
                    pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/p${path}/lista-de-exercicios/${content.exerciseList.id}`
                });
            }

            if (content.material) {
                window.history.replaceState(null, "", `/app/curso/${courseSlug}/meu-plano-de-estudos/p${path}/material/${content.material?.id}`);

                Promise.all([
                    dispatch(
                        bookletModuleActions.clickModuleContent({
                            ...content,
                            isDispatchingClickModuleContent: true
                        })
                    ),
                    dispatch(
                        lessonPlanCustomActions.markTextMaterialDoneRequest({
                            loId: content.id,
                            hasRequest: true
                        })
                    )
                ]);

                return;
            }
        },
        [courseSlug, dispatch, params]
    );

    const formatContent = useMemo(() => {
        if (!contentPerType) {
            return [];
        }

        return contentPerType?.contentPerType
            ?.filter((content) => !!content.items.length)
            ?.map((content) => {
                return {
                    label: {
                        text: dictionary.SubjectContentPerTypeTitle[removeSpecialCharacters(content.type)],
                        icon: (
                            <S.SubjectScreenDetailsContentPerTypeIconSpace>
                                <Donuts
                                    lineColor={theme.colors.system.success[200]}
                                    ringColor={theme.colors.base[200]}
                                    value={content?.progress?.percent ?? 0}
                                    size="16px"
                                />

                                {getIconByContentType(removeSpecialCharacters(content.type))}
                            </S.SubjectScreenDetailsContentPerTypeIconSpace>
                        )
                    },
                    content: (
                        <S.SubjectScreenDetailsContentPerTypeContent>
                            {content?.items?.map((item: any, index: number) => {
                                return <SubjectScreenDetailsContentPerTypeItem key={index} onClick={handleClickContentItem} item={item} />;
                            })}
                        </S.SubjectScreenDetailsContentPerTypeContent>
                    )
                };
            });
    }, [contentPerType, getIconByContentType, handleClickContentItem]);

    return (
        <S.SubjectScreenDetailsContentPerTypeContainer>
            <Accordion options={formatContent} activeItem={formatContent?.length === 1 ? 0 : null} />
        </S.SubjectScreenDetailsContentPerTypeContainer>
    );
});

export default SubjectScreenDetailsContentPerType;
