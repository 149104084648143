import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";

export const AttachmentsContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;

    svg {
        display: block;
    }

    strong {
        color: ${theme.colors.base[400]};
        margin-right: ${theme.spacing.smallX};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.smallXX};
    }

    ${MediaQueries.SMALL} {
        flex-wrap: wrap;
        justify-content: flex-start;

        strong {
            margin: 0;
            width: calc(100% - 36px);
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        strong {
            max-width: 200px;
        }
    }
`;

export const AttachmentsIconWrapper = styled.div`
    align-items: center;
    display: flex;
    margin-right: ${theme.spacing.smallX};

    svg {
        &:not(:last-child) {
            margin-right: ${theme.spacing.smallXX};
        }
    }
`;
