// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityTextareaVariants } from "./activity-textarea.types";

type LabelStyleProps = {
    color: string;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: ${({ color }) => color};
`;

type TextareaWrapperStyleProps = {
    isFocused: boolean;
    isFilled: boolean;
    variant: ActivityTextareaVariants;
    isHeightAdaptative?: boolean;
};

export const TextareaWrapper = styled.div<TextareaWrapperStyleProps>`
    position: relative;

    width: 100%;
    padding: 10px 12px;
    border: 1px solid;
    border-radius: 4px;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    gap: 8px;

    ${({ isHeightAdaptative }) =>
        !!isHeightAdaptative &&
        css`
            height: 100%;
        `}

    ${Label} {
        position: absolute;
        left: 12px;
        top: -14px;

        background-color: #fff;

        ${({ variant, isFocused }) =>
            !isFocused &&
            variant === "disabled" &&
            css`
                display: none;
            `}
    }


    ${({ isFocused }) =>
        isFocused &&
        css`
            border-color: #3e68d2;
        `}

    ${({ isFilled, isFocused }) =>
        !isFocused &&
        isFilled &&
        css`
            border-color: #7d8396;
        `}

    ${({ variant, isFocused }) =>
        !isFocused &&
        variant === "error" &&
        css`
            border-color: #e40000;
        `}


    ${({ variant, isFocused }) =>
        !isFocused &&
        variant === "disabled" &&
        css`
            border-color: #ced0dd;
        `}

    ${({ variant, isFocused }) =>
        !isFocused &&
        variant === "default" &&
        css`
            border-color: #83899e;
        `}
`;

export const TextareaErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 4px;

    > img {
        margin-bottom: 2px;
    }
`;

export const ErrorMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ErrorMessageLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #e40000;
`;

type ContainerStyleProps = {
    width?: number;
    isHeightAdaptative?: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;

    ${({ width }) =>
        width &&
        css`
            width: ${width}%;
        `}

    ${({ isHeightAdaptative }) =>
        isHeightAdaptative &&
        css`
            height: 100%;
        `}
`;

type TextareaFieldStyleProps = {
    isHeightAdaptative?: boolean;
};

export const TextareaField = styled.textarea<TextareaFieldStyleProps>`
    width: 100%;
    min-height: 132px;

    background-color: transparent;

    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;

    overflow: hidden;
    resize: none;

    ::placeholder {
        color: #4d4d63;
    }

    ${({ isHeightAdaptative }) =>
        !!isHeightAdaptative &&
        css`
            height: 100%;
        `}
`;
