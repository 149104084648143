// Dependencies
import { createGlobalStyle } from "styled-components";

// Utils
import { theme } from "config/theme";

export const ProappTheme = createGlobalStyle`
    body {
        --theme-color-white: #fff;
        --theme-color-black: #000;
        --default-font-family: "Nunito Sans", sans-serif;
        --title-font-family: "Nunito", sans-serif;
        --theme-color-primary-100-rgba: list-color-rgb(${theme.colors.brand[100]});
        --theme-color-primary-100: ${theme.colors.brand[100]};
        --theme-color-primary-200-rgba: list-color-rgb(${theme.colors.brand[200]});
        --theme-color-primary-200: ${theme.colors.brand[200]};
        --theme-color-primary-300-rgba: list-color-rgb(${theme.colors.brand[300]});
        --theme-color-primary-300: ${theme.colors.brand[300]};
        --theme-color-primary-400-rgba: list-color-rgb(${theme.colors.brand[400]});
        --theme-color-primary-400: ${theme.colors.brand[400]};
        --theme-color-primary-500-rgba: list-color-rgb(${theme.colors.brand[500]});
        --theme-color-primary-500: ${theme.colors.brand[500]};
        --theme-color-secondary-100-rgba: list-color-rgb(${theme.colors.secondary[100]});
        --theme-color-secondary-100: ${theme.colors.secondary[100]};
        --theme-color-secondary-200-rgba: list-color-rgb(${theme.colors.secondary[200]});
        --theme-color-secondary-200: ${theme.colors.secondary[200]};
        --theme-color-secondary-300-rgba: list-color-rgb(${theme.colors.secondary[300]});
        --theme-color-secondary-300: ${theme.colors.secondary[300]};
        --theme-color-secondary-400-rgba: list-color-rgb(${theme.colors.secondary[400]});
        --theme-color-secondary-400: ${theme.colors.secondary[400]};
        --theme-color-secondary-500-rgba: list-color-rgb(${theme.colors.secondary[500]});
        --theme-color-secondary-500: ${theme.colors.secondary[500]};
        --theme-color-warning-100-rgba: list-color-rgb(#FFBE62);
        --theme-color-warning-100: #FFBE62;
        --theme-color-warning-200-rgba: list-color-rgb(#FF9F18);
        --theme-color-warning-200: #FF9F18;
        --theme-color-warning-300-rgba: list-color-rgb(#D17F0C);
        --theme-color-warning-300: #D17F0C;
        --theme-color-warning-400-rgba: list-color-rgb(#9D5F07);
        --theme-color-warning-400: #9D5F07;
        --theme-color-warning-500-rgba: list-color-rgb(#794A07);
        --theme-color-warning-500: #794A07;
        --theme-color-positive-100-rgba: list-color-rgb(#55DC99);
        --theme-color-positive-100: #55DC99;
        --theme-color-positive-200-rgba: list-color-rgb(#1BA660);
        --theme-color-positive-200: #1BA660;
        --theme-color-positive-300-rgba: list-color-rgb(#00874F);
        --theme-color-positive-300: #00874F;
        --theme-color-positive-400-rgba: list-color-rgb(#0F6E42);
        --theme-color-positive-400: #0F6E42;
        --theme-color-positive-500-rgba: list-color-rgb(#045332);
        --theme-color-positive-500: #045332;
        --theme-color-negative-100-rgba: list-color-rgb(#FF8888);
        --theme-color-negative-100: #FF8888;
        --theme-color-negative-200-rgba: list-color-rgb(#F25555);
        --theme-color-negative-200: #F25555;
        --theme-color-negative-300-rgba: list-color-rgb(#E40000);
        --theme-color-negative-300: #E40000;
        --theme-color-negative-400-rgba: list-color-rgb(#B80000);
        --theme-color-negative-400: #B80000;
        --theme-color-negative-500-rgba: list-color-rgb(#840000);
        --theme-color-negative-500: #840000;
        --theme-color-info-100-rgba: list-color-rgb(#A1B6FF);
        --theme-color-info-100: #A1B6FF;
        --theme-color-info-200-rgba: list-color-rgb(#6B8BFB);
        --theme-color-info-200: #6B8BFB;
        --theme-color-info-300-rgba: list-color-rgb(#3E68D2);
        --theme-color-info-300: #3E68D2;
        --theme-color-info-400-rgba: list-color-rgb(#132D71);
        --theme-color-info-400: #132D71;
        --theme-color-info-500-rgba: list-color-rgb(#0B1F53);
        --theme-color-info-500: #0B1F53;
        --theme-color-neutral-100-rgba: list-color-rgb(#F0F1F7);
        --theme-color-neutral-100: #F0F1F7;
        --theme-color-neutral-200-rgba: list-color-rgb(#CED0DD);
        --theme-color-neutral-200: #CED0DD;
        --theme-color-neutral-300-rgba: list-color-rgb(#83899E);
        --theme-color-neutral-300: #83899E;
        --theme-color-neutral-400-rgba: list-color-rgb(#4D4D63);
        --theme-color-neutral-400: #4D4D63;
        --theme-color-neutral-500-rgba: list-color-rgb(#232326);
        --theme-color-neutral-500: #232326;
        --box-shadow-01: 0px 1px 2px rgba(0, 0, 0, 0.1);
        --box-shadow-02: 0px 4px 8px rgba(0, 0, 0, 0.1);
        --box-shadow-03: 0px 6px 12px rgba(0, 0, 0, 0.1);
        --box-shadow-04: 0px 8px 16px rgba(0, 0, 0, 0.1);
        --box-shadow-05: 0px 10px 24px rgba(0, 0, 0, 0.1);
        --space-xx-small: 0.5em;
        --space-x-small: 1em;
        --space-small: 1.25em;
        --space-medium: 2em;
        --space-large: 2.5em;
        --space-x-large: 3.5em;
        --space-xx-large: 5em;
        --space-xxx-large: 7em;
    }
`;
