import React, { useRef } from "react";

// Assets
import { ModalClose, ModalContainer, ModalWrapper } from "./styles";

import CloseIcon from "prodigio-icons/web/outlined/Close";

// Helpers
import { IModal } from "interfaces/IModal";
import { IModalActions } from "store/interfaces/IModal";
import { IResolutionState } from "store/ducks/resolution";
import useOnClickOutside from "hooks/use-on-click-outside";
import { IAnnouncementActions } from "store/interfaces/IActions";

// Modals
import Image from "./Image";
import UploadModal from "./Upload";
import SelectEvent from "./SelectEvent";
import Paywall from "components/Paywall";
import ComplaintModal from "./Complaint";
import BookletModal from "./BookletModal";
import SelectContent from "./SelectContent";
import SelectBooklet from "./SelectBooklet";
import BrowserWarning from "./BrowserWarning";
import SelectMaterial from "./SelectMaterial";
import AnnouncementModal from "./AnnouncementModal";
import useKeyboardKeyDown from "hooks/use-key-down";
import StartExerciseList from "./StartExerciseList";
import ExternalSchoolClass from "./ExternalSchoolClass";
import ConfirmActionModal from "./ConfirmActionModal/index";
import ExportExerciseListPDF from "./ExportExerciseListPDF";
import ResolutionExerciseList from "./ResolutionExerciseList";
import ReportVideoErrorModal from "./ReportVideoError";
import ConfirmEssayImageModal from "./ConfirmEssayImage";
import RaizExamModalMessage from "./RaizExamModalMessage";
import CallToActionModal from "./CallToActionModal";
import ShowIFrame from "./ShowIFrame";
import AttentionModal from "./AttentionModal";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as AnnouncementActions } from "store/ducks/announcement";
import { Creators as ModalActions } from "store/ducks/modal";
import { Creators as ResolutionActions } from "store/ducks/resolution";

interface IProps {
    modal: IModal;
    modalActions: IModalActions;
    announcementActions: IAnnouncementActions;
    resolutions: IResolutionState;
    resolutionActions: {
        resetResolution(): void;
        setResolutionRequest(): void;
    };
}

interface IModals {
    [key: string]: JSX.Element;
}

const NO_CARD_BLACK_LIST = ["startExerciseList"];

const Modal = ({ modal, modalActions, announcementActions }: IProps) => {
    const ref = useRef(null);

    const handleCloseModal = () => {
        if (!modal.isModalVisible) {
            return;
        }

        modalActions.closeModal();
    };

    useOnClickOutside(ref, () => {
        if (!modal.action?.hasCloseButton && modal.element !== "showIFrame") {
            return;
        }

        handleCloseModal();
    });

    useKeyboardKeyDown({
        callBack: () => handleCloseModal()
    });

    const modals: IModals = {
        resolutionExerciseList: <ResolutionExerciseList data={modal.action} onClose={handleCloseModal} modalActions={modalActions} />,
        selectBooklet: <SelectBooklet data={modal.action} modalActions={modalActions} />,
        selectMaterial: <SelectMaterial data={modal.action} />,
        selectContent: <SelectContent data={modal.action} />,
        selectEvent: <SelectEvent data={modal.action} />,
        exportExerciseListPDF: <ExportExerciseListPDF data={modal.action} onClose={modalActions.closeModal} />,
        announcement: <AnnouncementModal data={modal.action} announcementActions={announcementActions} onClose={modalActions.closeModal} />,
        booklet: <BookletModal action={modal.action} />,
        paywall: <Paywall />,
        confirmAction: <ConfirmActionModal action={modal.action} />,
        image: <Image image={modal.action} />,
        startExerciseList: <StartExerciseList {...modal.action} onClose={modalActions.closeModal} />,
        outdatedBrowser: <BrowserWarning browserName={modal.action?.browserName} />,
        externalSchoolClass: <ExternalSchoolClass onClose={modalActions.closeModal} />,
        upload: <UploadModal {...modal.action} />,
        complaint: <ComplaintModal {...modal.action} />,
        reportVideoError: <ReportVideoErrorModal {...modal.action} />,
        confirmEssayImageModal: <ConfirmEssayImageModal {...modal.action} />,
        raizExamModalMessage: <RaizExamModalMessage />,
        callToAction: <CallToActionModal action={modal.action} />,
        showIFrame: <ShowIFrame action={modal.action} onClose={handleCloseModal} />,
        attention: <AttentionModal action={modal.action} />
    };

    if (!modal.isModalVisible || !modals[modal.element]) {
        return null;
    }

    if (NO_CARD_BLACK_LIST.includes(modal.element)) {
        return <ModalContainer>{modals[modal.element] || <div />}</ModalContainer>;
    }

    return (
        <ModalContainer>
            <ModalWrapper ref={ref} isFullyAdaptative={modal.element === "showIFrame"}>
                {modal.action?.hasCloseButton && (
                    <ModalClose onClick={handleCloseModal} data-test-id="close-modal-button">
                        <CloseIcon width="12" height="12" style={{ color: !!modal.action?.isCallToAction ? "white" : "" }} />
                    </ModalClose>
                )}
                {modals[modal.element] || <div />}
            </ModalWrapper>
        </ModalContainer>
    );
};

const mapStateToProps = ({ modal, resolution }: IReduxStore) => ({
    modal,
    resolutions: resolution
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch),
    announcementActions: bindActionCreators(AnnouncementActions, dispatch),
    resolutionActions: bindActionCreators(ResolutionActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal as any);
