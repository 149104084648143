// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 48px;
    border-radius: 8px;
    background-color: #f0f1f7;
`;

export const ContentLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #232326;
`;
