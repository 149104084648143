// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEssaySubmissionCardProps } from "./performance-essay-submission-card.types";

// Styles
import {
    Container,
    EssaySubmissionGradeWrapper,
    NoteWrapper,
    NoteLabel,
    BadgeWrapper,
    Icon,
    EssaySubmissionTheme,
    EssaySubmissionDateWrapper,
    EssaySubmissionDate,
    EssaySumissionCardFullWidthElement,
    EssaySubmissionSkillWrapper,
    EssaySubmissionSkillName,
    EssaySubmissionSkillGrade,
    EssaySubmissionSkillProgressBar,
    EssaySubmissionSkillProgressBarFilled
} from "./performance-essay-submission-card.styles";

// Utilities
import { Spacing } from "component-library/utilities/spacing";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceEssaySubmissionCard: FunctionComponent<PerformanceEssaySubmissionCardProps> = ({
    note,
    essayTheme,
    scheduleIcon,
    submissionDate,
    skills,
    progressElement,
    badgeElement,
    seeDetailsElement
}) => {
    const formattedNote = leftPad(note, 3);

    return (
        <Container>
            <EssaySubmissionGradeWrapper>
                {progressElement}

                <NoteWrapper>
                    <NoteLabel>{formattedNote}</NoteLabel>
                </NoteWrapper>

                <ConditionallyRender shouldRender={!!badgeElement} content={<BadgeWrapper>{badgeElement}</BadgeWrapper>} />
            </EssaySubmissionGradeWrapper>

            <Spacing direction="vertical" size={16} />

            <div>
                <EssaySubmissionTheme>{essayTheme}</EssaySubmissionTheme>

                <Spacing direction="vertical" size={8} />

                <EssaySubmissionDateWrapper>
                    <Icon src={scheduleIcon} />

                    <Spacing direction="horizontal" size={8} />

                    <EssaySubmissionDate>Enviada em: {submissionDate}</EssaySubmissionDate>
                </EssaySubmissionDateWrapper>
            </div>

            <Spacing direction="vertical" size={16} />

            <EssaySumissionCardFullWidthElement>
                {skills.map((skill) => {
                    const formattedGrade = leftPad(skill.grade, 3);

                    return (
                        <EssaySubmissionSkillWrapper key={skill.name}>
                            <EssaySubmissionSkillName>{skill.name}</EssaySubmissionSkillName>

                            <Spacing direction="horizontal" size={8} />

                            <EssaySubmissionSkillGrade>{formattedGrade}</EssaySubmissionSkillGrade>

                            <Spacing direction="horizontal" size={24} />

                            <EssaySubmissionSkillProgressBar>
                                <EssaySubmissionSkillProgressBarFilled color={skill.progress.color} width={skill.progress.percentage} />
                            </EssaySubmissionSkillProgressBar>
                        </EssaySubmissionSkillWrapper>
                    );
                })}
            </EssaySumissionCardFullWidthElement>

            <Spacing direction="vertical" size={16} />

            <EssaySumissionCardFullWidthElement>{seeDetailsElement}</EssaySumissionCardFullWidthElement>
        </Container>
    );
};
