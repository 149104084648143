// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    ContentSeparator,
    LabelWrapper,
    NotificationAndMenuWrapper,
    ScoreAndDeadlineWrapper,
    StatusAndActionsWrapper
} from "./activity-teacher-item.styles";

// Components
import { Skeleton } from "component-library/utilities/skeleton";

export const ActivityTeacherItemSkeleton: FunctionComponent = () => {
    return (
        <Container className="webapp-activity-teacher-item-skeleton">
            <StatusAndActionsWrapper>
                <Skeleton width="80px" height="26px" borderRadius="200px" />

                <NotificationAndMenuWrapper>
                    <Skeleton width="24px" height="24px" borderRadius="12px" />
                    <Skeleton width="24px" height="24px" borderRadius="12px" />
                </NotificationAndMenuWrapper>
            </StatusAndActionsWrapper>

            <Skeleton width="220px" height="26px" borderRadius="4px" />

            <ContentSeparator />

            <ScoreAndDeadlineWrapper>
                <LabelWrapper>
                    <Skeleton width="26px" height="16px" borderRadius="4px" />
                    <Skeleton width="80px" height="22px" borderRadius="4px" />
                </LabelWrapper>

                <LabelWrapper>
                    <Skeleton width="48px" height="16px" borderRadius="4px" />
                    <Skeleton width="80px" height="22px" borderRadius="4px" />
                </LabelWrapper>
            </ScoreAndDeadlineWrapper>
        </Container>
    );
};
