import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

// Dependencies
import { useSelector } from "store";
import { useDispatch } from "react-redux";

// Components
import ForumNew, { IForumNewPayload } from "./new";

// Helpers
import { Types } from "store/ducks/forum";
import sanitizeHtml from "sanitize-html";
import { IForumUpload } from "store/interfaces/IForum";

export const validExtensions = ["jpg", "jpeg", "gif", "png"];

const ForumNewScreen = () => {
    const dispatch = useDispatch();
    const [isFetched, setIsFetched] = useState(false);
    const { filters, uploads } = useSelector(({ forum }) => forum);

    const getForumSubjects = useCallback(() => dispatch({ type: Types.GET_FORUM_SUBJECTS_REQUEST }), [dispatch]);

    useEffect(() => {
        if (!isFetched) {
            getForumSubjects();
            setIsFetched(true);
        }
    }, [getForumSubjects, isFetched]);

    const handleUpload = useCallback(
        (files: File[]) => {
            const payload = {
                key: "question",
                attachments: [...uploads.question.map((item) => item.file), ...files]
            };

            dispatch({ type: Types.UPLOAD_ATTACHMENTS_REQUEST, payload });
            dispatch({ type: "CLOSE_MODAL" });
        },
        [dispatch, uploads.question]
    );

    const handleAttachFile = useCallback(() => {
        dispatch({
            type: "OPEN_MODAL",
            element: "upload",
            action: {
                onUploadFile: handleUpload
            }
        });
    }, [dispatch, handleUpload]);

    const handleRemoveAttachment = useCallback((id: number) => dispatch({ type: Types.DELETE_ATTACHMENT, payload: { key: "question", id } }), [
        dispatch
    ]);

    const handleRetryUpload = useCallback(
        (attachment: IForumUpload) => dispatch({ type: Types.RETRY_UPLOAD_ATTACHMENTS_REQUEST, payload: { key: "question", attachment } }),
        [dispatch]
    );

    const subjects = useMemo(() => {
        if (!filters[0]) {
            return [];
        }

        return filters[0].items.map((item) => {
            delete item.checked;

            return item;
        });
    }, [filters]);

    const handleSubmit = useCallback(
        (data: IForumNewPayload) => {
            const hasInvalidFiles = uploads.question.some((item) => {
                const extension = item.file.name.split(".").pop();

                return !validExtensions.includes(extension as string);
            });

            if (hasInvalidFiles) {
                return alert(`Você possui arquivos não suportados na lista de anexos. Extensões suportadas: ${validExtensions.join(", ")}`);
            }

            const attachments = uploads.question.filter((item) => item.status === "success").map((item) => item.attachment);

            const payload: IForumNewPayload = {
                ...data,
                content: sanitizeHtml(data.content, {
                    allowedTags: ["b", "i", "p", "strong", "ul", "li", "figure", "table", "tr", "td", "tbody", "tfoot", "thead"]
                }),
                ...(Boolean(attachments.length) && { attachments })
            };

            dispatch({ type: Types.CREATE_OR_UPDATE_QUESTION_REQUEST, payload });
        },
        [dispatch, uploads.question]
    );

    return (
        <ForumNew
            subjects={subjects}
            attachments={uploads.question}
            onAttachFile={handleAttachFile}
            onRemoveAttachment={handleRemoveAttachment}
            onRetryUpload={handleRetryUpload}
            onSubmit={handleSubmit}
        />
    );
};

export default memo(ForumNewScreen);
