import React from "react";

const SubjectWaves = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ display: "none" }}>
        <g id="quimica">
            <path d="M68.5824 52.6147C37.9705 40.7658 38.666 13.1175 0.499982 0.437334L122 0.437336L122 47.9373C122 47.9373 99.1942 64.4636 68.5824 52.6147Z" />
        </g>
        <g id="fisica">
            <path d="M39.2 59.1529C3.76786 40.5168 3.05176e-05 -0.00012207 3.05176e-05 -0.00012207L3.23431e-05 110L63.5 110L114 110L158 110C158 110 152.116 87.4833 122.515 73.7137C92.9138 59.9442 74.6321 77.789 39.2 59.1529Z" />
        </g>
        <g id="biologia">
            <path d="M44.0022 76.9604C76.6389 76.9037 85.6607 53.3214 125.637 55.1785C143.668 56.0162 159.763 62.5532 172 69.4777L172 -8.20449e-05L89.5 -7.96507e-05L-9.11041e-05 -7.86171e-05L-8.35669e-05 59.9999C-8.35669e-05 59.9999 11.3655 77.0171 44.0022 76.9604Z" />
        </g>
        <g id="historia">
            <path d="M18.9185 74.4042C41.6286 50.5643 65.0009 60.1965 91.4207 29.6692C99.2772 20.5912 104.752 10.4237 108.567 0.617551L120 0.617551L120 110.617C120 112.826 118.209 114.618 116.001 114.618L0.957589 114.618C1.95567 103.733 5.90278 88.0676 18.9185 74.4042Z" />
        </g>
        <g id="geografia">
            <path d="M37.3047 37.0334C67.8527 49.3181 85.1316 30.8659 121.827 47.6993C137.268 54.7825 149.273 65.827 158 76.2451L158 -4.46613e-05L100 -4.06385e-05L0.428084 -3.91571e-05C4.00812 8.70302 14.1823 27.7349 37.3047 37.0334Z" />
        </g>
        <g id="filosofia">
            <path d="M76.7603 63.6425C47.7973 47.9825 50.9603 22.9018 14.571 5.41645C9.46149 2.9613 5.17219 1.41634 -3.00584e-05 -8.00383e-07L22.6875 -4.17422e-05L46.5 -4.05786e-05L94 -4.05329e-05L94 70.0274C88.4555 68.8341 82.6359 66.8194 76.7603 63.6425Z" />
        </g>
        <g id="sociologia">
            <path d="M32.0898 0.700827C65.0112 1.23889 73.7715 24.9518 114.124 23.6845C138.283 22.9258 159.146 12.0764 172 3.4909L172 80L78 80L-6.27645e-06 80L-6.28226e-06 8.5279C8.29002 4.09161 19.0783 0.488171 32.0898 0.700827Z" />
        </g>
        <g id="lingua-portuguesa">
            <path d="M138.788 0.470169C105.866 0.939798 97.0564 24.6345 56.7067 23.2833C33.2437 22.4976 12.9101 12.1995 5.4346e-05 3.71743L5.39516e-05 61L172 61L172 8.99013C163.578 4.25579 152.405 0.275929 138.788 0.470169Z" />
        </g>
        <g id="portugues">
            <path d="M138.788 0.470169C105.866 0.939798 97.0564 24.6345 56.7067 23.2833C33.2437 22.4976 12.9101 12.1995 5.4346e-05 3.71743L5.39516e-05 61L172 61L172 8.99013C163.578 4.25579 152.405 0.275929 138.788 0.470169Z" />
        </g>
        <g id="literatura">
            <path d="M72.5853 60.2049C53.5314 38.5005 32.7032 46.1091 10.6365 18.4112C6.18294 12.8212 2.70874 6.83055 -4.59719e-05 0.828614L-4.43269e-05 98L86.9608 98C86.7669 88.4264 84.2554 73.4983 72.5853 60.2049Z" />
        </g>
        <g id="interpretacao">
            <path d="M46.2636 1.19071C78.7176 6.743 83.759 31.5145 123.833 36.4159C141.757 38.6082 158.722 34.9536 172 30.2441L172 90L-2.5322e-05 90L-1.98502e-05 10.3683C9.26193 4.73036 25.6839 -2.33011 46.2636 1.19071Z" />
        </g>
        <g id="ingles">
            <path d="M8.60493 82.2594C24.4763 53.4117 49.5332 56.758 67.2841 20.4975C70.5922 13.7399 72.8867 6.80747 74.4305 -2.20078e-05L93 -2.26155e-05L93 114L0.515656 114C0.509842 104.656 2.39516 93.5462 8.60493 82.2594Z" />
        </g>
        <g id="espanhol">
            <path d="M137.811 32.4783C114.608 55.839 91.4417 45.721 64.3904 75.6902C53.6472 87.5924 47.1102 101.526 43.1329 114L2.79332e-05 114L2.53129e-05 -1.73465e-05L155.388 -1.67094e-05C153.206 9.91535 148.334 21.8829 137.811 32.4783Z" />
        </g>
        <g id="matematica">
            <path d="M54.4065 90.9831C84.6903 78.0611 83.854 52.7956 121.701 38.741C139.767 32.0321 158.314 32.0543 172.765 33.9102L172.765 0.96744L0.76508 0.967442L0.76508 90.2907C8.4607 93.7328 30.1647 101.327 54.4065 90.9831Z" />
        </g>
        <g id="redacao">
            <path d="M14.7466 30.0415C35.6924 55.4456 59.6941 47.5106 83.8615 79.8504C86.5763 83.4833 88.941 87.2469 91 91.066L91 0.584482L0.955772 0.584488C2.70498 9.71276 6.62069 20.1859 14.7466 30.0415Z" />
        </g>
        <g id="default">
            <path d="M138.788 0.470169C105.866 0.939798 97.0564 24.6345 56.7067 23.2833C33.2437 22.4976 12.9101 12.1995 5.4346e-05 3.71743L5.39516e-05 61L172 61L172 8.99013C163.578 4.25579 152.405 0.275929 138.788 0.470169Z" />
        </g>
    </svg>
);

export default SubjectWaves;
