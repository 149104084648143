// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityAnswerItemVariant } from "./activity-answers.types";

export const ActivityAnswersContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const ActivityAnswersTitleWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    gap: 8px;
`;

export const ActivityAnswersIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

export const ActivityAnswersTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #232326;
`;

export const AnswerItemCompositionsWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 16px;
    padding: 16px 0;
`;

export const ActivityAnswerItemContainer = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`;

export const ActivityAnswerItemTextContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

type ActivityAnswerItemIndicatorWrapperStyleProps = {
    backgroundColor: ActivityAnswerItemVariant;
};

export const ActivityAnswerItemIndicatorWrapper = styled.div<ActivityAnswerItemIndicatorWrapperStyleProps>`
    width: 24px;
    height: 24px;
    border-radius: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `};
`;

type ActivityAnswerItemIndicatorStyleProps = {
    color: ActivityAnswerItemVariant;
};

export const ActivityAnswerItemIndicator = styled.p<ActivityAnswerItemIndicatorStyleProps>`
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `};
`;

export const ActivityAnswerItemLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4d4d63;
`;
