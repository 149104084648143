import React from "react";
import { Row, Col } from "components/Grid";
import { ReportVideoErrorModalInputContainer } from "./styles";
import Select from "components/Select";
import TextField from "components/Form/TextField";
import DropdownIndicator from "screens/MigrationUser/components/indicator";
import { SelectLabel } from "components/Select/styles";
import { IReportVideoErrorModalFormState } from ".";
import { ISelectOption } from "interfaces/ISelectOption";
import { ReportVideoErrorModalVideoErrorType, ReportVideoErrorModalDevices } from "enums/ReportVideoErrorModal";

const memoryOptions: ISelectOption<string | number>[] = [
    ...[1, 2, 4, 6, 8, 10, 12, 16].map((n) => ({ value: n, label: `${n}gb` })),
    { value: "unknown", label: "Não sei" }
];

const connectionVelocityOptions: ISelectOption<string | number>[] = [
    ...["4g (móvel)", "3g (móvel)", "de 10mb a 50mb", "de 50mb a 100mb", "mais de 100mb"].map((n) => ({ value: n, label: n })),
    { value: "unknown", label: "Não sei" }
];

const errorsOptions: ISelectOption<ReportVideoErrorModalVideoErrorType>[] = [
    {
        value: ReportVideoErrorModalVideoErrorType.Freezing,
        label: "Travando muito"
    },
    {
        value: ReportVideoErrorModalVideoErrorType.Freezing,
        label: "Áudio ruim"
    },
    {
        value: ReportVideoErrorModalVideoErrorType.IncompleteVideo,
        label: "Vídeo incompleto"
    }
];

interface IProps {
    isMobileDevice: boolean;
    handleChangeValue(key: string, value: any): void;
    formState?: IReportVideoErrorModalFormState;
    browserOptions: ISelectOption<string>[];
    osOptions: ISelectOption<string>[];
    handleChangeTextField: (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
    deviceOptions: ISelectOption<ReportVideoErrorModalDevices>[];
}

const ReportVideoErrorModalForm: React.FC<IProps> = ({
    isMobileDevice,
    handleChangeValue,
    formState,
    browserOptions,
    osOptions,
    handleChangeTextField,
    deviceOptions
}) => {
    if (isMobileDevice) {
        return (
            <Row>
                <Col xs={12} sm={6}>
                    <ReportVideoErrorModalInputContainer>
                        <Select
                            name="video_error_type"
                            components={{ DropdownIndicator }}
                            placeholder="Selecione o problema"
                            options={errorsOptions}
                            value={formState?.video_error_type}
                            onChange={(event: ISelectOption) => handleChangeValue("video_error_type", event)}
                            label="Qual é o problema com o vídeo?"
                            maxMenuHeight={160}
                        />
                    </ReportVideoErrorModalInputContainer>

                    <ReportVideoErrorModalInputContainer>
                        <Select
                            name="device"
                            components={{ DropdownIndicator }}
                            placeholder="Selecione um dispositivo"
                            options={deviceOptions}
                            value={formState?.device}
                            onChange={(event: ISelectOption) => handleChangeValue("device", event)}
                            label="Dispositivo"
                            maxMenuHeight={120}
                        />
                    </ReportVideoErrorModalInputContainer>

                    <ReportVideoErrorModalInputContainer>
                        <Select
                            name="browser"
                            components={{ DropdownIndicator }}
                            placeholder="Selecione um navegador"
                            options={browserOptions}
                            value={formState?.browser}
                            onChange={(event: ISelectOption) => handleChangeValue("browser", event)}
                            label="Navegador"
                            maxMenuHeight={160}
                        />
                    </ReportVideoErrorModalInputContainer>

                    <ReportVideoErrorModalInputContainer>
                        <SelectLabel>Modelo do dispositivo</SelectLabel>

                        <TextField name="device_model" value={formState?.device_model} onChange={handleChangeTextField("device_model")} />
                    </ReportVideoErrorModalInputContainer>
                </Col>
                <Col xs={12} sm={6}>
                    <ReportVideoErrorModalInputContainer>
                        <Select
                            name="memory"
                            components={{ DropdownIndicator }}
                            placeholder="Selecione a memória"
                            options={memoryOptions}
                            value={formState?.memory}
                            onChange={(event: ISelectOption) => handleChangeValue("memory", event)}
                            label="Memória do seu dispositivo"
                            maxMenuHeight={120}
                        />
                    </ReportVideoErrorModalInputContainer>

                    <ReportVideoErrorModalInputContainer>
                        <Select
                            name="connection_velocity"
                            components={{ DropdownIndicator }}
                            placeholder="Selecione uma velocidade"
                            options={connectionVelocityOptions}
                            value={formState?.connection_velocity}
                            onChange={(event: ISelectOption) => handleChangeValue("connection_velocity", event)}
                            label="Velocidade de conexão"
                            maxMenuHeight={120}
                        />
                    </ReportVideoErrorModalInputContainer>

                    <ReportVideoErrorModalInputContainer>
                        <SelectLabel>Provedor de internet</SelectLabel>

                        <TextField name="provider" value={formState?.internet_provider} onChange={handleChangeTextField("internet_provider")} />
                    </ReportVideoErrorModalInputContainer>
                </Col>
            </Row>
        );
    }

    return (
        <Row>
            <Col xs={12} sm={6}>
                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="video_error_type"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione o problema"
                        options={errorsOptions}
                        value={formState?.video_error_type}
                        onChange={(event: ISelectOption) => handleChangeValue("video_error_type", event)}
                        label="Qual é o problema com o vídeo?"
                        maxMenuHeight={160}
                    />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="device"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione um dispositivo"
                        options={deviceOptions}
                        value={formState?.device}
                        onChange={(event: ISelectOption) => handleChangeValue("device", event)}
                        label="Dispositivo"
                        maxMenuHeight={120}
                    />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="browser"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione um navegador"
                        options={browserOptions}
                        value={formState?.browser}
                        onChange={(event: ISelectOption) => handleChangeValue("browser", event)}
                        label="Navegador"
                        maxMenuHeight={160}
                    />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <SelectLabel>Versão do navegador</SelectLabel>

                    <TextField name="browser_version" value={formState?.browser_version} onChange={handleChangeTextField("browser_version")} />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="operational_system"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione um sistema operacional"
                        options={osOptions}
                        value={formState?.operational_system}
                        onChange={(event: ISelectOption) => handleChangeValue("operational_system", event)}
                        label="Sistema operacional"
                        maxMenuHeight={160}
                    />
                </ReportVideoErrorModalInputContainer>
            </Col>
            <Col xs={12} sm={6}>
                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="memory"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione a memória"
                        options={memoryOptions}
                        value={formState?.memory}
                        onChange={(event: ISelectOption) => handleChangeValue("memory", event)}
                        label="Memória do seu dispositivo"
                        maxMenuHeight={120}
                    />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <SelectLabel>Processador do seu dispositivo</SelectLabel>
                    <TextField name="processor" value={formState?.processor} onChange={handleChangeTextField("processor")} />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <Select
                        name="connection_velocity"
                        components={{ DropdownIndicator }}
                        placeholder="Selecione uma velocidade"
                        options={connectionVelocityOptions}
                        value={formState?.connection_velocity}
                        onChange={(event: ISelectOption) => handleChangeValue("connection_velocity", event)}
                        label="Velocidade de conexão"
                        maxMenuHeight={120}
                    />
                </ReportVideoErrorModalInputContainer>

                <ReportVideoErrorModalInputContainer>
                    <SelectLabel>Provedor de internet</SelectLabel>

                    <TextField name="provider" value={formState?.internet_provider} onChange={handleChangeTextField("internet_provider")} />
                </ReportVideoErrorModalInputContainer>
            </Col>
        </Row>
    );
};

export default ReportVideoErrorModalForm;
