// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    HeaderContainer,
    LabelWrapper,
    Title,
    Label,
    ActionIconElementWrapper,
    StatusChipCompositionWrapper,
    TypeLabel
} from "./activity-student-item.styles";

// Types
import { ActivityStudentItemProps } from "./activity-student-item.types";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const ActivityStudentItem: FunctionComponent<ActivityStudentItemProps> = ({
    title,
    deadline,
    score,
    type,
    hasAction,
    statusChipComposition,
    actionIconElement,
    handleClick
}) => {
    return (
        <Container className="webapp-activity-item" hasAction={hasAction} onClick={() => !!hasAction && handleClick()}>
            <Title className="title">{title}</Title>

            <LabelWrapper className="deadline">
                <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="hide" content={<Label color="#4d4d63">Prazo:</Label>} />

                <Label color="#4d4d63">{deadline}</Label>
            </LabelWrapper>

            <TypeLabel className="type">{type}</TypeLabel>

            <LabelWrapper className="score">
                <ScreenWidthRender renderingWidth={1920} actionAfterRenderingWidth="hide" content={<Label color="#000000">Pontuação:</Label>} />

                <Label color="#000000">{score}</Label>
            </LabelWrapper>

            <StatusChipCompositionWrapper className="status-chip">{statusChipComposition}</StatusChipCompositionWrapper>

            <ActionIconElementWrapper className="action-icon">{actionIconElement}</ActionIconElementWrapper>
        </Container>
    );
};

export const ActivityStudentHeaderItem: FunctionComponent = () => {
    return (
        <HeaderContainer className="webapp-activity-header-item">
            <Label color="#83899E">Título</Label>

            <ScreenWidthRender renderingWidth={1920} actionAfterRenderingWidth="show" content={<Label color="#83899E">Pontuação</Label>} />

            <Label color="#83899E">Avaliação</Label>

            <Label color="#83899E">Prazo</Label>

            <Label color="#83899E">Status</Label>
            <div />
        </HeaderContainer>
    );
};
