// Dependencies
import React, { useState, useCallback, memo, useRef } from "react";
import { WebpInputBox } from "components/WebpInputBox";
import { DateTime } from "luxon";
import InfiniteScroll from "react-infinite-scroll-component";

// Store
import { IReduxStore } from "interfaces/IReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { Creators as postActions } from "store/ducks/doubtPost";
import { Creators as modalActions } from "store/ducks/modal";
import { IPost, IPostResponse, IGetTopicStatus } from "store/interfaces/IDoubtPost";

// Utils
import useMathJaxParser from "hooks/use-math-jax-parser";
import { GetWidth } from "utils/windowsSize";
import { ternary } from "utils/ternary";

// Components
import Spinner from "components/Spinner";
import AudioPlayer from "components/AudioPlayer";
import { WebpDoubtResponse } from "components/WebpDoubtResponse";
import { WebpCardDoubt } from "components/WebpCardDoubt";

// Styles
import { Wrapper, ContentModal } from "./styles";

interface IProps {
    posts: IPostResponse[] | IPost[];
    handleFetchNextPosts?: () => void;
    isSpecificPostVisualization?: boolean;
}

const Doubts = ({ posts, handleFetchNextPosts, isSpecificPostVisualization = false }: IProps) => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [idPostEdit, setIdPostEdit] = useState("");
    const [valueDoubt, setValueDoubt] = useState("");
    const contentRef = useRef<HTMLDivElement>(null);

    useMathJaxParser(contentRef);

    const { doubtPost, credentials } = useSelector(({ doubtPost, credentials }: IReduxStore) => ({
        doubtPost,
        credentials
    }));

    const isDoubtsActive = [IGetTopicStatus.Active].includes(doubtPost.topic.data.status);

    const handleClickEditDoubt = (idPost: string, valueContent: string) => {
        setIsOpenModal(true);
        setIdPostEdit(idPost);
        setValueDoubt(valueContent.split("<br/>").join("\n"));
    };

    const handleClickDeletePost = useCallback(
        (idPost: string) => {
            dispatch(
                modalActions.openModal("confirmAction", {
                    title: "Atenção!",
                    text: "Deseja realmente excluir sua dúvida?",
                    onConfirm: () => dispatch(postActions.deletePostRequest({ postId: idPost })),
                    variant: "warning",
                    buttonText: "Confirmar",
                    hasCancelButton: true,
                    onCancel: () => dispatch(modalActions.closeModal()),
                    variantCancelButton: "outline-warning",
                    cancelButtonText: "Cancelar"
                })
            );
        },
        [dispatch]
    );

    const getValueDoubt = useCallback(
        (value: string) => {
            setIsOpenModal(false);

            !!idPostEdit && dispatch(postActions.editPostRequest({ postMessage: value.trim(), postId: idPostEdit }));
        },
        [dispatch, idPostEdit]
    );

    const handleOpenImage = (item: string) => {
        const itemSplit = item.split('src="')[1];
        const currentItem = itemSplit.split('">')[0];

        const widthModal = ternary([
            [GetWidth < 768, "300"],
            [GetWidth < 1024, "600"],
            [GetWidth < 1440, "900"],
            [true, "1200"]
        ]);

        dispatch(
            modalActions.openModal("confirmAction", {
                text: <img src={currentItem} alt="zoom da imagem do conteúdo da dúvida" style={{ maxWidth: "100%" }} />,
                widthContainer: widthModal,
                onConfirm: () => dispatch(modalActions.closeModal()),
                variant: "info",
                hasIcon: false,
                buttonText: "Fechar"
            })
        );
    };

    const handleDownloadAttachment = (attachment: string) => {
        window.open(attachment, "_blank");
    };

    const doubtTime = (createdAt: string) => {
        const time = DateTime.fromISO(createdAt).toRelative();

        const isNow = Number(time?.split(" ")[1]) < 1;

        if (isNow) {
            return "agora";
        }

        return time?.toString();
    };

    return (
        <>
            {isOpenModal && (
                <ContentModal>
                    <WebpInputBox
                        titleBox={"Atualizar pergunta"}
                        onClickAction={getValueDoubt}
                        labelButtom={"Atualizar pergunta"}
                        placeHolder={"Atualize sua dúvida"}
                        onClickClose={() => setIsOpenModal(false)}
                        defaultValue={valueDoubt}
                    />
                </ContentModal>
            )}
            <InfiniteScroll
                className="InfiniteScroll"
                scrollableTarget="scrollable"
                dataLength={posts?.length}
                next={() => !!handleFetchNextPosts && handleFetchNextPosts()}
                hasMore={true}
                loader={doubtPost.posts.isLoading && <Spinner fixed={false} size={20} />}
            >
                <Wrapper>
                    {posts?.map((item: IPost) => {
                        const isAuthor = Number(item.user.userId) === credentials.student.id;

                        const formattedDoubtTime = doubtTime(item.createdAt);

                        return (
                            <WebpCardDoubt
                                key={item.id}
                                author={isAuthor}
                                doubt={item.content}
                                nameUser={item.user.name}
                                photoUser={item?.user.avatar}
                                time={formattedDoubtTime}
                                onClickEditDoubt={() => handleClickEditDoubt(item.id, item.content)}
                                onClickExcludeDoubt={() => handleClickDeletePost(item.id)}
                                isEditable={!item.posts.length && isDoubtsActive && !isSpecificPostVisualization}
                                isExcludable={isDoubtsActive && !isSpecificPostVisualization}
                            >
                                {item.posts?.map((itemAnswers: IPost) => {
                                    const formattedAnswerTime = doubtTime(itemAnswers.createdAt);
                                    const isAudio = itemAnswers?.attachment?.includes(".mp3");

                                    return (
                                        <WebpDoubtResponse
                                            onClickContent={() => handleOpenImage(itemAnswers.content)}
                                            onClickAttachment={() => handleDownloadAttachment(itemAnswers?.attachment!)}
                                            ref={contentRef}
                                            key={itemAnswers.id}
                                            content={itemAnswers.content}
                                            nameSender={itemAnswers?.user.name}
                                            photoUser={itemAnswers?.user.avatar}
                                            time={formattedAnswerTime}
                                            {...(!isAudio && { attachment: itemAnswers?.attachment })}
                                            {...(!!isAudio && { audioPlayerComposition: <AudioPlayer src={itemAnswers?.attachment!} /> })}
                                        />
                                    );
                                })}
                            </WebpCardDoubt>
                        );
                    })}
                </Wrapper>
            </InfiniteScroll>
        </>
    );
};

export default memo(Doubts);
