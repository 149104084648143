// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    contentAlignment?: "start" | "center";
};

export const Container = styled.div<ContainerStyleProps>`
    background-color: ${theme.colors.white};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 1rem;
    box-sizing: border-box;

    ${({ contentAlignment }) =>
        contentAlignment === "center" &&
        css`
            margin-bottom: 24px;
        `}
`;

type ContentCompositionWrapperProps = {
    contentAlignment?: "start" | "center";
};

export const ContentCompositionWrapper = styled.div<ContentCompositionWrapperProps>`
    width: 100%;

    ${({ contentAlignment }) =>
        contentAlignment === "center" &&
        css`
            display: flex;
            justify-content: center;
        `}

    ${({ contentAlignment }) =>
        contentAlignment === "start" &&
        css`
            justify-content: flex-start;
        `}
`;
