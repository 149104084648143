import React, { useCallback, useMemo } from "react";
import * as S from "./styles";

import AccordionOptions from "components/AccordionOptions";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";
import Skeleton from "./skeleton";
import { DateTime } from "luxon";
import Badge from "components/Badge";

interface IProps {
    realizations: { id: number; endDate?: string | null; isTest: boolean }[];
    loading: boolean;
    onClickResult(answerCardId: number): void;
}

const FinishedExamDays = ({ realizations, loading, onClickResult }: IProps) => {
    const formatDate = useCallback((date?: string | null) => {
        if (!date || !DateTime.fromISO(date).isValid) {
            return "-";
        }

        return DateTime.fromISO(date).toFormat("dd/MM/yyyy") || "-";
    }, []);

    const [firstRealization] = realizations;

    const anotherRealizations = useMemo(() => {
        return realizations
            .filter((item) => item.id !== firstRealization.id)
            .map((realization, index) => ({
                value: realization.id,
                label: `${realizations.length - index - 1}ª Resolução - Realizado em ${formatDate(realization?.endDate)} ${
                    !!realization?.isTest ? " (Teste)" : ""
                }`
            }));
    }, [firstRealization.id, formatDate, realizations]);

    if (loading) {
        return <Skeleton />;
    }

    return (
        <S.FinishedExamDaysContainer>
            <p>Provas realizadas</p>

            <S.FinishedExamDaysButton onClick={() => onClickResult(firstRealization.id)}>
                <p>
                    {~~realizations?.length}ª Resolução - Realizado em {formatDate(firstRealization?.endDate)}
                </p>
                {!!firstRealization?.isTest && <Badge variant="info">Teste</Badge>}
                <ChevronRight />
            </S.FinishedExamDaysButton>

            {realizations.length >= 2 && <AccordionOptions title="Ver mais" options={anotherRealizations} onSelect={onClickResult} />}
        </S.FinishedExamDaysContainer>
    );
};

export default FinishedExamDays;
