import React from "react";

// Helpers
import { theme } from "config/theme";

// Assets
import { SpinnerWrap } from "./styles";

interface IProps {
    color?: string;
    size?: number;
    fixed?: boolean;
}
const Spinner = ({ color = theme.colors.brand[300], size = 35, fixed = true }: IProps) => (
    <SpinnerWrap fixed={fixed}>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18">
            <path
                fill={color}
                stroke={color}
                strokeWidth={1}
                d="M15 8c0 3.9-3.1 7-7 7s-7-3-7-7h-1c0 4 3.6 8 8 8s8-3.6 8-8h-1z"
                style={{ transform: "translate(1px, 1px)" }}
            ></path>
        </svg>
    </SpinnerWrap>
);

export default Spinner;
