import React, { useEffect } from "react";

// Helpers
import { theme } from "config/theme";

// Assets
import maintenanceImg from "static/maintenance.svg";

import { Container, LogoWrapper, MaintenanceWrapper, MaintenanceTitle, MaintenanceSubtitle } from "./styles";

interface Props {
    onGetStatusHealth: () => void;
}

const Maintenance = ({ onGetStatusHealth }: Props) => {
    useEffect(() => {
        setInterval(() => {
            onGetStatusHealth();
        }, 30000);
    }, [onGetStatusHealth]);

    return (
        <Container>
            <LogoWrapper>
                <img alt={theme.project.name} src={theme.project.logo} />
            </LogoWrapper>
            <img src={maintenanceImg} alt="imagem de manutenção" />
            <MaintenanceWrapper>
                <MaintenanceTitle>
                    Oops! :( <br />
                    Estamos enfrentando <span>problemas técnicos...</span>
                </MaintenanceTitle>
                <MaintenanceSubtitle>
                    <span>No momento estamos com instabilidades no nosso sistema, </span>
                    em breve voltaremos a normalidade. Pedimos desculpas pelo inconveniente.
                </MaintenanceSubtitle>
            </MaintenanceWrapper>
        </Container>
    );
};

export default Maintenance;
