import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export const PaywallWrapper = styled.div``;

export const PaywallHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.medium} ${theme.spacing.large};

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    p {
        font-family: ${theme.typography.family.secondary};
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[400]};
        flex: 1;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        p {
            flex: unset;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.large};
    }
`;

export const PaywallScroll = styled.div<{ center: boolean }>`
    display: flex;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;

    ${({ center }) => !!center && `justify-content: center;`};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 0 ${theme.spacing.medium};
    }
`;

export const PaywallScrollItem = styled.div`
    display: flex;
    flex: 0 0 auto;

    margin: ${theme.spacing.small} ${theme.spacing.smallX};
    width: 217px;

    ${theme.project.slug === "promilitares" &&
        css`
            width: 90%;
            margin: ${theme.spacing.small} auto;
        `}
`;

export const PaywallCard = styled.div`
    background: ${theme.colors.base[100]};

    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    border-radius: 4px;
    position: relative;
    box-shadow: ${theme.shadow[5]};
`;

export const PaywallCardWave = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-top: -1px; /* TIRA BUG DE ESPAÇAMENTO DO  SVG */

    svg {
        max-width: 100%;
    }

    ${theme.project.slug === "promilitares" &&
        css`
            height: 100px;
            background: ${theme.colors.brand[400]};
            border-radius: 4px 4px 0 0;

            svg {
                display: none;
            }
        `};
`;

export const PaywallCardHeader = styled.div`
    /* background: ${theme.colors.secondary[400]}; */
    padding-top: ${theme.spacing.small};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    text-align: center;
    position: relative;
    min-height: 110px;

    svg {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    strong {
        color: ${theme.colors.base[100]};
        font-size: ${theme.typography.sizes.large};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding: 0 ${theme.spacing.smallXX};
    }

    p {
        color: ${theme.colors.base[100]};
        font-size: ${theme.typography.sizes.small};
        margin-top: ${theme.spacing.smallXX};
    }
`;

export const PaywallCardContent = styled.div`
    padding: ${theme.spacing.smallX};

    ${ButtonWrapper} {
        font-weight: bold;
        display: flex;
        align-items: center;
        font-size: ${theme.typography.sizes.medium};

        svg {
            margin-left: ${theme.spacing.smallXX};
        }
    }
`;

export const PaywallCardList = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PaywallCardListItem = styled.div`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.smallX} ${theme.spacing.smallXX};
    border-bottom: 1px solid ${theme.colors.base[100]};

    strong {
        width: 22px;
        text-align: center;
        color: ${theme.colors.base[500]};
        margin-right: ${theme.spacing.smallXX};
        font-size: ${theme.typography.sizes.smallX};
    }

    p {
        flex: 1;
        font-size: ${theme.typography.sizes.small};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.colors.base[400]};
    }
`;

export const PaywallRecommended = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    span {
        font-weight: bold;
        font-size: ${theme.typography.sizes.small};
        background: ${theme.colors.system.warning[400]};
        border-radius: 40px;
        text-transform: uppercase;
        color: ${theme.colors.white};
        padding: 0 ${theme.spacing.medium};
        position: absolute;
    }
`;

export const PaywallCardPrice = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: ${theme.spacing.small} 0;
`;

export const PaywallCardPriceStrike = styled.p`
    text-decoration: line-through;
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.system.danger[300]};
`;

export const PaywallCardPriceInstallments = styled.div`
    span {
        font-size: ${theme.typography.sizes.small};
        color: ${theme.colors.base[500]};
        margin-right: ${theme.spacing.smallXX};
        font-weight: bold;
    }

    strong {
        font-size: ${theme.typography.sizes.large};
        color: ${theme.colors.system.success[300]};
        font-weight: bold;
    }
`;

export const PaywallButton = styled.a`
    font-size: ${theme.typography.sizes.small};
    padding: calc(${theme.spacing.small} / 2) ${theme.spacing.small};
    background: ${theme.project.slug === "promilitares" ? theme.colors.brand[400] : theme.colors.secondary[400]};
    border: 0;
    border-radius: 4px;
    color: ${theme.colors.white};
    cursor: pointer;
    transition: all 0.2s ease 0s;
    font-weight: 600;
    line-height: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;

    svg {
        margin-left: ${theme.spacing.smallX};
    }

    &:hover {
        background: ${theme.project.slug === "promilitares" ? theme.colors.brand[400] : theme.colors.secondary[400]}};
    }
`;
