import styled from "styled-components";
import { theme } from "config/theme";

export const ExerciseNotDoneWrap = styled.div`
    h2,
    h3 {
        font-family: "Nunito Sans", sans-serif;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 20px;
        color: ${theme.colors.base[500]};
    }

    h2 {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ExerciseListDashboardNotDoneItem = styled.li`
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.sizes.smallX};
    line-height: 22px;
    color: ${theme.colors.base[300]};
`;
