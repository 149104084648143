// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ActivityListContainer,
    ActivityListItemCompositionsWrapper,
    ActivityListItemContainer,
    ActivityListItemDescription,
    ActivityListItemTitle,
    ActivityListTitle
} from "./activity-list.styles";

// Types
import { ActivityListItemProps, ActivityListProps } from "./activity-list.types";

export const ActivityList: FunctionComponent<ActivityListProps> = ({ title, activityListItemCompositions }) => {
    return (
        <ActivityListContainer>
            <ActivityListTitle>{title}</ActivityListTitle>

            <ActivityListItemCompositionsWrapper>{activityListItemCompositions}</ActivityListItemCompositionsWrapper>
        </ActivityListContainer>
    );
};

export const ActivityListItem: FunctionComponent<ActivityListItemProps> = ({ title, description }) => {
    return (
        <ActivityListItemContainer>
            <ActivityListItemTitle>{title}</ActivityListItemTitle>

            <ActivityListItemDescription>{description}</ActivityListItemDescription>
        </ActivityListItemContainer>
    );
};
