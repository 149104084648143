import React, { useCallback, memo } from "react";

// Helpers
import { WizardOption } from "./types";

// Assets
import { WizardNavContainer, WizardNavItem, WizardNavBullet, WizardNavLabel } from "./styles";

export interface WizardNavProps<T> {
    current: WizardOption<T>;
    completeds: T[];
    options: WizardOption<T>[];
    onChangeStep?(slug: T): void;
}

const WizardNav = <T,>({ completeds, current, options, onChangeStep }: WizardNavProps<T>) => {
    const handleChangeStep = useCallback(
        (slug: T) => () => {
            if (!onChangeStep) {
                return;
            }

            onChangeStep(slug);
        },
        [onChangeStep]
    );

    return (
        <WizardNavContainer data-test-id="wizard-nav">
            {options.map((option, index) => {
                const isCurrent = current?.slug === option?.slug;
                const isCompleted = completeds.includes(option.slug);

                return (
                    <WizardNavItem as={onChangeStep ? "button" : "div"} onClick={handleChangeStep(option.slug)} key={index}>
                        <WizardNavBullet isCompleted={isCompleted} isCurrent={isCurrent} />
                        {isCurrent && <WizardNavLabel data-test-id="wizard-current-step-label">{current.label}</WizardNavLabel>}
                    </WizardNavItem>
                );
            })}
        </WizardNavContainer>
    );
};

export default memo(WizardNav);
