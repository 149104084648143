import { theme } from "config/theme";
import styled from "styled-components/macro";
import { TitleH2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { DropzoneContainer } from "components/Dropzone/styles";

export const UploadModalContainer = styled.div`
    background: ${theme.colors.white};
    border-radius: ${theme.spacing.smallX};
    min-width: 790px;

    ${MediaQueries.SMALL} {
        max-width: 100%;
        min-width: unset;
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        max-width: 100%;
        min-width: unset;
    }
`;

export const UploadModalHeader = styled.header`
    border-bottom: 1px solid #eaeaea;
    padding: ${theme.spacing.small};
`;

export const UploadModalContent = styled.div`
    padding: ${theme.spacing.largeXX};

    ${MediaQueries.SMALL} {
        padding: 0;

        ${DropzoneContainer} {
            border: unset;
            width: 320px;
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        padding: 0;

        ${DropzoneContainer} {
            border: unset;
            width: 600px;
        }
    }
`;

export const UploadModalTitle = styled.strong`
    ${TitleH2};
    color: ${theme.colors.base[500]};
    display: block;
    text-align: center;
`;
