import { MediaQueries } from "assets/styles/settings";
import styled, { css } from "styled-components/macro";
import { Col, Grid as GridComponent, Row as RowComponent } from "react-styled-flexboxgrid";

export const GridWidth = css`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        max-width: 100%;
        width: 1152px;
    }
`;

const Grid = styled(GridComponent)`
    ${GridWidth};
`;

const Row = styled(RowComponent)``;

const GridConfig = {
    flexboxgrid: {
        gridSize: 12,
        gutterWidth: 2,
        outerMargin: 1,
        mediaQuery: "only screen",
        container: {
            sm: 46,
            md: 61,
            lg: 76
        },
        breakpoints: {
            xs: 0,
            sm: 48,
            md: 64,
            lg: 75
        }
    }
};

export { Grid, GridConfig, Row, Col };
