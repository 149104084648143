import styled from "styled-components/macro";
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const LogoWrapper = styled.div`
    margin: 48px 0 30px;

    @media (min-width: 1024px) {
        align-self: flex-start;
        margin-left: 72px;
    }

    img {
        width: 100%;
        height: 60px;
    }
`;

export const MaintenanceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 638px;
`;

export const MaintenanceTitle = styled.p`
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: ${theme.colors.base[500]};
    margin: 32px 0 24px;

    span {
        color: ${theme.colors.brand[300]};
    }

    @media (min-width: 1024px) {
        font-weight: 800;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.01em;
    }
`;

export const MaintenanceSubtitle = styled.p`
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-block-start: 0px;

    text-align: center;

    color: ${theme.colors.base[300]};

    @media (min-width: 1024px) {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;

        span {
            display: block;
        }
    }
`;
