//assets
import filter from "assets/img/filter.svg";
import search from "assets/img/search.svg";
import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//services
import { Types } from "../../../store/ducks/exerciseListCustom";
import { useHistory } from "react-router-dom";
import { WebpSearchInput } from "components/WebpSearchInput";
import { WebpText } from "components/WebpText";
import { WebpButton } from "components/WebpButton";
import Spinner from "../../../components/Spinner";
import useDebounce from "../../../hooks/use-debounce";
import { IReduxStore } from "../../../interfaces/IReduxStore";
import ReportCardQuestion from "./components/ReportCardQuestion";
import {
    CustomExerciseListCardsContainer,
    CustomExerciseListCardsDropDown,
    CustomExerciseListCardsDropDownList,
    CustomExerciseListCardsHeader,
    CustomExerciseListCardsSection,
    SpinnerWrapper
} from "./styled";
import { useWindowDimensions } from "hooks/use-window-dimensions";

function CustomExerciseListCards() {
    const { width } = useWindowDimensions();

    const [currentSortingValue, setCurrentSortingValue] = useState(3);
    const [page, setPage] = useState(1);
    const [searchInputValue, setSearchInputValue] = useState("");

    const [isDropDownOpened, setIsDropDownOpened] = useState(false);

    const debounceSearch = useDebounce(searchInputValue, 500);

    const { exerciseListCustom, courseSlug } = useSelector((state: IReduxStore) => ({
        exerciseListCustom: state.exerciseListCustom,
        courseSlug: state.course.slug
    }));
    const history = useHistory();
    const dispatch = useDispatch();

    const filterRef = useRef<HTMLDivElement>(null);

    const customList = exerciseListCustom.customList;
    const isLoading = exerciseListCustom.isLoading;

    const disableLoadMoreButton = useMemo(() => (customList?.total !== undefined ? customList?.total <= customList?.items?.length : false), [
        customList
    ]);

    const handleSearchInputChange = useCallback(
        (ev: CustomEvent<any>) => {
            setPage(1);
            setSearchInputValue(ev.detail.value);
        },
        [setPage, setSearchInputValue]
    );

    const loadMoreCards = () => {
        if (disableLoadMoreButton) return;
        setPage((prev) => prev + 1);
    };

    const handleCreateListBtnClick = () => {
        history.push(`/app/curso/${courseSlug}/banco-de-questoes/novo`);
    };

    const onToggleDropDown = useCallback(() => setIsDropDownOpened(!isDropDownOpened), [isDropDownOpened]);

    const onClickFilter = useCallback(() => {
        filterRef.current!.classList.toggle("clicked");
        onToggleDropDown();
    }, [onToggleDropDown]);

    const handleSorting = useCallback(
        (type: number) => {
            setPage(1);
            setCurrentSortingValue(type);
            onToggleDropDown();
        },
        [onToggleDropDown]
    );

    useEffect(() => {
        dispatch({ type: Types.GET_CUSTOM_EXERCISES_LIST_REQUEST, payload: { page: page, title: debounceSearch, order: currentSortingValue } });
    }, [currentSortingValue, debounceSearch, dispatch, page]);

    useEffect(() => {
        if (!exerciseListCustom.userHasList) {
            history.push(`/app/curso/${courseSlug}/banco-de-questoes/bem-vindo`);
        }
    }, [courseSlug, exerciseListCustom.userHasList, history]);

    return (
        <>
            <CustomExerciseListCardsContainer>
                <CustomExerciseListCardsHeader>
                    <div className="row-1">
                        <WebpText xs="title-sm" sm="title-md" id="title" color="neutral" colorWeight={500}>
                            <p>Banco de questões</p>
                        </WebpText>
                        <div className="search-container">
                            <WebpSearchInput
                                onWebpDropdownSearchChanged={handleSearchInputChange}
                                id="search"
                                searchInputComposition={<img id="search-icon" src={search} alt="" />}
                            />
                            <WebpButton
                                id="createListBtn"
                                variant="primary"
                                size="medium"
                                onClick={handleCreateListBtnClick}
                                contentButtonComposition={"Criar Lista"}
                                isFullyAdaptative={width <= 768}
                            ></WebpButton>
                        </div>
                    </div>

                    <div className="row-2">
                        <WebpText xs="title-xss" id="name" color="neutral" colorWeight={500}>
                            Listas criadas{" "}
                            <span>
                                <WebpText id="amount" xs="body-text-02">
                                    ({customList?.total})
                                </WebpText>
                            </span>
                        </WebpText>

                        <div ref={filterRef} className="filter">
                            <div className="row-order" onClick={(_) => onClickFilter()}>
                                <img id="filter" src={filter} loading="lazy" alt="Sorting Icon" />
                                <WebpText xs="title-xss" id="label" color="neutral" colorWeight={400}>
                                    <p>Ordenar</p>
                                </WebpText>
                                <ChevronDownIcon className="icon" />
                            </div>

                            <CustomExerciseListCardsDropDown isOpened={isDropDownOpened}>
                                <ul id="list-drop-down">
                                    <CustomExerciseListCardsDropDownList onClick={() => handleSorting(1)}>
                                        Ordem alfabética
                                    </CustomExerciseListCardsDropDownList>
                                    <CustomExerciseListCardsDropDownList onClick={() => handleSorting(2)}>
                                        Data de criação
                                    </CustomExerciseListCardsDropDownList>
                                    <CustomExerciseListCardsDropDownList onClick={() => handleSorting(3)}>
                                        Data da realização
                                    </CustomExerciseListCardsDropDownList>
                                </ul>
                            </CustomExerciseListCardsDropDown>
                        </div>
                    </div>
                </CustomExerciseListCardsHeader>
                {isLoading ? (
                    <SpinnerWrapper>
                        <Spinner />
                    </SpinnerWrapper>
                ) : (
                    <CustomExerciseListCardsSection customListItems={customList?.items.length}>
                        {customList && customList.items?.length === 0 ? (
                            <p style={{ textAlign: "center", gridColumn: "span 3" }}>Ops! Não encontramos nenhuma lista.</p>
                        ) : (
                            customList?.items?.map((card, index) => <ReportCardQuestion {...card} key={index} />)
                        )}
                    </CustomExerciseListCardsSection>
                )}

                {!isLoading && (
                    <div id="footer">
                        <WebpButton
                            size="medium"
                            variant="outline-primary"
                            onClick={() => loadMoreCards()}
                            disabled={disableLoadMoreButton}
                            contentButtonComposition={"Carregar mais"}
                        ></WebpButton>
                    </div>
                )}
            </CustomExerciseListCardsContainer>
        </>
    );
}

export default CustomExerciseListCards;
