import React, { memo, useCallback, useMemo, useState } from "react";
import { Col, Row } from "components/Grid";

import { IEssayComposition } from "store/interfaces/IEssay";

import { CardContainer } from "components/Card/styles";
import Switcher from "components/Switcher";
import Grades from "./components/grades";
import Comments from "./components/comments";
import Detail from "./components/detail";
import EssaySended from "./components/sended";

import * as S from "./styles";

interface IEssayCorrectionProps {
    composition: IEssayComposition;
    themeTitle: string;
}

const EssayCorrection = ({ composition, themeTitle }: IEssayCorrectionProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [commentIndex, setCommentIndex] = useState(0);

    const handleSwitcherChange = useCallback((index: number, comment = 0) => {
        setActiveIndex(index);
        setCommentIndex(comment);
    }, []);

    const Content = useMemo(() => {
        switch (activeIndex) {
            case 1:
                return <Comments composition={composition} comment={commentIndex} onChange={handleSwitcherChange} />;
            case 2:
                return <Detail composition={composition} themeTitle={themeTitle} />;
            case 0:
            default:
                return <Grades composition={composition} />;
        }
    }, [activeIndex, commentIndex, composition, handleSwitcherChange, themeTitle]);

    return (
        <Row>
            <Col xs={12} sm={7}>
                <EssaySended mode="correction" composition={composition} onChange={handleSwitcherChange} />
            </Col>
            <Col xs={12} sm={5}>
                <S.EssayCorrectionWrapper>
                    <CardContainer column={true}>
                        <Switcher activeIndex={activeIndex} options={["Nota", "Comentários", "Detalhes"]} onChange={handleSwitcherChange} />
                        {Content}
                    </CardContainer>
                </S.EssayCorrectionWrapper>
            </Col>
        </Row>
    );
};

export default memo(EssayCorrection);
