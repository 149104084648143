// Dependencies
import React, { useCallback, useEffect, useState } from "react";
import * as S from "../styles";

// Icons
import ExerciseListIcon from "prodigio-icons/web/filled/ExerciseList";
import ComplementIcon from "prodigio-icons/web/filled/Complement";
import VideoIcon from "prodigio-icons/web/filled/Video";
import MonitoriaIcon from "prodigio-icons/web/filled/Monitoria";

// Utils
import history from "services/history";
import { theme } from "config/theme";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators as modalActions } from "store/ducks/modal";

// Types
import { ContentPerType, Item } from "store/interfaces/IQrCode";
import { IReduxStore } from "interfaces/IReduxStore";

// Components
import { WebpDropdownFull } from "components/WebpDropdownFull";
import { WebpItemMaterials } from "components/WebpItemMaterials";
import Header from "../../Header";
import { Col, Row, Grid } from "components/Grid";

const DropdownMaterial = () => {
    const dispatch = useDispatch();

    const [contentQRCode, setContentQRCode] = useState<ContentPerType[]>([]);

    const { qrCode, courseSlug } = useSelector(({ qrCode, course }: IReduxStore) => ({
        qrCode: qrCode.qrCode,
        courseSlug: course.slug
    }));

    const isProraiz = theme.project.slug === "proraiz";

    const ridingStates = useCallback(async () => {
        const contentPerType = qrCode?.playlists[0].contentPerType;
        const contentVideo = contentPerType.filter((item: ContentPerType) => item.type === "video");
        const contentComplementary = contentPerType.filter((item: ContentPerType) => item.type === "complementary");
        const contentExerciseList = contentPerType.filter((item: ContentPerType) => item.type === "exerciseList");
        const contentBooklet = contentPerType.filter((item: ContentPerType) => item.type === "booklet");

        setContentQRCode([...contentVideo, ...contentComplementary, ...contentExerciseList, ...contentBooklet]);
    }, [qrCode]);

    useEffect(() => {
        ridingStates();
    }, [ridingStates]);

    const setIcon = (type?: string) => {
        switch (type) {
            case "exerciseList":
                return <ExerciseListIcon />;
            case "booklet":
                return <ComplementIcon />;
            case "video":
                return <VideoIcon />;
            default:
                return <MonitoriaIcon />;
        }
    };

    const setText = (type?: string) => {
        switch (type) {
            case "exerciseList":
                return "Fazer lista de exercícios";
            case "booklet":
                return isProraiz ? "Livro" : "Baixar apostila";
            case "video":
                return "Assistir vídeos relacionados";
            default:
                return "Baixar material complementar";
        }
    };

    const cancelModal = useCallback(
        (id: number, type?: string) => {
            switch (type) {
                case "exerciseList":
                    history.push({
                        pathname: `/app/curso/${courseSlug}/lista-de-exercicios/${id}`
                    });
                    break;
            }
        },
        [courseSlug]
    );

    const exerciceListUrl = useCallback(
        (id: number) => {
            history.push({
                pathname: `/app/curso/${courseSlug}/qr-code/exercise-list/${id}/resolutions`
            });
        },
        [courseSlug]
    );

    const openMaterial = useCallback((url?: string) => {
        try {
            window.open(url, "_blank");
        } catch (error) {
            console.log(error);
        }
    }, []);

    const confirmModal = useCallback(
        (id: number, type?: string, url?: string) => {
            switch (type) {
                case "exerciseList":
                    exerciceListUrl(id);
                    break;

                case "booklet":
                    openMaterial(url);
                    break;

                case "complementary":
                    openMaterial(url);
                    break;

                case "video":
                    history.push({
                        pathname: `/app/curso/${courseSlug}/video/${id}`
                    });
                    break;
            }
        },
        [courseSlug, exerciceListUrl, openMaterial]
    );

    const modalExerciseList = (id: number, title?: string, type?: string, url?: string) => {
        switch (type) {
            case "exerciseList":
                dispatch(
                    modalActions.openModal("attention", {
                        type: "OPEN_MODAL",
                        hasCloseButton: false,
                        title: "Lista de Exercícios - QR Code",
                        widthContainer: "520",
                        alignText: "left",
                        text: (
                            <S.ExerciseListModalTextWrapper>
                                <S.ExerciseListModalTextIndicator />
                                <S.ExerciseListModalText>{title}</S.ExerciseListModalText>
                            </S.ExerciseListModalTextWrapper>
                        ),
                        hasMainButton: true,
                        mainButtonText: "Ver resoluções das questões",
                        hasCancelButton: true,
                        cancelButtonText: "Resolver lista de exercícios",
                        handleCancelButton: () => cancelModal(id, type),
                        handleMainButton: () => confirmModal(id, type, url)
                    })
                );
                break;

            case "booklet":
                confirmModal(id, type, url);
                break;

            case "video":
                confirmModal(id, type, url);
                break;

            case "complementary":
                confirmModal(id, type, url);
                break;
        }
    };

    return (
        <S.Container>
            <Grid>
                <Row between="md">
                    <Col xs={12}>
                        <>
                            <Header title="Conteúdo relacionado" subtitle="Confira as listas vinculadas ao QR Code." />
                            <S.Container>
                                {contentQRCode?.map((item, index) => (
                                    <WebpDropdownFull
                                        key={index}
                                        headerComposition={
                                            <S.ContentHeader>
                                                <S.Icon>{setIcon(item.type)}</S.Icon>
                                                <S.Text>{setText(item.type)}</S.Text>
                                            </S.ContentHeader>
                                        }
                                        contentComposition={
                                            <S.Content>
                                                {item.items.map((itemMaterials: Item) => (
                                                    <WebpItemMaterials
                                                        key={itemMaterials.id}
                                                        text={itemMaterials.title}
                                                        onClickItem={() =>
                                                            modalExerciseList(itemMaterials.id, itemMaterials.title, item.type, itemMaterials.url)
                                                        }
                                                    />
                                                ))}
                                            </S.Content>
                                        }
                                    ></WebpDropdownFull>
                                ))}
                            </S.Container>
                        </>
                    </Col>
                </Row>
            </Grid>
        </S.Container>
    );
};

export default DropdownMaterial;
