import styled from "styled-components/macro";
import { theme } from "config/theme";
import { TitleH3 } from "assets/styles/global";

export const SubjectScreenDetailsSelectFrontTitle = styled.h2`
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};

    ${TitleH3};
`;
