import React, { memo } from "react";

import * as S from "./styles";

import AttentionIcon from "prodigio-icons/web/filled/Attention";
import Button from "components/Button";

interface IProps {
    withResolutions: boolean;
    onClickSeeResolution(): void;
}

const ExerciseListBlockedQuestion = memo(({ withResolutions = true, onClickSeeResolution }: IProps) => {
    return (
        <S.ExerciseListBlockedQuestionContainer>
            <S.ExerciseListBlockedQuestionMessage>
                <AttentionIcon />

                <p>Questões discursivas não são contabilizadas no progresso final.</p>
            </S.ExerciseListBlockedQuestionMessage>

            {withResolutions && (
                <Button type="button" variant="info" size="small" onClick={onClickSeeResolution}>
                    Ver Resolução
                </Button>
            )}
        </S.ExerciseListBlockedQuestionContainer>
    );
});

export default ExerciseListBlockedQuestion;
