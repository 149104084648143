import { createActions, createReducer } from "reduxsauce";
import { AlertActionsType, IAlertActionsCreators, IAlertState } from "store/interfaces/IAlert";

export const { Types, Creators } = createActions<Record<AlertActionsType, AlertActionsType>, IAlertActionsCreators>({
    showAlert: ["message", "alertType", "position"],
    hideAlert: []
});

const INITIAL_STATE: IAlertState = {
    isVisible: false,
    message: "",
    alertType: "info",
    position: ["right", "bottom"]
};

const showAlert = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isVisible: true,
    message: action.message,
    alertType: action.alertType,
    position: action.position || INITIAL_STATE.position
});

const hideAlert = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.SHOW_ALERT]: showAlert,
    [Types.HIDE_ALERT]: hideAlert
});
