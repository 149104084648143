import styled, { createGlobalStyle } from "styled-components";
import { PageContainer } from "assets/styles/global";

import { Col, GridWidth } from "components/Grid";

export const ExerciseListAnimations = createGlobalStyle`
    .question-enter {
  opacity: 0.2;
}
.question-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}
.question-exit {
  opacity: 1;
}
.question-exit-active {
  opacity: 0.2;
  transition: opacity 250ms ease-in;
}
`;

export const ExerciseListContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
`;

export const ExerciseListContent = styled(PageContainer)`
    flex: 1;
    position: relative;
`;

export const ExerciseListContentContainer = styled.div`
    width: 100%;

    margin: 0 auto;
    position: relative !important;

    ${GridWidth};

    @media (min-width: 768px) {
        ${Col} {
            padding: 0 12px;
        }
    }

    @media (min-width: 1024px) {
        ${Col} {
            padding: 0;
        }

        [data-test-id="result-questions"] {
            margin-left: 8px;
        }
    }
`;
