import { AccordionButton, AccordionContainer } from "components/Accordion/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const AccordionOptionsContainer = styled.div`
    width: 100%;

    ${AccordionButton} {
        background: ${theme.colors.white};
        border-radius: 8px;
    }

    ${AccordionContainer} {
        background: ${theme.colors.white};
        box-shadow: ${theme.shadow[2]};
        margin-bottom: ${theme.spacing.small};
        border-radius: 8px;
    }
`;

export const AccordionOptionsOptions = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionOptionsOptionButton = styled.button`
    display: flex;
    border: none;
    background: transparent;
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
    padding: ${theme.spacing.smallX} ${theme.spacing.small};
    align-items: center;

    font-weight: normal;

    :hover {
        background: ${theme.colors.base[100]};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    svg {
        margin-right: ${theme.spacing.small};
    }

    :before {
        content: "";
        display: block;
        width: 20px;
        margin-right: ${theme.spacing.small};
    }
`;
