// Dependencies
import React, { useCallback, useRef, useEffect, useState, useMemo, Fragment } from "react";
import { useParams } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";

// Redux
import { Creators as weekPlansActions } from "store/ducks/weekPlans";
import { Creators as bookletModuleActions } from "store/ducks/booklet-module";
import { Creators as subjectActions } from "store/ducks/subject";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
import { IBookletModule } from "interfaces/IBookletModule";
import { ILessonPlanEducationalModule, IWeekPlanActionsCreators, IWeekProgress, IWeekModules, IWeekCourses } from "store/ducks/weekPlans/types";
import { IBookletModuleActionsCreators, IBookletContentPerType } from "store/interfaces/IBookletModule";
import { ISubjectFront, ISubjectActions, ISubjectState } from "store/interfaces/ISubject";

// Services
import api from "services/api";
import history from "services/history";

// Components
import { Col } from "components/Grid";
import Header from "./Header";
import Modules from "../../components/Subject/Modules";
import SelectFront from "components/Subject/SelectFront";
import ActiveModule from "components/Subject/ActiveModule";
import ErrorUI from "components/ErrorUI";
import Button from "components/Button";
import Portal from "components/Portal";
import SubjectScreenDetailsActiveModuleModal from "../../components/Subject/ActiveModuleModal";

// Styles
import * as S from "./styles";

// Utils
import { theme } from "config/theme";
import { BreakPoints } from "assets/styles/settings";
import { isCourseFree } from "helpers/isCourseFree";
import { hasLessonPlanCustom } from "helpers/has-lesson-plan-custom";
import useIsMobile from "hooks/use-is-mobile";
import generateColors from "helpers/generateColors";

export interface IPropsWeekPlan extends RouteComponentProps<{ idModule?: string; date?: string; idMaterial?: string }> {
    weeks: ILessonPlanEducationalModule[];
    loading: boolean;
    weekPlansActions: IWeekPlanActionsCreators;
    bookletModulesActions: IBookletModuleActionsCreators;
    subjectActions: ISubjectActions;
    currentWeek?: ILessonPlanEducationalModule;
    isLoadingWeeks: boolean;
    progress: IWeekProgress;
    isLoadingProgress: boolean;
    isLoadingProgressModule: boolean;
    modules: IWeekModules[];
    courses: IWeekCourses[];
    isLoadingCourses: boolean;
    currentModule?: IBookletModule;
    isLoadingCurrentModule: boolean;
    errorCurrentModule: boolean;
    color: any;
    isLoadingCircularProgress: boolean;
    circularProgress: IWeekProgress;
    currentLessonPlan?: IWeekCourses;
    isLoadingModule: boolean;
    weekProgress: IWeekProgress;
    isLoadingWeekPlansInfos: boolean;
    subject: ISubjectState;
    errorBookletModule: boolean;
    errorWeekPlans: boolean;
}

const WeekPlans = ({
    subject,
    weeks = [],
    weekPlansActions,
    currentWeek,
    isLoadingWeeks = false,
    isLoadingProgress = false,
    progress,
    weekProgress,
    modules,
    courses,
    isLoadingCourses = false,
    currentModule,
    isLoadingCurrentModule = false,
    errorCurrentModule = false,
    currentLessonPlan,
    isLoadingModule = false,
    isLoadingWeekPlansInfos = false,
    errorBookletModule = false,
    errorWeekPlans = false
}: IPropsWeekPlan) => {
    const isMobile = useIsMobile(BreakPoints.tablet);
    const dispatch = useDispatch();
    const { idWeek, idModule, idMaterial }: any = useParams();

    const modulesRef = useRef([]);
    const containerRef = useRef<HTMLDivElement>(null);

    const [activeModal, setActiveModal] = useState(false);

    const { content, isLoading, courseSlug, storeContentPerType, hasDispatchedClickModuleContent } = useSelector(
        ({ lessonFreeCTA, course, bookletModule }: IReduxStore) => ({
            content: lessonFreeCTA.content,
            isLoading: lessonFreeCTA.isLoading,
            courseSlug: course?.slug,
            storeContentPerType: bookletModule?.currentModule?.contentPerType,
            hasDispatchedClickModuleContent: bookletModule.hasDispatchedClickModuleContent
        })
    );

    const hasError = !!subject.error || !!errorWeekPlans || !!errorBookletModule;

    const isPromilitaresFixedNewProducts = ["esa-fx", "espcex-fx", "eear-fx"].includes(courseSlug) && ["promilitares"].includes(theme.project.slug);

    const messageProgress = isPromilitaresFixedNewProducts ? "módulos concluídos" : "módulos concluídos da semana";

    const isSlugValid = hasLessonPlanCustom(theme.project.slug, courseSlug);

    useEffect(() => {
        if (isLoading && isCourseFree(courseSlug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, courseSlug, isLoading]);

    const currentWeekWithProgress = useMemo(() => {
        return {
            ...currentWeek!,
            progress: weekProgress
        };
    }, [currentWeek, weekProgress]);

    const requestWeeks = useCallback(() => {
        if (!!currentLessonPlan?.id) {
            weekPlansActions.getWeekPlansWeeksRequest({ id: currentLessonPlan?.id });
        }
    }, [currentLessonPlan, weekPlansActions]);

    useEffect(() => {
        requestWeeks();
    }, [requestWeeks]);

    const requestModules = useCallback(() => {
        if (!!currentWeek?.id) {
            weekPlansActions.getWeekPlansModulesRequest({
                id: currentWeek?.id
            });
        }
    }, [currentWeek, weekPlansActions]);

    useEffect(() => {
        requestModules();
    }, [requestModules]);

    const requestCurrentModules = useCallback(() => {
        if (!!currentWeek?.id) {
            weekPlansActions.getWeekPlansCurrentModuleRequest({ id: currentWeek?.id });
        }
    }, [currentWeek, weekPlansActions]);

    useEffect(() => {
        requestCurrentModules();
    }, [requestCurrentModules]);

    const requestCurrentModuleProgress = useCallback(() => {
        if (!!currentWeek?.id) {
            weekPlansActions.getWeekPlansCurrentModuleProgressRequest({ id: currentWeek.id });
        }
    }, [currentWeek, weekPlansActions]);

    useEffect(() => {
        requestCurrentModuleProgress();
    }, [requestCurrentModuleProgress]);

    const requestCourses = useCallback(() => {
        weekPlansActions.getWeekPlansCoursesRequest({});
    }, [weekPlansActions]);

    useEffect(() => {
        requestCourses();
    }, [requestCourses, courseSlug]);

    useEffect(() => {
        return () => weekPlansActions.clearWeekPlans();
    }, [weekPlansActions]);

    const requestCurrentLessonPlanProgress = useCallback(() => {
        if (!!currentLessonPlan?.id) {
            weekPlansActions.getWeekPlansProgressRequest({ id: currentLessonPlan?.id });
        }
    }, [currentLessonPlan, weekPlansActions]);

    useEffect(() => {
        requestCurrentLessonPlanProgress();
    }, [requestCurrentLessonPlanProgress]);

    const handleRequestInfos = useCallback(
        (lessonPlans: ISubjectFront) => {
            weekPlansActions.setLessonPlanEducation(lessonPlans);
        },
        [weekPlansActions]
    );

    const handleChangeModule = useCallback(
        (bookletModule: IBookletModule, shouldOpenMobileContent: boolean = true, shouldPush: boolean = true) => {
            if (!bookletModule || (!isMobile && bookletModule.id === currentModule?.id)) {
                return;
            }

            const moduleIndex = modules.findIndex((item) => item.id === bookletModule.id);

            if (moduleIndex < 0) {
                return;
            }

            if (shouldOpenMobileContent && !!isMobile) {
                setActiveModal(true);
            }

            containerRef.current?.scrollTo({
                top: (modulesRef?.current[moduleIndex] as any)?.offsetTop,
                left: 0,
                behavior: "smooth"
            });

            weekPlansActions.setWeekPlansCurrentModule(bookletModule);

            if (shouldPush) {
                return history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${currentWeek?.id}/${bookletModule.id}` });
            }
        },
        [courseSlug, currentModule, currentWeek, isMobile, modules, weekPlansActions]
    );

    useEffect(() => {
        if (!!currentWeek?.id && modules.length > 0 && !currentModule?.id && !!idModule) {
            const currentModuleTmp = modules.find((module) => module.id === Number(idModule));

            if (!!currentModuleTmp) {
                handleChangeModule(currentModuleTmp as any, false, false);
            }
        }

        if (!!currentWeek?.id && modules.length > 0 && !currentModule?.id && !idModule) {
            handleChangeModule(modules[0] as any, false);
        }
    }, [currentModule, currentWeek, handleChangeModule, idModule, modules]);

    useEffect(() => {
        if (!!currentModule?.id && !!storeContentPerType && !!idMaterial && !hasDispatchedClickModuleContent) {
            const currentModuleMaterialContent = storeContentPerType.find((content) => content.type === "Material");

            const selectedMaterial = currentModuleMaterialContent?.items.find((item) => item.material?.id === Number(idMaterial));

            if (selectedMaterial) {
                dispatch(
                    bookletModuleActions.clickModuleContent({
                        ...selectedMaterial,
                        isDispatchingClickModuleContent: true
                    })
                );
            }
        }
    }, [currentModule, dispatch, hasDispatchedClickModuleContent, idMaterial, storeContentPerType]);

    const handleChangeWeek = useCallback(
        (week: ILessonPlanEducationalModule | any) => {
            if (!week) {
                return;
            }

            containerRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            weekPlansActions.setWeekPlansCurrentWeek(week);
            weekPlansActions.getWeekPlansCurrentModuleProgressRequest({ id: week.id });

            if (!!currentWeek?.id) {
                history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/s/${week.id}` });
            }
        },
        [courseSlug, currentWeek, weekPlansActions]
    );

    useEffect(() => {
        if (weeks.length > 0 && !currentWeek?.id && !!idWeek) {
            const currentWeekTmp = weeks.find((week) => week.id === Number(idWeek));

            handleChangeWeek(currentWeekTmp);
        }

        if (weeks.length > 0 && !currentWeek?.id && !idWeek) {
            const currentWeekTmp = weeks.find((week) => week.isCurrent) ?? weeks[0];

            handleChangeWeek(currentWeekTmp);
        }
    }, [requestCourses, courseSlug, weeks.length, currentWeek, idWeek, weeks, handleChangeWeek]);

    const subjectColors = useMemo(() => {
        if (!subject?.slug || !subject?.color) {
            return theme.colors.base;
        }

        return generateColors(subject?.color);
    }, [subject]);

    const handleClickSelectPlan = useCallback(async () => {
        await api.delete(`/student/lessonplanselection`);

        dispatch(lessonPlanCustomActions.clearLessonPlanSelection());
        weekPlansActions.clearWeekPlans();

        history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos` });
    }, [courseSlug, dispatch, weekPlansActions]);

    if (!!hasError) {
        return <ErrorUI />;
    }

    return (
        <Fragment>
            <S.WeekPlansContainer>
                <Header
                    color={subjectColors}
                    name={currentLessonPlan?.title ?? ""}
                    courses={courses}
                    progress={progress}
                    isLoading={isLoadingCourses}
                    isLoadingProgress={isLoadingProgress}
                    onClickInfos={handleRequestInfos}
                    isLoadingInfos={isLoadingWeekPlansInfos}
                />

                <S.WeekPlansGrid fluid>
                    <S.WeekPlansContainerBox>
                        <SelectFront
                            isMobile={isMobile}
                            isLoadingFronts={isLoadingWeeks}
                            isLoadingProgress={isLoadingProgress}
                            fronts={weeks}
                            currentFront={currentWeekWithProgress}
                            onClickFront={handleChangeWeek}
                            withFront={true}
                            subjectProgress={subject.progress}
                            messageProgress={messageProgress}
                        />
                        {isSlugValid && (
                            <Button variant="primary" size="small" style={{ marginLeft: "auto" }} onClick={handleClickSelectPlan}>
                                Modificar plano
                            </Button>
                        )}
                    </S.WeekPlansContainerBox>

                    {errorBookletModule ? (
                        <ErrorUI />
                    ) : (
                        <S.WeekPlansScroll ref={containerRef}>
                            <Col xs={12} sm={6}>
                                <Modules
                                    activeModuleId={currentModule?.id}
                                    bookletModules={modules}
                                    onClickModule={(module: any) => handleChangeModule(module, true)}
                                    ref={modulesRef}
                                    isLoading={isLoadingModule}
                                />
                            </Col>
                            {!isMobile && currentModule && (
                                <ActiveModule
                                    withBlur={false}
                                    name={currentModule.name || ""}
                                    isLoading={isLoadingCurrentModule}
                                    contentPerType={(storeContentPerType as unknown) as IBookletContentPerType[] | undefined}
                                    error={false}
                                    imageSrc={content.modulo}
                                    linkHref={content.cta}
                                />
                            )}
                        </S.WeekPlansScroll>
                    )}
                </S.WeekPlansGrid>
            </S.WeekPlansContainer>

            {isMobile && (
                <Portal portalId="active-module">
                    {!!activeModal ? (
                        <SubjectScreenDetailsActiveModuleModal
                            onClickClose={() => setActiveModal(false)}
                            contentPerType={((storeContentPerType as unknown) as IBookletContentPerType[] | undefined) || []}
                            name={currentModule?.name || ""}
                            isLoading={false}
                            error={errorCurrentModule}
                            imageSrc={content.modulo}
                            linkHref={content.cta}
                        />
                    ) : (
                        <Fragment />
                    )}
                </Portal>
            )}
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    weekPlansActions: bindActionCreators(weekPlansActions, dispatch),
    bookletModulesActions: bindActionCreators(bookletModuleActions, dispatch),
    subjectActions: bindActionCreators(subjectActions, dispatch)
});

const mapStateToProps = ({ weekPlans, subject, bookletModule }: IReduxStore) => ({
    subject,
    weeks: weekPlans.weeks,
    loading: weekPlans.loading,
    currentWeek: weekPlans.currentWeek,
    isLoadingWeeks: weekPlans.isLoadingWeeks,
    progress: weekPlans.progress,
    isLoadingProgress: weekPlans.isLoadingProgress,
    isLoadingProgressModule: weekPlans.isLoadingProgressModule,
    modules: weekPlans.modules,
    courses: weekPlans.courses,
    isLoadingCourses: weekPlans.isLoadingCourses,
    currentModule: weekPlans.currentModule,
    isLoadingCurrentModule: bookletModule.isLoadingCurrentModule,
    errorCurrentModule: weekPlans.errorCurrentModule,
    currentLessonPlan: weekPlans.currentLessonPlan,
    isLoadingModule: weekPlans.isLoadingModule,
    weekProgress: weekPlans.weekProgress,
    isLoadingWeekPlansInfos: weekPlans.isLoadingWeekPlansInfos,
    errorWeekPlans: weekPlans.error,
    errorBookletModule: bookletModule.error
});

export default connect(mapStateToProps, mapDispatchToProps)(WeekPlans as any);
