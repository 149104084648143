// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Icon } from "./icon-badge.styles";

// Types
import { IconBadgeProps } from "./icon-badge.types";

export const IconBadge: FunctionComponent<IconBadgeProps> = ({ hasAction, backgroundColor, icon, scale = "small" }) => {
    return (
        <Container hasAction={hasAction} backgroundColor={backgroundColor} scale={scale}>
            <Icon src={icon} scale={scale} />
        </Container>
    );
};
