import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";

export const EssaySliderContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 278px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 316px;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        width: 400px;
    }

    > button:last-child {
        margin-left: 5px;
    }
`;

export const EssaySliderListButtons = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    > div {
        display: flex;
    }
`;
