import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { BodyText1, BodyText2, Micro } from "assets/styles/global";

export const EmptyContainer = styled.div`
    background: ${theme.colors.base[100]};
    border-radius: ${theme.spacing.smallXX};
    padding: ${theme.spacing.smallX} ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
    }
`;

export const EmptyText = styled.p`
    ${Micro};
    color: ${theme.colors.base[400]};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
        text-align: center;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${BodyText1};
    }
`;
