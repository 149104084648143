import { Button3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export const TabRowButton = styled.button`
    ${Button3};
    color: ${theme.colors.base[300]};
    display: block;
    font-family: ${theme.typography.family.primary};
    line-height: 1;
    padding: 14px;
    text-align: center;
    transition: color 0.2s ease;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 14px 20px;
    }
`;

export const TabRowItem = styled.li<{ isActive: boolean }>`
    border-bottom: 1px solid ${theme.colors.base[300]};
    position: relative;
    white-space: nowrap;

    &:before {
        display: ${({ isActive }) => (isActive ? "inherit" : "none")};
        background: ${theme.colors.brand[300]};
        border-radius: 4px 4px 0 0;
        bottom: -4px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        transition: bottom 0.2s ease;
        width: 100%;
    }

    ${({ isActive }) => {
        if (isActive) {
            return css`
                &:before {
                    bottom: 0;
                }

                ${TabRowButton} {
                    color: ${theme.colors.brand[300]};
                    font-weight: 500;
                }
            `;
        }
    }}
`;

export const TabRowContainer = styled.ul`
    display: flex;
    width: 100%;

    ${MediaQueries.SMALL} {
        justify-content: flex-start !important;
        overflow-x: auto;
        overflow-y: hidden;

        ${TabRowItem} {
            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        justify-content: flex-start !important;
    }
`;
