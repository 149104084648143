import { IModal, IModalAction } from "interfaces/IModal";
import { createActions, createReducer } from "reduxsauce";
import { IModalActionsCreators, ModalActionsType } from "store/interfaces/IModal";

export const { Creators, Types } = createActions<Record<ModalActionsType, ModalActionsType>, IModalActionsCreators>({
    openModal: ["element", "action"],
    closeModal: []
});

const INITIAL_STATE: IModal = {
    action: {
        hasCloseButton: true,
        type: "",
        payload: {
            data: "",
            endpoint: "",
            method: "",
            redirectPath: ""
        }
    },
    element: "",
    isModalVisible: false
};

const openModal = (state = INITIAL_STATE, action: IModalAction) => ({
    ...state,
    isModalVisible: true,
    element: action.element,
    action: {
        hasCloseButton: state.action.hasCloseButton,
        ...action.action
    }
});

const closeModal = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.OPEN_MODAL]: openModal,
    [Types.CLOSE_MODAL]: closeModal
});
