// Dependencies
import React, { Fragment, FunctionComponent } from "react";

// Styles
import {
    BreadcrumbDividerIcon,
    BreadcrumbItemCompositionsWrapper,
    BreadcrumbItemContainer,
    BreadcrumbItemIcon,
    BreadcrumbItemLabel
} from "./activity-breadcrumbs.styles";

// Types
import { ActivityBreadcrumbItemProps, ActivityBreadcrumbsProps } from "./activity-breadcrumbs.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Assets
import chevronLeft from "assets/img/chevron-left.svg";

export const ActivityBreadcrumbs: FunctionComponent<ActivityBreadcrumbsProps> = ({ breadcrumbItemCompositions }) => {
    return (
        <BreadcrumbItemCompositionsWrapper>
            {breadcrumbItemCompositions.map((composition, index) => (
                <Fragment key={index}>
                    <ConditionallyRender
                        shouldRender={!!composition}
                        content={
                            <Fragment>
                                {index !== 0 && <BreadcrumbDividerIcon src={chevronLeft} />}

                                {composition}
                            </Fragment>
                        }
                    />
                </Fragment>
            ))}
        </BreadcrumbItemCompositionsWrapper>
    );
};

export const ActivityBreadcrumbItem: FunctionComponent<ActivityBreadcrumbItemProps> = ({ icon, label, isActive, size, handleClick }) => {
    return (
        <BreadcrumbItemContainer hasCursorPointer={!!handleClick} onClick={handleClick}>
            <ConditionallyRender shouldRender={!!icon} content={<BreadcrumbItemIcon size={size} src={icon} />} />

            <BreadcrumbItemLabel isActive={isActive} size={size}>
                {label}
            </BreadcrumbItemLabel>
        </BreadcrumbItemContainer>
    );
};
