// Utils
import { ternary } from "utils/ternary";

// Types
import { TeacherCreateMobileStep, ActivityType } from "store/interfaces/activity";
import { ValidationSchema } from "./activity-teacher-create.schema";

export const getMobileStepData = (mobileStep: TeacherCreateMobileStep) => {
    const mobileStepIndex = ternary([
        [mobileStep === "activity-class-data", 0],
        [mobileStep === "activity-type-data", 1],
        [mobileStep === "activity-answer-data", 2],
        [mobileStep === "activity-files-data", 3]
    ]) as number;

    const mobileStepDescription = ternary([
        [
            mobileStep === "activity-class-data",
            "Selecione uma ou mais turmas, incluindo o prazo de entrega da atividade. Você também pode de forma opcional informar uma data para liberar a atividade."
        ],
        [mobileStep === "activity-type-data", "Selecione o tipo de atividade."],
        [mobileStep === "activity-answer-data", "Preencha os campos abaixo com as informações complementares"],
        [mobileStep === "activity-files-data", "Utilize essa etapa de forma opcional, caso queira adicionar um material de apoio."]
    ]) as string;

    return { mobileStepIndex, mobileStepDescription };
};

export const getMobileStep = (mobileStep: TeacherCreateMobileStep, type: "previous" | "next") => {
    if (type === "previous") {
        return ternary<TeacherCreateMobileStep>([
            [mobileStep === "activity-type-data", "activity-class-data"],
            [mobileStep === "activity-answer-data", "activity-type-data"],
            [mobileStep === "activity-files-data", "activity-answer-data"]
        ]);
    }

    return ternary<TeacherCreateMobileStep>([
        [mobileStep === "activity-class-data", "activity-type-data"],
        [mobileStep === "activity-type-data", "activity-answer-data"],
        [mobileStep === "activity-answer-data", "activity-files-data"]
    ]);
};

export const getMobileStepError = (mobileStep: TeacherCreateMobileStep, mobileFormData: ValidationSchema, formActivityType?: ActivityType) => {
    if (mobileStep === "activity-class-data" && !mobileFormData?.mainData?.releaseDate) {
        return "mainData";
    }

    if (mobileStep === "activity-type-data" && !mobileFormData?.activityType) {
        return "activityType";
    }

    if (mobileStep === "activity-answer-data" && (!mobileFormData?.generalInformations?.title || !mobileFormData?.generalInformations?.enunciation)) {
        return "generalInformations";
    }

    if (
        mobileStep === "activity-answer-data" &&
        formActivityType === "exercise-list" &&
        (!mobileFormData?.exerciseListInformations?.questionsBankExerciseListId ||
            mobileFormData?.exerciseListInformations?.releaseResult === undefined)
    ) {
        return "exerciseListInformations";
    }

    if (
        mobileStep === "activity-answer-data" &&
        formActivityType === "send-file" &&
        !mobileFormData?.sendFileInformations?.allowedFileTypes?.length
    ) {
        return "sendFileInformations";
    }

    return undefined;
};
