import React, { useState, useMemo, useCallback, useEffect, useRef } from "react";

import history from "services/history";

import { Col, Grid, Row } from "components/Grid";
import Icon from "components/Icons/icon";
import If from "components/If";
import PageLoader from "components/PageLoader";
import { IExtraExerciseList } from "interfaces/IExerciseList";
import Select from "components/Select";

import { BackButton, SubjectTitle, TextEmpty, ExtraCardListTitle, ExtraCardListHeader, ExtraExerciseListLoadMoreButton } from "./styles";

import ExerciseCard from "./ExerciseCard";

import convertEnumToOptions from "utils/convertEnumToOptions";
import { ExtraExerciseStatus } from "enums/ExtraExerciseStatus";
import { ISelectOption } from "interfaces/ISelectOption";
import { IModalActions } from "store/interfaces/IModal";
import { IAnswerCardActions } from "store/interfaces/IActions";
import { IAnswerCard } from "store/ducks/answerCard";
import { IExerciseListActions } from "store/ducks/exerciseList/types";
import IPagination from "interfaces/IPagination";
import ErrorUI from "components/ErrorUI";

import { IReduxStore } from "interfaces/IReduxStore";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as AnswerCardActions } from "store/ducks/answerCard";
import { Creators as ExerciseListActions } from "store/ducks/exerciseList";
import { Creators as ModalActions } from "store/ducks/modal";
import { BreakPoints } from "assets/styles/settings";
import useIsMobile from "hooks/use-is-mobile";
import { IPayloadRequest } from "interfaces/IRequestAction";

interface IExtraExerciseListProps {
    subjectSlug: string;
    answerCard: IAnswerCard;
    answerCardActions: IAnswerCardActions;
    exerciseListActions: IExerciseListActions;
    isLoading: boolean;
    modalActions: IModalActions;
    subject?: {
        id: number;
        name: string;
        slug: string;
        color: string;
    };
    isMobile: boolean;
    errorAvailable: boolean;
    availables: IPagination<IExtraExerciseList>;
    onClickLoadMoreAvailable(page: number): void;
    byStatus: IPagination<IExtraExerciseList>;
    errorExtra: boolean;
    onClickLoadMoreByStatus(page: number, status?: ExtraExerciseStatus): void;
}

const exerciseStatusFilter: ISelectOption[] = [
    { value: undefined, label: "Tudo" },
    ...convertEnumToOptions(ExtraExerciseStatus).filter((status) => status.value !== ExtraExerciseStatus.Available)
];

type filterType = ISelectOption<ExtraExerciseStatus | undefined>;

const ExtraExerciseList = ({
    subjectSlug,
    answerCardActions,
    exerciseListActions,
    isLoading,
    modalActions,
    subject,
    isMobile,
    errorAvailable,
    availables,
    onClickLoadMoreAvailable,
    byStatus,
    errorExtra,
    onClickLoadMoreByStatus
}: IExtraExerciseListProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [filter, setFilter] = useState<filterType>(exerciseStatusFilter[0]);

    const topOfAllList = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (byStatus?.page <= 1) {
            return;
        }

        setTimeout(() => {
            topOfAllList?.current?.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "start"
            });
        }, 200);
    }, [byStatus]);

    const handleChangeFilter = useCallback(
        (newFilter: filterType) => {
            setFilter(newFilter);
            onClickLoadMoreByStatus(1, newFilter?.value);
        },
        [onClickLoadMoreByStatus]
    );

    const empty = useMemo(
        () => (
            <Col xs={12}>
                <TextEmpty>Não há listas disponíveis até o momento. </TextEmpty>
            </Col>
        ),
        []
    );

    const listAvailables = useMemo(() => {
        if (errorAvailable) {
            return <ErrorUI />;
        }

        if (!availables?.items?.length) {
            return empty;
        }

        return availables?.items.map((exerciseList) => {
            return (
                <Col key={exerciseList.id} xs={12} sm={6}>
                    <ExerciseCard
                        exerciseList={exerciseList}
                        modalActions={modalActions}
                        exerciseListActions={exerciseListActions}
                        answerCardActions={answerCardActions}
                        subjectSlug={subjectSlug}
                    />
                </Col>
            );
        });
    }, [answerCardActions, availables, empty, errorAvailable, exerciseListActions, modalActions, subjectSlug]);

    const listByStatus = useMemo(() => {
        if (errorExtra) {
            return <ErrorUI />;
        }

        if (!byStatus?.items?.length) {
            return empty;
        }

        return byStatus?.items?.map((exerciseList) => {
            return (
                <Col xs={12} sm={6} key={exerciseList.id}>
                    <ExerciseCard
                        exerciseList={exerciseList}
                        modalActions={modalActions}
                        exerciseListActions={exerciseListActions}
                        answerCardActions={answerCardActions}
                        subjectSlug={subjectSlug}
                    />
                </Col>
            );
        });
    }, [errorExtra, byStatus, empty, modalActions, exerciseListActions, answerCardActions, subjectSlug]);

    return (
        <Grid>
            <If test={isLoading}>
                <PageLoader />
            </If>
            <If test={isMobile}>
                <BackButton onClick={() => history.push(`/app/curso/${courseSlug}/listas-extras`)}>
                    <Icon idIcon="caret-left-2" width="8" height="14" viewBox="0 0 8 14" />
                </BackButton>
                {!!subject && <SubjectTitle subjectColor={subject?.color}>{subject?.name}</SubjectTitle>}
            </If>
            <ExtraCardListHeader>
                <ExtraCardListTitle>Disponíveis</ExtraCardListTitle>
            </ExtraCardListHeader>

            <Row>
                {listAvailables}

                {availables?.totalPages > availables?.page && (
                    <ExtraExerciseListLoadMoreButton onClick={() => onClickLoadMoreAvailable(availables?.page + 1)}>
                        Carregar mais
                    </ExtraExerciseListLoadMoreButton>
                )}
            </Row>

            <ExtraCardListHeader ref={topOfAllList}>
                <ExtraCardListTitle>Todas as listas</ExtraCardListTitle>
                <Select options={exerciseStatusFilter} selected={filter} onChange={handleChangeFilter} />
            </ExtraCardListHeader>
            <Row>
                {listByStatus}

                {byStatus?.totalPages > byStatus?.page && (
                    <ExtraExerciseListLoadMoreButton onClick={() => onClickLoadMoreByStatus(byStatus?.page + 1, filter?.value)}>
                        Carregar mais
                    </ExtraExerciseListLoadMoreButton>
                )}
            </Row>
        </Grid>
    );
};

interface IExtraExerciseListBySubjectWrapperProps {
    exerciseListActions: IExerciseListActions;
    answerCard: any;
    answerCardActions: IAnswerCardActions;
    match: any;
    isLoading: boolean;
    modalActions: IModalActions;
    subject?: {
        id: number;
        name: string;
        slug: string;
        color: string;
    };
    isLoadingAvailable: boolean;
    errorAvailable: boolean;
    availables: IPagination<IExtraExerciseList>;
    byStatus: IPagination<IExtraExerciseList>;
    errorExtra: boolean;
}

const ExtraExerciseListBySubjectWrapper = ({
    exerciseListActions,
    answerCard,
    answerCardActions,
    match,
    isLoading,
    modalActions,
    subject,
    errorAvailable,
    availables,
    byStatus,
    errorExtra
}: IExtraExerciseListBySubjectWrapperProps) => {
    const { slug } = match.params;

    useEffect(() => {
        return () => {
            exerciseListActions.clearExerciseList();
        };
    }, [exerciseListActions]);

    const isMobile = useIsMobile(BreakPoints.small);

    const requestSubject = useCallback(() => {
        if (!slug || !isMobile) {
            return;
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/subject/${slug}`
        };

        exerciseListActions.getExerciseListSubjectRequest(payload);
    }, [exerciseListActions, isMobile, slug]);

    useEffect(() => {
        requestSubject();
    }, [requestSubject]);

    const requestAvailableLists = useCallback(
        (page: number = 1) => {
            if (!slug) {
                return;
            }

            exerciseListActions.getAvailableExtraExerciseListsBySlugRequest({ page, subjectSlug: slug });
        },
        [exerciseListActions, slug]
    );

    useEffect(() => {
        requestAvailableLists();
    }, [requestAvailableLists]);

    const requestListsByStatus = useCallback(
        (page: number = 1, status?: ExtraExerciseStatus) => {
            if (!slug) {
                return;
            }

            exerciseListActions.getExtraExerciseListByStatusRequest({ page, subjectSlug: slug, status });
        },
        [exerciseListActions, slug]
    );

    useEffect(() => {
        requestListsByStatus();
    }, [requestListsByStatus]);

    return (
        <ExtraExerciseList
            exerciseListActions={exerciseListActions}
            subjectSlug={slug}
            answerCard={answerCard}
            answerCardActions={answerCardActions}
            isLoading={isLoading}
            modalActions={modalActions}
            subject={subject}
            isMobile={isMobile}
            errorAvailable={errorAvailable}
            availables={availables}
            onClickLoadMoreAvailable={requestAvailableLists}
            byStatus={byStatus}
            errorExtra={errorExtra}
            onClickLoadMoreByStatus={requestListsByStatus}
        />
    );
};

const mapStateToProps = ({ exerciseList, course, answerCard }: IReduxStore) => ({
    answerCard,
    isLoading: exerciseList.isLoading || exerciseList.isLoadingAvailableExtra || exerciseList.isLoadingExtra,
    courseId: course.id,
    subject: exerciseList.subject,
    errorAvailable: exerciseList.errorAvailableExtra,
    availables: exerciseList.availableExtras,
    byStatus: exerciseList.extraListByStatus,
    errorExtra: exerciseList.errorExtra
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exerciseListActions: bindActionCreators(ExerciseListActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch) as any,
    answerCardActions: bindActionCreators(AnswerCardActions, dispatch) as any
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ExtraExerciseListBySubjectWrapper);
