const dataMock = {
    days: [
        {
            labelDay: "Seg",
            buttonIcon: [
                {
                    id: 1,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 2,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 3,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Ter",
            buttonIcon: [
                {
                    id: 4,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 5,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 6,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Qua",
            buttonIcon: [
                {
                    id: 7,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 8,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 9,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Qui",
            buttonIcon: [
                {
                    id: 10,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 11,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 12,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Sex",
            buttonIcon: [
                {
                    id: 13,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 14,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 15,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Sáb",
            buttonIcon: [
                {
                    id: 16,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 17,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 18,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        },
        {
            labelDay: "Dom",
            buttonIcon: [
                {
                    id: 19,
                    label: "manhã",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 20,
                    label: "tarde",
                    setHour: 4,
                    selected: false
                },
                {
                    id: 21,
                    label: "noite",
                    setHour: 4,
                    selected: false
                }
            ]
        }
    ]
};

export default dataMock;
