import styled from "styled-components";
import { StudentAreaScreenSignedCoursesCardDescription } from "../SignedCoursesCard/styles";
import { theme } from "config/theme";
import { ButtonWrapper } from "components/Button/styles";

export const StudentAreaScreenSignedCoursesCardCreditsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.smallX};

    ${StudentAreaScreenSignedCoursesCardDescription} {
        padding-bottom: ${theme.spacing.smallX};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }
`;

export const StudentAreaScreenSignedCoursesCardCredit = styled.div`
    display: flex;
    align-self: stretch;
    margin-bottom: ${theme.spacing.small};

    span {
        text-align: center;
        width: 20px;
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.brand[300]};
        margin-right: ${theme.spacing.smallX};
        font-weight: 600;
    }

    p {
        flex: 1;
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    ${ButtonWrapper} {
        padding: 0;
        border: none;

        :hover {
            background: transparent;
        }

        svg {
            margin-left: ${theme.spacing.smallX};
        }
    }
`;
