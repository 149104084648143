import React, { memo, useCallback, useMemo } from "react";

// Dependencies
import CloseIcon from "prodigio-icons/web/outlined/Close";
import FileIcon from "prodigio-icons/web/filled/TemplateSheet";

// Components
import Button from "components/Button";

// Helpers
import { DropzoneInputProps } from "react-dropzone";

// Assets
import { UploaderContainer, UploaderFileName, UploaderHelper, UploaderTxt, CloseButton, InputWrapper } from "./styles";

interface IUploaderProps {
    files: File[];
    dropzoneProps?: DropzoneInputProps;
    onSend(files: File[]): void;
    onClearFiles(): void;
}

const Uploader = ({ dropzoneProps, files, onClearFiles, onSend }: IUploaderProps) => {
    const handleSend = useCallback(() => onSend(files), [files, onSend]);

    const UploaderState = useMemo(() => {
        if (files[0]) {
            return (
                <>
                    <CloseButton type="button" onClick={onClearFiles}>
                        <CloseIcon width={16} height={16} />
                    </CloseButton>
                    <FileIcon width={23} height={32} />
                    {files.map((file) => (
                        <UploaderFileName key={file.lastModified}>{file.name}</UploaderFileName>
                    ))}
                    <Button variant="info" block={"true"} size="medium" onClick={handleSend}>
                        Salvar arquivo
                    </Button>
                </>
            );
        }

        return (
            <>
                <InputWrapper>
                    <input {...dropzoneProps} />
                    <Button variant="outline-info" block={"true"} size="medium">
                        Selecione um arquivo
                    </Button>
                </InputWrapper>
                <UploaderHelper>ou arraste para cá</UploaderHelper>
                <UploaderTxt>Extensões permitidas: (.jpg, .jpeg, .png)</UploaderTxt>
            </>
        );
    }, [dropzoneProps, files, handleSend, onClearFiles]);

    return <UploaderContainer>{UploaderState}</UploaderContainer>;
};

export default memo(Uploader);
