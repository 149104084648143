// Dependencies
import styled, { css, keyframes } from "styled-components";

const SkeletonAnimation = keyframes`
    from {
        background:  rgba(0, 0, 0, 0.04);
    }

    to {
        background: rgba(0, 0, 0, 0.08);
    }
`;

export type ContainerStyleProps = {
    height: string;
    width: string;
    borderRadius?: string;
    shouldForceWidth?: boolean;
};

export const Container = styled.span<ContainerStyleProps>`
    display: flex;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background: rgba(0, 0, 0, 0.04);
    animation: ${SkeletonAnimation} 0.5s alternate infinite;

    ${({ borderRadius }) =>
        !!borderRadius &&
        css`
            border-radius: ${borderRadius};
        `}

    ${({ shouldForceWidth }) =>
        !!shouldForceWidth &&
        css`
            flex-shrink: 0;
        `}
`;
