// Dependencies
import styled, { css } from "styled-components/macro";

export const PerformanceTopicWrapper = styled.div`
    width: 100%;

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const PerformanceTopicInfoWrapper = styled.div`
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }
`;

export type PerformanceTopicPositionWrapperStyleProps = {
    backgroundColor: string;
};

export const PerformanceTopicPositionWrapper = styled.div<PerformanceTopicPositionWrapperStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 30px;
    height: 30px;
    padding: 4px;

    border-radius: 4px;

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;

export const PerformanceTopicNumber = styled.p<{ color: string }>`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`;

export const PerformanceTopicName = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #505462;
`;

export const PerformanceTopicContentWrapper = styled.div`
    @media (min-width: 768px) {
        display: flex;
        align-items: center;
    }
`;

export const PerformanceTopicQuestionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 0px;
`;

export const PerformanceTopicQuestion = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505462;

    white-space: nowrap;
`;

export const PerformanceTopicQuestionDivider = styled.div`
    width: 0px;
    height: 16px;
    border: 1px solid #ced0dd;
`;

export const PerformanceTopicAssertivenessWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
`;

export type PerformanceTopicAssertivenessIndicatorStyleProps = {
    color: string;
};

export const PerformanceTopicAssertivenessIndicator = styled.div<PerformanceTopicAssertivenessIndicatorStyleProps>`
    width: 4px;
    height: 16px;

    border-radius: 8px;

    ${({ color }) =>
        color &&
        css`
            background-color: ${color};
        `}
`;

export const PerformanceTopicAssertiveness = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #3a3e47;

    white-space: nowrap;
`;

export const Border = styled.div`
    width: 100%;
    height: 0;
    border: 1px solid #ced0dd;
`;

// ----------

export const PerformanceTopicNameSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;
`;

export const PerformanceTopicQuestionSkeleton = styled.div`
    height: 20px;
    padding: 3px 0;
`;

export const PerformanceTopicAssertivenessSkeleton = styled.div`
    height: 22px;
    padding: 4px 0;
`;
