import React, { useCallback, useEffect, useMemo, useState } from "react";

// Assets
import { ExerciseListDashboardLoadingAnswerCards, ExerciseListDashboardAnswerCardResultMore } from "./styles";

// Components
import { Col } from "components/Grid";
import PageLoader from "components/PageLoader";
import Button from "components/Button";
import PreviousExamDashboard from "./previousExam";
import ExerciseListDashboard from "./exerciseList";
import ExerciseListDashboardAnswerCardResult from "components/AnswerCardResult";
import Spinner from "components/Spinner";

// Helpers
import { IModalActions } from "store/interfaces/IModal";
import { RouteComponentProps } from "react-router-dom";
import { IExerciseListState } from "interfaces/IExerciseList";
import { IAnswerCardActions } from "store/interfaces/IActions";
import { DateTime } from "luxon";
import { ExerciseListType } from "enums/ExerciseList";
import api from "services/api";
import { theme } from "config/theme";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import IPagination from "interfaces/IPagination";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { IAnswerCard } from "store/ducks/answerCard";
import { paginationInitialData } from "utils/paginationInitialData";
import { IExerciseListActions } from "store/ducks/exerciseList/types";

// Redux
import { Creators as alertActions } from "store/ducks/alert";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as ModalActions } from "store/ducks/modal";
import { Creators as ExerciseListActions } from "store/ducks/exerciseList";
import { Creators as AnswerCardActions } from "store/ducks/answerCard";
import { IReduxStore } from "interfaces/IReduxStore";

interface IRouteParams {
    examId: string;
    examDayId: string;
    id: string;
    subjectSlug: string;
    moduleId?: string;
    projectId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    modalActions: IModalActions;
    exerciseList: IExerciseListState;
    exerciseListActions: IExerciseListActions;
    zoneName: string;
    results: IPagination<IAnswerCard>;
    answerCardActions: IAnswerCardActions;
    isloadingResults: boolean;
    subject?: {
        id: number;
        name: string;
        slug: string;
        color: string;
    };
    exam?: {
        id: number;
        name: string;
        slug: string;
    };
}

export interface IChartValues {
    date: string;
    totalCorrect: number;
    totalWrong: number;
}

/**
 *
 *   Esse componente retorna o dashboard da Lista de Exercicios
 *
 */
const ExerciseListDashboardScreen = ({
    exerciseList,
    exerciseListActions,
    match,
    modalActions,
    zoneName,
    results = paginationInitialData,
    answerCardActions,
    isloadingResults,
    subject,
    exam
}: IProps) => {
    const { id, subjectSlug, moduleId, examId, examDayId, projectId } = match.params;
    const [project, setProject] = useState<{ name: string; id: number }>();
    const [unfinishedUserLists, setUnfinishedUserLists] = useState<any[]>([]);
    const [disableButtonPDF, setDisableButtonPDF] = useState(false);
    const dispatch = useDispatch();

    const {
        title,
        questionsTotal,
        type = ExerciseListType.Subject,
        isLoading,
        blockContent = false,
        realizationsAverageHitRate = 0,
        realizationsTotal = 0,
        isLoadingSubject
    } = exerciseList;

    const isPreviousExam = useMemo(() => !!examDayId && !!examId, [examDayId, examId]);

    // ###############
    // REQUEST ACTIONS
    // ###############

    useEffect(() => {
        answerCardActions.clearAnswerCardResults();
    }, [answerCardActions]);

    useEffect(() => {
        return () => {
            answerCardActions.clearAnswerCardResults();
        };
    }, [answerCardActions]);

    useEffect(() => {
        return () => {
            exerciseListActions.clearExerciseList();
        };
    }, [exerciseListActions]);

    const isExtra = useMemo(() => type === ExerciseListType.Extra, [type]);

    const requestSubject = useCallback(() => {
        if (!subjectSlug) {
            return;
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/subject/${subjectSlug}`
        };

        exerciseListActions.getExerciseListSubjectRequest(payload);
    }, [exerciseListActions, subjectSlug]);

    useEffect(() => {
        requestSubject();
    }, [requestSubject]);

    const requestPreviousExam = useCallback(async () => {
        if (!examId) {
            return;
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/exam/${examId}/description`
        };

        exerciseListActions.getExerciseListExamRequest(payload);
    }, [examId, exerciseListActions]);

    useEffect(() => {
        requestPreviousExam();
    }, [requestPreviousExam]);

    const requestProject = useCallback(async () => {
        try {
            if (!projectId) {
                return;
            }

            const { data } = await api.get(`/student/project/${projectId}/detail`);

            if (!data) {
                throw new Error("projectId not found");
            }

            setProject({
                name: data.name,
                id: data.id
            });
        } catch (error) {
            console.log(error);
        }
    }, [projectId]);

    useEffect(() => {
        requestProject();
    }, [requestProject]);

    const requestAnswerCard = useCallback(
        (page: number) => {
            if (!exerciseList.id || blockContent) {
                return;
            }

            const payload: IPayloadRequest = {
                method: "GET",
                endpoint: `/student/exerciselist/${exerciseList.id}/answercard`,
                headers: {
                    "x-page": page || 1
                }
            };

            answerCardActions.getResultCardsRequest(payload);
        },
        [blockContent, exerciseList.id, answerCardActions]
    );

    useEffect(() => {
        requestAnswerCard(1);
    }, [requestAnswerCard]);

    const requestExerciseList = useCallback(() => {
        if (!id) {
            return;
        }

        exerciseListActions.getExerciseListRequest(id);
    }, [id, exerciseListActions]);

    useEffect(() => requestExerciseList(), [requestExerciseList]);

    const requestExamDay = useCallback(() => {
        if (!examDayId) {
            return;
        }

        exerciseListActions.getExerciseListByExamDayRequest({ examDayId });
    }, [examDayId, exerciseListActions]);

    useEffect(() => requestExamDay(), [requestExamDay]);

    // ###############
    // HELPERS VALUES
    // ###############

    const userRealizations: IAnswerCard[] = useMemo(() => {
        try {
            if (!results.items || !results.items.length) {
                throw new Error();
            }

            const formatRealizations = results.items
                .filter(
                    (realization) =>
                        (!!realization.endDate && !!DateTime.fromISO(realization.endDate).isValid) || realization.status >= AnswerCardStatus.Done
                )
                .map((realization) => ({
                    ...realization,
                    endDate: DateTime.fromISO(realization.endDate!)
                        .setZone(zoneName || "UTC")
                        .toString()
                }));

            const unfinishedUserLists = results.items.filter(
                (realization) =>
                    (!!realization.endDate && !DateTime.fromISO(realization.endDate).isValid) || realization.status < AnswerCardStatus.Done
            );

            setUnfinishedUserLists(unfinishedUserLists);

            return formatRealizations;
        } catch (error) {
            return [];
        }
    }, [results.items, zoneName]);

    const hasRealizations = useMemo(() => !!userRealizations && !!userRealizations.length, [userRealizations]);

    const handleClickStartList = useCallback(
        () =>
            modalActions.openModal("startExerciseList", {
                hasRealizations,
                exerciseListId: id || exerciseList.id,
                subjectSlug: subjectSlug,
                examId,
                examDayId,
                projectId,
                moduleId
            }),
        [modalActions, hasRealizations, id, exerciseList.id, subjectSlug, examId, examDayId, projectId, moduleId]
    );

    const handleClickdownloadPDF = useCallback(async () => {
        try {
            setDisableButtonPDF(true);

            await api
                .get(`/student/exerciselist/${id || exerciseList.id}/report`, {
                    responseType: "blob"
                })
                .then((blob) => {
                    new File([blob.data], "file");

                    const url = window.URL.createObjectURL(blob.data);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${subjectSlug}-${title}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });

            setDisableButtonPDF(false);
        } catch (error) {
            console.log(error);
            dispatch(alertActions.showAlert("Ocorreu um erro ao imprimir a lista", "danger"));
            setDisableButtonPDF(false);
        }
    }, [dispatch, exerciseList.id, id, subjectSlug, title]);

    const memoListAnswerCards = useMemo(() => {
        try {
            if ((!id && !examDayId) || blockContent) {
                throw new Error();
            }

            if (isloadingResults && !results.page) {
                return (
                    <Col xs={12} style={{ marginBottom: theme.spacing.small }}>
                        <ExerciseListDashboardLoadingAnswerCards>
                            <Spinner fixed={false} size={25} />
                        </ExerciseListDashboardLoadingAnswerCards>
                    </Col>
                );
            }

            if (!results?.items?.length) {
                throw new Error();
            }

            return (
                <>
                    {results.items.map((answerCard) => (
                        <Col xs={12} sm={6} md={4} key={answerCard.id} data-test-id="answer-card-list">
                            <ExerciseListDashboardAnswerCardResult
                                subjectSlug={subjectSlug}
                                moduleId={moduleId}
                                exerciseListId={Number(id)}
                                answerCard={answerCard}
                                isExtra={isExtra}
                                examId={examId}
                                examDayId={examDayId}
                                questionsTotal={questionsTotal}
                                projectId={projectId}
                            />
                        </Col>
                    ))}

                    {results.totalPages > results.page && (
                        <ExerciseListDashboardAnswerCardResultMore>
                            <Button variant="info" size="medium" onClick={() => requestAnswerCard(results.page + 1)} isLoading={isloadingResults}>
                                Carregar mais
                            </Button>
                        </ExerciseListDashboardAnswerCardResultMore>
                    )}
                </>
            );
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [
        id,
        examDayId,
        blockContent,
        isloadingResults,
        results,
        subjectSlug,
        moduleId,
        isExtra,
        examId,
        questionsTotal,
        projectId,
        requestAnswerCard
    ]);

    return (
        <>
            {(isLoading || isLoadingSubject) && <PageLoader />}

            {isPreviousExam ? (
                <PreviousExamDashboard
                    isLoading={isLoading}
                    onClickStart={handleClickStartList}
                    blockContent={blockContent}
                    previousExam={exam}
                    hasRealizations={hasRealizations}
                    questionsTotal={questionsTotal}
                    previousExamDay={{ id: +examDayId, title }}
                    userRealizations={userRealizations}
                    listAnswerCards={memoListAnswerCards}
                    realizationsAverageHitRate={realizationsAverageHitRate}
                    onClickdownloadPDF={handleClickdownloadPDF}
                    disableButtonPDF={disableButtonPDF}
                />
            ) : (
                <ExerciseListDashboard
                    isLoading={isLoading}
                    onClickStart={handleClickStartList}
                    listAnswerCards={memoListAnswerCards}
                    blockContent={blockContent}
                    isExtra={isExtra}
                    questionsTotal={questionsTotal}
                    hasRealizations={hasRealizations}
                    title={title}
                    realizationsTotal={realizationsTotal}
                    userRealizations={userRealizations}
                    realizationsAverageHitRate={realizationsAverageHitRate}
                    unfinishedUserLists={unfinishedUserLists}
                    subject={subject}
                    project={project}
                    onClickdownloadPDF={handleClickdownloadPDF}
                    disableButtonPDF={disableButtonPDF}
                />
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch),
    exerciseListActions: bindActionCreators(ExerciseListActions, dispatch),
    answerCardActions: bindActionCreators(AnswerCardActions, dispatch)
});

const mapStateToProps = ({ exerciseList, answerCard, credentials }: IReduxStore) => ({
    exerciseList,
    zoneName: credentials.timezone.zoneName.trim(),
    results: answerCard.results,
    isloadingResults: answerCard.isLoading,
    subject: exerciseList.subject,
    exam: exerciseList.exam
});

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseListDashboardScreen as any);
