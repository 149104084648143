import { CardContainer } from "components/Card/styles";
import { theme } from "config/theme";
import styled from "styled-components";
import { ExamResultFeedbackContainer } from "../Feedback/styles";

export const ExamResultFeedBackListContainer = styled(CardContainer)`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    ${ExamResultFeedbackContainer} {
        :not(:last-child) {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;
