import { createActions, createReducer } from "reduxsauce";
import {
    IWeekPlanState,
    WeekPlanType,
    IWeekPlanActionsCreators,
    ILessonPlanEducationalModule,
    IWeekProgress,
    IWeekModules,
    IWeekCourses
} from "./types";
import { ReduxAction } from "store";
import { IBookletModule } from "interfaces/IBookletModule";

export const { Creators, Types } = createActions<Record<WeekPlanType, WeekPlanType>, IWeekPlanActionsCreators>({
    // Weeks

    getWeekPlansWeeksRequest: ["payload"],
    getWeekPlansWeeksSuccess: ["payload"],
    getWeekPlansWeeksFailure: [""],

    // Progress

    getWeekPlansProgressRequest: ["payload"],
    getWeekPlansProgressSuccess: ["payload"],
    getWeekPlansProgressFailure: [""],

    // CurrentModuleProgress

    getWeekPlansCurrentModuleProgressRequest: ["payload"],
    getWeekPlansCurrentModuleProgressSuccess: ["payload"],
    getWeekPlansCurrentModuleProgressFailure: [""],

    // Modules

    getWeekPlansModulesRequest: ["payload"],
    getWeekPlansModulesSuccess: ["payload"],
    getWeekPlansModulesFailure: [""],

    // CurrentModules

    getWeekPlansCurrentModuleRequest: ["payload"],
    getWeekPlansCurrentModuleSuccess: ["payload"],
    getWeekPlansCurrentModuleFailure: [""],
    setWeekPlansCurrentModule: ["payload"],

    // Courses

    getWeekPlansCoursesRequest: ["payload"],
    getWeekPlansCoursesSuccess: ["payload"],
    getWeekPlansCoursesFailure: [""],

    setLessonPlanEducation: ["payload"],
    setWeekPlansCurrentWeek: ["payload"],
    clearWeekPlans: [],

    // Request Lesson Plan Info

    getWeekPlansInfosRequest: ["payload"],
    getWeekPlansInfosSuccess: ["payload"],
    getWeekPlansInfosFailure: [""],

    // Menu Navigation

    getWeekPlansIsPublishedRequest: ["payload"],
    getWeekPlansIsPublishedSuccess: ["payload"],
    getWeekPlansIsPublishedFailure: [""]
});

export const INITIAL_STATE: IWeekPlanState = {
    isLoadingWeeks: false,
    isLoadingCourses: false,
    isLoadingProgress: false,
    isLoadingProgressModule: false,
    isLoadingCurrentModule: true,
    isLoadingWeekPlansInfos: true,
    isLoadingModule: false,
    loading: false,
    error: false,
    errorCurrentModule: false,
    weeks: [],
    modules: [],
    courses: [],
    items: [],
    currentWeek: undefined,
    currentModule: undefined,
    weekProgress: {
        completedItems: 0,
        percent: 0,
        totalItems: 0
    },
    progress: {
        completedItems: 0,
        percent: 0,
        totalItems: 0
    },
    id: 0,
    name: "",
    order: 0,
    slug: ""
};

// GENERIC ACTIONS

// const request = (state = INITIAL_STATE) => ({
//     ...state,
//     loading: true
// });

// const success = (state = INITIAL_STATE, action: any) => ({
//     ...state,
//     ...action.payload,
//     loading: false
// });

// const failure = (state = INITIAL_STATE) => ({
//     ...state,
//     loading: false,
//     error: true
// });

// Weeks

const getWeeksRequest = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    weeks: [],
    isLoadingWeeks: true,
    error: false
});

const getWeeksSuccess = (state = INITIAL_STATE, action: ReduxAction<ILessonPlanEducationalModule[]>) => ({
    ...state,
    weeks: action?.payload || [],
    isLoadingWeeks: false,
    error: false
});

const getWeeksFailure = (state = INITIAL_STATE) => ({
    ...state,
    weeks: [],
    isLoadingWeeks: false,
    error: true
});

const setCurrentWeek = (state = INITIAL_STATE, action: ReduxAction<ILessonPlanEducationalModule>) => ({
    ...state,
    currentWeek: action.payload,
    currentModule: undefined,
    modules: []
});

const clear = (state = INITIAL_STATE) => ({ ...INITIAL_STATE, isPublished: state?.isPublished });

// Progress

const getProgressRequest = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    isLoadingProgress: true
});

const getProgressSuccess = (state = INITIAL_STATE, action: ReduxAction<IWeekProgress>) => ({
    ...state,
    progress: action?.payload || INITIAL_STATE.progress,
    isLoadingProgress: false
});

const getProgressFailure = (state = INITIAL_STATE) => ({
    ...state,
    progress: INITIAL_STATE.progress,
    isLoadingProgress: false,
    error: true
});

// CurrentModuleProgress

const getCurrentModuleProgressRequest = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    isLoadingProgressModule: true
});

const getCurrentModuleProgressSuccess = (state = INITIAL_STATE, action: ReduxAction<IWeekProgress>) => ({
    ...state,
    weekProgress: action?.payload || INITIAL_STATE.weekProgress,
    isLoadingProgressModule: false
});

const getCurrentModuleProgressFailure = (state = INITIAL_STATE) => ({
    ...state,
    weekProgress: INITIAL_STATE.weekProgress,
    isLoadingProgressModule: false,
    error: true
});

// Modules

const getModulesRequest = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    isLoadingModule: true,
    error: false
});

const getModulesSuccess = (state = INITIAL_STATE, action: ReduxAction<IWeekModules[]>) => ({
    ...state,
    modules: action?.payload || INITIAL_STATE.modules,
    isLoadingModule: false
});

const getModulesFailure = (state = INITIAL_STATE) => ({
    ...state,
    modules: INITIAL_STATE.modules,
    isLoadingModule: false,
    error: true
});

const setLessonPlanEducation = (state = INITIAL_STATE, action: ReduxAction<IWeekCourses | undefined>): IWeekPlanState => ({
    ...state,
    currentLessonPlan: action.payload
});

// CurrentModules

const getCurrentModuleRequest = (state = INITIAL_STATE, action: ReduxAction<IBookletModule>) => ({
    ...state,
    isLoadingCurrentModule: true,
    errorCurrentModule: false
});

const getCurrentModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentModule: false,
    errorCurrentModule: false,
    currentModule: action.payload
});

const getCurrentModuleFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingCurrentModule: false,
    currentModule: undefined,
    errorCurrentModule: true
});

const setCurrentModule = (state = INITIAL_STATE, action: any) => ({
    ...state,
    currentModule: action.payload,
    isLoadingModule: false
});

// Courses

const getCoursesRequest = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    isLoadingCourses: true
});

const getCoursesSuccess = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    courses: action?.payload || INITIAL_STATE.courses,
    isLoadingCourses: false
});

const getCoursesFailure = (state = INITIAL_STATE, action: ReduxAction<any>) => ({
    ...state,
    courses: INITIAL_STATE.courses,
    isLoadingCourses: false,
    error: true
});

// Lesson Plan Infos

const getInfosPlanWeekRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingWeekPlansInfos: true
});

const getInfosPlanWeekSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingWeekPlansInfos: false,
    infos: {
        ...state.infos,
        ...action.payload
    }
});

const getInfosPlanWeekFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingWeekPlansInfos: false
});

// Menu Navigation

const getWeekPlansIsPublishedRequest = (state = INITIAL_STATE, action: any) => ({
    ...state
});

const getWeekPlansIsPublishedSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isPublished: action.payload
});

export default createReducer(INITIAL_STATE, {
    // Weeks

    [Types.GET_WEEK_PLANS_WEEKS_REQUEST]: getWeeksRequest,
    [Types.GET_WEEK_PLANS_WEEKS_SUCCESS]: getWeeksSuccess,
    [Types.GET_WEEK_PLANS_WEEKS_FAILURE]: getWeeksFailure,
    [Types.SET_WEEK_PLANS_CURRENT_WEEK]: setCurrentWeek,
    [Types.CLEAR_WEEK_PLANS]: clear,

    // Progress

    [Types.GET_WEEK_PLANS_PROGRESS_REQUEST]: getProgressRequest,
    [Types.GET_WEEK_PLANS_PROGRESS_SUCCESS]: getProgressSuccess,
    [Types.GET_WEEK_PLANS_PROGRESS_FAILURE]: getProgressFailure,

    // CurrentModuleProgressRequest

    [Types.GET_WEEK_PLANS_CURRENT_MODULE_PROGRESS_REQUEST]: getCurrentModuleProgressRequest,
    [Types.GET_WEEK_PLANS_CURRENT_MODULE_PROGRESS_SUCCESS]: getCurrentModuleProgressSuccess,
    [Types.GET_WEEK_PLANS_CURRENT_MODULE_PROGRESS_FAILURE]: getCurrentModuleProgressFailure,

    // Modules

    [Types.GET_WEEK_PLANS_MODULES_REQUEST]: getModulesRequest,
    [Types.GET_WEEK_PLANS_MODULES_SUCCESS]: getModulesSuccess,
    [Types.GET_WEEK_PLANS_MODULES_FAILURE]: getModulesFailure,

    // CurrentModules

    [Types.GET_WEEK_PLANS_CURRENT_MODULE_REQUEST]: getCurrentModuleRequest,
    [Types.GET_WEEK_PLANS_CURRENT_MODULE_SUCCESS]: getCurrentModuleSuccess,
    [Types.GET_WEEK_PLANS_CURRENT_MODULE_FAILURE]: getCurrentModuleFailure,
    [Types.SET_WEEK_PLANS_CURRENT_MODULE]: setCurrentModule,

    // Courses

    [Types.GET_WEEK_PLANS_COURSES_REQUEST]: getCoursesRequest,
    [Types.GET_WEEK_PLANS_COURSES_SUCCESS]: getCoursesSuccess,
    [Types.GET_WEEK_PLANS_COURSES_FAILURE]: getCoursesFailure,

    // Lesson Plan Infos

    [Types.GET_WEEK_PLANS_INFOS_REQUEST]: getInfosPlanWeekRequest,
    [Types.GET_WEEK_PLANS_INFOS_SUCCESS]: getInfosPlanWeekSuccess,
    [Types.GET_WEEK_PLANS_INFOS_FAILURE]: getInfosPlanWeekFailure,
    [Types.SET_LESSON_PLAN_EDUCATION]: setLessonPlanEducation,

    // Menu Navigation
    [Types.GET_WEEK_PLANS_IS_PUBLISHED_REQUEST]: getWeekPlansIsPublishedRequest,
    [Types.GET_WEEK_PLANS_IS_PUBLISHED_SUCCESS]: getWeekPlansIsPublishedSuccess
});
