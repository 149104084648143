import { IButtonProps } from ".";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";

const colors = {
    ...theme.colors.system,
    "outline-primary": theme.colors.brand,
    "outline-success": theme.colors.system.success,
    "outline-danger": theme.colors.system.danger,
    "outline-warning": theme.colors.system.warning,
    "outline-info": theme.colors.system.info,
    base: theme.colors.base,
    "outline-base": theme.colors.base,
    "outline-link": theme.colors.base,
    primary: theme.colors.brand,
    secondary: theme.colors.secondary,
    "outline-secondary": theme.colors.secondary
};

export const ButtonStyles = css<IButtonProps>`
    font-size: ${({ size }) => theme.typography.sizes[size || "small"]};
    padding: ${({ size }) => size && `calc(${theme.spacing[size!]}/2) ${theme.spacing[size!]}`};
    background: ${({ variant }) => (variant.includes("outline") ? "transparent" : colors[variant]["300"])};
    border: 0;
    border-radius: 4px;
    color: ${theme.colors.white};
    cursor: pointer;
    transition: all 0.2s ease 0s; 
    font-weight: 700;
    line-height: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;


    ${({ block }) =>
        block &&
        css`
            display: block;
            width: 100%;
        `}

    ${({ rounded }) =>
        rounded &&
        css`
            border-radius: 100%;
        `}

    ${({ variant }) =>
        variant === "outline-link"
            ? css`
                  color: ${colors[variant]["200"]};
                  svg {
                      fill: ${colors[variant]["200"]};
                  }
              `
            : variant.includes("outline") &&
              css`
                  color: ${colors[variant]["300"]};
                  border: 1px solid ${colors[variant]["300"]};
                  svg {
                      fill: ${colors[variant]["300"]};
                  }
              `}

    &:hover {
        ${({ variant }) =>
            variant.includes("outline") &&
            css`
                color: ${colors[variant]["400"]};
                border: 1px solid ${colors[variant]["400"]};

                svg {
                    fill: ${colors[variant]["400"]};
                }
            `}

        background: ${({ variant }) => (variant.includes("outline") ? colors[variant]["100"] : colors[variant]["400"])};
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: ${theme.colors.base[200]};
        color: ${theme.colors.base[300]};
        cursor: no-drop;
        border: none;
    }
`;

export const ButtonWrapper = styled.button<IButtonProps>`
    ${ButtonStyles};
`;
