import { createActions, createReducer } from "reduxsauce";
import { UpdateActionsType, IUpdateActionsCreators, IUpdateState } from "store/interfaces/IUpdate";

export const { Creators, Types } = createActions<Record<UpdateActionsType, UpdateActionsType>, IUpdateActionsCreators>({
    init: [],
    updateApplication: ["payload"]
});

export const INITIAL_STATE: IUpdateState = {
    serviceWorkerInitialized: false,
    serviceWorkerUpdated: false,
    serviceWorkerRegistration: null
};

const init = (state = INITIAL_STATE) => ({
    ...state,
    serviceWorkerInitialized: !state.serviceWorkerInitialized
});

const updateApplication = (state = INITIAL_STATE, action: any) => ({
    ...state,
    serviceWorkerUpdated: !state.serviceWorkerUpdated,
    serviceWorkerRegistration: action.payload
});

export default createReducer(INITIAL_STATE, {
    [Types.UPDATE_APPLICATION]: updateApplication,
    [Types.INIT]: init
});
