// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Components
import { Asset } from "component-library/utilities/asset";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { BottomSheet } from "component-library/elements/bottom-sheet";
import { Button } from "component-library/elements/button";
import { Tooltip } from "component-library/elements/tooltip";
import { Radial } from "component-library/elements/radial";
import { OptionItem } from "component-library/elements/option-item";
import { IconBadge } from "component-library/elements/icon-badge";
import { PerformanceResumeCard, PerformanceResumeCardSkeleton } from "component-library/compositions/performance-resume-card";
import { PerformanceEssayResumeCard, PerformanceEssayResumeCardSkeleton } from "component-library/compositions/performance-essay-resume-card";
import { PerformanceOffensive, PerformanceOffensiveSkeleton } from "component-library/compositions/performance-offensive";
import { PerformanceNavigator, PerformanceNavigatorSkeleton } from "component-library/compositions/performance-navigator";
import { PerformanceAreaCard, PerformanceAreaCardSkeleton } from "component-library/compositions/performance-area-card";
import { OptionList } from "component-library/compositions/option-list";
import { PerformanceGraphic, PerformanceGraphicSkeleton } from "component-library/compositions/performance-graphic";
import { PerformanceBanner } from "component-library/sections/performance-banner";
import { PerformanceResume } from "component-library/sections/performance-resume";
import { PerformanceEvolution } from "component-library/sections/performance-evolution";
import { PerformanceDashboard } from "component-library/workspaces/performance-dashboard";

// Assets
import calendar from "assets/img/performance-calendar.png";
import schedule from "assets/img/performance-schedule.svg";
import essay from "assets/img/performance-essay.svg";
import arrowRight from "assets/img/arrow-right.svg";
import clock from "assets/img/performance-clock.svg";
import play from "assets/img/performance-play.svg";
import board from "assets/img/performance-board.svg";
import simulado from "assets/img/performance-simulado.svg";
import trophy from "assets/img/performance-trophy.svg";
import sadFace from "assets/img/performance-sad-face.svg";
import subject from "assets/img/performance-subject.svg";
import chevronLeft from "assets/img/chevron-left.svg";
import chevronRight from "assets/img/chevron-right.svg";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

const periods = [
    {
        message: "Hoje",
        slug: "today"
    },
    {
        message: "Esta semana",
        slug: "this-week"
    },
    {
        message: "Este mês",
        slug: "this-month"
    },
    {
        message: "Últimos 12 meses",
        slug: "last-year"
    }
];

export const PerformanceDashboardScreen: FunctionComponent = () => {
    const [isPeriodOptionsOpened, setIsPeriodOptionsOpened] = useState(false);
    const [selectedNavigationPeriodSlug, setSelectedNavigationPeriodSlug] = useState("this-week");
    const [selectedPeriodSlug, setSelectedPeriodSlug] = useState<string>();

    const handleChangePeriodSelectVisibility = () => {
        setIsPeriodOptionsOpened((previousState) => !previousState);
    };

    const handleChangePeriod = (action: "previous" | "next") => {
        console.log(`navegar entre períodos: ${action}`);
    };

    const handleNavigateToEssayDetails = () => {
        console.log("navegar para a tela de detalhes das redações");
    };

    const handleNavigateToAreaDetails = (area: string) => {
        console.log(`navegar para a tela de detalhes da área: ${area}`);
    };

    const handleSelectNavigationPeriodSlug = (periodSlug: string) => {
        console.log(`exibir dados do período: ${periodSlug}`);

        setSelectedNavigationPeriodSlug(periodSlug);
    };

    const handleSelectPeriod = (periodSlug: string) => {
        setSelectedPeriodSlug(selectedPeriodSlug !== periodSlug ? periodSlug : undefined);
    };

    const selectedNavigationPeriodMessage = periods.find((period) => period.slug === selectedNavigationPeriodSlug)?.message;

    const periodOptionItems = periods.map((item) => (
        <OptionItem
            key={item.slug}
            message={item.message}
            active={item.slug === selectedNavigationPeriodSlug}
            handleClick={() => {
                handleSelectNavigationPeriodSlug(item.slug);
                handleChangePeriodSelectVisibility();
            }}
        />
    ));

    // ----------

    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsSkeletonVisible(false);
        }, 5000);
    }, []);

    // ----------

    return (
        <PerformanceDashboard
            bannerSection={
                <PerformanceBanner
                    title="Bem-vindo a sua área de desempenho"
                    subtitle="Aqui você acompanha sua evolução!"
                    offensiveComposition={
                        <>
                            <ConditionallyRender shouldRender={!isSkeletonVisible} content={<PerformanceOffensive dayCount={0} icon={calendar} />} />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceOffensiveSkeleton />} />
                        </>
                    }
                />
            }
            resumeSection={
                <PerformanceResume
                    title="Resumo geral"
                    description="O resumo geral traz dados gerais dos seus resultados acumulados em cada tipo de atividade dentro da plataforma."
                    resumeAreaCompositions={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <>
                                        <PerformanceResumeCard
                                            hoursStudied={0}
                                            areaDescription="Horas totais de estudo"
                                            icon={clock}
                                            areaInformationElement={
                                                <>
                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="hide"
                                                        renderingWidth={768}
                                                        content={
                                                            <Tooltip
                                                                message="É a soma do tempo investido em qualquer atividade dentro da plataforma"
                                                                arrowPosition="right"
                                                            />
                                                        }
                                                    />

                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="show"
                                                        renderingWidth={768}
                                                        content={
                                                            <Tooltip
                                                                message="É a soma do tempo investido em qualquer atividade dentro da plataforma"
                                                                arrowPosition="bottom"
                                                            />
                                                        }
                                                    />
                                                </>
                                            }
                                        />
                                        <PerformanceResumeCard hoursStudied={0} areaDescription="Aulas assistidas" icon={play} />
                                        <PerformanceResumeCard hoursStudied={0} areaDescription="Questões resolvidas" icon={board} />
                                        <PerformanceResumeCard hoursStudied={0} areaDescription="Simulados e provas realizadas" icon={simulado} />
                                    </>
                                }
                            />
                            <ConditionallyRender
                                shouldRender={isSkeletonVisible}
                                content={Array.from(Array(4)).map((_, index) => (
                                    <PerformanceResumeCardSkeleton key={`performance-resume-card-skeleton-${index}`} />
                                ))}
                            />
                        </>
                    }
                    resumeAreaEssayComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <PerformanceEssayResumeCard
                                        title="Desempenho redação"
                                        icon={essay}
                                        bestNote={0}
                                        deliveredEssays={0}
                                        lastNote={0}
                                        seeDetailsElement={
                                            <Button
                                                label="Exibir em detalhes"
                                                variant="outlined"
                                                appearance="secondary"
                                                size="small"
                                                isFullyAdaptative
                                                icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                handleClick={handleNavigateToEssayDetails}
                                            />
                                        }
                                    />
                                }
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceEssayResumeCardSkeleton />} />
                        </>
                    }
                />
            }
            evolutionSection={
                <PerformanceEvolution
                    title="Minha evolução"
                    description="Os dados da sua evolução consideram seu desempenho em todos os tipos de exercícios resolvidos dentro da plataforma, sejam eles de atividades, provas, banco de questões ou simulados, divididos por área do conhecimento."
                    navigatorComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <PerformanceNavigator
                                        period={selectedNavigationPeriodMessage!}
                                        totalQuestions={80}
                                        totalHits={45}
                                        hitsPercentage={34}
                                        scheduleIcon={schedule}
                                        isPeriodOptionsOpened={isPeriodOptionsOpened}
                                        handlePeriodClick={handleChangePeriodSelectVisibility}
                                        periodOptionsComposition={
                                            <>
                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="hide"
                                                    content={
                                                        <BottomSheet
                                                            content={periodOptionItems}
                                                            handleOtherClick={handleChangePeriodSelectVisibility}
                                                        />
                                                    }
                                                />

                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="show"
                                                    content={<OptionList content={periodOptionItems} />}
                                                />
                                            </>
                                        }
                                        previousColumnIconElement={
                                            <>
                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="hide"
                                                    content={
                                                        <Asset
                                                            source={chevronRight}
                                                            color="#7C8195"
                                                            hoverColor="#571D5D"
                                                            size={16}
                                                            handleClick={() => {
                                                                handleChangePeriod("previous");
                                                            }}
                                                        />
                                                    }
                                                />

                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="show"
                                                    content={
                                                        <Asset
                                                            source={chevronRight}
                                                            color="#7C8195"
                                                            hoverColor="#571D5D"
                                                            size={20}
                                                            handleClick={() => {
                                                                handleChangePeriod("previous");
                                                            }}
                                                        />
                                                    }
                                                />
                                            </>
                                        }
                                        nextColumnIconElement={
                                            <>
                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="hide"
                                                    content={
                                                        <Asset
                                                            source={chevronLeft}
                                                            color="#7C8195"
                                                            hoverColor="#571D5D"
                                                            size={16}
                                                            handleClick={() => {
                                                                handleChangePeriod("next");
                                                            }}
                                                        />
                                                    }
                                                />

                                                <ScreenWidthRender
                                                    renderingWidth={768}
                                                    actionAfterRenderingWidth="show"
                                                    content={
                                                        <Asset
                                                            source={chevronLeft}
                                                            color="#7C8195"
                                                            hoverColor="#571D5D"
                                                            size={20}
                                                            handleClick={() => {
                                                                handleChangePeriod("next");
                                                            }}
                                                        />
                                                    }
                                                />
                                            </>
                                        }
                                    />
                                }
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceNavigatorSkeleton />} />
                        </>
                    }
                    // TODO: criar mock dos dados dos cards abaixo
                    evolutionCardCompositions={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <>
                                        <PerformanceAreaCard
                                            totalHitsPercentage={0}
                                            icon={{ background: "#7CB8D3", src: subject }}
                                            totalQuestions={0}
                                            subjectTitle="Matemática"
                                            borderColor="#4186A0"
                                            hoverColor="#CEE3EE"
                                            badgeIconElement={<IconBadge hasAction icon={trophy} backgroundColor="#28B583" />}
                                            badgeInformationElement={
                                                <>
                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="hide"
                                                        renderingWidth={768}
                                                        content={<Tooltip arrowPosition="right" message="Maior desempenho" size="small" />}
                                                    />

                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="show"
                                                        renderingWidth={768}
                                                        content={<Tooltip arrowPosition="bottom" message="Maior desempenho" size="small" />}
                                                    />
                                                </>
                                            }
                                            radialElement={<Radial progress={65} />}
                                            actionIconElement={
                                                <>
                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="hide"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                                    />

                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="show"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                                    />
                                                </>
                                            }
                                            handleClick={() => handleNavigateToAreaDetails("Matemática")}
                                        />
                                        <PerformanceAreaCard
                                            totalHitsPercentage={0}
                                            icon={{ background: "#92CFC6", src: subject }}
                                            totalQuestions={0}
                                            subjectTitle="Ciências da Natureza"
                                            borderColor="#589C8E"
                                            hoverColor="#D6EDEA"
                                            radialElement={<Radial progress={65} />}
                                            actionIconElement={
                                                <>
                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="hide"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                                    />

                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="show"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                                    />
                                                </>
                                            }
                                            handleClick={() => handleNavigateToAreaDetails("Ciências da Natureza")}
                                        />
                                        <PerformanceAreaCard
                                            totalHitsPercentage={0}
                                            icon={{ background: "#FE9694", src: subject }}
                                            totalQuestions={0}
                                            subjectTitle="Linguagens"
                                            borderColor="#CC595C"
                                            hoverColor="#FEDAD7"
                                            radialElement={<Radial progress={65} />}
                                            actionIconElement={
                                                <>
                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="hide"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                                    />

                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="show"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                                    />
                                                </>
                                            }
                                            handleClick={() => handleNavigateToAreaDetails("Linguagens")}
                                        />
                                        <PerformanceAreaCard
                                            totalHitsPercentage={0}
                                            icon={{ background: "#FBEB99", src: subject }}
                                            totalQuestions={0}
                                            subjectTitle="Ciências Humanas"
                                            borderColor="#CAB35E"
                                            hoverColor="#FDF9D9"
                                            badgeIconElement={<IconBadge hasAction icon={sadFace} backgroundColor="#E85153" />}
                                            badgeInformationElement={
                                                <>
                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="hide"
                                                        renderingWidth={768}
                                                        content={<Tooltip arrowPosition="right" message="Menor desempenho" size="small" />}
                                                    />

                                                    <ScreenWidthRender
                                                        actionAfterRenderingWidth="show"
                                                        renderingWidth={768}
                                                        content={<Tooltip arrowPosition="bottom" message="Menor desempenho" size="small" />}
                                                    />
                                                </>
                                            }
                                            radialElement={<Radial progress={65} />}
                                            actionIconElement={
                                                <>
                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="hide"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                                    />

                                                    <ScreenWidthRender
                                                        renderingWidth={1024}
                                                        actionAfterRenderingWidth="show"
                                                        content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                                    />
                                                </>
                                            }
                                            handleClick={() => handleNavigateToAreaDetails("Ciências Humanas")}
                                        />
                                    </>
                                }
                            />
                            <ConditionallyRender
                                shouldRender={isSkeletonVisible}
                                content={Array.from(Array(4)).map((_, index) => (
                                    <PerformanceAreaCardSkeleton key={`performance-area-card-skeleton-${index}`} />
                                ))}
                            />
                        </>
                    }
                    evolutionGraphicComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <PerformanceGraphic
                                        maxPeriodResult={100}
                                        columns={[
                                            {
                                                period: {
                                                    slug: "monday",
                                                    value: "Segunda",
                                                    mobileValue: "S"
                                                },
                                                result: 70,
                                                informations: {
                                                    hits: 45,
                                                    totalQuestions: 70
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "tuesday",
                                                    value: "Terça",
                                                    mobileValue: "T"
                                                },
                                                result: 30,
                                                informations: {
                                                    hits: 15,
                                                    totalQuestions: 30
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "wednesday",
                                                    value: "Quarta",
                                                    mobileValue: "Q"
                                                },
                                                result: 42,
                                                informations: {
                                                    hits: 30,
                                                    totalQuestions: 42
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "thursday",
                                                    value: "Quinta",
                                                    mobileValue: "Q"
                                                },
                                                result: 22,
                                                informations: {
                                                    hits: 8,
                                                    totalQuestions: 22
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "friday",
                                                    value: "Sexta",
                                                    mobileValue: "S"
                                                },
                                                result: 54,
                                                informations: {
                                                    hits: 22,
                                                    totalQuestions: 54
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "saturday",
                                                    value: "Sábado",
                                                    mobileValue: "S"
                                                },
                                                result: 68,
                                                informations: {
                                                    hits: 38,
                                                    totalQuestions: 68
                                                }
                                            },
                                            {
                                                period: {
                                                    slug: "sunday",
                                                    value: "Domingo",
                                                    mobileValue: "D"
                                                },
                                                result: 82,
                                                informations: {
                                                    hits: 50,
                                                    totalQuestions: 82
                                                }
                                            }
                                        ]}
                                        subtitles={[
                                            { color: "#9C9FB0", label: "Questões" },
                                            ...(selectedPeriodSlug !== undefined ? [{ color: "#794A7D", label: "Período selecionado" }] : []),
                                            { color: "#28B583", label: "Acertos" }
                                        ]}
                                        hasPeriodSelection
                                        selectedPeriodSlug={selectedPeriodSlug}
                                        handleSelectPeriod={handleSelectPeriod}
                                        previousColumnsIcon={chevronRight}
                                        nextColumnsIcon={chevronLeft}
                                    />
                                }
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceGraphicSkeleton />} />
                        </>
                    }
                />
            }
        />
    );
};
