import { ISelectOption } from "interfaces/ISelectOption";
import { createActions, createReducer } from "reduxsauce";
import { FilesActionsType, IFilesActionsCreators, IFilesState } from "store/interfaces/IFile";

export const { Creators, Types } = createActions<Record<FilesActionsType, FilesActionsType>, IFilesActionsCreators>({
    getFilesRequest: [],
    getFilesSuccess: ["payload"],
    getFilesFailure: ["payload"],

    getFilesCategoriesRequest: ["payload"],
    getFilesCategoriesSuccess: ["payload"],
    getFilesCategoriesFailure: ["payload"],

    clearFiles: []
});

export const INITIAL_STATE: IFilesState = {
    isLoading: false,
    categories: [{ value: 0, label: "Ver tudo" }],
    documents: {
        count: 0,
        items: [],
        page: 0,
        totalItems: 0,
        totalPages: 0
    }
};

const actionRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getFilesSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    documents: {
        ...action.payload.data,
        items: action.payload.data.page === 1 ? action.payload.data.items : [...state.documents.items, ...action.payload.data.items]
    },
    isLoading: false
});

const getFilesCategoriesSuccess = (state = INITIAL_STATE, action: { payload: ISelectOption[] }) => ({
    ...state,
    categories: [...state.categories, ...action.payload],
    isLoading: false
});

const actionFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const clearFiles = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.GET_FILES_REQUEST]: actionRequest,
    [Types.GET_FILES_SUCCESS]: getFilesSuccess,
    [Types.GET_FILES_FAILURE]: actionFailure,

    [Types.GET_FILES_CATEGORIES_REQUEST]: actionRequest,
    [Types.GET_FILES_CATEGORIES_SUCCESS]: getFilesCategoriesSuccess,
    [Types.GET_FILES_CATEGORIES_FAILURE]: actionFailure,

    [Types.CLEAR_FILES]: clearFiles
});
