import styled from "styled-components";
import { IPageLoaderProps } from ".";
import { theme } from "config/theme";

export const PageLoaderContainer = styled.div<IPageLoaderProps>`
    height: 100%;
    width: 100%;
    right: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999999;

    position: ${({ position }) => position};
`;

export const PageLoaderText = styled.p`
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[400]};
    font-weight: bold;
    margin-top: 80px;
`;
