import styled, { css } from "styled-components/macro";
import { ButtonStyles } from "components/Button/styles";
import { IButtonProps } from "components/Button";
import { theme } from "config/theme";

interface ISliderButtonProps extends IButtonProps {
    active?: boolean;
}

export const EssaySliderButton = styled.button<ISliderButtonProps>`
    ${ButtonStyles};

    width: 32px;
    height: 40px;
    background: ${theme.colors.base[200]};
    border: none;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    display: block;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    outline: none;
    transition: background 0.3s ease;

    :hover {
        opacity: 0.7;
    }

    ${({ active }: any) =>
        active &&
        css`
            background: ${theme.colors.system.info[300]};
        `};
`;
