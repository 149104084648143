import React, { useMemo, useCallback, useContext, memo } from "react";

// Dependencies
import { DateTime } from "luxon";
import { string, object, number } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

// Components
import Button from "components/Button";
import Select from "components/Select";
import DropdownIndicator from "./indicator";
import TextField from "components/Form/TextField";

// Helpers
import { Step1Fields } from "../types";
import { genderOptions } from "../helpers";
import cpfValidate from "utils/cpfValidate";
import { MigrationUserContext } from "../context";
import { MASK_BIRTHDAY, MASK_CPF, MASK_PHONE } from "helpers";

// Assets
import { StepForm, StepTitle, StepFormGroup, StepWrapper, StepFormGroupWrapper } from "../styles";

const PHONE_REGEX = /^\(\d{2}\)\s\d{5}-\d{4}$/;
const BIRTH_DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/;

const schema = object({
    name: string().required("Campo obrigatório"),
    lastName: string().required("Campo obrigatório"),
    birthDate: string().matches(BIRTH_DATE_REGEX, "Data inválida"),
    gender: number().typeError("Campo obrigatório"),
    cpf: string().test("cpf", "CPF inválido", function(value) {
        if (!value) {
            return false;
        }

        const cpf = ((value as any) || "").match(/\d/g)?.join("");
        const isValid = cpfValidate(cpf);

        if (!isValid) {
            return false;
        }

        return true;
    }),
    celphone: string().matches(PHONE_REGEX, "Telefone inválido"),
    email: string()
});

const Step1 = () => {
    const {
        state,
        dispatch,
        methods: { changeStep }
    } = useContext(MigrationUserContext);

    const INITIAL_STATE = useMemo(() => {
        if (state.payload.step1) {
            const genderFound = genderOptions.find((gender) => gender.value === state.payload.step1?.gender);

            return {
                name: state.payload.step1.name || "",
                lastName: state.payload.step1.lastName || "",
                birthDate: state.payload.step1.birthDate || "",
                gender: genderFound ? genderFound.value : null,
                cpf: state.payload.step1.cpf || "",
                celphone: state.payload.step1.celphone || "",
                email: state.payload.step1.email || ""
            };
        }

        const genderFound = genderOptions.find((gender) => gender.value === state.credentials?.gender);

        return {
            name: state.credentials?.name || "",
            lastName: state.credentials?.lastName || "",
            birthDate: state.credentials?.birthDate
                ? DateTime.fromISO(state.credentials?.birthDate)
                      .plus({ hour: 3 })
                      .toFormat("dd/LL/yyyy")
                : "",
            gender: genderFound ? genderFound.value : null,
            cpf: state.credentials?.cpf || "",
            celphone: state.credentials?.phone || "",
            email: state.credentials?.email || ""
        };
    }, [state.credentials, state.payload.step1]);

    const { handleSubmit, errors, control } = useForm<Step1Fields>({
        mode: "onSubmit",
        defaultValues: INITIAL_STATE as any,
        resolver: yupResolver(schema)
    });

    const handleSaveForm = useCallback(
        (data: Step1Fields) => {
            dispatch({ type: "TOGGLE_LOADER", payload: true });

            try {
                dispatch({
                    type: "SET_PAYLOAD",
                    payload: {
                        ...state.payload,
                        step1: {
                            ...data,
                            username: state.credentials?.username
                        }
                    }
                });

                changeStep();
            } catch (error) {
                return null;
            } finally {
                dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
        },
        [changeStep, dispatch, state.credentials, state.payload]
    );

    return (
        <StepWrapper>
            <StepTitle>O Prenchimento é obrigatório, tá?</StepTitle>
            <StepForm onSubmit={handleSubmit(handleSaveForm)}>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="name"
                        render={({ onChange, value }) => <TextField error={errors.name?.message} label="Nome" value={value} onChange={onChange} />}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="lastName"
                        render={({ onChange, value }) => (
                            <TextField error={errors.lastName?.message} label="Sobrenome" value={value} onChange={onChange} />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup isHorizontal={true}>
                    <StepFormGroupWrapper>
                        <Controller
                            control={control}
                            name="birthDate"
                            render={({ onChange, value }) => (
                                <TextField
                                    error={errors.birthDate?.message}
                                    label="Nascimento"
                                    mask={MASK_BIRTHDAY}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </StepFormGroupWrapper>
                    <Controller
                        name="gender"
                        control={control}
                        render={({ onChange, value }) => (
                            <Select
                                components={{ DropdownIndicator }}
                                placeholder="Com qual gênero você se identifica?"
                                options={genderOptions}
                                error={errors.gender?.message}
                                value={genderOptions.find((item: any) => item.value === value)}
                                onChange={(item: any) => onChange(item.value)}
                            />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="cpf"
                        render={({ onChange, value }) => (
                            <TextField error={errors.cpf?.message} label="CPF" mask={MASK_CPF} value={value} onChange={onChange} />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="celphone"
                        render={({ onChange, value }) => (
                            <TextField error={errors.celphone?.message} label="Celular" mask={MASK_PHONE} value={value} onChange={onChange} />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="email"
                        render={({ onChange, value }) => (
                            <TextField error={errors.email?.message} label="E-mail" disabled={true} value={value} onChange={onChange} />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Button data-test-id="go-to-next-step" variant="info" size="medium" block={true} type="submit">
                        Continuar
                    </Button>
                </StepFormGroup>
            </StepForm>
        </StepWrapper>
    );
};

export default memo(Step1);
