import { useMemo } from "react";

interface IProps {
    answer: any;
    alternatives: any[];
}

const useMarkedAlternative = ({ answer, alternatives }: IProps) => {
    const markedAlternative = useMemo(() => {
        try {
            if (!answer || !answer.objectiveAnswer || !alternatives || !alternatives.length) {
                throw new Error();
            }

            return alternatives.find(({ id }: any) => id === answer.objectiveAnswer);
        } catch (error) {
            return null;
        }
    }, [answer, alternatives]);

    return markedAlternative;
};

export default useMarkedAlternative;
