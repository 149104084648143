import React from "react";
import ForbiddenMessage from "components/ForbiddenMessage";
import { LegacyLivesContainer } from "./styles";

const LegacyLives: React.FC = () => {
    return (
        <LegacyLivesContainer>
            <ForbiddenMessage type="maintenance" title="Aviso!" text="Esta área está em manutenção." />
        </LegacyLivesContainer>
    );
};

export default LegacyLives;
