// Dependencies
import styled from "styled-components";

export const TitleWrapper = styled.div`
    width: 100%;
    padding: 8px;
    background-color: #f1f1f7;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
`;

export const TitleIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

export const Title = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: #232326;
`;
