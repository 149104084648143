import React, { useCallback, useContext, memo } from "react";
import { useSelector } from "react-redux";

// Components
import Button from "components/Button";

// Helpers
import { MigrationUserContext } from "../context";

// Assets
import { FinishText, StepFormGroup, StepEdgesContainer } from "../styles";
import history from "services/history";

// Types
import { IReduxStore } from "interfaces/IReduxStore";

const Finish: React.FC = () => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);
    const { state } = useContext(MigrationUserContext);

    const handleButtonClick = useCallback(() => history.push({ pathname: `/app/curso/${courseSlug}/plano-de-estudos` }), [courseSlug]);

    return (
        <StepEdgesContainer>
            <FinishText>
                <strong>Obrigado pelas atualizações, {state.credentials?.name}! 🙂</strong>
            </FinishText>
            <FinishText>Caso precise alterar alguma informação, é só editá-las em "Meu Perfil", tá bem?</FinishText>
            <FinishText>
                <strong>Bons estudos!</strong>
            </FinishText>
            <StepFormGroup>
                <Button data-test-id="finish-wizard" variant="info" size="medium" block={true} onClick={handleButtonClick}>
                    Entrar na plataforma
                </Button>
            </StepFormGroup>
        </StepEdgesContainer>
    );
};

export default memo(Finish);
