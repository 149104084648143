// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

// Assets
import hoursIcon from "./assets/hoursIcon.svg";
import bookIcon from "./assets/bookIcon.svg";

type WebpStudyPlanDetailContentProps = {
    hours: number;
    labelMaterials: string;
};

export const WebpStudyPlanDetailContent: FunctionComponent<WebpStudyPlanDetailContentProps> = ({ hours, labelMaterials }) => {
    return (
        <Container>
            <div>
                <img src={hoursIcon} alt="svg de um relogio" />

                <p>Horas totais: {hours}h.</p>
            </div>
            <div>
                <img src={bookIcon} alt="svg de um livro" />

                <p>Matérias: {labelMaterials}</p>
            </div>
        </Container>
    );
};
