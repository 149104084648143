import React from "react";

// Dependencies
import ShareIcon from "prodigio-icons/web/outlined/Share";

// Components
import Like from "screens/Forum/question/components/like";

// Assets
import * as F from "./styles";

export interface IForumQuestionActionsProps {
    id: string;
    isLiked: boolean;
    likesTotal: number;
    onShowForumPost: () => void;
    onToggleLike: () => void;
    color?: string;
    disabledActions?: boolean;
}

const ForumQuestionActions: React.FC<IForumQuestionActionsProps> = ({
    color,
    id,
    isLiked,
    likesTotal,
    onShowForumPost,
    onToggleLike,
    disabledActions = true
}) => {
    return (
        <F.ForumQuestionActionsContainer>
            <Like disabled={disabledActions} color={color} count={likesTotal} id={id} isLiked={isLiked} onToggleLike={onToggleLike} />
            <F.ForumQuestionReplyButton disabled={disabledActions} color={color} onClick={onShowForumPost}>
                <ShareIcon width="16" height="13" />
                Responder
            </F.ForumQuestionReplyButton>
        </F.ForumQuestionActionsContainer>
    );
};

export default ForumQuestionActions;
