// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
`;

export const ResumeEssayCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        height: 100%;
    }
`;

export const ResumeEssayCardHeader = styled.div`
    display: flex;
    align-items: center;
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 48px;
    height: 100%;
    min-height: 48px;
    background-color: #ddd2df;
    border-radius: 4px;
`;

export const Icon = styled.img`
    width: 32px;
    height: 32px;
`;

export const ResumeEssayCardTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #505462;

    @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const ResumeEssayCardDelivered = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505462;
`;

export const ResumeEssayCardContent = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #505462;
`;

export const ResumeEssayCardHighlightedText = styled.span`
    font-size: 18px;
    line-height: 26px;
    color: #7c8195;
`;

export const ResumeEssaySeeDetailsElementWrapper = styled.div`
    width: 100%;

    @media (min-width: 768px) {
        width: 200px;
    }

    @media (min-width: 1024px) {
        width: 100%;
    }
`;

// ----------

export const IconWrapperSkeleton = styled.div`
    flex-shrink: 0;
    width: 48px;
    height: 48px;

    @media (min-width: 1024px) {
        height: 100%;
        min-height: 48px;
    }
`;

export const ResumeEssayCardTitleSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        height: 28px;
    }
`;

export const ResumeEssayCardDeliveredSkeleton = styled.div`
    height: 20px;
    padding: 3px 0;
`;

export const ResumeEssayCardContentSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;
`;
