import React, { useCallback, useEffect } from "react";
import {
    SignWrapContent,
    GeneralSignLeftContent,
    LogoWrapper,
    SignTitlesWrap,
    SignTitle,
    SignSubTitle,
    SignCopyright
} from "components/SignScreen/styles";
import PageLoader from "components/PageLoader";
import RightContent from "components/SignScreen/RightContent";
import { theme } from "config/theme";
import { IConfirmChangePassword } from "store/ducks/student-area";
import { useDispatch, useSelector } from "react-redux";
import { Creators as studentAreaActions } from "../../store/ducks/student-area";
import Button from "components/Button";
import { IReduxStore } from "interfaces/IReduxStore";
import history from "services/history";
import { useQueryString } from "hooks/use-query-string";

const isOurProject = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

const ConfirmationPassword = () => {
    const dispatch = useDispatch();
    const { code, email } = useQueryString() as Partial<IConfirmChangePassword>;
    const isLoading = useSelector((state: IReduxStore) => state.studentArea.isLoading);

    const termsUrl =
        theme.project.slug === "promilitares" ? "https://www.promilitares.com.br/termos-de-uso" : "https://www.proenem.com.br/termos-de-uso";

    const handleClickToBackToLogin = useCallback(() => {
        history.push("/");
    }, []);

    const requestSaveChangePasswordConfirm = useCallback(
        (code?: string, email?: string) => {
            dispatch(studentAreaActions.studentSavePasswordConfirmRequest({ code, email }));
        },
        [dispatch]
    );

    useEffect(() => {
        requestSaveChangePasswordConfirm(code, email);
    }, [code, email, requestSaveChangePasswordConfirm]);

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <>
            <SignWrapContent>
                <GeneralSignLeftContent>
                    <LogoWrapper>
                        <img alt={theme.project.name} src={theme.project.logo} />
                    </LogoWrapper>
                    <SignTitlesWrap>
                        <SignTitle>Parabéns! A sua senha foi modificada conforme solicitado. Volte ao formulário de login para entrar.</SignTitle>
                        <SignSubTitle>
                            Caso não consiga acessar experimente utilizar a opção "Esqueci minha senha" no formulário de login.
                        </SignSubTitle>
                    </SignTitlesWrap>
                    <Button onClick={handleClickToBackToLogin} style={{ marginRight: "auto" }} type="submit" variant="primary" size="medium">
                        Fazer login novamente
                    </Button>
                    {isOurProject && (
                        <SignCopyright>
                            <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                                Termos de uso
                            </a>
                        </SignCopyright>
                    )}
                </GeneralSignLeftContent>
                <RightContent />
            </SignWrapContent>
        </>
    );
};

export default ConfirmationPassword;
