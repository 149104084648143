import styled from "styled-components";
import { Container as WebpCardContainer } from "components/WebpCard/styles";
import { BreakPoints } from "../../../../../assets/styles/settings";

export const FilterFormWrapper = styled.div`
    ${WebpCardContainer} {
        padding: 1.5rem 1rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            padding: 1.5rem;
        }

        @media screen and (min-width: ${BreakPoints.wide}px) {
            padding: 1.5rem 1rem;
        }
        @media screen and (min-width: 1366px) {
            height: 448px;
        }
    }
`;

export const TitleWithSwitch = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .webp-text {
        max-width: 40%;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            max-width: 100%;
        }
    }
    @media screen and (min-width: ${BreakPoints.medium}px) {
        max-width: 458px;
        margin-bottom: 1rem;
    }
`;

export const FilterTitle = styled.div`
    margin-bottom: 1rem;
    @media screen and (min-width: ${BreakPoints.medium}px) {
        margin-bottom: 0.5rem;
    }
`;

export const DropdownFilterContainer = styled.div`
    display: grid;
    row-gap: 0.5rem;

    @media screen and (min-width: ${BreakPoints.tablet}px) {
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        padding: 0;
    }

    @media screen and (min-width: ${BreakPoints.medium}px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .webpcheckbox {
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            width: 188px;
        }
    }
`;

export const FilterChipsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .webp-chip {
        margin: 0 1rem 0.5rem 0;
    }
`;

export const CheckboxContainer = styled.div`
    display: flex;
    .webp-checkbox {
        margin-right: 1rem;
    }
`;

export const QuestiosAndExeptionsContainer = styled.div``;

export const QuestionLevelsContainer = styled.div``;

export const ExecptionsContainer = styled.div`
    margin-top: 2rem;
    @media screen and (min-width: ${BreakPoints.medium}px) {
        margin-top: 1rem;
    }
    @media screen and (min-width: 1366px) {
        margin-top: 0;

        ${FilterTitle} {
            margin-bottom: 1.5rem;
        }
    }
`;

const FormRow = styled.div`
    display: grid;
    margin-bottom: 2rem;

    &:last-child {
        margin-bottom: 0;
    }
    @media screen and (min-width: ${BreakPoints.medium}px) {
        margin-bottom: 1.5rem;
    }
`;
export const FormRowOne = styled(FormRow)``;
export const FormRowTwo = styled(FormRow)`
    .webp-checkbox {
        margin-bottom: 0.5rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-bottom: 0;
            width: 188px;
        }
    }
`;
export const FormRowThree = styled(FormRow)`
    .webp-checkbox {
        margin-bottom: 0.5rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-bottom: 0;
            width: 188px;
        }
    }
`;
export const FormRowFour = styled(FormRow)`
    ${TitleWithSwitch} {
        margin-bottom: 0.5rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-bottom: 1rem;
        }
    }
`;
export const FormRowFive = styled(FormRow)`
    @media screen and (min-width: 1366px) {
        grid-template-columns: 70% 28%;
        justify-content: space-between;
    }
    ${TitleWithSwitch} {
        margin-bottom: 0.5rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-bottom: 1rem;
        }
    }
`;
