import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { BodyText1, BodyText2, CardContainerSmallStyle } from "assets/styles/global";

export const EssayResumeContainer = styled.div`
    ${CardContainerSmallStyle};
    padding: ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }
`;

export const EssayResumeWrapper = styled.div``;

const TextStyles = css`
    color: ${theme.colors.base[400]};
    display: block;
`;

export const EssayResumeText = styled.span`
    ${BodyText1};
    ${TextStyles};
    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
        color: ${theme.colors.base[500]};
    }
`;

export const EssayResumeHelper = styled.span`
    ${BodyText2};
    ${TextStyles};
`;

export const EssayResumeActions = styled.nav`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${theme.spacing.small};

    ${ButtonWrapper} {
        flex: 1;

        &:first-child {
            margin-right: ${theme.spacing.small};
        }

        &:last-child {
            flex: unset;
            margin-top: ${theme.spacing.small};
            width: 100%;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.small};

        ${ButtonWrapper} {
            &:last-child {
                margin-top: ${theme.spacing.smallX};
                width: 100%;
            }
        }
    }
`;
