import React, { useMemo, useState, useCallback, useEffect } from "react";
import { ExamScreenContainer, ExamScreenContent } from "./styles";
import { Grid, Row, Col } from "components/Grid";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import ExamScreenItem from "./components/ExamItem";
import ExamScreenDesktopFilter from "./components/DesktopFilter";
import Spinner from "components/Spinner";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { IExamList } from "interfaces/IExam";
import { formatFilterToQueryString } from "utils/formatFilterToQueryString";
import ErrorScreen from "screens/Error";
import { formatResponseToFilter } from "helpers/formatResponseToFilter";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { IExamActions } from "store/ducks/exam/types";
import PageHeader from "components/PageHeader";
import DayModal from "components/ExamDayModal";
import isProdigioProject from "utils/isProdigioProject";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as examActions } from "store/ducks/exam";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    isLoading: boolean;
    exams: IExamList[];
    examActions: IExamActions;
    error: boolean;
    filters?: any;
    isEmpty?: boolean;
    loadingResolutions: number[];
    totalCredits: number;
    currentCourseId?: number;
    courseSlug: string;
}

const ExamScreen = ({ isLoading, exams, examActions, error, filters, isEmpty, loadingResolutions, totalCredits = 0 }: IProps) => {
    const isMobile = useIsMobile(BreakPoints.medium);

    const [filterOptions, setFilterOptions] = useState<IFilterGroup[]>([]);

    const formatFilterResponse = useCallback(() => {
        if (!filters) {
            return;
        }

        const format = formatResponseToFilter(filters, true);

        if (!format || !Array.isArray(format)) {
            return;
        }

        setFilterOptions(format);
    }, [filters]);

    useEffect(() => {
        formatFilterResponse();
    }, [formatFilterResponse]);

    useEffect(() => {
        return () => {
            examActions.clearAllExams();
        };
    }, [examActions]);

    const requestExams = useCallback(
        (filters?: string) => {
            const payload: IPayloadRequest = {
                endpoint: "/student/exam",
                method: "GET",
                headers: {
                    ...(!!filters && { "X-Where": filters })
                }
            };

            examActions.getAllExamsRequest(payload);
        },
        [examActions]
    );

    const requestFilters = useCallback(() => {
        const payload: IPayloadRequest = {
            endpoint: "/student/exam/filter",
            method: "GET"
        };

        examActions.getExamFiltersRequest(payload);
    }, [examActions]);

    useEffect(() => {
        requestFilters();
    }, [requestFilters]);

    useEffect(() => {
        requestExams();
    }, [requestExams]);

    const handleRequestExam = useCallback(
        (examDaySlug: string) => {
            if (!examDaySlug) {
                return;
            }

            examActions.openExamDayModal({ examDaySlug });
        },
        [examActions]
    );

    const listExams = useMemo(() => {
        switch (true) {
            case error:
                return <ErrorScreen />;
            case isEmpty:
                return (
                    <ExamScreenContent>
                        <p data-test-id="exam-empty">Sem provas para mostrar.</p>
                    </ExamScreenContent>
                );
            case !!exams?.length:
                return (
                    <ExamScreenContent>
                        {exams.map(({ name, examDays, slug, id, resolutionId, isAvailable }) => (
                            <ExamScreenItem
                                loadingResolutionId={!!loadingResolutions?.some((examId) => examId === id)}
                                name={name}
                                examDays={examDays}
                                key={`exam_${id}`}
                                slug={slug}
                                onClick={handleRequestExam}
                                resolutionId={resolutionId}
                                isAvailable={isAvailable}
                            />
                        ))}
                    </ExamScreenContent>
                );
            case isLoading:
            default:
                return <Spinner fixed={false} size={25} />;
        }
    }, [error, exams, handleRequestExam, isEmpty, isLoading, loadingResolutions]);

    const handleChangeFilter = useCallback(
        (options: IFilterGroup[]) => {
            setFilterOptions(options);

            requestExams(formatFilterToQueryString(options));
        },
        [requestExams]
    );

    const loadingFilter = useMemo(() => !filterOptions.length && isLoading, [filterOptions.length, isLoading]);

    const withCredits = isProdigioProject();

    return (
        <>
            <DayModal />

            <ExamScreenContainer>
                <PageHeader.Complex
                    title="Simulados"
                    modalOptions={filterOptions}
                    onFilter={handleChangeFilter}
                    {...(withCredits && { credit: { amount: totalCredits } })}
                    buttonNew={isMobile && null}
                />

                <Grid fluid>
                    <Row>
                        <Col xs={12} md={9}>
                            {listExams}
                        </Col>

                        {!isMobile && (
                            <Col xs={3}>
                                {loadingFilter ? (
                                    <Spinner fixed={false} size={25} />
                                ) : (
                                    <ExamScreenDesktopFilter onChangeFilter={handleChangeFilter} categoryOptions={filterOptions} />
                                )}
                            </Col>
                        )}
                    </Row>
                </Grid>
            </ExamScreenContainer>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    examActions: bindActionCreators(examActions, dispatch)
});

const mapStateToProps = ({ exam, course }: IReduxStore) => ({
    exams: exam.items || [],
    isLoading: exam.loading,
    error: exam.error,
    filters: exam.filters,
    isEmpty: !!exam?.isEmpty,
    loadingResolutions: exam?.examCompletedResolutionsLoading || [],
    totalCredits: exam?.totalCredits || 0,
    currentCourseId: course?.id,
    courseSlug: course?.slug
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ExamScreen);
