import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";

export const DropzoneContainer = styled.div<{ isDragging: boolean }>`
    align-items: center;
    background: ${theme.colors.base[100]};
    border: 1px dashed;
    border-color: ${({ isDragging }) => (isDragging ? hexToRgba(theme.colors.base[200], 0.7) : hexToRgba(theme.colors.base[200], 0.2))};
    border-radius: ${theme.spacing.smallX};
    display: flex;
    min-height: 160px;
    justify-content: center;
    overflow: hidden;
    outline: none;
    padding: ${theme.spacing.medium};
    position: relative;
`;
