// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 8px;
    background-color: #ced0dd;
    border-radius: 8px;

    overflow: hidden;

    position: relative;
`;

export type ProgressStyleProps = {
    color: string;
    progress: number;
};

export const Progress = styled.div<ProgressStyleProps>`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: ${({ progress }) => `${progress}%`};
    height: 8px;
    background-color: ${({ color }) => color};
    border-radius: 8px;
`;
