// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, ContentWrapper, PageTitle } from "./performance-subject.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

// Types
import { PerformanceSubjectProps } from "./performance-subject.types";

export const PerformanceSubject: FunctionComponent<PerformanceSubjectProps> = ({ pageTitle, backElement, navigatorComposition, topicsSection }) => {
    return (
        <Container>
            <ContentWrapper>
                <ScreenWidthRender
                    actionAfterRenderingWidth="show"
                    renderingWidth={768}
                    content={
                        <>
                            {backElement}

                            <Spacing direction="vertical" size={8} />
                        </>
                    }
                />

                <PageTitle>{pageTitle}</PageTitle>

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={24} />} />

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                {navigatorComposition}

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={48} />} />

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={24} />} />

                {topicsSection}
            </ContentWrapper>
        </Container>
    );
};
