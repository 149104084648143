import { CheckboxContainer as Checkbox, CheckboxHelper } from "components/Form/Checkbox/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export interface IListItemSectionContentProps {
    active?: boolean;
}

export const ProjectSectionDetails = styled.div`
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;

    svg {
        margin: 1px ${theme.spacing.smallXX} 0 0;
    }

    span {
        flex: 1;
    }
`;

export const ProjectSectionContainer = styled.button<IListItemSectionContentProps>`
    display: flex;
    justify-content: space-between;
    padding: 9px 0;
    position: relative;
    width: 100%;

    > * {
        position: relative;
        z-index: 2;
    }

    &:before {
        background: transparent;
        content: "";
        height: 100%;
        left: -8px;
        position: absolute;
        top: 0;
        transition: background 0.2s ease;
        width: calc(100% + 16px);
        z-index: 1;
    }

    &:hover {
        &:before {
            background: ${theme.colors.base[100]};
        }
    }

    ${({ active }) => {
        if (!active) {
            return css``;
        }

        return css`
            ${ProjectSectionDetails} {
                span {
                    font-weight: 600;
                }
            }

            &:before {
                background: ${theme.colors.base[100]};
            }
        `;
    }}
`;

export const ProjectSectionWrapper = styled.div`
    align-items: flex-start;
    display: flex;

    ${Checkbox} {
        margin: 3px ${theme.spacing.smallX} 0 0;
    }

    ${CheckboxHelper} {
        height: 12px;
        width: 12px;
    }
`;

export const Material = styled.a``;
