import React, { FunctionComponent, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Services
import history from "services/history";

// Styles
import { Container, Label, Button, ButtonLabel } from "./styles";

// Store
import { Creators as modalActions } from "store/ducks/modal";
import { IReduxStore } from "interfaces/IReduxStore";

type VideoLightRedirectorProps = {
    videoId: number;
};

const VideoLightRedirector: FunctionComponent<VideoLightRedirectorProps> = ({ videoId }) => {
    const dispatch = useDispatch();
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const handleClickLightButton = useCallback(() => {
        dispatch(modalActions.closeModal());
        history.push(`/app/curso/${courseSlug}/video-light/${videoId}`);
    }, [courseSlug, dispatch, videoId]);

    return (
        <Container>
            <Label>Está com problemas?</Label>

            <Button onClick={handleClickLightButton}>
                <ButtonLabel>Versão light</ButtonLabel>
            </Button>
        </Container>
    );
};

export default VideoLightRedirector;
