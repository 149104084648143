import { createActions, createReducer } from "reduxsauce";
import { CredentialsActionsType, ICredentialsActionsCreators, ICredentialsState } from "store/interfaces/ICredentials";

export const { Creators, Types } = createActions<Record<CredentialsActionsType, CredentialsActionsType>, ICredentialsActionsCreators>({
    getCredentialsRequest: [],
    getCredentialsSuccess: ["payload"],
    getCredentialsFailure: [],

    migrateUserRequest: ["payload"],
    migrateUserSuccess: ["payload"],
    migrateUserFailure: ["payload"],

    getOrderProblemsRequest: [],
    getOrderProblemsSuccess: ["payload"],
    getOrderProblemsFailure: [],

    clearCredentials: [],
    clearOrderProblems: [],
    clearCredentialsLoading: []
});

export const INITIAL_STATE: ICredentialsState = {
    isLoading: false,
    hasOrdersProblem: false,
    id: 0,
    email: "",
    facebookId: null,
    imageProfile: "",
    name: "",
    gender: 0,
    phone: null,
    slug: "",
    cpf: "",
    username: "",
    address: [],
    teacher: null,
    timezone: {
        id: 0,
        countryCode: "",
        zoneName: "",
        utcOffset: "",
        utcDstOffset: ""
    },
    student: {
        id: 0,
        brand: {
            id: 0,
            name: "",
            slug: ""
        }
    },
    courses: [],
    isMigrated: false,
    iat: 0,
    exp: 0,
    lastName: null,
    birthDate: null,
    contactEmail: null,
    isTermsAccepted: false,
    imageProfileThumb: null,
    status: 0,
    error: ""
};

const actionRequest = (state = INITIAL_STATE) => ({ ...state, error: "", isLoading: true });

const actionFailure = (state = INITIAL_STATE, action?: any) => ({
    ...state,
    ...(action && action.payload),
    isLoading: false
});

const actionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    error: "",
    isLoading: false
});

const clearCredentials = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

const getOrderProblemsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    hasOrdersProblem: action.payload
});

const clearOrderProblems = (state = INITIAL_STATE) => ({
    ...state,
    hasOrdersProblem: false
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_CREDENTIALS_REQUEST]: actionRequest,
    [Types.GET_CREDENTIALS_SUCCESS]: actionSuccess,
    [Types.GET_CREDENTIALS_FAILURE]: actionFailure,

    [Types.MIGRATE_USER_REQUEST]: actionRequest,
    [Types.MIGRATE_USER_SUCCESS]: actionRequest,
    [Types.MIGRATE_USER_FAILURE]: actionFailure,

    [Types.GET_ORDER_PROBLEMS_REQUEST]: actionRequest,
    [Types.GET_ORDER_PROBLEMS_SUCCESS]: getOrderProblemsSuccess,
    [Types.GET_ORDER_PROBLEMS_FAILURE]: actionFailure,

    [Types.CLEAR_CREDENTIALS]: clearCredentials,
    [Types.CLEAR_CREDENTIALS_LOADING]: actionFailure,
    [Types.CLEAR_ORDER_PROBLEMS]: clearOrderProblems
});
