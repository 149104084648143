import React, { memo } from "react";
import GabaritoIcon from "prodigio-icons/web/filled/Gabarito";
import { AccordionOptionsContainer, AccordionOptionsOptionButton, AccordionOptionsOptions } from "./styles";
import Accordion from "components/Accordion";
import { ISelectOption } from "interfaces/ISelectOption";

interface IProps {
    title: string;
    icon?: React.ReactNode;
    options: ISelectOption[];
    onSelect(value: any): void;
}

const AccordionOptions = memo(({ title, icon = <GabaritoIcon width={16} height={16} />, options = [], onSelect }: IProps) => {
    return (
        <AccordionOptionsContainer>
            <Accordion
                options={[
                    {
                        label: {
                            icon,
                            text: title
                        },
                        content: (
                            <AccordionOptionsOptions>
                                {options.map((option, index) => (
                                    <AccordionOptionsOptionButton key={`option_${index}`} type="button" onClick={() => onSelect(option.value)}>
                                        {option.label}
                                    </AccordionOptionsOptionButton>
                                ))}
                            </AccordionOptionsOptions>
                        )
                    }
                ]}
            />
        </AccordionOptionsContainer>
    );
});

export default AccordionOptions;
