import { theme } from "config/theme";
import ReactSelect from "react-select";
import styled from "styled-components/macro";

const typography = {
    color: theme.colors.base[200],
    fontSize: "15px"
};

export const customStyles = {
    indicatorSeparator: (base: React.CSSProperties) => ({ ...base, display: "none" }),
    control: (base: React.CSSProperties, state: any) => ({
        ...base,
        padding: "5px",
        boxShadow: "none",
        borderRadius: "30px",
        minHeight: "36px",
        flex: 1,
        cursor: "pointer"
    }),
    placeholder: (_base: React.CSSProperties) => typography,
    singleValue: (_base: React.CSSProperties) => typography,
    option: (_base: React.CSSProperties) => ({
        ...typography,
        cursor: "pointer",
        padding: "10px 15px",
        color: theme.colors.base[400],
        ":hover": {
            backgroundColor: theme.colors.base[100]
        }
    }),
    container: (base: React.CSSProperties) => ({
        ...base,
        flex: 1,
        borderRadius: "3px"
        // boxShadow: "0 0 6px 0 rgba(193, 206, 247, 0.18)"
    }),
    menu: (base: React.CSSProperties) => ({
        ...base,
        zIndex: 10
    })
};

export const SelectContainer = styled.div`
    position: relative;
`;

export const SelectLabel = styled.label`
    font-size: 12px;
    font-weight: 600;
    padding-left: 0px;
    line-height: 15px;
    margin-top: 9px;
    margin-bottom: 8px;
    display: block;
    color: ${theme.colors.base[300]};
`;

export const IconWrapper = styled.div`
    fill: ${theme.colors.base[100]};
    transform: rotate(180deg);
    margin-right: 10px;
`;

export const SelectStyled = styled(ReactSelect)<{ error?: string; size?: string }>`
    .react-select__control {
        border-color: ${({ error }) => (error ? theme.colors.system.danger[300] : "#dddddf")};
    }
`;
