import React from "react";
import { useSelector } from "react-redux";

// Components
import ForumMetadata from "screens/Forum/components/metadata";

// Helpers
import { theme } from "config/theme";
import { IForumItem } from "store/interfaces/IForum";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { ForumQuestionContainer } from "./styles";
import * as F from "screens/Forum/components/question-content/styles";

interface IForumQuestion extends IForumItem {
    markColor?: string;
}

const ForumQuestion: React.FC<IForumQuestion> = ({ markColor = theme.colors.brand[300], ...rest }) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <ForumQuestionContainer to={`/app/curso/${courseSlug}/forum/${rest._id}`}>
            <F.ForumQuestionContentContainer>
                {markColor && <F.ForumQuestionContentMark markColor={markColor} />}
                <F.ForumQuestionContentWrapper>
                    <F.ForumQuestionContentText className="ck-content" dangerouslySetInnerHTML={{ __html: rest.content }} />
                    <ForumMetadata
                        answers={rest.answersTotal}
                        user={rest.user?.name}
                        attachments={rest.attachmentsTotal}
                        likes={rest.likesTotal}
                        subject={rest.subject?.name}
                        color={theme.colors.brand[300]}
                        created={rest.created}
                    />
                </F.ForumQuestionContentWrapper>
            </F.ForumQuestionContentContainer>
        </ForumQuestionContainer>
    );
};

export default ForumQuestion;
