import styled, { css } from "styled-components";
import { theme } from "config/theme";
import Card from "components/Card";

export const ExerciseListResultCardContainer = styled(Card)`
    flex-direction: column;
    position: relative;

    border-radius: 4px;
    border: 2px solid ${theme.colors.base[100]};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    @media (min-width: 1024px) {
        margin: 0 8px;
    }
`;

export const ExerciseListResultCardLegend = styled.p`
    color: ${theme.colors.base[500]};
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const ExerciseListResultCardExecutionTimeLegend = styled.p`
    color: ${theme.colors.base[300]};
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    margin-bottom: ${theme.spacing.smallXX};
`;

export const ExerciseListResultCardChartContainer = styled.div<{ isDesktop?: boolean }>`
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;

    ${({ isDesktop }) =>
        isDesktop
            ? css`
                  width: 60%;
                  margin: 0 auto;
              `
            : css`
                  width: 40%;
                  max-width: 150px;
              `};
`;

export const ExerciseListResultCardLegends = styled.div<{ isDesktop?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    align-self: stretch;

    ${({ isDesktop }) =>
        isDesktop
            ? css``
            : css`
                  position: absolute;
                  bottom: ${theme.spacing.small};
                  left: ${theme.spacing.small};
              `};
`;

export const ExerciseListResultCardChartLegend = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[300]};
    display: flex;
    align-items: center;

    :first-child {
        margin-bottom: ${theme.spacing.smallXX};
    }

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExerciseListResultCardChartCenter = styled.div`
    padding-top: ${theme.spacing.small};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ExerciseListResultCardChartLegendDesktop = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};

    ${ExerciseListResultCardExecutionTimeLegend} {
        margin-right: ${theme.spacing.smallX};
        margin-bottom: 0;
    }
`;

export const ExerciseListResultCardAwaitingLegend = styled.strong`
    color: ${theme.colors.base[300]};
    margin-top: ${theme.spacing.small};
`;
