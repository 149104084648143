import styled from "styled-components";
import { theme } from "config/theme";

interface IPlaybackRateProps {
    isActive: boolean;
}

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 20px;
    background: transparent;
    z-index: 5;
`;

export const WrapperIcon = styled.span`
    position: relative;
`;

export const ConfigBalloon = styled.div`
    position: absolute;
    width: 170px;
    padding: 15px 10px;
    height: 75px;
    bottom: -85px;
    right: -10px;

    background: #f2f2f4;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    &:before {
        content: "";
        position: absolute;
        top: -6px;
        right: 8px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #f2f2f4;
    }
`;

export const TextConfig = styled.p`
    font-family: ${theme.typography.family.primary};
    font-size: 10px;
    line-height: 15px;
    color: #4a4e5a;
`;

export const WrapperPlaybackRate = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
`;

export const PlaybackRate = styled.button<IPlaybackRateProps>`
    font-family: ${theme.typography.family.primary};
    font-weight: ${({ isActive }) => (isActive ? "600" : "normal")};
    font-size: 11px;
    line-height: 16px;
    color: ${({ isActive }) => (isActive ? "#F2F2F4" : "#9A9DAD")};
    background: ${({ isActive }) => (isActive ? "#7C8195" : "transparent")};
    border-radius: ${({ isActive }) => (isActive ? "10px" : "none")};
    padding: ${({ isActive }) => (isActive ? "5px 7px" : "none")};

    margin-right: 10px;
`;
