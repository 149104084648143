import React from "react";
import { useSelector } from "store";
import EssaySended from "components/essayCorrection/components/sended";

const ExerciseListEssayDraft: React.FC = () => {
    const composition = useSelector(({ answerCard }) => answerCard?.essayCorrection);

    if (!composition) {
        return null;
    }

    return <EssaySended composition={composition} />;
};

export default ExerciseListEssayDraft;
