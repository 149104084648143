// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    ariaChecked: boolean;
    type: "checkbox" | "switch";
    ariaLabelledby: "label-before" | "label-after";
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    max-width: 100%;
    position: relative;
    align-items: center;

    .native-checkbox {
        width: 0;
        height: 0;
        opacity: 0;
        order: 1;
        position: absolute;
        
        cursor: pointer;
    }

    .label-container {
        display: flex;
        position: relative;
        align-items: center;
        
        cursor: pointer;
    }

    .label-text {
        order: 2;
        
        font-weight: 400;
        font-size: 0.75rem;
        font-style: normal;
        font-family: "Nunito", sans-serif;
        
        letter-spacing: 0.01em;
        line-height: 1.333;
    }

    ${({ type }) =>
        type === "checkbox" &&
        css`
            .checkbox {
                display: flex;
                width: 1em;
                height: 1em;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                order: 1;

                border-width: 0.0625em;
                border-style: solid;
                border-color: ${theme.colors.base[300]};
                border-radius: 0.125em;

                cursor: pointer;
            }

            .checkmark {
                display: block;
                width: 0.3em;
                height: 0.5em;
                transform: rotate(45deg) translate(-0.1em, -0.1em);

                opacity: 0;
                border-right: 0.0625em solid ${theme.colors.white};
                border-bottom: 0.0625em solid ${theme.colors.white};
            }
        `}

    ${({ type }) =>
        type === "switch" &&
        css`
            .switch {
                display: flex;
                width: 28px;
                height: 12px;
                flex-shrink: 0;
                position: relative;
                align-items: center;

                border-radius: 6px;
                background-color: ${theme.colors.base[200]};
            }
            .switch-knob {
                display: block;
                width: 16px;
                height: 16px;
                left: 0;
                position: absolute;

                border-radius: 8px;
                background-color: ${theme.colors.base[100]};
                box-shadow: 0px 1px 2px 0px ${theme.colors.black} 1a;
                transition: left ease-in-out 0.2s, background-color ease 0.2s;
            }
        `}

    ${({ ariaLabelledby }) =>
        ariaLabelledby === "label-after" &&
        css`
            .label-text {
                margin: 0 0 0 0.5em;
            }
        `}

    ${({ ariaLabelledby }) =>
        ariaLabelledby === "label-before" &&
        css`
            .checkbox,
            .switch {
                order: 2;
            }

            .label-text {
                order: 1;
                margin: 0 0.5em 0 0;
            }
        `}

    ${({ ariaChecked, type }) =>
        !!ariaChecked &&
        type === "checkbox" &&
        css`
            .checkbox {
                border-color: ${theme.colors.brand[500]};
                background-color: ${theme.colors.brand[300]};
            }
            .checkmark {
                opacity: 1;
                border-right: 0.0625em solid white;
                border-bottom: 0.0625em solid white;
            }
        `}
    
    ${({ ariaChecked, type }) =>
        !!ariaChecked &&
        type === "switch" &&
        css`
            .switch {
                background-color: ${theme.colors.system.success[200]};
            }
            .switch-knob {
                left: 50%;
                background-color: ${theme.colors.system.success[300]};
            }
        `}    
`;
