// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

// Types
import { ActivityExerciseLayoutDataActivityType } from "./activity-exercise.types";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: calc(100dvh - 50px);
    position: relative;

    @media (min-width: 600px) {
        min-height: calc(100dvh - 60px);
    }
`;

export const HeaderAndContentWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    width: 100%;
    background-color: #fff;
`;

export const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 14px 16px;
    max-width: 1360px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        padding: 26px 24px;
    }
`;

export const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_LARGE} {
        gap: 32px;
    }
`;

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_LARGE} {
        gap: 32px;
    }

    @media (min-width: 1366px) {
        min-width: 336px;
        flex-shrink: 0;
    }
`;

type ContentWrapperStyleProps = {
    activityType: ActivityExerciseLayoutDataActivityType;
    hasScore?: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperStyleProps>`
    max-width: 1360px;
    margin: 0 auto;

    width: 100%;
    background-color: #f0f1f7;
    padding: 24px 16px;

    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 40px 24px;
    }

    ${MediaQueries.BIGGER_THAN_LARGE} {
        display: grid;
        align-items: flex-start;
        grid-gap: 32px;
        grid-template-columns: calc(100% - 336px - 32px) 336px;
        grid-template-areas: "leftWrapper rightWrapper";

        ${LeftWrapper} {
            grid-area: leftWrapper;
        }

        ${RightWrapper} {
            grid-area: rightWrapper;
        }

        ${({ activityType, hasScore }) =>
            !hasScore &&
            activityType !== "exercise-list" &&
            css`
                grid-template-columns: 3fr 1fr;
                grid-template-areas: "leftWrapper leftWrapper";
            `}
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        padding: 48px 24px;
    }
`;

export const FooterWrapper = styled.div`
    display: flex;
    width: 100%;
    background-color: #fff;

    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;
`;

export const FooterContent = styled.div`
    padding: 24px 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;

    max-width: 1360px;
    margin: 0 auto;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 24px;
        flex-direction: row;
    }
`;
