import { CustomListFilterFormData } from "../../../interfaces/IExerciseListCustom";
import { DropdownFilterAvailableControls } from "./composites/FilterForm/types";

/**
 * Check if a WebpDropdownFilter has any checked options.
 */
export const dropdownFilterHasCheckedOptions = (options?: CustomListFilterFormData) => {
    const optionsCheckedStatus: boolean[] = [];

    function isChecked(state: any) {
        state?.options?.forEach((item: any) => {
            if (item && item?.type === DropdownFilterAvailableControls.checkbox) {
                optionsCheckedStatus.push(item.checked);
            }
            isChecked(item);
        });
    }

    isChecked(options);

    return optionsCheckedStatus.some((item) => item === true);
};
