import styled, { css } from "styled-components/macro";
import { Micro, BodyText2, Label } from "assets/styles/global";
import { theme } from "config/theme";
import { KnowledgeAreasResultsItemTitle } from "screens/ExamDayResult/components/KnowledgeAreasResults/styles";

export const ExamDayResultEssayContainer = styled.div<{ onClick?: any }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `};
`;

export const ExamDayResultEssayLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    :first-child {
        margin-bottom: ${theme.spacing.smallX};
    }

    ${KnowledgeAreasResultsItemTitle} {
        ${BodyText2};
    }

    > svg {
        color: ${theme.colors.base[500]};
    }
`;

export const ExamDayResultEssayNote = styled.p`
    display: flex;
    align-items: center;

    ${Micro};
    color: ${theme.colors.base[300]};

    > strong {
        ${Label};
        font-size: ${theme.typography.sizes.medium};
        color: ${theme.colors.brand[300]};
        margin-left: ${theme.spacing.smallXX};
    }
`;

export const ExamDayResultEssayButton = styled.button`
    ${Micro};
    color: ${theme.colors.brand[300]};
    background: transparent;
    border: none;

    :hover {
        color: ${theme.colors.brand[400]};

        > svg {
            color: ${theme.colors.brand[400]};
        }
    }

    > svg {
        color: ${theme.colors.brand[300]};
        margin-left: ${theme.spacing.smallX};
    }
`;
