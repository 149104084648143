import React, { useCallback, useMemo } from "react";
import { ListQuestionsContainer } from "./styles";
import ExerciseListQuestion from "../Question";

import ExerciseListSplash from "../Splash";
import { ExerciseListContentContainer } from "screens/ExerciseList/styles";
import getCurrentAnswer from "screens/ExerciseList/utils/getCurrentAnswer";
import getCurrentQuestionResolutions from "screens/ExerciseList/utils/getCurrentQuestionResolutions";
import IExerciseListScreenresolutions from "screens/ExerciseList/interfaces/IExerciseListScreenresolutions";
import { IQuestion } from "interfaces/IQuestion";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";
import { IAnswerCardItem } from "store/ducks/answerCard";
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";

interface IProps {
    answers: IAnswerCardItem[];
    showAnswers: boolean;
    questionsResolutions: IExerciseListScreenresolutions[];
    isFinished: boolean;
    subjectColor?: string | null;
    knowledgeAreas: IExamKnowledgeAreas[];
    onClickAlternative(data: { questionId: number; alternativeId?: number; discursiveAnswer?: string }): void;
    onClickSeeResolution(data: { resolutions: IExerciseListQuestionResolution[]; question: IQuestion; answer: IAnswerCardItem }): void;
    currentQuestion: IQuestion;
    blockQuestionToAnswer: boolean;
}

const ExerciseListQuestions = ({
    answers,
    showAnswers,
    onClickAlternative,
    questionsResolutions,
    onClickSeeResolution,
    isFinished,
    subjectColor,
    knowledgeAreas = [],
    currentQuestion,
    blockQuestionToAnswer = false
}: IProps) => {
    const currentResolutions = useCallback((questionId: number) => getCurrentQuestionResolutions({ questionsResolutions, questionId }), [
        questionsResolutions
    ]);

    const currentQuestionMemo = useMemo(() => {
        try {
            if (!currentQuestion) {
                throw new Error("question not found");
            }

            const currentAnswer = getCurrentAnswer({ answers, questionId: currentQuestion.id });
            const currentQuestionResolution = currentResolutions(currentQuestion.id);

            return (
                <ExerciseListQuestion
                    subjectColor={subjectColor}
                    key={currentQuestion.id}
                    onClickAlternative={({ alternativeId, discursiveAnswer }) =>
                        onClickAlternative({ questionId: currentQuestion.id, alternativeId, discursiveAnswer })
                    }
                    showAnswer={showAnswers}
                    question={currentQuestion}
                    resolutions={currentQuestionResolution}
                    onClickSeeResolution={() =>
                        onClickSeeResolution({ resolutions: currentQuestionResolution, question: currentQuestion, answer: currentAnswer })
                    }
                    isFinished={isFinished}
                    {...(currentAnswer && { answer: currentAnswer })}
                    blockQuestionToAnswer={blockQuestionToAnswer}
                />
            );
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [
        currentQuestion,
        answers,
        currentResolutions,
        subjectColor,
        showAnswers,
        isFinished,
        blockQuestionToAnswer,
        onClickAlternative,
        onClickSeeResolution
    ]);

    if (!currentQuestion) {
        return <ExerciseListSplash />;
    }

    return (
        <ExerciseListContentContainer>
            <ListQuestionsContainer id="ListQuestionsContainer">{currentQuestionMemo}</ListQuestionsContainer>
        </ExerciseListContentContainer>
    );
};

export default React.memo(ExerciseListQuestions);
