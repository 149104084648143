// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityProgressBarProps } from "./activity-progress-bar.types";

// Styles
import { Container, Progress } from "./activity-progress-bar.styles";

export const ActivityProgressBar: FunctionComponent<ActivityProgressBarProps> = ({ progress, progressColor }) => {
    return (
        <Container className="webapp-progress-bar">
            <Progress color={progressColor} progress={progress} />
        </Container>
    );
};
