import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { Button3 } from "assets/styles/global";
import { theme } from "config/theme";
import { Link } from "react-router-dom";

export const ExamResultDesktopResolutionsDropdownContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;

    > div {
        display: none;
        position: relative;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            display: inline-block;
        }
    }
`;

const ItemStyle = css`
    ${Button3};

    color: ${theme.colors.base[400]};
    display: flex;
    align-items: center;

    > svg {
        margin-left: ${theme.spacing.smallX};
    }

    :hover {
        color: ${theme.colors.base[500]};
    }
`;

export const ExamResultDesktopResolutionsDropdownActiveItem = styled.button<{ active: boolean }>`
    ${ItemStyle}

    padding: 4px 16px;

    ${({ active }) =>
        active &&
        css`
            > svg {
                transform: rotate(180deg);
            }
        `};
`;

export const ExamResultDesktopResolutionsDropdownList = styled.div`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    position: absolute;
    box-shadow: ${theme.shadow[2]};
    z-index: 1;
    width: 100%;
    padding: 8px 16px;
`;

export const ExamResultDesktopResolutionsDropdownListItem = styled(Link)`
    ${ItemStyle};

    :not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }
`;
