// Dependencies
import React, { FunctionComponent, useState, useEffect } from "react";

// Styles
import { Container, ButtonDaySelect } from "./styles";

type WebpButtonIconProps = {
    selectedButton: boolean;
    label: "manhã" | "tarde" | "noite";
    disabled: boolean;
    onClickButtonIcon: (value: "manhã" | "tarde" | "noite") => void;
};

export const WebpButtonIcon: FunctionComponent<WebpButtonIconProps> = ({ onClickButtonIcon, label, selectedButton = false, disabled }) => {
    const [colorFillIcon, setColorFillIcon] = useState<string>();

    const [colorFillIconStar, setColorFillIconStar] = useState<string>();

    const handleClicked = () => {
        onClickButtonIcon(label);

        if (label === "noite" && !selectedButton) {
            setColorFillIcon("#BACAE9");
            setColorFillIconStar("#83899E");
        } else {
            setColorFillIcon("#FFCC66 ");
        }

        if (!!selectedButton) {
            setColorFillIcon("");
            setColorFillIconStar("");
        }
    };

    useEffect(() => {
        window.addEventListener("resetButtonIconStatus", () => {
            setColorFillIcon("");
            setColorFillIconStar("");
        });
    }, []);

    const setSvg =
        label === "manhã" ? (
            <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.7639 16.25C19.5806 15.1712 20.125 13.7329 20.125 12.2226C20.125 8.62671 17.4028 5.75 14 5.75C10.5972 5.75 7.875 8.62671 7.875 12.2226C7.875 13.7329 8.35139 15.1712 9.23611 16.25H18.7639Z"
                    fill={colorFillIcon}
                />

                <path
                    d="M13.9628 4.29249C13.591 4.29249 13.2936 3.99504 13.2936 3.62323V1.16926C13.2936 0.79745 13.591 0.5 13.9628 0.5C14.3346 0.5 14.6321 0.79745 14.6321 1.16926V3.62323C14.6321 3.99504 14.3346 4.29249 13.9628 4.29249Z"
                    fill="#2E4369"
                />

                <path
                    d="M20.5067 7.267C20.358 7.267 20.1349 7.19263 20.0606 7.04391C19.7631 6.74646 19.7631 6.37465 20.0606 6.0772L21.7709 4.36686C22.0683 4.0694 22.4402 4.0694 22.7376 4.36686C23.0351 4.66431 23.0351 5.03612 22.7376 5.33357L20.9529 7.04391C20.8042 7.19263 20.6555 7.267 20.5067 7.267Z"
                    fill="#2E4369"
                />

                <path
                    d="M25.4147 13.8853H22.9607C22.5889 13.8853 22.2914 13.5878 22.2914 13.216C22.2914 12.8442 22.5889 12.5467 22.9607 12.5467H25.4147C25.7865 12.5467 26.0839 12.8442 26.0839 13.216C26.0839 13.5878 25.7865 13.8853 25.4147 13.8853Z"
                    fill="#2E4369"
                />

                <path
                    d="M7.49327 7.267C7.34455 7.267 7.19582 7.19263 7.0471 7.04391L5.26239 5.33357C4.96494 5.03612 4.96494 4.66431 5.26239 4.36686C5.55984 4.0694 5.93166 4.0694 6.22911 4.36686L7.93945 6.0772C8.2369 6.37465 8.2369 6.74646 7.93945 7.04391C7.86508 7.19263 7.71636 7.267 7.49327 7.267Z"
                    fill="#2E4369"
                />

                <path
                    d="M5.03931 13.8853H2.58534C2.21353 13.8853 1.91608 13.5878 1.91608 13.216C1.91608 12.8442 2.21353 12.5467 2.58534 12.5467H5.03931C5.41112 12.5467 5.70857 12.8442 5.70857 13.216C5.70857 13.5878 5.41112 13.8853 5.03931 13.8853Z"
                    fill="#2E4369"
                />

                <path
                    d="M19.54 17.7521H8.45999L8.2369 17.529C7.19582 16.1905 6.60092 14.6289 6.60092 12.9186C6.60092 8.82861 9.87288 5.55666 13.9628 5.55666C18.0528 5.55666 21.3247 8.82861 21.3247 12.9186C21.3247 14.5545 20.7298 16.1905 19.6887 17.529L19.54 17.7521ZM9.12925 16.4136H18.9451C19.6887 15.3725 20.0606 14.1827 20.0606 12.9186C20.0606 9.57224 17.3835 6.89518 14.0372 6.89518C10.6909 6.89518 8.01381 9.57224 8.01381 12.9186C7.93945 14.1827 8.38562 15.3725 9.12925 16.4136Z"
                    fill="#2E4369"
                />

                <path
                    d="M26.4557 17.7521H1.54426C1.17245 17.7521 0.875 17.4547 0.875 17.0829C0.875 16.711 1.17245 16.4136 1.54426 16.4136H26.4557C26.8276 16.4136 27.125 16.711 27.125 17.0829C27.125 17.4547 26.8276 17.7521 26.4557 17.7521Z"
                    fill="#2E4369"
                />

                <path
                    d="M23.2581 20.7266H4.74186C4.37004 20.7266 4.07259 20.4292 4.07259 20.0574C4.07259 19.6856 4.37004 19.3881 4.74186 19.3881H23.2581C23.63 19.3881 23.9274 19.6856 23.9274 20.0574C23.9274 20.4292 23.63 20.7266 23.2581 20.7266Z"
                    fill="#2E4369"
                />
            </svg>
        ) : label === "tarde" ? (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14.875" cy="14" r="7" fill={colorFillIcon} />
                <path
                    d="M14.4379 21.5802C10.1568 21.5802 6.73188 18.1552 6.73188 13.8741C6.73188 9.59297 10.1568 6.16805 14.4379 6.16805C18.7191 6.16805 22.144 9.59297 22.144 13.8741C22.144 18.1552 18.6412 21.5802 14.4379 21.5802ZM14.4379 7.49132C10.9352 7.49132 8.13298 10.2935 8.13298 13.7963C8.13298 17.299 10.9352 20.1012 14.4379 20.1012C17.9407 20.1012 20.7429 17.299 20.7429 13.7963C20.7429 10.3714 17.8628 7.49132 14.4379 7.49132Z"
                    fill="#2E4369"
                />

                <path
                    d="M14.433 4.84478C14.0438 4.84478 13.7325 4.53343 13.7325 4.14423V1.57555C13.7325 1.18636 14.0438 0.875 14.433 0.875C14.8222 0.875 15.1336 1.18636 15.1336 1.57555V4.14423C15.1336 4.53343 14.8222 4.84478 14.433 4.84478Z"
                    fill="#2E4369"
                />

                <path
                    d="M14.433 26.7953C14.0438 26.7953 13.7325 26.4839 13.7325 26.0947V23.526C13.7325 23.1368 14.0438 22.8255 14.433 22.8255C14.8222 22.8255 15.1336 23.1368 15.1336 23.526V26.0947C15.1336 26.4839 14.8222 26.7953 14.433 26.7953Z"
                    fill="#2E4369"
                />

                <path
                    d="M21.2074 7.95837C21.0517 7.95837 20.8182 7.88053 20.7403 7.72485C20.429 7.41349 20.429 7.0243 20.7403 6.71294L22.5306 4.92265C22.842 4.61129 23.2312 4.61129 23.5425 4.92265C23.8539 5.234 23.8539 5.6232 23.5425 5.93455L21.7522 7.72485C21.5966 7.88053 21.363 7.95837 21.2074 7.95837Z"
                    fill="#2E4369"
                />

                <path
                    d="M22.9977 23.6818C22.842 23.6818 22.6085 23.604 22.5307 23.4483L20.7404 21.658C20.429 21.3467 20.429 20.9575 20.7404 20.6461C21.0517 20.3347 21.4409 20.3347 21.7523 20.6461L23.5426 22.4364C23.8539 22.7478 23.8539 23.1369 23.5426 23.4483C23.3869 23.604 23.2312 23.6818 22.9977 23.6818Z"
                    fill="#2E4369"
                />

                <path
                    d="M26.4245 14.8861H23.8558C23.4666 14.8861 23.1552 14.5747 23.1552 14.1855C23.1552 13.7963 23.4666 13.485 23.8558 13.485H26.4245C26.8136 13.485 27.125 13.7963 27.125 14.1855C27.125 14.5747 26.7358 14.8861 26.4245 14.8861Z"
                    fill="#2E4369"
                />

                <path
                    d="M5.79976 23.6818C5.64408 23.6818 5.41056 23.604 5.33272 23.4483C5.02137 23.1369 5.02137 22.7478 5.33272 22.4364L7.12302 20.6461C7.43437 20.3347 7.82357 20.3347 8.13492 20.6461C8.44628 20.9575 8.44628 21.3467 8.13492 21.658L6.34463 23.4483C6.18895 23.604 5.95543 23.6818 5.79976 23.6818Z"
                    fill="#2E4369"
                />

                <path
                    d="M7.66789 7.95839C7.43437 7.95839 7.2787 7.88055 7.12302 7.72487L5.33272 5.93458C5.02137 5.62322 5.02137 5.23403 5.33272 4.92267C5.64408 4.61132 6.03327 4.61132 6.34463 4.92267L8.13492 6.71296C8.44628 7.02432 8.44628 7.41352 8.13492 7.72487C7.97925 7.88055 7.82357 7.95839 7.66789 7.95839Z"
                    fill="#2E4369"
                />

                <path
                    d="M5.01923 14.8861H2.45055C2.06136 14.8861 1.75 14.5747 1.75 14.1855C1.75 13.7963 2.06136 13.485 2.45055 13.485H5.01923C5.40843 13.485 5.71978 13.7963 5.71978 14.1855C5.71978 14.5747 5.40843 14.8861 5.01923 14.8861Z"
                    fill="#2E4369"
                />
            </svg>
        ) : (
            <svg width="25" height="28" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.6187 2.26724C16.6187 1.94224 16.375 1.69849 16.05 1.69849C15.8062 1.69849 15.5625 1.94224 15.5625 2.26724C15.5625 3.72974 14.3437 4.94849 12.8812 4.94849C12.5562 4.94849 12.3125 5.19224 12.3125 5.43599C12.3125 5.76099 12.5562 6.00474 12.8812 6.00474C14.3437 6.00474 15.5625 7.22349 15.5625 8.68599C15.5625 9.01099 15.8062 9.17349 16.05 9.17349C16.375 9.17349 16.6187 9.01099 16.6187 8.68599C16.6187 7.22349 17.8375 6.00474 19.3 6.00474C19.625 6.00474 19.7875 5.76099 19.7875 5.43599C19.7875 5.19224 19.625 4.94849 19.3 4.94849C17.8375 4.94849 16.6187 3.72974 16.6187 2.26724Z"
                    fill={colorFillIconStar}
                />

                <path
                    d="M11.529 22.011C16.8607 22.011 21.3999 18.0455 22.0484 12.8544C22.1204 12.566 21.9043 12.2776 21.6881 12.2055C21.3999 12.1334 21.1117 12.2055 20.9676 12.4218C19.5986 14.4406 17.1489 15.7384 14.6271 15.7384C10.5202 15.7384 7.20591 12.4218 7.20591 8.31218C7.20591 5.78872 8.50283 3.33735 10.5202 1.96747C10.7364 1.82327 10.8805 1.53488 10.7364 1.24648C10.5923 1.03018 10.3761 0.885986 10.0879 0.885986C4.90029 1.60698 0.9375 6.14921 0.9375 11.4845C0.9375 17.2525 5.69285 22.011 11.529 22.011Z"
                    fill={colorFillIcon}
                />

                <path
                    d="M16.0654 9.00359C15.4804 9.00359 15.0417 8.56486 15.0417 7.97989C15.0417 6.95619 14.2374 6.07874 13.1406 6.07874C12.5556 6.07874 12.1169 5.64001 12.1169 5.05504C12.1169 4.47007 12.5556 4.03135 13.1406 4.03135C14.1643 4.03135 15.0417 3.22701 15.0417 2.1302C15.0417 1.54523 15.4804 1.1065 16.0654 1.1065C16.6504 1.1065 17.0891 1.54523 17.0891 2.1302C17.0891 3.15389 17.8934 4.03135 18.9903 4.03135C19.5752 4.03135 20.014 4.47007 20.014 5.05504C20.014 5.64001 19.5752 6.07874 18.9903 6.07874C17.9666 6.07874 17.0891 6.88307 17.0891 7.97989C17.0891 8.56486 16.5773 9.00359 16.0654 9.00359ZM13.7987 5.05504C14.9686 5.27441 15.8461 6.22498 16.0654 7.3218C16.2848 6.15186 17.2354 5.27441 18.3322 5.05504C17.1622 4.83568 16.2848 3.8851 16.0654 2.78829C15.7729 3.95823 14.8955 4.83568 13.7987 5.05504Z"
                    fill="#83899E"
                />

                <path
                    d="M11.5319 22.8235C5.24348 22.8235 0.125 17.705 0.125 11.4897C0.125 5.71313 4.36603 0.887136 9.92324 0.0828032C10.5082 0.00968204 11.0932 0.375288 11.3125 0.887136C11.5319 1.47211 11.3125 2.05707 10.8007 2.42268C8.89954 3.66574 7.7296 5.9325 7.7296 8.27237C7.7296 12.0747 10.8007 15.1458 14.603 15.1458C16.9429 15.1458 19.2096 13.9758 20.4527 12.0747C20.8183 11.5628 21.4033 11.3435 21.9882 11.5628C22.5732 11.7822 22.8657 12.3672 22.7926 12.9521C22.0614 18.5825 17.2354 22.8235 11.5319 22.8235ZM10.1426 1.32586C5.17036 2.05707 1.44118 6.37122 1.44118 11.4897C1.44118 17.0469 5.97469 21.5804 11.5319 21.5804C16.5773 21.5804 20.8914 17.8512 21.6226 12.879C20.1602 15.1458 17.5278 16.5351 14.6761 16.5351C10.1426 16.5351 6.48654 12.879 6.48654 8.3455C6.41342 5.49377 7.87584 2.86141 10.1426 1.32586Z"
                    fill="#83899E"
                />
            </svg>
        );

    return (
        <Container>
            <ButtonDaySelect disabled={disabled} svgSize={label} isSelected={selectedButton} onClick={handleClicked}>
                {setSvg}

                <p className="periodDay">{label}</p>
            </ButtonDaySelect>
        </Container>
    );
};
