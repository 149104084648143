import React, { InputHTMLAttributes } from "react";

// Dependencies
import CheckIcon from "prodigio-icons/web/filled/CheckSuccess";

// Assets
import { CheckboxContainer, CheckboxHelper, CheckboxItem, CheckboxLabel, CheckboxWrapper } from "./styles";

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    value?: string | number;
}

const Checkbox = ({ label, value, checked = false, onChange, ...rest }: ICheckboxProps) => (
    <CheckboxContainer data-test-id="checkbox">
        <CheckboxWrapper>
            <CheckboxItem data-test-id="checkbox-input" type="checkbox" value={value} checked={checked} onChange={onChange} {...rest} />
            <CheckboxHelper>{checked && <CheckIcon data-test-id="checked-icon" width="8" height="6" />}</CheckboxHelper>
        </CheckboxWrapper>
        {label && <CheckboxLabel data-test-id="checkbox-label" checked={checked} dangerouslySetInnerHTML={{ __html: label }} />}
    </CheckboxContainer>
);

export default Checkbox;
