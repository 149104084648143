// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityQuestionIndicatorVariant } from "./activity-question-indicator.types";

type ContainerStyleProps = {
    variant: ActivityQuestionIndicatorVariant;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 24px;
    height: 24px;
    border-radius: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    ${({ variant }) =>
        variant === "danger" &&
        css`
            background-color: #ff8888;
        `}

    ${({ variant }) =>
        variant === "default" &&
        css`
            background-color: #f0f1f7;
        `}
    
    ${({ variant }) =>
        variant === "success" &&
        css`
            background-color: #55dc99;
        `}
`;

type LabelStyleProps = {
    variant: ActivityQuestionIndicatorVariant;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    ${({ variant }) =>
        variant === "danger" &&
        css`
            color: #840000;
        `}

    ${({ variant }) =>
        variant === "default" &&
        css`
            color: #232326;
        `}
    
    ${({ variant }) =>
        variant === "success" &&
        css`
            color: #045332;
        `}
`;
