const browsers = ["Safari", "Firefox", "Edg", "Chrome"] as const;

type Browser = typeof browsers[number];

export const isThisBrowser = (selectedBrowser: Browser) => {
    const browserDetails = window.navigator.userAgent;

    const otherBrowsers = browsers.filter((browserItem) => browserItem !== selectedBrowser);

    let itsNotOneOfOtherBrowsers = true;

    otherBrowsers.forEach((otherBrowser) => {
        if (itsNotOneOfOtherBrowsers) {
            itsNotOneOfOtherBrowsers = !browserDetails?.includes(otherBrowser);
        }
    });

    return browserDetails?.includes(selectedBrowser) && itsNotOneOfOtherBrowsers;
};
