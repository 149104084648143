import styled from "styled-components/macro";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { Button3 } from "assets/styles/global";

export const AppStoresContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.large};

    a {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }
`;

export const MyEssaysEditContainer = styled.section`
    padding-bottom: ${theme.spacing.largeX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-top: ${theme.spacing.largeX};
    }
`;

export const MyEssaysEditHelper = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: ${theme.spacing.large};
    }
`;

export const MyEssaysEditFooter = styled.footer`
    margin-bottom: ${theme.spacing.medium};

    ${MyEssaysEditHelper} {
        margin-top: 0;
    }
`;
