import React from "react";

// Dependencies
import IconAttention from "prodigio-icons/web/filled/Attention";

// Assets
import Edge from "assets/img/logo-edge.png";
import Chrome from "assets/img/logo-chrome.png";
import Safari from "assets/img/logo-safari.png";
import Firefox from "assets/img/logo-firefox.png";
import {
    BrowserWarningContainer,
    BrowserWarningTitle,
    BrowserWarningContent,
    BrowserWarningSuggestions,
    BrowserWarningTxt,
    Browser,
    BrowserName
} from "./styles";

type BrowserWarningProps = {
    browserName: string;
};

const BrowserWarning = ({ browserName }: BrowserWarningProps) => {
    return (
        <BrowserWarningContainer>
            <IconAttention height="40" width="40" />
            <BrowserWarningTitle>Atenção!</BrowserWarningTitle>
            <BrowserWarningContent>
                <p>Seu navegador atual não é compatível ou está desatualizado.</p>
                <p>Para continuar seus estudos, por favor, utilize outro ou baixe sua atualização.</p>
                <p>
                    Seu navegador atual é: <strong>{browserName}</strong>
                </p>
            </BrowserWarningContent>
            <BrowserWarningTxt>
                Conheça os navegadores compatíveis <span>(em suas versões mais atualizadas)</span>
            </BrowserWarningTxt>
            <BrowserWarningSuggestions>
                <Browser>
                    <img alt="Google Chrome" src={Chrome} />
                    <BrowserName>Chrome</BrowserName>
                </Browser>
                <Browser>
                    <img alt="Mozilla Firefox" src={Firefox} />
                    <BrowserName>Firefox</BrowserName>
                </Browser>
                <Browser>
                    <img alt="Microsoft Edge" src={Edge} />
                    <BrowserName>Edge</BrowserName>
                </Browser>
                <Browser>
                    <img alt="Safari" src={Safari} />
                    <BrowserName>Safari</BrowserName>
                </Browser>
            </BrowserWarningSuggestions>
        </BrowserWarningContainer>
    );
};

export default BrowserWarning;
