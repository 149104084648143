import React from "react";
import { theme } from "config/theme";
import CheckIcon from "prodigio-icons/web/filled/CheckSuccess";

const proenemMock = [
    {
        recommended: true,
        name: "Plano Acelera Proenem",
        timeOut: "6 meses",
        url: "https://proenem.com.br/planos/aceleracao-outubro/contratar",
        price: {
            value: 24.9,
            oldValue: 0,
            installments: 12
        },
        credits: [
            {
                value: 20,
                name: "Aulas ao vivo por semana"
            },
            {
                value: <CheckIcon width={13} />,
                name: "Monitorias ilimitadas"
            },
            {
                value: <CheckIcon width={13} />,
                name: "Banco de Exercícios"
            },
            {
                value: 3,
                name: "Redações corrigidas por mês"
            },
            {
                value: <CheckIcon width={13} />,
                name: "Acesso aos Simulados"
            },
            {
                value: <CheckIcon width={13} />,
                name: "Todos os Projetos Proenem"
            }
        ]
    }
    // {
    //     recommended: true,
    //     name: "Intensivo Setembro Plus",
    //     timeOut: "6 meses",
    //     url: "https://proenem.com.br/planos/plano-setembro-2020-plus/contratar",
    //     price: {
    //         value: 24.9,
    //         oldValue: 0,
    //         installments: 12
    //     },
    //     credits: [
    //         {
    //             value: 40,
    //             name: "Aulas ao vivo por semana"
    //         },
    //         {
    //             value: 2,
    //             name: "Simulados"
    //         },
    //         {
    //             value: 3,
    //             name: "Redações corrigidas por mês"
    //         }
    //     ]
    // },
    // {
    //     recommended: false,
    //     name: "Prodígio Plus",
    //     timeOut: "12 meses",
    //     url: "https://proenem.com.br/planos/Prodigio-2020-Plus/contratar",

    //     price: {
    //         value: 24.9,
    //         oldValue: 493.5,
    //         installments: 12
    //     },
    //     credits: [
    //         {
    //             value: 30,
    //             name: "Aulas ao vivo por semana"
    //         },
    //         {
    //             value: 5,
    //             name: "Simulados"
    //         },
    //         {
    //             value: 2,
    //             name: "Redações corrigidas por mês"
    //         }
    //     ]
    // }
];

const promilitaresMock = [
    {
        recommended: true,
        name: "Intensivo ESA + EEAR",
        timeOut: "6 meses ou até as provas",
        url: "https://promilitares.com.br/assinar/sargento-militar/intensivo-eear-e-esa",
        price: {
            value: 84.9,
            oldValue: 0,
            installments: 6
        },
        credits: [
            {
                value: <CheckIcon width={13} />,
                name: "Acesso liberado por 6 meses ou até as provas"
            },

            {
                value: <CheckIcon width={13} />,
                name: "Exercícios exclusivos"
            },

            {
                value: <CheckIcon width={13} />,
                name: "Acesso ilimitado as nossas monitorias"
            },

            {
                value: 8,
                name: "aulas específicas aos sábados"
            },

            {
                value: <CheckIcon width={13} />,
                name: "Plano de estudo extensivo EEAR e ESA"
            },

            {
                value: <CheckIcon width={13} />,
                name: "Conteúdo intensivo exclusivo"
            },

            {
                value: <CheckIcon width={13} />,
                name: "Acesso a nova plataforma Promilitares"
            },
            {
                value: 1,
                name: "crédito de redação"
            }
        ]
    }
];

export const mockPaywall = theme.project.slug === "proenem" ? proenemMock : promilitaresMock;
