import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { fadein, fadeInUp } from "assets/styles/animations";
import { BodyText1, BodyText2, Button3, CardContainerSmallStyle, Label, Micro, TitleH5 } from "assets/styles/global";

export const EssayCanvasContainer = styled.div`
    animation: ${fadein} 0.25s linear;
    position: relative;
`;

export const EssayCanvasWrapper = styled.div`
    background: repeating-linear-gradient(${theme.colors.base[100]} 0 1px, transparent 1px 100%);
    background-size: 100% 30px;
    background-position-y: -1px;
    height: 900px;
    margin: 0 auto;
    position: relative;
    width: 740px;
`;

export const EssayCanvasHelper = styled.textarea`
    appearance: none;
    background: transparent;
    color: ${theme.colors.base[200]};
    font-family: "Droid Sans Mono", monospace;
    line-height: 30px;
    height: 100%;
    outline: none;
    overflow: hidden;
    resize: none;
    text-align: justify;
    word-break: break-word;
    white-space: pre-wrap;
    width: 100%;
`;

export const EssayCanvasPrint = styled.div`
    appearance: none;
    background: transparent;
    color: ${theme.colors.base[200]};
    font-family: "Droid Sans Mono", monospace;
    line-height: 30px;
    outline: none;
    overflow: hidden;
    resize: none;
    text-align: justify;
    word-break: break-word;
    white-space: pre-wrap;
    width: 780px;
    height: 896px;
    position: relative;
    padding-left: 30px;
    padding-right: 10px;
    top: -906px;
    left: -30px;
    z-index: -1;
`;

export const EssayCanvasLines = styled.ul`
    left: -${theme.spacing.large};
    position: absolute;
    top: 1px;
    height: 100%;
`;

export const EssayCanvasLinesItem = styled.li`
    color: ${theme.colors.base[200]};
    font-size: 12px;
    line-height: 30px;
    text-align: right;
`;

export const EssayCanvasNav = styled.nav`
    align-items: center;
    display: flex;
    margin-top: ${theme.spacing.large};

    ${ButtonWrapper} {
        &:first-child {
            margin-right: ${theme.spacing.small};
        }

        &:last-child {
            margin-left: auto;
        }
    }
`;

const EssayAttachTextStyles = css`
    ${BodyText1};
    color: ${theme.colors.base[400]};
`;

export const EssayAttachContainer = styled.div`
    animation: ${fadein} 0.25s linear;
    display: flex;
`;

export const EssayAttachCol = styled.div`
    flex: 1;
    padding: ${theme.spacing.small} ${theme.spacing.large};

    &:not(:last-child) {
        border-right: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    }
`;

export const EssayAttachText = styled.p`
    ${EssayAttachTextStyles};
`;

export const EssayAttachWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
`;

export const EssayQrCodeWrapper = styled.div`
    align-items: center;
    display: flex;
`;

export const EssayQrcode = styled.figure`
    border: 1px solid ${theme.colors.base[200]};
    border-radius: ${theme.spacing.smallX};
    margin-right: ${theme.spacing.medium};
    padding: ${theme.spacing.small};

    img {
        max-width: 130px;
    }
`;

export const EssayQrCodeHelper = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
`;

export const EssayAttachInstrucions = styled.ul`
    list-style: disc;
    margin-bottom: ${theme.spacing.large};
`;
export const EssayAttachInstrucionsItem = styled.li`
    ${EssayAttachTextStyles};
    list-style: disc;
    list-style-position: inside;
`;

export const DropzonePreview = styled.figure`
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`;

export const EssayPreviewContainer = styled.div`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: ${theme.spacing.smallXX};
    height: 100%;
    left: 0;
    min-width: 300px;
    padding: ${theme.spacing.largeX} ${theme.spacing.small} ${theme.spacing.small};
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 9999992;

    ${MediaQueries.SMALL} {
        animation: ${fadeInUp} 0.25s linear;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        animation: ${fadein} 0.25s linear;
        height: unset;
        left: 50%;
        padding: ${theme.spacing.large};
        transform: translate(-50%, -50%);
        top: 50%;
        width: calc(100% - 112px);
    }
`;

export const EssayPreviewCloseButton = styled.button`
    padding: ${theme.spacing.small};
    position: absolute;
    right: 0;
    top: 0;

    svg {
        display: block;
    }
`;

export const EssayPreviewHelper = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: 16px;
        font-weight: 600;
    }
`;

export const EssayPreviewCanvas = styled.figure`
    align-items: center;
    background: #fafafc;
    border: 1px solid ${theme.colors.base[200]};
    border-radius: ${theme.spacing.smallX};
    display: flex;
    height: calc(100% - 142px);
    max-height: calc(100% - 142px);
    justify-content: center;
    margin-bottom: ${theme.spacing.small};
    overflow: hidden;

    img {
        display: block;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    p {
        font-style: italic;
        padding: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: calc(100% - 111px);
        max-height: calc(100% - 111px);
        margin-bottom: ${theme.spacing.large};
    }
`;

export const EssayPreviewNav = styled.nav`
    ${ButtonWrapper} {
        &:not(:last-child) {
            margin-bottom: ${theme.spacing.smallX};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: center;
        display: flex;

        ${ButtonWrapper} {
            &:not(:last-child) {
                margin: 0 ${theme.spacing.small} 0 0;
            }
        }
    }
`;

export const SpinnerWrapper = styled.div`
    padding: ${theme.spacing.small};
`;

export const EssayCardDetailsContainer = styled.div`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${CardContainerSmallStyle};
        padding: ${theme.spacing.small};
    }
`;

export const EssayCardDetailsHeader = styled.header`
    border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    margin-bottom: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.small};
`;

export const EssayCardDetailsHeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
`;

export const EssayCardDetailsHeaderTitle = styled(TitleH5)`
    align-items: center;
    display: flex;

    &:before {
        background: ${theme.colors.secondary[200]};
        border-radius: 5px;
        content: "";
        display: block;
        height: 24px;
        margin-right: 12px;
        width: 4px;
    }
`;

export const EssayCardDetailsHeaderHelper = styled.span`
    ${Label};
    color: ${theme.colors.system.success[400]};
`;

export const EssayCardDetailsTitle = styled.h2`
    ${Button3};
    color: ${theme.colors.base[400]};
    padding-left: ${theme.spacing.small};
`;

export const EssayCardDetailsContent = styled.div`
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 0;
    }
`;

export const EssayCardDetailsContentHelper = styled.p`
    color: ${theme.colors.base[400]};
    font-size: 12px;
`;

export const EssayCardDetailsList = styled.ul`
    margin-bottom: ${theme.spacing.small};
    padding: 0 ${theme.spacing.small};
`;

export const EssayCardDetailsListItem = styled.li`
    ${Micro};
    color: ${theme.colors.base[300]};
    list-style: disc;
    list-style-position: inside;

    span {
        margin-left: -${theme.spacing.smallXX};
    }

    strong {
        color: ${theme.colors.base[500]};
        font-weight: normal;

        svg {
            width: ${theme.typography.sizes.medium};
        }
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const EssayCardDetailsFooterHelper = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: ${theme.spacing.large};
    }
`;

export const EssayCardDetailsFooter = styled.footer`
    margin-bottom: ${theme.spacing.medium};

    ${EssayCardDetailsFooterHelper} {
        margin-top: 0;
    }
`;

export const EssayCardDetailsFooterList = styled.nav`
    margin-bottom: ${theme.spacing.medium};
`;

export const EssayCardDetailsFooterListItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    svg {
        color: ${theme.colors.base[500]};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
        margin-bottom: ${theme.spacing.small};
        padding-bottom: ${theme.spacing.small};
    }
`;

export const EssayCardDetailsFooterListItemWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;

    span {
        ${BodyText2};
        color: #000000;
        margin-left: ${theme.spacing.small};
    }
`;

export const InputWrapper = styled.div`
    position: relative;

    > input {
        appearance: none;
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
`;
