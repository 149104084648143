// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    IconWrapperSkeleton,
    OffensiveDaysWrapper,
    OffensiveDaysDescriptionSkeleton,
    OffensiveDaysContentSkeleton
} from "./performance-offensive.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceOffensiveSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <IconWrapperSkeleton>
                <Skeleton width="100%" height="100%" borderRadius="50%" />
            </IconWrapperSkeleton>

            <Spacing size={12} direction="horizontal" />

            <OffensiveDaysWrapper>
                <OffensiveDaysDescriptionSkeleton>
                    <Skeleton width="177px" height="100%" borderRadius="4px" />
                </OffensiveDaysDescriptionSkeleton>

                <OffensiveDaysContentSkeleton>
                    <Skeleton width="160px" height="100%" borderRadius="4px" />
                </OffensiveDaysContentSkeleton>
            </OffensiveDaysWrapper>
        </Container>
    );
};
