// Dependencies
import styled, { css, keyframes } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

const slideIn = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0%);
    }
`;

const slideOut = keyframes`
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(100%);
    }
`;

type ContainerStyleProps = {
    isFullyAdaptative?: boolean;
    width?: number;
    isOpened: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    height: 100vh;
    padding: 16px;
    background-color: #fff;

    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    z-index: 9999;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 40px;

        ${({ width, isFullyAdaptative }) =>
            width &&
            !isFullyAdaptative &&
            css`
                width: ${width}px;
            `}

        ${({ isFullyAdaptative }) =>
            !!isFullyAdaptative &&
            css`
                width: 100%;
            `}
            
        ${({ isOpened }) =>
            isOpened
                ? css`
                      animation: ${slideIn} 0.4s forwards;
                  `
                : css`
                      animation: ${slideOut} 0.4s forwards;
                  `}
    }

`;

export const Title = styled.p`
    color: #000;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;
