import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Types as EssayTypes } from "store/ducks/essay";

import { IReduxStore } from "interfaces/IReduxStore";
import Theme from "./theme";

interface IThemeScreen extends RouteComponentProps<{ id: string }> {}

const ThemeScreen = (props: IThemeScreen) => {
    const dispatch = useDispatch();
    const [isThemeFetched, setIsThemeFetched] = useState(false);
    const theme = useSelector(({ essay }: IReduxStore) => essay.themes.theme);

    const getTheme = useCallback(() => {
        const { id } = props.match.params;

        if (theme.idTheme === Number(id)) {
            return;
        }

        if (id) {
            dispatch({ type: EssayTypes.GET_ESSAY_THEME_REQUEST, payload: { id } });
            setIsThemeFetched(true);
        }
    }, [dispatch, props.match.params, theme.idTheme]);

    useEffect(() => {
        if (!isThemeFetched) {
            getTheme();
        }
    }, [dispatch, getTheme, isThemeFetched]);

    useEffect(() => {
        return () => {
            dispatch({ type: EssayTypes.CLEAR_ESSAY_THEME });
        };
    }, [dispatch]);

    return <Theme {...theme} />;
};

export default withRouter(ThemeScreen);
