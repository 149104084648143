import { BodyText1 } from "assets/styles/global";
import { theme } from "config/theme";
import styled from "styled-components";
import { ColorTaggedItemName } from "components/ColorTaggedExerciseList/styles";

export const SubjectGroupContainer = styled.div`
    margin: ${theme.spacing.small} 0;

    ${ColorTaggedItemName} {
        flex: 1;
        display: flex;

        margin-right: ${theme.spacing.small};

        strong {
            flex: 1;
            font-weight: 400;
        }
    }
`;

export const ExtraExerciseListScreenHomeTitle = styled.h2`
    ${BodyText1};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;
