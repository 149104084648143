import api from "../../services/api";
import { Types } from "../ducks/auth";
import apiMongo from "services/api-mongo";
import history from "../../services/history";
import { call, put, takeLatest, select } from "redux-saga/effects";
import { Creators as authActions } from "../ducks/auth";
import { Creators as alertActions } from "../ducks/alert";
import { Creators as courseActions } from "../ducks/course";
import { Creators as modalActions } from "../ducks/modal";
import { Creators as lessonPlanActions } from "../ducks/lesson-plan";
import { Creators as credentialsActions } from "../ducks/credentials";
import { IRecoverPasswordPayload, IResetPasswordPayload, ISignUpPayload, IRegisterPasswordPayload } from "interfaces/IToken";
import { showAlertError } from "utils/showAlertError";
import { navigation, theme } from "config/theme";
import { ReduxStore } from "store/ducks";
import { menuFormatter } from "helpers/menu-formatter";
import { parseJwt } from "utils/parse-jwt";
import { isDevelopment } from "utils/isDevelopment";

// Middleware for Login
function* login(action: any) {
    try {
        const { data: tokenResponse } = yield call(api.post, "/token", action.payload, { timeout: 120000 });

        try {
            const { data: onboardingResponse } = yield call(api.get, `${process.env.REACT_APP_ONBOARDING_API_URL}/onboarding/check`, {
                params: {
                    brand: theme.project.slug
                },
                headers: {
                    Token: tokenResponse.token
                }
            });

            if (onboardingResponse.token) {
                const { name } = parseJwt(tokenResponse.token);

                const formattedToken = encodeURIComponent(onboardingResponse.token);

                let onboardingUrl = `https://onboarding.${isDevelopment() ? "dev." : ""}${theme.project.slug}.com.br/ativo?token=${formattedToken}`;

                if (!!name) {
                    onboardingUrl = onboardingUrl.concat(`&nome=${name}`);
                }

                window.open(onboardingUrl, "_self");
            }
        } catch {
            api.defaults.headers.common["Authorization"] = yield `Bearer ${tokenResponse.token}`;
            apiMongo.defaults.headers.common["Authorization"] = yield `Bearer ${tokenResponse.token}`;

            yield put(authActions.loginSuccess(tokenResponse));

            yield put(credentialsActions.getCredentialsRequest());

            yield put(authActions.getMenuRequest());
        }
    } catch (error) {
        yield put(authActions.loginFailure());

        yield showAlertError(error, "Não conseguimos fazer login!");
    }
}

function* layers(action: any) {
    try {
        const { data } = yield call(api.post, "/token/layers", action.payload, { timeout: 120000 });

        api.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;
        apiMongo.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;

        yield put(authActions.layersSuccess(data));

        yield put(credentialsActions.getCredentialsRequest());

        yield put(authActions.getMenuRequest());
    } catch (error) {
        yield put(authActions.layersFailure());

        yield call(history.push, { pathname: "/" });

        yield showAlertError(error, "Não foi possível logar automaticamente!");
    }
}

// SIGNUP
function* signup(action: { type: string; payload: ISignUpPayload }) {
    try {
        const { recaptchaToken, ...rest } = action.payload;

        yield call(api.post, "/token/new", rest, {
            headers: {
                "x-recaptcha": recaptchaToken
            }
        });

        const payload = {
            email: action.payload.email,
            password: action.payload.password
        };

        yield put(authActions.loginRequest(payload));

        const brandSlug = theme.project.slug;

        if (!["proenem", "promilitares", "promedicina"].includes(brandSlug) || process.env.NODE_ENV !== "production") return;

        const tagSlug = {
            proenem: "pe",
            promilitares: "pm",
            promedicina: "pmed"
        } as any;

        yield call(
            api.post,
            `${process.env.REACT_APP_ACTIVE_CAMPAIGN_URL}/active`,
            {
                contact: {
                    firstName: action.payload.name,
                    email: action.payload.email
                },
                contactTag: {
                    tag: `${tagSlug[brandSlug]}_cadastro_site`
                }
            },
            { headers: { "Content-Type": "application/json" } }
        );
    } catch (error) {
        yield put(authActions.signUpFailure());
        yield put(alertActions.showAlert(error.response?.data?.detail || "Ocorreu um erro ao concluir seu cadastro", "danger"));
    }
}

function* registerPassword(action: { type: string; payload: IRegisterPasswordPayload }) {
    try {
        const { password, email, token, recaptchaToken } = action.payload;

        yield call(
            api.patch,
            "/token/recoverpassword",
            {
                email,
                code: token,
                newPassword: password
            },
            {
                headers: {
                    "x-recaptcha": recaptchaToken
                }
            }
        );

        yield put(authActions.registerPasswordSuccess());
        yield put(alertActions.showAlert("Senha cadastrada com sucesso.", "success"));
        yield call(history.push, { pathname: "/" });
    } catch (error) {
        yield put(authActions.registerPasswordFailure());
        yield put(alertActions.showAlert(error.response?.data?.detail || "Ocorreu um erro ao concluir o cadastro da senha", "danger"));
    }
}

// SIGNIN WITH FACEBOOK
function* loginFacebook(action: any) {
    try {
        const { data } = yield call(api.post, "/facebook/token", `access_token=${action.payload.access_token}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });

        if (!data.token || !data.credentials) {
            throw new Error("Error com o usuário.");
        }

        api.defaults.headers.common.Authorization = yield `Bearer ${data.token}`;

        yield put(authActions.loginSuccess(data));
        yield put(credentialsActions.getCredentialsRequest());
    } catch (error) {
        yield put(authActions.loginFailure());
        yield put(alertActions.showAlert(error.response?.data?.detail?.message || "Não conseguimos fazer login.", "danger"));
    }
}

// RECOVER PASSWORD
function* recoverPassword(action: { type: string; payload: IRecoverPasswordPayload }) {
    try {
        const { recaptchaToken, ...rest } = action.payload;

        yield call(api.post, "/token/forgotpassword", rest, {
            headers: {
                "x-recaptcha": recaptchaToken
            }
        });

        yield put(authActions.recoverPasswordSuccess());
        yield put(alertActions.showAlert("Pronto! Enviamos uma mensagem para seu email", "success"));
    } catch (error) {
        yield put(authActions.loginFailure());
        yield showAlertError(error, "Ocorreu um erro com sua solicitação. Tente novamente.");
    }
}

// RESET PASSWORD
function* resetPassword(action: { type: string; payload: IResetPasswordPayload }) {
    try {
        yield call(api.patch, "/token/recoverpassword", action.payload);

        yield put(authActions.resetPasswordSuccess());
        yield put(alertActions.showAlert("Nova senha cadastrada com sucesso.", "success"));
        yield call(history.push, { pathname: "/" });
    } catch (error) {
        yield put(authActions.loginFailure());
        yield showAlertError(error, "Ocorreu um erro com sua solicitação. Tente novamente.");
    }
}

// Middleware for refresh token
function* refreshToken(action: any) {
    try {
        const { data } = yield call(api.post, "/token/refreshtoken", action.payload);

        api.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;
        apiMongo.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;

        yield put(authActions.refreshTokenSuccess(data));
    } catch (error) {
        yield put(authActions.refreshTokenFailure());
        yield put(alertActions.showAlert(error.response?.data?.detail?.message || "Não conseguimos fazer login!", "danger"));
    }
}

// LOGOUT
function* logout(action: any) {
    delete api.defaults.headers.common.Authorization;

    yield put(modalActions.closeModal());
    yield put(courseActions.clearCourse());
    yield put(lessonPlanActions.clearLessonPlan());
    yield put(lessonPlanActions.clearLastVisitedDateUrl());
    yield put(credentialsActions.clearCredentials());
    yield put({ type: "CLEAR_STUDENT_AREA" });

    yield put(authActions.getMenuSuccess(navigation));

    if (!!action?.payload) {
        yield put(authActions.setRedirectPath(action?.payload));
    }

    yield call(history.push, { pathname: "/" });

    sessionStorage.clear();
}

function* getMenu() {
    try {
        const { courseSlug, isWeekPlansPublished } = yield select(({ course, weekPlans }: ReduxStore) => ({
            courseSlug: course?.slug,
            isWeekPlansPublished: !!weekPlans?.isPublished
        }));

        const menus = menuFormatter(theme.project.slug, courseSlug, navigation, isWeekPlansPublished);

        yield put(authActions.getMenuSuccess(menus));
    } catch (error) {
        console.log("error menu", error);
    }
}

function* getPlatformSearch(action: any) {
    try {
        const { data } = yield call(api.get, `https://survey.prodigioeducacao.com/${action?.payload?.courseSlug}`);

        yield put(authActions.platformSearchSuccess({ ...data, hasAnswered: data?.answered, hasSearch: true }));
    } catch (error) {
        yield put(authActions.platformSearchFailure());
    }
}

export default [
    takeLatest(Types.LOGIN_REQUEST, login),
    takeLatest(Types.LAYERS_REQUEST, layers),
    takeLatest(Types.LOGOUT, logout),
    takeLatest(Types.SIGN_UP_REQUEST, signup),
    takeLatest(Types.REGISTER_PASSWORD_REQUEST, registerPassword),
    takeLatest(Types.RECOVER_PASSWORD_REQUEST, recoverPassword),
    takeLatest(Types.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(Types.REFRESH_TOKEN_REQUEST, refreshToken),
    takeLatest(Types.LOGIN_FACEBOOK, loginFacebook),
    takeLatest(Types.GET_MENU_REQUEST, getMenu),
    takeLatest(Types.PLATFORM_SEARCH_REQUEST, getPlatformSearch),
    takeLatest("persist/REHYDRATE", getMenu)
];
