import React, { useState, useCallback, useRef, useMemo } from "react";

import {
    ExerciseStartCard,
    ExerciseStartClose,
    ExerciseStartLegend,
    ExerciseStartLegendWrap,
    ExerciseStartSwitch,
    ExerciseStartSwitchItem,
    ExerciseStartSwitchWrap
} from "./styles";

import Button from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import useOnClickOutside from "hooks/use-on-click-outside";

import CloseIcon from "prodigio-icons/web/outlined/Close";
import TimerIcon from "prodigio-icons/web/filled/Timer";
import CheckIcon from "prodigio-icons/web/filled/Gabarito";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    hasRealizations: boolean;
    exerciseListId: number;
    subjectSlug: string;
    previousExamId?: number;
    previousExamDayId?: number;
    projectId?: number;
    moduleId?: string;
    onClose(): void;
}

const StartExerciseList = ({
    hasRealizations,
    exerciseListId,
    onClose,
    subjectSlug,
    previousExamId,
    previousExamDayId,
    projectId,
    moduleId
}: IProps) => {
    const [mode, setMode] = useState<AnswerCardRealizationMode>(AnswerCardRealizationMode.Study);
    const ref = useRef<any>(null);

    const dispatch = useDispatch();

    const isStudyMode = !mode;

    const isLoading = useSelector<IReduxStore>((state) => state.answerCard.isLoading);

    const isPreviousExam = useMemo(() => !!previousExamId && !!previousExamDayId, [previousExamDayId, previousExamId]);

    const handleMobileStartExerciseList = useCallback(
        () =>
            dispatch({
                type: "POST_ANSWER_CARD_REQUEST",
                payload: {
                    id: exerciseListId,
                    mode: mode ? AnswerCardRealizationMode.Proof : AnswerCardRealizationMode.Study,
                    subjectSlug,
                    previousExamId,
                    previousExamDayId,
                    projectId,
                    moduleId
                }
            }),
        [dispatch, exerciseListId, mode, moduleId, previousExamDayId, previousExamId, projectId, subjectSlug]
    );

    useOnClickOutside(ref, () => onClose());

    return (
        <ExerciseStartCard ref={ref} data-test-id="start-exercise-list-modal">
            {!isLoading && (
                <ExerciseStartClose onClick={onClose}>
                    <CloseIcon width={14} height={14} />
                </ExerciseStartClose>
            )}

            <ExerciseStartSwitchWrap>
                <ExerciseStartSwitch data-test-id="start-exercise-list-modal-switch-button">
                    <ExerciseStartSwitchItem
                        active={mode === AnswerCardRealizationMode.Study}
                        onClick={() => setMode(AnswerCardRealizationMode.Study)}
                    >
                        Modo estudo
                    </ExerciseStartSwitchItem>
                    <ExerciseStartSwitchItem
                        active={mode === AnswerCardRealizationMode.Proof}
                        onClick={() => setMode(AnswerCardRealizationMode.Proof)}
                    >
                        Modo prova
                    </ExerciseStartSwitchItem>
                </ExerciseStartSwitch>
            </ExerciseStartSwitchWrap>

            <ExerciseStartLegendWrap>
                <ExerciseStartLegend>
                    <TimerIcon width={16} height={18} />
                    <span>
                        {isStudyMode
                            ? `Tempo livre para a execução da ${isPreviousExam ? "prova" : "lista"}`
                            : `Com cronômetro ${isPreviousExam ? "regressivo" : "progressivo"} ao longo da ${isPreviousExam ? "prova" : "lista"}`}
                    </span>
                </ExerciseStartLegend>
                <ExerciseStartLegend>
                    <CheckIcon width={18} height={18} />
                    <span>
                        {isStudyMode
                            ? "Gabarito ao final de cada questão respondida"
                            : `Gabarito após finalizar a ${isPreviousExam ? "prova" : "lista de exercício"}`}
                    </span>
                </ExerciseStartLegend>
            </ExerciseStartLegendWrap>

            <Button
                variant="primary"
                onClick={handleMobileStartExerciseList}
                isLoading={!!isLoading}
                block
                size="medium"
                data-test-id="start-exercise-list-modal-start"
            >
                {hasRealizations ? `Refazer ${isPreviousExam ? "prova" : "lista"}` : `Resolver ${isPreviousExam ? "prova" : "lista"}`}
            </Button>
        </ExerciseStartCard>
    );
};

export default React.memo(StartExerciseList);
