import { IFilterGroup } from "interfaces/IFilterGroup";

export function formatFilterToQueryString(filters: IFilterGroup[], divider = ";") {
    try {
        const activeFiltes = filters.filter((filter) => !!filter.items && !!filter.items.length && !!filter.items.some((item) => !!item.checked));

        let queryString = "";

        for (let i = 0; i < activeFiltes.length; i++) {
            const delimiter = !!queryString.length ? divider : "";
            const currentFilter = activeFiltes[i];

            if (!currentFilter) {
                continue;
            }

            const currentFilterValues = currentFilter.items?.filter((item) => !!item.checked).map((item) => item.value) || [];

            if (!currentFilterValues.length) {
                continue;
            }

            queryString = `${queryString}${delimiter}${activeFiltes[i].slug}=[${currentFilterValues.join(",")}]`;
        }

        return queryString;
    } catch (error) {
        return "";
    }
}
