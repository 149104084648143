import { Link } from "react-router-dom";
import Card from "components/Card";
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export const ExerciseListDashboardAnswerCard = styled(Card)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
`;

export const ExerciseListDashboardAnswerCardResultHeader = styled.dt`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ExerciseListDashboardAnswerCardResultTitle = styled.h3`
    font-size: 14px;
    color: ${theme.colors.brand[300]};
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
`;

export const ExerciseListDashboardAnswerCardResultLegendBadge = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        font-size: ${theme.typography.sizes.small};
        font-weight: 700;
        color: ${theme.colors.system.info[500]};
        background-color: ${theme.colors.system.info[100]};
        padding: ${theme.spacing.smallXX} ${theme.spacing.smallX};
        border-radius: 40px;
    }
`;

export const ExerciseListDashboardAnswerCardResultLegendItem = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    line-height: 20px;
    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;

    margin-top: ${theme.spacing.smallXX};
`;

export const ExerciseListDashboardAnswerCardResultExerciseDataFooter = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.small};
`;

export const ExerciseListDashboardAnswerCardResultTime = styled.div`
    display: flex;
    align-items: center;

    span {
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: ${theme.colors.base[400]};

        margin-left: ${theme.spacing.smallX};
    }
`;

const resultButtonStyle = css`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;

    span {
        font-family: ${theme.typography.family.secondary};
        font-weight: 600;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 20px;
        color: ${theme.colors.base[400]};
        margin-right: ${theme.spacing.smallXX};
    }

    svg {
        fill: ${theme.colors.brand[300]};
        color: ${theme.colors.brand[300]};
    }
`;

export const ExerciseListDashboardAnswerCardResultButton = styled.button`
    ${resultButtonStyle};
`;

export const ExerciseListDashboardAnswerCardResultLink = styled(Link)`
    ${resultButtonStyle};
`;

export const ExerciseListDashboardAnswerCardResultNote = styled.div`
    display: flex;
    margin: ${theme.spacing.medium} 0;
    flex-direction: column;
`;

export const ExerciseListDashboardAnswerCardResultPoints = styled.strong``;
