interface IProps {
    answers: any[];
    questionId: number;
}

const getQuestionMarkedAlternativeId = ({ questionId, answers }: IProps) => {
    try {
        if (!questionId || !answers) {
            throw new Error();
        }

        const currentQuestion = answers.find((item) => item.question.id === questionId);

        if (!currentQuestion) {
            throw new Error("question not marked or not objective");
        }

        return currentQuestion.objectiveAnswer;
    } catch (error) {
        return undefined;
    }
};

export default getQuestionMarkedAlternativeId;
