import React, { memo, useState, useEffect, useCallback } from "react";
import { theme } from "config/theme";
import history from "services/history";
import Button from "components/Button";
import { Grid, Row, Col } from "components/Grid";
import Spinner from "components/Spinner";

import { SelectedStartedCareer } from "../LessonPlanCustom/Wizard/components/SelectedStartedCareer";

import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";

import { WrapperContentCardsSectionCareers } from "./styles";
import {
    LessonPlanCustomHeaderContainer,
    LessonPlanCustomHeader,
    LessonPlanCustomTitle,
    LessonPlanCustomSubTitle,
    LessonPlanCustomFooterContainer,
    LessonPlanCustomFooterContent
} from "../LessonPlanCustom/Wizard/styles";

const SelectPlan = () => {
    const [selectedPlan, setSelectedPlan] = useState("");
    const dispatch = useDispatch();

    const { lessonPlanCustom, isLoadingLessonPlanCustom, courseSlug } = useSelector(({ lessonPlanCustom, course }: IReduxStore) => ({
        ...lessonPlanCustom,
        courseSlug: course.slug
    }));

    const isBrandPromilitares = theme.project.brand === "promilitares";
    const isBrandPromedicina = theme.project.brand === "promedicina";

    useEffect(() => {
        dispatch(lessonPlanCustomActions.getLessonPlanCustomRequest());
    }, [dispatch]);

    const handleNavigate = useCallback(() => {
        if (!selectedPlan) {
            return;
        }

        if (selectedPlan === "suggested-plan") {
            dispatch(lessonPlanCustomActions.postLessonPlanSelectionRequest({ selection: 2 }));
        }

        if (selectedPlan === "custom-plan" && !!lessonPlanCustom.length) {
            return history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-como-iniciar` });
        }

        if (selectedPlan === "custom-plan" && !lessonPlanCustom.length) {
            return history.push({
                pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/${
                    isBrandPromilitares || isBrandPromedicina ? "configure-seu-plano" : "escolha-sua-carreira"
                }`
            });
        }
    }, [selectedPlan, lessonPlanCustom.length, dispatch, courseSlug, isBrandPromilitares, isBrandPromedicina]);

    if (isLoadingLessonPlanCustom) {
        return <Spinner />;
    }

    return (
        <Grid fluid>
            <WrapperContentCardsSectionCareers>
                <Row style={{ minWidth: "320px" }}>
                    <Col xs={12} sm={12} md={12}>
                        <LessonPlanCustomHeaderContainer>
                            <LessonPlanCustomHeader>
                                <LessonPlanCustomTitle>Bem-vindo ao plano de estudos!</LessonPlanCustomTitle>
                            </LessonPlanCustomHeader>
                            <LessonPlanCustomSubTitle>Configure um plano de estudos adaptado à sua rotina.</LessonPlanCustomSubTitle>
                        </LessonPlanCustomHeaderContainer>
                    </Col>
                </Row>

                <Col xs={12} sm={10} md={8} lg={7}>
                    <SelectedStartedCareer onHandleSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} />
                </Col>

                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <LessonPlanCustomFooterContainer>
                            <LessonPlanCustomFooterContent>
                                <Button disabled={!selectedPlan} type="button" variant="info" size="medium" onClick={handleNavigate}>
                                    Continuar
                                </Button>
                            </LessonPlanCustomFooterContent>
                        </LessonPlanCustomFooterContainer>
                    </Col>
                </Row>
            </WrapperContentCardsSectionCareers>
        </Grid>
    );
};

export default memo(SelectPlan);
