import rocket from "./rocket.svg";
import brain from "./brain.svg";

const data = [
    {
        id: 1,
        image: rocket,
        resultRestart: true,
        slug: "Começar do zero",
        labelTitle: "Começar do zero",
        description: "Inicie o seu estudo do zero, com um novo plano de estudo.",
        variant: "primary",
        active: false
    },
    {
        id: 2,
        image: brain,
        resultRestart: false,
        slug: "Continuar com o plano atual",
        labelTitle: "Continuar com o plano atual",
        description: "Continue com o plano personalizado exclusivamente para você.",
        variant: "primary",
        active: false
    }
];

export default data;
