import styled from "styled-components";
import { theme } from "config/theme";

export const ExerciseListSplashContainer = styled.div`
    padding: ${theme.spacing.large} 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 50px;
        margin-bottom: ${theme.spacing.small};
    }

    strong,
    .sadIcon {
        margin-bottom: ${theme.spacing.smallX};
    }
`;
