import { TitleH5 } from "assets/styles/global";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { IReduxStore } from "interfaces/IReduxStore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as S from "./styles";

interface IProps {
    onClose(): void;
}

const ExternalSchoolClass: React.FC<IProps> = ({ onClose }) => {
    const [loading, setLoading] = useState(false);
    const externalSchoolClasses = useSelector((state: IReduxStore) => state?.course?.externalSchoolClasses ?? []);

    useEffect(() => {
        if (!externalSchoolClasses?.length) {
            onClose();
        }
        if (externalSchoolClasses?.length === 1) {
            setLoading(true);
            window.location.href = externalSchoolClasses[0].externalURL;
            const link = document.createElement("a");
            link.href = externalSchoolClasses[0].externalURL;
            document.body.appendChild(link);
            link.click();
        }
    }, [externalSchoolClasses, onClose]);

    return (
        <S.ExternalSchoolClassContainer>
            {loading ? (
                <Spinner fixed={false} />
            ) : (
                <>
                    <TitleH5>Escolha uma sala de aula.</TitleH5>

                    <div>
                        {externalSchoolClasses?.map((link) => (
                            <Button
                                type="button"
                                as="a"
                                variant="outline-primary"
                                key={`link_${link.name?.trim()}`}
                                href={link.externalURL}
                                target="_blank"
                            >
                                {link.name}
                            </Button>
                        ))}
                    </div>
                </>
            )}
        </S.ExternalSchoolClassContainer>
    );
};

export default ExternalSchoolClass;
