import api from "services/api";
import keys from "lodash/keys";
import { ReduxAction } from "store";
import { AxiosResponse } from "axios";
import history from "services/history";
import apiMongo from "services/api-mongo";
import { Types } from "store/ducks/forum";
import { ParsedQuery } from "query-string";
import sanitizeString from "helpers/sanitize-string";
import { IReduxStore } from "interfaces/IReduxStore";
import { IForumNewPayload } from "screens/Forum/new/new";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import {
    IComplaint,
    IComplaintPayload,
    IForumComment,
    IForumItem,
    IForumQuestion,
    IForumQuestionAnswer,
    IForumSubject,
    IForumUpload,
    ILikePayload,
    UploadType
} from "store/interfaces/IForum";
import { theme } from "config/theme";
import { headers } from "services/api";

const XBrand = theme.project.brand;

const getUploads = (state: IReduxStore) => state.forum.uploads;

function* getAllQuestions(action: ReduxAction<{ page: number; query?: ParsedQuery<any>; isResetedRequest?: boolean }>) {
    try {
        const { isResetedRequest, page, query } = action.payload;

        const whereClause = query || {};

        const where = keys(whereClause)
            .filter((item) => item !== "sort")
            .map((item) => {
                if (item.includes("subject.id")) {
                    return `${item}=[${whereClause[item]}]`;
                }

                return `${item}=${whereClause[item]}`;
            })
            .join(";");

        const { data }: AxiosResponse<IForumItem> = yield call(apiMongo.get, `/question`, {
            headers: {
                "X-Page": page || 1,
                "X-PerPage": 10,
                "X-Order": query?.sort || "-created",
                "X-Where": where || "myquestions=false"
            }
        });

        yield put({ type: Types.GET_ALL_QUESTIONS_SUCCESS, payload: { data, isResetedRequest } });
    } catch (error) {
        console.log("error", error);
        yield put({ type: "SHOW_ALERT", message: "Não foi possível carregar as perguntas", alertType: "danger" });
        yield put({ type: Types.GET_ALL_QUESTIONS_FAILURE, payload: error?.response?.data });
    }
}

function* getQuestion(action: ReduxAction<{ id: string }>) {
    try {
        const { data }: AxiosResponse<IForumQuestion> = yield call(apiMongo.get, `/question/${action.payload.id}`);

        const payload: IForumQuestion = {
            ...data,
            content: sanitizeString(data.content, "&nbsp;"),
            answers: data.answers
                ? data.answers.map((answer) => ({
                      ...answer,
                      content: sanitizeString(answer.content, "&nbsp;"),
                      comments: answer.comments
                          ? answer.comments.filter(Boolean).map((comment) => ({
                                ...comment,
                                content: sanitizeString(comment.content, "&nbsp;")
                            }))
                          : [],
                      attachments: answer.attachments ? answer.attachments.filter(Boolean) : []
                  }))
                : [],
            attachments: data.attachments ? data.attachments.filter(Boolean) : []
        };

        yield put({ type: Types.GET_QUESTION_SUCCESS, payload });
    } catch (error) {
        console.log("error", error);
        yield put({ type: "SHOW_ALERT", message: "Não foi possível carregar a pergunta", alertType: "danger" });
        yield put({ type: Types.GET_QUESTION_FAILURE, payload: error?.response?.data });
    }
}

function* getForumSubjects() {
    try {
        const { data }: AxiosResponse<IForumSubject[]> = yield call(api.get, "/student/forum/subject");

        const filters = {
            id: 1,
            title: "Disciplinas",
            slug: "disciplinas",
            items: data.map((item) => ({
                value: item.id,
                label: item.name,
                color: item.color
            }))
        };

        yield put({ type: Types.GET_FORUM_SUBJECTS_SUCCESS, payload: [filters] });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Não foi possível obter os temas disponíveis.", alertType: "danger" });
        yield put({ type: Types.GET_FORUM_SUBJECTS_FAILURE, payload: error?.response?.data });
    }
}

function* uploadAttachments(action: ReduxAction<{ key: UploadType; attachments: File[] }>) {
    try {
        const { attachments, key } = action.payload;

        const requests = attachments.map((item) => {
            return (function*() {
                const uploads = yield select(getUploads);
                const upload: IForumUpload | undefined = uploads[key].find((item2: IForumUpload) => item2.file.name === item.name);

                try {
                    if (!upload) {
                        throw new Error();
                    }

                    if (upload.status === "success" || !upload.file.size) {
                        return {
                            ...upload,
                            status: "success"
                        };
                    }

                    const formData = new FormData();

                    formData.append("files", item);

                    const { data } = yield call(apiMongo.post, "/attachment/upload", formData, { headers: { "x-company": "prodigio" } });

                    return {
                        ...upload,
                        status: "success",
                        attachment: data[0]._id
                    };
                } catch (e) {
                    return {
                        ...upload,
                        status: "error"
                    };
                }
            })();
        });

        const responses = yield all(requests);

        yield put({ type: Types.UPLOAD_ATTACHMENTS_SUCCESS, payload: { key, attachments: responses } });
    } catch (error) {
        console.log("error", error);
        yield put({ type: Types.UPLOAD_ATTACHMENTS_FAILURE });
    }
}

function* retryUploadAttachments(action: ReduxAction<{ key: UploadType; attachment: IForumUpload }>) {
    const uploads = yield select(getUploads);
    const { attachment, key } = action.payload;

    try {
        const formData = new FormData();

        formData.append("files", attachment.file);

        const { data } = yield call(apiMongo.post, "/attachment/upload", formData, { headers: { "x-company": "prodigio" } });

        const upload = {
            ...attachment,
            status: "success",
            attachment: data[0]._id
        };

        const attachments = uploads[key].map((item: IForumUpload) => {
            if (item.id === attachment.id) {
                return upload;
            }

            return item;
        });

        yield put({ type: Types.RETRY_UPLOAD_ATTACHMENTS_SUCCESS, payload: { key, attachments } });
    } catch (error) {
        console.log("error", error);

        const attachments = uploads[key].map((item: IForumUpload) => {
            if (item.id === attachment.id) {
                return {
                    ...item,
                    status: "error"
                };
            }

            return item;
        });

        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro ao tentar fazer o upload novamente.", alertType: "danger" });
        yield put({ type: Types.RETRY_UPLOAD_ATTACHMENTS_FAILURE, payload: { key, attachments } });
    }
}

function* createOrUpdateQuestion(action: ReduxAction<IForumNewPayload>) {
    try {
        const { id, ...rest } = action.payload;

        const isPost = !Boolean(id);

        const url = isPost ? "/question" : `/question/${id}`;
        const apiMethod = isPost ? apiMongo.post : apiMongo.patch;

        const { data }: AxiosResponse<IForumQuestion> = yield call(apiMethod, url, rest, headers);

        yield put({ type: Types.CREATE_OR_UPDATE_QUESTION_SUCCESS, payload: data });
        yield put({ type: "SHOW_ALERT", message: `Pergunta ${isPost ? "criada" : "editar"} com sucesso`, alertType: "success" });

        if (isPost) {
            const courseSlug = yield select((state: IReduxStore) => state.course.slug);
            yield call(history.push, { pathname: `/app/curso/${courseSlug}/forum/${data._id}` });
        }
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Não foi possível criar a questão. Tente novamente", alertType: "danger" });
        yield put({ type: Types.CREATE_OR_UPDATE_QUESTION_FAILURE, payload: error?.response?.data });
    }
}

function* toggleLike(action: ReduxAction<ILikePayload>) {
    try {
        const { endpoint, id, method } = action.payload;
        const axiosMethod = method === "POST" ? apiMongo.post : apiMongo.delete;

        const { data: value }: AxiosResponse<boolean> = yield call(axiosMethod, endpoint);

        const isQuestion = endpoint.includes("question");

        yield put({ type: Types.LIKE_SUCCESS, payload: { value, id: !isQuestion ? id : undefined } });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro. Tente novamente", alertType: "danger" });
        yield put({ type: Types.LIKE_FAILURE });
    }
}

function* createComplaint(action: ReduxAction<IComplaintPayload>) {
    try {
        const { data }: AxiosResponse<IComplaint> = yield call(apiMongo.post, "/student/complaint", action.payload);

        yield put({ type: Types.CREATE_COMPLAINT_SUCCESS, payload: data });
        yield put({ type: "CLOSE_MODAL" });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro. Tente novamente", alertType: "danger" });
        yield put({ type: Types.CREATE_COMPLAINT_FAILURE });
    }
}

function* createOrUpdateAnswer(action: ReduxAction<{ question: string; content: string; attachments: string[]; id?: string }>) {
    try {
        const { id, ...rest } = action.payload;

        const isPost = !Boolean(id);

        const url = isPost ? "/answer" : `/answer/${id}`;
        const apiMethod = isPost ? apiMongo.post : apiMongo.patch;

        const { data }: AxiosResponse<IForumQuestionAnswer> = yield call(apiMethod, url, rest, headers);

        yield put({ type: Types.CREATE_OR_UPDATE_ANSWER_SUCCESS, payload: { data, id } });
        yield put({ type: "SHOW_ALERT", message: "Resposta enviada com sucesso", alertType: "success" });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro. Tente novamente", alertType: "danger" });
        yield put({ type: Types.CREATE_OR_UPDATE_ANSWER_FAILURE });
    }
}

function* createOrUpdateComment(action: ReduxAction<{ answer: string; headers: string; content: string; attachments: string[]; id?: string }>) {
    try {
        const { id, answer, headers, ...rest } = action.payload;

        const isPost = !Boolean(id);

        const url = isPost ? "/comment" : `/comment/${id}`;
        const apiMethod = isPost ? apiMongo.post : apiMongo.patch;

        const { data }: AxiosResponse<IForumComment> = yield call(
            apiMethod,
            url,
            {
                ...rest,
                ...(!id && { answer })
            },
            {
                headers: {
                    "x-brand": XBrand
                }
            }
        );

        yield put({ type: Types.CREATE_OR_UPDATE_COMMENT_SUCCESS, payload: { data, answer, id } });
        yield put({ type: "SHOW_ALERT", message: "Resposta enviada com sucesso", alertType: "success" });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro. Tente novamente", alertType: "danger" });
        yield put({ type: Types.CREATE_OR_UPDATE_COMMENT_FAILURE });
    }
}

function* deleteArtefact(action: ReduxAction<{ id: string; artefact: string; idArtefact: string }>) {
    try {
        const { artefact, ...rest } = action.payload;

        yield call(apiMongo.delete, `/${artefact}/${action.payload.id}`);

        const type = action.type.replace("REQUEST", "SUCCESS");

        yield put({ type, payload: rest });
        yield put({ type: "SHOW_ALERT", message: "Conteúdo apagado com sucesso", alertType: "success" });
    } catch (error) {
        const type = action.type.replace("REQUEST", "FAILURE");

        yield put({ type });
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro ao apagar o conteúdo", alertType: "danger" });
    }
}

function* deleteQuestion(action: ReduxAction<{ id: string }>) {
    try {
        yield call(apiMongo.delete, `/question/${action.payload.id}`);
        yield put({ type: Types.DELETE_QUESTION_SUCCESS });

        yield put({ type: "SHOW_ALERT", message: "Pergunta apagada com sucesso", alertType: "success" });

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, { pathname: `/app/curso/${courseSlug}/forum` });
    } catch (error) {
        yield put({ type: "SHOW_ALERT", message: "Ocorreu um erro ao apagar a pergunta", alertType: "danger" });
        yield put({ type: Types.DELETE_QUESTION_FAILURE });
    }
}

export default [
    takeLatest(Types.LIKE_REQUEST, toggleLike),
    takeLatest(Types.GET_QUESTION_REQUEST, getQuestion),
    takeLatest(Types.CREATE_OR_UPDATE_ANSWER_REQUEST, createOrUpdateAnswer),
    takeLatest(Types.CREATE_OR_UPDATE_COMMENT_REQUEST, createOrUpdateComment),
    takeLatest(Types.CREATE_OR_UPDATE_QUESTION_REQUEST, createOrUpdateQuestion),
    takeLatest(Types.CREATE_COMPLAINT_REQUEST, createComplaint),
    takeLatest(Types.GET_ALL_QUESTIONS_REQUEST, getAllQuestions),
    takeLatest(Types.GET_FORUM_SUBJECTS_REQUEST, getForumSubjects),
    takeLatest(Types.UPLOAD_ATTACHMENTS_REQUEST, uploadAttachments),
    takeLatest(Types.RETRY_UPLOAD_ATTACHMENTS_REQUEST, retryUploadAttachments),
    takeLatest(Types.DELETE_QUESTION_REQUEST, deleteQuestion),
    takeLatest(Types.DELETE_COMMENT_REQUEST, deleteArtefact),
    takeLatest(Types.DELETE_ANSWER_REQUEST, deleteArtefact)
];
