import React, { useCallback, useEffect } from "react";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Creators as qrCodeActions } from "store/ducks/qrCode";

//tyes
import { Item } from "store/interfaces/IQrCode";
import { IReduxStore } from "interfaces/IReduxStore";

//components
import Spinner from "components/Spinner";
import ComplementIcon from "prodigio-icons/web/filled/Complement";
import Header from "../../Header";
import { Col, Row, Grid } from "components/Grid";
import { WebpItemMaterials } from "components/WebpItemMaterials";
import { WebpDropdownFull } from "components/WebpDropdownFull";

import * as S from "../styles";

import { Creators as modalActions } from "store/ducks/modal";

const DropdownExerciceList = () => {
    const { exerciceListId }: any = useParams();

    const dispatch = useDispatch();
    const { qrCodeExerciseList, isLoadingExerciseList } = useSelector(({ qrCode }: IReduxStore) => qrCode);

    const getExerciseList = useCallback(() => {
        if (!!exerciceListId) {
            dispatch(qrCodeActions.getQrCodeExerciseListRequest({ exerciseListId: exerciceListId }));
        }
    }, [dispatch, exerciceListId]);

    useEffect(() => {
        getExerciseList();
    }, [getExerciseList]);

    const handleClickSeeQuestionResolution = useCallback(
        (data: Item) => {
            const { question } = data;
            const { resolutions } = question;

            if (!resolutions || !resolutions.length || !question) {
                throw new Error();
            }

            dispatch(modalActions.openModal("resolutionExerciseList", { resolutions, question }));
        },
        [dispatch]
    );

    if (isLoadingExerciseList) {
        return <Spinner />;
    }

    return (
        <Grid>
            <Row between="md">
                <Col xs={12}>
                    <>
                        <Header title={qrCodeExerciseList.title} subtitle="Listagem de todas as resoluções deste tópico abaixo" />
                        <S.Container>
                            <WebpDropdownFull
                                headerComposition={
                                    <S.ContentHeader>
                                        <S.Icon>
                                            <ComplementIcon />
                                        </S.Icon>
                                        <S.Text>Lista de resoluções</S.Text>
                                    </S.ContentHeader>
                                }
                                contentComposition={
                                    <S.Content slot="content">
                                        {qrCodeExerciseList.items?.map((itemMaterials) => (
                                            <WebpItemMaterials
                                                key={itemMaterials.id}
                                                text={`Questão ${itemMaterials.order}`}
                                                onClickItem={() => handleClickSeeQuestionResolution(itemMaterials)}
                                            />
                                        ))}
                                    </S.Content>
                                }
                            ></WebpDropdownFull>
                        </S.Container>
                    </>
                </Col>
            </Row>
        </Grid>
    );
};

export default DropdownExerciceList;
