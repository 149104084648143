import React, { useState, useCallback, useMemo, TouchEvent } from "react";

// assets
import ErrorIcon from "prodigio-icons/web/filled/Error";
import SuccessIcon from "prodigio-icons/web/filled/Sucess";
import {
    ExerciseListAlternativeItem,
    ExercuseListAlternativeContent,
    ExerciseListAlternativeLetterContainer,
    ExerciseListAlternativeRightContainer,
    ExerciseListAlternativeEnunciation,
    ExerciseListAlternativeIgnoreButton,
    ExerciseListAlternativeProgressBar,
    ExerciseListAlternativeProgressPercent,
    ExerciseListAlternativeDiscartLabel,
    ExerciseListAlternativeContainer
} from "./styles";

// components
import ProgressBar from "components/ProgressBar";
import ExerciseListLetter from "components/Letter";

// helpers
import { theme } from "config/theme";
import Enunciation from "components/Enunciation";

interface IProps {
    isFinished: boolean;
    averageHitRate: number | string;
    currentLetter: string;
    showProgressBar: boolean;
    enunciation: string;
    progressBarLabel: React.ReactNode;
    answerStatus?: {
        isMarked?: boolean;
        isCorrect?: boolean;
        isWrong?: boolean;
    };
    showAnswer: boolean;
    onMark(): void;
    showDiscardLabel: boolean;
}

interface IAlternativeStyle {
    right?: number;
    left?: number;
}

// NESSE COMPONENTE TODAS OS ITEMS PRECISAM FICAR EM MEMOS POR CONTA DA RENDERIZAÇÃO NO MOBILE

const ExerciseListQuestionAlternative = ({
    currentLetter,
    answerStatus,
    onMark,
    showProgressBar,
    averageHitRate,
    enunciation,
    progressBarLabel,
    isFinished,
    showAnswer,
    showDiscardLabel
}: IProps) => {
    const [alternativeStyle, setAlternativeStyle] = useState<IAlternativeStyle>(); // ESTILOS PARA ALTERNATIVA
    const [styleNumber, setStyleNumber] = useState(0); // VALOR DE PIXELS CALCULADO PARA MOVIMENTAR A ALTERNATIVA
    const [spacedPixels, setSpacedPixels] = useState(0); // VALOR DE PIXELS QUE O USUARIO ARRASTOU

    const [isIgnored, setIsIgnored] = useState(false);

    const handleTouchMove = useCallback(
        (event: TouchEvent<HTMLDivElement>) => {
            try {
                if (isFinished) {
                    return;
                }

                const touch = event.changedTouches[0];

                const td_x = touch.pageX - spacedPixels;

                if ((td_x < 0 ? td_x * -1 : td_x) < 15) {
                    return;
                }

                setStyleNumber(td_x);

                if (td_x < 0) {
                    // ARRASTANDO PARA ESQUERDA
                    setAlternativeStyle({
                        right: td_x * -1 // TRANSFORMANDO O NUMERO EM POSITIVO
                    });
                } else {
                    // ARRASTANDO PARA DIRETA
                    setAlternativeStyle({
                        left: td_x
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        [isFinished, spacedPixels]
    );

    const handleTouchEnd = useCallback(() => {
        try {
            if (!alternativeStyle || isFinished) {
                return;
            }

            // total de pixels que precisam ser arrastados
            const halftWidth = 130;

            if (!!alternativeStyle.right && alternativeStyle.right >= halftWidth) {
                setIsIgnored(true);
            }

            if (!!alternativeStyle.left && alternativeStyle.left >= halftWidth) {
                setIsIgnored(false);
            }

            setAlternativeStyle(undefined);

            setStyleNumber(0);
        } catch (error) {
            console.log(error);
        }
    }, [alternativeStyle, isFinished]);

    const handleTouchStart = useCallback(
        (event: TouchEvent<HTMLDivElement>) => {
            try {
                if (isFinished) {
                    return;
                }

                const touch = event.changedTouches[0];
                setSpacedPixels(touch.pageX);
            } catch (error) {
                console.log(error);
            }
        },
        [isFinished]
    );

    // ###############
    // MEMO COMPONENTS
    // ###############

    const memoContent = useMemo(
        () => (
            <ExercuseListAlternativeContent id="ExercuseListAlternativeContent">
                <ExerciseListAlternativeLetterContainer>
                    <ExerciseListLetter
                        // disabled={isIgnored}
                        letter={currentLetter}
                        onClick={onMark}
                        {...answerStatus}
                        {...(showAnswer && !!answerStatus?.isCorrect && { id: "correct" })}
                        {...(showAnswer && !!answerStatus?.isWrong && { id: "incorrect" })}
                    />
                </ExerciseListAlternativeLetterContainer>

                <ExerciseListAlternativeRightContainer>
                    <ExerciseListAlternativeEnunciation>
                        <Enunciation enunciation={enunciation} />
                    </ExerciseListAlternativeEnunciation>

                    {showProgressBar && (
                        <ExerciseListAlternativeProgressBar>
                            <ProgressBar
                                color={theme.colors.system.info[200]}
                                value={Number(averageHitRate)}
                                responsive={true}
                                label={<>{progressBarLabel}</>}
                            />

                            <ExerciseListAlternativeProgressPercent data-test-id="exercise-list-alternative-averageHitRate">
                                {Number(averageHitRate)}%
                            </ExerciseListAlternativeProgressPercent>
                        </ExerciseListAlternativeProgressBar>
                    )}
                </ExerciseListAlternativeRightContainer>

                {showDiscardLabel && (
                    <ExerciseListAlternativeIgnoreButton onClick={() => setIsIgnored(!isIgnored)} variant={isIgnored ? "success" : "danger"}>
                        {isIgnored ? (
                            <>
                                <SuccessIcon width={18} height={18} />
                                Considerar alternativa
                            </>
                        ) : (
                            <>
                                <ErrorIcon width={18} height={18} />
                                Descartar alternativa
                            </>
                        )}
                    </ExerciseListAlternativeIgnoreButton>
                )}
            </ExercuseListAlternativeContent>
        ),
        [currentLetter, onMark, answerStatus, showAnswer, enunciation, showProgressBar, averageHitRate, progressBarLabel, showDiscardLabel, isIgnored]
    );

    // DiscarLabel (mobile)
    const memoSucessIcon = useMemo(() => <SuccessIcon width={18} height={18} />, []);
    const memoErrorIcon = useMemo(() => <ErrorIcon width={18} height={18} />, []);

    const memoDiscartLabel = useMemo(
        () => (
            <ExerciseListAlternativeDiscartLabel success={styleNumber > 0}>
                {styleNumber > 0 ? <>{memoSucessIcon} Considerar alternativa</> : <>Descartar alternativa {memoErrorIcon}</>}
            </ExerciseListAlternativeDiscartLabel>
        ),
        [styleNumber, memoSucessIcon, memoErrorIcon]
    );

    return (
        <ExerciseListAlternativeContainer>
            <ExerciseListAlternativeItem
                isIgnored={!answerStatus && isIgnored}
                id="ExerciseListAlternativeItem"
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                {...(showDiscardLabel && alternativeStyle)}
                {...(showAnswer && !!answerStatus?.isCorrect && { id: "correct-alternative" })}
            >
                {memoContent}
            </ExerciseListAlternativeItem>

            {showDiscardLabel && memoDiscartLabel}
        </ExerciseListAlternativeContainer>
    );
};

export default React.memo(ExerciseListQuestionAlternative);
