import React, { useMemo, useCallback, memo, useContext, useState } from "react";

// Dependencies
import { string, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import getCep from "cep-promise";
import _findIndex from "lodash/findIndex";

// Components
import Button from "components/Button";
import TextField from "components/Form/TextField";
import ZipCodeDropdown from "components/Dropdown/zip";
import DropdownIndicator from "screens/MigrationUser/components/indicator";

// Helpers
import { Step2Fields } from "../types";
import { MASK_ZIPCODE } from "helpers";
import { MigrationUserContext } from "../context";
import { addHyphenToZipCode } from "utils/addHyphenToZipCode";
import { UFOptions } from "utils/UFOptions";

// Assets
import { StepForm, StepTitle, StepFormGroup, StepWrapper, StepFormGroupWrapper, SelectWrapper } from "../styles";

const ZIP_CODE_REGEX = /^\d{5}-\d{3}$/;

const schema = object().shape({
    city: string().required("Campo obrigatório"),
    zipCode: string().matches(ZIP_CODE_REGEX, "CEP Inválido"),
    street: string().required("Campo obrigatório"),
    number: string().required("Campo obrigatório"),
    neighborhood: string().required("Campo obrigatório"),
    complement: string().nullable(),
    state: string().required("Campo obrigatório")
});

const Step2: React.FC = () => {
    const [disabledFields, setDisabledFields] = useState<string[]>([]);

    const {
        state,
        dispatch,
        methods: { changeStep }
    } = useContext(MigrationUserContext);

    const INITIAL_STATE = useMemo(() => {
        if (state.payload.step2) {
            return {
                ...state.payload.step2,
                zipCode: addHyphenToZipCode(state.payload.step2.zipCode)
            };
        }

        const address = state.credentials?.address?.[0];

        return {
            zipCode: address ? addHyphenToZipCode(address.zipCode) : "",
            street: address?.street || "",
            number: address?.number || "",
            complement: "",
            neighborhood: address?.neighborhood || "",
            city: address?.city || "",
            state: address?.state || ""
        };
    }, [state.credentials, state.payload.step2]);

    const { control, handleSubmit, errors, setValue, reset } = useForm<Step2Fields>({
        mode: "all",
        defaultValues: INITIAL_STATE,
        resolver: yupResolver(schema)
    });

    const verifyResponse = useCallback((data: any) => {
        const { logradouro, bairro, cidade, estado } = data;
        const fields: string[] = [];

        if (logradouro) {
            fields.push("street");
        }

        if (bairro) {
            fields.push("neighborhood");
        }

        if (cidade) {
            fields.push("city");
        }

        if (estado) {
            fields.push("state");
        }

        return setDisabledFields((prev) => [...prev, ...fields]);
    }, []);

    const handleGetAddress = useCallback(
        async (event: React.FocusEvent<HTMLInputElement>) => {
            const inputCep = event.target.value;

            try {
                const data = await getCep(inputCep);

                verifyResponse(data);

                setValue("city", data.city, { shouldValidate: true, shouldDirty: true });
                setValue("neighborhood", data.neighborhood, { shouldValidate: true, shouldDirty: true });
                setValue("street", data.street, { shouldValidate: true, shouldDirty: true });
                setValue("state", data.state, { shouldValidate: true, shouldDirty: true });
            } catch (error) {
                reset();
                setDisabledFields([]);
                console.log(error);
            }
        },
        [reset, setValue, verifyResponse]
    );

    const handleSaveForm = useCallback(
        (data: Step2Fields) => {
            const address = state.credentials?.address?.[0];

            dispatch({ type: "TOGGLE_LOADER", payload: true });

            try {
                dispatch({
                    type: "SET_PAYLOAD",
                    payload: {
                        ...state.payload,
                        step2: {
                            ...((state.payload.step2 || address) && { id: state.payload.step2?.id || address?.id }),
                            ...data,
                            zipCode: data.zipCode.replace("-", "")
                        }
                    }
                });

                changeStep();
            } catch (error) {
                return null;
            } finally {
                dispatch({ type: "TOGGLE_LOADER", payload: false });
            }
        },
        [changeStep, dispatch, state.credentials, state.payload]
    );

    const handleSelectItem = useCallback((value: string) => {
        return _findIndex(UFOptions, ["value", value]);
    }, []);

    return (
        <StepWrapper>
            <StepTitle>Aqui, o preenchimento é opcional.</StepTitle>
            <StepForm onSubmit={handleSubmit(handleSaveForm)}>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="zipCode"
                        render={({ onChange, value }) => (
                            <TextField
                                label="CEP"
                                onBlur={handleGetAddress}
                                mask={MASK_ZIPCODE}
                                error={errors.zipCode?.message}
                                rightSideElement={<ZipCodeDropdown />}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="street"
                        render={({ onChange, value }) => (
                            <TextField
                                label="Logradouro"
                                disabled={disabledFields.includes("street")}
                                error={errors.street?.message}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup isHorizontal={true}>
                    <StepFormGroupWrapper>
                        <Controller
                            control={control}
                            name="number"
                            render={({ onChange, value }) => (
                                <TextField label="Nº" error={errors.number?.message} value={value} onChange={onChange} />
                            )}
                        />
                    </StepFormGroupWrapper>
                    <Controller
                        control={control}
                        name="complement"
                        render={({ onChange, value }) => (
                            <TextField label="Complemento" error={errors.complement?.message} value={value} onChange={onChange} />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="neighborhood"
                        render={({ onChange, value }) => (
                            <TextField
                                label="Bairro"
                                disabled={disabledFields.includes("neighborhood")}
                                error={errors.neighborhood?.message}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="city"
                        render={({ onChange, value }) => (
                            <TextField
                                label="Cidade"
                                disabled={disabledFields.includes("city")}
                                error={errors.city?.message}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Controller
                        control={control}
                        name="state"
                        render={({ onChange, value }) => (
                            <SelectWrapper
                                name="state"
                                components={{ DropdownIndicator }}
                                error={errors.state?.message}
                                placeholder="UF"
                                options={UFOptions}
                                value={UFOptions[handleSelectItem(value)]}
                                onChange={(item: any) => onChange(item.value)}
                            ></SelectWrapper>
                        )}
                    />
                </StepFormGroup>
                <StepFormGroup>
                    <Button data-test-id="go-to-next-step" variant="info" size="medium" type="submit" block={true}>
                        Continuar
                    </Button>
                </StepFormGroup>
            </StepForm>
        </StepWrapper>
    );
};

export default memo(Step2);
