// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

// Components
import { WebpSearchInput } from "components/WebpSearchInput";

type WebpDropdownProps = {
    isOpened?: boolean;
    showSearch?: boolean;
};

export const WebpDropdown: FunctionComponent<WebpDropdownProps> = ({ isOpened = false, showSearch = false, children }) => {
    return (
        <Container className="webp-dropdown" isOpen={isOpened}>
            <div className="dropdown">
                {showSearch && <WebpSearchInput noBorder={true}></WebpSearchInput>}
                {children}
            </div>
        </Container>
    );
};
