import api from "services/api";
import apiMongo from "services/api-mongo";
import getContext from "helpers/getContext";

export default (store: any) => (next: any) => (action: any) => {
    const result = next(action);
    const state = store.getState();

    if (action.type === "persist/REHYDRATE") {
        const { token } = state.auth;

        api.defaults.headers.common.Authorization = `Bearer ${token}`;
        api.defaults.headers.common["X-Course"] = state.course.slug;
        api.defaults.headers.common["X-Context"] = getContext();

        apiMongo.defaults.headers.common.Authorization = `Bearer ${token}`;

        // if (!!credentials && process.env.NODE_ENV === "production") {
        //     // SOME ERROR API INTEGRATION
        // }
    }

    return result;
};
