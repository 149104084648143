import React from "react";
import { ExerciseListEssayContainer } from "./styles";

const ExerciseListEssayEmpty: React.FC = () => {
    return (
        <ExerciseListEssayContainer>
            <p>Você não realizou a redação.</p>
        </ExerciseListEssayContainer>
    );
};

export default ExerciseListEssayEmpty;
