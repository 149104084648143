// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Label } from "./activity-score.styles";

// Types
import { ActivityScoreProps } from "./activity-score.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";

export const ActivityScore: FunctionComponent<ActivityScoreProps> = ({ pointsLabel }) => {
    return (
        <Container isFilled={!!pointsLabel}>
            <ConditionallyRender shouldRender={!!pointsLabel} content={<Label>{pointsLabel}</Label>} />

            <ConditionallyRender shouldRender={!pointsLabel} content={<Spacing direction="vertical" size={20} />} />
        </Container>
    );
};
