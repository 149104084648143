import React, { useMemo } from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import formatDate from "utils/formatDate";

import * as S from "./styles";

interface IEssayGradesProps {
    composition: IEssayComposition;
    themeTitle: string;
}

const EssayDetail = ({ composition, themeTitle }: IEssayGradesProps) => {
    const sendDate = useMemo(() => formatDate(composition?.created, "dd/LL/yyyy"), [composition]);
    const correctionDate = useMemo(() => formatDate(composition?.revisions[0]?.finishDate, "dd/LL/yyyy"), [composition]);

    return (
        <S.EssayDetailContainer>
            <S.EssayDetailList>
                <S.EssayDetailItem>
                    <span>Tema: </span>
                    <strong>{themeTitle}</strong>
                </S.EssayDetailItem>
                <S.EssayDetailItem>
                    <span>Data de envio: </span>
                    <strong>{sendDate}</strong>
                </S.EssayDetailItem>
                <S.EssayDetailItem>
                    <span>Data de correção: </span>
                    <strong>{correctionDate}</strong>
                </S.EssayDetailItem>
                <S.EssayDetailItem>
                    <span>Corretor: </span>
                    <strong>{composition?.revisions[0]?.name || "-"}</strong>
                </S.EssayDetailItem>
            </S.EssayDetailList>
        </S.EssayDetailContainer>
    );
};

export default EssayDetail;
