// Dependencies
import styled, { css } from "styled-components";

// Types
import { RadialAppearance, RadialSize } from "./radial.types";

// Utils
import { ternary } from "utils/ternary";

type ContainerStyleProps = {
    firstGradientDegrees: string;
    secondGradientDegrees: string;
    progress: number;
    size: RadialSize;
    appearance: RadialAppearance;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    border-radius: 50%;

    ${({ appearance, progress, firstGradientDegrees, secondGradientDegrees }) => {
        const radialColor = ternary([
            [appearance === "default", "#50C79D"],
            [appearance === "success", "#28B583"],
            [appearance === "normal", "#9C9FB0"],
            [appearance === "danger", "#E85153"]
        ]);

        const backgroundColor = ternary([
            [appearance === "default", "#ced0dd"],
            [true, "#F0F1F7"]
        ]);

        return css`
            background: ${backgroundColor};

            background-image: -webkit-linear-gradient(${firstGradientDegrees}, ${progress <= 50 ? backgroundColor : radialColor} 50%, transparent 50%),
                -webkit-linear-gradient(${secondGradientDegrees}, ${radialColor} 50%, ${backgroundColor} 50%);
        `;
    }}

    ${({ size }) =>
        size === "small" &&
        css`
            width: 24px;
            height: 24px;

            @media (min-width: 1024px) {
                width: 30px;
                height: 30px;
            }
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            width: 104px;
            height: 104px;
        `}
`;

type OverlayStyleProps = {
    size: RadialSize;
};

export const Overlay = styled.div<OverlayStyleProps>`
    margin: auto;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.2s all ease-in-out;

    ${({ size }) =>
        size === "small" &&
        css`
            width: 16px;
            height: 16px;

            @media (min-width: 1024px) {
                width: 22px;
                height: 22px;
            }
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            width: 80px;
            height: 80px;
        `}
`;
