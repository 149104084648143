import styled from "styled-components";
import { theme } from "config/theme";

export const ConfirmActionModalContainer = styled.div<{ width?: string }>`
    width: ${(props) => props?.width || "352"}px;
    max-width: 100%;
    padding: ${theme.spacing.large};
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const ConfirmActionModalTitle = styled.strong`
    text-align: center;
    color: ${theme.colors.base[500]};
    font-weight: bold;
    font-size: ${theme.typography.sizes.large};
    margin-bottom: ${theme.spacing.medium};
`;

export const ConfirmActionModalLegend = styled.div<{ alignText: string }>`
    text-align: ${(props) => props?.alignText || "center"};
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.medium};
    margin-bottom: ${theme.spacing.medium};
    width: 100%;
`;

export const ConfirmActionModalButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-content: space-between;
    }
`;
