// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Description, Icon, IconWrapper, Title, TitleWrapper } from "./activity-card.styles";

// Types
import { ActivityCardProps } from "./activity-card.types";

export const ActivityCard: FunctionComponent<ActivityCardProps> = ({ headerIcon, title, description, actionButtonComposition }) => {
    return (
        <Container className="webapp-activity-card">
            <IconWrapper>
                <Icon src={headerIcon} />
            </IconWrapper>

            <TitleWrapper>
                <Title>{title}</Title>

                <Description>{description}</Description>
            </TitleWrapper>

            {actionButtonComposition}
        </Container>
    );
};
