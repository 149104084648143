import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";
import { css } from "styled-components";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { TeacherPictureWrapper } from "components/Teacher/styles";
import { fadein, fadeInDown, slideLeft } from "assets/styles/animations";
import {
    BodyText1,
    BodyText2,
    Micro,
    TitleH5,
    Button3,
    ContentList,
    ContentListItemLink,
    ContentListItemLinkExternal,
    ContentListItemAction
} from "assets/styles/global";
import { Link } from "react-router-dom";
import { AccordionButton, AccordionItem, AccordionLabel } from "components/Accordion/styles";

export const ModuleCardActionsContainer = styled.div`
    margin-bottom: 20px;

    > * {
        &:not(:last-child) {
            border-bottom: 1px solid #f3f3f7;
        }
    }

    ${AccordionItem} {
        &:not(:last-child) {
            border-bottom: 1px solid #f3f3f7;
        }
    }

    ${AccordionButton} {
        background: ${theme.colors.white};
        padding: 17.5px ${theme.spacing.smallX};
    }

    ${AccordionLabel} {
        margin-bottom: 0 !important;
        ${BodyText2};
    }

    ${ContentList} {
        p {
            ${Button3};
        }
    }

    ${ContentListItemLink},
    ${ContentListItemLinkExternal},
    ${ContentListItemAction} {
        background: ${theme.colors.white};
        padding-left: ${theme.spacing.smallX};
        padding-right: ${theme.spacing.smallX};

        &:hover {
            background: ${theme.colors.base[100]};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: auto;
    }
`;

const ModuleCardItemStyles = css`
    align-items: center;
    color: ${theme.colors.base[500]};
    display: flex;
    justify-content: space-between;
    padding: 17.5px ${theme.spacing.smallX};
    ${BodyText2};

    > div {
        align-items: center;
        display: flex;
    }

    > svg {
        color: #202020;
    }

    br {
        display: none;
    }
`;

export const ModuleCardLink = styled(Link)`
    width: 100%;

    ${ModuleCardItemStyles};
`;

export const EventCardExpandedTeachers = styled.ul`
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        align-items: center;
        display: flex;
    }
`;

export const EventCardExpandedTeacher = styled.li`
    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        &:not(:last-child) {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const TeacherName = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[300]};
`;

export const EventCardExpandedContainer = styled.div<{ subjectColor: string }>`
    background: ${theme.colors.base[100]};
    right: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    > * {
        position: relative;
        z-index: 2;
    }

    ${MediaQueries.SMALLER_THAN_MEDIUM} {
        animation: ${fadeInDown} 0.6s linear;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 64px 80px 20px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        animation: ${slideLeft} 0.6s ease-in-out;
        padding: 0;
        width: 400px;

        ${TeacherPictureWrapper} {
            margin: 0 9px 0 0;
        }
    }
`;

export const EventCardExpandedCloseButton = styled.button`
    display: flex;
    padding: 14px 0 22px;
    margin: ${theme.spacing.medium} 0 0 ${theme.spacing.medium};

    svg {
        display: block;
        color: ${theme.colors.white};
        fill: ${theme.colors.white};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin: 0;
        padding: ${theme.spacing.large} 0;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin: 80px 20px 0 auto;
        padding: 0;
    }
`;

export const EventCardExpandedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - 68px);
    justify-content: flex-start;
    padding: 0 24px 24px;
    position: relative;
    width: 100%;
    z-index: 2;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 0;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        height: calc(100% - 96px);
        padding: 20px;
    }
`;

export const EventCardExpandedGroup = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;

        ${EventCardExpandedTeachers} {
            margin: 0;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        align-items: flex-start;
        flex-direction: column;
        justify-content: flex-start;

        ${EventCardExpandedTeachers} {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 23px;
        }

        ${EventCardExpandedTeacher} {
            &:not(:last-child) {
                margin: 0 0 ${theme.spacing.smallX};
            }
        }
    }
`;

export const EventCardExpandedOuter = styled.div`
    padding: ${theme.spacing.small} ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: ${theme.spacing.small};
    }
`;

export const SpinnerWrapper = styled.div`
    padding: ${theme.spacing.small};

    .spinner {
        display: block;
        height: 20px;
        margin: 0 auto;
        position: static;
        width: 20px;
    }
`;

export const EventCardExpandedContent = styled.div`
    background: ${theme.colors.white};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-bottom: 21px;
    width: 100%;

    ${ButtonWrapper} {
        padding: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 222px;
        margin-bottom: ${theme.spacing.largeX};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        height: unset;
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const EventCardExpandedTypeWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.smallX};
`;

export const EventCardExpandedType = styled(TitleH5)<{ subjectColor: string }>`
    color: ${({ subjectColor }) => subjectColor};
`;

interface StatusBoxStyleProps {
    isPassed: boolean;
    isHappening: boolean;
}

export const StatusBox = styled.div<StatusBoxStyleProps>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 176px;
    z-index: 1;
    background: ${({ isPassed, isHappening }) =>
        isPassed ? theme.colors.secondary[300] : isHappening ? theme.colors.brand[300] : theme.colors.base[200]};
    border-radius: 0px 0px 8px 8px;
`;

export const EventCardExpandedTitle = styled.strong`
    ${BodyText1};
    color: ${theme.colors.base[500]};
    display: block;
    margin-bottom: ${theme.spacing.medium};
`;

export const EventCardExpandedSubject = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: 4px;
`;

export const EventCardExpandedSchedule = styled.ul`
    align-items: center;
    display: flex;
`;

export const EventCardExpandedScheduleItem = styled.li`
    align-items: center;
    display: flex;

    svg {
        color: ${theme.colors.base[400]};
        margin-right: ${theme.spacing.smallX};
    }

    &:not(:last-child) {
        margin-right: ${theme.spacing.medium};
    }
`;

export const EventCardExpandedScheduleText = styled.span`
    ${Micro};
    color: ${theme.colors.base[400]};

    span {
        margin: 0 ${theme.spacing.smallXX};
    }
`;

export const EventCardExpandedActions = styled.div`
    flex: 1;
    overflow-y: auto;

    ${ModuleCardActionsContainer} {
        margin: 0;
    }

    ${ModuleCardLink} {
        background: ${theme.colors.white};
    }
`;

export const EventCardBackDrop = styled.div`
    display: none;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        animation: ${fadein} 0.3s linear;
        background: ${hexToRgba(theme.colors.base[500], 0.32)};
        display: block;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
    }
`;

export const MeetingRoomLink = styled.p`
    padding: 16px;
    color: ${theme.colors.base[400]};

    > a {
        color: ${theme.colors.secondary[300]};
    }
`;
