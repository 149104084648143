// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    ContentSeparator,
    LabelWrapper,
    MainLabel,
    NotificationAndMenuWrapper,
    ScoreAndDeadlineWrapper,
    StatusAndActionsWrapper,
    SubLabel,
    Title
} from "./activity-teacher-item.styles";

// Types
import { ActivityTeacherItemProps } from "./activity-teacher-item.types";

export const ActivityTeacherItem: FunctionComponent<ActivityTeacherItemProps> = ({
    title,
    referenceDate,
    referenceDateLabel,
    score,
    scoreLabel,
    statusChipComposition,
    notificationComposition,
    actionMenuComposition,
    handleClick
}) => {
    return (
        <Container className="webapp-activity-teacher-item" onClick={() => !!handleClick && handleClick()}>
            <StatusAndActionsWrapper>
                {statusChipComposition}

                <NotificationAndMenuWrapper>
                    {notificationComposition}
                    {actionMenuComposition}
                </NotificationAndMenuWrapper>
            </StatusAndActionsWrapper>

            <Title>{title}</Title>

            <ContentSeparator />

            <ScoreAndDeadlineWrapper>
                <LabelWrapper>
                    <SubLabel>{referenceDateLabel}</SubLabel>
                    <MainLabel>{referenceDate}</MainLabel>
                </LabelWrapper>

                <LabelWrapper>
                    <SubLabel>{scoreLabel}</SubLabel>
                    <MainLabel>{score}</MainLabel>
                </LabelWrapper>
            </ScoreAndDeadlineWrapper>
        </Container>
    );
};
