// Dependencies
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useParams, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { scroller } from "react-scroll";
import _first from "lodash/first";
import _last from "lodash/last";
import _findIndex from "lodash/findIndex";
import _delay from "lodash/delay";
import { WebpProgressBarLabel } from "components/WebpProgressBarLabel";
import { WebpProgressBar } from "components/WebpProgressBar";
import { WebpNavItem } from "components/WebpNavItem";
import { WebpCard } from "components/WebpCard";
import { WebpNav } from "components/WebpNav";
import { WebpIconsStep } from "components/WebpIconsStep";
import { WebpContentStep } from "components/WebpContentStep";
import { WebpIconsStepGroup } from "components/WebpIconsStepGroup";
import { WebpStudyPlanDetail } from "components/WebpStudyPlanDetail";
import { WebpStudyPlanDetailProgress } from "components/WebpStudyPlanDetailProgress";
import { WebpStudyPlanDetailContent } from "components/WebpStudyPlanDetailContent";

// Services
import api from "services/api";
import history from "services/history";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as bookletModuleActions } from "store/ducks/booklet-module";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
import { Creators as modalActions } from "store/ducks/modal";

// Utils
import { theme } from "config/theme";
import { isCourseFree } from "helpers/isCourseFree";
import { BreakPoints } from "assets/styles/settings";
import useIsMobile from "hooks/use-is-mobile";

// Components
import { Grid, Row, Col } from "components/Grid";
import Spinner from "components/Spinner";
import ActiveModule from "./components/ActiveModule";
import SubjectScreenDetailsModulesSkeleton from "components/Subject/Modules/skeleton";
import LessonPlanDayWeeksSkeleton from "./components/ActiveModule/skeletonDayWeeks";
import Button from "components/Button";

// Styles
import {
    Header,
    Weeks,
    DaysWeek,
    ModuleContentMobile,
    ModuleContent,
    ButtonsMovePage,
    StudyPlanDetailContent,
    ActionButtonHeaderLink,
    ToolTipSpan
} from "./styles";

// Assets
import booklet from "./assets/booklet.svg";
import bookIcon from "./assets/book.svg";
import pencilIcon from "./assets/pencil.svg";
import playIcon from "./assets/play.svg";
import morningIcon from "./assets/manha.svg";
import afternoonIcon from "./assets/tarde.svg";
import nightIcon from "./assets/noite.svg";

const LessonPlanCustom = () => {
    const isMobile = useIsMobile(BreakPoints.tablet);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const { idWeek, idWeekDay, idDayPeriod, idDayPeriodItem, idMaterial }: any = useParams();

    const [dayContentItems, setDayContentItems] = useState<any[]>([]);
    const [isLoadingDayContentItems, setIsLoadingDayContentItems] = useState(true);
    const [activeTooltip, setActiveTooltip] = useState(false);
    const [activeMobileTooltip, setMobileActiveTooltip] = useState(false);
    const [activeAutoScroll, setActiveAutoScroll] = useState(true);
    const [isMobileContentModuleVisible, setIsMobileContentModuleVisible] = useState(false);
    const [isPlanWeeksVisible, setIsPlanWeeksVisible] = useState(false);

    const {
        lessonPlanCustom,
        lessonPlanWeeks,
        lessonPlanSection: lessonPlanWeekDays,
        lessonPlanSectionWeek,
        lessonPlanSectionWeekList,
        lessonPlanSectionWeekListItems,
        lessonPlanSectionWeekPlanList,
        isLoadingWeeks,
        isLoadingSection,
        isLoadingSectionWeek,
        isLoadingWeekListItems,
        isLoadingWeekPlanList
    } = useSelector(({ lessonPlanCustom }: IReduxStore) => lessonPlanCustom);
    const { content, isLoading, courseSlug, hasDispatchedClickModuleContent } = useSelector(
        ({ lessonFreeCTA, course, bookletModule }: IReduxStore) => ({
            content: lessonFreeCTA?.content,
            isLoading: lessonFreeCTA?.isLoading,
            courseSlug: course?.slug,
            hasDispatchedClickModuleContent: bookletModule?.hasDispatchedClickModuleContent
        })
    );

    const getWeeks = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomWeeksRequest({ id })), [dispatch]);
    const getDaysShort = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionRequest({ id })), [dispatch]);
    const getDayPeriods = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionWeekRequest({ id })), [dispatch]);
    const getModulesContentItems = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionWeekListItemsRequest({ id })), [
        dispatch
    ]);
    const getWeekPlanList = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionWeekPlanListRequest({ id })), [dispatch]);

    const weekSelectedIndex = _findIndex(lessonPlanWeeks, ["id", +idWeek]);
    const weekDaySelectedIndex = _findIndex(lessonPlanWeekDays, ["id", +idWeekDay]);

    const isLoadingActive = isLoadingWeekListItems || isLoadingDayContentItems;
    const isProenemBrand = theme.project.brand === "proenem";
    const isObsolete = lessonPlanCustom[0]?.isObsolete || false;

    useEffect(() => {
        if (isLoading && isCourseFree(courseSlug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, courseSlug, isLoading]);

    const handleClickModal = useCallback(
        async (type: string) => {
            const lessonPlanCustomId = lessonPlanCustom[0]?.id;

            if (!type) {
                return "";
            }

            if (type === "confirm") {
                await api.delete(`/student/lessonplangeneric/${lessonPlanCustomId}`);

                return history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-sua-carreira` });
            }

            await api.patch(`/student/lessonplangeneric/${lessonPlanCustomId}/activate`);
        },
        [courseSlug, lessonPlanCustom]
    );

    useEffect(() => {
        if (isProenemBrand && isObsolete) {
            const descriptionText =
                "Desde a liberação do plano de estudos personalizado, eu recebi vários feedbacks e constatei que precisava melhorar o motor virtual que gera o seu plano. Por conta disso, fiz alguns ajustes para tornar a sua experiência ainda melhor. Por isso, se você quiser, pode criar um novo plano agora e descartar o antigo. Caso prefira, você também pode prosseguir com o que foi criado anteriormente. É importante que você saiba que o meu foco é a sua APROVAÇÃO. Sempre que julgar que uma funcionalidade pode ter uma versão melhor do que a atual, vou trabalhar para disponibilizá-la para você. Caso tenha se sentido prejudicado(a), fica aqui o meu pedido de desculpas!";

            dispatch(
                modalActions.openModal("confirmAction", {
                    type: "OPEN_MODAL",
                    hasCloseButton: false,
                    hasCancelButton: true,
                    widthContainer: "600",
                    alignText: "justify",
                    title: "ATENÇÃO, PROALUNO!",
                    text: descriptionText,
                    variant: "warning",
                    variantCancelButton: "outline-warning",
                    buttonText: "Criar um novo plano",
                    cancelButtonText: "Manter o plano atual",
                    onConfirm: () => handleClickModal("confirm"),
                    onCancel: () => handleClickModal("cancel")
                })
            );
        }
    }, [dispatch, handleClickModal, isProenemBrand, isObsolete]);

    const handleSelectWeek = useCallback(
        (id: number, shouldPush = true) => {
            setIsPlanWeeksVisible(false);

            getDaysShort(id);

            if (shouldPush) {
                history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/p/${id}` });
            }
        },
        [courseSlug, getDaysShort]
    );

    const handlePervOrNextWeek = useCallback(
        (type: "previous" | "next") => {
            if ((type === "previous" && weekSelectedIndex > 0) || (type === "next" && weekSelectedIndex < lessonPlanWeeks.length - 1)) {
                setDayContentItems([]);
                setActiveAutoScroll(false);

                const factor = type === "previous" ? -1 : 1;

                const selectedWeekIdTmp = lessonPlanWeeks[weekSelectedIndex + factor]?.id;

                handleSelectWeek(selectedWeekIdTmp);
            }
        },
        [handleSelectWeek, lessonPlanWeeks, weekSelectedIndex]
    );

    const handleSelectWeekDay = useCallback(
        (id: number, shouldPush = true) => {
            setDayContentItems([]);
            setActiveAutoScroll(false);

            getDayPeriods(id);

            if (shouldPush) {
                history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${id}` });
            }
        },
        [courseSlug, getDayPeriods, idWeek]
    );

    const handleSelectWeekDayContent = useCallback(
        (periodId: number, periodItemId: number, shouldOpenMobileContent: boolean = true, shouldPush = true) => {
            if (shouldOpenMobileContent && !!isMobile) {
                setIsMobileContentModuleVisible(true);
            }

            setActiveAutoScroll(true);

            getModulesContentItems(periodItemId);

            if (shouldPush) {
                return history.push({
                    pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${periodId}/${periodItemId}`
                });
            }
        },
        [courseSlug, getModulesContentItems, idWeek, idWeekDay, isMobile]
    );

    const getLessonPlanWeeks = useCallback(() => {
        if (!lessonPlanWeeks.length) {
            const lessonPlanId = lessonPlanCustom[0]?.id;

            if (lessonPlanId) {
                getWeeks(lessonPlanId);
            }
        }
    }, [getWeeks, lessonPlanCustom, lessonPlanWeeks.length]);

    useEffect(() => {
        getLessonPlanWeeks();
    }, [getLessonPlanWeeks, courseSlug]);

    useEffect(() => {
        if (lessonPlanWeeks.length > 0 && lessonPlanWeekDays.length === 0 && !!idWeek && !isLoadingSection) {
            const isValidWeek = lessonPlanWeeks.findIndex((lessonPlanWeek) => lessonPlanWeek.id === +idWeek);

            if (isValidWeek > -1) {
                handleSelectWeek(+idWeek, false);
            }
        }

        if (lessonPlanWeeks.length > 0 && lessonPlanWeekDays.length === 0 && !idWeek && !isLoadingSection) {
            const currentWeekTmp = _first<number>(lessonPlanCustom[0].currents);

            if (!!currentWeekTmp) {
                if (pathname.split("/meu-plano-de-estudos/p")[1] === "") {
                    setActiveAutoScroll(false);
                }

                handleSelectWeek(currentWeekTmp);
            }
        }
    }, [handleSelectWeek, idWeek, isLoadingSection, lessonPlanCustom, lessonPlanWeekDays.length, lessonPlanWeeks, pathname]);

    useEffect(() => {
        if (lessonPlanWeekDays.length > 0 && lessonPlanSectionWeek.length === 0 && !!idWeekDay && !isLoadingSectionWeek) {
            const isValidWeekDay = lessonPlanWeekDays.findIndex((lessonPlanWeekDay) => lessonPlanWeekDay.id === +idWeekDay);

            if (isValidWeekDay > -1) {
                handleSelectWeekDay(+idWeekDay, false);
            }
        }

        if (lessonPlanWeekDays.length > 0 && lessonPlanSectionWeek.length === 0 && !idWeekDay && !isLoadingSectionWeek) {
            const parentId = lessonPlanWeekDays[0]?.id_parent;

            let currentWeekDayTmp = parentId;

            if (parentId === +idWeek) {
                currentWeekDayTmp = lessonPlanWeekDays[0]?.id ?? _last(lessonPlanCustom[0].currents);
            }

            if (!!currentWeekDayTmp) {
                handleSelectWeekDay(currentWeekDayTmp);
            }
        }
    }, [handleSelectWeekDay, idWeek, idWeekDay, isLoadingSectionWeek, lessonPlanCustom, lessonPlanSectionWeek.length, lessonPlanWeekDays]);

    useEffect(() => {
        if (
            lessonPlanSectionWeek.length > 0 &&
            lessonPlanSectionWeekListItems.contentPerType.length === 0 &&
            !!idDayPeriod &&
            !!idDayPeriodItem &&
            !isLoadingWeekListItems
        ) {
            handleSelectWeekDayContent(+idDayPeriod, +idDayPeriodItem, false, false);
        }

        if (
            lessonPlanSectionWeek.length > 0 &&
            lessonPlanSectionWeekListItems.contentPerType.length === 0 &&
            !idDayPeriod &&
            !idDayPeriodItem &&
            !isLoadingWeekListItems
        ) {
            if (!!dayContentItems.length && !!idWeekDay) {
                const { weekId, content } = _first(dayContentItems);

                if (!!weekId && !!content[0]?.id) {
                    handleSelectWeekDayContent(weekId, content[0]?.id, false);
                }
            }
        }
    }, [
        dayContentItems,
        handleSelectWeekDayContent,
        idDayPeriod,
        idDayPeriodItem,
        idWeekDay,
        isLoadingWeekListItems,
        lessonPlanSectionWeek.length,
        lessonPlanSectionWeekListItems.contentPerType.length
    ]);

    useEffect(() => {
        if (!!lessonPlanSectionWeekListItems.contentPerType.length && !!idMaterial && !hasDispatchedClickModuleContent) {
            const currentModuleMaterialContent = lessonPlanSectionWeekListItems.contentPerType.find((content) => content.type === "Material");

            const selectedMaterial = currentModuleMaterialContent?.items.find((item) => (item.material as any)?.id === Number(idMaterial));

            if (selectedMaterial) {
                dispatch(
                    bookletModuleActions.clickModuleContent({
                        ...selectedMaterial,
                        isDispatchingClickModuleContent: true
                    })
                );
            }
        }
    }, [dispatch, hasDispatchedClickModuleContent, idMaterial, lessonPlanSectionWeekListItems]);

    const getFormattedDayContentItems = useCallback(async () => {
        if (!!lessonPlanSectionWeek.length) {
            setIsLoadingDayContentItems(true);

            const promiseTurnos = lessonPlanSectionWeek.map(async (item: any) => {
                const weekId = item.id;
                const { data: content } = await api.get(`/student/lessonplangenericsection/${weekId}/section`);

                return {
                    weekName: item.name,
                    weekId,
                    content
                };
            });

            await Promise.all(promiseTurnos).then((response) => {
                setDayContentItems(response);
                setIsLoadingDayContentItems(false);
            });
        }
    }, [lessonPlanSectionWeek]);

    useEffect(() => {
        getFormattedDayContentItems();
    }, [getFormattedDayContentItems, courseSlug]);

    const scrollToTop = useCallback((id: number) => {
        _delay(() => {
            scroller.scrollTo(`${id}`, {
                containerId: "scrollable",
                smooth: true,
                duration: 200,
                offset: -70
            });
        }, 300);
    }, []);

    const isDayContentItemActive = useCallback(
        (periodId: number, periodItemId: number) => {
            const isActive = periodId === +idDayPeriod && periodItemId === +idDayPeriodItem;

            if (isActive && activeAutoScroll) {
                scrollToTop(periodItemId);
            }

            return isActive;
        },
        [activeAutoScroll, idDayPeriod, idDayPeriodItem, scrollToTop]
    );

    const handleChangePlan = useCallback(async () => {
        await api.delete(`/student/lessonplanselection`);

        dispatch(lessonPlanCustomActions.clearLessonPlanSelection());
        dispatch(lessonPlanCustomActions.getLessonPlanSelectionRequest());

        history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos` });
    }, [courseSlug, dispatch]);

    const handleListAllPlan = useCallback(() => {
        setIsPlanWeeksVisible(true);
        getWeekPlanList(lessonPlanCustom[0]?.id);
    }, [getWeekPlanList, lessonPlanCustom]);

    useEffect(() => {
        if (lessonPlanCustom.length > 0 && lessonPlanCustom[0]?.contentNotSeen) {
            setActiveTooltip(true);
        }
    }, [dispatch, lessonPlanCustom]);

    useEffect(() => {
        return () => {
            dispatch(lessonPlanCustomActions.clearLessonPlanCustom());
        };
    }, [dispatch]);

    if (isLoadingWeeks) {
        return <Spinner />;
    }

    return (
        <Fragment>
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <Header>
                            <div className="content-header-weeks">
                                <div className="content-header-titles-progressBar">
                                    <div className="content-header-titles">
                                        <h1 className="title-header-weeks">Plano de estudos</h1>
                                        {activeTooltip && (
                                            <ActionButtonHeaderLink
                                                showToolTip={activeMobileTooltip}
                                                withHover
                                                onClick={() => setMobileActiveTooltip(!activeMobileTooltip)}
                                            >
                                                <div className="icon" />
                                                <ToolTipSpan>{lessonPlanCustom[0]?.contentNotSeen}</ToolTipSpan>
                                            </ActionButtonHeaderLink>
                                        )}
                                        <p className="desc-completed-weeks">
                                            {`${lessonPlanCustom[0]?.progress?.completedItems || 0}/${lessonPlanCustom[0]?.progress?.totalItems ||
                                                lessonPlanWeeks.length} semanas concluídas`}
                                            {lessonPlanWeeks.length > 0 && (
                                                <span className="percents">{lessonPlanCustom[0]?.progress?.percent || 0}%</span>
                                            )}
                                        </p>
                                    </div>
                                    <WebpProgressBar
                                        color={theme.colors.system.info[200]}
                                        number={lessonPlanCustom[0]?.progress?.percent || 0}
                                        colorCompletelyFilled={theme.colors.system.success[200]}
                                    />
                                </div>
                                <div className="content-header-buttons">
                                    <Button variant="outline-primary" size="small" onClick={handleChangePlan}>
                                        Modificar plano
                                    </Button>
                                    <Button variant="primary" size="small" onClick={handleListAllPlan}>
                                        Ver planejamento
                                    </Button>
                                </div>
                            </div>

                            <div className="title-and-buttons">
                                <h2 className="title-header-weeks" id="title-header-weeks">
                                    {!!lessonPlanWeeks.length ? lessonPlanWeeks[weekSelectedIndex]?.name : ""}
                                </h2>
                                <div className="buttons-left-rigth" id="buttons-left-rigth">
                                    <button onClick={() => handlePervOrNextWeek("previous")}>{"<"}</button>
                                    <button onClick={() => handlePervOrNextWeek("next")}>{">"}</button>
                                </div>
                                <p className="desc-completed-weeks" id="desc-completed-weeks">
                                    {`${lessonPlanWeeks[weekSelectedIndex]?.progress?.completedItems ?? 0}/${lessonPlanWeeks[weekSelectedIndex]
                                        ?.progress?.totalItems ?? 0} dias concluídos`}
                                    <span className="percents">{lessonPlanWeeks[weekSelectedIndex]?.progress?.percent || 0}%</span>
                                </p>
                            </div>

                            <div className="divider" />
                        </Header>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        <Weeks>
                            {isLoadingSection ? (
                                <LessonPlanDayWeeksSkeleton />
                            ) : (
                                !!lessonPlanWeekDays.length && (
                                    <WebpNav
                                        contentLayout="flex-start"
                                        contentNavComposition={lessonPlanWeekDays?.map((item, key) => (
                                            <WebpNavItem
                                                key={key}
                                                onNavItemClicked={() => handleSelectWeekDay(item.id)}
                                                navType="square"
                                                label={item.name}
                                                active={weekDaySelectedIndex === key ? true : false}
                                            />
                                        ))}
                                    ></WebpNav>
                                )
                            )}
                        </Weeks>
                    </Col>
                    <Col xs={12} sm={6}>
                        {isLoadingDayContentItems ? (
                            <SubjectScreenDetailsModulesSkeleton />
                        ) : (
                            <DaysWeek dotActive={false}>
                                <div className="dots-left">
                                    <div />
                                </div>
                                <div className="content-days-week">
                                    {dayContentItems?.map((item: any, keyWeek: number) => {
                                        return (
                                            <div key={keyWeek} className="div-step-group-content">
                                                <div className="img-labelPeriod">
                                                    <img
                                                        className="img-period"
                                                        src={
                                                            item.weekName === "Manhã"
                                                                ? morningIcon
                                                                : item.weekName === "Tarde"
                                                                ? afternoonIcon
                                                                : nightIcon
                                                        }
                                                        alt="logo do período"
                                                    />
                                                    <h2 className="labelPeriod">{item.weekName}</h2>
                                                </div>
                                                {!!item?.content?.length && (
                                                    <WebpNav
                                                        orientation="vertical"
                                                        contentLayout="space-between"
                                                        contentNavComposition={item.content.map((contentItem: any, keyWeekList: number) => (
                                                            <WebpNavItem
                                                                key={keyWeekList}
                                                                navType="step"
                                                                id={contentItem?.id}
                                                                active={isDayContentItemActive(item?.weekId, contentItem?.id)}
                                                                onNavItemClicked={() =>
                                                                    handleSelectWeekDayContent(item?.weekId, contentItem?.id, true)
                                                                }
                                                                contentNavItemComposition={
                                                                    <WebpContentStep
                                                                        contentLabel={contentItem.name}
                                                                        material={contentItem.subject.name}
                                                                        materialComposition={
                                                                            <WebpIconsStepGroup>
                                                                                {Object.keys(contentItem.totalItems)?.map((totalItems, key) => {
                                                                                    const itemSum = contentItem?.totalItems[totalItems] || 0;
                                                                                    if (itemSum > 0) {
                                                                                        return (
                                                                                            <WebpIconsStep
                                                                                                key={key}
                                                                                                srcImg={
                                                                                                    totalItems === "video"
                                                                                                        ? playIcon
                                                                                                        : totalItems === "exerciselist"
                                                                                                        ? pencilIcon
                                                                                                        : totalItems === "material"
                                                                                                        ? booklet
                                                                                                        : bookIcon
                                                                                                }
                                                                                                amount={itemSum}
                                                                                            ></WebpIconsStep>
                                                                                        );
                                                                                    }
                                                                                })}
                                                                            </WebpIconsStepGroup>
                                                                        }
                                                                        progressComposition={
                                                                            <WebpProgressBarLabel
                                                                                label={` ${contentItem?.progress?.percent ?? 0}% Concluído`}
                                                                                contentProgressBarLabelComposition={
                                                                                    <WebpProgressBar
                                                                                        color={theme.colors.system.info[200]}
                                                                                        number={contentItem?.progress?.percent ?? 0}
                                                                                        colorCompletelyFilled={theme.colors.system.success[200]}
                                                                                    ></WebpProgressBar>
                                                                                }
                                                                            ></WebpProgressBarLabel>
                                                                        }
                                                                    ></WebpContentStep>
                                                                }
                                                            />
                                                        ))}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </DaysWeek>
                        )}
                    </Col>
                    <Col xs={12} sm={6}>
                        {isMobileContentModuleVisible && (
                            <ModuleContentMobile>
                                <WebpCard
                                    headerComposition={
                                        <button className="botton-close" onClick={() => setIsMobileContentModuleVisible(false)}>
                                            X
                                        </button>
                                    }
                                    contentComposition={
                                        <ActiveModule
                                            contentPerType={lessonPlanSectionWeekListItems}
                                            withBlur={false}
                                            name={lessonPlanSectionWeekList[0]?.name || ""}
                                            isLoading={isLoadingActive}
                                            error={false}
                                            imageSrc={content.modulo}
                                            linkHref={content.cta}
                                        />
                                    }
                                ></WebpCard>
                            </ModuleContentMobile>
                        )}

                        <ModuleContent>
                            <WebpCard
                                contentComposition={
                                    <ActiveModule
                                        contentPerType={lessonPlanSectionWeekListItems}
                                        withBlur={false}
                                        name={lessonPlanSectionWeekList[0]?.name || ""}
                                        isLoading={isLoadingActive}
                                        error={false}
                                        imageSrc={content.modulo}
                                        linkHref={content.cta}
                                    />
                                }
                            />
                        </ModuleContent>
                    </Col>
                </Row>

                <StudyPlanDetailContent show={isPlanWeeksVisible}>
                    <div className="wrapper-content">
                        <div className="buttonclose-and-title">
                            <button className="botton-close" onClick={() => setIsPlanWeeksVisible(false)}>
                                X
                            </button>
                            <h2 className="title-StudyPlanDetailContent">Cronograma completo de estudos</h2>
                        </div>
                        {isLoadingWeekPlanList ? (
                            <Spinner />
                        ) : (
                            lessonPlanSectionWeekPlanList.map((itemWeekPlanList: any, numberForArray: number) => (
                                <div className="wrapper-border" key={numberForArray} id={`${itemWeekPlanList.id}`}>
                                    <WebpStudyPlanDetail
                                        key={itemWeekPlanList.id}
                                        headerComposition={
                                            <WebpStudyPlanDetailProgress
                                                activeButton={itemWeekPlanList.id === +idWeek}
                                                labelButton={itemWeekPlanList.name}
                                                onClickedButton={() => handleSelectWeek(itemWeekPlanList.id)}
                                            />
                                        }
                                        bodyComposition={
                                            <WebpStudyPlanDetailContent
                                                hours={itemWeekPlanList.totalhours}
                                                labelMaterials={itemWeekPlanList.subjects}
                                            />
                                        }
                                    ></WebpStudyPlanDetail>
                                </div>
                            ))
                        )}
                    </div>
                </StudyPlanDetailContent>
            </Grid>
            <ButtonsMovePage>
                <Button variant="outline-primary" size="small" onClick={handleChangePlan}>
                    Modificar plano
                </Button>
                <Button variant="primary" size="small" onClick={handleListAllPlan}>
                    Ver planejamento
                </Button>
            </ButtonsMovePage>
        </Fragment>
    );
};

export default LessonPlanCustom;
