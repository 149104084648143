import styled from "styled-components";
import { theme } from "config/theme";
import { Button3 } from "assets/styles/global";

export const ExamResultRealizationsAccordionContainer = styled.div`
    display: flex;
    flex-direction: column;

    p {
        ${Button3};
        color: ${theme.colors.base[500]};
        margin-bottom: ${theme.spacing.smallX};
    }
`;
