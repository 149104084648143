// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { OptionItemProps } from "./option-item.types";

// Styles
import { Container, Label } from "./option-item.styles";

export const OptionItem: FunctionComponent<OptionItemProps> = ({ message, active, handleClick }) => {
    return (
        <Container active={!!active} onClick={() => !active && handleClick()}>
            <Label active={!!active}>{message}</Label>
        </Container>
    );
};
