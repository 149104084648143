import api from "services/api";
import history from "services/history";
import { Creators as alertActions } from "../ducks/alert";
import { IRequestAction } from "interfaces/IRequestAction";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { Types as PreviousExamTypes } from "../ducks/previousExam";
import { Creators as PreviousExamActions } from "store/ducks/previousExam";
import { IReduxStore } from "interfaces/IReduxStore";

function* getAllPreviousExams(action: IRequestAction<any>) {
    try {
        const { data } = yield call(api.get, action.payload.endpoint);

        yield put(PreviousExamActions.getPreviousExamSuccess(data));
    } catch (error) {
        yield put(PreviousExamActions.getPreviousExamFailure(error?.response?.data));
    }
}

function* getPreviousExamDetails(action: any) {
    const { id, isPreviousExam } = action.payload;

    try {
        if (!id) {
            throw new Error();
        }

        const [{ data: item }, { data: relateds }] = yield all([call(api.get, `/student/exam/${id}`), call(api.get, `/student/exam/${id}/related`)]);

        yield put(PreviousExamActions.getPreviousExamDetailSuccess({ item, relateds }));
    } catch (error) {
        yield put(alertActions.showAlert("Desculpe, não encontramos essa prova.", "danger"));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, {
            pathname: isPreviousExam ? `/app/curso/${courseSlug}/provas-anteriores` : `/app/curso/${courseSlug}/simulados`
        });
        yield put(PreviousExamActions.getPreviousExamDetailFailure(error?.response?.data));
    }
}

export default [
    takeLatest(PreviousExamTypes.GET_PREVIOUS_EXAM_REQUEST, getAllPreviousExams),
    takeLatest(PreviousExamTypes.GET_PREVIOUS_EXAM_DETAIL_REQUEST, getPreviousExamDetails)
];
