// Dependencies
import React, { FunctionComponent, useMemo } from "react";

// Types
import { ActivityFileProps } from "./activity-file.types";

// Styles
import { Container, Label, ContentWrapper, ImageContainer } from "./activity-file.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Utils
import isImage from "utils/is-image";

export const ActivityFile: FunctionComponent<ActivityFileProps> = ({
    source,
    isReadyToDownload,
    label,
    progressBarElement,
    actionElement,
    handleClick,
    handleImageClick,
    handleClickLabel
}) => {
    const hasImage = useMemo(() => isImage(source ?? ""), [source]);

    return (
        <Container
            className="webapp-file"
            hasImage={hasImage && !!handleImageClick}
            hasAction={!!handleClick}
            onClick={() => !!handleClick && handleClick()}
        >
            <ConditionallyRender
                shouldRender={hasImage}
                content={
                    <ImageContainer
                        hasImage={hasImage && !!handleImageClick}
                        dangerouslySetInnerHTML={{ __html: `<img src=${source} />` }}
                        onClick={() => !!handleImageClick && handleImageClick()}
                    />
                }
            />

            <ConditionallyRender
                shouldRender={!hasImage && !!label}
                content={
                    <Label hasHover={!!isReadyToDownload} onClick={() => !!handleClickLabel && handleClickLabel()}>
                        {label}
                    </Label>
                }
            />

            <ContentWrapper>
                <ConditionallyRender shouldRender={!hasImage && !!progressBarElement} content={progressBarElement} />

                {actionElement}
            </ContentWrapper>
        </Container>
    );
};
