import { theme } from "config/theme";
import { css } from "styled-components";

export const CKEditorDefaultStyles = css`
    :root {
        --ck-image-style-spacing: 1.5em;
        --ck-todo-list-checkmark-size: 16px;
    }

    .ck {
        &.ck-toolbar {
            border-color: #83899e !important;
            border-radius: 4px 4px 0 0 !important;
            background-color: #f1f1f7 !important;
        }

        &.ck-content {
            &.ck-editor__editable {
                * {
                    color: ${theme.colors.base[300]} !important;
                    font-size: 16px !important;
                    line-height: 22px !important;
                }

                p {
                    margin: var(--ck-spacing-large) 0 !important;
                }

                &.ck-focused {
                    box-shadow: unset !important;
                }
            }
        }

        &.ck-editor__editable_inline {
            border-top: unset !important;
            border-color: #83899e !important;
            border-radius: 0 0 4px 4px !important;
            min-height: 114px !important;
            padding: ${theme.spacing.smallXX} ${theme.spacing.small} !important;
        }
    }

    .ck-content {
        max-width: 100%;
        width: 100%;

        /* * {
            max-width: 100% !important;
            user-select: none;
            pointer-events: none;
        } */

        &.ck-project {
            ul,
            ol,
            dl {
                text-indent: 2rem;
            }
        }

        color: ${theme.colors.base[500]};

        code {
            background-color: hsla(0, 0%, 78%, 0.3);
            padding: 0.15em;
            border-radius: 2px;
        }

        li {
            list-style-position: inside;

            &:not(:last-child) {
                margin-bottom: 15px !important;
            }
        }

        ul li {
            list-style-type: disc;
        }

        ol li {
            list-style-type: decimal;
        }

        .image {
            display: table;
            clear: both;
            text-align: center;
            margin: 1em auto;

            > img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                min-width: 50px;
            }

            > figcaption {
                display: table-caption;
                caption-side: bottom;
                word-break: break-word;
                color: hsl(0, 0%, 20%);
                background-color: hsl(0, 0%, 97%);
                padding: 0.6em;
                font-size: 0.75em;
                outline-offset: -1px;
            }

            &.image_resized {
                max-width: 100%;
                display: block;
                box-sizing: border-box;

                img {
                    width: 100%;
                }

                > figcaption {
                    display: block;
                }
            }
        }

        .media {
            clear: both;
            margin: 1em 0;
            display: block;
            min-width: 15em;
        }

        .todo-list {
            list-style: none;

            li {
                margin-bottom: 5px;

                .todo-list {
                    margin-top: 5px;
                }
            }

            .todo-list__label > input {
                -webkit-appearance: none;
                display: inline-block;
                position: relative;
                width: var(--ck-todo-list-checkmark-size);
                height: var(--ck-todo-list-checkmark-size);
                vertical-align: middle;
                border: 0;
                left: -25px;
                margin-right: -15px;
                right: 0;
                margin-left: 0;
            }

            .todo-list__label > input::before {
                display: block;
                position: absolute;
                box-sizing: border-box;
                content: "";
                width: 100%;
                height: 100%;
                border: 1px solid hsl(0, 0%, 20%);
                border-radius: 2px;
                transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
            }

            .todo-list__label > input::after {
                display: block;
                position: absolute;
                box-sizing: content-box;
                pointer-events: none;
                content: "";
                left: calc(var(--ck-todo-list-checkmark-size) / 3);
                top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
                width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
                height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
                border-style: solid;
                border-color: transparent;
                border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
                transform: rotate(45deg);
            }

            .todo-list__label > input[checked]::before {
                background: hsl(126, 64%, 41%);
                border-color: hsl(126, 64%, 41%);
            }

            .todo-list__label > input[checked]::after {
                border-color: hsl(0, 0%, 100%);
            }

            .todo-list__label .todo-list__label__description {
                vertical-align: middle;
            }
        }

        .table {
            margin: 1em auto;
            display: table;

            table {
                border-collapse: collapse;
                border-spacing: 0;
                border: 1px double hsl(0, 0%, 70%);

                td,
                th {
                    min-width: 2em;
                    padding: 0.4em;
                    border-color: hsl(0, 0%, 85%);
                }

                th {
                    font-weight: bold;
                    background: hsl(0, 0%, 98%);
                }
            }
        }

        .page-break {
            position: relative;
            clear: both;
            padding: 5px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &:after {
                content: "";
                position: absolute;
                border-bottom: 2px dashed hsl(0, 0%, 77%);
                width: 100%;
            }
        }

        .page-break__label {
            position: relative;
            z-index: 1;
            padding: 0.3em 0.6em;
            display: block;
            text-transform: uppercase;
            border: 1px solid hsl(0, 0%, 77%);
            border-radius: 2px;
            font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
            font-size: 0.75em;
            font-weight: bold;
            color: hsl(0, 0%, 20%);
            background: #fff;
            box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .image-style-side,
        .image-style-align-left,
        .image-style-align-center,
        .image-style-align-right {
            max-width: 50%;
        }

        .image-style-side {
            float: right;
            margin-left: var(--ck-image-style-spacing);
        }

        .image-style-align-left {
            float: left;
            margin-right: var(--ck-image-style-spacing);
        }

        .image-style-align-center {
            margin-left: auto;
            margin-right: auto;

            img {
                margin: 0 auto;
            }
        }

        .image-style-align-right {
            float: right;
            margin-left: var(--ck-image-style-spacing);
        }

        blockquote {
            overflow: hidden;
            padding-right: 1.5em;
            padding-left: 1.5em;
            margin-left: 0;
            margin-right: 0;
            font-style: italic;
            border-left: solid 5px hsl(0, 0%, 80%);
        }

        hr {
            border-width: 1px 0 0;
            border-style: solid;
            border-color: hsl(0, 0%, 37%);
            margin: 0;
        }

        p {
            font-size: 15px !important;

            &:not(:last-child) {
                margin-bottom: ${theme.spacing.medium} !important;
            }

            &:empty {
                display: none;
            }
        }
    }

    .ck-content[dir="rtl"] blockquote {
        border-left: 0;
        border-right: solid 5px hsl(0, 0%, 80%);
    }

    @media print {
        .ck-content .page-break {
            padding: 0;
        }

        .ck-content .page-break::after {
            display: none;
        }
    }
`;
