export const ordererOptions = [
    { value: "-idTheme", label: "Mais recentes" },
    { value: "-popularity", label: "Mais populares" },
    { value: "-complexity", label: "Mais difíceis" },
    { value: "complexity", label: "Mais fáceis" }
];

export const ordererOptionsCompositions = [
    { value: "-created", label: "Mais recentes" },
    { value: "theme.popularity", label: "Mais populares" },
    { value: "-theme.complexity", label: "Mais difíceis" },
    { value: "theme.complexity", label: "Mais fáceis" }
];

export const mockFilterOptions = [
    {
        title: "Categorias",
        id: 1,
        slug: "categorias",
        items: [
            { value: 1, label: "Arte", checked: false },
            { value: 2, label: "Inclusão social", checked: false },
            { value: 3, label: "Política", checked: false }
        ]
    },
    {
        title: "Gênero Textual",
        id: 2,
        slug: "genero-textual",
        items: [
            { value: 1, label: "Biografia", checked: false },
            { value: 2, label: "Opinião", checked: false },
            { value: 3, label: "Dissertação", checked: false }
        ]
    },
    {
        title: "Concurso",
        id: 3,
        slug: "concurso",
        items: [
            { value: 1, label: "ENEM", checked: false },
            { value: 2, label: "Vestibulares SP", checked: false }
        ]
    },
    {
        title: "Temas",
        id: 4,
        slug: "temas",
        items: [
            { value: 1, label: "Todos", checked: false },
            { value: 2, label: "Enviados", checked: false },
            { value: 3, label: "Não enviados", checked: false }
        ]
    },
    {
        title: "Ano",
        id: 5,
        slug: "ano",
        items: [
            { value: 1, label: "2019", checked: false },
            { value: 2, label: "2018", checked: false },
            { value: 3, label: "2017", checked: false },
            { value: 4, label: "2016", checked: false },
            { value: 5, label: "2015", checked: false }
        ]
    }
];

export const themes = [
    {
        id: 1,
        title: "Tecnologia: o aumento do acesso à internet por crianças desacompanhadas pelos responsáveis",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Fuvest"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 2,
        title: "Um fenômeno recente: o da pós-verdade",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Unicamp"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 3,
        title: "Saneamento básico, saúde e qualidade de vida no Brasil",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 4,
        title: "Afastamento social e violência contra idosos no Brasil",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 5,
        title: "Como ser antirracista na socidade brasileira?",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Fuvest"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 6,
        title: "Tabagismo: o aumento do número de fumantes entre jovens brasileiros",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 7,
        title: "A superexposição da violência na mídia brasileira",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 8,
        title: "A contribuição da ciência para o bem-estar social no Brasil",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 9,
        title: "A contribuição da leitura para a formação social",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    },
    {
        id: 10,
        title: "A contribuição da nanotecnologia para a qualidade de vida",
        genre: {
            id: 1,
            name: "Reportagem"
        },
        contest: {
            id: 1,
            name: "Enem"
        },
        realizationsCount: 10,
        created: "2020-09-01T03:00:00.000Z",
        updated: "2020-09-01T03:00:00.000Z"
    }
];

export const contests = [
    {
        value: 1,
        label: "AFA"
    },
    {
        value: 2,
        label: "EFOMM"
    },
    {
        value: 3,
        label: "EPCAR"
    },
    {
        value: 4,
        label: "ITA"
    }
];

export const dispatches = [
    {
        created: "2020-09-01T03:00:00.000Z",
        value: 592.8
    },
    {
        created: "2020-09-02T03:00:00.000Z",
        value: 593.6
    },
    {
        created: "2020-09-03T03:00:00.000Z",
        value: 707.8
    },
    {
        created: "2020-09-04T03:00:00.000Z",
        value: 753.4
    },
    {
        created: "2020-09-05T03:00:00.000Z",
        value: 447.7
    }
];

export const correction = {
    comments: [
        {
            id: 1,
            competence: {
                id: 2,
                tittle: "Competência 1",
                cod: 1,
                color: "#d60d0d",
                description: "Demonstrar domínio da modalidade escrita formal da Língua Portuguesa"
            },
            tips:
                "Atenção à ortografia correta das palavras. Além de estudar as regras de ortografia, procure escrever com mais atenção e realizar uma revisão ortográfica em seu texto antes de enviá-lo para correção. Se há dúvidas em relação à ortografia da palavra utilizada, procure usar a internet ou um dicionário para escrevê-la corretamente.",
            commentary:
                "Ortografia inadequada. \nNúmeros ordinais seguem, de maneira geral, a mesma regra dos cardinais: escreva por extenso de primeiro a décimo; em algarismos a partir de 11º.\n",
            selections: [
                {
                    x: 303,
                    y: 230,
                    w: 28,
                    h: 29
                }
            ]
        },
        {
            id: 2,
            competence: {
                id: 2,
                tittle: "Competência 2",
                cod: 2,
                color: "#00b8ff",
                description:
                    "Compreender a proposta de redação e aplicar conceitos das várias áreas de conhecimento para desenvolver o tema, dentro dos limites estruturais do texto dissertativo-argumentativo em prosa"
            },
            tips:
                'Procure trazer referências sobre o autor citado. Ao invés de colocar, por exemplo, "De acordo com Leandro Karnal, aquele que não respeita a faxineira, mas respeita o governador, é um interesseiro.", coloque "De acordo com o historiador brasileiro Leandro Karnal, aquele que não respeita a faxineira, mas respeita o governador, é um interesseiro."',
            commentary: "Autor citado sem referência.",
            selections: [
                {
                    x: 285,
                    y: 347,
                    w: 215,
                    h: 30
                }
            ]
        },
        {
            id: 3,
            competence: {
                id: 3,
                tittle: "Competência 3",
                cod: 3,
                color: "#d60d0d",
                description: "Elaborar proposta de intervenção para o problema abordado, respeitando os direitos humanos"
            },
            tips:
                "Você está diante de um substantivo comum. Portanto, utilize letra minúscula. Em caso de dúvidas sobre a utilização de substantivos comuns e próprios, procure nossa monitoria de português. ;-)",
            commentary: "Substantivo comum.",
            selections: [
                {
                    x: 271,
                    y: 604,
                    w: 101,
                    h: 30
                },
                {
                    x: 375,
                    y: 605,
                    w: 80,
                    h: 22
                }
            ]
        }
    ]
};

export const competencies = [
    {
        id: 1,
        name: "Competência 1",
        description: "Demonstrar domínio da modalidade escrita formal da Língua Portuguesa",
        note: 160.0,
        noteMaximum: 200,
        color: "#d60d0d"
    },
    {
        id: 2,
        name: "Competência 2",
        description:
            "Compreender a proposta de redação e aplicar conceitos das várias áreas de conhecimento para desenvolver o tema, dentro dos limites estruturais do texto dissertativo-argumentativo em prosa",
        note: 160.0,
        noteMaximum: 200,
        color: "#00b8ff"
    },
    {
        id: 3,
        name: "Competência 3",
        description: "Selecionar, relacionar, organizar e interpretar informações, fatos, opiniões e argumentos em defesa de um ponto de vista",
        note: 200.0,
        noteMaximum: 200,
        color: "#00b8ff"
    },
    {
        id: 4,
        name: "Competência 4",
        description: "Demonstrar conhecimento dos mecanismos linguísticos necessários para a construção da argumentação",
        note: 160.0,
        noteMaximum: 200,
        color: "#d60d0d"
    },
    {
        id: 5,
        name: "Competência 5",
        description: "Elaborar proposta de intervenção para o problema abordado, respeitando os direitos humanos",
        note: 200.0,
        noteMaximum: 200,
        color: "#d60d0d"
    }
];
