import React, { useCallback, useMemo } from "react";

// Assets
import { ExerciseListContentContainer } from "screens/ExerciseList/styles";
import { ExerciseListResultListQuestions, ExerciseListResultListQuestionsTitle, ExerciseListResultListQuestionsHeader } from "./styles";
import FinalizarListaIcon from "prodigio-icons/web/filled/FinalizarLista";

// Components
import ExerciseListResultCard from "../ResultCard";
import ExerciseListResultQuestion from "../ResultQuestion";
import { Row, Col } from "components/Grid";

// Helpers
import getCurrentAnswer from "screens/ExerciseList/utils/getCurrentAnswer";
import ExerciseListResolutionMode from "screens/ExerciseList/enums/ExerciseListResolutionMode";
import useIsMobile from "hooks/use-is-mobile";
import getCurrentQuestionResolutions from "screens/ExerciseList/utils/getCurrentQuestionResolutions";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";
import { IAnswerCardItem } from "store/ducks/answerCard";
import IExerciseListScreenresolutions from "screens/ExerciseList/interfaces/IExerciseListScreenresolutions";
import { IExerciseListQuestion } from "interfaces/IExerciseListQuestion";
import { IQuestion } from "interfaces/IQuestion";

import ExerciseListResultKnowledgeArea from "../ResultKnowledgeArea";
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { formatToNaturalNumber } from "utils/formatToNaturalNumber";
import AnswerCardEssay from "components/AnswerCardEssay";

import { Creators as exerciseListActions } from "store/ducks/exerciseList";
import { useDispatch } from "react-redux";

interface IProps {
    questions: IExerciseListQuestion[];
    showAnswers: boolean;
    answers: IAnswerCardItem[];
    showNotes: boolean;
    resolutionMode: ExerciseListResolutionMode;
    questionsResolutions: IExerciseListScreenresolutions[];
    startDate: string;
    endDate: string;
    isExtra: boolean;
    points: number;
    isExam: boolean;
    onClickShowQuestion(questionNumber: number): void;
    onClickSeeResolution(data: { resolutions: IExerciseListQuestionResolution[]; question: IQuestion; answer: IAnswerCardItem }): void;
    knowledgeAreas: IExamKnowledgeAreas[];
    totalQuestions: number;
}

const ExerciseListResult = ({
    questions,
    showAnswers,
    answers,
    showNotes,
    resolutionMode,
    onClickShowQuestion,
    questionsResolutions,
    onClickSeeResolution,
    startDate,
    endDate,
    isExtra,
    points,
    isExam,
    knowledgeAreas,
    totalQuestions = 0
}: IProps) => {
    const isMobile = useIsMobile();

    const totalHits = useMemo(() => {
        try {
            if (!answers) {
                throw new Error();
            }

            return answers.filter((answer) => !!answer.hit).length;
        } catch (error) {
            return 0;
        }
    }, [answers]);

    const totalErrors = useMemo(() => {
        return formatToNaturalNumber(totalQuestions - totalHits);
    }, [totalHits, totalQuestions]);

    const currentResolutions = useCallback((questionId: number) => getCurrentQuestionResolutions({ questionsResolutions, questionId }), [
        questionsResolutions
    ]);

    const maxExtraExerciseListPoints = useMemo(() => {
        try {
            if (!questions || !isExtra) {
                throw new Error();
            }

            const total = questions.reduce((acc, curr) => acc + (curr!.points! || 0), 0);

            return total;
        } catch (error) {
            return 0;
        }
    }, [questions, isExtra]);

    const listExerciseListQuestions = useMemo(() => {
        try {
            if (!questions) {
                throw new Error();
            }

            return questions.map((questionItem, index) => {
                const currentAnswer = getCurrentAnswer({ answers, questionId: questionItem.question.id });
                const resolutions = currentResolutions(questionItem.question.id);

                return (
                    <ExerciseListResultQuestion
                        key={questionItem.id}
                        number={questionItem.question.number || index + 1}
                        question={questionItem.question}
                        answer={currentAnswer}
                        onClickShowQuestion={() => onClickShowQuestion(questionItem.question.number || index + 1)}
                        onClickSeeResolution={() => onClickSeeResolution({ resolutions, question: questionItem.question, answer: currentAnswer })}
                        resolutions={resolutions}
                        showAnsers={showAnswers}
                        isExtra={isExtra}
                    />
                );
            });
        } catch (error) {
            return null;
        }
    }, [answers, currentResolutions, isExtra, onClickSeeResolution, onClickShowQuestion, questions, showAnswers]);

    const dispatch = useDispatch();

    const handleClickSeeEssay = useCallback(() => {
        dispatch(exerciseListActions.goToEssay());
    }, [dispatch]);

    const listExamKnowledgeAreas = useMemo(() => {
        try {
            return (
                <>
                    {knowledgeAreas.map(({ name, id, color, questions }) => (
                        <ExerciseListResultKnowledgeArea
                            key={id}
                            answers={answers}
                            name={name}
                            color={color}
                            onClickSeeResolution={onClickSeeResolution}
                            onClickShowQuestion={onClickShowQuestion}
                            questions={questions}
                            questionsResolutions={questionsResolutions}
                            showAnswers={showAnswers}
                        />
                    ))}
                    <AnswerCardEssay onClickSeeEssay={handleClickSeeEssay} />
                </>
            );
        } catch (error) {
            return null;
        }
    }, [answers, handleClickSeeEssay, knowledgeAreas, onClickSeeResolution, onClickShowQuestion, questionsResolutions, showAnswers]);

    return (
        <ExerciseListContentContainer>
            <Row reverse={!isMobile}>
                <ExerciseListResultCard
                    resolutionMode={resolutionMode}
                    showAnswers={showAnswers}
                    totalHits={totalHits}
                    totalErrors={totalErrors}
                    endDate={endDate}
                    startDate={startDate}
                    isExtra={isExtra}
                    maxPoints={maxExtraExerciseListPoints}
                    totalPoints={points}
                />
                <Col xs={12} md={8}>
                    <ExerciseListResultListQuestions data-test-id="result-questions">
                        <ExerciseListResultListQuestionsHeader>
                            <ExerciseListResultListQuestionsTitle>
                                <FinalizarListaIcon width={22} height={24} /> Gabarito
                            </ExerciseListResultListQuestionsTitle>
                        </ExerciseListResultListQuestionsHeader>

                        {isExam ? listExamKnowledgeAreas : listExerciseListQuestions}
                    </ExerciseListResultListQuestions>
                </Col>
            </Row>
        </ExerciseListContentContainer>
    );
};

export default React.memo(ExerciseListResult);
