import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { SelectContainer } from "components/Select/styles";
import { CardContainerSmallStyle } from "assets/styles/global";
import { EssayThemesContentTitle, EssayThemesRow } from "../themes/styles";
import { EssayDispatchContainer, EssayDispatchRow, EssayDispatchThemeTitle } from "../components/styles";

export const MyEssaysContainer = styled.section``;

export const MyEssaysContent = styled.div`
    padding: 0 ${theme.spacing.smallX};

    ${EssayThemesContentTitle} {
        margin-bottom: ${theme.spacing.small};
    }

    ${EssayThemesRow} {
        margin-bottom: ${theme.spacing.small};

        ${EssayThemesContentTitle} {
            margin-bottom: 0;
        }
    }

    ${EssayDispatchContainer} {
        background: ${theme.colors.white};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-bottom: ${theme.spacing.medium};
        padding-top: ${theme.spacing.medium};

        ${EssayDispatchContainer} {
            height: 100%;
            padding-bottom: ${theme.spacing.small};
            padding-top: ${theme.spacing.small};
        }

        ${EssayDispatchRow} {
            &:not(:last-child) {
                margin-bottom: ${theme.spacing.small};
                padding-bottom: ${theme.spacing.small};
            }
        }

        ${EssayDispatchThemeTitle} {
            margin-bottom: ${theme.spacing.small};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${EssayThemesContentTitle},
        ${EssayThemesRow} {
            margin-bottom: ${theme.spacing.medium};
        }
    }
`;

export const MyEssaysPerformance = styled.div`
    ${CardContainerSmallStyle};
    margin-bottom: ${theme.spacing.medium};

    .react-select__control {
        border: unset;
        border-radius: ${theme.spacing.smallXX};
        min-height: unset;
        padding: 13px 16px;
        z-index: 11;
    }

    .react-select__value-container {
        padding: 0 16px 0 0;
    }

    .react-select__placeholder,
    .react-select__single-value {
        font-size: ${theme.typography.sizes.smallX};
    }

    .react-select__single-value {
        color: ${theme.colors.base[500]};
    }

    .react-select__menu {
        box-shadow: ${theme.shadow[3]};
        margin: 0;
    }

    ${SelectContainer} {
        border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    }
`;
