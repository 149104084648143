import React, { useCallback, useEffect } from "react";

// Assets
import { ExtraExerciseListsContainer, SubjectsHeader } from "./styles";

// Components
import { Grid } from "components/Grid";

// Helpers
import ExtraExerciseListScreenHome from "./Home";
import { IExtraExerciseListGroup } from "interfaces/IExerciseList";
import Spinner from "components/Spinner";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as ExerciseListActions } from "store/ducks/exerciseList";
import { IReduxStore } from "interfaces/IReduxStore";
import { IExerciseListActions } from "store/ducks/exerciseList/types";

interface ISubjectsProps {
    subjects: IExtraExerciseListGroup[];
    isLoading: boolean;
}

const ExtraExerciseListsScreen = ({ subjects, isLoading }: ISubjectsProps) => {
    return (
        <ExtraExerciseListsContainer>
            <Grid>
                <SubjectsHeader>
                    <h1>Avaliações</h1>
                </SubjectsHeader>

                {isLoading ? (
                    <Spinner fixed={false} />
                ) : (
                    subjects.map((subjectGroup) => <ExtraExerciseListScreenHome subjectGroup={subjectGroup} key={subjectGroup.id} />)
                )}
            </Grid>
        </ExtraExerciseListsContainer>
    );
};

type ExtraExerciseListsProps = {
    extras: IExtraExerciseListGroup[];
    exerciseListActions: IExerciseListActions;
    isLoading: boolean;
};

const ExtraExerciseLists = ({ extras, exerciseListActions, isLoading }: ExtraExerciseListsProps) => {
    const requestSubjects = useCallback(() => {
        exerciseListActions.getAllExtrasRequest();

        return () => {
            exerciseListActions.clearExerciseList();
        };
    }, [exerciseListActions]);

    useEffect(() => {
        requestSubjects();
    }, [requestSubjects]);

    return <ExtraExerciseListsScreen subjects={extras} isLoading={isLoading} />;
};

const mapStateToProps = ({ exerciseList }: IReduxStore) => ({
    extras: exerciseList.extras,
    isLoading: exerciseList.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exerciseListActions: bindActionCreators(ExerciseListActions, dispatch)
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ExtraExerciseLists);
