import styled from "styled-components";
import { VideoContainer } from "components/Video/styles";
import { SpinnerWrap } from "components/Spinner/styles";
import { theme } from "config/theme";
import { GridWidth } from "components/Grid";
import { MediaQueries } from "assets/styles/settings";
import Button from "components/Button";

export const LightVideoContainer = styled.div`
    width: 100%;
    margin: ${theme.spacing.large} auto;
    padding: 0 10px;

    ${GridWidth};
`;

export const LightVideoLoadingContainer = styled(VideoContainer)`
    > ${SpinnerWrap} {
        z-index: 1;
    }
`;

export const LightVideoForumContainer = styled.div`
    margin: ${theme.spacing.large} 0;
`;

export const LightVideoContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
    }
`;

export const LightVideoContentTexts = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    h1 {
        color: ${theme.colors.base[500]};
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex: 1;
        margin-bottom: unset;
    }
`;

export const LightVideoButtonsWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
`;

export const LightVideoExternPlayerButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 44px;

    border-radius: 4px;
    background-color: ${theme.colors.brand[300]};
    border: 1px solid ${theme.colors.brand[300]};

    color: ${theme.colors.white};
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.08px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    width: 100%;

    @media (min-width: 768px) {
        width: unset;
    }
`;

export const LightVideoReportButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    justify-content: center;
    align-items: center;

    width: 100%;

    @media (min-width: 768px) {
        width: unset;
    }

    > small {
        margin-bottom: 8px;
        color: ${theme.colors.base[300]};
        font-size: ${theme.typography.sizes.small};
    }
`;

export const LightVideoReportButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 44px;

    width: 100%;
    border-radius: 4px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.brand[300]};
    color: ${theme.colors.brand[300]};
    font-family: "Nunito", sans-serif;
    letter-spacing: 0.08px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    :hover {
        background-color: ${theme.colors.brand[100]};
    }

    @media (min-width: 768px) {
        width: unset;
    }
`;

export const LightVideoDoubts = styled.div`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LightVideoContentHeaderDoubt = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: 60px;
    }
`;

export const LightVideoTitleClearDoubt = styled.h3`
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    color: ${theme.colors.black};
`;

export const LightVideoFilterDoubt = styled.div`
    display: flex;
    gap: 8px;
`;

export const LightVideoDivider = styled.div`
    display: flex;
    margin: 20px 0;
    width: 100%;
    border: 1px solid ${theme.colors.base[100]};
`;

export const LightVideoContentDoubts = styled.div`
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LightVideoButtonToReLoadPage = styled(Button)`
    width: 100%;
    margin: 8px auto 0;
`;
