import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";

export const EssayPerformanceChartContainer = styled.div`
    padding: ${theme.spacing.small};

    .VictoryContainer,
    svg {
        display: block;
    }

    .group-wrapper {
        * {
            transition: all 0.2s ease;
        }

        &:hover {
            > .axis-line {
                stroke: ${theme.colors.secondary[100]} !important;
            }

            > text > tspan {
                fill: ${theme.colors.base[500]} !important;
            }
        }
    }

    .bullet-label {
        * {
            transition: all 0.2s ease;
        }

        &:hover {
            text {
                fill: ${theme.colors.system.success[400]} !important;
            }

            line {
                stroke: ${theme.colors.system.success[400]} !important;
            }
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        padding: ${theme.spacing.large};
    }
`;
