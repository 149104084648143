import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import React from "react";

import * as S from "./styles";

const FinishedExamDaysSkeleton: React.FC = () => {
    return (
        <S.FinishedExamDaysContainer>
            <p>
                <Skeleton width="35%" height="20px" />
            </p>

            <Skeleton
                width="100%"
                height="auto"
                light
                style={{ borderRadius: "4px", marginBottom: theme.spacing.small, padding: theme.spacing.small }}
            >
                <Skeleton width="95%" height="22px" />
            </Skeleton>

            <Skeleton
                width="100%"
                height="auto"
                light
                style={{ borderRadius: "4px", marginBottom: theme.spacing.small, padding: theme.spacing.small }}
            >
                <Skeleton width="95%" height="22px" />
            </Skeleton>
        </S.FinishedExamDaysContainer>
    );
};

export default FinishedExamDaysSkeleton;
