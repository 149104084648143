import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { DatePickerHeaderContainer, DatePickerContainer } from "./styles";

const DatePickerPlaceholder = () => {
    return (
        <DatePickerContainer style={{ background: "#FFF" }}>
            <div className="react-datepicker__header">
                <DatePickerHeaderContainer>
                    <Skeleton width="4px" height="8px" style={{ marginLeft: 7 }} />
                    <Skeleton width="93px" height="16px" />
                    <Skeleton width="4px" height="8px" style={{ marginRight: 7 }} />
                </DatePickerHeaderContainer>
            </div>
            <div className="react-datepicker__day-names">
                {Array.from(Array(7)).map((_, index) => (
                    <div className="react-datepicker__day-name" key={index}>
                        <Skeleton width="16px" height="12px" />
                    </div>
                ))}
            </div>
            <div className="react-datepicker__month" style={{ paddingBottom: 16 }}>
                {Array.from(Array(5)).map((_, index) => (
                    <div className="react-datepicker__week" style={{ marginBottom: 0 }} key={index}>
                        {Array.from(Array(7)).map((_, ix) => (
                            <div className="react-datepicker__day" key={ix}>
                                <span className="react-datepicker__day-helper">
                                    <Skeleton width="16px" height="12px" />
                                </span>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </DatePickerContainer>
    );
};

export default DatePickerPlaceholder;
