// Dependencies
import React, { FunctionComponent, ReactNode, useState } from "react";

// Styles
import { Container, WebpDropDownCompositionWrapper } from "./styles";

// Components
import { WebpButton } from "components/WebpButton";
import { WebpCard } from "components/WebpCard";
import { WebpDropdown } from "components/WebpDropdown";

export const FlatVariants = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    POSITIVE: "positive",
    NEGATIVE: "negative",
    WARNING: "warning",
    NEUTRAL: "neutral",
    INFO: "info"
} as const;
export type FlatVariant = typeof FlatVariants[keyof typeof FlatVariants];

export type WebpQuestionReportCardProps = {
    name: string;
    subname: string;
    badgeContent?: string;
    webpButtonHeaderComposition?: ReactNode;
    webpDropdownComposition?: ReactNode;
    webpBodyComposition?: ReactNode;
    webpButtonFooter: any;
    isNew: boolean;
    cardButtonClicked?: () => void;
};

export const WebpQuestionReportCard: FunctionComponent<WebpQuestionReportCardProps> = ({
    name,
    badgeContent,
    cardButtonClicked,
    subname,
    webpButtonFooter,
    webpDropdownComposition,
    webpButtonHeaderComposition,
    webpBodyComposition,
    isNew
}) => {
    const [isDropDownOpened, setIsDropDownOpened] = useState(false);

    const toggleDropdown = () => {
        setIsDropDownOpened(!isDropDownOpened);
    };

    return (
        <Container isNew={isNew}>
            <WebpCard
                headerComposition={
                    <div className="wrapper-header">
                        <div className="left">
                            <h3 className="name">{name}</h3>

                            <p className="subname">{subname}</p>
                        </div>

                        <div className="right">
                            {!!badgeContent && <div className="badge">{badgeContent}</div>}

                            <div onClick={() => toggleDropdown()} className="wrapper-button-dropdown">
                                <button>{webpButtonHeaderComposition}</button>

                                <WebpDropdown isOpened={isDropDownOpened}>
                                    <WebpDropDownCompositionWrapper>{webpDropdownComposition}</WebpDropDownCompositionWrapper>
                                </WebpDropdown>
                            </div>
                        </div>
                    </div>
                }
                contentComposition={<div className="wrapper-body">{webpBodyComposition}</div>}
                footerComposition={
                    <div className="wrapper-footer">
                        <WebpButton
                            {...webpButtonFooter}
                            onClick={() => !!cardButtonClicked && cardButtonClicked()}
                            contentButtonComposition={webpButtonFooter.label}
                            size={"small"}
                        ></WebpButton>
                    </div>
                }
            ></WebpCard>
        </Container>
    );
};
