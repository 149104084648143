import React, { useMemo } from "react";
import { Col } from "components/Grid";
import {
    ExerciseListResultCardContainer,
    ExerciseListResultCardLegend,
    ExerciseListResultCardExecutionTimeLegend,
    ExerciseListResultCardLegends,
    ExerciseListResultCardChartLegend,
    ExerciseListResultCardChartCenter,
    ExerciseListResultCardChartLegendDesktop,
    ExerciseListResultCardAwaitingLegend
} from "./styles";
import useIsMobile from "hooks/use-is-mobile";
import ExerciseListResultCardChart from "./chart";

// assets
import SuccessIcon from "prodigio-icons/web/filled/Sucess";
import ErrorIcon from "prodigio-icons/web/filled/Error";
import ProcessingIcon from "prodigio-icons/web/filled/Processing";
import ExerciseListResolutionMode from "screens/ExerciseList/enums/ExerciseListResolutionMode";
import { BreakPoints } from "assets/styles/settings";
import { theme } from "config/theme";
import ExerciseListTimer from "../Timer";
import pluralize from "utils/pluralize";

interface IProps {
    resolutionMode: ExerciseListResolutionMode;
    showAnswers: boolean;
    totalHits: number;
    totalErrors: number;
    startDate: string;
    endDate: string;
    isExtra: boolean;
    maxPoints: number;
    totalPoints: number;
}

const ExerciseListResultCard = ({
    resolutionMode,
    showAnswers,
    totalErrors,
    totalHits,
    startDate,
    endDate,
    isExtra = true,
    maxPoints,
    totalPoints
}: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);
    const isTablet = useIsMobile();

    const legend = useMemo(() => {
        if (isExtra) {
            return "Estatísticas da Lista Extra";
        }

        if (resolutionMode === ExerciseListResolutionMode.Exam) {
            return "Estatísticas do Modo prova";
        }

        if (resolutionMode === ExerciseListResolutionMode.Study) {
            return "Estatísticas do Modo Estudo";
        }

        return "Estatísticas";
    }, [resolutionMode, isExtra]);

    const chartLegends = useMemo(() => {
        if (isExtra) {
            return null;
        }

        return (
            <ExerciseListResultCardLegends isDesktop={!isMobile && !isTablet}>
                <ExerciseListResultCardChartLegend>
                    <SuccessIcon width={13} height={13} />
                    <span data-test-id="exercise-list-legend-total-hits">
                        {totalHits} {pluralize(totalHits, "acertos", "acerto")}
                    </span>
                </ExerciseListResultCardChartLegend>

                <ExerciseListResultCardChartLegend>
                    <ErrorIcon width={13} height={13} />
                    <span data-test-id="exercise-list-legend-total-errors">
                        {totalErrors} {pluralize(totalErrors, "erros", "erro")}
                    </span>
                </ExerciseListResultCardChartLegend>
            </ExerciseListResultCardLegends>
        );
    }, [isExtra, isMobile, isTablet, totalErrors, totalHits]);

    return (
        <>
            {(isMobile || isTablet) && (
                <Col xs={isMobile || !showAnswers ? 12 : 6} style={{ marginBottom: theme.spacing.medium }}>
                    <ExerciseListResultCardContainer style={{ height: isMobile ? "auto" : "100%" }}>
                        <ExerciseListResultCardLegend>{legend}</ExerciseListResultCardLegend>

                        <ExerciseListResultCardExecutionTimeLegend>Tempo de execução:</ExerciseListResultCardExecutionTimeLegend>

                        <ExerciseListTimer startDate={startDate} endDate={!!endDate ? endDate : new Date().toString()} />

                        {!showAnswers && isExtra && (
                            <ExerciseListResultCardAwaitingLegend>
                                Aguardando correção... <ProcessingIcon />
                            </ExerciseListResultCardAwaitingLegend>
                        )}

                        {isMobile && showAnswers && (
                            <ExerciseListResultCardChartCenter>
                                {isExtra ? (
                                    <ExerciseListResultCardChart totalErrors={maxPoints - totalPoints} totalHits={totalPoints} isExtra={true} />
                                ) : (
                                    <ExerciseListResultCardChart totalErrors={totalErrors} totalHits={totalHits} />
                                )}
                            </ExerciseListResultCardChartCenter>
                        )}
                    </ExerciseListResultCardContainer>
                </Col>
            )}

            {!isMobile && isTablet && showAnswers && (
                <Col xs={6} style={{ marginBottom: theme.spacing.medium }}>
                    <ExerciseListResultCardContainer style={{ alignItems: "center" }}>
                        {isExtra ? (
                            <ExerciseListResultCardChart totalErrors={maxPoints - totalPoints} totalHits={totalPoints} isExtra={true} />
                        ) : (
                            <ExerciseListResultCardChart totalErrors={totalErrors} totalHits={totalHits} />
                        )}

                        {chartLegends}
                    </ExerciseListResultCardContainer>
                </Col>
            )}

            {!isMobile && !isTablet && (
                <Col md={4}>
                    <ExerciseListResultCardContainer>
                        <ExerciseListResultCardLegend>{legend}</ExerciseListResultCardLegend>

                        <ExerciseListResultCardChartLegendDesktop>
                            <ExerciseListResultCardExecutionTimeLegend>Tempo de execução:</ExerciseListResultCardExecutionTimeLegend>

                            <ExerciseListTimer startDate={startDate} endDate={!!endDate ? endDate : new Date().toString()} />
                        </ExerciseListResultCardChartLegendDesktop>

                        {!showAnswers && isExtra && (
                            <ExerciseListResultCardAwaitingLegend>
                                Aguardando correção... <ProcessingIcon />
                            </ExerciseListResultCardAwaitingLegend>
                        )}

                        {showAnswers && (
                            <>
                                {isExtra ? (
                                    <ExerciseListResultCardChart
                                        isDesktop={true}
                                        totalErrors={maxPoints - totalPoints}
                                        totalHits={totalPoints}
                                        isExtra={true}
                                    />
                                ) : (
                                    <ExerciseListResultCardChart isDesktop={true} totalErrors={totalErrors} totalHits={totalHits} />
                                )}

                                {chartLegends}
                            </>
                        )}
                    </ExerciseListResultCardContainer>
                </Col>
            )}
        </>
    );
};

export default React.memo(ExerciseListResultCard);
