import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { LessonPlanModulesTitle, LessonPlanModulesContent, LessonPlanModuleEventsWrapper } from "../styles";
import { EventCardOuter, EventCardContainer, EventCardWrapper, EventCardHeaderContainer, EventSchedule, EventTitle } from "../EventCard/styles";

const ModulesListPlaceholder = () => {
    return (
        <>
            <LessonPlanModulesTitle>
                <Skeleton width="220px" height="20px" style={{ margin: "0 auto" }} />
            </LessonPlanModulesTitle>
            <LessonPlanModulesContent>
                <LessonPlanModuleEventsWrapper>
                    {Array.from(Array(5)).map((_, index) => (
                        <EventCardOuter key={index}>
                            <EventCardContainer isPassed={false} isHappening={false}>
                                <EventCardWrapper>
                                    <EventCardHeaderContainer>
                                        <Skeleton width="70px" height="20px" style={{ borderRadius: 40 }} />
                                    </EventCardHeaderContainer>
                                    <EventCardWrapper>
                                        <EventSchedule>
                                            <Skeleton width="12px" height="12px" style={{ borderRadius: "50%" }} />
                                            <span>
                                                <Skeleton width="84px" height="12px" />
                                            </span>
                                            <Skeleton width="65px" height="20px" style={{ borderRadius: 4 }} />
                                        </EventSchedule>
                                    </EventCardWrapper>
                                </EventCardWrapper>
                                <EventTitle>
                                    <Skeleton width="70%" height="14px" />
                                </EventTitle>
                                <EventCardWrapper>
                                    <Skeleton width="100px" height="12px" />
                                    <Skeleton width="28px" height="28px" style={{ borderRadius: "50%" }} />
                                </EventCardWrapper>
                            </EventCardContainer>
                        </EventCardOuter>
                    ))}
                </LessonPlanModuleEventsWrapper>
            </LessonPlanModulesContent>
        </>
    );
};

export default ModulesListPlaceholder;
