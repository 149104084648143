// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceTopicsProps } from "./performance-topics.types";

// Styles
import { Container, TopicsWrapper } from "./performance-topics.styles";

export const PerformanceTopics: FunctionComponent<PerformanceTopicsProps> = ({ iconBadgeElement, topicCompositions }) => {
    return (
        <Container>
            <div>{iconBadgeElement}</div>

            <TopicsWrapper>{topicCompositions}</TopicsWrapper>
        </Container>
    );
};
