import { theme } from "config/theme";
import { MediaQueries, MediaQueriesManual } from "assets/styles/settings";
import styled, { css } from "styled-components";
import { fadein } from "assets/styles/animations";
import { AccordionContainer, AccordionButton } from "components/Accordion/styles";
import { ButtonWrapper } from "components/Button/styles";

export const ExamDetailsDayContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin: ${theme.spacing.medium};
    padding: ${theme.spacing.large} ${theme.spacing.medium};
    background-color: ${theme.colors.white};
    border-radius: 8px;

    position: relative;
    bottom: 72px;

    ${AccordionButton} {
        background: ${theme.colors.white};
        border-radius: 8px;
    }

    ${AccordionContainer} {
        background: ${theme.colors.white};
        box-shadow: ${theme.shadow[2]};
        margin-bottom: ${theme.spacing.small};
        border-radius: 8px;
    }
`;

interface IExamDetailsDayHeaderStyleProps {
    variant?: "brand" | "secondary" | "base";
}

export const ExamDetailsDayHeader = styled.div<IExamDetailsDayHeaderStyleProps>`
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};

    :before {
        position: absolute;
        left: 0;
        content: " ";
        width: 4px;
        height: 24px;
        flex-shrink: 0;
        background-color: ${({ variant = "base" }) => theme.colors[variant][300]};
        border-radius: 8px;
    }
`;

export const ExamDetailsDayTexts = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    h2 {
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.medium};
        font-family: "Nunito Sans", sans-serif;
        line-height: 22px;
    }

    p {
        color: ${theme.colors.base[300]};
        font-family: "Nunito Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
`;

export const ExamDetailsDayTime = styled.div`
    display: flex;
    align-items: center;

    span {
        color: ${theme.colors.base[400]};
        font-weight: 600;
        font-family: "Nunito", sans-serif;
        font-size: 14px;
        font-weight: 600;
    }

    svg {
        margin-right: ${theme.spacing.smallXX};
    }
`;

export const ExamDetailsDayContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.small};
`;

export const ExamDetailsDayContentKnowledgeAreaWrapp = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
`;

export const ExamDetailsDayContentKnowledgeArea = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.base[400]};

    p {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-family: "Nunito Sans", sans-serif;
    }

    span {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExamDetailsDayInPersonApplyment = styled.div`
    padding: ${theme.spacing.small};
    background: ${theme.colors.system.info[100]};
    color: ${theme.colors.system.info[500]};
    border-radius: 4px;
    margin-bottom: ${theme.spacing.small};

    > p {
        margin-bottom: 10px;
    }

    > svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExamDetailsDayAppliedPeriod = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};

    strong {
        font-weight: 600;
        font-size: 14px;
        color: ${theme.colors.base[500]};
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const ExamDetailsDayAppliedPeriodChart = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    :before {
        content: " ";
        width: 1px;
        border: 1px dashed ${theme.colors.base[200]};
        position: absolute;
        left: 5px;
        z-index: -1;
        height: calc(100% - 10px);
        z-index: 1;
    }
`;

export const ExamDetailsDayAppliedPeriodBull = styled.div<{ variant: "info" | "danger" | "success" }>`
    content: " ";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: ${({ variant }) => theme.colors.system[variant][300]};
    border: 3px solid ${({ variant }) => theme.colors.system[variant][200]};
    margin-right: ${theme.spacing.smallX};
`;

export const ExamDetailsDayAppliedPeriodDay = styled.div`
    display: flex;
    align-items: center;
    z-index: 2;

    :not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }

    p {
        color: ${theme.colors.base[400]};
        font-family: "Nunito Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        > span {
            color: ${theme.colors.base[300]};
        }
    }
`;

export const ExamDetailsDayOptionButton = styled.button<{ withSpacing?: boolean }>`
    display: flex;
    border: none;
    background: transparent;
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
    padding: ${theme.spacing.smallX} ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};
    align-items: center;
    animation: ${fadein} 0.5s ease;
    font-weight: 600;

    :hover {
        background: ${theme.colors.base[100]};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    svg {
        margin-right: ${theme.spacing.small};
    }

    ${({ withSpacing }) =>
        withSpacing &&
        css`
            margin-bottom: unset;
            animation: unset;
            font-weight: normal;

            :before {
                content: "";
                display: block;
                width: 20px;
                margin-right: ${theme.spacing.small};
            }
        `};
`;

export const ExamDetailsDayTester = styled.div`
    display: flex;
    flex-direction: column;

    p {
        text-align: center;
        margin-bottom: ${theme.spacing.small};
    }

    > div {
        display: flex;
        margin-bottom: 20px;

        ${ButtonWrapper} {
            flex: 1;

            :first-child {
                margin-right: 20px;
            }
        }
    }
`;

export const ContentButtons = styled.div`
    gap: 15px;
    display: flex;
    flex-wrap: wrap;

    ${MediaQueriesManual(400)} {
        flex-wrap: nowrap;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-wrap: wrap;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-wrap: nowrap;
    }
`;

export const ExamDetailsDayTitle = styled.p`
    color: ${theme.colors.base[500]};
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    margin-bottom: 14px;
`;
