import React, { useMemo, useState, useCallback, useEffect } from "react";

import { useSelector } from "react-redux";

import {
    ExerciseListAnswerCardModalBackground,
    ExerciseListAnswerCardModalContainer,
    ExerciseListAnswerCardModalHeader,
    ExerciseListAnswerCardModalCloseButton,
    ExerciseListAnswerCardModalCloseHeader,
    ExerciseListAnswerCardModalTitle
} from "./styles";
import AnswerCardIcon from "prodigio-icons/web/filled/CartaoResposta";
import CloseIcon from "prodigio-icons/web/outlined/Close";

import ExerciseListAnswerCard from "../AnswerCard";
import { theme } from "config/theme";
import Filter from "components/Filter";
import { IAnswerCardItem } from "store/ducks/answerCard";
import { IExerciseListQuestion } from "interfaces/IExerciseListQuestion";
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { ISelectOption } from "interfaces/ISelectOption";
import { IReduxStore } from "interfaces/IReduxStore";
import Spinner from "components/Spinner";
import AnswerCardEssay from "components/AnswerCardEssay";

interface IExerciseListAnswerCardModalProps {
    showAnswers: boolean;
    showNotes: boolean;
    isFinished: boolean;
    onClickClose(): void;
    onClickSeeQuestion(questionNumber: number): void;
    isFeedBack?: boolean;
    canSeeQuestion?: boolean;
    isLoading?: boolean;
    handleClickSeeEssay?(): void;
}

const ExerciseListAnswerCardModal = ({
    onClickClose,
    showAnswers,
    showNotes,
    onClickSeeQuestion,
    isFinished,
    isFeedBack = false,
    canSeeQuestion = true,
    isLoading = false,
    handleClickSeeEssay
}: IExerciseListAnswerCardModalProps) => {
    const answers = useSelector<IReduxStore, IAnswerCardItem[]>((state) => state.answerCard?.answerCard?.answers || []);
    const questions = useSelector<IReduxStore, IExerciseListQuestion[]>((state) => state.exerciseList?.items || []);
    const knowledgeAreas = useSelector<IReduxStore, IExamKnowledgeAreas[]>((state) => state.exerciseList?.knowledgeAreas || []);

    const [knowledgeAreaFilter, setKnowledgeAreaFilter] = useState<IExamKnowledgeAreas[]>([]);

    useEffect(() => {
        setKnowledgeAreaFilter(knowledgeAreas);
    }, [knowledgeAreas]);

    const handleChangeFilter = useCallback(
        (option: ISelectOption) => {
            try {
                if (!option || !option.value) {
                    return setKnowledgeAreaFilter(knowledgeAreas);
                }

                setKnowledgeAreaFilter(knowledgeAreas.filter((knowledgeArea) => knowledgeArea.id === option?.value));
            } catch (error) {
                console.log(error);
            }
        },
        [knowledgeAreas]
    );

    const memoKnowledgeAreaFilter = useMemo(() => {
        try {
            if (!knowledgeAreas?.length) {
                return null;
            }

            const optionsFilter: ISelectOption[] = [
                { value: 0, label: "Ver tudo" },
                ...knowledgeAreas.map((knowledgeArea) => ({ value: knowledgeArea.id, label: knowledgeArea.name }))
            ];

            return <Filter options={optionsFilter} onChange={handleChangeFilter} />;
        } catch (error) {
            return null;
        }
    }, [handleChangeFilter, knowledgeAreas]);

    return (
        <ExerciseListAnswerCardModalBackground id="ExerciseListAnswerCardBackground" onClick={onClickClose}>
            <ExerciseListAnswerCardModalContainer data-test-id="exercise-list-answer-card-modal" onClick={(e: any) => e.stopPropagation()}>
                <ExerciseListAnswerCardModalCloseHeader>
                    <ExerciseListAnswerCardModalCloseButton data-test-id="exercise-list-answer-card-modal-close-button" onClick={onClickClose}>
                        <CloseIcon width={16} height={16} color={theme.colors.base[500]} />
                    </ExerciseListAnswerCardModalCloseButton>
                </ExerciseListAnswerCardModalCloseHeader>
                <ExerciseListAnswerCardModalHeader>
                    <ExerciseListAnswerCardModalTitle>
                        <AnswerCardIcon width={24} height={24} />
                        <p>Cartão resposta</p>
                    </ExerciseListAnswerCardModalTitle>

                    {memoKnowledgeAreaFilter}
                </ExerciseListAnswerCardModalHeader>

                {isLoading ? (
                    <Spinner fixed={false} />
                ) : (
                    <>
                        <ExerciseListAnswerCard
                            questions={questions}
                            showAnswers={showAnswers}
                            answers={answers}
                            showNotes={showNotes}
                            onClickSeeQuestion={onClickSeeQuestion}
                            isFinished={isFinished}
                            knowledgeAreas={knowledgeAreaFilter}
                            isFeedBack={isFeedBack}
                            canSeeQuestion={canSeeQuestion}
                        />

                        <AnswerCardEssay {...(canSeeQuestion && { onClickSeeEssay: handleClickSeeEssay })} />
                    </>
                )}
            </ExerciseListAnswerCardModalContainer>
        </ExerciseListAnswerCardModalBackground>
    );
};

export default React.memo(ExerciseListAnswerCardModal);
