import styled from "styled-components";
import { theme } from "config/theme";
import { PageContainer } from "assets/styles/global";
import { CardContainer } from "components/Card/styles";
import { fadeInUp } from "assets/styles/animations";
import { PageHeaderContainer } from "components/PageHeader/styles";

export const ExamDayResultContainer = styled(PageContainer)`
    ${CardContainer} {
        flex-direction: column;
        margin-bottom: ${theme.spacing.medium};
        animation: ${fadeInUp} 0.25s linear;
    }

    ${PageHeaderContainer} {
        margin-bottom: ${theme.spacing.medium};
    }
`;
