// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceResumeProps } from "./performance-resume.types";

// Styles
import { Container, Title, Description, ContentWrapper, ResumeArea, ResumeAreaEssay } from "./performance-resume.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceResume: FunctionComponent<PerformanceResumeProps> = ({
    title,
    description,
    resumeAreaCompositions,
    resumeAreaEssayComposition
}) => {
    return (
        <Container>
            <Title>{title}</Title>

            <Spacing size={8} direction="vertical" />

            <Description>{description}</Description>

            <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="hide" content={<Spacing size={20} direction="vertical" />} />

            <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="show" content={<Spacing size={24} direction="vertical" />} />

            <ContentWrapper>
                <ResumeArea>{resumeAreaCompositions}</ResumeArea>

                <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="vertical" />} />

                <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="show" content={<Spacing size={16} direction="horizontal" />} />

                <ResumeAreaEssay>{resumeAreaEssayComposition}</ResumeAreaEssay>
            </ContentWrapper>
        </Container>
    );
};
