import React from "react";

// Assets
import { ContentList, ContentListItemButton } from "assets/styles/global";
import ExerciseListIcon from "prodigio-icons/web/filled/ExerciseList";
import MaterialIcon from "prodigio-icons/web/filled/Complement";
import DownloadEbookIcon from "prodigio-icons/web/filled/DownloadEbook";
import { WatchScreenRelatedContentContainer, WatchScreenRelatedContentTitle } from "./styles";

// Components
import Accordion, { AccordionOption } from "components/Accordion";
import { AccordionIconWrapper, AccordionListDecoration, AccordionListDecorationWrapper } from "components/Accordion/styles";
import { IRelatedExerciseList, IRelatedMaterial } from "interfaces/IRelatedContent";

interface IWatchScreenRelatedContentProps {
    relatedExerciseLists?: IRelatedExerciseList[] | undefined;
    onClickExerciseList(id: number): void;
    relatedMaterials?: IRelatedMaterial[] | undefined;
    relatedBooklets?: IRelatedMaterial[] | undefined;
    onClickMaterial(material: IRelatedMaterial): void;
}

const WatchScreenRelatedContent = ({
    relatedExerciseLists,
    onClickExerciseList,
    relatedMaterials,
    relatedBooklets,
    onClickMaterial
}: IWatchScreenRelatedContentProps) => {
    const haveExerciseList = relatedExerciseLists && !!relatedExerciseLists.length;
    const haveMaterials = relatedMaterials && !!relatedMaterials.length;
    const haveBooklets = relatedBooklets && !!relatedBooklets.length;

    const accordionOptions: AccordionOption[] = [];

    if (haveExerciseList) {
        accordionOptions.push({
            label: {
                icon: <ExerciseListIcon />,
                text: "Listas de Exercícios"
            },
            content: (
                <ContentList>
                    {haveExerciseList &&
                        relatedExerciseLists!.map((exerciseList) => (
                            <ContentListItemButton
                                key={exerciseList.id}
                                onClick={() => onClickExerciseList(exerciseList.id)}
                                title={exerciseList.title}
                            >
                                <AccordionListDecorationWrapper>
                                    <AccordionListDecoration />
                                </AccordionListDecorationWrapper>
                                <p>{exerciseList.title}</p>
                            </ContentListItemButton>
                        ))}
                </ContentList>
            )
        });
    }

    if (haveMaterials) {
        accordionOptions.push({
            label: {
                icon: <MaterialIcon />,
                text: "Materiais complementares"
            },
            content: (
                <ContentList>
                    {haveMaterials &&
                        relatedMaterials!.map((material) => (
                            <ContentListItemButton key={material.id} onClick={() => onClickMaterial(material)} title={material.title}>
                                <AccordionIconWrapper>
                                    <span />
                                </AccordionIconWrapper>
                                <p>{material.title}</p>
                            </ContentListItemButton>
                        ))}
                </ContentList>
            )
        });
    }

    if (haveBooklets) {
        accordionOptions.push({
            label: {
                icon: <DownloadEbookIcon />,
                text: "Apostilas"
            },
            content: (
                <ContentList>
                    {haveBooklets &&
                        relatedBooklets!.map((material) => (
                            <ContentListItemButton key={material.id} onClick={() => onClickMaterial(material)} title={material.title}>
                                <AccordionIconWrapper>
                                    <span />
                                </AccordionIconWrapper>
                                <p>{material.title}</p>
                            </ContentListItemButton>
                        ))}
                </ContentList>
            )
        });
    }

    if (accordionOptions.length <= 0) {
        return null;
    }

    return (
        <WatchScreenRelatedContentContainer>
            <WatchScreenRelatedContentTitle>Conteúdo Relacionado</WatchScreenRelatedContentTitle>
            <Accordion options={accordionOptions} activeItem={0} />
        </WatchScreenRelatedContentContainer>
    );
};

export default WatchScreenRelatedContent;
