import styled from "styled-components";

import { theme } from "config/theme";
import { IProps } from "./index";

export const H1 = styled.h1<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 800 : 400};
        font-size: 35px;
        line-height: 1.51;
    `};
`;

export const H2 = styled.h2<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 700 : 400};
        font-size: 25px;
        line-height: 1.52;
    `};
`;

export const H3 = styled.h3<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 600 : 400};
        letter-spacing: 0.5px;
        font-size: 18px;
        line-height: 1.5;
    `};
`;

export const P1 = styled.p<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 600 : 400};
        font-size: 16px;
        line-height: 1.56;
    `};
`;

export const P2 = styled.p<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 600 : 400};
        font-size: 15px;
        line-height: 1.53;
    `};
`;

export const P3 = styled.p<IProps>`
    ${({ bold, color, gutterBottom, gutterTop }) => `
        margin-bottom: ${gutterBottom ? 20 : 0}px;
        margin-top: ${gutterTop ? 20 : 0}px;
        color: ${color ? color : theme.colors.black};
        font-weight: ${bold ? 500 : 400};
        font-size: 13px;
        line-height: 1.54;
    `};
`;
