// Dependencies
import React, { FunctionComponent, useCallback, useEffect } from "react";

// Types
import { ActivityOverlayProps } from "./activity-overlay.types";

// Styles
import { Container } from "./activity-overlay.styles";

export const ActivityOverlay: FunctionComponent<ActivityOverlayProps> = ({ position = "center", content, handleOutsideClick }) => {
    const scrollYWhenOpeningComponent = window.scrollY;

    const handleScroll = useCallback(() => {
        window.scrollTo({ top: scrollYWhenOpeningComponent });
    }, [scrollYWhenOpeningComponent]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    const handleClick = (event: any) => {
        if (!!handleOutsideClick && (event?.target?.className?.toString().split(" ") ?? []).includes("webapp-overlay")) {
            handleOutsideClick();
        }
    };

    return (
        <Container className="webapp-overlay" position={position} onClick={handleClick}>
            {content}
        </Container>
    );
};
