import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { WebpDropdownFilter } from "webapp-components/dist/libs/webp-components-react";
import { WebpCheckbox } from "components/WebpCheckbox";
import { ChipSelectedPayload, WebpChip } from "components/WebpChip";
import { WebpCard } from "components/WebpCard";
import { QuestionDifficultyLevel } from "../../../../../enums/QuestionDifficultyLevel";
import { QuestionStatusEnum } from "../../../../../enums/QuestionStatusEnum";
import {
    ExerciseListCustomStateKeys,
    IExerciseListCustomState,
    QuestionLevelEnumOptions,
    QuestionStatusEnumOptions
} from "../../../../../interfaces/IExerciseListCustom";
import { Types } from "../../../../../store/ducks/exerciseListCustom";
import { WebpText } from "components/WebpText";
import { handleFilterChangeType } from "../../index";
import {
    CheckboxContainer,
    DropdownFilterContainer,
    ExecptionsContainer,
    FilterChipsContainer,
    FilterFormWrapper,
    FilterTitle,
    FormRowFive,
    FormRowFour,
    FormRowOne,
    FormRowThree,
    FormRowTwo,
    QuestionLevelsContainer,
    TitleWithSwitch
} from "./styles";

interface IFilterFormProps {
    handleFilterChange: handleFilterChangeType;
    exerciseListCustomState: IExerciseListCustomState;
}

function FilterForm({ handleFilterChange, exerciseListCustomState }: IFilterFormProps) {
    const {
        subjects,
        topics,
        subtopics,
        questionType,
        contestsWithAuthoredQuestions,
        isFromContest,
        contest,
        contestYear,
        questionStatus,
        questionLevel,
        isCancelled,
        isFromAnotherList,
        isAuthored
    } = exerciseListCustomState;

    const dispatch = useDispatch();

    const checkIfStateHasOption = useCallback((option, state) => {
        return state.includes(option);
    }, []);

    // The safe way - maybe we need it
    //     if (!state) return false;
    //     return optionsSet.every((option) => checkIfStateHasOption(option, state));
    const checkIfStateHasAllOptions = (state: any[], optionsSet: any[]) => state.length === optionsSet.length;

    const toggleAllOptions = useCallback(
        (state: any[], optionsSet: any[], stateName: string) => {
            dispatch({
                type: Types.SET_TOGGLE_ALL_STATE_REQUEST,
                payload: { currentState: state, stateName: stateName, optionsSet: optionsSet }
            });
        },
        [dispatch]
    );

    const isDisabled = (list: any) => {
        return list?.options?.length > 0 ? false : true;
    };

    return (
        <FilterFormWrapper>
            <WebpCard
                headerComposition={
                    <>
                        <FormRowOne>
                            <FilterTitle>
                                <WebpText xs="title-xss" color="neutral" colorWeight={500}>
                                    <h3>Conteúdo da sua lista:</h3>
                                </WebpText>
                            </FilterTitle>
                            <DropdownFilterContainer>
                                <WebpDropdownFilter
                                    data={subjects}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Matéria"
                                    className="dropdown-filter"
                                    onOptionSelected={(ev: CustomEvent) => handleFilterChange(ExerciseListCustomStateKeys.SUBJECTS, ev, subjects)}
                                ></WebpDropdownFilter>
                                <WebpDropdownFilter
                                    data={topics}
                                    disabled={isDisabled(topics)}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Tópico"
                                    className="dropdown-filter"
                                    onOptionSelected={(ev: CustomEvent) => handleFilterChange(ExerciseListCustomStateKeys.TOPICS, ev, topics)}
                                ></WebpDropdownFilter>
                                <WebpDropdownFilter
                                    data={subtopics}
                                    disabled={isDisabled(subtopics)}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Subtópico"
                                    className="dropdown-filter"
                                    onOptionSelected={(ev: CustomEvent) => handleFilterChange(ExerciseListCustomStateKeys.SUBTOPICS, ev, subtopics)}
                                ></WebpDropdownFilter>
                                <WebpDropdownFilter
                                    data={questionType}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Tipo de questão"
                                    className="dropdown-filter"
                                    onOptionSelected={(ev: CustomEvent) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_TYPE, ev, questionType)
                                    }
                                ></WebpDropdownFilter>
                            </DropdownFilterContainer>
                        </FormRowOne>

                        <FormRowTwo>
                            <FilterTitle>
                                <WebpText xs="title-xss" color="neutral" colorWeight={500}>
                                    <h3>Origem das questões:</h3>
                                </WebpText>
                            </FilterTitle>
                            <DropdownFilterContainer>
                                <WebpCheckbox
                                    label="Questões autorais"
                                    labelOrder="label-after"
                                    className="webpcheckbox"
                                    checked={isAuthored}
                                    layout="checkbox"
                                    onWebpCheckboxChange={(ev: any) => handleFilterChange(ExerciseListCustomStateKeys.IS_AUTHORED, ev, isAuthored)}
                                />
                                <WebpDropdownFilter
                                    data={contestsWithAuthoredQuestions}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Criadas para"
                                    className="dropdown-filter"
                                    disabled={!isAuthored}
                                    onOptionSelected={(ev: CustomEvent) =>
                                        handleFilterChange(
                                            ExerciseListCustomStateKeys.CONTESTS_WITH_AUTHORED_QUESTIONS,
                                            ev,
                                            contestsWithAuthoredQuestions
                                        )
                                    }
                                ></WebpDropdownFilter>
                            </DropdownFilterContainer>
                        </FormRowTwo>

                        <FormRowThree>
                            <DropdownFilterContainer>
                                <WebpCheckbox
                                    label="Questões de concurso"
                                    labelOrder="label-after"
                                    className="webpcheckbox"
                                    checked={isFromContest}
                                    layout="checkbox"
                                    onWebpCheckboxChange={(ev: any) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.IS_FROM_CONTEST, ev, isFromContest)
                                    }
                                />
                                <WebpDropdownFilter
                                    data={contest}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Concurso"
                                    className="dropdown-filter"
                                    disabled={!isFromContest}
                                    onOptionSelected={(ev: CustomEvent) => handleFilterChange(ExerciseListCustomStateKeys.CONTEST, ev, contest)}
                                ></WebpDropdownFilter>
                                <WebpDropdownFilter
                                    data={contestYear}
                                    badgeColor={"primary"}
                                    filterPlaceholder="Ano"
                                    className="dropdown-filter"
                                    disabled={isDisabled(contestYear)}
                                    onOptionSelected={(ev: CustomEvent) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.CONTEST_YEAR, ev, contestYear)
                                    }
                                ></WebpDropdownFilter>
                                {/* 
                        
                        This is field is temporarily hidden by request of the Pedagogical team.
                        
                        <WebpDropdownFilter
                            data={contestPhase}
                            badgeColor={"primary"}
                            filterPlaceholder="Fase"
                            className="dropdown-filter"
                            disabled={isDisabled(contestPhase)}
                            onOptionSelected={(ev: CustomEvent) => handleFilterChange(ExerciseListCustomStateKeys.CONTEST_PHASE, ev, contestPhase)}
                        ></WebpDropdownFilter> 
                        
                        */}
                            </DropdownFilterContainer>
                        </FormRowThree>

                        <FormRowFour>
                            <TitleWithSwitch>
                                <WebpText xs="title-xss" color="neutral" colorWeight={500}>
                                    <h3>Questões que você quer responder:</h3>
                                </WebpText>
                                <WebpCheckbox
                                    label="Marcar todas"
                                    labelOrder="label-before"
                                    layout="switch"
                                    className="webpcheckbox"
                                    checked={checkIfStateHasAllOptions(questionStatus, [...Object.values(QuestionStatusEnumOptions)])}
                                    onWebpCheckboxChange={() =>
                                        toggleAllOptions(
                                            questionStatus,
                                            [...Object.values(QuestionStatusEnumOptions)],
                                            ExerciseListCustomStateKeys.QUESTION_STATUS
                                        )
                                    }
                                />
                            </TitleWithSwitch>
                            <FilterChipsContainer>
                                <WebpChip
                                    label="Não resolvidas"
                                    variant={"primary"}
                                    selected={checkIfStateHasOption(QuestionStatusEnum.NotSolved, questionStatus)}
                                    onChipSelected={(payload: ChipSelectedPayload) => {
                                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_STATUS, payload, questionStatus);
                                    }}
                                ></WebpChip>
                                <WebpChip
                                    label="Resolvidas"
                                    variant={"primary"}
                                    selected={checkIfStateHasOption(QuestionStatusEnum.Solved, questionStatus)}
                                    onChipSelected={(payload: ChipSelectedPayload) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_STATUS, payload, questionStatus)
                                    }
                                ></WebpChip>
                                <WebpChip
                                    label="Acertei"
                                    variant={"primary"}
                                    selected={checkIfStateHasOption(QuestionStatusEnum.Hits, questionStatus)}
                                    onChipSelected={(payload: ChipSelectedPayload) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_STATUS, payload, questionStatus)
                                    }
                                ></WebpChip>
                                <WebpChip
                                    label="Errei"
                                    variant={"primary"}
                                    selected={checkIfStateHasOption(QuestionStatusEnum.NotHits, questionStatus)}
                                    onChipSelected={(payload: ChipSelectedPayload) =>
                                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_STATUS, payload, questionStatus)
                                    }
                                ></WebpChip>
                            </FilterChipsContainer>
                        </FormRowFour>

                        <FormRowFive>
                            <QuestionLevelsContainer>
                                <TitleWithSwitch>
                                    <WebpText xs="title-xss" color="neutral" colorWeight={500}>
                                        <h3>Dificuldade das questões:</h3>
                                    </WebpText>

                                    <WebpCheckbox
                                        label="Marcar todas"
                                        labelOrder="label-before"
                                        layout="switch"
                                        className="webpcheckbox"
                                        checked={checkIfStateHasAllOptions(questionLevel, [...Object.values(QuestionLevelEnumOptions)])}
                                        onWebpCheckboxChange={() =>
                                            toggleAllOptions(
                                                questionLevel,
                                                [...Object.values(QuestionLevelEnumOptions)],
                                                ExerciseListCustomStateKeys.QUESTION_LEVEL
                                            )
                                        }
                                    />
                                </TitleWithSwitch>

                                <FilterChipsContainer>
                                    <WebpChip
                                        label="Fácil"
                                        variant={"primary"}
                                        selected={checkIfStateHasOption(QuestionDifficultyLevel.Easy, questionLevel)}
                                        onChipSelected={(payload: ChipSelectedPayload) =>
                                            handleFilterChange(ExerciseListCustomStateKeys.QUESTION_LEVEL, payload, questionLevel)
                                        }
                                    ></WebpChip>
                                    <WebpChip
                                        label="Intermediário"
                                        variant={"primary"}
                                        selected={checkIfStateHasOption(QuestionDifficultyLevel.Medium, questionLevel)}
                                        onChipSelected={(payload: ChipSelectedPayload) =>
                                            handleFilterChange(ExerciseListCustomStateKeys.QUESTION_LEVEL, payload, questionLevel)
                                        }
                                    ></WebpChip>
                                    <WebpChip
                                        label="Difícil"
                                        variant={"primary"}
                                        selected={checkIfStateHasOption(QuestionDifficultyLevel.Hard, questionLevel)}
                                        onChipSelected={(payload: ChipSelectedPayload) =>
                                            handleFilterChange(ExerciseListCustomStateKeys.QUESTION_LEVEL, payload, questionLevel)
                                        }
                                    ></WebpChip>
                                </FilterChipsContainer>
                            </QuestionLevelsContainer>
                            <ExecptionsContainer>
                                <FilterTitle>
                                    <WebpText xs="title-xss" color="neutral" colorWeight={500}>
                                        <h3>Não incluir questões:</h3>
                                    </WebpText>
                                </FilterTitle>
                                <CheckboxContainer>
                                    <WebpCheckbox
                                        label="Anuladas"
                                        labelOrder="label-after"
                                        layout="checkbox"
                                        className="webpcheckbox"
                                        checked={isCancelled}
                                        onWebpCheckboxChange={(ev: any) =>
                                            handleFilterChange(ExerciseListCustomStateKeys.IS_CANCELLED, ev, isCancelled)
                                        }
                                    />
                                    <WebpCheckbox
                                        label="De outras listas"
                                        labelOrder="label-after"
                                        layout="checkbox"
                                        className="webpcheckbox"
                                        checked={isFromAnotherList}
                                        onWebpCheckboxChange={(ev: any) =>
                                            handleFilterChange(ExerciseListCustomStateKeys.IS_FROM_ANOTHER_LIST, ev, isFromAnotherList)
                                        }
                                    />
                                </CheckboxContainer>
                            </ExecptionsContainer>
                        </FormRowFive>
                    </>
                }
            ></WebpCard>
        </FilterFormWrapper>
    );
}

export default FilterForm;
