import React, { memo, useMemo } from "react";
import Ranking from "../Ranking";
import AnswerCardProgress from "components/AnswerCardProgress";
import { ExamResultNoteCardContainer, ExamResultNoteCardDetails, AnswerCardProgressWrapper } from "./styles";
import ExamResultFeedback from "../Feedback";
import { IExamResultCurrent, IExamResultKnowledgeArea } from "interfaces/IExam";
import { HitPerformance } from "enums/HitPerformance";
import FeedBackList from "../FeedBackList";

interface IProps {
    currentResult?: IExamResultCurrent;
    knowledgeAreas: IExamResultKnowledgeArea[];
}

const ExamResultNoteCard = memo(({ currentResult, knowledgeAreas = [] }: IProps) => {
    const feedback = useMemo(() => {
        switch (currentResult?.hitPerformance) {
            case HitPerformance.GoingWell:
                return <ExamResultFeedback variant="success" legend="Sua nota está acima de 60% dos alunos" title="Parabéns!" />;

            case HitPerformance.CanBeBetter:
                return <ExamResultFeedback variant="warning" legend="O seu próximo simulado será melhor" title="Mantenha o foco!" />;

            case HitPerformance.Normal:
            default:
                return null;
        }
    }, [currentResult]);

    return (
        <ExamResultNoteCardContainer>
            <ExamResultNoteCardDetails>
                {!!currentResult?.ranking && (
                    <Ranking
                        note={currentResult?.points}
                        rankPosition={currentResult?.ranking?.position}
                        totalRankUsers={currentResult?.ranking?.total}
                    />
                )}

                <AnswerCardProgressWrapper>
                    <AnswerCardProgress
                        answersCorrect={currentResult?.totalCorrects}
                        answersWrong={currentResult?.totalWrong}
                        isFinished={true}
                        questionsTotal={currentResult?.totalQuestions}
                        totalAnswered={currentResult?.totalQuestions}
                    />

                    {feedback}
                </AnswerCardProgressWrapper>
            </ExamResultNoteCardDetails>

            <FeedBackList />
        </ExamResultNoteCardContainer>
    );
});

export default ExamResultNoteCard;
