import { createActions, createReducer } from "reduxsauce";
import { IQrCodeActionsCreators, IQrCodeState, QrCodeActionsType } from "store/interfaces/IQrCode";

export const { Creators, Types } = createActions<Record<QrCodeActionsType, QrCodeActionsType>, IQrCodeActionsCreators>({
    getQrCodeRequest: ["payload"],
    getQrCodeSuccess: ["payload"],
    getQrCodeFailure: [],

    getQrCodeExerciseListRequest: ["payload"],
    getQrCodeExerciseListSuccess: ["payload"],
    getQrCodeExerciseListFailure: [],

    clearQrCode: []
});

export const INITIAL_STATE: IQrCodeState = {
    isLoading: false,
    isLoadingExerciseList: false,
    qrCode: {
        id: 0,
        code: "",
        status: "",
        codeImagePath: "",
        codeImageUrl: "",
        playlists: []
    },
    qrCodeExerciseList: {
        id: 0,
        slug: "",
        code: "",
        title: "",
        type: 0,
        isReleased: false,
        durationDefault: null,
        durationInMinutes: null,
        description: null,
        realizationsTotal: 0,
        realizationsPerStudent: 0,
        realizationsAverageHitRate: 0,
        realizationsAveragePoints: 0,
        externalURL: null,
        learningObject: {
            readonly: false,
            id: 0,
            type: "",
            openForum: false,
            text: null,
            depthLevel: 0
        },
        items: [],
        subject: null,
        points: 0,
        questionsTotal: 0,
        realizations: []
    }
};

const getQrCodeRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getQrCodeSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    qrCode: action.payload,
    isLoading: false
});

const getQrCodeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const getQrCodeExerciseListRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingExerciseList: true });
const getQrCodeExerciseListSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    qrCodeExerciseList: action.payload,
    isLoadingExerciseList: false
});

const getQrCodeExerciseListFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingExerciseList: false
});

const clearQrCode = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_QR_CODE_REQUEST]: getQrCodeRequest,
    [Types.GET_QR_CODE_SUCCESS]: getQrCodeSuccess,
    [Types.GET_QR_CODE_FAILURE]: getQrCodeFailure,

    [Types.GET_QR_CODE_EXERCISE_LIST_REQUEST]: getQrCodeExerciseListRequest,
    [Types.GET_QR_CODE_EXERCISE_LIST_SUCCESS]: getQrCodeExerciseListSuccess,
    [Types.GET_QR_CODE_EXERCISE_LIST_FAILURE]: getQrCodeExerciseListFailure,

    [Types.CLEAR_QR_CODE]: clearQrCode
});
