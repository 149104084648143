// Dependencies
import React from "react";

// Styles
import { IconWrapper } from "components/SignScreen/styles";

interface IFilesProps {
    color: string;
}

export const Files = ({ color }: IFilesProps) => (
    <IconWrapper>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4607 2.04649C21.4607 3.6175 21.46 5.15667 21.4615 6.6966C21.4615 6.9362 21.4539 7.17882 21.4865 7.41463C21.6275 8.44504 22.2614 9.00384 23.3055 9.00915C24.8082 9.01749 26.3118 9.01142 27.8146 9.01142C28.0162 9.01142 28.2179 9.01142 28.4659 9.01142C28.4727 9.16003 28.4841 9.29196 28.4848 9.42465C28.4856 13.2642 28.4878 17.1046 28.4848 20.9442C28.4833 23.2196 26.9547 24.7458 24.6763 24.7473C20.7859 24.7496 16.8955 24.7504 13.0052 24.7473C11.1794 24.7458 9.88209 23.8064 9.38698 22.1103C9.29296 21.7888 9.2596 21.4385 9.25884 21.1019C9.25126 15.9483 9.24898 10.7947 9.25581 5.64117C9.25808 3.58945 10.6502 2.0753 12.7042 2.03208C15.5573 1.97143 18.4135 2.0154 21.2682 2.0154C21.3167 2.0154 21.3644 2.03057 21.4607 2.04725V2.04649Z"
                fill="white"
            />
            <path
                d="M4.00519 15.8467C4.00519 13.4841 4.00595 11.1223 4.00519 8.75969C4.00519 8.22894 4.10603 7.74672 4.61858 7.46618C5.54815 6.95742 6.50274 7.50789 6.58917 8.59819C6.59827 8.71117 6.59221 8.82566 6.59221 8.93939C6.59221 13.3984 6.59676 17.8582 6.58993 22.3173C6.5869 24.2636 7.40274 25.7452 9.06246 26.7528C9.86313 27.2388 10.7586 27.3776 11.6806 27.3776C14.9651 27.3776 18.2497 27.3776 21.5343 27.3791C21.6852 27.3791 21.8383 27.3821 21.9862 27.4072C22.8088 27.5467 23.2418 28.0274 23.1986 28.7424C23.1538 29.4862 22.6261 29.9608 21.8034 29.9964C21.7405 29.9995 21.6768 29.9972 21.6139 29.9972C18.2914 29.9972 14.9689 30.0033 11.6464 29.9942C10.3689 29.9904 9.12918 29.775 7.99869 29.1419C5.59819 27.7984 4.16214 25.7838 4.04917 23.008C3.95211 20.6249 4.02869 18.2343 4.02869 15.8467C4.02111 15.8467 4.01277 15.8467 4.00519 15.8467Z"
                fill={color}
            />
            <path d="M23.2539 2.10866C24.95 3.82449 26.631 5.5244 28.2937 7.20612H23.2539V2.10866Z" fill={color} />
        </svg>
    </IconWrapper>
);
