import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import api from "services/api";
import { AxiosResponse } from "axios";
import { ReduxStore } from "store/ducks";

import {
    ICareer,
    ILessonPlanCustom,
    ILessonPlanCustomState,
    ILessonPlanCustomSubmit,
    ILessonPlanWeeksSection,
    IScheduleDays,
    ILessonPlanWeeksSectionWeekList,
    ILessonPlanWeeksSectionWeekListItems,
    ILessonPlanCustomSectionWeekPlanList
} from "../../interfaces/ILessonPlanCustom";

import { Creators as modalActions } from "../../ducks/modal";
import { Creators as alertActions } from "../../ducks/alert";
import { Creators as lessonPlanCustomActions, Types as lessonPlanCustomTypes } from "store/ducks/lessonPlanCustom";

// Lesson Plan Custom
function* getLessonPlanCustom() {
    try {
        const { data }: AxiosResponse<ILessonPlanCustom[]> = yield call(api.get, `/student/lessonplangeneric`);

        yield put(lessonPlanCustomActions.getLessonPlanCustomSuccess(data));
    } catch (error) {
        yield put(lessonPlanCustomActions.getLessonPlanCustomFailure(error?.response.data));
    }
}

function* postLessonPlanCustom(action: { type: string; payload: ILessonPlanCustomSubmit }) {
    try {
        yield call(api.post, `/student/lessonplangeneric`, action.payload);

        yield put(lessonPlanCustomActions.postLessonPlanCustomSuccess());
    } catch (error) {
        const messageError = error?.response?.data?.detail ? error?.response?.data?.detail : "Não foi possível salvar meu plano de estudos.";
        yield put(modalActions.closeModal());
        yield put(alertActions.showAlert(messageError, "danger"));
        yield put(lessonPlanCustomActions.postLessonPlanCustomFailure(error?.response?.data));
    }
}

// Careers
function* getCareers() {
    try {
        const { data: careers }: AxiosResponse<ICareer[]> = yield call(api.get, `/student/career`);

        yield put(lessonPlanCustomActions.getLessonPlanCustomCareersSuccess(careers));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar lista de carreiras.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomCareersFailure(error?.response?.data));
    }
}

function* setCareer(action: { type: string; payload: ICareer }) {
    try {
        const career = action.payload;
        yield put(lessonPlanCustomActions.setLessonPlanCustomCareerSuccess(career));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar carreira.", "danger"));
        yield put(lessonPlanCustomActions.setLessonPlanCustomCareerFailure(error?.response?.data));
    }
}

// Schedule
function* setTotalHoursWeekTime(action: { type: string; payload: { totalHoursWeekTime: number; data: IScheduleDays } }) {
    try {
        const { totalHoursWeekTime, data } = action.payload;

        yield delay(100);

        yield put(lessonPlanCustomActions.setLessonPlanCustomTotalHoursWeekTimeSuccess({ totalHoursWeekTime, data }));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar as horas da semana.", "danger"));
        yield put(lessonPlanCustomActions.setLessonPlanCustomTotalHoursWeekTimeFailure(error?.response?.data));
    }
}

// Weeks
function* getLessonPlanCustomWeeks(action: { type: string; payload: { id: number } }) {
    try {
        const { data }: AxiosResponse<ILessonPlanWeeksSection[]> = yield call(api.get, `/student/lessonplangeneric/${action.payload.id}/section`);
        yield put(lessonPlanCustomActions.getLessonPlanCustomWeeksSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar as semanas.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomWeeksFailure(error?.response?.data));
    }
}

// Section
function* getLessonPlanCustomSection(action: { type: string; payload: { id: number } }) {
    try {
        const { data }: AxiosResponse<ILessonPlanWeeksSection[]> = yield call(
            api.get,
            `/student/lessonplangenericsection/${action.payload.id}/section`
        );

        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar as sessões da semanas.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionFailure(error?.response?.data));
    }
}

// Weeks
function* getLessonPlanCustomSectionWeek(action: { type: string; payload: { id: number } }) {
    try {
        const { data }: AxiosResponse<ILessonPlanWeeksSection[]> = yield call(
            api.get,
            `/student/lessonplangenericsection/${action.payload.id}/section`
        );

        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar os dias da semana.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekFailure(error?.response?.data));
    }
}

// Weeks List
function* getLessonPlanCustomSectionWeekList(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;
        const { data }: AxiosResponse<ILessonPlanWeeksSectionWeekList[]> = yield call(api.get, `/student/lessonplangenericsection/${id}/section`);

        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekListSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar a listagem das semanas.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekListFailure(error?.response?.data));
    }
}

// Weeks List Items
function* getLessonPlanCustomSectionWeekListItems(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;
        const { data }: AxiosResponse<ILessonPlanWeeksSectionWeekListItems> = yield call(api.get, `/student/lessonplangenericsection/${id}/item`);

        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekListItemsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar os itens do modulo.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekListItemsFailure(error?.response?.data));
    }
}

// Weeks Plan List
function* getLessonPlanCustomSectionWeekPlanList(action: { type: string; payload: { id: number } }) {
    try {
        const { id } = action.payload;
        const { data }: AxiosResponse<ILessonPlanCustomSectionWeekPlanList[]> = yield call(api.get, `/student/lessonplangenericsection/plan/${id}`);

        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekPlanListSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível carregar lista de planejamento.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomSectionWeekPlanListFailure(error?.response?.data));
    }
}

// Weeks Plan List
function* getLessonPlanCustomMinimumHoursRecommended() {
    try {
        const { career }: ILessonPlanCustomState = yield select(({ lessonPlanCustom }: ReduxStore) => lessonPlanCustom);

        const url = career ? `/student/lessonplangeneric/career/${career?.id}/weeks` : `/student/lessonplangeneric/weeks`;

        const { data }: AxiosResponse<{ weekNumber: number }> = yield call(api.get, url);

        yield put(lessonPlanCustomActions.getLessonPlanCustomMinimumHoursRecommendedSuccess(data.weekNumber));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter o mínimo de horas recomendadas.", "danger"));
        yield put(lessonPlanCustomActions.getLessonPlanCustomMinimumHoursRecommendedFailure(error?.response?.data));
    }
}

// Lesson Plan Selection
function* getLessonPlanSelection() {
    try {
        const { data }: AxiosResponse<{ selection: number }> = yield call(api.get, `/student/lessonplanselection`);

        yield put(lessonPlanCustomActions.getLessonPlanSelectionSuccess(data));
    } catch (error) {
        yield put(lessonPlanCustomActions.getLessonPlanSelectionSuccess({ selection: 0 }));

        yield put(lessonPlanCustomActions.getLessonPlanSelectionFailure(error?.response?.data));
    }
}

function* postLessonPlanSelection(action: { type: string; payload: { selection: number } }) {
    try {
        const data = action.payload;
        yield call(api.post, `/student/lessonplanselection`, action.payload);

        yield put(lessonPlanCustomActions.postLessonPlanSelectionSuccess(data));
    } catch (error) {
        const messageError = error?.response?.data?.detail ? error.response.data.detail : "Não foi possível salvar plano de estudos sugerido.";
        yield put(alertActions.showAlert(messageError, "danger"));
        yield put(lessonPlanCustomActions.postLessonPlanSelectionFailure(error?.response?.data));
    }
}

// Reset Plan
function* setResetPlan(action: { type: string; payload: { resetPlan: boolean } }) {
    try {
        const { resetPlan } = action.payload;

        yield put(lessonPlanCustomActions.setLessonPlanCustomResetPlanSuccess({ resetPlan }));
    } catch (error) {
        yield put(lessonPlanCustomActions.setLessonPlanCustomResetPlanFailure(error?.response?.data));
    }
}

function* markTextMaterialDone(action: { type: string; payload: { loId: number; hasRequest: boolean } }) {
    try {
        if (action.payload.hasRequest) {
            yield call(api.patch, `/learningobject/${action.payload.loId}/view`, {
                viewed: true
            });
        }

        yield put(lessonPlanCustomActions.markTextMaterialDoneSuccess(action.payload.loId));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível marcar o conteúdo como concluído.", "danger"));
        yield put(lessonPlanCustomActions.markTextMaterialDoneFailure(error?.response?.data));
    }
}

export default [
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_REQUEST, getLessonPlanCustom),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_WEEKS_REQUEST, getLessonPlanCustomWeeks),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_SECTION_REQUEST, getLessonPlanCustomSection),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_REQUEST, getLessonPlanCustomSectionWeek),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_REQUEST, getLessonPlanCustomSectionWeekList),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_PLAN_LIST_REQUEST, getLessonPlanCustomSectionWeekPlanList),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_ITEMS_REQUEST, getLessonPlanCustomSectionWeekListItems),
    takeLatest(lessonPlanCustomTypes.POST_LESSON_PLAN_CUSTOM_REQUEST, postLessonPlanCustom),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_CAREERS_REQUEST, getCareers),
    takeLatest(lessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_CAREER_REQUEST, setCareer),
    takeLatest(lessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_TOTAL_HOURS_WEEK_TIME_REQUEST, setTotalHoursWeekTime),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_CUSTOM_MINIMUM_HOURS_RECOMMENDED_REQUEST, getLessonPlanCustomMinimumHoursRecommended),
    takeLatest(lessonPlanCustomTypes.GET_LESSON_PLAN_SELECTION_REQUEST, getLessonPlanSelection),
    takeLatest(lessonPlanCustomTypes.POST_LESSON_PLAN_SELECTION_REQUEST, postLessonPlanSelection),
    takeLatest(lessonPlanCustomTypes.SET_LESSON_PLAN_CUSTOM_RESET_PLAN_REQUEST, setResetPlan),
    takeLatest(lessonPlanCustomTypes.MARK_TEXT_MATERIAL_DONE_REQUEST, markTextMaterialDone)
];
