interface MenuProps {
    name: string;
    url: string;
}

export function getRedirectPath(menu?: MenuProps[]): string {
    if (!!menu?.length) {
        const [firstMenuItem] = menu;
        return firstMenuItem?.url;
    }

    return "/app";
}
