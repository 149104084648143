import calendar from "./calendar.svg";
import study from "./study.svg";

import { theme } from "config/theme";

const LinkToCall = () => {
    let themeProject = "";

    if (theme.project.brand === "proenem") {
        themeProject = "https://atendimento.proenem.com.br/";
    }

    if (theme.project.brand === "promilitares") {
        themeProject = "https://atendimento.promilitares.com.br/";
    }

    if (theme.project.brand === "promedicina") {
        themeProject = "https://atendimento.promedicina.com.br/";
    }

    return themeProject;
};

export const dataCardSelected = [
    {
        id: 1,
        image: calendar,
        labelTitle: "Plano sugerido",
        slug: "suggested-plan",
        description: "Estude com nosso cronograma sugerido",
        labelButton: "Quero o plano sugerido",
        variant: "secondary",
        buttonKnowMore: "Saiba mais",
        active: false
    },
    {
        id: 2,
        image: study,
        labelTitle: "Plano personalizado",
        slug: "custom-plan",
        description: "Você define seus dias e horários de acordo com suas necessidades",
        labelButton: "Quero o plano personalizado",
        variant: "primary",
        buttonKnowMore: "Saiba mais",
        active: false
    }
];

export const dataKnowMore = [
    {
        id: 1,
        titleSection: "Plano sugerido:",
        variant: "secondary",
        description:
            "Aluno(a), este plano de estudos possui objetivo de auxiliar seus estudos através do cronograma semanal montado por nossa equipe pedagógica.<br/><br/>Os conteúdos são dividos por uma ordenação de conhecimento para que você obtenha êxito na sua aprovação.",
        action: LinkToCall()
    },
    {
        id: 2,
        titleSection: "Plano de estudos personalizado:",
        variant: "primary",
        description:
            "Com este plano de estudos personalizado você poderá escolher os dias da semana e períodos do dia que melhor se enquadram a sua rotina. Ele possui um mínimo de horas e semanas recomendadas para que você obtenha êxito em seus estudos.<br/><br/>O conteúdo será distribuído de forma uniforme ao longo do calendário de estudos que você montou.",
        action: LinkToCall()
    }
];
