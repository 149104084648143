import React, { useEffect, useState, useMemo, useCallback } from "react";
import { DateTime } from "luxon";

import { ExerciseListTimerContainer, ExerciseListTimerIcon } from "./styles";
import api from "services/api";

export interface IProps {
    startDate: string;
    handleForceFinish?(): void;
    endDate?: string;
    countdown?: boolean;
    progressiveCount?: boolean;
    durationInMinutes?: number;
}

const ExerciseListTimer = ({ startDate, endDate, countdown = false, progressiveCount = false, durationInMinutes, handleForceFinish }: IProps) => {
    const [time, setTime] = useState(0);
    const [apiDate, setApiDate] = useState<DateTime>();

    const [finished, setFinished] = useState(false);

    const requestApiDate = useCallback(async () => {
        try {
            const { data } = await api.get("/util/unixtime");

            if (!data) {
                throw new Error();
            }

            const formatDate = DateTime.fromMillis(data);

            if (!formatDate || !formatDate.isValid) {
                throw new Error();
            }

            setApiDate(formatDate);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        requestApiDate();
    }, [requestApiDate]);

    const getInterval = useCallback(() => {
        try {
            // eslint-disable-next-line
            let interval: any;

            if ((!progressiveCount && !countdown) || finished) {
                clearInterval(interval);

                throw new Error("not count component");
            }

            interval = setInterval(() => setTime((old) => old + 1), 1000);

            return () => {
                clearInterval(interval);
            };
        } catch (error) {
            return;
        }
    }, [countdown, finished, progressiveCount]);

    useEffect(() => getInterval(), [getInterval]);

    const getCurrentTime = useMemo(() => {
        try {
            if (!startDate || !apiDate) {
                throw new Error();
            }

            if (!!endDate) {
                return DateTime.fromISO(endDate)
                    .diff(DateTime.fromISO(startDate))
                    .toFormat("hh:mm:ss");
            }

            if (countdown && durationInMinutes) {
                const limitTime = DateTime.fromISO(startDate).plus({ minutes: durationInMinutes });

                const remaining = limitTime.diff(apiDate.plus({ seconds: time }));

                if (remaining.milliseconds <= 0 && !!handleForceFinish) {
                    handleForceFinish();
                    setFinished(true);

                    return null;
                }

                return remaining.toFormat("hh:mm:ss");
            }

            return apiDate
                .plus({ seconds: time })
                .diff(DateTime.fromISO(startDate))
                .toFormat("hh:mm:ss");
        } catch (error) {
            return null;
        }
    }, [apiDate, countdown, durationInMinutes, endDate, handleForceFinish, startDate, time]);

    const memoIcon = useMemo(() => <ExerciseListTimerIcon />, []);

    if (finished) {
        return null;
    }

    return (
        <ExerciseListTimerContainer data-test-id="exercise-list-time">
            {memoIcon}
            {getCurrentTime}
        </ExerciseListTimerContainer>
    );
};
export default React.memo(ExerciseListTimer);
