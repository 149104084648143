import React, { FunctionComponent } from "react";

// Styles
import { Container, Content, DescriptionLabelWrapper, DescriptionWrapper, Label } from "./activity-result-performance-card.styles";

// Types
import { ActivityResultPerformanceCardProps } from "./activity-result-performance-card.types";

// Components
import { ActivityTitle } from "components/ActivityTitle";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityResultPerformanceCard: FunctionComponent<ActivityResultPerformanceCardProps> = ({
    title,
    titleIcon,
    executionTimeLabel,
    correctAnswerLabel,
    wrongAnswerLabel,
    chartElement,
    executionTimeElement,
    correctAnswerElement,
    wrongAnswerElement
}) => {
    return (
        <Container>
            <ActivityTitle title={title} icon={titleIcon} />

            <Content>
                <ConditionallyRender shouldRender={!!chartElement} content={chartElement} />

                <DescriptionWrapper>
                    <ConditionallyRender
                        shouldRender={!!executionTimeLabel && !!executionTimeElement}
                        content={
                            <DescriptionLabelWrapper>
                                <Label>{executionTimeLabel}</Label>

                                {executionTimeElement}
                            </DescriptionLabelWrapper>
                        }
                    />

                    <ConditionallyRender
                        shouldRender={!!correctAnswerLabel && !!correctAnswerElement}
                        content={
                            <DescriptionLabelWrapper>
                                <Label>{correctAnswerLabel}</Label>

                                {correctAnswerElement}
                            </DescriptionLabelWrapper>
                        }
                    />

                    <ConditionallyRender
                        shouldRender={!!wrongAnswerLabel && !!wrongAnswerElement}
                        content={
                            <DescriptionLabelWrapper>
                                <Label>{wrongAnswerLabel}</Label>

                                {wrongAnswerElement}
                            </DescriptionLabelWrapper>
                        }
                    />
                </DescriptionWrapper>
            </Content>
        </Container>
    );
};
