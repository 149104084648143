import styled from "styled-components";
import { theme } from "config/theme";
import { Micro, TitleH2 } from "assets/styles/global";

export const ExamResultRankingContainer = styled.div`
    display: flex;
    width: 100%;
    background: ${theme.colors.brand[300]};
    padding: 24px 24px 16px;
    border-radius: 4px 4px 0px 0px;

    > svg {
        margin-right: ${theme.spacing.small};
        margin-top: 2px;
    }
`;

export const ExamResultRankingPosition = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const ExamResultRankingNote = styled.div`
    display: flex;
    flex-direction: column;

    > * {
        text-align: right;
    }
`;

export const ExamResultRankingGreyText = styled.p`
    color: ${theme.colors.white};

    ${Micro};
`;

export const ExamResultRankingDarkText = styled.p`
    color: ${theme.colors.white};

    ${TitleH2};
`;
