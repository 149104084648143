import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { hexToRgba } from "helpers";
import { MediaQueries } from "assets/styles/settings";

export const FilterButtonLegend = styled.p`
    padding-right: ${theme.spacing.smallX};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.colors.base[400]};
    }
`;

export const FilterButton = styled.button<{ isList?: boolean }>`
    border: 0;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.base[400]};

    span {
        margin-right: ${theme.spacing.smallX};
    }

    .filterIcon {
        margin-right: ${theme.spacing.smallX};
    }

    svg {
        transition: transform 0.2s ease;
    }

    ${({ isList }) =>
        isList &&
        css`
            padding: ${theme.spacing.small} 0;
            width: 100%;

            ${MediaQueries.BIGGER_THAN_SMALL} {
                padding: ${theme.spacing.smallX} 0;
            }

            span {
                flex: 1;
                text-align: left;
            }

            :first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            :last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            :not(:last-child) {
                border-bottom: 1px solid ${theme.colors.base[100]};
            }
        `};
`;

export const FilterOldModal = styled.div`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 16px 16px 0px 0px;
    background: ${theme.colors.base[100]};
    padding: ${theme.spacing.small};
    max-height: 60vh;
    padding-bottom: 0;
`;

export const FilterModalList = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    ${FilterButton} {
        &:last-child {
            margin-bottom: ${theme.spacing.medium};
        }
    }
`;

export const FilterModalTitle = styled.strong`
    color: ${theme.colors.base[500]};
    font-size: ${theme.typography.sizes.mediumX};
`;

export const FilterDarkBackground = styled.div`
    background: ${hexToRgba(theme.colors.base[500], 0.32)};
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000000;
`;

export const FilterGroup = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
`;

export const FilterDropDown = styled.div`
    position: relative;

    ${FilterButton} {
        width: 100%;
    }
`;

export const FilterDropDownList = styled.div`
    background: ${theme.colors.white};
    position: absolute;
    right: 0;
    width: 214px;
    box-shadow: ${theme.shadow[2]};
    margin-top: ${theme.spacing.smallX};
    border-radius: 4px;
    z-index: 99999;
    max-height: 50vh;
    overflow-y: auto;

    ${FilterButton} {
        padding: ${theme.spacing.small} ${theme.spacing.smallX};

        :hover {
            background: ${theme.colors.base[100]};
        }
    }
`;
