import { theme } from "config/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { ForumQuestionContentText, ForumQuestionContentWrapper } from "screens/Forum/components/question-content/styles";

export const ForumQuestionContainer = styled(Link)`
    display: block;
    position: relative;

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
        padding-bottom: ${theme.spacing.medium};
        margin-bottom: ${theme.spacing.medium};
    }

    ${ForumQuestionContentWrapper} {
        width: calc(100% - ${theme.spacing.small});
    }

    ${ForumQuestionContentText} {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
