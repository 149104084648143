import React, { ButtonHTMLAttributes } from "react";
import { ExerciseListLetterContainer } from "./styles";
import { IAlternativeStatus } from "screens/ExerciseList/interfaces/IAlternativeStatus";

// import { Container } from './styles';

interface IExerciseListLetterProps extends ButtonHTMLAttributes<any>, IAlternativeStatus {
    letter: string;
    onClick?(): void;
}

const ExerciseListLetter = ({
    letter,
    onClick,
    isCorrect = false,
    isWrong = false,
    isMarked = false,
    isCorrectNotMarked = false,
    ...rest
}: IExerciseListLetterProps) => {
    return (
        <ExerciseListLetterContainer
            onClick={onClick}
            isCorrect={isCorrect}
            isWrong={isWrong}
            isMarked={isMarked}
            isCorrectNotMarked={isCorrectNotMarked}
            type="button"
            currentCursor={!!onClick}
            data-test-id="exercise-list-alternative-button"
            {...rest}
        >
            {letter}
        </ExerciseListLetterContainer>
    );
};

export default React.memo(ExerciseListLetter);
