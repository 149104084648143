import { Action, ActionCreatorsMapObject } from "redux";

export enum IGetTopicStatus {
    Inactive = 0,
    Active = 1,
    OnlyReading = 2
}

export interface IPost {
    content: string;
    createdAt: string;
    updatedAt?: string;
    id: string;
    posts: IPost[];
    user: IUser;
    attachment?: string;
}

export interface IUser {
    avatar?: string;
    id: string;
    name?: string;
    userId: number;
    type?: number;
}

export interface IPagination {
    hasMoreItems: boolean;
    bookmark?: string;
}

export interface IGetTopicResponse {
    id: string;
    slug: string;
    reference: string;
    posts: IPost[];
    status: IGetTopicStatus;
    createdAt: string;
    title: string;
}

export interface IGetPostsResponse {
    data: IPost[];
    pagination: IPagination;
}

export interface IPostResponse {
    content: string;
    createdAt: string;
    updatedAt?: string;
    id: string;
    posts: IPost[];
    user: IUser;
    attachment?: string;
}

export interface IGetUpdatedPostsResponse {
    idPost: string;
    lastestUpdate: string;
    hasChanges: boolean;
}

// ----------

export interface IDoubtPostStatePostsFilters {
    quantity: number;
    justmine: boolean;
    bookmark?: string;
}

export interface IDoubtPostStatePostsFormattedItemsToCheckUpdates {
    IdPost: string;
    LastestUpdate: string;
}

export interface IDoubtPostStatePosts {
    isLoading: boolean;
    hasMore: boolean;
    filters: IDoubtPostStatePostsFilters;
    items: IPost[];
    formattedItemsToCheckUpdates: IDoubtPostStatePostsFormattedItemsToCheckUpdates[];
    deletedItemsCount: number;
}

export interface IDoubtPostStateTopic {
    isLoading: boolean;
    data: IGetTopicResponse;
}

export interface IDoubtPostStateSpecificPost {
    isLoading: boolean;
    hasError: boolean;
    data?: IPost;
}

export interface IDoubtPostState {
    topic: IDoubtPostStateTopic;
    posts: IDoubtPostStatePosts;
    specificPost: IDoubtPostStateSpecificPost;
    newPostsQuantity: number;
    postsToUpdate: string[];
}

// ----------

export interface IGetTopicPayload {
    learningObjectId: number;
}

export interface IGetPostsPayload {
    learningObjectId: number;
    quantity: number;
    justmine: boolean;
    bookmark?: string;
    isRestarting?: boolean;
}

export interface IGetHasNewPostsPayload {
    learningObjectId: number;
    quantity: number;
}

export interface IGetHasUpdatedPostsPayload {
    learningObjectId: number;
    posts: IDoubtPostStatePostsFormattedItemsToCheckUpdates[];
}

export interface IGetUpdatedPostsPayload {
    posts: string[];
}

export interface IGetSpecificPostPayload {
    specificPostId: string;
}

export interface ISendPostPayload {
    postMessage: string;
    topicReference: string;
}

export interface IEditPostPayload {
    postMessage: string;
    postId: string;
}

export interface IDeletePostPayload {
    postId: string;
}

// ----------

export interface IResponsePayload<Response, Variables> {
    response: Response;
    variables: Variables;
}

export interface IGetPostsResponseVariables {
    justmine: boolean;
    isRestarting?: boolean;
    serverDate: number;
}

export interface IGetUpdatedPostsResponseVariables {
    serverDate: number;
}

// ----------

export interface IDoubtPostActions {
    getTopicRequest(payload: IGetTopicPayload): Action<void>;
    getTopicSuccess(payload: IResponsePayload<IGetTopicResponse, {}>): Action<void>;
    getTopicFailure(payload: any): Action<void>;

    getPostsRequest(payload: IGetPostsPayload): Action<void>;
    getPostsSuccess(payload: IResponsePayload<IGetPostsResponse, IGetPostsResponseVariables>): Action<void>;
    getPostsFailure(payload: any): Action<void>;

    getHasNewPostsRequest(payload: IGetHasNewPostsPayload): Action<void>;
    getHasNewPostsSuccess(payload: IResponsePayload<IGetPostsResponse, {}>): Action<void>;
    getHasNewPostsFailure(payload: any): Action<void>;

    getHasUpdatedPostsRequest(payload: IGetHasUpdatedPostsPayload): Action<void>;
    getHasUpdatedPostsSuccess(payload: IResponsePayload<IGetUpdatedPostsResponse[], {}>): Action<void>;
    getHasUpdatedPostsFailure(payload: any): Action<void>;

    getUpdatedPostsRequest(payload: IGetUpdatedPostsPayload): Action<void>;
    getUpdatedPostsSuccess(payload: IResponsePayload<IPostResponse[], IGetUpdatedPostsResponseVariables>): Action<void>;
    getUpdatedPostsFailure(payload: any): Action<void>;

    getSpecificPostRequest(payload: IGetSpecificPostPayload): Action<void>;
    getSpecificPostSuccess(payload: IResponsePayload<IPostResponse, {}>): Action<void>;
    getSpecificPostFailure(payload: any): Action<void>;

    sendPostRequest(payload: ISendPostPayload): Action<void>;
    sendPostSuccess(payload: IResponsePayload<IPostResponse, {}>): Action<void>;
    sendPostFailure(payload: any): Action<void>;

    editPostRequest(payload: IEditPostPayload): Action<void>;
    editPostSuccess(payload: IResponsePayload<{}, IEditPostPayload>): Action<void>;
    editPostFailure(payload: any): Action<void>;

    deletePostRequest(payload: IDeletePostPayload): Action<void>;
    deletePostSuccess(payload: IResponsePayload<{}, IDeletePostPayload>): Action<void>;
    deletePostFailure(payload: any): Action<void>;

    clearStateRequest(): Action<void>;
}

export type DoubtPostActionsType =
    | "GET_TOPIC_REQUEST"
    | "GET_TOPIC_SUCCESS"
    | "GET_TOPIC_FAILURE"
    | "GET_POSTS_REQUEST"
    | "GET_POSTS_SUCCESS"
    | "GET_POSTS_FAILURE"
    | "GET_HAS_NEW_POSTS_REQUEST"
    | "GET_HAS_NEW_POSTS_SUCCESS"
    | "GET_HAS_NEW_POSTS_FAILURE"
    | "GET_HAS_UPDATED_POSTS_REQUEST"
    | "GET_HAS_UPDATED_POSTS_SUCCESS"
    | "GET_HAS_UPDATED_POSTS_FAILURE"
    | "GET_UPDATED_POSTS_REQUEST"
    | "GET_UPDATED_POSTS_SUCCESS"
    | "GET_UPDATED_POSTS_FAILURE"
    | "GET_SPECIFIC_POST_REQUEST"
    | "GET_SPECIFIC_POST_SUCCESS"
    | "GET_SPECIFIC_POST_FAILURE"
    | "SEND_POST_REQUEST"
    | "SEND_POST_SUCCESS"
    | "SEND_POST_FAILURE"
    | "EDIT_POST_REQUEST"
    | "EDIT_POST_SUCCESS"
    | "EDIT_POST_FAILURE"
    | "DELETE_POST_REQUEST"
    | "DELETE_POST_SUCCESS"
    | "DELETE_POST_FAILURE"
    | "CLEAR_STATE_REQUEST";

export interface IDoubtPostActionsCreators extends IDoubtPostActions, ActionCreatorsMapObject {}
