import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const WatchScreenTeachersContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const WatchScreenTeachersTitle = styled.strong`
    display: block;
    margin: ${theme.spacing.medium} 0 ${theme.spacing.small} 0;

    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.sizes.mediumX};
    color: ${theme.colors.base[500]};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-top: 0;
        padding: 0;
    }
`;

export const WatchScreenTeachersItem = styled.div`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.smallXX} 0;

    p {
        font-size: ${theme.typography.sizes.smallX};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.colors.base[400]};
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.smallXX} 0;
    }
`;
