import { call, put, takeLatest, select } from "redux-saga/effects";
import history from "services/history";
import api from "services/api";

import { ReduxAction } from "store";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as qrCodeActions, Types } from "store/ducks/qrCode";
import { IReduxStore } from "interfaces/IReduxStore";

export function* getQrCode(action: ReduxAction<{ slug: string }>) {
    try {
        const { slug } = action.payload;

        const { data } = yield call(api.get, `/student/qrcode-v2/${slug}`);

        yield put(qrCodeActions.getQrCodeSuccess(data));

        if (data) {
            const courseSlug = yield select((state: IReduxStore) => state.course.slug);
            yield call(history.push, `/app/curso/${courseSlug}/qr-code/${slug}`);
        }
    } catch (error) {
        yield put(qrCodeActions.getQrCodeFailure());
        yield put(alertActions.showAlert("Por favor verifique o QRCode e tente novamente.", "danger"));
    }
}

export function* getQrCodeExerciseList(action: ReduxAction<{ exerciseListId: string }>) {
    try {
        const { exerciseListId } = action.payload;

        const { data } = yield call(api.get, `/exerciselist/qrcode/${exerciseListId}`, {
            headers: {
                "X-Platform": "mobile"
            }
        });

        yield put(qrCodeActions.getQrCodeExerciseListSuccess(data));
    } catch (error) {
        yield put(qrCodeActions.getQrCodeFailure());
        yield put(alertActions.showAlert("Não foi possível carregar lista de exercícios. Tente novamente.", "danger"));
    }
}

export default [takeLatest(Types.GET_QR_CODE_REQUEST, getQrCode), takeLatest(Types.GET_QR_CODE_EXERCISE_LIST_REQUEST, getQrCodeExerciseList)];
