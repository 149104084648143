import PageHeaderSimple from "./simple";
import PageHeaderComplex from "./complex";

type Page = {
    url: string;
    label: string;
};

export interface IPageHeaderProps {
    breadcrumb: {
        parent: Page[];
        current: Pick<Page, "label">;
    };
    right?: React.ReactNode;
}

export default {
    Simple: PageHeaderSimple,
    Complex: PageHeaderComplex
};
