import { BodyText2, Micro, TitleH4 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { BadgeContainer } from "components/Badge/styles";
import { ButtonWrapper } from "components/Button/styles";
import { ProgressBarContainer, ProgressBarTabletWrapper } from "components/ProgressBar/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const Card = styled.div`
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-right: 15px;
`;

export const ProjectCardContainer = styled.div`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: ${theme.spacing.smallXX};
    cursor: pointer;
    width: 100%;
    height: 100%;
`;

interface IProjectCoverProps {
    image: string;
    width: number;
}

export const ProjectCover = styled.div<IProjectCoverProps>`
    background: ${({ image }) => `url(${image}) center no-repeat`};
    background-size: cover;
    height: ${({ width }) => `calc((${width}px / 16) * 9)`};
    width: 100%;
`;

export const ProjectCardThumb = styled.div<IProjectCoverProps>`
    background: ${({ image }) => `url(${image}) center no-repeat`};
    background-size: cover;
    height: ${({ width }) => `calc((${width}px / 16) * 9)`};
    width: 100%;
`;

export const ProjectTitleSmartphone = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ProjectInformations = styled.div`
    padding: 10px;
`;

interface IProjectCardContentProps {
    width: number;
}

export const ProjectCardContent = styled.div<IProjectCardContentProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${({ width }) => `calc(100% - ((${width}px / 16) * 9))`};
    padding: 16px;

    ${ProgressBarContainer} {
        margin-top: ${theme.spacing.smallXX};

        strong {
            color: ${theme.colors.base[500]};
            display: block;
            font-weight: bold;
            font-size: ${theme.typography.sizes.small};
            line-height: 1;
            text-align: right;
            width: 100%;
        }
    }

    ${ProgressBarTabletWrapper} {
        background: ${theme.colors.base[100]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${ProgressBarContainer} {
            margin-top: 2px;
        }
    }
`;

export const ProjectCardThumbWrapper = styled.div`
    position: relative;

    ${BadgeContainer} {
        position: absolute;
        right: ${theme.spacing.smallX};
        top: ${theme.spacing.smallX};
    }
`;

export const ProjectName = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[500]};
    margin-bottom: 4px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
        line-height: 22px;
    }
`;

export const ProjectMainTag = styled.p`
    ${Micro};
    color: ${theme.colors.base[400]};
    line-height: 1;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
    }
`;

export const ProjectActions = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;

    ${ButtonWrapper} {
        flex: 1;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        padding: 5px 12px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.small};

        ${ButtonWrapper} {
            flex: 1;
            font-size: ${theme.typography.sizes.medium};
            font-weight: bold;
            line-height: 1;
            padding: 8px 25px;
        }
    }
`;

export const ProjectPrice = styled.p`
    ${TitleH4};
    color: ${theme.colors.system.success[300]};
    flex: 2;
    padding-right: 5px;

    span {
        font-size: 14px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.large};

        span {
            font-size: ${theme.typography.sizes.mediumX};
        }
    }
`;
