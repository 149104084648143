// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Assets
import chevronRight from "assets/img/chevron-right.svg";
import chevronLeft from "assets/img/chevron-left.svg";
import trophyIllustration from "assets/img/performance-trophy.png";
import trophy from "assets/img/performance-trophy.svg";
import sadFace from "assets/img/performance-sad-face.svg";
import schedule from "assets/img/performance-schedule.svg";
import order from "assets/img/performance-order.svg";
import chevronUp from "assets/img/chevron-up.svg";
import chevronDown from "assets/img/chevron-down.svg";
import arrowRight from "assets/img/arrow-right.svg";

// Components
import { Asset } from "component-library/utilities/asset";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { BottomSheet } from "component-library/elements/bottom-sheet";
import { OptionItem } from "component-library/elements/option-item";
import { Back } from "component-library/elements/back";
import { Button } from "component-library/elements/button";
import { Radial } from "component-library/elements/radial";
import { IconBadge } from "component-library/elements/icon-badge";
import { PerformanceBestEssay, PerformanceBestEssaySkeleton } from "component-library/compositions/performance-best-essay";
import {
    PerformanceEssaySubmissionCard,
    PerformanceEssaySubmissionCardSkeleton
} from "component-library/compositions/performance-essay-submission-card";
import { PerformanceComment, PerformanceCommentSkeleton } from "component-library/compositions/performance-comment";
import { Ordenator } from "component-library/compositions/ordenator";
import { OptionList } from "component-library/compositions/option-list";
import { PerformanceGraphic, PerformanceGraphicSkeleton } from "component-library/compositions/performance-graphic";
import { PerformanceBanner } from "component-library/sections/performance-banner";
import { PerformanceEssayEvolution } from "component-library/sections/performance-essay-evolution";
import { PerformanceEssaySubmissions } from "component-library/sections/performance-essay-submissions";
import { PerformanceEssayAvarageNote } from "component-library/sections/performance-essay-avarage-note";
import { PerformanceEssayComments } from "component-library/sections/performance-essay-comments";
import { PerformanceEssayWorkspace } from "component-library/workspaces/performance-essay";

const orderOptions = [
    {
        message: "Data de envio",
        slug: "date"
    },
    {
        message: "Maiores notas",
        slug: "highest-grades"
    },
    {
        message: "Menores notas",
        slug: "lowest-grades"
    }
];

const submissionEssayCardSkills = [
    {
        grade: 0,
        name: "Competência 1",
        progress: {
            color: "#663A21",
            percentage: 90
        }
    },
    {
        grade: 0,
        name: "Competência 2",
        progress: {
            color: "#995A33",
            percentage: 60
        }
    },
    {
        grade: 0,
        name: "Competência 3",
        progress: {
            color: "#CC7C46",
            percentage: 40
        }
    },
    {
        grade: 0,
        name: "Competência 4",
        progress: {
            color: "#FC9F5B",
            percentage: 70
        }
    },
    {
        grade: 0,
        name: "Competência 5",
        progress: {
            color: "#FCB883",
            percentage: 50
        }
    }
];

export const PerfomanceEssayScreen: FunctionComponent = () => {
    const [isOrdenatorOpened, setIsOrdenatorOpened] = useState(false);
    const [selectedOrdenatorOptionSlug, setSelectedOrdenatorOptionSlug] = useState("date");
    const [openedCommentPosition, setOpenedCommentPosition] = useState<number>(0);
    const [selectedPeriodSlug, setSelectedPeriodSlug] = useState<string>();

    const handleBack = () => {
        console.log("navega para a tela anterior");
    };

    const handleLoadMoreSumissions = () => {
        console.log("carrega mais submissões");
    };

    const handleChangeOrdenatorSelectVisibility = () => {
        setIsOrdenatorOpened((previousState) => !previousState);
    };

    const handleSelectOrdenatorOptionSlug = (optionSlug: string) => {
        console.log(`exibir dados ordenado por: ${optionSlug}`);

        setSelectedOrdenatorOptionSlug(optionSlug);
    };

    const handleNavigateToEssayDetails = () => {
        console.log("navega para a tela de detalhes de uma redação");
    };

    const handleSelectPeriod = (periodSlug: string) => {
        setSelectedPeriodSlug(selectedPeriodSlug !== periodSlug ? periodSlug : undefined);
    };

    const ordernatorOptionsItems = orderOptions.map((item) => (
        <OptionItem
            key={item.slug}
            message={item.message}
            active={item.slug === selectedOrdenatorOptionSlug}
            handleClick={() => {
                handleSelectOrdenatorOptionSlug(item.slug);
                handleChangeOrdenatorSelectVisibility();
            }}
        />
    ));

    const handleOpenComments = (isOpen: boolean, position: number) => {
        setOpenedCommentPosition(isOpen ? position : 0);
    };

    // ----------

    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsSkeletonVisible(false);
        }, 5000);
    }, []);

    // ----------

    return (
        <PerformanceEssayWorkspace
            backElement={<Back label="Voltar" iconElement={<Asset source={chevronRight} color="#7C8195" size={20} />} handleClick={handleBack} />}
            bannerSection={
                <PerformanceBanner
                    title="Meu desempenho em redação"
                    subtitle="Acompanhe sua evolução em redações já corrigidas, vendo também a nota que atingiu em cada uma das cinco competências e os principais comentários já deixados por nossos professores."
                    offensiveComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={<PerformanceBestEssay note={{ value: 0, date: "00/00/0000" }} icon={trophyIllustration} />}
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceBestEssaySkeleton />} />
                        </>
                    }
                />
            }
            evolutionSection={
                <PerformanceEssayEvolution
                    sectionTitle="Evolução ao longo do tempo:"
                    graphicComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <PerformanceGraphic
                                        average={600}
                                        maxPeriodResult={1000}
                                        mobileDisplayedColumnsCount={5}
                                        columns={[
                                            {
                                                period: {
                                                    slug: "first",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 460
                                            },
                                            {
                                                period: {
                                                    slug: "second",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 660
                                            },
                                            {
                                                period: {
                                                    slug: "third",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 340
                                            },
                                            {
                                                period: {
                                                    slug: "fourth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 800
                                            },
                                            {
                                                period: {
                                                    slug: "fifth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 560
                                            },
                                            {
                                                period: {
                                                    slug: "sixth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 790
                                            },
                                            {
                                                period: {
                                                    slug: "seventh",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 420
                                            },
                                            {
                                                period: {
                                                    slug: "eighth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 620
                                            },
                                            {
                                                period: {
                                                    slug: "ninth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 810
                                            },
                                            {
                                                period: {
                                                    slug: "tenth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00"
                                                },
                                                result: 900
                                            }
                                        ]}
                                        subtitles={[
                                            { color: "#9C9FB0", label: "Redações enviadas" },
                                            ...(selectedPeriodSlug !== undefined ? [{ color: "#794A7D", label: "Redação selecionada" }] : []),
                                            { color: "#28B583", label: "Nota média" }
                                        ]}
                                        hasPeriodSelection
                                        selectedPeriodSlug={selectedPeriodSlug}
                                        handleSelectPeriod={handleSelectPeriod}
                                        previousColumnsIcon={chevronRight}
                                        nextColumnsIcon={chevronLeft}
                                    />
                                }
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceGraphicSkeleton />} />
                        </>
                    }
                />
            }
            averageNoteSection={
                <PerformanceEssayAvarageNote
                    sectionTitle="Nota média atingida em cada competência"
                    graphicComposition={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <PerformanceGraphic
                                        maxPeriodResult={1000}
                                        columns={[
                                            {
                                                period: {
                                                    slug: "first",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00",
                                                    color: "#663A21"
                                                },
                                                result: 430
                                            },
                                            {
                                                period: {
                                                    slug: "second",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00",
                                                    color: "#995A33"
                                                },
                                                result: 650
                                            },
                                            {
                                                period: {
                                                    slug: "third",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00",
                                                    color: "#CC7C46"
                                                },
                                                result: 300
                                            },
                                            {
                                                period: {
                                                    slug: "fourth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00",
                                                    color: "#FC9F5B"
                                                },
                                                result: 790
                                            },
                                            {
                                                period: {
                                                    slug: "fifth",
                                                    value: "00/00/00",
                                                    mobileValue: "00/00",
                                                    color: "#FCB883"
                                                },
                                                result: 580
                                            }
                                        ]}
                                        subtitles={[
                                            { color: "#663A21", label: "Competência 1" },
                                            { color: "#995A33", label: "Competência 2" },
                                            { color: "#CC7C46", label: "Competência 3" },
                                            { color: "#FC9F5B", label: "Competência 4" },
                                            { color: "#FCB883", label: "Competência 5" }
                                        ]}
                                        hasPeriodSelection={false}
                                        previousColumnsIcon={chevronRight}
                                        nextColumnsIcon={chevronLeft}
                                    />
                                }
                            />
                            <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceGraphicSkeleton />} />
                        </>
                    }
                />
            }
            commentsSection={
                <PerformanceEssayComments
                    sectionTitle="Comentários mais recebidos:"
                    sectionDescription="Lista dos 10 principais"
                    commentsCompositions={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <>
                                        <PerformanceComment
                                            position={1}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 1}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 1)}
                                        />
                                        <PerformanceComment
                                            position={2}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 2}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 2)}
                                        />
                                        <PerformanceComment
                                            position={3}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 3}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 3)}
                                        />
                                        <PerformanceComment
                                            position={4}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 4}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 4)}
                                        />
                                        <PerformanceComment
                                            position={5}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 5}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 5)}
                                        />
                                        <PerformanceComment
                                            position={6}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 6}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 6)}
                                        />
                                        <PerformanceComment
                                            position={7}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 7}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 7)}
                                        />
                                        <PerformanceComment
                                            position={8}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 8}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 8)}
                                        />
                                        <PerformanceComment
                                            position={9}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 9}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 9)}
                                        />
                                        <PerformanceComment
                                            position={10}
                                            skillIndicatorColor="#995A33"
                                            skill="Competência 1"
                                            iconCommentClosed={chevronDown}
                                            iconCommentOpened={chevronUp}
                                            title="Título do comentário em até três linhas no mobile"
                                            comment="Prezado(a) aluno(a), gostaria de parabenizá-lo pela excelente nota que obteve na redação do Enem, com uma pontuação total de 800 pontos. É um  resultado surpreendente então se o meu comentário for muito maior é só expandir o box."
                                            isOpened={openedCommentPosition === 10}
                                            handleOpen={(isOpen) => handleOpenComments(isOpen, 10)}
                                        />
                                    </>
                                }
                            />
                            <ConditionallyRender
                                shouldRender={isSkeletonVisible}
                                content={Array.from(Array(10)).map((_, index) => (
                                    <PerformanceCommentSkeleton key={`performance-comment-skeleton-${index}`} />
                                ))}
                            />
                        </>
                    }
                />
            }
            submissionsSection={
                <PerformanceEssaySubmissions
                    sectionTitle="Envios:"
                    correctedSubmissions={6}
                    ordenatorComposition={
                        <Ordenator
                            ordenatorIcon={order}
                            iconOrdenatorClosed={chevronUp}
                            iconOrdenatorOpen={chevronDown}
                            isOrdenatorOpened={isOrdenatorOpened}
                            label="Ordenar"
                            handleClick={handleChangeOrdenatorSelectVisibility}
                            ordenatorOptionsComposition={
                                <>
                                    <ScreenWidthRender
                                        renderingWidth={768}
                                        actionAfterRenderingWidth="hide"
                                        content={
                                            <BottomSheet content={ordernatorOptionsItems} handleOtherClick={handleChangeOrdenatorSelectVisibility} />
                                        }
                                    />

                                    <ScreenWidthRender
                                        renderingWidth={768}
                                        actionAfterRenderingWidth="show"
                                        content={<OptionList content={ordernatorOptionsItems} spacingAround="small" />}
                                    />
                                </>
                            }
                        />
                    }
                    submissionsCompositions={
                        <>
                            <ConditionallyRender
                                shouldRender={!isSkeletonVisible}
                                content={
                                    <>
                                        <PerformanceEssaySubmissionCard
                                            note={900}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="success" />}
                                            badgeElement={<IconBadge hasAction={false} icon={trophy} backgroundColor="#28B583" scale="medium" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                        <PerformanceEssaySubmissionCard
                                            note={800}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="normal" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                        <PerformanceEssaySubmissionCard
                                            note={800}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="normal" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                        <PerformanceEssaySubmissionCard
                                            note={700}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="normal" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                        <PerformanceEssaySubmissionCard
                                            note={700}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="normal" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                        <PerformanceEssaySubmissionCard
                                            note={600}
                                            essayTheme="Desafios para a valorização de comunidades e povos tradicionais no Brasil"
                                            scheduleIcon={schedule}
                                            submissionDate="12/10/2023"
                                            skills={submissionEssayCardSkills}
                                            progressElement={<Radial progress={70} size="large" appearance="danger" />}
                                            badgeElement={<IconBadge hasAction={false} icon={sadFace} backgroundColor="#E85153" scale="medium" />}
                                            seeDetailsElement={
                                                <Button
                                                    label="Ver redação na íntegra"
                                                    variant="outlined"
                                                    appearance="secondary"
                                                    size="small"
                                                    isFullyAdaptative
                                                    icon={<Asset source={arrowRight} color="#3a80cf" size={16} />}
                                                    handleClick={handleNavigateToEssayDetails}
                                                />
                                            }
                                        />
                                    </>
                                }
                            />
                            <ConditionallyRender
                                shouldRender={isSkeletonVisible}
                                content={
                                    <ConditionallyRender
                                        shouldRender={isSkeletonVisible}
                                        content={Array.from(Array(9)).map((_, index) => (
                                            <PerformanceEssaySubmissionCardSkeleton key={`performance-essay-submission-card-skeleton-${index}`} />
                                        ))}
                                    />
                                }
                            />
                        </>
                    }
                    loadMoreElement={<Button label="Carregar mais" appearance="secondary" handleClick={handleLoadMoreSumissions} />}
                />
            }
        />
    );
};
