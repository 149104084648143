import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { Col } from "components/Grid";
import { ErrorUIContainer } from "components/ErrorUI/styles";

export const SubjectScreenDetailsActiveModuleContainer = styled(Col)`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: sticky;
    top: 0;
`;

export const SubjectScreenDetailsActiveModuleCard = styled.div<{ withBlur?: boolean }>`
    display: flex;
    flex-direction: column;
    max-height: 100%;

    ${({ withBlur }) =>
        !!withBlur &&
        css`
            filter: blur(2px);
            /* opacity: 0.7; */
        `};

    ${ErrorUIContainer} {
        padding: ${theme.spacing.small};
    }
`;

export const SubjectScreenDetailsActiveModuleHeader = styled.header`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.small};
`;

export const SubjectScreenDetailsActiveModuleLegend = styled.strong`
    font-size: ${theme.typography.sizes.small};
    font-weight: bold;
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const SubjectScreenDetailsActiveModuleTitle = styled.h3`
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.medium};
`;
