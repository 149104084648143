import React from "react";

// Assets
import {
    ExercisePlatformChartLegend,
    ExercisePlatformChartItem,
    ExercisePlatformCharData,
    ExercisePlatformChartDivider,
    ExercisePlatformCharDataContainer,
    ExercisePlatformChartWrap
} from "./styles";

// Helpers
import { theme } from "config/theme";
import { VictoryPie, VictoryTooltip } from "victory";
import useIsMobile from "hooks/use-is-mobile";

interface IProps {
    questionsTotal: number;
    realizationsAverageHitRate: number;
}

const ExerciseListDashboardPlatformChart = ({ questionsTotal, realizationsAverageHitRate }: IProps) => {
    const isMobile = useIsMobile();

    const formatNumber = (number: number) => number.toFixed(2).replace(".", ",") || 0;

    const getTotalErrors = () => {
        const errors = questionsTotal - realizationsAverageHitRate;

        return errors;
    };

    const getErrorPercentage = () => {
        const errors = getTotalErrors();
        const result = (errors * 100) / questionsTotal;

        return `${formatNumber(result)}%`;
    };

    const getHitPercentage = () => {
        const result = (realizationsAverageHitRate * 100) / questionsTotal;

        return `${formatNumber(result)}%`;
    };

    return (
        <ExercisePlatformChartWrap>
            <ExercisePlatformChartLegend>
                <h3>Conteúdo da lista:</h3>
                <ExercisePlatformChartItem>
                    &bull; <span data-test-id="exercise-list-questions-total">{questionsTotal}</span> questões
                </ExercisePlatformChartItem>
            </ExercisePlatformChartLegend>

            <ExercisePlatformChartDivider />

            <ExercisePlatformCharDataContainer>
                <ExercisePlatformCharData>
                    <span>
                        <strong data-test-id="exercise-list-total-hits">{formatNumber(realizationsAverageHitRate)}</strong>
                        <br /> acertos
                    </span>
                    <VictoryPie
                        padding={0}
                        colorScale={[theme.colors.system.info[200], theme.colors.base[200]]}
                        innerRadius={130}
                        labelComponent={
                            <VictoryTooltip
                                active={false}
                                cornerRadius={isMobile ? 20 : 40}
                                pointerWidth={15}
                                pointerLength={20}
                                height={100}
                                dy={-35}
                                style={{
                                    fill: theme.colors.white,
                                    fontFamily: theme.typography.family.primary,
                                    fontSize: 40,
                                    fontWeight: "bold",
                                    padding: 20
                                }}
                                flyoutStyle={{
                                    fill: theme.colors.base[500]
                                }}
                            />
                        }
                        data={[
                            {
                                x: realizationsAverageHitRate,
                                y: realizationsAverageHitRate,
                                label: `${formatNumber(realizationsAverageHitRate)} acertos (${getHitPercentage()})`
                            },
                            {
                                x: getTotalErrors(),
                                y: getTotalErrors(),
                                label: `${formatNumber(getTotalErrors())} erros (${getErrorPercentage()})`
                            }
                        ]}
                    />
                </ExercisePlatformCharData>

                <small>média geral da primeira resolução</small>
            </ExercisePlatformCharDataContainer>
        </ExercisePlatformChartWrap>
    );
};

export default React.memo(ExerciseListDashboardPlatformChart);
