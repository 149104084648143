// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";

// Types
import { ActivityPartialModalProps } from "./activity-partial-modal.types";

// Styles
import { Container, Title } from "./activity-partial-modal.styles";

// Components
import { ActivityOverlay } from "components/ActivityOverlay";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityPartialModal: FunctionComponent<ActivityPartialModalProps> = ({
    isFullyAdaptative,
    width,
    title,
    isOpened,
    content,
    handleOutsideClick
}) => {
    const [shouldRenderModal, setShouldRenderModal] = useState(isOpened);

    useEffect(() => {
        if (isOpened) {
            setShouldRenderModal(true);
        } else {
            const timeoutId = setTimeout(() => {
                setShouldRenderModal(false);
            }, 300);

            return () => clearTimeout(timeoutId);
        }
    }, [isOpened]);

    return (
        <ConditionallyRender
            shouldRender={!!shouldRenderModal}
            content={
                <Fragment>
                    <ActivityOverlay
                        position="right"
                        handleOutsideClick={handleOutsideClick}
                        content={
                            <Container className="webapp-partial-modal" isOpened={isOpened} isFullyAdaptative={isFullyAdaptative} width={width}>
                                <Title>{title}</Title>

                                {content}
                            </Container>
                        }
                    />
                </Fragment>
            }
        />
    );
};
