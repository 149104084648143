import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import history from "services/history";
import queryString, { ParsedQuery } from "query-string";

import MyEssays from "./myEssays";

import { IReduxStore } from "interfaces/IReduxStore";
import { Types as EssayTypes } from "store/ducks/essay";
import { useQueryString } from "hooks/use-query-string";
import usePrevious from "hooks/use-previous";

const MyEssaysScreen = () => {
    const dispatch = useDispatch();
    const query = useQueryString();
    const prevQuery = usePrevious(query);
    const { isLoading, isResetedRequest, themes, compositions, latestCompositions, filters, courseSlug } = useSelector(
        ({ essay, course }: IReduxStore) => ({
            ...essay,
            courseSlug: course.slug
        })
    );
    const [isCompositionsFetched, setIsCompositionsFetched] = useState(false);

    const getCompositions = useCallback(
        (page: number, queryParam?: ParsedQuery<any>, isResetedRequest?: boolean) =>
            dispatch({ type: EssayTypes.GET_ESSAY_COMPOSITIONS_REQUEST, payload: { page, query: queryParam, isResetedRequest } }),
        [dispatch]
    );

    const getFilters = useCallback(() => dispatch({ type: EssayTypes.GET_ESSAY_FILTERS_REQUEST }), [dispatch]);

    const getThemes = useCallback(() => dispatch({ type: EssayTypes.GET_ESSAY_THEMES_REQUEST, payload: { perPage: 1000 } }), [dispatch]);

    const handlePagination = useCallback(() => getCompositions(compositions.all.page + 1), [getCompositions, compositions.all.page]);

    useEffect(() => {
        const prevQueryStr = JSON.stringify(prevQuery);
        const queryStr = JSON.stringify(query);

        if (prevQueryStr !== queryStr) {
            getCompositions(0, query, true);
        }
    }, [getCompositions, prevQuery, query]);

    useEffect(() => {
        if (!isCompositionsFetched) {
            getCompositions(0, query, true);
            getFilters();
            getThemes();
            setIsCompositionsFetched(true);
        }
    }, [dispatch, getCompositions, getFilters, getThemes, isCompositionsFetched, query]);

    useEffect(() => {
        return () => {
            dispatch({ type: EssayTypes.CLEAR_ESSAY_COMPOSITION });
        };
    }, [dispatch]);

    const handleNavigation = useCallback(
        (key: string) => (value: string | boolean) => {
            const qs = {
                ...query,
                [key]: value
            };

            history.push({ pathname: `/app/curso/${courseSlug}/redacoes/envios`, search: queryString.stringify(qs) });
        },
        [courseSlug, query]
    );

    const handleSelectContestChart = useCallback(
        (value: string) => {
            dispatch({ type: EssayTypes.GET_ESSAY_COMPOSITIONS_REQUEST, payload: { isFilterChart: value } });
        },
        [dispatch]
    );

    const filterThemes = useMemo(() => {
        return themes?.all?.items?.map((theme) => ({ value: theme.idTheme, label: theme.title }));
    }, [themes]);

    return (
        <MyEssays
            isFetched={isResetedRequest}
            isLoading={isLoading}
            compositions={compositions}
            latestCompositions={latestCompositions}
            onFetchCompositions={handlePagination}
            onSort={handleNavigation("sort")}
            contests={filters[4]?.items || []}
            onSelectContest={handleNavigation("contest")}
            onSelectContestChart={handleSelectContestChart}
            themes={filterThemes || []}
            onSelectTheme={handleNavigation("theme")}
        />
    );
};

export default MyEssaysScreen;
