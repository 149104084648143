// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    selected: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    width: 256px;

    @media (min-width: 768px) {
        flex: 1;
        width: 100%;
    }

    ${({ selected }) =>
        !!selected &&
        css`
            ${PositionWrapper} {
                background-color: #794a7d;
            }

            ${Position} {
                color: #fff;
            }

            ${CommentWrapper} {
                filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
            }
        `}
`;

export const PositionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 36px;
    height: 42px;
    border-radius: 8px;
    background-color: #f0f1f7;

    cursor: pointer;

    @media (min-width: 1024px) {
        width: 48px;
        height: 60px;
    }
`;

export const Position = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 1024px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }
`;

export const CommentWrapper = styled.div`
    width: 100%;

    position: relative;

    display: flex;
    align-items: flex-start;

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1));

    @media (min-width: 768px) {
        height: 100%;
    }
`;

export const Arrow = styled.span`
    display: block;

    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-right-color: #fff;
    margin-left: -8px;

    position: relative;
    top: 12px;
    left: 6px;

    z-index: 1;
    cursor: pointer;

    @media (min-width: 768px) {
        border: 16px solid transparent;
        border-right-color: #fff;

        top: 16px;
        left: 3px;
    }
`;

export const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;

    z-index: 2;
    cursor: pointer;

    @media (min-width: 1024px) {
        padding: 24px;
    }
`;

export const CommentSkillHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CommentSkillWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

type SkillIndicatorStyleProps = {
    color: string;
};

export const CommentSkillIndicator = styled.div<SkillIndicatorStyleProps>`
    display: flex;
    flex-shrink: 0;

    width: 4px;
    height: calc(100% - 2px);
    border-radius: 5px;

    ${({ color }) => css`
        background-color: ${color};
    `}
`;

export const CommentSkill = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;
`;

export const CommentTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #666b7b;
`;

export const Comment = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666b7b;
`;

// ----------

export const ContainerSkeleton = styled.div`
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    width: 256px;

    @media (min-width: 768px) {
        flex: 1;
        width: 100%;
    }
`;

export const PositionWrapperSkeleton = styled.div`
    flex-shrink: 0;

    width: 36px;
    height: 42px;

    @media (min-width: 1024px) {
        width: 48px;
        height: 60px;
    }
`;

export const CommentSkillIndicatorSkeleton = styled.div`
    flex-shrink: 0;
    width: 4px;
    height: calc(100% - 2px);
`;

export const CommentSkillSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;
`;

export const AssetWrapperSkeleton = styled.div`
    width: 16px;
    height: 16px;

    @media (min-width: 1024px) {
        width: 20px;
        height: 20px;
    }
`;

export const CommentTitleSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;
    width: 140px;

    @media (min-width: 768px) {
        width: 220px;
    }

    @media (min-width: 1024px) {
        width: 280px;
    }
`;
