import axios, { AxiosRequestConfig } from "axios";

export const headers = {
    headers: {
        "Content-Type": "application/json"
    }
};

export const config: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_FORUM,
    headers: headers.headers
};

const apiMongo = axios.create(config);

export default apiMongo;
