import styled from "styled-components/macro";
import { MediaQueriesManual } from "assets/styles/settings";

import { WebpCard } from "components/WebpCard";
import { theme } from "config/theme";

export const LessonPlanCustomStudyHeader = styled.div`
    margin-bottom: 24px;
    width: 100%;

    .labelLessonPlanCustomStudyPlan {
        //styleName: Body-text1;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: ${theme.colors.base[500]};

        padding-bottom: 16px;
        border-bottom: 1px solid #f0f1f7;
        margin-bottom: 24px;
        width: 100%;
        text-align: center;
    }

    .lessonPlan {
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        color: ${theme.colors.base[500]};
        margin-bottom: 31px;
    }

    ${MediaQueriesManual(768)} {
        display: flex;
        max-width: 600px;
        margin: 0 auto;

        .labelLessonPlanCustomStudyPlan {
            display: none;
        }

        .lessonPlan {
            margin: 0 auto 0 0;
        }
    }
`;

export const LessonPlanCustomStudyPlanWebpCard = styled(WebpCard)`
    margin-bottom: 24px;
    justify-content: center;
    display: flex;

    > .content {
        display: flex;
        justify-content: center;
    }

    webp-calendar {
        margin: auto;
    }

    ${MediaQueriesManual(1024)} {
        margin-bottom: 0px;
        justify-content: inherit;
        display: block;
    }
`;
