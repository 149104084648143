import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";
import queryString, { ParsedQuery } from "query-string";

// Components
import ForumHome from "./home";

// Helpers
import { useSelector } from "store";
import history from "services/history";
import { Types } from "store/ducks/forum";
import usePrevious from "hooks/use-previous";
import { ICheckboxItem } from "interfaces/ICheckbox";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { useQueryString } from "hooks/use-query-string";

const ForumHomeScreen = () => {
    const query = useQueryString();
    const dispatch = useDispatch();

    const { filters, courseSlug, ...rest } = useSelector(({ forum, course }) => ({
        questions: forum.questions,
        isLoadingForum: forum.isLoadingForum,
        isResetedRequest: forum.isResetedRequest,
        filters: forum.filters,
        courseSlug: course?.slug
    }));

    const [isFetched, setIsFetched] = useState(false);
    const [isFiltersRehydrated, setIsFiltersRehydrated] = useState(false);
    const [filterOptions, setFilterOptions] = useState<IFilterGroup[]>(filters);

    const prevQuery = usePrevious(query);

    // Update filter options when redux filters is available
    useEffect(() => {
        if (filters && !Boolean(filterOptions.length)) {
            setFilterOptions(filters);
        }
    }, [filterOptions.length, filters]);

    // Update filter options when url changes
    const updateFilters = useCallback(
        (subjectsIds: number[]) => {
            setIsFiltersRehydrated(false);

            try {
                const arr = filterOptions.map((option) => ({
                    ...option,
                    items: option.items.map((item) => {
                        const found = subjectsIds.find((subject: number) => subject === item.value);
                        if (!found) {
                            return item;
                        }
                        return {
                            ...item,
                            checked: true
                        };
                    })
                }));

                setFilterOptions(arr);
            } catch (error) {
                console.log("error", error);
                setFilterOptions(filters);
            } finally {
                setIsFiltersRehydrated(true);
            }
        },
        [filterOptions, filters]
    );

    // Fire updateFilters function when state is empty, has subjectIds and actual filters id not hydrated
    useEffect(() => {
        const subjects = query["subject.id"];
        const subjectsIds = subjects ? (subjects as string).split(",").map((item) => Number(item)) : [];

        if (Boolean(filterOptions.length) && subjectsIds && !isFiltersRehydrated) {
            updateFilters(subjectsIds);
        }
    }, [filterOptions, isFiltersRehydrated, prevQuery, query, updateFilters]);

    // Get questions
    const getQuestions = useCallback(
        (page: number, queryParam?: ParsedQuery<any>, isResetedRequest?: boolean) =>
            dispatch({ type: Types.GET_ALL_QUESTIONS_REQUEST, payload: { page, query: queryParam || query, isResetedRequest } }),
        [dispatch, query]
    );

    // Get subjects
    const getForumSubjects = useCallback(() => dispatch({ type: Types.GET_FORUM_SUBJECTS_REQUEST }), [dispatch]);

    // Get questions when filters updates
    useEffect(() => {
        const prevQueryStr = JSON.stringify(prevQuery);
        const queryStr = JSON.stringify(query);

        if (!rest.isLoadingForum && prevQueryStr !== queryStr) {
            getQuestions(1, query, true);
        }
    }, [rest.isLoadingForum, rest.questions.page, getQuestions, prevQuery, query]);

    useEffect(() => {
        if (!isFetched) {
            getForumSubjects();
            setIsFetched(true);
        }
    }, [getForumSubjects, isFetched]);

    // Clear forum reducer
    useEffect(() => {
        return () => {
            dispatch({ type: Types.CLEAR_FORUM });
        };
    }, [dispatch]);

    // Apply filters on url
    const handleFilters = useCallback(
        (options: IFilterGroup[]) => {
            setFilterOptions(options);

            const ids = options
                .reduce((acc: ICheckboxItem[], curr: IFilterGroup) => {
                    const arr = curr.items.filter((item) => item.checked);

                    return [...acc, ...arr];
                }, [])
                .map((item) => item.value);

            const qs: any = {
                ...query,
                ...(Boolean(ids.length) && { "subject.id": ids })
            };

            if (!Boolean(ids.length)) {
                delete qs["subject.id"];
            }

            history.push({ pathname: `/app/curso/${courseSlug}/forum`, search: queryString.stringify(qs, { arrayFormat: "comma" }) });
        },
        [courseSlug, query]
    );

    // Apply sort and myquestion on url
    const handleNavigation = useCallback(
        (key: string) => (value: string | boolean) => {
            const qs = {
                ...query,
                [key]: value
            };

            history.push({ pathname: `/app/curso/${courseSlug}/forum`, search: queryString.stringify(qs) });
        },
        [courseSlug, query]
    );

    const isUserQuestions = useMemo(() => Boolean(query?.myquestions), [query]);

    return (
        <ForumHome
            isFetched={isFetched}
            filterOptions={filterOptions}
            isUserQuestions={isUserQuestions}
            sort={query?.sort}
            onSort={handleNavigation("sort")}
            onFetchQuestions={getQuestions}
            onSelectFilter={handleFilters}
            onChangeUserQuestions={handleNavigation("myquestions")}
            {...rest}
        />
    );
};

export default memo(ForumHomeScreen);
