import React from "react";

import Correction from "components/essayCorrection";
import { useSelector } from "store";

import { ExerciseListEssayCorrectionContainer as Container } from "./styles";
import ExerciseListEssayEmpty from "./empty";

const ExerciseListEssayCorrection: React.FC = () => {
    const { themeTitle, correction } = useSelector(({ exerciseList, answerCard }) => ({
        themeTitle: exerciseList?.essayTheme?.title,
        correction: answerCard?.essayCorrection
    }));

    if (!correction || !themeTitle) {
        return <ExerciseListEssayEmpty />;
    }

    return (
        <Container>
            <Correction composition={correction} themeTitle={themeTitle} />
        </Container>
    );
};

export default ExerciseListEssayCorrection;
