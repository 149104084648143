import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { BadgeContainer } from "components/Badge/styles";
import { BodyText1, BodyText2 } from "assets/styles/global";

export const ColorTaggedItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
        padding-bottom: ${theme.spacing.smallX};
        margin-bottom: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ColorTaggedItemWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ColorTaggedItemBullet = styled.div<{ bulletColor: string }>`
    width: 4px;
    min-height: 24px;
    background: ${({ bulletColor }) => bulletColor};
    border-radius: ${theme.spacing.smallXX};
    height: 100%;
    margin-right: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-right: 12px;
    }
`;

export const ColorTaggedItemContent = styled.div`
    flex: 1;
    flex-direction: column;
    display: flex;
`;

export const ColorTaggedItemName = styled.div`
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ${theme.spacing.smallXX};


    > p {
        ${BodyText1}
        color: ${theme.colors.base[400]};
        flex :1;
    }
`;

export const ColorTaggedItemIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        color: ${theme.colors.brand[300]};
        width: auto;
        height: 8px;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            height: 10px;
        }
    }

    p {
        margin-right: 4px;
        color: ${theme.colors.base[400]};
        font-family: "Nunito Sans", sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
    }
`;

export const ColorTaggedItemDescription = styled.div<{ color: string }>`
    ${BodyText2};

    display: flex;
    align-items: center;
    color: ${({ color }) => color};
    margin-right: 32px;

    > ${BadgeContainer} {
        padding: ${theme.spacing.smallXX} ${theme.spacing.smallX};
        margin-right: ${theme.spacing.smallXX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-left: ${theme.spacing.small};
    }
`;
