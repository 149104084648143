// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ContainerSkeleton,
    AreaCardWrapper,
    AreaCardHeaderWrapper,
    AreaCardHeader,
    IconWrapperSkeleton,
    AreaCardTitleSkeleton,
    AreaCardContentWrapper,
    AreaCardContent,
    AreaCardQuestionsInfoWrapper,
    AreaCardQuestionsInfoSkeleton,
    RadialElementWrapperSkeleton,
    ActionIconWrapperSkeleton
} from "./performance-area-card.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceAreaCardSkeleton: FunctionComponent = () => {
    return (
        <ContainerSkeleton>
            <AreaCardWrapper>
                <AreaCardHeaderWrapper>
                    <AreaCardHeader>
                        <IconWrapperSkeleton>
                            <Skeleton width="100%" height="100%" borderRadius="4px" />
                        </IconWrapperSkeleton>

                        <Spacing direction="horizontal" size={16} />

                        <div>
                            <AreaCardTitleSkeleton>
                                <Skeleton width="124px" height="100%" borderRadius="4px" />
                            </AreaCardTitleSkeleton>

                            <AreaCardTitleSkeleton>
                                <Skeleton width="124px" height="100%" borderRadius="4px" />
                            </AreaCardTitleSkeleton>
                        </div>
                    </AreaCardHeader>
                </AreaCardHeaderWrapper>

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={8} />} />

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                <AreaCardContentWrapper>
                    <AreaCardContent>
                        <RadialElementWrapperSkeleton>
                            <Skeleton width="100%" height="100%" borderRadius="50%" />
                        </RadialElementWrapperSkeleton>

                        <ScreenWidthRender
                            actionAfterRenderingWidth="hide"
                            renderingWidth={1024}
                            content={<Spacing direction="horizontal" size={8} />}
                        />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={1024}
                            content={<Spacing direction="horizontal" size={16} />}
                        />

                        <AreaCardQuestionsInfoWrapper>
                            <AreaCardQuestionsInfoSkeleton>
                                <Skeleton width="84px" height="100%" borderRadius="4px" />
                            </AreaCardQuestionsInfoSkeleton>

                            <ScreenWidthRender
                                actionAfterRenderingWidth="hide"
                                renderingWidth={1024}
                                content={<Spacing direction="horizontal" size={8} />}
                            />

                            <AreaCardQuestionsInfoSkeleton>
                                <Skeleton width="84px" height="100%" borderRadius="4px" />
                            </AreaCardQuestionsInfoSkeleton>

                            <ScreenWidthRender
                                actionAfterRenderingWidth="hide"
                                renderingWidth={1024}
                                content={<Spacing direction="horizontal" size={8} />}
                            />
                        </AreaCardQuestionsInfoWrapper>
                    </AreaCardContent>

                    <ActionIconWrapperSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </ActionIconWrapperSkeleton>
                </AreaCardContentWrapper>
            </AreaCardWrapper>

            <Skeleton width="100%" height="8px" borderRadius="0px 0px 8px 8px" />
        </ContainerSkeleton>
    );
};
