import React, { useCallback, memo, forwardRef, useState } from "react";
import html2canvas from "html2canvas";

import Button from "components/Button";

import { EssayStatus } from "enums/EssayStatus";

import * as S from "./styles";

const ESSAY_MAX_COLS = 78;
const ESSAY_MAX_LINES = 30;

interface IEssayCanvasProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    handleDiscard?(): void;
    handleDraft?(content: string, image: string, status: number): void;
    handleCorrection?(content: string, image: string, status: number): void;
    draftButtonLabel?: string;
    generateBase64?: boolean;
}

const EssayCanvas = forwardRef<HTMLTextAreaElement, IEssayCanvasProps>(
    (
        { value, onChange, onKeyUp, handleDiscard, handleDraft, handleCorrection, draftButtonLabel = "Salvar rascunho", generateBase64 = true },
        ref
    ) => {
        const [isGeneratingDraft, setIsGeneratingDraft] = useState(false);

        const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => event.preventDefault(), []);

        const essayCanvasPrint: HTMLElement | null = document.getElementById("print");

        const handleSendDraft = useCallback(
            async (status: EssayStatus) => {
                if (!essayCanvasPrint || !handleDraft) {
                    return;
                }

                setIsGeneratingDraft(true);

                if (!generateBase64) {
                    handleDraft(value || "", "", status);

                    setIsGeneratingDraft(false);

                    return;
                }

                await new Promise((resolve) => setTimeout(() => resolve({}), 1000));

                const canvas = await html2canvas(essayCanvasPrint!);

                const image = canvas.toDataURL("image/png");
                const content = value || "";

                handleDraft(content, image, status);

                setIsGeneratingDraft(false);
            },
            [essayCanvasPrint, generateBase64, handleDraft, value]
        );

        const handleSendCorrection = useCallback(
            (status: number) => {
                if (!essayCanvasPrint || !handleCorrection) {
                    return;
                }

                html2canvas(essayCanvasPrint!).then((canvas) => {
                    const image = canvas.toDataURL("image/png");
                    const content = value || "";

                    handleCorrection(content, image, status);
                });
            },
            [essayCanvasPrint, handleCorrection, value]
        );

        return (
            <S.EssayCanvasContainer>
                <S.EssayCanvasWrapper>
                    <S.EssayCanvasLines>
                        {Array.from(Array(ESSAY_MAX_LINES)).map((_, index) => (
                            <S.EssayCanvasLinesItem key={index}>{index + 1}</S.EssayCanvasLinesItem>
                        ))}
                    </S.EssayCanvasLines>
                    <S.EssayCanvasHelper
                        ref={ref}
                        wrap="hard"
                        value={value}
                        id="essay"
                        cols={ESSAY_MAX_COLS}
                        rows={ESSAY_MAX_LINES}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        onPaste={handlePaste}
                    />
                    <S.EssayCanvasPrint id="print">{value}</S.EssayCanvasPrint>
                </S.EssayCanvasWrapper>
                <S.EssayCanvasNav>
                    {handleDiscard && (
                        <Button size="medium" variant="outline-info" title="Descartar" onClick={handleDiscard}>
                            Descartar
                        </Button>
                    )}

                    {handleDraft && (
                        <Button
                            size="medium"
                            variant="outline-info"
                            disabled={!value}
                            title={draftButtonLabel}
                            onClick={() => handleSendDraft(EssayStatus.Draft)}
                            isLoading={isGeneratingDraft}
                        >
                            {draftButtonLabel}
                        </Button>
                    )}
                    {handleCorrection && (
                        <Button
                            size="medium"
                            variant="info"
                            title="Enviar"
                            disabled={!value}
                            onClick={() => handleSendCorrection(EssayStatus.Pending)}
                        >
                            Enviar para correção
                        </Button>
                    )}
                </S.EssayCanvasNav>
            </S.EssayCanvasContainer>
        );
    }
);

export default memo(EssayCanvas);
