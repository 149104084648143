// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    textFormat: "title-xss" | "body-text-01" | "body-text-02" | "title-sm" | "title-md" | "microcopy";
    colorVariant: string;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;

    ${({ colorVariant }) =>
        colorVariant === "neutral-100" &&
        css`
            color: ${theme.colors.base[100]};
        `}

    ${({ colorVariant }) =>
        colorVariant === "neutral-200" &&
        css`
            color: ${theme.colors.base[200]};
        `}

    ${({ colorVariant }) =>
        colorVariant === "neutral-300" &&
        css`
            color: ${theme.colors.base[300]};
        `}

    ${({ colorVariant }) =>
        colorVariant === "neutral-400" &&
        css`
            color: ${theme.colors.base[400]};
        `}

    ${({ colorVariant }) =>
        colorVariant === "neutral-500" &&
        css`
            color: ${theme.colors.base[500]};
        `}

    ${({ textFormat }) =>
        textFormat === "title-xss" &&
        css`
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 16px;
            font-family: "Nunito", sans-serif;
            font-style: normal;
            display: flex;
            align-items: center;

            p {
                margin: 0px 0.549em 0px 0.25em;
                font-weight: 600;
            }

            span {
                font-weight: 600;
            }

            h3 {
                font-size: 16px;
                margin: 0;
            }

            div {
                font-size: 16px;
                color: ${theme.colors.brand[300]};
                font-weight: 600;
                margin: 0 0 0 0.286em;
                align-items: center;
            }
        `}

    ${({ textFormat }) =>
        textFormat === "body-text-01" &&
        css`
            font-family: "Nunito", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.375;
            letter-spacing: 0;

            h3 {
                font-size: 16px;
            }
        `}

    ${({ textFormat }) =>
        textFormat === "body-text-02" &&
        css`
            font-family: "Nunito", sans-serif;
            font-style: normal;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 1.428;

            p {
                margin-bottom: 8px;
            }

            @media (min-width: 767px) {
                p {
                    font-size: 14px;
                }
            }
        `}

    ${({ textFormat }) =>
        textFormat === "title-sm" &&
        css`
            font-family: "Nunito", sans-serif;
            font-style: normal;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 1.4;

            span {
                font-size: 20px;
            }

            @media (min-width: 500px) and (max-width: 767px) {
                h1 {
                    font-size: 20px;
                }
            }
        `}

    ${({ textFormat }) =>
        textFormat === "title-md" &&
        css`
            font-family: "Nunito", sans-serif;
            font-style: normal;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 1.5;

            p {
                font-size: 24px;
            }
        `}

    ${({ textFormat }) =>
        textFormat === "microcopy" &&
        css`
            font-family: "Nunito", sans-serif;
            font-style: normal;
            font-size: 0.75rem;
            font-weight: 400;
            letter-spacing: 0.01em;
            line-height: 1.333;
        `}
`;
