// Dependencies
import styled, { css, keyframes } from "styled-components";

// Types
import { ActivityButtonVariantType, ActivityButtonSize, ActivityButtonVariant } from "./activity-button.types";

export const Label = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.5px;
    text-align: center;
    white-space: nowrap;
`;

type ContainerStyleProps = {
    isFullyAdaptative: boolean;
    isDisabled: boolean;
    variant: ActivityButtonVariant;
    variantType: ActivityButtonVariantType;
    size: ActivityButtonSize;
};

export const Container = styled.button<ContainerStyleProps>`
    width: max-content;
    border-radius: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    transition: 2ms all ease-in-out;
    background-color: transparent;

    position: relative;

    ${({ isFullyAdaptative }) =>
        isFullyAdaptative &&
        css`
            width: 100%;
        `};

    ${({ size, variantType }) =>
        size === "small" &&
        variantType !== "link" &&
        css`
            padding: 4px 16px;
        `};

    ${({ size, variantType }) =>
        size === "large" &&
        variantType !== "link" &&
        css`
            padding: 8px 16px;
        `};

    ${({ isDisabled, variantType }) =>
        isDisabled &&
        variantType === "primary" &&
        css`
            background-color: #ced0dd;
            border: 1px solid #ced0dd;

            ${Label} {
                color: #4d4d63;
            }
        `};

    ${({ isDisabled, variantType }) =>
        isDisabled &&
        variantType === "secondary" &&
        css`
            background-color: transparent;
            border: 1px solid #ced0dd;

            ${Label} {
                color: #83899e;
            }
        `};

    ${({ isDisabled, variantType }) =>
        isDisabled &&
        variantType === "link" &&
        css`
            background-color: transparent;
            border: none;

            ${Label} {
                color: #ced0dd;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "primary" &&
        variant === "cta" &&
        css`
            background-color: #24388c;
            border: 1px solid #24388c;

            ${Label} {
                color: #ffffff;
            }

            &:hover {
                background-color: #162152;
                border-color: #162152;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "primary" &&
        variant === "danger" &&
        css`
            background-color: #e40000;
            border: 1px solid #e40000;

            ${Label} {
                color: #ffffff;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "primary" &&
        variant === "info" &&
        css`
            background-color: #3e68d2;
            border: 1px solid #3e68d2;

            ${Label} {
                color: #ffffff;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "secondary" &&
        variant === "cta" &&
        css`
            background-color: #fff;
            border: 1px solid #24388c;

            ${Label} {
                color: #24388c;
            }

            &:hover {
                background-color: #4ac8f4;
                border-color: #162152;

                ${Label} {
                    color: #162152;
                }
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "secondary" &&
        variant === "danger" &&
        css`
            background-color: transparent;
            border: 1px solid #e40000;

            ${Label} {
                color: #e40000;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "secondary" &&
        variant === "info" &&
        css`
            background-color: #fff;
            border: 1px solid #3e68d2;

            ${Label} {
                color: #3e68d2;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "link" &&
        variant === "cta" &&
        css`
            ${Label} {
                color: #24388c;
            }

            &:hover {
                ${Label} {
                    color: #162152;
                }
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "link" &&
        variant === "danger" &&
        css`
            ${Label} {
                color: #e40000;
                border-bottom: 1px solid #e40000;
            }
        `};

    ${({ isDisabled, variantType, variant }) =>
        !isDisabled &&
        variantType === "link" &&
        variant === "info" &&
        css`
            ${Label} {
                color: #3e68d2;
            }
        `};

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: not-allowed;
        `};

    ${({ isDisabled }) =>
        !isDisabled &&
        css`
            cursor: pointer;
        `};
`;

type ContentWrapperStyleProps = {
    isVisible: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperStyleProps>`
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${({ isVisible }) =>
        !isVisible &&
        css`
            visibility: hidden;
        `};
`;

export const Content = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ellipsis1 = keyframes`
   0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
`;

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

export const Loader = styled.div`
    display: flex;
    position: absolute;

    > div {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 13px;

        > div {
            position: absolute;
            top: 3.25px;
            width: 6.5px;
            height: 6.5px;
            border-radius: 50%;
            background: #24388c;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
        }

        > div:nth-child(1) {
            left: 4px;
            animation: ${ellipsis1} 0.6s infinite;
        }

        > div:nth-child(2) {
            left: 4px;
            animation: ${ellipsis2} 0.6s infinite;
        }

        > div:nth-child(3) {
            left: 16px;
            animation: ${ellipsis2} 0.6s infinite;
        }

        > div:nth-child(4) {
            left: 28px;
            animation: ${ellipsis3} 0.6s infinite;
        }
    }
`;
