import React from "react";
import { useSelector } from "react-redux";

// Assets
import { PreviousExamDetailsHeaderContainer, PreviousExamDetailsHeaderBackButton } from "./styles";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// Components
import { Grid } from "components/Grid";
import Skeleton from "components/Skeleton";

// Helpers
import { theme } from "config/theme";
import history from "services/history";

// Types
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    name: string;
    isLoading: boolean;
}

const PreviousExamDetailsHeader = ({ name, isLoading }: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <PreviousExamDetailsHeaderContainer>
            <Grid>
                <PreviousExamDetailsHeaderBackButton onClick={() => history.push({ pathname: `/app/curso/${courseSlug}/provas-anteriores` })}>
                    Provas anteriores
                    <ChevronRightIcon width={4} />
                </PreviousExamDetailsHeaderBackButton>
                {isLoading ? (
                    <>
                        <Skeleton height="25px" width="150px" style={{ marginBottom: theme.spacing.small }} />
                        <Skeleton height="25px" width="80%" />
                    </>
                ) : (
                    <>
                        <h1 data-test-id="previous-exam-name">{name}</h1>
                    </>
                )}
            </Grid>
        </PreviousExamDetailsHeaderContainer>
    );
};

export default PreviousExamDetailsHeader;
