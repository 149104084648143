// Dependencies
import styled, { keyframes } from "styled-components";
import { theme } from "config/theme";

const moveToRight = keyframes`
    0% { left: 5% }
    100% { left: calc(100% - 22px) }
`;

const moveToLeft = keyframes`
    0% { left: calc(100% - 22px) }
    100% { left: 5% }
`;

type ContainerStyleProps = {
    isActive: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 48px;
    height: 24px;
    padding: 2px;
    border-radius: 100px;
    background-color: ${({ isActive }) => (isActive ? theme.colors.brand[300] : "#ced0dd")};

    cursor: pointer;

    position: relative;
`;

type CircleStyleProps = {
    isActive: boolean;
};

export const Circle = styled.div<CircleStyleProps>`
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px 0px #0000001a;
    position: absolute;
    animation: ${({ isActive }) => (isActive ? moveToRight : moveToLeft)} 0.3s linear forwards;
`;
