import styled from "styled-components";
import { theme } from "config/theme";
import { MediaQueries, BreakPoints } from "assets/styles/settings";
import { TitleH2, TitleH3, TitleH4 } from "assets/styles/global";

export const ExerciseListCustomContainer = styled.div`
    ${MediaQueries.SMALL} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const Header = styled.div`
    margin: ${theme.spacing.medium} 0;

    display: flex;
    flex-direction: column;

    > h1 {
        ${TitleH3};
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const ExerciseListCustomHeader = styled.header`
    display: flex;
    margin-bottom: ${theme.spacing.small};
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacing.medium};
    @media screen and (min-width: ${BreakPoints.tablet}px) {
        padding-top: ${theme.spacing.largeX};
    }
`;

export const ExerciseListCustomTitle = styled.h2`
    ${TitleH2};
    color: ${theme.colors.base[500]};
`;

export const ExerciseListCustomSubTitle = styled.h2`
    ${TitleH2};
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${theme.colors.base[300]};
    padding-bottom: ${theme.spacing.medium};

    ${MediaQueries.SMALL} {
        text-align: center;
    }
`;

export const ExerciseListCustomCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 24px;

    width: 297px;
    height: 260px;

    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
`;

export const ExerciseListCustomCardTitle = styled.h2`
    ${TitleH3};
    font-weight: normal;
    color: ${theme.colors.base[500]};
    font-size: 16px;
    line-height: 22px;
`;

export const ExerciseListCustomCardSubTitle = styled.span`
    font-weight: normal;
    color: ${theme.colors.base[300]};
    font-size: 14px;
    line-height: 20px;
`;

export const ExerciseListCustomListContainer = styled.div``;

export const ExerciseListCustomListTitle = styled.h4`
    ${TitleH4};
    margin-top: ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.SMALL} {
        text-align: center;
    }
`;

export const ExerciseListCustomList = styled.ul`
    display: flex;
    flex-direction: row;

    ${MediaQueries.SMALL} {
        flex-direction: column;
    }
`;

export const ExerciseListCustomListItem = styled.li`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: ${theme.colors.base[400]};
    padding: 0 ${theme.spacing.large};

    & span {
        margin-top: ${theme.spacing.small};
        color: ${theme.colors.base[300]};
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
    }

    &:not(:last-child) {
        border-right: 1px solid ${theme.colors.base[200]};
    }

    &:first-child {
        padding-left: 0;
    }

    ${MediaQueries.SMALL} {
        align-items: center;
        padding: 0;

        & span {
            text-align: center;
            margin-bottom: ${theme.spacing.small};
        }

        &:not(:last-child) {
            border-right: none;
        }

        &:not(:first-child) {
            border-top: 1px solid ${theme.colors.base[200]};
            padding-top: ${theme.spacing.small};
        }
    }
`;
