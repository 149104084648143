// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as activityActions } from "store/ducks/activity";

// Services
import history from "services/history";

// Components
import { ActivityAnswerItem, ActivityAnswers } from "components/ActivityAnswers";
import { ActivityButton } from "components/ActivityButton";
import { ActivityExpandableCard } from "components/ActivityExpandableCard";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ActivityBreadcrumbItem, ActivityBreadcrumbs } from "components/ActivityBreadcrumbs";
import { ActivityProgressBar } from "components/ActivityProgressBar";
import { ActivityExerciseLayout } from "components/ActivityExerciseLayout";
import { ActivityTitle } from "components/ActivityTitle";
import { ActivityFile } from "components/ActivityFile";
import { ActivityPerformanceChart } from "components/ActivityPerformanceChart";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";
import { ActivityScore } from "components/ActivityScore";
import { ActivityScoreBadge } from "components/ActivityScoreBadge";
import { ActivityAnswerSheet, ActivityAnswerSheetItem } from "components/ActivityAnswerSheet";
import { ActivityResultPerformanceCard } from "components/ActivityResultPerformanceCard";
import { ActivityTextarea } from "components/ActivityTextarea";
import { ActivityQuestionIndicator } from "components/ActivityQuestionIndicator";
import { ActivityInput } from "components/ActivityInput";

// Styles
import {
    ActivityAnswerFilesWrapper,
    ActivityExerciseCardLabel,
    ActivityExerciseCardSeparator,
    ActivityExerciseCardTitle,
    ActivityExerciseCardTitleWrapper,
    ActivityScoreLabel,
    ActivityScoreLabelWrapper,
    ActivityScoreWrapper,
    ActivitySendFileAnswerWrapper,
    // AnswerItemIcon,
    DefaultActivityCard,
    Label,
    ScoreActivityCardWrapper,
    ActivityMobileTitle,
    SkeletonWrapper,
    ActivityDetailsLabel,
    Divider,
    ExerciseListSkeletonWrapper,
    AnswerSheetItemCompositionTitle,
    ActivityAnswerSheetTitle,
    DiscursiveCommentWrapper,
    AnswerSheetItemLabel
} from "./activity-submission-details.styles";

// Assets
import board from "assets/img/board.svg";
// import plus from "assets/img/plus.svg";
import comment from "assets/img/comment.svg";
import stats from "assets/img/stats.svg";
import arrowLeft from "assets/img/arrow-left.svg";
import discipline from "assets/img/discipline.svg";
import attachment from "assets/img/attachment.svg";

// Utils
import { ternary } from "utils/ternary";
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";
import { ValidationSchema, validationSchema } from "./activity-submission-details.schema";
import { getQuestionResultData, getExerciseListAnswerCardData } from "./activity-submission-details.helpers";

// Types
import { ActivityType } from "store/interfaces/activity";

type ActivitySubmissionDetailsParams = {
    activityId: string;
    activityType: string;
    submissionId: string;
};

export const ActivitySubmissionDetails: FunctionComponent = () => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();

    const { activityId, activityType, submissionId } = useParams<ActivitySubmissionDetailsParams>();

    const type = ternary([
        [activityType === "discursiva", "discursive"],
        [activityType === "lista-de-exercicios", "exercise-list"],
        [activityType === "envio-de-arquivo", "send-file"],
        [true, "exercise-list"]
    ]) as ActivityType;

    const { details, submission, isTeacherCorrecting } = useSelector(({ activity }: IReduxStore) => activity.activitySubmission);
    const { courseSlug, isTeacher } = useSelector(({ course, credentials }: IReduxStore) => ({
        courseSlug: course.slug,
        isTeacher: !!credentials.teacher?.id
    }));

    const { control, watch, formState, trigger, clearErrors, setError, setValue } = useForm<ValidationSchema>({
        resolver: yupResolver(validationSchema)
    });

    const teacherScore = watch("score");
    const teacherComment = watch("comment");

    useEffect(() => {
        if (!!submission.data?.score && !teacherScore) {
            setValue("score", submission.data?.score);
        }

        if (!!submission.data?.teacherComment && !teacherComment) {
            setValue("comment", submission.data?.teacherComment);
        }
    }, [setValue, submission.data, teacherComment, teacherScore]);

    useEffect(() => {
        if (activityId) {
            dispatch(activityActions.getActivitySubmissionDetailsRequest(activityId));
            dispatch(activityActions.getActivitySubmissionSubmissionRequest({ activityId, submissionId }));
        }
    }, [activityId, dispatch, submissionId]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearActivitySubmission());
        };
    }, [dispatch]);

    const handleSubmitTeacherCorrection = () => {
        if (!!details.data?.score && !teacherScore) {
            trigger();
            return;
        }

        if (!!teacherScore && !!details.data?.score && (teacherScore > details.data.score || teacherScore < 0)) {
            setError("score", {
                message: "Pontuação inválida"
            });
            return;
        }

        dispatch(
            activityActions.createActivitySubmissionTeacherCorrectionRequest({
                score: teacherScore,
                comment: teacherComment,
                activityId,
                submissionId
            })
        );
    };

    const handleNavigate = (type: "activities" | "activity-submissions") => {
        if (type === "activities") {
            history.push({ pathname: `/app/curso/${courseSlug}/atividades` });
            return;
        }

        history.push({ pathname: `/app/curso/${courseSlug}/atividades/${activityId}/entregas` });
    };

    const handleDownloadMaterials = () => {
        details.data?.supportMaterials?.forEach((material) => {
            openLinkInBlankPage(material.source);
        });
    };

    const enunciationSkeleton = (
        <SkeletonWrapper>
            <Skeleton height="26px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="26px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="26px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="26px" width="264px" borderRadius="4px" />

            <br />
            <br />

            <Skeleton height="26px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="26px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="26px" width="264px" borderRadius="4px" />
        </SkeletonWrapper>
    );

    const scoreSkeleton = (
        <Fragment>
            <ScreenWidthRender
                renderingWidth={768}
                haveFullyAdaptativeWidth
                actionAfterRenderingWidth="hide"
                content={<Skeleton height="40px" borderRadius="4px" />}
            />

            <ScreenWidthRender
                renderingWidth={768}
                actionAfterRenderingWidth="show"
                content={
                    <ScreenWidthRender
                        renderingWidth={1366}
                        actionAfterRenderingWidth="hide"
                        content={<Skeleton width="320px" height="40px" borderRadius="4px" />}
                    />
                }
            />

            <ScreenWidthRender
                renderingWidth={1366}
                actionAfterRenderingWidth="show"
                content={<Skeleton width="140px" height="40px" borderRadius="4px" />}
            />
        </Fragment>
    );

    const discursiveComposeSkeleton = (
        <SkeletonWrapper>
            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" width="264px" borderRadius="4px" />

            <br />
            <br />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" borderRadius="4px" />

            <Spacing direction="vertical" size={2} />

            <Skeleton height="22px" width="264px" borderRadius="4px" />
        </SkeletonWrapper>
    );

    const studentComment = (
        <ConditionallyRender
            shouldRender={!!submission.isLoading || !!submission.data?.studentComment}
            content={
                <Fragment>
                    <ActivityExerciseCardTitleWrapper>
                        <ActivityExerciseCardTitle>Comentário</ActivityExerciseCardTitle>
                    </ActivityExerciseCardTitleWrapper>

                    <ConditionallyRender
                        shouldRender={!!submission.isLoading}
                        content={
                            <SkeletonWrapper>
                                <Skeleton height="22px" borderRadius="4px" />

                                <Spacing direction="vertical" size={2} />

                                <Skeleton height="22px" borderRadius="4px" />

                                <Spacing direction="vertical" size={2} />

                                <Skeleton height="22px" width="264px" borderRadius="4px" />
                            </SkeletonWrapper>
                        }
                    />

                    <ConditionallyRender
                        shouldRender={!submission.isLoading && !!submission.data?.studentComment}
                        content={<ActivityExerciseCardLabel dangerouslySetInnerHTML={{ __html: submission.data?.studentComment ?? "" }} />}
                    />
                </Fragment>
            }
        />
    );

    const exerciseListAnswerComposition = (
        <Fragment>
            <ConditionallyRender
                shouldRender={!isTeacher}
                content={
                    <ActivityAnswers
                        icon={board}
                        title="Gabarito"
                        answerItemCompositions={
                            <Fragment>
                                <ConditionallyRender
                                    shouldRender={!!submission.isLoading}
                                    content={Array.from({ length: 10 }).map((_, index) => (
                                        <Fragment key={`activity-answer-item-skeleton-${index}`}>
                                            <ExerciseListSkeletonWrapper>
                                                <Skeleton height="24px" width="24px" borderRadius="12px" />

                                                <Skeleton height="20px" width="264px" borderRadius="4px" />
                                            </ExerciseListSkeletonWrapper>

                                            <ConditionallyRender shouldRender={index < 9} content={<Divider />} />
                                        </Fragment>
                                    ))}
                                />

                                <ConditionallyRender
                                    shouldRender={!submission.isLoading}
                                    content={submission.data?.answerCard?.questions.map((question, index) => {
                                        const { questionResultLabel, questionResultVariant } = getQuestionResultData(
                                            question,
                                            isTeacher,
                                            submission.data?.status === "Finished"
                                        );

                                        return (
                                            <Fragment key={`exercise-list-question-${index}`}>
                                                <ConditionallyRender shouldRender={index !== 0} content={<Divider />} />
                                                <ActivityAnswerItem
                                                    indicator={`${index + 1}`}
                                                    label={questionResultLabel ?? ""}
                                                    variant={questionResultVariant as any}
                                                    // actionButtonComposition={<AnswerItemIcon src={plus} onClick={() => console.log("click")} />}
                                                />
                                            </Fragment>
                                        );
                                    })}
                                />
                            </Fragment>
                        }
                    />
                }
            />

            <ConditionallyRender
                shouldRender={!!isTeacher}
                content={
                    <Fragment>
                        <ConditionallyRender
                            shouldRender={!!submission.isLoading}
                            content={Array.from({ length: 10 }).map((_, index) => (
                                <ActivityAnswerSheetItem
                                    key={`exercise-list-question-skeleton-${index}`}
                                    indicator={`${index + 1}`}
                                    statementElement={<Skeleton height="20px" borderRadius="4px" />}
                                    firstAnswerLabel="Resposta:"
                                    firstAnswerIndicatorElement={<Skeleton height="24px" width="24px" borderRadius="12px" />}
                                    secondAnswerLabel="Respondeu:"
                                    secondAnswerIndicatorElement={<Skeleton height="24px" width="24px" borderRadius="12px" />}
                                />
                            ))}
                        />

                        <ConditionallyRender
                            shouldRender={!submission.isLoading}
                            content={submission.data?.answerCard?.questions.map((question, index) => {
                                const { questionResultVariant } = getQuestionResultData(question, isTeacher, submission.data?.status === "Finished");

                                return (
                                    <ActivityAnswerSheetItem
                                        key={`exercise-list-question-${index}`}
                                        indicator={`${index + 1}`}
                                        statementElement={<AnswerSheetItemLabel dangerouslySetInnerHTML={{ __html: question.enunciation ?? "" }} />}
                                        firstAnswerLabel="Resposta:"
                                        firstAnswerIndicatorElement={<ActivityQuestionIndicator text={question.correctionOrder} />}
                                        secondAnswerLabel="Respondeu:"
                                        secondAnswerIndicatorElement={
                                            <ActivityQuestionIndicator text={question.answerOrder ?? "-"} variant={questionResultVariant as any} />
                                        }
                                    />
                                );
                            })}
                        />

                        <ActivityExerciseCardSeparator />

                        {studentComment}
                    </Fragment>
                }
            />
        </Fragment>
    );

    const discursiveAnswerComposition = (
        <Fragment>
            <ConditionallyRender
                shouldRender={!isTeacher}
                content={
                    <DefaultActivityCard>
                        <ActivityTitle title="Resposta enviada" icon={discipline} />

                        <Fragment>
                            <ConditionallyRender shouldRender={!!submission.isLoading} content={discursiveComposeSkeleton} />

                            <ConditionallyRender
                                shouldRender={!submission.isLoading}
                                content={<ActivityExerciseCardLabel dangerouslySetInnerHTML={{ __html: submission.data?.compose ?? "" }} />}
                            />
                        </Fragment>

                        <ActivityExerciseCardSeparator />

                        {studentComment}
                    </DefaultActivityCard>
                }
            />

            <ConditionallyRender
                shouldRender={!!isTeacher}
                content={
                    <Fragment>
                        <ConditionallyRender shouldRender={!!submission.isLoading} content={discursiveComposeSkeleton} />

                        <ConditionallyRender
                            shouldRender={!submission.isLoading}
                            content={<ActivityDetailsLabel dangerouslySetInnerHTML={{ __html: submission.data?.compose ?? "" }} />}
                        />

                        <ActivityExerciseCardSeparator />

                        {studentComment}
                    </Fragment>
                }
            />
        </Fragment>
    );

    const sendFileAnswerMainContent = (
        <Fragment>
            <ActivityAnswerFilesWrapper>
                <ConditionallyRender
                    shouldRender={!!submission.isLoading}
                    content={Array.from({ length: 3 }).map((_, index) => (
                        <Fragment key={`activity-answer-file-skeleton-${index}`}>
                            <Skeleton height="54px" borderRadius="8px" />
                        </Fragment>
                    ))}
                />

                <ConditionallyRender
                    shouldRender={!submission.isLoading}
                    content={submission.data?.files?.map((file) => (
                        <ActivityFile
                            key={file.id}
                            label={file.name}
                            handleClick={() => openLinkInBlankPage(file.source)}
                            progressBarElement={<ActivityProgressBar progressColor="#1BA660" progress={100} />}
                        />
                    ))}
                />
            </ActivityAnswerFilesWrapper>

            <ActivityExerciseCardSeparator />

            {studentComment}
        </Fragment>
    );

    const sendFileAnswerComposition = (
        <Fragment>
            <ConditionallyRender
                shouldRender={!isTeacher}
                content={
                    <DefaultActivityCard>
                        <ActivitySendFileAnswerWrapper>
                            <ActivityTitle title="Resposta enviada" icon={attachment} />

                            {sendFileAnswerMainContent}
                        </ActivitySendFileAnswerWrapper>
                    </DefaultActivityCard>
                }
            />

            <ConditionallyRender shouldRender={!!isTeacher} content={sendFileAnswerMainContent} />
        </Fragment>
    );

    const answerComposition = ternary([
        [type === "discursive", discursiveAnswerComposition],
        [type === "send-file", sendFileAnswerComposition],
        [type === "exercise-list" && (!!details.data?.reference || details.isLoading), exerciseListAnswerComposition]
    ]);

    const exerciseListAnswerCardData = useMemo(() => getExerciseListAnswerCardData(submission.data), [submission.data]);

    return (
        <ActivityExerciseLayout
            activityType={type}
            breadcrumbsComposition={
                <Fragment>
                    <ScreenWidthRender
                        actionAfterRenderingWidth="hide"
                        renderingWidth={1024}
                        content={
                            <ActivityBreadcrumbs
                                breadcrumbItemCompositions={[
                                    <ActivityBreadcrumbItem
                                        key={1}
                                        label="Voltar"
                                        icon={arrowLeft}
                                        size="small"
                                        isActive={false}
                                        handleClick={() => handleNavigate(isTeacher ? "activity-submissions" : "activities")}
                                    />
                                ]}
                            />
                        }
                    />

                    <ScreenWidthRender
                        actionAfterRenderingWidth="show"
                        renderingWidth={1024}
                        content={
                            <ActivityBreadcrumbs
                                breadcrumbItemCompositions={[
                                    <ActivityBreadcrumbItem
                                        key={1}
                                        label="Atividades"
                                        size="small"
                                        isActive={false}
                                        handleClick={() => handleNavigate("activities")}
                                    />,
                                    !details.isLoading ? (
                                        <ActivityBreadcrumbItem
                                            key={2}
                                            label={details.data?.title ?? ""}
                                            size={!isTeacher ? "large" : "small"}
                                            isActive={!isTeacher}
                                            {...(isTeacher && { handleClick: () => handleNavigate("activity-submissions") })}
                                        />
                                    ) : (
                                        <Skeleton height={!isTeacher ? "28px" : "20px"} width="280px" borderRadius="4px" />
                                    ),
                                    isTeacher ? (
                                        !submission.isLoading ? (
                                            <ActivityBreadcrumbItem key={3} label={submission.data?.user.name ?? ""} size="large" isActive={true} />
                                        ) : (
                                            <Skeleton height="28px" width="80px" borderRadius="4px" />
                                        )
                                    ) : (
                                        undefined
                                    )
                                ]}
                            />
                        }
                    />
                </Fragment>
            }
            statementComposition={
                <Fragment>
                    <ConditionallyRender
                        shouldRender={isTeacher}
                        content={
                            <Fragment>
                                <ActivityAnswerSheet
                                    titleElement={
                                        <Fragment>
                                            <ConditionallyRender shouldRender={!!details.isLoading} content={enunciationSkeleton} />

                                            <ConditionallyRender
                                                shouldRender={!details.isLoading}
                                                content={
                                                    <ActivityAnswerSheetTitle
                                                        dangerouslySetInnerHTML={{
                                                            __html: details.data?.enunciation?.split("\n").join("<br/>") ?? ""
                                                        }}
                                                    />
                                                }
                                            />
                                        </Fragment>
                                    }
                                    tableTitleElement={
                                        <AnswerSheetItemCompositionTitle>
                                            {type === "exercise-list" ? "Gabarito" : "Resposta"}
                                        </AnswerSheetItemCompositionTitle>
                                    }
                                    answerItemCompositions={answerComposition}
                                />
                            </Fragment>
                        }
                    />

                    <ConditionallyRender
                        shouldRender={!isTeacher}
                        content={
                            <DefaultActivityCard>
                                <ScreenWidthRender
                                    actionAfterRenderingWidth="hide"
                                    renderingWidth={1024}
                                    content={
                                        <Fragment>
                                            <ConditionallyRender
                                                shouldRender={!!details.isLoading}
                                                content={<Skeleton height="26px" width="280px" borderRadius="4px" />}
                                            />

                                            <ConditionallyRender
                                                shouldRender={!details.isLoading}
                                                content={<ActivityMobileTitle>{details.data?.title}</ActivityMobileTitle>}
                                            />
                                        </Fragment>
                                    }
                                />

                                <ConditionallyRender shouldRender={!!details.isLoading} content={enunciationSkeleton} />

                                <ConditionallyRender
                                    shouldRender={!details.isLoading}
                                    content={
                                        <Label dangerouslySetInnerHTML={{ __html: details.data?.enunciation?.split("\n").join("<br/>") ?? "" }} />
                                    }
                                />
                            </DefaultActivityCard>
                        }
                    />
                </Fragment>
            }
            {...((!!details.isLoading || !!details.data?.score) && {
                scoreComposition: (
                    <ScoreActivityCardWrapper>
                        <ActivityScoreWrapper>
                            <ActivityScoreLabelWrapper>
                                <ActivityScoreLabel>Pontuação máxima:</ActivityScoreLabel>

                                <ConditionallyRender shouldRender={!!details.isLoading} content={scoreSkeleton} />

                                <ConditionallyRender
                                    shouldRender={!details.isLoading}
                                    content={<ActivityScoreBadge pointsLabel={!!details.data?.score ? `${details.data?.score} pontos` : "-"} />}
                                />
                            </ActivityScoreLabelWrapper>

                            <ActivityScoreLabelWrapper>
                                <ActivityScoreLabel>{!isTeacher ? "Minha pontuação:" : "Pontuação:"}</ActivityScoreLabel>

                                <ConditionallyRender shouldRender={!!submission.isLoading} content={scoreSkeleton} />

                                <ConditionallyRender
                                    shouldRender={!submission.isLoading && !!submission.data?.score && !isTeacher}
                                    content={<ActivityScore pointsLabel={`${submission.data?.score} pontos`} />}
                                />

                                <ConditionallyRender
                                    shouldRender={
                                        !submission.isLoading &&
                                        !submission.data?.score &&
                                        (!isTeacher || (isTeacher && !["Finished", "WaitingCorrection"].includes(submission.data?.status ?? "")))
                                    }
                                    content={<ActivityScore />}
                                />

                                <ConditionallyRender
                                    shouldRender={
                                        !submission.isLoading &&
                                        isTeacher &&
                                        ["Finished", "WaitingCorrection"].includes(submission.data?.status ?? "")
                                    }
                                    content={
                                        <Controller
                                            name="score"
                                            control={control}
                                            render={({ onChange }) => (
                                                <ActivityInput
                                                    type="number"
                                                    defaultValue={submission.data?.score}
                                                    onChange={(event) => {
                                                        onChange(!!event.target.value.length ? Number(event.target.value) : undefined);
                                                        clearErrors();
                                                    }}
                                                    errorMessage={formState.errors.score?.message}
                                                />
                                            )}
                                        />
                                    }
                                />
                            </ActivityScoreLabelWrapper>
                        </ActivityScoreWrapper>

                        <ConditionallyRender shouldRender={!!details.isLoading} content={<Skeleton height="40px" borderRadius="4px" />} />

                        <ConditionallyRender
                            shouldRender={!details.isLoading && !!details.data?.supportMaterials?.length}
                            content={
                                <ActivityButton
                                    isFullyAdaptative
                                    size="large"
                                    label="Baixar material de apoio"
                                    variantType="secondary"
                                    onClick={handleDownloadMaterials}
                                />
                            }
                        />
                    </ScoreActivityCardWrapper>
                )
            })}
            shouldDisplayActivityCommentSection={!!submission.isLoading || !!submission.data?.teacherComment}
            activityCommentComposition={
                <ConditionallyRender
                    shouldRender={!!submission.isLoading || !!submission.data?.teacherComment}
                    content={
                        <DefaultActivityCard>
                            <ActivityExpandableCard
                                isLoading={!!submission.isLoading}
                                title="Comentário da atividade"
                                titleIcon={comment}
                                content={submission.data?.teacherComment ?? ""}
                                characterLimit={340}
                            />
                        </DefaultActivityCard>
                    }
                />
            }
            {...(!isTeacher && { answersComposition: answerComposition })}
            {...(!!isTeacher &&
                ["Finished", "WaitingCorrection"].includes(submission.data?.status ?? "") && {
                    teacherCommentComposition: (
                        <DiscursiveCommentWrapper>
                            <Controller
                                name="comment"
                                control={control}
                                render={({ onChange }) => (
                                    <ActivityTextarea
                                        defaultValue={submission.data?.teacherComment}
                                        onChange={(event) => onChange(event.target.value)}
                                        placeholder="Escreva seu comentário aqui"
                                    />
                                )}
                            />
                        </DiscursiveCommentWrapper>
                    ),
                    footerButtonsCompositions: (
                        <ActivityButton
                            label="Enviar correção"
                            isLoading={isTeacherCorrecting}
                            size="large"
                            variantType={type === "exercise-list" ? "secondary" : "primary"}
                            onClick={handleSubmitTeacherCorrection}
                            {...(width < 1024 && {
                                isFullyAdaptative: true
                            })}
                        />
                    )
                })}
            {...(!isTeacher &&
                type === "exercise-list" &&
                submission.data?.status === "Finished" && {
                    footerButtonsCompositions: (
                        <ActivityButton
                            label="Rever todas as questões"
                            size="large"
                            onClick={() => {
                                history.push({
                                    pathname: `/app/curso/${courseSlug}/atividades/lista-de-exercicios/${activityId}/${details.data?.submission?.id}/execucao/${details.data?.reference}/${submission.data?.reference}`
                                });
                            }}
                            {...(width < 1024 && {
                                isFullyAdaptative: true
                            })}
                        />
                    )
                })}
            {...(type === "exercise-list" &&
                (!!details.data?.reference || details.isLoading || submission.isLoading) && {
                    statisticsComposition: (
                        <DefaultActivityCard>
                            <ActivityResultPerformanceCard
                                titleIcon={stats}
                                title="Estatísticas"
                                executionTimeElement={
                                    <Fragment>
                                        <ConditionallyRender
                                            shouldRender={!!submission.isLoading}
                                            content={<Skeleton width="64px" height="20px" borderRadius="4px" />}
                                        />

                                        <ConditionallyRender
                                            shouldRender={!submission.isLoading}
                                            content={<ActivityDetailsLabel>{exerciseListAnswerCardData.executionTime}</ActivityDetailsLabel>}
                                        />
                                    </Fragment>
                                }
                                executionTimeLabel="Tempo de execução"
                                {...((!!isTeacher || submission.data?.status === "Finished" || submission.isLoading) && {
                                    correctAnswerElement: (
                                        <Fragment>
                                            <ConditionallyRender
                                                shouldRender={!!submission.isLoading}
                                                content={<Skeleton width="20px" height="20px" borderRadius="4px" />}
                                            />

                                            <ConditionallyRender
                                                shouldRender={!submission.isLoading}
                                                content={<ActivityDetailsLabel>{exerciseListAnswerCardData.correctAnswers}</ActivityDetailsLabel>}
                                            />
                                        </Fragment>
                                    ),
                                    correctAnswerLabel: "Respostas certas",
                                    wrongAnswerElement: (
                                        <Fragment>
                                            <ConditionallyRender
                                                shouldRender={!!submission.isLoading}
                                                content={<Skeleton width="20px" height="20px" borderRadius="4px" />}
                                            />

                                            <ConditionallyRender
                                                shouldRender={!submission.isLoading}
                                                content={<ActivityDetailsLabel>{exerciseListAnswerCardData.wrongAnswers}</ActivityDetailsLabel>}
                                            />
                                        </Fragment>
                                    ),
                                    wrongAnswerLabel: "Respostas erradas",
                                    chartElement: (
                                        <Fragment>
                                            <ConditionallyRender
                                                shouldRender={!!submission.isLoading}
                                                content={<Skeleton width="160px" height="160px" borderRadius="80px" shouldForceWidth />}
                                            />

                                            <ConditionallyRender
                                                shouldRender={!submission.isLoading}
                                                content={
                                                    <ActivityPerformanceChart
                                                        chartSlices={[
                                                            ...((exerciseListAnswerCardData.correctAnswers ?? 0) > 0
                                                                ? [
                                                                      {
                                                                          label: "acertos",
                                                                          value: exerciseListAnswerCardData.correctAnswers ?? 0,
                                                                          color: "#1BA660"
                                                                      }
                                                                  ]
                                                                : []),
                                                            ...((exerciseListAnswerCardData.wrongAnswers ?? 0) > 0
                                                                ? [
                                                                      {
                                                                          label: "erros",
                                                                          value: exerciseListAnswerCardData.wrongAnswers ?? 0,
                                                                          color: "#F25555"
                                                                      }
                                                                  ]
                                                                : [])
                                                        ]}
                                                    />
                                                }
                                            />
                                        </Fragment>
                                    )
                                })}
                            />
                        </DefaultActivityCard>
                    )
                })}
        />
    );
};
