import { theme } from "config/theme";
import styled from "styled-components";

export const WatchScreenRelatedContentTitle = styled.strong`
    display: block;
    margin-bottom: ${theme.spacing.small};

    font-weight: 600;
    font-size: ${theme.typography.sizes.mediumX};
    color: ${theme.colors.base[500]};
`;

export const WatchScreenRelatedContentContainer = styled.div``;
