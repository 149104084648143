// Dependencies
import styled, { keyframes } from "styled-components";

const transitionAnimation = keyframes`
   from {
        transform: translateY(50%);
    }
    to {
        transform: translateY(0);
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    width: 100%;
    padding: 16px;

    background-color: #fff;
    border: 1px solid #ced0dd;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 4;

    animation-name: ${transitionAnimation};
    animation-duration: 0.5s;
`;

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    background-color: #25272d;
    opacity: 0.5;

    z-index: 3;
`;
