// Dependencies
import React from "react";

// Redux
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

// Components
import { Loader } from "component-library/elements/loader";

// Pages
import ActivityStudentDashboard from "screens/activity-student-dashboard";
import ActivityTeacherDashboard from "screens/activity-teacher-dashboard";

// Utils
import { ternary } from "utils/ternary";

export const ActivityRedirector = () => {
    const { isTeacher } = useSelector(({ credentials }: IReduxStore) => ({
        isTeacher: !!credentials.teacher?.id
    }));

    const ComponentToDisplay = ternary([
        [isTeacher, ActivityTeacherDashboard],
        [!isTeacher, ActivityStudentDashboard],
        [true, ActivityStudentDashboard]
    ]) as any;

    if (!ComponentToDisplay) {
        return <Loader />;
    }

    return <ComponentToDisplay />;
};
