import { theme } from "config/theme";
import React, { memo, useMemo } from "react";

interface IDifficultyProps {
    // level: number;
    level: any;
}

const fills = new Map<number, string[]>([
    [1, ["M0.741211 12.7163H9.58043V23.2041H0.741211V12.7163Z", "M0.741211 12.7163H9.58043V18.7093H0.741211V12.7163Z"]],
    [
        2,
        [
            "M9.58043 6.72337H18.4196V23.2041H9.58043V6.72337ZM0.741211 12.7164H9.58043V23.2041H0.741211V12.7164Z",
            "M0.741211 12.7164H9.58043V18.7093H0.741211V12.7164ZM9.58043 6.72337H18.4196V12.7164H9.58043V6.72337Z"
        ]
    ],
    [
        3,
        [
            "M18.42.73h8.839v22.474h-8.84V.73zM9.58 6.723h8.84v16.481H9.58V6.724zM.741 12.716h8.84v10.488H.74V12.716z",
            "M.741 12.716h8.84v5.993H.74v-5.993zm8.84-5.993h8.839v5.993H9.58V6.723zM18.42.73h8.839v5.993h-8.84V.73z"
        ]
    ]
]);

const Difficulty = ({ level }: IDifficultyProps) => {
    const Paths = useMemo(() => {
        const path = fills.get(level);

        if (!path) {
            return null;
        }

        return (
            <>
                <path d={path[0]} fill={theme.colors.system.success[300]} />
                <path opacity="0.25" d={path[1]} fill={theme.colors.white} />
            </>
        );
    }, [level]);

    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 24" className="ico-difficulty">
            {Paths}
            <path
                d="M28 22.502L28.01.787c0-.412-.266-.759-.678-.787-.357 0-8.903.028-8.903.028a.75.75 0 00-.741.75V6.02H9.58a.75.75 0 00-.74.75v5.243H.74a.75.75 0 00-.741.75V23.25c0 .412.33.749.741.749h26.573c.76 0 .695-.796.695-.796L28 22.502zm-19.152-.047H1.473v-8.99H8.84v8.99h.01zm8.84 0h-7.366V7.472h7.366v14.983zm8.839 0H19.16V1.48h7.366v20.975z"
                fill="#2E4369"
            />
        </svg>
    );
};

export default memo(Difficulty);
