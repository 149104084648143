// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityOverlayPosition } from "./activity-overlay.types";

type ContainerStyleProps = {
    position: ActivityOverlayPosition;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 35, 38, 0.4);
    
    ${({ position }) =>
        position === "center" &&
        css`
            justify-content: center;
        `}

    ${({ position }) =>
        position === "left" &&
        css`
            justify-content: flex-start;
        `}
    
    ${({ position }) =>
        position === "right" &&
        css`
            justify-content: flex-end;
        `}
`;
