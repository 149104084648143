import React, { memo, useMemo } from "react";

// Dependencies
import { Link } from "react-router-dom";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// Components
import BackTo from "components/BackTo";
import BreadCrumb from "components/BreadCrumb";

// Helpers
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

// Assets
import { IPageHeaderProps } from "./index";
import { Col, Grid, Row } from "components/Grid";
import { PageHeaderContainer, PageHeaderWrapper, PageHeaderTitle } from "./styles";

const PageHeaderSimple = ({ breadcrumb, right }: IPageHeaderProps) => {
    const isMobile = useIsMobile(BreakPoints.tablet);

    const BackButton = useMemo(() => {
        const last = breadcrumb.parent[breadcrumb.parent.length - 1];

        return <BackTo to={last.url}>{last.label}</BackTo>;
    }, [breadcrumb.parent]);

    return (
        <PageHeaderContainer>
            <Grid fluid>
                <Row>
                    <Col xs={12} sm={right ? 6 : 12}>
                        {isMobile ? (
                            BackButton
                        ) : (
                            <>
                                <BreadCrumb>
                                    {breadcrumb.parent.map((item, index) => {
                                        const isNotLastItem = breadcrumb.parent.length - 1 !== index;

                                        return (
                                            <React.Fragment key={index}>
                                                <BreadCrumb.Item>
                                                    <Link to={item.url}>{item.label}</Link>
                                                </BreadCrumb.Item>
                                                {isNotLastItem && (
                                                    <BreadCrumb.Separator>
                                                        <ChevronRightIcon width="5" height="10" />
                                                    </BreadCrumb.Separator>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </BreadCrumb>
                                <PageHeaderWrapper>
                                    <PageHeaderTitle>{breadcrumb.current.label}</PageHeaderTitle>
                                </PageHeaderWrapper>
                            </>
                        )}
                    </Col>
                    {right && (
                        <Col xs={12} sm={6}>
                            {right}
                        </Col>
                    )}
                </Row>
            </Grid>
        </PageHeaderContainer>
    );
};

export default memo(PageHeaderSimple);
