import styled from "styled-components";
import { theme } from "config/theme";
import { AccordionButton, AccordionItem, AccordionChevron } from "components/Accordion/styles";
import { MediaQueries, MediaQueriesManual } from "assets/styles/settings";

export const PreviousExamDetailsExamDayContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 8px;
    padding: 16px 24px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        border-top: unset;
    }
`;

export const PreviosExamTexts = styled.div`
    flex: 1;

    display: flex;
    flex-direction: column;
`;

export const PreviousExamDetailsExamDayHeader = styled.header`
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    margin-bottom: ${theme.spacing.small};
`;

export const PreviousExamDetailsExamDayHeaderTitle = styled.p`
    color: ${theme.colors.brand[300]};
    font-size: ${theme.typography.sizes.small};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
    }
`;

export const PreviousExamDetailsExamDayHeaderSubTitle = styled.p`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: 14px;
    }
`;

export const PreviousExamDetailsExamDayKnowledgeAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
    flex: 1;
`;

export const PreviousExamDetailsExamDayKnowledgeArea = styled.p`
    font-size: 14px;
    color: ${theme.colors.base[400]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const PreviousExamDetailsExamDayOptionsContainer = styled.div`
    margin-bottom: ${theme.spacing.small};

    ${AccordionButton} {
        background: ${theme.colors.white};
        padding: ${theme.spacing.smallX} ${theme.spacing.small};
        border-radius: 8px;
        color: ${theme.colors.base[400]};
    }

    ${AccordionItem} {
        background: ${theme.colors.white};
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    ${AccordionChevron} {
        color: ${theme.colors.base[400]};
    }
`;

export const PreviousExamDetailsExamDayOptionWrapp = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PreviousExamDetailsExamDayOptionButton = styled.button`
    background: transparent;
    border: none;
    text-align: left;
    display: flex;
    align-items: center;
    padding: ${theme.spacing.smallX} ${theme.spacing.smallX};
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.base[400]};

    svg {
        margin-right: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.smallX} 0;

        :not(:last-child) {
            border-bottom: 1px solid ${theme.colors.base[100]};
        }
    }
`;

export const ContentButtons = styled.div`
    gap: 8px;
    display: flex;
    flex-wrap: wrap;

    ${MediaQueriesManual(400)} {
        flex-wrap: nowrap;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        flex-wrap: wrap;
    }

    ${MediaQueries.DESKTOP} {
        flex-wrap: nowrap;
    }
`;
