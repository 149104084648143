import { theme } from "../../../config/theme";
import { DropdownFilterControls } from "../../../enums/DropdownFilterControls";
import {
    ContractApi,
    contractBuildObject,
    CustomListFilterApiData,
    CustomListFilterCheckbox,
    CustomListFilterFormData,
    IExerciseListCustomApi,
    IExerciseListCustomState,
    IQuestionReportCard,
    IWebpChart
} from "../../../interfaces/IExerciseListCustom";
import { dropdownFilterHasCheckedOptions } from "../../../screens/ExerciseListCustom/QuestionsFilter/helpers";

export function getCustomListRequestEndpoint(title: string, order: number, page: number, perPage: number) {
    if (!!title) return `questionengine/exerciselist?order=${order}&page=${1}&perPage=${100}&title=${title}`;

    return `questionengine/exerciselist?order=${order}&page=${page}&perPage=${perPage}&title=${title}`;
}

export function transformDataToFilterOptions(data: CustomListFilterApiData[], selectedOptions?: number[]): any {
    const newOptions = data.map((item) => {
        if (item.options && item.options.length > 0) {
            const itemOptions = transformDataToFilterOptions(item.options, selectedOptions);
            const newItem = {
                id: item.id,
                type: DropdownFilterControls.list,
                label: item.name,
                options: itemOptions.options
            };
            return newItem;
        }

        return { id: item.id, label: item.name, type: DropdownFilterControls.checkbox, checked: selectedOptions?.includes(item.id) };
    });

    const finalData = {
        type: DropdownFilterControls.list,
        options: newOptions
    };

    return finalData;
}

export function transformYearDataToFilterOptions(data: any[], selectedYears: any[]): any {
    const newOptions = data.map((item) => {
        if (item.options && item.options.length > 0) {
            const itemOptions = transformYearDataToFilterOptions(item.options, selectedYears);
            const newItem = {
                id: item.id,
                type: DropdownFilterControls.list,
                label: item.name,
                options: itemOptions.options
            };

            return newItem;
        }

        // This ID format is required by the backend so that we can query for contest phase
        const newId = `${item.name}-${item.idContest}`;

        return { id: newId, label: `${item.name}`, type: DropdownFilterControls.checkbox, checked: selectedYears.includes(newId) };
    });

    const finalData = {
        type: DropdownFilterControls.list,
        options: newOptions
    };

    return finalData;
}

export function transformPhaseDataToFilterOptions(data: any[], selectedPhases: any[]): any {
    const newOptions = data.map((item) => {
        if (item.options && item.options.length > 0) {
            const itemOptions = transformPhaseDataToFilterOptions(item.options, selectedPhases);
            const newItem = {
                id: item.idContest ? `${item.name}-${item.idContest}` : item.id,
                type: DropdownFilterControls.list,
                label: item.name,
                options: itemOptions.options
            };

            return newItem;
        }

        // apiId is the id that must be sent in the savelist request
        return {
            id: item.relationId,
            label: item.name,
            type: DropdownFilterControls.checkbox,
            checked: selectedPhases.includes(item.relationId),
            apiId: item.id
        };
    });

    const finalData = {
        type: DropdownFilterControls.list,
        options: newOptions
    };

    return finalData;
}

/**
 * Check whole state for checked options and push their IDs to an array.
 */
export function getFilteredOptionsIds(options: CustomListFilterCheckbox[]) {
    return options
        .filter((item: CustomListFilterCheckbox) => {
            return item.checked === true;
        })
        .map((item: CustomListFilterCheckbox) => item.id);
}

export function getFilteredPhaseIds(options: CustomListFilterCheckbox[]) {
    return options
        .filter((item: CustomListFilterCheckbox) => {
            return item.checked === true;
        })
        .map((item: any) => item.apiId);
}

export function getFilteredYearsLabels(options: CustomListFilterCheckbox[]) {
    return options
        .filter((item: CustomListFilterCheckbox) => {
            return item.checked === true;
        })
        .map((item: CustomListFilterCheckbox) => item.label);
}

export function buildContractObject(exerciseListCustomState: IExerciseListCustomState) {
    const contract: ContractApi = {};

    const {
        subjects,
        topics,
        subtopics,
        questionType,
        isAuthored,
        contestsWithAuthoredQuestions,
        isFromContest,
        contest,
        contestYear,
        contestPhase,
        questionStatus,
        questionLevel,
        isCancelled,
        isFromAnotherList
    } = exerciseListCustomState;

    const subject: contractBuildObject = {
        key: "subject",
        data: subjects && getFilteredOptionsIds(subjects?.options as CustomListFilterCheckbox[])
    };

    let selectedTopics: any = [];
    if (dropdownFilterHasCheckedOptions(topics)) {
        (topics?.options as CustomListFilterFormData[]).forEach((subjects) => {
            const arrayItem = getFilteredOptionsIds(subjects.options as CustomListFilterCheckbox[]);
            if (arrayItem.length > 0) {
                selectedTopics = [...selectedTopics, ...arrayItem];
            }
        });
    }

    const topic: contractBuildObject = { key: "topic", data: selectedTopics };

    let selectedSubtopic: any = [];
    if (dropdownFilterHasCheckedOptions(subtopics)) {
        subtopics?.options.forEach((subjects) => {
            (subjects as CustomListFilterFormData).options.forEach((item) => {
                const arrayItem = getFilteredOptionsIds((item as CustomListFilterFormData).options as CustomListFilterCheckbox[]);
                if (arrayItem.length > 0) {
                    selectedSubtopic = [...selectedSubtopic, ...arrayItem];
                }
            });
        });
    }
    const subtopic: contractBuildObject = { key: "subtopic", data: selectedSubtopic };

    const type = { key: "type", data: getFilteredOptionsIds(questionType?.options as CustomListFilterCheckbox[]) };

    const origin = { key: "origin", data: [...(isAuthored ? [1, 3] : []), ...(isFromContest ? [2] : [])] };

    const elegibleContest = {
        key: "elegibleContest",
        data: dropdownFilterHasCheckedOptions(contestsWithAuthoredQuestions)
            ? getFilteredOptionsIds(contestsWithAuthoredQuestions?.options as CustomListFilterCheckbox[])
            : []
    };

    const contestIds = {
        key: "contest",
        data: dropdownFilterHasCheckedOptions(contest) ? getFilteredOptionsIds(contest?.options as CustomListFilterCheckbox[]) : []
    };

    let selecterYears: any = [];
    if (dropdownFilterHasCheckedOptions(contestYear)) {
        (contestYear?.options as CustomListFilterFormData[]).forEach((contest) => {
            const arrayItem = getFilteredYearsLabels(contest.options as CustomListFilterCheckbox[]);
            if (arrayItem.length > 0) {
                selecterYears = [...selecterYears, ...arrayItem];
            }
        });
    }

    const year: contractBuildObject = { key: "year", data: selecterYears };

    let selectedPhases: any = [];
    if (dropdownFilterHasCheckedOptions(contestPhase)) {
        contestPhase?.options.forEach((contest) => {
            (contest as CustomListFilterFormData).options.forEach((year) => {
                const arrayItem = getFilteredPhaseIds((year as CustomListFilterFormData).options as CustomListFilterCheckbox[]);
                if (arrayItem.length > 0) {
                    selectedPhases = [...selectedPhases, ...arrayItem];
                }
            });
        });
    }

    const phase: contractBuildObject = { key: "phase", data: selectedPhases };

    const status = { key: "status", data: questionStatus };
    const level = { key: "level", data: questionLevel };
    const lediscardCancelledvel = { key: "lediscardCancelledvel", data: isCancelled };
    const discardFromAnotherLists = { key: "discardFromAnotherLists", data: isFromAnotherList };

    const stateValidator = [
        subject,
        topic,
        subtopic,
        type,
        origin,
        elegibleContest,
        contestIds,
        year,
        phase,
        status,
        level,
        lediscardCancelledvel,
        discardFromAnotherLists
    ];
    stateValidator.forEach((state: contractBuildObject) => {
        if (Array.isArray(state.data) && state.data.length > 0) {
            return (contract[state.key] = state.data);
        }

        if (typeof state.data === "boolean") {
            return (contract[state.key] = state.data);
        }
    });

    return contract;
}

export const buildQueryString = (object: ContractApi) => {
    return Object.keys(object)
        .filter((key: string) => object[key] !== undefined)
        .map((key) => {
            const value = object[key];

            if (typeof value === "string") {
                return value
                    .split(",")
                    .map((item) => `${key}=${item}`)
                    .join("&");
            }

            return `${key}=${value}`;
        })
        .join("&");
};

export const transformDataToCustomListState = (data: IExerciseListCustomApi) => {
    return {
        ...data,
        items: data.items.map((list) => {
            const dateCreated = new Date(list.created);
            const sub = Intl.DateTimeFormat("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }).format(dateCreated);
            const hits = !!list.answerCard?.hits ? list.answerCard?.hits : 0;
            const errors = !!list.answerCard?.errors ? list.answerCard?.errors : 0;
            const emptyAnswers = !!list.answerCard ? list.answerCard?.emptyAnswers : list.questionsTotal;
            const btnLabel = !list.answerCard ? "Fazer lista" : list.answerCard.status === 2 ? "Refazer lista" : "Continuar lista";
            const hitsText = hits === 1 ? "Acerto" : "Acertos";
            const errorsText = errors === 1 ? "Erro" : "Erros";
            const emptyAnswersText = emptyAnswers === 1 ? "Não respondida" : "Não respondidas";

            const answerCardDateStatus = (status?: number, date?: string) => {
                if (!date || !status) return "Não realizada";

                const answerCardDate = new Date(date);

                const stringDate = Intl.DateTimeFormat("pt-BR", { day: "2-digit", month: "2-digit", year: "numeric" }).format(answerCardDate);

                return status === 2 ? `Última realização em ${stringDate}` : `Iniciada em ${stringDate}`;
            };

            const card: IQuestionReportCard = {
                name: list.title,
                listId: list.id,
                answerCardId: list.answerCard?.id,
                subname: `Criada em ${sub}`,
                listCreatedDate: list.created,
                answerCardDate: list.answerCard?.created,
                status: list.answerCard?.status,
                answerCardDateStatus: answerCardDateStatus(list.answerCard?.status, list.answerCard?.created),
                isNew: list.isNew,
                ...(list.isNew && { badgeContent: "novo" }),
                webpButtonFooter: {
                    label: btnLabel,
                    style: {
                        background: "transparent"
                    }
                }
            };

            const chart: IWebpChart = {
                chartConfig: {
                    data: {
                        type: "doughnut",
                        data: {
                            labels: [hitsText, errorsText, emptyAnswersText],
                            datasets: [
                                {
                                    data: [hits, errors, emptyAnswers],
                                    backgroundColor: [theme.colors.system.success[200], theme.colors.system.danger[200], theme.colors.base[200]]
                                }
                            ]
                        },
                        options: {
                            cutout: 38,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: {
                                    enabled: false
                                }
                            }
                        }
                    },
                    label: list.questionsTotal === 1 ? "questão" : "questões",
                    value: list.questionsTotal
                }
            };

            return { card, chart };
        })
    };
};
