import { bounceInRight } from "assets/styles/animations";
import { theme } from "config/theme";
import styled from "styled-components";

export const AnnouncementToastContainer = styled.div`
    position: fixed;
    bottom: 20px;
    right: 30px;
`;

export const AnnouncementToastCard = styled.div`
    width: 270px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    margin: 10px 0;
    animation: ${bounceInRight} 0.4s linear;
    cursor: pointer;
`;

export const AnnouncementToastCardHeader = styled.div`
    padding: 7px 10px;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    span {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.colors.base[200]};
    }
`;

export const AnnouncementToastCardContent = styled.div`
    padding: 7px 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    font-weight: 300;
    font-size: ${theme.typography.sizes.medium};
`;

export const AnnouncementToastClose = styled.button``;
