import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { Button2, Button3, Label, Micro } from "assets/styles/global";
import { DropdownContainer, DropdownContent, DropdownTextContainer, DropdownButton } from "components/Dropdown/styles";
import { hexToRgba } from "helpers";

export const EssayCompetencesContainer = styled.div``;

export const EssayCompetencesTitle = styled.strong`
    ${Label};
    color: ${theme.colors.base[500]};
    display: block;
    margin-bottom: ${theme.spacing.small};
`;

export const EssayCompetencesList = styled.ul``;

export const EssayCompetenceContainer = styled.li`
    ${DropdownContainer} {
        position: unset;
    }

    ${DropdownContent} {
        left: 0;
        right: unset;
        width: calc(100vw - 70px);

        &:before {
            left: 105px;
            right: unset;
        }
    }

    ${DropdownTextContainer} {
        padding: ${theme.spacing.smallX} ${theme.spacing.small};
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${DropdownContent} {
            width: 100%;

            &:before {
                left: 119px;
            }
        }

        &:not(:last-child) {
            margin-bottom: ${theme.spacing.medium};
        }
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        ${DropdownContent} {
            &:before {
                left: 105px;
            }
        }
    }
`;

export const EssayCompetenceWrapper = styled.div<{ color: string }>`
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.smallXX};
    position: relative;

    > div {
        align-items: center;
        display: flex;
    }

    ${DropdownContent} {
        border-bottom-color: ${({ color }) => color};

        &:before {
            border-top-color: ${({ color }) => color};
        }
    }

    ${DropdownTextContainer} {
        background: ${({ color }) => hexToRgba(color, 0.1)};
    }

    ${DropdownButton} {
        svg > path:nth-child(1) {
            fill: ${({ color }) => color};
        }
    }
`;

const EssayTextStyles = css`
    ${Button3};
    color: ${theme.colors.base[500]};
`;

export const EssayCompetenceTitle = styled.span`
    ${EssayTextStyles};
    margin-right: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${Button2};
        color: ${theme.colors.base[400]};
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        ${Button3};
    }
`;

export const EssayCompetenceText = styled.span`
    ${Micro};
    color: ${theme.colors.base[300]};

    strong {
        ${Button3};
        ${EssayTextStyles};
        margin-left: ${theme.spacing.smallXX};
    }
`;

export const EssayCommentNumber = styled.span<{ color: string }>`
    ${Button2};
    background: ${({ color }) => color};
    border-radius: 50%;
    color: #331c10;
    font-weight: 700;
    height: 24px;
    line-height: 24px;
    margin-right: ${theme.spacing.smallX};
    text-align: center;
    width: 24px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-right: ${theme.spacing.small};
    }
`;
