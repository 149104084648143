import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { DropdownContent } from "components/Dropdown/styles";
import { EssayCompetenceTitle, EssayCompetenceWrapper } from "../competences/styles";
import { BodyText1, BodyText2, Button2, Button3, CardContainerSmallStyle, TitleH4 } from "assets/styles/global";

export const EssayCommentSliderButton = styled.button`
    ${CardContainerSmallStyle};
    ${TitleH4};
    align-items: center;
    color: ${theme.colors.system.info[300]};
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: ${theme.spacing.smallX};
    width: 32px !important;
`;

export const EssayCommentsContainer = styled.div``;

export const EssayCommentsHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
`;

export const EssayCommentsHeaderTitle = styled.span`
    ${Button3};
    color: ${theme.colors.base[500]};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${BodyText1};
        color: ${theme.colors.base[500]};
    }
`;

export const EssayCommentsContent = styled.div``;

export const EssayCommentsContentTitle = styled.div<{ color?: string }>`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.small};

    ${EssayCompetenceTitle} {
        ${Button2};
        color: ${theme.colors.base[500]};
    }

    ${EssayCompetenceWrapper} {
        margin: 0;
    }

    ${DropdownContent} {
        left: -8px;

        &:before {
            left: 127px;
        }
    }

    &:before {
        background: ${({ color }) => color || theme.colors.black};
        border-radius: 4px;
        content: "";
        display: block;
        height: 22px;
        margin-right: ${theme.spacing.smallXX};
        width: 4px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${EssayCompetenceTitle} {
            color: ${theme.colors.base[400]};
        }

        &:before {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const EssayCommentContainer = styled.div`
    color: ${theme.colors.base[400]};
    height: 220px;
    overflow-y: auto;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        height: unset;
    }
`;

export const EssayCommentTitle = styled.p`
    ${Button3};
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        color: ${theme.colors.base[400]};
    }
`;

export const EssayCommentContent = styled.div`
    p {
        ${BodyText2};

        &:not(:last-child) {
            margin-bottom: ${theme.spacing.smallX};
        }
    }
`;

export const EssayCommentsSliderWrapper = styled.div`
    .slick-slider {
        align-items: center;
        display: flex;

        > button {
            svg {
                display: block;
            }

            &:first-child {
                margin-right: 8px;
            }
            &:last-child {
                margin-left: 8px;
            }
        }
    }

    .slick-list {
        max-width: 115px;
    }

    .slick-track {
        height: 56px;
        padding: 8px 0;
    }

    .slick-current {
        ${EssayCommentSliderButton} {
            background: ${theme.colors.system.info[300]};
            color: ${theme.colors.white};
        }
    }
`;

export const EssaySliderWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    height: 56px;

    overflow-x: auto;

    background: red;
`;
