import React, { useCallback } from "react";

import { TabRowButton, TabRowContainer, TabRowItem } from "./styles";

interface IOptions {
    readonly id: number;
    readonly value: string;
    readonly disabled?: boolean;
}

interface IProps {
    items: readonly IOptions[];
    activeIndex: number;
    onChangeTab(index: number): void;
}

const TabRow = (props: IProps) => {
    const { items, activeIndex, onChangeTab } = props;

    const handleClick = useCallback((index: number) => (e: any) => onChangeTab(index), [onChangeTab]);

    return (
        <TabRowContainer>
            {items.map((item, index: number) => (
                <TabRowItem isActive={activeIndex === index} key={index}>
                    <TabRowButton disabled={item.disabled} onClick={handleClick(index)}>
                        {item.value}
                    </TabRowButton>
                </TabRowItem>
            ))}
        </TabRowContainer>
    );
};

export default TabRow;
