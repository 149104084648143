// Dependencies
import styled from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const InputCardCompositionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;
`;

export const InputCardCompositionTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #232326;
`;

export const InputCardCompositionsWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
`;

export const InputCardContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const InputCardContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 16px;
    border: 1px solid #83899e;
    border-radius: 4px;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;

export const InputCardInnerContent = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        gap: 40px;
    }
`;

export const TextContentWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: baseline;
    gap: 8px;
`;

export const InputCardTitle = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #232326;
`;

export const AdditionalTitleLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    color: #83899e;
`;

export const Description = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #83899e;
`;

export const UnderTitleAdditionalContentCompositionWrapper = styled.div`
    width: 100%;
    margin-top: 12px;

    display: flex;
    justify-content: flex-start;
    gap: 24px;
`;

export const ErrorMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ErrorMessageLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #e40000;
`;

export const UnderInputMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 4px;

    > img {
        margin-bottom: 2px;
    }
`;
