import { createActions, createReducer } from "reduxsauce";

export interface ILearningObjectState {
    isLoading: boolean;
}

export const { Creators, Types } = createActions({
    patchLikeLearningObjectRequest: ["payload"],
    patchLikeLearningObjectSuccess: ["payload"],
    patchLikeLearningObjectFailure: ["payload"],

    patchViewLearningObjectRequest: ["payload"],
    patchViewLearningObjectSuccess: ["payload"],
    patchViewLearningObjectFailure: ["payload"]
});

export const INITIAL_STATE: ILearningObjectState = {
    isLoading: false
};

const patchLikeLearningObjectRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const patchLikeLearningObjectSuccess = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const patchLikeLearningObjectFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const patchViewLearningObjectRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const patchViewLearningObjectSuccess = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const patchViewLearningObjectFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer(INITIAL_STATE, {
    [Types.PATCH_LIKE_LEARNING_OBJECT_REQUEST]: patchLikeLearningObjectRequest,
    [Types.PATCH_LIKE_LEARNING_OBJECT_SUCCESS]: patchLikeLearningObjectSuccess,
    [Types.PATCH_LIKE_LEARNING_OBJECT_FAILURE]: patchLikeLearningObjectFailure,

    [Types.PATCH_VIEW_LEARNING_OBJECT_REQUEST]: patchViewLearningObjectRequest,
    [Types.PATCH_VIEW_LEARNING_OBJECT_SUCCESS]: patchViewLearningObjectSuccess,
    [Types.PATCH_VIEW_LEARNING_OBJECT_FAILURE]: patchViewLearningObjectFailure
});
