import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { SwitcherItem } from "components/Switcher/styles";
import { theme } from "config/theme";
import { Button2 } from "assets/styles/global";

export const EssayCorrectionWrapper = styled.div`
    ${SwitcherItem} {
        min-width: 86px;

        ${Button2} {
            font-size: ${theme.typography.sizes.small};
            line-height: 22px;
            padding: 5px 10px;
        }

        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            min-width: 110px;
        }
    }
`;
