// Dependencies
import React from "react";

// Styles
import { DropImage } from "../../styles";

// Utils
import { theme } from "config/theme";

export const Background = () => (
    <DropImage width="768" height="1024" viewBox="0 0 768 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.1">
            <path
                d="M23.5996 468.758L125.506 849.06C132.47 876.742 146.23 902.255 165.557 923.321C184.885 944.388 209.18 960.352 236.272 969.789C267.607 980.079 301.306 981.107 333.285 972.749L362.621 964.889L191.43 326.023C190.115 321.054 190.444 315.79 192.369 311.009C194.293 306.228 197.711 302.183 202.119 299.47C161.23 312.031 123.812 333.868 92.8262 363.251C61.8407 392.634 38.1396 428.757 23.5996 468.758Z"
                fill={theme.colors.black}
            />
            <path
                d="M750.401 255.483C744.578 249.897 738.475 244.606 732.117 239.63L728.79 237.126C725.077 234.303 721.209 231.686 717.205 229.288C703.843 221.245 689.424 215.078 674.357 210.962L184.134 80.467C155.575 72.7053 125.445 72.5799 96.7796 80.1033C74.9517 85.9932 54.5182 96.1713 36.6849 110.037C18.8516 123.903 3.97973 141.177 -7.05327 160.837C-18.0863 180.498 -25.0569 202.148 -27.5538 224.51C-30.0507 246.872 -28.0233 269.494 -21.5913 291.039L25.5737 467.053C40.1178 426.928 63.8397 390.698 94.8607 361.233C125.882 331.769 163.35 309.878 204.298 297.296C207.086 295.522 210.217 294.354 213.487 293.868C216.757 293.382 220.091 293.589 223.27 294.476L525.129 374.703C528.87 375.623 532.337 377.408 535.251 379.914C540.513 384.18 544.02 390.219 545.112 396.892C545.623 398.132 545.972 399.434 546.149 400.764C614.77 390.678 716.665 360.357 766.634 270.753C762.072 264.441 757.049 258.472 751.605 252.896"
                fill={theme.colors.black}
            />
            <path
                d="M543.814 403.744C543.913 407.413 543.257 411.066 541.884 414.479C540.512 417.893 538.454 420.995 535.835 423.596L358.63 600.353L351.961 606.662L411.136 827.497L430.694 808.312L744.283 498.149C766.769 476.087 782.939 448.487 791.123 418.2C799.307 387.914 799.207 356.04 790.834 325.872C781.979 289.867 760.023 258.434 729.186 237.613C677.127 326.942 613.696 393.688 545.681 403.62"
                fill={theme.colors.black}
            />
            <path
                d="M583.323 117.265C588.793 136.259 588.354 156.484 582.061 175.364C575.769 194.244 563.908 210.923 547.989 223.278C532.07 235.632 512.814 243.102 492.675 244.735C472.535 246.369 452.424 242.093 434.902 232.451C417.381 222.809 403.243 208.239 394.289 190.596C385.335 172.953 381.971 153.036 384.626 133.382C387.28 113.729 395.833 95.2289 409.194 80.2389C422.555 65.2489 440.12 54.4475 459.651 49.2109C485.433 42.2982 512.838 45.5563 535.967 58.284C559.096 71.0116 576.101 92.1912 583.323 117.265Z"
                fill={theme.colors.brand[300]}
            />
            <path
                d="M549.157 126.792C552.732 138.999 552.528 152.01 548.571 164.168C544.613 176.326 537.081 187.081 526.934 195.063C516.787 203.046 504.483 207.895 491.59 208.993C478.697 210.091 465.798 207.388 454.535 201.229C443.272 195.07 434.154 185.733 428.344 174.407C422.534 163.081 420.293 150.278 421.907 137.629C423.521 124.98 428.917 113.057 437.408 103.379C445.899 93.6999 457.101 86.703 469.587 83.2793C486.087 78.7547 503.675 80.7804 518.559 88.9198C533.443 97.0592 544.432 110.66 549.157 126.792Z"
                fill={theme.colors.black}
            />
        </g>
    </DropImage>
);
