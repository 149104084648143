import { MediaQueries } from "assets/styles/settings";
import styled, { css } from "styled-components/macro";

export const Content = styled.section`
    height: 100%;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: flex;
        flex-wrap: wrap;
    }
`;

export const Children = styled.section<{ isMenuExpanded: boolean }>`
    height: 100%;
    transition: width 0.2s ease;

    ${MediaQueries.SMALL},
    ${MediaQueries.TABLET_PORTRAIT} {
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${({ isMenuExpanded }) => {
            if (isMenuExpanded) {
                return css`
                    width: calc(100% - 280px);
                `;
            }

            return css`
                width: calc(100% - 70px);
            `;
        }}
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        flex: 1;
        height: 100%;
    }
`;

export const ChildrenWrapper = styled.div`
    height: 100%;
    padding-top: 50px;
    overflow-x: hidden;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-top: 60px;
    }
`;

type ToastTextStyleProps = {
    strong?: boolean;
};

export const ToastText = styled.p<ToastTextStyleProps>`
    color: #3a3e47;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    ${({ strong }) =>
        !!strong &&
        css`
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            line-height: 22px;
        `}
`;

export const ToastButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;
