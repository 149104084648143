import React, { memo } from "react";
import SubjectScreenDetailsContentPerType from "../ContentPerType";

import * as S from "./styles";
import Skeleton from "components/Skeleton";

const SubjectScreenDetailsActiveModuleModalSkeleton = memo(() => {
    return (
        <>
            <S.SubjectScreenDetailsActiveModuleModalHeader>
                <S.SubjectScreenDetailsActiveModuleModalButton onClick={() => null}>
                    <Skeleton width="16px" height="16px" />
                </S.SubjectScreenDetailsActiveModuleModalButton>

                <S.SubjectScreenDetailsActiveModuleModalTitle>
                    <Skeleton width="60%" height="16px" />
                </S.SubjectScreenDetailsActiveModuleModalTitle>

                <S.SubjectScreenDetailsActiveModuleModalProgress>
                    <strong>
                        <Skeleton width="50%" height="16px" />
                    </strong>

                    <Skeleton width="100%" height="10px" />
                </S.SubjectScreenDetailsActiveModuleModalProgress>
            </S.SubjectScreenDetailsActiveModuleModalHeader>

            <SubjectScreenDetailsContentPerType contentPerType={[]} />
        </>
    );
});

export default SubjectScreenDetailsActiveModuleModalSkeleton;
