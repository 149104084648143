import { theme } from "config/theme";
import { ProjectFeaturesTitle } from "screens/Projects/Studying/components/Features/styles";
import styled from "styled-components";

export const ProjectResumeContainer = styled.ul``;

export const ProjectResumeWrapper = styled.li`
    &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
        margin-bottom: ${theme.spacing.small};
        padding-bottom: ${theme.spacing.small};
    }
`;

export const ProjectResumeTitle = styled(ProjectFeaturesTitle)``;
