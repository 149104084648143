import styled from "styled-components/macro";
import { theme } from "config/theme";
import { TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { DropdownContent } from "components/Dropdown/styles";
import { SubjectScreenDetailsDesktopSelectFrontDropdownActiveItem } from "components/Subject/DesktopSelectFront/styles";
import { SpinnerWrap } from "components/Spinner/styles";

export const SubjectScreenDetailsHeaderBackButton = styled.button`
    display: flex;
    color: ${theme.colors.base[500]};
    align-items: center;
    color: ${theme.project.slug !== "promilitares" ? theme.colors.base[500] : theme.colors.white};
    flex: 1;

    ${TitleH3};

    svg {
        fill: ${theme.project.slug !== "promilitares" ? theme.colors.base[500] : theme.colors.white};
        margin-right: ${theme.spacing.smallX};
    }
`;

export const SubjectScreenDetailsHeaderContainer = styled.header`
    background: ${theme.colors.base[200]};
    border-radius: 0 0 16px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing.small} ${theme.spacing.smallX};
    width: 100%;
    z-index: 10;
    margin-bottom: ${theme.spacing.large};

    .promilitares-wave {
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        background: ${theme.colors.white};
        border-radius: 0;
        padding: ${theme.spacing.medium} 0;

        ${SubjectScreenDetailsHeaderBackButton} {
            display: none;
        }
    }
`;

export const SubjectScreenDetailsHeaderWrapTexts = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: unset;
    }

    ${DropdownContent} {
        width: 185px;
        max-width: 100vw;
        top: calc(100% + ${theme.spacing.small});
        right: -5px;
        background: ${theme.colors.system.info[100]};
    }

    ${SubjectScreenDetailsDesktopSelectFrontDropdownActiveItem} {
        color: ${theme.colors.base[500]};
        margin-top: ${theme.spacing.small};
    }
`;

export const SubjectScreenDetailsHeaderName = styled.h1`
    display: none;
    color: ${theme.colors.brand[300]};
    margin-right: 8px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: unset;
    }

    ${TitleH3};
`;

export const SubjectScreenDetailsHeaderEntity = styled.button`
    display: none;
    align-items: center;
    margin-right: 16px;
    background: transparent;
    border: none;
    outline: none;
    text-align: left;
    cursor: unset;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
    }

    p {
        color: ${theme.colors.base[500]};
        font-family: ${theme.typography.family.secondary};
        font-size: ${theme.typography.sizes.smallX};
        margin-right: 16px;
    }
`;

export const SubjectScreenDetailsHeaderDropdown = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: ${theme.spacing.smallX};

    ${SpinnerWrap} {
        margin: ${theme.spacing.smallX} 0;
    }

    li {
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
        padding: ${theme.spacing.smallX};

        p {
            font-weight: 600;
            color: ${theme.colors.base[500]};
            cursor: pointer;
        }
    }
`;

export const SubjectScreenDetailsDesktopSelectFrontContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};
`;

export const SubjectScreenDetailsDesktopSelectFrontDropdown = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
`;

export const SelectFrontContainer = styled.div`
    display: flex;
    margin-right: calc(${theme.spacing.largeXXX} + ${theme.spacing.largeX});

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-right: unset;
    }
`;
