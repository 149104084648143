import React from "react";

import * as S from "./styles";
import Button from "components/Button";
import { useModal } from "hooks/use-modal";

const RaizExamModalMessage: React.FC = () => {
    const modal = useModal();

    return (
        <S.RaizExamModalMessageContainer>
            <S.RaizExamModalMessageTitle>Seja bem-vindo ao simulado modelo ENEM!</S.RaizExamModalMessageTitle>

            <div style={{ margin: "10px 0" }}>
                <p>Colocamos a sua disposição 180 questões divididas em 4 áreas de conhecimento e 1 redação.</p>
                <p>O simulado será aplicado em duas janelas diferentes, fique atento!</p>
                <p>Após a finalização do simulado é possível visualizar a quantidade de acertos.</p>
                <p>O resultado TRI com ranking será liberado em até 5 dias após o encerramento da segunda janela de execução.</p>
                <p>Boa prova!</p>
            </div>

            <Button type="button" variant="info" onClick={modal.close} size="medium">
                Ok, entendi
            </Button>
        </S.RaizExamModalMessageContainer>
    );
};

export default RaizExamModalMessage;
