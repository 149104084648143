const contextStrategy = new Map<string | string[], string>([
    ["materias", "course"],
    [["projetos", "projeto"], "project"]
]);

const getContext = (): string => {
    const path = window.location.pathname;

    // Split pathname and filter for valid values
    const arrPath = path.split("/").filter((item) => item);

    const pathHasCourse = arrPath.includes("curso");

    // Get subsequent index of `app` array item
    const rootIndex = pathHasCourse ? arrPath.findIndex((item) => item === "curso") + 2 : arrPath.findIndex((item) => item === "app") + 1;

    // Get user location
    const sectionValue = arrPath[rootIndex];

    let context;

    // Iterate over `contextStrategy` keys finding matches for `sectionValue`
    for (const item of contextStrategy.keys()) {
        if (item.includes(sectionValue)) {
            context = contextStrategy.get(item);
        }
    }

    return context || "course";
};

export default getContext;
