import styled from "styled-components";
import { BodyText2, Button2, Button3, Label, Micro } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { ProgressBarContainer, ProgressBarTabletWrapper } from "components/ProgressBar/styles";
import { TabRowContainer, TabRowItem } from "components/TabRow/styles";
import { VideoPlayerWrapper } from "components/VideoPlayer/styles";
import { theme } from "config/theme";
import { ProjectContainer } from "../Home/styles";

export const ProjectStudyingContainer = styled(ProjectContainer)`
    padding: 0;

    ${ProgressBarTabletWrapper} {
        background: ${theme.colors.base[100]};
    }

    ${ProgressBarContainer} {
        strong {
            ${Label};
            flex: 1;
            text-align: right;
        }
    }

    ${TabRowContainer} {
        margin-bottom: ${theme.spacing.medium};
    }

    ${TabRowItem} {
        flex: 1;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${TabRowContainer} {
            justify-content: flex-start;
            width: unset;
        }

        ${TabRowItem} {
            flex: unset;
            min-width: 144px;
            overflow: hidden;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.medium} 0;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        padding: ${theme.spacing.large} 0;
    }
`;

export const ProjectContent = styled.div`
    left: 0;
    margin-bottom: ${theme.spacing.medium};
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: ${theme.spacing.small};
        position: static;
    }
`;

export const VideoWrapper = styled.div`
    background: ${theme.colors.base[100]};
    height: 180px;
    width: 100%;

    ${VideoPlayerWrapper} {
        height: 100%;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 434px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        height: 320px;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        height: 480px;
    }
`;

export const VideoPlayerEmbed = styled.div`
    height: 100%;
    width: 100%;
`;

export const ProjectContentWrapper = styled.div``;

export const ProjectPlaceholderWrapper = styled.div`
    align-items: center;
    background: ${theme.colors.white};
    border-radius: 0 0 ${theme.spacing.smallXX} ${theme.spacing.smallXX};
    box-shadow: ${theme.shadow[1]};
    color: ${theme.colors.black};
    display: flex;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};

    ${ButtonWrapper} {
        font-size: ${theme.typography.sizes.small};
        font-weight: 700;
        padding: calc(10px / 2) 8px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.small};

        ${ButtonWrapper} {
            padding: ${theme.spacing.smallX} ${theme.spacing.small};

            svg {
                height: 12px;
                width: 11px;
            }
        }
    }
`;

export const ProjectCurrentContent = styled.div`
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
    background: ${theme.colors.white};
    border-radius: 0 0 ${theme.spacing.smallXX} ${theme.spacing.smallXX};
    box-shadow: ${theme.shadow[1]};
    color: ${theme.colors.black};
    display: flex;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};

    > div:last-child {
        padding: 0;
    }

    ${ButtonWrapper} {
        font-size: ${theme.typography.sizes.small};
        font-weight: 700;
        padding: calc(10px / 2) 8px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.small};

        ${ButtonWrapper} {
            padding: ${theme.spacing.smallX} ${theme.spacing.small};

            svg {
                height: 12px;
                width: 11px;
            }
        }
    }
`;

export const ProjectCurrentContentWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ProjectCurrentContentTitleWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        align-items: baseline;
        flex-direction: row;
        padding-right: ${theme.spacing.smallX};
    }
`;

export const ProjectCurrentContentTxt = styled.span`
    ${Micro};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-right: ${theme.spacing.smallXX};
    }
`;

export const ProjectCurrentContentTitle = styled.strong`
    ${Button3};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${Button2};
        font-weight: bold;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex: 1;
    }
`;

export const ProjectOverview = styled.div`
    margin-bottom: ${theme.spacing.small};
    padding: 0 ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: ${theme.spacing.medium};
        padding: 0;
    }
`;

export const ProjectTabWrapper = styled.div`
    background: #fff;
    padding: 8px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        left: 0;
        max-height: calc(100vh - 122px);
        overflow-y: auto;
        position: sticky;
        top: 0;
        z-index: 2;
    }
`;
