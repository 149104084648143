import { call, put, takeLatest, delay, select } from "redux-saga/effects";
import api from "services/api";
import { Creators as authActions } from "store/ducks/auth";
import { Creators as CourseActions, Types as CourseTypes } from "store/ducks/course";

import history from "services/history";
import { getRedirectPath } from "helpers/getRedirectPath";
import { ReduxStore } from "store/ducks";

function* getExternalSchoolClassLinks() {
    try {
        const { data } = yield call(api.get, "/student/schoolclass/me");

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(CourseActions.getExternalSchoolClassSuccess(data.filter(({ externalURL }) => !!externalURL)));
    } catch (error) {
        yield put(CourseActions.getExternalSchoolClassFailure());
    }
}

function* setCourse(action: any) {
    yield put(authActions.getMenuRequest());

    yield delay(300);

    const { menu, loginRedirectPath } = yield select(({ auth }: ReduxStore) => ({ menu: auth.menu, loginRedirectPath: auth.redirectPath }));

    const defaultRedirectPath = yield getRedirectPath(menu);

    if (!loginRedirectPath && !action?.payload?.shouldPreventRedirect) {
        yield call(history.push, {
            pathname: defaultRedirectPath
        });
    }

    if (loginRedirectPath) {
        const [pathname, search] = loginRedirectPath.split("?");

        yield call(history.push, {
            pathname,
            search
        });

        yield put(authActions.clearRedirectPath());
    }
}

export default [
    takeLatest(CourseTypes.GET_EXTERNAL_SCHOOL_CLASS_REQUEST, getExternalSchoolClassLinks),
    takeLatest(CourseTypes.SET_COURSE, setCourse)
];
