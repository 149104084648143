// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const SpinnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ShowIFrameContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 8px;
    gap: 16px;
    flex-shrink: 0;

    @media (min-width: 768px) {
        padding: 24px;
    }
`;

export const ShowIFrameSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #ced0dd;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const ShowIFrameTitle = styled.p`
    width: 100%;
    color: ${theme.colors.system.info[400]};
    text-align: center;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;

    @media (min-width: 768px) {
        font-size: 32px;
        font-weight: 800;
        line-height: 44px;
    }
`;

export const CloseIconWrapper = styled.div`
    display: block;
    cursor: pointer;

    @media (min-width: 1280px) {
        display: none;
    }
`;

export const IFrame = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
`;
