// Dependencies
import styled, { keyframes } from "styled-components";

// Utils
import { theme } from "config/theme";

const rotate = keyframes`
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto auto;

    .wrapper {
        display: inline-block;
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 100px;
        transform: translateZ(0) scale(1);
        backface-visibility: hidden;
        transform-origin: 0 0;
    }

    .wrapper div {
        box-sizing: border-box;
    }

    .wrapper > div {
        position: absolute;
        top: 14px;
        left: 14px;
        width: 72px;
        height: 72px;
        border: 8px solid #000;
        border-radius: 50%;
        animation: ${rotate} 1s linear infinite;
        border-color: ${theme.colors.brand[300]} transparent ${theme.colors.brand[300]} transparent;
    }

    .wrapper > div:nth-child(2),
    .wrapper > div:nth-child(4) {
        top: 23px;
        left: 23px;
        width: 54px;
        height: 54px;
        animation: ${rotate} 1s linear infinite reverse;
    }

    .wrapper > div:nth-child(2) {
        border-color: transparent ${theme.colors.brand[300]} transparent ${theme.colors.brand[300]};
    }

    .wrapper > div:nth-child(3) {
        border-color: transparent;
    }

    .wrapper > div:nth-child(3) div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
    }

    .wrapper > div:nth-child(3) div:before,
    .wrapper > div:nth-child(3) div:after {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 24px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-shadow: 0 64px 0 0 ${theme.colors.brand[300]};
        background: ${theme.colors.brand[300]};
    }

    .wrapper > div:nth-child(3) div:after {
        left: -8px;
        top: 24px;
        box-shadow: 64px 0 0 0 ${theme.colors.brand[300]};
    }

    .wrapper > div:nth-child(4) {
        border-color: transparent;
    }

    .wrapper > div:nth-child(4) div {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: rotate(45deg);
    }

    .wrapper > div:nth-child(4) div:before,
    .wrapper > div:nth-child(4) div:after {
        content: "";
        display: block;
        position: absolute;
        top: -8px;
        left: 15px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        box-shadow: 0 46px 0 0 ${theme.colors.brand[300]};
        background: ${theme.colors.brand[300]};
    }
    .wrapper > div:nth-child(4) div:after {
        left: -8px;
        top: 15px;
        box-shadow: 46px 0 0 0 ${theme.colors.brand[300]};
    }
`;
