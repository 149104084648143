import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import WizardNav from "components/Wizard/nav";
import PageHeader from "components/PageHeader";
import { Col, Grid, Row } from "components/Grid";
import EssayCorrection from "components/essayCorrection";
import EssayDraft from "./components/draft";

import * as S from "./styles";

import { IEssayComposition } from "store/interfaces/IEssay";
import { EssayStatus as StatusEssay } from "enums/EssayStatus";
import { IReduxStore } from "interfaces/IReduxStore";

const statuses = [
    {
        slug: "draft",
        label: "Rascunho"
    },
    {
        slug: "in-progress",
        label: "Em correção"
    },
    {
        slug: "Corrected",
        label: "Corrigida"
    }
];

interface IEssayStatusProps {
    composition: IEssayComposition;
    themeTitle: string;
}

const EssayStatus = ({ composition, themeTitle }: IEssayStatusProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const wizardStatus = [0, 1, 1, 2];
    const [currentStatus] = useState(statuses[wizardStatus[composition?.status]]);

    return (
        <>
            <PageHeader.Simple
                breadcrumb={{
                    current: {
                        label: "Envio de redação"
                    },
                    parent: [
                        {
                            label: "Redação",
                            url: `/app/curso/${courseSlug}/redacoes`
                        },
                        {
                            label: "Detalhe do tema",
                            url: `/app/curso/${courseSlug}/redacoes/temas/${composition.idTheme}`
                        }
                    ]
                }}
            />
            <S.EssayStatusContainer>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <WizardNav completeds={[statuses[0]]} current={currentStatus} options={statuses} />
                        </Col>
                    </Row>
                    <Row>
                        {composition.status !== StatusEssay.Corrected ? (
                            <EssayDraft composition={composition} />
                        ) : (
                            <EssayCorrection composition={composition} themeTitle={themeTitle} />
                        )}
                    </Row>
                </Grid>
            </S.EssayStatusContainer>
        </>
    );
};

export default memo(EssayStatus);
