// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    @media (min-width: 1024px) {
        max-width: 416px;
    }
`;

export const IconOverlay = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
`;

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    background-color: #ddd2df;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media (min-width: 1024px) {
        width: 48px;
        height: 48px;
    }
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;

    @media (min-width: 1024px) {
        width: 32px;
        height: 32px;
    }
`;

export const ResumeCardWrapper = styled.div`
    width: 100%;
    height: auto;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    display: flex;
    align-items: center;

    @media (min-width: 1024px) {
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;

        margin-left: 16px;
        padding: 16px 16px 16px 48px;
    }
`;

export const ResumeCardCounter = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #7c8195;

    @media (min-width: 1024px) {
        font-size: 24px;
        line-height: 36px;
    }
`;

export const ResumeCardDescription = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #505462;
`;

export const AreaInformationIcon = styled.img`
    width: 24px;
    height: 24px;
    padding: 2px;
    background-color: #c6e0fe;
    border-radius: 200px;
    cursor: pointer;
`;

type AreaInformationTooltipStyleProps = {
    isVisible: boolean;
};

export const AreaInformationTooltip = styled.div<AreaInformationTooltipStyleProps>`
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};

    position: absolute;
    top: -36px;
    right: 24px;
    bottom: 0;

    z-index: 2;

    @media (min-width: 768px) {
        top: unset;
        right: unset;
        bottom: 20px;

        transform: translateX(-44%);
    }
`;

export const AreaInformationWrapper = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;

    @media (min-width: 1024px) {
        &:hover {
            ${AreaInformationTooltip} {
                display: flex;
            }
        }
    }
`;

export const AreaInformation = styled.div`
    position: relative;
`;

// ----------

export const IconWrapperSkeleton = styled.div`
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;

    @media (min-width: 1024px) {
        width: 48px;
        height: 48px;
    }
`;

export const ResumeCardCounterSkeleton = styled.div`
    height: 26px;
    font-size: 18px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        height: 36px;
        padding: 6px 0;
    }
`;

export const ResumeCardDescriptionSkeleton = styled.div`
    height: 22px;
    padding: 4px 0;
`;
