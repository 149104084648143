import { IBookletModule } from "interfaces/IBookletModule";
import { IContentPerType, IContentPerTypeItem } from "interfaces/IContentPerType";
import { IRelatedVideo } from "interfaces/IRelatedContent";
import { ITeacher } from "interfaces/ITeacher";
import { IVideo } from "interfaces/IVideo";
import { createActions, createReducer } from "reduxsauce";
import { LikeStatusType } from "utils/types/LikeStatus";

export interface IWatchScreenVideo extends IVideo {
    learningObject: {
        id: number;
        viewed?: boolean;
        isLiked?: LikeStatusType;
        openForum?: number;
    };
    playlist?: IRelatedVideo[];
    blockContent?: boolean;
}

export interface IWatchBookletModule extends IBookletModule {
    contentPerType: IContentPerType[];
}

export interface IWatchLessonPlanModule {
    id: number;
    slug: string;
    name: string;
}

export interface IWatchSubjectSlug {
    name: string;
    color: string;
    id: number;
}
export interface IWatchState {
    isLoading: boolean;
    eventStartDate?: string;
    eventEndDate?: string;
    id: number;
    date: string;
    title: string;
    teachers: ITeacher[];
    materials: any[];
    booklets: any[];
    exerciseLists: any[];
    video: IWatchScreenVideo;
    bookletModule: IWatchBookletModule;
    relatedVideos?: IContentPerTypeItem[];
    category: number;
    module?: IWatchLessonPlanModule;
    subjectObj?: IWatchSubjectSlug;
    hasForum: boolean;
    eventId: number;
}

export const { Creators, Types } = createActions({
    setWatch: ["payload"],
    setCurrentVideo: ["payload"],
    setCurrentVideoData: ["payload"],

    setWatchLikeRequest: ["payload"],
    setWatchLikeSuccess: ["payload"],
    setWatchLikeFailure: ["payload"],

    setViewedVideoRequest: ["payload"],
    setViewedVideoSuccess: ["payload"],
    setViewedVideoFailure: ["payload"],

    getWatchBookletModuleRequest: ["payload"],
    getWatchBookletModuleSuccess: ["payload"],
    getWatchBookletModuleFailure: ["payload"],

    getWatchVideoRequest: ["payload"],
    getWatchVideoSuccess: ["payload"],
    getWatchVideoFailure: ["payload"],

    getWatchLessonPlanRequest: ["payload"],
    getWatchLessonPlanSuccess: ["payload"],
    getWatchLessonPlanFailure: ["payload"],

    clearWatchVideo: []
});

export const INITIAL_STATE: IWatchState = {
    isLoading: false,
    id: 0,
    date: "",
    title: "",
    teachers: [],
    materials: [],
    booklets: [],
    exerciseLists: [],
    hasForum: false,
    eventId: 0,
    video: {
        created: "",
        videoHash: null,
        blockContent: false,
        code: "",
        description: "",
        hashVideo: "",
        id: 0,
        openForum: 0,
        provider: 0,
        title: "",
        url: "",
        videoKey: "",
        subject: {
            color: "",
            id: 0,
            image: "",
            name: "",
            order: 0,
            slug: "",
            progress: {
                completedItems: 0,
                percent: 0,
                totalItems: 0
            }
        },
        totalViews: 0,
        learningObject: {
            id: 0
        }
    },
    bookletModule: {
        code: "",
        created: "",
        progress: {
            completedItems: -1,
            totalItems: -1,
            percent: -1
        },
        stats: [],
        subject: {
            color: "",
            id: -1,
            image: "",
            name: "",
            order: -1,
            progress: {
                completedItems: -1,
                percent: -1,
                totalItems: -1
            },
            slug: ""
        },
        subjectFront: {},
        content: [],
        id: 0,
        name: "",
        order: 0,
        contentPerType: []
    },
    category: -1
};

const setWatch = (state = INITIAL_STATE, action: any) => ({ ...state, ...action.payload });

const setCurrentVideo = (state = INITIAL_STATE, action: any) => ({
    ...state,
    video: action.payload
});

const setCurrentVideoData = (state = INITIAL_STATE, action: any) => ({
    ...state
});

const getVideoSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    video: action.payload,
    isLoading: false
});

const getBookletModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    bookletModule: action.payload,
    isLoading: false
});

const getLessonPlanSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

const setLikeSuccess = (state = INITIAL_STATE, action: any) => {
    const { status } = action.payload;

    const updateVideo = {
        ...state.video,
        learningObject: {
            ...state.video.learningObject,
            isLiked: status
        }
    };

    return {
        ...state,
        video: updateVideo
    };
};

// GENERIC ACTIONS

const watchWithoutLoading = (state = INITIAL_STATE, action: any) => ({
    ...state
});

const watchRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});

const watchFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

const clearVideo = (state = INITIAL_STATE) => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
    [Types.SET_WATCH]: setWatch,
    [Types.SET_CURRENT_VIDEO]: setCurrentVideo,
    [Types.SET_CURRENT_VIDEO_DATA]: setCurrentVideoData,

    // BOOKLET MODULE
    [Types.GET_WATCH_BOOKLET_MODULE_REQUEST]: watchRequest,
    [Types.GET_WATCH_BOOKLET_MODULE_SUCCESS]: getBookletModuleSuccess,
    [Types.GET_WATCH_BOOKLET_MODULE_FAILURE]: watchFailure,

    // LESSON PLAN
    [Types.GET_WATCH_LESSON_PLAN_REQUEST]: watchRequest,
    [Types.GET_WATCH_LESSON_PLAN_SUCCESS]: getLessonPlanSuccess,
    [Types.GET_WATCH_LESSON_PLAN_FAILURE]: watchFailure,

    // VIDEO
    [Types.GET_WATCH_VIDEO_REQUEST]: watchRequest,
    [Types.GET_WATCH_VIDEO_SUCCESS]: getVideoSuccess,
    [Types.GET_WATCH_VIDEO_FAILURE]: watchFailure,

    // LIKE VIDEO
    [Types.SET_WATCH_LIKE_REQUEST]: watchWithoutLoading,
    [Types.SET_WATCH_LIKE_SUCCESS]: setLikeSuccess,
    [Types.SET_WATCH_LIKE_FAILURE]: watchFailure,

    // CLEAR
    [Types.CLEAR_WATCH_VIDEO]: clearVideo
});
