// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

type WebpIconsStepProps = {
    srcImg: string;
    amount?: string;
};

export const WebpIconsStep: FunctionComponent<WebpIconsStepProps> = ({ srcImg, amount }) => {
    return (
        <Container>
            <img src={srcImg} alt="Material Icon" />
            <p>{amount}</p>
        </Container>
    );
};
