// Dependencies
import React, { FunctionComponent, useRef, useEffect, useState } from "react";

// Types
import { PerformanceGraphicProps } from "./performance-graphic.types";

// Styles
import {
    Container,
    GraphicWrapper,
    Graphic,
    GraphicIndicator,
    MobileGraphicIndicator,
    ColumnSelectedContent,
    ColumnSelectedContentMobile,
    ColumnSelectedSubContent,
    Column,
    GraphicFooter,
    SubtitlesContainer,
    SubtitleWrapper,
    Subtitle,
    SubtitleIndicator,
    AverageLabel,
    GraphicColumnsController,
    Divider,
    AverageLine,
    AverageNote
} from "./performance-graphic.styles";

// Utils
import { Asset } from "component-library/utilities/asset";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";

// Utils
import { ternary } from "utils/ternary";

export type GraphicProperties = {
    x0: number;
    xAxisLengthFactor: number;
    sidePadding: number;
    YOthersAxisFactor: number;
};

const mobileProperties: GraphicProperties = {
    x0: 24,
    xAxisLengthFactor: 1.5,
    sidePadding: 12,
    YOthersAxisFactor: 1.25
};

const desktopProperties: GraphicProperties = {
    x0: 36,
    xAxisLengthFactor: 1.75,
    sidePadding: 24,
    YOthersAxisFactor: 1.325
};

export const PerformanceGraphic: FunctionComponent<PerformanceGraphicProps> = ({
    columns,
    subtitles,
    hasPeriodSelection,
    selectedPeriodSlug,
    maxPeriodResult,
    average,
    previousColumnsIcon,
    nextColumnsIcon,
    mobileDisplayedColumnsCount,
    handleSelectPeriod
}) => {
    const graphicRef = useRef<SVGSVGElement>(null);

    const [width, setWidth] = useState(0);
    const [graphicProperties, setGraphicProperties] = useState(desktopProperties);
    const [displayDesktopColumns, setDisplayDesktopColumns] = useState(true);
    const [columnsGroupIndex, setColumnsGroupIndex] = useState(0);
    const [isAverageNoteDisplayed, setIsAverageNoteDisplayed] = useState(false);

    const height = 376;

    const totalColumns = columns.length;
    const mobileColumnsToDisplay =
        !!mobileDisplayedColumnsCount && mobileDisplayedColumnsCount < totalColumns ? mobileDisplayedColumnsCount : totalColumns;

    const mobileFirstIndex = columnsGroupIndex;
    const mobileFinalIndex = mobileFirstIndex + mobileColumnsToDisplay;
    const mobileColumns = columns.slice(mobileFirstIndex, mobileFinalIndex);

    const columnsToDisplay = displayDesktopColumns ? columns : mobileColumns;

    const x0 = graphicProperties.x0;
    const xAxisLength = width! - x0 * graphicProperties.xAxisLengthFactor;

    const y0 = 48;
    const yAxisLength = height - y0 * 2;

    const xAxisY = y0 + yAxisLength;

    const dataYMax = maxPeriodResult ?? columns.reduce((currMax, column) => Math.max(currMax, column.result), -Infinity);

    const dataYMin = 0;

    const dataYRange = dataYMax - dataYMin;

    const numYTicks = 5;

    const barPlotWidth = xAxisLength / columnsToDisplay.length;

    const yAverageRatio = (average! - dataYMin) / dataYRange;
    const yAverageAxis = y0 + (1 - yAverageRatio) * yAxisLength;

    const handleResize = () => {
        setTimeout(() => {
            if (!!graphicRef.current?.clientWidth) {
                setWidth(graphicRef.current?.clientWidth);

                setGraphicProperties(window.innerWidth >= 768 ? desktopProperties : mobileProperties);

                setDisplayDesktopColumns(window.innerWidth >= 1024);
            }
        }, 400);
    };

    useEffect(() => {
        const hamburgerMenuElement = document.getElementById("hamburger-menu");

        if (!!hamburgerMenuElement) {
            hamburgerMenuElement.addEventListener("click", handleResize);
        }

        return () => {
            window.removeEventListener("click", handleResize);
        };
    }, []);

    useEffect(() => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const shouldDisplayHits = !!columnsToDisplay[0].informations;

    const hits = columnsToDisplay.map((column, index) => {
        const sidePadding = graphicProperties.sidePadding;

        const x = x0 + index * barPlotWidth + (barPlotWidth + sidePadding) / 2;

        const hitsPercentage = column.informations ? column.informations.hits / column.informations.totalQuestions : 0;

        const yRatio = (column.result * hitsPercentage - dataYMin) / dataYRange;

        const y = y0 + (1 - yRatio) * yAxisLength;

        return [x, y];
    });

    const popylineHits = hits.map(([x, y]) => `${x + 2},${y}`).join(" ");
    const popylinePointsHits = hits.map(([x, y]) => [x - 6, y - 6]);
    const popylineInnerPointsHits = hits.map(([x, y]) => [x - 3, y - 3]);

    const handleSelectColumn = (columnSlug: string) => {
        if (!!handleSelectPeriod && !!hasPeriodSelection) {
            handleSelectPeriod(columnSlug);
        }
    };

    return (
        <Container>
            <GraphicWrapper>
                <Graphic width="100%" height={height} style={{ overflow: "unset" }} ref={graphicRef}>
                    <ConditionallyRender
                        shouldRender={width > 0 && barPlotWidth > 0}
                        content={
                            <>
                                {/* X axis */}
                                <line x1={x0} y1={xAxisY} x2={width} y2={xAxisY} stroke="#F0F1F7" />

                                {/* Y initial axis */}
                                <line x1={x0} y1={y0} x2={x0} y2={y0 + yAxisLength} stroke="#F0F1F7" />

                                {/* Y final axis */}
                                <line x1={width} y1={y0} x2={width} y2={y0 + yAxisLength} stroke="#F0F1F7" />

                                {/* Y others axis */}
                                {Array.from(Array(columnsToDisplay.length - 1)).map((_, index) => {
                                    const xValue = x0 * graphicProperties.YOthersAxisFactor + barPlotWidth * (index + 1);

                                    return <line key={index} x1={xValue} y1={y0} x2={xValue} y2={y0 + yAxisLength} stroke="#F0F1F7" />;
                                })}

                                {Array.from({ length: numYTicks }).map((_, index) => {
                                    const y = y0 + index * (yAxisLength / numYTicks);

                                    const yValue = Math.round(dataYMax - index * (dataYRange / numYTicks));

                                    return (
                                        <g key={index}>
                                            <line x1={x0} y1={y} x2={width} y2={y} stroke="#F0F1F7" />

                                            <GraphicIndicator x={x0 - 5} y={y + 5} textAnchor="end" hasMobile={false}>
                                                {yValue}
                                            </GraphicIndicator>
                                        </g>
                                    );
                                })}

                                {/* Columns */}
                                {columnsToDisplay.map((column, index) => {
                                    const sidePadding = graphicProperties.sidePadding;

                                    const x = x0 + index * barPlotWidth + sidePadding;
                                    const yRatio = (column.result - dataYMin) / dataYRange;

                                    const y = y0 + (1 - yRatio) * yAxisLength;

                                    const height = yRatio * yAxisLength;

                                    const columnColor = ternary([
                                        [!hasPeriodSelection && !!column.period.color, column.period.color],
                                        [!hasPeriodSelection && !column.period.color, "#9C9FB0"],
                                        [hasPeriodSelection && selectedPeriodSlug === column.period.slug, "#794A7D"],
                                        [hasPeriodSelection && !!column.period.color, column.period.color],
                                        [hasPeriodSelection && !column.period.color, "#9C9FB0"]
                                    ])!;

                                    return (
                                        <g key={index}>
                                            <rect fill={columnColor} x={x} y={y} rx={8} width={barPlotWidth - sidePadding} height={height} />

                                            <GraphicIndicator x={x + (barPlotWidth - sidePadding) / 2} y={xAxisY + 16} textAnchor="middle" hasMobile>
                                                {column.period.value}
                                            </GraphicIndicator>

                                            <MobileGraphicIndicator x={x + (barPlotWidth - sidePadding) / 2} y={xAxisY + 16} textAnchor="middle">
                                                {column.period.mobileValue}
                                            </MobileGraphicIndicator>
                                        </g>
                                    );
                                })}

                                {/* Hits line */}
                                <ConditionallyRender
                                    shouldRender={!!shouldDisplayHits}
                                    content={
                                        <g>
                                            <polyline points={popylineHits} fill="none" stroke="#50C79D" strokeWidth="4px" />

                                            {popylinePointsHits.map(([x, y], index) => (
                                                <rect key={index} fill="#7AD7B6" x={x} y={y} rx={6} width={12} height={12} />
                                            ))}

                                            {popylineInnerPointsHits.map(([x, y], index) => (
                                                <rect key={index} fill="#28B583" x={x} y={y} rx={3} width={6} height={6} />
                                            ))}
                                        </g>
                                    }
                                />

                                {/* Invisible columns to click */}
                                {columnsToDisplay.map((column, index) => {
                                    const sidePadding = graphicProperties.sidePadding;

                                    const x = x0 + index * barPlotWidth + sidePadding;
                                    const yRatio = (column.result - dataYMin) / dataYRange;

                                    const y = y0 + (1 - yRatio) * yAxisLength;

                                    const height = yRatio * yAxisLength;

                                    return (
                                        <Column
                                            key={index}
                                            hasAction={hasPeriodSelection}
                                            fill="transparent"
                                            stroke="transparent"
                                            x={x}
                                            y={y}
                                            rx={8}
                                            width={barPlotWidth - sidePadding}
                                            height={height}
                                            {...(window.innerWidth < 1024 && {
                                                onClick: () => handleSelectColumn(column.period.slug)
                                            })}
                                            {...(window.innerWidth >= 1024 && {
                                                onMouseEnter: () => handleSelectColumn(column.period.slug),
                                                onMouseLeave: () => handleSelectColumn(column.period.slug)
                                            })}
                                        />
                                    );
                                })}

                                {/* X average axis */}
                                <ConditionallyRender
                                    shouldRender={!!average}
                                    content={
                                        <g>
                                            <line
                                                x1={x0}
                                                y1={yAverageAxis}
                                                x2={width}
                                                y2={yAverageAxis}
                                                stroke="#50C79D"
                                                strokeDasharray="14, 8"
                                                strokeWidth="4px"
                                            />

                                            <AverageLine
                                                x1={x0}
                                                y1={yAverageAxis}
                                                x2={width}
                                                y2={yAverageAxis}
                                                stroke="transparent"
                                                strokeWidth="4px"
                                                {...(window.innerWidth < 1024 && {
                                                    onClick: () => setIsAverageNoteDisplayed((prevState) => !prevState)
                                                })}
                                                {...(window.innerWidth >= 1024 && {
                                                    onMouseEnter: () => setIsAverageNoteDisplayed(true),
                                                    onMouseLeave: () => setIsAverageNoteDisplayed(false)
                                                })}
                                            />
                                        </g>
                                    }
                                />

                                {/* Column informations data */}
                                {columnsToDisplay.map((column, index) => {
                                    const sidePadding = graphicProperties.sidePadding;

                                    const x = x0 + index * barPlotWidth + sidePadding;
                                    const yRatio = (column.result - dataYMin) / dataYRange;

                                    const height = yRatio * yAxisLength;

                                    const hitsPercentage = column.informations
                                        ? Math.floor((column.informations.hits / column.informations.totalQuestions) * 100)
                                        : 0;

                                    const columnSelectedValue = !!column.informations ? `${hitsPercentage}%` : `${column.result}`;

                                    const isSmallBackgroundProperties = !column.informations || window.innerWidth < 1024;

                                    const backgroundProperties = {
                                        y: isSmallBackgroundProperties ? yAxisLength - height + 26 : yAxisLength - height - 10,
                                        width: isSmallBackgroundProperties ? columnSelectedValue.length * 10 : 72,
                                        height: isSmallBackgroundProperties ? 14 : 50
                                    };

                                    return (
                                        <g key={index}>
                                            <ConditionallyRender
                                                shouldRender={(selectedPeriodSlug === column.period.slug || !hasPeriodSelection) && !!average}
                                                content={
                                                    <rect
                                                        fill="#fff"
                                                        x={x}
                                                        y={backgroundProperties.y}
                                                        rx={0}
                                                        width={backgroundProperties.width}
                                                        height={backgroundProperties.height}
                                                    />
                                                }
                                            />

                                            <ConditionallyRender
                                                shouldRender={selectedPeriodSlug === column.period.slug || !hasPeriodSelection}
                                                content={
                                                    <>
                                                        <ColumnSelectedContent x={x} y={yAxisLength - height + (!!column.informations ? 4 : 40)}>
                                                            {columnSelectedValue}
                                                        </ColumnSelectedContent>

                                                        <ColumnSelectedContentMobile x={x} y={yAxisLength - height + 40}>
                                                            {columnSelectedValue}
                                                        </ColumnSelectedContentMobile>

                                                        <ConditionallyRender
                                                            shouldRender={!!column.informations}
                                                            content={
                                                                <>
                                                                    <ColumnSelectedSubContent x={x} y={yAxisLength - height + 24}>
                                                                        {column.informations?.totalQuestions} questões
                                                                    </ColumnSelectedSubContent>

                                                                    <ColumnSelectedSubContent x={x} y={yAxisLength - height + 40}>
                                                                        {column.informations?.hits} acertos
                                                                    </ColumnSelectedSubContent>
                                                                </>
                                                            }
                                                        />
                                                    </>
                                                }
                                            />
                                        </g>
                                    );
                                })}

                                {/* average note */}
                                <AverageNote isVisible={!!average && isAverageNoteDisplayed}>
                                    <rect fill="#7AD7B6" x={width - 42} y={yAverageAxis - 24} rx={21} width={42} height={42} />

                                    <rect fill="#28B583" x={width - 36} y={yAverageAxis - 18} rx={15} width={30} height={30} />

                                    <AverageLabel x={width - 32} y={yAverageAxis + 2}>
                                        {average}
                                    </AverageLabel>
                                </AverageNote>
                            </>
                        }
                    />
                </Graphic>
            </GraphicWrapper>

            <GraphicFooter>
                <SubtitlesContainer>
                    {subtitles.map((subtitle) => (
                        <SubtitleWrapper key={subtitle.label}>
                            <SubtitleIndicator color={subtitle.color} />

                            <Spacing direction="horizontal" size={8} />

                            <Subtitle>{subtitle.label}</Subtitle>
                        </SubtitleWrapper>
                    ))}
                </SubtitlesContainer>

                <ScreenWidthRender
                    renderingWidth={1024}
                    actionAfterRenderingWidth="hide"
                    content={
                        <ConditionallyRender
                            shouldRender={!!mobileDisplayedColumnsCount && mobileDisplayedColumnsCount < totalColumns}
                            content={
                                <GraphicColumnsController>
                                    <Spacing size={20} direction="horizontal" />

                                    <Divider />

                                    <Spacing size={20} direction="horizontal" />

                                    <Asset
                                        source={previousColumnsIcon}
                                        color={columnsGroupIndex === 0 ? "#CED0DD" : "#7C8195"}
                                        size={20}
                                        handleClick={() => {
                                            if (columnsGroupIndex > 0) {
                                                setColumnsGroupIndex(columnsGroupIndex - 1);
                                            }
                                        }}
                                    />

                                    <Spacing size={24} direction="horizontal" />

                                    <Asset
                                        source={nextColumnsIcon}
                                        color={columnsGroupIndex === totalColumns - mobileColumnsToDisplay ? "#CED0DD" : "#7C8195"}
                                        size={20}
                                        handleClick={() => {
                                            if (columnsGroupIndex < totalColumns - mobileColumnsToDisplay) {
                                                setColumnsGroupIndex(columnsGroupIndex + 1);
                                            }
                                        }}
                                    />
                                </GraphicColumnsController>
                            }
                        />
                    }
                />
            </GraphicFooter>
        </Container>
    );
};
