import React, { useEffect, useCallback, memo } from "react";

// Components
import { Grid } from "components/Grid";
import SubjectGroup from "components/SubjectGroup";
import ForbiddenMessage from "components/ForbiddenMessage";

// Helpers
import { theme } from "config/theme";
import { ISubjectGroup } from "interfaces/ISubject";
import { ISubjectActions } from "store/interfaces/ISubject";

// Assets
import { SubjectsHeader, SubjectScreenContainer } from "./styles";
import SubjectsSkeleton from "./skeleton";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as SubjectActions } from "store/ducks/subject";

interface ISubjectsProps {
    subjects: ISubjectGroup[];
    isLoading: boolean;
    course: {
        id: number;
        isPublished: boolean;
        helptext: string;
        slug?: string;
    };
    error: boolean;
}

const Subjects = ({ subjects, isLoading, course, error }: ISubjectsProps) => {
    if (isLoading) {
        return <SubjectsSkeleton />;
    }
    return (
        <SubjectScreenContainer data-test-id="subject-container">
            <Grid>
                <SubjectsHeader>
                    <h1>{theme.project.slug !== "promilitares" ? "Matérias" : "Módulos"}</h1>
                </SubjectsHeader>
                {error && <p>Desculpe, tivemos um erro. Tente novamente.</p>}
                {course?.isPublished ? (
                    subjects.map((subjectGroup) => <SubjectGroup subjectGroup={subjectGroup} key={subjectGroup.id} />)
                ) : (
                    <ForbiddenMessage text={course.helptext} />
                )}
            </Grid>
        </SubjectScreenContainer>
    );
};

interface ISubjectScreenWrapperProps {
    course: {
        id: number;
        isPublished: boolean;
        helptext: string;
    };
    isLoading: boolean;
    subjects: ISubjectGroup[];
    subjectActions: ISubjectActions;
    error: boolean;
}

const SubjectScreenWrapper = ({ course, isLoading, subjects, subjectActions, error }: ISubjectScreenWrapperProps) => {
    const requestSubjects = useCallback(() => {
        if (!course.isPublished) {
            return;
        }

        const payload = {
            endpoint: "/student/subject-v2"
        };

        subjectActions.getSubjectsRequest(payload);
        subjectActions.getSubjectProgressRequest();

        return () => subjectActions.clearSubject();
    }, [course.isPublished, subjectActions]);

    useEffect(() => requestSubjects(), [requestSubjects]);

    return <Subjects isLoading={isLoading} subjects={subjects} course={course} error={error} />;
};

const mapStateToProps = ({ subject, course }: IReduxStore) => {
    const subjects = subject.items.filter((subject) => !!subject.subjects && !!subject.subjects.length);

    return {
        course: {
            id: course.id,
            helptext: course.helptext || "",
            isPublished: course.isPublished,
            slug: course.slug
        },
        isLoading: subject.isLoading,
        subjects,
        error: subject.error
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    subjectActions: bindActionCreators(SubjectActions, dispatch)
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(SubjectScreenWrapper));
