import styled from "styled-components";
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 377px;
    width: 100%;
    height: 59px;
    padding: 0 18px;
    margin-top: 24px;
    border-radius: 16px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    background-color: ${theme.colors.white};
`;
