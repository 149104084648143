// Dependencies
import React, { FunctionComponent, useEffect, Fragment, useMemo } from "react";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/l10n/pt.js";

// Types
import { ActivityDatePickerProps } from "./activity-date-picker.types";

// Styles
import { Container, Label, UnderSelectMessage, UnderSelectMessageIcon, UnderSelectMessageWrapper } from "./activity-date-picker.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";

// Assets
import fail from "assets/img/fail.svg";

export const ActivityDatePicker: FunctionComponent<ActivityDatePickerProps> = ({
    isTimeEnabled,
    errorMessage,
    defaultTime,
    ...datePickerDefaultProps
}) => {
    useEffect(() => {
        const weekdays = document.querySelectorAll(".flatpickr-weekday");

        weekdays.forEach((weekday) => {
            const text = weekday.textContent || "";
            weekday.textContent = text.trim().slice(0, 1);
        });

        const calendars = document.querySelectorAll(".flatpickr-calendar");
        calendars.forEach((calendar) => {
            if (!calendar.querySelector(".flatpickr-time-label")) {
                const numInputWrapper = calendar.querySelector(".flatpickr-time");

                const textElement = document.createElement("p");
                textElement.setAttribute("class", "flatpickr-time-label");
                textElement.textContent = "Escolha um horário";

                calendar.insertBefore(textElement, numInputWrapper);
            }
        });
    }, []);

    const formattedDate = useMemo(() => {
        return !!isTimeEnabled ? "d/m/Y H:i" : "d/m/Y";
    }, [isTimeEnabled]);

    return (
        <Container className="webapp-date-picker" isFilled={!!datePickerDefaultProps.value}>
            <Label>{datePickerDefaultProps.placeholder}</Label>

            <Flatpickr
                data-enable-time
                options={{
                    altInput: true,
                    altFormat: "d/m/Y \\à\\s H:i\\h",
                    altInputClass: "flatpickr-input",
                    dateFormat: formattedDate,
                    enableTime: !!isTimeEnabled,
                    locale: "pt",
                    monthSelectorType: "static",
                    ...(!!defaultTime && {
                        defaultHour: defaultTime.hour,
                        defaultMinute: defaultTime.minute
                    })
                }}
                {...datePickerDefaultProps}
            />

            <ConditionallyRender
                shouldRender={!!errorMessage}
                content={
                    <Fragment>
                        <Spacing size={4} direction="vertical" />

                        <UnderSelectMessageWrapper>
                            <UnderSelectMessageIcon src={fail} />

                            <Spacing size={4} direction="horizontal" />

                            <UnderSelectMessage>{errorMessage}</UnderSelectMessage>
                        </UnderSelectMessageWrapper>
                    </Fragment>
                }
            />
        </Container>
    );
};
