import styled from "styled-components";
import { theme } from "config/theme";
import { ExerciseListKnowledgeAreaContainer } from "components/KnowledgeArea/styles";

export const ExerciseListResultKnowledgeAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};

    ${ExerciseListKnowledgeAreaContainer} {
        font-weight: bold;
    }
`;
