// Utils
import { ternary } from "utils/ternary";

// Types
import { Activity } from "store/interfaces/activity";
import { ActivityChipProps } from "components/ActivityChip/activity-chip.types";

export const getActivityStatus = (activity: Activity) => {
    const shouldRedirectToSubmissionDetails =
        ["Finished", "Released"].includes(activity.status) &&
        !!activity.submission?.status &&
        ["WaitingCorrection", "Finished"].includes(activity.submission?.status);
    const shouldRedirectToActivityExecution =
        activity.status === "Released" &&
        (!activity.submission || (!!activity.submission && !!activity.submission?.status && ["Started"].includes(activity.submission?.status)));

    const label = ternary([
        [activity.status === "AwaitingRelease", `Libera em: ${activity.releaseDate}`],
        [activity.status === "Released" && !activity.submission, "Disponível"],
        [activity.status === "Finished" && !activity.submission, "Não entregue"],
        [activity.status === "Finished" && !!activity.submission && activity.submission.status === "Started", "Não entregue"],
        // [
        //     ["Finished", "Released"].includes(activity.status) &&
        //         !!activity.submission &&
        //         activity.submission.status === "Started" &&
        //         activity.type === "ExerciseList",
        //     "0/10 respondidas"
        // ],
        [["Finished", "Released"].includes(activity.status) && !!activity.submission && activity.submission.status === "Started", "Iniciada"],
        [
            ["Finished", "Released"].includes(activity.status) && !!activity.submission && activity.submission.status === "WaitingCorrection",
            "Aguardando correção"
        ],
        [
            ["Finished", "Released"].includes(activity.status) &&
                !!activity.submission &&
                activity.submission.status === "Finished" &&
                activity.score !== undefined &&
                !!activity.submission.score,
            `${activity.submission?.score} pontos`
        ],
        [
            ["Finished", "Released"].includes(activity.status) &&
                !!activity.submission &&
                activity.submission.status === "Finished" &&
                activity.score === undefined,
            "Finalizada"
        ],
        [true, "Indisponível"]
    ]);

    const variant = ternary<ActivityChipProps["variant"]>([
        [activity.status === "AwaitingRelease", "danger"],
        [activity.status === "Released" && !activity.submission, "warning"],
        [activity.status === "Finished" && !activity.submission, "disabled"],
        [activity.status === "Finished" && !!activity.submission && activity.submission.status === "Started", "disabled"],
        // [
        //     ["Finished", "Released"].includes(activity.status) &&
        //         !!activity.submission &&
        //         activity.submission.status === "Started" &&
        //         activity.type === "ExerciseList",
        //     "cta"
        // ],
        [["Finished", "Released"].includes(activity.status) && !!activity.submission && activity.submission.status === "Started", "cta"],
        [["Finished", "Released"].includes(activity.status) && !!activity.submission && activity.submission.status === "WaitingCorrection", "info"],
        [
            ["Finished", "Released"].includes(activity.status) &&
                !!activity.submission &&
                activity.submission.status === "Finished" &&
                activity.score !== undefined &&
                !!activity.submission.score,
            "success"
        ],
        [
            ["Finished", "Released"].includes(activity.status) &&
                !!activity.submission &&
                activity.submission.status === "Finished" &&
                activity.score === undefined,
            "success"
        ],
        [true, "disabled"]
    ]);

    return {
        shouldRedirectToSubmissionDetails,
        shouldRedirectToActivityExecution,
        label,
        variant
    };
};
