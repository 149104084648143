import React from "react";
import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import { ExamResultRealizationsAccordionContainer as Container } from "./styles";

const ExamResultRealizationsAccordionSkeleton = () => {
    return (
        <Container>
            <Skeleton width="60%" height="19px" style={{ marginBottom: theme.spacing.smallX }} />
            <Skeleton width="100%" height="48px" />
        </Container>
    );
};

export default ExamResultRealizationsAccordionSkeleton;
