import React, { useMemo, useEffect, useCallback, memo, Suspense, useRef, Fragment } from "react";

import { bindActionCreators, Dispatch } from "redux";
import { Creators as videoActions } from "store/ducks/video";

import { IVideoState, IVideoActionsCreators } from "store/interfaces/IVideo";
import { connect } from "react-redux";

import { IReduxStore } from "interfaces/IReduxStore";

// Components
import Spinner from "components/Spinner";

// Assets
import { VideoContainer, VideoBlocked } from "./styles";
import LokedIcon from "prodigio-icons/web/filled/Locked";
import { DateTime } from "luxon";
import VideoPlatform from "enums/VideoPlatform";
import { theme } from "config/theme";
import ErrorBoundary from "components/ErrorBoundary";
import formatSecondsToHours from "utils/formatSecondsToHours";
import { getBrowser } from "helpers/getBrowser";
import { IPayloadRequest } from "interfaces/IRequestAction";

interface IVideoProps {
    eventStartDate?: string;
    eventEndDate?: string;
    idVideo: number;
    showPaywall?: boolean;
    zoneName?: string;
    onBlockContent?(): void;
    onMarkViewed?(): void;
    contextId?: number;
    videoState: IVideoState;
    videoActions: IVideoActionsCreators;
}

const ProdigioPlayer = React.lazy(() => import("prodigio-player/dist/js"));

export const VIDEO_PLATFORMS_WITH_PRODIGIO_PLAYER = [VideoPlatform.Prodigio, VideoPlatform.VZaar, VideoPlatform.Vimeo];

const Video = ({
    eventStartDate = "",
    idVideo,
    showPaywall = true,
    onBlockContent,
    zoneName = "UTC",
    onMarkViewed,
    contextId,
    videoState,
    videoActions,
    eventEndDate
}: IVideoProps) => {
    const startVideo = useRef<any>(null);
    const finishedVideo = useRef<any>(null);

    const { isLoading = true, url, videoEmbed, provider, blockContent, id, userIp, viewId, error } = videoState;

    useEffect(() => {
        return () => {
            videoActions.clearVideo();
        };
    }, [videoActions]);

    const handleBlockContent = useCallback(() => {
        if (!!onBlockContent && !!blockContent) {
            onBlockContent();
        }
    }, [blockContent, onBlockContent]);

    useEffect(() => {
        handleBlockContent();
    }, [handleBlockContent]);

    const requestVideo = useCallback(() => {
        if (!idVideo) {
            return;
        }

        videoActions.getVideoByPlayerRequest({
            id: idVideo,
            showPaywall,
            brandSlug: theme.project.slug
        });
    }, [idVideo, showPaywall, videoActions]);

    useEffect(() => {
        requestVideo();
    }, [requestVideo]);

    const browser = useMemo(() => getBrowser().name, []);

    const handleMarkView = useCallback(
        (time: number) => {
            if (finishedVideo.current === viewId) {
                return;
            }

            finishedVideo.current = viewId;

            const watchedTime = formatSecondsToHours(time);

            const payload = {
                method: "POST",
                endpoint: `/student/video/view/${viewId}/progresspart?completed=1`,
                body: {
                    startTime: "00:00:00",
                    endTime: watchedTime,
                    platform: "web",
                    device: browser,
                    ip: userIp
                },
                onMarkViewed: !!onMarkViewed && onMarkViewed()
            };

            videoActions.markVideoViewRequest(payload);
        },
        [browser, onMarkViewed, userIp, videoActions, viewId]
    );

    // const handleSaveProgress = useCallback(
    //     (time: number) => {
    //         if (time <= 10 || !viewId || !userIp || finishedVideo.current === viewId) {
    //             return;
    //         }

    //         const watchedTime = formatSecondsToHours(time);

    //         const payload: IPayloadRequest<any> = {
    //             endpoint: `/student/video/view/${viewId}/progresspart`,
    //             method: "POST",
    //             body: {
    //                 startTime: "00:00:00",
    //                 endTime: watchedTime,
    //                 platform: "web",
    //                 device: browser,
    //                 ip: userIp
    //             }
    //         };

    //         videoActions.saveVideoProgressRequest(payload);
    //     },
    //     [browser, userIp, videoActions, viewId]
    // );

    const handleMarkStart = useCallback(() => {
        if (!id || startVideo.current === id) {
            return;
        }

        startVideo.current = id;

        const payload: IPayloadRequest<any> = {
            method: "POST",
            body: {
                video: {
                    id
                }
            },
            headers: {
                ...(!!contextId && { "x-context-id": contextId })
            },
            endpoint: "/student/video/view"
        };

        videoActions.createVideoViewRequest(payload);
    }, [contextId, id, videoActions]);

    const memoProdigioVideo = useMemo(() => {
        if (!url) {
            return <p>Desculpe, tivemos um erro nesse vídeo.</p>;
        }

        return (
            <ErrorBoundary component={<p>Desculpe, tivemos um erro nesse vídeo.</p>}>
                <Suspense fallback={<Spinner fixed={false} size={40} />}>
                    <ProdigioPlayer
                        src={url}
                        brandColor={theme.colors.brand[300]}
                        // onProgress={(time: number) => {
                        //     handleSaveProgress(time);
                        // }}
                        onViewed={(time: number) => {
                            handleMarkView(time);
                        }}
                        onStart={() => {
                            handleMarkStart();
                        }}
                    />
                </Suspense>
            </ErrorBoundary>
        );
    }, [handleMarkStart, handleMarkView, url]);

    // Check if current time is smaller than event start date
    const currentTimeIsSmallerThenStart = useMemo(() => {
        const now = DateTime.local().setZone(zoneName);
        const startDate = DateTime.fromISO(eventStartDate).setZone(zoneName);

        return now < startDate;
    }, [eventStartDate, zoneName]);

    const isLiveFinished = useMemo(() => {
        if (!eventEndDate || (provider !== VideoPlatform.LiveStream && provider !== VideoPlatform.Vimeo)) {
            return false;
        }

        const now = DateTime.local().setZone(zoneName);
        const endDate = DateTime.fromISO(eventEndDate)
            .setZone(zoneName)
            .plus({ hours: 2 });

        return now >= endDate;
    }, [eventEndDate, provider, zoneName]);

    const withProdigioPlayer = useMemo(() => {
        if (!url) {
            return false;
        }

        if (provider === VideoPlatform.LiveStream || provider === VideoPlatform.Vimeo) {
            return !eventEndDate || isLiveFinished;
        }

        return VIDEO_PLATFORMS_WITH_PRODIGIO_PLAYER.includes(provider);
    }, [eventEndDate, isLiveFinished, provider, url]);

    if (error || !idVideo) {
        return (
            <VideoContainer>
                <p>Desculpe, não encontramos esse vídeo.</p>
            </VideoContainer>
        );
    }

    if (isLoading) {
        return (
            <VideoContainer>
                <Spinner fixed={false} size={40} />
            </VideoContainer>
        );
    }

    if (blockContent) {
        return (
            <VideoContainer>
                <VideoBlocked>
                    <LokedIcon width="60" height="60" />
                </VideoBlocked>
            </VideoContainer>
        );
    }

    if (withProdigioPlayer) {
        return <VideoContainer>{memoProdigioVideo}</VideoContainer>;
    }

    if (!!videoEmbed) {
        return (
            <VideoContainer>
                <Spinner fixed={false} size={40} />
                <div dangerouslySetInnerHTML={{ __html: videoEmbed }} />
            </VideoContainer>
        );
    }

    // Check if current time is smaller than event start date
    if (currentTimeIsSmallerThenStart) {
        return (
            <VideoContainer>
                <p>Aguarde mais um pouco. Em breve a aula estará aqui.</p>
            </VideoContainer>
        );
    }

    return (
        <VideoContainer>
            <Fragment />
        </VideoContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    videoActions: bindActionCreators(videoActions, dispatch)
});

const mapStateToProps = ({ video }: IReduxStore) => ({
    videoState: video
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Video));
