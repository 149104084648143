// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityMenuFloatContainerAlignment } from "./activity-menu.types";

export const MenuContainer = styled.div`
    position: relative;
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    cursor: pointer;
`;

type FloatContainerStyleProps = {
    alignment: ActivityMenuFloatContainerAlignment;
    width?: number;
};

export const FloatContainer = styled.div<FloatContainerStyleProps>`
    background-color: white;
    border-radius: 8px;
    padding: 4px;

    box-shadow: 0px 6px 12px 0px #0000001a;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;

    position: absolute;
    top: 32px;
    z-index: 1;

    ${({ width }) =>
        !!width &&
        css`
            width: ${width}px;
        `}

    ${({ alignment }) =>
        alignment === "left" &&
        css`
            right: 0px;
        `}

    ${({ alignment }) =>
        alignment === "right" &&
        css`
            left: 0px;
        `}
`;

export const MenuItemContainer = styled.div`
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: #ffffff;

    &:hover {
        background-color: #f0f1f7;
    }
`;

export const MenuItemLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000000;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
