import React, { useMemo } from "react";
import { IReduxStore } from "interfaces/IReduxStore";

import _get from "lodash/get";
import { EssayStatus } from "enums/EssayStatus";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { connect } from "react-redux";
import IconAttention from "prodigio-icons/web/filled/Attention";
import IconSucess from "prodigio-icons/web/filled/Sucess";
import IconError from "prodigio-icons/web/filled/Error";
import {
    ExerciseListAnswerCardKnowledgeAreaContainer,
    ExerciseListAnswerCardKnowledgeAreaQuestions
} from "components/AnswerCardKnowledgeArea/styles";
import {
    ExerciseListAnswerCardQuestionContainer,
    ExerciseListAnswerCardQuestionDetails,
    ExerciseListAnswerCardQuestionNumber,
    ExerciseListAnswerCardQuestionSeeButton,
    ExerciseListAnswerCardQuestionNote
} from "components/AnswerCardQuestion/styles";
import ExerciseListKnowledgeArea from "../KnowledgeArea";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";
import { AnswerCardEssayStatusMessage } from "./styles";

interface IProps {
    essayNote: number;
    essayStatus?: EssayStatus;
    isAnswerCardFinished: boolean;
    hasSentEssay: boolean;
    isAnswerCardCorrected: boolean;
    onClickSeeEssay?(): void;
    hasEssay: boolean;
}

const AnswerCardEssay: React.FC<IProps> = ({
    essayNote,
    essayStatus,
    isAnswerCardFinished,
    hasSentEssay,
    isAnswerCardCorrected,
    onClickSeeEssay,
    hasEssay = false
}) => {
    const withoutComposition = !hasSentEssay && isAnswerCardFinished;
    const Icon = withoutComposition ? IconError : hasSentEssay ? IconSucess : IconAttention;

    const statusMessage = useMemo(() => {
        if (hasSentEssay) {
            if (!isAnswerCardFinished) {
                return "Redação salva com sucesso.";
            }

            switch (essayStatus) {
                case EssayStatus.Corrected:
                    return "Redação corrigida.";

                default:
                    return "Redação enviada.";
            }
        }

        return isAnswerCardFinished ? "Você não realizou a redação." : "Você ainda não fez a redação.";
    }, [essayStatus, hasSentEssay, isAnswerCardFinished]);

    const showEssayNote = hasSentEssay && isAnswerCardFinished && isAnswerCardCorrected;

    if (!hasEssay) {
        return null;
    }

    return (
        <ExerciseListAnswerCardKnowledgeAreaContainer>
            <ExerciseListKnowledgeArea color="#FC9F5B" name="Redação" />
            <ExerciseListAnswerCardKnowledgeAreaQuestions>
                <ExerciseListAnswerCardQuestionContainer>
                    <ExerciseListAnswerCardQuestionNumber>
                        <Icon width={15} height={15} />
                    </ExerciseListAnswerCardQuestionNumber>

                    <AnswerCardEssayStatusMessage>
                        <p>{statusMessage}</p>
                    </AnswerCardEssayStatusMessage>

                    <ExerciseListAnswerCardQuestionDetails>
                        {showEssayNote && (
                            <ExerciseListAnswerCardQuestionNote isCorrect>
                                Nota: <span>{essayNote}</span>
                            </ExerciseListAnswerCardQuestionNote>
                        )}

                        {onClickSeeEssay && (
                            <ExerciseListAnswerCardQuestionSeeButton onClick={onClickSeeEssay}>
                                Ver
                                <ChevronRightIcon width={4} height={8} />
                            </ExerciseListAnswerCardQuestionSeeButton>
                        )}
                    </ExerciseListAnswerCardQuestionDetails>
                </ExerciseListAnswerCardQuestionContainer>
            </ExerciseListAnswerCardKnowledgeAreaQuestions>
        </ExerciseListAnswerCardKnowledgeAreaContainer>
    );
};

const mapStateToProps = ({ exerciseList, answerCard }: IReduxStore) => ({
    essayNote: _get(answerCard, "essayCorrection.revisions[0].score", 0),
    essayStatus: answerCard?.essayCorrection?.status,
    isAnswerCardFinished: answerCard?.answerCard?.status >= AnswerCardStatus.Done,
    hasSentEssay: !!answerCard?.answerCard?.composition?.idComposition || !!answerCard?.essayCorrection?.idComposition,
    isAnswerCardCorrected: answerCard?.answerCard?.status === AnswerCardStatus.Corrected,
    hasEssay: !!exerciseList?.essayTheme?.idTheme
});

export default connect(mapStateToProps)(AnswerCardEssay);
