// Dependencies
import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1360px;
    margin: 0 auto;
`;

type ContentWrapperStyleProps = {
    hasFixedMobileContent: boolean;
};

export const ContentWrapper = styled.div<ContentWrapperStyleProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;

    ${({ hasFixedMobileContent }) =>
        hasFixedMobileContent
            ? css`
                  padding: 24px 0 72px;
              `
            : css`
                  padding: 24px 16px;
              `}

    @media (min-width: 768px) {
        padding: 24px;
    }

    @media (min-width: 1024px) {
        padding: 40px 24px;
    }
`;

type TitleAndActionButtonsWrapperStyleProps = {
    hasFixedMobileContent: boolean;
};

export const TitleAndActionButtonsWrapper = styled.div<TitleAndActionButtonsWrapperStyleProps>`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ hasFixedMobileContent }) =>
        hasFixedMobileContent &&
        css`
            padding: 0 16px;

            @media (min-width: 768px) {
                padding: 0;
            }
        `}
`;

type EmptyStateWrapperStyleProps = {
    hasFixedMobileContent: boolean;
};

export const EmptyStateWrapper = styled.div<EmptyStateWrapperStyleProps>`
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (min-width: 768px) {
        gap: 80px;
    }

    ${({ hasFixedMobileContent }) =>
        hasFixedMobileContent &&
        css`
            padding: 0 16px;

            @media (min-width: 768px) {
                padding: 0;
            }
        `}

    > .webapp-activity-card {
        width: 100%;

        @media (min-width: 768px) {
            width: 300px;
        }
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
`;

export const Title = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #232326;
`;

export const Description = styled.p`
    font-size: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #83899e;
`;

export const ActionButtonsCompositionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;

    > button {
        flex-shrink: 0;
    }
`;

type ActivitiesContentCompositionsWrapperStyleProps = {
    hasFixedMobileContent: boolean;
};

export const ActivitiesContentCompositionsWrapper = styled.div<ActivitiesContentCompositionsWrapperStyleProps>`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;

    ${({ hasFixedMobileContent }) =>
        hasFixedMobileContent &&
        css`
            padding: 0 16px;

            @media (min-width: 768px) {
                padding: 0;
            }
        `}
`;

export const FixedMobileContentWrapper = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px;
    background-color: #fff;
`;

export const BreadcrumbAndTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
