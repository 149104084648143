import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

const ExamDetails = () => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return <Redirect to={`/app/curso/${courseSlug}/simulados`} />;
};

export default ExamDetails;
