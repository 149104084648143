import { call, put, takeLatest } from "redux-saga/effects";

import api from "services/api";
import { Creators as weekplansActions, Types as weekPlansTypes } from "store/ducks/weekPlans";
import { Creators as bookletModuleActions } from "store/ducks/booklet-module";
import { Creators as subjectActions } from "store/ducks/subject";
import { Creators as authActions } from "store/ducks/auth";

import { ReduxAction } from "store";

// Lesson Plan
function* getLessonPlans(action: ReduxAction<any>) {
    try {
        const { data } = yield call(api.get, `/student/lessonplaneducational`);

        const currentLessonPlan = data.find((lessonPlan: any) => lessonPlan.isCurrent) ?? data[0];

        yield put(weekplansActions.getWeekPlansCoursesSuccess(data));

        yield put(weekplansActions.setLessonPlanEducation(currentLessonPlan));
    } catch (error) {
        yield put(weekplansActions.getWeekPlansCoursesFailure());
    }
}

// Progress
function* getProgress(action: ReduxAction<any>) {
    try {
        const { payload } = action;

        if (!payload?.id) {
            throw new Error("Não foi possível carregar as semanas do plano de estudos");
        }

        const { data } = yield call(api.get, `/student/lessonplaneducational/${payload.id}/progress`);

        yield put(weekplansActions.getWeekPlansProgressSuccess(data));
    } catch (error) {
        yield put(weekplansActions.getWeekPlansProgressFailure());
    }
}

// CurrentModuleProgress
function* getCurrentModuleProgress(action: ReduxAction<any>) {
    try {
        const { payload } = action;

        if (!payload?.id) {
            throw new Error("Não foi possível carregar as semanas do plano de estudos");
        }

        const { data } = yield call(api.get, `/student/lessonplanmoduleeducational/${payload.id}/progress`);

        yield put(weekplansActions.getWeekPlansCurrentModuleProgressSuccess(data));
    } catch (error) {
        yield put(weekplansActions.getWeekPlansCurrentModuleProgressFailure());
    }
}

// Weeks
function* getWeek(action: ReduxAction<any>) {
    try {
        const { payload } = action;

        if (!payload?.id) {
            throw new Error("Não foi possível carregar as semanas do plano de estudos");
        }

        const id = payload.id;

        const { data } = yield call(api.get, `student/lessonplanmoduleeducational`, {
            headers: {
                "x-where": `lessonPlanEducational=${id}`
            }
        });

        yield put(weekplansActions.getWeekPlansWeeksSuccess(data));
    } catch (error) {
        yield put(subjectActions.getSubjectModuleFailure(error?.response?.data));
        yield put(weekplansActions.getWeekPlansWeeksFailure());
    }
}

// Modules
function* getLessonPlanEducationalModules(action: ReduxAction<any>) {
    try {
        const { payload } = action;

        if (!payload?.id) {
            throw new Error("Erro interno do servico");
        }

        const { data } = yield call(api.get, `student/lessonplanmoduleeducational/${payload.id}/item`);

        if (!data) {
            throw new Error("Não foi possível carregar as semanas do plano de estudos");
        }

        const newData = data.map((item: any) => {
            return {
                ...item.bookletModule,
                idItem: item.id
            };
        });

        yield put(weekplansActions.getWeekPlansModulesSuccess(newData));
    } catch (error) {
        yield put(weekplansActions.getWeekPlansModulesFailure());
    }
}

function* setWeekPlansCurrentModule(action: ReduxAction<any>) {
    try {
        const { payload } = action;

        if (!payload) {
            throw new Error("Não foi possível carregar as semanas do plano de estudos");
        }

        yield put(subjectActions.setSubject(payload.subject));

        yield put(bookletModuleActions.setCurrentModule(payload));

        yield put(bookletModuleActions.getCurrentModuleRequest(payload));
    } catch (error) {
        console.log(error);
    }
}

// Menu Navigation

function* getWeekPlansIsPublishedRequest(action: ReduxAction<any>) {
    try {
        const { data } = yield call(api.get, `student/lessonplaneducational/ispublished`);

        yield put(weekplansActions.getWeekPlansIsPublishedSuccess(data));

        yield put(authActions.getMenuRequest());
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_WEEKS_REQUEST, getWeek),
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_PROGRESS_REQUEST, getProgress),
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_COURSES_REQUEST, getLessonPlans),
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_CURRENT_MODULE_REQUEST, getLessonPlanEducationalModules),
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_CURRENT_MODULE_PROGRESS_REQUEST, getCurrentModuleProgress),
    takeLatest(weekPlansTypes.SET_WEEK_PLANS_CURRENT_MODULE, setWeekPlansCurrentModule),
    takeLatest(weekPlansTypes.GET_WEEK_PLANS_IS_PUBLISHED_REQUEST, getWeekPlansIsPublishedRequest)
];
