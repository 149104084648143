import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";
import { PageContainer } from "assets/styles/global";
import { SpinnerWrap } from "components/Spinner/styles";

export const ExtraExerciseListsContainer = styled(PageContainer)`
    ${SpinnerWrap} {
        margin: ${theme.spacing.largeXX} 0;
    }
`;

export const SubjectsHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin: ${theme.spacing.large} 0;
    }

    h1 {
        color: ${theme.colors.base[500]};
        font-weight: bold;
        font-size: ${theme.typography.sizes.large};
        font-family: "Nunito", sans-serif;
    }
`;
