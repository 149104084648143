import styled from "styled-components";
import { theme } from "config/theme";
import TextField from "components/Form/TextField";
import Select from "components/Select";

export const PersonalDataInputRow = styled.div`
    margin-top: ${theme.spacing.small};
`;

export const LastStudentInputRow = styled.div`
    margin: ${theme.spacing.small} 0;
`;

export const DropdownContainer = styled.div`
    position: absolute;
`;

export const UpperCaseTextField = styled(TextField)`
    text-transform: uppercase;
`;

export const SelectWrapper = styled(Select)`
    .react-select__control {
        border-radius: 5px;
        padding: 12px 8px;
    }
`;
