import styled from "styled-components";
import { theme } from "config/theme";
import { SpinnerWrap } from "components/Spinner/styles";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";

export const ExamScreenItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.spacing.smallX};

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
        margin-bottom: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-bottom: unset;
    }

    > ${ButtonWrapper} {
        margin-top: ${theme.spacing.small};

        ${MediaQueries.BIGGER_THAN_SMALL} {
            display: none;
        }
    }
`;

export const ExamScreenItemTitle = styled.h2`
    color: ${theme.colors.base[500]};
    font-size: ${theme.typography.sizes.medium};
    margin-bottom: ${theme.spacing.small};
    flex: 1;
`;

export const ExamScreenItemHeader = styled.div`
    display: flex;

    ${SpinnerWrap} {
        width: 20px;
        height: 20px;
    }

    > ${ButtonWrapper} {
        display: none;
        padding: 0;
        border: 0;
        text-align: left;
        background: transparent;

        :hover {
            background: transparent;

            > svg {
                color: ${theme.colors.base[400]};
            }
        }

        > svg {
            margin-right: unset;
            margin-left: ${theme.spacing.smallX};
        }

        ${MediaQueries.BIGGER_THAN_SMALL} {
            display: flex;
        }
    }
`;

export const ExamScreenItemDays = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ExamScreenItemSeeResolutionIcon = styled(ChevronRight)`
    color: ${theme.colors.base[300]};
    margin-right: ${theme.spacing.smallX};
`;
