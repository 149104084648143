import React, { forwardRef } from "react";

import * as S from "./styles";
interface IButtonProps {
    onClick: () => void;
    children: React.ReactNode;
    active?: boolean;
    style?: any;
}

const EssaySliderButton = forwardRef<HTMLButtonElement, IButtonProps>(({ onClick, children, active = false, style }, ref) => {
    return (
        <S.EssaySliderButton variant="base" onClick={onClick} active={active} ref={ref} style={style}>
            {children}
        </S.EssaySliderButton>
    );
});

export default EssaySliderButton;
