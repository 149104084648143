// Dependencies
import * as yup from "yup";

export const validationSchema = yup
    .object({
        score: yup.number().required("Campo obrigatório."),
        comment: yup
            .string()
            .trim()
            .optional()
    })
    .required();

export type ValidationSchema = yup.InferType<typeof validationSchema>;
