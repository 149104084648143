import styled, { css } from "styled-components";

export const Container = styled.div`
    width: 100%;

    > .ck .ck-reset .ck-editor .ck-rounded-corners {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #83899e;
    }

    > .ck-blurred .ck .ck-content .ck-editor__editable .ck-rounded-corners .ck-editor__editable_inline {
        border: none !important;
    }
`;

export const InfoMessagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding-top: 4px;

    @media (min-width: 768px) {
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
`;

type WordLimitterLabelProps = {
    hasExceeded: boolean;
};

export const WordLimitterLabel = styled.p<WordLimitterLabelProps>`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    color: #83899e;

    ${({ hasExceeded }) =>
        hasExceeded &&
        css`
            color: #e40000;
        `}
`;

export const ErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    > img {
        margin-bottom: 2px;
    }
`;

export const ErrorMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ErrorMessageLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #e40000;
`;
