// Dependencies
import styled from "styled-components";

export const ActivityListContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
`;

export const ActivityListTitle = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #232326;
`;

export const ActivityListItemCompositionsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;

    > div {
        flex: 1;
    }

    @media (min-width: 768px) {
        flex-direction: row;
    }
`;

export const ActivityListItemContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
`;

export const ActivityListItemTitle = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #232326;
`;

export const ActivityListItemDescription = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #83899e;
`;
