import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const StudentAreaScreenSignedCoursesCardDurationContainer = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    padding: ${theme.spacing.smallXX} 0;
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: column;
        margin-top: ${theme.spacing.large};
    }
`;

export const StudentAreaScreenSignedCoursesCardDurationLine = styled.div`
    position: relative;
    width: 1px;
    height: 56px;
    background-image: linear-gradient(${theme.colors.base[200]} 50%, ${theme.colors.white} 50%);
    background-size: 1px 9px;
    display: flex;
    justify-content: center;

    :before {
        content: "";
        position: absolute;
        top: 0;
        width: 12px;
        height: 12px;
        background: ${theme.colors.system.success[200]};
        border: 3px solid ${theme.colors.system.success[100]};
        border-radius: 100%;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            left: 0;
            top: unset;
        }
    }

    :after {
        content: "";
        position: absolute;
        bottom: -12px;
        width: 12px;
        height: 12px;
        background: ${theme.colors.system.danger[200]};
        border: 3px solid ${theme.colors.system.danger[100]};
        border-radius: 100%;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            right: -12px;
            top: unset;
            bottom: 0;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: unset;
        background-size: 9px 1px;
        height: 12px;
        background-image: linear-gradient(to right, ${theme.colors.base[200]} 50%, ${theme.colors.white} 50%);
        margin: 0 ${theme.spacing.large};
        margin-bottom: ${theme.spacing.small};
        background-repeat: repeat-x;
        background-position: center;
    }
`;

const legendsCSS = css`
    color: ${theme.colors.base[300]};
    font-size: ${theme.typography.sizes.smallX};
    display: flex;
    align-items: center;
    position: absolute;

    span {
        color: ${theme.colors.base[400]};
        padding: 0 ${theme.spacing.smallXX};
    }
`;

export const StudentAreaScreenSignedCoursesCardDurationDates = styled.div`
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: unset;
        height: unset;
        width: 100%;
    }
`;

export const StudentAreaScreenSignedCoursesCardDurationDays = styled.div`
    width: 50%;
    position: absolute;
    top: 0;
    left: 0;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: relative;
        width: 100%;
        padding: 0 ${theme.spacing.large};
    }
`;

export const StudentAreaScreenSignedCoursesCardDurationMissingDays = styled.p<{ top: number }>`
    ${legendsCSS};

    right: 0;
    top: ${({ top }) => `${top}px`};
    text-align: right;
    justify-content: flex-end;
    padding-right: ${theme.spacing.small};

    transition: top 0.5s ease, left 0.4s ease;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        white-space: nowrap;
        right: unset;
        left: ${({ top }) => `${top}px`};
        justify-content: center;
        top: unset;
        bottom: ${theme.spacing.small};
        padding-right: unset;
    }
`;

export const StudentAreaScreenSignedCoursesCardDurationDatesLegend = styled.p<{ direction: "top" | "bottom" }>`
    ${legendsCSS};

    left: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: unset;
        padding-left: unset;
        position: unset;
        flex: 1;
    }

    ${({ direction }) =>
        direction === "top"
            ? css`
                  top: 0;

                  ${MediaQueries.BIGGER_THAN_SMALL} {
                      left: 0;
                  }
              `
            : css`
                  bottom: -12px;

                  ${MediaQueries.BIGGER_THAN_SMALL} {
                      right: 0;
                      justify-content: flex-end;
                  }
              `};
`;

export const StudentAreaScreenSignedCoursesCardDurationPointer = styled.small`
    width: 12px;
    height: 2px;
    background: ${theme.colors.system.warning[300]};
    position: absolute;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;

    :after {
        content: "";
        position: absolute;
        right: -3px;
        width: 6px;
        height: 6px;
        background: ${theme.colors.system.warning[300]};
        border-radius: 100%;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: absolute;
        bottom: -${theme.spacing.small};
        transform: rotate(90deg);
        right: unset;
        left: ${theme.spacing.large};

        :after {
            right: -4px;
            width: 6px;
            height: 6px;
        }
    }
`;
