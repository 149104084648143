// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

// Assets
import vector from "./assets/vectorAcert.svg";

// Components
import { WebpButton } from "components/WebpButton";

type WebpStudyPlanDetailProgressProps = {
    labelButton: string;
    numberPercent?: number;
    activeButton: boolean;
    onClickedButton: () => void;
};

export const WebpStudyPlanDetailProgress: FunctionComponent<WebpStudyPlanDetailProgressProps> = ({
    activeButton,
    onClickedButton,
    labelButton,
    numberPercent
}) => {
    const handleClick = () => {
        onClickedButton();
    };

    return (
        <Container isComplete={!!numberPercent && numberPercent >= 100}>
            <WebpButton
                className="webp-button"
                onClick={() => handleClick()}
                size="full"
                variant="outline-primary"
                active={activeButton}
                contentButtonComposition={labelButton}
            ></WebpButton>

            {numberPercent && (
                <div>
                    <p>{numberPercent}%</p>
                    <img src={vector} alt="simbolo de certo"></img>
                </div>
            )}
        </Container>
    );
};
