import { theme } from "config/theme";
import { fadePulse } from "assets/styles/animations";
import BGHighlight from "assets/img/bg-highlight.png";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { TeacherName } from "components/Teacher/styles";
import { BadgeContainer } from "components/Badge/styles";
import { BodyText2, Label, Micro, TitleH5, Button3 } from "assets/styles/global";
import { EventCardExpandedTeachers } from "components/Modal/EventCardExpanded/styles";

export const EventCardOuter = styled.div<{ isHighlightedType?: string }>`
    position: relative;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    ${({ isHighlightedType }) => {
        if (isHighlightedType) {
            return css`
                margin-bottom: 16px;
                padding-bottom: 16px;
            `;
        }

        return undefined;
    }}
`;

export const EventCardHightlightText = styled.span`
    ${Button3};
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
    text-align: center;
    z-index: 2;

    img {
        margin-right: 4px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const EventCardHightlight = styled.div<{ isHighlightedType?: string }>`
    padding: 6px 0;

    &:before {
        background: ${theme.colors.base[100]} url(${BGHighlight}) left top repeat;
        border-radius: 8px;
        content: "";
        opacity: 0;
        height: 100%;
        left: -16px;
        position: absolute;
        top: 0;
        width: calc(100% + 32px);
        z-index: -1;
    }

    ${({ isHighlightedType }) => {
        if (isHighlightedType === "isHappening") {
            return css`
                ${EventCardHightlightText} {
                    animation: ${fadePulse} 1.5s linear infinite;
                }

                &:before {
                    animation: ${fadePulse} 1.5s linear infinite;
                    opacity: 1;
                }
            `;
        }

        if (isHighlightedType === "isNextEvent") {
            return css`
                &:before {
                    opacity: 0;
                }
            `;
        }

        return undefined;
    }}

    ${MediaQueries.BIGGER_THAN_SMALL} {
        &:before {
            left: -32px;
            width: calc(100% + 64px);
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        &:before {
            left: -16px;
            width: calc(100% + 32px);
        }
    }
`;

interface EventCardContainerStyleProps {
    isPassed: boolean;
    isHappening: boolean;
}

export const EventCardContainer = styled.button<EventCardContainerStyleProps>`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-left: 6px solid
        ${({ isPassed, isHappening }) => (isPassed ? theme.colors.secondary[300] : isHappening ? theme.colors.brand[300] : theme.colors.base[200])};
    border-radius: 4px;
    padding: 8px 10px;
    text-align: left;
    width: 100%;

    ${TeacherName} {
        display: none;
    }

    ${EventCardExpandedTeachers} {
        align-items: center;
        display: flex;
        margin: 0;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: unset;
        border-left-width: ${theme.spacing.smallX};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${theme.spacing.small};
        width: 100%;
    }
`;

export const EventType = styled.strong`
    ${Label};
    color: ${theme.colors.base[400]};
    display: block;
    line-height: 1;
    margin-bottom: 10px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
        margin-bottom: 0;
    }
`;

export const EventTitle = styled(TitleH5)`
    margin-bottom: 10px;
    color: ${theme.colors.base[500]};
`;

export const EventSubject = styled.span`
    ${Micro};
    color: ${theme.colors.base[400]};
    display: block;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
    }
`;

export const EventBadge = styled.span`
    background: ${theme.colors.system.success[100]};
    color: ${theme.colors.system.success[500]};
    border-radius: ${theme.spacing.smallXX};
    padding: ${theme.spacing.smallXX};
    line-height: 1;
    margin-right: 0 !important;
`;

export const EventSchedule = styled(EventType)`
    ${Label};
    align-items: center;
    display: flex;
    margin: 0;

    span {
        margin: 0 ${theme.spacing.smallXX};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        font-size: 12px;
        font-weight: bold;
    }
`;

export const EventCardWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:first-child {
        margin-bottom: 18px;
    }
`;

export const EventCardHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: ${theme.spacing.smallX};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    ${EventType} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
    }
`;

export const EventCardTag = styled(BadgeContainer)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;
    max-width: max-content;
    background: ${theme.colors.base[200]};
    color: ${theme.colors.base[400]};

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin: 0 ${theme.spacing.smallX} 0 0;
    }
`;

export const LoadingMessage = styled.p`
    bottom: 14px;
    color: #9b9fb0;
    font-size: 12px;
    font-style: italic;
    position: absolute;
    right: 50px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        bottom: unset;
        right: 200px;
        top: 18px;
    }
`;
