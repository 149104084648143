import { theme } from "config/theme";
import styled from "styled-components/macro";
import { TitleH4 } from "assets/styles/global";
import { RadioLabel } from "components/Form/Radio/styles";

export const RadioGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const RadioGroupItem = styled.button.attrs({ type: "button" })<{ active: boolean }>`
    display: flex;
    align-items: center;
    color: ${({ active }) => (active ? theme.colors.brand[300] : theme.colors.base[400])};
    cursor: pointer;

    ${RadioLabel} {
        margin-right: ${theme.spacing.smallX};
    }

    :not(:last-child) {
        padding-bottom: ${theme.spacing.smallX};
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const RadioGroupItemTitle = styled.p`
    ${TitleH4};

    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;
