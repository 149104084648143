import styled, { css } from "styled-components";
import { MediaQueriesManual } from "assets/styles/settings";
import { theme } from "config/theme";
import vectorAcert from "./assets/vectorAcert.svg";
import alertHover from "./assets/alertHover.svg";
import alertDefault from "./assets/alertDefault.svg";

interface Props {
    dotActive?: boolean;
    show?: boolean;
}

export const Container = styled.div`
    display: flex;
`;

export const Header = styled.div`
    .content-header-weeks {
        margin: 32px 0 8px 0;
    }

    .content-header-titles {
        display: flex;
        margin-bottom: 8px;
        gap: 8px;
        align-items: center;
    }

    .title-header-weeks {
        font-family: Nunito;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: #3a3e47;
    }

    .title-and-alert {
        display: flex;
        align-items: flex-start;
        position: relative;
    }

    .title-and-buttons {
        margin: 40px 0 16px;
        display: grid;
        grid-template-columns: 120px 50px;
        grid-template-rows: 1fr 1fr;
        gap: 0px 0px;
        grid-auto-flow: row;
        grid-template-areas:
            "title-header-weeks buttons-left-rigth"
            "desc-completed-weeks desc-completed-weeks";
    }

    #title-header-weeks {
        margin: 0;
        grid-area: title-header-weeks;
        display: flex;
        align-items: center;
    }

    .desc-completed-weeks {
        //styleName: Title-XSS;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: #666b7b;
    }

    #desc-completed-weeks {
        margin: 0 0 16px 0;

        grid-area: desc-completed-weeks;
    }

    .percents {
        //styleName: Button-2;
        font-family: Nunito;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.005em;
        color: #3a3e47;
        margin-left: 8px;
    }

    .buttons-left-rigth {
        width: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        grid-area: buttons-left-rigth;

        button {
            transform: scale(1, 2);
            color: ${theme.colors.base[300]};

            :hover,
            :active {
                color: ${theme.colors.brand[300]};
            }
        }
    }

    .divider {
        display: flex;
        border: 1px solid #f0f1f7;
        width: 100%;
        margin-bottom: 20px;
    }

    .content-header-buttons {
        display: none;
    }

    ${MediaQueriesManual(1366)} {
        .content-header-weeks {
            display: flex;
            justify-content: space-between;
        }

        .content-header-titles-progressBar {
            width: 544px;
        }

        .content-header-titles {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
        }

        .title-and-buttons {
            max-width: 544px;
            grid-template-columns: 120px 375px;
            grid-template-rows: 1fr;
            gap: 0px 0px;
            grid-template-areas: "title-header-weeks desc-completed-weeks buttons-left-rigth";
        }

        #desc-completed-weeks {
            display: flex;
            align-items: center;
            margin: 0;

            b {
                margin: 0 0 0 4px;
            }
        }

        .content-header-buttons {
            display: flex;
            width: 352px;
            justify-content: end;
            gap: 32px;
        }
    }
`;

export const Weeks = styled.div`
    margin-bottom: 25px;

    .webp-nav {
        gap: 8px;
    }

    ${MediaQueriesManual(1366)} {
        .webp-nav {
            width: 544px;
            padding: 16px 0 24px 19.5px;
            box-sizing: border-box;
        }
    }
`;

export const DaysWeek = styled.div<Props>`
    display: flex;
    height: 100%;
    box-sizing: border-box;

    .dots-left {
        height: 100%;
        padding: 50px 0 220px 0;
        display: flex;
        align-items: center;

        div {
            height: 100%;
            border: 1px dashed #ced0dd;
            margin: 0 18px 0 0;
        }
    }

    .content-days-week {
        width: 100%;
    }

    .div-step-group-content {
        margin: 44px 0 32px 0;

        :last-child {
            margin: 0 0 96px;
        }

        :nth-child(1) {
            margin: 0 0 32px;
        }

        .dot-container {
            min-height: 60px;
        }

        .dot-dashed {
            height: 123%;
        }
    }

    .img-labelPeriod {
        display: flex;
        align-items: center;
        margin: 0 0 6px 0px;
    }

    .img-period {
        margin: 0 8px 0 0;
        width: 26px;
    }

    .labelPeriod {
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        color: #666b7b;
    }

    .webp-nav-item {
        margin: 18px 0 0 0;
        position: relative;
        width: 100%;

        :before {
            content: "";
            border: 1px solid #c6e0fe;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px #4b9eff;
            width: 16px;
            height: 16px;
            position: absolute;
            border-radius: 50%;
            left: -27px;
            top: 0px;
            background: #ffffff;

            ${(props) =>
                props.dotActive &&
                css`
                    background-image: url(${vectorAcert});
                    box-shadow: 0px 0px 4px #50c79d;
                    background-size: 16px;
                    background-position: 0px -3px;
                    background-repeat: no-repeat;
                    background-color: #50c79d;
                `}
        }

        :nth-child(1) {
            margin: 0;
        }
    }

    .webp-progress-bar {
        margin-right: 16px;
    }

    ${MediaQueriesManual(768)} {
        .dots-left {
            padding: 50px 0 250px 0;
        }

        .div-step-group-content {
            :last-child {
                margin: 10px 0 160px 0;
            }
        }
    }

    ${MediaQueriesManual(1366)} {
        width: 544px;

        .webp-nav-item {
            width: 500px;
        }

        webp-step-dot {
            margin: 0 0 16px 0;
        }
    }

    ${MediaQueriesManual(1400)} {
        .webp-nav-item {
            width: 500px;
        }
    }
`;

export const ModuleContentMobile = styled.div`
    height: 100vh;
    width: 100vw;
    margin: 0 auto;

    position: absolute;
    z-index: 100;
    top: 0px;
    left: 0px;

    .webp-card {
        width: 100%;
        height: 100%;
    }

    .botton-close {
        margin: 0 0 8px auto;
        display: flex;
        font-size: 16px;
        color: #25272d;
    }

    img {
        width: 500px;
    }

    ${MediaQueriesManual(768)} {
        display: none;
    }
`;

export const ModuleContent = styled.div`
    display: none;

    width: 100%;
    max-width: 544px;
    margin: 0 auto;

    position: sticky;
    top: 10px;
    left: 0px;

    .webp-card {
        width: 100%;
        height: 100%;
    }

    img {
        width: 500px;
    }

    ${MediaQueriesManual(768)} {
        display: flex;
    }

    ${MediaQueriesManual(1366)} {
        transform: translate(0px, -120px);
        top: 130px;
    }
`;

export const StudyPlanDetailContent = styled.div<Props>`
    width: 100vw;
    height: 100%;
    display: none;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    top: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.5);

    ${(props) =>
        props.show &&
        css`
            display: flex;
        `}

    .wrapper-content {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 100;
        top: 0px;
        right: 0px;
        background: #ffffff;
        padding: 0px 20px 24px 24px;
        overflow: auto;
    }

    .buttonclose-and-title {
        padding: 24px 0 40px;
        position: sticky;
        top: 0px;
        background: #ffffff;
    }

    .botton-close {
        margin: 0 0 8px auto;
        display: flex;
        font-size: 16px;
        color: #25272d;
    }

    .title-StudyPlanDetailContent {
        font-family: Nunito;
        font-weight: bold;
        font-size: 20px;
        line-height: 28px;
        color: #666b7b;
    }

    .wrapper-border {
        padding-bottom: 10px;
        margin: 10px 0;
        border-bottom: 0.5px solid #ced0dd;
        width: 100%;

        &:first-child {
            margin: 0 0 10px;
        }

        &:last-child {
            border: none;
        }
    }

    webp-study-plan-detail-progress {
        margin: 0 0 10px;
    }

    ${MediaQueriesManual(1366)} {
        .wrapper-content {
            max-width: 448px;
            height: 100vh;
        }

        .buttonclose-and-title {
            padding: 24px 0;
        }

        .wrapper-border {
            margin: 24px 0;

            &:nth-child(1) {
                margin: 0 0 24px;
            }
        }
    }
`;

export const ButtonsMovePage = styled.div`
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    position: sticky;
    bottom: 0;
    padding: 20px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;

    ${MediaQueriesManual(1366)} {
        display: none;
    }
`;

export const ActionButtonHeaderLink = styled.div<{ withHover?: boolean; showToolTip?: boolean }>`
    cursor: pointer;
    justify-content: flex-start;
    margin: auto auto auto 16px;
    position: relative;
    display: flex;
    flex-direction: column;

    .icon {
        background-image: url(${alertDefault});
        background-repeat: no-repeat;
        background-position: left;
        background-size: 20px 20px;
        height: 20px;
        width: 20px;

        ${MediaQueriesManual(1200)} {
            &:hover {
                background-image: url(${alertHover});
            }
        }
    }

    img {
        width: 24px;
    }

    span {
        display: none;
    }

    ${MediaQueriesManual(1200)} {
        &:hover {
            span {
                display: unset;
            }
        }
    }

    ${({ showToolTip }) => {
        if (showToolTip) {
            return css`
                .icon {
                    background-image: url(${alertHover});
                }

                span {
                    display: block;
                }
            `;
        } else {
            return css`
                .icon {
                    background-image: url(${alertDefault});
                }

                span {
                    display: none;
                }
            `;
        }
    }}
`;

export const ToolTipSpan = styled.span`
    z-index: 2;
    text-align: center;
    box-shadow: ${theme.shadow[1]};
    font-size: ${theme.typography.sizes.small};
    padding: ${theme.spacing.smallXX} ${theme.spacing.small};
    font-weight: 600;
    white-space: nowrap;
    position: absolute;
    top: 25px;
    transform: translateX(-60%);
    height: fit-content;
    width: fit-content;
    background: aliceblue;

    ${MediaQueriesManual(768)} {
        transform: translateX(-30%);
    }
`;
