// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #3a3e47;
`;

export const Description = styled.div`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #505462;
`;

export const EvolutionCardsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 8px;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px 32px;
    }

    @media (min-width: 1024px) {
        grid-gap: 16px;
    }

    @media (min-width: 1366px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1440px) {
        grid-column-gap: 32px;
    }
`;
