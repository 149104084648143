// Dependencies
import React, { ReactNode, useRef, forwardRef } from "react";

// Components
import { WebpImageDoubt } from "components/WebpImageDoubt";

// Styles
import { Container } from "./styles";

// Assets
import fileArrowDown from "./assets/fileArrowDown.svg";

type WebpDoubtResponseProps = {
    onClickContent?: () => void;
    onClickAttachment?: () => void;
    attachment?: string;
    nameSender?: string;
    photoUser?: string;
    time?: string;
    content: any;
    audioPlayerComposition?: ReactNode;
};

export const WebpDoubtResponse = forwardRef<HTMLDivElement, WebpDoubtResponseProps>(
    ({ onClickContent, onClickAttachment, attachment, content, nameSender, photoUser, time, audioPlayerComposition }, ref) => {
        const divContent = useRef<HTMLDivElement>(null);

        const handleClickContent = () => {
            if (!!onClickContent) {
                onClickContent();
            }
        };

        return (
            <Container ref={ref}>
                <div className="border" />

                <div className="header">
                    <div className="image-and-name">
                        <WebpImageDoubt photoUser={photoUser}></WebpImageDoubt>

                        <div className="name-and-lesson">
                            <h2 className="name-sender">{nameSender}</h2>
                        </div>
                    </div>

                    <p className="time">{time}</p>
                </div>

                <div
                    ref={divContent}
                    className="content"
                    id="content"
                    dangerouslySetInnerHTML={{ __html: content }}
                    onClick={() => handleClickContent()}
                />

                {audioPlayerComposition}

                {!!attachment && (
                    <div className="likes-attachment-wrapper">
                        <button className="button-attachment" onClick={() => !!onClickAttachment && onClickAttachment()}>
                            <img alt="anexo" src={fileArrowDown} /> Download do anexo
                        </button>
                    </div>
                )}
            </Container>
        );
    }
);
