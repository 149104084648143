import styled from "styled-components";
import { theme } from "config/theme";

export const AnswerCardEssayStatusMessage = styled.div`
    padding: ${theme.spacing.smallX} ${theme.spacing.small};
    flex: 1;

    > p {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
    }
`;
