import React, { memo, useMemo, useCallback, useState, useRef } from "react";

import * as S from "./styles";
import Donuts from "../Donuts";

import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";

import { SubjectScreenDetailsDesktopSelectFrontSkeletonModules, SubjectScreenDetailsDesktopSelectFrontSkeletonStats } from "./skeleton";
import { ISelectSubjectFront } from "../SelectFront";
import { ISubjectFront } from "store/interfaces/ISubject";
import { theme } from "config/theme";

const SubjectScreenDetailsDesktopSelectFront = ({
    isLoadingFronts = false,
    isLoadingProgress = false,
    fronts = [],
    currentFront,
    onClickFront,
    subjectProgress,
    messageProgress = "módulos concluídos"
}: ISelectSubjectFront) => {
    const [activeDropdown, setActiveDropdown] = useState(false);

    const refDropdown = useRef<HTMLDivElement>(null);

    const handleClickFront = useCallback(
        (front: ISubjectFront) => {
            setActiveDropdown(false);

            if (front?.id === currentFront?.id) {
                return;
            }

            onClickFront(front);
        },
        [currentFront, onClickFront]
    );

    const progress = useMemo(() => {
        if (currentFront?.id === null) {
            return subjectProgress;
        }

        return currentFront?.progress;
    }, [currentFront, subjectProgress]);

    return (
        <S.SubjectScreenDetailsDesktopSelectFrontContainer>
            <S.SubjectScreenDetailsDesktopSelectFrontDropdown onClick={() => setActiveDropdown(!activeDropdown)}>
                {isLoadingFronts ? (
                    <SubjectScreenDetailsDesktopSelectFrontSkeletonModules />
                ) : (
                    <S.SubjectScreenDetailsDesktopSelectFrontDropdownActiveItem active={activeDropdown}>
                        <span>{currentFront?.title || ""}</span>

                        <ChevronDown width={8} height={4} />
                    </S.SubjectScreenDetailsDesktopSelectFrontDropdownActiveItem>
                )}

                {activeDropdown && (
                    <S.SubjectScreenDetailsDesktopSelectFrontDropdownItems ref={refDropdown}>
                        {fronts.map((front, index) => (
                            <S.SubjectScreenDetailsDesktopSelectFrontDropdownItem
                                key={`front_${index}`}
                                onClick={() => handleClickFront(front)}
                                active={currentFront?.id === front.id}
                            >
                                {front.title}
                            </S.SubjectScreenDetailsDesktopSelectFrontDropdownItem>
                        ))}
                    </S.SubjectScreenDetailsDesktopSelectFrontDropdownItems>
                )}
            </S.SubjectScreenDetailsDesktopSelectFrontDropdown>

            <S.SubjectScreenDetailsDesktopSelectFrontStats>
                {isLoadingProgress && <SubjectScreenDetailsDesktopSelectFrontSkeletonStats />}

                {!!progress && !isLoadingProgress && (
                    <>
                        <Donuts
                            size="24px"
                            value={progress?.percent || 0}
                            lineColor={(progress?.percent || 0) === 100 ? theme.colors.system.success[200] : theme.colors.system.info[200]}
                            ringColor={theme.colors.base[200]}
                        />

                        <strong>{progress?.percent || 0}%</strong>
                        <span>&bull;</span>
                        <p>
                            {progress?.completedItems || 0}/{progress?.totalItems || 0} {messageProgress}
                        </p>
                    </>
                )}
            </S.SubjectScreenDetailsDesktopSelectFrontStats>
        </S.SubjectScreenDetailsDesktopSelectFrontContainer>
    );
};

export default memo(SubjectScreenDetailsDesktopSelectFront);
