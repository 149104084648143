import IPagination from "interfaces/IPagination";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { createActions, createReducer } from "reduxsauce";
import { ReduxAction } from "store";
import { EssayActionsType, IEssayState, IEssayActionsCreators, IEssayTheme, IEssayComposition } from "store/interfaces/IEssay";

export const { Creators, Types } = createActions<Record<EssayActionsType, EssayActionsType>, IEssayActionsCreators>({
    getEssayThemesRequest: ["payload"],
    getEssayThemesSuccess: ["payload"],
    getEssayThemesFailure: ["payload"],

    getEssayThemeRequest: ["payload"],
    getEssayThemeSuccess: ["payload"],
    getEssayThemeFailure: ["payload"],

    getEssayCompositionsRequest: ["payload"],
    getEssayCompositionsSuccess: ["payload"],
    getEssayCompositionsFailure: ["payload"],

    getEssayCompositionRequest: ["payload"],
    getEssayCompositionSuccess: ["payload"],
    getEssayCompositionFailure: ["payload"],

    getEssayFiltersRequest: [],
    getEssayFiltersSuccess: ["payload"],
    getEssayFiltersFailure: [],

    createEssayImageRequest: ["payload"],
    createEssayImageSuccess: ["payload"],
    createEssayImageFailure: [],

    createEssayFileRequest: ["payload"],
    createEssayFileSuccess: ["payload"],
    createEssayFileFailure: [],

    updateEssayStatusRequest: ["payload"],
    updateEssayStatusSuccess: [],
    updateEssayStatusFailure: [],

    createEssayRequest: ["payload"],
    createEssaySuccess: [],
    createEssayFailure: [],

    clearEssay: [],
    clearEssayTheme: [],
    clearEssayComposition: []
});

export const INITIAL_STATE: IEssayState = {
    isLoading: false,
    balance: 0,
    isResetedRequest: false,
    themes: {
        all: {
            items: [],
            count: 0,
            page: 0,
            totalItems: 0,
            totalPages: 0
        },
        theme: {
            isLoadingTheme: false,
            amount: 0,
            average: 0,
            complexity: 1,
            contest: {
                idCompany: 0,
                idContest: 0,
                name: "",
                correctionEngine: "",
                slug: ""
            },
            created: "",
            description: "",
            idTheme: 0,
            month: 0,
            paper: "",
            title: "",
            content: "",
            pathFile: "",
            pathThumb: "",
            year: 0,
            genre: {
                idGenre: 0,
                title: ""
            },
            stats: {
                corrected: 0,
                correctorPending: 0,
                discarded: 0,
                draft: 0,
                pending: 0
            },
            compositions: [],
            category: {
                title: ""
            }
        }
    },
    compositions: {
        all: {
            items: [],
            count: 0,
            page: 1,
            totalItems: 0,
            totalPages: 0
        },
        composition: {
            isLoadingComposition: false,
            idComposition: 0,
            idStudent: 0,
            idTheme: 0,
            title: "",
            content: "",
            path: "",
            status: 0,
            pathOriginal: "",
            code: "",
            created: "",
            revisions: [],
            theme: {
                idTheme: 0,
                created: "",
                description: "",
                pathFile: "",
                pathThumb: "",
                title: "",
                content: "",
                month: 1,
                year: 1,
                complexity: 1,
                average: 1,
                amount: 1,
                paper: "",
                genre: {
                    idGenre: 0,
                    title: ""
                },
                contest: {
                    idContest: 0,
                    idCompany: 0,
                    name: "",
                    correctionEngine: "",
                    slug: ""
                },
                stats: {
                    corrected: 0,
                    correctorPending: 0,
                    discarded: 0,
                    draft: 0,
                    pending: 0
                },
                category: {
                    title: ""
                }
            },
            comments: [],
            competences: []
        }
    },
    filters: [],
    latestCompositions: []
};

interface ActionProps<T> extends IPagination<T> {
    balance: number;
    isResetedRequest: boolean;
    latestCompositions: IEssayComposition[];
}

const actionRequest = (state = INITIAL_STATE, action: ReduxAction<{ isResetedRequest: boolean }>) => {
    return {
        ...state,
        isLoading: true,
        isResetedRequest: !!action?.payload?.isResetedRequest
    };
};

const getEssayThemesSuccess = (state = INITIAL_STATE, action: ReduxAction<ActionProps<IEssayTheme>>) => ({
    ...state,
    balance: action.payload.balance,
    themes: {
        ...state.themes,
        all: {
            ...action.payload,
            items: !!action?.payload?.isResetedRequest ? action?.payload?.items : [...state.themes.all.items, ...action?.payload?.items]
        }
    },
    isResetedRequest: false,
    isLoading: false
});

const getEssayThemeRequest = (state = INITIAL_STATE) => ({
    ...state,
    themes: {
        ...state.themes,
        theme: {
            ...state.themes.theme,
            isLoadingTheme: true
        }
    },
    isLoading: true
});

const getEssayThemeSuccess = (state = INITIAL_STATE, action: ReduxAction<IEssayTheme>) => ({
    ...state,
    themes: {
        ...state.themes,
        theme: {
            ...action.payload,
            isLoadingTheme: false
        }
    },
    isLoading: false
});

const getEssayThemeFailure = (state = INITIAL_STATE) => ({
    ...state,
    themes: {
        ...state.themes,
        theme: {
            ...state.themes.theme,
            isLoadingTheme: false
        }
    },
    isLoading: false
});

const getEssayCompositionsSuccess = (state = INITIAL_STATE, action: ReduxAction<ActionProps<IEssayComposition>>) => ({
    ...state,
    compositions: {
        ...state.compositions,
        all: {
            ...action.payload,
            items: !!action?.payload?.isResetedRequest ? action?.payload?.items : [...state.compositions.all.items, ...action?.payload?.items]
        }
    },
    latestCompositions: action?.payload?.latestCompositions,
    isResetedRequest: false,
    isLoading: false
});

const getEssayCompositionRequest = (state = INITIAL_STATE) => ({
    ...state,
    compositions: {
        ...state.compositions,
        composition: {
            ...state.compositions.composition,
            isLoadingComposition: true
        }
    },
    isLoading: true
});

const getEssayCompositionSuccess = (state = INITIAL_STATE, action: ReduxAction<IEssayComposition>) => ({
    ...state,
    compositions: {
        ...state.compositions,
        composition: {
            ...action.payload,
            isLoadingComposition: false
        }
    },
    isLoading: false
});

const getEssayCompositionFailure = (state = INITIAL_STATE) => ({
    ...state,
    compositions: {
        ...state.compositions,
        composition: {
            ...state.compositions.composition,
            isLoadingComposition: false
        }
    },
    isLoading: false
});

const actionEssayFiltersRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getEssayFiltersSuccess = (state = INITIAL_STATE, action: ReduxAction<IFilterGroup[]>): IEssayState => ({
    ...state,
    filters: action.payload,
    isLoading: false
});

const actionEssayFiltersFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const actionFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const startLoading = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const stopLoading = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearEssay = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const clearEssayTheme = (state = INITIAL_STATE) => ({
    ...state,
    themes: {
        ...state.themes,
        theme: INITIAL_STATE.themes.theme
    }
});

const clearEssayComposition = (state = INITIAL_STATE) => ({
    ...state,
    compositions: {
        ...state.compositions,
        composition: INITIAL_STATE.compositions.composition
    }
});

export default createReducer<IEssayState>(INITIAL_STATE, {
    [Types.GET_ESSAY_THEMES_REQUEST]: actionRequest,
    [Types.GET_ESSAY_THEMES_SUCCESS]: getEssayThemesSuccess,
    [Types.GET_ESSAY_THEMES_FAILURE]: actionFailure,

    [Types.GET_ESSAY_THEME_REQUEST]: getEssayThemeRequest,
    [Types.GET_ESSAY_THEME_SUCCESS]: getEssayThemeSuccess,
    [Types.GET_ESSAY_THEME_FAILURE]: getEssayThemeFailure,

    [Types.GET_ESSAY_COMPOSITIONS_REQUEST]: actionRequest,
    [Types.GET_ESSAY_COMPOSITIONS_SUCCESS]: getEssayCompositionsSuccess,
    [Types.GET_ESSAY_COMPOSITIONS_FAILURE]: actionFailure,

    [Types.GET_ESSAY_COMPOSITION_REQUEST]: getEssayCompositionRequest,
    [Types.GET_ESSAY_COMPOSITION_SUCCESS]: getEssayCompositionSuccess,
    [Types.GET_ESSAY_COMPOSITION_FAILURE]: getEssayCompositionFailure,

    [Types.GET_ESSAY_FILTERS_REQUEST]: actionEssayFiltersRequest,
    [Types.GET_ESSAY_FILTERS_SUCCESS]: getEssayFiltersSuccess,
    [Types.GET_ESSAY_FILTERS_FAILURE]: actionEssayFiltersFailure,

    [Types.CREATE_ESSAY_IMAGE_REQUEST]: startLoading,
    [Types.CREATE_ESSAY_IMAGE_SUCCESS]: stopLoading,
    [Types.CREATE_ESSAY_IMAGE_FAILURE]: stopLoading,

    [Types.CREATE_ESSAY_FILE_REQUEST]: startLoading,
    [Types.CREATE_ESSAY_FILE_SUCCESS]: stopLoading,
    [Types.CREATE_ESSAY_FILE_FAILURE]: stopLoading,

    [Types.UPDATE_ESSAY_STATUS_REQUEST]: startLoading,
    [Types.UPDATE_ESSAY_STATUS_SUCCESS]: stopLoading,
    [Types.UPDATE_ESSAY_STATUS_FAILURE]: stopLoading,

    [Types.CREATE_ESSAY_REQUEST]: startLoading,
    [Types.CREATE_ESSAY_SUCCESS]: stopLoading,
    [Types.CREATE_ESSAY_FAILURE]: stopLoading,

    [Types.CREATE_ESSAY_REQUEST]: startLoading,
    [Types.CREATE_ESSAY_SUCCESS]: stopLoading,
    [Types.CREATE_ESSAY_FAILURE]: stopLoading,

    [Types.CLEAR_ESSAY]: clearEssay,
    [Types.CLEAR_ESSAY_THEME]: clearEssayTheme,
    [Types.CLEAR_ESSAY_COMPOSITION]: clearEssayComposition
});
