import React, { useCallback, useContext, memo } from "react";

// Components
import Button from "components/Button";

// Helpers
import { MigrationUserContext } from "../context";

// Assets
import { StartTitle, StartText, StepEdgesContainer } from "../styles";

const Start: React.FC = () => {
    const {
        state,
        dispatch,
        methods: { changeStep }
    } = useContext(MigrationUserContext);

    const handleButtonClick = useCallback(() => {
        dispatch({ type: "TOGGLE_LOADER", payload: true });

        try {
            changeStep();
        } catch (error) {
            return null;
        } finally {
            dispatch({ type: "TOGGLE_LOADER", payload: false });
        }
    }, [changeStep, dispatch]);

    return (
        <StepEdgesContainer>
            <StartTitle>Olá {state.credentials?.name},</StartTitle>
            <StartText>
                Somos o <strong>Sistema Prodígio de Ensino</strong> - uma plataforma digital de aprendizagem - e você nos conhece através das marcas{" "}
                <strong>ProEnem</strong> e <strong>ProMilitares</strong>.
            </StartText>
            <StartText>
                O nosso objetivo é sempre melhorar a sua experiência e, para isso, precisamos que você confirme alguns dados. É bem rapidinho!
            </StartText>
            <StartText>Você poderá continuar estudando assim que concluir :)</StartText>
            <Button
                data-test-id="start-wizard"
                disabled={state.isLoading}
                isLoading={state.isLoading}
                variant="info"
                size="medium"
                block={true}
                onClick={handleButtonClick}
            >
                Vamos lá!
            </Button>
        </StepEdgesContainer>
    );
};

export default memo(Start);
