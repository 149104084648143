// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEssayResumeCardProps } from "./performance-essay-resume-card.types";

// Styles
import {
    Container,
    ResumeEssayCardWrapper,
    ResumeEssayCardHeader,
    IconWrapper,
    Icon,
    ResumeEssayCardContent,
    ResumeEssayCardTitle,
    ResumeEssayCardDelivered,
    ResumeEssayCardHighlightedText,
    ResumeEssaySeeDetailsElementWrapper
} from "./performance-essay-resume-card.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceEssayResumeCard: FunctionComponent<PerformanceEssayResumeCardProps> = ({
    icon,
    bestNote,
    deliveredEssays,
    lastNote,
    seeDetailsElement,
    title
}) => {
    return (
        <Container>
            <ResumeEssayCardWrapper>
                <ResumeEssayCardHeader>
                    <IconWrapper>
                        <Icon src={icon} />
                    </IconWrapper>

                    <Spacing direction="horizontal" size={16} />

                    <div>
                        <ResumeEssayCardTitle>{title}</ResumeEssayCardTitle>

                        <Spacing direction="vertical" size={2} />

                        <ResumeEssayCardDelivered>Entregues: {leftPad(deliveredEssays, 2)}</ResumeEssayCardDelivered>
                    </div>
                </ResumeEssayCardHeader>

                <Spacing direction="vertical" size={16} />

                <div>
                    <ResumeEssayCardContent>
                        Última nota:&nbsp;<ResumeEssayCardHighlightedText>{leftPad(lastNote, 3)}</ResumeEssayCardHighlightedText>
                    </ResumeEssayCardContent>

                    <ResumeEssayCardContent>
                        Melhor nota até aqui:&nbsp;<ResumeEssayCardHighlightedText>{leftPad(bestNote, 3)}</ResumeEssayCardHighlightedText>
                    </ResumeEssayCardContent>
                </div>

                <Spacing direction="vertical" size={16} />

                <ResumeEssaySeeDetailsElementWrapper>{seeDetailsElement}</ResumeEssaySeeDetailsElementWrapper>
            </ResumeEssayCardWrapper>
        </Container>
    );
};
