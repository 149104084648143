// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityType } from "store/interfaces/activity";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const ActivityFormActionButtonsWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
`;

export const ActivityFormInputsClassAndDateWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_LARGE} {
        flex-direction: row;
    }
`;

export const ActivityFormInputsDatesWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-direction: row;
    }

    ${MediaQueries.BIGGER_THAN_LARGE} {
        width: unset;

        > .webapp-input,
        > .webapp-date-picker {
            width: 256px;
            flex-shrink: 0;
        }
    }
`;

type ActivityDefaultExerciseFormWrapperStyleProps = {
    type: ActivityType;
};

export const ActivityDefaultExerciseFormWrapper = styled.div<ActivityDefaultExerciseFormWrapperStyleProps>`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;

    ${({ type }) =>
        type === "exercise-list" &&
        css`
            grid-template-areas:
                "input dropdown"
                "textarea textarea"
                "card1 card2";

            .webapp-input {
                grid-area: input;
            }

            .webapp-dropdown {
                grid-area: dropdown;
            }

            .webapp-textarea {
                grid-area: textarea;
            }

            .webapp-input-card:nth-child(1) {
                grid-area: card1;
            }

            .webapp-input-card:nth-child(2) {
                grid-area: card2;
            }
        `}

    ${({ type }) =>
        type === "discursive" &&
        css`
            grid-template-areas:
                "input input"
                "textarea textarea"
                "card1 card2";

            .webapp-input {
                grid-area: input;
            }

            .webapp-textarea {
                grid-area: textarea;
            }

            .webapp-input-card:nth-child(1) {
                grid-area: card1;
            }

            .webapp-input-card:nth-child(2) {
                grid-area: card2;
            }
        `}

    ${({ type }) =>
        type === "send-file" &&
        css`
            grid-template-areas:
                "input input"
                "textarea textarea"
                "card1 card2";

            .webapp-input {
                grid-area: input;
            }

            .webapp-textarea {
                grid-area: textarea;
            }

            .webapp-input-card:nth-child(1) {
                grid-area: card1;
            }

            .webapp-input-card:nth-child(2) {
                grid-area: card2;
            }
        `}
    }
`;

export const ActivityCustomExerciseFormWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        gap: 24px;
    }
`;

export const ActivityCustomExerciseQuestionsBoard = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
`;

export const ActivityCustomExerciseQuestionsAccordionWrapper = styled.div`
    width: 100%;
    border-radius: 4px;

    flex-direction: column;
    display: flex;
    align-items: flex-start;
`;

export const QuestionsErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 4px;

    > img {
        margin-bottom: 2px;
    }
`;

export const ErrorMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ErrorMessageLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #e40000;
`;

export const ActivityFilesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
        grid-template-rows: auto;
    }

    > .webapp-file .webapp-progress-bar {
        width: 120px;
    }
`;

type ActivityDefaultActionButtonStyleProps = {
    color: string;
};

export const ActivityDefaultActionButton = styled.p<ActivityDefaultActionButtonStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: ${({ color }) => color};

    cursor: pointer;
`;

export const ActivityDefaultRemoveIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
`;

export const ActivityPartialModalCurrentQuestionWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
    justify-content: space-between;

    padding: 12px 0;

    position: relative;
    overflow-y: auto;
`;

export const ActivityPartialModalCurrentQuestionContent = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
`;

export const ActivityPartialModalSeparator = styled.div`
    width: 100%;
    height: 1px;
    flex-shrink: 0;
    background-color: #f0f1f7;
    margin: 16px 0;
`;

export const ActivityPartialModalFooterWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    position: sticky;
    bottom: -14px;

    border-top: 1px solid #f0f1f7;
    padding: 16px 0;
    background-color: #ffffff;
`;

export const ActivityPartialModalButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 24px;
`;

export const AdditionalContentWrapper = styled.div`
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
    }
`;

export const ClassSelectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
`;

export const ClassSelectionInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

export const ClassSelectionInfoIcon = styled.img`
    width: 14px;
    height: 14px;
`;

export const ClassSelectionInfoLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #4d4d63;
`;

export const ClassSelectionChipsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
`;

export const ClassSelectionChipIcon = styled.img`
    width: 16px;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    :hover {
        background-color: #ced0dd;
    }
`;

export const ActivityQuestionAlternativeBottomSheetContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ActivityAlternativeActionsWrapper = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

export const ActivityAlternativeActionIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
`;

export const ActivityForm = styled.form`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const MobileFileUploadContentWrapper = styled.div`
    width: 100%;
    padding: 24px;
    border: 2px dashed #24388c;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const MobileFileUploadContentTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: #4d4d63;
    text-align: center;
`;
