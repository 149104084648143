// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

export type CheckboxLabelPosition = "label-before" | "label-after";

type WebpCheckboxProps = {
    id?: string;
    name?: string;
    label: string;
    value?: string;
    className?: string;
    checked?: boolean;
    layout: "checkbox" | "switch";
    labelOrder: CheckboxLabelPosition;
    onWebpCheckboxChange: (ev: any) => void;
};

export const WebpCheckbox: FunctionComponent<WebpCheckboxProps> = ({
    checked = false,
    label,
    labelOrder,
    layout,
    name,
    value = "on",
    id,
    onWebpCheckboxChange
}) => {
    const onClick = (ev: any) => {
        ev.preventDefault();

        onWebpCheckboxChange({
            detail: {
                checked: !checked,
                value: value
            }
        });
    };

    return (
        <Container className="webp-checkbox" onClick={onClick} ariaLabelledby={labelOrder} ariaChecked={checked} type={layout}>
            <label className="label-container" htmlFor={id}>
                {layout === "checkbox" ? (
                    <span className="checkbox">
                        <span className="checkmark"></span>
                    </span>
                ) : (
                    <span className="switch">
                        <span className="switch-knob"></span>
                    </span>
                )}
                <span className="label-text">{label}</span>
            </label>

            <input className="native-checkbox" type="checkbox" aria-checked={checked} id={id} name={name} />
        </Container>
    );
};
