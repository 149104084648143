import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

// Dependencies
import { DateTime } from "luxon";
import CalendarIcon from "prodigio-icons/web/filled/Calendar";
import { VictoryGroup, VictoryBar, VictoryLine, VictoryLabel } from "victory";

// Components
import Badge from "components/Badge";

// Helpers
import { theme } from "config/theme";
import history from "services/history";
import pluralize from "utils/pluralize";
import { IModalActions } from "store/interfaces/IModal";
import { IAnswerCardActions } from "store/interfaces/IActions";
import { IExtraExerciseList } from "interfaces/IExerciseList";
import { ExtraExerciseStatus } from "enums/ExtraExerciseStatus";
import { IExerciseListActions } from "store/ducks/exerciseList/types";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import {
    ExerciseCardButton,
    ApplicationPeriodContent,
    ExerciseCardContainer,
    DateApplicationPeriod,
    ItemTotalQuestions,
    ListTotalQuestions,
    TextTotalQuestions,
    ExerciseCardTitle,
    ExerciseCardHeader,
    ExerciseCardContent,
    ExerciseCardChartContainer,
    ExerciseCardChartLegends,
    ExerciseCardChartLegendLabel,
    ExerciseCardTotalNote
} from "./styles";

interface IExerciseCardProps {
    exerciseList: IExtraExerciseList;
    modalActions: IModalActions;
    exerciseListActions: IExerciseListActions;
    answerCardActions: IAnswerCardActions;
    subjectSlug: string;
}

const ExerciseCard = ({ exerciseList, modalActions, answerCardActions, exerciseListActions, subjectSlug }: IExerciseCardProps) => {
    const { id, title, details, startDate, endDate, status, isOpen, realizationsAverageHitRate, points, firstResolution, externalURL } = exerciseList;
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isAvailable = status === ExtraExerciseStatus.Available && !!isOpen;
    const isInProgress = status === ExtraExerciseStatus.InProgress;
    const isCorrected = status === ExtraExerciseStatus.Corrected && !isOpen;

    const formatDisplayDate = useMemo(() => {
        try {
            if (!startDate || !endDate) {
                return null;
            }

            const formattedStartDate = DateTime.fromISO(startDate)
                .setLocale("pt-BR")
                .toFormat("dd/MM/yyyy HH:mm");
            const formattedEndDate = DateTime.fromISO(endDate)
                .setLocale("pt-BR")
                .toFormat("dd/MM/yyyy HH:mm");

            return `${formattedStartDate} até ${formattedEndDate}`;
        } catch (error) {
            return null;
        }
    }, [startDate, endDate]);

    const redirectToExercisePage = useCallback(() => {
        answerCardActions.postAnswerCardRequest({ id, mode: "1", isExtra: true, subjectSlug });
        exerciseListActions.getExerciseListSuccess(exerciseList);
    }, [answerCardActions, exerciseList, exerciseListActions, id, subjectSlug]);

    const handleButtonClick = useCallback(() => {
        if (isCorrected || (isOpen && isInProgress)) {
            if (!firstResolution?.id) {
                return;
            }

            return history.push({
                pathname: `/app/curso/${courseSlug}/avaliacoes/${subjectSlug}/lista-de-exercicios/${exerciseList.id}/prova/${firstResolution.id}`
            });
        }

        modalActions.openModal("confirmAction", {
            title: "Atenção!",
            text: "Esta é uma ação permanente. Caso você finalize a lista não poderá voltar a fazer os exercícios, fique atento.",
            onConfirm: () => (isOpen && isAvailable ? redirectToExercisePage() : null),
            variant: "warning",
            buttonText: "Iniciar Lista"
        });
    }, [
        courseSlug,
        exerciseList.id,
        firstResolution,
        isAvailable,
        isCorrected,
        isInProgress,
        isOpen,
        modalActions,
        redirectToExercisePage,
        subjectSlug
    ]);

    const badgeStatus = useMemo(() => {
        if (!status || status === ExtraExerciseStatus.Available) {
            return null;
        }

        const arrStatus = Object.values(ExtraExerciseStatus).filter((item: any) => typeof item !== "string");
        const statusIsPresentInEnum = arrStatus.includes(status);

        if (!statusIsPresentInEnum) {
            return null;
        }

        const answerCardBadges = {
            [ExtraExerciseStatus.Corrected]: <Badge variant="success">Corrigida</Badge>,
            [ExtraExerciseStatus.Expired]: <Badge variant="info">Não disponível</Badge>,
            [ExtraExerciseStatus.InProgress]: <Badge variant="warning">Em progresso</Badge>,
            [ExtraExerciseStatus.Soon]: <Badge variant="info">Em breve</Badge>,
            [ExtraExerciseStatus.WaitingCorrection]: <Badge variant="warning">Aguardando correção</Badge>
        };

        return answerCardBadges[status] || null;
    }, [status]);

    const chartNote = useMemo(() => {
        try {
            if (!firstResolution?.id || status !== ExtraExerciseStatus.Corrected) {
                return null;
            }

            const media = realizationsAverageHitRate + 1;

            const dataMedia = [
                { x: 1, y: media },
                { x: 2, y: media },
                { x: 3, y: media, label: realizationsAverageHitRate }
            ];

            const currentNote = (firstResolution?.points ?? 0) + 1;

            return (
                <ExerciseCardChartContainer>
                    <VictoryGroup
                        width={400}
                        height={100}
                        padding={{
                            top: 30,
                            bottom: 0,
                            left: 16,
                            right: 16
                        }}
                    >
                        <VictoryBar
                            standalone={false}
                            cornerRadius={{ topLeft: 4, topRight: 4 }}
                            barRatio={1}
                            barWidth={47}
                            style={{
                                data: {
                                    fill: theme.colors.system.success[200],
                                    alignmentBaseline: "end"
                                }
                            }}
                            data={[{ x: 2, y: currentNote, label: currentNote }]}
                            labelComponent={<VictoryLabel dy={currentNote} text={() => `Nota ${firstResolution?.points ?? 0}`} />}
                            alignment="middle"
                        />
                        <VictoryLine
                            data={dataMedia}
                            style={{
                                data: {
                                    stroke: theme.colors.system.warning[400],
                                    strokeWidth: 2,
                                    strokeLinecap: "round",
                                    strokeDasharray: 10
                                }
                            }}
                            labelComponent={
                                <VictoryLabel
                                    renderInPortal
                                    dx={-6}
                                    dy={-1}
                                    style={{
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: theme.typography.sizes.smallX,
                                        fill: theme.colors.system.warning[400]
                                    }}
                                />
                            }
                        />
                    </VictoryGroup>

                    <ExerciseCardChartLegends>
                        <ExerciseCardChartLegendLabel color={theme.colors.system.success[200]}>Sua Nota</ExerciseCardChartLegendLabel>
                        <ExerciseCardChartLegendLabel color={theme.colors.system.warning[400]}>Nota média da turma</ExerciseCardChartLegendLabel>
                    </ExerciseCardChartLegends>
                </ExerciseCardChartContainer>
            );
        } catch (error) {
            return null;
        }
    }, [firstResolution, realizationsAverageHitRate, status]);

    const totalDiscursive = (details && details.discursive) || 0;
    const totalObjective = (details && details.objective) || 0;

    return (
        <ExerciseCardContainer>
            <ExerciseCardContent>
                <ExerciseCardHeader>
                    <ApplicationPeriodContent>
                        <CalendarIcon />
                        <DateApplicationPeriod>{formatDisplayDate}</DateApplicationPeriod>
                    </ApplicationPeriodContent>
                    {badgeStatus}
                </ExerciseCardHeader>
                <ExerciseCardTitle>{title}</ExerciseCardTitle>
                <ExerciseCardTotalNote>
                    Nota máxima: <strong>{points || 0}</strong>
                </ExerciseCardTotalNote>
                <ListTotalQuestions>
                    {!!totalObjective && (
                        <ItemTotalQuestions>
                            <TextTotalQuestions>
                                {totalObjective} {pluralize(totalObjective, "Múltiplas", "Múltipla")}{" "}
                                {pluralize(totalObjective, "Escolhas", "Escolha")}
                            </TextTotalQuestions>
                        </ItemTotalQuestions>
                    )}
                    {!!totalDiscursive && (
                        <ItemTotalQuestions>
                            <TextTotalQuestions>
                                {totalDiscursive} {pluralize(totalDiscursive, "Discursivas", "Discursiva")}
                            </TextTotalQuestions>
                        </ItemTotalQuestions>
                    )}
                </ListTotalQuestions>
            </ExerciseCardContent>
            {chartNote}
            {(isCorrected || isInProgress || isAvailable) && (
                <ExerciseCardButton
                    variant="info"
                    block={true}
                    {...(!!externalURL ? { as: "a", href: externalURL, target: "_blank" } : { onClick: handleButtonClick })}
                >
                    {isCorrected && "Ver correção"}
                    {isInProgress && "Continuar fazendo"}
                    {isAvailable && "Resolver"}
                </ExerciseCardButton>
            )}
        </ExerciseCardContainer>
    );
};

export default ExerciseCard;
