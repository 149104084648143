import React, { useMemo } from "react";

// Assets
import { ExerciseListDashboardPreviousExamChartContainer, ExerciseListDashboardPreviousExamChartHeader } from "./styles";
import { ExerciseMainLegends, ExerciseMainLegendItem } from "../MainChart/styles";

// Components
import { Row, Col } from "components/Grid";

// Helpers
import { VictoryBar, VictoryLine, VictoryLabel, VictoryChart, VictoryAxis } from "victory";
import useIsMobile from "hooks/use-is-mobile";
import { theme } from "config/theme";
import formatPercent from "utils/formatPercent";
import { DateTime } from "luxon";
import { IAnswerCard } from "store/ducks/answerCard";

interface IProps {
    userRealizations: IAnswerCard[];
    questionsTotal: number;
    realizationsAverageHitRate: number;
}

const ExerciseListDashboardPreviousExamChart = ({ userRealizations = [], questionsTotal = 0, realizationsAverageHitRate }: IProps) => {
    const isMobile = useIsMobile();

    const realizations = userRealizations.slice(isMobile ? -2 : -4);

    const correctChart = realizations.map((item, index: number) => ({
        x: index + 1,
        y: item.answersCorrect || 0.1,
        label: item.answersCorrect
    }));

    const wrongChart = realizations.map((item, index: number) => ({
        x: index + 1,
        y: item.answersWrong || 0.1,
        label: item.answersWrong
    }));

    const horizontalDates = realizations.map((item, index: number) => ({
        x: index + 1,
        y: 0,
        label: DateTime.fromISO(item.endDate!).toFormat("dd/MM/yyyy")
    }));

    const chartWidth = useMemo(() => (isMobile ? 20 : 25), [isMobile]);

    return (
        <ExerciseListDashboardPreviousExamChartContainer>
            <ExerciseListDashboardPreviousExamChartHeader>
                <p>Quantidade total de questões:</p>
                <span>
                    &bull; <strong>{questionsTotal}</strong> Questões
                </span>
            </ExerciseListDashboardPreviousExamChartHeader>

            <Row>
                <Col md={9} style={{ width: "100%" }}>
                    <VictoryChart
                        width={isMobile ? 250 : 500}
                        height={isMobile ? 150 : 150}
                        domainPadding={{ x: 0 }}
                        padding={{
                            top: 30,
                            bottom: isMobile ? 30 : 15,
                            left: 25
                        }}
                    >
                        {/* CORRECT BAR */}
                        <VictoryBar
                            cornerRadius={{ topLeft: 4, topRight: 4 }}
                            style={{
                                data: {
                                    fill: theme.colors.system.success[200],
                                    width: chartWidth,
                                    transform: `translate(${-(chartWidth / 2)}px, 0)`
                                },
                                labels: { display: "none", fill: "none" }
                            }}
                            data={correctChart}
                            labelComponent={
                                <VictoryLabel
                                    dy={5}
                                    dx={-chartWidth / 2}
                                    style={{
                                        fill: theme.colors.system.success[400],
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: theme.typography.sizes.small,
                                        fontWeight: "bold"
                                    }}
                                />
                            }
                        />

                        {/* WRONG BAR */}
                        <VictoryBar
                            cornerRadius={{ topLeft: 4, topRight: 4 }}
                            style={{
                                data: {
                                    fill: theme.colors.system.danger[200],
                                    width: chartWidth,
                                    transform: `translate(${chartWidth / 2}px, 0)`
                                },
                                labels: { display: "none", fill: "none" }
                            }}
                            data={wrongChart}
                            labelComponent={
                                <VictoryLabel
                                    dy={5}
                                    dx={chartWidth / 2}
                                    style={{
                                        fill: theme.colors.system.danger[500],
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: theme.typography.sizes.small,
                                        fontWeight: "bold"
                                    }}
                                />
                            }
                        />

                        {/* LINHA HORIZONTAL */}
                        <VictoryBar
                            style={{
                                data: { display: "none", fill: "none" },
                                labels: { display: "none", fill: "none" }
                            }}
                            data={horizontalDates}
                            labelComponent={
                                <VictoryLabel
                                    dy={isMobile ? 35 : 25}
                                    // dx={}
                                    style={{
                                        fill: theme.colors.base[200],
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: theme.typography.sizes.small,
                                        width: chartWidth * 2
                                    }}
                                />
                            }
                        />

                        {/* LINHA VERTICAL */}

                        <VictoryAxis
                            dependentAxis
                            standalone={false}
                            fixLabelOverlap={false}
                            style={{
                                axis: { stroke: "transparent", strokeWidth: 0, left: 5 },
                                ticks: {
                                    stroke: "transparent",
                                    strokeWidth: 0
                                },
                                tickLabels: {
                                    fontSize: theme.typography.sizes.small,
                                    fill: theme.colors.base[200],
                                    fontFamily: theme.typography.family.secondary
                                },
                                grid: {
                                    stroke: "transparent",
                                    strokeWidth: 0
                                }
                            }}
                            tickFormat={(x: number) => Math.floor(x)}
                            domain={[0, questionsTotal]}
                        />

                        {/* LINHA DE MÉDIA */}

                        <VictoryLine
                            standalone={false}
                            style={{
                                data: { stroke: theme.colors.system.warning[400], strokeDasharray: "5,5", strokeWidth: 1 },
                                labels: {
                                    fill: theme.colors.system.warning[400]
                                }
                            }}
                            data={[
                                { x: 0, y: formatPercent(realizationsAverageHitRate), label: "" },
                                {
                                    x: correctChart.length + 1,
                                    y: formatPercent(realizationsAverageHitRate),
                                    label: formatPercent(realizationsAverageHitRate)
                                }
                            ]}
                            labelComponent={
                                <VictoryLabel
                                    className="exercise-list-label-average"
                                    renderInPortal
                                    dx={-11}
                                    dy={5}
                                    style={{
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: theme.typography.sizes.small,
                                        fill: theme.colors.system.warning[400]
                                    }}
                                />
                            }
                        />
                    </VictoryChart>
                </Col>

                <Col md={3}>
                    <ExerciseMainLegends>
                        <ExerciseMainLegendItem variant="success">Acertos</ExerciseMainLegendItem>
                        <ExerciseMainLegendItem variant="danger">Erros</ExerciseMainLegendItem>
                        <ExerciseMainLegendItem variant="warning">Média geral de acertos</ExerciseMainLegendItem>
                    </ExerciseMainLegends>
                </Col>
            </Row>
        </ExerciseListDashboardPreviousExamChartContainer>
    );
};

export default React.memo(ExerciseListDashboardPreviousExamChart);
