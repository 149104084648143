// Dependencies
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";

// Utils
import { useQueryString } from "hooks/use-query-string";
import { theme } from "config/theme";

// Components
import PageLoader from "components/PageLoader";

const isAllowedBrand = ["proraiz"].includes(theme.project.slug);

const allowedDomains = ["raizsistemadeensino.com.br"];

const QrCodeRedirect: React.FC = () => {
    const { url } = useQueryString();

    const { token, isLoadingValidToken } = useSelector(({ auth, weekPlans }: IReduxStore) => ({
        token: auth.token,
        isLoadingValidToken: weekPlans?.isPublished
    }));

    useEffect(() => {
        if (isLoadingValidToken !== undefined && isAllowedBrand && !!token && !!url) {
            const urlIsAllowed = !!allowedDomains.find((domain) => (url as string).includes(domain));

            if (urlIsAllowed) {
                window.open(`${url}?token=${encodeURIComponent(token)}`, "_self");
            }
        }
    }, [isLoadingValidToken, token, url]);

    return <PageLoader />;
};

export default QrCodeRedirect;
