// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    transition: 0.2s ease-in-out;

    cursor: pointer;

    &:hover {
        box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.15);
    }
`;

export const StatusAndActionsWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const NotificationAndMenuWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const Title = styled.p`
    height: 100%;
    font-size: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #232326;
`;

export const ContentSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f0f1f7;
`;

export const ScoreAndDeadlineWrapper = styled.div`
    display: flex;
    gap: 40px;
    align-items: center;
`;

export const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
`;

export const SubLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-transform: uppercase;
    color: #83899e;
`;

export const MainLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #232326;
`;
