// Dependencies
import styled, { css } from "styled-components";

// Types
import { SpacingDataDirection } from "./spacing.types";

type ContainerStyleProps = {
    size: number;
    direction: SpacingDataDirection;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    flex-shrink: 0;

    ${({ size, direction }) =>
        direction === "horizontal" &&
        css`
            width: ${size}px;
        `};

    ${({ size, direction }) =>
        direction === "vertical" &&
        css`
            height: ${size}px;
        `};
`;
