import React, { lazy, Suspense } from "react";

// Components
import Spinner from "components/Spinner";
import ErrorBoundary from "components/ErrorBoundary";
import retry from "utils/retryLoad";

interface IProdigioIconProps {
    folder?: string;
    name?: string;
}

const ProdigioIcon = ({ folder, name }: IProdigioIconProps) => {
    if (folder !== "filled" && folder !== "outlined") {
        return null;
    }

    const Icon = lazy(() => retry(() => import(`prodigio-icons/web/${folder}/${name}`)));

    return (
        <ErrorBoundary component={<span>&times;</span>}>
            <Suspense fallback={<Spinner color="#505462" size={12} />}>
                <Icon />
            </Suspense>
        </ErrorBoundary>
    );
};

export default ProdigioIcon;
