// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    hasAction: boolean;
    hasImage: boolean;
};

type LabelStyleProps = {
    hasHover: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #f0f1f7;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    transition: 0.2s ease-in-out;

    ${({ hasAction }) =>
        !!hasAction &&
        css`
            cursor: pointer;

            &:hover {
                box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.15);
            }
        `}

    ${({ hasImage }) =>
        !!hasImage &&
        css`
            flex-direction: column;
            align-items: flex-end;
        `}
`;

export const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;

    @media (min-width: 1024px) {
        width: fit-content;
    }
`;

export const Label = styled.p<LabelStyleProps>`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;

    ${({ hasHover }) =>
        !!hasHover &&
        css`
            cursor: pointer;

            &:hover {
                color: #3e68d2;
            }
        `}
`;

export const ImageContainer = styled.div<{ hasImage: boolean }>`
    display: flex;
    justify-content: flex-start;
    width: 100%;

    img {
        max-width: 100%;

        ${({ hasImage }) =>
            !!hasImage &&
            css`
                cursor: pointer;
            `}
    }
`;
