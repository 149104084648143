// Dependencies
import React, { FunctionComponent, useRef, useState } from "react";

// Types
import { ActivityInputProps } from "./activity-input.types";

// Styles
import { Container, InputWrapper, InputField, UnderInputMessageWrapper, ErrorMessageLabel, ErrorMessageIcon, Label } from "./activity-input.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Utils
import { ternary } from "utils/ternary";

// Assets
import fail from "assets/img/fail.svg";

export const ActivityInput: FunctionComponent<ActivityInputProps> = ({
    register,
    width,
    label,
    variant = "default",
    errorMessage,
    iconElement,
    ...inputDefaultProps
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const inputParentRef = useRef(null);

    const inputValue = (inputParentRef.current as any)?.querySelector("input")?.value;

    const labelColor = ternary([
        [isFocused, "#24388C"],
        [!isFocused && !!inputValue, "#4D4D63"],
        [!isFocused && variant === "error", "#e40000"],
        [!isFocused && variant === "default", "#83899E"]
    ]);

    return (
        <Container width={width} className="webapp-input">
            <InputWrapper variant={variant} isFocused={isFocused} isFilled={!!inputValue} ref={inputParentRef}>
                <ConditionallyRender shouldRender={!!label && (isFocused || !!inputValue)} content={<Label color={labelColor!}>{label!}</Label>} />

                {iconElement}

                <InputField
                    {...register}
                    {...inputDefaultProps}
                    onFocus={(event) => {
                        setIsFocused(true);

                        if (inputDefaultProps.onFocus) {
                            inputDefaultProps.onFocus(event);
                        }
                    }}
                    onBlur={(event) => {
                        setIsFocused(false);

                        if (inputDefaultProps.onBlur) {
                            inputDefaultProps.onBlur(event);
                        }
                    }}
                />
            </InputWrapper>

            <ConditionallyRender
                shouldRender={!!errorMessage}
                content={
                    <UnderInputMessageWrapper>
                        <ErrorMessageIcon src={fail} />

                        <ErrorMessageLabel>{errorMessage}</ErrorMessageLabel>
                    </UnderInputMessageWrapper>
                }
            />
        </Container>
    );
};
