import React, { useCallback, useRef, useMemo, useEffect, useState } from "react";
import {
    StudentAreaScreenSignedCoursesCardDurationContainer,
    StudentAreaScreenSignedCoursesCardDurationDates,
    StudentAreaScreenSignedCoursesCardDurationDays,
    StudentAreaScreenSignedCoursesCardDurationMissingDays,
    StudentAreaScreenSignedCoursesCardDurationLine,
    StudentAreaScreenSignedCoursesCardDurationDatesLegend,
    StudentAreaScreenSignedCoursesCardDurationPointer
} from "./styles";
import { Interval, DateTime } from "luxon";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

interface IProps {
    startDate: string;
    endDate: string;
    isActive: boolean;
}

const StudentAreaScreenSignedCoursesCardDuration = ({ startDate, endDate, isActive }: IProps) => {
    const chartRef = useRef<HTMLDivElement>(null);
    const [chartSize, setChartSize] = useState(0);

    const isMobile = useIsMobile(BreakPoints.small);

    const missingDays = Interval.fromDateTimes(new Date(), new Date(endDate)).length("days");

    const formatMissingDaysPixels = useMemo(() => {
        try {
            const totalDays = Interval.fromDateTimes(new Date(startDate), new Date(endDate)).length("days");

            const missingDaysSize = (chartSize * Math.trunc(missingDays)) / Math.trunc(totalDays);

            const pixels = chartSize - missingDaysSize;

            if (pixels < 0) {
                throw new Error();
            }

            return pixels;
        } catch (error) {
            console.log("error", error);

            return 0;
        }
    }, [startDate, endDate, chartSize, missingDays]);

    const getChartSize = useCallback(() => {
        try {
            if (!chartRef || !chartRef.current) {
                throw new Error();
            }

            const { clientWidth, clientHeight } = chartRef.current;

            if (isMobile) {
                return setChartSize(clientHeight);
            }

            setChartSize(clientWidth);
        } catch (error) {
            console.log("error", error);
        }
    }, [isMobile]);

    useEffect(() => getChartSize(), [getChartSize]);

    if (!startDate || !endDate) {
        return null;
    }

    return (
        <StudentAreaScreenSignedCoursesCardDurationContainer id="StudentAreaScreenSignedCoursesCardDurationContainer">
            {isActive && (
                <StudentAreaScreenSignedCoursesCardDurationDays>
                    <StudentAreaScreenSignedCoursesCardDurationMissingDays top={formatMissingDaysPixels}>
                        <span>Faltam</span> {Math.trunc(missingDays)} dias <StudentAreaScreenSignedCoursesCardDurationPointer />
                    </StudentAreaScreenSignedCoursesCardDurationMissingDays>
                </StudentAreaScreenSignedCoursesCardDurationDays>
            )}

            <StudentAreaScreenSignedCoursesCardDurationLine ref={chartRef}></StudentAreaScreenSignedCoursesCardDurationLine>

            <StudentAreaScreenSignedCoursesCardDurationDates>
                <StudentAreaScreenSignedCoursesCardDurationDatesLegend direction="top">
                    Início: <span>{DateTime.fromISO(startDate).toFormat("dd/MM/yyyy")}</span>
                </StudentAreaScreenSignedCoursesCardDurationDatesLegend>
                <StudentAreaScreenSignedCoursesCardDurationDatesLegend direction="bottom">
                    Final: <span>{DateTime.fromISO(endDate).toFormat("dd/MM/yyyy")}</span>
                </StudentAreaScreenSignedCoursesCardDurationDatesLegend>
            </StudentAreaScreenSignedCoursesCardDurationDates>
        </StudentAreaScreenSignedCoursesCardDurationContainer>
    );
};

export default React.memo(StudentAreaScreenSignedCoursesCardDuration);
