/**
 *  Types of options layouts defined by the webp-dropdown-filter component
 */

export enum DropdownFilterControls {
    text = "TEXT",
    checkbox = "CHECKBOX",
    category = "CATEGORY",
    list = "LIST"
}
