// Dependencies
import styled, { css, keyframes } from "styled-components";

const transitionAnimation = keyframes`
  from {
    transform: translateY(50%);
  }

  to {
    transform: translateY(0);
  }
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 9999;

    animation-name: ${transitionAnimation};
    animation-duration: 0.5s;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    position: relative;

    width: 100%;
    max-height: 480px;
    padding: 16px;
    background-color: #ffffff;
    border: 1px solid #ced0dd;

    border-radius: 28px 28px 0 0;
`;

export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    overflow-y: auto;

    width: 100%;
`;

export const Label = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #232326;
`;

type ContentWrapperStyleProps = {
    type: "default" | "list";
};

export const ContentWrapper = styled.div<ContentWrapperStyleProps>`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;

    ${({ type }) =>
        type === "list" &&
        css`
            gap: 8px;
        `}
`;

export const ActionButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: 0;
    width: 100%;
    gap: 8px;
    background-color: #ffffff;
    border-top: 1px solid #f0f1f7;
`;
