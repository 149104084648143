import { theme } from "config/theme";
import styled from "styled-components/macro";

export const ForumQuestionActionsContainer = styled.nav`
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-top: ${theme.spacing.medium};
`;

export const ForumQuestionReplyButton = styled.button<{ color?: string }>`
    align-items: center;
    color: ${({ color }) => color || theme.colors.base[400]};
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-left: ${theme.spacing.medium};

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;
