// Dependencies
import * as yup from "yup";

export const validationSchema = yup
    .object({
        compose: yup
            .string()
            .trim()
            .required("A resposta da atividade é obrigatória."),
        comment: yup
            .string()
            .trim()
            .optional()
    })
    .required();

export type ValidationSchema = yup.InferType<typeof validationSchema>;
