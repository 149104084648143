// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, FixedContent, HeaderLabel, Icon, IndicatorLabel, OpenedContent, Title, TitleWrapper } from "./activity-accordion.styles";

// Types
import { ActivityAccordionProps } from "./activity-accordion.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

// Assets
import chevronDown from "assets/img/chevron-down.svg";
import chevronUp from "assets/img/chevron-up.svg";

export const ActivityAccordion: FunctionComponent<ActivityAccordionProps> = ({
    hasBorderTop,
    indicator,
    title,
    headerLabel,
    isOpened,
    actionButtonCompositions,
    openedContentComposition,
    handleClick
}) => {
    return (
        <Container hasBorderTop={!!hasBorderTop}>
            <FixedContent>
                <IndicatorLabel>{indicator}</IndicatorLabel>

                <TitleWrapper>
                    <HeaderLabel>{headerLabel}</HeaderLabel>
                    <ScreenWidthRender
                        actionAfterRenderingWidth="show"
                        renderingWidth={1024}
                        content={<Title dangerouslySetInnerHTML={{ __html: title }} />}
                    />
                </TitleWrapper>

                {actionButtonCompositions}

                <Icon src={!!isOpened ? chevronUp : chevronDown} onClick={handleClick} />
            </FixedContent>

            <ScreenWidthRender
                actionAfterRenderingWidth="hide"
                renderingWidth={1024}
                content={<Title dangerouslySetInnerHTML={{ __html: title }} />}
            />

            <ConditionallyRender shouldRender={!!isOpened} content={<OpenedContent>{openedContentComposition}</OpenedContent>} />
        </Container>
    );
};
