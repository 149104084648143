import React from "react";

// Components
import Skeleton from "components/Skeleton";
import { WeekPickerContainer, WeekPickerDetails, WeekPickerNav, WeekPickerNavButton, WeekPickerDays, DayCardContainer } from "../styles";

// Assets

const ModulePickerPlaceholder = () => {
    return (
        <WeekPickerContainer>
            <WeekPickerDetails>
                <div>
                    <Skeleton width="92px" height="24px" />
                </div>
                <WeekPickerNav>
                    <WeekPickerNavButton style={{ marginRight: 32 }}>
                        <Skeleton width="7px" height="14px" />
                    </WeekPickerNavButton>
                    <WeekPickerNavButton>
                        <Skeleton width="7px" height="14px" />
                    </WeekPickerNavButton>
                </WeekPickerNav>
            </WeekPickerDetails>
            <WeekPickerDays>
                {Array.from(Array(5)).map((_, index) => (
                    <DayCardContainer to="aaaaa" key={index}>
                        <Skeleton width="28px" height="18px" style={{ marginBottom: 4 }} />
                        <Skeleton width="80%" height="15px" />
                    </DayCardContainer>
                ))}
            </WeekPickerDays>
        </WeekPickerContainer>
    );
};

export default ModulePickerPlaceholder;
