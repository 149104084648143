// Dependencies
import styled, { css } from "styled-components";

export const ActivityTeacherItemsWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 32px;
    justify-items: center;

    @media (min-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1560px) {
        grid-template-columns: repeat(4, 1fr);
    }
`;

type ActionButtonIconStyleProps = {
    hasCursorPointer?: boolean;
};

export const ActionButtonIcon = styled.img<ActionButtonIconStyleProps>`
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    ${({ hasCursorPointer }) =>
        !!hasCursorPointer &&
        css`
            cursor: pointer;
        `}
`;

type ActivityModalFilterContentWrapperStyleProps = {
    hasBorderBottom?: boolean;
    hasMobileMarginTop?: boolean;
};

export const ActivityModalFilterContentWrapper = styled.div<ActivityModalFilterContentWrapperStyleProps>`
    width: 100%;
    padding-top: 24px;

    ${({ hasMobileMarginTop }) =>
        !!hasMobileMarginTop &&
        css`
            margin-top: 24px;
        `}

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    @media (min-width: 1024px) {
        margin-top: 24px;
        border-top: 1px solid #f0f1f7;

        ${({ hasBorderBottom }) =>
            !!hasBorderBottom &&
            css`
                border-bottom: 1px solid #f0f1f7;
            `}
    }

    ${({ hasBorderBottom }) =>
        !!hasBorderBottom &&
        css`
            padding-bottom: 24px;
        `}
`;

export const ActivityModalFilterContentTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: #232326;
`;

export const ActivityCurrentActivityDeletedLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #232326;
`;

export const ActivityModalFilterStatusChipsWrapper = styled.div`
    width: 100%;

    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;
`;

export const ActivityModalFilterClassCheckboxsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;
    }
`;

export const ActivityModalFilterDeadlineInputsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }
`;

export const FilterEmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    margin-top: 80px;
`;

export const FilterEmptyStateTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #000000;
`;

export const FilterEmptyStateDescription = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #000000;
`;
