import React, { memo } from "react";
import DesktopSelectFront from "../DesktopSelectFront";
import MobileSelectFront from "../MobileSelectFront";
import { ISubjectFront, ISubjectCurrentFront } from "store/interfaces/ISubject";
import { SubjectScreenDetailsSelectFrontTitle } from "./styles";
import { ISubjectProgress } from "interfaces/ISubject";

export interface ISelectSubjectFront {
    isLoadingFronts?: boolean;
    isLoadingProgress?: boolean;
    fronts?: ISubjectFront[];
    currentFront?: ISubjectCurrentFront;
    onClickFront(front: ISubjectFront): void;
    subjectProgress?: ISubjectProgress;
    messageProgress?: string;
}

interface IProps extends ISelectSubjectFront {
    isMobile: boolean;
    withFront: boolean;
    subjectName?: string;
}

const SubjectScreenDetailsSelectFront = memo(({ isMobile, withFront, subjectName = "", ...props }: IProps) => {
    if (!withFront) {
        return <SubjectScreenDetailsSelectFrontTitle>Módulos de {subjectName}</SubjectScreenDetailsSelectFrontTitle>;
    }

    return isMobile ? <MobileSelectFront {...props} /> : <DesktopSelectFront {...props} />;
});

export default SubjectScreenDetailsSelectFront;
