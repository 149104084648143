// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Styles
import { Container } from "./styles";

type WebpStepProps = {
    label?: string;
    active: boolean;
};

export const WebpStep: FunctionComponent<WebpStepProps> = ({ active, label, children }) => {
    const [clicked, setClicked] = useState(true);
    const [isActive, setIsActive] = useState(active);

    useEffect(() => {
        label && setClicked(false);
    }, [label]);

    const handleClickedEmitter = () => {
        clicked && setIsActive(!isActive);
    };

    return (
        <Container onClick={handleClickedEmitter} active={isActive} clicked={clicked}>
            {label && <p>{label}</p>}
            {!label && children}
        </Container>
    );
};
