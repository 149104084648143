// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityToggleProps } from "./activity-toggle.types";

// Styles
import { Circle, Container } from "./activity-toggle.styles";

export const ActivityToggle: FunctionComponent<ActivityToggleProps> = ({ isActive, handleClick }) => {
    return (
        <Container isActive={isActive} onClick={handleClick}>
            <Circle isActive={isActive} />
        </Container>
    );
};
