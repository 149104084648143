import React, { useCallback, useEffect, useState, useMemo } from "react";

// Dependencies
import { Link } from "react-router-dom";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// Components
import Button from "components/Button";
import Icon from "components/Icons/icon";
import ProjectTags from "../components/Tags";
import BreadCrumb from "components/BreadCrumb";
import PageLoader from "components/PageLoader";
import Forbidden from "../components/Forbidden";
import VideoPlayer from "components/VideoPlayer";

// Helpers
import history from "services/history";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { IVideoActions } from "store/interfaces/IVideo";
import { IProjectActions } from "store/interfaces/IProject";
import { IProjectDetails } from "interfaces/IProjectDetails";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { Col, Grid, Row } from "components/Grid";
import { ProjectCover } from "../Home/components/Card/styles";
import Accordion, { AccordionOption } from "components/Accordion";
import {
    BreadCrumbWrapper,
    ContainerBackButton,
    ContainerVideoPlayerMobile,
    NotFoundResume,
    ProjectDetailsContainer,
    ProjectDetailsContent,
    ProjectDetailsHeader,
    ProjectDetailsPrice,
    ProjectDetailsPriceWrapper,
    ProjectDetailsResumes,
    ProjectDetailsSubtitle,
    ProjectDetailsTxt,
    ProjectMetaData,
    ProjectTitle,
    VideoAnnouncement
} from "./styles";

// Redux
import { connect, useSelector } from "react-redux";
import { Creators as VideoActions } from "store/ducks/video";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as ProjectActions } from "store/ducks/project";

interface IProjectDetailsScreenProps {
    isLoading: boolean;
    isSubscribed: boolean;
    project: IProjectDetails;
    projectActions: IProjectActions;
    videoActions: IVideoActions;
    videoUrl: string;
}

const ProjectDetailsScreen = ({ isLoading, isSubscribed, project, projectActions, videoActions, videoUrl }: IProjectDetailsScreenProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [imageWidth, setImageWidth] = useState(0);
    const isMobile = useIsMobile(BreakPoints.tablet);
    const isWide = useIsMobile(BreakPoints.wide);
    const isMobileAndTablet = useIsMobile();

    const imageContainer = useCallback((node: any) => {
        if (!node) {
            return;
        }

        setImageWidth(node.getBoundingClientRect().width);
    }, []);

    useEffect(() => {
        return () => projectActions.clearProjects();
    }, [projectActions]);

    const videoHandShake = useCallback(() => {
        if (!!project.video && !!project.video.id) {
            videoActions.getVideoRequest(project.video.id);
        }
    }, [videoActions, project.video]);

    useEffect(() => {
        videoHandShake();
    }, [videoHandShake]);

    const renderProjectResumes = useMemo(() => {
        const resumes: AccordionOption[] = project.resumes.map((resume) => ({
            label: {
                text: resume.title
            },
            hasHtmlContent: true,
            content: resume.text
        }));

        return <Accordion activeItem={0} options={resumes} />;
    }, [project.resumes]);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!project.isPublished) {
        return <Forbidden message="Este projeto ainda não foi publicado." />;
    }

    return (
        <ProjectDetailsContainer>
            <ProjectDetailsHeader>
                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={6}>
                            {isMobile && project.video && (
                                <ContainerVideoPlayerMobile>
                                    <VideoPlayer height="178px" src={videoUrl} />
                                    <ContainerBackButton to={`/app/curso/${courseSlug}/projetos`}>
                                        <Icon idIcon="ico-caret" width="15" height="15" viewBox="0 0 307.054 307.054" />
                                    </ContainerBackButton>
                                </ContainerVideoPlayerMobile>
                            )}
                            {!isMobileAndTablet && (
                                <BreadCrumbWrapper>
                                    <BreadCrumb>
                                        <BreadCrumb.Item>
                                            <Link to={`/app/curso/${courseSlug}/projetos`}>Projetos</Link>
                                        </BreadCrumb.Item>
                                        <BreadCrumb.Separator>
                                            <ChevronRightIcon width="4" height="8" />
                                        </BreadCrumb.Separator>
                                    </BreadCrumb>
                                </BreadCrumbWrapper>
                            )}
                            <ProjectMetaData>
                                <ProjectTitle>{project.name}</ProjectTitle>
                                <ProjectDetailsTxt>Detalhes do projeto</ProjectDetailsTxt>
                                {project.tags && project.tags.length > 0 && <ProjectTags tags={project.tags} />}
                                {isMobile && (
                                    <Button
                                        disabled={!isSubscribed}
                                        block={true}
                                        variant="primary"
                                        onClick={() => history.push(`/app/curso/${courseSlug}/projetos/${project.id}/estudando`)}
                                    >
                                        Iniciar
                                    </Button>
                                )}
                            </ProjectMetaData>
                        </Col>
                    </Row>
                </Grid>
            </ProjectDetailsHeader>
            <ProjectDetailsContent>
                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={6}>
                            <ProjectDetailsSubtitle>O que você vai aprender:</ProjectDetailsSubtitle>
                            <ProjectDetailsResumes>
                                {project.resumes.length > 0 ? renderProjectResumes : <NotFoundResume>Nenhum resumo foi encontrado.</NotFoundResume>}
                            </ProjectDetailsResumes>
                        </Col>
                        <Col xs={false} sm={6}>
                            <ProjectDetailsPrice>
                                {project.video ? (
                                    <VideoAnnouncement height={isWide ? "232px" : "288px"} src={videoUrl} />
                                ) : (
                                    project.urlImage && <ProjectCover ref={imageContainer} width={imageWidth} image={project.urlImage} />
                                )}
                                <ProjectDetailsPriceWrapper>
                                    <ProjectTitle>{project.name}</ProjectTitle>
                                    <Button
                                        disabled={!isSubscribed}
                                        block={true}
                                        variant="primary"
                                        onClick={() => history.push(`/app/curso/${courseSlug}/projetos/${project.id}/estudando`)}
                                    >
                                        Iniciar
                                    </Button>
                                </ProjectDetailsPriceWrapper>
                            </ProjectDetailsPrice>
                        </Col>
                    </Row>
                </Grid>
            </ProjectDetailsContent>
        </ProjectDetailsContainer>
    );
};

interface IWrapperProps {
    brandSlug: string;
    courseSlug: string;
    isLoading: boolean;
    isSubscribed: boolean;
    project: IProjectDetails;
    projectActions: IProjectActions;
    videoActions: IVideoActions;
    videoUrl: string;
    match: any;
}

const ProjectDetailsScreenWrapper = (props: IWrapperProps) => {
    useEffect(() => {
        props.projectActions.getProjectDetailRequest({ id: props.match.params.id, isDetailPage: true });
    }, [props.projectActions, props.match.params.id]);

    return <ProjectDetailsScreen {...props} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(ProjectActions, dispatch),
    videoActions: bindActionCreators(VideoActions, dispatch)
});

const mapStateToProps = ({ credentials, course, project, video }: IReduxStore) => ({
    brandSlug: credentials.student.brand.slug,
    courseSlug: course.slug,
    videoUrl: video.url,
    project: project.currentProject,
    isLoading: project.isLoading,
    isSubscribed: !!project.currentProject.student
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ProjectDetailsScreenWrapper);
