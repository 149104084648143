import React, { useMemo } from "react";

// Components
import Button from "components/Button";

// Helpers
import { FlatVariant, OutlineVariant } from "utils/types/Variant";

// Assets
import ForbiddenIcon from "assets/img/ico-forbidden.svg";
import MaintenanceIcon from "assets/img/maintenance-icon.svg";
import NotAvailableIcon from "assets/img/not-available-icon.svg";

import { ForbiddenMessageContainer, ForbiddenMessageTitle, ForbiddenMessageText } from "./styles";

interface IProps {
    title?: string;
    text: string;
    ctaText?: string;
    variant?: FlatVariant | OutlineVariant | "outline-base" | "base" | "outline-link" | "primary" | "secondary" | "outline-secondary";
    onClick?(): void;
    type?: "error" | "maintenance" | "notAvailable";
}

const ForbiddenMessage = ({ title = "Ops!", text, ctaText, variant = "warning", onClick, type = "error" }: IProps) => {
    const imageIcon = useMemo(() => {
        switch (type) {
            case "error":
                return ForbiddenIcon;
            case "notAvailable":
                return NotAvailableIcon;
            default:
                return MaintenanceIcon;
        }
    }, [type]);

    return (
        <ForbiddenMessageContainer>
            <img src={imageIcon} alt={title} />
            <ForbiddenMessageTitle>{title}</ForbiddenMessageTitle>
            <ForbiddenMessageText>{text}</ForbiddenMessageText>
            {!!ctaText && !!onClick && (
                <Button variant={variant} onClick={onClick}>
                    {ctaText}
                </Button>
            )}
        </ForbiddenMessageContainer>
    );
};

export default ForbiddenMessage;
