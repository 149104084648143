import React, { forwardRef, useMemo, useCallback, useState, memo } from "react";
import ExerciseListTimer from "../Timer";
import {
    ExerciseListHeaderContainer,
    ExerciseListHeaderWrapperTimer,
    ExerciseListHeaderBackButton,
    ExerciseListHeaderBackIcon,
    ExerciseListHeaderContainerProgressBar,
    ExerciseListHeaderTitle,
    ExerciseListHeaderProgressBarTotals,
    ExerciseListHeaderContainerProgressBarLabels,
    ExerciseListHeaderProgressBarPercent,
    ExerciseListHeaderProgressBarTotalsDone,
    ExerciseListHeaderContent,
    ExerciseListHeaderSubject
} from "./styles";
import { ExerciseListContentContainer } from "screens/ExerciseList/styles";
import ProgressBar from "components/ProgressBar";
import ExerciseListResolutionMode from "screens/ExerciseList/enums/ExerciseListResolutionMode";
import formatPercent from "utils/formatPercent";
import generateColors from "helpers/generateColors";
import { theme } from "config/theme";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { getExerciseListDashboardRoute } from "helpers/getExerciseListDashboardRoute";
import history from "services/history";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

export interface IProps {
    title: string;
    startDate: string;
    endDate: string | null;
    totalAnsweredQuestions: number;
    totalQuestions: number;
    resolutionMode: ExerciseListResolutionMode;
    isFinished: boolean;
    activeQuestionNumber: number;
    subject?: { name: string; color?: string | null };
    durationInMinutes: number | null;
    isCountDown: boolean;
    onForceFinish(): void;
    showEssay: boolean;
    showResult: boolean;
    lastQuestionNumber: number;
}

const ExerciseListHeader = forwardRef((props: IProps, ref: any) => {
    const {
        title,
        startDate,
        totalAnsweredQuestions,
        totalQuestions,
        resolutionMode,
        isFinished,
        endDate,
        activeQuestionNumber,
        subject,
        isCountDown,
        onForceFinish,
        durationInMinutes,
        showEssay,
        showResult = false,
        lastQuestionNumber
    } = props;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isSmallerThanDesktop = useIsMobile(BreakPoints.medium);

    const [finished, setFinished] = useState(false);

    const isExam = useMemo(() => resolutionMode === ExerciseListResolutionMode.Exam, [resolutionMode]);
    const percentage = useMemo(() => formatPercent((totalAnsweredQuestions / totalQuestions) * 100), [totalAnsweredQuestions, totalQuestions]);
    const subjectColors = useMemo(() => {
        if (!subject || !subject.color) {
            return theme.colors.base;
        }

        return generateColors(subject.color);
    }, [subject]);

    const handleClickBack = useCallback(() => {
        const dashboardPath = getExerciseListDashboardRoute(courseSlug);

        history.push({
            pathname: dashboardPath
        });
    }, [courseSlug]);

    // usado pra quando o tempo de resolução expira
    const handleForceFinish = useCallback(() => {
        if (isFinished) {
            return;
        }

        setFinished(true);
        onForceFinish();
    }, [isFinished, onForceFinish]);

    const timerMemo = useMemo(() => {
        try {
            if (!isExam) {
                throw new Error();
            }

            return (
                <ExerciseListHeaderWrapperTimer>
                    <ExerciseListTimer
                        startDate={startDate}
                        {...(isCountDown &&
                            !!durationInMinutes && {
                                countdown: true,
                                durationInMinutes,
                                handleForceFinish: finished ? () => null : handleForceFinish
                            })}
                        {...(isFinished ? { endDate: !!endDate ? endDate : new Date().toString() } : { progressiveCount: !isCountDown })}
                    />
                </ExerciseListHeaderWrapperTimer>
            );
        } catch (error) {
            return null;
        }
    }, [isExam, startDate, isCountDown, durationInMinutes, finished, handleForceFinish, isFinished, endDate]);

    const memoTitle = useMemo(
        () => (
            <ExerciseListHeaderTitle>
                <ExerciseListHeaderBackButton data-test-id="exercise-list-back-to-dashboard" onClick={handleClickBack}>
                    <ExerciseListHeaderBackIcon />
                </ExerciseListHeaderBackButton>

                <h5>{title}</h5>

                {!!subject && <ExerciseListHeaderSubject>{subject?.name}</ExerciseListHeaderSubject>}
            </ExerciseListHeaderTitle>
        ),
        [handleClickBack, subject, title]
    );

    const questionNumberLabel = useMemo(
        () => (
            <ExerciseListHeaderProgressBarTotals color={subjectColors[500]}>
                {showEssay ? (
                    "Redação"
                ) : (
                    <>
                        Questão{" "}
                        <ExerciseListHeaderProgressBarTotalsDone color={theme.colors.brand[300]} data-test-id="exercise-list-active-question">
                            {activeQuestionNumber}
                        </ExerciseListHeaderProgressBarTotalsDone>
                        <span data-test-id="exercise-list-total-questions">/{lastQuestionNumber}</span>
                    </>
                )}
            </ExerciseListHeaderProgressBarTotals>
        ),
        [activeQuestionNumber, lastQuestionNumber, showEssay, subjectColors]
    );

    const memoProgressBar = useMemo(() => {
        try {
            return (
                <ExerciseListHeaderContainerProgressBar progressBarBackground={theme.colors.base[200]}>
                    <ExerciseListHeaderContainerProgressBarLabels>
                        {isSmallerThanDesktop && activeQuestionNumber > 0 && questionNumberLabel}
                        <ExerciseListHeaderProgressBarPercent>
                            <span data-test-id="exercise-list-percentage">{percentage}</span>% respondido
                        </ExerciseListHeaderProgressBarPercent>
                    </ExerciseListHeaderContainerProgressBarLabels>
                    <ProgressBar color={theme.colors.system.info[200]} value={percentage} responsive={true} />
                </ExerciseListHeaderContainerProgressBar>
            );
        } catch (error) {
            return null;
        }
    }, [activeQuestionNumber, isSmallerThanDesktop, percentage, questionNumberLabel]);

    return (
        <ExerciseListHeaderContainer id="ExerciseListHeaderContainer" ref={ref}>
            <ExerciseListContentContainer id="ExerciseListContentContainer">
                {memoTitle}

                <ExerciseListHeaderContent id="ExerciseListHeaderContent">
                    {!isSmallerThanDesktop && !showResult && (
                        <>
                            {questionNumberLabel}
                            {timerMemo}
                        </>
                    )}

                    {memoProgressBar}

                    {isSmallerThanDesktop && timerMemo}
                </ExerciseListHeaderContent>
            </ExerciseListContentContainer>
        </ExerciseListHeaderContainer>
    );
});

export default memo(ExerciseListHeader);
