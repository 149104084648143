import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { ProgressBarContainer, ProgressBarLine } from "components/ProgressBar/styles";
import { MediaQueries } from "assets/styles/settings";

export const SubjectScreenDetailsModuleCardTitle = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${theme.spacing.small};

    div {
        display: flex;
        flex: 1;
        flex-direction: column;

        strong,
        span {
            font-weight: 600;
        }

        strong {
            color: ${theme.colors.base[400]};
            font-size: ${theme.typography.sizes.medium};
        }

        span {
            font-size: 14px;
            margin-bottom: 4px;
            color: ${theme.colors.brand[300]};
        }
    }

    > svg {
        flex-direction: row;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: ${theme.spacing.smallX};
    }
`;

export const SubjectScreenDetailsModuleCard = styled.div`
    display: flex;
    flex: 1;
    background: ${theme.colors.white};
    border-radius: 4px;
    padding: ${theme.spacing.small};
    flex-direction: column;
    position: relative;
    justify-content: center;
    margin-right: ${theme.spacing.smallX};
`;

export const SubjectScreenDetailsModuleContainer = styled.button<{ active: boolean; isStarted?: boolean }>`
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    background: transparent;
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 20px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-top: 20px;
        padding-bottom: unset;
    }

    ${({ isStarted }) =>
        !isStarted &&
        css`
            ${SubjectScreenDetailsModuleCardTitle} {
                strong {
                    color: ${theme.colors.base[300]};
                }
            }

            ${SubjectScreenDetailsModuleCardProgress} {
                ${SubjectScreenDetailsModuleCardProgressLabel} {
                    color: ${theme.colors.base[300]};
                }
            }
        `};

    :first-child {
        :before {
            content: " ";
            width: 16px;
            height: 32px;
            background: ${theme.colors.base[100]};
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    :last-child {
        :before {
            content: " ";
            width: 16px;
            height: 100%;
            background: ${theme.colors.base[100]};
            position: absolute;
            top: 24px;
            left: 0;
            z-index: 1;
        }
    }

    ${({ active }) =>
        !!active &&
        css`
            /* z-index: 999; */

            ${SubjectScreenDetailsModuleCard} {
                box-shadow: ${theme.shadow[3]};

                ${MediaQueries.BIGGER_THAN_SMALL} {
                    :before {
                        position: absolute;
                        right: -8px;
                        content: " ";
                        width: 16px;
                        height: 16px;
                        background: ${theme.colors.white};
                        transform: rotate(45deg);
                        box-shadow: 5px -4px 6px 0px rgba(0, 0, 0, 0.05);
                    }
                }
            }
        `}
`;

const bubblecolors = {
    base: {
        light: theme.colors.base[100],
        dark: theme.colors.base[200]
    },
    success: {
        light: theme.colors.system.success[100],
        dark: theme.colors.system.success[200]
    },
    info: {
        light: theme.colors.system.info[100],
        dark: theme.colors.system.info[200]
    }
};

export const SubjectScreenDetailsModuleBubble = styled.span<{ variant: "info" | "success" | "base" }>`
    width: 16px;
    height: 16px;
    background: ${({ variant }) => bubblecolors[variant].light};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing.smallX};
    margin-top: ${theme.spacing.smallX};
    z-index: 2;

    :before {
        display: block;
        content: " ";
        background: ${({ variant }) => bubblecolors[variant].dark};
        border-radius: 100%;
        width: 8px;
        height: 8px;
    }
`;

export const SubjectScreenDetailsModuleCardProgress = styled.div`
    display: flex;
    align-items: center;

    ${ProgressBarContainer} {
        flex: auto;
        margin-right: ${theme.spacing.small};
    }

    ${ProgressBarLine} {
        transition: width 1s;
    }
`;

export const SubjectScreenDetailsModuleCardProgressLabel = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
    font-family: ${theme.typography.family.secondary};
`;
