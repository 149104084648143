// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityButtonProps } from "./activity-button.types";

// Styles
import { Container, ContentWrapper, Content, Loader, Label } from "./activity-button.styles";

// Utils
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityButton: FunctionComponent<ActivityButtonProps> = ({
    isFullyAdaptative = false,
    isDisabled = false,
    isLoading = false,
    size = "small",
    variant = "cta",
    variantType = "primary",
    label,
    iconElement,
    ...buttonDefaultProps
}) => (
    <Container
        className="webapp-activity-button"
        {...buttonDefaultProps}
        variant={variant}
        variantType={variantType}
        isFullyAdaptative={isFullyAdaptative}
        disabled={isLoading}
        isDisabled={isDisabled || isLoading}
        size={size}
        onClick={(ev) => {
            if (!isDisabled && !isLoading && buttonDefaultProps.onClick) {
                buttonDefaultProps.onClick(ev);
            }
        }}
    >
        <ContentWrapper isVisible={!isLoading}>
            {iconElement}

            <Content>
                <Label>{label}</Label>
            </Content>
        </ContentWrapper>

        <ConditionallyRender
            shouldRender={isLoading}
            content={
                <Loader>
                    <div>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                </Loader>
            }
        />
    </Container>
);
