import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { ForumNewAttachmentWrapper } from "screens/Forum/new/styles";

export const ForumPostContainer = styled.div`
    margin-bottom: ${theme.spacing.large};

    .btn-without-border {
        border: unset;
    }
`;

export const ForumPostNav = styled.nav`
    align-items: center;
    display: flex;
    margin-top: ${theme.spacing.medium};

    ${ButtonWrapper} {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }

    ${MediaQueries.SMALL} {
        ${ButtonWrapper} {
            &:not(:last-child) {
                margin-right: 0;
            }
        }
    }
`;

export const ForumPostNavWrapper = styled(ForumNewAttachmentWrapper)`
    margin-top: ${theme.spacing.medium};

    ${ForumPostNav} {
        margin-top: 0;
    }

    ${MediaQueries.TABLET_LANDSCAPE} {
        flex-wrap: wrap;
    }
`;
