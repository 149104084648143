import React, { memo } from "react";

import Spinner from "components/Spinner";

import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

import ScannerQrCode from "./components/ScannerQrCode";

const QrCode = () => {
    const { isLoading } = useSelector(({ qrCode }: IReduxStore) => qrCode);

    if (isLoading) {
        return <Spinner fixed={false} />;
    }

    return <ScannerQrCode />;
};

export default memo(QrCode);
