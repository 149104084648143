import { createActions, createReducer } from "reduxsauce";
import { ILessonPlanModule } from "interfaces/ILessonPlan";
import { ILessonPlanActionsCreators, ILessonPlanState, LessonPlanActionsType } from "store/interfaces/ILessonPlan";
import { DateTime } from "luxon";

export const { Creators, Types } = createActions<Record<LessonPlanActionsType, LessonPlanActionsType>, ILessonPlanActionsCreators>({
    getLessonPlanRequest: ["payload"],
    getLessonPlanSuccess: ["payload"],
    getLessonPlanFailure: ["payload"],

    setModuleRequest: ["payload"],
    setModuleSuccess: ["payload"],
    setModuleFailure: [],

    setLessonPlan: ["payload"],
    setLessonPlanDay: ["payload"],

    getLessonPlanModuleRequest: ["payload"],
    getLessonPlanModuleSuccess: ["payload"],
    getLessonPlanModuleFailure: ["payload"],

    getLessonPlanEventRequest: ["payload"],
    getLessonPlanEventSuccess: ["payload"],
    getLessonPlanEventFailure: ["payload"],

    getLessonPlanEventMeetingRoomRequest: ["payload"],
    getLessonPlanEventMeetingRoomSuccess: ["payload"],
    getLessonPlanEventMeetingRoomFailure: ["payload"],

    getLessonPlanServerDateRequest: [],
    getLessonPlanServerDateSuccess: ["payload"],
    getLessonPlanServerDateFailure: ["payload"],

    clearEvent: [],
    clearLessonPlan: [],
    clearLastVisitedDateUrl: []
});

export const INITIAL_STATE: ILessonPlanState = {
    isLoading: false,
    isLoadingModule: false,
    hasError: false,
    day: "",
    module: {
        startDate: "",
        endDate: "",
        id: 0,
        isCurrent: false,
        name: "",
        shortName: "",
        created: "",
        order: 0,
        events: [],
        slug: "",
        hasEvents: false
    },
    id: 0,
    updated: "",
    created: "",
    name: "",
    event: {
        isAvailable: false,
        hasForum: false,
        isLoading: false,
        id: 0,
        startDate: "",
        endDate: "",
        order: 0,
        title: "",
        category: 0,
        teachers: [],
        content: [],
        contentPerType: [],
        created: "",
        isCurrent: false,
        bookletModules: [],
        subtitle: "",
        module: {
            id: -1,
            created: "",
            endDate: "",
            isCurrent: false,
            name: "",
            order: -1,
            shortName: "",
            startDate: "",
            events: [],
            slug: "",
            hasEvents: false
        },
        video: {
            id: -1,
            hashVideo: null,
            openForum: -1,
            videoKey: "",
            videoHash: "",
            url: "",
            totalViews: -1,
            title: "",
            provider: -1,
            created: "",
            description: "",
            code: "",
            subject: {
                color: "",
                id: -1,
                image: "",
                name: "",
                order: -1,
                progress: {
                    completedItems: -1,
                    percent: -1,
                    totalItems: -1
                },
                slug: ""
            }
        }
    },
    events: [],
    modules: [],
    lastVisitedDateUrl: "",
    meetingRoom: {
        isLoading: false
    }
};

const getLessonPlanRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true, hasError: false });

const getLessonPlanSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false,
    hasError: false
});

const getLessonPlanFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    hasError: true
});

const setLessonPlan = (state = INITIAL_STATE, action: any) => {
    if (!action.payload) {
        return INITIAL_STATE;
    }

    const modules = !!action.payload.modules
        ? action.payload.modules.filter((_module: ILessonPlanModule) => _module.startDate && _module.endDate)
        : [];

    return {
        ...state,
        ...action.payload,
        isLoading: false,
        modules
    };
};

const setModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    module: action.payload || INITIAL_STATE.module
});

const setLessonPlanDay = (state = INITIAL_STATE, action: any) => {
    const formattedDate = DateTime.fromISO(action.payload).toFormat("dd-LL-yyyy");
    const lastVisitedDateUrl = `/app/plano-de-estudos/${state.module.id}/${formattedDate}`;

    return {
        ...state,
        day: action.payload,
        lastVisitedDateUrl
    };
};

const getLessonPlanModuleRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingModule: true });

const getLessonPlanModuleSuccess = (state = INITIAL_STATE, action: any) => {
    const { events, ...rest } = action.payload;

    return {
        ...state,
        module: rest,
        events: !!events.length ? events : [],
        isLoadingModule: false
    };
};

const getLessonPlanModuleFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingModule: false
});

const getLessonPlanEventRequest = (state = INITIAL_STATE) => ({
    ...state,
    event: {
        ...state.event,
        isLoading: true
    }
});

const getLessonPlanEventSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    event: {
        ...action.payload,
        isLoading: false
    }
});

const getLessonPlanEventFailure = (state = INITIAL_STATE) => ({
    ...state,
    event: {
        ...state.event,
        isLoading: false
    }
});

const getLessonPlanEventMeetingRoomRequest = (state = INITIAL_STATE) => ({
    ...state,
    meetingRoom: {
        ...state.meetingRoom,
        isLoading: true,
        link: undefined
    }
});

const getLessonPlanEventMeetingRoomSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    meetingRoom: {
        ...state.meetingRoom,
        isLoading: false,
        link: action.payload.link
    }
});

const getLessonPlanEventMeetingRoomFailure = (state = INITIAL_STATE) => ({
    ...state,
    meetingRoom: {
        ...state.meetingRoom,
        isLoading: false
    }
});

const getLessonPlanServerDateRequest = (state = INITIAL_STATE) => ({
    ...state
});

const getLessonPlanServerDateSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    meetingRoom: {
        ...state.meetingRoom,
        serverDate: action.payload.serverDate
    }
});

const getLessonPlanServerDateFailure = (state = INITIAL_STATE) => ({
    ...state
});

const clearLessonPlan = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE,
    lastVisitedDateUrl: state.lastVisitedDateUrl
});

const clearLastVisitedDateUrl = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

const clearEvent = (state = INITIAL_STATE) => ({
    ...state,
    event: INITIAL_STATE.event,
    meetingRoom: INITIAL_STATE.meetingRoom
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_LESSON_PLAN_REQUEST]: getLessonPlanRequest,
    [Types.GET_LESSON_PLAN_SUCCESS]: getLessonPlanSuccess,
    [Types.GET_LESSON_PLAN_FAILURE]: getLessonPlanFailure,

    [Types.SET_MODULE_REQUEST]: getLessonPlanRequest,
    [Types.SET_MODULE_SUCCESS]: setModuleSuccess,
    [Types.SET_MODULE_FAILURE]: getLessonPlanFailure,

    [Types.SET_LESSON_PLAN]: setLessonPlan,
    [Types.SET_LESSON_PLAN_DAY]: setLessonPlanDay,

    [Types.GET_LESSON_PLAN_MODULE_REQUEST]: getLessonPlanModuleRequest,
    [Types.GET_LESSON_PLAN_MODULE_SUCCESS]: getLessonPlanModuleSuccess,
    [Types.GET_LESSON_PLAN_MODULE_FAILURE]: getLessonPlanModuleFailure,

    [Types.GET_LESSON_PLAN_EVENT_REQUEST]: getLessonPlanEventRequest,
    [Types.GET_LESSON_PLAN_EVENT_SUCCESS]: getLessonPlanEventSuccess,
    [Types.GET_LESSON_PLAN_EVENT_FAILURE]: getLessonPlanEventFailure,

    [Types.CLEAR_EVENT]: clearEvent,
    [Types.CLEAR_LESSON_PLAN]: clearLessonPlan,
    [Types.CLEAR_LAST_VISITED_DATE_URL]: clearLastVisitedDateUrl,

    [Types.GET_LESSON_PLAN_EVENT_MEETING_ROOM_REQUEST]: getLessonPlanEventMeetingRoomRequest,
    [Types.GET_LESSON_PLAN_EVENT_MEETING_ROOM_SUCCESS]: getLessonPlanEventMeetingRoomSuccess,
    [Types.GET_LESSON_PLAN_EVENT_MEETING_ROOM_FAILURE]: getLessonPlanEventMeetingRoomFailure,

    [Types.GET_LESSON_PLAN_SERVER_DATE_REQUEST]: getLessonPlanServerDateRequest,
    [Types.GET_LESSON_PLAN_SERVER_DATE_SUCCESS]: getLessonPlanServerDateSuccess,
    [Types.GET_LESSON_PLAN_SERVER_DATE_FAILURE]: getLessonPlanServerDateFailure
});
