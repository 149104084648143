import React, { useMemo, useCallback, memo, useContext, useEffect } from "react";

// Components
import Button from "components/Button";
import Checkbox from "components/Form/Checkbox";
import TextField from "components/Form/TextField";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { Step3Fields } from "../types";
import { useForm } from "hooks/use-form";
import { MigrationUserContext } from "../context";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { ErrorMessage } from "assets/styles/global";
import { StepForm, StepText, StepFormGroup, StepFormGroupAlter, StepWrapper, StepFormPasswordWrapper, StepTermsBox } from "../styles";
import { theme } from "config/theme";

const Step3: React.FC = () => {
    const reduxDispatch = useDispatch();
    const { isLoading, isTermsAccepted, error } = useSelector(({ credentials }: IReduxStore) => credentials);

    const {
        state,
        dispatch,
        methods: { changeStep }
    } = useContext(MigrationUserContext);

    const termsUrl =
        theme.project.slug === "promilitares" ? "https://www.promilitares.com.br/termos-de-uso" : "https://www.proenem.com.br/termos-de-uso";

    useEffect(() => {
        if (isTermsAccepted) {
            changeStep();
        }
    }, [changeStep, isTermsAccepted]);

    const INITIAL_STATE = useMemo(
        () => ({
            isTermsAccepted: false,
            password: ""
        }),
        []
    );

    const [form, handleChange, setChange] = useForm<Step3Fields>(INITIAL_STATE);

    const hasErrors = useMemo(() => {
        const keys = Object.keys(form) as Array<keyof Step3Fields>;

        return keys.some((item) => {
            if (!form[item]) {
                return true;
            }

            return !Boolean(form[item]);
        });
    }, [form]);

    const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => handleChange(event), [handleChange]);
    const handleCheckChange = useCallback(() => setChange({ ...form, isTermsAccepted: !form.isTermsAccepted }), [form, setChange]);

    const handleSaveForm = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            // Set step3 to context
            dispatch({ type: "SET_PAYLOAD", payload: { ...state.payload, step3: form } });
            // Call migration request
            reduxDispatch({ type: "MIGRATE_USER_REQUEST", payload: { ...state.payload, step3: form } });
        },
        [dispatch, form, reduxDispatch, state.payload]
    );

    return (
        <StepWrapper>
            <StepText>Também atualizamos os nossos Termos de uso. É necessário que você leia e concorde para prosseguir:</StepText>
            <StepForm onSubmit={handleSaveForm}>
                <StepText>
                    Leia nossos{" "}
                    <a href={termsUrl} rel="noopener noreferrer" target="_blank">
                        Termos de Uso
                    </a>
                    :
                </StepText>
                <StepFormGroupAlter>
                    <StepTermsBox>
                        <p>
                            Ao prosseguir com a utilização dos Serviços, você estará consentindo de forma livre, informada e inequívoca que o ProENEM
                            ou qualquer de seus sociedades afiliadas poderá fornecer seus Dados e Suas Informações (conforme definidos nos Termos de
                            Uso ao qual você já aderiu anteriormente) a um ou mais Operadores, a fim de viabilizar o tratamento de Dados e Suas
                            Informações pelo ProENEM, inclusive no exterior, para as finalidades: (i) fornecer os Serviços; (ii) personalizar os
                            Serviços; (iii) aprimorar nossa Plataforma; (iv) viabilizando um satisfatório funcionamento dos Serviços e da Plataforma;
                            (v) informar os usuários sobre as atualizações disponíveis, ofertas, promoções e campanhas de publicidade de nossos
                            Serviços (sejam Serviços atualmente fornecidos ou serviços que venhamos a desenvolver e a fornecer no futuro) e de
                            serviços fornecidos ou a serem fornecidos no futuro por nossos parceiros; e (vi) desenvolver novos produtos e/ou Serviços
                            pelo ProENEM ou por qualquer sociedade Afiliada do ProEnem ou qualquer empresa parceira do ProENEM utilizando-se de tais
                            dados como ferramenta estatística ou experimental, incluindo, sem limitação, os dados de acesso, visualizações, provas e
                            simulados realizados, redações, interações de todas e quaisquer naturezas do usuário com a plataforma. Por meio da
                            utilização de nossos serviços e acesso a nossa plataforma, você concorda expressamente com o compartilhamento dos seus
                            dados pessoais com nossos parceiros comerciais para o oferecimento de serviços ou produtos, observado que tais parceiros
                            deverão sempre respeitar os Termos de Uso atualmente em vigor. Ao prosseguir com a utilização dos Serviços você estará
                            ratificando todos os termos e condições dos Termos de Uso atualmente em vigor para a prestação dos Serviços do ProENEM.
                        </p>
                    </StepTermsBox>
                    <Checkbox
                        data-test-id="use-terms"
                        checked={form.isTermsAccepted}
                        label={`Declaro que li e estou ciente e de acordo com as novos Termos de Uso.`}
                        required={true}
                        onChange={handleCheckChange}
                    />
                </StepFormGroupAlter>
                <StepFormGroup>
                    <StepFormPasswordWrapper>
                        <StepText>Para sua segurança, você precisa inserir novamente a sua senha:</StepText>
                        <TextField required={true} type="password" name="password" label="Senha" value={form.password} onChange={handleFieldChange} />
                    </StepFormPasswordWrapper>
                </StepFormGroup>
                <StepFormGroup>
                    <Button
                        data-test-id="go-to-next-step"
                        disabled={isLoading || hasErrors}
                        isLoading={isLoading}
                        variant="info"
                        size="medium"
                        block={true}
                        type="submit"
                    >
                        Salvar informações
                    </Button>
                </StepFormGroup>
            </StepForm>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </StepWrapper>
    );
};

export default memo(Step3);
