const formatPercent = (number: number) => {
    const formatNumber = Math.floor(number);

    if (formatNumber < 0 || isNaN(formatNumber)) {
        return 0;
    }

    if (formatNumber > 100) {
        return 100;
    }

    return formatNumber;
};

export default formatPercent;
