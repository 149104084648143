import React, { useState } from "react";
import { theme } from "config/theme";
import Button from "components/Button";
import { ExerciseListSplashContainer } from "./styles";

import SadIcon from "prodigio-icons/web/filled/Sad";
import Spinner from "components/Spinner";

interface IProps {
    error?: boolean;
}

const ExerciseListSplash = ({ error = false }: IProps) => {
    const [clicked, setClicked] = useState(false);

    return (
        <ExerciseListSplashContainer>
            {error ? (
                <>
                    <SadIcon className="sadIcon" width={30} height={30} />
                    <strong>Desculpe, tivemos um problema.</strong>
                    <Button
                        variant="info"
                        onClick={() => {
                            window.location.reload();
                            setClicked(true);
                        }}
                        isLoading={clicked}
                    >
                        Tente novamente
                    </Button>
                </>
            ) : (
                <>
                    <img src={theme.project.logoSmall} alt={theme.project.name} />
                    <Spinner fixed={false} size={25} />
                </>
            )}
        </ExerciseListSplashContainer>
    );
};

export default React.memo(ExerciseListSplash);
