import { put, takeLatest, all, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";

import { Types as NotificationTypes, Creators as notificationActions } from "store/ducks/notification";

import api from "services/api";

export function* notificationsRequest() {
    try {
        const [notificationsAll, notificationsNotRead]: Array<AxiosResponse> = yield all([
            call(api.get, "/student/notification/all"),
            call(api.get, "/student/notification/notRead")
        ]);

        const payload = {
            all: !!notificationsAll.data.length ? notificationsAll.data : [],
            notRead: !!notificationsNotRead.data.length ? notificationsNotRead.data : []
        };

        yield put(notificationActions.getNotificationsSuccess(payload));
    } catch (error) {
        console.log(error);
        yield put(notificationActions.getNotificationsFailure());
    }
}

export function* setReadAllNotificationsRequest() {
    try {
        yield call(api.post, "/student/notification/setReadAllNotification");

        yield put(notificationActions.getNotificationsRequest());

        yield put(notificationActions.setReadAllNotificationsSuccess());
    } catch (error) {
        console.log(error);
        yield put(notificationActions.setReadAllNotificationsFailure());
    }
}

export function* setViewedAllNotificationsRequest() {
    try {
        yield call(api.post, "/student/notification/setViewedAllNotification");

        yield put(notificationActions.setViewedAllNotificationsSuccess());
    } catch (error) {
        console.log(error);
        yield put(notificationActions.setReadAllNotificationsFailure());
    }
}

export function* setReadByIdNotificationRequest(action: { type: string; payload: { notificationId: string } }) {
    try {
        const { notificationId } = action.payload;

        yield call(api.post, `/student/notification/${notificationId}/read`);

        yield put(notificationActions.setReadByIdNotificationSuccess());
    } catch (error) {
        console.log(error);
        yield put(notificationActions.setReadByIdNotificationFailure());
    }
}

export default [
    takeLatest(NotificationTypes.GET_NOTIFICATIONS_REQUEST, notificationsRequest),
    takeLatest(NotificationTypes.SET_READ_ALL_NOTIFICATIONS_REQUEST, setReadAllNotificationsRequest),
    takeLatest(NotificationTypes.SET_VIEWED_ALL_NOTIFICATIONS_REQUEST, setViewedAllNotificationsRequest),
    takeLatest(NotificationTypes.SET_READ_BY_ID_NOTIFICATION_REQUEST, setReadByIdNotificationRequest)
];
