import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ChipCloseButtonClickedPayload, WebpChip } from "components/WebpChip";
import { WebpFilterItem } from "components/WebpFilterItem";
import {
    CustomListFilterCheckbox,
    CustomListFilterFormData,
    ExerciseListCustomStateKeys,
    ExerciseListCustomStateKeysTypes,
    IExerciseListCustomState,
    QuestionLevelEnumToOption,
    QuestionStatusEnumToOption
} from "../../../../../interfaces/IExerciseListCustom";
import { Types } from "../../../../../store/ducks/exerciseListCustom";
import { dropdownFilterHasCheckedOptions } from "../../helpers";
import { DropdownFilterAvailableControls } from "../FilterForm/types";
import { FilterListWrapper } from "./styles";

interface IFilterListingProps {
    handleFilterChange: (filterName: string, payload: any, currentState?: boolean | CustomListFilterFormData | undefined) => void;
    exerciseListCustomState: IExerciseListCustomState;
}

function FilterListing({ handleFilterChange, exerciseListCustomState }: IFilterListingProps) {
    const {
        subjects,
        topics,
        subtopics,
        questionType,
        contestsWithAuthoredQuestions,
        contest,
        contestYear,
        contestPhase,
        questionStatus,
        questionLevel,
        isCancelled,
        isFromAnotherList
    } = exerciseListCustomState;

    const formWrapper = window.document.querySelector("#filter-form-wrapper");
    const formWrapperHeight = formWrapper?.clientHeight || 0;
    const scrollableWrapper = window.document.querySelector("#scrollable");

    const scrollableWidth = scrollableWrapper?.scrollWidth || 0;

    const shouldDisplayEmptyStateFilterList =
        dropdownFilterHasCheckedOptions(subjects) ||
        dropdownFilterHasCheckedOptions(topics) ||
        dropdownFilterHasCheckedOptions(subtopics) ||
        dropdownFilterHasCheckedOptions(questionType) ||
        dropdownFilterHasCheckedOptions(contestsWithAuthoredQuestions) ||
        dropdownFilterHasCheckedOptions(contest) ||
        dropdownFilterHasCheckedOptions(contestYear) ||
        dropdownFilterHasCheckedOptions(contestPhase) ||
        questionStatus.length > 0 ||
        questionLevel.length > 0 ||
        isCancelled ||
        isFromAnotherList;

    const dispatch = useDispatch();

    const handleClearFilters = () => {
        dispatch({ type: Types.CLEAR_STATE });
        dispatch({ type: Types.GET_ALL_SUBJECTS_REQUEST });
        dispatch({ type: Types.GET_QUESTION_TYPE_REQUEST });
    };

    const handleRemoveFilterItem = (
        item: CustomListFilterCheckbox,
        currentState: CustomListFilterFormData,
        stateName: ExerciseListCustomStateKeysTypes
    ): any => {
        handleFilterChange(
            stateName,
            {
                detail: {
                    item: {
                        ...item,
                        checked: false
                    }
                }
            } as CustomEvent,
            currentState
        );
    };

    const renderCheckedOptions = (options: any, state: CustomListFilterFormData, stateName: ExerciseListCustomStateKeysTypes): any => {
        if (options.type === DropdownFilterAvailableControls.list) {
            return renderCheckedOptions(options.options, state, stateName);
        }

        return options.map((option: CustomListFilterCheckbox) => {
            if (option.checked) {
                return (
                    <WebpChip
                        slot="options"
                        withCloseButton
                        onChipCloseButtonClicked={() => handleRemoveFilterItem(option, state, stateName)}
                        label={option.label}
                        key={option.id}
                    />
                );
            }
            return null;
        });
    };

    const renderQuestionStatusCheckedOptions = useCallback(() => {
        if (questionStatus && questionStatus.length > 0) {
            return questionStatus.map((option: any) => (
                <WebpChip
                    slot="options"
                    withCloseButton
                    onChipCloseButtonClicked={(payload: ChipCloseButtonClickedPayload) =>
                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_STATUS, payload, questionStatus)
                    }
                    label={QuestionStatusEnumToOption[option]}
                    key={QuestionStatusEnumToOption[option]}
                />
            ));
        }
    }, [handleFilterChange, questionStatus]);

    const renderQuestionLevelCheckedOptions = useCallback(() => {
        if (questionLevel && questionLevel.length > 0) {
            return questionLevel.map((option: any) => (
                <WebpChip
                    slot="options"
                    withCloseButton
                    onChipCloseButtonClicked={(payload: ChipCloseButtonClickedPayload) =>
                        handleFilterChange(ExerciseListCustomStateKeys.QUESTION_LEVEL, payload, questionLevel)
                    }
                    label={QuestionLevelEnumToOption[option]}
                    key={QuestionLevelEnumToOption[option]}
                />
            ));
        }
    }, [handleFilterChange, questionLevel]);

    const showClearButton =
        dropdownFilterHasCheckedOptions(subjects) ||
        dropdownFilterHasCheckedOptions(topics) ||
        dropdownFilterHasCheckedOptions(questionType) ||
        dropdownFilterHasCheckedOptions(contestsWithAuthoredQuestions) ||
        dropdownFilterHasCheckedOptions(contest) ||
        dropdownFilterHasCheckedOptions(contestYear) ||
        dropdownFilterHasCheckedOptions(contestPhase) ||
        questionStatus.length > 0 ||
        questionLevel.length > 0 ||
        isCancelled ||
        isFromAnotherList ||
        false;

    return (
        <>
            <FilterListWrapper
                titleText="Filtros selecionados"
                descriptionText="Selecione dentre as opções ao lado para começar a filtrar as questões para criar sua lista. "
                showClearButton={showClearButton}
                onClearButton={() => handleClearFilters()}
                scrollableWidth={scrollableWidth}
                formWrapperHeight={formWrapperHeight}
                shouldDisplayEmptyState={shouldDisplayEmptyStateFilterList}
                type={"exercise-list-custom"}
                filterItemComposition={
                    <>
                        {dropdownFilterHasCheckedOptions(subjects) && (
                            <WebpFilterItem
                                titleText="Matérias"
                                optionsComposition={
                                    subjects && renderCheckedOptions(subjects.options, subjects, ExerciseListCustomStateKeys.SUBJECTS)
                                }
                            />
                        )}

                        {dropdownFilterHasCheckedOptions(topics) && (
                            <WebpFilterItem
                                titleText="Tópicos"
                                optionsComposition={
                                    topics &&
                                    topics.options.map((subject: any) => renderCheckedOptions(subject, topics, ExerciseListCustomStateKeys.TOPICS))
                                }
                            />
                        )}
                        {dropdownFilterHasCheckedOptions(subtopics) && (
                            <WebpFilterItem
                                titleText="Subtópicos"
                                optionsComposition={
                                    subtopics &&
                                    subtopics.options.map((subject: any) =>
                                        subject.options.map((topic: any) =>
                                            renderCheckedOptions(topic, subtopics, ExerciseListCustomStateKeys.SUBTOPICS)
                                        )
                                    )
                                }
                            />
                        )}
                        {dropdownFilterHasCheckedOptions(questionType) && (
                            <WebpFilterItem
                                titleText="Tipo de Questão"
                                optionsComposition={
                                    questionType &&
                                    renderCheckedOptions(questionType.options, questionType, ExerciseListCustomStateKeys.QUESTION_TYPE)
                                }
                            />
                        )}
                        {dropdownFilterHasCheckedOptions(contestsWithAuthoredQuestions) && (
                            <WebpFilterItem
                                titleText="Questões autorais para"
                                optionsComposition={
                                    contestsWithAuthoredQuestions &&
                                    renderCheckedOptions(
                                        contestsWithAuthoredQuestions.options,
                                        contestsWithAuthoredQuestions,
                                        ExerciseListCustomStateKeys.CONTESTS_WITH_AUTHORED_QUESTIONS
                                    )
                                }
                            />
                        )}
                        {dropdownFilterHasCheckedOptions(contest) && (
                            <WebpFilterItem
                                titleText="Concursos"
                                optionsComposition={contest && renderCheckedOptions(contest.options, contest, ExerciseListCustomStateKeys.CONTEST)}
                            />
                        )}

                        {dropdownFilterHasCheckedOptions(contestYear) && (
                            <WebpFilterItem
                                titleText="Ano"
                                optionsComposition={
                                    contestYear &&
                                    contestYear.options.map((contestName: any) =>
                                        renderCheckedOptions(contestName, contestYear, ExerciseListCustomStateKeys.CONTEST_YEAR)
                                    )
                                }
                            />
                        )}

                        {dropdownFilterHasCheckedOptions(contestPhase) && (
                            <WebpFilterItem
                                titleText="Fases"
                                optionsComposition={
                                    contestPhase &&
                                    contestPhase.options.map((contest: any) =>
                                        contest.options.map((contestYear: any) =>
                                            renderCheckedOptions(contestYear, contestPhase, ExerciseListCustomStateKeys.CONTEST_PHASE)
                                        )
                                    )
                                }
                            />
                        )}

                        {questionStatus.length > 0 && (
                            <WebpFilterItem
                                titleText="Status das questões"
                                optionsComposition={questionStatus && renderQuestionStatusCheckedOptions()}
                            />
                        )}
                        {questionLevel.length > 0 && (
                            <WebpFilterItem
                                titleText="Dificuldade das questões"
                                optionsComposition={questionLevel && renderQuestionLevelCheckedOptions()}
                            />
                        )}

                        {(isCancelled || isFromAnotherList) && (
                            <WebpFilterItem
                                titleText="Não incluir questões"
                                optionsComposition={
                                    <>
                                        {isCancelled && (
                                            <WebpChip
                                                withCloseButton
                                                onChipCloseButtonClicked={(payload: ChipCloseButtonClickedPayload) =>
                                                    handleFilterChange(ExerciseListCustomStateKeys.IS_CANCELLED, payload, isCancelled)
                                                }
                                                label="Anuladas"
                                            />
                                        )}
                                        {isFromAnotherList && (
                                            <WebpChip
                                                withCloseButton
                                                onChipCloseButtonClicked={(payload: ChipCloseButtonClickedPayload) =>
                                                    handleFilterChange(ExerciseListCustomStateKeys.IS_FROM_ANOTHER_LIST, payload, isFromAnotherList)
                                                }
                                                label="De outras listas"
                                            />
                                        )}
                                    </>
                                }
                            ></WebpFilterItem>
                        )}
                    </>
                }
            ></FilterListWrapper>
        </>
    );
}

export default FilterListing;
