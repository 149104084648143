import React, { useCallback } from "react";

// Redux
import { useSelector } from "react-redux";

// Helpers
import { theme } from "config/theme";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { AlertFeedbackButton, AlertFeedbackContainer, AlertFeedbackText } from "./styles";

const AlertUpdate = () => {
    const { serviceWorkerRegistration, serviceWorkerUpdated } = useSelector((state: IReduxStore) => state.update);

    const handleUpdate = useCallback(() => {
        const registrationWaiting = serviceWorkerRegistration?.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: "SKIP_WAITING" });

            registrationWaiting.addEventListener("statechange", (e: any) => {
                if (e.target.state === "activated") {
                    window.location.reload();
                }
            });
        }
    }, [serviceWorkerRegistration]);

    if (!serviceWorkerUpdated) {
        return null;
    }

    return (
        <AlertFeedbackContainer variant="success">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="ico ico--thunder">
                <path
                    d="M216.056 509.316l197.488-340.044c4.471-7.699-1.87-17.173-10.692-15.973l-131.364 17.855L302.875 6.372c1.058-5.555-6.104-8.738-9.518-4.231L99.183 258.451c-5.656 7.465.333 18.08 9.647 17.1l144.828-15.245-47.479 245.308c-1.145 5.917 6.85 8.914 9.877 3.702z"
                    fill="#ffdc64"
                />
                <path
                    d="M159.905 240.287c-3.627.29-6.036-3.675-4.108-6.76L300.976 1.241c-2.16-1.709-5.56-1.819-7.619.899L99.183 258.45c-5.656 7.466.333 18.08 9.647 17.1l144.828-15.245-47.479 245.308c-.64 3.309 1.592 5.637 4.201 6.194l81.359-257.447c3.814-12.067-5.808-24.156-18.423-23.146l-113.411 9.073z"
                    fill="#ffc850"
                />
            </svg>
            <AlertFeedbackText>Uma nova versão do {theme.project.name} está disponível.</AlertFeedbackText>
            <AlertFeedbackButton onClick={handleUpdate}>Clique para atualizar</AlertFeedbackButton>
        </AlertFeedbackContainer>
    );
};

export default AlertUpdate;
