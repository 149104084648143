import React, { memo, useMemo } from "react";

// Components
import SubjectCard from "components/SubjectCard";

// Helpers
import { theme } from "config/theme";
import { ISubjectGroup, ISubject } from "interfaces/ISubject";

import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { SubjectCardName as EmptyText } from "components/SubjectCard/styles";
import { SubjectGroupCard, SubjectGroupCards, SubjectGroupContainer, SubjectGroupTitle } from "./styles";
import SubjectGroupSkeleton from "./skeleton";

interface ISubjectGroupProps {
    subjectGroup: ISubjectGroup;
    isLoading?: boolean;
}

const SubjectGroup = ({ subjectGroup, isLoading = false }: ISubjectGroupProps) => {
    const isPromilitares = theme.project.slug === "promilitares";

    const { progressItems } = useSelector((state: IReduxStore) => state.subject);

    const subjectsFormatted = subjectGroup?.subjects?.map((item) => {
        const progress = progressItems?.find((progressItem) => progressItem?.subjectId === item?.subject?.id);

        if (progress) {
            return {
                ...item,
                subject: {
                    ...item.subject,
                    progress: progress.progress
                }
            };
        }

        return item;
    });

    const renderSubjectCard = useMemo(
        () => (subject: { id: number; order: number; subject: ISubject }) => <SubjectCard subject={subject.subject} />,
        []
    );

    if (isLoading) {
        return <SubjectGroupSkeleton length={2} />;
    }

    return (
        <SubjectGroupContainer data-test-id="subject-group">
            {!isPromilitares && (
                <SubjectGroupTitle data-test-id="subject-group-title">
                    <strong>{subjectGroup.name}</strong>
                </SubjectGroupTitle>
            )}
            {!!subjectsFormatted?.length ? (
                <SubjectGroupCards>
                    {subjectsFormatted?.map((subject) => (
                        <SubjectGroupCard key={subject.id}>{renderSubjectCard(subject)}</SubjectGroupCard>
                    ))}
                </SubjectGroupCards>
            ) : (
                <EmptyText>Nenhuma disciplina encontrada</EmptyText>
            )}
        </SubjectGroupContainer>
    );
};

export default memo(SubjectGroup);
