import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";

export const AttachmentsContainer = styled.div``;

export const AttachmentsHelper = styled.span`
    ${BodyText2};
    color: #666b7b;
    display: block;
    margin-bottom: ${theme.spacing.small};
`;

export const AttachmentsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const AttachmentsContentButton = styled.button`
    align-items: center;
    background: ${theme.colors.base[100]};
    box-shadow: ${theme.shadow[2]};
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    position: absolute;
    right: -12px;
    top: -12px;
    width: 24px;
`;

export const AttachmentsContentItem = styled.div`
    position: relative;
    margin: 0 ${theme.spacing.small} ${theme.spacing.small} 0;
    height: 100px;
    width: calc((100% / 7) - 14px);
`;

export const AttachmentsContentLink = styled.a<{ bg: string }>`
    background: ${({ bg }) => `url(${bg}) center no-repeat`};
    background-size: cover;
    border-radius: ${theme.spacing.smallX};
    display: block;
    height: 100%;
    overflow: hidden;
    width: 100%;

    img {
        display: block;
        height: auto;
        max-width: 100%;
    }

    &:nth-child(7n + 7) {
        margin-right: 0;
    }

    &:nth-last-child(-n + 7) {
        margin-bottom: 0;
    }

    ${MediaQueries.SMALL} {
        margin: 0 ${theme.spacing.small} ${theme.spacing.small} 0 !important;
        width: calc(50% - ${theme.spacing.smallX});

        &:nth-child(2n + 2) {
            margin-right: 0 !important;
        }

        &:nth-last-child(-n + 2) {
            margin-bottom: 0 !important;
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        margin: 0 ${theme.spacing.small} ${theme.spacing.small} 0 !important;
        width: calc((100% / 6) - 14px);

        &:nth-child(6n + 6) {
            margin-right: 0 !important;
        }

        &:nth-last-child(-n + 6) {
            margin-bottom: 0 !important;
        }
    }
`;
