import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import Card from "components/Card";
import { EssayAttachCol } from "components/MakeEssay/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { SwitcherContainer } from "components/Switcher/styles";

export const ExerciseListEssayContainer = styled(Card)<{ center?: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-bottom: ${theme.spacing.large};
    box-shadow: ${theme.shadow[1]};
    position: relative;
    padding: ${theme.spacing.small};

    ${EssayAttachCol} {
        padding: 0;
    }

    ${({ center }) =>
        center &&
        css`
            align-items: center;
            padding: 40px 0;

            > svg {
                margin-bottom: 40px;
            }
        `};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        border-left: 8px solid #fc9f5b;

        padding: ${theme.spacing.medium} ${theme.spacing.large};
    }
`;

export const ExerciseListEssayThemeContainer = styled.div`
    display: flex;
    margin-bottom: 40px;

    display: flex;
    flex: 1;
    flex-direction: column;

    > strong {
        color: ${theme.colors.base[400]};
        display: block;
        margin-bottom: 8px;
    }

    > p {
        margin-bottom: 16px;
    }
`;

export const ExerciseListEssayThemeDownloadButtons = styled.div`
    display: flex;

    ${ButtonWrapper} {
        > svg {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            /* color: #000; */
        }

        :not(:last-child) {
            margin-right: 16px;
        }
    }
`;

export const ExerciseListEssayWriteInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.small};

    > svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExerciseListEssayCorrectionContainer = styled.div`
    ${SwitcherContainer} {
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const ExerciseListEssayThemeInfoCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.medium};

    span {
        max-width: 1100px;
        background-color: ${theme.colors.system.warning[100]};
        color: ${theme.colors.base[400]};
        padding: ${theme.spacing.medium};
        border-radius: ${theme.spacing.smallXX};
    }
`;
