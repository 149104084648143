import React, { useMemo, useCallback, useEffect } from "react";
import { ExamResultContainer, ExamResultLeft as Left, ExamResultRight as Right } from "./styles";
import { RouteComponentProps } from "react-router-dom";

import { Row, Grid } from "components/Grid";
import KnowledgeAreasCard from "./components/KnowledgeAreasCard";
import PageHeader from "components/PageHeader";
import ContestCard from "./components/ContestCard";
import { ExamResultCardsSkeleton, ExamResultKnowledgeAreasSkeleton } from "./skeleton";
import NoteCard from "./components/NoteCard";
import DesktopResolutionsDropdown from "./components/DesktopResolutionsDropdown";
import ExamResultRealizationsAccordion from "./components/RealizationsAccordion";
import FeedBackList from "./components/FeedBackList";
import Card from "components/Card";
import ExamResultEssay from "components/ExamResultEssay";

import { theme } from "config/theme";
import history from "services/history";

import { IReduxStore } from "interfaces/IReduxStore";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as examActions } from "store/ducks/exam";

import { IExamActionsCreators } from "store/ducks/exam/types";
import { IExam, IExamResultCurrent, IExamResolution, IExamScore } from "interfaces/IExam";

interface IProps {
    currentResult?: IExamResultCurrent;
    scores: IExamScore[];
    exam: IExam;
    loadingResult: boolean;
    loading: boolean;
    loadingCurrentResult: boolean;
    onClickScore(score: IExamScore): void;
    resolutions: IExamResolution[];
    onChangeResolution(resolutionId: number): void;
}

const ExamResult = ({
    currentResult,
    scores = [],
    exam,
    loading = true,
    loadingResult = true,
    loadingCurrentResult = true,
    onClickScore,
    resolutions = [],
    onChangeResolution
}: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const knowledgeAreas = useMemo(() => currentResult?.knowledgeAreas || [], [currentResult]);

    const mainCard = useMemo(() => {
        switch (true) {
            case scores?.length === 1:
            case !scores?.length && !!currentResult?.resolution:
                return <NoteCard currentResult={currentResult} knowledgeAreas={knowledgeAreas} />;
            case scores?.length > 1:
                return <ContestCard scores={scores} onClickScore={onClickScore} activeScoreId={currentResult?.score?.id || 0} />;
            case loading:
            default:
                return <ExamResultCardsSkeleton />;
        }
    }, [currentResult, knowledgeAreas, loading, onClickScore, scores]);

    const title = currentResult ? `Resultado ${currentResult?.currentResolution?.attempt}ª realização ` : "";

    const handleClickEssay = useCallback(() => {
        const { examDay, answerCard } = currentResult?.composition || {};

        if (!examDay || !answerCard) {
            return;
        }

        history.push({ pathname: `/app/curso/${courseSlug}/simulados/${exam?.id}/${examDay}/prova/${answerCard}?redacao=1` });
    }, [courseSlug, currentResult, exam]);

    return (
        <ExamResultContainer id="ExamResultContainer">
            <PageHeader.Simple
                breadcrumb={{
                    current: {
                        label: title
                    },
                    parent: [
                        {
                            url: `/app/curso/${courseSlug}/simulados`,
                            label: "Simulados"
                        },
                        {
                            url: `/app/curso/${courseSlug}/simulados`,
                            label: exam?.name
                        }
                    ]
                }}
                right={
                    <DesktopResolutionsDropdown
                        resolutions={resolutions}
                        currentResolution={currentResult?.currentResolution}
                        onChangeResolution={onChangeResolution}
                    />
                }
            />

            <Grid>
                <Row>
                    <Left xs={12} sm={6} md={6} spacingAccordion={scores?.length > 1}>
                        {mainCard}

                        <ExamResultRealizationsAccordion resolutions={resolutions} onClickResolution={onChangeResolution} />
                    </Left>

                    <Right xs={12} sm={6} md={6}>
                        {loadingCurrentResult ? (
                            <ExamResultKnowledgeAreasSkeleton />
                        ) : (
                            <>
                                {!!currentResult?.composition?.idComposition && (
                                    <Card style={{ marginBottom: theme.spacing.small }}>
                                        <ExamResultEssay
                                            composition={currentResult?.composition}
                                            onClickEssay={handleClickEssay}
                                            rankingPosition={currentResult?.ranking?.compositionPosition}
                                            totalRankUsers={currentResult?.ranking?.total}
                                        />
                                    </Card>
                                )}

                                <KnowledgeAreasCard knowledgeAreas={knowledgeAreas} />
                            </>
                        )}

                        {scores?.length > 1 && <FeedBackList />}
                    </Right>
                </Row>
            </Grid>
        </ExamResultContainer>
    );
};

export interface IExamResultProps extends RouteComponentProps<{ examSlug: string; resolutionId: string }> {
    currentResult?: IExamResultCurrent;
    scores: IExamScore[];
    examActions: IExamActionsCreators;
    exam: IExam;
    loadingResult: boolean;
    loading: boolean;
    loadingCurrentResult: boolean;
    resolutions: IExamResolution[];
}

const ExamResultWrapper = ({
    examActions,
    match,
    scores = [],
    currentResult,
    exam,
    loading = true,
    loadingResult = true,
    loadingCurrentResult = true,
    resolutions = []
}: IExamResultProps) => {
    const { examSlug = "", resolutionId } = match?.params;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const requestExam = useCallback(() => {
        if (!examSlug) {
            return;
        }

        examActions.getExamRequest({ slug: examSlug, resolutionId });
    }, [examActions, examSlug, resolutionId]);

    useEffect(() => requestExam(), [requestExam]);

    const handleClickScore = useCallback(
        (score: IExamScore) => {
            if (!score?.id) {
                return;
            }

            examActions.getExamResultScoreRequest(score);
        },
        [examActions]
    );

    const handleChangeResolution = useCallback(
        (resolutionId: number) => {
            history.push(`/app/curso/${courseSlug}/simulados/${examSlug}/resultado/${resolutionId}`);
        },
        [courseSlug, examSlug]
    );

    return (
        <ExamResult
            currentResult={currentResult}
            scores={scores}
            exam={exam}
            loading={loading}
            loadingResult={loadingResult}
            loadingCurrentResult={loadingCurrentResult}
            onClickScore={handleClickScore}
            resolutions={resolutions}
            onChangeResolution={handleChangeResolution}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    examActions: bindActionCreators(examActions, dispatch)
});

const mapStateToProps = ({ exam }: IReduxStore) => ({
    currentResult: exam?.currentExamResult,
    scores: exam?.scores || [],
    exam: {
        id: exam.id,
        name: exam.name,
        slug: exam.slug
    },
    loading: exam.loading,
    loadingResult: exam.loadingResult,
    loadingCurrentResult: exam.loadingCurrentResult,
    resolutions: exam?.resolutions || [],
    composition: exam?.currentExamResult?.composition
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultWrapper);
