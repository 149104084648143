import React, { memo, useMemo } from "react";

import AttentionIcon from "prodigio-icons/web/outlined/Attention";
import LikeIcon from "prodigio-icons/web/outlined/Like";
import { ExamResultFeedbackContainer } from "./styles";
import { TitleH5 } from "assets/styles/global";

interface IProps {
    variant: "success" | "danger" | "warning";
    title: string;
    legend: string;
}

const ExamResultFeedback = memo(({ variant, title, legend }: IProps) => {
    const renderIcon = useMemo(() => {
        if (!variant) {
            return null;
        }

        const icons = {
            success: <LikeIcon width={16} height={16} />,
            danger: <AttentionIcon width={16} height={16} />,
            warning: <AttentionIcon width={16} height={16} />
        };

        return icons[variant] || null;
    }, [variant]);

    return (
        <ExamResultFeedbackContainer variant={variant}>
            {renderIcon}

            <div>
                <TitleH5>{title}</TitleH5>
                <p>{legend}</p>
            </div>
        </ExamResultFeedbackContainer>
    );
});

export default ExamResultFeedback;
