import { PageContainer } from "assets/styles/global";
import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { PageHeaderContainer } from "components/PageHeader/styles";
import { Col } from "components/Grid";
import { ExamResultRealizationsAccordionContainer } from "./components/RealizationsAccordion/styles";

export const ExamResultContainer = styled(PageContainer)`
    padding-bottom: ${theme.spacing.medium};

    ${PageHeaderContainer} {
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const ExamResultRight = styled(Col)``;

export const ExamResultLeft = styled(Col)<{ spacingAccordion: boolean }>`
    ${ExamResultRealizationsAccordionContainer} {
        ${({ spacingAccordion }) =>
            spacingAccordion &&
            css`
                margin-right: ${theme.spacing.small};
            `};
    }
`;
