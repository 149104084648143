import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAnswerCardItem } from "store/ducks/answerCard";
import { WebpRadioGroup, WebpRadioInput } from "webapp-components/dist/libs/webp-components-react";
import { WebpText } from "components/WebpText";
import { IQuestion } from "../../../../../interfaces/IQuestion";
import { IReduxStore } from "../../../../../interfaces/IReduxStore";
import { Types } from "../../../../../store/ducks/answerCard";
import { RadioGroupWrapper } from "./styles";

interface IAnswerHit {
    answer?: IAnswerCardItem;
    question?: IQuestion;
}

function AnswerHit({ answer, question }: IAnswerHit) {
    const dispatch = useDispatch();
    const exerciseListState = useSelector((state: IReduxStore) => state.exerciseList);

    const handleRadioCheck = (ev: CustomEvent) => {
        const idAnswerCard = exerciseListState.realizations?.[exerciseListState.realizations.length - 1].id;
        const idQuestion = exerciseListState.currentQuestion?.id;
        const checkedValue = ev.detail.value === "acertei" ? true : false;

        dispatch({
            type: Types.SET_OPEN_QUESTION_ANSWER_REQUEST,
            payload: {
                checkedValue: { checked: ev.detail.checked, value: checkedValue },
                idQuestion: idQuestion,
                idAnswerCard: idAnswerCard,
                question: question
            }
        });
    };

    const hit = useMemo(() => {
        return !!answer && answer.hit === true;
    }, [answer]);

    const noHit = useMemo(() => {
        return !!answer && answer.hit === false;
    }, [answer]);

    return (
        <RadioGroupWrapper>
            <WebpText color="neutral" colorWeight={400} xs="title-xss">
                <p>Minha resposta:</p>
            </WebpText>

            <WebpRadioGroup layout="horizontal" className="radio-answer-hit" onRadioGroupOptionCheck={(ev) => handleRadioCheck(ev)}>
                <WebpRadioInput label="Acertei" value="acertei" name="hit" checked={hit} colorVariant="outline-secondary"></WebpRadioInput>
                <WebpRadioInput label="Errei" value="errei" name="hit" checked={noHit} colorVariant="outline-secondary"></WebpRadioInput>
            </WebpRadioGroup>
        </RadioGroupWrapper>
    );
}

export default AnswerHit;
