import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { SubjectScreenDetailsDonutsContainer, SubjectScreenDetailsDonutsLine } from "../Donuts/styles";

import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";

export const SubjectScreenDetailsMobileSelectFrontContainer = styled.div`
    display: flex;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[2]};
    padding: 0 ${theme.spacing.small};
    border-radius: 8px;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
`;

export const SubjectScreenDetailsMobileSelectFrontTitle = styled.strong<{ selected?: boolean }>`
    font-weight: bold;
    color: ${theme.colors.base[500]};
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.small};
    flex: 1;

    ${({ selected }) =>
        !!selected &&
        css`
            flex: unset;
            font-size: ${theme.typography.sizes.medium};
            font-weight: 600;
        `}
`;

export const SubjectScreenDetailsMobileSelectFrontTexts = styled.p<{ column?: boolean }>`
    display: flex;
    flex-direction: row;
    flex: 1;

    ${({ column }) =>
        !!column &&
        css`
            flex-direction: column;

            ${SubjectScreenDetailsMobileSelectFrontTitle} {
                margin-bottom: ${theme.spacing.smallXX};
            }
        `};
`;

export const SubjectScreenDetailsMobileSelectFrontButton = styled.button.attrs({
    type: "button"
})<{ selected?: boolean }>`
    outline: none;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;

    -webkit-tap-highlight-color: transparent;

    padding: ${theme.spacing.small} 0;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    ${SubjectScreenDetailsDonutsContainer} {
        margin-right: ${theme.spacing.smallX};
    }

    ${SubjectScreenDetailsDonutsLine} {
        stroke-width: 5;
    }

    ${({ selected }) =>
        !!selected &&
        css`
            ${SubjectScreenDetailsMobileSelectFrontTexts} {
                margin-right: ${theme.spacing.smallX};
            }
        `};
`;

export const SubjectScreenDetailsMobileSelectFrontSubTitle = styled.span`
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[400]};
    font-family: ${theme.typography.family.secondary};
`;

export const SubjectScreenDetailsMobileSelectFrontChevronDown = styled(ChevronDown)<{ isactive: boolean }>`
    ${({ isactive }) =>
        !!isactive &&
        css`
            transform: rotate(180deg);
        `}
`;

export const SubjectScreenDetailsMobileSelectFrontModuleTitle = styled.p`
    font-weight: 600;
    font-family: ${theme.typography.family.primary};
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;
