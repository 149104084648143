import React, { ButtonHTMLAttributes } from "react";
import { ButtonWrapper } from "./styles";
import { Size } from "utils/types/size";
import { FlatVariant, OutlineVariant } from "utils/types/Variant";
import Spinner from "components/Spinner";
import { theme } from "config/theme";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: Size;
    variant: FlatVariant | OutlineVariant | "outline-base" | "base" | "outline-link" | "primary" | "secondary" | "outline-secondary";
    rounded?: boolean;
    block?: boolean | any;
    as?: any;
    to?: string;
    children: any;
    style?: React.CSSProperties;
    type?: "button" | "reset" | "submit";
    disabled?: boolean;
    isLoading?: boolean;
    onClick?(e: any): void;
    href?: any;
    target?: any;
}

const Button = ({ children, size = "small", type = "button", as = "button", isLoading = false, disabled, ...rest }: IButtonProps) => (
    <ButtonWrapper size={size} as={as} disabled={isLoading || disabled} {...rest}>
        {isLoading ? <Spinner fixed={false} size={16} color={theme.colors.white} /> : children}
    </ButtonWrapper>
);

export default Button;
