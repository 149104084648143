import React, { useState, useCallback, useEffect } from "react";

import * as S from "./styles";
import Button from "components/Button";
import { useModal } from "hooks/use-modal";
import { useAlert } from "hooks/use-alert";
import Spinner from "components/Spinner";

export interface IConfirmEssayImageModalProps {
    file: File;
    onConfirm(): void;
}

const ConfirmEssayImageModal: React.FC<IConfirmEssayImageModalProps> = ({ file, onConfirm }) => {
    const { close: closeModal } = useModal();
    const { show: showAlert } = useAlert();

    const [previewImage, setPreviewImage] = useState<string>();
    const [loading, setLoading] = useState(false);

    const getPreviewImage = useCallback(async () => {
        try {
            setLoading(true);

            const image = URL.createObjectURL(file);

            if (!image) {
                throw new Error();
            }

            setPreviewImage(image);
        } catch (error) {
            showAlert("Algo de errado com a imagem. Tente novamente.", "danger");
            closeModal();
        } finally {
            setLoading(false);
        }
    }, [closeModal, file, showAlert]);

    useEffect(() => {
        getPreviewImage();
    }, [getPreviewImage]);

    const handleClickSend = useCallback(() => {
        onConfirm();
        closeModal();
    }, [closeModal, onConfirm]);

    return (
        <S.ConfirmEssayImageModalContainer>
            <S.ConfirmEssayImageModalTitle>Preview da redação</S.ConfirmEssayImageModalTitle>

            {loading ? (
                <Spinner />
            ) : (
                <>
                    <img src={previewImage} alt="preview da redação" />
                    <S.ConfirmEssayImageModalLegend>Tudo certo com a sua redação?</S.ConfirmEssayImageModalLegend>
                    <S.ConfirmEssayImageModalButtonGroup>
                        <Button variant="success" type="button" size="medium" onClick={handleClickSend}>
                            Sim, enviar!
                        </Button>

                        <Button variant="danger" type="button" size="medium" onClick={closeModal}>
                            Não
                        </Button>
                    </S.ConfirmEssayImageModalButtonGroup>
                </>
            )}
        </S.ConfirmEssayImageModalContainer>
    );
};

export default ConfirmEssayImageModal;
