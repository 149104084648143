import React from "react";
import useIsMobile from "hooks/use-is-mobile";

import Skeleton from "components/Skeleton";
import CardDetailsAttachmentsPlaceholder from "./cardDetailsAttachmentsPlaceholder";

import * as S from "./styles";

const EssayCardDetailsPlaceholder = () => {
    const isMobile = useIsMobile();

    return (
        <S.EssayCardDetailsContainer>
            <S.EssayCardDetailsHeader>
                <S.EssayCardDetailsHeaderWrapper>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton width="4px" height="24px" style={{ borderRadius: 5, marginRight: 12 }} />
                        <Skeleton width="43px" height="16px" />
                    </div>
                    <S.EssayCardDetailsHeaderHelper>
                        <Skeleton width="140px" height="12px" />
                    </S.EssayCardDetailsHeaderHelper>
                </S.EssayCardDetailsHeaderWrapper>
                <S.EssayCardDetailsTitle>
                    <Skeleton width="100%" height="12px" style={{ marginBottom: 12 }} />
                    <Skeleton width="80%" height="12px" style={{ marginBottom: 12 }} />
                    <Skeleton width="60%" height="12px" />
                </S.EssayCardDetailsTitle>
            </S.EssayCardDetailsHeader>
            <S.EssayCardDetailsContent>
                <S.EssayCardDetailsList>
                    <S.EssayCardDetailsListItem style={{ color: "rgba(0,0,0,0.1)" }}>
                        <Skeleton width="150px" height="12px" style={{ display: "inline-block" }} />
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem style={{ color: "rgba(0,0,0,0.1)" }}>
                        <Skeleton width="150px" height="12px" style={{ display: "inline-block" }} />
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem style={{ color: "rgba(0,0,0,0.1)" }}>
                        <Skeleton width="150px" height="12px" style={{ display: "inline-block" }} />
                    </S.EssayCardDetailsListItem>
                </S.EssayCardDetailsList>
                <S.EssayCardDetailsContentHelper>
                    <Skeleton width="70%" height="12px" />
                </S.EssayCardDetailsContentHelper>
            </S.EssayCardDetailsContent>
            {isMobile && (
                <S.EssayCardDetailsFooter>
                    <CardDetailsAttachmentsPlaceholder />
                    {isMobile && <Skeleton width="100%" height="40px" style={{ borderRadius: 4 }} />}
                </S.EssayCardDetailsFooter>
            )}
        </S.EssayCardDetailsContainer>
    );
};

export default EssayCardDetailsPlaceholder;
