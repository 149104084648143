import React, { useCallback, useEffect, useMemo, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";

// Components
import Button from "components/Button";
import Editor from "components/Form/Editor";
import AttachmentsController from "screens/Forum/components/attachments-controller";

// Helpers
import { Types } from "store/ducks/forum";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { IForumUpload } from "store/interfaces/IForum";

// Assets
import * as F from "./styles";

interface IForumPostProps {
    attachments: IForumUpload[];
    isCreated: boolean;
    isCreatedKey: string;
    isLoading: boolean;
    onAttachments: () => void;
    onPost: (content: string) => void;
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
    content?: string;
    isEditing?: boolean;
    onCancel?: () => void;
}

const ForumPost: React.FC<IForumPostProps> = (props) => {
    const dispatch = useDispatch();
    const [content, setContent] = useState("");
    const isMobile = useIsMobile(BreakPoints.small);

    const { attachments, isCreated, isCreatedKey, isLoading, onAttachments, onRemoveAttachment, onRetryUpload, onPost, onCancel } = props;

    useEffect(() => {
        if (props.isEditing && props.content && !content) {
            setContent(props.content);
        }
    }, [content, props.content, props.isEditing]);

    useEffect(() => {
        if (isCreated && Boolean(content.length)) {
            setContent("");
            dispatch({ type: Types.CLEAR_FORUM_CREATED, payload: { key: isCreatedKey } });
        }
    }, [content.length, dispatch, isCreated, isCreatedKey]);

    const buttonSize = useMemo(() => (isMobile ? "smallX" : "medium"), [isMobile]);

    const handleContentChange = useCallback((_: any, editor: any) => setContent(editor.getData()), []);

    const handleSubmit = useCallback(() => onPost(content), [content, onPost]);

    const ButtonLabel = useMemo(() => (props.content && props.isEditing ? "Editar" : "Responder"), [props.content, props.isEditing]);

    return (
        <F.ForumPostContainer>
            <Editor data={content} onChange={handleContentChange} isPreventDefault={false} isPasteAsPlainText={false} />
            <F.ForumPostNavWrapper>
                <F.ForumPostNav>
                    <Button disabled={!Boolean(content.length >= 17) || isLoading} size={buttonSize} variant="primary" onClick={handleSubmit}>
                        {ButtonLabel}
                    </Button>
                    <Button className="btn-without-border" size={buttonSize} variant="outline-primary" onClick={onAttachments}>
                        Adicionar anexo
                    </Button>
                    {onCancel && (
                        <Button className="btn-without-border" size={buttonSize} variant="outline-danger" onClick={onCancel}>
                            Cancelar
                        </Button>
                    )}
                </F.ForumPostNav>
                {Boolean(attachments.length) && (
                    <AttachmentsController attachments={attachments} onRetryUpload={onRetryUpload} onRemoveAttachment={onRemoveAttachment} />
                )}
            </F.ForumPostNavWrapper>
        </F.ForumPostContainer>
    );
};

export default ForumPost;
