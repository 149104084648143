// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1360px;
    margin: 0 auto;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;

    @media (min-width: 768px) {
        padding: 24px;
    }
`;

export const SectionSpacing = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;

    @media (min-width: 768px) {
        padding: 0;
    }
`;

export const AvarageAndCommentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
        flex: 2;
    }

    @media (min-width: 1024px) {
        grid-gap: 16px;
    }

    @media (min-width: 1440px) {
        grid-gap: 32px;
    }
`;
