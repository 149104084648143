// Dependencies
import React, { FunctionComponent, useEffect } from "react";

// Styles
import {
    Container,
    PeriodWrapper,
    SelectedPeriodWrapper,
    ScheduleIconWrapper,
    ScheduleIcon,
    SelectedPeriodLabel,
    DayInformationsAssertiveness,
    Divider,
    AssertivenessWrapper,
    ActionsWrapper,
    PeriodOptionsWrapper
} from "./performance-navigator.styles";

// Types
import { PerformanceNavigatorProps } from "./performance-navigator.types";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceNavigator: FunctionComponent<PerformanceNavigatorProps> = ({
    scheduleIcon,
    period,
    totalQuestions,
    totalHits,
    hitsPercentage,
    isPeriodOptionsOpened,
    handlePeriodClick,
    periodOptionsComposition,
    previousColumnIconElement,
    nextColumnIconElement
}) => {
    const assertiveness = `${totalQuestions} questões, ${totalHits} acertos | ${hitsPercentage}% aproveitamento`;
    const mobileAssertiveness = assertiveness.split(" | ");

    useEffect(() => {
        const handleWindowClick = (event: any) => {
            if (
                !event.target?.closest(PeriodWrapper) &&
                !event.target?.closest(PeriodOptionsWrapper) &&
                isPeriodOptionsOpened &&
                window.innerWidth >= 768
            ) {
                handlePeriodClick();
            }
        };

        window.addEventListener("click", handleWindowClick);

        return () => {
            window.removeEventListener("click", handleWindowClick);
        };
    }, [isPeriodOptionsOpened, handlePeriodClick]);

    return (
        <Container>
            <PeriodWrapper onClick={handlePeriodClick}>
                <SelectedPeriodWrapper>
                    <ScheduleIconWrapper>
                        <ScheduleIcon src={scheduleIcon} />
                    </ScheduleIconWrapper>

                    <Spacing size={8} direction="horizontal" />

                    <SelectedPeriodLabel>{period}</SelectedPeriodLabel>
                </SelectedPeriodWrapper>

                <ScreenWidthRender
                    renderingWidth={768}
                    actionAfterRenderingWidth="show"
                    content={
                        <>
                            <Spacing size={4} direction="vertical" />

                            <DayInformationsAssertiveness>{assertiveness}</DayInformationsAssertiveness>
                        </>
                    }
                />
            </PeriodWrapper>

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="vertical" />} />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="show" content={<Spacing size={26} direction="horizontal" />} />

            <Divider />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="vertical" />} />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="show" content={<Spacing size={16} direction="horizontal" />} />

            <AssertivenessWrapper>
                <ScreenWidthRender
                    renderingWidth={768}
                    actionAfterRenderingWidth="hide"
                    content={
                        <DayInformationsAssertiveness>
                            {mobileAssertiveness[0]}
                            <br />
                            {mobileAssertiveness[1]}
                        </DayInformationsAssertiveness>
                    }
                />

                <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="horizontal" />} />

                <ActionsWrapper>
                    {previousColumnIconElement}

                    <Spacing size={24} direction="horizontal" />

                    {nextColumnIconElement}
                </ActionsWrapper>
            </AssertivenessWrapper>

            <ConditionallyRender
                shouldRender={!!periodOptionsComposition && isPeriodOptionsOpened}
                content={
                    <>
                        <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={periodOptionsComposition} />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={768}
                            content={<PeriodOptionsWrapper>{periodOptionsComposition}</PeriodOptionsWrapper>}
                        />
                    </>
                }
            />
        </Container>
    );
};
