// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isComplete: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    align-items: center;

    .webp-button {
        display: flex;
        width: 88px;
        height: 30px;
        margin-right: 16px;
    }

    div {
        display: flex;
        align-items: center;

        p {
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0.005em;
            line-height: 1.375;
            font-family: "Nunito", sans-serif;
            font-style: normal;
            color: ${theme.colors.base[300]};
        }

        img {
            display: none;
        }

        ${({ isComplete }) =>
            !!isComplete &&
            css`
                p {
                    color: ${theme.colors.system.success[400]};
                    margin-right: 8px;
                }

                img {
                    display: flex;
                    transform: translateY(-2px);
                }
            `}
    }
`;
