import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";
import { AccordionButton, AccordionIconWrapper, AccordionItem, AccordionLabel } from "components/Accordion/styles";

export const SubjectScreenDetailsContentPerTypeContent = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: calc(32px + 8px);
    padding-right: ${theme.spacing.small};
    margin-bottom: ${theme.spacing.smallX};
`;

const ContentPerTypeItem = css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.smallX} 0;
    text-align: left;
    text-transform: none;
    text-decoration: none;

    :hover {
        background: ${theme.colors.base[100]};
    }

    strong {
        font-weight: 600;
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[400]};
    }

    p {
    }
`;

export const SubjectScreenDetailsContentPerTypeItemButton = styled.button`
    ${ContentPerTypeItem};
`;

export const SubjectScreenDetailsContentPerTypeItemLink = styled.a.attrs({
    target: "_blank",
    rel: "noopener noreferrer nofollow"
})`
    ${ContentPerTypeItem};
    cursor: pointer;
`;

export const SubjectScreenDetailsContentPerTypeAccordionIcon = styled.span<{ active?: boolean }>`
    width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    content: " ";
    margin-right: ${theme.spacing.smallX};

    :before {
        content: " ";
        width: 10px;
        height: 10px;
        background: ${({ active }) => (!!active ? theme.colors.system.success[200] : theme.colors.white)};
        border: 1px solid ${({ active }) => (!!active ? theme.colors.system.success[200] : theme.colors.base[200])};
        border-radius: 100%;
    }
`;

export const SubjectScreenDetailsContentPerTypeIconSpace = styled.span`
    display: flex;
    width: calc(32px + 8px);
    justify-content: flex-end;

    svg {
        :first-child:not(:last-child) {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const SubjectScreenDetailsContentPerTypeContainer = styled.div`
    ${AccordionButton} {
        background: ${theme.colors.white};
    }

    ${AccordionIconWrapper} {
        width: auto;
    }

    ${AccordionItem} {
        :not(:last-child) {
            border-bottom: 1px solid ${theme.colors.base[100]};
        }
    }

    ${AccordionLabel} {
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.smallX};
    }
`;

export const SubjectScreenDetailsContentPerTypeItemEventDate = styled.span`
    color: ${theme.colors.brand[300]};
    font-weight: 600;
    font-size: ${theme.typography.sizes.smallX};
    margin-right: ${theme.spacing.smallX};
`;
