// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Components
import { Asset } from "component-library/utilities/asset";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { BottomSheet } from "component-library/elements/bottom-sheet";
import { Tooltip } from "component-library/elements/tooltip";
import { Radial } from "component-library/elements/radial";
import { OptionItem } from "component-library/elements/option-item";
import { IconBadge } from "component-library/elements/icon-badge";
import { Back } from "component-library/elements/back";
import { PerformanceNavigator, PerformanceNavigatorSkeleton } from "component-library/compositions/performance-navigator";
import { PerformanceAreaCard, PerformanceAreaCardSkeleton } from "component-library/compositions/performance-area-card";
import { OptionList } from "component-library/compositions/option-list";
import { PerformanceGraphic, PerformanceGraphicSkeleton } from "component-library/compositions/performance-graphic";
import { PerformanceArea } from "component-library/workspaces/performance-area";

// Assets
import schedule from "assets/img/performance-schedule.svg";
import arrowRight from "assets/img/arrow-right.svg";
import trophy from "assets/img/performance-trophy.svg";
import sadFace from "assets/img/performance-sad-face.svg";
import subject from "assets/img/performance-subject.svg";
import chevronLeft from "assets/img/chevron-left.svg";
import chevronRight from "assets/img/chevron-right.svg";

const periods = [
    {
        message: "Hoje",
        slug: "today"
    },
    {
        message: "Esta semana",
        slug: "this-week"
    },
    {
        message: "Este mês",
        slug: "this-month"
    },
    {
        message: "Últimos 12 meses",
        slug: "last-year"
    }
];

export const PerformanceAreaScreen: FunctionComponent = () => {
    const [isPeriodOptionsOpened, setIsPeriodOptionsOpened] = useState(false);
    const [selectedNavigationPeriodSlug, setSelectedNavigationPeriodSlug] = useState("this-week");
    const [selectedPeriodSlug, setSelectedPeriodSlug] = useState<string>();

    const handleChangePeriodSelectVisibility = () => {
        setIsPeriodOptionsOpened((previousState) => !previousState);
    };

    const handleChangePeriod = (action: "previous" | "next") => {
        console.log(`navegar entre períodos: ${action}`);
    };

    const handleNavigateToSubjectDetails = (area: string) => {
        console.log(`navegar para a tela de detalhes da matéria: ${area}`);
    };

    const handleSelectNavigationPeriodSlug = (periodSlug: string) => {
        console.log(`exibir dados do período: ${periodSlug}`);

        setSelectedNavigationPeriodSlug(periodSlug);
    };

    const handleBack = () => {
        console.log("navega para a tela anterior");
    };

    const handleSelectPeriod = (periodSlug: string) => {
        setSelectedPeriodSlug(selectedPeriodSlug !== periodSlug ? periodSlug : undefined);
    };

    const selectedNavigationPeriodMessage = periods.find((period) => period.slug === selectedNavigationPeriodSlug)?.message;

    const periodOptionItems = periods.map((item) => (
        <OptionItem
            key={item.slug}
            message={item.message}
            active={item.slug === selectedNavigationPeriodSlug}
            handleClick={() => {
                handleSelectNavigationPeriodSlug(item.slug);
                handleChangePeriodSelectVisibility();
            }}
        />
    ));

    // ----------

    const [isSkeletonVisible, setIsSkeletonVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsSkeletonVisible(false);
        }, 5000);
    }, []);

    // ----------

    return (
        <PerformanceArea
            pageTitle="Ciências da Natureza"
            backElement={<Back label="Voltar" iconElement={<Asset source={chevronRight} color="#7C8195" size={20} />} handleClick={handleBack} />}
            navigatorComposition={
                <>
                    <ConditionallyRender
                        shouldRender={!isSkeletonVisible}
                        content={
                            <PerformanceNavigator
                                period={selectedNavigationPeriodMessage!}
                                totalQuestions={80}
                                totalHits={45}
                                hitsPercentage={34}
                                scheduleIcon={schedule}
                                isPeriodOptionsOpened={isPeriodOptionsOpened}
                                handlePeriodClick={handleChangePeriodSelectVisibility}
                                periodOptionsComposition={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <BottomSheet content={periodOptionItems} handleOtherClick={handleChangePeriodSelectVisibility} />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={<OptionList content={periodOptionItems} />}
                                        />
                                    </>
                                }
                                previousColumnIconElement={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <Asset
                                                    source={chevronRight}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={16}
                                                    handleClick={() => {
                                                        handleChangePeriod("previous");
                                                    }}
                                                />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={
                                                <Asset
                                                    source={chevronRight}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={20}
                                                    handleClick={() => {
                                                        handleChangePeriod("previous");
                                                    }}
                                                />
                                            }
                                        />
                                    </>
                                }
                                nextColumnIconElement={
                                    <>
                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="hide"
                                            content={
                                                <Asset
                                                    source={chevronLeft}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={16}
                                                    handleClick={() => {
                                                        handleChangePeriod("next");
                                                    }}
                                                />
                                            }
                                        />

                                        <ScreenWidthRender
                                            renderingWidth={768}
                                            actionAfterRenderingWidth="show"
                                            content={
                                                <Asset
                                                    source={chevronLeft}
                                                    color="#7C8195"
                                                    hoverColor="#571D5D"
                                                    size={20}
                                                    handleClick={() => {
                                                        handleChangePeriod("next");
                                                    }}
                                                />
                                            }
                                        />
                                    </>
                                }
                            />
                        }
                    />
                    <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceNavigatorSkeleton />} />
                </>
            }
            // TODO: criar mock dos dados dos cards abaixo
            evolutionCardCompositions={
                <>
                    <ConditionallyRender
                        shouldRender={!isSkeletonVisible}
                        content={
                            <>
                                <PerformanceAreaCard
                                    totalHitsPercentage={0}
                                    icon={{ background: "#92CFC6", src: subject }}
                                    totalQuestions={0}
                                    subjectTitle="Física"
                                    borderColor="#589C8E"
                                    hoverColor="#D6EDEA"
                                    badgeIconElement={<IconBadge hasAction icon={trophy} backgroundColor="#28B583" />}
                                    badgeInformationElement={
                                        <>
                                            <ScreenWidthRender
                                                actionAfterRenderingWidth="hide"
                                                renderingWidth={768}
                                                content={<Tooltip arrowPosition="right" message="Maior desempenho" size="small" />}
                                            />

                                            <ScreenWidthRender
                                                actionAfterRenderingWidth="show"
                                                renderingWidth={768}
                                                content={<Tooltip arrowPosition="bottom" message="Maior desempenho" size="small" />}
                                            />
                                        </>
                                    }
                                    radialElement={<Radial progress={65} />}
                                    actionIconElement={
                                        <>
                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="hide"
                                                content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                            />

                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="show"
                                                content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                            />
                                        </>
                                    }
                                    handleClick={() => handleNavigateToSubjectDetails("Física")}
                                />
                                <PerformanceAreaCard
                                    totalHitsPercentage={0}
                                    icon={{ background: "#92CFC6", src: subject }}
                                    totalQuestions={0}
                                    subjectTitle="Química"
                                    borderColor="#589C8E"
                                    hoverColor="#D6EDEA"
                                    radialElement={<Radial progress={65} />}
                                    actionIconElement={
                                        <>
                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="hide"
                                                content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                            />

                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="show"
                                                content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                            />
                                        </>
                                    }
                                    handleClick={() => handleNavigateToSubjectDetails("Química")}
                                />
                                <PerformanceAreaCard
                                    totalHitsPercentage={0}
                                    icon={{ background: "#92CFC6", src: subject }}
                                    totalQuestions={0}
                                    subjectTitle="Biologia"
                                    borderColor="#589C8E"
                                    hoverColor="#D6EDEA"
                                    badgeIconElement={<IconBadge hasAction icon={sadFace} backgroundColor="#E85153" />}
                                    badgeInformationElement={
                                        <>
                                            <ScreenWidthRender
                                                actionAfterRenderingWidth="hide"
                                                renderingWidth={768}
                                                content={<Tooltip arrowPosition="right" message="Menor desempenho" size="small" />}
                                            />

                                            <ScreenWidthRender
                                                actionAfterRenderingWidth="show"
                                                renderingWidth={768}
                                                content={<Tooltip arrowPosition="bottom" message="Menor desempenho" size="small" />}
                                            />
                                        </>
                                    }
                                    radialElement={<Radial progress={65} />}
                                    actionIconElement={
                                        <>
                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="hide"
                                                content={<Asset source={arrowRight} color="#7C8195" size={16} />}
                                            />

                                            <ScreenWidthRender
                                                renderingWidth={1024}
                                                actionAfterRenderingWidth="show"
                                                content={<Asset source={arrowRight} color="#7C8195" size={20} />}
                                            />
                                        </>
                                    }
                                    handleClick={() => handleNavigateToSubjectDetails("Biologia")}
                                />
                            </>
                        }
                    />
                    <ConditionallyRender
                        shouldRender={isSkeletonVisible}
                        content={Array.from(Array(3)).map((_, index) => (
                            <PerformanceAreaCardSkeleton key={`performance-area-card-skeleton-${index}`} />
                        ))}
                    />
                </>
            }
            evolutionGraphicComposition={
                <>
                    <ConditionallyRender
                        shouldRender={!isSkeletonVisible}
                        content={
                            <PerformanceGraphic
                                maxPeriodResult={100}
                                columns={[
                                    {
                                        period: {
                                            slug: "monday",
                                            value: "Segunda",
                                            mobileValue: "S"
                                        },
                                        result: 70,
                                        informations: {
                                            hits: 45,
                                            totalQuestions: 70
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "tuesday",
                                            value: "Terça",
                                            mobileValue: "T"
                                        },
                                        result: 30,
                                        informations: {
                                            hits: 15,
                                            totalQuestions: 30
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "wednesday",
                                            value: "Quarta",
                                            mobileValue: "Q"
                                        },
                                        result: 42,
                                        informations: {
                                            hits: 30,
                                            totalQuestions: 42
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "thursday",
                                            value: "Quinta",
                                            mobileValue: "Q"
                                        },
                                        result: 22,
                                        informations: {
                                            hits: 8,
                                            totalQuestions: 22
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "friday",
                                            value: "Sexta",
                                            mobileValue: "S"
                                        },
                                        result: 54,
                                        informations: {
                                            hits: 22,
                                            totalQuestions: 54
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "saturday",
                                            value: "Sábado",
                                            mobileValue: "S"
                                        },
                                        result: 68,
                                        informations: {
                                            hits: 38,
                                            totalQuestions: 68
                                        }
                                    },
                                    {
                                        period: {
                                            slug: "sunday",
                                            value: "Domingo",
                                            mobileValue: "D"
                                        },
                                        result: 82,
                                        informations: {
                                            hits: 50,
                                            totalQuestions: 82
                                        }
                                    }
                                ]}
                                subtitles={[
                                    { color: "#9C9FB0", label: "Questões" },
                                    ...(selectedPeriodSlug !== undefined ? [{ color: "#794A7D", label: "Período selecionado" }] : []),
                                    { color: "#28B583", label: "Acertos" }
                                ]}
                                hasPeriodSelection
                                selectedPeriodSlug={selectedPeriodSlug}
                                handleSelectPeriod={handleSelectPeriod}
                                previousColumnsIcon={chevronRight}
                                nextColumnsIcon={chevronLeft}
                            />
                        }
                    />
                    <ConditionallyRender shouldRender={isSkeletonVisible} content={<PerformanceGraphicSkeleton />} />
                </>
            }
        />
    );
};
