import styled, { css } from "styled-components/macro";
import { theme } from "config/theme";

import {
    SubjectScreenDetailsDonutsContainer,
    SubjectScreenDetailsDonutsRing,
    SubjectScreenDetailsDonutsLine,
    SubjectScreenDetailsDonutsCompleted
} from "../Donuts/styles";
import { BodyText2, TitleH3 } from "assets/styles/global";

export const SubjectScreenDetailsDesktopSelectFrontContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};
`;

export const SubjectScreenDetailsDesktopSelectFrontStats = styled.div`
    display: flex;
    align-items: center;
    font-size: ${theme.typography.sizes.small};

    ${SubjectScreenDetailsDonutsContainer}, ${SubjectScreenDetailsDonutsCompleted} {
        margin-right: ${theme.spacing.smallX};
    }

    ${SubjectScreenDetailsDonutsRing} {
        stroke-width: 3;
    }

    ${SubjectScreenDetailsDonutsLine} {
        stroke-width: 5;
    }

    strong {
        font-weight: bold;
        color: ${theme.colors.base[400]};
        margin-right: ${theme.spacing.smallX};
    }

    span {
        color: ${theme.colors.system.info[300]};
        margin-right: ${theme.spacing.smallX};
    }

    p {
        color: ${theme.colors.base[400]};
    }
`;

export const SubjectScreenDetailsDesktopSelectFrontDropdown = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    position: relative;
`;

export const SubjectScreenDetailsDesktopSelectFrontDropdownActiveItem = styled.button.attrs({
    type: "button"
})<{ active: boolean }>`
    background: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;

    span {
        ${TitleH3};

        color: ${theme.colors.base[500]};

        margin-right: ${theme.spacing.smallX};
    }

    svg {
        transition: transform 0.3s ease;
        transform: rotate(${({ active }) => (active ? 180 : 0)}deg);
    }
`;

export const SubjectScreenDetailsDesktopSelectFrontDropdownItems = styled.div`
    width: 185px;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    z-index: 3;
    border-radius: 4px;
    box-shadow: ${theme.shadow[2]};
    margin-top: ${theme.spacing.smallX};
    overflow: auto;
    background-color: #fff;
`;

export const SubjectScreenDetailsDesktopSelectFrontDropdownItem = styled.button.attrs({
    type: "button"
})<{ active: boolean }>`
    background: ${theme.colors.white};
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small} 10px;
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.small};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :hover {
        color: ${theme.colors.base[500]};
        background: ${theme.colors.base[100]};
    }

    :first-child {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
    }

    :last-child {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    ${BodyText2};

    ${({ active }) =>
        active &&
        css`
            background: ${theme.colors.base[100]};
            color: ${theme.colors.base[500]};
        `};
`;
