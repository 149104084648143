// Dependencies
import styled, { css } from "styled-components/macro";

// Components
import { DropdownButton, DropdownContainer, DropdownContent, DropdownItem } from "components/Dropdown/styles";
import { ButtonWrapper } from "components/Button/styles";

// Utils
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const WatchScreenVideoContainer = styled.div<{ fixed?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${({ fixed }) =>
        fixed &&
        css`
            left: 0;
            position: fixed;
            top: 50px;
            z-index: 2;
        `}

    ${MediaQueries.BIGGER_THAN_SMALL} {
        top: 60px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        position: unset;

        &:last-child {
            margin-bottom: ${theme.spacing.large};
        }
    }
`;

export const WatchScreenVideoContentContainer = styled.div`
    padding: ${theme.spacing.medium} ${theme.spacing.medium};
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    ${DropdownContent} {
        min-width: 150px;

        svg {
            fill: ${theme.colors.base[500]};
        }
    }

    h1 {
        color: ${theme.colors.base[500]};
        font-family: "Nunito", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
    }
`;

export const WatchScreenVideoContent = styled.div`
    background: ${theme.colors.white};
    border-radius: 0px 0px 16px 16px;
    box-shadow: ${theme.shadow[1]};
    width: 100%;
`;

export const WatchScreenVideoContentHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;

    ${DropdownContent} {
        right: calc(100% - 16px);
        top: calc(100% + 16px);
    }

    p {
        color: ${theme.colors.base[400]};
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        line-height: 26px;
        font-style: normal;
        font-weight: 600;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        flex: 1;
    }

    ${DropdownContainer} {
        margin: 0 ${theme.spacing.smallX};
    }

    ${DropdownButton} {
        svg {
            fill: ${theme.colors.base[400]};
            color: ${theme.colors.base[400]};
        }
    }

    ${DropdownItem} {
        svg:nth-child(2) {
            display: none;
        }

        &:hover {
            svg {
                :nth-child(1) {
                    display: none;
                }

                :nth-child(2) {
                    display: unset;
                }
            }
        }
    }
`;

export const WatchScreenVideoContentHeaderActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
`;

export const WatchScreenVideoContentHeaderAction = styled.button.attrs({ type: "button" })`
    background: transparent;
    border: 0;
    padding: 0;
    outline: none;
    cursor: pointer;

    svg {
        fill: ${theme.colors.base[400]};
        color: ${theme.colors.base[400]};
    }

    &:focus {
        outline: none;
    }
`;

export const WatchScreenVideoContentSwitch = styled.div`
    flex: 1;
`;

export const WatchScreenVideoContentActions = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;

    > div:last-child {
        padding: 0;
    }

    ${ButtonWrapper} {
        margin-right: auto;
        margin-top: 20px;

        > svg {
            margin-left: 8px;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${ButtonWrapper} {
            margin-left: auto;
            margin-right: unset;
            margin-top: unset;
        }
    }
`;
