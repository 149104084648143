// Dependencies
import React from "react";

// Styles
import {
    CarouselCardItemContainer,
    CarouselCardItemDescriptionWrapper,
    CarouselCardItemIconWrapper,
    CarouselCardItemIcon,
    CarouselCardItemTitleWrapper,
    CarouselCardItemSubTitle
} from "./carousel-card-item.styles";

// Types
import { CarouselCardItemProps } from "./carousel-card-item.types";

export const CarouselCardItem: React.FunctionComponent<CarouselCardItemProps> = ({ title, subTitle, icon, content }) => {
    return (
        <CarouselCardItemContainer>
            {content}

            <CarouselCardItemDescriptionWrapper>
                <CarouselCardItemIconWrapper>
                    <CarouselCardItemIcon src={icon} />
                </CarouselCardItemIconWrapper>

                <CarouselCardItemTitleWrapper>
                    {title}
                    <CarouselCardItemSubTitle>{subTitle}</CarouselCardItemSubTitle>
                </CarouselCardItemTitleWrapper>
            </CarouselCardItemDescriptionWrapper>
        </CarouselCardItemContainer>
    );
};

export default CarouselCardItem;
