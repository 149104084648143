// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    background-color: #ffffff;
`;

export const HeaderContent = styled.div`
    padding: 14px 16px;
    max-width: 1360px;
    margin: 0 auto;

    @media (min-width: 1024px) {
        padding: 26px 24px;
    }
`;

export const ContentWrapper = styled.div`
    max-width: 1360px;
    margin: 0 auto;

    width: 100%;
    padding: 24px 16px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    @media (min-width: 1024px) {
        padding: 40px 24px;
    }

    @media (min-width: 1920px) {
        padding: 48px 24px;
    }
`;
