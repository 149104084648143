import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import ChevronLeft from "prodigio-icons/web/outlined/ChevronLeft";
import { ProgressBarTabletWrapper, ProgressBarMobileWrapper, ProgressBarContainer, ProgressBarLine } from "components/ProgressBar/styles";
import { ExerciseListTimerContainer } from "../Timer/styles";
import { ExerciseListContentContainer } from "screens/ExerciseList/styles";

export interface IProgressBarFillProps {
    percentage: string;
}

export const ExerciseListHeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.medium} 0 42px;
    border-radius: 0px 0px 4px 4px;
    > ${ExerciseListContentContainer} {
        padding: 0 16px;
    }
`;

export const ExerciseListHeaderBackButton = styled.button`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.small};
    width: ${theme.spacing.small};
    height: ${theme.spacing.small};
`;

export const ExerciseListHeaderBackIcon = styled(ChevronLeft)`
    width: 100%;
    height: 100%;
    fill: ${theme.colors.base[300]};
`;

export const ExerciseListHeaderWrapperTimer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: 0;
        flex: 1;
    }

    ${ExerciseListTimerContainer} {
        color: ${theme.colors.base[400]};
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
    }
`;

export const ExerciseListHeaderTitle = styled.div`
    display: flex;
    margin-bottom: ${theme.spacing.small};
    align-items: center;
    padding: 0 ${theme.spacing.smallXX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: ${theme.spacing.medium};
        padding: 0;
    }

    h5 {
        color: ${theme.colors.base[400]};
        font-family: "Nunito", sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        text-align: left;
        font-size: ${theme.typography.sizes.medium};

        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            font-size: ${theme.typography.sizes.mediumX};
            font-weight: bold;
        }
    }

    svg {
        color: ${({ color }) => color};
    }
`;

export const ExerciseListHeaderSubject = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.brand[300]};
    font-weight: 600;
    font-family: ${theme.typography.family.primary};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-weight: bold;
        font-size: ${theme.typography.sizes.mediumXX};
    }
`;

export const ExerciseListHeaderContainerProgressBarLabels = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.smallX};
    padding: 0 ${theme.spacing.smallXX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        justify-content: flex-end;
        margin-bottom: 0;
        padding: 0;
    }
`;

export const ExerciseListHeaderProgressBarPercent = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
    font-weight: 600;
    font-family: ${theme.typography.family.primary};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-weight: bold;
    }
`;

export const ExerciseListHeaderContainerProgressBar = styled.div<{ progressBarBackground: string }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: ${theme.spacing.smallX};
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${ProgressBarLine}, ${ProgressBarTabletWrapper} {
            height: 10px;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex: 1;
        margin-bottom: 0;

        ${ProgressBarLine}, ${ProgressBarTabletWrapper} {
            height: 8px;
        }

        ${ExerciseListHeaderContainerProgressBarLabels}, ${ProgressBarContainer} {
            width: 50%;
            margin-left: auto;
        }
    }

    ${ProgressBarTabletWrapper}, ${ProgressBarMobileWrapper} {
        background: ${({ progressBarBackground }) => progressBarBackground};
    }
`;

export const ExerciseListHeaderProgressBarTotals = styled.p<{ color: string }>`
    color: ${({ color }) => color};
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: ${theme.typography.family.primary};
    flex: 1;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        font-size: ${theme.typography.sizes.large};
        font-weight: bold;
        color: ${theme.colors.base[500]};
    }

    span {
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
    }
`;

export const ExerciseListHeaderProgressBarTotalsDone = styled.strong<{ color: string }>`
    margin-left: ${theme.spacing.smallXX};
    margin-right: 1px;
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: 20px;
    line-height: 1;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.largeX};
        font-weight: 800;
    }
`;

export const ExerciseListHeaderContent = styled.div`
    display: flex;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: ${theme.spacing.medium};
        flex-direction: row;
    }
`;
