import React from "react";

import { ExportExerciseListPDFContainer } from "./styles";

interface IProps {
    data: any;
    onClose(): void;
}

const ExportExerciseListPDF = ({ data, onClose }: IProps) => (
    <ExportExerciseListPDFContainer>
        <h3>{data.title}</h3>
    </ExportExerciseListPDFContainer>
);

export default ExportExerciseListPDF;
