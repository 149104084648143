import { TitleH5 } from "assets/styles/global";
import { ButtonWrapper } from "components/Button/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExternalSchoolClassContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 500px;
    max-width: 100%;
    padding: ${theme.spacing.large} 0;
    align-items: center;
    overflow-y: auto;

    ${TitleH5} {
        margin-bottom: 20px;
    }

    > div {
        ${ButtonWrapper} {
            :not(:last-child) {
                margin-bottom: ${theme.spacing.small};
            }
        }
    }
`;
