// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

// Assets
import vector from "./assets/vector.svg";

type WebpItemMaterialsProps = {
    text: string;
    onClickItem: () => void;
};

export const WebpItemMaterials: FunctionComponent<WebpItemMaterialsProps> = ({ text, onClickItem }) => {
    return (
        <Container className="webp-item-materials" onClick={onClickItem}>
            <div className="content-text">
                <h2 className="text">{text}</h2>
            </div>
            <button className="button">
                <img src={vector} alt="vector" />
            </button>
        </Container>
    );
};
