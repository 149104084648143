import { call, put, takeLatest, select } from "redux-saga/effects";

import api from "services/api";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as subjectActions } from "store/ducks/subject";
import { Creators as watchActions } from "store/ducks/watch";
import { Creators as bookletModulesActions } from "store/ducks/booklet-module";
import { Types as SubjectTypes } from "store/ducks/subject";
import { IRequestAction } from "interfaces/IRequestAction";
import { IReduxStore } from "interfaces/IReduxStore";
import { ReduxStore } from "store/ducks";
import { IBookletModuleItem } from "store/interfaces/IBookletModule";

function* getAll(action: any) {
    try {
        const { payload } = action;
        const { data } = yield call(api.get, payload.endpoint, { headers: payload.headers });

        yield put(subjectActions.getSubjectsSuccess(Array.isArray(data) ? data : data.items));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a lista de matérias.", "danger"));
        yield put(subjectActions.getSubjectsFailure(error?.response?.data));
    }
}

function* getSubjectsProgress(action: any) {
    try {
        const { subjectSubjectId, bookletModuleItems } = yield select(({ subject, bookletModule }: ReduxStore) => ({
            subjectSubjectId: subject?.id,
            bookletModuleItems: bookletModule
        }));

        const endpoint = !!action.payload?.id
            ? `/student/subject-v2/${action.payload.id}/front/module/progress`
            : `/student/subject-v2/${subjectSubjectId}/module/progress`;

        const { data: progress } = yield call(api.get, endpoint);

        const bookletModuleItemsFormatted = bookletModuleItems.items.map((item: IBookletModuleItem) => {
            const progressItem = progress.find((v: any) => v.id === item.id);
            return {
                ...item,
                progress: {
                    totalItems: !!progressItem ? progressItem.totalItems : 0,
                    completedItems: !!progressItem ? progressItem.completedItems : 0,
                    percent: !!progressItem ? progressItem.percent : 0
                }
            };
        });

        yield put(bookletModulesActions.getBookletModulesSuccess(bookletModuleItemsFormatted));
    } catch (error) {
        console.log(error);
    }
}

function* getModule(action: any) {
    try {
        const { data } = yield call(api.get, `/bookletmodule/${action.payload.id}`, {
            headers: {
                "X-Relations": "content.learningObject.video;subject;events;progress;subjectFront"
            }
        });
        yield put(subjectActions.getSubjectModuleSuccess(data));

        const types: { [type: string]: string } = {
            monitoria: "Monitoria",
            "ao-vivo": "Ao Vivo",
            "videos-relacionados": "Vídeos Relacionados"
        };
        const contentMaterials = data.contentPerType.find((v: any) => v.type === "Material");
        const contentExerciseLists = data.contentPerType.find((v: any) => v.type === "ExerciseList");
        const contentVideos = data.contentPerType.find((v: any) => v.type === "Video");
        const currentVideo = {
            id: !!contentVideos.items[0].learningObject.video.id ? contentVideos.items[0].learningObject.video.id : 0,
            learningObjectId: !!contentVideos.items[0].learningObject.id ? contentVideos.items[0].learningObject.id : 0,
            title: !!contentVideos.items[0].learningObject.video.title ? contentVideos.items[0].learningObject.video.title : "",
            subtitle: !!data.subject && !!data.subject.name ? data.subject.name : types[action.payload.type],
            isLiked: !!contentVideos.items[0].learningObject.isLiked ? contentVideos.items[0].learningObject.isLiked : 0,
            viewed: !!contentVideos.items[0].learningObject.viewed ? contentVideos.items[0].learningObject.viewed : false,
            provider: !!contentVideos.items[0].learningObject.video.provider ? contentVideos.items[0].learningObject.video.provider : 0,
            url: ""
        };

        const watch = {
            entity: "materia",
            type: action.payload.type,
            id: data.id,
            subject: {
                slug: data.subject.slug,
                name: data.subject.name
            },
            date: data.startDate,
            title: data.name,
            video: currentVideo,
            relatedVideos:
                !!contentVideos && !!contentVideos.items
                    ? contentVideos.items.map((item: any) => ({
                          id: item.learningObject.video.id,
                          learningObjectId: item.learningObject.id,
                          title: item.learningObject.video.title,
                          subtitle: !!item.learningObject.subject ? item.learningObject.subject.name : types[action.payload.type],
                          isLiked: !!item.learningObject.isLiked ? item.learningObject.isLiked : 0,
                          viewed: !!item.learningObject.viewed ? item.learningObject.viewed : false,
                          provider: item.learningObject.video.provider,
                          url: ""
                      }))
                    : [],
            teachers: [],
            materials: !!contentMaterials && !!contentMaterials.items ? contentMaterials.items : [],
            exerciseLists: !!contentExerciseLists && contentExerciseLists.items ? contentExerciseLists.items : []
        };
        yield put(watchActions.setWatch(watch));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um problema ao carregar os planos de estudos.", "danger"));
        yield put(subjectActions.getSubjectModuleFailure(error?.response?.data));
    }
}
function* getSubjectProgressBySlug(action: any) {
    try {
        const slug = action.payload;

        if (!slug) {
            return;
        }

        const { data } = yield call(api.get, `/student/subject/${slug}/progress`);

        if (!data) {
            throw new Error("Cannot get subject progress");
        }

        yield put(subjectActions.getSubjectProgressBySlugSuccess(data));
    } catch (error) {
        yield put(subjectActions.getSubjectProgressBySlugFailure());
    }
}

function* getSubjectProgress() {
    try {
        const { data } = yield call(api.get, `/student/subject/progresses`);

        if (!data) {
            throw new Error("Cannot get subject progress");
        }

        yield put(subjectActions.getSubjectProgressSuccess(data));
    } catch (error) {
        console.log(error);
    }
}

function* getSubjectFronts(action: any) {
    try {
        const slug = action.payload;

        if (!slug) {
            return;
        }

        const { data } = yield call(api.get, `/student/subject/${slug}/front`);

        if (!data || !Array.isArray(data)) {
            throw new Error("Cannot get subject fronts");
        }

        const defaultFront = {
            id: null,
            title: "Todas as Frentes"
        };

        const fronts = [...data, defaultFront];

        yield put(subjectActions.getSubjectFrontsSuccess(fronts));

        if (!!data?.length) {
            return yield put(subjectActions.setCurrentFront({ front: defaultFront, currentFrontSelectedByUser: false }));
        }

        yield put(subjectActions.disableSubjectFronts(data));

        const payloadBookletModule = {
            endpoint: `/student/subject-v2/${slug}/module`
        };

        yield put(bookletModulesActions.getBookletModulesRequest(payloadBookletModule));
    } catch (error) {
        yield put(subjectActions.getSubjectFrontsFailure());
    }
}

function* getSubjectInfos(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield call(api.get, payload.endpoint);

        if (!data) {
            throw new Error("Cannot get subject infos");
        }

        yield put(subjectActions.getSubjectInfosSuccess(data));
    } catch (error) {
        yield put(subjectActions.getSubjectInfosFailure());
    }
}

function* getSubjectBySlug(action: IRequestAction<any>) {
    try {
        const slug = action.payload;

        if (!slug) {
            return;
        }

        const { data } = yield call(api.get, `/student/subject-v2/${slug}`);

        if (!data.id) {
            throw new Error("Cannot get this subject.");
        }

        yield put(subjectActions.getSubjectBySlugSuccess(data));

        yield put(subjectActions.getSubjectProgressBySlugRequest(data.slug));
        yield put(subjectActions.getSubjectFrontsRequest(data.slug));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro ao carregar a matéria.", "danger"));
        yield put(subjectActions.getSubjectBySlugFailure(error?.response?.data));
    }
}

function* getSubjectFrontProgress(action: IRequestAction<any>) {
    try {
        const { payload } = action;

        const { data } = yield call(api.get, payload.endpoint);

        if (!data) {
            throw new Error("Cannot get subject infos");
        }

        yield put(subjectActions.getSubjectFrontProgressSuccess(data));
    } catch (error) {
        yield put(subjectActions.getSubjectFrontProgressFailure());
    }
}

function* setCurrentFront(action: any) {
    try {
        if (action.payload?.front?.id === undefined) {
            throw new Error();
        }

        yield put(
            subjectActions.setCurrentFrontSuccess({
                front: action.payload.front,
                currentFrontSelectedByUser: action.payload.currentFrontSelectedByUser
            })
        );

        const subjectSubject = yield select((state: IReduxStore) => state.subject?.slug);

        yield put(
            bookletModulesActions.getBookletModulesRequest({
                endpoint: !!action.payload?.front?.id
                    ? `/student/subject-v2/${subjectSubject}/front/${action.payload.front.id}/module`
                    : `/student/subject-v2/${subjectSubject}/module`
            })
        );
    } catch (error) {
        yield put(alertActions.showAlert("Ocorreu um erro. Tente novamente.", "danger"));
    }
}

export default [
    takeLatest(SubjectTypes.GET_SUBJECTS_REQUEST, getAll),
    takeLatest(SubjectTypes.GET_SUBJECTS_PROGRESS_REQUEST, getSubjectsProgress),
    takeLatest(SubjectTypes.GET_SUBJECT_BY_SLUG_REQUEST, getSubjectBySlug),
    takeLatest(SubjectTypes.GET_SUBJECT_MODULE_REQUEST, getModule),
    takeLatest(SubjectTypes.GET_SUBJECT_PROGRESS_BY_SLUG_REQUEST, getSubjectProgressBySlug),
    takeLatest(SubjectTypes.GET_SUBJECT_PROGRESS_REQUEST, getSubjectProgress),
    takeLatest(SubjectTypes.GET_SUBJECT_FRONTS_REQUEST, getSubjectFronts),
    takeLatest(SubjectTypes.GET_SUBJECT_INFOS_REQUEST, getSubjectInfos),
    takeLatest(SubjectTypes.GET_SUBJECT_FRONT_PROGRESS_REQUEST, getSubjectFrontProgress),
    takeLatest(SubjectTypes.SET_CURRENT_FRONT, setCurrentFront)
];
