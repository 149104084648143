import styled from "styled-components";
import { theme } from "config/theme";
import { fadeInUp } from "assets/styles/animations";

export const SubjectScreenDetailsActiveModuleModalContainer = styled.div`
    display: flex;
    background: ${theme.colors.white};
    position: absolute;
    padding: ${theme.spacing.medium} ${theme.spacing.smallX};
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    left: 0;
    flex-direction: column;
    overflow-y: auto;
    animation: ${fadeInUp} 0.5s;
`;

export const SubjectScreenDetailsActiveModuleModalHeader = styled.header`
    display: flex;
    flex-direction: column;
    padding: 0 ${theme.spacing.small};
`;

export const SubjectScreenDetailsActiveModuleModalButton = styled.button.attrs({ type: "button" })`
    border: none;
    background: transparent;
    outline: none;
    width: 16px;
    height: 16px;
    margin-left: auto;
    margin-bottom: ${theme.spacing.medium};

    svg {
        fill: #000;
    }
`;

export const SubjectScreenDetailsActiveModuleModalTitle = styled.h3`
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.medium};
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
`;

export const SubjectScreenDetailsActiveModuleModalProgress = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};

    > strong {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
        margin-bottom: ${theme.spacing.smallX};
    }
`;
