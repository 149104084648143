import { TitleH2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const PreviousExamScreenHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};
`;

export const PreviousExamScreenHeaderTitle = styled.h1`
    ${TitleH2};

    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: 0;
    }
`;

export const PreviousExamScreenHeaderSearch = styled.div`
    display: flex;
    justify-content: space-between;

    > h5 {
        color: ${theme.colors.base[400]};
    }
`;
