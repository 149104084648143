import { call, takeLatest } from "redux-saga/effects";
import { Types as AlertTypes } from "store/ducks/alert";
import { AlertPosition } from "store/interfaces/IAlert";
import _get from "lodash/get";
import { toast } from "react-toastify";

function* showAlert(action: { type: string; message: string; alertType: string; position?: AlertPosition }) {
    try {
        const { alertType, message = "" } = action;

        const alertVariants = {
            danger: "error"
        };

        const type: any = _get(alertVariants, alertType, alertType);

        yield call(toast, message, { type });
    } catch (error) {
        console.log(error);
    }
}

export default [takeLatest(AlertTypes.SHOW_ALERT, showAlert)];
