import styled from "styled-components";
import { TitleH3, TitleH4 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { TabRowContainer } from "components/TabRow/styles";
import { theme } from "config/theme";

export const ProjectContainer = styled.div`
    height: 100%;
    overflow-y: auto;
    padding: 32px 8px 24px;

    ${TabRowContainer} {
        margin-bottom: 31px;
        align-items: center;
        justify-content: center;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 32px 0px 24px;
    }
`;

export const ProjectTitle = styled.h1`
    ${TitleH3};
    margin-bottom: 24px;
    color: ${theme.colors.base[500]};
`;

export const ProjectSubjectTitle = styled.h2`
    ${TitleH4};
    color: ${theme.colors.base[400]};
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${TitleH3};
    }
`;
