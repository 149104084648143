import React, { memo } from "react";
import {
    SubjectScreenDetailsDonutsHole,
    SubjectScreenDetailsDonutsContainer,
    SubjectScreenDetailsDonutsLine,
    SubjectScreenDetailsDonutsRing,
    SubjectScreenDetailsDonutsText,
    SubjectScreenDetailsDonutsPercent,
    SubjectScreenDetailsDonutsCompleted
} from "./styles";
import formatPercent from "utils/formatPercent";

interface IProps {
    size?: string;
    value: number;
    showPercentage?: boolean;
    lineColor: string;
    ringColor: string;
}

const SubjectScreenDetailsDonuts = memo(({ size = "100%", value = 0, showPercentage = false, lineColor, ringColor }: IProps) => {
    const percentage = formatPercent(value);
    const percentageRemaining = formatPercent(100 - percentage);

    if (value >= 100) {
        return <SubjectScreenDetailsDonutsCompleted width={size} height={size} />;
    }

    return (
        <SubjectScreenDetailsDonutsContainer viewBox="0 0 40 40" width={size} height={size}>
            <SubjectScreenDetailsDonutsHole cx="20" cy="20" r="15.91549430918954" fill="transparent"></SubjectScreenDetailsDonutsHole>
            <SubjectScreenDetailsDonutsRing
                cx="20"
                cy="20"
                r="15.91549430918954"
                fill="transparent"
                color={ringColor}
            ></SubjectScreenDetailsDonutsRing>
            <SubjectScreenDetailsDonutsLine
                color={lineColor}
                cx="20"
                cy="20"
                r="15.91549430918954"
                fill="transparent"
                strokeDasharray={`${percentage} ${percentageRemaining}`}
                strokeDashoffset="25"
                strokeLinecap="round"
                percentage={percentage}
                percentageRemaining={percentageRemaining}
            ></SubjectScreenDetailsDonutsLine>
            {showPercentage && (
                <SubjectScreenDetailsDonutsText>
                    <text y="50%" transform="translate(0, 2)">
                        <SubjectScreenDetailsDonutsPercent x="50%" textAnchor="middle">
                            {percentage}%
                        </SubjectScreenDetailsDonutsPercent>
                    </text>
                </SubjectScreenDetailsDonutsText>
            )}
        </SubjectScreenDetailsDonutsContainer>
    );
});

export default SubjectScreenDetailsDonuts;
