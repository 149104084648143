// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isPhotoUser: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.base[100]};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;

    ${({ isPhotoUser }) =>
        !!isPhotoUser &&
        css`
            background-size: cover;
        `}

    .tag-user {
        width: 24px;
        height: 24px;
        display: flex;
        position: absolute;
        right: -2px;
        bottom: -5px;
        border-radius: 400px;
        border: 2px solid #fafafc;
    }
`;
