// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { BottomSheetProps } from "./bottom-sheet.types";

// Styles
import { Container, Wrapper, Overlay } from "./bottom-sheet.styles";

export const BottomSheet: FunctionComponent<BottomSheetProps> = ({ content, handleOtherClick }) => {
    return (
        <>
            <Overlay onClick={handleOtherClick} />

            <Wrapper>
                <Container>{content}</Container>
            </Wrapper>
        </>
    );
};
