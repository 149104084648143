import isEqual from "lodash/isEqual";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { lightenDarkenColor } from "helpers";
import { FlatVariant } from "utils/types/Variant";
import { MediaQueries } from "assets/styles/settings";
import { AlertPosition } from "store/interfaces/IAlert";
import { bounceInRight, bounceInUp, fadein } from "assets/styles/animations";

const positions = new Map<AlertPosition, { [key: string]: string }>([
    [["left", "bottom"], { left: "1rem", bottom: "1rem" }],
    [["left", "top"], { left: "1rem", top: "1rem" }],
    [["left", "center"], { left: "1rem", top: "50%", transform: "translateY(-50%)" }],

    [["center", "bottom"], { left: "50%", bottom: "1rem", transform: "translateX(-50%)" }],
    [["center", "top"], { left: "50%", top: "1rem", transform: "translateX(-50%)" }],
    [["center", "center"], { left: "50%", top: "50%", transform: "translate(-50%, -50%)" }],

    [["right", "bottom"], { right: "1rem", bottom: "1rem" }],
    [["right", "top"], { right: "1rem", top: "1rem" }],
    [["right", "center"], { right: "1rem", top: "50%", transform: "translateY(-50%)" }]
]);

const getPosition = (position: AlertPosition) => {
    let result;

    for (const item of positions.keys()) {
        const match = isEqual(item, position);

        if (match) {
            result = positions.get(item);
        }
    }

    if (!result) {
        return { right: "1rem", bottom: "1rem" };
    }

    return result;
};

export const AlertCloseButton = styled.button`
    margin-left: 16px;

    svg {
        display: block;
    }
`;

export const AlertWrap = styled.span<{ type: FlatVariant; position: AlertPosition }>`
    align-items: center;
    animation: ${fadein} 0.5s 0s both;
    background: ${(props) => theme.colors.system[props.type][300] || theme.colors.brand[300]};
    border-radius: 4px;
    box-shadow: 2px 2px 3px ${(props) => lightenDarkenColor(theme.colors.system[props.type][300], -20)};
    color: white;
    display: flex;
    max-width: 80vw;
    padding: 12px;
    z-index: 99999;

    ${({ position }) => {
        return css`
            ${getPosition(position)};
        `;
    }};
    position: fixed;

    ${AlertCloseButton}:hover {
        color: ${(props) => lightenDarkenColor(theme.colors.system[props.type][300], -20)};
    }
`;

export const AlertFeedbackText = styled.p`
    color: ${theme.colors.base[300]};
    font-size: 15px;
`;

export const AlertFeedbackButton = styled.button`
    margin-top: 4px;

    &:hover {
        text-decoration: underline;
    }
`;

export const AlertFeedbackContainer = styled.div<{ variant: FlatVariant }>`
    animation: ${bounceInUp} 0.5s 0s both;
    background: #f7f8fa;
    border-bottom: 4px solid ${({ variant }) => theme.colors.system[variant][300]};
    border-radius: 6px;
    box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px, rgba(9, 30, 66, 0.25) 0px 20px 32px -8px;
    bottom: 10px;
    padding: 16px 24px 16px 56px;
    position: fixed;
    right: 10px;
    transition: all 0.2s ease;
    width: calc(100% - 20px);
    z-index: 9999999;

    .ico {
        height: 24px;
        left: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;

        &--network {
            stroke: ${({ variant }) => theme.colors.system[variant][300]};
        }
    }

    ${AlertCloseButton} {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    ${AlertFeedbackButton} {
        color: ${({ variant }) => theme.colors.system[variant][300]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        animation: ${bounceInRight} 0.5s 0s both;
        bottom: unset;
        right: 1rem;
        top: 1rem;
        width: unset;
    }
`;
