export default function validateEssayText(event: any, textareaElement: HTMLTextAreaElement | null) {
    if (textareaElement) {
        const height = textareaElement.offsetHeight;
        const scrollHeight = textareaElement.scrollHeight;

        if (scrollHeight > height) {
            return false;
        }
    }

    return true;
}
