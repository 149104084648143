import React, { useCallback, memo } from "react";

// Components
import Radio from "components/Form/Radio";

// Helpers
import { ISelectOption } from "interfaces/ISelectOption";

// Assets
import { RadioGroupContainer, RadioGroupItem, RadioGroupItemTitle } from "./styles";

interface IProps {
    activeItem: any;
    items: ISelectOption<string | number | string[]>[];
    radioVariant?: "success" | "danger" | "warning" | "info" | "brand" | "secondary";
    title?: string;
    onClick(value: any): void;
}

const RadioGroup = ({ items, activeItem, onClick, title, radioVariant = "brand" }: IProps) => {
    const handleClick = useCallback((value: string | number | string[]) => () => onClick(value), [onClick]);

    return (
        <RadioGroupContainer data-test-id="radio-group">
            {!!title && <RadioGroupItemTitle>{title}</RadioGroupItemTitle>}
            {items.map(({ value, label }, index) => {
                const isChecked = activeItem === value;

                return (
                    <RadioGroupItem active={isChecked} onClick={handleClick(value)} key={`radioGroup_${index}`} data-test-id="radio-group-item">
                        <Radio inputName="filter" isChecked={isChecked} onChange={handleClick(value)} value={value} variant={radioVariant} />
                        <span>{label}</span>
                    </RadioGroupItem>
                );
            })}
        </RadioGroupContainer>
    );
};

export default memo(RadioGroup);
