// Dependencies
import React, { useState, useEffect, useRef, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";
import { ISubjectState, ISubjectActions, ISubjectFront } from "store/interfaces/ISubject";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
import { Creators as BookletModuleActions } from "store/ducks/booklet-module";
import { Creators as ModalActions } from "store/ducks/modal";
import { Creators as SubjectActions } from "store/ducks/subject";

// Services
import history from "services/history";

// Components
import { IBookletModuleItem, IBookletModulesActions, ICurrentBookletModule } from "store/interfaces/IBookletModule";
import { Col } from "components/Grid";
import Header from "../../components/Subject/Header";
import ForbiddenMessage from "components/ForbiddenMessage";
import Modules from "../../components/Subject/Modules";
import ActiveModule from "../../components/Subject/ActiveModule";
import Portal from "components/Portal";
import SubjectScreenDetailsActiveModuleModal from "../../components/Subject/ActiveModuleModal";
import SelectFront from "../../components/Subject/SelectFront";
import ErrorUI from "components/ErrorUI";

// Styles
import * as S from "./styles";

// Utils
import { BreakPoints } from "assets/styles/settings";
import { isCourseFree } from "helpers/isCourseFree";
import useIsMobile from "hooks/use-is-mobile";

interface IProps extends RouteComponentProps<{ slug?: string; moduleId?: string; materialId?: string }> {
    subject: ISubjectState;
    course: {
        id: number;
        isPublished: boolean;
        helptext: string;
        slug: string;
    };
    bookletModules: IBookletModuleItem[];
    bookletModulesActions: IBookletModulesActions;
    currentModule?: ICurrentBookletModule;
    subjectActions: ISubjectActions;
    isLoadingModules: boolean;
    isLoadingCurrentModule: boolean;
    errorBookletModule: boolean;
    errorCurrentModule: boolean;
}

const SubjectScreenDetails = ({
    subject,
    course,
    bookletModules = [],
    bookletModulesActions,
    subjectActions,
    match,
    isLoadingModules = true,
    currentModule,
    isLoadingCurrentModule = false,
    errorBookletModule = false,
    errorCurrentModule = false
}: IProps) => {
    const { slug, moduleId, materialId } = match.params;

    const dispatch = useDispatch();
    const { content, isLoading: contentIsLoading, courseSlug, hasDispatchedClickModuleContent } = useSelector(
        ({ lessonFreeCTA, course, bookletModule }: IReduxStore) => ({
            content: lessonFreeCTA.content,
            isLoading: lessonFreeCTA.isLoading,
            courseSlug: course.slug,
            hasDispatchedClickModuleContent: bookletModule.hasDispatchedClickModuleContent
        })
    );

    const [activeModal, setActiveModal] = useState(false);

    useEffect(() => {
        if (contentIsLoading && isCourseFree(course.slug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, course.slug, contentIsLoading]);

    const {
        isLoading = true,
        isLoadingProgress = true,
        isLoadingFronts = true,
        fronts = [],
        isLoadingInfos = true,
        infos,
        currentFront,
        isWithSubjectFront = true,
        error,
        currentFrontSelectedByUser
    } = subject;

    const isMobile = useIsMobile(BreakPoints.tablet);
    const modulesRef = useRef([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        return () => bookletModulesActions.clearBookletModules();
    }, [bookletModulesActions]);

    useEffect(() => {
        return () => subjectActions.clearSubject();
    }, [subjectActions]);

    const requestSubject = useCallback(() => {
        if (!slug || !course.isPublished) {
            return;
        }

        subjectActions.getSubjectBySlugRequest(slug);
    }, [course.isPublished, slug, subjectActions]);

    useEffect(() => requestSubject(), [requestSubject]);

    useEffect(() => {
        if (!!bookletModules.length) {
            subjectActions.getSubjectsProgressRequest(subject.currentFront);
        }
    }, [bookletModules.length, subject.currentFront, subjectActions]);

    const handleClickModule = useCallback(
        (module?: any, shouldPush: boolean = true) => {
            if (!module || !bookletModules?.length) {
                return;
            }

            const moduleIndex = bookletModules.findIndex((item) => item.id === module.id);

            setActiveModal(isMobile);

            bookletModulesActions.getCurrentModuleRequest(module);

            containerRef.current?.scrollTo({
                top: (modulesRef?.current[moduleIndex] as any)?.offsetTop,
                left: 0,
                behavior: "smooth"
            });

            if (shouldPush) {
                return history.push({ pathname: `/app/curso/${courseSlug}/materias/${slug}/${module.id}` });
            }
        },
        [bookletModules, bookletModulesActions, courseSlug, isMobile, slug]
    );

    useEffect(() => {
        if (bookletModules.length > 0 && !!currentFront && !isMobile && !!currentFrontSelectedByUser) {
            handleClickModule(bookletModules[0]);
        }
    }, [bookletModules, currentFront, currentFrontSelectedByUser, handleClickModule, isMobile]);

    useEffect(() => {
        if (bookletModules.length > 0 && !currentModule?.id && !isLoadingCurrentModule && !!moduleId) {
            const currentModuleTmp = bookletModules.find((module) => module.id === Number(moduleId));

            handleClickModule(currentModuleTmp, false);
        }

        if (bookletModules.length > 0 && !currentModule?.id && !isLoadingCurrentModule && !moduleId && !isMobile) {
            handleClickModule(bookletModules[0]);
        }
    }, [bookletModules, currentModule, handleClickModule, isLoadingCurrentModule, isMobile, moduleId]);

    useEffect(() => {
        if (!!currentModule?.id && !!currentModule?.contentPerType && !!materialId && !hasDispatchedClickModuleContent) {
            const currentModuleMaterialContent = currentModule?.contentPerType.find((content) => content.type === "Material");

            const selectedMaterial = currentModuleMaterialContent?.items.find((item) => item.material?.id === Number(materialId));

            if (selectedMaterial) {
                dispatch(
                    bookletModulesActions.clickModuleContent({
                        ...selectedMaterial,
                        isDispatchingClickModuleContent: true,
                        isMaterialsPage: true
                    })
                );
            }
        }
    }, [bookletModulesActions, currentModule, dispatch, hasDispatchedClickModuleContent, materialId]);

    const handleRequestInfos = useCallback(() => {
        if (!subject.slug || !!infos) {
            return;
        }

        const payload = {
            endpoint: `/student/subject/${subject.slug}/info`
        };

        subjectActions.getSubjectInfosRequest(payload);
    }, [infos, subject.slug, subjectActions]);

    useEffect(() => {
        modulesRef.current = [];
    }, [bookletModules]);

    const handleChangeFront = useCallback(
        (front: ISubjectFront) => {
            if (!front) {
                return;
            }

            containerRef?.current?.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });

            subjectActions.setCurrentFront({ front, currentFrontSelectedByUser: true });
        },
        [subjectActions]
    );

    if (!course?.isPublished) {
        return <ForbiddenMessage text={course.helptext} />;
    }

    if (!!error) {
        return <ErrorUI />;
    }

    return (
        <Fragment>
            <S.SubjectScreenDetailsContainer>
                <Header
                    name={subject.name}
                    progress={subject.progress}
                    isLoading={isLoading}
                    isLoadingProgress={isLoadingProgress}
                    infos={infos}
                    onClickInfos={handleRequestInfos}
                    isLoadingInfos={isLoadingInfos}
                />

                <S.SubjectScreenDetailsGrid fluid>
                    <SelectFront
                        isMobile={isMobile}
                        isLoadingFronts={isLoadingFronts}
                        isLoadingProgress={isLoadingFronts}
                        fronts={fronts}
                        currentFront={currentFront}
                        onClickFront={handleChangeFront}
                        withFront={isWithSubjectFront}
                        subjectName={subject.name}
                        subjectProgress={subject.progress}
                    />

                    {errorBookletModule ? (
                        <ErrorUI />
                    ) : (
                        <S.SubjectScreenDetailsScroll ref={containerRef}>
                            <Col xs={12} sm={6}>
                                <Modules
                                    activeModuleId={currentModule?.id}
                                    bookletModules={bookletModules}
                                    onClickModule={handleClickModule}
                                    ref={modulesRef}
                                    isLoading={isLoadingModules}
                                />
                            </Col>

                            {!isMobile && (
                                <ActiveModule
                                    withBlur={false}
                                    name={currentModule?.name || ""}
                                    contentPerType={currentModule?.contentPerType || []}
                                    isLoading={isLoadingCurrentModule}
                                    error={errorCurrentModule}
                                    imageSrc={content.modulo}
                                    linkHref={content.cta}
                                />
                            )}
                        </S.SubjectScreenDetailsScroll>
                    )}
                </S.SubjectScreenDetailsGrid>
            </S.SubjectScreenDetailsContainer>

            {isMobile && (
                <Portal portalId="active-module">
                    {!!activeModal ? (
                        <SubjectScreenDetailsActiveModuleModal
                            onClickClose={() => setActiveModal(false)}
                            contentPerType={currentModule?.contentPerType || []}
                            name={currentModule?.name || ""}
                            isLoading={isLoadingCurrentModule}
                            error={errorCurrentModule}
                            imageSrc={content.modulo}
                            linkHref={content.cta}
                        />
                    ) : (
                        <Fragment />
                    )}
                </Portal>
            )}
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch),
    bookletModulesActions: bindActionCreators(BookletModuleActions, dispatch),
    subjectActions: bindActionCreators(SubjectActions, dispatch)
});

const mapStateToProps = ({ subject, bookletModule, course }: IReduxStore) => ({
    subject,
    bookletModules: bookletModule.items,
    isLoadingModules: bookletModule.isLoading,
    course: {
        id: course.id,
        helptext: course.helptext || "",
        isPublished: course.isPublished,
        slug: course.slug
    },
    currentModule: bookletModule.currentModule,
    isLoadingCurrentModule: bookletModule.isLoadingCurrentModule,
    errorBookletModule: bookletModule.error,
    errorCurrentModule: bookletModule.errorCurrentModule
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(SubjectScreenDetails);
