import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// Components
import ProgressBadge from "./Badge";
import ProgressBar from "components/ProgressBar";

// Helpers
import { ISubject } from "interfaces/ISubject";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { Container, ContentWrapper, DetailsWrapper, CTA, SubjectCardName, SubjectCardTotalModules } from "./styles";
import { theme } from "config/theme";

interface ISubjectCardProps {
    subject: ISubject;
}

const SubjectCard = ({ subject }: ISubjectCardProps) => {
    const { name, totalModules, progress, slug } = subject;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const percentCalculated = useMemo(() => progress?.percent || Math.round((progress?.completedItems * 100) / progress?.totalItems) || 0, [
        progress
    ]);

    const subjectIsCompleted = progress?.completedItems > 0 && progress?.completedItems >= progress?.totalItems;

    return (
        <Container to={`/app/curso/${courseSlug}/materias/${slug}`}>
            <ContentWrapper>
                <DetailsWrapper>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <SubjectCardName>{name}</SubjectCardName>
                        <SubjectCardTotalModules>
                            {totalModules} módulo{totalModules! > 1 && "s"}
                        </SubjectCardTotalModules>
                    </div>
                    {subjectIsCompleted && <ProgressBadge />}
                </DetailsWrapper>
                <ProgressBar
                    color={subjectIsCompleted ? theme.colors.system.success[200] : theme.colors.system.info[200]}
                    value={percentCalculated}
                    responsive={true}
                    label={
                        <>
                            <p />
                            <p>
                                <strong>{percentCalculated}%</strong>
                            </p>
                        </>
                    }
                />
            </ContentWrapper>
            <CTA>Estudar agora</CTA>
        </Container>
    );
};

export default SubjectCard;
