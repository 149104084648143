import styled from "styled-components";
import { theme } from "config/theme";
import { ExerciseListLetterContainer } from "../Letter/styles";
import { MediaQueries } from "assets/styles/settings";
import { FlatVariant } from "utils/types/Variant";

export const ExerciseListAnswerCardQuestionContainer = styled.div`
    display: flex;
    margin-bottom: ${theme.spacing.medium};
    border-bottom: ${theme.colors.base[100]} 1px solid;
`;

export const ExerciseListAnswerCardQuestionNumber = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: ${theme.colors.base[100]} 1px solid;
    /* padding: ${theme.spacing.smallX} 0; */

    strong {
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
    }
`;

export const ExerciseListAnswerCardQuestionAlternatives = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};
    overflow-x: auto;

    p {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
    }

    ${ExerciseListLetterContainer} {
        ${MediaQueries.SMALLX} {
            width: 25px;
            height: 25px;
            flex: 0 0 25px;

            :not(:last-child) {
                margin-right: ${theme.spacing.smallXX};
            }
        }

        flex: 0 0 30px;

        :not(:last-child) {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const ExerciseListAnswerCardQuestionAlternativeStatus = styled.strong<{ variant: FlatVariant }>`
    font-weight: bold;
    color: ${({ variant }) => theme.colors.system[variant][400]};
`;

export const ExerciseListAnswerCardQuestionDetails = styled.div`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing.smallX};

    > div {
        flex: 1;
        align-items: center;
        display: flex;
    }
`;

export const ExerciseListAnswerCardQuestionSeeButton = styled.button`
    outline: none;
    border: 0;
    color: ${theme.colors.brand[300]};
    flex: 1;

    svg {
        margin-left: ${theme.spacing.smallX};
    }
`;

export const ExerciseListAnswerCardQuestionNote = styled.strong<{ isCorrect: boolean }>`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.small};
    margin-right: ${theme.spacing.small};

    span {
        color: ${({ isCorrect }) => (isCorrect ? theme.colors.system.success[300] : theme.colors.system.danger[300])};
    }
`;

export const ExerciseListAnswerCardQuestionDiscursive = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};

    > p {
        color: ${theme.colors.base[300]};
        font-size: ${theme.typography.sizes.small};
    }

    > div {
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.small};

        * {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        img,
        figure,
        ul,
        li {
            display: none;
        }
    }
`;
