// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    active: boolean;
    clicked: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;
    min-width: 214px;
    width: fit-content;
    width: -moz-fit-content;
    min-height: 52px;
    background: #fff;
    border-radius: 4px;
    padding: 16px 8px;
    position: relative;

    p {
        color: ${theme.colors.base[300]};
        font-size: 0.875rem;
    }

    @media only screen and (min-width: 1191px) and (max-width: 1290px) {
        min-width: 190px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1090px) {
        min-width: 200px;
    }

    ${({ active }) =>
        active &&
        css`
            filter: drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.1));

            &:after {
                content: "";
                position: absolute;
                width: 10px;
                height: 10px;
                border: none;
                right: -5px;
                top: 50%;
                background: #fff;
                z-index: -1;
                transform: translateY(-50%) rotate(45deg);
            }

            p {
                color: ${theme.colors.base[500]};
            }
        `}

    ${({ clicked }) =>
        clicked &&
        css`
            cursor: pointer;
            padding: 16px 16px;
            width: 100%;
            min-width: 262px;
            box-sizing: border-box;

            :after {
                content: "";
                display: none;
            }
        `}
`;
