import React, { useMemo, useCallback, useEffect } from "react";

import useIsMobile from "hooks/use-is-mobile";
import { theme } from "config/theme";
import history from "services/history";
import LessonPlanCustomImage from "assets/img/monte-seu-plano.svg";
import { ICareer } from "store/interfaces/ILessonPlanCustom";
import { WebpCard } from "components/WebpCard";

import { LessonPlanCustomCareerWebpCard, LessonPlanCustomCareerContent, FieldWrapper, SelectStyled } from "./styles";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IItem {
    label: string;
    value: number;
}
interface IProps {
    onHandleCareer: (event: IItem) => void;
    careers: IItem[];
    career: ICareer | undefined;
}

const LessonPlanCustomCareer = ({ careers, career, onHandleCareer }: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (theme.project.brand !== "proenem") {
            history.push({
                pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/configure-seu-plano`
            });
        }
    }, [courseSlug]);

    const careerFormatted = useMemo(() => {
        if (!career) {
            return [];
        }

        return {
            label: career?.name,
            value: career?.id
        };
    }, [career]);

    const handleSelectItem = useCallback(
        (event) => {
            onHandleCareer(event);
        },
        [onHandleCareer]
    );

    return (
        <LessonPlanCustomCareerWebpCard>
            <WebpCard
                headerComposition={
                    <LessonPlanCustomCareerContent>
                        {isMobile && <p className="labelLessonPlanCustomCareer">Escolha sua carreira</p>}
                        <p>Qual carreira você quer seguir?</p>
                        <FieldWrapper>
                            <SelectStyled
                                name="search"
                                placeholder="Digite o nome do curso"
                                noOptionsMessage={() => "Nenhum resultado"}
                                options={careers}
                                value={careerFormatted}
                                onChange={handleSelectItem}
                                theme={(theme: any) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#F0F1F7",
                                        primary75: "#F0F1F7",
                                        primary50: "#F0F1F7",
                                        primary25: "#F0F1F7"
                                    }
                                })}
                                styles={{
                                    control: (baseStyles: any) => ({
                                        ...baseStyles,
                                        borderColor: theme.colors.base[300]
                                    }),
                                    option: (styles: any, { isFocused, isSelected }: any) => {
                                        return {
                                            ...styles,
                                            backgroundColor: isSelected || isFocused ? theme.colors.base[100] : theme.colors.white,
                                            color: isSelected ? theme.colors.base[400] : theme.colors.base[300]
                                        };
                                    }
                                }}
                            ></SelectStyled>
                        </FieldWrapper>
                        <img src={LessonPlanCustomImage} width={280} alt="Imagem monte seu plano de estudos" />
                    </LessonPlanCustomCareerContent>
                }
            ></WebpCard>
        </LessonPlanCustomCareerWebpCard>
    );
};

export { LessonPlanCustomCareer };
