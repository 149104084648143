// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    variant: "secondary" | "brand";
    active: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;
    box-sizing: border-box;

    .box-image {
        width: 100%;
        height: 193px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        background: ${theme.colors.white};
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        margin-bottom: 8px;
        padding: 24px 18px;
        text-align: center;
        cursor: pointer;

        ${({ active, variant }) =>
            active &&
            css`
                border: 2px solid ${theme.colors[variant][300]};
                box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
            `}

        img {
            margin-bottom: 11px;
        }

        h2 {
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0.005em;
            line-height: 1.375;
            color: #232326;
            margin-bottom: 8px;
        }

        p {
            font-size: 0.75rem;
            font-weight: 400;
            letter-spacing: 0.01em;
            line-height: 1.333;
            color: ${theme.colors.base[400]};
        }
    }

    .box-image:hover {
        border: 2px solid ${({ variant }) => theme.colors[variant][300]};
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    .know-more {
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.001em;
        line-height: 1.428;
        color: ${theme.colors.base[400]};
        display: flex;
        justify-content: center;
        margin-top: 8px;
        cursor: pointer;

        b {
            color: ${({ variant }) => theme.colors[variant][300]};
            font-weight: 300;
            transform: scale(0.7, 1.5);
            margin-left: 8px;
        }
    }

    @media (min-width: 768px) {
        max-width: 246px;
    }
`;
