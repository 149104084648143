import React, { useCallback, useMemo } from "react";

// Dependencies
import CheckIcon from "prodigio-icons/web/outlined/Check";
import CloseIcon from "prodigio-icons/web/outlined/Close";
import RetryIcon from "prodigio-icons/web/outlined/GoFoward";

// Components
import Spinner from "components/Spinner";

// Helpers
import { theme } from "config/theme";
import { IForumUpload, UploadStatus } from "store/interfaces/IForum";

// Assets
import * as A from "./styles";

interface IAttachmentProps extends IForumUpload {
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
}

const Attachment: React.FC<IAttachmentProps> = ({ onRemoveAttachment, onRetryUpload, ...rest }) => {
    const handleRemoveAttachment = useCallback(() => onRemoveAttachment(rest.id), [rest.id, onRemoveAttachment]);

    const handleRetryUpload = useCallback(() => onRetryUpload(rest), [rest, onRetryUpload]);

    const StatusComponent = useMemo(() => {
        const icons = new Map<UploadStatus, any>([
            [
                "error",
                <button key={1} onClick={handleRetryUpload}>
                    <RetryIcon width="16" height="16" color={theme.colors.system.danger[300]} />
                </button>
            ],
            ["loading", <Spinner fixed={false} size={12} key={2} />],
            ["success", <CheckIcon width="16" height="13" color={theme.colors.system.success[300]} key={3} />]
        ]);

        const icon = icons.get(rest.status);

        if (!icon) {
            return null;
        }

        return icon;
    }, [handleRetryUpload, rest.status]);

    return (
        <A.AttachmentsContainer>
            <A.AttachmentsIconWrapper>{StatusComponent}</A.AttachmentsIconWrapper>
            <strong>{rest.file.name}</strong>
            {rest.status !== "loading" && (
                <button type="button" onClick={handleRemoveAttachment}>
                    <CloseIcon width="12" height="12" />
                </button>
            )}
        </A.AttachmentsContainer>
    );
};

export default Attachment;
