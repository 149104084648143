import styled from "styled-components";
import { theme } from "config/theme";
import { Label } from "assets/styles/global";

export const ProgressBadgeContainer = styled.div`
    display: flex;
    width: max-content;
    background: ${theme.colors.base[100]};
    border-radius: 40px;
    box-shadow: ${theme.shadow[1]};
    padding: ${theme.spacing.smallXX} ${theme.spacing.smallX};

    span {
        ${Label};
        color: ${theme.colors.system.success[400]};
        display: block;
        line-height: 1;
    }
`;
