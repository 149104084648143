// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, ContentWrapper, TitleActionButtonWrapper } from "./activity-form-item.styles";

// Types
import { ActivityFormItemProps } from "./activity-form-item.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityFormItem: FunctionComponent<ActivityFormItemProps> = ({ content, headerComposition }) => {
    return (
        <Container>
            <TitleActionButtonWrapper>{headerComposition}</TitleActionButtonWrapper>

            <ConditionallyRender shouldRender={!!content} content={<ContentWrapper>{content}</ContentWrapper>} />
        </Container>
    );
};
