import styled, { css } from "styled-components";
import { theme } from "config/theme";
import { ExerciseListLetterContainer } from "components/Letter/styles";
import { DropdownContainer, DropdownContent, DropdownItem } from "components/Dropdown/styles";
import { MediaQueries } from "assets/styles/settings";

export const ExerciseListResultQuestionsContainer = styled.div`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small} 0;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    ${ExerciseListLetterContainer} {
        margin-right: ${theme.spacing.small};
    }

    ${DropdownContainer} {
        padding: 0 ${theme.spacing.smallX};
    }

    ${DropdownContent} {
        min-width: 150px;
        right: 2px;
    }

    ${DropdownItem} {
        :hover {
            background: ${theme.colors.base[100]};
        }
    }
`;

export const ExerciseListResultQuestionFeedback = styled.p`
    color: ${theme.colors.base[400]};
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.spacing.small};
    }
`;

export const ExerciseListResultQuestionDropdownButton = styled.span<{ active: boolean }>`
    svg {
        color: ${theme.colors.base[300]};
    }

    ${({ active }) =>
        active &&
        css`
            svg {
                color: ${theme.colors.brand[300]};
            }
        `};

    :hover {
        svg {
            color: ${theme.colors.brand[300]};
        }
    }
`;
