import React, { useMemo, useState, memo, useCallback } from "react";
import { IExamResolution } from "interfaces/IExam";

import { ExamResultRealizationsAccordionContainer as Container } from "./styles";
import AccordionOptions from "components/AccordionOptions";
import { ISelectOption } from "interfaces/ISelectOption";
import Skeleton from "./skeleton";

interface IProps {
    resolutions: IExamResolution[];
    onClickResolution(resolutionId: number): void;
}

const ExamResultRealizationsAccordion: React.FC<IProps> = ({ resolutions = [], onClickResolution }) => {
    const [forceRender, setForceRender] = useState(false);

    const handleForceRender = useCallback(() => {
        setForceRender((old) => !old);

        setTimeout(() => {
            setForceRender((old) => !old);
        }, 200);
    }, []);

    const handleClickResolution = useCallback(
        (resolutionId: number) => {
            onClickResolution(resolutionId);

            handleForceRender();
        },
        [handleForceRender, onClickResolution]
    );

    const items = useMemo<ISelectOption[]>(
        () =>
            resolutions?.map((item) => ({
                value: item.id,
                label: `${item.attempt}ª realização`
            })) || [],
        [resolutions]
    );

    if (forceRender) {
        return <Skeleton />;
    }

    if (!items?.length) {
        return null;
    }

    return (
        <Container>
            <p>Veja outros resultados deste simulado:</p>
            <AccordionOptions title="Ver resultados" options={items} onSelect={handleClickResolution} />
        </Container>
    );
};

export default memo(ExamResultRealizationsAccordion);
