// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: block;
    border-radius: 0px 8px 8px 0px;
    padding: 24px;
    position: relative;
    background-color: ${theme.colors.base[100]};

    .border {
        width: 4px;
        height: 100%;
        background-color: ${theme.colors.brand[300]};
        border-radius: 8px 0px 0px 8px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .header {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        justify-content: space-between;
        align-items: center;
        font-family: "Nunito";
    }

    .image-and-name {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .name-sender {
        font-size: 16px;
        color: ${theme.colors.base[400]};
        font-weight: normal;
    }

    .time {
        color: ${theme.colors.base[300]};
        font-size: 14px;
    }

    .content {
        font-family: "Nunito", sans-serif;
        font-style: normal;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.428;
        text-align: initial;
        color: ${theme.colors.base[400]};
        white-space: pre-wrap;
        width: 100%;
        margin-top: 24px;
        flex-direction: column;
        display: block;

        &:first-child {
            margin: 0 !important;
            padding: 0 !important;
            box-sizing: border-box !important;
        }

        img {
            max-width: 100%;
        }

        p,
        span,
        button,
        b {
            font-size: 16px !important;
        }
    }

    .likes-attachment-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;

        margin-top: 24px;

        @media (min-width: 768px) {
            flex-direction: row;
            gap: unset;
        }
    }

    .button-attachment {
        font-family: "Nunito", sans-serif;
        font-size: 0.75rem;
        font-weight: 400;
        letter-spacing: 0.01em;
        line-height: 1.333;

        background-color: transparent;
        border: none;
        cursor: pointer;
        color: t ${theme.colors.base[300]};

        display: flex;
        align-items: end;

        img {
            margin-right: 5px;
            width: 20px;
        }
    }

    @media (min-width: 768px) {
        flex-direction: row;
        gap: unset;
        margin: unset;
    }
`;
