import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import SubjectScreenDetailsContentPerType from "../ContentPerType";

import CloseIcon from "prodigio-icons/web/outlined/Close";

import * as S from "./styles";
import ProgressBar from "components/ProgressBar";
import { theme } from "config/theme";

import SubjectScreenDetailsActiveModuleModalSkeleton from "./skeleton";
import { ISubjectProgress } from "interfaces/ISubject";
import formatPercent from "utils/formatPercent";
import ErrorUI from "components/ErrorUI";
import { IBookletContentPerType } from "store/interfaces/IBookletModule";
import { isCourseFree } from "helpers/isCourseFree";

interface IProps {
    contentPerType?: IBookletContentPerType[];
    onClickClose(): void;
    name: string;
    isLoading: boolean;
    progress?: ISubjectProgress;
    error: boolean;
    imageSrc?: string;
    linkHref?: string;
}

const SubjectScreenDetailsActiveModuleModal = memo(
    ({ contentPerType = [], onClickClose, name, isLoading = true, progress, error = false, imageSrc = "", linkHref = "" }: IProps) => {
        const { courseSlug } = useSelector(({ course }: IReduxStore) => ({ courseSlug: course?.slug }));
        const progressPercentage = useMemo(() => formatPercent(progress?.percent || 0), [progress]);
        const hasSources = imageSrc && linkHref;

        if (error) {
            return <ErrorUI message="Ocorreu um erro ao carregar esse módulo." withButton={false} />;
        }

        if (isLoading) {
            return <SubjectScreenDetailsActiveModuleModalSkeleton />;
        }
        return (
            <S.SubjectScreenDetailsActiveModuleModalContainer>
                <S.SubjectScreenDetailsActiveModuleModalHeader>
                    <S.SubjectScreenDetailsActiveModuleModalButton onClick={onClickClose}>
                        <CloseIcon width={16} />
                    </S.SubjectScreenDetailsActiveModuleModalButton>

                    <S.SubjectScreenDetailsActiveModuleModalTitle>{name}</S.SubjectScreenDetailsActiveModuleModalTitle>
                    <S.SubjectScreenDetailsActiveModuleModalProgress>
                        <strong>{progressPercentage}% concluído</strong>
                        <ProgressBar
                            value={progressPercentage}
                            responsive
                            color={progressPercentage === 100 ? theme.colors.system.success[200] : theme.colors.system.info[200]}
                        />
                    </S.SubjectScreenDetailsActiveModuleModalProgress>

                    {!contentPerType.length && isCourseFree(courseSlug) && hasSources && (
                        <a target="_blank" href={linkHref} rel="noopener noreferrer" style={{ padding: "10px" }} className="pricecard_plataforma">
                            <img loading="lazy" src={imageSrc} alt="imagem" style={{ width: "100%", borderRadius: "4px" }}></img>
                        </a>
                    )}
                </S.SubjectScreenDetailsActiveModuleModalHeader>
                <SubjectScreenDetailsContentPerType contentPerType={contentPerType} />
            </S.SubjectScreenDetailsActiveModuleModalContainer>
        );
    }
);

export default SubjectScreenDetailsActiveModuleModal;
