import React, { useCallback } from "react";
import history from "services/history";
import { theme } from "config/theme";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";

import {
    ColorTaggedItemBullet,
    ColorTaggedItemContainer,
    ColorTaggedItemWrapper,
    ColorTaggedItemContent,
    ColorTaggedItemName,
    ColorTaggedItemIconContainer,
    ColorTaggedItemDescription
} from "./styles";
import { FlatVariant } from "utils/types/Variant";
import Badge from "components/Badge";

interface IColorTaggedList {
    bulletColor?: string;
    listHeading: string;
    listDescription?: React.ReactNode | string;
    listDescriptionColor?: string;
    linkTo?: string;
    onClick?(): void;
    badge?: {
        variant: FlatVariant;
        text: string;
    };
    isExamsPage?: boolean;
}

const ColorTaggedItem = ({
    bulletColor = theme.colors.brand[300],
    listHeading,
    listDescription,
    linkTo,
    onClick,
    listDescriptionColor = theme.colors.base[300],
    badge,
    isExamsPage = false
}: IColorTaggedList) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick();
        }

        if (!linkTo) {
            return;
        }
        history.push({ pathname: linkTo });
    }, [linkTo, onClick]);

    return (
        <>
            <ColorTaggedItemContainer data-test-id="tagged-exercise-list" onClick={handleClick}>
                <ColorTaggedItemWrapper>
                    <ColorTaggedItemContent>
                        <ColorTaggedItemName data-test-id="tagged-exercise-list-name">
                            <ColorTaggedItemBullet bulletColor={bulletColor} />
                            <p>{listHeading}</p>
                        </ColorTaggedItemName>
                    </ColorTaggedItemContent>

                    <ColorTaggedItemIconContainer>
                        {!!badge && (
                            <Badge variant={badge.variant} backgroundWeight={100}>
                                {badge.text}
                            </Badge>
                        )}

                        {!isExamsPage && (
                            <ColorTaggedItemDescription data-test-id="tagged-exercise-description" color={listDescriptionColor}>
                                {listDescription}
                            </ColorTaggedItemDescription>
                        )}

                        <p>Ver prova</p>
                        <ChevronRight />
                    </ColorTaggedItemIconContainer>
                </ColorTaggedItemWrapper>

                {!!isExamsPage && (
                    <ColorTaggedItemDescription data-test-id="tagged-exercise-description" color={listDescriptionColor}>
                        {listDescription}
                    </ColorTaggedItemDescription>
                )}
            </ColorTaggedItemContainer>
        </>
    );
};

export default ColorTaggedItem;
