import { createActions, createReducer } from "reduxsauce";
import { SubjectActionsType, ISubjectActionsCreators, ISubjectState } from "store/interfaces/ISubject";

export const { Creators, Types } = createActions<Record<SubjectActionsType, SubjectActionsType>, ISubjectActionsCreators>({
    getSubjectBySlugRequest: ["payload"],
    getSubjectBySlugSuccess: ["payload"],
    getSubjectBySlugFailure: ["payload"],

    getSubjectsRequest: ["payload"],
    getSubjectsSuccess: ["payload"],
    getSubjectsFailure: ["payload"],

    getSubjectsProgressRequest: ["payload"],
    getSubjectsProgressSuccess: ["payload"],
    getSubjectsProgressFailure: ["payload"],

    getSubjectModuleRequest: ["payload"],
    getSubjectModuleSuccess: ["payload"],
    getSubjectModuleFailure: ["payload"],

    getSubjectProgressBySlugRequest: ["payload"],
    getSubjectProgressBySlugSuccess: ["payload"],
    getSubjectProgressBySlugFailure: [],

    getSubjectProgressRequest: [],
    getSubjectProgressSuccess: ["payload"],

    getSubjectFrontsRequest: ["payload"],
    getSubjectFrontsSuccess: ["payload"],
    getSubjectFrontsFailure: [],

    getSubjectInfosRequest: ["payload"],
    getSubjectInfosSuccess: ["payload"],
    getSubjectInfosFailure: [],

    getSubjectFrontProgressRequest: ["payload"],
    getSubjectFrontProgressSuccess: ["payload"],
    getSubjectFrontProgressFailure: [],

    setCurrentFront: ["payload"],
    setCurrentFrontSuccess: ["payload"],
    setSubject: ["payload"],

    clearSubject: [],
    disableSubjectFronts: []
});

export const INITIAL_STATE: ISubjectState = {
    isLoading: false,
    isLoadingProgress: true,
    isLoadingFronts: true,
    isLoadingFrontProgress: true,
    isLoadingInfos: true,
    isWithSubjectFront: true,
    id: 0,
    name: "",
    color: "",
    image: null,
    order: 0,
    slug: "",
    progress: {
        completedItems: 0,
        percent: 0,
        totalItems: 0
    },
    items: [],
    progressItems: [],
    error: false,
    fronts: [],
    currentFrontSelectedByUser: false
};

const getSubjectModuleSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    module: action.payload
});

const getSubjectsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    items: action.payload,
    isLoading: false
});

const subjectsRequestProgress = (state = INITIAL_STATE, action: any) => ({
    ...state
});

const subjectsRequestProgressFailure = (state = INITIAL_STATE, action: any) => ({
    ...state
});

// GENERIC ACTIONS
const subjectRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});

const subjectRequestSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    ...action.payload,
    isLoading: false
});

const subjectRequestFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false,
    error: true
});

const clearSubject = () => INITIAL_STATE;

const disableSubjectFronts = (state = INITIAL_STATE) => ({ ...state, isWithSubjectFront: false });

const requestProgressBySlug = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingProgress: true
});

const requestProgressFailureBySlug = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingProgress: false
});

const requestProgressSuccessBySlug = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingProgress: false,
    progress: action.payload
});

const subjectProgressRequest = (state = INITIAL_STATE) => ({
    ...state
});

const subjectProgressSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    progressItems: action.payload
});

const requestFronts = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingFronts: true
});

const requestFrontsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingFronts: false
});

const requestFrontsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingFronts: false,
    fronts: action.payload || []
});

const requestInfos = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingInfos: true
});

const requestInfosFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingInfos: false
});

const requestInfosSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingInfos: false,
    infos: {
        ...state.infos,
        ...action.payload
    }
});

const requestFrontProgress = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingFrontProgress: true
});

const requestFrontProgressFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingFrontProgress: false
});

const requestFrontProgressSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoadingFrontProgress: false,
    currentFront: {
        ...state.currentFront,
        progress: action.payload
    }
});

const setCurrentFront = (state = INITIAL_STATE, action: any) => {
    return {
        ...state
    };
};

const setCurrentFrontSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        currentFront: action.payload.front,
        currentFrontSelectedByUser: action.payload.currentFrontSelectedByUser
    };
};

const setSubject = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        ...action.payload
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.GET_SUBJECT_BY_SLUG_REQUEST]: subjectRequest,
    [Types.GET_SUBJECT_BY_SLUG_SUCCESS]: subjectRequestSuccess,
    [Types.GET_SUBJECT_BY_SLUG_FAILURE]: subjectRequestFailure,

    [Types.GET_SUBJECTS_REQUEST]: subjectRequest,
    [Types.GET_SUBJECTS_SUCCESS]: getSubjectsSuccess,
    [Types.GET_SUBJECTS_FAILURE]: subjectRequestFailure,

    [Types.GET_SUBJECTS_PROGRESS_REQUEST]: subjectsRequestProgress,
    [Types.GET_SUBJECTS_PROGRESS_SUCCESS]: getSubjectsSuccess,
    [Types.GET_SUBJECTS_PROGRESS_FAILURE]: subjectsRequestProgressFailure,

    [Types.GET_SUBJECT_MODULE_REQUEST]: subjectRequest,
    [Types.GET_SUBJECT_MODULE_SUCCESS]: getSubjectModuleSuccess,
    [Types.GET_SUBJECT_MODULE_FAILURE]: subjectRequestFailure,

    [Types.GET_SUBJECT_PROGRESS_BY_SLUG_REQUEST]: requestProgressBySlug,
    [Types.GET_SUBJECT_PROGRESS_BY_SLUG_SUCCESS]: requestProgressSuccessBySlug,
    [Types.GET_SUBJECT_PROGRESS_BY_SLUG_FAILURE]: requestProgressFailureBySlug,

    [Types.GET_SUBJECT_PROGRESS_REQUEST]: subjectProgressRequest,
    [Types.GET_SUBJECT_PROGRESS_SUCCESS]: subjectProgressSuccess,

    [Types.GET_SUBJECT_FRONTS_REQUEST]: requestFronts,
    [Types.GET_SUBJECT_FRONTS_SUCCESS]: requestFrontsSuccess,
    [Types.GET_SUBJECT_FRONTS_FAILURE]: requestFrontsFailure,

    [Types.GET_SUBJECT_INFOS_REQUEST]: requestInfos,
    [Types.GET_SUBJECT_INFOS_SUCCESS]: requestInfosSuccess,
    [Types.GET_SUBJECT_INFOS_FAILURE]: requestInfosFailure,

    [Types.GET_SUBJECT_FRONT_PROGRESS_REQUEST]: requestFrontProgress,
    [Types.GET_SUBJECT_FRONT_PROGRESS_SUCCESS]: requestFrontProgressSuccess,
    [Types.GET_SUBJECT_FRONT_PROGRESS_FAILURE]: requestFrontProgressFailure,

    [Types.SET_CURRENT_FRONT]: setCurrentFront,
    [Types.SET_CURRENT_FRONT_SUCCESS]: setCurrentFrontSuccess,
    [Types.SET_SUBJECT]: setSubject,

    [Types.CLEAR_SUBJECT]: clearSubject,
    [Types.DISABLE_SUBJECT_FRONTS]: disableSubjectFronts
});
