import React, { useCallback, useEffect } from "react";

// Dependencies
import ChevronLeft from "prodigio-icons/web/outlined/ChevronLeft";

// Components
import StudentAreaScreenHome from "./Home";
import StudentForm from "./StudentAreaForms";
import StudentAreaScreenSignedCourses from "./SignedCourses";

// Helpers
import history from "services/history";
import useIsMobile from "hooks/use-is-mobile";

// Assets
import { Col, Grid, Row } from "components/Grid";
import { BreakPoints } from "assets/styles/settings";
import { ChevronIconContainer, ScreenTitle, StudentAreaScreenContainer } from "./styles";

import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as StudentAreaActions, IStudentActionCreators, IStudentAreaState } from "store/ducks/student-area";
import { Creators as CredentialsActions } from "store/ducks/credentials";
import { Creators as AlertActions } from "store/ducks/alert";
import { IReduxStore } from "interfaces/IReduxStore";

export type StudentAreaScreenTypes = "edit-personal" | "change-password" | "signed-courses" | "financial" | "profile";

export interface IHandleRequestAction {
    onRequestAction(type: StudentAreaScreenTypes): void;
}

export interface IOnSubmitActionStudentArea {
    onSubmitAction<TForm extends any>({ form, type }: { form: TForm; type: StudentAreaScreenTypes | "upload/imageProfile" }): void;
}

interface IStudentAreaScreenProps {
    screen?: StudentAreaScreenTypes;
    financialCode?: string;
    studentArea: IStudentAreaState;
    imageProfile: any;
    credentialsName: string;
    credentialsEmail: string;
    isLoading: boolean;
}

const screenTitleMap = new Map<StudentAreaScreenTypes, string>([
    ["profile", ""],
    ["edit-personal", "Dados pessoais e endereço"],
    ["change-password", "Alterar senha"],
    ["financial", "Dados financeiros"],
    ["signed-courses", "Assinaturas"]
]);

const StudentAreaScreen = ({
    screen,
    financialCode,
    studentArea,
    imageProfile,
    credentialsName,
    credentialsEmail,
    isLoading,
    onSubmitAction,
    onRequestAction
}: IStudentAreaScreenProps & IOnSubmitActionStudentArea & IHandleRequestAction) => {
    const isMobile = useIsMobile(BreakPoints.small);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <StudentAreaScreenContainer>
            <Grid>
                <Row>
                    {(screen === "profile" || !isMobile) && (
                        <Col xs={12} sm={6} md={4}>
                            <StudentAreaScreenHome
                                name={credentialsName}
                                imageProfile={imageProfile}
                                screen={screen}
                                onSubmitAction={onSubmitAction}
                            />
                        </Col>
                    )}
                    {screen !== "profile" && !!screen && (
                        <Col xs={12} sm={6} md={8}>
                            {screen !== "signed-courses" && (
                                <>
                                    {isMobile && (
                                        <ChevronIconContainer onClick={() => history.push(`/app/curso/${courseSlug}/area-do-aluno/perfil/`)}>
                                            <ChevronLeft />
                                        </ChevronIconContainer>
                                    )}
                                    <ScreenTitle>{screenTitleMap.get(screen)}</ScreenTitle>
                                </>
                            )}
                            {(screen === "edit-personal" || screen === "change-password") /* || screen === "add/credit-card" */ && (
                                <StudentForm
                                    onSubmitAction={onSubmitAction}
                                    screenName={screen}
                                    studentArea={studentArea}
                                    onRequestAction={onRequestAction}
                                    credentialsEmail={credentialsEmail}
                                />
                            )}
                            {screen === "signed-courses" && (
                                <StudentAreaScreenSignedCourses
                                    onRequestAction={onRequestAction}
                                    signedCourses={studentArea.subscriptions}
                                    isLoading={isLoading}
                                />
                            )}
                            {/* {screen === "financeiro" && !financialCode && <Financial />} */}
                            {/* {screen === "financeiro" && !!financialCode && <TransactionDetails />} */}
                        </Col>
                    )}
                </Row>
            </Grid>
        </StudentAreaScreenContainer>
    );
};

interface IStudentAreaScreenWrapperProps {
    studentAreaActions: IStudentActionCreators;
    studentArea: IStudentAreaState;
    alertActions: any;
    screenName?: StudentAreaScreenTypes;
    credentialsImageProfile: any;
    credentialsName: string;
    credentialsEmail: string;
    isLoading: boolean;
}

const IMAGE_VALIDATION = {
    MAX_SIZE: 5242880,
    ALLOWED_TYPES: ["image/png", "image/jpg", "image/jpeg"]
};

const StudentAreaScreenWrapper = ({
    studentAreaActions,
    studentArea,
    alertActions,
    screenName,
    credentialsImageProfile,
    credentialsName,
    credentialsEmail,
    isLoading
}: IStudentAreaScreenWrapperProps) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch({ type: "CLEAR_STUDENT_AREA" });
        };
    }, [dispatch]);

    const handleRequestAction = useCallback(
        (type: StudentAreaScreenTypes) => {
            if (type === "edit-personal") {
                return studentAreaActions.studentRequest();
            }

            if (type === "signed-courses") {
                return studentAreaActions.studentSubscriptionsRequest();
            }

            if (type === "financial") {
                return studentAreaActions.studentFinancialDataRequest();
            }
        },
        [studentAreaActions]
    );

    const submitImageProfile = useCallback(
        async (form: FileList) => {
            try {
                if (!IMAGE_VALIDATION.ALLOWED_TYPES.includes(form[0].type)) {
                    alertActions.showAlert(
                        `Imagem enviada com extensão .${form[0].type.split("/")[1]}. Extensões de imagem permitidas (.png, .jpg, .jpeg)`,
                        "warning"
                    );

                    throw new Error();
                }

                if (form[0].size > IMAGE_VALIDATION.MAX_SIZE) {
                    alertActions.showAlert("A imagem deve ser menor que 5Mb", "warning");

                    throw new Error();
                }

                const formDataImage = new FormData();
                formDataImage.set("file", form[0]);
                await studentAreaActions.studentPhotoUploadRequest(formDataImage);
            } catch (error) {
                console.log(error);
            }
        },
        [alertActions, studentAreaActions]
    );

    const onSubmitAction = useCallback(
        async ({ form, type }: { form: any; type: StudentAreaScreenTypes }) => {
            try {
                const submitActions: { [key: string]: () => void } = {
                    "edit-personal": () => studentAreaActions.studentSaveRequest(form),
                    profile: () => submitImageProfile(form),
                    "change-password": () => studentAreaActions.studentSavePasswordRequest(form)
                };

                if (!submitActions[type]) {
                    throw new Error("action submit não implementada");
                }

                return submitActions[type]();
            } catch (error) {
                console.log(error);
            }
        },
        [studentAreaActions, submitImageProfile]
    );

    return (
        <StudentAreaScreen
            onSubmitAction={onSubmitAction}
            onRequestAction={handleRequestAction}
            screen={screenName}
            studentArea={studentArea}
            imageProfile={credentialsImageProfile}
            credentialsName={credentialsName}
            credentialsEmail={credentialsEmail}
            isLoading={isLoading}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    studentAreaActions: bindActionCreators(StudentAreaActions, dispatch),
    credentials: bindActionCreators(CredentialsActions, dispatch),
    alertActions: bindActionCreators(AlertActions, dispatch)
});

const mapStateToProps = (state: IReduxStore) => ({
    studentArea: state.studentArea,
    credentialsImageProfile: state.credentials.imageProfile,
    credentialsName: state.credentials.name,
    credentialsEmail: state.credentials.email,
    isLoading: state.subject.isLoading,
    courseId: state.course.id,
    email: state.studentArea.email
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(StudentAreaScreenWrapper);
