import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";

import Filter from "components/Filter";
import Spinner from "components/Spinner";
import Select from "components/Select";
import PageHeader from "components/PageHeader";
import EssayDispatches from "../components/essayDispatchesList";
import PerformanceChart from "./components/performanceChart";

import DropdownIndicator from "screens/MigrationUser/components/indicator";

import { ICheckboxItem } from "interfaces/ICheckbox";
import { ISelectOption } from "interfaces/ISelectOption";
import { IReduxStore } from "interfaces/IReduxStore";
import { IEssayState } from "store/interfaces/IEssay";

import { Col, Grid, Row } from "components/Grid";
import { EssayThemesRow, EssayThemesContentTitle } from "../themes/styles";

import * as S from "./styles";

import { ordererOptionsCompositions } from "../mock";
interface IEssayCompositionsProps {
    isFetched: boolean;
    onFetchCompositions: () => void;
    onSort: (value: string) => void;
    onSelectContest: (value: string) => void;
    onSelectTheme: (value: string) => void;
    onSelectContestChart: (value: string) => void;
    contests: ICheckboxItem[];
    themes: ICheckboxItem[];
}

const MyEssays = ({
    isFetched,
    isLoading,
    compositions,
    latestCompositions,
    onSort,
    onSelectContest,
    onFetchCompositions,
    onSelectContestChart,
    onSelectTheme,
    contests,
    themes
}: Pick<IEssayState, "compositions" | "latestCompositions" | "isLoading"> & IEssayCompositionsProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [contest, setContest] = useState<ISelectOption>();

    const handleSelectChange = useCallback(
        (option: ISelectOption) => {
            onSelectContestChart(option?.value);
            setContest(option);
        },
        [onSelectContestChart]
    );

    const handleSort = useCallback((option: ISelectOption<string>) => onSort(option?.value), [onSort]);

    const handleSelectContest = useCallback((option: ISelectOption) => onSelectContest(option?.value), [onSelectContest]);

    const handleSelectTheme = useCallback((option: ISelectOption) => onSelectTheme(option?.value), [onSelectTheme]);

    return (
        <S.MyEssaysContainer>
            <PageHeader.Simple
                breadcrumb={{
                    current: {
                        label: "Detalhes da Redação"
                    },
                    parent: [
                        {
                            label: "Redações",
                            url: `/app/curso/${courseSlug}/redacoes`
                        },
                        {
                            label: "Minhas redações",
                            url: `/app/curso/${courseSlug}/redacoes/envios`
                        }
                    ]
                }}
            />
            <S.MyEssaysContent>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <EssayThemesContentTitle>Desempenho em redação</EssayThemesContentTitle>
                            <S.MyEssaysPerformance>
                                <Select
                                    inputId="contest"
                                    name="contest"
                                    components={{ DropdownIndicator }}
                                    placeholder="Selecione um concurso"
                                    options={contests}
                                    selected={contest}
                                    onChange={handleSelectChange}
                                />
                                <PerformanceChart compositions={latestCompositions} />
                            </S.MyEssaysPerformance>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <EssayThemesRow>
                                <EssayThemesContentTitle>Envios</EssayThemesContentTitle>

                                <EssayThemesRow>
                                    <Filter label="Concurso" withIcon={false} options={contests} onChange={handleSelectContest} />
                                    <Filter label="Temas" withIcon={false} options={themes} onChange={handleSelectTheme} />
                                    <Filter label="Ordenar" withIcon={false} options={ordererOptionsCompositions} onChange={handleSort} />
                                </EssayThemesRow>
                            </EssayThemesRow>
                        </Col>
                    </Row>

                    {isFetched && isLoading ? (
                        <Spinner fixed={false} size={20} />
                    ) : (
                        <EssayDispatches compositions={compositions} onFetchCompositions={onFetchCompositions} />
                    )}
                </Grid>
            </S.MyEssaysContent>
        </S.MyEssaysContainer>
    );
};

export default memo(MyEssays);
