import React, { memo, useCallback, useState } from "react";

// Components
import Upload from "./upload";

interface IUploadModalProps {
    onUploadFile: (files: File[]) => void;
}

const UploadModal = ({ onUploadFile }: IUploadModalProps) => {
    const [files, setFiles] = useState<File[]>([]);

    const handleClearFiles = useCallback(() => setFiles([]), []);

    const handleUpload = useCallback((files: File[]) => setFiles(files), []);

    return <Upload files={files} onClearFiles={handleClearFiles} onUpload={handleUpload} onSubmit={onUploadFile} />;
};

export default memo(UploadModal);
