import styled from "styled-components";
import { theme } from "config/theme";
import { TitleH5, BodyText1 } from "assets/styles/global";

const colors = {
    success: theme.colors.system.success[300],
    danger: theme.colors.system.danger[300],
    warning: theme.colors.secondary[300]
};

export const ExamResultFeedbackContainer = styled.div<{ variant: "success" | "danger" | "warning" }>`
    display: flex;

    svg {
        color: ${({ variant }) => colors[variant]};
        margin-right: ${theme.spacing.smallX};
        margin-top: 2px; // resolve alignment problem with knowledge area name
    }

    > div {
        display: flex;
        flex-direction: column;

        ${TitleH5} {
            color: ${({ variant }) => colors[variant]};
            margin-bottom: ${theme.spacing.smallXX};
        }

        p {
            ${BodyText1};

            color: ${theme.colors.base[400]};
        }
    }
`;
