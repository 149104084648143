import React, { memo, useMemo, useRef, useState, useEffect } from "react";

// Dependencies
import { VictoryChart, VictoryLine, VictoryContainer, VictoryAxis, VictoryGroup, VictoryScatter } from "victory";

// Helpers
import formatDate from "utils/formatDate";
import { theme } from "config/theme";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { IEssayComposition } from "store/interfaces/IEssay";
import { EssayStatus } from "enums/EssayStatus";

// Assets
import * as S from "./styles";

interface IPerformanceChartProps {
    compositions: IEssayComposition[];
}

const BORDER_WIDTH = 47;

const PARAMS_VALUES = {
    tickValues: theme.project.slug === "promilitares" ? [0, 5, 10, 15, 20] : [0, 250, 500, 750, 1000],
    domain: theme.project.slug === "promilitares" ? 20 : 1000
};

const GroupWrapper = (props: any) => <g className="group-wrapper" {...props} />;

const EssayPerformanceChart = ({ compositions }: IPerformanceChartProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number>();
    const isMobile = useIsMobile(BreakPoints.small);
    const isSmallerThanDesktop = useIsMobile(BreakPoints.huge);

    const axisXValue = useMemo(
        () => (value: string) => {
            return isSmallerThanDesktop ? `Enviado em\n${value}` : `Enviado em ${value}`;
        },
        [isSmallerThanDesktop]
    );

    const BulletLabel = useMemo(
        () => (props: any) => (
            <g className="bullet-label">
                <line
                    x1={props.x}
                    y1={props.y}
                    x2={props.x + BORDER_WIDTH}
                    y2={props.y}
                    style={{ stroke: theme.colors.system.success[200], strokeWidth: "1px", strokeDasharray: "2, 3", strokeLinecap: "round" }}
                />
                <text
                    x={props.x}
                    y={props.y - 4}
                    fontSize={12}
                    style={{
                        fontFamily: theme.typography.family.secondary,
                        fill: theme.colors.system.success[200],
                        transform: "translateX(27px)"
                    }}
                >
                    {props.datum.y}
                </text>
                <circle
                    cx={props.x}
                    cy={props.y}
                    r={isMobile ? 4 : 6}
                    stroke={theme.colors.system.success[100]}
                    strokeWidth={4}
                    fill={theme.colors.system.success[200]}
                />
            </g>
        ),
        [isMobile]
    );

    const Line = useMemo(
        () => (props: any) => (
            <line
                x1={props.x1}
                x2={props.x2}
                y1={props.y1}
                y2={props.y2}
                style={{ stroke: theme.colors.base[100] }}
                width={1}
                className="axis-line"
            />
        ),
        []
    );

    const formattedData = useMemo(() => {
        let data = [{ y: 0 }];

        if (compositions?.length) {
            const compositionsFilter = compositions.filter((item) => item.status === EssayStatus.Corrected);

            if (!compositionsFilter.length) {
                return data;
            }

            data = compositionsFilter.map((composition) => ({
                x: formatDate(composition?.created, "dd/LL/yyyy"),
                y: Number(composition?.revisions[0]?.score) || 0
            }));

            return isMobile ? data.slice(Math.max(data.length - 3, 0)) : data;
        }

        return data;
    }, [compositions, isMobile]);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }

        const styles = getComputedStyle(containerRef.current);
        let elementWidth = containerRef.current?.clientWidth;

        elementWidth -= parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);

        if (!containerWidth) {
            setContainerWidth(elementWidth);
        }
    }, [containerWidth]);

    return (
        <S.EssayPerformanceChartContainer ref={containerRef}>
            <VictoryChart
                height={isMobile ? 170 : 225}
                width={containerWidth}
                containerComponent={<VictoryContainer responsive={false} />}
                domain={{ y: [0, PARAMS_VALUES.domain] }}
                padding={isMobile ? { top: 10, left: 40, right: 30, bottom: 40 } : { top: 10, left: 40, right: 70, bottom: 40 }}
                domainPadding={isMobile ? { x: 30 } : { x: [40, 0] }}
            >
                <VictoryAxis
                    tickValues={[0]}
                    tickFormat={axisXValue}
                    gridComponent={<Line />}
                    groupComponent={<GroupWrapper />}
                    style={{
                        axis: { stroke: "transparent" },
                        tickLabels: {
                            fontFamily: theme.typography.family.secondary,
                            fontSize: 12,
                            fill: theme.colors.base[300],
                            lineHeight: "16px"
                        },
                        ticks: { stroke: "transparent" }
                    }}
                />
                <VictoryAxis
                    tickValues={PARAMS_VALUES.tickValues}
                    dependentAxis
                    padding={{ right: 150 }}
                    style={{
                        axis: { stroke: "transparent" },
                        tickLabels: {
                            fontFamily: theme.typography.family.secondary,
                            fontSize: 12,
                            fill: theme.colors.base[200]
                        },
                        ticks: { stroke: "transparent" }
                    }}
                />
                <VictoryGroup
                    name="line"
                    data={formattedData}
                    style={{
                        labels: {
                            fontFamily: theme.typography.family.secondary,
                            fontSize: 12,
                            fill: theme.colors.system.success[200]
                        }
                    }}
                >
                    <VictoryLine
                        style={{
                            data: {
                                stroke: theme.colors.system.success[100],
                                strokeWidth: 1
                            }
                        }}
                    />
                    <VictoryScatter dataComponent={<BulletLabel />} />
                </VictoryGroup>
            </VictoryChart>
        </S.EssayPerformanceChartContainer>
    );
};

export default memo(EssayPerformanceChart);
