// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: block;

    div {
        display: flex;
        align-items: center;
        margin-top: 8px;
    }

    img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }

    p {
        color: ${theme.colors.base[400]};
        line-height: 1.375;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        font-family: "Nunito", sans-serif;
    }
`;
