import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { ProjectCardContainer, ProjectCardThumbWrapper, ProjectCardContent, ProjectActions } from "./styles";

const ProjectCardPlaceholder = () => (
    <ProjectCardContainer onClick={() => null}>
        <ProjectCardThumbWrapper>
            <Skeleton width="100%" height="150px" />
        </ProjectCardThumbWrapper>
        <ProjectCardContent width={150}>
            <Skeleton width="100%" height="22px" style={{ marginBottom: 5 }} />
            <Skeleton width="30%" height="20px" />
            <ProjectActions>
                <Skeleton width="100%" height="32px" />
            </ProjectActions>
        </ProjectCardContent>
    </ProjectCardContainer>
);

export default ProjectCardPlaceholder;
