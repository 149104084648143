import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

// Components
import Like from "./components/like";
import Empty from "components/Empty";
import BackTo from "components/BackTo";
import ForumPost from "./components/post";
import ForumAnswer from "./components/answer";
import Attachments from "../components/attachments";
import ForumQuestionDetails from "../components/details";

// Helpers
import pluralize from "utils/pluralize";
import { validExtensions } from "../new";
import { Types } from "store/ducks/forum";
import { useDispatch } from "react-redux";
import { IForumNewPayload } from "../new/new";
import sanitizeString from "helpers/sanitize-string";
import { IForumQuestion, IForumState, IForumUpload, UploadType } from "store/interfaces/IForum";
import { isCourseFree } from "helpers/isCourseFree";
import { theme } from "config/theme";

// Assets
import * as F from "./styles";
import { Grid } from "components/Grid";
import { ForumQuestionDetailsHelper } from "../components/details/styles";

interface IForumQuestionProps extends Omit<IForumState, "filters" | "questions" | "isLoadingForumSubjects"> {
    isLoadingAnswer: boolean;
    question: IForumQuestion;
    userName: string;
    onAttachments: (key: UploadType) => void;
    onDeleteQuestion: (id: string) => void;
    onPostQuestion: (payload: IForumNewPayload) => void;
    onRemoveAttachment: (id: number, key: UploadType) => void;
    onRetryUpload: (attachment: IForumUpload, key: UploadType) => void;
    onToggleLike: (key: UploadType, isLiked: boolean, id: string) => void;
}

const ForumQuestion: React.FC<IForumQuestionProps> = ({
    onAttachments,
    onDeleteQuestion,
    onPostQuestion,
    onRemoveAttachment,
    onRetryUpload,
    onToggleLike,
    ...rest
}) => {
    const dispatch = useDispatch();
    const [isEditingQuestion, setIsEditingQuestion] = useState(false);

    const { courseSlug } = useSelector(({ course }: IReduxStore) => ({ courseSlug: course?.slug }));
    const hasCourseFree = isCourseFree(courseSlug);

    const {
        isAnswerCreated,
        isLoadingAnswer,
        isCommentCreated,
        isLoadingComment,
        isLoadingQuestion,
        isQuestionCreated,
        userName,
        question,
        uploads
    } = rest;

    const handlePostQuestion = useCallback(
        (content: string) => {
            const hasInvalidFiles = uploads.question.some((item) => {
                const extension = item.file.name.split(".").pop();
                return !validExtensions.includes(extension as string);
            });

            if (hasInvalidFiles) {
                return alert(`Você possui arquivos não suportados na lista de anexos. Extensões suportadas: ${validExtensions.join(", ")}`);
            }

            const attachments = uploads.question.filter((item) => item.status === "success").map((item) => item.attachment || (item.id as any));

            const payload = {
                attachments: attachments || [],
                content: sanitizeString(content, "&nbsp;"),
                id: question._id
            };

            setIsEditingQuestion(false);

            return onPostQuestion(payload);
        },
        [onPostQuestion, question._id, uploads]
    );

    const handlePostAnswer = useCallback(
        (content: string) => {
            const hasInvalidFiles = uploads.answer.some((item) => {
                const extension = item.file.name.split(".").pop();
                return !validExtensions.includes(extension as string);
            });

            if (hasInvalidFiles) {
                return alert(`Você possui arquivos não suportados na lista de anexos. Extensões suportadas: ${validExtensions.join(", ")}`);
            }

            const attachments = uploads.answer.filter((item) => item.status === "success").map((item) => item.attachment || (item.id as any));

            const payload = {
                attachments: attachments || [],
                question: question._id,
                content: sanitizeString(content, "&nbsp;")
            };

            return dispatch({ type: Types.CREATE_OR_UPDATE_ANSWER_REQUEST, payload });
        },
        [dispatch, question._id, uploads.answer]
    );

    const handleAttachments = useCallback((key: UploadType) => () => onAttachments(key), [onAttachments]);

    const handleToggleLike = useCallback((key: UploadType) => (isLiked: boolean, id: string) => onToggleLike(key, isLiked, id), [onToggleLike]);

    const handleRemoveAttachment = useCallback((key: UploadType) => (id: number) => onRemoveAttachment(id, key), [onRemoveAttachment]);

    const handleRetryUpload = useCallback((key: UploadType) => (attachment: IForumUpload) => onRetryUpload(attachment, key), [onRetryUpload]);

    const handleComplaintSubmit = useCallback(
        (id: string) => (value: number) => dispatch({ type: Types.CREATE_COMPLAINT_REQUEST, payload: { complaintType: value, question: id } }),
        [dispatch]
    );

    const handleComplaint = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "complaint",
                action: {
                    onComplaint: handleComplaintSubmit(id)
                }
            });
        },
        [dispatch, handleComplaintSubmit]
    );

    const handleUpdateQuestion = useCallback(
        (id: string) => {
            const attachments = question.attachments?.map((item) => ({
                id: item._id,
                status: "success",
                file: {
                    name: item.originalFilename,
                    path: item.originalFilename
                },
                attachment: item._id
            }));

            dispatch({ type: Types.UPLOAD_ATTACHMENTS_SUCCESS, payload: { key: "question", attachments: attachments || [] } });

            setIsEditingQuestion(true);
        },
        [dispatch, question.attachments]
    );

    return (
        <F.ForumQuestionContainer>
            <F.ForumQuestionContent color={theme.colors.brand[100]}>
                <F.ForumQuestionContentTop>
                    <Grid>
                        <F.ForumQuestionWrapper>
                            <BackTo to={`/app/curso/${courseSlug}/forum`}>Tira-dúvidas</BackTo>
                        </F.ForumQuestionWrapper>
                        <ForumQuestionDetails
                            actions={{
                                onComplaint: handleComplaint,
                                onUpdate: userName === question.user?.username && !question.answers?.length ? handleUpdateQuestion : undefined,
                                onDelete: userName === question.user?.username && !question.answers?.length ? onDeleteQuestion : undefined
                            }}
                            id={question._id}
                            date={question.created}
                            user={question.user?.name}
                            subject={question.subject?.name}
                        />
                        <F.ForumQuestionText className="ck-content" dangerouslySetInnerHTML={{ __html: question.content }} />
                        {Boolean(question.attachments?.length) && <Attachments attachments={question.attachments!} />}
                        {isEditingQuestion && (
                            <ForumPost
                                isCreated={isQuestionCreated}
                                isCreatedKey="isQuestionCreated"
                                isEditing={isEditingQuestion}
                                isLoading={isLoadingQuestion}
                                attachments={uploads.question}
                                content={question.content}
                                onAttachments={handleAttachments("question")}
                                onCancel={() => setIsEditingQuestion(false)}
                                onPost={handlePostQuestion}
                                onRemoveAttachment={handleRemoveAttachment("question")}
                                onRetryUpload={handleRetryUpload("question")}
                            />
                        )}
                    </Grid>
                </F.ForumQuestionContentTop>
                <F.ForumQuestionContentBottom>
                    <Grid>
                        <F.ForumQuestionWrapper>
                            <Like
                                disabled={hasCourseFree}
                                color={theme.colors.brand[300]}
                                count={question.likesTotal}
                                id={question._id}
                                isLiked={question.isLiked}
                                onToggleLike={handleToggleLike("question")}
                            />
                            <ForumQuestionDetailsHelper>{`${question.answersTotal} ${pluralize(
                                question.answersTotal,
                                "respostas",
                                "resposta"
                            )}`}</ForumQuestionDetailsHelper>
                        </F.ForumQuestionWrapper>
                    </Grid>
                </F.ForumQuestionContentBottom>
            </F.ForumQuestionContent>
            {!hasCourseFree && (
                <F.ForumQuestionAnswers>
                    <Grid>
                        <F.ForumQuestionAnswersHelper>Escreva algo aqui:</F.ForumQuestionAnswersHelper>
                        <ForumPost
                            isCreated={isAnswerCreated}
                            isCreatedKey="isAnswerCreated"
                            isLoading={isLoadingAnswer}
                            attachments={uploads.answer}
                            onAttachments={handleAttachments("answer")}
                            onPost={handlePostAnswer}
                            onRemoveAttachment={handleRemoveAttachment("answer")}
                            onRetryUpload={handleRetryUpload("answer")}
                        />
                    </Grid>
                </F.ForumQuestionAnswers>
            )}

            <F.ForumQuestionAnswers>
                <Grid>
                    <F.ForumQuestionAnswersTitle>
                        Respostas <span>({question.answersTotal})</span>
                    </F.ForumQuestionAnswersTitle>
                    {!!question.answers?.length ? (
                        <F.ForumAnswersWrapper>
                            {question.answers!.map((answer) => (
                                <ForumAnswer
                                    isLoading={isLoadingComment}
                                    isCreated={isCommentCreated}
                                    disabledActions={hasCourseFree}
                                    color={theme.colors.brand[300]}
                                    uploads={uploads}
                                    userName={userName}
                                    question={question._id}
                                    onAttachments={handleAttachments("comment")}
                                    onToggleLike={(key: UploadType, isLiked: boolean, id: string) => handleToggleLike(key)(isLiked, id)}
                                    onRemoveAttachment={handleRemoveAttachment("comment")}
                                    onRetryUpload={handleRetryUpload("comment")}
                                    {...answer}
                                    key={answer._id}
                                />
                            ))}
                        </F.ForumAnswersWrapper>
                    ) : (
                        <Empty message="Esta pergunta ainda não possui respostas" />
                    )}
                </Grid>
            </F.ForumQuestionAnswers>
        </F.ForumQuestionContainer>
    );
};

export default memo(ForumQuestion);
