// Dependencies
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

// Redux
import { Creators as authActions } from "store/ducks/auth";

const LayersPortalScreen: React.FC = () => {
    const dispatch = useDispatch();

    const LayersPortal = (window as any).LayersPortal;

    useEffect(() => {
        LayersPortal?.connectedPromise?.then(() => {
            const { session, userId, communityId } = (window as any).LayersPortal;

            if (!!session && !!userId && !!communityId) {
                dispatch(
                    authActions.layersRequest({
                        session: session as string,
                        user_id: userId as string,
                        community_id: communityId as string
                    })
                );
            }

            LayersPortal("ready");
        });

        return () => !!LayersPortal && !!LayersPortal.connected && LayersPortal("close");
    }, [LayersPortal, dispatch]);

    return null;
};

export default LayersPortalScreen;
