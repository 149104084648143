import React, { memo, lazy, Suspense } from "react";

// Dependencies
import { Col, Row } from "components/Grid";

// Components
import ErrorBoundary from "components/ErrorBoundary";
import ProjectCardPlaceholder from "../Card/placeholder";

// Helpers
import retry from "utils/retryLoad";
import { IProjectDetails, IProjectTag } from "interfaces/IProjectDetails";

// Assets
import { ProjectName } from "../Card/styles";

const ProjectCard = lazy(() => retry(() => import("../Card")));

interface IProjectListItemProps {
    projects: IProjectDetails[];
}

const ProjectsList = ({ projects }: IProjectListItemProps) => {
    return (
        <Row style={{ rowGap: 16 }}>
            {projects.map((item) => {
                const mainTag = item.tags && item.tags.find((tag: IProjectTag) => tag.isMain);

                return (
                    <Col xs={12} sm={6} md={4} lg={3} key={item.id}>
                        <ErrorBoundary component={<ProjectName>Ocorreu um erro</ProjectName>}>
                            <Suspense fallback={<ProjectCardPlaceholder />}>
                                <ProjectCard
                                    id={item.id}
                                    name={item.name}
                                    featured={mainTag}
                                    price={item.price}
                                    progress={undefined}
                                    imageUrl={item.urlImage}
                                    isSubscribed={!!item.student}
                                    isPublished={!!item?.isPublished}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </Col>
                );
            })}
        </Row>
    );
};

const memoFn = (prev: IProjectListItemProps, next: IProjectListItemProps) => {
    if (prev.projects.length === next.projects.length) {
        return true;
    }

    return false;
};

export default memo(ProjectsList, memoFn);
