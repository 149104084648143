// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useMemo, useCallback, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";

// Components
import { ActivityBoard } from "components/ActivityBoard";
import { ActivityButton } from "components/ActivityButton";
import { ActivityFormItem } from "components/ActivityFormItem";
import { ActivityInput } from "components/ActivityInput";
import { ActivityChip } from "components/ActivityChip";
import { ActivitySelector, ActivitySelectorItem, ActivitySelectorItemProps } from "components/ActivitySelector";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ActivityTextarea } from "components/ActivityTextarea";
import { ActivityInputCard, ActivityInputCardComposition } from "components/ActivityInputCard";
import { ActivityToggle } from "components/ActivityToggle";
import { ActivityFile } from "components/ActivityFile";
import { ActivityProgressBar } from "components/ActivityProgressBar";
import { ActivityCheckbox } from "components/ActivityCheckbox";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ActivityStepForm, ActivityStepFormItem } from "components/ActivityStepForm";
import { ActivityDatePicker } from "components/ActivityDatePicker";
import { ActivityRadio } from "components/ActivityRadio";
import { ActivityHeader } from "components/ActivityHeader";
import { ActivityAccordion } from "components/ActivityAccordion";
import { ActivityQuestions, ActivityQuestionOption } from "components/ActivityQuestions";
import { ActivityEditor } from "components/ActivityEditor";
import { ActivityPartialModal } from "components/ActivityPartialModal";
import { ActivityAlert } from "components/ActivityAlert";
import { ActivityEmptyState } from "components/ActivityEmptyState";
import { ActivitySelect } from "components/ActivitySelect";
import { ActivityQuestionIndicator } from "components/ActivityQuestionIndicator";
import { Skeleton } from "component-library/utilities/skeleton";
import { FileInput } from "component-library/elements/file-input";
import { ActivityBottomSheet } from "components/ActivityBottomSheet";
import { ActivityBreadcrumbs, ActivityBreadcrumbItem } from "components/ActivityBreadcrumbs";

// Types
// import { ActivitySelectorItemProps } from "components/ActivitySelector";
import { IReduxStore } from "interfaces/IReduxStore";
import { ActivitySelectBottomSheetOptionsType } from "components/ActivitySelect/activity-select.types";
import { ActivityType, TeacherCreateActivityExerciseListQuestionAlternative, ActivityExerciseListType } from "store/interfaces/activity";
import { ActivityOverlay } from "components/ActivityOverlay";
import { Loader } from "component-library/elements/loader";

// Styles
import {
    ActivityDefaultExerciseFormWrapper,
    ActivityDefaultRemoveIcon,
    ActivityDefaultActionButton,
    ActivityFilesWrapper,
    ActivityFormActionButtonsWrapper,
    ActivityFormInputsClassAndDateWrapper,
    ActivityFormInputsDatesWrapper,
    ActivityCustomExerciseFormWrapper,
    ActivityCustomExerciseQuestionsBoard,
    ActivityCustomExerciseQuestionsAccordionWrapper,
    ActivityPartialModalCurrentQuestionWrapper,
    ActivityPartialModalCurrentQuestionContent,
    ActivityPartialModalSeparator,
    ActivityPartialModalButtonsWrapper,
    ActivityPartialModalFooterWrapper,
    AdditionalContentWrapper,
    ClassSelectionWrapper,
    ClassSelectionInfo,
    ClassSelectionInfoIcon,
    ClassSelectionInfoLabel,
    ClassSelectionChipsWrapper,
    ClassSelectionChipIcon,
    ActivityQuestionAlternativeBottomSheetContent,
    ActivityAlternativeActionIcon,
    ActivityAlternativeActionsWrapper,
    ActivityForm,
    MobileFileUploadContentWrapper,
    MobileFileUploadContentTitle,
    QuestionsErrorMessageWrapper,
    ErrorMessageIcon,
    ErrorMessageLabel
} from "./activity-teacher-create.styles";

// Assets
import close from "assets/img/close.svg";
import info from "assets/img/info.svg";
import trash from "assets/img/trash.svg";
import bluePencil from "assets/img/activity-pencil.svg";
import blueTrash from "assets/img/activity-trash.svg";
import cursorClick from "assets/img/cursor-click.svg";
import listPlus from "assets/img/list-plus.svg";
import activityExerciseList from "assets/img/activity-exercise-list.svg";
import activityDiscursive from "assets/img/activity-discursive.svg";
import activitySendFile from "assets/img/activity-send-file.svg";
import arrowLeft from "assets/img/arrow-left.svg";
import fail from "assets/img/fail.svg";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as activityActions } from "store/ducks/activity";

// Utils
import { ValidationSchema, validationSchema } from "./activity-teacher-create.schema";
import { ternary } from "utils/ternary";
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { getMobileStepData, getMobileStep, getMobileStepError } from "./activity-teacher-create.helpers";

// Services
import history from "services/history";

type ActivityTeacherCreateParams = {
    activityId?: string;
};

export const ActivityTeacherCreate: FunctionComponent = () => {
    const { activityId } = useParams<ActivityTeacherCreateParams>();

    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const {
        details,
        subjects,
        enrollments,
        mobileStep,
        questionsBankExerciseLists,
        fileTypes,
        isCreating,
        supportMaterials,
        activityType,
        currentActivity,
        currentQuestion,
        currentQuestionAccordion
    } = useSelector(({ activity }: IReduxStore) => activity.teacherCreate);

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [shouldNotSaveData, setShouldNotSaveData] = useState(false);

    const { handleSubmit, control, setValue, watch, formState, register, clearErrors, trigger } = useForm<ValidationSchema>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            generalInformations: {
                enunciation: undefined,
                title: undefined,
                score: undefined
            },
            mainData: {
                classes: undefined,
                releaseDate: undefined,
                sendDate: undefined
            },
            activityType: undefined,
            sendFileInformations: {
                allowedFileTypes: undefined
            },
            exerciseListInformations: {
                releaseResult: undefined,
                questionsBankExerciseListId: undefined
            },
            discursiveInformations: {
                wordsLimit: undefined
            },
            questions: undefined
        }
    });

    const formMainDataClasses: string[] | undefined = watch("mainData.classes") ?? [];
    const formActivityType = watch("activityType.data") as ActivityType | undefined;
    const formExerciseListType = watch("activityType.exerciseListType") as ActivityExerciseListType | undefined;
    const formQuestions = watch("questions") as number;
    const formExerciseListInformationsQuestionsBankExerciseListId = watch("exerciseListInformations.questionsBankExerciseListId") as number;
    const formExerciseListInformationsReleaseResult = !!watch("exerciseListInformations.releaseResult") as boolean;
    const formSendFileInformationsAllowedFileTypes: string[] | undefined = watch("sendFileInformations.allowedFileTypes") ?? [];
    const formDiscursiveInformationsWordsLimit = watch("discursiveInformations.wordsLimit") as number;
    const formMainDataReleaseDate = watch("mainData.releaseDate") as string;
    const formMainDataSendDate = watch("mainData.sendDate") as string;
    const formGeneralInformations = watch("generalInformations");
    const mobileFormData = watch();

    useEffect(() => {
        register("activityType.data");
        register("activityType.exerciseListType");
        register("questions");
    }, [register]);

    useEffect(() => {
        dispatch(activityActions.getTeacherCreateEnrollmentsRequest());
        dispatch(activityActions.getTeacherCreateQuestionsBankExerciseListsRequest());
        dispatch(activityActions.getTeacherCreateFileTypesRequest());
    }, [dispatch]);

    useEffect(() => {
        if (formExerciseListType === "custom") {
            dispatch(activityActions.getTeacherCreateSubjectsRequest());
        }
    }, [dispatch, formExerciseListType]);

    useEffect(() => {
        if (activityId) {
            dispatch(activityActions.getTeacherCreateActivityDetailsRequest(activityId));
        }
    }, [dispatch, activityId]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearTeacherCreate());
        };
    }, [dispatch]);

    useEffect(() => {
        if (currentQuestion?.isShownUndoRemoveAlternative) {
            timeoutRef.current = setTimeout(() => {
                dispatch(activityActions.setTeacherCreateShowUndoRemoveAlternativeAlert(false));
                dispatch(activityActions.setTeacherCreateIntervalToUndoRemoveAlternative(5));
            }, (currentQuestion?.undoRemoveAlternativeInSeconds ?? 0) * 1000);

            const interval = setInterval(() => {
                dispatch(activityActions.setTeacherCreateIntervalToUndoRemoveAlternative((currentQuestion?.undoRemoveAlternativeInSeconds ?? 0) - 1));
            }, 1000);

            return () => {
                if (timeoutRef.current !== null) clearTimeout(timeoutRef.current);
                clearInterval(interval);
            };
        }
    }, [dispatch, currentQuestion]);

    useEffect(() => {
        if (
            !details.isLoading &&
            !enrollments.isLoading &&
            details.data &&
            !shouldNotSaveData &&
            !!details.data?.classes?.length &&
            !formMainDataClasses.length
        ) {
            const formattedClasses = details.data.classes.map((classItem) => classItem.id);

            setValue("mainData.classes", formattedClasses, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }, [details.data, details.isLoading, enrollments.isLoading, formMainDataClasses.length, setValue, shouldNotSaveData]);

    useEffect(() => {
        if (
            !details.isLoading &&
            !questionsBankExerciseLists.isLoading &&
            details.data &&
            !shouldNotSaveData &&
            formActivityType === "exercise-list" &&
            formExerciseListType === "pre-created" &&
            details.data?.reference &&
            !formExerciseListInformationsQuestionsBankExerciseListId
        ) {
            setValue("exerciseListInformations.questionsBankExerciseListId", `${details.data?.reference}`, {
                shouldDirty: true,
                shouldValidate: true
            });
        }
    }, [
        details.data,
        details.isLoading,
        formActivityType,
        formExerciseListType,
        formExerciseListInformationsQuestionsBankExerciseListId,
        questionsBankExerciseLists.isLoading,
        setValue,
        shouldNotSaveData
    ]);

    useEffect(() => {
        if (
            !details.isLoading &&
            !fileTypes.isLoading &&
            details.data &&
            !shouldNotSaveData &&
            formActivityType === "send-file" &&
            !!details.data?.allowedFileTypes &&
            !formSendFileInformationsAllowedFileTypes.length
        ) {
            setValue(
                "sendFileInformations.allowedFileTypes",
                details.data.allowedFileTypes.map((fileType) => fileType.type),
                { shouldValidate: true, shouldDirty: true }
            );
        }
    }, [
        details.data,
        details.isLoading,
        fileTypes.isLoading,
        formActivityType,
        formSendFileInformationsAllowedFileTypes.length,
        setValue,
        shouldNotSaveData
    ]);

    useEffect(() => {
        if (!details.isLoading && details.data && !shouldNotSaveData) {
            const { type, title, enunciation, score, releaseDate, dueDate } = details.data;
            const { questions } = currentActivity?.exerciseList ?? {};
            const { exerciseListType } = activityType;

            if (type && !formActivityType) {
                const activityType = ternary([
                    [type === "ExerciseList", "exercise-list"],
                    [type === "Composition", "discursive"],
                    [type === "Upload", "send-file"]
                ]);
                setValue("activityType.data", activityType, { shouldValidate: true, shouldDirty: true });
                setValue("activityType.exerciseListType", exerciseListType, { shouldValidate: true, shouldDirty: true });
            }

            if (releaseDate && !formMainDataReleaseDate) {
                setValue("mainData.releaseDate", releaseDate, { shouldValidate: true, shouldDirty: true });
            }

            if (dueDate && !formMainDataSendDate) {
                setValue("mainData.sendDate", dueDate, { shouldValidate: true, shouldDirty: true });
            }

            if (title && !formGeneralInformations.title) {
                setValue("generalInformations.title", title, { shouldValidate: true, shouldDirty: true });
            }

            if (enunciation && !formGeneralInformations.enunciation) {
                setValue("generalInformations.enunciation", enunciation, { shouldValidate: true, shouldDirty: true });
            }

            if (score && !formGeneralInformations.score) {
                setValue("generalInformations.score", score, { shouldValidate: true, shouldDirty: true });
            }

            if (!!details.data.supportMaterials?.length && !supportMaterials.data?.length) {
                dispatch(activityActions.createTeacherCreateSupportMaterialsSuccess(details.data.supportMaterials));
            }

            if (formActivityType === "exercise-list") {
                const { releaseResult } = details.data;

                if (releaseResult !== undefined && !formExerciseListInformationsReleaseResult) {
                    setValue("exerciseListInformations.releaseResult", !!releaseResult, { shouldValidate: true, shouldDirty: true });
                }

                if (exerciseListType === "custom" && !formQuestions) {
                    setValue("questions", questions?.length ?? 0);
                }
            }

            if (formActivityType === "discursive") {
                const { wordsLimit } = details.data;

                if (wordsLimit && !formDiscursiveInformationsWordsLimit) {
                    setValue("discursiveInformations.wordsLimit", wordsLimit, { shouldValidate: true, shouldDirty: true });
                }
            }
        }
    }, [
        details.data,
        details.isLoading,
        dispatch,
        formActivityType,
        activityType,
        formDiscursiveInformationsWordsLimit,
        formExerciseListInformationsReleaseResult,
        formGeneralInformations.enunciation,
        formGeneralInformations.score,
        formGeneralInformations.title,
        formMainDataReleaseDate,
        formMainDataSendDate,
        formSendFileInformationsAllowedFileTypes.length,
        setValue,
        shouldNotSaveData,
        supportMaterials.data,
        currentActivity,
        formQuestions
    ]);

    useEffect(() => {
        if (!details.isLoading && details.data && !shouldNotSaveData) {
            const {
                type,
                title,
                enunciation,
                score,
                releaseDate,
                dueDate,
                classes,
                allowedFileTypes,
                releaseResult,
                reference,
                wordsLimit
            } = details.data;

            if (
                ((!!classes?.length && !!formMainDataClasses.length) || !classes?.length) &&
                ((!!type && !!formActivityType) || !type) &&
                ((!!releaseDate && !!formMainDataReleaseDate) || !releaseDate) &&
                ((!!dueDate && !!formMainDataSendDate) || !dueDate) &&
                ((!!title && !!formGeneralInformations.title) || !title) &&
                ((!!enunciation && !!formGeneralInformations.enunciation) || !enunciation) &&
                ((!!score && !!formGeneralInformations.score) || !score) &&
                ((!!details.data.supportMaterials?.length && !!supportMaterials.data?.length) || !details.data.supportMaterials?.length) &&
                ((formActivityType === "send-file" && !!allowedFileTypes?.length && !!formSendFileInformationsAllowedFileTypes.length) ||
                    formActivityType !== "send-file" ||
                    !allowedFileTypes) &&
                ((formActivityType === "exercise-list" && !!releaseResult && !!formExerciseListInformationsReleaseResult) ||
                    formActivityType !== "exercise-list" ||
                    !releaseResult) &&
                ((formActivityType === "exercise-list" && !!reference && !!formExerciseListInformationsQuestionsBankExerciseListId) ||
                    formActivityType !== "exercise-list" ||
                    !reference) &&
                ((formActivityType === "discursive" && !!wordsLimit && !!formDiscursiveInformationsWordsLimit) ||
                    formActivityType !== "discursive" ||
                    !wordsLimit)
            ) {
                setShouldNotSaveData(true);
            }
        }
    }, [
        details.data,
        details.isLoading,
        formActivityType,
        formDiscursiveInformationsWordsLimit,
        formExerciseListInformationsQuestionsBankExerciseListId,
        formExerciseListInformationsReleaseResult,
        formGeneralInformations.enunciation,
        formGeneralInformations.score,
        formGeneralInformations.title,
        formMainDataClasses.length,
        formMainDataReleaseDate,
        formMainDataSendDate,
        formSendFileInformationsAllowedFileTypes.length,
        shouldNotSaveData,
        supportMaterials.data
    ]);

    const { mobileStepIndex, mobileStepDescription } = useMemo(() => {
        return getMobileStepData(mobileStep);
    }, [mobileStep]);

    const handleFormCreateActivity = (data: ValidationSchema) => {
        dispatch(
            activityActions.createTeacherCreateActivityRequest({
                ...data,
                supportMaterials: supportMaterials.data,
                id: activityId,
                exerciseList: currentActivity?.exerciseList
            })
        );
    };

    const handlePreviousMobileStep = () => {
        dispatch(activityActions.setTeacherCreateMobileStep(getMobileStep(mobileStep, "previous")));
    };

    const handleNextMobileStep = () => {
        const stepError = getMobileStepError(mobileStep, mobileFormData, formActivityType);

        if (!!stepError) {
            trigger(stepError);
            return;
        }

        if (mobileStep === "activity-files-data") {
            handleFormCreateActivity(mobileFormData);
        } else {
            dispatch(activityActions.setTeacherCreateMobileStep(getMobileStep(mobileStep, "next")));
        }
    };

    const removeMainDataClass = (classId: string) => {
        const filteredClasses = formMainDataClasses.filter((classItem) => classItem !== classId);

        setValue("mainData.classes", filteredClasses);
    };

    const getSendFileInformationsAllowedFileTypes = (fileType: string) => {
        const fileTypesAlreadySelected = !!formSendFileInformationsAllowedFileTypes.find((fileTypeItem) => fileTypeItem === fileType);

        if (fileTypesAlreadySelected) {
            const filteredFileTypes = formSendFileInformationsAllowedFileTypes.filter((fileTypeItem) => fileTypeItem !== fileType);

            return filteredFileTypes;
        }

        return [...formSendFileInformationsAllowedFileTypes, fileType];
    };

    const filteredMainDataClasses = useMemo(() => {
        return (enrollments.data ?? []).filter((enrollment) => {
            const enrollmentAlreadySelected = formMainDataClasses.find((classItem) => classItem === enrollment.value);

            return !enrollmentAlreadySelected;
        });
    }, [enrollments.data, formMainDataClasses]);

    const getSelectedClassName = useCallback(
        (classeId: string) => {
            return (enrollments.data ?? []).find((enrollment) => enrollment.value === classeId)?.label ?? "";
        },
        [enrollments.data]
    );

    const handleSetFormActivityTypeValue = (data: ActivityType) => {
        setValue("activityType.data", data);

        setTimeout(() => {
            if (!!details.data && !!activityId) {
                const { title, enunciation, score } = details.data;

                if (title) {
                    setValue("generalInformations.title", title, { shouldValidate: true, shouldDirty: true });
                }

                if (enunciation) {
                    setValue("generalInformations.enunciation", enunciation, { shouldValidate: true, shouldDirty: true });
                }

                if (score) {
                    setValue("generalInformations.score", score, { shouldValidate: true, shouldDirty: true });
                }

                if (data === "send-file") {
                    const { allowedFileTypes } = details.data;

                    if (allowedFileTypes) {
                        setValue(
                            "sendFileInformations.allowedFileTypes",
                            allowedFileTypes.map((fileType) => fileType.type),
                            { shouldValidate: true, shouldDirty: true }
                        );
                    }
                }

                if (data === "discursive") {
                    const { wordsLimit } = details.data;

                    if (!!wordsLimit) {
                        setValue("discursiveInformations.wordsLimit", wordsLimit, { shouldValidate: true, shouldDirty: true });
                    }
                }
            }

            clearErrors("activityType.data");

            clearErrors("generalInformations");
            clearErrors("exerciseListInformations");
            clearErrors("sendFileInformations");
            clearErrors("discursiveInformations");
            clearErrors("questions");
        }, 200);
    };

    const handleSetExerciseListTypeValue = (data: ActivityExerciseListType) => {
        setValue("activityType.exerciseListType", data);

        setTimeout(() => {
            if (!!details.data && !!activityId) {
                const { title, enunciation, score, reference, releaseResult } = details.data;
                const { questions } = currentActivity?.exerciseList ?? {};

                if (title) {
                    setValue("generalInformations.title", title, { shouldValidate: true, shouldDirty: true });
                }

                if (enunciation) {
                    setValue("generalInformations.enunciation", enunciation, { shouldValidate: true, shouldDirty: true });
                }

                if (score) {
                    setValue("generalInformations.score", score, { shouldValidate: true, shouldDirty: true });
                }

                if (releaseResult !== undefined) {
                    setValue("exerciseListInformations.releaseResult", !!releaseResult, { shouldValidate: true, shouldDirty: true });
                }

                if (data === "pre-created") {
                    if (!!reference) {
                        setValue("exerciseListInformations.questionsBankExerciseListId", `${reference}`, { shouldValidate: true, shouldDirty: true });
                    }
                }

                if (data === "custom") {
                    if (!!questions) {
                        setValue("questions", (questions?.length ?? 0) + 1, { shouldValidate: true, shouldDirty: true });
                    }
                }
            }

            clearErrors("activityType.exerciseListType");

            clearErrors("generalInformations");
            clearErrors("exerciseListInformations");
            clearErrors("questions");
        }, 200);
    };

    const handleGoToDashboard = () => {
        history.push({
            pathname: `/app/curso/${courseSlug}/atividades`
        });
    };

    const handleCurrentQuestion = (field: string, value: any) => {
        dispatch(activityActions.setTeacherCreateCurrentQuestionSubjectAndStatement({ field, value }));
    };

    const handleCreateAlternative = () => {
        dispatch(activityActions.setTeacherCreateIsCurrentAlternativeBottomSheetOpened(true));
        dispatch(activityActions.setTeacherCreateIsCurrentAlternativeCorrectAnswer(false));
        dispatch(activityActions.setTeacherCreateCurrentQuestion());
    };

    const handleCloseCurrentQuestionModal = () => {
        dispatch(activityActions.setTeacherCreateIsCurrentQuestionModalOpened(false));
        dispatch(activityActions.clearTeacherCreateCurrentQuestion());
    };

    const handleSaveAlternative = (alternative: TeacherCreateActivityExerciseListQuestionAlternative) => {
        dispatch(activityActions.setTeacherCreateSaveAlternative(alternative));
        dispatch(activityActions.setTeacherCreateIsCurrentAlternativeBottomSheetOpened(false));
        dispatch(activityActions.setTeacherCreateIsCurrentAlternativeCorrectAnswer(false));
    };

    const handleEditAlternative = (alternative: TeacherCreateActivityExerciseListQuestionAlternative, shouldCancel = false) => {
        dispatch(activityActions.setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit(alternative));
        dispatch(activityActions.setTeacherCreateIsCurrentAlternativeCorrectAnswer(!!alternative.isCorrect));
        dispatch(activityActions.editTeacherCreateCurrentQuestionAlternative({ alternative, shouldCancel }));
    };

    const handleAlternativeCurrentQuestionChange = (value: string, alternativeIndex: any) => {
        dispatch(activityActions.setTeacherCreateCurrentAlternativeStatementChange({ value, alternativeIndex }));
    };

    const mainDataSection = (
        <ActivityFormInputsClassAndDateWrapper>
            <ClassSelectionWrapper>
                <ConditionallyRender shouldRender={!!enrollments.isLoading} content={<Skeleton height="42px" borderRadius="4px" />} />

                <ConditionallyRender
                    shouldRender={!enrollments.isLoading}
                    content={
                        <Controller
                            name="mainData.classes"
                            control={control}
                            render={({ onChange }) => (
                                <ActivitySelect
                                    label="Selecionar turma"
                                    options={filteredMainDataClasses}
                                    shouldScrollToPageEnd
                                    shouldHiddenSelectedOptionLabel
                                    handleClick={(option) => {
                                        onChange([...formMainDataClasses, option.value]);
                                    }}
                                    {...(!!formState.errors?.mainData?.classes &&
                                        !!(formState.errors?.mainData?.classes as any)?.message && {
                                            errorMessage: `${(formState.errors.mainData.classes as any).message}`
                                        })}
                                />
                            )}
                        />
                    }
                />

                <ClassSelectionInfo>
                    <ClassSelectionInfoIcon src={info} />
                    <ClassSelectionInfoLabel>Adicione uma ou mais turmas</ClassSelectionInfoLabel>
                </ClassSelectionInfo>

                <ClassSelectionChipsWrapper>
                    {formMainDataClasses.map((classItem) => (
                        <ActivityChip
                            key={classItem}
                            variant="info"
                            label={getSelectedClassName(classItem)}
                            lastIconElement={<ClassSelectionChipIcon src={close} onClick={() => removeMainDataClass(classItem)} />}
                        />
                    ))}
                </ClassSelectionChipsWrapper>
            </ClassSelectionWrapper>

            <ActivityFormInputsDatesWrapper>
                <Controller
                    name="mainData.releaseDate"
                    control={control}
                    render={({ onChange }) => (
                        <ActivityDatePicker
                            errorMessage={formState.errors?.mainData?.releaseDate?.message && formState.errors.mainData.releaseDate.message}
                            placeholder="Liberar em"
                            value={formMainDataReleaseDate}
                            isTimeEnabled
                            defaultTime={{ hour: 0, minute: 0 }}
                            onChange={([newDate]) => {
                                onChange(newDate.toISOString());
                            }}
                        />
                    )}
                />

                <Controller
                    name="mainData.sendDate"
                    control={control}
                    render={({ onChange }) => (
                        <ActivityDatePicker
                            errorMessage={formState.errors?.mainData?.sendDate?.message && formState.errors.mainData.sendDate.message}
                            placeholder="Entregar até"
                            isTimeEnabled
                            defaultTime={{ hour: 23, minute: 59 }}
                            value={formMainDataSendDate}
                            onChange={([newDate]) => {
                                onChange(newDate.toISOString());
                            }}
                        />
                    )}
                />
            </ActivityFormInputsDatesWrapper>
        </ActivityFormInputsClassAndDateWrapper>
    );

    const exerciseListType = (selectorVariant: ActivitySelectorItemProps["variant"], selectorGuidance: ActivitySelectorItemProps["guidance"]) => (
        <ActivitySelector
            itemsCount={2}
            errorMessage={formState.errors?.activityType?.exerciseListType?.message && formState.errors.activityType.exerciseListType.message}
            selectorItemsCompositions={
                <Fragment>
                    <ActivitySelectorItem
                        alignment="center"
                        isActive={formExerciseListType === "pre-created"}
                        variant={selectorVariant}
                        guidance={selectorGuidance}
                        title="Usar lista preexistente do banco de questões"
                        handleClick={(event) => {
                            event.stopPropagation();
                            handleSetExerciseListTypeValue("pre-created");
                        }}
                        headerIcon={cursorClick}
                        inputComposition={<ActivityRadio isChecked={formExerciseListType === "pre-created"} />}
                    />

                    <ActivitySelectorItem
                        alignment="center"
                        isActive={formExerciseListType === "custom"}
                        variant={selectorVariant}
                        guidance={selectorGuidance}
                        title="Quero criar uma lista com novas questões"
                        handleClick={(event) => {
                            event.stopPropagation();
                            handleSetExerciseListTypeValue("custom");
                        }}
                        headerIcon={listPlus}
                        inputComposition={<ActivityRadio isChecked={formExerciseListType === "custom"} />}
                    />
                </Fragment>
            }
        />
    );

    const activityTypeSelectorSection = (
        <ActivitySelector
            itemsCount={3}
            errorMessage={formState.errors?.activityType?.data?.message && formState.errors.activityType.data.message}
            selectorItemsCompositions={
                <Fragment>
                    <ScreenWidthRender
                        actionAfterRenderingWidth="hide"
                        renderingWidth={1024}
                        haveFullyAdaptativeWidth
                        haveFullyAdaptativeHeight
                        content={
                            <ActivitySelectorItem
                                headerIcon={activityExerciseList}
                                isActive={formActivityType === "exercise-list"}
                                title="Lista de exercícios"
                                description="Permite que o aluno responda uma lista preexistente do banco de questões. Correção automática pelo sistema."
                                handleClick={() => handleSetFormActivityTypeValue("exercise-list")}
                                {...(formActivityType === "exercise-list" && {
                                    additionalContentComposition: exerciseListType("white", "horizontal")
                                })}
                            />
                        }
                    />

                    <ScreenWidthRender
                        actionAfterRenderingWidth="show"
                        renderingWidth={1024}
                        haveFullyAdaptativeWidth
                        haveFullyAdaptativeHeight
                        content={
                            <ActivitySelectorItem
                                headerIcon={activityExerciseList}
                                isActive={formActivityType === "exercise-list"}
                                title="Lista de exercícios"
                                description="Permite que o aluno responda uma lista preexistente do banco de questões. Correção automática pelo sistema."
                                handleClick={() => handleSetFormActivityTypeValue("exercise-list")}
                            />
                        }
                    />

                    <ActivitySelectorItem
                        headerIcon={activityDiscursive}
                        isActive={formActivityType === "discursive"}
                        title="Atividade discursiva"
                        description="Permite que o aluno descreva sobre uma pergunta ou escreva uma redação. Correção manual pelo professor."
                        handleClick={() => handleSetFormActivityTypeValue("discursive")}
                    />

                    <ActivitySelectorItem
                        headerIcon={activitySendFile}
                        isActive={formActivityType === "send-file"}
                        title="Envio de arquivo"
                        description="O envio de arquivos, permite que alunos anexem um arquivo a esta atividade. Correção manual pelo professor."
                        handleClick={() => handleSetFormActivityTypeValue("send-file")}
                    />
                </Fragment>
            }
        />
    );

    const activityFormByTypeSection = (
        <Fragment>
            <ConditionallyRender
                shouldRender={formActivityType === "exercise-list" && formExerciseListType === "pre-created"}
                content={
                    <ActivityDefaultExerciseFormWrapper type={formActivityType!}>
                        <Controller
                            name="generalInformations.title"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityInput
                                    label="Título da atividade"
                                    placeholder="Título da atividade"
                                    value={formGeneralInformations.title}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.title?.message && formState.errors.generalInformations.title.message
                                    }
                                />
                            )}
                        />

                        <ConditionallyRender
                            shouldRender={!!questionsBankExerciseLists.isLoading}
                            content={<Skeleton height="42px" borderRadius="4px" />}
                        />

                        <ConditionallyRender
                            shouldRender={!questionsBankExerciseLists.isLoading}
                            content={
                                <Controller
                                    name="exerciseListInformations.questionsBankExerciseListId"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivitySelect
                                            label="Selecionar lista"
                                            options={questionsBankExerciseLists.data?.map((list) => ({ ...list, value: `${list?.value}` })) ?? []}
                                            defaultValue={`${formExerciseListInformationsQuestionsBankExerciseListId}`}
                                            shouldScrollToPageEnd
                                            errorMessage={
                                                formState.errors?.exerciseListInformations?.questionsBankExerciseListId?.message &&
                                                formState.errors.exerciseListInformations.questionsBankExerciseListId.message
                                            }
                                            handleClick={(option) => {
                                                onChange(option.value);
                                            }}
                                        />
                                    )}
                                />
                            }
                        />

                        <Controller
                            name="generalInformations.enunciation"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityTextarea
                                    placeholder="Enunciado para alunos"
                                    label="Enunciado para alunos"
                                    value={formGeneralInformations.enunciation}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.enunciation?.message &&
                                        formState.errors.generalInformations.enunciation.message
                                    }
                                />
                            )}
                        />

                        <ActivityInputCard
                            title="Liberar resultado"
                            additionalTitleLabel="(Opcional)"
                            description="Permite que o aluno veja o resultado antes do prazo de entrega"
                            errorMessage={
                                formState.errors?.exerciseListInformations?.releaseResult?.message &&
                                formState.errors.exerciseListInformations.releaseResult.message
                            }
                            inputComposition={
                                <Controller
                                    name="exerciseListInformations.releaseResult"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivityToggle
                                            isActive={formExerciseListInformationsReleaseResult}
                                            handleClick={() => onChange(!formExerciseListInformationsReleaseResult)}
                                        />
                                    )}
                                />
                            }
                        />

                        <ActivityInputCard
                            title="Pontuação"
                            description="Defina a quantidade de pontos dessa atividade"
                            errorMessage={formState.errors?.generalInformations?.score?.message && formState.errors.generalInformations.score.message}
                            inputComposition={
                                <Controller
                                    name="generalInformations.score"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivityInput
                                            width={56}
                                            type="number"
                                            variant="neutral"
                                            placeholder="00"
                                            inputMode="numeric"
                                            value={formGeneralInformations.score}
                                            onChange={(event) => onChange(!!event.target.value.length ? Number(event.target.value) : undefined)}
                                        />
                                    )}
                                />
                            }
                        />
                    </ActivityDefaultExerciseFormWrapper>
                }
            />

            <ConditionallyRender
                shouldRender={formActivityType === "exercise-list" && formExerciseListType === "custom"}
                content={
                    <ActivityCustomExerciseFormWrapper>
                        <Controller
                            name="generalInformations.title"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityInput
                                    label="Título da atividade"
                                    placeholder="Título da atividade"
                                    value={formGeneralInformations.title}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.title?.message && formState.errors.generalInformations.title.message
                                    }
                                />
                            )}
                        />

                        <Controller
                            name="generalInformations.enunciation"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityTextarea
                                    placeholder="Enunciado para alunos"
                                    label="Enunciado para alunos"
                                    defaultValue={formGeneralInformations?.enunciation}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.enunciation?.message &&
                                        formState.errors.generalInformations.enunciation.message
                                    }
                                />
                            )}
                        />

                        <ActivityCustomExerciseQuestionsBoard>
                            <ActivityHeader
                                title="Questões"
                                titleVariant="default"
                                actionButtonElement={
                                    <ActivityButton
                                        label="Nova questão"
                                        type="button"
                                        size="small"
                                        onClick={() => dispatch(activityActions.setTeacherCreateIsCurrentQuestionModalOpened(true))}
                                    />
                                }
                            />

                            <ConditionallyRender
                                shouldRender={!currentActivity?.exerciseList?.questions?.length}
                                content={<ActivityEmptyState content="Nenhuma questão adicionada" />}
                            />

                            {formState.errors.questions?.message && (
                                <QuestionsErrorMessageWrapper>
                                    <ErrorMessageIcon src={fail} />

                                    <ErrorMessageLabel>{formState.errors.questions?.message}</ErrorMessageLabel>
                                </QuestionsErrorMessageWrapper>
                            )}

                            <ConditionallyRender
                                shouldRender={!!currentActivity?.exerciseList?.questions?.length}
                                content={
                                    <ActivityCustomExerciseQuestionsAccordionWrapper>
                                        {currentActivity?.exerciseList?.questions?.map((question, index) => (
                                            <ActivityAccordion
                                                key={`activity-question-${index + 1}`}
                                                indicator={(index + 1).toString().padStart(2, "0")}
                                                headerLabel={
                                                    (question.subject?.label ??
                                                        (subjects.data ?? []).find((subject) => subject.value === question.subject?.value)?.label)!
                                                }
                                                isOpened={currentQuestionAccordion?.order === question?.order}
                                                title={question.statement!}
                                                hasBorderTop={index === 0}
                                                handleClick={() =>
                                                    dispatch(
                                                        activityActions.setTeacherCreateIsCurrentAlternativeAccordionOpened(
                                                            currentQuestionAccordion?.order === question?.order ? undefined : question
                                                        )
                                                    )
                                                }
                                                actionButtonCompositions={
                                                    <Fragment>
                                                        <ScreenWidthRender
                                                            actionAfterRenderingWidth="hide"
                                                            renderingWidth={768}
                                                            content={
                                                                <ActivityAlternativeActionsWrapper>
                                                                    <ActivityAlternativeActionIcon
                                                                        src={bluePencil}
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                activityActions.setTeacherCreateIsCurrentQuestionModalOpened(true)
                                                                            );
                                                                            dispatch(activityActions.editTeacherCreateCurrentQuestion(question));
                                                                        }}
                                                                    />

                                                                    <ActivityAlternativeActionIcon
                                                                        src={blueTrash}
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                activityActions.deleteTeacherCreateCurrentQuestion(question.order)
                                                                            )
                                                                        }
                                                                    />
                                                                </ActivityAlternativeActionsWrapper>
                                                            }
                                                        />

                                                        <ScreenWidthRender
                                                            actionAfterRenderingWidth="show"
                                                            renderingWidth={768}
                                                            content={
                                                                <ActivityAlternativeActionsWrapper>
                                                                    <ActivityDefaultActionButton
                                                                        color="#3E68D2"
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                activityActions.setTeacherCreateIsCurrentQuestionModalOpened(true)
                                                                            );
                                                                            dispatch(activityActions.editTeacherCreateCurrentQuestion(question));
                                                                        }}
                                                                    >
                                                                        Editar
                                                                    </ActivityDefaultActionButton>

                                                                    <ActivityDefaultActionButton
                                                                        color="#3E68D2"
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                activityActions.deleteTeacherCreateCurrentQuestion(question.order)
                                                                            );
                                                                            setValue(
                                                                                "questions",
                                                                                (currentActivity?.exerciseList?.questions?.length ?? 0) - 1
                                                                            );
                                                                            clearErrors("questions");
                                                                        }}
                                                                    >
                                                                        Remover
                                                                    </ActivityDefaultActionButton>
                                                                </ActivityAlternativeActionsWrapper>
                                                            }
                                                        />
                                                    </Fragment>
                                                }
                                                openedContentComposition={
                                                    <ActivityQuestions
                                                        questionOptionCompositions={
                                                            <Fragment>
                                                                {question.alternatives?.map((alternative, index) => (
                                                                    <ActivityQuestionOption
                                                                        key={`activity-question-alternative-${index}`}
                                                                        label={alternative.statement}
                                                                        questionIndicatorElement={
                                                                            <ActivityQuestionIndicator
                                                                                text={alternative.order?.toString()!}
                                                                                variant={alternative.isCorrect ? "success" : "default"}
                                                                            />
                                                                        }
                                                                    />
                                                                ))}
                                                            </Fragment>
                                                        }
                                                    />
                                                }
                                            />
                                        ))}
                                    </ActivityCustomExerciseQuestionsAccordionWrapper>
                                }
                            />
                        </ActivityCustomExerciseQuestionsBoard>

                        <ActivityInputCardComposition
                            title="Extras"
                            inputCardCompositions={
                                <Fragment>
                                    <ActivityInputCard
                                        title="Liberar resultado"
                                        additionalTitleLabel="(Opcional)"
                                        description="Permite que o aluno veja o resultado antes do prazo de entrega"
                                        errorMessage={
                                            formState.errors?.exerciseListInformations?.releaseResult?.message &&
                                            formState.errors.exerciseListInformations.releaseResult.message
                                        }
                                        inputComposition={
                                            <Controller
                                                name="exerciseListInformations.releaseResult"
                                                control={control}
                                                render={({ onChange }) => (
                                                    <ActivityToggle
                                                        isActive={formExerciseListInformationsReleaseResult}
                                                        handleClick={() => onChange(!formExerciseListInformationsReleaseResult)}
                                                    />
                                                )}
                                            />
                                        }
                                    />

                                    <ActivityInputCard
                                        title="Pontuação"
                                        description="Defina a quantidade de pontos dessa atividade"
                                        errorMessage={
                                            formState.errors?.generalInformations?.score?.message &&
                                            formState.errors.generalInformations.score.message
                                        }
                                        inputComposition={
                                            <Controller
                                                name="generalInformations.score"
                                                control={control}
                                                render={({ onChange }) => (
                                                    <ActivityInput
                                                        width={56}
                                                        type="number"
                                                        variant="neutral"
                                                        placeholder="00"
                                                        inputMode="numeric"
                                                        value={formGeneralInformations.score}
                                                        onChange={(event) =>
                                                            onChange(!!event.target.value.length ? Number(event.target.value) : undefined)
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                </Fragment>
                            }
                        />
                    </ActivityCustomExerciseFormWrapper>
                }
            />

            <ConditionallyRender
                shouldRender={formActivityType === "discursive"}
                content={
                    <ActivityDefaultExerciseFormWrapper type={formActivityType!}>
                        <Controller
                            name="generalInformations.title"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityInput
                                    label="Título da atividade"
                                    placeholder="Título da atividade"
                                    value={formGeneralInformations.title}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.title?.message && formState.errors.generalInformations.title.message
                                    }
                                />
                            )}
                        />

                        <Controller
                            name="generalInformations.enunciation"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityTextarea
                                    placeholder="Enunciado para alunos"
                                    label="Enunciado para alunos"
                                    value={formGeneralInformations.enunciation}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.enunciation?.message &&
                                        formState.errors.generalInformations.enunciation.message
                                    }
                                />
                            )}
                        />

                        <ActivityInputCard
                            title="Limite de palavras"
                            additionalTitleLabel="(Opcional)"
                            description="Defina uma quantidade máxima de palavras."
                            errorMessage={
                                formState.errors?.discursiveInformations?.wordsLimit?.message &&
                                formState.errors.discursiveInformations.wordsLimit.message
                            }
                            inputComposition={
                                <Controller
                                    name="discursiveInformations.wordsLimit"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivityInput
                                            width={64}
                                            type="number"
                                            variant="neutral"
                                            placeholder="000"
                                            value={formDiscursiveInformationsWordsLimit}
                                            inputMode="numeric"
                                            onChange={(event) => onChange(!!event.target.value.length ? Number(event.target.value) : undefined)}
                                        />
                                    )}
                                />
                            }
                        />

                        <ActivityInputCard
                            title="Pontuação"
                            description="Defina a quantidade de pontos dessa atividade"
                            errorMessage={formState.errors?.generalInformations?.score?.message && formState.errors.generalInformations.score.message}
                            inputComposition={
                                <Controller
                                    name="generalInformations.score"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivityInput
                                            width={56}
                                            type="number"
                                            variant="neutral"
                                            placeholder="00"
                                            inputMode="numeric"
                                            value={formGeneralInformations.score}
                                            onChange={(event) => onChange(!!event.target.value.length ? Number(event.target.value) : undefined)}
                                        />
                                    )}
                                />
                            }
                        />
                    </ActivityDefaultExerciseFormWrapper>
                }
            />

            <ConditionallyRender
                shouldRender={formActivityType === "send-file"}
                content={
                    <ActivityDefaultExerciseFormWrapper type={formActivityType!}>
                        <Controller
                            name="generalInformations.title"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityInput
                                    label="Título da atividade"
                                    placeholder="Título da atividade"
                                    value={formGeneralInformations.title}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.title?.message && formState.errors.generalInformations.title.message
                                    }
                                />
                            )}
                        />

                        <Controller
                            name="generalInformations.enunciation"
                            control={control}
                            render={({ onChange }) => (
                                <ActivityTextarea
                                    placeholder="Enunciado para alunos"
                                    label="Enunciado para alunos"
                                    value={formGeneralInformations.enunciation}
                                    onChange={(event) => onChange(event.target.value)}
                                    errorMessage={
                                        formState.errors?.generalInformations?.enunciation?.message &&
                                        formState.errors.generalInformations.enunciation.message
                                    }
                                />
                            )}
                        />

                        <ActivityInputCard
                            title="Selecione quais os tipos de arquivo o aluno pode enviar"
                            {...(!!formState.errors?.sendFileInformations?.allowedFileTypes &&
                                !!(formState.errors?.sendFileInformations?.allowedFileTypes as any)?.message && {
                                    errorMessage: `${(formState.errors?.sendFileInformations?.allowedFileTypes as any).message}`
                                })}
                            underTitleAdditionalContentComposition={
                                <AdditionalContentWrapper>
                                    <ConditionallyRender
                                        shouldRender={fileTypes.isLoading}
                                        content={
                                            <Fragment>
                                                <Skeleton height="20px" width="80px" borderRadius="4px" />
                                                <Skeleton height="20px" width="80px" borderRadius="4px" />
                                                <Skeleton height="20px" width="80px" borderRadius="4px" />
                                                <Skeleton height="20px" width="80px" borderRadius="4px" />
                                            </Fragment>
                                        }
                                    />

                                    <ConditionallyRender
                                        shouldRender={!fileTypes.isLoading}
                                        content={
                                            <Controller
                                                name="sendFileInformations.allowedFileTypes"
                                                control={control}
                                                render={({ onChange }) => (
                                                    <Fragment>
                                                        {fileTypes.data?.map((fileType) => {
                                                            const isChecked = !!formSendFileInformationsAllowedFileTypes.find(
                                                                (fileTypeItem) => fileTypeItem === fileType.type
                                                            );

                                                            return (
                                                                <ActivityCheckbox
                                                                    key={fileType.type}
                                                                    label={fileType.name}
                                                                    isChecked={isChecked}
                                                                    handleClick={() =>
                                                                        onChange(getSendFileInformationsAllowedFileTypes(fileType.type))
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Fragment>
                                                )}
                                            />
                                        }
                                    />
                                </AdditionalContentWrapper>
                            }
                        />

                        <ActivityInputCard
                            title="Pontuação"
                            description="Defina a quantidade de pontos dessa atividade"
                            errorMessage={formState.errors?.generalInformations?.score?.message && formState.errors.generalInformations.score.message}
                            inputComposition={
                                <Controller
                                    name="generalInformations.score"
                                    control={control}
                                    render={({ onChange }) => (
                                        <ActivityInput
                                            width={56}
                                            type="number"
                                            variant="neutral"
                                            placeholder="00"
                                            inputMode="numeric"
                                            value={formGeneralInformations.score}
                                            onChange={(event) => onChange(!!event.target.value.length ? Number(event.target.value) : undefined)}
                                        />
                                    )}
                                />
                            }
                        />
                    </ActivityDefaultExerciseFormWrapper>
                }
            />
        </Fragment>
    );

    const supportMaterialsContent = (
        <ActivityFilesWrapper>
            {supportMaterials.data?.map((supportMaterial) => (
                <ActivityFile
                    key={supportMaterial.id}
                    label={supportMaterial.name}
                    isReadyToDownload={supportMaterial.progress === 100 || !!supportMaterial.source}
                    handleClickLabel={() => window.open(`${!!supportMaterial.source && supportMaterial.source}`, "_blank")}
                    progressBarElement={
                        <ActivityProgressBar
                            progress={supportMaterial.progress ?? 100}
                            progressColor={supportMaterial.progress === 100 || !supportMaterial.progress ? "#1BA660" : "#FFBE62"}
                        />
                    }
                    actionElement={
                        <Fragment>
                            <ConditionallyRender
                                shouldRender={supportMaterial.progress !== 100 && !supportMaterial.source}
                                content={
                                    <ActivityDefaultActionButton
                                        color="#e40000"
                                        onClick={() => dispatch(activityActions.cancelTeacherCreateSupportMaterialUpload(supportMaterial.name))}
                                    >
                                        Cancelar
                                    </ActivityDefaultActionButton>
                                }
                            />
                            <ConditionallyRender
                                shouldRender={supportMaterial.progress === 100 || !!supportMaterial.source}
                                content={
                                    <ActivityDefaultRemoveIcon
                                        src={trash}
                                        onClick={() => dispatch(activityActions.deleteTeacherCreateSupportMaterialRequest(supportMaterial.id))}
                                    />
                                }
                            />
                        </Fragment>
                    }
                />
            ))}
        </ActivityFilesWrapper>
    );

    const activitySupportFilesMobile = (
        <Fragment>
            <MobileFileUploadContentWrapper>
                <MobileFileUploadContentTitle>Carregue arquivos clicando no botão abaixo</MobileFileUploadContentTitle>

                <FileInput
                    maximumFilesSize={20}
                    onFileUpload={(files) => dispatch(activityActions.createTeacherCreateSupportMaterialsRequest(files))}
                    allowedFileTypes={[
                        "pdf",
                        "doc",
                        "docx",
                        "ppt",
                        "pptx",
                        "jpg",
                        "jpeg",
                        "bpm",
                        "png",
                        "mp3",
                        "ogg",
                        "aac",
                        "wav",
                        "mp4",
                        "avi",
                        "mov",
                        "wmv",
                        "mpeg"
                    ]}
                    handleError={() =>
                        dispatch(
                            alertActions.showAlert(
                                "Erro ao carregar arquivo. O tamanho máximo permitido para os arquivos anexados é de 20MB.",
                                "danger"
                            )
                        )
                    }
                    content={<ActivityButton label="Anexar arquivo" size="small" type="button" />}
                />
            </MobileFileUploadContentWrapper>

            {supportMaterialsContent}
        </Fragment>
    );

    const alternativeSubjectsSelect = (type: ActivitySelectBottomSheetOptionsType) => (
        <ActivitySelect
            label="Matéria"
            bottomSheetPlaceholder="Selecione uma matéria"
            bottomSheetOptionsType={type}
            options={subjects.data ?? []}
            defaultValue={currentQuestion?.subject?.value}
            shouldScrollToPageEnd
            handleClick={(option) =>
                handleCurrentQuestion(
                    "subject",
                    (subjects.data ?? []).find((subject) => subject.value === option.value)
                )
            }
        />
    );

    const editorCompositionMobile = (
        <ActivityEditor
            value={currentQuestion?.currentAlternative?.statement ?? ""}
            handleChange={(value) =>
                handleAlternativeCurrentQuestionChange(
                    value,
                    currentQuestion?.alternatives?.findIndex((item) => item.order === currentQuestion?.currentAlternative?.order)
                )
            }
        />
    );

    return (
        <Fragment>
            <ConditionallyRender
                shouldRender={!!supportMaterials.isLoading || (!!activityId && !!details.isLoading)}
                content={<ActivityOverlay content={<Loader />} />}
            />

            <ActivityPartialModal
                isOpened={!!currentQuestion?.isCurrentQuestionModalOpened}
                width={720}
                title="Nova questão"
                {...(width >= 768 && { handleOutsideClick: handleCloseCurrentQuestionModal })}
                content={
                    <ActivityPartialModalCurrentQuestionWrapper>
                        <ActivityPartialModalCurrentQuestionContent>
                            <ConditionallyRender shouldRender={!!false} content={<Skeleton height="42px" borderRadius="4px" />} />

                            <ConditionallyRender
                                shouldRender={!false}
                                content={
                                    <Fragment>
                                        <ScreenWidthRender
                                            haveFullyAdaptativeWidth
                                            actionAfterRenderingWidth="hide"
                                            renderingWidth={768}
                                            content={alternativeSubjectsSelect("list")}
                                        />

                                        <ScreenWidthRender
                                            haveFullyAdaptativeWidth
                                            actionAfterRenderingWidth="show"
                                            renderingWidth={768}
                                            content={alternativeSubjectsSelect("default")}
                                        />
                                    </Fragment>
                                }
                            />

                            <ActivityEditor
                                placeholder="Enunciado da questão"
                                value={currentQuestion?.statement ?? ""}
                                handleChange={(value) => handleCurrentQuestion("statement", value)}
                            />

                            <ActivityPartialModalSeparator />

                            <ActivityHeader
                                title="Alternativas"
                                description="Adicione alternativas e indique a correta."
                                titleVariant="small"
                                actionButtonElement={<ActivityButton label="Adicionar" size="small" onClick={handleCreateAlternative} />}
                            />

                            <ConditionallyRender
                                shouldRender={!currentQuestion?.alternatives?.length}
                                content={<ActivityEmptyState content="Nenhuma alternativa foi criada" />}
                            />

                            <ConditionallyRender
                                shouldRender={!!currentQuestion?.alternatives?.length}
                                content={
                                    <ActivityQuestions
                                        questionOptionCompositions={currentQuestion?.alternatives?.map((alternative, alternativeIndex) => {
                                            const actionButtonCompositions = ternary([
                                                [
                                                    !!alternative.isEditing && width >= 768,
                                                    <Fragment key="activity-question-editing-options">
                                                        <ActivityDefaultActionButton
                                                            color="#3E68D2"
                                                            onClick={() => handleSaveAlternative(alternative)}
                                                        >
                                                            Salvar
                                                        </ActivityDefaultActionButton>
                                                        <ActivityDefaultActionButton
                                                            color="#3E68D2"
                                                            onClick={() => {
                                                                // handleEditAlternative(alternative, true);
                                                                dispatch(
                                                                    activityActions.cancelTeacherCreateCurrentQuestionAlternativeEdit(alternative)
                                                                );
                                                            }}
                                                        >
                                                            Cancelar
                                                        </ActivityDefaultActionButton>
                                                    </Fragment>
                                                ],
                                                [
                                                    !alternative.isEditing,
                                                    <Fragment key="activity-question-default-options">
                                                        <ScreenWidthRender
                                                            actionAfterRenderingWidth="hide"
                                                            renderingWidth={768}
                                                            content={
                                                                <ActivityAlternativeActionsWrapper>
                                                                    <ActivityAlternativeActionIcon
                                                                        src={blueTrash}
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                activityActions.deleteTeacherCreateCurrentQuestionAlternative(
                                                                                    alternative
                                                                                )
                                                                            )
                                                                        }
                                                                    />

                                                                    <ActivityAlternativeActionIcon
                                                                        src={bluePencil}
                                                                        onClick={() => {
                                                                            // handleEditAlternative(alternative);
                                                                            dispatch(
                                                                                activityActions.setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit(
                                                                                    alternative
                                                                                )
                                                                            );

                                                                            dispatch(
                                                                                activityActions.setTeacherCreateIsCurrentAlternativeBottomSheetOpened(
                                                                                    true
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                </ActivityAlternativeActionsWrapper>
                                                            }
                                                        />

                                                        <ScreenWidthRender
                                                            actionAfterRenderingWidth="show"
                                                            renderingWidth={768}
                                                            content={
                                                                <ActivityAlternativeActionsWrapper>
                                                                    <ActivityDefaultActionButton
                                                                        color="#3E68D2"
                                                                        onClick={() => handleEditAlternative(alternative)}
                                                                    >
                                                                        Editar
                                                                    </ActivityDefaultActionButton>
                                                                    <ActivityDefaultActionButton
                                                                        color="#3E68D2"
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                activityActions.deleteTeacherCreateCurrentQuestionAlternative(
                                                                                    alternative
                                                                                )
                                                                            );
                                                                            dispatch(
                                                                                activityActions.setTeacherCreateShowUndoRemoveAlternativeAlert(true)
                                                                            );
                                                                        }}
                                                                    >
                                                                        Remover
                                                                    </ActivityDefaultActionButton>
                                                                </ActivityAlternativeActionsWrapper>
                                                            }
                                                        />
                                                    </Fragment>
                                                ],
                                                [true, <Fragment key="activity-question-default-options" />]
                                            ]);

                                            const editorComposition = (
                                                <ActivityEditor
                                                    value={alternative.statement ?? ""}
                                                    handleChange={(value) => handleAlternativeCurrentQuestionChange(value, alternativeIndex)}
                                                />
                                            );

                                            const questionOptionRestProps = ternary([
                                                [
                                                    !!alternative.isEditing && width >= 768,
                                                    {
                                                        actionButtonAlignment: "under",
                                                        contentComposition: editorComposition
                                                    }
                                                ],
                                                [!alternative.isEditing, { label: alternative.statement }],
                                                [true, {}]
                                            ]);

                                            return (
                                                <Fragment key={`activity-question-alternative-${alternative.order}`}>
                                                    <ConditionallyRender
                                                        shouldRender={!!currentQuestion?.isCurrentAlternativeBottomSheetOpened}
                                                        content={
                                                            <ScreenWidthRender
                                                                actionAfterRenderingWidth="hide"
                                                                renderingWidth={768}
                                                                content={
                                                                    <ActivityBottomSheet
                                                                        label="Alternativa"
                                                                        handleOutsideClick={() => {
                                                                            dispatch(
                                                                                activityActions.cancelTeacherCreateCurrentQuestionAlternativeEdit(
                                                                                    alternative
                                                                                )
                                                                            );
                                                                            dispatch(
                                                                                activityActions.setTeacherCreateIsCurrentAlternativeBottomSheetOpened(
                                                                                    false
                                                                                )
                                                                            );
                                                                        }}
                                                                        content={
                                                                            <ActivityQuestionAlternativeBottomSheetContent>
                                                                                {editorCompositionMobile}

                                                                                <ActivityCheckbox
                                                                                    label="Essa é a alternativa correta"
                                                                                    isChecked={!!currentQuestion?.isCurrentAlternativeCorrectAnswer}
                                                                                    handleClick={() =>
                                                                                        dispatch(
                                                                                            activityActions.setTeacherCreateIsCurrentAlternativeCorrectAnswer(
                                                                                                !currentQuestion?.isCurrentAlternativeCorrectAnswer
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <ActivityButton
                                                                                    isFullyAdaptative
                                                                                    size="large"
                                                                                    label="Salvar"
                                                                                    onClick={() => handleSaveAlternative(alternative)}
                                                                                />
                                                                            </ActivityQuestionAlternativeBottomSheetContent>
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    />

                                                    <ActivityQuestionOption
                                                        questionIndicatorElement={<ActivityQuestionIndicator text={alternative.order?.toString()!} />}
                                                        actionButtonCompositions={actionButtonCompositions}
                                                        radioComposition={
                                                            <ActivityRadio
                                                                variant={alternative.isCorrect ? "success" : "default"}
                                                                hasCheckIcon
                                                                isChecked={alternative.isCorrect}
                                                                handleClick={() =>
                                                                    dispatch(
                                                                        activityActions.setTeacherCreateCurrentQuestionCorrectAlternative({
                                                                            alternative
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        {...(questionOptionRestProps as any)}
                                                    />
                                                </Fragment>
                                            );
                                        })}
                                    />
                                }
                            />
                        </ActivityPartialModalCurrentQuestionContent>

                        <ActivityPartialModalFooterWrapper>
                            <ConditionallyRender
                                shouldRender={!!currentQuestion?.isShownUndoRemoveAlternative}
                                content={
                                    <ActivityAlert
                                        firstLabel="Uma alternativa foi removida"
                                        secondLabel={`${currentQuestion?.undoRemoveAlternativeInSeconds}s`}
                                        actionButtonComposition={
                                            <ActivityButton
                                                label="Desfazer"
                                                variant="danger"
                                                size="small"
                                                onClick={() => {
                                                    dispatch(activityActions.undoTeacherCreateRemoveAlternative());
                                                    dispatch(activityActions.setTeacherCreateShowUndoRemoveAlternativeAlert(false));
                                                    dispatch(activityActions.setTeacherCreateIntervalToUndoRemoveAlternative(5));
                                                }}
                                            />
                                        }
                                    />
                                }
                            />

                            <ActivityPartialModalButtonsWrapper>
                                <ActivityButton
                                    label="Cancelar"
                                    size="large"
                                    isFullyAdaptative={width < 1024}
                                    variantType="secondary"
                                    onClick={handleCloseCurrentQuestionModal}
                                />
                                <ActivityButton
                                    label={`${currentQuestion?.isEditing ? "Salvar questão" : "Criar questão"}`}
                                    isFullyAdaptative={width < 1024}
                                    isDisabled={
                                        !currentQuestion?.subject ||
                                        !currentQuestion?.statement ||
                                        (currentQuestion?.alternatives?.length ?? 0) < 2 ||
                                        !currentQuestion?.alternatives?.some((alternative) => alternative.isCorrect) ||
                                        currentQuestion?.alternatives?.some((alternative) => alternative.statement?.length === 0)
                                    }
                                    size="large"
                                    onClick={() => {
                                        dispatch(activityActions.setTeacherCreateSaveCurrentQuestion(currentQuestion!));
                                        dispatch(activityActions.setTeacherCreateIsCurrentQuestionModalOpened(false));
                                        dispatch(activityActions.clearTeacherCreateCurrentQuestion());
                                        setValue("questions", (currentActivity?.exerciseList?.questions?.length ?? 0) + 1);
                                        clearErrors("questions");
                                    }}
                                />
                            </ActivityPartialModalButtonsWrapper>
                        </ActivityPartialModalFooterWrapper>
                    </ActivityPartialModalCurrentQuestionWrapper>
                }
            />

            <ScreenWidthRender
                actionAfterRenderingWidth="hide"
                renderingWidth={1024}
                haveFullyAdaptativeWidth
                haveFullyAdaptativeHeight
                content={
                    <ActivityStepForm
                        currentStepIndex={mobileStepIndex}
                        title={`${!activityId ? "Nova" : "Editar"} atividade`}
                        description={mobileStepDescription}
                        headerActionElement={
                            <ConditionallyRender
                                shouldRender={!isCreating}
                                content={
                                    <ActivityDefaultActionButton color="#232326" onClick={handleGoToDashboard}>
                                        Cancelar
                                    </ActivityDefaultActionButton>
                                }
                            />
                        }
                        formCompositions={[
                            <ActivityStepFormItem key={1} content={mainDataSection} />,
                            <ActivityStepFormItem key={2} content={activityTypeSelectorSection} />,
                            <ActivityStepFormItem key={3} content={activityFormByTypeSection} />,
                            <ActivityStepFormItem key={4} content={activitySupportFilesMobile} />
                        ]}
                        footerButtonCompositions={
                            <Fragment>
                                <ConditionallyRender
                                    shouldRender={mobileStep !== "activity-class-data" && !isCreating}
                                    content={
                                        <ActivityButton
                                            label="Voltar"
                                            isFullyAdaptative
                                            size="large"
                                            variantType="secondary"
                                            onClick={handlePreviousMobileStep}
                                        />
                                    }
                                />

                                <ActivityButton
                                    label={mobileStep !== "activity-files-data" ? "Próximo" : `${!activityId ? "Criar" : "Salvar"} atividade`}
                                    isFullyAdaptative
                                    isLoading={mobileStep === "activity-files-data" && isCreating}
                                    size="large"
                                    onClick={handleNextMobileStep}
                                />
                            </Fragment>
                        }
                    />
                }
            />

            <ScreenWidthRender
                actionAfterRenderingWidth="show"
                renderingWidth={1024}
                content={
                    <ActivityBoard
                        sectionTitle={`${!activityId ? "Nova" : "Editar"} atividade`}
                        breadcrumbComposition={
                            <ActivityBreadcrumbs
                                breadcrumbItemCompositions={[
                                    <ActivityBreadcrumbItem
                                        key={1}
                                        label="Voltar"
                                        icon={arrowLeft}
                                        size="small"
                                        isActive={false}
                                        handleClick={handleGoToDashboard}
                                    />
                                ]}
                            />
                        }
                        activitiesContentCompositions={
                            <ActivityForm onSubmit={handleSubmit(handleFormCreateActivity)}>
                                <ActivityFormItem
                                    headerComposition={<ActivityHeader title="Turma e data" titleVariant="large" />}
                                    content={mainDataSection}
                                />

                                <ActivityFormItem
                                    headerComposition={
                                        <ActivityHeader
                                            title="Tipo de atividade"
                                            description="Selecione uma das atividades abaixo para preencher informações adicionais"
                                            titleVariant="large"
                                        />
                                    }
                                    content={activityTypeSelectorSection}
                                />

                                <ConditionallyRender
                                    shouldRender={formActivityType === "exercise-list"}
                                    content={
                                        <ActivityFormItem
                                            headerComposition={<ActivityHeader title="Escolha das questões" titleVariant="large" />}
                                            content={exerciseListType("neutral", "horizontal")}
                                        />
                                    }
                                />

                                <ConditionallyRender
                                    shouldRender={!!formActivityType}
                                    content={
                                        <ActivityFormItem
                                            headerComposition={<ActivityHeader title="Informações da atividade" titleVariant="large" />}
                                            content={activityFormByTypeSection}
                                        />
                                    }
                                />

                                <ActivityFormItem
                                    headerComposition={
                                        <ActivityHeader
                                            title="Material de apoio"
                                            description="Adicione conteúdo extra para dar contexto aos alunos (opcional)"
                                            titleVariant="large"
                                            actionButtonElement={
                                                <FileInput
                                                    maximumFilesSize={20}
                                                    allowedFileTypes={[
                                                        "pdf",
                                                        "doc",
                                                        "docx",
                                                        "ppt",
                                                        "pptx",
                                                        "jpg",
                                                        "jpeg",
                                                        "bpm",
                                                        "png",
                                                        "mp3",
                                                        "ogg",
                                                        "aac",
                                                        "wav",
                                                        "mp4",
                                                        "avi",
                                                        "mov",
                                                        "wmv",
                                                        "mpeg"
                                                    ]}
                                                    onFileUpload={(files) =>
                                                        dispatch(activityActions.createTeacherCreateSupportMaterialsRequest(files))
                                                    }
                                                    handleError={() =>
                                                        dispatch(
                                                            alertActions.showAlert(
                                                                "Erro ao carregar arquivo. O tamanho máximo permitido para os arquivos anexados é de 20MB.",
                                                                "danger"
                                                            )
                                                        )
                                                    }
                                                    content={<ActivityButton label="Anexar arquivo" size="small" type="button" />}
                                                />
                                            }
                                        />
                                    }
                                    {...(!!supportMaterials.data?.length && {
                                        content: supportMaterialsContent
                                    })}
                                />

                                <ActivityFormActionButtonsWrapper>
                                    <ActivityButton
                                        label={`${!activityId ? "Criar" : "Salvar"} atividade`}
                                        size="large"
                                        type="submit"
                                        isLoading={isCreating}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!isCreating}
                                        content={
                                            <ActivityButton label="Cancelar" size="large" variantType="secondary" onClick={handleGoToDashboard} />
                                        }
                                    />
                                </ActivityFormActionButtonsWrapper>
                            </ActivityForm>
                        }
                    />
                }
            />
        </Fragment>
    );
};
