import { menuColors, theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { RadioGroupContainer } from "components/RadioGroup/styles";
import { FilterGroupContainer } from "components/FilterGroup/styles";
import { CardContainerSmallStyle, TitleH5 } from "assets/styles/global";
import { Card } from "screens/Projects/Home/components/Card/styles";

export const ForumHomeContainer = styled.section`
    padding-bottom: ${theme.spacing.medium};

    ${MediaQueries.SMALL} {
        ${FilterGroupContainer} {
            margin-bottom: ${theme.spacing.small};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${FilterGroupContainer} {
            padding: 0;
        }

        ${RadioGroupContainer} {
            border-bottom: 1px solid ${theme.colors.base[100]};
            margin-bottom: ${theme.spacing.medium};
            padding-bottom: ${theme.spacing.medium};
        }
    }
`;

export const ForumCol = styled.div`
    ${CardContainerSmallStyle};
    padding: ${theme.spacing.medium};

    ${MediaQueries.SMALL} {
        padding: ${theme.spacing.small};
    }
`;

export const ForumRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const ForumContent = styled.div`
    margin-bottom: ${theme.spacing.medium};
`;

export const ForumContentTitle = styled(TitleH5)`
    color: ${theme.colors.base[400]};
`;

export const ForumContentButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ForumContentText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${Card} {
        max-width: 1100px;
        background-color: ${menuColors.cardColor};
        color: ${menuColors.background};
        margin: ${theme.spacing.medium};
        padding: ${theme.spacing.medium};
        a {
            color: ${menuColors.secondary};
        }
    }
`;
