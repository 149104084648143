// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceBannerProps } from "./performance-banner.types";

// Styles
import { Container, ContentWrapper, TitleWrapper, TitleIndicator, Title, Subtitle } from "./performance-banner.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceBanner: FunctionComponent<PerformanceBannerProps> = ({ title, subtitle, offensiveComposition }) => {
    return (
        <Container>
            <ContentWrapper>
                <TitleWrapper>
                    <TitleIndicator />

                    <Spacing size={8} direction="horizontal" />

                    <Title>{title}</Title>
                </TitleWrapper>

                <Spacing size={8} direction="vertical" />

                <Subtitle>{subtitle}</Subtitle>
            </ContentWrapper>

            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="horizontal" size={24} />} />

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing size={16} direction="vertical" />} />

            {offensiveComposition}
        </Container>
    );
};
