import { AxiosResponse } from "axios";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { getExerciseListDashboardRoute } from "helpers/getExerciseListDashboardRoute";
import { IAnswerCardPost } from "interfaces/IAnswerCardPost";
import { IReduxStore } from "interfaces/IReduxStore";
import { IRequestAction } from "interfaces/IRequestAction";
import _omit from "lodash/omit";
import _uniqBy from "lodash/uniqBy";
import { call, put, select, takeLatest } from "redux-saga/effects";
import history from "services/history";
import { ReduxStore } from "store/ducks";
import { IComments, ICompetences, IEssayComposition } from "store/interfaces/IEssay";
import { showAlertError } from "utils/showAlertError";
import api from "../../services/api";
import { Creators as AnswerCardActions, IAnswerCardComposition, Types as AnswerCardTypes } from "../ducks/answerCard";
import { Creators as exerciseListActions } from "../ducks/exerciseList";
import { Creators as modalActions } from "../ducks/modal";
import { exerciseListSagaUtils } from "./exerciseList/utils";

const { exibitionMiddleware } = exerciseListSagaUtils;

function* answerCardGetAll(action: any) {
    try {
        const { data } = yield call(
            api.get,
            `/answercard`,

            {
                headers: {
                    "X-Where": `exerciseList=${action.payload}`,
                    "X-Relations": "answers",
                    "X-No-Pagination": true
                }
            }
        );

        yield put(AnswerCardActions.getAnswerCardsSuccess(data.items));
    } catch (error) {
        yield showAlertError(error, "Não foi possível obter a Lista de Exercicios.");

        yield put(AnswerCardActions.getAnswerCardsFailure(error?.response?.data));
    }
}

function* requestComposition(idComposition: number) {
    const { data } = yield call(api.get, `/student/essay/composition/${idComposition}/summary`);

    const savedComposition = yield select<(state: ReduxStore) => IAnswerCardComposition | undefined>(
        ({ answerCard }) => answerCard?.answerCard?.composition
    );

    if (!data?.idComposition) {
        throw new Error();
    }

    return { ...data, ...savedComposition };
}

function* answerCardGetById(action: any) {
    try {
        const { id, isFeedBack } = action.payload;

        if (!!isFeedBack) {
            const { data } = yield call(api.get, `/student/exerciselist/${id}/answerkeytemplate`);

            yield put(AnswerCardActions.getAnswerCardSuccess(data));

            return;
        }

        const { data } = yield call(api.get, `/answercard/${id}`);

        yield put(AnswerCardActions.getAnswerCardSuccess(data));

        // // SHOW EXERCISE LIST RESULT CARD
        // if (data?.status >= AnswerCardStatus.Done) {
        //     yield put(exerciseListActions.showResult(true));
        // }

        yield exibitionMiddleware();

        const idComposition = data?.examResolution?.idComposition;

        if (!idComposition) {
            return;
        }

        // REQUEST COMPOSITION
        const hasEssayCorrection = data?.status === AnswerCardStatus.Done || data?.status === AnswerCardStatus.Corrected;

        if (hasEssayCorrection) {
            return yield put(AnswerCardActions.getAnswerCardEssayCorrectionRequest());
        }

        const composition = yield requestComposition(idComposition);

        return yield put(AnswerCardActions.getAnswerCardSuccess({ composition }));
    } catch (error) {
        yield put(AnswerCardActions.getAnswerCardFailure(error?.response?.data));

        yield showAlertError(error);
    }
}

function checkHasCompositionToSave(answerCardId: number, compositionText?: string) {
    const localStorageSaved = localStorage.getItem(`answerCardComposition:${answerCardId}`);

    const hasNewContent = compositionText !== localStorageSaved;

    return !!localStorageSaved && hasNewContent;
}

function* answerCardPatchById(action: any) {
    try {
        const { answerCardId, status, isExtra, isSimulatedExam, examDaySlug, activityId, activitySubmissionId } = action.payload;

        const isActivity = !!activityId && !!activitySubmissionId;

        const isFinishing = status === AnswerCardStatus.Done;

        const { compositionId, compositionText, idTheme } = yield select(({ answerCard, exerciseList }: ReduxStore) => ({
            compositionId: answerCard?.answerCard?.composition?.idComposition,
            compositionText: answerCard?.answerCard?.composition?.content,
            idTheme: exerciseList?.essayTheme?.idTheme
        }));

        const isSimulated = isSimulatedExam && !!examDaySlug;
        const endpoint = isSimulated ? `/student/examday/${examDaySlug}/finish` : `/answercard/${answerCardId}`;
        const body = isSimulated ? { answerCardId: +answerCardId, compositionId } : { status };

        // MIDDLEWARE TO SAVE ESSAY
        const hasCompositionToSave = checkHasCompositionToSave(answerCardId, compositionText) && isSimulated;

        if (hasCompositionToSave) {
            return yield put(
                exerciseListActions.saveExerciseListEssayRequest({
                    content: localStorage.getItem(`answerCardComposition:${answerCardId}`) || "",
                    idComposition: compositionId,
                    idTheme,
                    onSuccessPatchAnswerCardPayload: action.payload
                })
            );
        }

        const { data } = yield call(api.patch, endpoint, body);

        yield put(AnswerCardActions.patchAnswerCardSuccess(data));

        if (isFinishing && !isActivity) {
            yield put(exerciseListActions.showResult(true));
        }

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);

        if (isExtra || isSimulatedExam) {
            localStorage.removeItem(`answerCardComposition:${answerCardId}`);

            return yield call(history.push, { pathname: getExerciseListDashboardRoute(courseSlug) });
        }

        if (!!isFinishing && !!isActivity) {
            yield call(api.put, `/activities/${activityId}/submissions/${activitySubmissionId}`, {
                reference: `${answerCardId}`,
                finish: true
            });

            return yield call(history.push, {
                pathname: `/app/curso/${courseSlug}/atividades/lista-de-exercicios/${activityId}/entrega/${activitySubmissionId}`
            });
        }

        yield put(AnswerCardActions.getAnswerCardSuccess(data));
    } catch (error) {
        yield showAlertError(error, "Não foi possível atualizar a Lista de Exercicios.");

        yield put(AnswerCardActions.patchAnswerCardFailure(error?.response?.data));
    }
}

function* postAnswerCardRequest(action: IAnswerCardPost) {
    try {
        const { subjectSlug, moduleId, mode, id, isExtra, previousExamId, previousExamDayId, projectId, next, examDaySlug } = action.payload;

        const endpoint = !!examDaySlug ? `/student/examday/${examDaySlug}/resolution` : `/answercard`;

        const postRequest = yield call(api.post, endpoint, {
            realizationMode: action.payload.mode,
            exerciseList: action.payload.id
        });

        if (!postRequest.data) {
            throw new Error("Falha ao obter o answercard");
        }

        yield put(AnswerCardActions.postAnswerCardSuccess(postRequest.data));

        const realizationModeString: { [key: number]: string } = {
            [AnswerCardRealizationMode.Study]: "estudo",
            [AnswerCardRealizationMode.Proof]: "prova"
        };

        yield put(modalActions.closeModal());

        if (next) {
            return next(postRequest.data.id, mode);
        }

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);

        const getRedirectURL = () => {
            if (!!projectId) {
                return `/app/curso/${courseSlug}/projetos/${projectId}/lista-de-exercicios/${id}/${realizationModeString[mode]}/${postRequest.data.id}`;
            }

            if (!!previousExamId) {
                return `/app/curso/${courseSlug}/provas-anteriores/${previousExamId}/dia/${previousExamDayId}/${realizationModeString[mode]}/${postRequest.data.id}`;
            }

            if (!!subjectSlug) {
                return isExtra
                    ? `/app/curso/${courseSlug}/avaliacoes/${subjectSlug}/lista-de-exercicios/${id}/${realizationModeString[mode]}/${postRequest.data.id}`
                    : `/app/curso/${courseSlug}/materias/${subjectSlug}/${moduleId}/lista-de-exercicios/${id}/${realizationModeString[mode]}/${postRequest.data.id}`;
            }

            return `/app/curso/${courseSlug}/lista-de-exercicios/${id}/${realizationModeString[mode]}/${postRequest.data.id}`;
        };

        yield call(history.push, {
            pathname: getRedirectURL()
        });
    } catch (error) {
        yield showAlertError(error, "Não foi possível obter a Lista de Exercicio.");

        yield put(AnswerCardActions.postAnswerCardFailure(error?.response?.data));
    }
}

function* answerCardPostQuestionById(action: any) {
    try {
        const { answerCardId, optionId, discursiveAnswer, questionId } = action.payload;

        const formatBody = !discursiveAnswer
            ? { answerCard: answerCardId, objectiveAnswer: optionId, question: questionId }
            : { answerCard: answerCardId, discursiveAnswer: discursiveAnswer, question: questionId };

        const { data } = yield call(api.post, `/answercardquestion`, formatBody);

        if (!data || !data.question) {
            throw new Error("answer whithout question");
        }

        yield put(AnswerCardActions.postAnswerCardQuestionSuccess(data));
    } catch (error) {
        yield showAlertError(error);

        yield put(AnswerCardActions.postAnswerCardQuestionFailure(error?.response?.data));
    }
}

function* getResultCards(action: IRequestAction<any>) {
    try {
        const { endpoint, headers } = action.payload;

        const { data } = yield call(api.get, endpoint, {
            headers
        });

        if (!Array.isArray(data?.items)) {
            throw new Error();
        }

        const results = yield select((state: IReduxStore) => state.answerCard.results?.items || []) || [];

        yield put(
            AnswerCardActions.getResultCardsSuccess({
                ...data,
                items: _uniqBy([...results, ...data.items], "id")
            })
        );
    } catch (error) {
        yield showAlertError(error);

        yield put(AnswerCardActions.getResultCardsFailure(error?.response?.data));
    }
}

function* getEssayResult() {
    try {
        const idComposition = yield select<(state: ReduxStore) => number | undefined>(
            ({ answerCard }) => answerCard?.answerCard?.examResolution?.idComposition
        );

        if (!idComposition) {
            throw new Error();
        }

        const { data: composition } = yield call(api.get, `/student/essay/composition/${idComposition}`);

        if (!composition?.idComposition) {
            throw new Error();
        }

        const { data: competences }: AxiosResponse<ICompetences[]> = yield call(
            api.get,
            `/student/essay/composition/${idComposition}/revision/competences`
        );

        if (!Array.isArray(competences)) {
            throw new Error();
        }

        const { data: commentsResponse }: AxiosResponse<{ comments: IComments }> = yield call(
            api.get,
            `/student/essay/composition/${idComposition}/revision/comments`
        );

        if (!Array.isArray(commentsResponse?.comments)) {
            throw new Error();
        }

        const response: Partial<IEssayComposition> = {
            ..._omit(composition, ["idContest", "code"]),
            competences,
            comments: commentsResponse?.comments ?? []
        };

        console.log({ response });

        yield put(AnswerCardActions.getAnswerCardEssayCorrectionSuccess(response));
    } catch (error) {
        console.log({ error });
        yield showAlertError(error);

        yield put(AnswerCardActions.getAnswerCardEssayCorrectionFailure());
    }
}

function* setOpenQuestionAnswerRequest(action: any) {
    try {
        const { idQuestion, idAnswerCard, checkedValue, question } = action.payload;

        const hit = checkedValue.checked ? checkedValue.value : null;
        const payload = {
            idQuestion,
            idAnswerCard,
            hit
        };

        const { data } = yield call(api.post, "questionengine/answercardquestion/hit", payload);

        const stateData = {
            ...data,
            hit,
            question
        };

        yield put(AnswerCardActions.setOpenQuestionAnswerSuccess(stateData));
    } catch (error) {
        console.log(error);
        yield showAlertError(error);
    }
}

export default [
    takeLatest(AnswerCardTypes.GET_ANSWER_CARDS_REQUEST, answerCardGetAll),
    takeLatest(AnswerCardTypes.GET_ANSWER_CARD_REQUEST, answerCardGetById),
    takeLatest(AnswerCardTypes.PATCH_ANSWER_CARD_REQUEST, answerCardPatchById),
    takeLatest(AnswerCardTypes.POST_ANSWER_CARD_REQUEST, postAnswerCardRequest),
    takeLatest(AnswerCardTypes.POST_ANSWER_CARD_QUESTION_REQUEST, answerCardPostQuestionById),
    takeLatest(AnswerCardTypes.GET_RESULT_CARDS_REQUEST, getResultCards),
    takeLatest(AnswerCardTypes.GET_ANSWER_CARD_ESSAY_CORRECTION_REQUEST, getEssayResult),
    takeLatest(AnswerCardTypes.SET_OPEN_QUESTION_ANSWER_REQUEST, setOpenQuestionAnswerRequest)
];
