import Skeleton from "components/Skeleton";

import React, { memo } from "react";
import * as S from "./styles";
import { theme } from "config/theme";

const SubjectScreenDetailsMobileSelectFrontSkeleton = memo(() => {
    return (
        <>
            <S.SubjectScreenDetailsMobileSelectFrontContainer>
                <S.SubjectScreenDetailsMobileSelectFrontButton selected onClick={() => null}>
                    <Skeleton width="50px" height="50px" style={{ borderRadius: "100%", marginRight: theme.spacing.smallX }} />

                    <S.SubjectScreenDetailsMobileSelectFrontTexts column>
                        <S.SubjectScreenDetailsMobileSelectFrontTitle>
                            <Skeleton width="55%" height="16px" />
                        </S.SubjectScreenDetailsMobileSelectFrontTitle>

                        <S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                            <Skeleton width="70%" height="16px" />
                        </S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                    </S.SubjectScreenDetailsMobileSelectFrontTexts>

                    <Skeleton width="12px" height="12px" />
                </S.SubjectScreenDetailsMobileSelectFrontButton>
            </S.SubjectScreenDetailsMobileSelectFrontContainer>

            <S.SubjectScreenDetailsMobileSelectFrontModuleTitle>
                <Skeleton width="60%" height="18px" />
            </S.SubjectScreenDetailsMobileSelectFrontModuleTitle>
        </>
    );
});

export default SubjectScreenDetailsMobileSelectFrontSkeleton;
