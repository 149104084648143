import { ButtonWrapper } from "components/Button/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExerciseListBlockedQuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${theme.colors.base[100]};
    padding-top: ${theme.spacing.small};
    margin-top: ${theme.spacing.small};

    > ${ButtonWrapper} {
        align-self: baseline;
    }
`;

export const ExerciseListBlockedQuestionMessage = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.base[200]};
    margin-bottom: ${theme.spacing.small};

    > svg {
        margin-right: ${theme.spacing.smallX};
    }
`;
