// Dependencies
import React, { Fragment, FunctionComponent, useCallback, useEffect, useRef, useState } from "react";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ActivityBottomSheet } from "components/ActivityBottomSheet";
import { ActivityButton } from "components/ActivityButton";

// Types
import { ActivitySelectOption, ActivitySelectProps } from "./activity-select.types";

// Styles
import {
    Container,
    Label,
    CurrentOption,
    ValueWrapper,
    OptionsWrapper,
    Option,
    UnderSelectMessageWrapper,
    UnderSelectMessage,
    UnderSelectMessageIcon,
    InputOpenedIndicatorIcon
} from "./activity-select.styles";

// Assets
import chevronDown from "assets/img/chevron-down.svg";
import chevronUp from "assets/img/chevron-up.svg";
import fail from "assets/img/fail.svg";

// Utils
import { useWindowDimensions } from "hooks/use-window-dimensions";

export const ActivitySelect: FunctionComponent<ActivitySelectProps> = ({
    register,
    width,
    label,
    options,
    errorMessage,
    defaultValue,
    bottomSheetPlaceholder,
    bottomSheetOptionsType = "default",
    shouldScrollToPageEnd = false,
    shouldHiddenSelectedOptionLabel = false,
    handleClick
}) => {
    const { width: windowWidth } = useWindowDimensions();

    const containerRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<ActivitySelectOption | undefined>(undefined);
    const [preSelectedOption, setPreSelectedOption] = useState<ActivitySelectOption | undefined>(undefined);

    const handleOptionClick = useCallback(
        (option: ActivitySelectOption, shouldSave?: boolean, shouldPreSelectOnly?: boolean) => {
            if (shouldPreSelectOnly) {
                setPreSelectedOption(option);
                return;
            }

            if (shouldSave) {
                handleClick(option);
            }

            setIsOpen(false);
            setSelectedOption(option);
            setPreSelectedOption(undefined);
        },
        [handleClick]
    );

    useEffect(() => {
        if (defaultValue) {
            const defaultSelectedOption = options.find((option) => option.value === defaultValue);

            if (defaultSelectedOption) {
                handleOptionClick(defaultSelectedOption, !selectedOption);
            }
        }
    }, [defaultValue, handleOptionClick, options, selectedOption]);

    useEffect(() => {
        if (isOpen && shouldScrollToPageEnd) {
            window.scrollTo({
                top: 20000
            });
        }
    }, [isOpen, shouldScrollToPageEnd]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Container
            {...register}
            ref={containerRef}
            hasError={!!errorMessage}
            width={width}
            onClick={() => {
                if (windowWidth > 768) {
                    setIsOpen(!isOpen);
                    return;
                }

                if (!isOpen) {
                    setIsOpen(true);
                }
            }}
        >
            <ValueWrapper isOpen={isOpen}>
                <ConditionallyRender
                    shouldRender={!!label && !!selectedOption && !shouldHiddenSelectedOptionLabel}
                    content={<Label>{label}</Label>}
                />

                <ConditionallyRender
                    shouldRender={!!selectedOption && !shouldHiddenSelectedOptionLabel}
                    content={<CurrentOption>{selectedOption?.label}</CurrentOption>}
                />

                <ConditionallyRender
                    shouldRender={(!selectedOption && !!label) || !!shouldHiddenSelectedOptionLabel}
                    content={<CurrentOption>{label}</CurrentOption>}
                />

                <Spacing size={10} direction="horizontal" />

                <InputOpenedIndicatorIcon src={isOpen ? chevronUp : chevronDown} />
            </ValueWrapper>

            <ConditionallyRender
                shouldRender={!!isOpen}
                content={
                    <Fragment>
                        <ScreenWidthRender
                            renderingWidth={768}
                            actionAfterRenderingWidth="hide"
                            content={
                                <ActivityBottomSheet
                                    handleOutsideClick={() => setIsOpen(false)}
                                    label={bottomSheetPlaceholder || ""}
                                    type={bottomSheetOptionsType}
                                    content={options.map((option, index) => (
                                        <Option
                                            type={bottomSheetOptionsType}
                                            value={option.value}
                                            key={index}
                                            {...(bottomSheetOptionsType === "list" && {
                                                isActive:
                                                    (!!preSelectedOption?.value ? preSelectedOption?.value : selectedOption?.value) === option.value
                                            })}
                                            onClick={() =>
                                                handleOptionClick(option, bottomSheetOptionsType !== "list", bottomSheetOptionsType === "list")
                                            }
                                        >
                                            {option.label}
                                        </Option>
                                    ))}
                                    {...(bottomSheetOptionsType === "list" && {
                                        actionButtonCompositions: (
                                            <ActivityButton
                                                isFullyAdaptative
                                                isDisabled={!preSelectedOption}
                                                label="Selecionar"
                                                size="large"
                                                onClick={() => handleOptionClick(preSelectedOption!, true)}
                                            />
                                        )
                                    })}
                                />
                            }
                        />

                        <ScreenWidthRender
                            renderingWidth={768}
                            actionAfterRenderingWidth="show"
                            content={
                                <OptionsWrapper>
                                    {options.map((option, index) => (
                                        <Option
                                            type={bottomSheetOptionsType}
                                            value={option.value}
                                            key={index}
                                            onClick={() => handleOptionClick(option, true)}
                                        >
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptionsWrapper>
                            }
                        />
                    </Fragment>
                }
            />

            <ConditionallyRender
                shouldRender={!!errorMessage}
                content={
                    <Fragment>
                        <Spacing size={4} direction="vertical" />

                        <UnderSelectMessageWrapper>
                            <UnderSelectMessageIcon src={fail} />

                            <Spacing size={4} direction="horizontal" />

                            <UnderSelectMessage>{errorMessage}</UnderSelectMessage>
                        </UnderSelectMessageWrapper>
                    </Fragment>
                }
            />
        </Container>
    );
};
