import React, { useMemo } from "react";

import * as S from "./styles";

import TimerIcon from "prodigio-icons/web/filled/Timer";
import SeePasswordIcon from "prodigio-icons/web/filled/SeePassword";
import Button from "components/Button";
import { IKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { DateTime } from "luxon";
import { ExamDayStatus } from "enums/ExamDayStatus";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import Skeleton from "./skeleton";

import IcoInfo from "prodigio-icons/web/outlined/Info";

interface IProps {
    title?: string;
    startDate?: string;
    endDate?: string;
    name: string;
    totalQuestions?: number;
    knowledgeAreas?: IKnowledgeAreas[];
    durationInMinutes?: number;
    startLabel?: string;
    onClickStart(): void;
    isAvailable: boolean;
    onClickStartTester(mode: AnswerCardRealizationMode | "feedback"): void;
    isTester: boolean;
    canSeeAnswers: boolean;
    onClickSeeAnswers(): void;
    loading: boolean;
    loadingStartExam: boolean;
    isInPersonApplyment: boolean;
    handleClickdownloadPDF(): void;
    disableButtonPDF: boolean;
    examDaysStatus: number;
    variantColor: string;
}

const ExamDayDetails = ({
    title,
    name,
    totalQuestions = 0,
    knowledgeAreas = [],
    onClickStart,
    startDate = "",
    endDate = "",
    durationInMinutes = 0,
    startLabel,
    isAvailable = false,
    isTester = false,
    onClickStartTester,
    canSeeAnswers = false,
    onClickSeeAnswers,
    loading = false,
    loadingStartExam = false,
    isInPersonApplyment = false,
    handleClickdownloadPDF,
    disableButtonPDF,
    examDaysStatus,
    variantColor
}: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);

    const memoTime = useMemo(
        () => (
            <S.ExamDetailsDayTime>
                <TimerIcon width={13} height={14} />

                <span data-test-id="exam-day-duration">
                    {DateTime.local()
                        .set({ hour: 0, minute: 0, second: 0 })
                        .plus({ minutes: durationInMinutes })
                        .toFormat("HH:mm")}
                </span>
            </S.ExamDetailsDayTime>
        ),
        [durationInMinutes]
    );

    const memoAppliedPeriod = useMemo(
        () =>
            isInPersonApplyment ? (
                <S.ExamDetailsDayInPersonApplyment>
                    <IcoInfo width="13px" height="13px" />
                    Simulado de aplicação presencial. Confira a data da prova junto à escola.
                </S.ExamDetailsDayInPersonApplyment>
            ) : (
                <S.ExamDetailsDayAppliedPeriod data-test-id="exam-day-applied-period">
                    <strong>Aplicado entre:</strong>

                    <S.ExamDetailsDayAppliedPeriodChart>
                        <S.ExamDetailsDayAppliedPeriodDay>
                            <S.ExamDetailsDayAppliedPeriodBull variant="success" />

                            <p>
                                Data inicial:{" "}
                                <span data-test-id="exam-day-applied-period-start">
                                    {DateTime.fromISO(startDate).isValid ? DateTime.fromISO(startDate).toFormat("dd/MM/yyyy 'às' HH:mm") : "-"}
                                </span>
                            </p>
                        </S.ExamDetailsDayAppliedPeriodDay>

                        <S.ExamDetailsDayAppliedPeriodDay>
                            <S.ExamDetailsDayAppliedPeriodBull variant="danger" />

                            <p>
                                Data final:{" "}
                                <span data-test-id="exam-day-applied-period-end">
                                    {DateTime.fromISO(endDate).isValid ? DateTime.fromISO(endDate).toFormat("dd/MM/yyyy 'às' HH:mm") : "-"}
                                </span>
                            </p>
                        </S.ExamDetailsDayAppliedPeriodDay>
                    </S.ExamDetailsDayAppliedPeriodChart>
                </S.ExamDetailsDayAppliedPeriod>
            ),
        [endDate, isInPersonApplyment, startDate]
    );

    const memoKnowledgeAreas = useMemo(() => {
        if (!knowledgeAreas?.length) {
            return null;
        }

        return (
            <S.ExamDetailsDayContentKnowledgeAreaWrapp data-test-id="exam-day-knowledgeAreas">
                {knowledgeAreas.map((knowledgeArea) => (
                    <S.ExamDetailsDayContentKnowledgeArea key={knowledgeArea.id} data-test-id="exam-day-knowledgeAreas-item">
                        <span>&bull;</span> <p>{knowledgeArea.name}</p>
                    </S.ExamDetailsDayContentKnowledgeArea>
                ))}
            </S.ExamDetailsDayContentKnowledgeAreaWrapp>
        );
    }, [knowledgeAreas]);

    const memoRealizations = useMemo(() => {
        if (!canSeeAnswers) {
            return null;
        }

        return (
            <S.ExamDetailsDayOptionButton type="button" onClick={onClickSeeAnswers}>
                <SeePasswordIcon width={16} height={10} /> Ver suas respostas
            </S.ExamDetailsDayOptionButton>
        );
    }, [canSeeAnswers, onClickSeeAnswers]);

    if (loading) {
        return <Skeleton />;
    }

    const disabledTestButton = loading || loadingStartExam;

    return (
        <S.ExamDetailsDayContainer data-test-id="exam-day">
            <S.ExamDetailsDayTitle>{title}</S.ExamDetailsDayTitle>

            <S.ExamDetailsDayHeader variant={variantColor as any}>
                <S.ExamDetailsDayTexts>
                    <h2 data-test-id="exam-day-name">{name}</h2>

                    <p data-test-id="exam-day-total-questions">{!!totalQuestions ? `${totalQuestions} questões` : "Sem questões"}</p>
                </S.ExamDetailsDayTexts>

                {memoTime}
            </S.ExamDetailsDayHeader>

            <S.ExamDetailsDayContent>
                {memoKnowledgeAreas}
                {memoAppliedPeriod}
            </S.ExamDetailsDayContent>

            {isMobile && memoRealizations}

            {!isInPersonApplyment && (
                <>
                    {isTester && (
                        <S.ExamDetailsDayTester>
                            <p>Fazer prova como testador:</p>

                            <div>
                                <Button
                                    variant="primary"
                                    block={true}
                                    size="small"
                                    onClick={() => onClickStartTester(AnswerCardRealizationMode.Proof)}
                                    disabled={disabledTestButton}
                                >
                                    Modo prova
                                </Button>

                                <Button
                                    variant="outline-primary"
                                    block={true}
                                    size="small"
                                    onClick={() => onClickStartTester("feedback")}
                                    disabled={disabledTestButton}
                                >
                                    Modo gabarito
                                </Button>
                            </div>

                            <p>Fazer prova como aluno:</p>
                        </S.ExamDetailsDayTester>
                    )}

                    <S.ContentButtons>
                        <Button
                            variant={(variantColor === "brand" ? "primary" : variantColor) as any}
                            block={true}
                            size="small"
                            onClick={() => !loadingStartExam && onClickStart()}
                            disabled={!isAvailable || !totalQuestions || loadingStartExam}
                            isLoading={loadingStartExam}
                        >
                            {startLabel}
                        </Button>
                        <Button
                            variant={`outline-${variantColor === "brand" ? "primary" : variantColor}` as any}
                            block={true}
                            size="small"
                            onClick={handleClickdownloadPDF}
                            disabled={examDaysStatus <= ExamDayStatus.CommingSoon || disableButtonPDF}
                            isLoading={loadingStartExam}
                        >
                            {disableButtonPDF ? "Exportando..." : "Imprimir"}
                        </Button>
                    </S.ContentButtons>
                </>
            )}
        </S.ExamDetailsDayContainer>
    );
};

export default React.memo(ExamDayDetails);
