import styled from "styled-components";
import { theme } from "config/theme";
import { AnswerCardProgressContainer } from "components/AnswerCardProgress/styles";
import { ExamResultFeedbackContainer } from "../Feedback/styles";

export const ExamResultNoteCardContainer = styled.div`
    margin-bottom: ${theme.spacing.medium};
`;

export const ExamResultNoteCardDetails = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};

    ${AnswerCardProgressContainer} {
        margin-bottom: ${theme.spacing.small};
    }

    /* ${ExamResultFeedbackContainer} {
        margin-bottom: ${theme.spacing.medium};
    } */
`;

export const AnswerCardProgressWrapper = styled.div`
    padding: 16px 24px 24px;
    background: ${theme.colors.white};
    border-radius: 0px 0px 4px 4px;
`;
