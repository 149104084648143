// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    padding: 24px;
    border-radius: 4px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px #0000001a;
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 64px;
    height: 64px;
`;

export const Icon = styled.img`
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
`;

export const Title = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #232326;
`;

export const Description = styled.p`
    font-size: "Nunito Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #83899e;
`;
