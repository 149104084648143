import { all, call, put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as resolutionActions } from "../ducks/resolution";

const normalizeData = (data: any, resolutions: any) => {
    const currentResolution = resolutions.find((resolution: any) => {
        return resolution.type === "Video" ? resolution.video.id === data.id : resolution.material.id === data.id;
    });

    if (currentResolution.type === "Video") {
        return { ...currentResolution, video: { ...currentResolution.video, url: data.url } };
    }

    return { ...currentResolution, material: { ...currentResolution.material, url: data.fileName } };
};

export function* getById(action: any) {
    try {
        const { resolutions } = action.payload;

        const allResolutions = yield all(
            resolutions.map((resolution: any) => {
                return resolution.type === "Video"
                    ? call(api.get, `/video/${resolution.video.id}`)
                    : call(api.get, `/material/${resolution.material.id}`);
            })
        );

        const data = allResolutions.map((resolution: any) => normalizeData(resolution.data, resolutions));

        yield put(resolutionActions.setResolutionSuccess({ data }));
    } catch (error) {
        yield put(resolutionActions.setResolutionFailure(error?.response?.data));
    }
}
