import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { CardContainerSmallStyle } from "assets/styles/global";
import { EssayThemesContentTitle } from "../themes/styles";

export const EssayThemeContainer = styled.section`
    padding-bottom: ${theme.spacing.largeX};
    position: relative;
    height: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-top: ${theme.spacing.largeX};
    }
`;

export const MakeEssayCanvasWrapper = styled.div`
    ${CardContainerSmallStyle};
    margin-top: ${theme.spacing.small};
    padding: ${theme.spacing.medium} 40px ${theme.spacing.large};
`;

export const MakeEssaySwitcherWrapper = styled.div`
    border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    margin-bottom: ${theme.spacing.large};
    padding-bottom: ${theme.spacing.medium};
`;

export const AppStoresContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.large};

    a {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }
`;

export const EssayThemeMyPerformanceTitle = styled.div`
    ${EssayThemesContentTitle};
    margin-top: ${theme.spacing.medium};
    margin-bottom: ${theme.spacing.small};
`;

export const EssayThemeMyPerformance = styled.div`
    ${CardContainerSmallStyle};
`;
