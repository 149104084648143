import React, { useRef, useCallback, useEffect, useState, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";

//styles and visuals
import { theme } from "config/theme";
import {
    LightVideoLoadingContainer,
    LightVideoContainer,
    LightVideoReportButton,
    LightVideoContent,
    LightVideoContentTexts,
    LightVideoDoubts,
    LightVideoForumContainer,
    LightVideoContentHeaderDoubt,
    LightVideoTitleClearDoubt,
    LightVideoContentDoubts,
    LightVideoButtonToReLoadPage,
    LightVideoReportButtonWrapper,
    LightVideoDivider,
    LightVideoFilterDoubt,
    LightVideoExternPlayerButton,
    LightVideoButtonsWrapper
} from "./styles";
import {
    WatchScreenVideoContainer,
    WatchScreenVideoContent,
    WatchScreenVideoContentHeader
} from "components/WatchScreenDoubt/components/VideoDoubt/styles";
import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";
import ChevronUp from "prodigio-icons/web/outlined/ChevronUp";

//components
import Button from "components/Button";
import Spinner from "components/Spinner";
import PageLoader from "components/PageLoader";
import { VIDEO_PLATFORMS_WITH_PRODIGIO_PLAYER } from "components/Video";
import InputPost from "components/WatchScreenDoubt/components/InputDoubt";
import Doubts from "components/WatchScreenDoubt/components/Doubt";
import ForumDoubt from "components/WatchScreenDoubt/components/ForumDoubt";

//utils
import HLSSource from "hls.js";
import api from "services/api";
import { useModal } from "hooks/use-modal";
import { DateTime } from "luxon";
import VideoPlatform from "enums/VideoPlatform";
import scrollIntoView from "scroll-into-view";
import { hasAnswerDoubts } from "helpers/has-answer-doubts";

// redux
import { useDispatch } from "react-redux";
import { useSelector } from "store";
import { Creators as VideoActions } from "store/ducks/video";
import { Creators as postActions } from "store/ducks/doubtPost";
import { IGetTopicStatus } from "store/interfaces/IDoubtPost";
import { WebpChip } from "components/WebpChip";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";
import { isDevelopment } from "utils/isDevelopment";

interface IVideo {
    url?: string;
    title: string;
    subject?: {
        name: string;
        color?: string;
    };
    loId?: number;
    openForum?: boolean;
    hasForum?: boolean;
    embed?: string;
    videoId?: number;
    eventEndDate?: string;
    provider?: VideoPlatform;
}

const LightVideoDoubt: React.FC<RouteComponentProps<{ id: string; eventId?: string }>> = ({ match }) => {
    const { id, eventId } = match.params;

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [videoResponse, setVideoResponse] = useState<IVideo>();
    const [showDoubts, setShowDoubts] = useState(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const answerQuestionsElementRef = useRef<HTMLDivElement | null>(null);

    const modal = useModal();
    const dispatch = useDispatch();

    const { isLoadingReportError, isReportErrorSent, doubtPost, course, token } = useSelector(({ video, doubtPost, course, auth }) => ({
        isLoadingReportError: !!video?.isLoadingReportError,
        isReportErrorSent: !!video?.isReportErrorSent,
        doubtPost,
        course,
        token: auth.token
    }));

    const topicStatus = doubtPost.topic.data.status;
    const hasDoubts = hasAnswerDoubts(course?.slug!, theme.project.slug, topicStatus);
    const projectSlug = theme.project.slug;
    const isB2BBrand = !["proenem", "promilitares", "promedicina", "proraiz"].includes(projectSlug);
    const isDevelopmentEnvironment = isDevelopment();

    const isLiveFinished = useMemo(() => {
        if (
            !videoResponse?.eventEndDate ||
            (videoResponse?.provider !== VideoPlatform.LiveStream && videoResponse?.provider !== VideoPlatform.Vimeo)
        ) {
            return false;
        }

        const now = DateTime.local().setZone("UTC");
        const endDate = DateTime.fromISO(videoResponse.eventEndDate)
            .setZone("UTC")
            .plus({ hours: 2 });

        return now >= endDate;
    }, [videoResponse]);

    const withHTMLVideo = useMemo(() => {
        if (!videoResponse?.url) {
            return false;
        }

        if (videoResponse?.provider === VideoPlatform.LiveStream || videoResponse?.provider === VideoPlatform.Vimeo) {
            return !videoResponse?.eventEndDate || isLiveFinished;
        }

        return VIDEO_PLATFORMS_WITH_PRODIGIO_PLAYER.includes(videoResponse?.provider as any);
    }, [videoResponse, isLiveFinished]);

    const showEmbed = !!videoResponse?.embed && !withHTMLVideo;
    const hideVideo = loading || error || showEmbed;

    useEffect(() => {
        dispatch(VideoActions.clearVideoReportError());
    }, [dispatch]);

    const requestVideo = useCallback(async () => {
        try {
            if (!id && !eventId) {
                throw new Error();
            }

            setLoading(true);

            const endpoint = !!eventId ? `/lessonplanevent/${eventId}` : `/video/${id}`;

            const { data } = await api.get(endpoint);

            const video = !!eventId ? data?.video : data;

            if (!video?.id) {
                setVideoResponse({
                    title: data?.title
                });

                throw new Error();
            }

            const loId = video?.learningObject?.id;

            const response: IVideo = {
                url: video?.url,
                title: video?.title,
                loId,
                openForum: !!video?.learningObject?.openForum,
                hasForum: !!data?.hasForum && !!loId,
                embed: video?.videoEmbed,
                videoId: video.id,
                ...(!!video?.subject?.id && {
                    subject: {
                        name: video?.subject?.name,
                        color: video?.subject?.color
                    }
                }),
                provider: video?.provider,
                eventEndDate: data?.endDate
            };

            setVideoResponse(response);
            setError(false);
        } catch (error) {
            console.log(error);
            setError(true);
        } finally {
            setLoading(false);
        }
    }, [eventId, id]);

    useEffect(() => {
        requestVideo();
    }, [requestVideo]);

    const startHls = useCallback(() => {
        try {
            const videoElement = videoRef?.current;

            if (!videoResponse?.url || !videoElement) {
                return;
            }

            if (HLSSource.isSupported()) {
                (window as any).hls = new HLSSource({
                    maxBufferLength: 16,
                    capLevelOnFPSDrop: true,
                    autoStartLoad: true,
                    capLevelToPlayerSize: false,
                    fpsDroppedMonitoringThreshold: 0.4,
                    testBandwidth: true,
                    maxMaxBufferLength: 4 * 60
                });

                (window as any).hls.loadSource(videoResponse.url);
                (window as any).hls.attachMedia(videoElement);
            } else if (videoElement?.canPlayType("application/vnd.apple.mpegurl")) {
                videoElement.src = videoResponse?.url;
            }

            if (!isDevelopmentEnvironment) {
                videoElement?.play();
            }
        } catch (error) {
            console.log(error);
        }
    }, [isDevelopmentEnvironment, videoResponse]);

    useEffect(() => {
        startHls();
    }, [startHls]);

    const handleClickExternalPlayer = useCallback(() => {
        if (!!token && !!projectSlug) {
            const videoParams = `?video=${videoResponse?.videoId}&curso=${course?.slug}`;
            const basePath = `https://player${projectSlug === "proraiz" ? "-" : "."}${isDevelopmentEnvironment ? "dev." : ""}`;

            if (isB2BBrand) {
                document.cookie = `authentication=${token}; domain=prodigioeducacao.com; path=/; SameSite=None; Secure`;
                openLinkInBlankPage(`${basePath}prodigioeducacao.com${videoParams}`);
            } else {
                document.cookie = `authentication=${token}; domain=${projectSlug}.com.br; path=/; SameSite=None; Secure`;
                openLinkInBlankPage(`${basePath + projectSlug}.com.br${videoParams}`);
            }
        }
    }, [course, isB2BBrand, isDevelopmentEnvironment, projectSlug, token, videoResponse]);

    const handleClickReport = useCallback(() => {
        modal.open("reportVideoError", {
            url: window.location.href,
            videoId: videoResponse?.videoId,
            isEmbed: showEmbed,
            isForumOpen: !!videoResponse?.openForum
        });
    }, [modal, videoResponse, showEmbed]);

    // Answer questions - START

    useEffect(() => {
        if (videoResponse?.loId) {
            dispatch(postActions.getTopicRequest({ learningObjectId: videoResponse?.loId }));
        }

        return () => {
            dispatch(postActions.clearStateRequest());
        };
    }, [dispatch, videoResponse]);

    const getPosts = useCallback(
        (justmine?: boolean, isRestarting?: boolean) => {
            const justmineHasChanged = justmine !== undefined && justmine !== doubtPost.posts.filters.justmine;

            if (videoResponse?.loId && (doubtPost.posts.hasMore || justmineHasChanged || isRestarting)) {
                if (!!intervalRef.current) {
                    clearInterval(intervalRef.current);

                    intervalRef.current = null;
                }

                return dispatch(
                    postActions.getPostsRequest({
                        ...doubtPost.posts.filters,
                        learningObjectId: videoResponse?.loId,
                        ...(justmine !== undefined && { justmine }),
                        ...((!!isRestarting || justmineHasChanged) && { isRestarting: true })
                    })
                );
            }
        },
        [dispatch, doubtPost.posts.filters, doubtPost.posts.hasMore, videoResponse]
    );

    const handleJustmineFilter = useCallback((justmine: boolean) => getPosts(justmine), [getPosts]);

    const doubtsButton = useMemo(() => {
        const Icon = showDoubts ? ChevronUp : ChevronDown;

        return (
            <LightVideoForumContainer>
                <Button type="button" variant="primary" style={{ margin: "0 auto" }} onClick={() => setShowDoubts(!showDoubts)}>
                    {showDoubts ? "Ocultar" : "Exibir"} tira-dúvidas <Icon width={11} height={11} style={{ marginLeft: 10 }} />
                </Button>
            </LightVideoForumContainer>
        );
    }, [showDoubts]);

    const handleRefreshPosts = () => {
        if (!!answerQuestionsElementRef.current) {
            scrollIntoView(
                answerQuestionsElementRef.current,
                {
                    align: {
                        top: 0.2
                    }
                },
                (type: string) => {
                    if (type === "complete") {
                        getPosts(undefined, true);
                    }
                }
            );
        }
    };

    const contentChipFilter = useMemo(() => {
        return (
            <LightVideoFilterDoubt>
                <WebpChip
                    label="Minhas dúvidas"
                    disabled={doubtPost.posts.filters.justmine}
                    selected={doubtPost.posts.filters.justmine}
                    variant="neutral"
                    onChipSelected={() => !doubtPost.posts.filters.justmine && handleJustmineFilter(true)}
                ></WebpChip>
                <WebpChip
                    label="Todas"
                    disabled={!doubtPost.posts.filters.justmine}
                    selected={!doubtPost.posts.filters.justmine}
                    variant="neutral"
                    onChipSelected={() => doubtPost.posts.filters.justmine && handleJustmineFilter(false)}
                ></WebpChip>
            </LightVideoFilterDoubt>
        );
    }, [doubtPost.posts.filters.justmine, handleJustmineFilter]);

    const newPostsButtonLabel = useMemo(() => {
        if (doubtPost.newPostsQuantity >= 10) {
            return `Existem 10+ novas dúvidas`;
        }

        if (doubtPost.newPostsQuantity <= 1) {
            return `Existe 1 nova dúvida`;
        }

        return `Existem ${doubtPost.newPostsQuantity} novas dúvidas`;
    }, [doubtPost.newPostsQuantity]);

    useEffect(() => {
        if (!doubtPost.posts.items.length && showDoubts) {
            getPosts();
        }
    }, [doubtPost.posts.items.length, showDoubts, getPosts]);

    useEffect(() => {
        if (doubtPost.posts.formattedItemsToCheckUpdates.length > 0 && !!intervalRef.current) {
            clearInterval(intervalRef.current);

            intervalRef.current = null;
        }
    }, [doubtPost.posts.formattedItemsToCheckUpdates.length]);

    useEffect(() => {
        if (!!intervalRef.current && (doubtPost.posts.filters.justmine || doubtPost.newPostsQuantity >= 10)) {
            clearInterval(intervalRef.current);

            intervalRef.current = null;
        }

        if (
            !intervalRef.current &&
            !doubtPost.posts.filters.justmine &&
            !!videoResponse?.loId &&
            topicStatus === IGetTopicStatus.Active &&
            doubtPost.newPostsQuantity < 10
        ) {
            intervalRef.current = setInterval(() => {
                dispatch(
                    postActions.getHasUpdatedPostsRequest({
                        learningObjectId: videoResponse?.loId!,
                        posts: doubtPost.posts.formattedItemsToCheckUpdates
                    })
                );

                dispatch(
                    postActions.getHasNewPostsRequest({
                        learningObjectId: videoResponse?.loId!,
                        quantity: doubtPost.posts.filters.quantity
                    })
                );
            }, 20000);
        }
    }, [
        dispatch,
        doubtPost.newPostsQuantity,
        doubtPost.posts.filters.justmine,
        doubtPost.posts.filters.quantity,
        doubtPost.posts.formattedItemsToCheckUpdates,
        topicStatus,
        videoResponse
    ]);

    useEffect(() => {
        return () => {
            if (!!intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (doubtPost.postsToUpdate.length > 0) {
            dispatch(postActions.getUpdatedPostsRequest({ posts: doubtPost.postsToUpdate }));
        }
    }, [dispatch, doubtPost.postsToUpdate]);

    return (
        <>
            {isLoadingReportError && <PageLoader text="Enviando..." />}
            <LightVideoContainer>
                <WatchScreenVideoContainer fixed={false}>
                    <LightVideoLoadingContainer>
                        {loading ? <Spinner fixed={false} size={40} /> : error ? <p>Desculpe, tivemos um problema ao exibir esse vídeo.</p> : null}

                        {showEmbed ? (
                            <span dangerouslySetInnerHTML={{ __html: videoResponse?.embed || "" }} />
                        ) : (
                            <video controls ref={videoRef} style={{ ...(hideVideo && { display: "none" }) }} onError={() => setError(true)}>
                                <p style={{ color: "white" }}>O seu navegador não suporta esse vídeo.</p>
                            </video>
                        )}
                    </LightVideoLoadingContainer>
                </WatchScreenVideoContainer>
                <WatchScreenVideoContent>
                    <LightVideoContent>
                        <LightVideoContentTexts>
                            <WatchScreenVideoContentHeader color={videoResponse?.subject?.color || theme.colors.base[300]}>
                                <p>{videoResponse?.subject?.name || "Sem matéria"}</p>
                            </WatchScreenVideoContentHeader>

                            <h1>{videoResponse?.title}</h1>
                        </LightVideoContentTexts>

                        {!!videoResponse?.videoId && !isReportErrorSent && (
                            <LightVideoButtonsWrapper>
                                <LightVideoReportButtonWrapper>
                                    <small>Ainda está com problemas?</small>
                                    <LightVideoReportButton onClick={handleClickReport}>Reportar problema</LightVideoReportButton>
                                </LightVideoReportButtonWrapper>
                                <LightVideoExternPlayerButton onClick={handleClickExternalPlayer}>Testar outro player</LightVideoExternPlayerButton>
                            </LightVideoButtonsWrapper>
                        )}
                    </LightVideoContent>
                </WatchScreenVideoContent>

                {(videoResponse?.hasForum || hasDoubts) && doubtsButton}

                {showDoubts && videoResponse?.hasForum && !hasDoubts && !doubtPost.topic.isLoading && (
                    <LightVideoForumContainer>
                        <ForumDoubt code={videoResponse?.loId} isOpen={!!videoResponse?.openForum} />
                    </LightVideoForumContainer>
                )}

                {showDoubts && hasDoubts && doubtPost.newPostsQuantity > 0 && !doubtPost.posts.filters.justmine && (
                    <LightVideoDoubts style={{ position: "sticky", top: "8px", zIndex: "50" }}>
                        <LightVideoButtonToReLoadPage size="small" variant="info" onClick={handleRefreshPosts}>
                            {newPostsButtonLabel}
                        </LightVideoButtonToReLoadPage>
                    </LightVideoDoubts>
                )}

                {showDoubts &&
                    hasDoubts &&
                    ([IGetTopicStatus.Active].includes(topicStatus) ||
                        ([IGetTopicStatus.OnlyReading].includes(topicStatus) && doubtPost.posts.items.length > 0)) && (
                        <LightVideoDoubts>
                            <LightVideoContentHeaderDoubt ref={answerQuestionsElementRef}>
                                <LightVideoTitleClearDoubt>Tira-dúvidas</LightVideoTitleClearDoubt>
                                {contentChipFilter}
                            </LightVideoContentHeaderDoubt>
                            <LightVideoDivider />
                            <LightVideoContentDoubts>
                                {[IGetTopicStatus.Active].includes(topicStatus) && <InputPost />}
                                <Doubts posts={doubtPost.posts.items} handleFetchNextPosts={getPosts} />
                            </LightVideoContentDoubts>
                        </LightVideoDoubts>
                    )}
            </LightVideoContainer>
        </>
    );
};

export default LightVideoDoubt;
