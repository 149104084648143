// Dependencies
import styled, { css } from "styled-components";
import { theme } from "config/theme";

// Types
import { ActivityRadioVariant } from "./activity-radio.types";

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

type ActivityRadioCircleStyleProps = {
    isChecked: boolean;
    variant: ActivityRadioVariant;
};

export const ActivityRadioCircle = styled.div<ActivityRadioCircleStyleProps>`
    width: 12px;
    height: 12px;
    border-radius: 50%;

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "default" &&
        css`
            background-color: ${theme.colors.brand[300]};
        `}

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "danger" &&
        css`
            background-color: #e40000;
        `}

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "success" &&
        css`
            background-color: #00874f;
        `}
`;

type ActivityRadioCircleWrapperStyleProps = {
    isChecked: boolean;
    variant: ActivityRadioVariant;
};

export const ActivityRadioCircleWrapper = styled.div<ActivityRadioCircleWrapperStyleProps>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "default" &&
        css`
            border: 1px solid ${theme.colors.brand[300]};
        `}

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "danger" &&
        css`
            border: 1px solid #e40000;
            background-color: #e40000;
        `}

    ${({ isChecked, variant }) =>
        !!isChecked &&
        variant === "success" &&
        css`
            border: 1px solid #00874f;
            background-color: #00874f;
        `}

    ${({ isChecked }) =>
        !isChecked &&
        css`
            border: 1px solid #cccccc;
        `}
`;

export const ActivityRadioCircleIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

type LabelStyleProps = {
    isChecked: boolean;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: ${({ isChecked }) => (isChecked ? "#232326" : "#83899e")};
`;
