import { call, put } from "redux-saga/effects";

import HttpStatus from "enums/HttpStatus";
import { IContentPerType } from "interfaces/IContentPerType";
import api from "../../services/api";
import { Creators as alertActions } from "../ducks/alert";
import { Creators as watchActions } from "../ducks/watch";
import { AxiosResponse } from "axios";
import { ILessonPlanEvent } from "interfaces/ILessonPlan";

export function* getWatchBookletModule(action: any) {
    const { moduleId } = action.payload;
    try {
        if (!moduleId) {
            return;
        }

        const { data } = yield call(api.get, `/bookletmodule/${moduleId}`, {
            headers: {
                "X-Relations": "content.learningObject.video;subject;events;progress;subjectFront"
            }
        });

        yield put(watchActions.getWatchBookletModuleSuccess(data));
    } catch (error) {
        yield put(watchActions.getWatchBookletModuleFailure(error?.response?.data));
        yield put(alertActions.showAlert("Módulo não encontrado!", "danger"));
    }
}

export function* getWatchVideo(action: any) {
    const { id } = action.payload;

    try {
        const { data } = yield call(api.get, `/video/${id}`);

        // Formata a playlist pro padrao de video relacionado
        const formatData = {
            ...data,
            ...(data.playlist && {
                playlist: data.playlist.map((item: any) => ({
                    ...item.video,
                    viewed: item.viewed,
                    isLiked: item.isLiked
                }))
            })
        };

        yield put(watchActions.getWatchVideoSuccess(formatData));

        const { teachers } = data;

        if (!!teachers && !!teachers.length) {
            yield put(watchActions.setWatch({ teachers }));
        }
    } catch (error) {
        if (error.response && error.response.status !== HttpStatus.PAYMENT_REQUIRED) {
            yield put(alertActions.showAlert("Vídeo não encontrado!", "danger"));
        }

        yield put(watchActions.getWatchVideoFailure(error?.response?.data));
    }
}

export function* setWatchLike(action: any) {
    const { id, status } = action.payload;
    try {
        yield call(api.patch, `/learningobject/${id}/like`, {
            isLike: status
        });

        yield put(watchActions.setWatchLikeSuccess({ id, status }));
    } catch (error) {
        yield put(watchActions.setWatchLikeFailure(error?.response?.data));
    }
}

export function* getWatchLessonPlan(action: any) {
    try {
        const { eventId } = action.payload;

        if (!eventId) {
            return;
        }

        const { data }: AxiosResponse<ILessonPlanEvent> = yield call(api.get, `/lessonplanevent/${eventId}`, {
            headers: {
                "X-Relations": "teachers;bookletModules;module"
            }
        });

        const { video, teachers, contentPerType, category, subjectObj, startDate, title, endDate } = data;

        // Verifica se existe conteudo relacionado no contentPerType
        const relatedVideos = contentPerType.find((item: IContentPerType) => item.type === "Video");
        const relatedExerciseList = contentPerType.find((item: IContentPerType) => item.type === "ExerciseList");
        const relatedMaterial = contentPerType.find((item: IContentPerType) => item.type === "Material");
        const relatedBooklet = contentPerType.find((item: IContentPerType) => item.type === "Apostila");

        const updateContent = {
            ...(!!startDate && { eventStartDate: startDate }),
            ...(!!endDate && { eventEndDate: endDate }),
            ...(!!teachers && !!teachers.length && { teachers }),
            ...(!!relatedVideos && !!relatedVideos.items.length && { relatedVideos: relatedVideos.items }),
            ...(!!relatedExerciseList && !!relatedExerciseList.items.length && { exerciseLists: relatedExerciseList.items }),
            ...(!!relatedMaterial && !!relatedMaterial.items.length && { materials: relatedMaterial.items }),
            ...(!!relatedBooklet && !!relatedBooklet.items.length && { booklets: relatedBooklet.items }),
            ...((!!category || category === 0) && { category }),
            ...(!!data.module && { module: data.module }),
            ...(!!video && { video }),
            ...(!!subjectObj && { subjectObj }),
            hasForum: data.hasForum || false,
            title
        };

        yield put(watchActions.setWatch(updateContent));

        yield put(watchActions.getWatchLessonPlanSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Evento não encontrado!", "danger"));
        yield put(watchActions.getWatchLessonPlanFailure(error?.response?.data));
    }
}
