import styled from "styled-components";
import { TextareaField } from "components/Form/Textarea/styles";
import { theme } from "config/theme";

export const ExerciseListDiscursiveInputContainer = styled.div`
    margin-top: ${theme.spacing.small};

    ${TextareaField} {
        border: 1px solid ${theme.colors.base[200]};
        padding: ${theme.spacing.small};
    }
`;
