import React, { FunctionComponent, useState, Fragment, useEffect, useRef } from "react";

// Styles
import { ChartContainer, TooltipContainer, TooltipLabel, ChartSlice, ChartEmptyZone } from "./activity-performance-chart.styles";

// Types
import { ActivityPerformanceChartProps } from "./activity-performance-chart.types";

// Utils
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityPerformanceChart: FunctionComponent<ActivityPerformanceChartProps> = ({ chartSlices }) => {
    const tooltipRef = useRef<HTMLDivElement>(null);

    const [tooltipValue, setTooltipValue] = useState<string | undefined>(undefined);

    const formatTooltipValue = (value: number, label: string) => {
        const valuePercentage = (value / totalValueOfSlices) * 100;

        if (Number.isInteger(valuePercentage)) {
            return `${valuePercentage}% ${label}`;
        }

        return `${valuePercentage.toFixed(2)}% ${label}`;
    };

    const handleMouseMove = (event: any) => {
        if (tooltipRef.current) {
            tooltipRef.current.style.left = event.pageX - window.scrollX + "px";
            tooltipRef.current.style.top = event.pageY - window.scrollY + "px";
        }
    };

    useEffect(() => {
        document.addEventListener("mousemove", handleMouseMove, false);

        return () => {
            document.removeEventListener("mousemove", handleMouseMove, false);
        };
    }, []);

    const totalValueOfSlices = chartSlices.reduce((acc, slice) => acc + slice.value, 0);
    const circleRadius = 50;
    const graphCentralCoordinate = 50;
    let cumulativePercent = 0;

    return (
        <Fragment>
            <ChartContainer viewBox="0 0 100 100" onMouseMove={handleMouseMove}>
                {chartSlices.length === 1 ? (
                    <ChartSlice
                        key="activity-performance-chart-slice"
                        onMouseLeave={() => setTooltipValue(undefined)}
                        onMouseEnter={() => {
                            setTooltipValue(formatTooltipValue(chartSlices[0].value, chartSlices[0].label));
                        }}
                    >
                        <circle cx={graphCentralCoordinate} cy={graphCentralCoordinate} r={circleRadius} fill={chartSlices[0].color} />
                    </ChartSlice>
                ) : (
                    chartSlices.map((slice, index) => {
                        const startAngle = cumulativePercent * 3.6;
                        const slicePercentage = (slice.value / totalValueOfSlices) * 100;
                        const endAngle = startAngle + slicePercentage * 3.6;
                        const largeArcFlag = slicePercentage > 50 ? 1 : 0;
                        const x1 = graphCentralCoordinate + circleRadius * Math.cos((startAngle - 90) * (Math.PI / 180));
                        const y1 = graphCentralCoordinate + circleRadius * Math.sin((startAngle - 90) * (Math.PI / 180));
                        const x2 = graphCentralCoordinate + circleRadius * Math.cos((endAngle - 90) * (Math.PI / 180));
                        const y2 = graphCentralCoordinate + circleRadius * Math.sin((endAngle - 90) * (Math.PI / 180));

                        cumulativePercent += slicePercentage;

                        return (
                            <ChartSlice
                                key={`activity-performance-chart-slice-${index}`}
                                onMouseLeave={() => setTooltipValue(undefined)}
                                onMouseEnter={() => setTooltipValue(formatTooltipValue(slice.value, slice.label))}
                            >
                                <path
                                    d={`M${graphCentralCoordinate} ${graphCentralCoordinate} L${x1} ${y1} A${circleRadius} ${circleRadius} 0 ${largeArcFlag} 1 ${x2} ${y2} Z`}
                                    fill={slice.color}
                                />
                            </ChartSlice>
                        );
                    })
                )}

                <ChartEmptyZone cx={graphCentralCoordinate} cy={graphCentralCoordinate} r="32" onMouseEnter={() => setTooltipValue(undefined)} />
            </ChartContainer>

            <ConditionallyRender
                shouldRender={!!tooltipValue}
                content={
                    <TooltipContainer ref={tooltipRef}>
                        <TooltipLabel>{tooltipValue}</TooltipLabel>
                    </TooltipContainer>
                }
            />
        </Fragment>
    );
};
