// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 1360px;
    margin: 0 auto;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 16px;

    @media (min-width: 768px) {
        padding: 24px;
    }
`;

export const PageTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #3a3e47;
`;
