import React, { useEffect, useRef } from "react";

// Assets
import {
    PaywallButton,
    PaywallCard,
    PaywallCardContent,
    PaywallCardHeader,
    PaywallCardList,
    PaywallCardListItem,
    PaywallCardPrice,
    PaywallCardPriceInstallments,
    PaywallCardPriceStrike,
    PaywallCardWave,
    PaywallHeader,
    PaywallRecommended,
    PaywallScroll,
    PaywallScrollItem,
    PaywallWrapper
} from "./styles";

// Icons
import HappyIcon from "prodigio-icons/web/filled/Happy";
import SadIcon from "prodigio-icons/web/filled/Sad";

// Components
import Icon from "components/Icons/icon";

import scrollIntoView from "scroll-into-view";
import { mockPaywall } from "./index.mock";

const Paywall = () => {
    const recommendedRef = useRef(null);

    const scrollTo = () => {
        const element = recommendedRef.current;

        if (!element) {
            return;
        }

        scrollIntoView(
            element,
            {
                align: {
                    top: 0.5
                }
            },
            (type: string) => {
                if (type !== "complete") {
                    return;
                }
            }
        );
    };

    useEffect(() => {
        scrollTo();
        // eslint-disable-next-line
    }, []);

    return (
        <PaywallWrapper>
            <PaywallHeader>
                <SadIcon width="32" height="32" />
                <p>Você ainda não tem permissão para acessar esse conteúdo.</p>
            </PaywallHeader>

            <PaywallScroll center={mockPaywall?.length === 1}>
                {mockPaywall.map((item, index) => (
                    <PaywallScrollItem key={index} {...(item.recommended && { ref: recommendedRef })}>
                        <PaywallCard>
                            <PaywallCardWave>
                                <Icon idIcon="ico-paywall-wave" width="217" height="114.7" viewBox="0 0 217 114.7" />
                            </PaywallCardWave>
                            {item.recommended && (
                                <PaywallRecommended>
                                    <span>Recomendado</span>
                                </PaywallRecommended>
                            )}
                            <PaywallCardHeader>
                                <strong>{item.name}</strong>
                                <p>Acesso até {item.timeOut}</p>
                            </PaywallCardHeader>

                            <PaywallCardContent>
                                <PaywallCardList>
                                    {item.credits.map((credit, index) => (
                                        <PaywallCardListItem key={`credit_${index}`}>
                                            <strong>{credit.value}</strong>
                                            <p>{credit.name}</p>
                                        </PaywallCardListItem>
                                    ))}
                                </PaywallCardList>

                                <PaywallCardPrice>
                                    <PaywallCardPriceInstallments>
                                        {!!item.price.oldValue && (
                                            <PaywallCardPriceStrike>De: R$ {item.price.oldValue.toFixed(2).replace(".", ",")}</PaywallCardPriceStrike>
                                        )}
                                        <span>{item.price.installments}x de</span>
                                        <strong>R${item.price.value.toFixed(2).replace(".", ",")}</strong>
                                    </PaywallCardPriceInstallments>
                                </PaywallCardPrice>

                                <PaywallButton href={item.url} target="_blank">
                                    Eu Quero <HappyIcon width="14" height="14" />
                                </PaywallButton>
                                {/* <Button block={true} variant="secondary">
                                    Eu Quero <HappyIcon width="14" height="14" />
                                </Button> */}
                            </PaywallCardContent>
                        </PaywallCard>
                    </PaywallScrollItem>
                ))}
            </PaywallScroll>
        </PaywallWrapper>
    );
};

export default Paywall;
