import React, { useState, useEffect, useMemo, useCallback } from "react";

// Components
import BreadCrumb from "components/BreadCrumb";

// Helpers
import { Link } from "react-router-dom";
import { IWatchLessonPlanModule } from "store/ducks/watch";
import { EventCategory } from "enums/EventCategory";
import dictionary from "helpers/dictionary";
import { useParams } from "react-router";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as weekPlansActions } from "store/ducks/weekPlans";
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import _findIndex from "lodash/findIndex";
import _find from "lodash/find";
import api from "services/api";

interface IWatchScreenBreadCrumbProps {
    subject?: { name: string; slug: string; color: string };
    lessonPlanModule?: IWatchLessonPlanModule;
    category: number;
    eventId: number;
    moduleId: number;
    bookletModuleName: string;
}

const WatchScreenBreadCrumb = ({ subject, lessonPlanModule, category, moduleId, eventId, bookletModuleName }: IWatchScreenBreadCrumbProps) => {
    const { idWeek, idWeekDay, idDayPeriod, idDayPeriodItem, idModule } = useParams();

    const [selectedPeriodName, setSelectedPeriodName] = useState("...");
    const [selectedModuleName, setSelectedModuleName] = useState("...");
    const [nameModuleSelected, setNameModuleSelected] = useState("...");
    const [idDayPeriodItemInitial, setIdDayPeriodItemInitial] = useState<number>();

    const { lessonPlanCustom, lessonPlanSectionWeekPlanList, lessonPlanSection } = useSelector(
        ({ lessonPlanCustom }: IReduxStore) => lessonPlanCustom
    );
    const { currentLessonPlan, currentWeek, modules, weeks } = useSelector(({ weekPlans }: IReduxStore) => weekPlans);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const dispatch = useDispatch();

    const getLessonPlanCustom = useCallback(() => dispatch(lessonPlanCustomActions.getLessonPlanCustomRequest()), [dispatch]);
    const getWeekPlanList = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionWeekPlanListRequest({ id })), [dispatch]);
    const getDaysShort = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionRequest({ id })), [dispatch]);
    const getCurrentSuggestedPlan = useCallback(() => {
        dispatch(weekPlansActions.getWeekPlansCoursesRequest({}));
    }, [dispatch]);
    const getCurrentWeekSuggestedPlan = useCallback((id) => dispatch(weekPlansActions.getWeekPlansWeeksRequest({ id })), [dispatch]);
    const getCurrentWeekModulesSuggestedPlan = useCallback((id) => dispatch(weekPlansActions.getWeekPlansCurrentModuleRequest({ id })), [dispatch]);

    const getWeekSelected = _findIndex(lessonPlanSectionWeekPlanList, ["id", Number(idWeek)]);
    const getDayShortSelected = _findIndex(lessonPlanSection, ["id", Number(idWeekDay)]);
    const getModuleSelected = _findIndex(modules, ["id", Number(idModule)]);

    useEffect(() => {
        if (weeks.length > 0 && !currentWeek?.id && !!idWeek) {
            const currentWeekTmp = weeks.find((week) => week.id === Number(idWeek));

            dispatch(weekPlansActions.setWeekPlansCurrentWeek(currentWeekTmp as any));
        }
    }, [currentWeek, dispatch, idWeek, weeks]);

    useEffect(() => {
        if (getModuleSelected !== -1) {
            setNameModuleSelected(modules[getModuleSelected].name);
        }
    }, [getModuleSelected, modules]);

    useEffect(() => {
        getCurrentSuggestedPlan();
    }, [getCurrentSuggestedPlan]);

    useEffect(() => {
        if (!!currentLessonPlan?.id) {
            getCurrentWeekSuggestedPlan(currentLessonPlan.id);
        }
    }, [getCurrentWeekSuggestedPlan, currentLessonPlan]);

    useEffect(() => {
        if (!!currentWeek?.id) {
            getCurrentWeekModulesSuggestedPlan(currentWeek.id);
        }
    }, [getCurrentWeekModulesSuggestedPlan, currentWeek]);

    const getContentItems = useCallback(async () => {
        const { data: periodData } = await api.get(`/student/lessonplangenericsection/${idWeekDay}/section`);
        const { data: moduleData } = await api.get(`/student/lessonplangenericsection/${idDayPeriod}/section`);

        const selectedPeriod = _find(periodData, ["id", +idDayPeriod]);
        setSelectedPeriodName(selectedPeriod?.name);

        const selectedModule = _find(moduleData, ["id", +idDayPeriodItem]);
        setSelectedModuleName(selectedModule?.name);
        setIdDayPeriodItemInitial(moduleData[0].id);
    }, [idDayPeriodItem, idDayPeriod, idWeekDay]);

    useEffect(() => {
        if (!!lessonPlanSection.length) {
            getContentItems();
        }
    }, [getContentItems, lessonPlanSection.length]);

    const loadDataPlanCustom = useCallback(() => {
        if (idWeek) {
            if (!lessonPlanCustom.length) {
                getLessonPlanCustom();
            }

            if (!!lessonPlanCustom.length) {
                const idPlan = lessonPlanCustom[0]?.id;
                getWeekPlanList(idPlan);
            }

            getDaysShort(idWeek);
        }
    }, [getDaysShort, getLessonPlanCustom, getWeekPlanList, idWeek, lessonPlanCustom]);

    useEffect(() => {
        if (!!idDayPeriodItem) {
            loadDataPlanCustom();
        }
    }, [idDayPeriodItem, loadDataPlanCustom]);

    useEffect(() => {
        return () => {
            dispatch(lessonPlanCustomActions.clearLessonPlanCustom());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(weekPlansActions.clearWeekPlans());
        };
    }, [dispatch]);

    const renderByType = useMemo(() => {
        try {
            if (!!moduleId && !!eventId && !!subject?.slug) {
                return (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/materias`}>Matérias</Link>
                        </BreadCrumb.Item>
                        <BreadCrumb.Separator />
                        {!!subject && (
                            <BreadCrumb.Item>
                                <Link to={`/app/curso/${courseSlug}/materias/${subject?.slug}`}>{subject?.name}</Link>
                            </BreadCrumb.Item>
                        )}
                        <BreadCrumb.Separator />
                        {!!bookletModuleName && (
                            <>
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/materias/${subject?.slug}/${moduleId}`}>{bookletModuleName}</Link>
                                </BreadCrumb.Item>
                                <BreadCrumb.Separator active />
                            </>
                        )}
                        <BreadCrumb.Item active>
                            {
                                // @ts-ignore
                                dictionary[EventCategory[category]]
                            }
                        </BreadCrumb.Item>
                    </>
                );
            }

            if (!!subject && typeof subject === "object" && !!idDayPeriodItem) {
                return (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p`}>Meu plano de estudos</Link>
                        </BreadCrumb.Item>

                        {!!idWeek && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}`}>
                                        {lessonPlanSectionWeekPlanList[getWeekSelected]?.name
                                            ? lessonPlanSectionWeekPlanList[getWeekSelected]?.name
                                            : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}`}>
                                        {lessonPlanSection[getDayShortSelected]?.name ? lessonPlanSection[getDayShortSelected]?.name : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && !!selectedPeriodName && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link
                                        to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${idDayPeriod}/${idDayPeriodItemInitial}`}
                                    >
                                        {selectedPeriodName ? selectedPeriodName : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && (
                            <>
                                <BreadCrumb.Separator active />
                                <BreadCrumb.Item active>
                                    <Link
                                        to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${idDayPeriod}/${idDayPeriodItem}`}
                                    >
                                        {selectedModuleName ? selectedModuleName : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}
                    </>
                );
            }

            if (!!subject && typeof subject === "object" && !!idModule) {
                return (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s`}>Meu plano de estudos</Link>
                        </BreadCrumb.Item>

                        {!!currentWeek && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s/${currentWeek.id}`}>
                                        {currentWeek.title ? currentWeek.title : "..."}
                                    </Link>
                                </BreadCrumb.Item>

                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s/${currentWeek.id}/${idModule}`}>
                                        {nameModuleSelected}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}
                    </>
                );
            }

            if (!!subject && typeof subject === "object" && !eventId) {
                return (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/materias`}>Matérias</Link>
                        </BreadCrumb.Item>
                        <BreadCrumb.Separator />
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/materias/${subject?.slug}`}>{subject?.name}</Link>
                        </BreadCrumb.Item>
                        <BreadCrumb.Separator />
                        {!!bookletModuleName && (
                            <>
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/materias/${subject?.slug}/${moduleId}`}>{bookletModuleName}</Link>
                                </BreadCrumb.Item>
                                <BreadCrumb.Separator active />
                            </>
                        )}
                        <BreadCrumb.Item active>Videos Relacionados</BreadCrumb.Item>
                    </>
                );
            }

            if (!!lessonPlanModule) {
                return (
                    <>
                        <BreadCrumb.Item>
                            <Link to="/" data-test-id="video-lesson-plan">
                                Plano de estudos
                            </Link>
                        </BreadCrumb.Item>
                        <BreadCrumb.Separator />
                        {!!lessonPlanModule && (
                            <BreadCrumb.Item>
                                <Link to={`/app/curso/${courseSlug}/plano-de-estudos/${lessonPlanModule.id}`} data-test-id="video-lesson-plan-name">
                                    {lessonPlanModule.name}
                                </Link>
                            </BreadCrumb.Item>
                        )}
                        <BreadCrumb.Separator active />
                        <BreadCrumb.Item active data-test-id="video-lesson-plan-category">
                            {
                                // @ts-ignore
                                dictionary[EventCategory[category]]
                            }
                        </BreadCrumb.Item>
                    </>
                );
            }
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [
        moduleId,
        eventId,
        subject,
        idDayPeriodItem,
        idModule,
        lessonPlanModule,
        courseSlug,
        bookletModuleName,
        category,
        idWeek,
        lessonPlanSectionWeekPlanList,
        getWeekSelected,
        idWeekDay,
        lessonPlanSection,
        getDayShortSelected,
        selectedPeriodName,
        idDayPeriod,
        idDayPeriodItemInitial,
        selectedModuleName,
        currentWeek,
        nameModuleSelected
    ]);

    return <BreadCrumb>{renderByType}</BreadCrumb>;
};

export default React.memo(WatchScreenBreadCrumb);
