import React, { memo, useState } from "react";

// Components
import Button from "components/Button";
import TextField from "components/Form/TextField";

// Helpers
import { useForm } from "hooks/use-form";
import { IChangePassword } from "store/ducks/student-area";
import { IOnSubmitActionStudentArea } from "../..";

// Assets
import { StudentAreaFormContainer, StudentInputRow } from "../styles";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { StudentInfoCard } from "./styles";

type PasswordInput = { name: keyof IChangePassword; value: string };

const INITIAL_FORM_STATE: IChangePassword = {
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: ""
};

const ChangePasswordForm = ({ onSubmitAction }: IOnSubmitActionStudentArea) => {
    const [form, handleChange] = useForm<IChangePassword>(INITIAL_FORM_STATE);
    const [newPasswordError, setNewPasswordError] = useState("");
    const [passwordConfirmationError, setPasswordConfirmationError] = useState("");
    const { showStudentInfoAboutEmail, showStudentEmail } = useSelector(({ studentArea }: IReduxStore) => ({
        showStudentInfoAboutEmail: studentArea?.isSuccess,
        showStudentEmail: studentArea?.confirmEmail
    }));

    const checkIfPasswordsMatch = ({ name, value }: PasswordInput) => {
        const otherPasswordName = name === "newPassword" ? "passwordConfirmation" : "newPassword";
        if (value === form[otherPasswordName] || form.newPassword === "" || form.passwordConfirmation === "") {
            setPasswordConfirmationError("");
        } else {
            setPasswordConfirmationError("Senhas diferentes.");
        }
    };

    const onHandleChangePasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
        checkIfPasswordsMatch({ name: e.currentTarget.name, value: e.currentTarget.value } as PasswordInput);
        handleChange(e);
    };

    const onHandleChangeNewPassword = (e: React.ChangeEvent<HTMLInputElement>, lengthLimit = 6) => {
        onHandleChangePasswordConfirmation(e);
        if (e.currentTarget.value.length >= lengthLimit || e.currentTarget.value === "") {
            setNewPasswordError("");
        } else {
            setNewPasswordError(`No mínimo ${lengthLimit} caracteres.`);
        }
    };

    const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmitAction({ form, type: "change-password" });
    };

    return (
        <StudentAreaFormContainer onSubmit={onSubmitForm}>
            <StudentInputRow>
                <TextField label="Senha atual" name="oldPassword" type="password" value={form.oldPassword} onChange={handleChange} />
            </StudentInputRow>
            <StudentInputRow>
                <TextField
                    label="Nova Senha"
                    name="newPassword"
                    type="password"
                    value={form.newPassword}
                    error={newPasswordError}
                    onChange={onHandleChangeNewPassword}
                />
            </StudentInputRow>
            <StudentInputRow>
                <TextField
                    label="Confirmar Senha"
                    name="passwordConfirmation"
                    type="password"
                    value={form.passwordConfirmation}
                    error={passwordConfirmationError}
                    onChange={onHandleChangePasswordConfirmation}
                />
            </StudentInputRow>

            <Button
                variant="primary"
                block={true}
                disabled={!form.oldPassword || !form.newPassword || !form.passwordConfirmation}
                size="medium"
                type="submit"
            >
                <span>Salvar</span>
            </Button>
            {showStudentInfoAboutEmail && (
                <StudentInfoCard>
                    <span>
                        Olá, como medida de segurança enviamos um link por e-mail para <strong>{showStudentEmail}</strong> solicitando a confirmação
                        da troca de senha. Esta mudança só é efetivada quando você clica no link enviado. Caso a mensagem não esteja na sua caixa de
                        entrada, verifique por favor sua caixa de spam.
                    </span>
                </StudentInfoCard>
            )}
        </StudentAreaFormContainer>
    );
};

export default memo(ChangePasswordForm);
