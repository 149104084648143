import React, { useMemo, useCallback } from "react";

// import BoletoIcon from "prodigio-icons/web/filled/Boleto";
import CursosIcon from "prodigio-icons/web/filled/Cursos";
import LockedIcon from "prodigio-icons/web/filled/Locked";
import UserIcon from "prodigio-icons/web/filled/User";
import CursosOutlinedIcon from "prodigio-icons/web/outlined/Cursos";
import LockedOutlinedIcon from "prodigio-icons/web/outlined/Locked";
// import PayOutlinedIcon from "prodigio-icons/web/outlined/Pay";
import UserOutlinedIcon from "prodigio-icons/web/outlined/User";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";
import EditIcon from "prodigio-icons/web/outlined/Edit";

import Avatar from "components/Avatar";
import Icon from "components/Icons/icon";
import useIsMobile from "hooks/use-is-mobile";

import history from "services/history";

import {
    StudentAreaScreenHomeContentContainer,
    StudentAreaScreenAvatarWrapper,
    StudentAreaScreenCardContainer,
    StudentAreaScreenEditIconContainer,
    StudentAreaScreenName,
    StudentAreaScreenProfileAvatarContainer,
    StudentAreaScreenProfileContainer,
    StudentAreaScreenStudentButton,
    StudentAreaScreenBackgroundWave,
    StudentAreaScreenStudentButtonText,
    StudentAreaScreenProfileTitle,
    StudentAreaScreenMyAccountContainer,
    StudentAreaScreenStudentButtonsContainer
} from "./styles";
import { IOnSubmitActionStudentArea } from "..";
import { StudentAreaScreenTypes } from "..";
import { BreakPoints } from "assets/styles/settings";
import { theme } from "config/theme";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IHomeProps {
    screen?: StudentAreaScreenTypes;
    name: string;
    imageProfile: string | null;
}

const StudentAreaScreenHome = ({ screen, name, imageProfile, onSubmitAction }: IHomeProps & IOnSubmitActionStudentArea) => {
    const isMobile = useIsMobile(BreakPoints.small);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const onFileChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files) {
                onSubmitAction({ form: e.target.files, type: "profile" });
            }
        },
        [onSubmitAction]
    );

    const menuButtons = useMemo(() => {
        try {
            const buttons = [
                {
                    path: `/app/curso/${courseSlug}/area-do-aluno/perfil/edicao`,
                    icon: screen === "edit-personal" ? <UserIcon width={18} /> : <UserOutlinedIcon width={18} />,
                    label: "Dados pessoais"
                },
                {
                    path: `/app/curso/${courseSlug}/area-do-aluno/perfil/alterar-senha`,
                    icon: screen === "change-password" ? <LockedIcon width={15} /> : <LockedOutlinedIcon width={15} />,
                    label: "Senha"
                },
                {
                    path: `/app/curso/${courseSlug}/area-do-aluno/perfil/assinaturas`,
                    icon: screen === "signed-courses" ? <CursosIcon width={18} /> : <CursosOutlinedIcon width={18} />,
                    label: "Assinaturas"
                }
            ];

            return buttons.map((button, index) => (
                <StudentAreaScreenStudentButton
                    key={`button_${index}`}
                    onClick={() => {
                        history.push(button.path);
                    }}
                >
                    {button.icon}
                    <StudentAreaScreenStudentButtonText>{button.label}</StudentAreaScreenStudentButtonText>
                    <ChevronRight />
                </StudentAreaScreenStudentButton>
            ));
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [courseSlug, screen]);

    const profileMemo = useMemo(() => {
        try {
            return (
                <StudentAreaScreenProfileContainer id="StudentAreaScreenProfileContainer">
                    <StudentAreaScreenProfileAvatarContainer id="StudentAreaScreenProfileAvatarContainer">
                        <StudentAreaScreenAvatarWrapper id="StudentAreaScreenAvatarWrapper" isMobile={isMobile}>
                            <Avatar src={imageProfile ? imageProfile : ""} initialUserChar={name.charAt(0)} />
                            <StudentAreaScreenEditIconContainer htmlFor="fileInput">
                                <EditIcon width="20px" height="20px" />
                                <input id="fileInput" onChange={onFileChange} type="file" style={{ display: "none" }} />
                            </StudentAreaScreenEditIconContainer>
                        </StudentAreaScreenAvatarWrapper>
                        <StudentAreaScreenName>{name}</StudentAreaScreenName>
                    </StudentAreaScreenProfileAvatarContainer>
                    {isMobile &&
                        (theme.project.slug !== "promilitares" ? (
                            <StudentAreaScreenBackgroundWave>
                                <Icon width="100%" height="100%" idIcon="ico-wave-descend" viewBox="0 0 320 168" />
                            </StudentAreaScreenBackgroundWave>
                        ) : (
                            <div className="promilitares-shape">
                                <svg viewBox="0 0 320 161" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0v160.756l320-64.8V0H0z" fill={theme.colors.brand[300]} />
                                </svg>
                            </div>
                        ))}
                </StudentAreaScreenProfileContainer>
            );
        } catch (error) {
            return null;
        }
    }, [imageProfile, isMobile, name, onFileChange]);

    return (
        <StudentAreaScreenCardContainer id="StudentAreaScreenCardContainer">
            <StudentAreaScreenHomeContentContainer id="StudentAreaScreenHomeContentContainer">
                {profileMemo}
                <StudentAreaScreenMyAccountContainer>
                    <StudentAreaScreenProfileTitle>Minha conta</StudentAreaScreenProfileTitle>

                    <StudentAreaScreenStudentButtonsContainer>{menuButtons}</StudentAreaScreenStudentButtonsContainer>
                </StudentAreaScreenMyAccountContainer>
            </StudentAreaScreenHomeContentContainer>
        </StudentAreaScreenCardContainer>
    );
};

export default StudentAreaScreenHome;
