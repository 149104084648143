// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { TooltipProps } from "./tooltip.types";

// Styles
import { Container, Label, Arrow } from "./tooltip.styles";

// Helpers
import { cutString } from "helpers/cut-string";

export const Tooltip: FunctionComponent<TooltipProps> = ({ message, arrowPosition, size = "large" }) => {
    return (
        <Container arrowPosition={arrowPosition}>
            <Label size={size}>{cutString(message, 250)}</Label>

            <Arrow />
        </Container>
    );
};
