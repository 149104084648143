import React, { memo } from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import _get from "lodash/get";

import Competence from "../competences/competence";
import Comment from "./comment";
import Slider from "./Slider";

import * as S from "./styles";
interface IEssayCommentsProps {
    comment: number;
    onChange(index: number, comment: number): void;
    composition?: IEssayComposition;
}

const Comments = ({ comment, composition, onChange }: IEssayCommentsProps) => {
    return (
        <S.EssayCommentsContainer>
            <S.EssayCommentsHeader>
                <S.EssayCommentsSliderWrapper>
                    <Slider composition={composition} comment={comment} onChange={onChange} />
                </S.EssayCommentsSliderWrapper>
            </S.EssayCommentsHeader>
            {!!composition?.comments?.length && (
                <S.EssayCommentsContent>
                    <S.EssayCommentsContentTitle color={composition?.comments[comment]?.competence?.color}>
                        <Competence
                            item={{
                                name: _get(composition, `comments[${comment}].competence.title`, "Sem título"),
                                description: _get(composition, `comments[${comment}].competence.description`, "Nenhuma descrição"),
                                color: _get(composition, `comments[${comment}].competence.color`, ""),
                                note: 0,
                                noteMaximum: 0
                            }}
                            withContent={false}
                        />
                    </S.EssayCommentsContentTitle>
                    <Comment composition={composition} commentIndex={comment} />
                </S.EssayCommentsContent>
            )}
        </S.EssayCommentsContainer>
    );
};

export default memo(Comments);
