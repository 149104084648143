// Dependencies
import React, { FunctionComponent, useEffect } from "react";

// Types
import { OrdenatorProps } from "./ordenator.types";

// Styles
import { Container, LabelWrapper, Icon, Label, OrdenatorOptionsWrapper } from "./ordenator.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Asset } from "component-library/utilities/asset";

export const Ordenator: FunctionComponent<OrdenatorProps> = ({
    ordenatorIcon,
    label,
    ordenatorOptionsComposition,
    isOrdenatorOpened,
    iconOrdenatorClosed,
    iconOrdenatorOpen,
    handleClick
}) => {
    useEffect(() => {
        const handleWindowClick = (event: any) => {
            if (
                !event.target?.closest(LabelWrapper) &&
                !event.target?.closest(OrdenatorOptionsWrapper) &&
                isOrdenatorOpened &&
                window.innerWidth >= 768
            ) {
                handleClick();
            }
        };

        window.addEventListener("click", handleWindowClick);

        return () => {
            window.removeEventListener("click", handleWindowClick);
        };
    }, [isOrdenatorOpened, handleClick]);

    return (
        <Container>
            <LabelWrapper onClick={handleClick}>
                <ConditionallyRender shouldRender={!!ordenatorIcon} content={<Icon src={ordenatorIcon} />} />

                <Label>{label}</Label>

                <Asset
                    source={isOrdenatorOpened ? iconOrdenatorOpen : iconOrdenatorClosed}
                    color={isOrdenatorOpened ? "#571D5D" : "#7C8195"}
                    size={20}
                />
            </LabelWrapper>

            <ConditionallyRender
                shouldRender={!!ordenatorOptionsComposition && isOrdenatorOpened}
                content={
                    <>
                        <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={ordenatorOptionsComposition} />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={768}
                            content={<OrdenatorOptionsWrapper>{ordenatorOptionsComposition}</OrdenatorOptionsWrapper>}
                        />
                    </>
                }
            />
        </Container>
    );
};
