// Dependencies
import React, { FunctionComponent, MouseEvent, useRef, useState } from "react";

// Types
import { ActivityMenuItemProps, ActivityMenuProps } from "./activity-menu.types";

// Styles
import { FloatContainer, Icon, MenuContainer, MenuItemContainer, MenuItemLabel } from "./activity-menu.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Assets
import dotsCircle from "assets/img/activity-dots-circle.svg";

// Utils
import useOnClickOutside from "hooks/use-on-click-outside";

export const ActivityMenu: FunctionComponent<ActivityMenuProps> = ({ floatContainerAlignment, floatContainerWidth, itemsCompositions }) => {
    const menuContainerRef = useRef<HTMLDivElement>(null);

    const [isOpened, setIsOpened] = useState(false);

    useOnClickOutside(menuContainerRef, () => setIsOpened(false));

    const handleMenuClicked = (event: MouseEvent) => {
        event.stopPropagation();
        setIsOpened(!isOpened);
    };

    return (
        <MenuContainer ref={menuContainerRef}>
            <Icon src={dotsCircle} onClick={handleMenuClicked} />

            <ConditionallyRender
                shouldRender={!!isOpened}
                content={
                    <FloatContainer width={floatContainerWidth} alignment={floatContainerAlignment}>
                        {itemsCompositions}
                    </FloatContainer>
                }
            />
        </MenuContainer>
    );
};

export const ActivityMenuItem: FunctionComponent<ActivityMenuItemProps> = ({ label, handleClick }) => {
    const handleMenuItemClicked = (event: MouseEvent) => {
        event.stopPropagation();
        handleClick();
    };

    return (
        <MenuItemContainer onClick={handleMenuItemClicked}>
            <MenuItemLabel title={label}>{label}</MenuItemLabel>
        </MenuItemContainer>
    );
};
