// Dependencies
import React, { ChangeEvent, FunctionComponent, useRef } from "react";

// Types
import { FileInputProps } from "./file-input.types";

// Styles
import { Container, FileInputField } from "./file-input.styles";

export const FileInput: FunctionComponent<FileInputProps> = ({ maximumFilesSize, allowedFileTypes, onFileUpload, handleError, content }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = (event: any) => {
        event?.stopPropagation();

        if (inputRef.current) {
            inputRef.current.value = "";
        }

        inputRef.current?.click();
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (files && files.length > 0 && onFileUpload) {
            if (maximumFilesSize) {
                const maxFileSizeInBytes = maximumFilesSize * 1000 * 1000;
                let exceededSizeLimit = false;

                Array.from(files).forEach((file) => {
                    if (file.size > maxFileSizeInBytes) {
                        exceededSizeLimit = true;
                    }
                });

                if (exceededSizeLimit && !!handleError) {
                    handleError();

                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }

                    return;
                }
            }

            onFileUpload(files);
        }
    };

    return (
        <Container onClick={handleClick}>
            <FileInputField
                ref={inputRef}
                type="file"
                multiple
                onChange={handleFileChange}
                {...(!!allowedFileTypes && { accept: allowedFileTypes.map((fileType) => `.${fileType}`).join(", ") })}
            />

            {content}
        </Container>
    );
};
