import dots from "assets/img/three_dots.svg";
import React, { useCallback } from "react";
//store
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as modalActions } from "store/ducks/modal";
import { WebpText } from "components/WebpText";

import { WebpQuestionReportCard } from "components/WebpQuestionReportCard";
import { IReportCardQuestion } from "../../../../interfaces/IExerciseListCustom";
import { Types } from "../../../../store/ducks/exerciseListCustom";
import { Chart } from "./styles";
import api from "../../../../services/api";
import { IReduxStore } from "interfaces/IReduxStore";

function ReportCardQuestion({ card, chart, modalActions }: IReportCardQuestion) {
    const dispatch = useDispatch();
    const history = useHistory();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const confirmRemove = useCallback(() => {
        dispatch({ type: Types.DELETE_LIST_REQUEST, payload: card.listId });
        modalActions?.closeModal();
    }, [card.listId, dispatch, modalActions]);

    const onRemoveCardQuestionReport = useCallback(() => {
        modalActions?.openModal("confirmAction", {
            text: <p>Tem certeza que deseja excluir essa lista?.</p>,
            onConfirm: () => confirmRemove(),
            variant: "warning",
            buttonText: "Excluir"
        });
    }, [confirmRemove, modalActions]);

    const handleCardButtonClick = () => {
        if (card.status === 0 || card.status === 1) {
            return history.push(`/app/curso/${courseSlug}/banco-de-questoes/lista-de-exercicios/${card.listId}/estudo/${card.answerCardId}`);
        }
        dispatch({ type: Types.CREATE_ANSWER_CARD_REQUEST, payload: card.listId });
    };

    const handleNavigateToExerciseDashboard = () => {
        history.push(`/app/curso/${courseSlug}/banco-de-questoes/lista-de-exercicios/${card.listId}`);
    };

    const downloadPDF = useCallback(async () => {
        try {
            dispatch(
                modalActions &&
                    modalActions.openModal("confirmAction", {
                        type: "OPEN_MODAL",
                        hasCloseButton: true,
                        hasCancelButton: false,
                        widthContainer: "313",
                        title: "Seu download começará em alguns segundos...",
                        variant: "success"
                    })
            );
            await api
                .get(`/student/exerciselist/${card.listId}/report`, {
                    responseType: "blob"
                })
                .then((blob) => {
                    new File([blob.data], "file");
                    const url = window.URL.createObjectURL(blob.data);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `Minha-lista-de-exercicio-${card.name}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });

            dispatch(modalActions && modalActions.closeModal());
        } catch (error) {
            console.log(error);
        }
    }, [card.listId, card.name, dispatch, modalActions]);

    const answerCardDate = card.answerCardDateStatus;

    return (
        <WebpQuestionReportCard
            isNew={card.isNew}
            badgeContent={card.badgeContent || ""}
            name={card.name || ""}
            subname={card.subname || ""}
            webpButtonFooter={card.webpButtonFooter}
            webpButtonHeaderComposition={<img src={dots} alt="Dots icon" />}
            webpDropdownComposition={
                <ul className="webp-dropdown-children">
                    <li onClick={() => onRemoveCardQuestionReport()}>Excluir lista</li>
                    <li onClick={downloadPDF}>Exportar para PDF</li>
                    <li onClick={handleNavigateToExerciseDashboard}>Histórico de realizações</li>
                </ul>
            }
            webpBodyComposition={
                <div className="body">
                    <Chart {...chart} className="chartItem">
                        <WebpText xs="microcopy" color="neutral" colorWeight={500}>
                            <p style={{ marginBottom: "0.5rem" }}>{answerCardDate}</p>
                        </WebpText>
                    </Chart>
                </div>
            }
            cardButtonClicked={() => handleCardButtonClick()}
        ></WebpQuestionReportCard>
    );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(modalActions, dispatch)
});

export default connect(null, mapDispatchToProps)(ReportCardQuestion);
