// Dependecies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    font-family: "Nunito Sans";

    @media (min-width: 768px) {
        width: auto;
        height: auto;
    }

    .textarea-button {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        gap: 8px;

        @media (min-width: 768px) {
            gap: 24px;
        }
    }

    .textarea {
        height: 100%;
        border: solid 1px ${theme.colors.base[100]} !important;
        border-radius: 8px;
        font-family: "Nunito Sans";
        padding: 10px;
        border: none;
        font-size: 16px;
        color: ${theme.colors.base[400]};

        @media (min-width: 768px) {
            min-height: 90px;
            max-width: 100%;
        }

        &:active,
        &:focus {
            border: solid 1px ${theme.colors.base[100]} !important;
            outline: 0;
        }
    }

    .screen {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.white};
        padding: 41px 16px;

        @media (min-width: 768px) {
            display: flex;
            width: 485px;
            min-height: 258px;
            height: fit-content;
            height: -moz-fit-content;
            flex-direction: column;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 24px;
            background-color: ${theme.colors.white};
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            .button-close {
                color: ${theme.colors.base[400]};
                border: none;
                background-color: transparent;
                padding: 0;
                cursor: pointer;
                height: fit-content;
                height: -moz-fit-content;
            }

            .title {
                font-size: 24px;
                font-weight: 700;
                color: ${theme.colors.base[500]};
                line-height: 33px;
            }
        }
    }
`;
