import React, { memo, useCallback, useEffect, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import ForumQuestion from "./question";
import Spinner from "components/Spinner";
import ForbiddenMessage from "components/ForbiddenMessage";

// Helpers
import { useSelector } from "store";
import history from "services/history";
import { Types } from "store/ducks/forum";
import { IForumNewPayload } from "../new/new";
import { IForumUpload, ILikePayload, UploadType } from "store/interfaces/IForum";

// Assets
import { LoaderWrapper } from "./styles";
import { NotFoundProject as ForbiddenContainer } from "screens/Projects/Details/styles";

const ForumQuestionScreen = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const [isFetched, setIsFetched] = useState(false);

    const { forum, userName, courseSlug } = useSelector(({ auth, forum, course }) => {
        const { filters, questions, isLoadingForumSubjects, ...rest } = forum;

        return {
            userName: auth.credentials.username || "",
            forum: rest,
            courseSlug: course?.slug
        };
    });

    const getQuestion = useCallback(() => {
        dispatch({ type: Types.GET_QUESTION_REQUEST, payload: { id } });
    }, [dispatch, id]);

    useEffect(() => {
        if (!isFetched && !forum.question._id && id) {
            getQuestion();
            setIsFetched(true);
        }
    }, [getQuestion, id, isFetched, forum.question._id]);

    const handleToggleLike = useCallback(
        (key: UploadType, isLiked: boolean, id: string) => {
            const payload: ILikePayload = {
                id,
                endpoint: `/${key}/${id}/like`,
                method: isLiked ? "DELETE" : "POST"
            };

            dispatch({ type: "LIKE_REQUEST", payload });
        },
        [dispatch]
    );

    const handlePost = useCallback((payload: IForumNewPayload) => dispatch({ type: Types.CREATE_OR_UPDATE_QUESTION_REQUEST, payload }), [dispatch]);

    const handleUpload = useCallback(
        (key: UploadType) => (files: File[]) => {
            const payload = {
                key,
                attachments: [
                    ...forum.uploads[key].map((item) => ({
                        ...item.file,
                        ...(item.id && { id: item.id })
                    })),
                    ...files
                ]
            };

            dispatch({ type: Types.UPLOAD_ATTACHMENTS_REQUEST, payload });
            dispatch({ type: "CLOSE_MODAL" });
        },
        [dispatch, forum.uploads]
    );

    const handleAttachFiles = useCallback(
        (key: UploadType) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "upload",
                action: {
                    onUploadFile: handleUpload(key)
                }
            });
        },
        [dispatch, handleUpload]
    );

    const handleRedirectToForum = useCallback(() => history.push({ pathname: `/app/curso/${courseSlug}/forum` }), [courseSlug]);

    const handleRemoveAttachment = useCallback((id: number, key: UploadType) => dispatch({ type: Types.DELETE_ATTACHMENT, payload: { key, id } }), [
        dispatch
    ]);

    const handleRetryUpload = useCallback(
        (attachment: IForumUpload, key: UploadType) => dispatch({ type: Types.RETRY_UPLOAD_ATTACHMENTS_REQUEST, payload: { key, attachment } }),
        [dispatch]
    );

    const handleDelete = useCallback((id: string) => dispatch({ type: Types.DELETE_QUESTION_REQUEST, payload: { id } }), [dispatch]);

    const handleConfirmDelete = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "confirmAction",
                action: {
                    title: "Atenção",
                    text: "Tem certeza que deseja apagar este conteúdo?",
                    variant: "outline-warning",
                    buttonText: "Confirmar",
                    onConfirm: () => handleDelete(id)
                }
            });
        },
        [dispatch, handleDelete]
    );

    useEffect(() => {
        return () => {
            dispatch({ type: Types.CLEAR_FORUM_QUESTION });
        };
    }, [dispatch]);

    if (forum.isLoadingForum) {
        return (
            <LoaderWrapper>
                <Spinner />
            </LoaderWrapper>
        );
    }

    if (forum.question.hasError || forum.question.complaint) {
        const isComplained = Boolean(forum.question.complaint);

        return (
            <ForbiddenContainer>
                <ForbiddenMessage
                    title={isComplained ? "Acesso proibido" : undefined}
                    text={isComplained ? "Esta pergunta foi denunciada e não se encontra mais disponível" : "Pergunta não encontrada"}
                    variant="secondary"
                    ctaText="Voltar ao forum"
                    onClick={handleRedirectToForum}
                />
            </ForbiddenContainer>
        );
    }

    return (
        <ForumQuestion
            {...forum}
            userName={userName}
            onPostQuestion={handlePost}
            onDeleteQuestion={handleConfirmDelete}
            onAttachments={handleAttachFiles}
            onToggleLike={handleToggleLike}
            onRemoveAttachment={handleRemoveAttachment}
            onRetryUpload={handleRetryUpload}
        />
    );
};

export default memo(ForumQuestionScreen);
