import { createActions, createReducer } from "reduxsauce";
import { INotificationState, NotificationActionsType, INotificationCreators, INotifications } from "../../interfaces/INotification";

export const { Creators, Types } = createActions<Record<NotificationActionsType, NotificationActionsType>, INotificationCreators>({
    getNotificationsRequest: [],
    getNotificationsSuccess: ["payload"],
    getNotificationsFailure: [],

    setReadAllNotificationsRequest: [],
    setReadAllNotificationsSuccess: [],
    setReadAllNotificationsFailure: [],

    setViewedAllNotificationsRequest: [],
    setViewedAllNotificationsSuccess: [],
    setViewedAllNotificationsFailure: [],

    setReadByIdNotificationRequest: ["payload"],
    setReadByIdNotificationSuccess: [],
    setReadByIdNotificationFailure: []
});

const INITIAL_STATE: INotificationState = {
    isLoading: false,
    notifications: {
        all: [],
        notRead: []
    }
};

const getNotificationsRequest = () => ({ ...INITIAL_STATE, isLoading: true });

const getNotificationsSuccess = (state = INITIAL_STATE, action: { payload: INotifications }) => ({
    ...state,
    isLoading: false,
    notifications: {
        all: action.payload.all,
        notRead: action.payload.notRead
    }
});

const getNotificationsFailure = () => ({ ...INITIAL_STATE, isLoading: false });

export default createReducer(INITIAL_STATE, {
    [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
    [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
    [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure
});
