import { theme } from "config/theme";
import styled from "styled-components/macro";
import { PageContainer, TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";

export const SubjectScreenContainer = styled(PageContainer)`
    padding: 32px 0 24px;
`;

export const SubjectsHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 0 ${theme.spacing.smallX};

    h1 {
        ${TitleH3};
        color: ${theme.colors.base[500]};
    }
`;

export const Wrapper = styled.main`
    h2 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #7c8195;
        margin-left: 10px;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            font-size: 18px;
            font-weight: 900;
        }
    }
`;

export const SubjectsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
`;

export interface ISubjectListItemProps {
    color?: string;
}

export const SubjectsListItemMark = styled.span`
    position: absolute;
    left: 0px;
    top: 26px;
    width: 6px;
    height: 34px;
`;

export const SubjectsListItem = styled.li<ISubjectListItemProps>`
    width: 50%;

    ${SubjectsListItemMark} {
        background: ${({ color }) => `${color};`};
    }

    a {
        display: flex;
        flex-direction: column;
        margin: 10px 10px;
        background: #ffffff;
        border-radius: 10px;
        height: 100px;
        padding-top: 26px;
        padding-left: 16px;
        padding-right: 16px;
        position: relative;

        :hover {
            opacity: 0.8;
        }

        h3 {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 7px;

            ${({ color }) => `color: ${color};`}
        }

        p {
            font-size: 11px;
            line-height: 16px;
            color: #81818d;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 33.3%;

        a {
            height: 180px;
        }
    }
`;

export const SubjectsListItemDetails = styled.aside`
    display: none;
    justify-content: space-between;
    margin-bottom: 26px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
    }
`;

export const SubjectsListItemFinished = styled.aside`
    display: flex;
    justify-content: space-between;

    p:last-child {
        display: none;
    }
    ${MediaQueries.BIGGER_THAN_SMALL} {
        p:last-child {
            display: flex;
        }
    }
`;

export const SubjectsListItemStudyNow = styled.span`
    width: 100%;
    height: 40px;
    display: none;
    align-items: center;
    justify-content: center;
    background: #c4c4c4;
    border-radius: 0px 0px 10px 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 0px;
    bottom: 0px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
    }
`;
