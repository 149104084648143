import React, { useCallback, useEffect } from "react";
import { WebpButtonIcon } from "components/WebpButtonIcon";
import { WebpCard } from "components/WebpCard";
import { WebpTotalNumber } from "components/WebpTotalNumber";
import { WebpStudyPlanPeriodDay } from "components/WebpStudyPlanPeriodDay";

import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import history from "services/history";
import { theme } from "config/theme";
import { LessonPlanCustomSchedulesHeader, LessonPlanCustomSchedulesCalendar, LessonPlanCustomSchedulesWrapper } from "./styles";
import api from "services/api";
import { DateTime } from "luxon";

interface IDataOptions {
    days: IDataLabel[];
}

interface IDataLabel {
    labelDay: string;
    buttonIcon: IDataButton[];
}
interface IDataButton {
    id: number;
    label: string;
    setHour: number;
    selected: boolean;
}

interface IButtonImageCalendar {
    totalHours: number;
    dataOptions: IDataOptions;
    onHandleSetHour(time: number): void;
    hourRecommended: number;
    setHourRecommended(hourNumber: number): void;
    disabled: boolean;
}

const LessonPlanCustomSchedules = ({
    totalHours,
    onHandleSetHour,
    dataOptions,
    hourRecommended,
    setHourRecommended,
    disabled
}: IButtonImageCalendar) => {
    const { career, courseSlug } = useSelector(({ lessonPlanCustom, course }: IReduxStore) => ({
        career: lessonPlanCustom.career,
        courseSlug: course.slug
    }));
    const isBrandProenem = theme.project.brand === "proenem";

    const handleSetHour = useCallback(
        (evHover: number, evSelected: boolean) => {
            const timer = evSelected ? totalHours - evHover : totalHours + evHover;
            onHandleSetHour(timer);
        },
        [onHandleSetHour, totalHours]
    );

    useEffect(() => {
        if (!career && isBrandProenem) {
            history.push({ pathname: `/app/curso/${courseSlug}/meu-plano-de-estudos-wizard/escolha-sua-carreira` });
        }
    }, [career, courseSlug, isBrandProenem]);

    const getMinimumHoursRecommended = useCallback(async () => {
        const dateStart = localStorage.getItem("date-start");
        const dateEnd = localStorage.getItem("date-end");

        if (dateStart && dateEnd) {
            const startDate = DateTime.fromFormat(dateStart, "dd/LL/yyyy").toFormat("yyyy-MM-dd");

            const endDate = DateTime.fromFormat(dateEnd, "dd/LL/yyyy").toFormat("yyyy-MM-dd");

            const { data } = await api.get(`/student/lessonplangeneric/career/257/shifts?startDate=${startDate}&endDate=${endDate}`);

            setHourRecommended(data.hourNumber);
        }
    }, [setHourRecommended]);

    useEffect(() => {
        getMinimumHoursRecommended();
    }, [getMinimumHoursRecommended]);

    return (
        <LessonPlanCustomSchedulesWrapper>
            <WebpCard
                contentAlignment="center"
                headerComposition={
                    <LessonPlanCustomSchedulesHeader>
                        <p className="labelLesson">Escolha seus horários</p>
                        <p className="labelTitle">Selecione os períodos em que deseja estudar por semana</p>
                        <div className="divider" />
                        <WebpTotalNumber
                            stringMain="Horas estimadas:"
                            stringSecondary="Mínimo recomendado:"
                            recommendedNumber={hourRecommended}
                            currentNumber={totalHours}
                        />
                    </LessonPlanCustomSchedulesHeader>
                }
                contentComposition={
                    <LessonPlanCustomSchedulesCalendar>
                        {dataOptions.days.map((day, index) => (
                            <WebpStudyPlanPeriodDay
                                key={index}
                                labelDay={day.labelDay as any}
                                buttonIconComposition={day.buttonIcon.map((button, index) => (
                                    <WebpButtonIcon
                                        key={button.id}
                                        label={button.label as any}
                                        selectedButton={button.selected}
                                        disabled={disabled}
                                        onClickButtonIcon={() => {
                                            handleSetHour(button.setHour, button.selected);
                                            button.selected = !button.selected;
                                        }}
                                    />
                                ))}
                            ></WebpStudyPlanPeriodDay>
                        ))}
                    </LessonPlanCustomSchedulesCalendar>
                }
            ></WebpCard>
        </LessonPlanCustomSchedulesWrapper>
    );
};

export { LessonPlanCustomSchedules };
