import styled from "styled-components";
import { theme, menuColors } from "config/theme";

interface IMenuClassListStyleProps {
    expanded?: boolean;
    active?: boolean;
    menuExpanded?: boolean;
}

export const MenuClassListContainer = styled.nav`
    margin-top: 40px;

    h3 {
        font-family: ${theme.typography.family.secondary};
        font-size: 14px;
        line-height: 22px;
        color: ${menuColors.text || theme.colors.brand[300]};
        margin-left: 10px;
        font-weight: 600;
    }
`;

export const MenuCurrentCourse = styled.div<IMenuClassListStyleProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 54px;
    padding: ${theme.spacing.small};
    background: ${({ expanded }) => (expanded ? menuColors.activeItemBackground || theme.colors.base[100] : "transparent")};
    transition: background 0.3s;
    cursor: pointer;

    svg {
        fill: ${({ expanded }) => (expanded ? menuColors.activeText || theme.colors.base[400] : menuColors.text || theme.colors.base[400])};
        color: ${({ expanded }) => (expanded ? menuColors.activeText || theme.colors.base[400] : menuColors.text || theme.colors.base[400])};
        transition: all 0.3s;
    }

    p {
        font-weight: 600;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 22px;
        color: ${({ expanded }) => (expanded ? menuColors.activeText || theme.colors.base[400] : menuColors.text || theme.colors.base[400])};
        transition: all 0.3s;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        margin-left: ${theme.spacing.small};
    }

    &:hover {
        background: ${menuColors.activeItemBackground || theme.colors.base[100]};
    }
`;

export const WrapperArrowIcon = styled.span<IMenuClassListStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    transform: ${({ expanded }) => (expanded ? "rotate(-180deg)" : "none")};
    transition: transform 0.5s;
    margin-left: 5px;
`;

export const MenuCurrentCourseLabel = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: ${theme.spacing.smallX};
    }

    ${WrapperArrowIcon} {
        margin-right: ${theme.spacing.small};
    }
`;

export const MenuCoursesList = styled.ul`
    margin-bottom: 15px;
`;

export const MenuCoursesItem = styled.li`
    height: 50px;
`;

export const MenuCoursesButton = styled.button<IMenuClassListStyleProps>`
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    position: relative;
    padding: 0 15px;

    p {
        font-weight: 600;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 22px;
        color: ${({ active }) => (active ? menuColors.activeText || theme.colors.brand[300] : menuColors.text || theme.colors.base[400])};
        margin-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 0.3s;
    }

    &:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border: ${({ active }) =>
            `1px solid ${active ? menuColors.activeText || theme.colors.brand[300] : menuColors.text || theme.colors.base[400]}`};
        border-radius: 100%;
        background: ${({ active }) => (active ? menuColors.activeText || theme.colors.brand[300] : "transparent")};
        margin-right: 10px;
        transition: all 0.3s;
    }

    &:hover {
        p {
            color: ${({ active }) => (active ? menuColors.activeText || theme.colors.brand[400] : theme.colors.base[500])};
        }

        &:before {
            border: ${({ active }) => `1px solid ${active ? menuColors.activeText || theme.colors.brand[400] : theme.colors.base[500]}`};
            background: ${({ active }) => (active ? menuColors.activeText || theme.colors.brand[400] : theme.colors.base[500])}};
        }
    }
`;
