// Dependencies
import { css } from "styled-components";
import { theme } from "config/theme";

import "flatpickr/dist/themes/light.css";

export const FlatpickrDefaultStyles = css`
    .flatpickr-months .flatpickr-month {
        background: #ffffff !important;
    }

    .flatpickr-current-month .numInputWrapper {
        width: 4ch !important;
    }

    .flatpickr-current-month span.cur-month:hover,
    .flatpickr-current-month input.cur-year:hover {
        background: transparent !important;
    }

    .flatpickr-current-month span.cur-month,
    .flatpickr-current-month input.cur-year {
        font-family: "Nunito", sans-serif !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 22px !important;
        letter-spacing: 0.005em !important;
        text-align: center !important;
        color: #1178ab !important;
        text-transform: uppercase !important;
    }

    span.flatpickr-weekday {
        background: #ffffff !important;
        font-family: "Nunito Sans", sans-serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        letter-spacing: 0em !important;
        color: #83899e !important;
    }

    .flatpickr-weekdays {
        background: #ffffff !important;
    }

    .flatpickr-calendar.open {
        display: flex !important;
        flex-direction: column !important;
        gap: 16px !important;
        padding: 24px !important;
        background: #ffffff !important;
        border-radius: 8px !important;
        box-shadow: 0px 1px 2px 0px #0000001a !important;
    }

    .flatpickr-days {
        border: none !important;
    }

    .flatpickr-day {
        width: 32px !important;
        height: 32px !important;
        padding: 5px !important;
        border: none !important;

        font-family: "Nunito Sans", sans-serif !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 22px !important;
        letter-spacing: 0em !important;
        color: #232326 !important;
    }

    .dayContainer,
    .flatpickr-days {
        width: 100% !important;
        min-width: unset !important;
        max-width: unset !important;
    }

    .flatpickr-rContainer {
        display: flex !important;
        flex-direction: column !important;
        gap: 16px !important;
    }

    .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
        right: 28px !important;
        top: 22px !important;
    }

    .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
        left: 28px !important;
        top: 22px !important;
    }

    .flatpickr-months .flatpickr-next-month:hover,
    .flatpickr-months .flatpickr-prev-month:hover {
        background-color: #f0f1f7;
        border-radius: 8px;
    }

    .flatpickr-months .flatpickr-next-month:hover svg,
    .flatpickr-months .flatpickr-prev-month:hover svg {
        fill: ${theme.colors.brand[300]} !important;
    }

    .flatpickr-day.flatpickr-disabled {
        color: #ced0dd !important;
    }

    .flatpickr-day.today {
        border: 1px solid #ced0dd !important;
    }

    .flatpickr-day.selected {
        border: none !important;
        background-color: ${theme.colors.brand[300]} !important;
        color: #ffffff !important;
        font-weight: 700 !important;
        max-width: 33px;
    }

    .flatpickr-day.nextMonthDay,
    .flatpickr-day.prevMonthDay {
        color: #ced0dd !important;
        opacity: 0.9;
    }

    .flatpickr-time {
        border: none !important;

        .numInputWrapper {
            &:hover {
                background: transparent;
            }

            .numInput {
                border: 1px solid #f0f1f7;
                border-radius: 4px;

                &:hover {
                    border: 1px solid #83899e;
                    background: white;
                }
            }

            .arrowUp,
            .arrowDown {
                border: none;
            }
        }
    }

    .flatpickr-time-label {
        font-family: "Nunito Sans";
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        color: #83899e;
        margin: 0;
        margin-block: 16px;
    }
`;
