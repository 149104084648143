import styled from "styled-components";
import { theme } from "config/theme";
import { ProgressBarTabletWrapper } from "components/ProgressBar/styles";
import { Micro } from "assets/styles/global";

export const ExamResultKnowledgeAreasCardTitle = styled.p`
    color: ${theme.colors.base[500]};
    font-size: ${theme.typography.sizes.smallX};
    margin-bottom: ${theme.spacing.small};
    font-weight: 600;
`;

export const ExamResultKnowledgeAreasCardItemProgressLabel = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: ${theme.spacing.smallXX};

    p {
        color: ${theme.colors.base[500]};
        font-weight: bold;
        font-size: ${theme.typography.sizes.small};

        :first-child {
            flex: 1;
        }
    }
`;

export const ExamResultKnowledgeAreasCardItemProgress = styled.div`
    ${ProgressBarTabletWrapper} {
        background: ${theme.colors.base[200]};
    }
`;

export const ExamResultKnowledgeAreasCardItem = styled.div`
    display: flex;
    flex-direction: column;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
        margin-bottom: ${theme.spacing.small};

        ${ExamResultKnowledgeAreasCardItemProgress} {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;

export const ExamResultKnowledgeAreasCardItemNote = styled.span`
    display: flex;
    align-items: center;

    ${Micro};
    color: ${theme.colors.base[300]};

    > strong {
        color: ${theme.colors.system.info[300]};
        margin-left: ${theme.spacing.smallXX};
    }
`;

export const ExamResultKnowledgeAreasCardItemName = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.smallX};
    justify-content: space-between;

    p {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
    }
`;

export const ExamResultKnowledgeAreasCardItemNotes = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.smallX};

    p {
        color: ${theme.colors.base[300]};
        font-size: ${theme.typography.sizes.small};

        :first-child {
            flex: 1;
        }

        strong {
            color: ${theme.colors.system.info[300]};
            font-weight: bold;
        }
    }
`;
