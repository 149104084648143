import React from "react";

// Assets
import { ExerciseMainChartBar, ExerciseMainLegends, ExerciseMainLegendItem } from "../MainChart/styles";

// Components
import { Row, Col } from "components/Grid";

// Helpers
import { VictoryBar, VictoryLine, VictoryLabel, VictoryChart, VictoryAxis } from "victory";
import { theme } from "config/theme";
import useIsMobile from "hooks/use-is-mobile";
import { DateTime } from "luxon";
import { IAnswerCard } from "store/ducks/answerCard";

interface IProps {
    userRealizations: IAnswerCard[];
    realizationsAverageHitRate: number;
}

const ExerciseListDashboardUserChart = ({ userRealizations, realizationsAverageHitRate }: IProps) => {
    const isMobile = useIsMobile();

    const dataChart = userRealizations.slice(isMobile ? -3 : -5).map((item, index: number) => ({
        x: index + 1,
        y: item.answersCorrect,
        label: `${item.answersCorrect} acertos\n${DateTime.fromISO(item.endDate!).toFormat("dd/MM/yyyy")}`
    }));

    return (
        <Row>
            <Col xs={12} sm={9}>
                <ExerciseMainChartBar data-test-id="exercise-list-chart-user">
                    <VictoryChart
                        width={400}
                        height={150}
                        domainPadding={{ x: 20 }}
                        padding={{ top: isMobile ? 10 : 40, bottom: isMobile ? 30 : 20, left: 25, right: 20 }}
                    >
                        <VictoryBar
                            cornerRadius={{ topLeft: 3, topRight: 3 }}
                            style={{
                                data: { fill: theme.colors.system.success[200], width: 25 },
                                labels: { fontSize: 8, display: "none", fill: "none" }
                            }}
                            data={dataChart}
                            labelComponent={
                                <VictoryLabel
                                    y={150}
                                    style={{
                                        fill: theme.colors.base[400],
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: isMobile ? theme.typography.sizes.small : 8
                                    }}
                                />
                            }
                        />

                        <VictoryAxis
                            dependentAxis
                            style={{
                                axis: { stroke: "transparent" },
                                tickLabels: {
                                    fontSize: 8,
                                    fill: theme.colors.base[300]
                                }
                            }}
                        />

                        <VictoryLine
                            standalone={false}
                            style={{
                                data: { stroke: theme.colors.secondary[300], strokeDasharray: 5, strokeWidth: 3 },
                                labels: {
                                    fill: theme.colors.secondary[300]
                                }
                            }}
                            data={[
                                { x: 0, y: realizationsAverageHitRate, label: "" },
                                {
                                    x: dataChart.length + 1,
                                    y: realizationsAverageHitRate,
                                    label: realizationsAverageHitRate
                                }
                            ]}
                            labelComponent={
                                <VictoryLabel
                                    className="exercise-list-label-average"
                                    renderInPortal
                                    dx={-5}
                                    dy={8}
                                    style={{
                                        fontFamily: theme.typography.family.secondary,
                                        fontSize: 8,
                                        fill: theme.colors.secondary[300],
                                        fontWeight: 700
                                    }}
                                />
                            }
                        />
                    </VictoryChart>
                </ExerciseMainChartBar>
            </Col>

            <Col xs={12} sm={3}>
                <ExerciseMainLegends>
                    <ExerciseMainLegendItem variant="success">Seus acertos</ExerciseMainLegendItem>
                    <ExerciseMainLegendItem variant="warning">Média geral de acertos</ExerciseMainLegendItem>
                </ExerciseMainLegends>
            </Col>
        </Row>
    );
};

export default React.memo(ExerciseListDashboardUserChart);
