import React, { memo, useCallback } from "react";

import { IEssayComposition } from "store/interfaces/IEssay";

import EssayDispatches from "../../components/essayDispatchesList";
import { Col } from "components/Grid";

import * as S from "./styles";
interface IEssayCompositionsProps {
    compositions?: IEssayComposition[];
}

const EssayDispatchesList = ({ compositions }: IEssayCompositionsProps) => {
    const handlePagination = useCallback(() => {}, []);

    return (
        <Col xs={12}>
            <S.EssayCardDetailsFooterHelper>Envios</S.EssayCardDetailsFooterHelper>
            <EssayDispatches composition={compositions} onFetchCompositions={handlePagination} />
        </Col>
    );
};
export default memo(EssayDispatchesList);
