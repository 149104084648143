import React, { memo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from "prodigio-icons/web/outlined/Close";
import { theme } from "config/theme";
import history from "services/history";
import formatImage from "services/exif";

import Button from "components/Button";
import Spinner from "components/Spinner";

import { Backdrop } from "assets/styles/global";

import * as S from "./styles";
import { IReduxStore } from "interfaces/IReduxStore";

interface IEssayPreviewProps {
    file?: any;
    onClose(): void;
}

const EssayPreview = (props: IEssayPreviewProps) => {
    const dispatch = useDispatch();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [preview, setPreview] = useState<string>();
    const [hasError, setHasError] = useState();

    const handleImage = useCallback((value: string) => setPreview(value), []);
    const handleError = useCallback((error: any) => setHasError(error), []);

    const loadImage = useCallback(async () => {
        if (props.file && !preview) {
            await formatImage(props.file, handleImage, handleError);
        }
    }, [handleError, handleImage, preview, props.file]);

    useEffect(() => {
        loadImage();
    }, [loadImage]);

    const handleConfirmAction = useCallback(() => {
        dispatch({
            type: "OPEN_MODAL",
            element: "confirmAction",
            action: {
                title: "Atenção",
                text: "Tem certeza que deseja usar <strong>1 crédito</strong> para enviar sua redação?",
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () => history.push({ pathname: `/app/curso/${courseSlug}/redacoes/envios/1` })
            }
        });
    }, [courseSlug, dispatch]);

    return (
        <>
            <Backdrop />
            <S.EssayPreviewContainer>
                <S.EssayPreviewCloseButton type="button" onClick={props.onClose}>
                    <CloseIcon width={16} height={16} />
                </S.EssayPreviewCloseButton>
                <S.EssayPreviewHelper>Deseja enviar este aquivo?</S.EssayPreviewHelper>
                <S.EssayPreviewCanvas>
                    {preview ? (
                        <img src={preview} alt="Preview" />
                    ) : hasError ? (
                        <p>Nenhuma imagem encontrada</p>
                    ) : (
                        <S.SpinnerWrapper>
                            <Spinner fixed={false} size={16} color={theme.colors.brand[300]} />
                        </S.SpinnerWrapper>
                    )}

                    {hasError && hasError}
                </S.EssayPreviewCanvas>
                <S.EssayPreviewNav>
                    <Button
                        size="medium"
                        variant="outline-info"
                        block={true}
                        onClick={() => history.push({ pathname: `/app/curso/${courseSlug}/redacoes/envios/1` })}
                    >
                        Salvar rascunho
                    </Button>
                    <Button size="medium" variant="info" block={true} onClick={handleConfirmAction}>
                        Enviar para correção
                    </Button>
                </S.EssayPreviewNav>
            </S.EssayPreviewContainer>
        </>
    );
};

export default memo(EssayPreview);
