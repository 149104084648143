import React, { useState, useEffect, useCallback } from "react";

// Assets
import ChevronLeftIcon from "prodigio-icons/web/outlined/ChevronLeft";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";

// Components
import BreadCrumb from "components/BreadCrumb";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as weekPlansActions } from "store/ducks/weekPlans";
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import _findIndex from "lodash/findIndex";
import _find from "lodash/find";
import api from "services/api";

interface IProps {
    isMobile: boolean;
    subject?: {
        name: string;
        slug: string;
    };
    previousExam?: { name: string; id: number };
    previousExamDay?: { title: string; id: number };
    project?: { name: string; id: number };
}

const ExerciseListDashboardBreadCrumb = ({ isMobile, subject, previousExam, previousExamDay, project }: IProps) => {
    const { idWeek, idModule, idWeekDay, idDayPeriod, idDayPeriodItem } = useParams();

    const [nameModuleSelected, setNameModuleSelected] = useState("...");
    const [selectedPeriodName, setSelectedPeriodName] = useState("...");
    const [selectedModuleName, setSelectedModuleName] = useState("...");
    const [idDayPeriodItemInitial, setIdDayPeriodItemInitial] = useState<number>();

    const { lessonPlanCustom, lessonPlanSectionWeekPlanList, lessonPlanSection } = useSelector(
        ({ lessonPlanCustom }: IReduxStore) => lessonPlanCustom
    );
    const { currentLessonPlan, currentWeek, modules, weeks } = useSelector(({ weekPlans }: IReduxStore) => weekPlans);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const dispatch = useDispatch();

    const getLessonPlanCustom = useCallback(() => dispatch(lessonPlanCustomActions.getLessonPlanCustomRequest()), [dispatch]);
    const getWeekPlanList = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionWeekPlanListRequest({ id })), [dispatch]);
    const getDaysShort = useCallback((id) => dispatch(lessonPlanCustomActions.getLessonPlanCustomSectionRequest({ id })), [dispatch]);
    const getCurrentSuggestedPlan = useCallback(() => {
        dispatch(weekPlansActions.getWeekPlansCoursesRequest({}));
    }, [dispatch]);
    const getCurrentWeekSuggestedPlan = useCallback((id) => dispatch(weekPlansActions.getWeekPlansWeeksRequest({ id })), [dispatch]);
    const getCurrentWeekModulesSuggestedPlan = useCallback((id) => dispatch(weekPlansActions.getWeekPlansCurrentModuleRequest({ id })), [dispatch]);

    const getWeekSelected = _findIndex(lessonPlanSectionWeekPlanList, ["id", Number(idWeek)]);
    const getDayShortSelected = _findIndex(lessonPlanSection, ["id", Number(idWeekDay)]);
    const getModuleSelected = _findIndex(modules, ["id", Number(idModule)]);

    useEffect(() => {
        if (weeks.length > 0 && !currentWeek?.id && !!idWeek) {
            const currentWeekTmp = weeks.find((week) => week.id === Number(idWeek));

            dispatch(weekPlansActions.setWeekPlansCurrentWeek(currentWeekTmp as any));
        }
    }, [currentWeek, dispatch, idWeek, weeks]);

    useEffect(() => {
        if (getModuleSelected !== -1) {
            setNameModuleSelected(modules[getModuleSelected].name);
        }
    }, [getModuleSelected, modules]);

    useEffect(() => {
        getCurrentSuggestedPlan();
    }, [getCurrentSuggestedPlan]);

    useEffect(() => {
        if (!!currentLessonPlan?.id) {
            getCurrentWeekSuggestedPlan(currentLessonPlan.id);
        }
    }, [getCurrentWeekSuggestedPlan, currentLessonPlan]);

    useEffect(() => {
        if (!!currentWeek?.id) {
            getCurrentWeekModulesSuggestedPlan(currentWeek.id);
        }
    }, [getCurrentWeekModulesSuggestedPlan, currentWeek]);

    const getContentItems = useCallback(async () => {
        const { data: periodData } = await api.get(`/student/lessonplangenericsection/${idWeekDay}/section`);
        const { data: moduleData } = await api.get(`/student/lessonplangenericsection/${idDayPeriod}/section`);

        const selectedPeriod = _find(periodData, ["id", +idDayPeriod]);
        setSelectedPeriodName(selectedPeriod?.name);

        const selectedModule = _find(moduleData, ["id", +idDayPeriodItem]);
        setSelectedModuleName(selectedModule?.name);
        setIdDayPeriodItemInitial(moduleData[0].id);
    }, [idDayPeriodItem, idDayPeriod, idWeekDay]);

    useEffect(() => {
        if (!!lessonPlanSection.length) {
            getContentItems();
        }
    }, [getContentItems, lessonPlanSection.length]);

    const loadDataPlanCustom = useCallback(() => {
        if (idWeek) {
            if (!lessonPlanCustom.length) {
                getLessonPlanCustom();
            }

            if (!!lessonPlanCustom.length) {
                const idPlan = lessonPlanCustom[0]?.id;
                getWeekPlanList(idPlan);
            }

            getDaysShort(idWeek);
        }
    }, [getDaysShort, getLessonPlanCustom, getWeekPlanList, idWeek, lessonPlanCustom]);

    useEffect(() => {
        if (!!idDayPeriodItem) {
            loadDataPlanCustom();
        }
    }, [idDayPeriodItem, loadDataPlanCustom]);

    useEffect(() => {
        return () => {
            dispatch(lessonPlanCustomActions.clearLessonPlanCustom());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(weekPlansActions.clearWeekPlans());
        };
    }, [dispatch]);

    if (!!project) {
        return (
            <BreadCrumb>
                {isMobile ? (
                    <BreadCrumb.Item>
                        <Link to={`/app/curso/${courseSlug}/projetos/${project.id}/estudando`}>
                            <ChevronLeftIcon with={8} height={14} />
                        </Link>
                    </BreadCrumb.Item>
                ) : (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/projetos`}>Projetos</Link>
                        </BreadCrumb.Item>

                        <BreadCrumb.Separator />

                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/projetos/${project.id}/estudando`}>{project.name}</Link>
                        </BreadCrumb.Item>
                    </>
                )}
            </BreadCrumb>
        );
    }

    if (!!previousExam && !!previousExamDay) {
        return (
            <BreadCrumb>
                {isMobile ? (
                    <BreadCrumb.Item>
                        <Link to={`/app/curso/${courseSlug}/provas-anteriores/${previousExam.id}`}>
                            <ChevronLeftIcon with={8} height={14} />
                        </Link>
                    </BreadCrumb.Item>
                ) : (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/provas-anteriores`} data-test-id="exercise-list-previousExam">
                                Provas Anteriores
                            </Link>
                        </BreadCrumb.Item>

                        <BreadCrumb.Separator />

                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/provas-anteriores/${previousExam.id}`} data-test-id="exercise-list-previousExam-name">
                                {previousExam.name}
                            </Link>
                        </BreadCrumb.Item>

                        <BreadCrumb.Separator />

                        <BreadCrumb.Item>
                            <Link
                                to={`/app/curso/${courseSlug}/provas-anteriores/${previousExam.id}`}
                                data-test-id="exercise-list-previousExamDay-name"
                            >
                                {previousExamDay.title}
                            </Link>
                        </BreadCrumb.Item>
                    </>
                )}
            </BreadCrumb>
        );
    }

    if (!!subject) {
        return (
            <BreadCrumb>
                <BreadCrumb.Item>
                    <Link to={`/app/curso/${courseSlug}/materias`}>Matérias</Link>
                </BreadCrumb.Item>

                <BreadCrumb.Separator />

                <BreadCrumb.Item>
                    <Link to={`/app/curso/${courseSlug}/materias/${subject.slug}`} data-test-id="exercise-list-subject-name">
                        {subject.name}
                    </Link>
                </BreadCrumb.Item>
            </BreadCrumb>
        );
    }

    if (!!idDayPeriodItem) {
        return (
            <BreadCrumb>
                {isMobile ? (
                    <BreadCrumb.Item>
                        <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${idDayPeriod}/${idDayPeriodItem}`}>
                            <ChevronLeftIcon with={8} height={14} /> Voltar
                        </Link>
                    </BreadCrumb.Item>
                ) : (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p`}>Meu plano de estudos</Link>
                        </BreadCrumb.Item>

                        {!!idWeek && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/`}>
                                        {lessonPlanSectionWeekPlanList[getWeekSelected]?.name
                                            ? lessonPlanSectionWeekPlanList[getWeekSelected]?.name
                                            : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}`}>
                                        {lessonPlanSection[getDayShortSelected]?.name ? lessonPlanSection[getDayShortSelected]?.name : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && !!selectedPeriodName && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link
                                        to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${idDayPeriod}/${idDayPeriodItemInitial}`}
                                    >
                                        {selectedPeriodName ? selectedPeriodName : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}

                        {!!idWeek && !!idWeekDay && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link
                                        to={`/app/curso/${courseSlug}/meu-plano-de-estudos/p/${idWeek}/${idWeekDay}/${idDayPeriod}/${idDayPeriodItem}`}
                                    >
                                        {selectedModuleName ? selectedModuleName : "..."}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}
                    </>
                )}
            </BreadCrumb>
        );
    }

    if (!!idModule) {
        return (
            <BreadCrumb>
                {isMobile ? (
                    <BreadCrumb.Item>
                        <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s/${idWeek}/${idModule}`}>
                            <ChevronLeftIcon with={8} height={14} /> Voltar
                        </Link>
                    </BreadCrumb.Item>
                ) : (
                    <>
                        <BreadCrumb.Item>
                            <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s`}>Meu plano de estudos</Link>
                        </BreadCrumb.Item>

                        {!!currentWeek && (
                            <>
                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s/${currentWeek.id}`}>
                                        {currentWeek.title ? currentWeek.title : "..."}
                                    </Link>
                                </BreadCrumb.Item>

                                <BreadCrumb.Separator />
                                <BreadCrumb.Item>
                                    <Link to={`/app/curso/${courseSlug}/meu-plano-de-estudos/s/${currentWeek.id}/${idModule}`}>
                                        {nameModuleSelected}
                                    </Link>
                                </BreadCrumb.Item>
                            </>
                        )}
                    </>
                )}
            </BreadCrumb>
        );
    }

    return (
        <BreadCrumb>
            {!isMobile && (
                <BreadCrumb.Item>
                    <Link to="/">Home</Link>
                </BreadCrumb.Item>
            )}

            <BreadCrumb.Item>
                <Link to="/">{isMobile ? <ChevronLeftIcon with={8} height={14} /> : <ChevronRightIcon with={5} height={8} />}</Link>
            </BreadCrumb.Item>
        </BreadCrumb>
    );
};

export default React.memo(ExerciseListDashboardBreadCrumb);
