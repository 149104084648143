import React from "react";

import { PreviousExamCardContainer, PreviousExamCardTitle, PreviousExamCardListWrap } from "./styles";
import ColorTaggedItem from "components/ColorTaggedExerciseList";
import { ExamFilter } from "enums/ExamFilter";
import { IPreviousExam } from "store/interfaces/IPreviousExam";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    name: string;
    year: number;
    items: IPreviousExam[];
    filterBy: ExamFilter;
    id: number;
}

const PreviousExamCard = ({ name, items, filterBy, year, id }: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <PreviousExamCardContainer data-test-previos-exam-id={(id || year).toString()} data-test-id="previous-exam">
            <PreviousExamCardTitle data-test-id="previous-exam-name">{filterBy === ExamFilter.Year ? year : name}</PreviousExamCardTitle>
            <PreviousExamCardListWrap>
                {items.map((previousExam) => (
                    <ColorTaggedItem
                        listHeading={filterBy === ExamFilter.Year ? previousExam.name : previousExam.year.toString()}
                        key={previousExam.id}
                        linkTo={`/app/curso/${courseSlug}/provas-anteriores/${previousExam.id}`}
                        listDescription={<>{previousExam.questionsTotal <= 0 ? "Sem questões" : `${previousExam.questionsTotal} questões`}</>}
                    />
                ))}
            </PreviousExamCardListWrap>
        </PreviousExamCardContainer>
    );
};

export default PreviousExamCard;
