import rootSaga from "./sagas";
import { theme } from "config/theme";
import migrations from "./migrations";
import createSagaMiddleware from "redux-saga";
import authMiddleware from "./middlewares/auth";
import storage from "redux-persist/lib/storage";
import rootReducer, { ReduxStore } from "./ducks";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore, createMigrate } from "redux-persist";
// @ts-ignore
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { TypedUseSelectorHook, useSelector as useReduxSelector } from "react-redux";

export type ReduxAction<T> = {
    type: string;
    payload: T;
};

// Reducers
const middlewares: any[] = [];
const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
        // if (process.env.NODE_ENV === "production") {
        //     // SOME ERROR API INTEGRATION
        // }
    }
});

middlewares.push(...[authMiddleware, sagaMiddleware]);

const persistConfig = {
    key: theme.project.slug,
    storage,
    whitelist: ["auth", "credentials", "course"],
    // debug: true,
    stateReconciler: autoMergeLevel2,
    version: 5,
    migrate: createMigrate(migrations)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middlewares)));
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export const useSelector: TypedUseSelectorHook<ReduxStore> = useReduxSelector;

export { store, persistor };
