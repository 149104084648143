import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { BodyText2, TitleH3 } from "assets/styles/global";

export const AnnouncementBarContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${theme.colors.white};
    box-shadow: rgba(9, 30, 66, 0.31) 0px 0px 1px, rgb(132 134 136 / 25%) 0px 20px 32px -8px;
    border-radius: ${theme.spacing.smallX};
    bottom: ${theme.spacing.small};
    max-width: 350px;
    position: fixed;
    padding: ${theme.spacing.medium};
    right: ${theme.spacing.small};
    width: calc(100% - 32px);
    z-index: 9999;

    span {
        display: block;
        margin-top: 6px;
    }

    strong {
        ${TitleH3};
        color: ${theme.colors.base[400]};
        margin-bottom: ${theme.spacing.small};
        text-align: center;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: unset;
    }
`;

export const AnnouncementBarText = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    margin-bottom: ${theme.spacing.small};
    text-align: center;
`;
