import React, { Fragment } from "react";
import { connect } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import Portal from "components/Portal";
import ExamDay from "./ExamDay";
import ResultModal from "./ResultModal";

interface IProps {
    active: boolean;
}

const ExamDayModal = ({ active }: IProps) => {
    return (
        <>
            <Portal portalId="exam-day">{active ? <ExamDay /> : <Fragment />}</Portal>
            <ResultModal />
        </>
    );
};

const mapStateToProps = ({ exam }: IReduxStore) => ({
    active: exam.activeDayModal
});

export default connect(mapStateToProps)(ExamDayModal);
