import React, { memo } from "react";
import {
    ExamResultRankingContainer,
    ExamResultRankingNote,
    ExamResultRankingGreyText,
    ExamResultRankingDarkText,
    ExamResultRankingPosition
} from "./styles";

import RankingIcon from "prodigio-icons/web/filled/Ranking";

interface IProps {
    note?: number;
    rankPosition?: number;
    totalRankUsers?: number;
}

const ExamResultRanking = memo(({ note = 0, rankPosition = 0, totalRankUsers = 0 }: IProps) => {
    return (
        <ExamResultRankingContainer>
            <RankingIcon width={36} height={48} />

            <ExamResultRankingPosition>
                <ExamResultRankingDarkText>{rankPosition}° lugar</ExamResultRankingDarkText>
                <ExamResultRankingGreyText>de {totalRankUsers} no Ranking</ExamResultRankingGreyText>
            </ExamResultRankingPosition>

            <ExamResultRankingNote>
                <ExamResultRankingGreyText>Sua Nota:</ExamResultRankingGreyText>
                <ExamResultRankingDarkText>{note}</ExamResultRankingDarkText>
            </ExamResultRankingNote>
        </ExamResultRankingContainer>
    );
});

export default ExamResultRanking;
