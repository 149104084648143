// Dependencies
import * as yup from "yup";

export const mainDataValidationSchema = yup
    .object({
        classes: yup
            .array(
                yup
                    .string()
                    .trim()
                    .required("A turma é obrigatória.")
            )
            .optional(),
        releaseDate: yup
            .string()
            .trim()
            .required("A data de liberação é obrigatória."),
        sendDate: yup
            .string()
            .trim()
            .optional()
    })
    .required();

export const generalInformationsValidationSchema = yup
    .object({
        title: yup
            .string()
            .trim()
            .required("O título é obrigatório."),
        score: yup.number().optional(),
        enunciation: yup
            .string()
            .trim()
            .required("O enunciado é obrigatório.")
    })
    .required();

export const exerciseListInformationsValidationSchema = yup
    .object({
        questionsBankExerciseListId: yup.number().required("A lista de exercícios é obrigatória."),
        releaseResult: yup.boolean().optional()
    })
    .required()
    .when("activityType", (activityType: any, schema: any) => {
        if (activityType.data === "exercise-list" && activityType.exerciseListType !== "custom") {
            return schema;
        }

        return yup
            .object({
                questionsBankExerciseListId: yup.number().optional(),
                releaseResult: yup.boolean().optional()
            })
            .optional();
    });

export const sendFileInformationsValidationSchema = yup
    .object({
        allowedFileTypes: yup
            .array(
                yup
                    .string()
                    .trim()
                    .required()
            )
            .required("O tipo de arquivo é obrigatório.")
    })
    .required()
    .when("activityType.data", (data: any, schema: any) => {
        if (data === "send-file") {
            return schema;
        }

        return yup
            .object({
                allowedFileTypes: yup
                    .array(
                        yup
                            .string()
                            .trim()
                            .optional()
                    )
                    .optional()
            })
            .optional();
    });

export const discursiveInformationsValidationSchema = yup
    .object({
        wordsLimit: yup.number().optional()
    })
    .required()
    .when("activityType.data", (data: any, schema: any) => {
        if (data === "discursive") {
            return schema;
        }

        return yup
            .object({
                wordsLimit: yup.number().optional()
            })
            .optional();
    });

export const validationSchema = yup
    .object({
        mainData: mainDataValidationSchema,
        activityType: yup
            .object({
                data: yup
                    .string()
                    .trim()
                    .required("O tipo de atividade é obrigatório."),
                exerciseListType: yup
                    .string()
                    .trim()
                    .required("O tipo da lista de exercício é obrigatório.")
                    .when("data", (data: any, schema: any) => {
                        if (data === "exercise-list") {
                            return schema;
                        }

                        return yup
                            .string()
                            .trim()
                            .optional();
                    })
            })
            .required(),
        generalInformations: generalInformationsValidationSchema,
        exerciseListInformations: exerciseListInformationsValidationSchema,
        sendFileInformations: sendFileInformationsValidationSchema,
        discursiveInformations: discursiveInformationsValidationSchema,
        questions: yup
            .number()
            .min(1, "É necessário ter pelo menos uma questão criada")
            .required("As questões são obrigatórias")
            .when("activityType", (activityType: any, schema: any) => {
                if (activityType.data === "exercise-list" && activityType.exerciseListType !== "pre-created") {
                    return schema;
                }

                return yup.number().optional();
            })
    })
    .required();

export type ValidationSchema = yup.InferType<typeof validationSchema>;
