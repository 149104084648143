import React, { useMemo, useCallback, memo } from "react";

// Dependencies
import InfoIcon from "prodigio-icons/web/filled/Info";
import ErrorIcon from "prodigio-icons/web/filled/Error";
import SuccessIcon from "prodigio-icons/web/filled/Sucess";
import AttentionIcon from "prodigio-icons/web/filled/Attention";

// Components
import Button from "components/Button";

// Redux
import { useDispatch } from "react-redux";

// Helpers
import { FlatVariant, OutlineVariant } from "utils/types/Variant";

// Assets
import { ConfirmActionModalContainer, ConfirmActionModalTitle, ConfirmActionModalLegend, ConfirmActionModalButtonWrapper } from "./styles";

type Variants = FlatVariant | OutlineVariant;

interface IConfirmActionModalProps {
    action: {
        title?: string;
        text?: string | React.ReactNode;
        hasIcon?: boolean;
        hasButton?: boolean;
        hasCancelButton?: boolean;
        cancelButtonText?: string;
        buttonText?: string;
        variant?: Variants;
        variantCancelButton?: Variants;
        widthContainer?: string;
        isCallToAction?: boolean;
        alignText?: string;
        onConfirm(): void;
        onCancel(): void;
    };
}

const icons = new Map<Variants | Variants[], (props: React.SVGProps<SVGSVGElement>) => JSX.Element>([
    [["success", "outline-success"], SuccessIcon],
    [["danger", "outline-danger"], ErrorIcon],
    [["warning", "outline-warning"], AttentionIcon],
    [["info", "outline-info"], InfoIcon]
]);

const ConfirmActionModal = ({ action }: IConfirmActionModalProps) => {
    const {
        text,
        variant,
        variantCancelButton,
        onConfirm,
        onCancel,
        title,
        buttonText,
        cancelButtonText,
        widthContainer,
        alignText = "center",
        hasIcon = true,
        hasButton = true,
        hasCancelButton = false
    } = action;

    const dispatch = useDispatch();

    const handleClickButton = useCallback(() => {
        try {
            dispatch({ type: "CLOSE_MODAL" });

            if (onConfirm) {
                onConfirm();
            }
        } catch (error) {
            console.log(error);
        }
    }, [onConfirm, dispatch]);

    const handleClickCancelButton = useCallback(() => {
        try {
            dispatch({ type: "CLOSE_MODAL" });

            if (onCancel) {
                onCancel();
            }
        } catch (error) {
            console.log(error);
        }
    }, [onCancel, dispatch]);

    const ModalIcon = useMemo(() => {
        let Icon = InfoIcon;

        if (!variant) {
            return <InfoIcon width={64} height={64} />;
        }

        for (const item of icons.keys()) {
            if (item.includes(variant)) {
                Icon = icons.get(item);
            }
        }

        return <Icon width={64} height={64} />;
    }, [variant]);

    const ConfirmButton = useMemo(() => {
        const defaultText = "OK";

        return (
            <Button variant={variant as any} onClick={handleClickButton} size="medium" data-test-id="confirm-action-modal-button">
                {buttonText || defaultText}
            </Button>
        );
    }, [buttonText, handleClickButton, variant]);

    const CancelButton = useMemo(() => {
        const defaultText = "Voltar";

        return (
            <Button variant={variantCancelButton as any} onClick={handleClickCancelButton} size="medium" data-test-id="cancel-action-modal-button">
                {cancelButtonText || defaultText}
            </Button>
        );
    }, [cancelButtonText, handleClickCancelButton, variantCancelButton]);

    return (
        <ConfirmActionModalContainer width={widthContainer} data-test-id="confirm-action-modal">
            {hasIcon && ModalIcon}
            {!!title && <ConfirmActionModalTitle>{title}</ConfirmActionModalTitle>}
            {!!text && <ConfirmActionModalLegend alignText={alignText}>{text}</ConfirmActionModalLegend>}
            <ConfirmActionModalButtonWrapper>
                {hasCancelButton && CancelButton}
                {hasButton && ConfirmButton}
            </ConfirmActionModalButtonWrapper>
        </ConfirmActionModalContainer>
    );
};

export default memo(ConfirmActionModal);
