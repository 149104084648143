// Dependencies
import React from "react";

// Screens
import PaywallContainer from "screens/Paywall";
import LegacyLives from "screens/LegacyLives";
import Announcement from "screens/AnnouncementScreen";
import ExamDayDetails from "screens/ExamDay";
import ExamDayResult from "screens/ExamDayResult";
import ExamResultContainer from "screens/ExamResult";
import Exam from "screens/Exam/indexNew";
import ExerciseListDashboard from "screens/ExerciseListDashboard";
import ExerciseListScreen from "screens/ExerciseList";
import Subjects from "screens/SubjectsScreen";
import ExtraExerciseListBySubject from "screens/ExtraExerciseListBySubject";
import ExtraExerciseLists from "screens/ExtraExerciseListScreen";
import PreviousExam from "screens/PreviousExam";
import PreviousExamDetails from "screens/PreviousExamDetails";
import SubjectsDetails from "screens/SubjectScreenDetails";
import Project from "screens/Projects/Home";
import ProjectDetails from "screens/Projects/Details";
import ProjectStudyingScreen from "screens/Projects/Studying";
import FilesScreen from "screens/Files";
import LessonPlan from "screens/LessonPlan";
import StudentScreen, { StudentAreaScreenTypes } from "screens/StudentAreaScreen";
import ExamDetails from "screens/ExamDetails";
import ForumNewScreen from "screens/Forum/new";
import ForumHomeScreen from "screens/Forum/home";
import ForumQuestionScreen from "screens/Forum/question";
import ExerciseListResolutionMode from "screens/ExerciseList/enums/ExerciseListResolutionMode";
import EssayLegacy from "screens/Essay/index.legacy";
import ThemeScreen from "screens/Essay/theme";
import MyEssaysScreen from "screens/Essay/MyEssays";
import MyEssaysEditScreen from "screens/Essay/MyEssays/Edit";
import EssayStatusScreen from "screens/Essay/status";
import SelectExamVersion from "screens/SelectExamVersion";
import LegacyExamScreen from "screens/Exam";
import QuestionsFilter from "screens/ExerciseListCustom/QuestionsFilter";
import ExerciseListCustom from "screens/ExerciseListCustom/index";
import DropdownExerciceList from "screens/QrCode/components/Dropdown/DropdownExerciceList";
import QrCode from "screens/QrCode";
import DropdownMaterial from "screens/QrCode/components/Dropdown/DropdownMaterial";
import WatchScreenDoubt from "screens/WatchScreenDoubt";
import LightVideoDoubt from "screens/LightVideoDoubt";
import SelectLessonPlan from "screens/SelectLessonPlan";
import PerformanceDashboard from "screens/performance-dashboard";
import PerformanceArea from "screens/performance-area";
import PerformanceSubject from "screens/performance-subject";
import PerformanceEssay from "screens/performance-essay";
import QrCodeRedirect from "screens/QrCodeRedirect";
import ActivityRedirector from "screens/activity-redirector";
import ActivityTeacherCreate from "screens/activity-teacher-create";
import ActivityStudentExecution from "screens/activity-student-execution";
import ActivityTeacherSubmissions from "screens/activity-teacher-submissions";
import ActivitySubmissionDetails from "screens/activity-submission-details";

// Types
import { ExamType } from "enums/ExamType";

const privateRoutes = [
    {
        component: QrCodeRedirect,
        path: "/app/redirect",
        exact: true
    },
    {
        component: LessonPlan,
        path: "/app/curso/:courseSlug/plano-de-estudos",
        exact: true
    },
    {
        component: LessonPlan,
        path: "/app/curso/:courseSlug/plano-de-estudos/:idModule",
        exact: true
    },
    {
        component: LessonPlan,
        path: "/app/curso/:courseSlug/plano-de-estudos/:idModule/:day",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/p/:idWeek?/:idWeekDay?/:idDayPeriod?/:idDayPeriodItem?",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/p/:idWeek?/:idWeekDay?/:idDayPeriod?/:idDayPeriodItem?/material/:idMaterial",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/s/:idWeek?/:idModule?",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/s/:idWeek?/:idModule?/material/:idMaterial",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos-wizard/editando-plano",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos-wizard/escolha-como-iniciar",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos-wizard/escolha-sua-carreira",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos-wizard/escolha-seus-horarios",
        exact: true
    },
    {
        component: SelectLessonPlan,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos-wizard/configure-seu-plano",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/s/:idWeek?/:idModule?/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/p/:idWeek?/:idWeekDay?/:idDayPeriod?/:idDayPeriodItem?/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: EssayLegacy,
        path: "/app/curso/:courseSlug/redacoes",
        exact: true
    },
    {
        component: EssayLegacy,
        path: "/app/curso/:courseSlug/redacoes/fazer/:id",
        exact: true
    },
    {
        component: ThemeScreen,
        path: "/app/curso/:courseSlug/redacoes/temas/:id",
        exact: true
    },
    {
        component: ThemeScreen,
        path: "/app/curso/:courseSlug/redacoes/temas/:id/fazer",
        exact: true
    },
    {
        component: MyEssaysScreen,
        path: "/app/curso/:courseSlug/redacoes/envios",
        exact: true
    },
    {
        component: MyEssaysEditScreen,
        path: "/app/curso/:courseSlug/redacoes/envios/:id/editar",
        exact: true
    },
    {
        component: EssayStatusScreen,
        path: "/app/curso/:courseSlug/redacoes/envios/:id",
        exact: true
    },
    {
        component: LegacyLives,
        path: "/app/curso/:courseSlug/aulas-ao-vivo-anteriores",
        exact: true
    },
    {
        component: Exam,
        path: "/app/curso/:courseSlug/simulados",
        exact: true
    },
    {
        component: Subjects,
        path: "/app/curso/:courseSlug/materias",
        exact: true
    },
    {
        component: SubjectsDetails,
        path: "/app/curso/:courseSlug/materias/:slug",
        exact: true
    },
    {
        component: SubjectsDetails,
        path: "/app/curso/:courseSlug/materias/:slug/:moduleId",
        exact: true
    },
    {
        component: SubjectsDetails,
        path: "/app/curso/:courseSlug/materias/:slug/:moduleId/material/:materialId",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/lista-de-exercicios/:id/estudo/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/lista-de-exercicios/:id/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/lista-de-exercicios/:id/gabarito",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/lista-de-exercicios/:id/estudo/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/lista-de-exercicios/:id/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/lista-de-exercicios/:id/gabarito",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: ExtraExerciseListBySubject,
        path: "/app/curso/:courseSlug/materias/:slug/:moduleId/lista-de-exercicios-extra",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/materias/:slug/:moduleId/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: ExtraExerciseListBySubject,
        path: "/app/curso/:courseSlug/avaliacoes/:slug",
        exact: true
    },
    {
        component: ExtraExerciseLists,
        path: "/app/curso/:courseSlug/avaliacoes",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/avaliacoes/:subjectSlug/lista-de-exercicios/:id/prova/:answerCardId",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/avaliacoes/:slug/:id",
        exact: true
    },
    {
        component: PreviousExam,
        path: "/app/curso/:courseSlug/provas-anteriores",
        exact: true
    },
    {
        component: PreviousExamDetails,
        path: "/app/curso/:courseSlug/provas-anteriores/:id",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/provas-anteriores/:examId/dia/:examDayId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} examType={ExamType.Previous} {...props} />,
        path: "/app/curso/:courseSlug/provas-anteriores/:examId/dia/:examDayId/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} examType={ExamType.Previous} {...props} />,
        path: "/app/curso/:courseSlug/provas-anteriores/:examId/dia/:examDayId/estudo/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => (
            <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Feedback} examType={ExamType.Previous} {...props} />
        ),
        path: "/app/curso/:courseSlug/provas-anteriores/:examId/dia/:examDayId/gabarito",
        exact: true
    },
    {
        component: Project,
        path: "/app/curso/:courseSlug/projetos",
        exact: true
    },
    {
        component: ProjectDetails,
        path: "/app/curso/:courseSlug/projetos/:id",
        exact: true
    },
    {
        component: ProjectStudyingScreen,
        path: "/app/curso/:courseSlug/projetos/:id/estudando",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/projetos/:projectId/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/projetos/:projectId/lista-de-exercicios/:id/estudo/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/projetos/:projectId/lista-de-exercicios/:id/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Feedback} {...props} />,
        path: "/app/curso/:courseSlug/projetos/:projectId/lista-de-exercicios/:id/gabarito",
        exact: true
    },
    {
        component: Announcement,
        path: "/app/curso/:courseSlug/comunicados",
        exact: true
    },

    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="profile" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil",
        exact: true
    },
    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="edit-personal" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil/edicao",
        exact: true
    },
    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="change-password" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil/alterar-senha",
        exact: true
    },
    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="signed-courses" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil/assinaturas",
        exact: true
    },
    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="financial" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil/financeiro",
        exact: true
    },
    {
        component: (props: StudentAreaScreenTypes & any) => <StudentScreen {...props} screenName="financial" />,
        path: "/app/curso/:courseSlug/area-do-aluno/perfil/financeiro/:financialCode",
        exact: true
    },
    {
        component: FilesScreen,
        path: "/app/curso/:courseSlug/arquivos",
        exact: true
    },
    {
        component: ExamDetails,
        path: "/app/curso/:courseSlug/simulados/:examId",
        exact: true
    },
    {
        component: ExamResultContainer,
        path: "/app/curso/:courseSlug/simulados/:examSlug/resultado",
        exact: true
    },
    {
        component: ExamResultContainer,
        path: "/app/curso/:courseSlug/simulados/:examSlug/resultado/:resolutionId",
        exact: true
    },
    {
        component: ExamDayDetails,
        path: "/app/curso/:courseSlug/simulados/:examId/:examDayId",
        exact: true
    },
    {
        component: ExamDayResult,
        path: "/app/curso/:courseSlug/simulados/:examId/:examDayId/resultado/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} examType={ExamType.Simulated} {...props} />,
        path: "/app/curso/:courseSlug/simulados/:examId/:examDayId/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => (
            <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Feedback} examType={ExamType.Simulated} {...props} />
        ),
        path: "/app/curso/:courseSlug/simulados/:examId/:examDayId/gabarito",
        exact: true
    },
    {
        component: PaywallContainer,
        path: "/app/curso/:courseSlug/show-paywall",
        exact: true
    },
    {
        component: ForumHomeScreen,
        path: "/app/curso/:courseSlug/forum",
        exact: true
    },
    {
        component: ForumNewScreen,
        path: "/app/curso/:courseSlug/forum/nova",
        exact: true
    },
    {
        component: ForumQuestionScreen,
        path: "/app/curso/:courseSlug/forum/:id",
        exact: true
    },
    {
        component: SelectExamVersion,
        path: "/app/curso/:courseSlug/simulado-beta",
        exact: true
    },
    {
        component: LegacyExamScreen,
        path: "/app/curso/:courseSlug/simulados-legado",
        exact: true
    },

    {
        component: ExerciseListCustom,
        path: "/app/curso/:courseSlug/banco-de-questoes",
        exact: true
    },
    {
        component: QuestionsFilter,
        path: "/app/curso/:courseSlug/banco-de-questoes/novo",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/banco-de-questoes/lista-de-exercicios/:id/estudo/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/banco-de-questoes/lista-de-exercicios/:id/prova/:answerCardId",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Study} {...props} />,
        path: "/app/curso/:courseSlug/banco-de-questoes/lista-de-exercicios/:id/gabarito",
        exact: true
    },
    {
        component: ExerciseListDashboard,
        path: "/app/curso/:courseSlug/banco-de-questoes/lista-de-exercicios/:id",
        exact: true
    },
    {
        component: QrCode,
        path: "/app/curso/:courseSlug/qr-code",
        exact: true
    },
    {
        component: DropdownMaterial,
        path: "/app/curso/:courseSlug/qr-code/:slug",
        exact: true
    },
    {
        component: DropdownExerciceList,
        path: "/app/curso/:courseSlug/qr-code/exercise-list/:exerciceListId/resolutions",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/s/:idWeek?/:idModule?/video/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/s/:idWeek?/:idModule?/ao-vivo/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/meu-plano-de-estudos/p/:idWeek?/:idWeekDay?/:idDayPeriod?/:idDayPeriodItem?/video/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/plano-de-estudos/:moduleSlug/:startDate/:type/:eventId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/plano-de-estudos/:moduleSlug/:startDate/:type/:eventId/video/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/plano-de-estudos/:eventType/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/video/:videoId?",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/ao-vivo/:eventId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/materias/:subjectSlug/:moduleId/ao-vivo/:eventId/video/:videoId?",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/video/:videoId",
        exact: true
    },
    {
        component: WatchScreenDoubt,
        path: "/app/curso/:courseSlug/video/:videoId/notificacao/:IdPostSpecific",
        exact: true
    },
    {
        component: LightVideoDoubt,
        path: "/app/curso/:courseSlug/video-light/:id",
        exact: true
    },
    {
        component: LightVideoDoubt,
        path: "/app/curso/:courseSlug/evento-light/:eventId",
        exact: true
    },
    {
        component: PerformanceDashboard,
        path: "/app/curso/:courseSlug/52d12d68f3845edad37adac43a503855/desempenho",
        exact: true
    },
    {
        component: PerformanceArea,
        path: "/app/curso/:courseSlug/52d12d68f3845edad37adac43a503855/desempenho/area",
        exact: true
    },
    {
        component: PerformanceSubject,
        path: "/app/curso/:courseSlug/52d12d68f3845edad37adac43a503855/desempenho/materia",
        exact: true
    },
    {
        component: PerformanceEssay,
        path: "/app/curso/:courseSlug/52d12d68f3845edad37adac43a503855/desempenho/redacao",
        exact: true
    },
    {
        component: ActivityRedirector,
        path: "/app/curso/:courseSlug/atividades",
        exact: true
    },
    {
        component: ActivityTeacherCreate,
        path: "/app/curso/:courseSlug/atividades/criar",
        exact: true
    },
    {
        component: ActivityTeacherCreate,
        path: "/app/curso/:courseSlug/atividades/:activityId/editar",
        exact: true
    },
    {
        component: ActivityTeacherSubmissions,
        path: "/app/curso/:courseSlug/atividades/:activityId/entregas",
        exact: true
    },
    {
        component: ActivitySubmissionDetails,
        path: `/app/curso/:courseSlug/atividades/:activityType/:activityId/entrega/:submissionId`,
        exact: true
    },
    {
        component: ActivityStudentExecution,
        path: "/app/curso/:courseSlug/atividades/:activityType/:activityId/execucao",
        exact: true
    },
    {
        component: (props: any) => <ExerciseListScreen resolutionMode={ExerciseListResolutionMode.Exam} {...props} />,
        path: "/app/curso/:courseSlug/atividades/lista-de-exercicios/:activityId/:activitySubmissionId/execucao/:id/:answerCardId",
        exact: true
    }
];

export default privateRoutes;
