import React, { useCallback, useState, useEffect } from "react";
import { useModal } from "hooks/use-modal";
import { ISelectOption } from "interfaces/ISelectOption";
import { ReportVideoErrorModalContainer, ReportVideoErrorModalContent } from "./styles";
import { ComplaintModalContainer, ComplaintModalTitle, ComplaintModalHeader, ComplaintModalFooter } from "../Complaint/styles";
import Button from "components/Button";
import { theme } from "config/theme";
import { useDispatch } from "react-redux";
import { Creators as VideoActions } from "store/ducks/video";
import Bowser from "bowser";
import ReportVideoErrorModalForm from "./form";
import { ReportVideoErrorModalVideoErrorType, ReportVideoErrorModalDevices } from "enums/ReportVideoErrorModal";

interface IProps {
    url: string;
    videoId: number;
    isEmbed: boolean;
    onSubmitSuccess?(): void;
    isForumOpen: boolean;
}

export interface IReportVideoErrorModalFormState {
    browser?: ISelectOption;
    browser_version?: string;
    operational_system?: ISelectOption;
    processor?: ISelectOption;
    video_error_type?: ISelectOption<ReportVideoErrorModalVideoErrorType>;
    memory?: ISelectOption;
    device?: ISelectOption<ReportVideoErrorModalDevices>;
    connection_velocity?: ISelectOption<string>;
    internet_provider?: string;
    device_model?: string;
}

const deviceOptions: ISelectOption<ReportVideoErrorModalDevices>[] = [
    {
        value: ReportVideoErrorModalDevices.Desktop,
        label: "Computador"
    },
    {
        value: ReportVideoErrorModalDevices.Tablet,
        label: "Tablet"
    },
    {
        value: ReportVideoErrorModalDevices.CellPhone,
        label: "Celular"
    }
];

const browserOptions: ISelectOption<string>[] = [
    ...[
        "Google Chrome",
        "Mozilla Firefox",
        "Internet Explorer",
        "Microsoft Edge",
        "Safari",
        "Opera",
        "UC Browser",
        "Brave",
        "Vivaldi"
    ].map((text) => ({ value: text, label: text })),
    { value: "unknown", label: "Outro" }
];

const osOptions: ISelectOption<string>[] = [
    ...["Android", "iOS (iPhone)", "Windows 10", "Windows 8", "Windows 7", "Windows Vista", "Windows XP", "Linux", "macOS"].map((text) => ({
        value: text,
        label: text
    })),
    { value: "unknown", label: "Outro" }
];

const ReportVideoErrorModal: React.FC<IProps> = ({ url, videoId, onSubmitSuccess, isEmbed = false, isForumOpen = false }) => {
    const [formState, setFormState] = useState<IReportVideoErrorModalFormState>();

    const [formError, setFormError] = useState(false);

    const modal = useModal();

    const dispatch = useDispatch();

    const isMobileDevice = [ReportVideoErrorModalDevices.CellPhone, ReportVideoErrorModalDevices.Tablet].includes(
        formState?.device?.value as ReportVideoErrorModalDevices
    );

    const setBrowserInfos = useCallback(() => {
        const { os, browser, platform } = Bowser.parse(window.navigator.userAgent);

        const browserName = (browser?.name ?? "").trim().toLowerCase();
        const osName = (os?.name === "Windows" ? `Windows ${os.versionName}` : os?.name)?.toLowerCase();

        const findBrowserOption = browserOptions.find(({ value }) =>
            value
                .toLowerCase()
                .trim()
                .includes(browserName || "")
        );
        const findDeviceOption = deviceOptions.find(({ value }) =>
            value
                .toLowerCase()
                .replace("Google", "")
                .trim()
                .includes(platform?.type || "")
        );
        const findOSOption = osOptions.find(({ value }) => value.toLowerCase().includes(osName || ""));

        setFormState((old) => ({
            ...old,
            browser: findBrowserOption,
            browser_version: browser?.version || "",
            device: findDeviceOption,
            operational_system: findOSOption
        }));
    }, []);

    useEffect(() => {
        setBrowserInfos();
    }, [setBrowserInfos]);

    const handleSubmit = useCallback(async () => {
        try {
            const hasError = isMobileDevice
                ? !formState?.video_error_type?.value || !formState?.browser?.value
                : !formState?.video_error_type?.value || !formState?.operational_system?.value || !formState?.browser?.value;

            setFormError(hasError);

            if (hasError) {
                return;
            }

            const commonMetadata = [
                {
                    key: "video_error_type",
                    value: formState?.video_error_type?.value
                },
                {
                    key: "browser",
                    value: formState?.browser?.value
                },
                {
                    key: "jsHeapSizeLimit",
                    value: (window as any)?.performance?.memory?.jsHeapSizeLimit
                },
                {
                    key: "totalJSHeapSize",
                    value: (window as any)?.performance?.memory?.totalJSHeapSize
                },
                {
                    key: "usedJSHeapSize",
                    value: (window as any)?.performance?.memory?.usedJSHeapSize
                },
                {
                    key: "memory",
                    value: formState?.memory?.value === "unknown" ? "unknown" : (formState?.memory?.value || 0) * 1024
                },
                {
                    key: "device",
                    value: formState?.device?.value
                },
                {
                    key: "browser_version",
                    value: formState?.browser_version
                },
                {
                    key: "is_forum_open",
                    value: isForumOpen
                },
                {
                    key: "internet_provider",
                    value: formState?.internet_provider
                },
                {
                    key: "video",
                    value: videoId
                },

                {
                    key: "user_agent",
                    value: window?.navigator?.userAgent
                },
                {
                    key: "connection_velocity",
                    value: formState?.connection_velocity?.value
                }
            ];

            const metadata = isMobileDevice
                ? [
                      {
                          key: "device_model",
                          value: formState?.device_model
                      }
                  ]
                : [
                      {
                          key: "operational_system",
                          value: formState?.operational_system?.value
                      },
                      {
                          key: "processor",
                          value: formState?.processor
                      }
                  ].filter(({ value }) => !!value && value !== "unknown");

            dispatch(
                VideoActions.reportVideoErrorRequest({
                    url,
                    metadata: [...metadata, ...commonMetadata],
                    onSuccess: () => (onSubmitSuccess ? onSubmitSuccess() : null),
                    isEmbed
                })
            );
        } catch (error) {
            console.log(error);
        }
    }, [formState, onSubmitSuccess, url, videoId, dispatch, isEmbed, isForumOpen, isMobileDevice]);

    const handleCancel = useCallback(() => {
        modal.close();
    }, [modal]);

    const handleChangeValue = useCallback(
        (key: string, value: any) => {
            setFormState({ ...formState, [key]: value });
        },
        [formState]
    );

    const handleChangeTextField = useCallback(
        (name: string) => {
            return (event: React.ChangeEvent<HTMLInputElement>) => handleChangeValue(name, event?.target?.value);
        },
        [handleChangeValue]
    );

    return (
        <>
            <ReportVideoErrorModalContainer>
                <ComplaintModalContainer style={{ width: "100%" }}>
                    <ComplaintModalHeader>
                        <ComplaintModalTitle>Reportar problema no vídeo</ComplaintModalTitle>
                    </ComplaintModalHeader>
                    <ReportVideoErrorModalContent>
                        <p style={{ marginBottom: 16 }}>Preencha o formulário abaixo com as suas informações.</p>

                        <ReportVideoErrorModalForm
                            browserOptions={browserOptions}
                            deviceOptions={deviceOptions}
                            formState={formState}
                            handleChangeTextField={handleChangeTextField}
                            handleChangeValue={handleChangeValue}
                            isMobileDevice={isMobileDevice}
                            osOptions={osOptions}
                        />

                        {formError && (
                            <p style={{ color: theme.colors.system.danger[300] }}>
                                Os campos "Qual é o problema com o vídeo?"{!isMobileDevice && ', "Sistema operacional"'} e "Navegador" são
                                obrigatórios.
                            </p>
                        )}
                    </ReportVideoErrorModalContent>
                    <ComplaintModalFooter>
                        <Button disabled={false} size="medium" variant="primary" block={true} onClick={handleSubmit}>
                            Enviar
                        </Button>
                        <Button size="medium" variant="outline-primary" block={true} onClick={handleCancel}>
                            Cancelar
                        </Button>
                    </ComplaintModalFooter>
                </ComplaintModalContainer>
            </ReportVideoErrorModalContainer>
        </>
    );
};

export default ReportVideoErrorModal;
