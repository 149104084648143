import styled from "styled-components/macro";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const LegacyLivesContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: ${theme.spacing.large};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: center;
    }
`;
