// Dependencies
import { theme } from "config/theme";

export interface IDictionary {
    [key: string]: any;
}

const isProraiz = theme.project.slug === "proraiz";

const dictionary: IDictionary = {
    Live: "Ao vivo",
    Complementar: "Complementar",
    Monitoria: "Monitoria",
    Theory: "Teoria",
    Resolution: "Resolução",
    Available: "Disponível",
    WaitingCorrection: "Em correção",
    Corrected: "Corrigido",
    Expired: "Expirado",
    Soon: "Em breve",
    InProgress: "Em progresso",
    SubjectContentPerTypeTitle: {
        material: "Baixar material complementar",
        apostila: isProraiz ? "Livro" : "Baixar apostila",
        booklet: isProraiz ? "Livro" : "Baixar apostila",
        exerciselist: "Fazer listas de exercício",
        video: "Assistir vídeos relacionados",
        aovivo: "Assistir aula AO VIVO",
        monitoria: "Assistir aula de monitoria",
        extra: "Assistir aula complementar",
        links: "Links",
        link: "Links"
    }
};

export default dictionary;
