import React, { memo, useMemo } from "react";

// Dependencies
import ClassesIcon from "prodigio-icons/web/outlined/Classes";
import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";

// Components
import If from "components/If";

// Helpers
import { ICourseState } from "store/ducks/course";
import { theme } from "config/theme";

// Assets
import { MenuClassListContainer, MenuCoursesButton, MenuCoursesItem, MenuCoursesList, MenuCurrentCourse, WrapperArrowIcon } from "./styles";

interface IMenuClassListProps {
    courses: ICourseState[];
    course: ICourseState;
    classExpanded: boolean;
    menuExpanded: boolean;
    setClassExpanded(): void;
    onChangeCourse(e: ICourseState): void;
}

const MenuClassList = ({ courses, course, menuExpanded, classExpanded, setClassExpanded, onChangeCourse }: IMenuClassListProps) => {
    const brandSlug = theme.project.slug;

    const renderMenuClassList = useMemo(() => {
        const mappedCourses = courses.map((currentCourse) => (
            <MenuCoursesItem key={currentCourse.id}>
                <MenuCoursesButton
                    data-test-id={currentCourse.id === course.id ? "school-class-item-active" : "school-class-item"}
                    id="MenuCoursesButton"
                    active={currentCourse.id === course.id}
                    onClick={() => {
                        onChangeCourse(currentCourse);
                        setClassExpanded();
                    }}
                >
                    <p title={currentCourse.name}>{currentCourse.name}</p>
                </MenuCoursesButton>
            </MenuCoursesItem>
        ));

        return mappedCourses;
    }, [course.id, courses, onChangeCourse, setClassExpanded]);

    const menuTitle = useMemo(() => {
        if (["proenem", "promilitares", "promedicina"].includes(brandSlug)) {
            return "Curso";
        }

        return "Turma";
    }, [brandSlug]);

    return (
        <MenuClassListContainer>
            <h3>{menuTitle}</h3>

            <MenuCurrentCourse id="MenuCurrentCourse" expanded={classExpanded} menuExpanded={menuExpanded} onClick={setClassExpanded}>
                <ClassesIcon width="16" height="16" />

                <If test={menuExpanded}>
                    <p title={course.name} data-test-id="school-class-current">
                        {course.name}
                    </p>
                </If>

                <WrapperArrowIcon expanded={classExpanded}>
                    <ChevronDownIcon width="8" height="4" />
                </WrapperArrowIcon>
            </MenuCurrentCourse>

            <If test={classExpanded && menuExpanded}>
                <MenuCoursesList data-test-id="school-class-list">{renderMenuClassList}</MenuCoursesList>
            </If>
        </MenuClassListContainer>
    );
};

export default memo(MenuClassList);
