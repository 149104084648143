import { BreakPoints } from "assets/styles/settings";
import { useEffect, useState } from "react";

const useIsLargeDesktop = (breakpoint = BreakPoints.wide) => {
    const [isLargeDesktop, setIsLargeDesktop] = useState(false);
    const matchMedia = window.matchMedia(`(min-width: ${breakpoint - 1}px)`);
    const checkWidth = (e: MediaQueryListEvent) => setIsLargeDesktop(e.matches);

    useEffect(() => {
        setIsLargeDesktop(matchMedia.matches);
        // tslint:disable-next-line: deprecation
        matchMedia.addListener(checkWidth);

        return () => {
            // tslint:disable-next-line: deprecation
            matchMedia.removeListener(checkWidth);
        };
    }, [matchMedia]);

    return isLargeDesktop;
};

export default useIsLargeDesktop;
