import React, { memo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { IEssayComposition, IEssayCompositions } from "store/interfaces/IEssay";

import Empty from "components/Empty";
import { Row, Col } from "components/Grid";
import Spinner from "components/Spinner";
import EssayDispatch from "./essayDispatchesCard";
import { EssayStatus } from "enums/EssayStatus";

import * as S from "./styles";

interface IEssayDispatchesProps {
    composition?: IEssayComposition[];
    compositions?: IEssayCompositions;
    onFetchCompositions: () => void;
}

const EssayDispatches = ({ composition, compositions, onFetchCompositions }: IEssayDispatchesProps) => {
    return (
        <S.EssayDispatchesContainer>
            {!!compositions ? (
                !compositions?.all?.totalItems ? (
                    <Empty message="Você ainda não tem nenhum envio para esse tema" />
                ) : (
                    <InfiniteScroll
                        scrollableTarget="scrollable"
                        scrollThreshold={1}
                        dataLength={compositions.all.items.length}
                        next={onFetchCompositions}
                        hasMore={compositions.all.totalPages > 1 && compositions.all.totalPages > compositions.all.page}
                        loader={<Spinner fixed={false} size={20} />}
                    >
                        <Row>
                            {compositions.all.items.map((composition, index) => {
                                if (composition.status >= EssayStatus.Draft) {
                                    return (
                                        <Col xs={12} sm={6} lg={4} className="card-wrapper" key={index}>
                                            <EssayDispatch composition={composition} />
                                        </Col>
                                    );
                                }
                                return null;
                            })}
                        </Row>
                    </InfiniteScroll>
                )
            ) : !composition?.length ? (
                <Empty message="Você ainda não tem nenhum envio para esse tema" />
            ) : (
                <Row>
                    {composition.map((composition, index) => {
                        if (!composition.status) {
                            return null;
                        }
                        return (
                            <Col xs={12} sm={6} lg={4} className="card-wrapper" key={index}>
                                <EssayDispatch composition={composition} />
                            </Col>
                        );
                    })}
                </Row>
            )}
        </S.EssayDispatchesContainer>
    );
};

export default memo(EssayDispatches);
