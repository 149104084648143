// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityInputVariants } from "./activity-input.types";

type LabelStyleProps = {
    color: string;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: ${({ color }) => color};
`;

type InputWrapperStyleProps = {
    isFocused: boolean;
    isFilled: boolean;
    variant: ActivityInputVariants;
};

export const InputWrapper = styled.div<InputWrapperStyleProps>`
  position: relative;

  width: 100%;
  padding: 10px 12px;
  border: 1px solid;
  border-radius: 4px;
  background-color: #fff;

  display: flex;
  align-items: center;
  gap: 8px;

  ${Label} {
    position: absolute;
    left: 12px;
    top: -14px;

    background-color: #fff;

    ${({ variant, isFocused }) =>
        !isFocused &&
        variant === "disabled" &&
        css`
            display: none;
        `}
  }

  ${({ isFocused }) =>
      isFocused &&
      css`
          border-color: #3e68d2;
      `}

  ${({ isFilled, isFocused }) =>
      !isFocused &&
      isFilled &&
      css`
          border-color: #7d8396;
      `}

  ${({ variant, isFocused }) =>
      !isFocused &&
      variant === "error" &&
      css`
          border-color: #e40000;
      `}

  ${({ variant, isFocused }) =>
      !isFocused &&
      variant === "disabled" &&
      css`
          border-color: #ced0dd;
      `}

  ${({ variant, isFocused }) =>
      !isFocused &&
      variant === "default" &&
      css`
          border-color: #83899e;
      `}

      ${({ variant, isFocused }) =>
          !isFocused &&
          variant === "neutral" &&
          css`
              border-color: #c8cad4;
          `}
`;

export const ErrorMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ErrorMessageLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #e40000;
`;

export const UnderInputMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding-top: 4px;

    > img {
        margin-bottom: 2px;
    }
`;

type ContainerStyleProps = {
    width?: number;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;

    ${({ width }) =>
        width &&
        css`
            width: ${width ? `${width}px` : "100%"};
        `}
`;

type InputFieldStyleProps = {
    isDisabled: boolean;
};

export const InputField = styled.input<InputFieldStyleProps>`
    width: 100%;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    -moz-appearance: textfield;

    ::placeholder {
        color: #4d4d63;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ${({ isDisabled }) =>
        isDisabled
            ? css`
                  background-color: #ced0dd;
              `
            : css`
                  background-color: transparent;
              `}
`;
