// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ScreenWidthRenderProps } from "./screen-width-render.types";

// Styles
import { Container } from "./screen-width-render.styles";

export const ScreenWidthRender: FunctionComponent<ScreenWidthRenderProps> = ({
    haveFullyAdaptativeWidth,
    haveFullyAdaptativeHeight,
    actionAfterRenderingWidth,
    renderingWidth,
    content
}) => {
    return (
        <Container
            actionAfterRenderingWidth={actionAfterRenderingWidth}
            renderingWidth={renderingWidth}
            haveFullyAdaptativeWidth={!!haveFullyAdaptativeWidth}
            haveFullyAdaptativeHeight={!!haveFullyAdaptativeHeight}
        >
            {content}
        </Container>
    );
};
