// Dependencies
import styled, { css } from "styled-components";

export type AssetStyleProps = {
    size: number;
    color: string;
    hoverColor?: string;
};

export const Container = styled.div<AssetStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        ${({ size }) =>
            css`
                width: ${size}px;
                height: ${size}px;
            `}

        ${({ hoverColor }) =>
            !!hoverColor &&
            css`
                cursor: pointer;

                &:hover {
                    > path {
                        fill: ${hoverColor};
                    }
                }
            `}

        > path {
            ${({ color }) =>
                css`
                    fill: ${color};
                `}
        }
    }
`;
