import React, { useState, useEffect, useMemo, useCallback } from "react";

// Assets
import {
    ExerciseListQuestionEnunciation,
    ExerciseListQuestionContainer,
    ExerciseListQuestionCanceled,
    ExerciseListQuestionContent,
    ExerciseListQuestionAttachment
} from "./styles";

// Components
import ExerciseListQuestionListAlternatives from "../QuestionListAlternatives";
import ExerciseListDiscursiveInput from "../DiscursiveInput";

// Helpers
import QuestionType from "screens/ExerciseList/enums/QuestionType";
import { CSSTransition } from "react-transition-group";
import { IAnswerCardItem } from "store/ducks/answerCard";
import ExerciseListQuestionDiscursiveAnswer from "../QuestionDiscursiveAnswer";
import Enunciation from "components/Enunciation";
import { IQuestion } from "interfaces/IQuestion";

import TemplateSheetIcon from "prodigio-icons/web/filled/TemplateSheet";
import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";
import BlockedQuestion from "../BlockedQuestion";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";

interface IExerciseListQuestionProps {
    question: IQuestion;
    answer?: IAnswerCardItem;
    showAnswer: boolean;
    resolutions: IExerciseListQuestionResolution[];
    isFinished: boolean;
    subjectColor?: string | null;
    onClickAlternative(data: { alternativeId?: number; discursiveAnswer?: string }): void;
    onClickSeeResolution(): void;
    blockQuestionToAnswer: boolean;
}

const ExerciseListQuestion = ({
    question,
    answer,
    showAnswer,
    onClickAlternative,
    resolutions,
    onClickSeeResolution,
    isFinished,
    subjectColor,
    blockQuestionToAnswer = false
}: IExerciseListQuestionProps) => {
    const [newQuestionId, setNewQuestionId] = useState(0);

    const { enunciation, alternatives, questionType, id, isCanceled = false, attachment } = question;

    const forceAnimation = useCallback(() => setNewQuestionId(id || 0), [id]);
    useEffect(() => forceAnimation(), [forceAnimation]);

    const memoAlternatives = useMemo(() => {
        try {
            if (questionType !== QuestionType.Objective) {
                throw new Error();
            }

            return (
                <ExerciseListQuestionListAlternatives
                    showAnswer={showAnswer}
                    answer={answer}
                    alternatives={alternatives}
                    onClickAlternative={(alternativeId) => onClickAlternative({ alternativeId })}
                    resolutions={resolutions}
                    onClickSeeResolution={onClickSeeResolution}
                    isFinished={isFinished}
                />
            );
        } catch (error) {
            return null;
        }
    }, [questionType, showAnswer, answer, alternatives, resolutions, onClickSeeResolution, isFinished, onClickAlternative]);

    const memoDiscursive = useMemo(() => {
        try {
            if (questionType !== QuestionType.Discursive || blockQuestionToAnswer) {
                throw new Error();
            }

            if (blockQuestionToAnswer && isFinished) {
                return null;
            }

            if (!isFinished && !!blockQuestionToAnswer && !!answer) {
                return <ExerciseListQuestionDiscursiveAnswer answer={answer} showAnswer={false} />;
            }

            // Exibe o input caso nao tenha finalizado
            if (!isFinished) {
                return (
                    <ExerciseListDiscursiveInput
                        onSubmit={(discursiveAnswer) => onClickAlternative({ discursiveAnswer })}
                        discursiveAnswer={(!!answer && answer.discursiveAnswer) || ""}
                    />
                );
            }

            // Caso exista resposta, mostra o feedback do professor
            if (!!answer && !!blockQuestionToAnswer) {
                return <ExerciseListQuestionDiscursiveAnswer answer={answer} showAnswer={showAnswer} />;
            }

            throw new Error();
        } catch (error) {
            return null;
        }
    }, [questionType, isFinished, answer, blockQuestionToAnswer, onClickAlternative, showAnswer]);

    return (
        <CSSTransition in={id === newQuestionId} timeout={250} classNames="question" unmountOnExit mountOnEnter exit>
            <ExerciseListQuestionContainer>
                {isCanceled && (
                    <ExerciseListQuestionCanceled>
                        <p>ANULADA</p>
                    </ExerciseListQuestionCanceled>
                )}

                <ExerciseListQuestionContent>
                    {!!attachment && (
                        <ExerciseListQuestionAttachment>
                            <p>Essa questão tem enunciado:</p>

                            <a href={attachment} rel="nofollow noopener noreferrer" target="_blank">
                                <TemplateSheetIcon width={11} height={16} />
                                <span>Ver</span>
                                <ChevronRightIcon height={5} width={8} />
                            </a>
                        </ExerciseListQuestionAttachment>
                    )}

                    <ExerciseListQuestionEnunciation>
                        <Enunciation enunciation={enunciation} />
                    </ExerciseListQuestionEnunciation>

                    {memoAlternatives}
                    {blockQuestionToAnswer && <BlockedQuestion withResolutions={!!resolutions?.length} onClickSeeResolution={onClickSeeResolution} />}
                    {memoDiscursive}
                </ExerciseListQuestionContent>
            </ExerciseListQuestionContainer>
        </CSSTransition>
    );
};

export default React.memo(ExerciseListQuestion);
