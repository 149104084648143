// Dependencies
import styled, { css } from "styled-components";

// Types
import { OptionListSpacingAround } from "./option-list.types";

type ContainerStyleProps = {
    spacingAround: OptionListSpacingAround;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #ced0dd;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;

    ${({ spacingAround }) =>
        spacingAround === "small" &&
        css`
            padding: 8px;
        `}

    ${({ spacingAround }) =>
        spacingAround === "large" &&
        css`
            padding: 24px 16px;
        `}
`;
