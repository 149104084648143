// Dependencies
import styled, { css } from "styled-components/macro";

// Types
import { ButtonAppearance, ButtonSize, ButtonVariant } from "./button.types";
import { ternary } from "utils/ternary";

export type ContainerStyleProps = {
    variant: ButtonVariant;
    appearance: ButtonAppearance;
    size: ButtonSize;
    isFullyAdaptative: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
    border-radius: 4px;
    transition: 0.2s all ease-in-out;
    cursor: pointer;

    ${({ isFullyAdaptative }) =>
        isFullyAdaptative &&
        css`
            width: 100%;
        `}

    ${({ size }) => css`
        padding: ${ternary([
                [size === "small", "5px"],
                [size === "large", "9px"]
            ])}
            16px;
    `}

    ${({ variant, appearance }) =>
        variant === "contained" &&
        css`
            background-color: ${ternary([
                [appearance === "primary", "#571D5D"],
                [appearance === "secondary", "#3a80cf"]
            ])};

            :hover {
                background-color: ${ternary([
                    [appearance === "primary", "#794A7D"],
                    [appearance === "secondary", "#2B629F"]
                ])};
            }
        `}

    ${({ variant, appearance }) =>
        variant === "outlined" &&
        css`
            border: 1px solid
                ${ternary([
                    [appearance === "primary", "#571D5D"],
                    [appearance === "secondary", "#3a80cf"]
                ])};

            :hover {
                border: 1px solid
                    ${ternary([
                        [appearance === "primary", "#571D5D"],
                        [appearance === "secondary", "#2b629f"]
                    ])};

                background-color: ${ternary([
                    [appearance === "primary", "#DDD2DF"],
                    [appearance === "secondary", "#c6e0fe"]
                ])};

                > p {
                    color: ${ternary([
                        [appearance === "primary", "#571D5D"],
                        [appearance === "secondary", "#2b629f"]
                    ])};
                }

                > .pdg-asset > svg > path {
                    fill: ${ternary([
                        [appearance === "primary", "#571D5D"],
                        [appearance === "secondary", "#2b629f"]
                    ])};
                }
            }
        `}
`;

export type LabelStyleProps = {
    variant: ButtonVariant;
    appearance: ButtonAppearance;
    size: ButtonSize;
};

export const Label = styled.p<LabelStyleProps>`
    flex-shrink: 0;
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    ${({ variant }) =>
        variant === "contained" &&
        css`
            color: #ffffff;
        `}

    ${({ variant, appearance }) =>
        variant === "outlined" &&
        css`
            color: ${ternary([
                [appearance === "primary", "#571D5D"],
                [appearance === "secondary", "#3a80cf"]
            ])};
        `}
`;
