import styled from "styled-components";

import { theme } from "config/theme";

export const StudentInfoCard = styled.div`
    width: 100%;
    padding: ${theme.spacing.small};
    margin-top: ${theme.spacing.small};
    background-color: ${theme.colors.system.info[200]};
    color: ${theme.colors.system.info[500]};

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.spacing.smallXX};
    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;
