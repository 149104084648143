import { ActionCreatorsMapObject } from "redux";
import { createActions, createReducer } from "reduxsauce";
import { IMentorshipState, MentorshipActionsType } from "store/interfaces/IMentorship";

export const { Creators, Types } = createActions<Record<MentorshipActionsType, MentorshipActionsType>, ActionCreatorsMapObject>({
    getMentorshipRequest: ["payload"],
    getMentorshipSuccess: ["payload"],
    getMentorshipFailure: ["payload"],

    createOrEditMentorshipRequest: ["payload"],
    createOrEditMentorshipSuccess: ["payload"],
    createOrEditMentorshipFailure: ["payload"],

    deleteMentorshipRequest: ["payload"],
    deleteMentorshipSuccess: ["payload"],
    deleteMentorshipFailure: ["payload"],

    likeMentorshipRequest: ["payload"],
    likeMentorshipSuccess: ["payload"],
    likeMentorshipFailure: ["payload"],

    createOrEditMentorshipCommentsRequest: ["payload"],
    createOrEditMentorshipCommentsSuccess: ["payload"],
    createOrEditMentorshipCommentsFailure: ["payload"],

    deleteMentorshipCommentsRequest: ["payload"],
    deleteMentorshipCommentsSuccess: ["payload"],
    deleteMentorshipCommentsFailure: [],

    openMentorshipRequest: ["payload"],
    openMentorshipSuccess: ["payload"],
    openMentorshipFailure: ["payload"]
});

export const INITIAL_STATE: IMentorshipState = {
    isLoading: false,
    code: 0,
    openMentorship: true,
    items: []
};

// OPEN MENTORSHIP
const openMentorshipRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const openMentorshipSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const openMentorshipFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// GET MENTORSHIP ANSWERS
const getMentorshipRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getMentorshipSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ...action.payload
});
const getMentorshipFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// CREATE OR EDIT ANSWERS
const createOrEditMentorshipRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditMentorshipSuccess = (state = INITIAL_STATE, action: any) => {
    const haveThisAnswer = state.items.find((item) => item.id === action.payload.id);

    const { payload } = action;

    const updatedAnswers = haveThisAnswer
        ? state.items.map((item) =>
              item.id === action.payload.id
                  ? {
                        ...item,
                        ...action.payload
                    }
                  : item
          )
        : [payload, ...state.items];

    return {
        ...state,
        isLoading: false,
        items: updatedAnswers
    };
};
const createOrEditMentorshipFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// DELETE ANSWERS
const deleteMentorshipRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteMentorshipSuccess = (state = INITIAL_STATE, action: any) => {
    const updatedAnswers = state.items.filter((item) => item.id !== action.payload.id);

    return {
        ...state,
        isLoading: false,
        items: updatedAnswers
    };
};
const deleteMentorshipFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// LIKE ANSWERS
const likeMentorshipRequest = (state = INITIAL_STATE) => ({ ...state });
const likeMentorshipSuccess = (state = INITIAL_STATE, action: any) => {
    const { answer, status, comment } = action.payload;

    // Quando for um comentário, procura por ele através do id da questão
    if (!!comment) {
        return {
            ...state,
            items: state.items.map((item) =>
                item.id === answer
                    ? {
                          ...item,
                          answers: item.answers.map((_item) =>
                              _item.id === comment
                                  ? { ..._item, isLiked: status, likesCount: status ? ++_item.likesCount : --_item.likesCount }
                                  : _item
                          )
                      }
                    : item
            ),
            isLoading: false
        };
    }

    return {
        ...state,
        items: state.items.map((item) =>
            item.id === answer ? { ...item, isLiked: status, likesCount: status ? ++item.likesCount : --item.likesCount } : item
        ),
        isLoading: false
    };
};
const likeMentorshipFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// CREATE OR EDIT COMMENTS
const createOrEditMentorshipCommentsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditMentorshipCommentsSuccess = (state = INITIAL_STATE, action: any) => {
    const { comment, answer } = action.payload;

    const oldAnswer = state.items.find((item) => item.id === answer);

    const haveThisComment = oldAnswer!.answers.some((itemAnswer) => itemAnswer.id === comment.id);

    const updatedAnswer = haveThisComment
        ? {
              ...oldAnswer,
              answers: oldAnswer!.answers.map((item) =>
                  item.id === comment.id
                      ? {
                            ...item,
                            ...comment
                        }
                      : item
              )
          }
        : {
              ...oldAnswer,
              answers: [comment, ...oldAnswer!.answers]
          };

    return {
        ...state,
        isLoading: false,
        items: state.items.map((item) => (item.id === answer ? updatedAnswer : item))
    };
};
const createOrEditMentorshipCommentsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// DELETE COMMENTS
const deleteMentorshipCommentsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const deleteMentorshipCommentsSuccess = (state = INITIAL_STATE, action: any) => {
    const { commentId, answerId } = action.payload;

    return {
        ...state,
        isLoading: false,
        items: state.items.map((item) =>
            item.id === answerId
                ? {
                      ...item,
                      answers: item.answers.filter((comment) => comment.id !== commentId)
                  }
                : item
        )
    };
};
const deleteMentorshipCommentsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer<IMentorshipState>(INITIAL_STATE, {
    [Types.OPEN_MENTORSHIP_REQUEST]: openMentorshipRequest,
    [Types.OPEN_MENTORSHIP_SUCCESS]: openMentorshipSuccess,
    [Types.OPEN_MENTORSHIP_FAILURE]: openMentorshipFailure,

    [Types.GET_MENTORSHIP_REQUEST]: getMentorshipRequest,
    [Types.GET_MENTORSHIP_SUCCESS]: getMentorshipSuccess,
    [Types.GET_MENTORSHIP_FAILURE]: getMentorshipFailure,

    [Types.CREATE_OR_EDIT_MENTORSHIP_REQUEST]: createOrEditMentorshipRequest,
    [Types.CREATE_OR_EDIT_MENTORSHIP_SUCCESS]: createOrEditMentorshipSuccess,
    [Types.CREATE_OR_EDIT_MENTORSHIP_FAILURE]: createOrEditMentorshipFailure,

    [Types.DELETE_MENTORSHIP_REQUEST]: deleteMentorshipRequest,
    [Types.DELETE_MENTORSHIP_SUCCESS]: deleteMentorshipSuccess,
    [Types.DELETE_MENTORSHIP_FAILURE]: deleteMentorshipFailure,

    [Types.CREATE_OR_EDIT_MENTORSHIP_COMMENTS_REQUEST]: createOrEditMentorshipCommentsRequest,
    [Types.CREATE_OR_EDIT_MENTORSHIP_COMMENTS_SUCCESS]: createOrEditMentorshipCommentsSuccess,
    [Types.CREATE_OR_EDIT_MENTORSHIP_COMMENTS_FAILURE]: createOrEditMentorshipCommentsFailure,

    [Types.DELETE_MENTORSHIP_COMMENTS_REQUEST]: deleteMentorshipCommentsRequest,
    [Types.DELETE_MENTORSHIP_COMMENTS_SUCCESS]: deleteMentorshipCommentsSuccess,
    [Types.DELETE_MENTORSHIP_COMMENTS_FAILURE]: deleteMentorshipCommentsFailure,

    [Types.LIKE_MENTORSHIP_REQUEST]: likeMentorshipRequest,
    [Types.LIKE_MENTORSHIP_SUCCESS]: likeMentorshipSuccess,
    [Types.LIKE_MENTORSHIP_FAILURE]: likeMentorshipFailure
});
