import React, { useState } from "react";

import Icon from "components/Icons/icon";
import { ConfigBalloon, Container, PlaybackRate, TextConfig, WrapperIcon, WrapperPlaybackRate } from "./styles";

interface IVideoConfigProps {
    active: number;
    onChangeActive: any;
}

interface IPlaybackRates {
    label: string;
    value: number;
}

const VideoConfig = ({ active, onChangeActive }: IVideoConfigProps) => {
    const [open, setOpen] = useState(false);
    const [currentActive, setCurrentActive] = useState(active);

    const playbackRates: IPlaybackRates[] = [
        { label: "0.25", value: 0.25 },
        { label: "0.5", value: 0.5 },
        { label: "1", value: 1 },
        { label: "1.25", value: 1.25 },
        { label: "1.5", value: 1.5 }
    ];

    const handleCurrentActive = (value: number) => {
        setCurrentActive(value);
        onChangeActive(value);
        setOpen(false);
    };

    const renderPlaybackRates = () => {
        const mappedPlaybackRates = playbackRates.map((playbackRate) => (
            <PlaybackRate
                key={playbackRate.value}
                isActive={playbackRate.value === currentActive}
                onClick={() => handleCurrentActive(playbackRate.value)}
            >
                {playbackRate.label}
            </PlaybackRate>
        ));

        return mappedPlaybackRates;
    };

    return (
        <Container>
            <WrapperIcon>
                <button onClick={() => setOpen(!open)}>
                    <Icon idIcon="ico-config" width="15px" height="15px" viewBox="0 0 12 13" />
                </button>
                {open && (
                    <ConfigBalloon>
                        <TextConfig>Velocidade do vídeo:</TextConfig>
                        <WrapperPlaybackRate>{renderPlaybackRates()}</WrapperPlaybackRate>
                    </ConfigBalloon>
                )}
            </WrapperIcon>
        </Container>
    );
};

export default VideoConfig;
