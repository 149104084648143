import React, { memo, useCallback, useMemo, useState } from "react";

import InfoFilledIcon from "prodigio-icons/web/filled/Info";
import InfoOutlinedIcon from "prodigio-icons/web/outlined/Info";

import Dropdown from "components/Dropdown";
import ProgressBar from "components/ProgressBar";
import { DropdownTextContainer } from "components/Dropdown/styles";

import { theme } from "config/theme";
import * as S from "./styles";

interface IItem {
    name: string;
    description: string;
    note: number;
    noteMaximum: number;
    color?: string;
}

interface IEssayCompetenceProps {
    as?: any;
    withContent?: boolean;
    commentNumber?: number;
    item: IItem;
}

const EssayCompetence = ({ as = "li", withContent = true, commentNumber, item }: IEssayCompetenceProps) => {
    const [isInfoOpen, setIsInfoOpen] = useState(false);

    const Icon = useMemo(() => (isInfoOpen ? InfoFilledIcon : InfoOutlinedIcon), [isInfoOpen]);

    const handleToggleInfoTooltip = useCallback(() => setIsInfoOpen(!isInfoOpen), [isInfoOpen]);

    const total = useMemo(() => (item?.note * 100) / item?.noteMaximum, [item]);

    return (
        <S.EssayCompetenceContainer as={as}>
            <S.EssayCompetenceWrapper color={item?.color || theme.colors.base[200]}>
                <div>
                    {commentNumber && <S.EssayCommentNumber color="#FCD0AA">{commentNumber}</S.EssayCommentNumber>}
                    <S.EssayCompetenceTitle>{item?.name}</S.EssayCompetenceTitle>
                    <Dropdown
                        active={isInfoOpen}
                        direction="bottom"
                        label={<Icon width="16" height="16" color={item?.color || theme.colors.base[200]} />}
                        onClick={handleToggleInfoTooltip}
                    >
                        <DropdownTextContainer>
                            <p>{item?.description}</p>
                        </DropdownTextContainer>
                    </Dropdown>
                </div>
                {withContent && (
                    <div>
                        <S.EssayCompetenceText>
                            Nota:
                            <strong>{item?.note}</strong>
                        </S.EssayCompetenceText>
                    </div>
                )}
            </S.EssayCompetenceWrapper>
            {withContent && <ProgressBar color={item?.color || "#995A33"} value={total || 0} responsive={true} />}
        </S.EssayCompetenceContainer>
    );
};

export default memo(EssayCompetence);
