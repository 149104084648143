import * as React from "react";

import { TextareaField } from "./styles";

// Assets
export interface IProps {
    value: string;
    placeholder: string;
    limit?: boolean;
    minRows?: number;
    maxLength?: number;
    autoFocus?: boolean;
    onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}

interface IState {
    textareaRows: number;
}

const MIN_ROWS = 2;
const TEXTAREA_LINEHEIGHT = 24;

class Textarea extends React.PureComponent<IProps, IState> {
    private readonly textAreaRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: IProps) {
        super(props);

        this.textAreaRef = React.createRef<HTMLTextAreaElement>();

        this.state = {
            textareaRows: props.minRows ? (props.minRows > MIN_ROWS ? props.minRows : MIN_ROWS) : MIN_ROWS
        };
    }

    public changeHeight = () => {
        const textarea = this.textAreaRef.current;

        if (!textarea) {
            return;
        }

        const previousRows = textarea.rows;

        textarea.rows = this.props.minRows ? (this.props.minRows > MIN_ROWS ? this.props.minRows : MIN_ROWS) : MIN_ROWS;

        // tslint:disable-next-line: no-bitwise
        const currentRows = ~~(textarea.scrollHeight / TEXTAREA_LINEHEIGHT);

        if (currentRows === previousRows) {
            textarea.rows = currentRows;
        }

        this.setState({ textareaRows: currentRows });
    }

    public render() {
        const { onChange, value, placeholder, maxLength, limit, autoFocus } = this.props;
        const { textareaRows } = this.state;

        return (
            <TextareaField
                value={value}
                placeholder={placeholder}
                maxLength={maxLength}
                limit={limit}
                onChange={onChange}
                onKeyDown={this.changeHeight}
                rows={textareaRows}
                autoFocus={autoFocus}
                ref={this.textAreaRef}
            />
        );
    }
}

export default Textarea;
