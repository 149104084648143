import React from "react";
import { theme } from "config/theme";
import Skeleton from "components/Skeleton";
import { Col } from "components/Grid";
import useIsMobile from "hooks/use-is-mobile";

const PreviousExamDetailsExamDaySkeleton = () => {
    const isMobile = useIsMobile();

    return (
        <>
            <Col xs={12} md={6}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Skeleton height="30px" width="80%" style={{ marginBottom: theme.spacing.small }} />
                    <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.small }} />
                    <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.medium }} />
                    <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.small }} />
                </div>
            </Col>
            {!isMobile && (
                <Col xs={12} md={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Skeleton height="30px" width="80%" style={{ marginBottom: theme.spacing.small }} />
                        <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.small }} />
                        <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.medium }} />
                        <Skeleton height="25px" width="100%" style={{ marginBottom: theme.spacing.small }} />
                    </div>
                </Col>
            )}
        </>
    );
};

export default React.memo(PreviousExamDetailsExamDaySkeleton);
