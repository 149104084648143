// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    IconOverlay,
    IconWrapperSkeleton,
    ResumeCardWrapper,
    ResumeCardCounterSkeleton,
    ResumeCardDescriptionSkeleton
} from "./performance-resume-card.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceResumeCardSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <ScreenWidthRender
                renderingWidth={1024}
                actionAfterRenderingWidth="show"
                content={
                    <IconOverlay>
                        <IconWrapperSkeleton>
                            <Skeleton width="100%" height="100%" borderRadius="50%" />
                        </IconWrapperSkeleton>
                    </IconOverlay>
                }
            />

            <ResumeCardWrapper>
                <ScreenWidthRender
                    renderingWidth={1024}
                    actionAfterRenderingWidth="hide"
                    content={
                        <IconWrapperSkeleton>
                            <Skeleton width="100%" height="100%" borderRadius="50%" />
                        </IconWrapperSkeleton>
                    }
                />

                <Spacing size={16} direction="horizontal" />

                <div>
                    <ResumeCardCounterSkeleton>
                        <Skeleton width="48px" height="100%" borderRadius="4px" />
                    </ResumeCardCounterSkeleton>

                    <ResumeCardDescriptionSkeleton>
                        <Skeleton width="144px" height="100%" borderRadius="4px" />
                    </ResumeCardDescriptionSkeleton>
                </div>
            </ResumeCardWrapper>
        </Container>
    );
};
