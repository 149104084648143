import styled, { css } from "styled-components";

import { theme } from "config/theme";
import { WebpDropdown } from "components/WebpDropdown";

export const CustomExerciseListCardsContainer = styled.div`
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;

    #footer {
        width: 100%;
        margin: 2.5em auto;
        -webkit-animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    @media (min-width: 768px) {
        #footer {
            width: 13.75em;
            margin: 4em auto 1.5em;
        }

        .webp-button {
            &#createListBtn {
                width: 5.875em;
            }
        }
    }

    @media (min-width: 1366px) {
        #footer {
            width: 10.125em;
            margin: 4em auto 2em;
        }

        .webp-button {
            &#createListBtn {
                width: 10em;
            }
        }
    }

    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

export const CustomExerciseListCardsHeader = styled.div`
    display: flex;
    flex-direction: column;

    #title {
        color: var(--theme-color-neutral-500);
        margin: 1.25em auto;
        text-align: center;
        font-weight: bold;

        @media (max-width: 767px) {
            font-size: 20px;
        }
    }

    .row-2 {
        display: flex;
        margin-bottom: 2.063em;
    }

    .row-1 {
        margin-bottom: 2em;

        #createListBtn {
            margin-top: 1em;
        }

        #search {
            position: relative;
            margin-bottom: 1rem;

            #search-icon {
                position: absolute;
                right: 10.62px;
                top: 50%;
                transform: translate(0%, -50%);
            }
        }
    }

    #name {
        display: flex;

        #amount {
            margin-left: 0.286em;
            color: ${theme.colors.brand[300]};
        }
    }

    .filter {
        display: block;
        align-items: center;
        width: 34px;
        margin-left: auto;
        height: 0;
        position: relative;
        cursor: pointer;

        .row-order {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        #label {
            display: none;
        }

        #filter {
            width: 24px;
            height: 24px;
        }

        .icon {
            width: 8px;
            height: 4px;
            margin-left: 4px;
            transition: transform 0.3s;
            transform: rotate(180deg);
        }

        &.clicked {
            .icon {
                transform: rotate(0deg);
            }
        }
    }

    @media (min-width: 768px) {
        #title {
            margin: 0;
            text-align: left;
        }

        #name {
            margin: 0;
            font-weight: 700;
        }

        [class^="row"] {
            display: flex;
            align-items: center;
        }

        .row-1 {
            margin: 3em 0 2.188em;
            justify-content: space-between;
            align-items: center;

            #createListBtn {
                margin: 0;
                width: auto;
                margin-left: 2rem;
            }

            .search-container {
                display: flex;
                width: 60%;
                justify-content: space-between;
            }
        }

        .filter {
            width: 20%;
            top: -10px;

            #label {
                display: inline-block;
                font-weight: 600;
                color: var(--theme-color-neutral-400);
                margin: 0 0.549em 0 0.25em;
            }
        }
    }

    @media (min-width: 1366px) {
        .row-1 {
            justify-content: space-between;

            .search-container {
                #search {
                    margin-left: auto;
                    width: 33.938em;
                }
            }
        }
    }
`;

export const CustomExerciseListCardsSection = styled.div<{ customListItems?: number }>`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));

    // Min-height prevents footer from moving
    @media (min-width: 768px) {
        min-height: 250px;
    }

    ${({ customListItems }) =>
        customListItems === 1 &&
        css`
            @media (min-width: 1200px) {
                grid-template-columns: repeat(auto-fit, minmax(288px, 352px));
            }
        `}
    gap: 2em;

    .webp-dropdown-children li {
        padding: 0.143em 0.604em 0.286em 0.554em;
        font-family: var(--default-font-family);
        cursor: pointer;
    }

    .webp-dropdown-children li:hover {
        background: var(--theme-color-neutral-200);
        color: var(--theme-color-neutral-500);
    }
`;

export const CustomExerciseListCardsDropDown = styled(WebpDropdown)`
    width: 13.313em;
    height: 0;
    right: -5px;
    position: absolute;

    > .dropdown {
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: theme-color(neutral, 200) theme-color(neutral, 100);
        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: theme-color(neutral, 100);
        }

        &::-webkit-scrollbar-thumb {
            background-color: theme-color(neutral, 200);
            border-radius: 20px;
        }
    }
`;

export const CustomExerciseListCardsDropDownList = styled.li`
    font-size: 0.875rem;
    line-height: 1.43;
    font-family: var(--default-font-family);
    padding: 0.143em 0.604em 0.286em 0.554em;
    color: var(--theme-color-neutral-400);
    cursor: pointer;

    &:hover,
    &.active {
        color: var(--theme-color-neutral-500);
        background: var(--theme-color-neutral-200);
    }
`;

export const SpinnerWrapper = styled.div`
    width: 100%;
    position: relative;

    // Min-height prevents footer from moving
    @media (min-width: 768px) {
        min-height: 250px;
    }
`;
