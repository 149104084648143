// Dependencies
import { theme } from "config/theme";
import styled from "styled-components";

import { IProps } from ".";

const setSize = (props: IProps) => {
    if (props.size === "small") {
        return "24px";
    } else if (props.size === "large") {
        return "46px";
    }

    return "35px";
};

export const InitialChar = styled.span`
    color: ${theme.colors.white};
    display: block;
    font-weight: 700;
    text-align: center;
    width: 100%;
`;

export const AvatarContainer = styled.figure`
    background-color: ${theme.colors.brand[300]};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: inline-block;
    height: ${setSize};
    overflow: hidden;
    width: ${setSize};

    ${InitialChar} {
        line-height: ${setSize};
    }
`;
