// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    variant?: "brand" | "base";
    disabled: boolean;
    selected: boolean;
    wrapLine?: boolean;
    withCloseButton?: boolean;
};
export const Container = styled.div<ContainerStyleProps>`
    display: inline-flex;
    padding-inline: 1em;
    cursor: pointer;
    border-radius: 0.75em;
    transition: all ease 0.2s;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    border: 0.8px solid ${theme.colors.base[200]};
    
    .label-text {
        color: ${theme.colors.base[300]};
        font-size: 1rem;
        font-weight: 600;
        font-style: normal;
        font-family: "Nunito", sans-serif;
        line-height: 1.375;
        letter-spacing: 0.005em;
    }

        ${({ disabled, variant }) =>
            !!disabled &&
            variant &&
            css`
                cursor: not-allowed;
            `}

        ${({ selected, variant }) =>
            !!selected &&
            !!variant &&
            css`
                background-color: ${theme.colors[variant][300]};
                border-color: ${theme.colors[variant][300]};

                .label-text {
                    color: ${theme.colors.white};
                }
            `}

            ${({ withCloseButton, variant }) =>
                !!withCloseButton &&
                !!variant &&
                css`
                    cursor: default;
                    padding-inline: 0.428em;

                    .label-text {
                        font-size: 0.875rem;
                        font-weight: 600;
                        letter-spacing: 0.01em;
                        line-height: 1.571;
                        font-family: "Nunito", sans-serif;
                        font-style: normal;

                        max-width: 100%;
                        color: ${theme.colors[variant][400]};
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .close-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        cursor: pointer;
                        width: 1rem;
                        height: 1rem;
                        margin-left: 0.5em;
                        border-radius: 0.5em;
                        background-color: ${theme.colors[variant][200]};
                        color: ${theme.colors[variant][400]};
                    }
                `}

                ${({ wrapLine }) =>
                    !!wrapLine &&
                    css`
                        .label-text {
                            white-space: pre-wrap;
                        }
                    `}
`;
