// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityHeaderProps } from "./activity-header.types";

// Styles
import { Container, Description, Title, TitleWrapper } from "./activity-header.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityHeader: FunctionComponent<ActivityHeaderProps> = ({ title, description, titleVariant, actionButtonElement }) => {
    return (
        <Container>
            <TitleWrapper>
                <Title variant={titleVariant}>{title}</Title>

                <ConditionallyRender shouldRender={!!description} content={<Description>{description}</Description>} />
            </TitleWrapper>

            {actionButtonElement}
        </Container>
    );
};
