import React from "react";

// Components
import { Grid } from "components/Grid";
import Icon from "components/Icons/icon";

// Assets
import {
    AnnouncementScreenCard,
    AnnouncementScreenCardContent,
    AnnouncementScreenCardFooter,
    AnnouncementScreenCardFooterTeacher,
    AnnouncementScreenCardFooterTime,
    AnnouncementScreenCardHeader,
    AnnouncementScreenCardHeaderBadge,
    AnnouncementScreenContainer,
    AnnouncementScreenEmpty,
    AnnouncementScreenHeader,
    AnnouncementScreenHeaderContainer,
    AnnouncementScreenSearch,
    AnnouncementScreenSearchButton,
    AnnouncementScreenWrapp
} from "./styles";

// Redux
import { connect } from "react-redux";
import { Creators as ModalActions } from "store/ducks/modal";
import { bindActionCreators, compose, Dispatch } from "redux";

// Helpers
import { IReduxStore } from "interfaces/IReduxStore";
import { DateTime } from "luxon";
import { IAnnouncementState } from "store/ducks/announcement";

export interface IProps {
    modalActions: any;
    announcement: IAnnouncementState;
}

const AnnouncementScreen = ({ modalActions, announcement }: IProps) => (
    <>
        <AnnouncementScreenHeader>
            <AnnouncementScreenHeaderContainer fluid>
                <h1>Comunicados</h1>
                <AnnouncementScreenSearch>
                    <AnnouncementScreenSearchButton>
                        <Icon idIcon="ico-search" width="20" height="20" viewBox="0 0 60 60" />
                    </AnnouncementScreenSearchButton>
                </AnnouncementScreenSearch>
            </AnnouncementScreenHeaderContainer>
        </AnnouncementScreenHeader>
        <AnnouncementScreenContainer>
            <Grid fluid>
                <AnnouncementScreenWrapp>
                    {(!announcement || announcement.items.length === 0) && (
                        <AnnouncementScreenEmpty>Sem comunicados até agora =(</AnnouncementScreenEmpty>
                    )}
                    {announcement &&
                        announcement.items.map((item) => (
                            <AnnouncementScreenCard key={item.id} onClick={() => modalActions.openModal("announcement", item)}>
                                <AnnouncementScreenCardHeader>
                                    <strong>{item.notification.title}</strong>
                                    <AnnouncementScreenCardHeaderBadge>
                                        {item.viewed}
                                        {!!item.viewed ? "Lido" : "Não Lido"}
                                    </AnnouncementScreenCardHeaderBadge>
                                </AnnouncementScreenCardHeader>
                                <AnnouncementScreenCardContent>
                                    {item.notification.preview || item.notification.title} <i>ler mais</i>
                                </AnnouncementScreenCardContent>
                                <AnnouncementScreenCardFooter>
                                    <AnnouncementScreenCardFooterTeacher>
                                        por <span>{item.notification.signature}</span>
                                    </AnnouncementScreenCardFooterTeacher>
                                    <AnnouncementScreenCardFooterTime>
                                        {DateTime.fromISO(item.sentDate).toFormat("dd/MM/yyyy") || " "}
                                    </AnnouncementScreenCardFooterTime>
                                </AnnouncementScreenCardFooter>
                            </AnnouncementScreenCard>
                        ))}
                </AnnouncementScreenWrapp>
            </Grid>
        </AnnouncementScreenContainer>
    </>
);

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch)
});

const mapStateToProps = ({ announcement }: IReduxStore) => ({
    announcement
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(AnnouncementScreen);
