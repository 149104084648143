import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { theme } from "config/theme";

export default function getQuestionInKnowledgeArea(knowledgeAreas: IExamKnowledgeAreas[], questionNumber: number) {
    try {
        if (!questionNumber || isNaN(questionNumber) || !knowledgeAreas || !knowledgeAreas.length) {
            throw new Error();
        }

        const knowledgeArea = knowledgeAreas.find((knowledgeArea) => knowledgeArea.questions.some((question) => question.number === questionNumber));

        if (!knowledgeArea) {
            throw new Error("knowledgeArea not found");
        }

        const question = knowledgeArea.questions.find((question) => question.number === questionNumber);

        if (!question) {
            throw new Error("question not found");
        }

        return {
            question,
            knowledgeArea: {
                name: knowledgeArea.name,
                color: knowledgeArea.color || theme.colors.base[300]
            }
        };
    } catch (error) {
        return undefined;
    }
}
