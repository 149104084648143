// Dependencies
import styled, { css } from "styled-components";

// Types
import { IconBadgeScale } from "./icon-badge.types";

type ContainerStyleProps = {
    hasAction: boolean;
    backgroundColor: string;
    scale: IconBadgeScale;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    ${({ hasAction }) =>
        hasAction &&
        css`
            cursor: pointer;
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}

    ${({ scale }) =>
        scale === "small" &&
        css`
            width: 24px;
            height: 24px;
        `}

    ${({ scale }) =>
        scale === "medium" &&
        css`
            width: 36px;
            height: 36px;
        `}

    ${({ scale }) =>
        scale === "large" &&
        css`
            width: 56px;
            height: 56px;
        `}
`;

type IconStyleProps = {
    scale: IconBadgeScale;
};

export const Icon = styled.img<IconStyleProps>`
    ${({ scale }) =>
        scale === "small" &&
        css`
            width: 16px;
            height: 16px;
        `}
    
    ${({ scale }) =>
        scale === "medium" &&
        css`
            width: 24px;
            height: 24px;
        `}

    ${({ scale }) =>
        scale === "large" &&
        css`
            width: 40px;
            height: 40px;
        `}
`;
