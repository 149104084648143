import React, { memo } from "react";
import * as S from "./styles";
import Skeleton from "components/Skeleton";

const LessonPlanDayWeeksSkeleton = memo(() => {
    return (
        <S.SubjectScreenDetailsActiveModuleContainer xs={12} sm={6}>
            <S.SubjectScreenDetailsActiveModuleCard>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", marginLeft: "5px" }}>
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                    <Skeleton width="58px" height="68px" />
                </div>
            </S.SubjectScreenDetailsActiveModuleCard>
        </S.SubjectScreenDetailsActiveModuleContainer>
    );
});

export default LessonPlanDayWeeksSkeleton;
