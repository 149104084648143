import React, { useMemo } from "react";

// assets
import { ExerciseNotDoneWrap, ExerciseListDashboardNotDoneItem } from "./styles";

// helpers
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { theme } from "config/theme";

interface IProps {
    questionsTotal: number;
    isPreviousExam?: boolean;
    knowledgeAreas?: IExamKnowledgeAreas[];
}

const ExerciseListDashboardNotDone = ({ questionsTotal, isPreviousExam = false, knowledgeAreas = [] }: IProps) => {
    const hasKnowledgeAreas = useMemo(() => !!knowledgeAreas && !!knowledgeAreas.length, [knowledgeAreas]);

    return (
        <ExerciseNotDoneWrap>
            <h3>Conteúdo da {isPreviousExam ? "prova" : "lista"}:</h3>
            <ExerciseListDashboardNotDoneItem>
                &bull; <span data-test-id="exercise-list-questions-total">{questionsTotal}</span> Questões
            </ExerciseListDashboardNotDoneItem>

            {hasKnowledgeAreas && (
                <>
                    <h3 style={{ marginTop: theme.spacing.small }}>Áreas de conhecimento:</h3>

                    {knowledgeAreas.map((knowledgeArea) => (
                        <ExerciseListDashboardNotDoneItem key={knowledgeArea.id}>
                            &bull; <span>{knowledgeArea.name}</span>
                        </ExerciseListDashboardNotDoneItem>
                    ))}
                </>
            )}
        </ExerciseNotDoneWrap>
    );
};

export default React.memo(ExerciseListDashboardNotDone);
