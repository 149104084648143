import styled from "styled-components/macro";
import { theme } from "config/theme";
import ReactSelect from "react-select";
import { MediaQueries } from "assets/styles/settings";

import { Container as WebpCardContainer } from "components/WebpCard/styles";

export const LessonPlanCustomCareerWebpCard = styled.div`
    ${WebpCardContainer} {
        ${MediaQueries.SMALL} {
            margin-top: ${theme.spacing.medium};
            margin-bottom: ${theme.spacing.small};
        }

        ${MediaQueries.TABLET_PORTRAIT} {
            margin-top: ${theme.spacing.medium};
            margin-bottom: ${theme.spacing.small};
        }
    }
`;

export const LessonPlanCustomCareerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 461px;

    img {
        align-self: center;
    }

    > p {
        font-size: 16px;
        line-height: 22px;
        color: ${theme.colors.base[500]};
    }

    .labelLessonPlanCustomCareer {
        padding-bottom: 16px;
        border-bottom: 1px solid #f0f1f7;
        margin-bottom: 16px;
        width: 100%;
        text-align: center;
    }
`;

export const FieldWrapper = styled.div`
    margin-top: ${theme.spacing.small};
    margin-bottom: 82px;
`;

export const SelectStyled = styled(ReactSelect)<{ error?: string; size?: string }>`
    .react-select__control {
        border-color: ${({ error }) => (error ? theme.colors.system.danger[300] : theme.colors.base[300])};
    }

    #react-select-2-input {
        width: 100% !important;
    }
`;
