import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText2, TitleH3 } from "assets/styles/global";

export const ForbiddenMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 ${theme.spacing.medium};

    img {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ForbiddenMessageTitle = styled.strong`
    ${TitleH3};
    color: ${theme.colors.system.info[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const ForbiddenMessageText = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    white-space: break-spaces;
`;
