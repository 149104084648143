import styled, { css } from "styled-components";
import { fadein } from "assets/styles/animations";
import { theme } from "config/theme";
import { hexToRgba } from "helpers";

export const ModalContainer = styled.div`
    align-items: center;
    background: ${hexToRgba(theme.colors.base[500], 0.5)};
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999999;

    animation: ${fadein} 0.15s linear;
`;

type ModalWrapperStyleProps = {
    isFullyAdaptative?: boolean;
};

export const ModalWrapper = styled.div<ModalWrapperStyleProps>`
    background: #fff;
    border-radius: 4px;
    overflow-y: auto;
    position: relative;

    ${({ isFullyAdaptative }) =>
        !!isFullyAdaptative
            ? css`
                  width: calc(100% - ${theme.spacing.large});
                  height: calc(100% - ${theme.spacing.large});

                  @media (min-width: 768px) {
                      width: calc(100% - ${theme.spacing.large});
                      height: calc(100% - ${theme.spacing.largeX});
                      max-width: 1260px;
                  }
              `
            : css`
                  max-width: calc(100% - ${theme.spacing.small});
                  max-height: calc(100% - ${theme.spacing.small});
                  min-height: 50px;
              `}
`;

export const ModalClose = styled.button`
    position: fixed;
    right: 30px;
    top: 58px;
    z-index: 10;
    background: transparent;
    display: flex;
    border: none;

    svg {
        color: ${theme.colors.base[500]};
    }
`;
