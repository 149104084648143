import { AxiosError } from "axios";
import HttpStatus from "enums/HttpStatus";
import { put } from "redux-saga/effects";
import { Creators as modalActions } from "../../ducks/modal";

interface IErrorHandler {
    payload: AxiosError;
    type: string;
}

export function* errorHandler(action: IErrorHandler) {
    try {
        if (!action.payload || !action.payload.response) {
            throw new Error("Ocorreu um erro com o request");
        }

        const { response } = action.payload;

        if (response.status === HttpStatus.PAYMENT_REQUIRED) {
            yield put(modalActions.openModal("paywall"));

            const updateData = {
                ...response.data,
                blockContent: true
            };

            yield put({
                type: action.type.replace("FAILURE", "SUCCESS"),
                payload: updateData
            });
        }

        throw new Error("Action não monitorada.");
    } catch (error) {
        //  console.log(error);
    }
}
