import { IReduxStore } from "interfaces/IReduxStore";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as answerCardActions, IAnswerCard } from "store/ducks/answerCard";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as examActions } from "store/ducks/exam";
import { Creators as modalActions } from "store/ducks/modal";
import { IExamActions } from "store/ducks/exam/types";
import { IAnswerCardActions } from "store/interfaces/IActions";
import { IAlertActions } from "store/interfaces/IAlert";
import { IModalActions } from "store/interfaces/IModal";
import IPagination from "interfaces/IPagination";
import { IExamDay } from "interfaces/IExamDays";
import { paginationInitialData } from "utils/paginationInitialData";
import { connect } from "react-redux";

import CloseIcon from "prodigio-icons/web/outlined/Close";

import * as S from "./styles";
import ExamDayDetails from "../ExamDayDetails";
import { ExamDayStatus } from "enums/ExamDayStatus";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";

import history from "services/history";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import FinishedExamDays from "../FinishedExamDays";
import { ExamDayHeaderSkeleton as HeaderSkeleton } from "./skeleton";
import isProdigioProject from "utils/isProdigioProject";
import { ApplymentWindowType } from "enums/ApplymentWindowType";
import { theme } from "config/theme";

import { useDispatch } from "react-redux";
import api from "services/api";
import { ternary } from "utils/ternary";

//

interface IProps {
    examActions: IExamActions;
    answerCardActions: IAnswerCardActions;
    alertActions: IAlertActions;
    modalActions: IModalActions;
    results: IPagination<IAnswerCard>;
    examDay: IExamDay;
    answerCard: IAnswerCard;
    activeResultModal: boolean;
    error: boolean;
    resultError: boolean;
    resultLoading: boolean;
    loading: boolean;
    hasCredits: boolean;
    courseSlug: string;
}

const ExamDay = ({
    examActions,
    answerCardActions,
    examDay,
    alertActions,
    answerCard,
    activeResultModal,
    error,
    resultError,
    modalActions,
    resultLoading,
    results = paginationInitialData,
    loading,
    hasCredits,
    courseSlug
}: IProps) => {
    const [disableButtonPDF, setDisableButtonPDF] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            examActions.clearExamDay();
        };
    }, [examActions]);

    useEffect(() => {
        return () => answerCardActions.clearAnswerCardResults();
    }, [answerCardActions]);

    const requestResults = useCallback(() => {
        if (!examDay?.exerciseList?.id) {
            return;
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/exerciselist/${examDay?.exerciseList?.id}/answercard?status=[${AnswerCardStatus.Done},${AnswerCardStatus.Corrected}]`
        };

        answerCardActions.getResultCardsRequest(payload);
    }, [answerCardActions, examDay]);

    useEffect(() => {
        requestResults();
    }, [requestResults]);

    const isAvailable = useMemo(() => {
        const { status, exam } = examDay;

        if (!status) {
            return false;
        }

        return !!exam?.isAvailableForTests || !!exam?.isAvailable || status === ExamDayStatus.InProgress;
    }, [examDay]);

    const isTester = useMemo(() => !!examDay?.exam?.isAvailableForTests, [examDay]);

    const startExamButtonLabel = useMemo(() => {
        if (examDay?.status === ExamDayStatus.CommingSoon) {
            return "Em breve";
        }

        if (!!examDay?.studentHasOpenAnswerCard) {
            return "Continuar prova";
        }

        return !!results?.items?.length ? "Refazer prova" : "Fazer prova";
    }, [examDay, results]);

    const realizations = useMemo(
        () => results?.items?.map(({ id, endDate, examResolution }) => ({ id, endDate, isTest: !!examResolution?.test })) || [],
        [results]
    );

    const handleCloseModal = useCallback(() => {
        examActions.clearExamDay();
        examActions.closeExamDayModal();
    }, [examActions]);

    const handleStartList = useCallback(
        (mode?: AnswerCardRealizationMode | "feedback") => {
            if (!examDay?.exerciseList?.id || !examDay?.questionsTotal || !examDay?.exam?.slug || !examDay.slug) {
                return alertActions.showAlert("Desculpe, você ainda não pode iniciar a prova 😞", "warning");
            }

            if (mode === "feedback") {
                return history.push({ pathname: `/app/curso/${courseSlug}/simulados/${examDay?.exam?.slug}/${examDay.slug}/gabarito` });
            }

            const realizationMode = !!mode && !isNaN(mode) ? mode : AnswerCardRealizationMode.Proof;

            answerCardActions.postAnswerCardRequest({
                id: examDay?.exerciseList?.id,
                mode: realizationMode,
                examDaySlug: examDay.slug,
                next: (answerCardId: number) =>
                    history.push(`/app/curso/${courseSlug}/simulados/${examDay?.exam?.slug}/${examDay.slug}/prova/${answerCardId}`)
            });
        },
        [alertActions, answerCardActions, courseSlug, examDay]
    );

    const handleClickStartList = useCallback(() => {
        if (!examDay?.exerciseList?.id || !examDay?.questionsTotal) {
            return alertActions.showAlert("Desculpe, você ainda não pode iniciar a prova 😞", "warning");
        }

        const isProdigio = isProdigioProject();

        if (!hasCredits && isProdigio && !!examDay?.exam?.isPaid) {
            return modalActions.openModal("confirmAction", {
                title: "Ops!",
                text: <p>Você não tem créditos suficiente para fazer esse simulado.</p>,
                onConfirm: () => modalActions.closeModal(),
                variant: "danger",
                buttonText: "Cancelar"
            });
        }

        const text =
            isProdigio && !!examDay?.exam?.isPaid ? (
                <p>
                    Tem certeza que deseja usar <strong>1 crédito</strong> para comprar o simulado?
                </p>
            ) : (
                <p>Tem certeza que deseja iniciar o simulado?</p>
            );

        modalActions.openModal("confirmAction", {
            title: "Atenção!",
            text,
            onConfirm: () => handleStartList(),
            variant: "warning",
            buttonText: "Confirmar"
        });
    }, [alertActions, examDay, handleStartList, hasCredits, modalActions]);

    const handleClickResult = useCallback(
        (answerCardId: number) => {
            if (!answerCardId || !examDay?.exam?.slug || !examDay.slug) {
                return;
            }

            history.push(`/app/curso/${courseSlug}/simulados/${examDay?.exam?.slug}/${examDay.slug}/resultado/${answerCardId}`);
        },
        [courseSlug, examDay]
    );

    const handleClickSeeAnswers = useCallback(() => {
        handleClickResult(realizations[0]?.id);
    }, [handleClickResult, realizations]);

    const handleClickdownloadPDF = useCallback(async () => {
        try {
            setDisableButtonPDF(true);
            await api

                .get(`/student/exerciselist/${examDay?.exerciseList?.id}/report`, {
                    responseType: "blob"
                })

                .then((blob) => {
                    new File([blob.data], "file");

                    const url = window.URL.createObjectURL(blob.data);

                    const a = document.createElement("a");

                    a.href = url;

                    a.download = `${examDay?.exam?.name}.pdf`;

                    document.body.appendChild(a);

                    a.click();

                    a.remove();
                });

            setDisableButtonPDF(false);
        } catch (error) {
            console.log(error);

            dispatch(alertActions.showAlert("Ocorreu um erro ao imprimir a lista", "danger"));

            setDisableButtonPDF(false);
        }
    }, [alertActions, dispatch, examDay]);

    const isInPersonApplyment = examDay?.applyment === ApplymentWindowType.InPerson;

    const hasEssay = !!examDay?.essayTheme?.idTheme;

    const knowledgeAreas = hasEssay
        ? [
              ...examDay?.knowledgeAreas,
              {
                  id: 69,
                  name: "Redação",
                  slug: "redacao",
                  color: theme.colors.brand[300]
              }
          ]
        : examDay?.knowledgeAreas ?? [];

    const headerVariantColor = ternary([
        [!loading && !isAvailable, "base"],
        [!loading && isAvailable && !!realizations && realizations?.length > 0, "secondary"],
        [!loading && isAvailable && !!realizations && realizations?.length === 0, "brand"],
        [true, "base"]
    ]);

    return (
        <S.ExamDayDarkBackground onClick={handleCloseModal}>
            <S.ExamDayCard onClick={(e: any) => e.stopPropagation()}>
                {loading ? (
                    <HeaderSkeleton onClose={handleCloseModal} />
                ) : (
                    <S.ExamDayHeader variant={headerVariantColor as any}>
                        <button type="button" onClick={handleCloseModal}>
                            <CloseIcon />
                        </button>
                    </S.ExamDayHeader>
                )}

                <ExamDayDetails
                    isAvailable={isAvailable}
                    isTester={isTester}
                    startDate={examDay?.startDate}
                    endDate={examDay?.endDate}
                    name={examDay?.name || ""}
                    title={examDay?.exam?.name}
                    totalQuestions={examDay?.questionsTotal || 0}
                    knowledgeAreas={knowledgeAreas}
                    durationInMinutes={examDay?.durationInMinutes || 0}
                    startLabel={startExamButtonLabel}
                    onClickStart={!!examDay?.studentHasOpenAnswerCard ? handleStartList : handleClickStartList}
                    onClickStartTester={handleStartList}
                    onClickSeeAnswers={handleClickSeeAnswers}
                    canSeeAnswers={realizations?.length === 1}
                    loading={loading}
                    loadingStartExam={resultLoading}
                    isInPersonApplyment={isInPersonApplyment}
                    handleClickdownloadPDF={handleClickdownloadPDF}
                    disableButtonPDF={disableButtonPDF}
                    examDaysStatus={examDay?.status || 0}
                    variantColor={headerVariantColor as any}
                />

                {!!realizations?.length && <FinishedExamDays realizations={realizations} loading={resultLoading} onClickResult={handleClickResult} />}
            </S.ExamDayCard>
        </S.ExamDayDarkBackground>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    examActions: bindActionCreators(examActions, dispatch),
    answerCardActions: bindActionCreators(answerCardActions, dispatch) as any,
    alertActions: bindActionCreators(alertActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch)
});

const mapStateToProps = ({ exam, answerCard, exerciseList, course }: IReduxStore) => ({
    examDay: exam.day,
    answerCard: answerCard.answerCard,
    activeResultModal: exam.activeResultModal,
    error: exam.error,
    loading: exam.loadingDay,
    results: answerCard.results,
    resultLoading: answerCard.isLoading || exerciseList.isLoading || exam.loadingDay,
    resultError: answerCard.error || exerciseList.error,
    hasCredits: !!exam?.totalCredits,
    courseSlug: course.slug
});

export default connect(mapStateToProps, mapDispatchToProps)(ExamDay);
