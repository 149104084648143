import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ExerciseListEssayContainer, ExerciseListEssayWriteInfo } from "./styles";
import { connect } from "react-redux";
import IconAttention from "prodigio-icons/web/filled/Attention";
import EssayTheme from "./theme";
import { MakeEssaySwitcherWrapper } from "components/MakeEssay/styles";
import Switcher from "components/Switcher";
import EssayAttach from "components/MakeEssay/attach";
import EssayCanvas from "components/MakeEssay/canvas";
import validateEssayText from "utils/validateEssayText";
import { KeyboardKeys } from "enums/Keys";
import useDebounce from "hooks/use-debounce";
import { IReduxStore } from "interfaces/IReduxStore";
import { IExerciseListEssayTheme } from "interfaces/IExerciseList";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { useModal } from "hooks/use-modal";
import { IConfirmEssayImageModalProps } from "components/Modal/ConfirmEssayImage";

enum SwitcherIndex {
    Attach = 0,
    Write = 1
}

interface IProps {
    essayTheme?: IExerciseListEssayTheme;
    essayText?: string;
    essayImage?: string;
    answerCardId?: number;
    isFinished: boolean;
    handleSendEssay(data: { files?: File[]; content?: string }): void;
}

const ExerciseListEssayMake: React.FC<IProps> = ({ essayTheme, essayText, essayImage, answerCardId, isFinished, handleSendEssay }) => {
    const modal = useModal();

    const savedText = localStorage.getItem(`answerCardComposition:${answerCardId}`);
    const textInitial = savedText ?? essayText ?? "";

    // State
    const [activeSwitchIndex, setActiveSwitchIndex] = useState(savedText ? SwitcherIndex.Write : SwitcherIndex.Attach);
    const [fileImage, setFileImage] = useState<string>();
    const [text, setText] = useState(textInitial);
    const [files, setFiles] = useState<File[]>([]);

    // TMP
    const tempText = useRef(text);
    const tempShowNotSavedYet = useRef(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        tempText.current = text;
    }, [text]);

    // Actions
    const handleSaveEssayLocalStorage = useCallback(
        (data: { content: string }) => {
            if (!data?.content) {
                return;
            }

            localStorage.setItem(`answerCardComposition:${answerCardId}`, data?.content || "");
        },
        [answerCardId]
    );

    const debounceText = useDebounce(text, 1500);

    useEffect(() => {
        if (!debounceText) {
            return;
        }

        handleSaveEssayLocalStorage({ content: debounceText });
    }, [debounceText, handleSaveEssayLocalStorage]);

    useEffect(() => {
        const hasText = !!essayText || !!savedText;

        setActiveSwitchIndex(hasText ? SwitcherIndex.Write : SwitcherIndex.Attach);

        setFileImage(hasText ? "" : essayImage);
    }, [essayImage, essayText, savedText]);

    const handleSwitcherChange = useCallback((index: number) => setActiveSwitchIndex(index), []);

    const handleChangeFiles = useCallback((files: File[]) => setFiles(files), []);

    const handleChangeText = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.currentTarget;
        const canWrite = validateEssayText(event, textareaRef.current);

        if (!canWrite) {
            return;
        }

        setText(value);
    }, []);

    const handleKeyUp = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { value } = event.currentTarget;
        const canWrite = validateEssayText(event, textareaRef.current);

        if (!canWrite && event.keyCode !== KeyboardKeys.KEY_ENTER) {
            return setText(value.substr(0, value.length - 1));
        }

        return setText(value);
    }, []);

    const handleSelectFiles = useCallback(
        (files: File[]) => {
            const [file] = files;

            const payload: IConfirmEssayImageModalProps = {
                file,
                onConfirm: () => handleSendEssay({ files })
            };

            modal.open("confirmEssayImageModal", payload);
        },
        [handleSendEssay, modal]
    );

    const activeMode = useMemo(() => {
        switch (activeSwitchIndex) {
            case SwitcherIndex.Attach:
                return (
                    <EssayAttach
                        fileImage={fileImage}
                        files={files}
                        onClearFiles={() => handleChangeFiles([])}
                        onUpload={handleSelectFiles}
                        onSend={(files) => handleSendEssay({ files })}
                        onClearFileImage={() => setFileImage("")}
                    />
                );

            case SwitcherIndex.Write:
                return (
                    <EssayCanvas
                        value={text}
                        onChange={handleChangeText}
                        onKeyUp={handleKeyUp}
                        ref={textareaRef}
                        handleDraft={(content) => handleSendEssay({ content })}
                        draftButtonLabel="Salvar"
                        generateBase64={false}
                    />
                );

            default:
                return null;
        }
    }, [activeSwitchIndex, fileImage, files, handleChangeFiles, handleChangeText, handleKeyUp, handleSelectFiles, handleSendEssay, text]);

    const showNotSavedYet = useMemo(() => {
        if (activeSwitchIndex === SwitcherIndex.Write) {
            return text !== essayText;
        }

        return !!files?.length;
    }, [activeSwitchIndex, essayText, files, text]);

    useEffect(() => {
        tempShowNotSavedYet.current = showNotSavedYet;
    }, [showNotSavedYet]);

    const unMount = useCallback(() => {
        console.log("%cUNMOUNT", "background: red; color: #fff;");

        if (!tempShowNotSavedYet.current || isFinished) {
            return;
        }

        handleSendEssay({ content: tempText.current });
    }, [handleSendEssay, isFinished]);

    useEffect(() => {
        return () => {
            unMount();
        };
    }, [unMount]);

    if (!essayTheme?.idTheme) {
        return null;
    }

    return (
        <ExerciseListEssayContainer>
            <EssayTheme essayTheme={essayTheme} />

            <MakeEssaySwitcherWrapper>
                <Switcher activeIndex={activeSwitchIndex} options={["Anexar", "Escrever"]} onChange={handleSwitcherChange} />
            </MakeEssaySwitcherWrapper>

            {showNotSavedYet && (
                <ExerciseListEssayWriteInfo>
                    <IconAttention width={16} height={16} />
                    <p>Você possui alterações não salvas.</p>
                </ExerciseListEssayWriteInfo>
            )}

            {activeMode}
        </ExerciseListEssayContainer>
    );
};

// USES CONNECT TO PREVENT UNNECESSARY RENDERS
const mapStateToProps = ({ answerCard, exerciseList }: IReduxStore) => {
    return {
        essayTheme: exerciseList?.essayTheme,
        essayText: answerCard?.answerCard?.composition?.content,
        essayImage: answerCard?.answerCard?.composition?.pathOriginal,
        answerCardId: answerCard?.answerCard?.id,
        isFinished: ~~answerCard?.answerCard?.status >= AnswerCardStatus.Done
    };
};

export default connect(mapStateToProps)(ExerciseListEssayMake);
