// Dependencies
import React, {
    Fragment,
    FunctionComponent,
    useEffect
    // useMemo
} from "react";
import { useParams } from "react-router-dom";

// Services
import history from "services/history";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as activityActions } from "store/ducks/activity";

// Components
import { ActivityResults } from "components/ActivityResults";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ActivityBreadcrumbs, ActivityBreadcrumbItem } from "components/ActivityBreadcrumbs";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ActivityButton } from "components/ActivityButton";
// import { ActivityInput } from "components/ActivityInput";
import { ActivityStudentGrid, ActivityStudentGridRow, ActivityStudentGridCell } from "components/ActivityStudentTable";
import { ActivityChip } from "components/ActivityChip";
import { ActivityChipVariants } from "components/ActivityChip/activity-chip.types";
// import { ActivityDropContent } from "components/ActivityDropContent";
// import { ActivityBottomSheet } from "components/ActivityBottomSheet";
// import { ActivityCheckbox } from "components/ActivityCheckbox";
// import { ActivityDropDown, ActivityOptionItem } from "components/ActivityDropDown";
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

// Styles
import {
    ActivityDetailsInfoWrapper,
    ActivityDetailsLabel,
    ActivityDetailsLabelWrapper,
    ActivityDetailsSeparator,
    ActivityDetailsTitle,
    ActivityResultContentWrapper,
    ActivityResultCurrentStudentContentWrapper,
    ActivityResultDefaultCard,
    // ActivityStudentFilterIcon,
    // ActivityStudentFilterInputIcon,
    ActivityStudentTableFilterCompositionWrapper,
    ActivityStudentTableDefaultText,
    ActivityStudentTableContentWrapper,
    ActivityStudentTableTitle,
    ActivityStudentTableTitleWrapper,
    ActivityStudentTableDefaultTextWrapper,
    ActivityStudentTableActionButtonIcon,
    ActivityDetailsIndicatorLabel,
    // ActivitFilterBottomSheetClassCheckboxsWrapper,
    // ActivityFilterBottomSheetContentTitle,
    // ActivityFilterBottomSheetContentWrapper,
    // ActivityFilterBottomSheetStatusChipsWrapper,
    // ActivityFilterBottomSheetWrapper,
    // ActivityFilterButtonsWrapper,
    // ActivityFilterBottomSheetFixedContent,
    // ActivityResultFilterCloseIcon,
    // ActivityResultFilterOpenedContentCard,
    // ActivityResultFilterTitle,
    // ActivityResultFilterTitleWrapper,
    ActivityStudentGridHeaderItemLabel,
    SkeletonWrapper
} from "./activity-teacher-submissions.styles";

// Assets
import arrowLeft from "assets/img/arrow-left.svg";
import chevronLeft from "assets/img/chevron-left.svg";
// import filter from "assets/img/activity-filter.svg";
// import searchIcon from "assets/img/search.svg";
// import close from "assets/img/close.svg";

// Utils
import { ternary } from "utils/ternary";
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";

// Data
import // classes,
// status,
"./activity-teacher-submissions.mock";

// Types
import { ActivityTypeEnum, ActivitySubmissionStatusEnum } from "store/interfaces/activity";

type ActivityTeacherSubmissionsParams = {
    activityId: string;
};

export const ActivityTeacherSubmissions: FunctionComponent = () => {
    const { activityId } = useParams<ActivityTeacherSubmissionsParams>();

    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const { data, activityDetails } = useSelector(({ activity }: IReduxStore) => activity.teacherActivitySubmissions);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    // const [isTeacherActivityFilterOpened, setIsTeacherActivityFilterOpened] = useState(false);

    useEffect(() => {
        if (activityId) {
            dispatch(activityActions.getTeacherActivitySubmissionsActivityDetailsRequest(activityId));
            dispatch(activityActions.getTeacherActivitySubmissionsDataRequest(activityId));
        }
    }, [activityId, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearTeacherActivitySubmissions());
        };
    }, [dispatch]);

    const handleStudentCardClick = (submissionId: string) => {
        const activityType = ternary([
            [activityDetails.data?.type === "ExerciseList", "lista-de-exercicios"],
            [activityDetails.data?.type === "Composition", "discursiva"],
            [activityDetails.data?.type === "Upload", "envio-de-arquivo"],
            [true, "lista-de-exercicios"]
        ]);

        history.push({
            pathname: `/app/curso/${courseSlug}/atividades/${activityType}/${activityDetails.data?.id}/entrega/${submissionId}`
        });
    };

    const handleDownloadMaterials = () => {
        activityDetails.data?.supportMaterials?.forEach((material) => {
            openLinkInBlankPage(material.source);
        });
    };

    // const selectedStatus = "em-andamento";
    // const selectedClass = "intensivo";

    // const defaultHeaderLabels = ["Nome", "Turma", "Desempenho", "Status", "Ação"];
    // const headerLabels = useMemo(() => (width < 1920 ? defaultHeaderLabels.filter((label) => label !== "Turma") : defaultHeaderLabels), [
    //     defaultHeaderLabels,
    //     width
    // ]);
    const headerLabels = ["Nome", "Desempenho", "Status", "Ação"];

    // const filtersCompositions = (
    //     <ActivityDropContent
    //         contentElement={
    //             <ActivityButton
    //                 label="Filtrar"
    //                 size="large"
    //                 variantType="secondary"
    //                 iconElement={<ActivityStudentFilterIcon src={filter} />}
    //                 onClick={() => setIsTeacherActivityFilterOpened(!isTeacherActivityFilterOpened)}
    //             />
    //         }
    //         isOpened={isTeacherActivityFilterOpened}
    //         openedContentElement={
    //             <Fragment>
    //                 <ScreenWidthRender
    //                     actionAfterRenderingWidth="hide"
    //                     renderingWidth={1024}
    //                     content={
    //                         <ActivityBottomSheet
    //                             label="Filtros"
    //                             handleOutsideClick={() => setIsTeacherActivityFilterOpened(false)}
    //                             content={
    //                                 <ActivityFilterBottomSheetWrapper>
    //                                     <ActivityFilterBottomSheetFixedContent>
    //                                         <ActivityFilterBottomSheetContentWrapper>
    //                                             <ActivityFilterBottomSheetContentTitle>Status</ActivityFilterBottomSheetContentTitle>

    //                                             <ActivityFilterBottomSheetStatusChipsWrapper>
    //                                                 {status.map((item) => (
    //                                                     <ActivityChip
    //                                                         key={`activity-filter-status-${item.value}`}
    //                                                         label={item.label}
    //                                                         variant={item.value === selectedStatus ? "cta" : "default"}
    //                                                         size="large"
    //                                                         handleClick={() => console.log(item.value)}
    //                                                     />
    //                                                 ))}
    //                                             </ActivityFilterBottomSheetStatusChipsWrapper>
    //                                         </ActivityFilterBottomSheetContentWrapper>

    //                                         <ActivityFilterBottomSheetContentWrapper hasPaddingBottom>
    //                                             <ActivityFilterBottomSheetContentTitle>Turma</ActivityFilterBottomSheetContentTitle>

    //                                             <ActivitFilterBottomSheetClassCheckboxsWrapper>
    //                                                 {classes.map((item) => (
    //                                                     <ActivityCheckbox
    //                                                         key={`activity-filter-class-${item.value}`}
    //                                                         label={item.label}
    //                                                         isChecked={selectedClass === item.value}
    //                                                         handleClick={() => console.log(item.value)}
    //                                                     />
    //                                                 ))}
    //                                             </ActivitFilterBottomSheetClassCheckboxsWrapper>
    //                                         </ActivityFilterBottomSheetContentWrapper>
    //                                     </ActivityFilterBottomSheetFixedContent>
    //                                 </ActivityFilterBottomSheetWrapper>
    //                             }
    //                             actionButtonCompositions={
    //                                 <Fragment>
    //                                     <ActivityButton isFullyAdaptative label="Filtrar" size="large" onClick={() => console.log("Filtrar")} />

    //                                     <ActivityButton
    //                                         isFullyAdaptative
    //                                         label="Remover filtros"
    //                                         variantType="secondary"
    //                                         size="large"
    //                                         onClick={() => console.log("Remover filtros")}
    //                                     />
    //                                 </Fragment>
    //                             }
    //                         />
    //                     }
    //                 />

    //                 <ScreenWidthRender
    //                     actionAfterRenderingWidth="show"
    //                     renderingWidth={1024}
    //                     content={
    //                         <ActivityResultFilterOpenedContentCard>
    //                             <ActivityResultFilterTitleWrapper>
    //                                 <ActivityResultFilterTitle>Filtros</ActivityResultFilterTitle>

    //                                 <ActivityResultFilterCloseIcon src={close} onClick={() => setIsTeacherActivityFilterOpened(false)} />
    //                             </ActivityResultFilterTitleWrapper>

    //                             <ActivityFilterBottomSheetContentWrapper>
    //                                 <ActivityFilterBottomSheetContentTitle>Status</ActivityFilterBottomSheetContentTitle>

    //                                 <ActivityFilterBottomSheetStatusChipsWrapper>
    //                                     {status.map((item) => (
    //                                         <ActivityChip
    //                                             key={`activity-filter-status-${item.value}`}
    //                                             label={item.label}
    //                                             variant={item.value === selectedStatus ? "cta" : "default"}
    //                                             size="large"
    //                                             handleClick={() => console.log(item.value)}
    //                                         />
    //                                     ))}
    //                                 </ActivityFilterBottomSheetStatusChipsWrapper>
    //                             </ActivityFilterBottomSheetContentWrapper>

    //                             <ActivityFilterBottomSheetContentWrapper>
    //                                 <ActivityFilterBottomSheetContentTitle>Turma</ActivityFilterBottomSheetContentTitle>

    //                                 <ConditionallyRender shouldRender={isSkeletonVisible} content={<Skeleton height="42px" borderRadius="4px" />} />

    //                                 <ConditionallyRender
    //                                     shouldRender={!isSkeletonVisible}
    //                                     content={
    //                                         <ActivityDropDown
    //                                             placeholder="Selecione uma lista"
    //                                             optionItemsCompositions={classes.map((item) => (
    //                                                 <ActivityOptionItem
    //                                                     key={`activity-filter-class-${item.value}`}
    //                                                     label={item.label}
    //                                                     isActive={selectedClass === item.value}
    //                                                     handleClick={() => console.log(item.value)}
    //                                                 />
    //                                             ))}
    //                                         />
    //                                     }
    //                                 />
    //                             </ActivityFilterBottomSheetContentWrapper>

    //                             <ActivityDetailsSeparator />

    //                             <ActivityFilterButtonsWrapper>
    //                                 <ActivityButton isFullyAdaptative label="Filtrar" onClick={() => console.log("Filtrar")} />

    //                                 <ActivityButton label="Remover filtros" variantType="secondary" onClick={() => console.log("Remover filtros")} />
    //                             </ActivityFilterButtonsWrapper>
    //                         </ActivityResultFilterOpenedContentCard>
    //                     }
    //                 />
    //             </Fragment>
    //         }
    //     />
    // );

    const supportMaterialsContent = (
        <ActivityDetailsLabelWrapper>
            <ActivityDetailsIndicatorLabel>Material extra</ActivityDetailsIndicatorLabel>

            <ConditionallyRender shouldRender={!!activityDetails.isLoading} content={<Skeleton height="24px" width="80px" borderRadius="4px" />} />

            <ConditionallyRender
                shouldRender={!activityDetails.isLoading && !!activityDetails.data?.supportMaterials?.length}
                content={<ActivityButton variantType="link" variant="info" label="Visualizar" onClick={handleDownloadMaterials} />}
            />

            <ConditionallyRender
                shouldRender={!activityDetails.isLoading && !activityDetails.data?.supportMaterials?.length}
                content={<ActivityDetailsLabel>-</ActivityDetailsLabel>}
            />
        </ActivityDetailsLabelWrapper>
    );

    return (
        <ActivityResults
            breadcrumbsComposition={
                <Fragment>
                    <ScreenWidthRender
                        actionAfterRenderingWidth="hide"
                        renderingWidth={1024}
                        content={
                            <ActivityBreadcrumbs
                                breadcrumbItemCompositions={[
                                    <ActivityBreadcrumbItem
                                        key={1}
                                        label="Voltar"
                                        icon={arrowLeft}
                                        size="small"
                                        isActive={false}
                                        handleClick={() => history.goBack()}
                                    />
                                ]}
                            />
                        }
                    />

                    <ScreenWidthRender
                        actionAfterRenderingWidth="show"
                        renderingWidth={1024}
                        content={
                            <ActivityBreadcrumbs
                                breadcrumbItemCompositions={[
                                    <ActivityBreadcrumbItem
                                        key={1}
                                        label="Atividades"
                                        size="small"
                                        isActive={false}
                                        handleClick={() => history.push({ pathname: `/app/curso/${courseSlug}/atividades` })}
                                    />,
                                    !activityDetails.isLoading ? (
                                        <ActivityBreadcrumbItem key={2} label={activityDetails.data?.title!} size="large" isActive={true} />
                                    ) : (
                                        <Skeleton height="28px" width="280px" borderRadius="4px" />
                                    )
                                ]}
                            />
                        }
                    />
                </Fragment>
            }
            content={
                <ActivityResultContentWrapper>
                    <ActivityResultCurrentStudentContentWrapper className="additional-content">
                        <ActivityResultDefaultCard>
                            <ConditionallyRender
                                shouldRender={!!activityDetails.isLoading}
                                content={
                                    <Fragment>
                                        <Skeleton height="26px" borderRadius="4px" />

                                        <SkeletonWrapper>
                                            <Skeleton height="20px" borderRadius="4px" />

                                            <Spacing direction="vertical" size={2} />

                                            <Skeleton height="20px" borderRadius="4px" />

                                            <Spacing direction="vertical" size={2} />

                                            <Skeleton height="20px" width="144px" borderRadius="4px" />
                                        </SkeletonWrapper>
                                    </Fragment>
                                }
                            />

                            <ConditionallyRender
                                shouldRender={!activityDetails.isLoading}
                                content={
                                    <Fragment>
                                        <ScreenWidthRender
                                            actionAfterRenderingWidth="hide"
                                            renderingWidth={1024}
                                            content={<ActivityDetailsTitle>{activityDetails.data?.title}</ActivityDetailsTitle>}
                                        />

                                        <ScreenWidthRender
                                            actionAfterRenderingWidth="show"
                                            renderingWidth={1024}
                                            content={<ActivityDetailsTitle>Enunciado</ActivityDetailsTitle>}
                                        />

                                        <ActivityDetailsLabel
                                            dangerouslySetInnerHTML={{ __html: activityDetails.data?.enunciation?.split("\n").join("<br/>") ?? "" }}
                                        />
                                    </Fragment>
                                }
                            />

                            <ScreenWidthRender
                                actionAfterRenderingWidth="show"
                                renderingWidth={1440}
                                haveFullyAdaptativeWidth
                                content={supportMaterialsContent}
                            />

                            <ActivityDetailsSeparator />

                            <ActivityDetailsInfoWrapper>
                                <ActivityDetailsLabelWrapper>
                                    <ActivityDetailsIndicatorLabel>Tipo</ActivityDetailsIndicatorLabel>

                                    <ConditionallyRender
                                        shouldRender={!!activityDetails.isLoading}
                                        content={<Skeleton height="20px" width="80px" borderRadius="4px" />}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!activityDetails.isLoading}
                                        content={
                                            <ActivityDetailsLabel>
                                                {ActivityTypeEnum[activityDetails.data?.type ?? "ExerciseList"]}
                                            </ActivityDetailsLabel>
                                        }
                                    />
                                </ActivityDetailsLabelWrapper>

                                <ActivityDetailsLabelWrapper>
                                    <ActivityDetailsIndicatorLabel>Prazo</ActivityDetailsIndicatorLabel>

                                    <ConditionallyRender
                                        shouldRender={!!activityDetails.isLoading}
                                        content={<Skeleton height="20px" width="80px" borderRadius="4px" />}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!activityDetails.isLoading}
                                        content={<ActivityDetailsLabel>{activityDetails.data?.dueDate ?? "-"}</ActivityDetailsLabel>}
                                    />
                                </ActivityDetailsLabelWrapper>

                                <ActivityDetailsLabelWrapper>
                                    <ActivityDetailsIndicatorLabel>Pontuação</ActivityDetailsIndicatorLabel>

                                    <ConditionallyRender
                                        shouldRender={!!activityDetails.isLoading}
                                        content={<Skeleton height="20px" width="80px" borderRadius="4px" />}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!activityDetails.isLoading}
                                        content={<ActivityDetailsLabel>{activityDetails.data?.score ?? "-"}</ActivityDetailsLabel>}
                                    />
                                </ActivityDetailsLabelWrapper>

                                <ScreenWidthRender
                                    actionAfterRenderingWidth="hide"
                                    renderingWidth={1440}
                                    haveFullyAdaptativeWidth
                                    content={supportMaterialsContent}
                                />
                            </ActivityDetailsInfoWrapper>
                        </ActivityResultDefaultCard>
                    </ActivityResultCurrentStudentContentWrapper>

                    <ActivityResultCurrentStudentContentWrapper className="result">
                        <ActivityStudentGrid
                            headerComposition={
                                <Fragment>
                                    <ScreenWidthRender
                                        actionAfterRenderingWidth="hide"
                                        renderingWidth={1024}
                                        haveFullyAdaptativeWidth
                                        content={
                                            <ActivityStudentTableTitleWrapper>
                                                <ActivityStudentTableFilterCompositionWrapper>
                                                    <ActivityStudentTableTitle>Alunos</ActivityStudentTableTitle>

                                                    {/* {filtersCompositions} */}
                                                </ActivityStudentTableFilterCompositionWrapper>

                                                {/* <ActivityInput
                                                    label="Pesquisar"
                                                    placeholder="Pesquisar um aluno"
                                                    iconElement={<ActivityStudentFilterInputIcon src={searchIcon} />}
                                                /> */}
                                            </ActivityStudentTableTitleWrapper>
                                        }
                                    />

                                    <ScreenWidthRender
                                        actionAfterRenderingWidth="show"
                                        renderingWidth={1024}
                                        haveFullyAdaptativeWidth
                                        content={
                                            <ActivityStudentTableTitleWrapper>
                                                <ActivityStudentTableTitle>Alunos</ActivityStudentTableTitle>

                                                {/* <ActivityStudentTableFilterCompositionWrapper>
                                                    <ActivityInput
                                                        label="Pesquisar"
                                                        placeholder="Pesquisar um aluno"
                                                        width={256}
                                                        iconElement={<ActivityStudentFilterInputIcon src={searchIcon} />}
                                                    />

                                                    {filtersCompositions}
                                                </ActivityStudentTableFilterCompositionWrapper> */}
                                            </ActivityStudentTableTitleWrapper>
                                        }
                                    />
                                </Fragment>
                            }
                            rowHeaderComposition={
                                <ActivityStudentGridRow
                                    cellCompositions={headerLabels.map((headerLabel, index) => (
                                        <ActivityStudentGridHeaderItemLabel key={`activity-student-grid-header-item-label-${index}`}>
                                            {headerLabel}
                                        </ActivityStudentGridHeaderItemLabel>
                                    ))}
                                />
                            }
                            rowItemCompositions={
                                <Fragment>
                                    <ConditionallyRender
                                        shouldRender={!!data.isLoading}
                                        content={Array.from({ length: 8 }).map((_, index) => (
                                            <ActivityStudentGridRow
                                                key={`activity-student-grid-row-${index}`}
                                                hasBorderBottom={index !== 7}
                                                cellCompositions={
                                                    <Fragment>
                                                        <ActivityStudentGridCell
                                                            isFullyAdaptative
                                                            element={
                                                                <Fragment>
                                                                    <ScreenWidthRender
                                                                        actionAfterRenderingWidth="hide"
                                                                        renderingWidth={1920}
                                                                        content={
                                                                            <ActivityStudentTableContentWrapper>
                                                                                <ActivityStudentTableDefaultTextWrapper>
                                                                                    <Skeleton width="100px" height="20px" borderRadius="4px" />

                                                                                    {/* <Spacing size={8} direction="vertical" />

                                                                                    <ActivityStudentTableDefaultText color="#83899e">
                                                                                        Turma:{" "}
                                                                                        <Skeleton width="96px" height="20px" borderRadius="4px" />
                                                                                    </ActivityStudentTableDefaultText> */}
                                                                                </ActivityStudentTableDefaultTextWrapper>

                                                                                <ScreenWidthRender
                                                                                    actionAfterRenderingWidth="hide"
                                                                                    renderingWidth={1024}
                                                                                    content={
                                                                                        <ActivityStudentTableDefaultTextWrapper>
                                                                                            <ActivityStudentTableDefaultText>
                                                                                                Pontos:
                                                                                                <Skeleton
                                                                                                    width="24px"
                                                                                                    height="20px"
                                                                                                    borderRadius="4px"
                                                                                                />
                                                                                            </ActivityStudentTableDefaultText>

                                                                                            <Skeleton
                                                                                                width="96px"
                                                                                                height="26px"
                                                                                                borderRadius="200px"
                                                                                            />
                                                                                        </ActivityStudentTableDefaultTextWrapper>
                                                                                    }
                                                                                />
                                                                            </ActivityStudentTableContentWrapper>
                                                                        }
                                                                    />

                                                                    <ScreenWidthRender
                                                                        actionAfterRenderingWidth="show"
                                                                        renderingWidth={1920}
                                                                        content={
                                                                            <ActivityStudentTableDefaultText>
                                                                                <Skeleton width="160px" height="20px" borderRadius="4px" />
                                                                            </ActivityStudentTableDefaultText>
                                                                        }
                                                                    />
                                                                </Fragment>
                                                            }
                                                        />

                                                        {/* <ActivityStudentGridCell
                                                            renderingWidth={1920}
                                                            element={<Skeleton width="96px" height="20px" borderRadius="4px" />}
                                                        /> */}

                                                        <ActivityStudentGridCell
                                                            renderingWidth={1024}
                                                            element={
                                                                <ActivityStudentTableDefaultText>
                                                                    Pontos:
                                                                    <Skeleton width="24px" height="20px" borderRadius="4px" />
                                                                </ActivityStudentTableDefaultText>
                                                            }
                                                            isFullyAdaptative
                                                        />

                                                        <ActivityStudentGridCell
                                                            renderingWidth={1024}
                                                            element={<Skeleton width="96px" height="26px" borderRadius="200px" />}
                                                            isFullyAdaptative
                                                        />

                                                        <ActivityStudentGridCell
                                                            renderingWidth={1024}
                                                            element={<Skeleton width="96px" height="22px" borderRadius="4px" />}
                                                        />
                                                    </Fragment>
                                                }
                                            />
                                        ))}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!data.isLoading}
                                        content={data.data?.map((submission, index) => {
                                            const chipVariant = ternary([
                                                [submission.status === "Finished", "success"],
                                                [submission.status === "WaitingCorrection", "danger"],
                                                [submission.status === "Started", "warning"],
                                                [true, "disabled"]
                                            ]) as ActivityChipVariants;

                                            return (
                                                <ActivityStudentGridRow
                                                    key={`activity-student-grid-row-${submission.id}`}
                                                    hasBorderBottom={index !== (data.data?.length ?? 0) - 1}
                                                    {...(width < 1024 &&
                                                        submission.status !== "Unrealized" && {
                                                            handleClick: () => handleStudentCardClick(submission.id)
                                                        })}
                                                    cellCompositions={
                                                        <Fragment>
                                                            <ActivityStudentGridCell
                                                                isFullyAdaptative
                                                                element={
                                                                    <Fragment>
                                                                        <ScreenWidthRender
                                                                            actionAfterRenderingWidth="hide"
                                                                            renderingWidth={1920}
                                                                            content={
                                                                                <ActivityStudentTableContentWrapper>
                                                                                    <ActivityStudentTableDefaultTextWrapper>
                                                                                        <ActivityStudentTableDefaultText>
                                                                                            {submission.user.name}
                                                                                        </ActivityStudentTableDefaultText>

                                                                                        {/* <ActivityStudentTableDefaultText color="#83899e">
                                                                                            Turma: -
                                                                                        </ActivityStudentTableDefaultText> */}
                                                                                        <ScreenWidthRender
                                                                                            renderingWidth={1024}
                                                                                            actionAfterRenderingWidth="hide"
                                                                                            content={
                                                                                                <ActivityStudentTableActionButtonIcon
                                                                                                    src={chevronLeft}
                                                                                                />
                                                                                            }
                                                                                        />
                                                                                    </ActivityStudentTableDefaultTextWrapper>

                                                                                    <ScreenWidthRender
                                                                                        actionAfterRenderingWidth="hide"
                                                                                        renderingWidth={1024}
                                                                                        content={
                                                                                            <ActivityStudentTableDefaultTextWrapper>
                                                                                                <ActivityStudentTableDefaultText>
                                                                                                    Pontos: {submission.score ?? "-"}
                                                                                                </ActivityStudentTableDefaultText>

                                                                                                <ActivityChip
                                                                                                    label={
                                                                                                        ActivitySubmissionStatusEnum[
                                                                                                            submission.status
                                                                                                        ]
                                                                                                    }
                                                                                                    variant={chipVariant}
                                                                                                />
                                                                                            </ActivityStudentTableDefaultTextWrapper>
                                                                                        }
                                                                                    />
                                                                                </ActivityStudentTableContentWrapper>
                                                                            }
                                                                        />

                                                                        <ScreenWidthRender
                                                                            actionAfterRenderingWidth="show"
                                                                            renderingWidth={1920}
                                                                            content={
                                                                                <ActivityStudentTableDefaultText>
                                                                                    {submission.user.name}
                                                                                </ActivityStudentTableDefaultText>
                                                                            }
                                                                        />
                                                                    </Fragment>
                                                                }
                                                            />

                                                            {/* <ActivityStudentGridCell
                                                                renderingWidth={1920}
                                                                element={
                                                                    <ActivityStudentTableDefaultText clampLines={2}>
                                                                        Extensiva 2022
                                                                    </ActivityStudentTableDefaultText>
                                                                }
                                                            /> */}

                                                            <ActivityStudentGridCell
                                                                renderingWidth={1024}
                                                                text={`Pontos: ${submission.score ?? "-"}`}
                                                                isFullyAdaptative
                                                            />

                                                            <ActivityStudentGridCell
                                                                renderingWidth={1024}
                                                                element={
                                                                    <ActivityChip
                                                                        label={ActivitySubmissionStatusEnum[submission.status]}
                                                                        variant={chipVariant}
                                                                    />
                                                                }
                                                            />

                                                            <ActivityStudentGridCell
                                                                renderingWidth={1024}
                                                                element={
                                                                    <ActivityButton
                                                                        label={
                                                                            ["Unrealized", "Started"].includes(submission.status)
                                                                                ? "Aguardando..."
                                                                                : "Ver entrega"
                                                                        }
                                                                        variantType="link"
                                                                        variant="info"
                                                                        isDisabled={["Unrealized", "Started"].includes(submission.status)}
                                                                        onClick={() =>
                                                                            !["Unrealized", "Started"].includes(submission.status) &&
                                                                            handleStudentCardClick(submission.id)
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </Fragment>
                                                    }
                                                />
                                            );
                                        })}
                                    />
                                </Fragment>
                            }
                        />
                    </ActivityResultCurrentStudentContentWrapper>
                </ActivityResultContentWrapper>
            }
        />
    );
};
