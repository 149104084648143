// Dependencies
import styled, { css } from "styled-components/macro";

// Types
import { TooltipPosition, TooltipSize } from "./tooltip.types";

export const Arrow = styled.span`
    display: block;
    width: 0;
    height: 0;
    border: 8px solid transparent;
`;

type ContainerStyleProps = {
    arrowPosition: TooltipPosition;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    align-items: center;

    ${({ arrowPosition }) => {
        if (arrowPosition === "top") {
            return css`
                flex-direction: column-reverse;

                ${Arrow} {
                    border-bottom-color: #3a3e47;
                }
            `;
        }

        if (arrowPosition === "right") {
            return css`
                flex-direction: row;

                ${Arrow} {
                    border-left-color: #3a3e47;
                }
            `;
        }

        if (arrowPosition === "bottom") {
            return css`
                flex-direction: column;

                ${Arrow} {
                    border-top-color: #3a3e47;
                }
            `;
        }

        if (arrowPosition === "left") {
            return css`
                flex-direction: row-reverse;

                ${Arrow} {
                    border-right-color: #3a3e47;
                }
            `;
        }
    }};
`;

type LabelStyleProps = {
    size: TooltipSize;
};

export const Label = styled.p<LabelStyleProps>`
    height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #3a3e47;
    border-radius: 4px;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;

    ${({ size }) => {
        if (size === "small") {
            return css`
                width: 98px;
                padding: 8px;
            `;
        }

        if (size === "large") {
            return css`
                width: 200px;
                padding: 8px 16px;
            `;
        }
    }}
`;
