import React, { memo } from "react";

import Skeleton from "components/Skeleton";

export const SubjectScreenDetailsDesktopSelectFrontSkeletonModules = memo(() => {
    return <Skeleton width="100%" height="45px" style={{ maxWidth: "100%", width: "230px", height: "22px" }} />;
});

export const SubjectScreenDetailsDesktopSelectFrontSkeletonStats = memo(() => {
    return (
        <>
            <Skeleton width="20px" height="20px" style={{ marginRight: "8px" }} />

            <strong>
                <Skeleton width="25px" height="16px" />
            </strong>
            <span>
                <Skeleton width="6px" height="6px" style={{ borderRadius: "100%", marginRight: "8px" }} />
            </span>
            <p>
                <Skeleton width="120px" height="16px" style={{ maxWidth: "100%" }} />
            </p>
        </>
    );
});
