import { theme } from "config/theme";
import styled from "styled-components/macro";
import { FlatVariant } from "utils/types/Variant";

export const RadioHelper = styled.span`
    border: 2px solid ${theme.colors.base[300]};
    border-radius: 50%;
    display: block;
    height: 100%;
    position: relative;
    width: 100%;

    &:after {
        border-radius: 50%;
        content: "";
        height: 10px;
        left: 3px;
        position: absolute;
        top: 3px;
        transform: scale(0);
        transition: transform 0.2s ease;
        width: 10px;
    }
`;

export const RadioInput = styled.input`
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    &:checked ~ ${RadioHelper} {
        border-color: ${theme.colors.brand[300]};
    }

    &:checked ~ ${RadioHelper}:after {
        transform: scale(1);
    }
`;

const colors = {
    ...theme.colors,
    ...theme.colors.system
};

export const RadioLabel = styled.label<{ variant: FlatVariant | "brand" | "secondary" }>`
    cursor: pointer;
    display: block;
    height: 20px;
    position: relative;
    width: 20px;
    user-select: none;

    &:hover ${RadioInput} ~ ${RadioHelper} {
        background-color: #fafafa;
    }

    ${RadioHelper} {
        &:after {
            background: ${({ variant }) => colors[variant][300]};
        }
    }

    ${RadioInput} {
        &:checked ~ ${RadioHelper} {
            border-color: ${({ variant }) => colors[variant][300]};
        }
    }
`;
