// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useMemo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as activityActions } from "store/ducks/activity";
import { Creators as alertActions } from "store/ducks/alert";
import { ActivityType } from "store/interfaces/activity";

// Services
import history from "services/history";

// Components
import { ActivityButton } from "components/ActivityButton";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ActivityBreadcrumbItem, ActivityBreadcrumbs } from "components/ActivityBreadcrumbs";
// import { ActivityProgressBar } from "components/ActivityProgressBar";
import { ActivityExerciseLayout } from "components/ActivityExerciseLayout";
import { ActivityTitle } from "components/ActivityTitle";
import { ActivityEditor } from "components/ActivityEditor";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Skeleton } from "component-library/utilities/skeleton";
import { ActivityScoreBadge } from "components/ActivityScoreBadge";
import { ActivityScore } from "components/ActivityScore";
import { Spacing } from "component-library/utilities/spacing";
import { ActivityFileWrapper } from "components/ActivityFileWrapper";
import { FileInput } from "component-library/elements/file-input";
import { ActivityFile } from "components/ActivityFile";
import { ActivityProgressBar } from "components/ActivityProgressBar";
import { ActivityOverlay } from "components/ActivityOverlay";
import { Loader } from "component-library/elements/loader";
import { ActivitySubmissionFile } from "components/ActivitySubmissionFile";
import { ActivityModal } from "components/ActivityModal";

// Styles
import {
    ActivityExerciseCardSeparator,
    ActivityExerciseCardSubTitle,
    ActivityExerciseCardTitle,
    ActivityExerciseCardTitleWrapper,
    // ActivityProgressLabel,
    // ActivityProgressLabelWrapper,
    // ActivityProgressWrapper,
    ActivityScoreLabel,
    ActivityScoreLabelWrapper,
    ActivityScoreWrapper,
    ActivitySendFileAnswerWrapper,
    DefaultActivityCard,
    Label,
    ScoreActivityCardWrapper,
    SkeletonWrapper,
    ActivityMobileTitle,
    ActivityDefaultRemoveIcon,
    ActionButtonIcon
} from "./activity-student-execution.styles";

// Assets
import arrowLeft from "assets/img/arrow-left.svg";
import discipline from "assets/img/discipline.svg";
import attachment from "assets/img/attachment.svg";
import trash from "assets/img/trash.svg";
import redTrash from "assets/img/red-trash.svg";
import close from "assets/img/close.svg";

// Utils
import { ternary } from "utils/ternary";
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";
import { ValidationSchema, validationSchema } from "./activity-student-execution.schema";
import { ActivityDefaultActionButton } from "screens/activity-teacher-create/activity-teacher-create.styles";
import isImage from "utils/is-image";

type ActivityStudentExecutionParams = {
    activityType: string;
    activityId: string;
};

export const ActivityStudentExecution: FunctionComponent = () => {
    const { activityType, activityId } = useParams<ActivityStudentExecutionParams>();

    const { activityDetails, answerCardId, isCreating, isRequestingFile } = useSelector(({ activity }: IReduxStore) => activity.studentExecution);
    const { courseSlug } = useSelector(({ course }: IReduxStore) => ({
        courseSlug: course.slug
    }));

    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const { control, watch, setValue, formState, trigger, clearErrors } = useForm<ValidationSchema>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            compose: "",
            comment: ""
        }
    });

    const comment = watch("comment");
    const compose = watch("compose");

    const [commentHasAlreadyBeenSaved, setCommentHasAlreadyBeenSaved] = useState(false);
    const [composeHasAlreadyBeenSaved, setComposeHasAlreadyBeenSaved] = useState(false);
    const [userHasExceedWordLimit, setUserHasExceedWordLimit] = useState(false);

    useEffect(() => {
        if (!!activityId) {
            dispatch(activityActions.getStudentExecutionActivityDetailsRequest(activityId));
        }
    }, [activityId, dispatch]);

    useEffect(() => {
        if (!activityDetails.isLoading && !!activityDetails.data?.submission?.compose && !compose && !composeHasAlreadyBeenSaved) {
            setValue("compose", activityDetails.data?.submission?.compose ?? "");
            setComposeHasAlreadyBeenSaved(true);
        }
    }, [activityDetails.data, activityDetails.isLoading, compose, composeHasAlreadyBeenSaved, setValue]);

    useEffect(() => {
        if (!activityDetails.isLoading && !!activityDetails.data?.submission?.studentComment && !comment && !commentHasAlreadyBeenSaved) {
            setValue("comment", activityDetails.data?.submission?.studentComment);
            setCommentHasAlreadyBeenSaved(true);
        }
    }, [activityDetails.data, activityDetails.isLoading, comment, commentHasAlreadyBeenSaved, setValue]);

    const handleRedirectToExerciseListExecution = useCallback(
        (answerCardIdParam: string) => {
            if (!!activityDetails.data?.submission?.id) {
                history.push({
                    pathname: `/app/curso/${courseSlug}/atividades/lista-de-exercicios/${activityId}/${activityDetails.data?.submission?.id}/execucao/${activityDetails.data?.reference}/${answerCardIdParam}`
                });
            }
        },
        [activityDetails.data, activityId, courseSlug]
    );

    useEffect(() => {
        if (!!answerCardId?.data) {
            handleRedirectToExerciseListExecution(answerCardId?.data);
        }
    }, [answerCardId, handleRedirectToExerciseListExecution]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearStudentExecution());
        };
    }, [dispatch]);

    const allowedFileTypes = useMemo(() => {
        return activityDetails.data?.allowedFileTypes?.reduce((accumulator: any[], fileType) => {
            return [...accumulator, ...fileType.allowedExtensions];
        }, []);
    }, [activityDetails.data]);

    const handleBackClick = () => {
        history.push({ pathname: `/app/curso/${courseSlug}/atividades` });
    };

    const handleDownloadMaterials = () => {
        activityDetails.data?.supportMaterials?.forEach((material) => {
            openLinkInBlankPage(material.source);
        });
    };

    const handleSubmit = (shouldFinishAnswer?: boolean) => {
        if (type === "discursive" && !compose) {
            trigger();
            return;
        }

        if (type === "send-file" && !activityDetails.data?.submission?.files?.length) {
            dispatch(alertActions.showAlert("O envio dos arquivos é obrigatório para esse tipo de atividade.", "danger"));
            return;
        }

        const payload = {
            activityId,
            submissionId: activityDetails.data?.submission?.id ?? "",
            shouldFinishAnswer: !!shouldFinishAnswer,
            compose,
            comment,
            files: activityDetails.data?.submission?.files?.map((file) => file.id) ?? []
        };

        dispatch(activityActions.createStudentExecutionActivitySubmissionRequest(payload));
    };

    const studentCommentComposition = (
        <Fragment>
            <ActivityExerciseCardTitleWrapper>
                <ActivityExerciseCardTitle>Comentário</ActivityExerciseCardTitle>

                <ActivityExerciseCardSubTitle>Adicione informações extras sobre a resposta enviada. </ActivityExerciseCardSubTitle>
            </ActivityExerciseCardTitleWrapper>

            <Controller
                name="comment"
                control={control}
                render={({ onChange }) => <ActivityEditor value={comment} handleChange={(value) => onChange(!!value ? value : undefined)} />}
            />
        </Fragment>
    );

    const resultsSkeleton = (
        <Fragment>
            <ScreenWidthRender
                renderingWidth={768}
                haveFullyAdaptativeWidth
                actionAfterRenderingWidth="hide"
                content={<Skeleton height="40px" borderRadius="4px" />}
            />

            <ScreenWidthRender
                renderingWidth={768}
                actionAfterRenderingWidth="show"
                content={
                    <ScreenWidthRender
                        renderingWidth={1366}
                        actionAfterRenderingWidth="hide"
                        content={<Skeleton width="320px" height="40px" borderRadius="4px" />}
                    />
                }
            />

            <ScreenWidthRender
                renderingWidth={1366}
                actionAfterRenderingWidth="show"
                content={<Skeleton width="140px" height="40px" borderRadius="4px" />}
            />
        </Fragment>
    );

    const discursiveAnswerComposition = (
        <DefaultActivityCard>
            <ActivityTitle title="Utilize o campo abaixo, para escrever sua resposta" icon={discipline} />

            <Controller
                name="compose"
                control={control}
                render={({ onChange }) => (
                    <ActivityEditor
                        value={compose}
                        maxWords={activityDetails.data?.wordsLimit}
                        errorMessage={formState.errors.compose?.message}
                        handleExceedWordLimit={(hasExceed) => setUserHasExceedWordLimit(hasExceed)}
                        handleChange={(value) => {
                            clearErrors();
                            onChange(!!value ? value : undefined);
                        }}
                    />
                )}
            />

            <ActivityExerciseCardSeparator />

            {studentCommentComposition}
        </DefaultActivityCard>
    );

    const sendFileAnswerComposition = (
        <DefaultActivityCard>
            <ActivityModal
                isOpened={!!activityDetails.data?.submission?.selectedFileModal}
                closeIconElement={
                    <ActionButtonIcon
                        src={close}
                        hasCursorPointer
                        onClick={() => dispatch(activityActions.setStudentExecutionActivitySubmissionFileModal(undefined))}
                    />
                }
                content={<ActivitySubmissionFile source={activityDetails.data?.submission?.selectedFileModal?.source ?? ""} />}
                handleOutsideClick={() => dispatch(activityActions.setStudentExecutionActivitySubmissionFileModal(undefined))}
            />
            <ActivitySendFileAnswerWrapper>
                <ActivityTitle title="Utilize o campo abaixo, para anexar seu arquivo" icon={attachment} />

                <ActivityFileWrapper
                    description={!!allowedFileTypes ? `Formatos permitidos: ${allowedFileTypes?.join(", ")}; com tamanho máximo de 20MB.` : ""}
                    fileInputComposition={
                        <FileInput
                            maximumFilesSize={20}
                            allowedFileTypes={allowedFileTypes}
                            handleError={() =>
                                dispatch(
                                    alertActions.showAlert(
                                        "Erro ao carregar arquivo. O tamanho máximo permitido para os arquivos anexados é de 20MB.",
                                        "danger"
                                    )
                                )
                            }
                            onFileUpload={(files) =>
                                dispatch(
                                    activityActions.createStudentExecutionActivitySubmissionFileRequest({
                                        activityId,
                                        files
                                    })
                                )
                            }
                            content={<ActivityButton label="Procurar arquivo" size="small" type="button" />}
                        />
                    }
                />

                <ConditionallyRender
                    shouldRender={!!activityDetails.data?.submission?.files?.length}
                    content={(activityDetails.data?.submission?.files || []).map((file, index) => {
                        const hasFileCompleteProgress = file.progress === 100 || !!file.source;

                        return (
                            <ActivityFile
                                key={`activity-student-execution-file-${index}`}
                                label={file.name}
                                source={file.source}
                                isReadyToDownload={hasFileCompleteProgress}
                                handleClickLabel={() => window.open(`${!!file.source && file.source}`, "_blank")}
                                progressBarElement={
                                    <ActivityProgressBar
                                        progress={file.progress ?? 100}
                                        progressColor={file.progress === 100 || !file.progress ? "#1BA660" : "#FFBE62"}
                                    />
                                }
                                handleImageClick={() => {
                                    dispatch(activityActions.setStudentExecutionActivitySubmissionFileModal(file));
                                }}
                                actionElement={
                                    <Fragment>
                                        <ConditionallyRender
                                            shouldRender={!isImage(file.source) && hasFileCompleteProgress}
                                            content={
                                                <ActivityDefaultRemoveIcon
                                                    src={trash}
                                                    onClick={() =>
                                                        dispatch(
                                                            activityActions.deleteStudentExecutionActivitySubmissionFileRequest({
                                                                activityId,
                                                                fileId: file.id
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                        />
                                        <ConditionallyRender
                                            shouldRender={!!isImage(file.source) && hasFileCompleteProgress}
                                            content={
                                                <ActivityButton
                                                    type="button"
                                                    iconElement={<ActivityDefaultRemoveIcon src={redTrash} />}
                                                    variant="danger"
                                                    variantType="secondary"
                                                    label="Excluir arquivo"
                                                    onClick={() =>
                                                        dispatch(
                                                            activityActions.deleteStudentExecutionActivitySubmissionFileRequest({
                                                                activityId,
                                                                fileId: file.id
                                                            })
                                                        )
                                                    }
                                                    {...(width < 1024 && {
                                                        isFullyAdaptative: true
                                                    })}
                                                />
                                            }
                                        />
                                        <ConditionallyRender
                                            shouldRender={file.progress !== 100 && !file.source}
                                            content={
                                                <ActivityDefaultActionButton
                                                    color="#e40000"
                                                    onClick={() => dispatch(activityActions.cancelStudentExecutionActivitySubmissionFile(file.name))}
                                                >
                                                    Cancelar
                                                </ActivityDefaultActionButton>
                                            }
                                        />
                                    </Fragment>
                                }
                            />
                        );
                    })}
                />

                <ActivityExerciseCardSeparator />

                {studentCommentComposition}
            </ActivitySendFileAnswerWrapper>
        </DefaultActivityCard>
    );

    const type = ternary([
        [activityType === "discursiva", "discursive"],
        [activityType === "lista-de-exercicios", "exercise-list"],
        [activityType === "envio-de-arquivo", "send-file"],
        [true, "exercise-list"]
    ]) as ActivityType;

    const answerComposition = ternary([
        [type === "discursive", discursiveAnswerComposition],
        [type === "send-file", sendFileAnswerComposition]
    ]);

    const actionButtonLabel = ternary([
        [type === "exercise-list" && !activityDetails.data?.submission, "Iniciar lista"],
        [type === "exercise-list" && !!activityDetails.data?.submission, "Continuar lista"],
        [type !== "exercise-list", "Salvar rascunho"]
    ]);

    return (
        <Fragment>
            <ConditionallyRender shouldRender={isRequestingFile} content={<ActivityOverlay content={<Loader />} />} />

            <ActivityExerciseLayout
                activityType={type}
                breadcrumbsComposition={
                    <Fragment>
                        <ScreenWidthRender
                            actionAfterRenderingWidth="hide"
                            renderingWidth={1024}
                            content={
                                <ActivityBreadcrumbs
                                    breadcrumbItemCompositions={[
                                        <ActivityBreadcrumbItem
                                            key={1}
                                            label="Voltar"
                                            icon={arrowLeft}
                                            size="small"
                                            isActive={false}
                                            handleClick={handleBackClick}
                                        />
                                    ]}
                                />
                            }
                        />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={1024}
                            content={
                                <ActivityBreadcrumbs
                                    breadcrumbItemCompositions={[
                                        <ActivityBreadcrumbItem
                                            key={1}
                                            label="Atividades"
                                            size="small"
                                            isActive={false}
                                            handleClick={handleBackClick}
                                        />,
                                        !activityDetails.isLoading ? (
                                            <ActivityBreadcrumbItem key={2} label={activityDetails.data?.title!} size="large" isActive={true} />
                                        ) : (
                                            <Skeleton height="28px" width="280px" borderRadius="4px" />
                                        )
                                    ]}
                                />
                            }
                        />
                    </Fragment>
                }
                statementComposition={
                    <DefaultActivityCard>
                        <ScreenWidthRender
                            actionAfterRenderingWidth="hide"
                            renderingWidth={1024}
                            content={
                                <Fragment>
                                    <ConditionallyRender
                                        shouldRender={!!activityDetails.isLoading}
                                        content={<Skeleton height="26px" width="280px" borderRadius="4px" />}
                                    />

                                    <ConditionallyRender
                                        shouldRender={!activityDetails.isLoading}
                                        content={<ActivityMobileTitle>{activityDetails.data?.title}</ActivityMobileTitle>}
                                    />
                                </Fragment>
                            }
                        />

                        <ConditionallyRender
                            shouldRender={!!activityDetails.isLoading}
                            content={
                                <SkeletonWrapper>
                                    <Skeleton height="26px" borderRadius="4px" />

                                    <Spacing direction="vertical" size={2} />

                                    <Skeleton height="26px" borderRadius="4px" />

                                    <Spacing direction="vertical" size={2} />

                                    <Skeleton height="26px" borderRadius="4px" />

                                    <Spacing direction="vertical" size={2} />

                                    <Skeleton height="26px" width="264px" borderRadius="4px" />

                                    <br />
                                    <br />

                                    <Skeleton height="26px" borderRadius="4px" />

                                    <Spacing direction="vertical" size={2} />

                                    <Skeleton height="26px" borderRadius="4px" />

                                    <Spacing direction="vertical" size={2} />

                                    <Skeleton height="26px" width="264px" borderRadius="4px" />
                                </SkeletonWrapper>
                            }
                        />

                        <ConditionallyRender
                            shouldRender={!activityDetails.isLoading}
                            content={
                                <Label dangerouslySetInnerHTML={{ __html: activityDetails.data?.enunciation?.split("\n").join("<br/>") ?? "" }} />
                            }
                        />
                    </DefaultActivityCard>
                }
                {...((!!activityDetails.isLoading || !!activityDetails.data?.score) && {
                    scoreComposition: (
                        <ScoreActivityCardWrapper>
                            <ActivityScoreWrapper>
                                <ActivityScoreLabelWrapper>
                                    <ActivityScoreLabel>Pontuação máxima:</ActivityScoreLabel>

                                    <ConditionallyRender shouldRender={!!activityDetails.isLoading} content={resultsSkeleton} />

                                    <ConditionallyRender
                                        shouldRender={!activityDetails.isLoading}
                                        content={
                                            <ActivityScoreBadge
                                                pointsLabel={!!activityDetails.data?.score ? `${activityDetails.data?.score} pontos` : "-"}
                                            />
                                        }
                                    />
                                </ActivityScoreLabelWrapper>

                                <ActivityScoreLabelWrapper>
                                    <ActivityScoreLabel>Minha pontuação:</ActivityScoreLabel>

                                    <ConditionallyRender shouldRender={!!activityDetails.isLoading} content={resultsSkeleton} />

                                    <ConditionallyRender shouldRender={!activityDetails.isLoading} content={<ActivityScore />} />
                                </ActivityScoreLabelWrapper>
                            </ActivityScoreWrapper>

                            <ConditionallyRender shouldRender={!!activityDetails.isLoading} content={<Skeleton height="40px" borderRadius="4px" />} />

                            <ConditionallyRender
                                shouldRender={!activityDetails.isLoading && !!activityDetails.data?.supportMaterials?.length}
                                content={
                                    <ActivityButton
                                        type="button"
                                        isFullyAdaptative
                                        size="large"
                                        label="Baixar material de apoio"
                                        variantType="secondary"
                                        onClick={handleDownloadMaterials}
                                    />
                                }
                            />
                        </ScoreActivityCardWrapper>
                    )
                })}
                footerButtonsCompositions={
                    <Fragment>
                        <ActivityButton
                            type="button"
                            label={actionButtonLabel ?? ""}
                            size="large"
                            isDisabled={userHasExceedWordLimit}
                            variantType={type !== "exercise-list" ? "secondary" : "primary"}
                            isLoading={type !== "exercise-list" ? isCreating : !!answerCardId?.isLoading}
                            onClick={() => {
                                if (type === "exercise-list" && !activityDetails.data?.submission) {
                                    dispatch(activityActions.getStudentExecutionAnswerCardIdRequest(activityId));

                                    return;
                                }

                                if (type === "exercise-list" && !!activityDetails.data?.submission) {
                                    handleRedirectToExerciseListExecution(activityDetails.data.submission.reference ?? "");

                                    return;
                                }

                                handleSubmit();
                            }}
                            {...(width < 1024 && {
                                isFullyAdaptative: true
                            })}
                        />

                        <ConditionallyRender
                            shouldRender={type !== "exercise-list"}
                            content={
                                <ActivityButton
                                    type="button"
                                    label="Finalizar atividade"
                                    size="large"
                                    variantType="primary"
                                    isLoading={isCreating}
                                    onClick={() => handleSubmit(true)}
                                    isDisabled={userHasExceedWordLimit}
                                    {...(width < 1024 && {
                                        isFullyAdaptative: true
                                    })}
                                />
                            }
                        />
                    </Fragment>
                }
                shouldDisplayActivityCommentSection={false}
                answersComposition={answerComposition}
                // {...(type === "exercise-list" && {
                //     progressComposition: (
                //         <ActivityProgressWrapper>
                //             <ActivityProgressLabelWrapper>
                //                 <ConditionallyRender
                //                     shouldRender={!!activityDetails.isLoading}
                //                     content={<Skeleton width="60px" height="16px" borderRadius="4px" />}
                //                 />

                //                 <ConditionallyRender
                //                     shouldRender={!activityDetails.isLoading}
                //                     content={<ActivityProgressLabel>4 questões</ActivityProgressLabel>}
                //                 />

                //                 <ConditionallyRender
                //                     shouldRender={!!activityDetails.isLoading}
                //                     content={<Skeleton width="96px" height="16px" borderRadius="4px" />}
                //                 />

                //                 <ConditionallyRender
                //                     shouldRender={!activityDetails.isLoading}
                //                     content={<ActivityProgressLabel>25% respondida</ActivityProgressLabel>}
                //                 />
                //             </ActivityProgressLabelWrapper>

                //             <ConditionallyRender shouldRender={!!activityDetails.isLoading} content={<Skeleton height="8px" borderRadius="8px" />} />

                //             <ConditionallyRender
                //                 shouldRender={!activityDetails.isLoading}
                //                 content={<ActivityProgressBar progressColor={theme.colors.brand[300]} progress={25} />}
                //             />
                //         </ActivityProgressWrapper>
                //     )
                // })}
            />
        </Fragment>
    );
};
