import * as React from "react";

import { SVG } from "./styles";

interface IProps {
    idIcon: string;
    height: string;
    width: string;
    viewBox?: string;
    style?: {};
    children?: string;
}

const Icon = ({ idIcon, width, height, viewBox, style = {} }: IProps) => (
    <SVG style={style} className={idIcon} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <use xmlnsXlink="http://www.w3.org/1999/xlink" className={idIcon} xlinkHref={`#${idIcon}`} />
    </SVG>
);

export default Icon;
