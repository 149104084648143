import React, { useMemo, useCallback, useState } from "react";

// assets
import * as S from "./styles";
import GabaritoIcon from "prodigio-icons/web/filled/Gabarito";
import EyeIcon from "prodigio-icons/web/filled/SeePassword";
import { BreakPoints } from "assets/styles/settings";

// components
import Accordion from "components/Accordion";
import Button from "components/Button";
import Badge from "components/Badge";

// helpers
import { IExerciseListRealization } from "interfaces/IExerciseList";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import useIsMobile from "hooks/use-is-mobile";
import history from "services/history";
import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";

import { useDispatch, useSelector } from "react-redux";
import api from "services/api";
import { Creators as alertActions } from "store/ducks/alert";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    name: string;
    totalQuestions: number;
    realizations: IExerciseListRealization[];
    onClickStartList(data: { hasRealizations: boolean; exerciseListId: number }): void;
    exerciseListId: number;
    previousExamId: number;
    previousExamDayId: number;
    knowledgeAreas: IExamKnowledgeAreas[];
    namePDF: string;
    examDaysStatus: number;
}

const PreviousExamDetailsExamDay = ({
    name,
    totalQuestions,
    realizations,
    onClickStartList,
    exerciseListId,
    previousExamId,
    previousExamDayId,
    knowledgeAreas,
    namePDF
}: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isMobile = useIsMobile(BreakPoints.small);
    const dispatch = useDispatch();

    const [disableButtonPDF, setDisableButtonPDF] = useState(false);

    const totalDonesRealizations = useMemo(() => realizations?.filter((realization) => realization.status >= AnswerCardStatus.Done).length || 0, [
        realizations
    ]);

    const totalUnFishedRealizations = useMemo(() => realizations?.filter((realization) => realization.status < AnswerCardStatus.Done).length || 0, [
        realizations
    ]);

    const handleClickStartList = useCallback(() => {
        try {
            // CONTINUAR LISTA
            if (!!totalUnFishedRealizations) {
                const [unFishedAnswerCard] = realizations?.filter((realization) => realization.status < AnswerCardStatus.Done).slice(-1);

                if (!unFishedAnswerCard) {
                    throw new Error();
                }

                const realizationMode: { [key: number]: string } = {
                    [AnswerCardRealizationMode.Study]: "estudo",
                    [AnswerCardRealizationMode.Exam]: "prova",
                    [AnswerCardRealizationMode.Proof]: "prova"
                };

                if (!realizationMode[unFishedAnswerCard.realizationMode]) {
                    throw new Error();
                }

                return history.push({
                    pathname: `/app/curso/${courseSlug}/provas-anteriores/${previousExamId}/dia/${previousExamDayId}/${
                        realizationMode[unFishedAnswerCard.realizationMode]
                    }/${unFishedAnswerCard.id}`
                });
            }

            return onClickStartList({ hasRealizations: !!realizations?.length, exerciseListId });
        } catch (error) {
            console.log(error);
        }
    }, [courseSlug, exerciseListId, onClickStartList, previousExamDayId, previousExamId, realizations, totalUnFishedRealizations]);

    const handleClickdownloadPDF = useCallback(async () => {
        try {
            setDisableButtonPDF(true);
            await api

                .get(`/student/exerciselist/${exerciseListId}/report`, {
                    responseType: "blob"
                })

                .then((blob) => {
                    new File([blob.data], "file");

                    const url = window.URL.createObjectURL(blob.data);

                    const a = document.createElement("a");

                    a.href = url;

                    a.download = `${namePDF}.pdf`;

                    document.body.appendChild(a);

                    a.click();

                    a.remove();
                });

            setDisableButtonPDF(false);
        } catch (error) {
            console.log(error);

            dispatch(alertActions.showAlert("Ocorreu um erro ao imprimir a lista", "danger"));

            setDisableButtonPDF(false);
        }
    }, [dispatch, exerciseListId, namePDF]);

    const memoOptions = useMemo(() => {
        try {
            return (
                <S.PreviousExamDetailsExamDayOptionWrapp>
                    <S.PreviousExamDetailsExamDayOptionButton
                        onClick={() =>
                            history.push({
                                pathname: `/app/curso/${courseSlug}/provas-anteriores/${previousExamId}/dia/${previousExamDayId}/gabarito`
                            })
                        }
                    >
                        <EyeIcon width={18} /> Ver o gabarito
                    </S.PreviousExamDetailsExamDayOptionButton>

                    {totalDonesRealizations > 0 && (
                        <S.PreviousExamDetailsExamDayOptionButton
                            data-test-id="previous-exam-day-results-button"
                            onClick={() =>
                                history.push({
                                    pathname: `/app/curso/${courseSlug}/provas-anteriores/${previousExamId}/dia/${previousExamDayId}`
                                })
                            }
                        >
                            <GabaritoIcon width={16} /> Ver resultados
                        </S.PreviousExamDetailsExamDayOptionButton>
                    )}

                    {/* {totalDonesRealizations === 1 && (
                        <S.PreviousExamDetailsExamDayOptionButton onClick={() => onClickStartList({ hasRealizations: true, exerciseListId })}>
                            <GoBackIcon width={18} /> Recomeçar
                        </S.PreviousExamDetailsExamDayOptionButton>
                    )} */}
                </S.PreviousExamDetailsExamDayOptionWrapp>
            );
        } catch (error) {
            console.log(error);

            return null;
        }
    }, [courseSlug, previousExamDayId, previousExamId, totalDonesRealizations]);

    const listknowledgeAreas = useMemo(
        () => (
            <S.PreviousExamDetailsExamDayKnowledgeAreaContainer data-test-id="previous-exam-day-knowledgeAreas">
                {knowledgeAreas.map((knowledgeArea) => (
                    <S.PreviousExamDetailsExamDayKnowledgeArea key={knowledgeArea.id} data-test-id="previous-exam-day-knowledgeArea-item">
                        &bull; {knowledgeArea.name}
                    </S.PreviousExamDetailsExamDayKnowledgeArea>
                ))}
            </S.PreviousExamDetailsExamDayKnowledgeAreaContainer>
        ),
        [knowledgeAreas]
    );

    const badgeMemo = useMemo(() => {
        if (realizations.length <= 0) {
            return null;
        }

        if (totalUnFishedRealizations === 0) {
            return (
                <Badge variant="success" backgroundWeight={100}>
                    Feita
                </Badge>
            );
        }

        if (totalUnFishedRealizations >= 1) {
            return (
                <Badge variant="info" backgroundWeight={100}>
                    Iniciada
                </Badge>
            );
        }

        return null;
    }, [realizations.length, totalUnFishedRealizations]);

    return (
        <S.PreviousExamDetailsExamDayContainer data-test-day-id={previousExamDayId}>
            <S.PreviousExamDetailsExamDayHeader>
                <S.PreviosExamTexts>
                    <S.PreviousExamDetailsExamDayHeaderTitle data-test-id="previous-exam-day-name">{name}</S.PreviousExamDetailsExamDayHeaderTitle>
                    <S.PreviousExamDetailsExamDayHeaderSubTitle data-test-id="previous-exam-day-totalQuestions">
                        {totalQuestions} questões
                    </S.PreviousExamDetailsExamDayHeaderSubTitle>
                </S.PreviosExamTexts>

                {badgeMemo}
            </S.PreviousExamDetailsExamDayHeader>

            {listknowledgeAreas}

            <S.PreviousExamDetailsExamDayOptionsContainer>
                {isMobile && (
                    <Accordion
                        options={[
                            {
                                label: {
                                    text: "Opções"
                                },
                                content: memoOptions
                            }
                        ]}
                    />
                )}
                {!isMobile && memoOptions}
            </S.PreviousExamDetailsExamDayOptionsContainer>
            <S.ContentButtons>
                <Button data-test-id="exam-day-start-button" variant="primary" block size="medium" onClick={handleClickStartList}>
                    {!!realizations?.length ? (!!totalUnFishedRealizations ? "Continuar prova" : "Refazer prova") : "Fazer prova"}
                </Button>
                <Button
                    data-test-id="exam-day-start-button"
                    variant="outline-primary"
                    block
                    size="medium"
                    disabled={disableButtonPDF}
                    onClick={handleClickdownloadPDF}
                >
                    {disableButtonPDF ? "Exportando..." : "Imprimir"}
                </Button>
            </S.ContentButtons>
        </S.PreviousExamDetailsExamDayContainer>
    );
};

export default PreviousExamDetailsExamDay;
