import React, { forwardRef, useMemo } from "react";

import * as S from "./styles";
import ProgressBar from "components/ProgressBar";
import { theme } from "config/theme";
import { IProgress } from "interfaces/IProgress";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";
import { IBookletModuleItem } from "store/interfaces/IBookletModule";
import { ISubjectFront } from "store/interfaces/ISubject";

export interface IProps {
    name: string;
    progress: IProgress;
    onClick(): void;
    id: number;
    module?: IBookletModuleItem;
    subjectName?: string;
    subjectFront?: ISubjectFront;
}

const SubjectScreenDetailsModule = forwardRef(({ name, progress, onClick, id, module, subjectName, subjectFront }: IProps, ref: any) => {
    const isActive = useMemo(() => !!module?.id, [module]);
    const isFinished = useMemo(() => !!progress && progress.percent >= 100, [progress]);
    const isStarted = useMemo(() => !!progress && progress.percent >= 1, [progress]);
    const variant = useMemo(() => (isStarted ? (isFinished ? "success" : "info") : "base"), [isFinished, isStarted]);
    const progressCalculated = useMemo(() => progress?.percent || 0, [progress]);

    const subjectLabel = subjectFront?.title ?? subjectName;

    return (
        <S.SubjectScreenDetailsModuleContainer onClick={onClick} ref={ref} data-id={id} active={isActive} isStarted={isStarted}>
            <S.SubjectScreenDetailsModuleBubble variant={variant} />
            <S.SubjectScreenDetailsModuleCard>
                <S.SubjectScreenDetailsModuleCardTitle>
                    <div>
                        {!!subjectLabel && <span>{subjectLabel}</span>}
                        <strong>{name}</strong>
                    </div>
                    {!isActive && <ChevronRight height={7} />}
                </S.SubjectScreenDetailsModuleCardTitle>

                <S.SubjectScreenDetailsModuleCardProgress>
                    <ProgressBar
                        color={variant === "base" ? theme.colors.base[200] : theme.colors.system[variant][200]}
                        value={progressCalculated}
                        responsive
                    />
                    <S.SubjectScreenDetailsModuleCardProgressLabel>{progressCalculated}% concluído</S.SubjectScreenDetailsModuleCardProgressLabel>
                </S.SubjectScreenDetailsModuleCardProgress>
            </S.SubjectScreenDetailsModuleCard>
        </S.SubjectScreenDetailsModuleContainer>
    );
});

export default SubjectScreenDetailsModule;
