import styled from "styled-components";
import { theme } from "config/theme";
import { PageContainer } from "assets/styles/global";
import { ExamScreenItemContainer } from "./components/ExamItem/styles";
import { CardContainer } from "components/Card/styles";
import { fadeInUp, fadein } from "assets/styles/animations";

import { ErrorUIContainer } from "components/ErrorUI/styles";
import { ButtonStyles } from "components/Button/styles";

export const ExamScreenContainer = styled(PageContainer)`
    padding: 0 ${theme.spacing.smallX};

    ${ErrorUIContainer} {
        animation: ${fadein} 0.5s ease;
        ${CardContainer} {
            box-shadow: none;
        }
    }
`;

export const ExamScreenContent = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${fadeInUp} 0.25s linear;
    margin-bottom: 40px;
    background: ${theme.colors.white};
    border-radius: 8px;
    box-shadow: ${theme.shadow[1]};
    padding: ${theme.spacing.small};

    ${ExamScreenItemContainer} {
        :not(:last-child) {
            margin-bottom: ${theme.spacing.medium};
        }
    }
`;

export const ExamScreenVersionButton = styled.button.attrs({ variant: "secondary", size: "small", type: "button" })`
    ${ButtonStyles};

    display: flex;
    align-items: center;

    > svg {
        margin-left: 10px;
    }
`;
