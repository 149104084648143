// Dependencies
import React, { useRef, useState, useEffect } from "react";
import Slider from "rc-slider";

// Assets
import play from "assets/img/play.svg";
import pause from "assets/img/pause.svg";

// Utils
import { theme } from "config/theme";

// Styles
import { Container } from "./styles";

interface IAudioPlayerProps {
    src: string;
}

const AudioPlayer = ({ src }: IAudioPlayerProps) => {
    const audioRef = useRef<HTMLAudioElement>(null);

    const [progress, setProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);

    const sliderStyles = {
        marginLeft: "16px",
        display: "flex",
        alignItems: "center"
    };

    const setupProgressListener = () => {
        if (!audioRef.current) return;

        audioRef.current.addEventListener("timeupdate", () => {
            const currentTime = audioRef.current?.currentTime;
            const duration = audioRef.current?.duration;

            if (currentTime && duration) {
                setProgress(currentTime === duration ? 0 : Math.ceil(currentTime));
            }
        });
    };

    const handleSliderChange = (time: number) => {
        if (!audioRef.current) return;

        audioRef.current.currentTime = time;
        setProgress(time);
    };

    useEffect(() => {
        if (!audioRef.current) return;

        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        if (!audioRef.current) return;

        audioRef.current.currentTime = 10000000;

        setTimeout(() => {
            if (!audioRef.current?.currentTime) return;

            audioRef.current.currentTime = 0;
        }, 1000);
    }, []);

    return (
        <>
            <Container>
                <button type="button" onClick={() => setIsPlaying(!isPlaying)}>
                    <img src={isPlaying ? pause : play} alt={isPlaying ? "Pausar" : "Tocar"} />
                </button>

                <Slider
                    {...(!!audioRef.current?.duration && { max: audioRef.current?.duration })}
                    value={progress}
                    trackStyle={{ backgroundColor: theme.colors.brand[300], height: "6px" }}
                    railStyle={{ backgroundColor: theme.colors.base[100] }}
                    handleStyle={{ borderColor: theme.colors.brand[300], backgroundColor: theme.colors.brand[300], borderWidth: 4, opacity: 1 }}
                    onChange={handleSliderChange as any}
                    style={sliderStyles}
                />
            </Container>

            <audio
                src={src}
                ref={audioRef}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onLoadedMetadata={setupProgressListener}
                onEnded={() => setProgress(0)}
            />
        </>
    );
};

export default AudioPlayer;
