import { store } from "store";
import { theme } from "config/theme";
import HttpStatus from "enums/HttpStatus";

const showPaywall = theme.project.slug === "proenem" || theme.project.slug === "promilitares";

const apiErrorHandling = async (error: any) => {
    try {
        if (!error.response) {
            throw new Error(error);
        }

        const { status } = error.response;

        if (status === HttpStatus.UNAUTHORIZED) {
            store.dispatch({ type: "LOGOUT" });
        }

        if (status === HttpStatus.PAYMENT_REQUIRED && showPaywall) {
            store.dispatch({ type: "OPEN_MODAL", element: "paywall" });
        }

        throw new Error(error);
    } catch (ex) {
        return Promise.reject(error);
    }
};

export default apiErrorHandling;
