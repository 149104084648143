// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, LabelWrapper, Label, ActionIconElementWrapper, StatusChipCompositionWrapper } from "./activity-student-item.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Skeleton } from "component-library/utilities/skeleton";
import { Spacing } from "component-library/utilities/spacing";

// Types
import { ActivityStudentItemSkeletonProps } from "./activity-student-item.types";

export const ActivityStudentItemSkeleton: FunctionComponent<ActivityStudentItemSkeletonProps> = ({ actionIconElement }) => {
    return (
        <Container className="webapp-activity-item" hasAction>
            <span className="title">
                <Skeleton height="22px" width="220px" borderRadius="4px" />
            </span>

            <LabelWrapper className="deadline">
                <ScreenWidthRender renderingWidth={1024} actionAfterRenderingWidth="hide" content={<Label color="#4d4d63">Prazo:</Label>} />

                <Skeleton height="20px" width="80px" borderRadius="4px" />
            </LabelWrapper>

            <span className="type">
                <Skeleton height="20px" width="120px" borderRadius="4px" />

                <ScreenWidthRender
                    renderingWidth={1024}
                    actionAfterRenderingWidth="show"
                    content={
                        <ScreenWidthRender
                            renderingWidth={1920}
                            actionAfterRenderingWidth="hide"
                            content={<Spacing direction="vertical" size={2} />}
                        />
                    }
                />
            </span>

            <LabelWrapper className="score">
                <ScreenWidthRender renderingWidth={1920} actionAfterRenderingWidth="hide" content={<Label color="#000000">Pontuação:</Label>} />

                <Skeleton height="20px" width="24px" borderRadius="4px" />
            </LabelWrapper>

            <StatusChipCompositionWrapper className="status-chip">
                <Skeleton height="24px" width="132px" borderRadius="200px" />
            </StatusChipCompositionWrapper>

            <ActionIconElementWrapper className="action-icon">{actionIconElement}</ActionIconElementWrapper>
        </Container>
    );
};
