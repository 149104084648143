import React, { useCallback, useMemo, useEffect, useRef } from "react";

// Assets
import { ExerciseListContainer, ExerciseListContent, ExerciseListAnimations } from "./styles";

// Components
import ExerciseListHeader from "./components/Header";
import ExerciseListFooter from "./components/Footer";
import ExerciseListQuestions from "./components/ListQuestions";
import ExerciseListAnswerCardModal from "components/AnswerCardModal";
import ExerciseListResult from "./components/Result";
import { Grid } from "components/Grid";
import ExerciseListSplash from "./components/Splash";
import PageLoader from "components/PageLoader";

// Helpers
import { IAnswerCard, IAnswerCardItem } from "store/ducks/answerCard";
import { IAnswerCardActions } from "store/interfaces/IActions";
import { KeyboardKeys } from "enums/Keys";
import useKeyboardKeyDown, { IKeyDownEvent } from "hooks/use-key-down";
import ExerciseListResolutionMode from "./enums/ExerciseListResolutionMode";
import { RouteComponentProps } from "react-router-dom";
import { IExerciseListState } from "interfaces/IExerciseList";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import getQuestionMarkedAlternativeId from "./utils/getQuestionMarkedAlternative";
import { IModalActions } from "store/interfaces/IModal";
import history from "services/history";
import IExerciseListScreenresolutions from "./interfaces/IExerciseListScreenresolutions";
import { ExerciseListType } from "enums/ExerciseList";
import _last from "lodash/last";

import { IQuestion } from "interfaces/IQuestion";
import getNumberByLetter from "utils/getNumberByLetter";
import QuestionType from "./enums/QuestionType";

import { DateTime } from "luxon";
import { IAlertActions } from "store/interfaces/IAlert";
import { useBlockCopy } from "hooks/use-block-copy";
import { ExamType } from "enums/ExamType";
import { getExerciseListDashboardRoute } from "helpers/getExerciseListDashboardRoute";
import { IPayloadRequest } from "interfaces/IRequestAction";
import { IExerciseListActions, ISaveExerciseListEssayPayload, ISaveExerciseListEssayPayloadImportant } from "store/ducks/exerciseList/types";
import ExerciseListEssay from "./components/Essay";

import EssayDialogue from "./components/EssayDialogue";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as answerCardActions } from "store/ducks/answerCard";
import { Creators as exerciseListActions } from "store/ducks/exerciseList";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as modalActions } from "store/ducks/modal";
import { IReduxStore } from "interfaces/IReduxStore";

interface IRouteParams {
    id: string;
    answerCardId?: string;
    subjectSlug?: string;
    examDayId?: string;
    examId?: string;
    projectId?: string;
    activityId?: string;
    activitySubmissionId?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
    exerciseList: IExerciseListState;
    answerCard: IAnswerCard;
    exerciseListActions: IExerciseListActions;
    answerCardActions: IAnswerCardActions;
    modalActions: IModalActions;
    resolutionMode: ExerciseListResolutionMode;
    isLoading: boolean;
    error: boolean;
    alertActions: IAlertActions;
    examType?: ExamType;
    subject?: {
        id: number;
        name: string;
        slug: string;
        color: string;
    };
    isLoadingEssay: boolean;
    hasSentEssay: boolean;
    isLoadingEssayCorrection: boolean;
}

/**
 *  returns the exercise list flux
 */

const ExerciseListScreen = ({
    exerciseList,
    answerCard,
    exerciseListActions,
    answerCardActions,
    modalActions,
    resolutionMode,
    match,
    isLoading,
    error,
    alertActions,
    examType,
    subject,
    isLoadingEssay,
    hasSentEssay,
    isLoadingEssayCorrection
}: IProps) => {
    const refHeader = useRef<HTMLDivElement>(null);

    // Block Copy
    useBlockCopy();

    const { id, answerCardId, subjectSlug, examDayId, examId, activityId, activitySubmissionId } = match.params;

    const ignoreUnMountEssay = useRef(false);
    const tmpEssayData = useRef<ISaveExerciseListEssayPayloadImportant>();

    const {
        items = [],
        title,
        type = ExerciseListType.Subject,
        durationInMinutes = null,
        knowledgeAreas = [],
        isAnswerReleased,
        essayTheme,
        currentQuestion,
        showEssay = false,
        showAnswerCard = false,
        showResult = false
    } = exerciseList;
    const { answers, startDate, endDate, points = 0, examResolution, composition } = answerCard;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    useEffect(() => {
        tmpEssayData.current = {
            idTheme: essayTheme?.idTheme,
            idComposition: examResolution?.idComposition || composition?.idComposition,
            idResolution: examResolution?.id,
            idAnswerCard: !!answerCardId ? +answerCardId : undefined
        };
    }, [answerCardId, composition, essayTheme, examResolution]);

    const isMobile = useIsMobile(BreakPoints.small);
    const isExtra = useMemo(() => type === ExerciseListType.Extra, [type]);
    const isFinished = useMemo(() => answerCard.status >= AnswerCardStatus.Done, [answerCard.status]);
    const isAwaitingCorrection = useMemo(() => isExtra && answerCard.status === AnswerCardStatus.Done, [answerCard.status, isExtra]);
    const showNotes = useMemo(() => answerCard.status === AnswerCardStatus.Corrected && resolutionMode === ExerciseListResolutionMode.Exam, [
        resolutionMode,
        answerCard.status
    ]);
    const isExam = useMemo(() => !!examDayId && !!examId && !!examType, [examDayId, examId, examType]);

    const isSimulatedExam = useMemo(() => examType === ExamType.Simulated && !!isExam, [examType, isExam]);
    const isCountDown = useMemo(() => {
        if (isExtra) {
            return !!durationInMinutes && !isFinished;
        }

        return isSimulatedExam && !!durationInMinutes && !isFinished;
    }, [durationInMinutes, isExtra, isFinished, isSimulatedExam]);
    const isFeedBack = useMemo(() => resolutionMode === ExerciseListResolutionMode.Feedback, [resolutionMode]);

    const showEssayCorrection = useMemo(() => isFinished || showNotes, [isFinished, showNotes]);

    const hasEssay = useMemo(() => !!essayTheme?.idTheme, [essayTheme]);
    const showEssayDialogue = hasEssay && !hasSentEssay && !isFinished;

    const totalQuestions = useMemo(() => {
        const total = exerciseList?.questionsTotal || 0;

        return hasEssay ? total + 1 : total;
    }, [exerciseList, hasEssay]);

    const showAnswers = useMemo(() => {
        if (isFeedBack) {
            return true;
        }

        if (isExtra) {
            return showNotes;
        }

        if (isSimulatedExam) {
            return !!isFinished && !!isAnswerReleased;
        }

        if (resolutionMode === ExerciseListResolutionMode.Exam) {
            return isFinished;
        }

        return resolutionMode === ExerciseListResolutionMode.Study;
    }, [isFeedBack, isExtra, isSimulatedExam, resolutionMode, showNotes, isFinished, isAnswerReleased]);

    const questionsResolutions: IExerciseListScreenresolutions[] = useMemo(() => {
        try {
            if (!answerCard.exerciseList?.items) {
                return [];
            }

            const resolutions = answerCard.exerciseList.items.map((item) => ({
                questionId: item.question.id,
                resolutions: item.question.resolutions || []
            }));

            return resolutions;
        } catch (error) {
            console.log(error);

            return [];
        }
    }, [answerCard.exerciseList]);

    const blockQuestionToAnswer = useMemo(() => {
        return !isExtra && currentQuestion?.questionType === QuestionType.Discursive;
    }, [currentQuestion, isExtra]);

    useEffect(() => {
        return () => {
            exerciseListActions.clearExerciseList();
        };
    }, [exerciseListActions]);

    // ###############
    // VALIDATIONS
    // ###############

    const handleClickGoToQuestion = useCallback(
        (questionNumber: number) => {
            exerciseListActions.goToQuestionNumber({ questionNumber });
        },
        [exerciseListActions]
    );

    const validateResolutionMode = useCallback(() => {
        try {
            if (!!examDayId) {
                return;
            }

            if (resolutionMode === undefined || type === undefined) {
                return;
            }

            // Se estiver no modo estudo e for lista extra
            if (resolutionMode === ExerciseListResolutionMode.Study && type === ExerciseListType.Extra) {
                // Redireciona o usuário pra rota correta
                return history.push({ pathname: `/app/curso/${courseSlug}/lista-de-exercicios/${id}/prova/${answerCardId}` });
            }
        } catch (error) {
            console.log(error);
        }
    }, [examDayId, resolutionMode, type, courseSlug, id, answerCardId]);

    useEffect(() => validateResolutionMode(), [validateResolutionMode]);

    // scrolla pro topo da pagina toda vez q troca de questao
    const scrollToTop = useCallback(() => {
        if (!!refHeader && !!refHeader.current && !!currentQuestion) {
            refHeader.current.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        }
    }, [currentQuestion]);

    useEffect(() => scrollToTop(), [scrollToTop]);

    // ###############
    // REQUEST ACTIONS
    // ###############

    const handleSendEssay = useCallback(
        (data: { files?: File[]; content?: string }) => {
            if (!!ignoreUnMountEssay?.current || (!data?.content && !data?.files)) {
                return;
            }

            // get first file
            const [file] = data.files || [];

            const payload: ISaveExerciseListEssayPayload = {
                file,
                content: data?.content || "",
                canvasElement: document.getElementById("print")?.cloneNode(true),
                ...tmpEssayData?.current
            };

            // if (!!file) {
            //     setFiles([]);
            // }

            exerciseListActions.saveExerciseListEssayRequest(payload);
        },
        [exerciseListActions]
    );

    const requestSubject = useCallback(async () => {
        if (!subjectSlug) {
            return;
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/subject/${subjectSlug}`
        };

        exerciseListActions.getExerciseListSubjectRequest(payload);
    }, [exerciseListActions, subjectSlug]);

    useEffect(() => {
        requestSubject();
    }, [requestSubject]);

    const requestExerciseList = useCallback(() => {
        if (!id || exerciseList.id === +id) {
            return;
        }

        exerciseListActions.getExerciseListRequest(id);
    }, [id, exerciseList.id, exerciseListActions]);

    useEffect(() => requestExerciseList(), [requestExerciseList]);

    const requestExamDay = useCallback(() => {
        if (!examDayId) {
            return;
        }

        exerciseListActions.getExerciseListByExamDayRequest({ examDayId });
    }, [exerciseListActions, examDayId]);

    useEffect(() => requestExamDay(), [requestExamDay]);

    const requestFeedBackCard = useCallback(() => {
        if (isFeedBack && !!exerciseList.id && !answerCard.id) {
            answerCardActions.getAnswerCardRequest({ id: exerciseList.id, isFeedBack: true });
        }
    }, [answerCard.id, answerCardActions, exerciseList.id, isFeedBack]);

    useEffect(() => {
        requestFeedBackCard();
    }, [requestFeedBackCard]);

    const requestAnswerCard = useCallback(() => {
        if (!!answerCardId) {
            answerCardActions.getAnswerCardRequest({ id: answerCardId });
        }

        return () => {
            answerCardActions.clearAnswerCard();
        };
    }, [answerCardId, answerCardActions]);

    useEffect(() => requestAnswerCard(), [requestAnswerCard]);

    // ###############
    // HANDLE ACTIONS
    // ###############

    const handleFinishTimeOut = useCallback(() => {
        if (isFinished) {
            return;
        }

        answerCardActions.patchAnswerCardRequest({
            answerCardId,
            status: AnswerCardStatus.Done,
            endDate: DateTime.fromISO(startDate).plus({ minutes: durationInMinutes || 1 })
        });

        const redirectpath = getExerciseListDashboardRoute(courseSlug);

        if (isSimulatedExam && !!redirectpath) {
            history.push({ pathname: redirectpath });
        }

        return modalActions.openModal("confirmAction", {
            title: "Tempo esgotado!",
            text: "O tempo de resolução esgotou e a lista foi finalizada automaticamente.",
            onConfirm: () => modalActions.closeModal(),
            variant: "info",
            buttonText: "Ok"
        });
    }, [answerCardActions, answerCardId, courseSlug, durationInMinutes, isFinished, isSimulatedExam, modalActions, startDate]);

    const handleClickFinishList = useCallback(() => {
        const savedText = localStorage.getItem(`answerCardComposition:${answerCardId}`);

        const hasUnsavedEssay = showEssayDialogue && !!savedText;

        const showEssayMessage = hasUnsavedEssay || showEssayDialogue;

        modalActions.openModal("confirmAction", {
            title: "Finalizar",
            text: (
                <>
                    {showEssayMessage && (
                        <strong>{hasUnsavedEssay ? "Sua redação será salva automaticamente." : "Você ainda não enviou sua redação!"}</strong>
                    )}
                    <p>Você realmente quer finalizar?</p>
                    {isExtra && <p>Aguarde o término do período da avaliação para saber o seu resultado!</p>}
                </>
            ),
            onConfirm: () => {
                ignoreUnMountEssay.current = true;
                answerCardActions.patchAnswerCardRequest({
                    answerCardId,
                    status: AnswerCardStatus.Done,
                    isSimulatedExam,
                    examDaySlug: examDayId,
                    activityId,
                    activitySubmissionId
                });
            },
            variant: "warning",
            buttonText: hasUnsavedEssay ? "Finalizar e salvar redação" : showEssayMessage ? "Finalizar sem redação" : "Finalizar"
        });
    }, [answerCardId, showEssayDialogue, activityId, activitySubmissionId, modalActions, isExtra, answerCardActions, isSimulatedExam, examDayId]);

    const handleClickNextQuestion = useCallback(() => {
        exerciseListActions.goToNextQuestion();
    }, [exerciseListActions]);

    const handleClickPrevQuestion = useCallback(() => {
        exerciseListActions.goToPrevQuestion();
    }, [exerciseListActions]);

    const handleClickAlternative = useCallback(
        (data: { questionId: number; alternativeId?: number; discursiveAnswer?: string }) => {
            try {
                if (isFeedBack) {
                    return alertActions.showAlert("Não é possível responder uma questão no modo gabarito.", "warning");
                }

                const { questionId, alternativeId, discursiveAnswer } = data;

                if (!questionId || (!alternativeId && !discursiveAnswer) || isFinished) {
                    throw new Error();
                }

                if (!!currentQuestion?.isCanceled) {
                    return alertActions.showAlert("Não é possível responder uma questão anulada.", "warning");
                }

                const markedAlternativeId = getQuestionMarkedAlternativeId({ answers: answerCard.answers, questionId });

                // Impede que a questao seja marcada mais de uma vez no modo estudo
                if (!!markedAlternativeId && resolutionMode === ExerciseListResolutionMode.Study) {
                    return;
                }

                // Impede que uma alternativa seja marcada mais de uma vez
                if (markedAlternativeId === alternativeId && !discursiveAnswer) {
                    return;
                }

                answerCardActions.postAnswerCardQuestionRequest({
                    answerCardId: answerCard.id,
                    ...(!!alternativeId && { optionId: alternativeId }),
                    ...(!!discursiveAnswer && { discursiveAnswer }),
                    questionId
                });

                // Joga pra próxima questao quando está no modo prova
                if (resolutionMode === ExerciseListResolutionMode.Exam) {
                    return handleClickNextQuestion();
                }
            } catch (error) {
                console.log(error);
            }
        },
        [
            isFeedBack,
            isFinished,
            currentQuestion,
            answerCard.answers,
            answerCard.id,
            resolutionMode,
            answerCardActions,
            alertActions,
            handleClickNextQuestion
        ]
    );

    const handleClickSeeQuestionResolution = useCallback(
        (data: { resolutions: any[]; question: IQuestion; answer: IAnswerCardItem }) => {
            try {
                const { resolutions, question, answer } = data;
                if (!resolutions || !resolutions.length || !question) {
                    throw new Error();
                }

                modalActions.openModal("resolutionExerciseList", { resolutions, question, answer });
            } catch (error) {
                console.log(error);
            }
        },
        [modalActions]
    );

    const handleMarkAlternativeByKeyboard = useCallback(
        (letter: string) => {
            try {
                const alternativeIndex = getNumberByLetter(letter);

                if (alternativeIndex === undefined || alternativeIndex < 0) {
                    throw new Error();
                }

                if (!currentQuestion) {
                    throw new Error();
                }

                const alternative = currentQuestion.alternatives[alternativeIndex];

                if (!alternative) {
                    throw new Error("alternative not found");
                }

                handleClickAlternative({
                    questionId: currentQuestion.id,
                    alternativeId: alternative.id
                });
            } catch (error) {
                console.log(error);
            }
        },
        [currentQuestion, handleClickAlternative]
    );

    const handleShowAnswerCard = useCallback(
        (show: boolean) => {
            exerciseListActions.showAnswerCard(show);
        },
        [exerciseListActions]
    );

    const handleShowResult = useCallback(
        (show = true) => {
            exerciseListActions.showResult(show);
        },
        [exerciseListActions]
    );

    // ###############
    // MEMO COMPONENTS
    // ###############

    const memoFooter = useMemo(() => {
        if (isExtra && isFinished && !showAnswers) {
            return null;
        }

        return (
            <ExerciseListFooter
                activeQuestionNumber={currentQuestion?.number || 0}
                totalQuestions={totalQuestions}
                isFinished={isFinished}
                onClickAnswerCard={() => handleShowAnswerCard(true)}
                onClickFinish={handleClickFinishList}
                onClickNextQuestion={handleClickNextQuestion}
                onClickPrevQuestion={handleClickPrevQuestion}
                isMobile={isMobile}
                onClickSeeQuestions={() => handleClickGoToQuestion(1)}
                onClickSeeResult={handleShowResult}
                isFeedBack={isFeedBack}
                hasEssay={hasEssay}
                showEssay={showEssay}
                showResult={showResult}
            />
        );
    }, [
        isExtra,
        isFinished,
        showAnswers,
        currentQuestion,
        totalQuestions,
        handleClickFinishList,
        handleClickNextQuestion,
        handleClickPrevQuestion,
        isMobile,
        handleShowResult,
        isFeedBack,
        hasEssay,
        showEssay,
        showResult,
        handleShowAnswerCard,
        handleClickGoToQuestion
    ]);

    const memoResult = useMemo(() => {
        return (
            <ExerciseListResult
                answers={answers}
                questions={items}
                showAnswers={showAnswers}
                showNotes={showNotes}
                resolutionMode={resolutionMode}
                onClickShowQuestion={handleClickGoToQuestion}
                questionsResolutions={questionsResolutions}
                onClickSeeResolution={handleClickSeeQuestionResolution}
                startDate={startDate}
                endDate={endDate || new Date().toISOString()}
                isExtra={isExtra}
                points={points || 0}
                isExam={isExam}
                knowledgeAreas={knowledgeAreas}
                totalQuestions={totalQuestions}
            />
        );
    }, [
        answers,
        items,
        showAnswers,
        showNotes,
        resolutionMode,
        handleClickGoToQuestion,
        questionsResolutions,
        handleClickSeeQuestionResolution,
        startDate,
        endDate,
        isExtra,
        points,
        isExam,
        knowledgeAreas,
        totalQuestions
    ]);

    const memoQuestions = useMemo(() => {
        if (!currentQuestion) {
            return null;
        }

        return (
            <ExerciseListQuestions
                subjectColor={subject?.color}
                answers={answers}
                currentQuestion={currentQuestion}
                showAnswers={showAnswers}
                onClickAlternative={handleClickAlternative}
                questionsResolutions={questionsResolutions}
                onClickSeeResolution={handleClickSeeQuestionResolution}
                isFinished={isFinished}
                knowledgeAreas={knowledgeAreas}
                blockQuestionToAnswer={blockQuestionToAnswer}
            />
        );
    }, [
        answers,
        blockQuestionToAnswer,
        currentQuestion,
        handleClickAlternative,
        handleClickSeeQuestionResolution,
        isFinished,
        knowledgeAreas,
        questionsResolutions,
        showAnswers,
        subject
    ]);

    const exhibitionStage = useMemo(() => {
        switch (true) {
            case showResult:
                return memoResult;

            case showEssay:
                return <ExerciseListEssay showCorrection={showEssayCorrection} isFinished={isFinished} handleSendEssay={handleSendEssay} />;

            default:
                return memoQuestions;
        }
    }, [handleSendEssay, isFinished, memoQuestions, memoResult, showEssay, showEssayCorrection, showResult]);

    // ################
    // KEYBOARD ACTIONS
    // ################

    const keyboardConfigs: IKeyDownEvent[] = useMemo(() => {
        try {
            if (showEssay) {
                return [];
            }

            if (isAwaitingCorrection || !currentQuestion) {
                throw new Error();
            }

            const isDiscursiveQuestion = currentQuestion?.questionType === QuestionType.Discursive;

            if (isDiscursiveQuestion) {
                throw new Error();
            }

            const navigationEvents = [
                {
                    key: KeyboardKeys.KEY_RIGHT,
                    callBack: () => handleClickNextQuestion()
                },
                {
                    key: KeyboardKeys.KEY_LEFT,
                    callBack: () => handleClickPrevQuestion()
                },
                {
                    key: KeyboardKeys.KEY_R,
                    callBack: () => handleShowAnswerCard(!showAnswerCard)
                }
            ];

            if (isFinished) {
                return navigationEvents;
            }

            // AÇÕES DE MARCAR ALTERNATIVA PELO TECLADO SÓ EXISTEM SE ESTIVER NUMA QUESTAO OBJETIVA
            const letterEvents = [
                {
                    key: KeyboardKeys.KEY_A,
                    callBack: () => handleMarkAlternativeByKeyboard("A")
                },
                {
                    key: KeyboardKeys.KEY_B,
                    callBack: () => handleMarkAlternativeByKeyboard("B")
                },
                {
                    key: KeyboardKeys.KEY_C,
                    callBack: () => handleMarkAlternativeByKeyboard("C")
                },
                {
                    key: KeyboardKeys.KEY_D,
                    callBack: () => handleMarkAlternativeByKeyboard("D")
                },
                {
                    key: KeyboardKeys.KEY_E,
                    callBack: () => handleMarkAlternativeByKeyboard("E")
                }
            ];

            return [...navigationEvents, ...letterEvents];
        } catch (error) {
            return [];
        }
    }, [
        currentQuestion,
        handleClickNextQuestion,
        handleClickPrevQuestion,
        handleMarkAlternativeByKeyboard,
        handleShowAnswerCard,
        isAwaitingCorrection,
        isFinished,
        showAnswerCard,
        showEssay
    ]);

    useKeyboardKeyDown({ events: keyboardConfigs });

    const hasNoIdOrExamDayId = useMemo(() => !id && !examDayId, [id, examDayId]);
    const hasNoQuestionsOrKnowledgeAreas = useMemo(() => !items && !knowledgeAreas, [items, knowledgeAreas]);
    const hasError = useMemo(() => hasNoIdOrExamDayId || hasNoQuestionsOrKnowledgeAreas || !!error || !totalQuestions, [
        error,
        hasNoIdOrExamDayId,
        hasNoQuestionsOrKnowledgeAreas,
        totalQuestions
    ]);

    const totalAnsweredQuestions = useMemo(() => {
        const totalAnswers = answers?.length || 0;

        return hasSentEssay ? totalAnswers + 1 : totalAnswers;
    }, [answers, hasSentEssay]);

    const handleClickGoToEssay = useCallback(() => {
        exerciseListActions.goToEssay();
    }, [exerciseListActions]);

    const activeQuestionNumber = (isExam ? currentQuestion?.originalNumber : currentQuestion?.number) || 0;

    const lastQuestionNumber = useMemo(() => {
        if (!isExam) {
            return totalQuestions;
        }

        const lastKnowleadge = _last(knowledgeAreas);

        const lastQuestion = _last(lastKnowleadge?.questions || []);

        if (!!lastQuestion?.originalNumber) {
            return hasEssay ? lastQuestion.originalNumber + 1 : lastQuestion.originalNumber;
        }

        return totalQuestions;
    }, [hasEssay, isExam, knowledgeAreas, totalQuestions]);

    if (hasError) {
        return <ExerciseListSplash error={error} />;
    }

    return (
        <>
            {(isLoading || isLoadingEssayCorrection) && <PageLoader />}

            {isLoadingEssay && <PageLoader text="Salvando redação..." />}
            <ExerciseListAnimations />
            {showEssayDialogue && <EssayDialogue />}

            <ExerciseListContainer id="ExerciseListContainer">
                <ExerciseListContent>
                    <ExerciseListHeader
                        activeQuestionNumber={activeQuestionNumber}
                        ref={refHeader}
                        title={title}
                        resolutionMode={resolutionMode}
                        totalAnsweredQuestions={totalAnsweredQuestions}
                        totalQuestions={totalQuestions}
                        startDate={startDate}
                        isFinished={isFinished}
                        endDate={endDate}
                        subject={subject}
                        onForceFinish={handleFinishTimeOut}
                        durationInMinutes={durationInMinutes}
                        isCountDown={isCountDown}
                        showEssay={showEssay}
                        showResult={showResult}
                        lastQuestionNumber={lastQuestionNumber}
                    />

                    <Grid fluid style={{ transform: `translateY(-32px)` }}>
                        {exhibitionStage}
                    </Grid>
                </ExerciseListContent>

                {memoFooter}
            </ExerciseListContainer>

            {showAnswerCard && (
                <ExerciseListAnswerCardModal
                    onClickClose={() => handleShowAnswerCard(false)}
                    showAnswers={showAnswers}
                    showNotes={showNotes}
                    onClickSeeQuestion={handleClickGoToQuestion}
                    isFinished={isFinished}
                    isFeedBack={isFeedBack}
                    handleClickSeeEssay={handleClickGoToEssay}
                />
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exerciseListActions: bindActionCreators(exerciseListActions, dispatch),
    answerCardActions: bindActionCreators(answerCardActions, dispatch),
    modalActions: bindActionCreators(modalActions, dispatch),
    alertActions: bindActionCreators(alertActions, dispatch)
});

const mapStateToProps = ({ exerciseList, answerCard }: IReduxStore) => {
    return {
        exerciseList,
        answerCard: answerCard.answerCard,
        isLoading: exerciseList.isLoading || answerCard.isLoading,
        error: answerCard.error || exerciseList.error,
        subject: exerciseList.subject,
        isLoadingEssay: !!exerciseList?.isLoadingEssay,
        hasSentEssay: !!answerCard?.answerCard?.composition?.idComposition || !!answerCard?.essayCorrection?.idComposition,
        isLoadingEssayCorrection: !!answerCard?.isLoadingEssayCorrection
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExerciseListScreen as any);
