import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { FilterGroupContainer } from "components/FilterGroup/styles";
import { fadeInUp, slideLeft, fadein } from "assets/styles/animations";

export const FilterModalBackground = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(37, 39, 45, 0.5);
    z-index: 9999999;
    top: 0;
    left: 0;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        animation: ${fadein} 0.3s ease-in-out;
    }
`;

export const FilterModalContainer = styled.div`
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    animation: ${fadeInUp} 0.25s linear;

    ${FilterGroupContainer} {
        flex: 1;
        overflow-y: auto;
    }

    ${ButtonWrapper} {
        margin: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        animation: ${slideLeft} 0.6s ease-in-out;
        margin-left: auto;
        width: 312px;
    }
`;

export const FilterModalButton = styled.button`
    align-items: center;
    color: ${theme.colors.base[400]};
    display: flex;

    .ico-filter,
    > span {
        margin-right: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        .ico-chevron-down {
            height: 8px;
            width: 16px;
        }
    }
`;

export const FilterModalItems = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding: ${theme.spacing.small};
    flex: 1;
    margin: ${theme.spacing.smallXX};

    ${FilterGroupContainer} {
        &:not(:last-child) {
            border-bottom: 1px solid ${theme.colors.base[100]};
            margin-bottom: ${theme.spacing.small};
            padding-bottom: ${theme.spacing.small};
        }
    }
`;

export const FilterModalTitle = styled.strong`
    font-weight: 600;
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;

export const FilterModalHeader = styled.div`
    padding: ${theme.spacing.small};
`;

export const FilterModalCloseButton = styled.button`
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.medium};

    svg {
        color: ${theme.colors.base[500]};
        display: block;
    }

    ${MediaQueries.BIGGER_THAN_TABLET} {
        margin-left: auto;
    }
`;
