// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container, HeaderCompositionWrapper } from "./styles";

type WebpStudyPlanDetailProps = {
    headerComposition: ReactNode;
    bodyComposition: ReactNode;
};

export const WebpStudyPlanDetail: FunctionComponent<WebpStudyPlanDetailProps> = ({ headerComposition, bodyComposition }) => {
    return (
        <Container>
            <HeaderCompositionWrapper>{headerComposition}</HeaderCompositionWrapper>

            {bodyComposition}
        </Container>
    );
};
