import React, { memo, useCallback, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";

// Components
import Complaint from "./complaint";
import { ISelectOption } from "interfaces/ISelectOption";
import { ComplaintTypeEnum } from "store/interfaces/IForum";

interface IComplaintModalProps {
    onComplaint: (value: any) => void;
    options?: ISelectOption[];
}

const defaultOptions = [
    { value: ComplaintTypeEnum.Spam, label: "Isso é um spam! Precisamos removê-lo do site" },
    { value: ComplaintTypeEnum.Offensive, label: "Esse conteúdo está ofendendo alguém/me ofendendo" },
    { value: ComplaintTypeEnum.WrongSubject, label: "Esse conteúdo deve estar na matéria errada" },
    { value: ComplaintTypeEnum.DubiousLink, label: "O conteúdo contém link(s) duvidoso(s)" }
];

const ComplaintModal: React.FC<IComplaintModalProps> = ({ onComplaint, options = defaultOptions }) => {
    const dispatch = useDispatch();
    const [option, setOption] = useState<number | undefined>();

    const handleCloseModal = useCallback(() => dispatch({ type: "CLOSE_MODAL" }), [dispatch]);

    const handleSubmit = useCallback(() => onComplaint(option), [onComplaint, option]);

    return <Complaint options={options} active={option} onCancel={handleCloseModal} onSelectComplaint={setOption} onSubmit={handleSubmit} />;
};

export default memo(ComplaintModal);
