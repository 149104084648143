import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const ProjectTagsContainer = styled.ul`
    align-items: center;
    display: flex;
    margin-bottom: ${theme.spacing.medium};
    overflow-x: auto;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        margin-bottom: 0;
    }
`;
