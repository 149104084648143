import React from "react";
import { theme } from "config/theme";
import { VictoryPie, VictoryTooltip } from "victory";
import { ExerciseListResultCardChartContainer } from "./styles";
import pluralize from "utils/pluralize";

interface IProps {
    isDesktop?: boolean;
    totalHits: number;
    totalErrors: number;
    isExtra?: boolean;
}

const ExerciseListResultCardChart = ({ isDesktop = false, totalHits, totalErrors, isExtra = false }: IProps) => {
    const totalQuestions = totalHits + totalErrors;
    const hitsPercentage = (totalHits / totalQuestions) * 100;
    const errorsPercentage = (totalErrors / totalQuestions) * 100;

    return (
        <ExerciseListResultCardChartContainer isDesktop={isDesktop}>
            <VictoryPie
                padding={0}
                colorScale={[theme.colors.system.success[200], theme.colors.system.danger[200]]}
                innerRadius={130}
                labelComponent={
                    <VictoryTooltip
                        active={false}
                        cornerRadius={40}
                        pointerWidth={24}
                        pointerLength={12}
                        className="exercise-list-tooltip"
                        dy={-35}
                        style={{
                            fill: theme.colors.white,
                            fontFamily: theme.typography.family.primary,
                            fontSize: 30,
                            fontWeight: 700,
                            padding: 8
                        }}
                        flyoutStyle={{
                            fill: theme.colors.base[400]
                        }}
                    />
                }
                data={[
                    {
                        x: totalHits,
                        y: totalHits,
                        label: `${totalHits} ${
                            isExtra ? pluralize(totalHits, "pontos", "ponto") : pluralize(totalHits, "acertos", "acerto")
                        } (${hitsPercentage}%)`
                    },
                    {
                        x: totalErrors,
                        y: totalErrors,
                        label: `${totalErrors} ${
                            isExtra ? pluralize(totalErrors, "pontos", "ponto") : pluralize(totalErrors, "erros", "erro")
                        } (${errorsPercentage}%)`
                    }
                ]}
            />
        </ExerciseListResultCardChartContainer>
    );
};

export default React.memo(ExerciseListResultCardChart);
