// Dependencies
import React, { memo, useEffect, useState } from "react";

// Utils
import { theme } from "config/theme";

// Components
import Spinner from "components/Spinner";

// Styles
import { SpinnerWrapper, ShowIFrameContainer, HeaderWrapper, ShowIFrameTitle, CloseIconWrapper, IFrame, ShowIFrameSeparator } from "./styles";

// Assets
import CloseIcon from "prodigio-icons/web/outlined/Close";

interface IShowIFrameProps {
    action: {
        title?: string;
        source?: string;
    };
    onClose: () => void;
}

const ShowIFrame = ({ action, onClose }: IShowIFrameProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const { title, source } = action;

    useEffect(() => {
        if (!!source) {
            setIsLoading(true);
        }
    }, [source]);

    return (
        <ShowIFrameContainer data-test-id="show-iframe">
            <HeaderWrapper>
                {!!title && <ShowIFrameTitle>{title}</ShowIFrameTitle>}

                <CloseIconWrapper onClick={onClose}>
                    <CloseIcon width="12" height="12" />
                </CloseIconWrapper>
            </HeaderWrapper>

            <ShowIFrameSeparator />
            {!!source && (
                <>
                    {isLoading && (
                        <SpinnerWrapper>
                            <Spinner fixed={false} size={80} color={theme.colors.system.info[400]} />
                        </SpinnerWrapper>
                    )}
                    <IFrame src={source} onLoad={() => setIsLoading(false)} hidden={isLoading} />
                </>
            )}
        </ShowIFrameContainer>
    );
};

export default memo(ShowIFrame);
