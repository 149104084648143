import { theme } from "config/theme";
import { fadeInDown } from "assets/styles/animations";
import { MediaQueries } from "assets/styles/settings";
import styled, { css } from "styled-components/macro";

export const DropdownContent = styled.div<{ direction: "top" | "bottom" }>`
    animation: ${fadeInDown} 0.3s linear;
    display: flex;
    flex-direction: column;
    position: absolute;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[2]};
    border-radius: 4px;
    padding: 0;
    z-index: 9;

    ${({ direction }) => {
        if (direction === "top") {
            return css`
                top: calc(100% + 10px);
                border-top: ${theme.spacing.smallX} solid ${theme.colors.brand[300]};
            `;
        }

        if (direction === "bottom") {
            return css`
                bottom: calc(100% + 10px);
                border-bottom: ${theme.spacing.smallX} solid ${theme.colors.brand[300]};
            `;
        }
    }}
    right: 0;

    &:before {
        display: block;
        content: "";
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        height: 0;
        position: absolute;
        right: ${theme.spacing.smallX};
        width: 0;

        ${({ direction }) => {
            if (direction === "top") {
                return css`
                    top: -16px;
                    border-bottom: 8px solid ${theme.colors.brand[300]};
                `;
            }

            if (direction === "bottom") {
                return css`
                    bottom: -16px;
                    border-top: 8px solid ${theme.colors.brand[300]};
                `;
            }
        }}
    }
`;

export const DropdownButton = styled.button`
    border: 0;
    cursor: pointer;
    display: flex;
`;

export const DropdownItem = styled.button`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.smallX} ${theme.spacing.smallXX};
    margin: ${theme.spacing.smallXX};
    border-bottom: 0.5px solid ${theme.colors.base[100]};

    color:${theme.colors.base[400]};
    font-family: 'Nunito Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.small};
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    &:first-child {
        margin-bottom: 0;
    }

    &:last-child {
        border-bottom: 0;
        margin-top: 0;
    }

    &:hover {
        /* background: ${theme.colors.base[100]}; */
    }

    span {
        font-size: ${theme.typography.sizes.smallX};
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        color: ${theme.colors.base[400]};

        ${MediaQueries.BIGGER_THAN_SMALL} {
            font-size: ${theme.typography.sizes.medium};
        }
    }

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    
`;

export const DropdownContainer = styled.div`
    align-items: center;
    display: flex;
    position: relative;
`;

export const DropdownIconContainer = styled.div`
    svg {
        width: 12px;
        height: 12px;
        position: absolute;
        top: ${theme.spacing.smallX};
        right: ${theme.spacing.smallX};
    }
`;

export const DropdownTextContainer = styled.div`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[2]};
    border-radius: ${theme.spacing.smallXX};
    display: flex;
    justify-content: center;

    a {
        color: ${theme.colors.base[500]};
    }

    p {
        font-size: 12px;
        line-height: ${theme.spacing.small};
        letter-spacing: 0.01em;
        color: ${theme.colors.base[500]};
    }

    div {
        align-self: center;
        margin: ${theme.spacing.small};

        p {
            white-space: nowrap;
        }
    }
`;
