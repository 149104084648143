// Dependencies
import styled from "styled-components";

// Types
import { theme } from "config/theme";

export const CarouselCardItemContainer = styled.div`
    padding: 16px 16px 24px 16px;

    border-radius: 8px;
    background-color: ${theme.colors.white};
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    width: 392px;
    height: 418px;

    z-index: 4;
`;

export const CarouselCardItemDescriptionWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
`;

export const CarouselCardItemIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 8px;
    border-radius: 4px;
    border: 2px solid ${theme.colors.base[100]};
    background-color: ${theme.colors.white};
`;

export const CarouselCardItemIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const CarouselCardItemTitleWrapper = styled.div`
    width: 100%;
`;

export const CarouselCardItemSubTitle = styled.p`
    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
