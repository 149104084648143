// Dependencies
import React, { Fragment, ReactNode, forwardRef } from "react";

// Styles
import { Container } from "./styles";

// Commponents
import { WebpImageDoubt } from "components/WebpImageDoubt";

// Assets
import pencil from "./assets/pencil.svg";
import trash from "./assets/trash.svg";
import bell from "./assets/bell.svg";
import bellFull from "./assets/bellFull.svg";

type WebpCardDoubtProps = {
    doubt: string;
    time?: string;
    nameUser?: string;
    photoUser?: string;
    author: boolean;
    isEditable: boolean;
    isExcludable: boolean;
    isFollowing?: boolean;
    activeButtonFollow?: boolean;
    children: ReactNode;

    clickFollow?: () => void;
    onClickEditDoubt: () => void;
    onClickExcludeDoubt: () => void;
};

export const WebpCardDoubt = forwardRef<HTMLDivElement, WebpCardDoubtProps>(
    (
        {
            activeButtonFollow = false,
            author = false,
            onClickEditDoubt,
            onClickExcludeDoubt,
            clickFollow,
            doubt,
            isEditable = true,
            isExcludable = true,
            isFollowing = false,
            nameUser,
            photoUser,
            children,
            time
        },
        ref
    ) => {
        const handleClickFollow = () => {
            if (!!clickFollow) {
                clickFollow();
            }
        };

        const handleClickEditDoubt = () => {
            onClickEditDoubt();
        };

        const handleClickExcludeDoubt = () => {
            onClickExcludeDoubt();
        };

        return (
            <Container ref={ref}>
                <div className="header">
                    <div className="image-and-name-wrapper">
                        <WebpImageDoubt photoUser={photoUser}></WebpImageDoubt>
                        <h2 className="name-user">{nameUser}</h2>
                    </div>

                    <p className="time">{time}</p>
                </div>

                <div className="doubt" id="doubt" dangerouslySetInnerHTML={{ __html: doubt }} />

                <div className="contentButtonsInteragions">
                    {author ? (
                        <div className="contentButtonsAuthor">
                            {isEditable && (
                                <button className="buttons" onClick={() => handleClickEditDoubt()}>
                                    <img className="svgButton" src={pencil} alt="ilustrative" />
                                    Editar
                                </button>
                            )}

                            {isExcludable && (
                                <button className="buttons" onClick={() => handleClickExcludeDoubt()}>
                                    <img className="svgButton" src={trash} alt="ilustrative" />
                                    Excluir
                                </button>
                            )}
                        </div>
                    ) : activeButtonFollow ? (
                        <button className="buttons bell" onClick={() => handleClickFollow()}>
                            <img className="svgButton" src={isFollowing ? bellFull : bell} alt="ilustrative" />
                            {isFollowing ? "Seguindo essa dúvida" : "Seguir duvida"}
                        </button>
                    ) : (
                        <Fragment></Fragment>
                    )}
                </div>

                {children}
            </Container>
        );
    }
);
