import React from "react";
import { ModalImageContainer } from "./styles";

interface IProps {
    image: {
        src: string;
        hasCloseButton: boolean;
    };
}

const ModalImage = ({ image }: IProps) => {
    return (
        <ModalImageContainer>
            <img src={image.src} alt="Zoom de Imagem" />
        </ModalImageContainer>
    );
};

export default React.memo(ModalImage);
