import React, { useState, useMemo } from "react";

// Dependencies
import formatDate from "utils/formatDate";
import ChartIcon from "prodigio-icons/web/filled/Chart";
import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";
import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";

// Components
import Button from "components/Button";
import StudentAreaScreenSignedCoursesCredits from "../SignedCoursesCredits";
import StudentAreaScreenSignedCoursesCardDuration from "../SignedCoursesDuration";

// Helpers
import { ISubscription } from "store/ducks/student-area";

import {
    StudentAreaScreenSignedCoursesCardWrapper,
    StudentAreaScreenSignedCoursesCardName,
    StudentAreaScreenSignedCoursesCardLegend,
    StudentAreaScreenSignedCoursesCardHeader,
    StudentAreaScreenSignedCoursesCardTexts,
    StudentAreaScreenSignedCoursesCardIconWrapper,
    StudentAreaScreenSignedCoursesCardDescription,
    StudentAreaScreenSignedCoursesCardFooter
} from "./styles";

export interface ISignedCoursesCard {
    subscription: ISubscription;
    showCredits?: boolean;
    onClickDetails?(): void;
}

const StudentAreaScreenSignedCoursesCard = ({ subscription, onClickDetails, showCredits = false }: ISignedCoursesCard) => {
    const { isActive, endDate, name, startDate } = subscription;

    const [isCardExpanded, setIsCardExpanded] = useState<boolean>(true);

    const memoDetails = useMemo(
        () => (
            <>
                <StudentAreaScreenSignedCoursesCardDescription>Duração</StudentAreaScreenSignedCoursesCardDescription>

                <StudentAreaScreenSignedCoursesCardDuration endDate={endDate} startDate={startDate} isActive={isActive} />

                <StudentAreaScreenSignedCoursesCardFooter>
                    <StudentAreaScreenSignedCoursesCardDescription>Detalhes do plano</StudentAreaScreenSignedCoursesCardDescription>

                    <Button variant="outline-primary" onClick={onClickDetails}>
                        Ver <ChevronRight width={4} />
                    </Button>
                </StudentAreaScreenSignedCoursesCardFooter>
            </>
        ),
        [endDate, isActive, onClickDetails, startDate]
    );

    return (
        <>
            <StudentAreaScreenSignedCoursesCardWrapper>
                <StudentAreaScreenSignedCoursesCardHeader
                    onClick={() => !showCredits && setIsCardExpanded(!isCardExpanded)}
                    isCardExpanded={isCardExpanded}
                >
                    <StudentAreaScreenSignedCoursesCardTexts>
                        <StudentAreaScreenSignedCoursesCardName>
                            <ChartIcon width={13} /> {name}
                        </StudentAreaScreenSignedCoursesCardName>
                        {!showCredits && (
                            <StudentAreaScreenSignedCoursesCardLegend>
                                {isActive
                                    ? `Adquirido em ${formatDate(startDate, "dd/MM/yyyy")}`
                                    : `Expirado em ${formatDate(endDate, "dd/MM/yyyy")}`}
                            </StudentAreaScreenSignedCoursesCardLegend>
                        )}
                    </StudentAreaScreenSignedCoursesCardTexts>
                    {!showCredits && (
                        <StudentAreaScreenSignedCoursesCardIconWrapper isCardExpanded={isCardExpanded}>
                            <ChevronDown width="10" />
                        </StudentAreaScreenSignedCoursesCardIconWrapper>
                    )}
                </StudentAreaScreenSignedCoursesCardHeader>
                {isCardExpanded && !showCredits && memoDetails}
                {showCredits && <StudentAreaScreenSignedCoursesCredits credits={subscription.credits || []} />}
            </StudentAreaScreenSignedCoursesCardWrapper>
        </>
    );
};

export default StudentAreaScreenSignedCoursesCard;
