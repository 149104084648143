// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SectionTitle = styled.div`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 768px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 0px;
    border: 1px solid #f0f1f7;
`;
