import React, { memo, useState, useCallback, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as qrCodeActions } from "store/ducks/qrCode";
import { Creators as modalActions } from "store/ducks/modal";

import Button from "components/Button";
import TextField from "components/Form/TextField";
import QrCode from "components/QrCode";

import {
    ScannerQrCodeContainer,
    ScannerQrCodeWrapper,
    ScannerQrCodeContent,
    ScannerQrCodeSidebar,
    ScannerQrCodeTitle,
    ScannerQrCodeSubTitle,
    ScannerQrCodeInput,
    ScannerQrCodeFooter,
    ScannerQrCodeFooterContainer,
    ScannerQrCodeFooterItem
} from "./styles";

const ScannerQrCode = () => {
    const [qrCodeSlug, setQrCodeSlug] = useState("");
    const [textFieldQrCodeSlug, setTextFieldQrCodeSlug] = useState("");

    const dispatch = useDispatch();
    const { isLoading } = useSelector(({ qrCode }: IReduxStore) => qrCode);

    const handleGetQrCode = useCallback(() => {
        dispatch(qrCodeActions.getQrCodeRequest({ slug: qrCodeSlug }));
    }, [dispatch, qrCodeSlug]);

    const handleChangeQrCodeSlug = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldQrCodeSlug(e.currentTarget.value);
    }, []);

    const handleSubmitQrCodeSlug = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            if (!!textFieldQrCodeSlug.length) {
                setQrCodeSlug(textFieldQrCodeSlug);
                setTextFieldQrCodeSlug("");
            }
        },
        [textFieldQrCodeSlug]
    );

    useEffect(() => {
        if (!!qrCodeSlug.length) {
            handleGetQrCode();
        }
    }, [handleGetQrCode, qrCodeSlug]);

    const showEnableDevicesModal = useCallback(() => {
        const enableDevicesContent = (
            <p>O ProEnem precisa ter acesso à câmera e ao microfone. Clique no ícone de câmera bloqueada na barra de endereço do seu navegador.</p>
        );

        dispatch(
            modalActions.openModal("attention", {
                type: "OPEN_MODAL",
                hasCloseButton: false,
                widthContainer: "480",
                alignText: "left",
                title: "Atenção, aluno!",
                text: enableDevicesContent
            })
        );
    }, [dispatch]);

    const checkDeviceStatus = useCallback(async () => {
        const cameraPermission = await navigator.permissions.query({ name: "camera" as PermissionName });
        const microphonePermission = await navigator.permissions.query({ name: "microphone" as PermissionName });

        if (cameraPermission.state === "denied" || microphonePermission.state === "denied") {
            showEnableDevicesModal();
        }
    }, [showEnableDevicesModal]);

    useEffect(() => {
        checkDeviceStatus();
    }, [checkDeviceStatus]);

    return (
        <ScannerQrCodeContainer>
            <ScannerQrCodeWrapper>
                <ScannerQrCodeContent>
                    <QrCode onSetQrCodeSlug={setQrCodeSlug} />
                </ScannerQrCodeContent>
                <ScannerQrCodeSidebar>
                    <ScannerQrCodeTitle>Ler QR Code</ScannerQrCodeTitle>
                    <ScannerQrCodeSubTitle>Escaneie o QR Code ou insira o código</ScannerQrCodeSubTitle>

                    <ScannerQrCodeInput>
                        <TextField style={{ height: "40px" }} label="Digite o código" value={textFieldQrCodeSlug} onChange={handleChangeQrCodeSlug} />
                        <Button variant="primary" block={true} size="medium" onClick={handleSubmitQrCodeSlug} disabled={isLoading}>
                            {isLoading ? "Carregando..." : "Aplicar"}
                        </Button>
                    </ScannerQrCodeInput>

                    <ScannerQrCodeFooter>
                        <ScannerQrCodeFooterItem>Instruções:</ScannerQrCodeFooterItem>

                        <ScannerQrCodeFooterContainer>
                            <div>
                                <ScannerQrCodeFooterItem>
                                    <span>&bull;</span> Certifique-se de estar um lugar bem iluminado
                                </ScannerQrCodeFooterItem>
                            </div>
                            <div>
                                <ScannerQrCodeFooterItem>
                                    <span>&bull;</span> Mantenha o código centralizado na câmera
                                </ScannerQrCodeFooterItem>
                            </div>
                            <div>
                                <ScannerQrCodeFooterItem>
                                    <span>&bull;</span> Certifique-se que o código está legível
                                </ScannerQrCodeFooterItem>
                            </div>
                        </ScannerQrCodeFooterContainer>
                    </ScannerQrCodeFooter>
                </ScannerQrCodeSidebar>
            </ScannerQrCodeWrapper>
        </ScannerQrCodeContainer>
    );
};

export default memo(ScannerQrCode);
