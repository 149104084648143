import { TitleH3, TitleH4, BodyText2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { CardStyles } from "components/Card/styles";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { ExamResultFeedbackContainer } from "../Feedback/styles";

export const ExamResultContestCardContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0 -0.5rem;

    ::-webkit-scrollbar {
        display: none;
    }

    > div {
        display: flex;
        margin: 0 0.5rem;

        /* FAKE ELEMENT TO REMOVE SPACING BUG (MOBILE ONLY) */
        :after {
            content: " ";
            width: 1px;
            flex: 0 0 auto;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-wrap: unset;
        overflow-x: unset;
        margin: unset;
        margin-bottom: ${theme.spacing.small};

        > div {
            width: 100%;
            margin: unset;
            flex-direction: column;
            padding-right: ${theme.spacing.smallX};
            overflow-x: unset;

            :after {
                display: none;
            }
        }
    }
`;

const ExamResultContestCardItem = styled.button<{ active?: boolean }>`
    ${CardStyles};

    flex: 0 0 auto;

    text-align: left;
    flex-direction: column;

    margin-right: ${theme.spacing.smallX};
    margin-bottom: ${theme.spacing.small};

    opacity: 0.8;

    transition: opacity 0.5s;

    :hover {
        opacity: 1;
    }

    ${ExamResultFeedbackContainer} {
        > div {
            p {
                ${BodyText2};
                color: ${theme.colors.base[300]};
            }
        }
    }

    ${({ active }) =>
        !!active &&
        css`
            opacity: 1;
            box-shadow: ${theme.shadow[3]};

            ${MediaQueries.BIGGER_THAN_SMALL} {
                :before {
                    display: block;
                    content: " ";
                    position: absolute;
                    top: ${theme.spacing.small};
                    width: ${theme.spacing.small};
                    height: ${theme.spacing.small};
                    background: #fff;

                    right: -8px;

                    transform: rotate(45deg);
                    box-shadow: 5px -4px 6px 0px rgba(0, 0, 0, 0.05);
                }
            }
        `};
`;

ExamResultContestCardItem.defaultProps = {
    active: false
};

export { ExamResultContestCardItem };

export const ExamResultContestCardTitle = styled.h3`
    color: ${theme.colors.base[500]};
    ${TitleH3};
    margin-bottom: ${theme.spacing.smallX};
`;

export const ExamResultContestCardLegends = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.small};
    width: 100%;
`;

export const ExamResultContestCardNote = styled.p`
    flex: 1;
    span {
        ${BodyText2};
        color: ${theme.colors.base[300]};
    }

    strong {
        ${TitleH4};
        color: ${theme.colors.base[500]};
    }
`;

export const ExamResultContestCardRanking = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    strong {
        ${TitleH4};
        color: ${theme.colors.base[500]};
    }
`;
