import React from "react";
import { Link } from "react-router-dom";

import If from "components/If";
import Separator from "../Separator";
import { Card, HeaderCard, DescriptionCard, PositionTitleAndBadge, TitleCard, BadgeCard, FindContentText, BreadCrumbCard } from "./styles";

interface IProps {
    title: string;
    badge: string;
    date: string;
    link: string;
    breadcrumb: string;
    onCloseModal(): void;
}

const ContentCard = ({ title, badge, link, breadcrumb, onCloseModal }: IProps) => {
    const handleClick = () => {
        const [, booklet] = link.split("#");
        window.sessionStorage.setItem("currentBookletModule", booklet);
        onCloseModal();
    };

    return (
        <Link to={link} onClick={() => handleClick()}>
            <Card>
                <HeaderCard>
                    <PositionTitleAndBadge>
                        <TitleCard>{title}</TitleCard>
                        <If test={!!badge}>
                            <BadgeCard>{badge}</BadgeCard>
                        </If>
                    </PositionTitleAndBadge>
                </HeaderCard>
                <If test={!!breadcrumb}>
                    <Separator />
                    <DescriptionCard>
                        <FindContentText>Onde posso encontrar?</FindContentText>
                        <BreadCrumbCard>{breadcrumb}</BreadCrumbCard>
                    </DescriptionCard>
                </If>
            </Card>
        </Link>
    );
};
export default ContentCard;
