// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceCommentProps } from "./performance-comment.types";

// Styles
import {
    Container,
    PositionWrapper,
    Position,
    CommentWrapper,
    Arrow,
    CommentContainer,
    CommentSkillHeader,
    CommentSkillWrapper,
    CommentSkillIndicator,
    CommentSkill,
    CommentTitle,
    Comment
} from "./performance-comment.styles";

// Components
import { Asset } from "component-library/utilities/asset";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";

export const PerformanceComment: FunctionComponent<PerformanceCommentProps> = ({
    position,
    skillIndicatorColor,
    skill,
    iconCommentClosed,
    iconCommentOpened,
    title,
    comment,
    isOpened,
    handleOpen
}) => {
    return (
        <Container selected={isOpened}>
            <PositionWrapper onClick={() => handleOpen(!isOpened)}>
                <Position>{position}º</Position>
            </PositionWrapper>

            <CommentWrapper>
                <Arrow onClick={() => handleOpen(!isOpened)} />

                <CommentContainer onClick={() => handleOpen(!isOpened)}>
                    <CommentSkillHeader>
                        <CommentSkillWrapper>
                            <CommentSkillIndicator color={skillIndicatorColor} />

                            <Spacing direction="horizontal" size={8} />

                            <CommentSkill>{skill}</CommentSkill>
                        </CommentSkillWrapper>

                        <ScreenWidthRender
                            actionAfterRenderingWidth="hide"
                            renderingWidth={1024}
                            content={
                                <Asset
                                    size={16}
                                    source={isOpened ? iconCommentOpened : iconCommentClosed}
                                    color={isOpened ? "#571D5D" : "#7C8195"}
                                    hoverColor={isOpened ? "#571D5D" : "#7C8195"}
                                />
                            }
                        />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={1024}
                            content={
                                <Asset
                                    size={20}
                                    source={isOpened ? iconCommentOpened : iconCommentClosed}
                                    color={isOpened ? "#571D5D" : "#7C8195"}
                                    hoverColor={isOpened ? "#571D5D" : "#7C8195"}
                                />
                            }
                        />
                    </CommentSkillHeader>

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={12} />} />

                    <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                    <CommentTitle>{title}</CommentTitle>

                    <ConditionallyRender
                        shouldRender={!!isOpened}
                        content={
                            <>
                                <ScreenWidthRender
                                    actionAfterRenderingWidth="hide"
                                    renderingWidth={1024}
                                    content={<Spacing direction="vertical" size={12} />}
                                />

                                <ScreenWidthRender
                                    actionAfterRenderingWidth="show"
                                    renderingWidth={1024}
                                    content={<Spacing direction="vertical" size={4} />}
                                />

                                <Comment>{comment}</Comment>
                            </>
                        }
                    />
                </CommentContainer>
            </CommentWrapper>
        </Container>
    );
};
