// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

// Types
import { ActivitySelectorItemAlignment, ActivitySelectorItemGuidance, ActivitySelectorItemVariant } from "./activity-selector.types";

export const SelectorContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

type SelectorContentStyleProps = {
    itemsCount: 2 | 3;
};

export const SelectorContent = styled.div<SelectorContentStyleProps>`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 24px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: grid;
        flex-direction: row;

        ${({ itemsCount }) =>
            itemsCount === 2 &&
            css`
                grid-template-columns: 1fr 1fr;
            `}

        ${({ itemsCount }) =>
            itemsCount === 3 &&
            css`
                grid-template-columns: 1fr 1fr 1fr;
            `}
    }
`;

type SelectorItemContainerStyleProps = {
    isActive: boolean;
    alignment: ActivitySelectorItemAlignment;
    variant: ActivitySelectorItemVariant;
    guidance: ActivitySelectorItemGuidance;
    hasAction: boolean;
    hasFullyAdaptativeHeight: boolean;
};

export const SelectorItemContainer = styled.div<SelectorItemContainerStyleProps>`
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid ${({ isActive }) => (isActive ? "#24388C" : "#F0F1F7")};

    display: flex;
    gap: 12px;

    ${({ hasFullyAdaptativeHeight }) =>
        hasFullyAdaptativeHeight &&
        css`
            height: 100%;
        `}

    ${({ hasAction }) =>
        hasAction &&
        css`
            cursor: pointer;
        `}

    ${({ variant }) =>
        variant === "white" &&
        css`
            background-color: #ffffff;
        `}

    ${({ variant }) =>
        variant === "neutral" &&
        css`
            background-color: #f0f1f7;
        `}

    ${({ alignment }) =>
        alignment === "start" &&
        css`
            align-items: flex-start;
        `}

    ${({ alignment }) =>
        alignment === "center" &&
        css`
            align-items: center;
        `}

    ${({ alignment }) =>
        alignment === "end" &&
        css`
            align-items: flex-end;
        `}

    ${({ guidance }) =>
        guidance === "horizontal" &&
        css`
            flex-direction: row;
        `}

    ${({ guidance }) =>
        guidance === "vertical" &&
        css`
            flex-direction: column;
        `}

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 24px;
        flex-direction: row;
        gap: 24px;
    }
`;

export const SelectorItemIcon = styled.img`
    display: flex;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
`;

export const SelectorContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const SelectorItemTitle = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #232326;
`;

export const SelectorItemDescription = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
`;

export const UnderSelectMessageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UnderSelectMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const UnderSelectMessage = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e40000;
`;
