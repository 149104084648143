import styled, { css } from "styled-components";
import { theme } from "config/theme";

interface ItemStyleProps {
    active?: boolean;
}

export const BreadcrumbContainer = styled.ul`
    display: flex;
    align-items: center;
`;

export const Item = styled.li<ItemStyleProps>`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
    font-family: ${theme.typography.family.secondary};
    line-height: 1;

    a {
        color: ${theme.colors.base[400]};
        line-height: 1;

        &:hover {
            color: ${theme.colors.base[500]};
        }
    }

    ${({ active }) =>
        active &&
        css`
            color: ${theme.colors.brand[500]};
            font-weight: 600;

            a {
                color: ${theme.colors.brand[500]};
            }
        `}
`;

interface SeparatorStyleProps {
    active?: boolean;
}

export const Separator = styled.li<SeparatorStyleProps>`
    line-height: 1;
    margin: 0 ${theme.spacing.small};
    display: flex;
    align-items: center;

    ${({ active }) =>
        active &&
        css`
            color: ${theme.colors.brand[400]};
            font-weight: 600;
        `}
`;
