import React, { useState, useEffect } from "react";

import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Types as EssayTypes } from "store/ducks/essay";

import Spinner from "components/Spinner";
import EssayStatus from "./status";

import * as S from "./styles";

interface IEssayStatusScreen extends RouteComponentProps<{ id: string }> {}

const EssayStatusScreen = (props: IEssayStatusScreen) => {
    const dispatch = useDispatch();
    const [isCompositionFetched, setIsCompositionFetched] = useState(false);
    const {
        isLoading,
        compositions: { composition },
        themes: { theme }
    } = useSelector(({ essay }: IReduxStore) => essay);

    useEffect(() => {
        const { id } = props.match.params;

        if (id) {
            dispatch({ type: EssayTypes.GET_ESSAY_COMPOSITION_REQUEST, payload: { id, getTheme: true } });
            setIsCompositionFetched(true);
        }
    }, [composition.idTheme, dispatch, isCompositionFetched, props.match.params]);

    return (
        <>
            {isLoading ? (
                <S.EssaySpinnerContainer>
                    <Spinner fixed={false} />
                </S.EssaySpinnerContainer>
            ) : (
                <EssayStatus composition={composition} themeTitle={theme.title} />
            )}
        </>
    );
};

export default EssayStatusScreen;
