// sampleReducer.js
import { createReducer, createActions } from "reduxsauce";
import { ExamTypes, IExamResponse, IExamActionsCreators } from "./types";
import { IExamDay, IExamDayResult } from "interfaces/IExamDays";
import { IExam, IExamList, IExamResolution, IExamResult, IExamScore } from "interfaces/IExam";
import { ReduxAction } from "store";
import { ApplymentWindowType } from "enums/ApplymentWindowType";

export interface IExamState extends IExamResponse, IExam, IExamResult {
    loading: boolean;
    loadingDay: boolean;
    error: boolean;
    activeResultModal: boolean;
    loadingResult: boolean;
    errorResult: boolean;
    loadingCurrentResult: boolean;
    errorCurrentResult: boolean;
    loadingRelatedDays: boolean;
    activeDayModal: boolean;
    examCompletedResolutionsLoading: number[];
    isEmpty: boolean;
    totalCredits: number;

    isLoadingSaveExam?: boolean;
}

export const { Creators, Types } = createActions<Record<ExamTypes, ExamTypes>, IExamActionsCreators>({
    getAllExamsRequest: ["payload"],
    getAllExamsSuccess: ["payload"],
    getAllExamsFailure: ["payload"],

    getExamDayRequest: ["payload"],
    getExamDaySuccess: ["payload"],
    getExamDayFailure: ["payload"],

    getExamFiltersRequest: ["payload"],
    getExamFiltersSuccess: ["payload"],
    getExamFiltersFailure: ["payload"],

    openExamResultRequest: ["payload"],
    openExamResultSuccess: [],
    openExamResultFailure: [],

    getExamDayResultRequest: ["payload"],
    getExamDayResultSuccess: ["payload"],
    getExamDayResultFailure: ["payload"],

    getExamResultScoreRequest: ["payload"],
    getExamResultScoreSuccess: ["payload"],
    getExamResultScoreFailure: [],

    getExamResolutionRequest: ["payload"],
    getExamResolutionSuccess: ["payload"],
    getExamResolutionFailure: [],

    getExamRequest: ["payload"],
    getExamSuccess: ["payload"],
    getExamFailure: [],

    getExamDayRelatedDaysRequest: ["payload"],
    getExamDayRelatedDaysSuccess: ["payload"],
    getExamDayRelatedDaysFailure: [],

    openExamDayModal: ["payload"],
    closeExamDayModal: ["payload"],

    getExamCompletedResolutionIdRequest: ["payload"],
    getExamCompletedResolutionIdSuccess: ["payload"],
    getExamCompletedResolutionIdFailure: ["payload"],

    getExamCreditsRequest: [],
    getExamCreditsSuccess: ["payload"],
    getExamCreditsFailure: [],

    closeExamResult: [],

    clearAllExams: [],
    clearExamDay: [],
    clearExamDayResult: []
});

const INITIAL_STATE_DAY: IExamDay = {
    duration: "",
    durationInMinutes: 0,
    endDate: "",
    exerciseList: {
        code: "",
        description: "",
        durationDefault: 0,
        id: 0,
        realizations: [],
        realizationsAverageHitRate: 0,
        realizationsPerStudent: 0,
        realizationsTotal: 0,
        slug: "",
        title: ""
    },
    id: 0,
    isAnswerReleased: false,
    isOpen: false,
    isPaid: false,
    isReleased: false,
    knowledgeAreas: [],
    name: "",
    questionsTotal: 0,
    showRanking: false,
    slug: "",
    startDate: "",
    applyment: ApplymentWindowType.Online
};

const INITIAL_STATE_DAY_RESULT: IExamDayResult = {
    endDate: "",
    knowledgeAreas: [],
    startDate: "",
    totalCorrects: 0,
    totalQuestions: 0,
    totalWrong: 0,
    examDay: {
        exam: {
            id: 0,
            isAvailable: false,
            name: "",
            slug: ""
        },
        id: 0,
        isAnswerReleased: false,
        name: "",
        slug: ""
    },
    examResolution: undefined
};

const INITIAL_STATE: IExamState = {
    name: "",
    id: 0,
    slug: "",
    error: false,
    loading: false,
    loadingDay: false,
    activeResultModal: false,
    items: [],
    day: INITIAL_STATE_DAY,
    dayResult: INITIAL_STATE_DAY_RESULT,
    loadingResult: true,
    errorResult: false,
    loadingCurrentResult: true,
    errorCurrentResult: false,
    currentExamResult: undefined,
    relatedDays: [],
    loadingRelatedDays: true,
    activeDayModal: false,
    examCompletedResolutionsLoading: [],
    isEmpty: false,
    totalCredits: 0
};

const chore = (state = INITIAL_STATE) => {
    return state;
};

const request = (state = INITIAL_STATE) => {
    return { ...state, error: false, loading: true };
};

const getAllSucess = (state = INITIAL_STATE, action: ReduxAction<IExamList[]>) => {
    return {
        ...state,
        error: false,
        loading: false,
        items: action?.payload || [],
        isEmpty: !action?.payload?.length
    };
};

const examFilterSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, error: false, loading: false, filters: action.payload };
};

const examDayResultSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, error: false, loading: false, dayResult: action.payload };
};

const getExamSucess = (state = INITIAL_STATE, action: any) => {
    return { ...state, error: false, loading: false, ...action.payload };
};

// RESULT

const getExamResultScoreRequest = (state = INITIAL_STATE, action: ReduxAction<IExamScore>) => {
    return {
        ...state,
        loadingCurrentResult: true,
        errorCurrentResult: false,
        currentExamResult: {
            ...(state.currentExamResult as any),
            score: action.payload
        }
    };
};

const getExamResultScoreSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        loadingCurrentResult: false,
        errorCurrentResult: false,
        currentExamResult: {
            ...(state?.currentExamResult as any),
            ...action.payload
        }
    };
};

const getExamResultScoreFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        loadingCurrentResult: false,
        errorCurrentResult: true,
        currentExamResult: {
            ...(state.currentExamResult as any),
            knowledgeAreas: []
        }
    };
};

const getExamDayRelatedDaysRequest = (state = INITIAL_STATE, action: any) => {
    return { ...state, loadingRelatedDays: true, relatedDays: [] };
};

const getExamDayRelatedDaysSuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, loadingRelatedDays: false, relatedDays: action.payload };
};

const getExamDayRelatedDaysFailure = (state = INITIAL_STATE, action: any) => {
    return { ...state, loadingRelatedDays: false, relatedDays: [] };
};

// uh oh
const failure = (state = INITIAL_STATE) => {
    return { ...state, error: true, loading: false };
};

const openExamResult = (state = INITIAL_STATE) => {
    return { ...state, activeResultModal: true, loading: false };
};

const closeExamResult = (state = INITIAL_STATE) => {
    return { ...state, activeResultModal: false, loading: false };
};

const requestDay = (state = INITIAL_STATE) => {
    return { ...state, error: false, loadingDay: true };
};

const examDaySuccess = (state = INITIAL_STATE, action: any) => {
    return { ...state, error: false, loadingDay: false, day: action.payload };
};

// clears
const clear = () => INITIAL_STATE;

const clearExamDay = (state = INITIAL_STATE) => {
    return { ...state, day: INITIAL_STATE_DAY };
};

const clearExamDayResult = (state = INITIAL_STATE) => {
    return { ...state, dayResult: INITIAL_STATE_DAY_RESULT, relatedDays: [] };
};

const openExamDayModal = (state = INITIAL_STATE, _: any) => {
    return { ...state, loadingDay: true, activeDayModal: true };
};

const closeExamDayModal = (state = INITIAL_STATE) => {
    return { ...state, activeDayModal: false };
};

const loadingCompletedResolutions = (state = INITIAL_STATE, action: ReduxAction<{ examId: number }>) => {
    const loadingIds = [...state.examCompletedResolutionsLoading, action?.payload?.examId];

    return { ...state, examCompletedResolutionsLoading: loadingIds };
};

const setCompletedResolutionId = (state = INITIAL_STATE, action: ReduxAction<{ examId: number; resolutionId: number }>) => {
    const { examId, resolutionId } = action?.payload || {};

    const loadingIds = state.examCompletedResolutionsLoading.filter((id) => id !== examId);

    const updateExams = state.items.map((exam) => (exam.id === examId ? { ...exam, resolutionId } : exam));

    return { ...state, examCompletedResolutionsLoading: loadingIds, items: updateExams };
};

const stopLoadingCompletedResolutions = (state = INITIAL_STATE, action: ReduxAction<{ examId: number }>) => {
    const loadingIds = state.examCompletedResolutionsLoading.filter((id) => id !== action?.payload?.examId);

    return { ...state, examCompletedResolutionsLoading: loadingIds };
};

const getResolutionRequest = (state = INITIAL_STATE, action: ReduxAction<IExamResolution | { id: number }>) => {
    const currentResolution = state?.resolutions?.find(({ id }) => id === action?.payload?.id);

    return {
        ...state,
        loadingCurrentResult: true,
        errorCurrentResult: false,
        currentExamResult: {
            currentResolution
        },
        scores: []
    };
};

const getResolutionSuccess = (state = INITIAL_STATE, action: ReduxAction<IExamScore[]>) => {
    return {
        ...state,
        loadingCurrentResult: false,
        errorCurrentResult: false,
        scores: action?.payload || []
    };
};

const getResolutionFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        loadingCurrentResult: false,
        errorCurrentResult: false,
        scores: []
    };
};

const setTotalCredits = (state = INITIAL_STATE, action: ReduxAction<number>) => {
    return {
        ...state,
        totalCredits: ~~action?.payload || 0
    };
};

const HANDLERS = {
    [Types.GET_ALL_EXAMS_REQUEST]: request,
    [Types.GET_ALL_EXAMS_SUCCESS]: getAllSucess,
    [Types.GET_ALL_EXAMS_FAILURE]: failure,

    [Types.GET_EXAM_DAY_REQUEST]: requestDay,
    [Types.GET_EXAM_DAY_SUCCESS]: examDaySuccess,
    [Types.GET_EXAM_DAY_FAILURE]: failure,

    [Types.GET_EXAM_FILTERS_REQUEST]: request,
    [Types.GET_EXAM_FILTERS_SUCCESS]: examFilterSuccess,
    [Types.GET_EXAM_FILTERS_FAILURE]: chore,

    [Types.OPEN_EXAM_RESULT_REQUEST]: request,
    [Types.OPEN_EXAM_RESULT_SUCCESS]: openExamResult,
    [Types.OPEN_EXAM_RESULT_FAILURE]: closeExamResult,

    [Types.CLOSE_EXAM_RESULT]: chore,

    [Types.GET_EXAM_DAY_RESULT_REQUEST]: request,
    [Types.GET_EXAM_DAY_RESULT_SUCCESS]: examDayResultSuccess,
    [Types.GET_EXAM_DAY_RESULT_FAILURE]: failure,

    [Types.GET_EXAM_RESULT_SCORE_REQUEST]: getExamResultScoreRequest,
    [Types.GET_EXAM_RESULT_SCORE_SUCCESS]: getExamResultScoreSuccess,
    [Types.GET_EXAM_RESULT_SCORE_FAILURE]: getExamResultScoreFailure,

    [Types.GET_EXAM_REQUEST]: request,
    [Types.GET_EXAM_SUCCESS]: getExamSucess,
    [Types.GET_EXAM_FAILURE]: failure,

    [Types.GET_EXAM_DAY_RELATED_DAYS_REQUEST]: getExamDayRelatedDaysRequest,
    [Types.GET_EXAM_DAY_RELATED_DAYS_SUCCESS]: getExamDayRelatedDaysSuccess,
    [Types.GET_EXAM_DAY_RELATED_DAYS_FAILURE]: getExamDayRelatedDaysFailure,

    [Types.OPEN_EXAM_DAY_MODAL]: openExamDayModal,
    [Types.CLOSE_EXAM_DAY_MODAL]: closeExamDayModal,

    [Types.CLEAR_ALL_EXAMS]: clear,
    [Types.CLEAR_EXAM_DAY]: clearExamDay,
    [Types.CLEAR_EXAM_DAY_RESULT]: clearExamDayResult,

    [Types.GET_EXAM_COMPLETED_RESOLUTION_ID_REQUEST]: loadingCompletedResolutions,
    [Types.GET_EXAM_COMPLETED_RESOLUTION_ID_SUCCESS]: setCompletedResolutionId,
    [Types.GET_EXAM_COMPLETED_RESOLUTION_ID_FAILURE]: stopLoadingCompletedResolutions,

    [Types.GET_EXAM_RESOLUTION_REQUEST]: getResolutionRequest,
    [Types.GET_EXAM_RESOLUTION_SUCCESS]: getResolutionSuccess,
    [Types.GET_EXAM_RESOLUTION_FAILURE]: getResolutionFailure,

    [Types.GET_EXAM_CREDITS_REQUEST]: chore,
    [Types.GET_EXAM_CREDITS_SUCCESS]: setTotalCredits,
    [Types.GET_EXAM_CREDITS_FAILURE]: chore
};

export default createReducer(INITIAL_STATE, HANDLERS);
