// Dependencies
import { useLocation } from "react-router";

// Components
import If from "components/If";

// Helpers
import { navigation, theme } from "config/theme";
import { useModal } from "hooks/use-modal";
import { IExternalSchoolClass } from "interfaces/IExternalSchoolClass";
import Question from "prodigio-icons/web/outlined/Question";
import React, { memo, useCallback, useMemo } from "react";
import { useSelector } from "store";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";

// Assets
import {
    ChatButtonWrapper,
    MenuListBallon,
    MenuListBallonContainer,
    MenuListItem,
    MenuListItemButton,
    MenuListItemContainer,
    MenuListItemExternal,
    MenuListNotExpanded,
    MenuListNotExpandedButton,
    MenuListNotExpandedExternal,
    MenuListWrapper
} from "./styles";

export interface IMenuListProps {
    expanded: boolean;
    onClose(): void;
    externalSchoolClasses?: IExternalSchoolClass[];
}
/**
 * Add access to the global object movideskChatWidget, loaded by the movidesk script.
 */
declare global {
    interface Window {
        movideskChatWidget: any;
    }
}

window.movideskChatWidget = window.movideskChatWidget || {};

const MenuList = ({ expanded, onClose, externalSchoolClasses = [] }: IMenuListProps) => {
    const modal = useModal();
    const { pathname } = useLocation();

    const shouldRenderHelpCenter = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

    const hasOnlyOneExternalSchoolClass = useMemo(() => externalSchoolClasses?.length === 1, [externalSchoolClasses]);

    const handleClickExternalSchoolClass = useCallback(() => {
        modal.open("externalSchoolClass");
    }, [modal]);

    const menuItems = useSelector(({ auth }) => auth?.menu || []);

    const renderMenuList = useMemo(() => {
        const externalSchoolClass = navigation.find(({ isSchoolClassLink }) => !!isSchoolClassLink);

        const mappedMenuList = menuItems
            .filter(({ isSchoolClassLink }) => !isSchoolClassLink)
            .map((item, index) => {
                return (
                    <MenuListItemContainer id="MenuListItemContainer" key={item.name}>
                        <If test={expanded}>
                            {item.isExternal ? (
                                <MenuListItemExternal href={item.url} target="_blank">
                                    {item.icon}
                                    <p>{item.name}</p>
                                </MenuListItemExternal>
                            ) : (
                                <MenuListItem
                                    id="MenuListItem"
                                    key={index}
                                    to={item.url}
                                    onClick={onClose}
                                    {...(pathname.includes(item.url.split("/app")[1]) && { className: "selected" })}
                                >
                                    {item.icon}
                                    <p>{item.name}</p>
                                </MenuListItem>
                            )}
                        </If>
                        <If test={!expanded}>
                            {item.isExternal ? (
                                <MenuListNotExpandedExternal href={item.url} target="_blank">
                                    {item.icon}
                                    <MenuListBallonContainer>
                                        <MenuListBallon>
                                            <p>{item.name}</p>
                                        </MenuListBallon>
                                    </MenuListBallonContainer>
                                </MenuListNotExpandedExternal>
                            ) : (
                                <MenuListNotExpanded
                                    key={index}
                                    to={item.url}
                                    onClick={onClose}
                                    {...(pathname.includes(item.url.split("/app")[1]) && { className: "selected" })}
                                >
                                    {item.icon}
                                    <MenuListBallonContainer>
                                        <MenuListBallon>
                                            <p>{item.name}</p>
                                        </MenuListBallon>
                                    </MenuListBallonContainer>
                                </MenuListNotExpanded>
                            )}
                        </If>
                    </MenuListItemContainer>
                );
            });

        return (
            <>
                {!!externalSchoolClass && !!externalSchoolClasses?.length && (
                    <MenuListItemContainer id="MenuListItemContainer">
                        {expanded ? (
                            <MenuListItemButton
                                {...(hasOnlyOneExternalSchoolClass
                                    ? { as: "a", href: externalSchoolClasses[0]?.externalURL, target: "_blank" }
                                    : { onClick: handleClickExternalSchoolClass })}
                            >
                                {externalSchoolClass.icon}
                                <p>{externalSchoolClass.name}</p>
                            </MenuListItemButton>
                        ) : (
                            <MenuListNotExpandedButton
                                {...(hasOnlyOneExternalSchoolClass
                                    ? { as: "a", href: externalSchoolClasses[0]?.externalURL, target: "_blank" }
                                    : { onClick: handleClickExternalSchoolClass })}
                            >
                                {externalSchoolClass.icon}
                                <MenuListBallonContainer>
                                    <MenuListBallon>
                                        <p>{externalSchoolClass.name}</p>
                                    </MenuListBallon>
                                </MenuListBallonContainer>
                            </MenuListNotExpandedButton>
                        )}
                    </MenuListItemContainer>
                )}
                {mappedMenuList}
            </>
        );
    }, [menuItems, externalSchoolClasses, expanded, hasOnlyOneExternalSchoolClass, handleClickExternalSchoolClass, onClose, pathname]);

    const handleHelpCenter = () => {
        openLinkInBlankPage(`https://atendimento.${theme.project.brand}.com.br/`);
    };

    return (
        <MenuListWrapper expanded={expanded}>
            <h3>Menu</h3>

            <ul>
                {renderMenuList}

                <If test={shouldRenderHelpCenter}>
                    <ChatButtonWrapper>
                        <If test={expanded}>
                            <button type="button" onClick={handleHelpCenter}>
                                Central de ajuda
                            </button>
                        </If>

                        <If test={!expanded}>
                            <button type="button">
                                <Question width="22" height="22" onClick={handleHelpCenter} />
                            </button>
                        </If>
                    </ChatButtonWrapper>
                </If>
            </ul>
        </MenuListWrapper>
    );
};

export default memo(MenuList);
