import styled from "styled-components";
import { theme } from "config/theme";
import { BreadcrumbContainer, Item } from "components/BreadCrumb/styles";

export const ExerciseListDashboardHeaderContainer = styled.header`
    background: ${theme.colors.white};
    margin-bottom: ${theme.spacing.medium};
    padding-bottom: ${theme.spacing.small};
`;

export const ExerciseListDashboardHeaderWrapp = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    h1 {
        color: ${theme.colors.brand[300]};
        font-family: "Nunito", sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
    }

    ${BreadcrumbContainer} {
        margin-bottom: ${theme.spacing.smallXX};
    }

    ${Item} {
        :first-child {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const ExerciseListDashboardHeaderContent = styled.div`
    display: flex;
    align-items: center;
    padding-top: ${theme.spacing.medium};
`;

export const ContentButtons = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.small};
`;
