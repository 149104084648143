import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { CardContainerSmallStyle, Micro } from "assets/styles/global";

export const EssaySendedWrapper = styled.div`
    position: relative;

    > img {
        display: block;
        height: auto;
        width: 780px;
    }

    > svg {
        top: 0;
        left: 0;
        position: absolute;
        height: 100%;
        width: 780px;

        svg {
            cursor: pointer;
        }
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        > img {
            width: 100%;
        }

        > svg {
            width: 100%;
        }
    }
`;

export const EssayWrapperCanvas = styled.div`
    background: transparent;
    z-index: 1;
    top: 18px;

    width: 608px;
    height: 698px;
    position: absolute;
`;

export const EssaySendedText = styled.span`
    ${Micro};
    color: ${theme.colors.base[300]};
    display: block;
    margin-top: ${theme.spacing.smallX};
    text-align: center;
    transition: opacity 0.2s ease;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        align-items: center;
        background: ${theme.colors.base[500]};
        border-radius: ${theme.spacing.smallXX};
        color: ${theme.colors.white};
        display: flex;
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
        left: 50%;
        line-height: 22px;
        opacity: 0;
        padding: ${theme.spacing.smallX} ${theme.spacing.small};
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;

        svg {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const EssaySendedContainer = styled.div`
    ${CardContainerSmallStyle};

    padding: 0;
    margin-bottom: ${theme.spacing.medium};
    overflow-x: auto;

    > img {
        display: block;
        height: auto;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        position: relative;

        &:hover {
            ${EssaySendedText} {
                opacity: 0.8;
            }
        }
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        > img {
            // max-width: 740px;
        }

        overflow-x: hidden;
    }
`;

export const EssaySendedErrorWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const EssaySendedErrorText = styled.text`
    margin-left: 1rem;
`;

export const EssaySendedContentText = styled.p`
    white-space: pre-wrap;
    font-family: ${theme.typography.family.primary}, sans-serif;
    font-size: 1rem;
    line-height: 1.7rem;
    text-align: justify;
    padding: 1rem;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 0;
    }
`;
