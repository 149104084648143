// Dependencies
import React, { Fragment, FunctionComponent, useState } from "react";

// Types
import { ActivityExpandableCardProps } from "./activity-expandable-card.types";

// Styles
import { Container, Content, SkeletonWrapper, ActivityCommentButtonIcon } from "./activity-expandable-card.styles";

// Components
import { ActivityTitle } from "components/ActivityTitle";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Skeleton } from "component-library/utilities/skeleton";
import { Spacing } from "component-library/utilities/spacing";
import { ActivityButton } from "components/ActivityButton";

// Utils
import { ternary } from "utils/ternary";

// Assets
import chevronUp from "assets/img/chevron-up.svg";
import chevronDown from "assets/img/chevron-down.svg";

export const ActivityExpandableCard: FunctionComponent<ActivityExpandableCardProps> = ({ isLoading, titleIcon, title, content, characterLimit }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formattedContent = content.split("\n").join("<br/>");

    const hasExceededCharacterLimit = formattedContent.length > characterLimit;

    const contentToDisplay = ternary([
        [hasExceededCharacterLimit && !isExpanded, `${formattedContent.slice(0, characterLimit)}...`],
        [!hasExceededCharacterLimit || isExpanded, formattedContent]
    ]);

    return (
        <Container shouldDisplayExpandableButton={hasExceededCharacterLimit}>
            <ActivityTitle title={title} icon={titleIcon} />

            <ConditionallyRender
                shouldRender={isLoading}
                content={
                    <Fragment>
                        <SkeletonWrapper>
                            <Skeleton height="20px" borderRadius="4px" />

                            <Spacing direction="vertical" size={2} />

                            <Skeleton height="20px" borderRadius="4px" />

                            <Spacing direction="vertical" size={2} />

                            <Skeleton height="20px" borderRadius="4px" />

                            <Spacing direction="vertical" size={2} />

                            <Skeleton height="20px" width="160px" borderRadius="4px" />
                        </SkeletonWrapper>

                        <ConditionallyRender
                            shouldRender={hasExceededCharacterLimit}
                            content={<Skeleton width="180px" height="22px" borderRadius="4px" />}
                        />
                    </Fragment>
                }
            />

            <ConditionallyRender
                shouldRender={!isLoading}
                content={
                    <Fragment>
                        <Content shouldDisplayExpandableButton={hasExceededCharacterLimit} hasEllipsis={!!hasExceededCharacterLimit}>
                            {contentToDisplay}
                        </Content>

                        <ConditionallyRender
                            shouldRender={hasExceededCharacterLimit}
                            content={
                                <ActivityButton
                                    iconElement={<ActivityCommentButtonIcon src={isExpanded ? chevronUp : chevronDown} />}
                                    label={`${isExpanded ? "Minimizar" : "Expandir"} comentário`}
                                    variantType="link"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                />
                            }
                        />
                    </Fragment>
                }
            />
        </Container>
    );
};
