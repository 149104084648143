import { theme } from "config/theme";
import styled from "styled-components/macro";
import { CardContainer } from "components/Card/styles";
import { Micro } from "assets/styles/global";

export const EssayDetailContainer = styled.div`
    ${CardContainer} {
        padding: 0;
    }
`;

export const EssayDetailList = styled.ul`
    margin-bottom: ${theme.spacing.small};
    padding: 0 ${theme.spacing.small};
`;

export const EssayDetailItem = styled.li`
    ${Micro};
    color: ${theme.colors.base[300]};
    list-style: disc;
    list-style-position: inside;

    span {
        margin-left: -${theme.spacing.smallXX};
    }

    strong {
        color: ${theme.colors.base[500]};
        font-weight: normal;
    }

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }
`;
