// Dependencies
import React from "react";

// Types
import { ITeacher } from "interfaces/ITeacher";

// Styles
import { WatchScreenTeachersContainer, WatchScreenTeachersItem, WatchScreenTeachersTitle } from "./styles";

interface IWatchScreenTeachersProps {
    teachers: ITeacher[];
}

const WatchScreenTeachersVersion2 = ({ teachers }: IWatchScreenTeachersProps) => (
    <WatchScreenTeachersContainer>
        <WatchScreenTeachersTitle>Professor{teachers.length > 1 && "es"}</WatchScreenTeachersTitle>
        {teachers.map((teacher) => (
            <WatchScreenTeachersItem key={teacher.id} data-test-id="video-teacher">
                <p data-test-id="video-teacher-name">{teacher.person.name}</p>
            </WatchScreenTeachersItem>
        ))}
    </WatchScreenTeachersContainer>
);

export default WatchScreenTeachersVersion2;
