const DEFAULT_IP = "127.0.0.1";

export async function getUserIp() {
    try {
        const requestIP = await fetch("https://api64.ipify.org/?format=json");
        const { ip = DEFAULT_IP } = await requestIP.json();

        return ip;
    } catch (error) {
        return DEFAULT_IP;
    }
}
