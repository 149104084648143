// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    scrollableWidth?: number;
    formWrapperHeight?: number;
    type: "lesson-plan-custom" | "exercise-list-custom";
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;
    max-width: 100%;
    width: 100%;
    height: 100%;

    ${({ type }) =>
        type === "exercise-list-custom" &&
        css`
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 549px;

            @media (min-width: 768px) and (max-width: 1024px) {
                max-height: 732px;
            }

            @media (min-width: 1200px) {
                height: 100%;
                max-height: 549px;
            }

            @media (min-width: 1366px) {
                height: 300px;
            }
        `}

    .webp-card {
        min-height: 100%;
        padding: 1rem;
    }

    .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        h3 {
            line-height: 1.375;
        }
    }

    .header-container-clean {
        display: flex;
        align-items: center;
        justify-content: space-between;
        all: unset;
        cursor: pointer;

        span {
            color: ${theme.colors.system.danger[300]};
        }
    }

    .description-container {
        text-align: center;
        img {
            margin-bottom: 22px;
        }
    }

    .webp-filter-item {
        margin-top: 1rem;

        &:nth-child(1) {
            margin-top: 0;
        }
    }
`;
