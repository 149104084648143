// Dependencies
import React, { FunctionComponent, useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Services
import api from "services/api";

// Types
import { ActivityEditorProps } from "./activity-editor.types";

// Styles
import {
    Container,
    ErrorMessageWrapper,
    ErrorMessageIcon,
    ErrorMessageLabel,
    WordLimitterLabel,
    InfoMessagesWrapper
} from "./activity-editor.styles";

// Assets
import fail from "assets/img/fail.svg";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityEditor: FunctionComponent<ActivityEditorProps> = ({
    placeholder,
    value,
    errorMessage,
    maxWords,
    handleChange,
    handleExceedWordLimit
}) => {
    const [wordCountData, setWordCountData] = useState(0);
    const [editorData, setEditorData] = useState(value);
    const [hasExceeded, setHasExceeded] = useState(false);

    useEffect(() => {
        setWordCountData(countWords(value ?? ""));
        setEditorData(value);
    }, [value]);

    useEffect(() => {
        if (!!handleExceedWordLimit) {
            handleExceedWordLimit(hasExceeded);
        }
    }, [hasExceeded, handleExceedWordLimit]);

    function uploadAdapter(loader: any) {
        return {
            upload: () => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve, reject) => {
                    try {
                        const file = await loader.file;

                        const formData = new FormData();

                        formData.append("file", file);

                        const response = await api.post<{ fileUrl: string }>("/upload", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        });

                        if (response.data && response.data.fileUrl) {
                            resolve({
                                default: response.data.fileUrl
                            });
                        }
                    } catch (error) {
                        console.error("Erro ao fazer upload do arquivo:", error);
                        reject(error);
                    }
                });
            }
        };
    }

    function uploadPlugin(editor: any) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
            return uploadAdapter(loader);
        };
    }

    function countWords(text: string) {
        const textWithoutHtml = text.replace(/<[^>]*>/g, " ");
        const wordsArray = textWithoutHtml.trim().split(/\s+/);
        return wordsArray.filter((word) => word.length > 0).length;
    }

    const handleEditorChange = (_: any, editor: any) => {
        const data = editor.getData();
        const words = countWords(data);

        setHasExceeded(!!maxWords && words > maxWords ? true : false);

        setWordCountData(words);
        setEditorData(data);

        if (!!handleChange) {
            handleChange(data);
        }
    };

    return (
        <Container>
            <CKEditor
                editor={ClassicEditor}
                data={editorData}
                config={{
                    placeholder,
                    extraPlugins: [uploadPlugin],
                    toolbar: ["bold", "italic", "numberedList", "bulletedList", "link", "imageUpload", "|", "undo", "redo"]
                }}
                onChange={handleEditorChange}
            />

            <InfoMessagesWrapper>
                <ConditionallyRender
                    shouldRender={!!maxWords}
                    content={
                        <WordLimitterLabel hasExceeded={hasExceeded}>
                            Limite de palavras: {wordCountData}/{maxWords}
                        </WordLimitterLabel>
                    }
                />

                <ConditionallyRender
                    shouldRender={!!errorMessage}
                    content={
                        <ErrorMessageWrapper>
                            <ErrorMessageIcon src={fail} />

                            <ErrorMessageLabel>{errorMessage}</ErrorMessageLabel>
                        </ErrorMessageWrapper>
                    }
                />
            </InfoMessagesWrapper>
        </Container>
    );
};
