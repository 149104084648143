import styled from "styled-components";
import { MediaQueries } from "assets/styles/settings";

export const IframeForLegacyContainer = styled.div`
    min-height: 100vh;
    width: 100%;

    ${MediaQueries.SMALL} {
        padding-bottom: 20px;
    }
`;

export const Iframe = styled.iframe`
    min-height: 100vh;
    width: 100%;
`;
