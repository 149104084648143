// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: block;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: ${theme.colors.white};
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    .header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        font-family: "Nunito";
    }

    .image-and-name-wrapper {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .name-user {
        font-weight: 600;
        font-size: 16px;
        color: ${theme.colors.base[400]};
    }

    .time {
        font-size: 14px;
        color: ${theme.colors.base[300]};
    }

    .doubt {
        font-family: "Nunito", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.375;
        white-space: pre-wrap;
        color: ${theme.colors.base[400]};
        text-align: initial;

        p,
        span,
        button,
        b {
            font-size: 16px !important;
        }
    }

    .contentButtonsInteragions {
        display: flex;
        flex-wrap: wrap;
    }

    .contentButtonsAuthor {
        display: flex;
        gap: 27px;
    }

    .buttons {
        font-size: 0.75rem;
        font-weight: 400;
        font-family: "Nunito", sans-serif;
        letter-spacing: 0.01em;
        line-height: 1.333;
        font-style: normal;
        display: flex;
        color: ${theme.colors.base[400]};
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 1px 6px;
    }

    .bell {
        color: ${theme.colors.system.info[400]};
    }

    .svgButton {
        margin-right: 4px;
    }
`;
