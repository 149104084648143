import { createActions, createReducer } from "reduxsauce";
import {
    IDoubtPostState,
    IGetTopicResponse,
    DoubtPostActionsType,
    IDoubtPostActionsCreators,
    IGetPostsResponse,
    IPostResponse,
    IEditPostPayload,
    IDeletePostPayload,
    IGetTopicStatus,
    IResponsePayload,
    IGetPostsResponseVariables,
    IGetUpdatedPostsResponse,
    IPost,
    IDoubtPostStatePostsFormattedItemsToCheckUpdates,
    IGetUpdatedPostsResponseVariables
} from "../../interfaces/IDoubtPost";

export const { Creators, Types } = createActions<Record<DoubtPostActionsType, DoubtPostActionsType>, IDoubtPostActionsCreators>({
    getTopicRequest: ["payload"],
    getTopicSuccess: ["payload"],
    getTopicFailure: ["payload"],

    getPostsRequest: ["payload"],
    getPostsSuccess: ["payload"],
    getPostsFailure: ["payload"],

    getHasNewPostsRequest: ["payload"],
    getHasNewPostsSuccess: ["payload"],
    getHasNewPostsFailure: ["payload"],

    getHasUpdatedPostsRequest: ["payload"],
    getHasUpdatedPostsSuccess: ["payload"],
    getHasUpdatedPostsFailure: ["payload"],

    getUpdatedPostsRequest: ["payload"],
    getUpdatedPostsSuccess: ["payload"],
    getUpdatedPostsFailure: ["payload"],

    getSpecificPostRequest: ["payload"],
    getSpecificPostSuccess: ["payload"],
    getSpecificPostFailure: ["payload"],

    sendPostRequest: ["payload"],
    sendPostSuccess: ["payload"],
    sendPostFailure: ["payload"],

    editPostRequest: ["payload"],
    editPostSuccess: ["payload"],
    editPostFailure: ["payload"],

    deletePostRequest: ["payload"],
    deletePostSuccess: ["payload"],
    deletePostFailure: ["payload"],

    clearStateRequest: []
});

const INITIAL_STATE: IDoubtPostState = {
    topic: {
        isLoading: true,
        data: {
            ...({} as IGetTopicResponse),
            status: IGetTopicStatus.Inactive
        }
    },
    posts: {
        isLoading: true,
        hasMore: true,
        items: [],
        filters: {
            quantity: 10,
            justmine: false
        },
        formattedItemsToCheckUpdates: [],
        deletedItemsCount: 0
    },
    specificPost: {
        isLoading: true,
        hasError: false
    },
    newPostsQuantity: 0,
    postsToUpdate: []
};

interface IAction<T> {
    payload: T;
    type: string;
}

const getTopicRequest = (state = INITIAL_STATE) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: true
    }
});
const getTopicSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IGetTopicResponse, {}>>) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: false,
        data: payload.response
    }
});
const getTopicFailure = (state = INITIAL_STATE) => ({
    ...state,
    topic: {
        ...state.topic,
        isLoading: false
    }
});

const getPostsRequest = (state = INITIAL_STATE) => ({
    ...state,
    posts: {
        ...state.posts,
        isLoading: true
    }
});
const getPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IGetPostsResponse, IGetPostsResponseVariables>>) => {
    const { data, pagination } = payload.response;
    const { justmine, isRestarting, serverDate } = payload.variables;

    const justmineHasChanged = justmine !== state.posts.filters.justmine;
    const mustClearPosts = justmineHasChanged || !!isRestarting;

    const formattedItemsToCheckUpdates = data.map((post) => ({
        IdPost: post.id,
        LastestUpdate: new Date(serverDate).toISOString()
    }));

    return {
        ...state,
        posts: {
            ...state.posts,
            isLoading: false,
            hasMore: pagination.hasMoreItems,
            items: mustClearPosts ? data : [...state.posts.items, ...data],
            filters: {
                ...state.posts.filters,
                justmine,
                bookmark: pagination.bookmark
            },
            formattedItemsToCheckUpdates: mustClearPosts
                ? formattedItemsToCheckUpdates
                : [...state.posts.formattedItemsToCheckUpdates, ...formattedItemsToCheckUpdates],
            ...(mustClearPosts && { deletedItemsCount: 0 })
        },
        ...(!!isRestarting && { newPostsQuantity: 0, postsToUpdate: [] })
    };
};
const getPostsFailure = (state = INITIAL_STATE) => ({
    ...state,
    posts: {
        ...state.posts,
        isLoading: false
    }
});

const getHasNewPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getHasNewPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IGetPostsResponse, {}>>) => {
    // let newPosts = payload.response.data;

    // if (state.posts.items.length < 10 && state.posts.deletedItemsCount > 0) {
    //     newPosts = newPosts.slice(0, 10 - state.posts.deletedItemsCount);
    // }

    // newPosts = newPosts.filter((newPostItem) => {
    //     return !state.posts.items.find((postItem) => postItem.id === newPostItem.id);
    // });

    let newPostsQuantity = 0;

    payload.response.data.forEach((newPostItem, index) => {
        const isNew = !state.posts.items.find((postItem) => postItem.id === newPostItem.id);

        if (isNew && newPostsQuantity === index) {
            newPostsQuantity = newPostsQuantity + 1;
        }
    });

    return {
        ...state,
        newPostsQuantity
        // newPostsQuantity: newPosts.length
    };
};
const getHasNewPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const getHasUpdatedPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getHasUpdatedPostsSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IGetUpdatedPostsResponse[], {}>>) => {
    let hasDeletedItems: boolean = false;

    const filteredItems = state.posts.items.reduce((accumulator, postItem) => {
        const isActiveItem = !!payload.response.find((postToUpdateItem) => postToUpdateItem.idPost === postItem.id);

        if (isActiveItem) {
            return [...accumulator, postItem];
        }

        hasDeletedItems = true;

        return accumulator;
    }, [] as IPost[]);

    const filteredFormattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.reduce((accumulator, formattedItemsToCheckUpdatesItem) => {
        const isActiveItem = !!payload.response.find((postToUpdateItem) => postToUpdateItem.idPost === formattedItemsToCheckUpdatesItem.IdPost);

        if (isActiveItem) {
            return [...accumulator, formattedItemsToCheckUpdatesItem];
        }

        return accumulator;
    }, [] as IDoubtPostStatePostsFormattedItemsToCheckUpdates[]);

    const postsToUpdate = payload.response.reduce((accumulator, post) => {
        if (post.hasChanges) {
            return [...accumulator, post.idPost];
        }

        return accumulator;
    }, [] as string[]);

    return {
        ...state,
        ...((!!hasDeletedItems as any) && {
            posts: {
                ...state.posts,
                filters: {
                    ...state.posts.filters,
                    bookmark: filteredItems[filteredItems.length - 1]?.id ?? undefined
                },
                items: filteredItems,
                formattedItemsToCheckUpdates: filteredFormattedItemsToCheckUpdates
            }
        }),
        postsToUpdate
    };
};
const getHasUpdatedPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const getUpdatedPostsRequest = (state = INITIAL_STATE) => ({ ...state });
const getUpdatedPostsSuccess = (
    state = INITIAL_STATE,
    { payload }: IAction<IResponsePayload<IPostResponse[], IGetUpdatedPostsResponseVariables>>
) => {
    const { serverDate } = payload.variables;

    const items = state.posts.items.reduce((accumulator, postItem) => {
        const updatedPost = payload.response.find((updatedPostItem) => updatedPostItem.id === postItem.id);

        if (!!updatedPost) {
            return [...accumulator, updatedPost];
        }

        return [...accumulator, postItem];
    }, [] as IPost[]);

    const formattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.reduce((accumulator, formattedItemsToCheckUpdatesItem) => {
        const updatedPost = payload.response.find((updatedPostItem) => updatedPostItem.id === formattedItemsToCheckUpdatesItem.IdPost);

        if (!!updatedPost) {
            return [
                ...accumulator,
                {
                    IdPost: updatedPost.id,
                    LastestUpdate: new Date(serverDate).toISOString()
                }
            ];
        }

        return [...accumulator, formattedItemsToCheckUpdatesItem];
    }, [] as IDoubtPostStatePostsFormattedItemsToCheckUpdates[]);

    return {
        ...state,
        posts: {
            ...state.posts,
            items,
            formattedItemsToCheckUpdates
        },
        postsToUpdate: []
    };
};
const getUpdatedPostsFailure = (state = INITIAL_STATE) => ({ ...state });

const getSpecificPostRequest = (state = INITIAL_STATE) => ({
    ...state,
    specificPost: {
        ...state.specificPost,
        isLoading: true
    }
});
const getSpecificPostSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostResponse, {}>>) => ({
    ...state,
    specificPost: {
        ...state.specificPost,
        isLoading: false,
        ...(!payload.response?.id && { hasError: true }),
        ...(!!payload.response?.id && { data: payload.response })
    }
});
const getSpecificPostFailure = (state = INITIAL_STATE) => ({
    ...state,
    specificPost: {
        ...state.specificPost,
        isLoading: false
    }
});

const sendPostRequest = (state = INITIAL_STATE) => ({ ...state });
const sendPostSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<IPostResponse, {}>>) => ({
    ...state,
    posts: {
        ...state.posts,
        items: [payload.response, ...state.posts.items],
        formattedItemsToCheckUpdates: [
            {
                IdPost: payload.response.id,
                LastestUpdate: payload.response.createdAt
            },
            ...state.posts.formattedItemsToCheckUpdates
        ]
    }
});
const sendPostFailure = (state = INITIAL_STATE) => ({ ...state });

const editPostRequest = (state = INITIAL_STATE) => ({ ...state });
const editPostSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IEditPostPayload>>) => {
    const { postId, postMessage } = payload.variables;

    const formattedPosts = state.posts.items.map((item) => {
        if (item.id === postId) {
            return {
                ...item,
                content: postMessage
            };
        }

        return item;
    });

    return {
        ...state,
        posts: {
            ...state.posts,
            items: formattedPosts
        }
    };
};
const editPostFailure = (state = INITIAL_STATE) => ({ ...state });

const deletePostRequest = (state = INITIAL_STATE) => ({ ...state });
const deletePostSuccess = (state = INITIAL_STATE, { payload }: IAction<IResponsePayload<{}, IDeletePostPayload>>) => {
    const { postId } = payload.variables;

    let bookmark: string | undefined = state.posts.filters.bookmark;

    const lastPostId = state.posts.items[state.posts.items.length - 1].id;

    if (postId === lastPostId && state.posts.items.length === 1) {
        bookmark = undefined;
    }

    if (postId === lastPostId && state.posts.items.length > 1) {
        const penultimatePostId = state.posts.items[state.posts.items.length - 2].id;

        bookmark = penultimatePostId;
    }

    const filteredPosts = state.posts.items.filter((item) => item.id !== postId);
    const filteredFormattedItemsToCheckUpdates = state.posts.formattedItemsToCheckUpdates.filter((item) => item.IdPost !== postId);
    const filteredPostsToUpdate = state.postsToUpdate.filter((item) => item !== postId);

    return {
        ...state,
        posts: {
            ...state.posts,
            items: filteredPosts,
            formattedItemsToCheckUpdates: filteredFormattedItemsToCheckUpdates,
            filters: {
                ...state.posts.filters,
                bookmark
            },
            deletedItemsCount: state.posts.deletedItemsCount + 1
        },
        postsToUpdate: filteredPostsToUpdate
    };
};
const deletePostFailure = (state = INITIAL_STATE) => ({ ...state });

const clearStateRequest = () => INITIAL_STATE;

const REDUCERS_CREATE = {
    [Types.GET_TOPIC_REQUEST]: getTopicRequest,
    [Types.GET_TOPIC_SUCCESS]: getTopicSuccess,
    [Types.GET_TOPIC_FAILURE]: getTopicFailure,

    [Types.GET_POSTS_REQUEST]: getPostsRequest,
    [Types.GET_POSTS_SUCCESS]: getPostsSuccess,
    [Types.GET_POSTS_FAILURE]: getPostsFailure,

    [Types.GET_HAS_NEW_POSTS_REQUEST]: getHasNewPostsRequest,
    [Types.GET_HAS_NEW_POSTS_SUCCESS]: getHasNewPostsSuccess,
    [Types.GET_HAS_NEW_POSTS_FAILURE]: getHasNewPostsFailure,

    [Types.GET_HAS_UPDATED_POSTS_REQUEST]: getHasUpdatedPostsRequest,
    [Types.GET_HAS_UPDATED_POSTS_SUCCESS]: getHasUpdatedPostsSuccess,
    [Types.GET_HAS_UPDATED_POSTS_FAILURE]: getHasUpdatedPostsFailure,

    [Types.GET_UPDATED_POSTS_REQUEST]: getUpdatedPostsRequest,
    [Types.GET_UPDATED_POSTS_SUCCESS]: getUpdatedPostsSuccess,
    [Types.GET_UPDATED_POSTS_FAILURE]: getUpdatedPostsFailure,

    [Types.GET_SPECIFIC_POST_REQUEST]: getSpecificPostRequest,
    [Types.GET_SPECIFIC_POST_SUCCESS]: getSpecificPostSuccess,
    [Types.GET_SPECIFIC_POST_FAILURE]: getSpecificPostFailure,

    [Types.SEND_POST_REQUEST]: sendPostRequest,
    [Types.SEND_POST_SUCCESS]: sendPostSuccess,
    [Types.SEND_POST_FAILURE]: sendPostFailure,

    [Types.EDIT_POST_REQUEST]: editPostRequest,
    [Types.EDIT_POST_SUCCESS]: editPostSuccess,
    [Types.EDIT_POST_FAILURE]: editPostFailure,

    [Types.DELETE_POST_REQUEST]: deletePostRequest,
    [Types.DELETE_POST_SUCCESS]: deletePostSuccess,
    [Types.DELETE_POST_FAILURE]: deletePostFailure,

    [Types.CLEAR_STATE_REQUEST]: clearStateRequest
};

export default createReducer(INITIAL_STATE, REDUCERS_CREATE);
