import styled from "styled-components";
import { TitleH3 } from "assets/styles/global";
import { theme } from "config/theme";

export const FilesContainer = styled.div`
    height: 100%;
    padding: 32px 0 24px;
`;

export const FilesHeader = styled.header`
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;
`;

export const FilesTitle = styled.h2`
    ${TitleH3};
    color: ${theme.colors.base[500]};
`;

export const FilesGetMore = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.large} 0;
    width: 100%;
`;

export const FilesEmpty = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.spacing.large} 0;
    width: 100%;

    svg {
        margin-bottom: ${theme.spacing.small};
    }

    strong {
        color: ${theme.colors.base[400]};
    }
`;
