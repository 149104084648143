// Dependencies
import styled from "styled-components";

// Uitls
import { theme } from "config/theme";

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 24px;
    border: 2px dashed ${theme.colors.brand[300]};

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

export const Title = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: #4d4d63;
    text-align: center;
`;

export const Description = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #4d4d63;
    text-align: center;
`;
