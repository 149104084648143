import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

// Dependencies
import { object, number, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

// Components
import Button from "components/Button";
import Select from "components/Select";
import Editor from "components/Form/Editor";
import PageHeader from "components/PageHeader";
import { Grid, Row, Col } from "components/Grid";
import AttachmentsController from "../components/attachments-controller";
import DropdownIndicator from "screens/MigrationUser/components/indicator";

// Helpers
import { IForumUpload } from "store/interfaces/IForum";
import { ISelectOption } from "interfaces/ISelectOption";
import { isCourseFree } from "helpers/isCourseFree";

// Assets
import * as F from "./styles";
import { ErrorMessage } from "assets/styles/global";

export interface IForumNewPayload {
    content: string;
    subject?: number;
    id?: string;
    attachments?: string[];
}

interface IForumNewProps {
    attachments: IForumUpload[];
    subjects: ISelectOption[];
    onAttachFile: () => void;
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
    onSubmit: (data: IForumNewPayload) => void;
}

const schema = object({
    subject: object({ idExternal: number(), name: string() }).required("Campo obrigatório"),
    content: string()
        .min(17, "A pergunta precisa ter mais de 10 caracteres")
        .required("Campo obrigatório")
});

const ForumNew = ({ attachments, subjects, onAttachFile, onRemoveAttachment, onRetryUpload, onSubmit }: IForumNewProps) => {
    const { control, handleSubmit, errors, formState } = useForm<IForumNewPayload>({
        mode: "all",
        resolver: yupResolver(schema)
    });

    const { courseSlug } = useSelector(({ course }: IReduxStore) => ({ courseSlug: course?.slug }));
    const hasCourseFree = isCourseFree(courseSlug);

    const handleEditorChange = useCallback(
        (onChange: any) => (event: any, editor: any) => {
            const data = editor.getData();

            onChange(data);
        },
        []
    );

    return (
        <>
            <PageHeader.Complex
                title="Nova dúvida"
                backTo={{
                    path: `/app/curso/${courseSlug}/forum`,
                    label: "Fórum"
                }}
                buttonNew={
                    <Button disabled={!formState.isValid || hasCourseFree} size="medium" variant="primary" onClick={handleSubmit(onSubmit)}>
                        Publicar
                    </Button>
                }
            />
            <F.ForumNewContainer>
                <Grid>
                    <Row>
                        <Col xs={12}>
                            <F.ForumNewContent>
                                <F.ForumNewFieldWrapper>
                                    <Controller
                                        control={control}
                                        defaultValue=""
                                        name="subject"
                                        render={({ onChange, value }) => (
                                            <Select
                                                isDisabled={hasCourseFree}
                                                components={{ DropdownIndicator }}
                                                error={errors?.subject?.message}
                                                label="Disciplina"
                                                placeholder="Selecione uma disciplina"
                                                options={subjects}
                                                onChange={(item: ISelectOption<number>) => onChange({ idExternal: item.value, name: item.label })}
                                            />
                                        )}
                                    />
                                </F.ForumNewFieldWrapper>
                            </F.ForumNewContent>
                            <F.ForumNewContent>
                                <F.ForumNewFieldWrapper>
                                    <F.ForumNewFieldLabel>Digite aqui sua dúvida</F.ForumNewFieldLabel>
                                    <F.ForumNewEditorWrapper>
                                        <Controller
                                            control={control}
                                            defaultValue=""
                                            name="content"
                                            render={({ onChange }) => (
                                                <Editor
                                                    disabled={hasCourseFree}
                                                    onChange={handleEditorChange(onChange)}
                                                    isPreventDefault={false}
                                                    isPasteAsPlainText={false}
                                                />
                                            )}
                                        />
                                        {errors?.content?.message && <ErrorMessage>{errors.content.message}</ErrorMessage>}
                                    </F.ForumNewEditorWrapper>
                                    <F.ForumNewAttachmentWrapper>
                                        <Button disabled={hasCourseFree} size="medium" variant="outline-primary" onClick={onAttachFile}>
                                            Adicionar anexo
                                        </Button>
                                        {Boolean(attachments.length) && (
                                            <AttachmentsController
                                                attachments={attachments}
                                                onRemoveAttachment={onRemoveAttachment}
                                                onRetryUpload={onRetryUpload}
                                            />
                                        )}
                                    </F.ForumNewAttachmentWrapper>
                                </F.ForumNewFieldWrapper>
                            </F.ForumNewContent>
                        </Col>
                    </Row>
                </Grid>
            </F.ForumNewContainer>
        </>
    );
};

export default memo(ForumNew);
