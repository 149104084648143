import { BodyText1 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";
import { SkeletonItem } from "components/Skeleton/styles";

export const SubjectGroupContainer = styled.div`
    margin-left: -${theme.spacing.smallX};
    padding-left: ${theme.spacing.small};
    width: calc(100% + ${theme.spacing.small});

    &:not(:last-child) {
        margin-bottom: 20px;
    }
`;

export const SubjectGroupTitle = styled.div`
    display: flex;
    margin-bottom: ${theme.spacing.smallX};

    &::before {
        content: "";
        background-color: ${theme.colors.brand[300]};
        width: 4px;
        border-radius: 4px;
    }

    strong {
        padding-left: ${theme.spacing.smallX};
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.mediumX};
        font-weight: 600;
        line-height: 26px;
        font-family: "Nunito", sans-serif;
    }
`;

export const SubjectGroupCards = styled.div`
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    margin-bottom: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        overflow: visible;
        flex-wrap: wrap;
    }
`;

export const SubjectGroupCard = styled.div`
    margin-right: ${theme.spacing.smallX};

    &:last-child {
        padding-right: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-bottom: ${theme.spacing.smallX};
    }
`;

export const Title = styled.h1`
    ${BodyText1}
`;

export const SubjectCardSkeleton = styled(SkeletonItem)`
    width: 172px;
    /* margin-right: ${theme.spacing.smallX}; */
    position: relative;
    height: 114px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 193px;
        height: 139px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 210px;
        height: 148px;
    }
`;
