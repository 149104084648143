import { theme } from "config/theme";
import MaskedInput from "react-text-mask";
import styled, { css } from "styled-components/macro";
import { Micro, BodyText2 } from "assets/styles/global";

const errorStylesheet = css`
    border-color: ${theme.colors.system.danger[300]} !important;

    label {
        color: ${theme.colors.system.danger[200]} !important;
        border-bottom: 1px solid #fff !important;
    }
`;

const successStylesheet = css`
    border-color: ${theme.colors.system.success[300]} !important;

    label {
        color: ${theme.colors.system.danger[300]} !important;
        border-bottom: 1px solid #fff !important;
    }
`;

const disabledStylesheet = css`
    background-color: ${theme.colors.base[100]} !important;
    border-color: ${theme.colors.base[100]} !important;
`;

export const TextFieldLabel = styled.label<{ success?: string; error?: string }>`
    ${BodyText2}
    line-height: 20px;
    color: ${theme.colors.base[300]};
    position: absolute;
    left: ${theme.spacing.small};
    top: 50%;
    margin-top: -9px;
    transition: all 0.15s ease-in;
    padding-left: 3px;
    padding-right: 3px;
    z-index: 2;

    ${({ error }) => error && errorStylesheet}
    ${({ success }) => success && successStylesheet}
`;

export const TextFieldWrapper = styled.div<{ disabled: boolean; success?: string; error?: string }>`
    background: ${theme.colors.white};
    border: 1px solid #dddddf;
    border-radius: 5px;
    position: relative;
    transition: border 0.2s ease;
    width: 100%;

    &:hover {
        border-color: ${theme.colors.base[400]};
    }

    &:focus {
        border-color: ${theme.colors.system.info[400]} !important;

        ${TextFieldLabel} {
            color: ${theme.colors.system.info[400]};
            border-bottom: 1px solid #fff !important;
        }
    }

    ${({ disabled }) => disabled && disabledStylesheet};
    ${({ success }) => success && successStylesheet};
    ${({ error }) => error && errorStylesheet};
`;

export const inputStylesheet = css<{ success?: string; error?: string }>`
    background: transparent;
    border: none;
    border-radius: 5px;
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.smallX};
    outline: none;
    padding: ${theme.spacing.small} ${theme.spacing.smallX} ${theme.spacing.small} ${theme.spacing.small};
    position: relative;
    width: 100%;
    z-index: 3;

    &::placeholder {
        color: ${theme.colors.base[200]};
    }

    &:focus,
    &:not(:placeholder-shown) {
        & + ${TextFieldLabel} {
            ${Micro};
            font-size: 12px !important;
            line-height: ${theme.spacing.small};
            letter-spacing: 0.01em;
            top: -5px;
            left: ${theme.spacing.small};
            height: 14px;
            border-bottom: 1px solid #fff;
        }
    }

    ${({ error }) => error && errorStylesheet};
    ${({ success }) => success && successStylesheet};
`;

export const TextFieldInput = styled.input<{ success?: string; error?: string }>`
    ${inputStylesheet};
`;

export const MaskedFieldInput = styled(MaskedInput)<{ success?: string; error?: string }>`
    ${inputStylesheet};
`;

export const TextFieldContainerTooltip = styled.div<{ isPasswordIcon?: boolean }>`
    position: absolute;
    top: ${({ isPasswordIcon }) => (isPasswordIcon ? "50%" : "0")};
    right: ${({ isPasswordIcon }) => (isPasswordIcon ? "8px" : "0")};
    transform: ${({ isPasswordIcon }) => (isPasswordIcon ? "translateY(-50%)" : "none")};
    z-index: 4;
    cursor: pointer;

    svg {
        display: block;
    }
`;

export const TogglePasswordButton = styled.button`
    background: transparent;
    border: none;
`;
