// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

export const ColorVariants = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    OUTLINE_PRIMARY: "outline-primary",
    OUTLINE_SECONDARY: "outline-secondary",
    OUTLINE_INFO: "outline-info"
} as const;
export type ColorVariant = typeof ColorVariants[keyof typeof ColorVariants];

type WebpButtonProps = {
    id?: string;
    className?: string;
    active?: boolean;
    disabled?: boolean;
    activeClickActive?: boolean;
    isFullyAdaptative?: boolean;
    variant: ColorVariant;
    onClick?: (e: any) => void;
    contentButtonComposition?: ReactNode;
    type?: "submit" | "reset" | "button";
    size?: "small" | "medium" | "large" | "full";
};

export const WebpButton: FunctionComponent<WebpButtonProps> = ({
    active = false,
    disabled,
    size = "small",
    type = "button",
    variant = "primary",
    contentButtonComposition,
    onClick,
    isFullyAdaptative = true,
    className,
    id
}) => {
    return (
        <Container
            id={id}
            className={className}
            buttonSize={size}
            buttonVariant={variant}
            isActive={active}
            type={type}
            isDisabled={!!disabled}
            onClick={(event) => !!onClick && onClick(event)}
            isFullyAdaptative={isFullyAdaptative}
        >
            {contentButtonComposition}
        </Container>
    );
};
