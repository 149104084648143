import React, { forwardRef, memo, useCallback } from "react";

// Dependencies
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// Helpers
import config from "./utils/config";

interface IEditorProps {
    id: string;
    data: string;
    onError: any;
    disabled: boolean;
    onChange: (event: any, editor: any) => void;
    onBlur: (event: any, editor: any) => void;
    onFocus: (event: any, editor: any) => void;
    onKeyboardEvent?: (status: boolean) => void;
    isPreventDefault?: boolean;
    isPasteAsPlainText?: boolean;
}

const Editor = forwardRef<any, Partial<IEditorProps>>((props, ref) => {
    const hasPreventDefault = props?.isPreventDefault ?? true;
    const hasPasteAsPlainText = props?.isPasteAsPlainText ?? true;

    const callKeyboardEvent = useCallback(() => {
        if (typeof props?.onKeyboardEvent === "function") {
            props?.onKeyboardEvent(true);
        }
    }, [props]);

    const handleReady = useCallback(
        (editor: any) => {
            editor?.keystrokes.set("Ctrl+V", (data: any, cancel: any) => {
                hasPasteAsPlainText && cancel();
                callKeyboardEvent();
            });
        },
        [hasPasteAsPlainText, callKeyboardEvent]
    );

    const handleRightClick = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            hasPreventDefault && event.preventDefault();
            callKeyboardEvent();
        },
        [hasPreventDefault, callKeyboardEvent]
    );

    return (
        <div onContextMenu={handleRightClick}>
            <CKEditor config={config} editor={ClassicEditor} onReady={handleReady} ref={ref} {...props} />
        </div>
    );
});

export default memo(Editor);
