import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExercisePlatformChartWrap = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-family: "Nunito Sans", sans-serif;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 20px;
    }
`;

export const ExercisePlatformCharDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    small {
        font-family: "Nunito Sans", sans-serif;
        font-size: ${theme.typography.sizes.small};
        line-height: 16px;
        color: ${theme.colors.base[300]};
        margin-top: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        small {
            font-size: ${theme.typography.sizes.smallX};
        }
    }
`;

export const ExercisePlatformChartDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${theme.colors.base[100]};

    margin: 4px 0 24px;
`;

export const ExercisePlatformCharData = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .VictoryContainer {
        max-height: 150px !important;
        max-width: 150px !important;
    }

    span {
        font-family: "Nunito Sans", sans-serif;
        font-size: ${theme.typography.sizes.small};
        color: ${theme.colors.base[400]};
        text-align: center;
        margin-top: -3px;
        position: absolute;
        z-index: 1;

        strong {
            font-family: "Nunito", sans-serif;
            font-size: ${theme.typography.sizes.large};
            color: ${theme.colors.base[400]};
            text-align: center;
        }
    }
`;

export const ExercisePlatformChartLegend = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

export const ExercisePlatformChartItem = styled.li`
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    font-size: ${theme.typography.sizes.smallX};
    line-height: 22px;
    color: ${theme.colors.base[300]};

    padding: 8px 0 4px;
`;
