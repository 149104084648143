import { theme } from "config/theme";
import styled from "styled-components/macro";
import { BodyText2 } from "assets/styles/global";

export const ComplaintContainer = styled.div`
    align-items: center;
    display: flex;

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ComplaintText = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[300]};
    font-style: italic;
`;
