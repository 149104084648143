import { takeLatest, put, call, select } from "redux-saga/effects";
import { Types as lessonFreeTypes, Creators as lessonFreeActions } from "store/ducks/lessonFreeCTA";

const getCourseSlug = (state: any) => state.course.slug;

export function* getLessonFreeCTA(action: any) {
    try {
        const courseSlug = yield select(getCourseSlug);
        const response = yield call(fetch, `https://files.prodigioeducacao.com/prices/${courseSlug}.json`);
        const priceData = yield response.json();

        const payload = {
            content: {
                cta: priceData.CTA,
                bancoQuestoes: priceData.BancoQuestoes,
                modulo: priceData.Modulo,
                redacoes: priceData.Redacoes,
                duvidas: priceData?.Duvidas ? priceData.Duvidas : ""
            }
        };

        yield put(lessonFreeActions.getLessonFreeSuccess(payload));
    } catch (error) {
        yield put(lessonFreeActions.getLessonFreeFailure());
    }
}

export default [takeLatest(lessonFreeTypes.GET_LESSON_FREE_REQUEST, getLessonFreeCTA)];
