import { DateTime } from "luxon";
import React, { memo } from "react";
import { IBookletContentPerTypeItem } from "store/interfaces/IBookletModule";
import {
    SubjectScreenDetailsContentPerTypeAccordionIcon,
    SubjectScreenDetailsContentPerTypeItemButton,
    SubjectScreenDetailsContentPerTypeItemEventDate,
    SubjectScreenDetailsContentPerTypeItemLink
} from "./styles";

interface IProps {
    item: IBookletContentPerTypeItem;
    onClick(item: IBookletContentPerTypeItem): void;
}

const SubjectScreenDetailsContentPerTypeItem = memo(({ item, onClick }: IProps) => {
    const isMaterialLink = !!item?.material?.url;

    if (isMaterialLink) {
        return (
            <SubjectScreenDetailsContentPerTypeItemLink onClick={() => onClick(item)}>
                <SubjectScreenDetailsContentPerTypeAccordionIcon active={!!item?.done} />

                <strong>{item?.material?.title}</strong>
            </SubjectScreenDetailsContentPerTypeItemLink>
        );
    }

    return (
        <SubjectScreenDetailsContentPerTypeItemButton onClick={() => onClick(item)}>
            <SubjectScreenDetailsContentPerTypeAccordionIcon active={!!item?.done} />
            <p>
                {!!item?.event?.endDate && (
                    <SubjectScreenDetailsContentPerTypeItemEventDate>
                        {DateTime.fromISO(item.event.endDate).isValid && DateTime.fromISO(item.event.endDate).toFormat("dd/LL/yyyy - HH:mm")}
                    </SubjectScreenDetailsContentPerTypeItemEventDate>
                )}
                <strong>{item?.exerciseList?.title || item?.material?.title || item?.video?.title || item?.event?.title || "-"}</strong>
            </p>
        </SubjectScreenDetailsContentPerTypeItemButton>
    );
});

export default SubjectScreenDetailsContentPerTypeItem;
