import styled, { keyframes } from "styled-components/macro";
import { fadein } from "assets/styles/animations";
import GabaritoIcon from "prodigio-icons/web/filled/Gabarito";

const lineAnimation = (percentage: number, percentageRemaining: number) => keyframes`
    0% {
        stroke-dasharray: 0, 100;
    }

    100% {
        stroke-dasharray: ${percentage}, ${percentageRemaining};
    }
`;

export const SubjectScreenDetailsDonutsCompleted = styled(GabaritoIcon)``;

export const SubjectScreenDetailsDonutsContainer = styled.svg`
    animation: ${fadein} 1s;
`;

export const SubjectScreenDetailsDonutsHole = styled.circle``;

export const SubjectScreenDetailsDonutsRing = styled.circle<{ color: string }>`
    stroke: ${({ color }) => color};
    stroke-width: 2;
`;

export const SubjectScreenDetailsDonutsLine = styled.circle<{ color: string; percentage: number; percentageRemaining: number }>`
    transform-origin: center;
    stroke: ${({ color }) => color};
    animation: ${({ percentage, percentageRemaining }) => lineAnimation(percentage, percentageRemaining)} 1.5s;
    stroke-width: 4;
`;

export const SubjectScreenDetailsDonutsPercent = styled.tspan`
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
    animation: ${fadein} 2s;
`;

export const SubjectScreenDetailsDonutsText = styled.g`
    font-family: Arial, Helvetica, sans-serif;
    fill: #25272d;
`;
