import React, { memo } from "react";
import { SubjectScreenDetailsModulesContainer } from "./styles";

import * as S from "../Module/styles";
import ProgressBar from "components/ProgressBar";
import { theme } from "config/theme";
import Skeleton from "components/Skeleton";

const SubjectScreenDetailsModulesSkeleton = memo(() => {
    return (
        <SubjectScreenDetailsModulesContainer>
            {[0, 1, 2].map((n) => (
                <S.SubjectScreenDetailsModuleContainer key={n} onClick={() => null} ref={null} active={n === 0} isStarted={true}>
                    <S.SubjectScreenDetailsModuleBubble variant="base" />

                    <S.SubjectScreenDetailsModuleCard>
                        <S.SubjectScreenDetailsModuleCardTitle>
                            <strong>
                                <Skeleton width={`${50 + Math.floor(Math.random() * 30)}%`} height="20px" />
                            </strong>

                            {n !== 0 && <Skeleton width="16px" height="10px" />}
                        </S.SubjectScreenDetailsModuleCardTitle>

                        <S.SubjectScreenDetailsModuleCardProgress>
                            <ProgressBar color={theme.colors.base[200]} value={30 + Math.floor(Math.random() * 30)} responsive />

                            <S.SubjectScreenDetailsModuleCardProgressLabel style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton width="15px" height="17px" style={{ marginRight: "8px" }} />
                                <Skeleton width="45px" height="17px" />
                            </S.SubjectScreenDetailsModuleCardProgressLabel>
                        </S.SubjectScreenDetailsModuleCardProgress>
                    </S.SubjectScreenDetailsModuleCard>
                </S.SubjectScreenDetailsModuleContainer>
            ))}
        </SubjectScreenDetailsModulesContainer>
    );
});

export default SubjectScreenDetailsModulesSkeleton;
