// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const ActivityResultContentWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 16px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_HUGE} {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: "result result result additional";
        grid-gap: 32px;

        > .result {
            grid-area: result;
        }

        > .additional-content {
            grid-area: additional;
        }
    }
`;

export const ActivityResultDefaultCard = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;

    padding: 16px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

    @media (min-width: 1024px) {
        gap: 24px;
        padding: 24px;
    }

    @media (min-width: 1440px) {
        width: 336px;
        flex-shrink: 0;
    }
`;

export const ActivityDetailsTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    color: #000000;
`;

export const ActivityDetailsIndicatorLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #83899e;

    ${MediaQueries.BIGGER_THAN_HUGE} {
        color: #000000;
    }
`;

export const ActivityDetailsLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #000000;
`;

export const ActivityDetailsLabelWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0px;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
`;

export const ActivityDetailsSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f0f1f7;
`;

export const ActivityDetailsInfoWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
`;

export const ActivityResultCurrentStudentContentWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
`;

// export const ActivityStudentFilterInputIcon = styled.img`
//     width: 16px;
//     height: 16px;
//     flex-shrink: 0;
// `;

// export const ActivityStudentFilterIcon = styled.img`
//     width: 24px;
//     height: 24px;
//     flex-shrink: 0;
// `;

export const ActivityStudentTableFilterCompositionWrapper = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 1024px) {
        width: unset;
    }
`;

export const ActivityStudentTableTitleWrapper = styled.div`
    width: 100%;

    display: flex;
    gap: 16px;
    align-items: center;
    flex-wrap: wrap;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        justify-content: space-between;
        flex-direction: row;
        gap: 24px;
        flex-wrap: nowrap;
    }
`;

export const ActivityStudentTableTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #232326;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 100%;
    }
`;

export const ActivityStudentTableContentWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ActivityStudentTableDefaultTextWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export const ActivityStudentTableActionButtonIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

type ActivityStudentTableDefaultTextStyleProps = {
    color?: string;
    clampLines?: number;
};

export const ActivityStudentTableDefaultText = styled.p<ActivityStudentTableDefaultTextStyleProps>`
    display: flex;
    align-items: center;
    gap: 4px;

    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;

    ${({ color }) =>
        !!color &&
        css`
            color: ${color};
        `}

    ${({ color }) =>
        !color &&
        css`
            color: #232326;
        `}

    ${({ clampLines }) =>
        !!clampLines &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${clampLines};
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 140px;
        `}

    ${({ clampLines }) =>
        !clampLines &&
        css`
            white-space: nowrap;
        `}    
`;

// export const ActivityFilterBottomSheetWrapper = styled.div`
//     width: 100%;

//     position: relative;
// `;

// export const ActivityFilterBottomSheetFixedContent = styled.div`
//     width: 100%;

//     display: flex;
//     flex-direction: column;
//     gap: 16px;
//     align-items: flex-start;
// `;

// type ActivityFilterBottomSheetContentWrapperStyleProps = {
//     hasPaddingBottom?: boolean;
// };

// export const ActivityFilterBottomSheetContentWrapper = styled.div<ActivityFilterBottomSheetContentWrapperStyleProps>`
//     width: 100%;

//     display: flex;
//     flex-direction: column;
//     gap: 16px;
//     align-items: flex-start;
//     padding-top: 24px;

//     ${({ hasPaddingBottom }) =>
//         !!hasPaddingBottom &&
//         css`
//             padding-bottom: 24px;
//         `}

//     @media (min-width: 1024px) {
//         padding: 0;
//     }
// `;

// export const ActivityFilterBottomSheetContentTitle = styled.p`
//     font-family: "Nunito", sans-serif;
//     font-size: 18px;
//     font-weight: 600;
//     line-height: 26px;
//     letter-spacing: 0em;
//     text-align: left;
//     color: #232326;
// `;

// export const ActivityFilterBottomSheetStatusChipsWrapper = styled.div`
//     width: 100%;

//     display: flex;
//     gap: 16px;
//     align-items: center;
//     flex: 1 0 0;
//     flex-wrap: wrap;
// `;

// export const ActivitFilterBottomSheetClassCheckboxsWrapper = styled.div`
//     width: 100%;

//     display: flex;
//     align-items: flex-start;
//     flex-direction: column;
//     gap: 24px;
// `;

// export const ActivityFilterButtonsWrapper = styled.div`
//     width: 100%;
//     padding: 16px;
//     border-top: 1px solid #ced0dd;
//     background: #fff;

//     display: flex;
//     flex-direction: column;
//     gap: 8px;

//     position: sticky;
//     bottom: 0;

//     ${MediaQueries.BIGGER_THAN_MEDIUM} {
//         position: static;

//         flex-direction: row;
//         gap: 16px;
//         border: none;
//         padding: 0;
//     }
// `;

// export const ActivityResultFilterOpenedContentCard = styled.div`
//     width: 360px;

//     display: flex;
//     flex-direction: column;
//     gap: 24px;
//     align-items: flex-start;

//     padding: 24px;
//     border-radius: 4px;
//     background-color: #ffffff;

//     box-shadow: 0px 10px 24px 0px #0000001a;
// `;

// export const ActivityResultFilterTitleWrapper = styled.div`
//     width: 100%;

//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     gap: 24px;
// `;

// export const ActivityResultFilterTitle = styled.p`
//     flex: 1;
//     font-family: "Nunito", sans-serif;
//     font-size: 18px;
//     font-weight: 600;
//     line-height: 26px;
//     letter-spacing: 0em;
//     color: #000000;
// `;

// export const ActivityResultFilterCloseIcon = styled.img`
//     width: 24px;
//     height: 24px;
//     flex-shrink: 0;
//     cursor: pointer;
// `;

export const ActivityStudentGridHeaderItemLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #232326;
`;

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
