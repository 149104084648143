import styled from "styled-components";
import { Col } from "components/Grid";
import { theme } from "config/theme";
import { SpinnerWrap } from "components/Spinner/styles";

export const PreviousExamDetailsContainer = styled.div``;

export const PreviousExamDetailsExamDayCol = styled(Col)`
    margin: ${theme.spacing.medium} 0;
`;

export const PreviousExamDetailsDescription = styled.p`
    color: ${theme.colors.base[400]};
    font-size: ${theme.spacing.small};
    text-align: justify;
`;

export const PreviousExamDetailsRelateds = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 8px;
    padding: 16px 24px;
    margin: 0 16px;

    ${SpinnerWrap} {
        margin: 0 auto;
    }

    h3 {
        color: ${theme.colors.base[400]};
        padding-bottom: ${theme.spacing.medium};
    }
`;
