// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 768px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const Description = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #505462;

    @media (min-width: 768px) {
        font-size: 16px;
        line-height: 22px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 1024px) {
        flex-direction: row;
    }
`;

export const ResumeArea = styled.div`
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 16px 32px;
        flex: 2;
    }

    @media (min-width: 1024px) {
        grid-gap: 16px;
    }

    @media (min-width: 1440px) {
        grid-gap: 16px 32px;
    }
`;

export const ResumeAreaEssay = styled.div`
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;

    @media (min-width: 768px) {
        flex: 1;
    }

    @media (min-width: 1024px) {
        width: 31.7%;
    }
`;
