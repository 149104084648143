import { lightenDarkenColor } from "helpers";
import { theme } from "config/theme";
import { css } from "styled-components";

export const ToastifyStyles = css`
    .Toastify__toast-container {
        max-width: 80vw !important;
        bottom: 1rem !important;
        left: unset !important;
    }

    .Toastify__toast-body {
        font-family: ${theme.typography.family.primary};
    }

    .Toastify__close-button {
        align-self: unset !important;
        padding: 0 !important;
    }

    .Toastify__toast {
        min-height: unset !important;
        padding: 12px !important;
        border-radius: 4px !important;
    }

    .Toastify__toast--info {
        background: ${theme.colors.system.info[300]} !important;
        box-shadow: 2px 2px 3px ${(props) => lightenDarkenColor(theme.colors.system.info[300], -20)} !important;
    }

    .Toastify__toast--success {
        background: ${theme.colors.system.success[300]} !important;
        box-shadow: 2px 2px 3px ${(props) => lightenDarkenColor(theme.colors.system.success[300], -20)} !important;
    }

    .Toastify__toast--warning {
        background: ${theme.colors.system.warning[300]} !important;
        box-shadow: 2px 2px 3px ${(props) => lightenDarkenColor(theme.colors.system.warning[300], -20)} !important;
    }

    .Toastify__toast--error {
        background: ${theme.colors.system.danger[300]} !important;
        box-shadow: 2px 2px 3px ${(props) => lightenDarkenColor(theme.colors.system.danger[300], -20)} !important;
    }

    .Toastify__close-button {
        padding: 0 !important;
        margin: 0;
        display: flex;
        align-items: center;
    }
`;
