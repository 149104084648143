import { call, put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as alertActions } from "../ducks/alert";
import { Creators as learningObjectActions } from "../ducks/learning-object";

export function* patchLike(action: any) {
    try {
        if (!action.payload.id) {
            return;
        }

        const { data } = yield call(api.patch, `/learningobject/${action.payload.id}/like`, {
            isLike: action.payload.likeStatus
        });

        yield put(learningObjectActions.patchLikeLearningObjectSuccess(data.items));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar o like.", "danger"));
        yield put(learningObjectActions.patchLikeLearningObjectFailure(error?.response?.data));
    }
}

export function* patchView(action: any) {
    try {
        if (!action.payload.id) {
            return;
        }

        const { data } = yield call(api.patch, `/learningobject/${action.payload.id}/view`, {
            viewed: action.payload.viewed
        });

        yield put(learningObjectActions.patchViewLearningObjectSuccess(data.items));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar sua visualização.", "danger"));
        yield put(learningObjectActions.patchViewLearningObjectFailure(error?.response?.data));
    }
}
