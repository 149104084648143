import React from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import _get from "lodash/get";

import * as S from "./styles";
interface IEssayCommentProps {
    commentIndex?: number;
    composition?: IEssayComposition;
}

const EssayComment = ({ composition, commentIndex = 0 }: IEssayCommentProps) => {
    return (
        <S.EssayCommentContainer>
            <S.EssayCommentTitle>{_get(composition, `comments[${commentIndex}].tips`, "")}</S.EssayCommentTitle>
            <S.EssayCommentContent
                dangerouslySetInnerHTML={{
                    __html: `<p>${_get(composition, `comments[${commentIndex}].commentary`, "")}</p>`
                }}
            />
        </S.EssayCommentContainer>
    );
};

export default EssayComment;
