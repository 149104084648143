// Dependencies
import styled from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const DefaultActivityCard = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const Label = styled.p`
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    font-family: "Nunito", sans-serif;
    color: #4d4d63;
`;

// export const AnswerItemIcon = styled.img`
//     width: 24px;
//     height: 24px;
//     flex-shrink: 0;
// `;

export const ActivityScoreWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_HUGE} {
        flex-direction: column;
    }
`;

export const ScoreActivityCardWrapper = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const ActivityScoreLabelWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        flex-direction: row;
        align-items: center;
    }

    > .webapp-input {
        width: 100%;

        ${MediaQueries.BIGGER_THAN_TABLET} {
            width: 320px;
        }

        ${MediaQueries.BIGGER_THAN_LARGE} {
            width: 140px;
        }
    }
`;

export const ActivityScoreLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #232326;
    white-space: nowrap;
`;

export const ActivityExerciseCardLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #4d4d63;

    img {
        width: inherit !important;
        max-width: 100%;
    }

    ul li {
        list-style-type: disc;
        margin-left: 24px;
    }

    ol li {
        list-style-type: decimal;
        margin-left: 32px;
    }
`;

export const ActivityExerciseCardSeparator = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f0f1f7;
`;

export const ActivitySendFileAnswerWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    > .webapp-file {
        width: 300px;
    }
`;

export const ActivityExerciseCardTitleWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
`;

export const ActivityExerciseCardTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: #000000;
`;

export const ActivityAnswerFilesWrapper = styled.div`
    width: 100%;

    gap: 16px;
    display: flex;
    align-items: flex-start;
    flex: 1 0 0;
    flex-wrap: wrap;
`;

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ActivityMobileTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #24388c;
`;

export const ActivityDetailsLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #000000;

    img {
        width: inherit !important;
    }

    ul li {
        list-style-type: disc;
        margin-left: 24px;
    }

    ol li {
        list-style-type: decimal;
        margin-left: 32px;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #f0f1f7;
`;

export const ExerciseListSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

export const AnswerSheetItemCompositionTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    color: #000;
`;

export const ActivityAnswerSheetTitle = styled.p`
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    font-family: "Nunito Sans", sans-serif;
    color: #000;
`;

export const DiscursiveCommentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
`;

export const AnswerSheetItemLabel = styled.p`
    display: block;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #232326;

    width: 100%;

    p {
        margin: 0 !important;
    }

    figure,
    img {
        width: inherit !important;
        max-width: 100%;
    }

    ul li {
        list-style-type: disc;
        margin-left: 24px;
    }

    ol li {
        list-style-type: decimal;
        margin-left: 32px;
    }

    span {
        line-break: anywhere;
    }
`;
