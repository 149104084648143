import styled from "styled-components";
import { theme } from "config/theme";

export const VideoPlayerWrapper = styled.section`
    width: 100%;
    position: relative;
    background: ${theme.colors.black};
`;

export const MenuButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 65px;
    height: 30px;
    right: 0px;
    top: 50px;
    background: rgba(49, 52, 60, 0.4);
    border-radius: 4px 0px 0px 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    z-index: 5;

    svg {
        margin-right: 5px;
        fill: #fff;
        transform: rotate(-90deg);
    }
`;

export const BackButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 15px;
    top: 20px;
    background: transparent;
    z-index: 5;

    svg {
        fill: #fff;
        transform: rotate(-90deg);
    }
`;

export const Arrow = styled.button<{ left?: boolean }>`
    width: 30px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: rgba(49, 52, 60, 0.7);
    border-radius: ${({ left }) => (left ? "0px 4px 4px 0px" : "4px 0px 0px 4px")};
    top: 50%;
    right: ${({ left }) => (left ? "none" : "2px")};
    left: ${({ left }) => (left ? "2px" : "none")};
    transform: translateY(-50%);
    z-index: 5;

    svg {
        fill: #fff;
        transform: ${({ left }) => (left ? "none" : "rotate(180deg)")};
    }
`;
