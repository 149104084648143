// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    isInvalid: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    align-items: center;

    .native-input {
        width: 50px;
        height: 32px;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.375;
        font-family: "Nunito", sans-serif;
        font-style: normal;
        padding: 0.5em;

        border-radius: 4px;
        border: 1px solid ${theme.colors.base[200]};

        text-align: right;

        outline: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &[type="number"] {
            -moz-appearance: textfield;
        }

        @media (min-width: 768px) {
            width: 80px;
        }

        @media (min-width: 1366px) {
            width: 50px;
        }
    }

    .button {
        width: 24px;
        height: 24px;
        background-color: ${theme.colors.brand[300]};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;

        @media (min-width: 768px) {
            width: 32px;
            height: 32px;
        }

        @media (min-width: 1366px) {
            width: 24px;
            height: 24px;
        }
    }

    .minus {
        margin-right: 0.5rem;
        background-color: ${theme.colors.base[100]};
        color: ${theme.colors.base[400]};
    }
    .plus {
        margin-left: 0.5rem;
        color: ${theme.colors.white};
    }

    ${({ isInvalid }) =>
        !!isInvalid &&
        css`
            .native-input {
                border-color: ${theme.colors.brand[300]};
            }
        `}
`;
