function formatSecondsToHours(seconds: number) {
    try {
        const [hour, minute, second] = new Date(seconds * 1000)
            .toISOString()
            .substr(11, 8)
            .split(":");
        const final = [hour, minute, second].filter((item) => item !== undefined).join(":");

        return final;
    } catch (error) {
        return "00:00:00";
    }
}

export default formatSecondsToHours;
