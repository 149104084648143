import styled from "styled-components/macro";
import { Grid, Row } from "components/Grid";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const SubjectScreenDetailsContainer = styled.div`
    width: 100%;
    height: 100%;
    flex: 1;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
    }

    /* overflow-y: auto; */
`;

export const SubjectScreenDetailsGrid = styled(Grid)`

    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        max-height: 100%;
    }



    /* ${MediaQueries.BIGGER_THAN_SMALL} {
        flex: 1;
        display: flex;
        flex-direction: column;
    } */
`;

export const SubjectScreenDetailsScroll = styled(Row)`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex: 1;

        overflow-y: auto;
        position: relative;
    }

    /* total width */
    ::-webkit-scrollbar {
        background-color: transparent;
        width: 0px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
        background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0px;
        border: 0px solid transparent;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: transparent;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
`;
