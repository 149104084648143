// Dependencies
import React, { FunctionComponent, useEffect, useState } from "react";

// Types
import { AssetProps } from "./asset.types";

// Styles
import { Container } from "./asset.styles";

export const Asset: FunctionComponent<AssetProps> = ({ source, size, color, hoverColor, handleClick }) => {
    const [svg, setSvg] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            const svgSource = await fetch(source).then((res) => res.text());

            setSvg(svgSource);
        })();
    }, [source]);

    if (!svg) {
        return null;
    }

    return (
        <Container
            className="pdg-asset"
            size={size}
            color={color}
            hoverColor={hoverColor}
            dangerouslySetInnerHTML={{ __html: svg }}
            onClick={() => !!handleClick && handleClick()}
        />
    );
};
