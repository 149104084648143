// Dependencies
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    width: 256px;

    padding: 16px;

    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    background-color: #fff;

    @media (min-width: 768px) {
        width: 100%;
        padding: 24px;
    }
`;

export const EssaySubmissionGradeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-self: center;
    width: max-content;
    position: relative;
`;

export const NoteWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 20px;
    left: 20px;
    width: 64px;
    height: 64px;
`;

export const NoteLabel = styled.div`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #505462;
`;

export const BadgeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 68px;
    left: 68px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
`;

export const Icon = styled.img`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
`;

export const EssaySubmissionTheme = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3a3e47;
`;

export const EssaySubmissionDateWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const EssaySubmissionDate = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #666b7b;
`;

export const EssaySumissionCardFullWidthElement = styled.div`
    width: 100%;
`;

export const EssaySubmissionSkillWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    flex-grow: 0;
`;

export const EssaySubmissionSkillName = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #666b7b;
    white-space: nowrap;
`;

export const EssaySubmissionSkillGrade = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: #1e9369;
`;

export const EssaySubmissionSkillProgressBar = styled.div`
    position: relative;

    width: 100%;
    height: 8px;

    background-color: #f0f1f7;
    border-radius: 4px;
`;

type SkillProgressBarStyleProps = {
    color: string;
    width: number;
};

export const EssaySubmissionSkillProgressBarFilled = styled.div<SkillProgressBarStyleProps>`
    position: absolute;

    height: 8px;
    border-radius: 4px;

    ${({ width, color }) => css`
        width: ${width}%;
        background-color: ${color};
    `}
`;

// ----------

export const EssaySubmissionDateSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;
`;

export const EssaySubmissionSkillNameSkeleton = styled.div`
    height: 16px;
    padding: 2px 0;
`;

export const EssaySubmissionSkillGradeSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;
`;

export const EssaySubmissionThemeSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;
`;

export const EssaySubmissionContentSkeleton = styled.div`
    width: 100%;
`;
