// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    padding: 16px 24px;
    border-radius: 4px;
    border: 1px solid #f88;
    background-color: #fff;

    display: flex;
    align-items: center;
    gap: 24px;
`;

export const LabelWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

export const Label = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: #e40000;
`;
