import Color from "color";
import { isValidHex } from "./isValidHex";

interface IGetColorsList {
    colorsAmount: number;
    colorsShiftAmount: number;
    mixColor: string;
    rotate: number;
    saturation: number;
    mainColor: string;
}

interface ILightColors {
    100: string;
    200: string;
}

interface IDarkColors {
    400: string;
    500: string;
}

export interface IGenerateColors extends ILightColors, IDarkColors {
    300: string;
}

const numberToHex = (value: string) => `#${value}`;
const errorColor = "transparent";

export const getColorsList = ({ colorsAmount, colorsShiftAmount, mixColor, rotate, saturation, mainColor }: IGetColorsList) => {
    const formatColor = mainColor.replace("#", "");

    const colorsList = [];
    const givenColor = isValidHex(numberToHex(formatColor)) ? numberToHex(formatColor) : errorColor;

    let step;
    for (step = 0; step < colorsAmount; step++) {
        if (isValidHex(numberToHex(formatColor))) {
            colorsList.push(
                Color(givenColor)
                    .rotate(((step + 1) / colorsAmount) * -rotate)
                    .saturate(((step + 1) / colorsAmount) * (saturation / 100))
                    .mix(Color(mixColor), ((colorsShiftAmount / 100) * (step + 1)) / colorsAmount)
                    .hex()
            );
        } else {
            colorsList.push(errorColor);
        }
    }

    return colorsList;
};

export const generateDarkColors = (mainColor: string): IDarkColors => {
    const configColorsDark = {
        colorsAmount: 2,
        colorsShiftAmount: 80,
        mixColor: "black",
        rotate: 6,
        saturation: 15,
        mainColor
    };

    const colors = getColorsList(configColorsDark);

    return Object.values(colors).reduce((prev, curr, index) => ({ [(index + 1) * 100 + 300]: curr, ...prev }), {}) as IDarkColors;
};

export const generateLightColors = (mainColor: string): ILightColors => {
    const configColorsLight = {
        colorsAmount: 2,
        colorsShiftAmount: 95,
        mixColor: "white",
        rotate: -5,
        saturation: -5,
        mainColor
    };

    const colors = getColorsList(configColorsLight);

    return Object.values(colors)
        .reverse()
        .reduce((prev, curr, index) => ({ [(index + 1) * 100]: curr, ...prev }), {}) as ILightColors;
};

const generateColors = (mainColor: string): IGenerateColors => {
    return {
        ...generateLightColors(mainColor),
        300: mainColor,
        ...generateDarkColors(mainColor)
    };
};

export default generateColors;
