// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, ContentWrapper, SectionSpacing, AvarageAndCommentsWrapper } from "./performance-essay.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";

// Types
import { PerformanceEssayWorkspaceProps } from "./performance-essay.types";

export const PerformanceEssayWorkspace: FunctionComponent<PerformanceEssayWorkspaceProps> = ({
    backElement,
    bannerSection,
    evolutionSection,
    averageNoteSection,
    commentsSection,
    submissionsSection
}) => {
    const sectionDivider = (
        <>
            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={48} />} />

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={32} />} />
        </>
    );

    return (
        <Container>
            <ContentWrapper>
                <SectionSpacing>
                    <ScreenWidthRender
                        actionAfterRenderingWidth="show"
                        renderingWidth={1024}
                        content={
                            <>
                                {backElement}

                                <Spacing direction="vertical" size={8} />
                            </>
                        }
                    />
                </SectionSpacing>

                <SectionSpacing>{bannerSection}</SectionSpacing>

                {sectionDivider}

                <SectionSpacing>{evolutionSection}</SectionSpacing>

                {sectionDivider}

                <AvarageAndCommentsWrapper>
                    <SectionSpacing>{averageNoteSection}</SectionSpacing>

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={32} />} />

                    {commentsSection}
                </AvarageAndCommentsWrapper>

                {sectionDivider}

                {submissionsSection}
            </ContentWrapper>
        </Container>
    );
};
