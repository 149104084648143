import { fadeInUp } from "assets/styles/animations";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const ProjectsListContainer = styled.section`
    animation: ${fadeInUp} 0.4s linear;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
`;
