import styled from "styled-components/macro";
import { MediaQueriesManual } from "assets/styles/settings";
import { theme } from "config/theme";

export const Wrapper = styled.div`
    .webp-card {
        padding: 24px 8px;
        margin: 0 0 24px;
    }

    .content-card-restart {
        webp-card-restart {
            margin: 0 0 24px;

            &:last-child {
                margin: 0;
            }
        }
    }

    ${MediaQueriesManual(768)} {
        .webp-card {
            padding: 32px 24px;
        }

        .content-card-restart {
            display: flex;
            justify-content: space-between;

            webp-card-restart {
                margin: 0;
            }
        }
    }
    ${MediaQueriesManual(1024)} {
        .webp-card {
            padding: 24px 6px;
            margin: 0;
        }
    }

    ${MediaQueriesManual(1366)} {
        .webp-card {
            padding: 24px 16px;
        }
    }
`;

export const ContentTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-section {
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 22px;

        color: ${theme.colors.base[500]};
        margin-bottom: 16px;
    }

    ${MediaQueriesManual(768)} {
        align-items: start;

        .title-select-start,
        .divider {
            display: none;
        }

        .title-section {
            font-size: 16px;
            line-height: 22px;
        }
    }
`;
