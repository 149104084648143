export enum WeekDayType {
    "Seg" = 1,
    "Ter" = 2,
    "Qua" = 3,
    "Qui" = 4,
    "Sex" = 5,
    "Sáb" = 6,
    "Dom" = 0
}

export enum ShiftType {
    "manhã" = 0,
    "tarde" = 1,
    "noite" = 2
}
