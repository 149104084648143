import React from "react";

// Components
import Switch from "components/Switch";

// Assets
import * as U from "./styles";

interface IUserQuestionsProps {
    isActive: boolean;
    onChange: (value: boolean) => void;
}

const UserQuestions = ({ isActive, onChange }: IUserQuestionsProps) => {
    return (
        <U.UserQuestionsContainer>
            <Switch
                initialValue={isActive}
                defaultLabel="Somente as minhas perguntas"
                activeLabel="Somente as minhas perguntas"
                variant="success"
                onChange={onChange}
            />
        </U.UserQuestionsContainer>
    );
};

export default UserQuestions;
