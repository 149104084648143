// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

type ContainerStyleProps = {
    isFilled: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 9px 12px;
    border-radius: 4px;
    border: 1px solid #83899e;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        width: 320px;
    }

    ${MediaQueries.BIGGER_THAN_LARGE} {
        width: 140px;
    }

    ${({ isFilled }) =>
        !!isFilled &&
        css`
            background-color: #ced0dd;
        `}
`;

export const Label = styled.div`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4d4d63;
`;
