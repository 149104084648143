// Dependencies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    variant: "brand" | "secondary";
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;

    .button-title {
        display: flex;
        justify-content: left;
        align-content: center;
        margin-bottom: 33px;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        font-family: "Nunito", sans-serif;
        letter-spacing: 0;
        line-height: 1.375;
        color: ${theme.colors.base[500]};

        button {
            border: none;
            background: transparent;
            transform: scale(0.9, 1.7);
            margin: 0 8px 0 0;
            padding: 1px 6px;
            cursor: pointer;
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;

            ${({ variant }) =>
                variant === "brand" &&
                css`
                    color: ${theme.colors[variant][300]};
                `}

            ${({ variant }) =>
                variant === "secondary" &&
                css`
                    color: ${theme.colors[variant][300]};
                `}
        }
    }

    @media (min-width: 768px) {
        .button-title {
            button {
                transform: scale(1.1, 2.1);
            }
        }
    }
`;

export const ActionCompositionWrapper = styled.div`
    max-width: 288px;
    display: block;
`;

export const DescriptionCompositionWrapper = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Nunito", sans-serif;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.428;
    color: ${theme.colors.base[500]};
    margin-bottom: 32px;
`;
