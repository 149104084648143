// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;

    flex-shrink: 0;
`;

export const Label = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #505462;
`;

export const OrdenatorOptionsWrapper = styled.div`
    width: 194px;

    position: absolute;
    top: 32px;
    right: 0;
    z-index: 4;
`;
