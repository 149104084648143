import { JSX } from "webapp-components/dist/libs/webp-components/dist/types";
import { DropdownFilterControls } from "../enums/DropdownFilterControls";
import { QuestionDifficultyLevel } from "../enums/QuestionDifficultyLevel";
import { QuestionStatusEnum } from "../enums/QuestionStatusEnum";
import { IModalActions } from "../store/interfaces/IModal";
import { WebpQuestionReportCardProps } from "../components/WebpQuestionReportCard";

export interface IExerciseListCustomState {
    isLoading: boolean;
    error: boolean;
    subjects?: CustomListFilterFormData;
    topics?: CustomListFilterFormData;
    subtopics?: CustomListFilterFormData;
    questionType?: CustomListFilterFormData;
    isAuthored?: boolean;
    contestsWithAuthoredQuestions?: CustomListFilterFormData;
    isFromContest?: boolean;
    contest?: CustomListFilterFormData;
    contestYear?: CustomListFilterFormData;
    contestPhase?: CustomListFilterFormData;
    questionStatus?: any;
    questionLevel?: any;
    isCancelled?: boolean;
    isFromAnotherList?: boolean;
    listSize?: number;
    listSizeLimit?: number;
    userListSize?: number;
    listName?: string;
    customList?: IExerciseListCustom;
    userHasList?: boolean;
}

export interface IExerciseListCustom {
    page: number;
    total: number;
    perPage: number;
    items: IReportCardQuestion[];
}

export interface IWebpChart extends JSX.WebpChart {}

export interface IReportCardQuestion {
    card: IQuestionReportCard;
    chart: IWebpChart;
    modalActions?: IModalActions;
}

export interface IExerciseListCustomApi {
    page: number;
    total: number;
    perPage: number;
    items: IExerciseListCustomApiList[];
}

export interface IExerciseListCustomApiList {
    created: string;
    id: number;
    questionsTotal: number;
    title: string;
    isNew: boolean;
    answerCard?: {
        id: number;
        created: string;
        status: number;
        hits: number;
        errors: number;
        emptyAnswers: number;
    };
}

export interface IQuestionReportCard extends WebpQuestionReportCardProps {
    listId: number;
    status?: number;
    listCreatedDate: string;
    answerCardDate?: string;
    answerCardDateStatus?: string;
    answerCardId?: number;
    isNew: boolean;
}

export const ExerciseListCustomStateKeys = {
    SUBJECTS: "subjects",
    TOPICS: "topics",
    SUBTOPICS: "subtopics",
    QUESTION_TYPE: "questionType",
    IS_AUTHORED: "isAuthored",
    CONTESTS_WITH_AUTHORED_QUESTIONS: "contestsWithAuthoredQuestions",
    IS_FROM_CONTEST: "isFromContest",
    CONTEST: "contest",
    CONTEST_YEAR: "contestYear",
    CONTEST_PHASE: "contestPhase",
    QUESTION_STATUS: "questionStatus",
    QUESTION_LEVEL: "questionLevel",
    IS_CANCELLED: "isCancelled",
    IS_FROM_ANOTHER_LIST: "isFromAnotherList",
    LIST_SIZE: "listSize",
    LIST_SIZE_LIMIT: "listSizeLimit",
    USER_LIST_SIZE: "userListSize"
} as const;

export type ExerciseListCustomStateKeysTypes = typeof ExerciseListCustomStateKeys[keyof typeof ExerciseListCustomStateKeys];

/**
 * Data from /questionengine endpoint
 */
export interface CustomListFilterApiData {
    id: number;
    name: string;
    options?: CustomListFilterApiData[];
}

/**
 * Data format that must be used for webp-dropdown-filter
 */
export interface CustomListFilterFormData {
    type: DropdownFilterControls.list;
    options: (CustomListFilterCheckbox | CustomListFilterFormData)[];
}

/**
 * Type Guard Technique
 */
export function isListOption(option: CustomListFilterCheckbox | CustomListFilterFormData): option is CustomListFilterFormData {
    return (option as CustomListFilterFormData).options !== undefined;
}

export interface CustomListFilterCheckbox {
    checked: boolean;
    id?: number;
    label: string;
    type: DropdownFilterControls.checkbox;
    value?: any;
}

export const QuestionStatusEnumOptions = {
    NOT_SOLVED: 1,
    SOLVED: 2,
    HITS: 3,
    NOT_HITS: 4
} as const;

export const QuestionLevelEnumOptions = {
    EASY: 1, //Default
    MEDIUM: 2,
    HARD: 3
} as const;

export const QuestionStatusEnumToOption: { [key: number]: string } = {
    [QuestionStatusEnum.NotSolved]: "Não resolvidas",
    [QuestionStatusEnum.Solved]: "Resolvidas",
    [QuestionStatusEnum.Hits]: "Acertei",
    [QuestionStatusEnum.NotHits]: "Errei"
};

export const QuestionStatusOptionToEnum: { [key: string]: number } = {
    "Não resolvidas": QuestionStatusEnum.NotSolved,
    Resolvidas: QuestionStatusEnum.Solved,
    Acertei: QuestionStatusEnum.Hits,
    Errei: QuestionStatusEnum.NotHits
};

export const QuestionLevelEnumToOption: { [key: number]: string } = {
    [QuestionDifficultyLevel.Easy]: "Fácil",
    [QuestionDifficultyLevel.Medium]: "Intermediário",
    [QuestionDifficultyLevel.Hard]: "Difícil"
};

export const QuestionLevelOptionToEnum: { [key: string]: number } = {
    Fácil: QuestionDifficultyLevel.Easy,
    Intermediário: QuestionDifficultyLevel.Medium,
    Difícil: QuestionDifficultyLevel.Hard
};

export interface contractBuildObject {
    key: string;
    data: any;
}

export interface ContractApi {
    [key: string]: any;
}
