// Dependencies
import React, { memo, useEffect, useCallback } from "react";
import { theme } from "config/theme";
import { useLocation } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as weekPlansActions } from "store/ducks/weekPlans";

// Components
import Spinner from "components/Spinner";
import SelectPlan from "screens/WeekPlans/SelectPlan";
import ForbiddenMessage from "components/ForbiddenMessage";
import { NotFoundProject as ForbiddenContainer } from "screens/Projects/Details/styles";

// Screens
import LessonPlanCustom from "screens/LessonPlanCustom";
import WeekPlan from "screens/WeekPlans";
import LessonPlanCustomWizard from "screens/LessonPlanCustom/Wizard";

// Helpers
import { hasLessonPlanCustom } from "helpers/has-lesson-plan-custom";
import { ternary } from "utils/ternary";

// Services
import history from "services/history";
import api from "services/api";

const SelectLessonPlan = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const { weekPlans, lessonPlanCustom, course, userEmail } = useSelector(({ weekPlans, lessonPlanCustom, course, credentials }: IReduxStore) => ({
        weekPlans,
        lessonPlanCustom,
        course,
        userEmail: credentials.email
    }));

    const turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey = `${course.slug}-has-already-been-previously-selected-automatically:${userEmail}`;
    const turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomatically = Boolean(
        localStorage.getItem(turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey)
    );
    const isProenemOrPromedicinaTurmaIntensiva2023 =
        ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(course.slug) &&
        ["proenem", "promedicina"].includes(theme.project.slug);

    const currentCourseLessonPlanSelection = lessonPlanCustom?.lessonPlanSelection;
    const hasSuggestedLessonPlan = weekPlans?.isPublished;
    const hasCustomLessonPlan = hasLessonPlanCustom(theme.project.slug, course?.slug);

    const shouldRedirectToSuggested = hasSuggestedLessonPlan && currentCourseLessonPlanSelection === 2;
    const shouldRedirectToCustom =
        hasCustomLessonPlan &&
        (currentCourseLessonPlanSelection === 1 ||
            (currentCourseLessonPlanSelection === 0 && hasSuggestedLessonPlan !== undefined && !hasSuggestedLessonPlan));
    const shouldSelectSuggestedPlan =
        currentCourseLessonPlanSelection === 0 &&
        hasSuggestedLessonPlan &&
        !hasCustomLessonPlan &&
        !!course.slug &&
        !lessonPlanCustom.isLoadingLessonPlanSelection &&
        course.isPublished;
    const shouldDeleteSelection =
        ((hasSuggestedLessonPlan !== undefined && !hasSuggestedLessonPlan && currentCourseLessonPlanSelection === 2) ||
            ((!hasCustomLessonPlan ||
                (hasCustomLessonPlan && !lessonPlanCustom.lessonPlanCustom.length && !lessonPlanCustom.isLoadingLessonPlanCustom)) &&
                currentCourseLessonPlanSelection === 1)) &&
        !lessonPlanCustom.isLoadingLessonPlanSelection &&
        course.isPublished;
    const shouldSelectPlan =
        currentCourseLessonPlanSelection === 0 &&
        hasCustomLessonPlan &&
        hasSuggestedLessonPlan &&
        (!isProenemOrPromedicinaTurmaIntensiva2023 ||
            (isProenemOrPromedicinaTurmaIntensiva2023 && turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomatically));

    const canSelectTurmaIntensiva2023SuggestedLessonPlan =
        !lessonPlanCustom.isLoadingLessonPlanSelection &&
        hasSuggestedLessonPlan !== undefined &&
        currentCourseLessonPlanSelection === 0 &&
        hasCustomLessonPlan &&
        hasSuggestedLessonPlan &&
        isProenemOrPromedicinaTurmaIntensiva2023 &&
        !turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomatically &&
        course.isPublished;
    const canSaveTurmaIntensiva2023SuggestedLessonPlanSelectionAutomatically =
        !lessonPlanCustom.isLoadingLessonPlanSelection &&
        hasSuggestedLessonPlan !== undefined &&
        currentCourseLessonPlanSelection !== 0 &&
        isProenemOrPromedicinaTurmaIntensiva2023 &&
        !turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomatically &&
        course.isPublished;

    const isLoading =
        lessonPlanCustom.isLoadingLessonPlanSelection ||
        hasSuggestedLessonPlan === undefined ||
        (shouldRedirectToCustom && lessonPlanCustom.isLoadingLessonPlanCustom) ||
        shouldSelectSuggestedPlan;
    const shouldShowUnpublishedCourseMessage =
        !lessonPlanCustom.isLoadingLessonPlanSelection &&
        hasSuggestedLessonPlan !== undefined &&
        (hasSuggestedLessonPlan || hasCustomLessonPlan) &&
        !course.isPublished;

    const redirectPath = ternary([
        [
            shouldRedirectToCustom &&
                currentCourseLessonPlanSelection === 0 &&
                !!lessonPlanCustom.lessonPlanCustom.length &&
                !pathname.includes("/meu-plano-de-estudos-wizard"),
            `/app/curso/${course.slug}/meu-plano-de-estudos-wizard/escolha-como-iniciar`
        ],
        [
            shouldRedirectToCustom &&
                currentCourseLessonPlanSelection === 0 &&
                !lessonPlanCustom.lessonPlanCustom.length &&
                !pathname.includes("/meu-plano-de-estudos-wizard"),
            `/app/curso/${course.slug}/meu-plano-de-estudos-wizard/${
                ["promilitares", "promedicina"].includes(theme.project.brand) ? "configure-seu-plano" : "escolha-sua-carreira"
            }`
        ],
        [
            shouldRedirectToCustom && !pathname.includes("/meu-plano-de-estudos/p") && !pathname.includes("/meu-plano-de-estudos-wizard"),
            `/app/curso/${course.slug}/meu-plano-de-estudos/p`
        ],
        [!!shouldRedirectToSuggested && !pathname.includes("/meu-plano-de-estudos/s"), `/app/curso/${course.slug}/meu-plano-de-estudos/s`]
    ]);

    const deleteLessonPlanSelection = useCallback(async () => {
        await api.delete(`/student/lessonplanselection`);
        dispatch(lessonPlanCustomActions.clearLessonPlanSelection());
    }, [dispatch]);

    useEffect(() => {
        if (shouldDeleteSelection) {
            deleteLessonPlanSelection();
        }
    }, [shouldDeleteSelection, deleteLessonPlanSelection, lessonPlanCustom.isLoadingLessonPlanSelection, course.isPublished]);

    useEffect(() => {
        if (shouldSelectSuggestedPlan) {
            dispatch(lessonPlanCustomActions.postLessonPlanSelectionRequest({ selection: 2 }));
        }
    }, [dispatch, shouldSelectSuggestedPlan, lessonPlanCustom.isLoadingLessonPlanSelection, course.isPublished]);

    useEffect(() => {
        if (currentCourseLessonPlanSelection === 0) {
            dispatch(lessonPlanCustomActions.getLessonPlanSelectionRequest());
        }
    }, [dispatch, currentCourseLessonPlanSelection, course.slug]);

    useEffect(() => {
        dispatch(weekPlansActions.getWeekPlansIsPublishedRequest());
    }, [dispatch, course.slug]);

    useEffect(() => {
        if (hasCustomLessonPlan && (currentCourseLessonPlanSelection === 1 || shouldRedirectToCustom) && course.isPublished) {
            dispatch(lessonPlanCustomActions.getLessonPlanCustomRequest());
        }
    }, [dispatch, hasCustomLessonPlan, course.slug, currentCourseLessonPlanSelection, shouldRedirectToCustom, course.isPublished]);

    useEffect(() => {
        if (canSaveTurmaIntensiva2023SuggestedLessonPlanSelectionAutomatically) {
            localStorage.setItem(turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey, JSON.stringify(true));
        }
    }, [canSaveTurmaIntensiva2023SuggestedLessonPlanSelectionAutomatically, turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey]);

    useEffect(() => {
        if (canSelectTurmaIntensiva2023SuggestedLessonPlan) {
            dispatch(lessonPlanCustomActions.postLessonPlanSelectionRequest({ selection: 2 }));
            localStorage.setItem(turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey, JSON.stringify(true));
        }
    }, [canSelectTurmaIntensiva2023SuggestedLessonPlan, dispatch, turmaIntensiva2023HasAlreadyBeenPreviouslySelectedAutomaticallyKey]);

    useEffect(() => {
        if (!!redirectPath && !isLoading && !canSelectTurmaIntensiva2023SuggestedLessonPlan) {
            history.push({
                pathname: redirectPath
            });
        }
    }, [isLoading, redirectPath, canSelectTurmaIntensiva2023SuggestedLessonPlan]);

    const ForbiddenComponent = () => (
        <ForbiddenContainer>
            <ForbiddenMessage text={!!course.helptext ? course.helptext : "Plano de estudos não disponível no momento."} />
        </ForbiddenContainer>
    );

    const ComponentToDisplay = ternary([
        [shouldShowUnpublishedCourseMessage, ForbiddenComponent],
        [!!redirectPath || !!isLoading || !course.isPublished, Spinner as any],
        [!!shouldSelectPlan && pathname.split("/meu-plano-de-estudos")[1] === "", SelectPlan],
        [currentCourseLessonPlanSelection === 0 && pathname.includes("/meu-plano-de-estudos-wizard"), LessonPlanCustomWizard],
        [shouldRedirectToCustom, LessonPlanCustom],
        [!!shouldRedirectToSuggested, WeekPlan as any],
        [true, Spinner as any]
    ])!;

    return <ComponentToDisplay />;
};

export default memo(SelectLessonPlan);
