import React, { useEffect, useMemo, useState, useCallback, memo } from "react";

// Dependencies
import ChevronLeft from "prodigio-icons/web/outlined/ChevronLeft";

// Components
import Spinner from "components/Spinner";
import OrderProblems from "../components/OrderProblems";
import StudentAreaScreenSignedCoursesCard from "../components/SignedCoursesCard";

// Helpers
import history from "services/history";
import { IHandleRequestAction } from "..";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { ISubscription } from "store/ducks/student-area";

// Assets
import { ChevronIconContainer, ScreenTitle } from "../styles";
import { StudentAreaScreenSignedCoursesTitle } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface ISignedCoursesProps {
    signedCourses: ISubscription[];
    isLoading: boolean;
}

const StudentAreaScreenSignedCourses = ({ signedCourses = [], onRequestAction, isLoading }: ISignedCoursesProps & IHandleRequestAction) => {
    const dispatch = useDispatch();
    const [isFetched, setIsFetched] = useState(false);
    const isMobile = useIsMobile(BreakPoints.small);
    const [activeSubscription, setActiveSubscription] = useState<ISubscription>();
    const { orderAddressLess, ordersFixeds, courseSlug } = useSelector(({ studentArea, course }: IReduxStore) => ({
        ...studentArea,
        courseSlug: course.slug
    }));

    useEffect(() => {
        if (!isFetched) {
            dispatch({ type: "GET_ORDER_ADDRESSLESS_REQUEST" });
            setIsFetched(true);
        }
    }, [dispatch, isFetched]);

    const requestSubscriptions = useCallback(() => {
        onRequestAction("signed-courses");
    }, [onRequestAction]);

    useEffect(() => requestSubscriptions(), [requestSubscriptions]);

    const availableSubscriptions: ISubscription[] = useMemo(() => {
        try {
            return signedCourses.filter((signedCourse) => !!signedCourse.isActive);
        } catch (error) {
            return [];
        }
    }, [signedCourses]);

    if (!!activeSubscription) {
        return (
            <>
                <ScreenTitle onClick={() => setActiveSubscription(undefined)}>
                    <ChevronLeft /> Detalhamento da assinatura
                </ScreenTitle>
                <StudentAreaScreenSignedCoursesCard subscription={activeSubscription} showCredits={true} />
            </>
        );
    }

    return (
        <>
            {isMobile && (
                <ChevronIconContainer onClick={() => history.push(`/app/curso/${courseSlug}/area-do-aluno/perfil`)}>
                    <ChevronLeft />
                </ChevronIconContainer>
            )}
            {Boolean(orderAddressLess.length) && (
                <OrderProblems dispatch={dispatch} orders={orderAddressLess} ordersFixeds={ordersFixeds} isLoading={isLoading} />
            )}
            <ScreenTitle>Assinaturas</ScreenTitle>
            <StudentAreaScreenSignedCoursesTitle>Assinaturas ativas</StudentAreaScreenSignedCoursesTitle>
            {isLoading ? (
                <Spinner fixed={false} />
            ) : (
                availableSubscriptions.map((subscription) => (
                    <StudentAreaScreenSignedCoursesCard
                        key={subscription.id}
                        subscription={subscription}
                        onClickDetails={() => setActiveSubscription(subscription)}
                    />
                ))
            )}
        </>
    );
};

export default memo(StudentAreaScreenSignedCourses);
