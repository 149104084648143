import React from "react";

import { H1, H2, H3, P1, P2, P3 } from "./styles";

export type Variant = "h1" | "h2" | "h3" | "p1" | "p2" | "p3";

export interface IProps {
    bold?: boolean;
    color?: string;
    children?: any;
    gutterBottom?: boolean;
    gutterTop?: boolean;
    variant?: Variant;
    className?: string;
}

const getDesiredElement = (variant: Variant) => {
    let element;

    switch (variant) {
        case "h1":
            element = H1;
            break;
        case "h2":
            element = H2;
            break;
        case "h3":
            element = H3;
            break;
        case "p1":
            element = P1;
            break;
        case "p2":
            element = P2;
            break;
        case "p3":
            element = P3;
            break;
        default:
            element = P1;
    }

    return element;
};

const Typography = ({
    variant = "p1",
    gutterBottom = false,
    gutterTop = false,
    children = null,
    bold = false,
    color = "",
    className = ""
}: IProps) => {
    const Element = getDesiredElement(variant);

    return (
        <Element bold={bold} color={color} gutterBottom={gutterBottom} gutterTop={gutterTop} className={className}>
            {children}
        </Element>
    );
};

export default Typography;
