import React, { useMemo, useCallback, memo } from "react";
import getCep from "cep-promise";

// Dependencies
import { array, string, object } from "yup";
import { Row, Col } from "components/Grid";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import _findIndex from "lodash/findIndex";

// Components
import Button from "components/Button";
import TextField from "components/Form/TextField";
import ZipCodeDropdown from "components/Dropdown/zip";
import DropdownIndicator from "screens/MigrationUser/components/indicator";

// Helpers
import { IStudentAreaState } from "store/ducks/student-area";
import { MASK_CPF, MASK_PHONE, MASK_ZIPCODE } from "helpers";
import { IOnSubmitActionStudentArea } from "../..";

// Assets
import { StudentAreaFormContainer } from "../styles";
import { LastStudentInputRow, PersonalDataInputRow, SelectWrapper } from "./styles";
import { addHyphenToZipCode } from "utils/addHyphenToZipCode";
import { UFOptions } from "utils/UFOptions";

interface IPersonalDataProps extends IOnSubmitActionStudentArea {
    studentArea: IStudentAreaState;
    credentialsEmail: string;
}
export interface IPersonalDataForm {
    name: string;
    lastName: string;
    cpf: string;
    phone: string;
    email: string;
    addresses: Array<{
        address: {
            city: string;
            zipCode: string;
            street: string;
            number: string;
            neighborhood: string;
            complement: string;
            state: string;
        };
    }>;
}

const ZIP_CODE_REGEX = /^\d{5}-\d{3}$/;

const schema = object().shape({
    name: string().required("Campo obrigatório"),
    lastName: string().required("Campo obrigatório"),
    cpf: string()
        .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido")
        .required("Campo obrigatório"),
    phone: string().nullable(),
    email: string().required("Campo obrigatório"),
    addresses: array().of(
        object().shape({
            address: object().shape({
                city: string().required("Campo obrigatório"),
                zipCode: string()
                    .matches(ZIP_CODE_REGEX, "CEP Inválido")
                    .required("Campo obrigatório"),
                street: string().required("Campo obrigatório"),
                number: string().required("Campo obrigatório"),
                neighborhood: string().required("Campo obrigatório"),
                complement: string().nullable(),
                state: string().required("Campo obrigatório")
            })
        })
    )
});

const PersonalDataForm = ({ onSubmitAction, studentArea, credentialsEmail }: IPersonalDataProps) => {
    const INITIAL_FORM_STATE: IPersonalDataForm = useMemo(() => {
        return {
            name: studentArea.name || "",
            lastName: studentArea.lastName || "",
            phone: studentArea.phone || "",
            cpf: studentArea.cpf || "",
            email: credentialsEmail || "",
            addresses: [
                {
                    address: {
                        zipCode: addHyphenToZipCode(studentArea.addresses[0]?.address.zipCode || ""),
                        street: studentArea.addresses[0]?.address.street || "",
                        number: studentArea.addresses[0]?.address.number || "",
                        complement: studentArea.addresses[0]?.address.complement || "",
                        city: studentArea.addresses[0]?.address.city || "",
                        state: studentArea.addresses[0]?.address.state || "",
                        neighborhood: studentArea.addresses[0]?.address.neighborhood || ""
                    }
                }
            ]
        };
    }, [credentialsEmail, studentArea]);

    const { control, handleSubmit, errors, formState, setValue, reset } = useForm<IPersonalDataForm>({
        mode: "all",
        defaultValues: INITIAL_FORM_STATE,
        resolver: yupResolver(schema)
    });

    const handleSelectItem = useCallback((value: string) => {
        return _findIndex(UFOptions, ["value", value]);
    }, []);

    const handleGetAddress = useCallback(
        async (event: React.FocusEvent<HTMLInputElement>) => {
            const inputCep = event.target.value;

            if (ZIP_CODE_REGEX.test(inputCep)) {
                try {
                    const data = await getCep(inputCep);

                    setValue("addresses[0].address.city", data.city, { shouldValidate: true, shouldDirty: true });
                    setValue("addresses[0].address.neighborhood", data.neighborhood, { shouldValidate: true, shouldDirty: true });
                    setValue("addresses[0].address.street", data.street, { shouldValidate: true, shouldDirty: true });
                    setValue("addresses[0].address.state", data.state, { shouldValidate: true, shouldDirty: true });
                } catch (error) {
                    reset();
                    console.log(error);
                }
            }
        },
        [reset, setValue]
    );

    const handleSubmitForm = useCallback(
        (data: IPersonalDataForm) => {
            const payload = {
                ...data,
                addresses: data.addresses.map((item) => ({
                    ...item,
                    ...(studentArea.addresses.length > 0 && { id: studentArea.addresses[0].id })
                }))
            };

            return onSubmitAction({ form: payload, type: "edit-personal" });
        },
        [onSubmitAction, studentArea.addresses]
    );

    return (
        <StudentAreaFormContainer onSubmit={handleSubmit(handleSubmitForm)}>
            <Row>
                <Col xs={6}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="name"
                            render={({ onChange, value }) => (
                                <TextField label="Nome" error={errors.name?.message} value={value} onChange={onChange} />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={6}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="lastName"
                            render={({ onChange, value }) => (
                                <TextField label="Sobrenome" error={errors.lastName?.message} value={value} onChange={onChange} />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="cpf"
                            render={({ onChange, value }) => (
                                <TextField mask={MASK_CPF} label="CPF" error={errors.cpf?.message} value={value} onChange={onChange} />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} md={6} lg={4}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="phone"
                            render={({ onChange, value }) => (
                                <TextField mask={MASK_PHONE} label="Celular" error={errors.phone?.message} value={value} onChange={onChange} />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} lg={4}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="email"
                            render={({ value }) => <TextField disabled={true} label="E-mail" error={errors.email?.message} value={value} />}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} md={6} lg={3}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.zipCode"
                            render={({ onChange, value }) => (
                                <TextField
                                    label="CEP"
                                    value={value}
                                    error={errors.addresses?.[0]?.address?.zipCode?.message}
                                    onChange={onChange}
                                    onBlur={handleGetAddress}
                                    mask={MASK_ZIPCODE}
                                    rightSideElement={<ZipCodeDropdown />}
                                />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} md={6} lg={6}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.street"
                            render={({ onChange, value }) => (
                                <TextField
                                    label="Endereço"
                                    value={value}
                                    error={errors.addresses?.[0]?.address?.street?.message}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={3} md={3}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.number"
                            render={({ onChange, value }) => (
                                <TextField label="Nº" value={value} error={errors.addresses?.[0]?.address?.number?.message} onChange={onChange} />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={9} md={6}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.complement"
                            render={({ onChange, value }) => (
                                <TextField
                                    label="Complemento"
                                    error={errors.addresses?.[0]?.address?.complement?.message}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={12} md={6}>
                    <PersonalDataInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.neighborhood"
                            render={({ onChange, value }) => (
                                <TextField
                                    label="Bairro"
                                    error={errors.addresses?.[0]?.address?.neighborhood?.message}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />
                    </PersonalDataInputRow>
                </Col>
                <Col xs={9} md={9}>
                    <LastStudentInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.city"
                            render={({ onChange, value }) => (
                                <TextField label="Cidade" value={value} error={errors.addresses?.[0]?.address?.city?.message} onChange={onChange} />
                            )}
                        />
                    </LastStudentInputRow>
                </Col>
                <Col xs={3} md={3}>
                    <LastStudentInputRow>
                        <Controller
                            control={control}
                            name="addresses[0].address.state"
                            render={({ onChange, value }) => {
                                return (
                                    <SelectWrapper
                                        name="state"
                                        components={{ DropdownIndicator }}
                                        error={errors.addresses?.[0]?.address?.state?.message}
                                        placeholder="UF"
                                        options={UFOptions}
                                        value={UFOptions[handleSelectItem(value)]}
                                        onChange={(item: any) => onChange(item.value)}
                                    ></SelectWrapper>
                                );
                            }}
                        />
                    </LastStudentInputRow>
                </Col>
            </Row>
            <Button variant="primary" block={true} disabled={studentArea.isLoading && !formState.isValid} size="medium" type="submit">
                Salvar Alterações
            </Button>
        </StudentAreaFormContainer>
    );
};

export default memo(PersonalDataForm);
