import React from "react";
import { ExerciseListAnswerCardKnowledgeAreaContainer, ExerciseListAnswerCardKnowledgeAreaQuestions } from "./styles";
import { IQuestion } from "interfaces/IQuestion";

import ExerciseListKnowledgeArea from "../KnowledgeArea";

import ExerciseListAnswerCardQuestion from "../AnswerCardQuestion";
import { IAnswerCardItem } from "store/ducks/answerCard";

interface IProps {
    questions: IQuestion[];
    name: string;
    showAnswers: boolean;
    showNotes: boolean;
    color?: string | null;
    isFinished: boolean;
    answers: IAnswerCardItem[];
    onClickSeeQuestion(questionNumber: number): void;
    isFeedBack: boolean;
    canSeeQuestion: boolean;
}

const ExerciseListAnswerCardKnowledgeArea = ({
    questions,
    name,
    color,
    showAnswers,
    showNotes,
    onClickSeeQuestion,
    isFinished,
    answers,
    isFeedBack = false,
    canSeeQuestion
}: IProps) => {
    return (
        <ExerciseListAnswerCardKnowledgeAreaContainer>
            <ExerciseListKnowledgeArea color={color} name={name} />

            <ExerciseListAnswerCardKnowledgeAreaQuestions>
                {questions.map((question, index) => {
                    const currentAnswer = answers.filter((answer) => !!answer.question).find((answer) => answer.question.id === question.id);

                    const number = question?.originalNumber ?? question.number ?? 1;

                    return (
                        <ExerciseListAnswerCardQuestion
                            key={question.id}
                            question={question}
                            number={number}
                            showAnswer={showAnswers}
                            showNote={showNotes}
                            answer={currentAnswer}
                            onClickSee={() => onClickSeeQuestion(question.number || index + 1)}
                            isFinished={isFinished}
                            isFeedBack={isFeedBack}
                            canSeeQuestion={canSeeQuestion}
                        />
                    );
                })}
            </ExerciseListAnswerCardKnowledgeAreaQuestions>
        </ExerciseListAnswerCardKnowledgeAreaContainer>
    );
};

export default React.memo(ExerciseListAnswerCardKnowledgeArea);
