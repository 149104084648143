import React, { useCallback } from "react";
import _last from "lodash/last";
import { QrScanner } from "@yudiel/react-qr-scanner";

// Assets
import { QrCodeContainer } from "./styles";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

interface IQrCodeProps {
    onSetQrCodeSlug: (qrCodeId: string) => void;
}

const QrCode = ({ onSetQrCodeSlug }: IQrCodeProps) => {
    const isLaptop = useIsMobile(BreakPoints.wide);
    const videoHeight = isLaptop ? "100%" : "286px";

    const getQrCodeSlug = useCallback(
        (qrCodeSlug: string) => {
            const slug = !!qrCodeSlug.length ? _last(qrCodeSlug) : qrCodeSlug;

            if (slug) {
                onSetQrCodeSlug(slug);
            }
        },
        [onSetQrCodeSlug]
    );

    return (
        <QrCodeContainer>
            <QrScanner
                videoStyle={{ height: videoHeight }}
                viewFinderBorder={60}
                onDecode={(result: any) => getQrCodeSlug(result?.split("="))}
                hideCount={true}
            />
        </QrCodeContainer>
    );
};

export default QrCode;
