import styled, { keyframes } from "styled-components";

const colors = {
    default: {
        normal: "rgba(0, 0, 0, 0.04)",
        dark: "rgba(0, 0, 0, 0.08)"
    },
    light: {
        normal: "rgba(0, 0, 0, 0.02)",
        dark: "rgba(0, 0, 0, 0.04)"
    }
};

const SkeletonAnimation = (light: boolean) => keyframes`
    from {
        background:  ${light ? colors.light.normal : colors.default.normal};
    }

    to {
        background: ${light ? colors.light.dark : colors.default.dark};
    }
`;

export const SkeletonItem = styled.span<{ width?: string; height: string; light?: boolean }>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    animation: ${({ light }) => SkeletonAnimation(!!light)} 0.5s alternate infinite;
    background: ${({ light }) => (!!light ? colors.light.normal : colors.default.normal)};
    display: flex;
`;
