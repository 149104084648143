import React, { memo, useCallback, useMemo } from "react";

// Components
import Uploader from "components/Uploader";

// Helpers
import { useDropzone } from "react-dropzone";

// Assets
import { DropzoneContainer } from "./styles";

interface IDropzoneProps {
    accept?: string;
    multiple?: boolean;
    files: File[];
    onSend(files: File[]): void;
    onUpload(files: File[]): void;
    onClearFiles(): void;
}

const Dropzone = ({ accept = "*", files, multiple = false, onSend, onUpload, onClearFiles }: IDropzoneProps) => {
    const handleChange = useCallback(
        (files: File[]) => {
            onUpload(files);
        },

        [onUpload]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleChange,
        accept
    });

    const dropzoneConfig = useMemo(
        () => ({
            ...getInputProps(),
            accept,
            multiple,
            disabled: Boolean(files.length)
        }),
        [accept, files.length, getInputProps, multiple]
    );

    return (
        <DropzoneContainer {...getRootProps()} isDragging={isDragActive}>
            <Uploader files={files} dropzoneProps={dropzoneConfig} onClearFiles={onClearFiles} onSend={onSend} />
        </DropzoneContainer>
    );
};

export default memo(Dropzone);
