import some from "lodash/some";
import { DateTime } from "luxon";
import api from "../../services/api";
import { theme } from "config/theme";
import { AxiosResponse } from "axios";
import history from "services/history";
import findIndex from "lodash/findIndex";
import apiMongo from "services/api-mongo";
import { IReduxStore } from "interfaces/IReduxStore";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { Creators as authActions } from "../ducks/auth";
import { ICredentials } from "store/interfaces/ICredentials";
import { Creators as alertActions } from "../ducks/alert/index";
import { MigrationUserPayload } from "screens/MigrationUser/types";
import { Creators as lessonPlanActions } from "../ducks/lesson-plan";
import { Creators as courseActions, ICourseState } from "../ducks/course";
import { Creators as credentialsActions, Types } from "../ducks/credentials";

const getCurrentCredentials = (state: IReduxStore) => state.credentials;

const isOurProject = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

function* setCourse(courses: ICourseState[]) {
    // Get index of the first course published
    const firstCourseIsPublishedIndex = findIndex(courses, { isPublished: true });

    api.defaults.headers.common["X-Course"] = courses[firstCourseIsPublishedIndex].slug;

    // Set course
    yield put(courseActions.setCourse({ course: courses[firstCourseIsPublishedIndex] }));
}

function* redirectUser(data: ICredentials) {
    try {
        // User not migrated yet. Redirect to `recadastro` page
        if (!data.isTermsAccepted && isOurProject) {
            return yield call(history.push, { pathname: "/recadastro" });
        }

        // Check if some course is published
        const hasPublishedCourses = some(data.courses, { isPublished: true });

        if (!hasPublishedCourses) {
            // Redirect user to subject page when no published course found
            const courseSlug = yield select((state: IReduxStore) => state.course.slug);
            return yield call(history.push, { pathname: `/app/curso/${courseSlug}/materias` });
        }

        // Set course
        yield call(setCourse, data.courses);

        // Get lesson plan
        yield put(lessonPlanActions.getLessonPlanRequest());
    } catch (error) {
        console.log("error redirect", error);
    }
}

function* getCredentials() {
    try {
        const { data }: AxiosResponse<ICredentials> = yield call(api.get, "/person/me");

        // Persist /person/me data
        yield put(credentialsActions.getCredentialsSuccess(data));

        // Get
        yield put({ type: "GET_ORDER_PROBLEMS_REQUEST" });

        yield call(redirectUser, data);
    } catch (error) {
        console.log("error", JSON.stringify(error));
        yield put(alertActions.showAlert(error.message, "danger"));
        yield put(credentialsActions.getCredentialsFailure());
    }
}

function* migrationUser(action: { type: string; payload: MigrationUserPayload }) {
    try {
        const { payload } = action;

        const formatted = {
            ...payload.step1,
            ...payload.step3,
            ...(payload.step2 && {
                address: [payload.step2]
            }),
            birthDate: DateTime.fromFormat(payload.step1?.birthDate!, "dd/LL/yyyy").toFormat("yyyy-LL-dd")
        };

        // Start migration process
        const { data } = yield call(api.post, "/person/migrate", formatted);
        const { credentials: updatedCredentials, ...rest } = data;

        yield put(credentialsActions.migrateUserSuccess());

        // Set new token to axios
        api.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;
        apiMongo.defaults.headers.common["Authorization"] = yield `Bearer ${data.token}`;

        // Set new token to redux preserving current content
        yield put(
            authActions.loginSuccess({
                ...rest,
                refresh_token: rest.refresh_token
            })
        );

        const credentials = yield select(getCurrentCredentials);

        // Merge both credentials object to fill api debit
        const newCredentials = {
            ...credentials,
            ...updatedCredentials,
            isTermsAccepted: true
        };

        // Set new credentials
        yield put(credentialsActions.getCredentialsSuccess(newCredentials));

        // Set course
        yield call(setCourse, newCredentials.courses);
    } catch (error) {
        console.log("error", error);
        yield put(credentialsActions.migrateUserFailure({ error: "Ocorreu um erro ao tentar fazer a atualização. Tente novamente." }));
    }
}

function* getOrdersProblem() {
    try {
        const { data } = yield call(api.get, "/student/order/has-problem");

        yield put({ type: "GET_ORDER_PROBLEMS_SUCCESS", payload: data });
    } catch (error) {
        console.log("error", error);
        yield put({ type: "GET_ORDER_PROBLEMS_FAILURE" });
    }
}

export default [
    takeLatest(Types.GET_CREDENTIALS_REQUEST, getCredentials),
    takeLatest(Types.MIGRATE_USER_REQUEST, migrationUser),
    takeLatest(Types.GET_ORDER_PROBLEMS_REQUEST, getOrdersProblem)
];
