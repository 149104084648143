import React, { useMemo } from "react";
import { StudentAreaScreenSignedCoursesCardCreditsContainer, StudentAreaScreenSignedCoursesCardCredit } from "./styles";
import { StudentAreaScreenSignedCoursesCardDescription } from "../SignedCoursesCard/styles";
import CheckIcon from "prodigio-icons/web/outlined/Check";
import ProjectIcon from "prodigio-icons/web/filled/Project";
import { ISubscriptionCredit } from "store/ducks/student-area";
import { theme } from "config/theme";
import { CreditType } from "enums/CreditType";
import Button from "components/Button";
import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";
import history from "services/history";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    credits: ISubscriptionCredit[];
}

const StudentAreaScreenSignedCoursesCredits = ({ credits }: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const courses = useMemo(() => {
        try {
            return credits.filter((creditItem) => !!creditItem.course && creditItem.credit?.type === CreditType.Course);
        } catch (error) {
            console.log("error", error);
            return [];
        }
    }, [credits]);

    const essays = useMemo(() => {
        try {
            return credits.filter((creditItem) => !!creditItem.credit && creditItem.credit.type === CreditType.Essay);
        } catch (error) {
            console.log("error", error);
            return [];
        }
    }, [credits]);

    const projects = useMemo(() => {
        try {
            return credits.filter((creditItem) => !!creditItem.project && creditItem.credit?.type === CreditType.Project);
        } catch (error) {
            console.log("error", error);
            return [];
        }
    }, [credits]);

    return (
        <>
            <StudentAreaScreenSignedCoursesCardCreditsContainer>
                <StudentAreaScreenSignedCoursesCardDescription>O plano inclui:</StudentAreaScreenSignedCoursesCardDescription>

                {courses.map((credit) => (
                    <StudentAreaScreenSignedCoursesCardCredit key={credit.id}>
                        <span>
                            <CheckIcon width={12} color={theme.colors.system.success[300]} />
                        </span>
                        <p>{credit.course?.name}</p>
                    </StudentAreaScreenSignedCoursesCardCredit>
                ))}
            </StudentAreaScreenSignedCoursesCardCreditsContainer>

            {!!essays.length && (
                <StudentAreaScreenSignedCoursesCardCreditsContainer>
                    <StudentAreaScreenSignedCoursesCardDescription>Créditos de redação</StudentAreaScreenSignedCoursesCardDescription>

                    {essays.map((credit) => (
                        <StudentAreaScreenSignedCoursesCardCredit key={credit.id}>
                            <span>{credit.quantity}</span>
                            <p>Créditos</p>
                        </StudentAreaScreenSignedCoursesCardCredit>
                    ))}
                </StudentAreaScreenSignedCoursesCardCreditsContainer>
            )}

            {!!projects.length && (
                <StudentAreaScreenSignedCoursesCardCreditsContainer>
                    <StudentAreaScreenSignedCoursesCardDescription>Projetos</StudentAreaScreenSignedCoursesCardDescription>

                    {projects.map((credit) => (
                        <StudentAreaScreenSignedCoursesCardCredit key={credit.id}>
                            <span>
                                <ProjectIcon width={14} />
                            </span>
                            <p>{credit.project?.name}</p>

                            <Button variant="outline-primary" onClick={() => history.push(`/app/curso/${courseSlug}/projetos/${credit.project?.id}`)}>
                                Ver <ChevronRight width={4} />
                            </Button>
                        </StudentAreaScreenSignedCoursesCardCredit>
                    ))}
                </StudentAreaScreenSignedCoursesCardCreditsContainer>
            )}
        </>
    );
};

export default React.memo(StudentAreaScreenSignedCoursesCredits);
