// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityStepFormItemProps, ActivityStepFormProps } from "./activity-step-form.types";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Styles
import {
    Container,
    ActivityStepFormItemComposition,
    ActivityStepFormCompositionsWrapper,
    ActivityStepFormContentWrapper,
    ActivityStepFormCurrentStepIndicator,
    ActivityStepFormDescription,
    ActivityStepFormInformationWrapper,
    ActivityStepFormSelector,
    ActivityStepFormSelectorItem,
    ActivityStepFormTitle,
    ActivityStepFormItemContainer,
    ActivityStepFormFooterButtonCompositionsWrapper,
    ContentWrapper
} from "./activity-step-form.styles";

// Utils
import { ternary } from "utils/ternary";

export const ActivityStepForm: FunctionComponent<ActivityStepFormProps> = ({
    title,
    description,
    currentStepIndex,
    formCompositions,
    headerActionElement,
    footerButtonCompositions
}) => {
    return (
        <Container>
            <ContentWrapper>
                {headerActionElement}

                <ActivityStepFormContentWrapper>
                    <ActivityStepFormTitle>{title}</ActivityStepFormTitle>

                    <ConditionallyRender
                        shouldRender={formCompositions.length > 1}
                        content={
                            <ActivityStepFormSelector>
                                {formCompositions.map((_, index) => {
                                    const selectorItemVariant = ternary([
                                        [index === currentStepIndex, "current"],
                                        [index < currentStepIndex, "checked"],
                                        [true, "default"]
                                    ]) as any;

                                    return <ActivityStepFormSelectorItem key={index} variant={selectorItemVariant} />;
                                })}
                            </ActivityStepFormSelector>
                        }
                    />

                    <ActivityStepFormInformationWrapper>
                        <ConditionallyRender
                            shouldRender={formCompositions.length > 1}
                            content={
                                <ActivityStepFormCurrentStepIndicator>
                                    Etapa {currentStepIndex + 1} de {formCompositions.length}
                                </ActivityStepFormCurrentStepIndicator>
                            }
                        />

                        <ActivityStepFormDescription>{description}</ActivityStepFormDescription>
                    </ActivityStepFormInformationWrapper>

                    <ActivityStepFormCompositionsWrapper>
                        {formCompositions.map((item, index) => (
                            <ActivityStepFormItemComposition key={index} isActive={index === currentStepIndex}>
                                {item}
                            </ActivityStepFormItemComposition>
                        ))}
                    </ActivityStepFormCompositionsWrapper>
                </ActivityStepFormContentWrapper>
            </ContentWrapper>

            <ConditionallyRender
                shouldRender={!!footerButtonCompositions}
                content={
                    <ActivityStepFormFooterButtonCompositionsWrapper>{footerButtonCompositions}</ActivityStepFormFooterButtonCompositionsWrapper>
                }
            />
        </Container>
    );
};

export const ActivityStepFormItem: FunctionComponent<ActivityStepFormItemProps> = ({ content }) => {
    return <ActivityStepFormItemContainer>{content}</ActivityStepFormItemContainer>;
};
