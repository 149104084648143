import { theme } from "config/theme";
import styled from "styled-components/macro";
import { hexToRgba } from "helpers";
import { TitleH4 } from "assets/styles/global";
import { ForumPostContainer } from "./components/post/styles";
import { AttachmentsContainer } from "../components/attachments/styles";
import { ForumQuestionDetailsContainer, ForumQuestionDetailsHelper } from "../components/details/styles";

export const ForumQuestionContainer = styled.div`
    .ck-content {
        * {
            user-select: unset;
            pointer-events: unset;
        }
    }
`;

export const ForumQuestionContent = styled.div<{ color?: string }>`
    background: ${({ color }) => (color ? hexToRgba(color, 0.2) : theme.colors.base[100])};
    box-shadow: ${theme.shadow[1]};
    overflow: hidden;
`;

export const ForumQuestionContentTop = styled.div`
    padding: ${theme.spacing.largeX} 0 ${theme.spacing.large};

    ${AttachmentsContainer} {
        margin-top: ${theme.spacing.large};
    }

    ${ForumQuestionDetailsContainer} {
        margin-bottom: ${theme.spacing.medium};
    }

    ${ForumPostContainer} {
        margin: 32px 0 0 0;
    }
`;

export const ForumQuestionWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.medium};
`;

export const ForumQuestionContentBottom = styled.div`
    padding: ${theme.spacing.medium} 0;

    ${ForumQuestionDetailsHelper} {
        flex: unset;
        margin: 0 0 0 ${theme.spacing.medium};
    }

    ${ForumQuestionWrapper} {
        justify-content: flex-end;
        margin-bottom: 0;
    }
`;

export const ForumQuestionText = styled.div`
    p {
        color: ${theme.colors.base[400]};
        ${TitleH4};
    }
`;

export const ForumQuestionAnswers = styled.div`
    background: ${theme.colors.base[100]};
    box-shadow: ${theme.shadow[1]};
    padding: ${theme.spacing.largeX} 0;

    &:not(:last-child) {
        margin-bottom: 2px;
    }

    ${ForumPostContainer} {
        margin-bottom: 0;
    }
`;

export const ForumQuestionAnswersTitle = styled.h2`
    ${TitleH4};
    color: #25272d;
    margin-bottom: ${theme.spacing.largeX};

    span {
        color: #afafaf;
    }
`;

export const ForumQuestionAnswersHelper = styled.span`
    ${TitleH4};
    color: ${theme.colors.base[500]};
    display: block;
    margin-bottom: ${theme.spacing.small};
`;

export const ForumAnswersWrapper = styled.div``;

export const LoaderWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;
