import Bowser from "bowser";

const DEFAULT_BROWSER = "unknown";

export function getBrowser() {
    try {
        const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();

        return {
            name: browser?.name ?? DEFAULT_BROWSER,
            version: browser?.version
        };
    } catch (error) {
        return {
            name: DEFAULT_BROWSER,
            version: undefined
        };
    }
}
