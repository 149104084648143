// Dependencies
import { theme } from "config/theme";
import styled, { css } from "styled-components";

export const Label = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #7d8396;
    background-color: #ffffff;

    position: absolute;
    left: 12px;
    top: -14px;
`;

export const UnderSelectMessage = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

type ContainerStyleProps = {
    hasError?: boolean;
    width?: number;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    position: relative;

    ${({ width }) =>
        width &&
        css`
            width: ${width}%;
        `}

    ${({ hasError }) =>
        hasError &&
        css`
            ${Label} {
                color: #e40000;
            }

            ${UnderSelectMessage} {
                color: #e40000;
            }
        `}
`;

export const CurrentOption = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4d4d63;
`;

type ValueWrapperStyleProps = {
    isOpen: boolean;
};

export const ValueWrapper = styled.div<ValueWrapperStyleProps>`
    position: relative;

    width: 100%;
    height: 42px;
    padding: 10px 12px;
    background-color: #ffffff;
    border: 1px solid #83899e;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;

    ${({ isOpen }) =>
        isOpen &&
        css`
            border-color: #3e68d2;

            ${Label} {
                color: #3e68d2;
            }
        `}
`;

export const InputOpenedIndicatorIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const OptionsWrapper = styled.div`
    position: absolute;
    max-height: 320px;
    overflow-y: auto;

    width: 100%;
    padding: 8px;
    background-color: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 4;
`;

type OptionProps = {
    value: string | number;
    type: "default" | "list";
    isActive?: boolean;
};

export const Option = styled.p<OptionProps>`
    width: 100%;
    padding: 8px 12px;
    border-radius: 4px;

    cursor: pointer;

    ${({ type }) =>
        type === "default" &&
        css`
            font-family: "Nunito Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4d4d63;

            :hover {
                background-color: ${theme.colors.brand[300]};
                color: #ffffff;
            }
        `}

    ${({ type, isActive }) =>
        type === "list" &&
        css`
            font-family: "Nunito", sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.005em;
            color: #83899e;
            border: 1px solid #83899e;
            text-align: center;

            ${isActive &&
                css`
                    border-color: ${theme.colors.brand[300]};
                    color: ${theme.colors.brand[300]};
                `}
        `}
`;

export const UnderSelectMessageWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const UnderSelectMessageIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;
