import React, { useMemo } from "react";

import { IEssayComposition } from "store/interfaces/IEssay";
import Competences from "../competences";

import * as S from "./styles";

interface IEssayGradesProps {
    composition: IEssayComposition;
}

const EssayGrades = ({ composition }: IEssayGradesProps) => {
    const getStatsTotal = useMemo(() => Number(composition?.revisions[0]?.score ?? 0).toLocaleString("pt-br", { minimumFractionDigits: 2 }), [
        composition
    ]);
    const getCommentsTotal = useMemo(() => composition?.comments?.length ?? 0, [composition]);

    return (
        <S.EssayGradesContainer>
            <S.EssayGradesHeader>
                <span className="grades__text">
                    Nota final: <strong>{getStatsTotal}</strong>
                </span>
                <span className="grades__comments">
                    Comentários: <strong>{getCommentsTotal}</strong>
                </span>
            </S.EssayGradesHeader>
            {!!composition?.competences.length && <Competences composition={composition} />}
        </S.EssayGradesContainer>
    );
};

export default EssayGrades;
