import React, { useState, useMemo, memo, useCallback, useEffect } from "react";

// Dependencies
import CloseIcon from "prodigio-icons/web/outlined/Close";
import FilterIcon from "prodigio-icons/web/filled/Filter";
import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";

// Components
import Button from "components/Button";
import FilterGroup from "components/FilterGroup";

// Helpers
import Portal from "components/Portal";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { FilterModalBackground, FilterModalContainer, FilterModalCloseButton, FilterModalHeader, FilterModalButton } from "./styles";

interface IProps {
    options: IFilterGroup[];
    size?: "small" | "normal" | "large";
    withButtonLabel?: boolean;
    onChange(options: IFilterGroup[]): void;
}

const sizes = {
    small: 8,
    normal: 16,
    large: 24
};

const FilterModal = memo(({ options = [], size = "normal", withButtonLabel = true, onChange }: IProps) => {
    const isMobile = useIsMobile(BreakPoints.tablet);
    const [activeModal, setActiveModal] = useState(false);
    const [selections, setSelections] = useState<IFilterGroup[]>(options);

    useEffect(() => {
        if (Boolean(options.length) && !Boolean(selections.length)) {
            setSelections(options);
        }
    }, [options, selections.length]);

    const handleModal = useCallback((state: boolean) => () => setActiveModal(state), []);

    const handleChange = useCallback(() => {
        onChange(selections);
        handleModal(false)();
    }, [handleModal, onChange, selections]);

    const memoButton = useMemo(
        () => (
            <FilterModalButton type="button" onClick={handleModal(true)}>
                <FilterIcon className="ico-filter" width={sizes[size]} height={sizes[size]} />
                {withButtonLabel && <span>Filtrar</span>}
                <ChevronDown width={8} height={6} className="ico-chevron-down" />
            </FilterModalButton>
        ),
        [handleModal, size, withButtonLabel]
    );

    const memoModalHeader = useMemo(
        () => (
            <FilterModalHeader>
                <FilterModalCloseButton type="button" onClick={handleModal(false)}>
                    {isMobile ? <ChevronDown width={16} height={8} /> : <CloseIcon width={16} height={16} />}
                </FilterModalCloseButton>
            </FilterModalHeader>
        ),
        [handleModal, isMobile]
    );

    const memoModal = useMemo(() => {
        if (!activeModal) {
            return null;
        }

        return (
            <FilterModalBackground>
                <FilterModalContainer>
                    {memoModalHeader}
                    <FilterGroup options={selections} onChange={setSelections} withHeader={true} />
                    <Button type="button" onClick={handleChange} variant="primary" size="medium">
                        Aplicar
                    </Button>
                </FilterModalContainer>
            </FilterModalBackground>
        );
    }, [activeModal, handleChange, memoModalHeader, selections]);

    return (
        <>
            {memoButton}
            <Portal portalId="portal-filter">{activeModal ? memoModal : null}</Portal>
        </>
    );
});

export default FilterModal;
