import styled from "styled-components";
import { CardStyles } from "components/Card/styles";
import { theme } from "config/theme";

export const WatchScreenForumErrorContainer = styled.div`
    ${CardStyles};

    margin-top: ${theme.spacing.large};

    svg {
        display: none;
    }
`;
