// Dependencies
import React, { Fragment, FunctionComponent } from "react";

// Types
import { ActivityBottomSheetProps } from "./activity-bottom-sheet.types";

// Styles
import { Container, InnerContainer, Wrapper, ContentWrapper, Label, ActionButtonsWrapper } from "./activity-bottom-sheet.styles";

// Components
import { ActivityOverlay } from "components/ActivityOverlay";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityBottomSheet: FunctionComponent<ActivityBottomSheetProps> = ({
    label,
    content,
    type = "default",
    actionButtonCompositions,
    handleOutsideClick
}) => {
    return (
        <Fragment>
            <ActivityOverlay
                handleOutsideClick={handleOutsideClick}
                content={
                    <Wrapper className="webapp-bottom-sheet">
                        <Container>
                            <InnerContainer>
                                <Label>{label}</Label>

                                <ContentWrapper type={type}>{content}</ContentWrapper>
                            </InnerContainer>

                            <ConditionallyRender
                                shouldRender={!!actionButtonCompositions}
                                content={<ActionButtonsWrapper>{actionButtonCompositions}</ActionButtonsWrapper>}
                            />
                        </Container>
                    </Wrapper>
                }
            />
        </Fragment>
    );
};
