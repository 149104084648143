import IExerciseListScreenresolutions from "../interfaces/IExerciseListScreenresolutions";

interface IProps {
    questionId: number;
    questionsResolutions: IExerciseListScreenresolutions[];
}

const getCurrentQuestionResolutions = ({ questionId, questionsResolutions }: IProps) => {
    try {
        if (!questionsResolutions || !questionId) {
            throw new Error("resolutions not found");
        }

        const getresolutions = questionsResolutions.find((questionsResolution) => questionsResolution.questionId === questionId);

        if (!getresolutions || !getresolutions.resolutions) {
            throw new Error("question not have resolutions");
        }

        return getresolutions.resolutions;
    } catch (error) {
        return [];
    }
};

export default getCurrentQuestionResolutions;
