import { DateTime, Interval } from "luxon";

export const hexToRgba = (hex: string, alpha?: number) => {
    const regx = new RegExp(`.{1,${hex.length > 4 ? 2 : 1}}`, "g");
    const pairs = hex
        .replace("#", "")
        .match(regx)!
        .map((pair) => parseInt(pair.length === 1 ? pair + pair : pair, 16));

    const rgba = `rgba(${pairs.join(", ")}, ${alpha || 1})`;

    return rgba;
};

export const lightenDarkenColor = (col: string, percent: number) => {
    const color = col.split("#")[1];

    const num = parseInt(color, 16);
    // tslint:disable-next-line: binary-expression-operand-order
    const amt = Math.round(2.55 * percent);

    const R = (num >> 16) + amt;
    // tslint:disable-next-line: number-literal-format
    const B = ((num >> 8) & 0x00ff) + amt;
    // tslint:disable-next-line: number-literal-format
    const G = (num & 0x0000ff) + amt;

    // tslint:disable-next-line: number-literal-format
    return `#${
        // tslint:disable-next-line: binary-expression-operand-order
        (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
            .toString(16)
            .slice(1)
    }`;
};

export const compareObjects = (obj1: any, obj2: any) => {
    // tslint:disable-next-line: forin
    for (const p in obj1) {
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
            return false;
        }

        switch (typeof obj1[p]) {
            //Deep compare objects
            case "object":
                if (!compareObjects(obj1[p], obj2[p])) {
                    return false;
                }
                break;
            //Compare function code
            case "function":
                // tslint:disable-next-line: triple-equals
                if (typeof obj2[p] === "undefined" || (p !== "compare" && obj1[p].toString() !== obj2[p].toString())) {
                    return false;
                }
                break;
            //Compare values
            default:
                // tslint:disable-next-line: triple-equals
                if (obj1[p] !== obj2[p]) {
                    return false;
                }
        }
    }

    //Check object 2 for any extra properties
    for (const p in obj2) {
        if (typeof obj1[p] === "undefined") {
            return false;
        }
    }

    return true;
};

export const MASK_CPF = [/[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, ".", /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/];
export const MASK_ZIPCODE = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/];
export const MASK_PHONE = ["(", /[0-9]/, /[0-9]/, ")", " ", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const MASK_BIRTHDAY = [/[0-3]/, /[0-9]/, "/", /[0-1]/, /[0-9]/, "/", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

export const regexes = {
    phone: /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/,
    password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/,
    cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    birthDate: /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/
};

export const getIntervalBetweenDates = (start: string, end: string) =>
    Interval.fromDateTimes(DateTime.fromISO(start, { zone: "utc" }).startOf("day"), DateTime.fromISO(end, { zone: "utc" }).startOf("day"))
        .splitBy({
            days: 1
        })
        .map((d) => d.end);
