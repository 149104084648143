import styled from "styled-components";
import { theme } from "config/theme";
import Card from "components/Card";
import { MediaQueries } from "assets/styles/settings";
import { SpinnerWrap } from "components/Spinner/styles";
import { Col } from "components/Grid";

export const ExerciseListDashboardContainer = styled.div``;

export const ExerciseListDashboardTip = styled(Card)`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.base[200]};
    border-radius: 4px;
    margin-bottom: ${theme.spacing.small};

    align-self: stretch;
    width: 100%;

    p {
        font-family: "Nunito Sans", sans-serif;
        font-weight: 400;
        font-size: ${theme.typography.sizes.smallX};
        line-height: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
    }
`;

export const ExerciseRealizationsEmpty = styled(Card)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 140px;

    p {
        font-family: ${theme.typography.family.secondary};
        font-size: ${theme.typography.sizes.small};
        line-height: 20px;
        color: ${theme.colors.black};
        margin-top: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 100%;
        margin-top: 0px;

        p {
            font-size: ${theme.typography.sizes.smallX};
        }
    }
`;

export const ExerciseListDashboardLoadingAnswerCards = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing.small};

    ${SpinnerWrap} {
        display: flex;
        align-items: center;
    }

    .spinner {
        margin: 0;
    }
`;

export const ExerciseListDashboardAnswerCardResultMore = styled(Col).attrs({
    xs: 12
})`
    margin-bottom: ${theme.spacing.medium};

    button {
        margin: 0 auto;
    }
`;

export const ContentButtons = styled.div`
    width: 100%;

    div {
        display: flex;
        flex-wrap: wrap;
        gap: ${theme.spacing.small};
    }
`;
