// Dependencies
import React, { FunctionComponent, useCallback, useState } from "react";

// Types
import { PerformanceAreaCardProps } from "./performance-area-card.types";

// Styles
import {
    Container,
    AreaCardWrapper,
    AreaCardHeaderWrapper,
    AreaCardHeader,
    IconWrapper,
    Icon,
    AreaCardTitle,
    AreaCardContentWrapper,
    AreaCardContent,
    AreaCardQuestionsInfoWrapper,
    AreaCardQuestionsInfo,
    Badge,
    BadgeTooltip,
    AreaCardBorder
} from "./performance-area-card.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

// Utils
import { leftPad } from "helpers/left-pad";

export const PerformanceAreaCard: FunctionComponent<PerformanceAreaCardProps> = ({
    totalHitsPercentage,
    icon,
    totalQuestions,
    subjectTitle,
    borderColor,
    hoverColor,
    radialElement,
    badgeInformationElement,
    actionIconElement,
    badgeIconElement,
    handleClick
}) => {
    const [isBadgeTooltipVisible, setIsBadgeTooltipVisible] = useState(false);

    const handleBadgeClick = useCallback(
        (event: any) => {
            if (window.innerWidth >= 1024) return;

            event.stopPropagation();
            setIsBadgeTooltipVisible(!isBadgeTooltipVisible);
        },
        [isBadgeTooltipVisible]
    );

    return (
        <Container hoverColor={hoverColor} onClick={handleClick}>
            <AreaCardWrapper>
                <AreaCardHeaderWrapper>
                    <AreaCardHeader>
                        <IconWrapper backgroundColor={icon.background}>
                            <Icon src={icon.src} />
                        </IconWrapper>

                        <Spacing direction="horizontal" size={16} />

                        <AreaCardTitle>{subjectTitle}</AreaCardTitle>
                    </AreaCardHeader>

                    <ConditionallyRender
                        shouldRender={!!badgeIconElement}
                        content={
                            <>
                                <Spacing direction="horizontal" size={16} />

                                <Badge>
                                    <ConditionallyRender
                                        shouldRender={!!badgeInformationElement}
                                        content={<BadgeTooltip isVisible={isBadgeTooltipVisible}>{badgeInformationElement}</BadgeTooltip>}
                                    />

                                    <div onClick={handleBadgeClick}>{badgeIconElement}</div>
                                </Badge>
                            </>
                        }
                    />
                </AreaCardHeaderWrapper>

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={8} />} />

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                <AreaCardContentWrapper>
                    <AreaCardContent>
                        {radialElement}

                        <ScreenWidthRender
                            actionAfterRenderingWidth="hide"
                            renderingWidth={1024}
                            content={<Spacing direction="horizontal" size={8} />}
                        />

                        <ScreenWidthRender
                            actionAfterRenderingWidth="show"
                            renderingWidth={1024}
                            content={<Spacing direction="horizontal" size={16} />}
                        />

                        <AreaCardQuestionsInfoWrapper>
                            <AreaCardQuestionsInfo>Questões: {leftPad(totalQuestions, 2)}</AreaCardQuestionsInfo>

                            <ScreenWidthRender
                                actionAfterRenderingWidth="hide"
                                renderingWidth={1024}
                                content={<Spacing direction="horizontal" size={8} />}
                            />

                            <AreaCardQuestionsInfo>Acertos: {leftPad(totalHitsPercentage, 2)}%</AreaCardQuestionsInfo>

                            <ScreenWidthRender
                                actionAfterRenderingWidth="hide"
                                renderingWidth={1024}
                                content={<Spacing direction="horizontal" size={8} />}
                            />
                        </AreaCardQuestionsInfoWrapper>
                    </AreaCardContent>

                    {actionIconElement}
                </AreaCardContentWrapper>
            </AreaCardWrapper>

            <AreaCardBorder backgroundColor={borderColor} />
        </Container>
    );
};
