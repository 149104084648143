import React from "react";

// Dependencies
import QuestionIcon from "prodigio-icons/web/outlined/Question";

// Components
import Dropdown from "components/Dropdown";

// Assets
import { DropdownContainer, DropdownIconContainer, DropdownTextContainer } from "./styles";

const ZipCodeDropdown = () => {
    return (
        <DropdownContainer>
            <Dropdown
                direction="bottom"
                label={
                    <DropdownIconContainer>
                        <QuestionIcon />
                    </DropdownIconContainer>
                }
            >
                <DropdownTextContainer>
                    <div>
                        <p>Não sabe seu CEP?</p>
                        <p>
                            <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/" rel="noopener noreferrer" target="_blank">
                                <strong>Clique aqui.</strong>
                            </a>
                        </p>
                    </div>
                </DropdownTextContainer>
            </Dropdown>
        </DropdownContainer>
    );
};

export default ZipCodeDropdown;
