import React, { memo, useMemo } from "react";

import Dropzone from "components/Dropzone";

import * as S from "./styles";
import { UploaderContainer, UploaderFileName } from "components/Uploader/styles";

import Button from "components/Button";
import { DropzoneContainer } from "components/Dropzone/styles";
import IconSucess from "prodigio-icons/web/filled/Sucess";

interface IEssayAttachProps {
    files: File[];
    onSend?(files: File[]): void;
    onUpload(files: File[]): void;
    onClearFiles(): void;
    onClearFileImage?(): void;
    fileImage?: string;
}

const EssayAttach = ({ files, onClearFiles, onSend = () => null, onUpload, fileImage, onClearFileImage }: IEssayAttachProps) => {
    const previewFile = useMemo(() => {
        if (!fileImage) {
            return null;
        }

        return (
            <DropzoneContainer isDragging={false}>
                <UploaderContainer>
                    <IconSucess width={23} height={23} />
                    <UploaderFileName style={{ marginBottom: "20px" }}>Sua redação foi salva com sucesso!</UploaderFileName>
                    <S.EssayAttachButtonGroup>
                        <Button variant="info" size="medium" as="a" href={fileImage} target="_blank">
                            Visualizar
                        </Button>

                        <Button variant="danger" size="medium" type="button" onClick={onClearFileImage}>
                            Remover
                        </Button>
                    </S.EssayAttachButtonGroup>
                </UploaderContainer>
            </DropzoneContainer>
        );
    }, [fileImage, onClearFileImage]);

    return (
        <S.EssayAttachContainer>
            <S.EssayAttachCol>
                <S.EssayAttachInstructions>
                    <S.EssayAttachInstructionsItem>Baixe o modelo da folha de redação;</S.EssayAttachInstructionsItem>
                    <S.EssayAttachInstructionsItem>Redija sua redação de acordo com o tema;</S.EssayAttachInstructionsItem>
                    <S.EssayAttachInstructionsItem>Digitalize sua redação;</S.EssayAttachInstructionsItem>
                    <S.EssayAttachInstructionsItem>Envie de volta.</S.EssayAttachInstructionsItem>
                </S.EssayAttachInstructions>
                {previewFile ?? (
                    <Dropzone
                        accept="image/x-png,image/jpeg,image/png,image/jpg"
                        files={files}
                        onUpload={onUpload}
                        onClearFiles={onClearFiles}
                        onSend={onSend}
                    />
                )}
            </S.EssayAttachCol>
        </S.EssayAttachContainer>
    );
};

export default memo(EssayAttach);
