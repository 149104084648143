import React, { useState } from "react";

// Assets
import Icon from "components/Icons/icon";
import { INotification } from "interfaces/IAblyCallback";
// import { AblyServiceWatchNotification } from "services/ablyServiceWatch";
import { IAnnouncementActions } from "store/interfaces/IActions";
import {
    AnnouncementToastCard,
    AnnouncementToastCardContent,
    AnnouncementToastCardHeader,
    AnnouncementToastClose,
    AnnouncementToastContainer
} from "./styles";

interface IProps {
    announcementActions: IAnnouncementActions;
    // handleClose(data: any): void;
    // announcements: IAnnouncementState[];
    onOpenModal(data: any): void;
}

const AnnouncementToast = ({ onOpenModal, announcementActions }: IProps) => {
    const [notifications, setNotifications] = useState<INotification[]>([]);

    // useEffect(() => {
    //     listenAblyChanges();
    //     // eslint-disable-next-line
    // }, []);

    // // ABLY
    // const listenAblyChanges = () => {
    //     try {
    //         AblyServiceWatchNotification({
    //             onNewNotification: (notification: INotification) => {
    //                 const newData = [...notifications, notification];
    //                 setNotifications(newData as any);

    //                 announcementActions.createOrEditAnnouncementsSuccess({ id: notification.id, notification });

    //                 setTimeout(() => removeNotification(notification.id), 5000);
    //             }
    //         });
    //     } catch (error) {
    //         console.log("error", error);
    //     }
    // };

    const removeNotification = (id: any) => {
        const updateNotifications = notifications.filter((item: INotification) => item.id !== id);
        setNotifications(updateNotifications as any);
    };

    const handleOpenModal = (notification: INotification) => {
        onOpenModal(notification);
        removeNotification(notification.id);
    };

    return (
        <AnnouncementToastContainer>
            {!!notifications &&
                notifications.map((notification: INotification) => (
                    <AnnouncementToastCard key={notification.id}>
                        <AnnouncementToastCardHeader>
                            <span onClick={() => handleOpenModal(notification)}>{notification.signature}</span>
                            <AnnouncementToastClose onClick={() => removeNotification(notification.id)}>
                                <Icon idIcon="ico-close-modal" width="15" height="15" viewBox="0 0 12 12" />
                            </AnnouncementToastClose>
                        </AnnouncementToastCardHeader>
                        <AnnouncementToastCardContent onClick={() => handleOpenModal(notification)}>
                            {notification.title}
                        </AnnouncementToastCardContent>
                    </AnnouncementToastCard>
                ))}
        </AnnouncementToastContainer>
    );
};

export default AnnouncementToast;
