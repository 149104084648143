import { theme } from "config/theme";
import { lightenDarkenColor } from "helpers";
import styled from "styled-components";

import { IProps } from ".";

export const TextareaField = styled.textarea<IProps>`
    border-radius: 3px;
    border: 1px solid ${(props) => (props.limit ? "red" : "#CCC")};
    display: block;
    font-size: 15px;
    margin-bottom: 20px;
    min-height: 56px;
    padding: 7px 13px;
    resize: none;
    width: 100%;
    transition: all 0.2s ease;

    &:hover {
        background: ${lightenDarkenColor(theme.colors.base[100], 2)};
    }

    &:focus {
        background: ${theme.colors.white};
        border-color: ${(props) => (props.limit ? "red" : theme.colors.secondary[200])};
    }
`;
