// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityRadioProps } from "./activity-radio.types";

// Styles
import { Container, ActivityRadioCircle, Label, ActivityRadioCircleIcon, ActivityRadioCircleWrapper } from "./activity-radio.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Assets
import check from "assets/img/check.svg";

export const ActivityRadio: FunctionComponent<ActivityRadioProps> = ({ isChecked, hasCheckIcon, label, variant = "default", handleClick }) => {
    return (
        <Container className="webapp-checkbox">
            <ActivityRadioCircleWrapper variant={variant} isChecked={!!isChecked} {...(!!handleClick && { onClick: handleClick })}>
                <ActivityRadioCircle variant={variant} isChecked={!!isChecked} />

                <ConditionallyRender shouldRender={!!hasCheckIcon} content={<ActivityRadioCircleIcon src={check} />} />
            </ActivityRadioCircleWrapper>

            <ConditionallyRender shouldRender={!!label} content={<Label isChecked={!!isChecked}>{label}</Label>} />
        </Container>
    );
};
