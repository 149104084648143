import { theme } from "config/theme";
import { Col } from "components/Grid";
import styled from "styled-components/macro";
import { BodyText2, TitleH4 } from "assets/styles/global";
import { AccordionItem, AccordionLabel } from "components/Accordion/styles";
import Select from "components/Select";

export const OrderProblemsContainer = styled.div`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: ${theme.spacing.smallXX};
    margin-bottom: ${theme.spacing.small};
    padding: ${theme.spacing.small};

    ${AccordionLabel} {
        ${BodyText2};
    }

    ${AccordionItem} {
        margin-bottom: ${theme.spacing.smallX};
    }
`;

export const OrderProblemsTitle = styled.h4`
    ${TitleH4};
    margin-bottom: ${theme.spacing.smallX};
`;

export const OrderProblemsText = styled.p`
    ${BodyText2};
    border-bottom: 1px solid ${theme.colors.base[100]};
    color: ${theme.colors.base[300]};
    margin-bottom: ${theme.spacing.small};
    padding-bottom: ${theme.spacing.small};
`;

export const OrderProblemsList = styled.ul``;

export const OrderProblemsListItem = styled.li``;

export const OrderCode = styled.span``;

export const OrderProductName = styled.strong``;

export const OrderAddressContainer = styled.form`
    padding: ${theme.spacing.small};

    ${Col} {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        margin-bottom: ${theme.spacing.small};
    }
`;

export const SuccessMessageContainer = styled.div`
    padding: ${theme.spacing.small};

    p {
        ${BodyText2};
        color: ${theme.colors.system.success[300]};
        font-style: italic;
    }
`;

export const SelectWrapper = styled(Select)`
    .react-select__control {
        border-radius: 5px;
        padding: 12px 8px;
    }
`;
