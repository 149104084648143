import React, { forwardRef, memo } from "react";

// Dependencies
import SelectDep from "react-select";

// Components
import Icon from "components/Icons/icon";

// Helpers
import { Props } from "react-select/lib/Select";

// Assets
import { ErrorMessage } from "assets/styles/global";
import { customStyles, IconWrapper, SelectLabel, SelectStyled, SelectContainer } from "./styles";

const DropdownIndicator = () => (
    <IconWrapper>
        <Icon idIcon="ico-caret" width="12" height="12" viewBox="0 0 307.054 307.054" />
    </IconWrapper>
);

const Select = forwardRef<SelectDep, Props>(({ isSearchable = false, label, components, error, ...rest }, ref) => {
    return (
        <SelectContainer>
            {!!label && <SelectLabel>{label}</SelectLabel>}
            <SelectStyled
                styles={customStyles}
                components={{ DropdownIndicator, ...components }}
                noOptionsMessage={() => "Nenhum resultado"}
                className="react-select-container"
                classNamePrefix="react-select"
                error={error}
                isSearchable={isSearchable}
                ref={ref}
                onChange={rest.onChange}
                {...rest}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </SelectContainer>
    );
});

export default memo(Select);
