import styled from "styled-components";

import { TitleH5, BodyText2 } from "assets/styles/global";
import { theme } from "config/theme";

import Card from "components/Card";
import Typography from "components/Typography";
import { MediaQueries } from "assets/styles/settings";
import { BadgeContainer } from "components/Badge/styles";
import { ButtonStyles } from "components/Button/styles";

interface IStyledProps {
    isMobile?: boolean;
    isAvailable?: boolean;
}

export const ExerciseCardTitle = styled(Typography)`
    ${TitleH5};

    color: ${theme.colors.base[500]};
    font-weight: 600;
    margin-bottom: ${theme.spacing.small};

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.3rem;
    max-height: calc(1.3rem * 2);
`;

export const ExerciseCardContainer = styled(Card)`
    margin-bottom: ${theme.spacing.small};
    padding: 0;
    display: flex;
    flex-direction: column;
`;

export const ExerciseCardContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.small};
`;

export const ListTotalQuestions = styled.ul<IStyledProps>`
    display: flex;
    flex-direction: column;
    color: #81818d;
`;

export const ItemTotalQuestions = styled.li`
    list-style: disc;
    list-style-position: inside;
`;

export const TextTotalQuestions = styled.span`
    font-size: ${theme.typography.sizes.smallX};
    line-height: ${theme.typography.sizes.mediumXX};
    color: ${theme.colors.base[300]};
    margin: -${theme.spacing.smallX};
`;

export const ApplicationPeriodContent = styled.div`
    display: flex;
    font-size: ${theme.typography.sizes.small};
    margin-right: ${theme.spacing.smallX};

    svg {
        margin-right: ${theme.spacing.smallX};
        width: 16px;
        height: 16px;
    }
`;

export const DateApplicationPeriod = styled.span`
    line-height: ${theme.typography.sizes.medium};
    letter-spacing: 0.01em;
    color: ${theme.colors.base[400]};
    font-size: 10px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.small};
    }
`;

export const PerformanceContainer = styled.div<IStyledProps>`
    position: relative;
    margin-left: ${theme.spacing.small};
    margin-right: ${theme.spacing.small};

    div {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const Divider = styled.hr`
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${theme.colors.base[100]};
    text-align: center;
    margin-right: ${theme.spacing.smallXX};
    margin-left: ${theme.spacing.smallXX};
`;

export const ExerciseCardButton = styled.button.attrs({
    type: "button",
    size: "small"
})<any>`
    ${ButtonStyles};
`;

export const ExerciseCardHeader = styled.div`
    display: flex;
    margin-bottom: ${theme.spacing.small};
    justify-content: space-between;
    align-items: center;

    ${BadgeContainer} {
        font-size: 10px;

        white-space: nowrap;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            font-size: ${theme.typography.sizes.small};
        }
    }
`;

export const ExerciseCardTotalNote = styled.p`
    ${BodyText2};

    color: ${theme.colors.base[400]};

    strong {
        color: ${theme.colors.system.info[300]};
    }
`;

export const ExerciseCardChartContainer = styled.div`
    z-index: 1;
`;

export const ExerciseCardChartLegends = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const ExerciseCardChartLegendLabel = styled.span<{ color: string }>`
    color: ${theme.colors.base[500]};
    font-size: ${theme.typography.sizes.small};
    display: flex;
    align-items: center;
    margin: ${theme.spacing.small} 0;

    &:before {
        content: "";
        display: block;
        background: ${({ color }) => color};
        width: 6px;
        height: 6px;
        border-radius: 100%;
        margin-right: ${theme.spacing.smallXX};
    }
`;
