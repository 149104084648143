import React, { forwardRef, memo, useCallback, useMemo, useState } from "react";

// Dependencies
import SeePasswordIcon from "prodigio-icons/web/outlined/SeePassword";
import HidePasswordIcon from "prodigio-icons/web/outlined/HidePassword";

// Helpers
import { MaskedInputProps } from "react-text-mask";

// Assets
import { ErrorMessage } from "assets/styles/global";
import { MaskedFieldInput, TextFieldContainerTooltip, TextFieldInput, TextFieldLabel, TextFieldWrapper } from "./styles";

export interface ITextFieldProps extends MaskedInputProps {
    error?: string;
    success?: string;
    label?: string;
    mask: (string | RegExp)[];
    rightSideElement?: React.ReactNode;
}

const TextField = forwardRef<any, ITextFieldProps & any>(({ error, success, label, rightSideElement, mask, ...rest }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const passwordFieldId = rest?.name ?? "password-field";

    const handleShowPassword = useCallback(() => {
        const newValue = !showPassword;

        setShowPassword(newValue);

        document.getElementById(passwordFieldId)?.setAttribute("type", newValue ? "text" : "password");
    }, [passwordFieldId, showPassword]);

    const isPassword = useMemo(() => rest.type === "password", [rest.type]);

    return (
        <>
            <TextFieldWrapper error={error} success={success} disabled={rest.disabled}>
                {!!rightSideElement && rest.type !== "password" && <TextFieldContainerTooltip>{rightSideElement}</TextFieldContainerTooltip>}
                {!rightSideElement && isPassword && (
                    <TextFieldContainerTooltip isPasswordIcon={isPassword} onClick={handleShowPassword}>
                        {!showPassword ? <SeePasswordIcon /> : <HidePasswordIcon />}
                    </TextFieldContainerTooltip>
                )}
                {mask ? (
                    <MaskedFieldInput ref={ref} mask={mask} error={error} success={success} placeholder=" " {...rest} />
                ) : (
                    <TextFieldInput ref={ref} error={error} success={success} placeholder=" " id={passwordFieldId} {...rest} />
                )}
                {!!label && (
                    <TextFieldLabel error={error} success={success}>
                        {label}
                    </TextFieldLabel>
                )}
            </TextFieldWrapper>
            {error && <ErrorMessage data-test-id="error-message">{error}</ErrorMessage>}
        </>
    );
});

export default memo(TextField);
