// Dependencies
import React, { FunctionComponent } from "react";

// Components
import { WebpButton } from "components/WebpButton";

// Styles
import { Container } from "./styles";

// Assets
import checked from "./assets/checked.svg";

export const FlatVariants = {
    PRIMARY: "primary",
    SECONDARY: "secondary"
} as const;

export type FlatVariant = typeof FlatVariants[keyof typeof FlatVariants];

type WebpNewCardSelectedProps = {
    img: string;
    labelTitle: string;
    desc: string;
    buttonKnowMore?: string;
    labelButton?: string;
    active: boolean;
    variant: FlatVariant;
    onClickButton: () => void;
    onClickButtonKnowMore?: () => void;
};

export const WebpNewCardSelected: FunctionComponent<WebpNewCardSelectedProps> = ({
    active,
    buttonKnowMore,
    onClickButton,
    onClickButtonKnowMore,
    desc,
    img,
    labelTitle,
    variant,
    labelButton
}) => {
    const handleClick = () => {
        onClickButton();
    };

    const handleClickKnowMore = () => {
        if (!!onClickButtonKnowMore) {
            onClickButtonKnowMore();
        }
    };

    return (
        <Container className="webp-new-card-selected" variant={variant === "primary" ? "brand" : variant} active={active}>
            <div className="box-image" onClick={() => handleClick()}>
                <img src={img} alt="" />
                <h2>{labelTitle}</h2>
                <p>{desc}</p>
            </div>

            {labelButton && (
                <WebpButton
                    activeClickActive={false}
                    active={active}
                    variant={(active ? variant : `outline-${variant}`) as any}
                    onClick={() => handleClick()}
                    contentButtonComposition={
                        <>
                            {labelButton}
                            {active && <img src={checked} alt="" />}
                        </>
                    }
                ></WebpButton>
            )}

            {buttonKnowMore && (
                <div className="know-more" onClick={() => handleClickKnowMore()}>
                    {buttonKnowMore}
                    <b>{">"}</b>
                </div>
            )}
        </Container>
    );
};
