import { menuColors, theme } from "config/theme";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";

interface IMenuListStyleProps {
    expanded: boolean;
}

export const MenuListWrapper = styled.nav<IMenuListStyleProps>`
    flex: 1;
    margin-top: ${theme.spacing.small};
    overflow-y: auto;

    h3 {
        font-family: ${theme.typography.family.secondary};
        font-size: 14px;
        line-height: 22px;
        color: ${menuColors.text || theme.colors.brand[300]};
        margin: 0 10px 8px;
        font-weight: 600;
    }
`;

export const MenuListItemContainer = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    position: relative;
    white-space: nowrap;
    transition: all 0.3s;
    transition: background 0.3s;

    svg {
        fill: ${menuColors.text || theme.colors.base[400]};
        color: ${menuColors.text || theme.colors.base[400]};
        transition: all 0.3s;
    }

    p {
        font-weight: 600;
        font-size: ${theme.typography.sizes.smallX};
        line-height: 22px;
        color: ${menuColors.text || theme.colors.base[400]};
        margin-left: 15px;
        transition: all 0.3s;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0px;
        width: 4px;
        height: 50px;
        transition: background 0.3s;
    }

    &:hover {
        background: ${theme.colors.base[100]};

        svg {
            fill: ${theme.colors.base[500]};
            color: ${theme.colors.base[500]};
        }

        p {
            color: ${theme.colors.base[500]};
        }

        &:before {
            background: ${theme.colors.base[200]};
        }
    }

    a.selected {
        background: ${menuColors.activeItemBackground || theme.colors.base[100]};

        p {
            color: ${menuColors.activeText || theme.colors.brand[400]};
        }

        svg {
            fill: ${menuColors.activeText || theme.colors.brand[300]};
            color: ${menuColors.activeText || theme.colors.brand[300]};
        }

        &:before {
            content: "";
            position: absolute;
            left: 0px;
            width: 4px;
            height: 50px;
            background: ${menuColors.activeText || theme.colors.brand[300]};
        }

        &:hover {
            background: ${menuColors.activeItemBackground || theme.colors.base[100]};

            p {
                color: ${menuColors.activeText || theme.colors.brand[500]};
            }

            svg {
                fill: ${menuColors.activeText || theme.colors.brand[400]};
                color: ${menuColors.activeText || theme.colors.brand[400]};
            }

            &:before {
                background: ${menuColors.activeText || theme.colors.brand[300]};
            }
        }
    }
`;

export const MenuListItemStyles = css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    color: ${menuColors.text || theme.colors.secondary[100]};

    p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

export const MenuListItem = styled(NavLink)`
    ${MenuListItemStyles};
`;

export const MenuListItemExternal = styled.a`
    ${MenuListItemStyles};
`;

export const MenuListItemButton = styled.button.attrs({ type: "button" })`
    ${MenuListItemStyles};
`;

export const MenuListBallonContainer = styled.span`
    position: fixed;
    left: 80px;
`;

export const MenuListBallon = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 4px;
    background: ${theme.colors.brand[300]};
    padding-right: 15px;
    color: ${theme.colors.base[100]};

    p {
        font-size: ${theme.typography.sizes.smallX};
        line-height: 20px;
        color: ${theme.colors.base[100]} !important;
    }

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: -2px;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid ${theme.colors.brand[300]};
        transform: translateX(-50%);
    }
`;

const MenuListNotExpandedStyles = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    position: relative;

    ${MenuListBallon} {
        display: none;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &:hover {
        ${MenuListBallon} {
            display: flex;
            opacity: 1;
            transition: opacity 0.3s;
        }
    }
`;

export const MenuListNotExpanded = styled(NavLink)`
    ${MenuListNotExpandedStyles};
`;

export const MenuListNotExpandedExternal = styled.a`
    ${MenuListNotExpandedStyles};
`;

export const MenuListNotExpandedButton = styled.button.attrs({ type: "button" })`
    ${MenuListNotExpandedStyles};
`;

export const ChatButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    margin-top: 1rem;

    button {
        width: 100%;
        color: ${theme.colors.white};
        background-color: ${theme.colors.brand[300]};
        padding-block: 0.5rem;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
    }
`;
