import { AxiosResponse } from "axios";
import { theme } from "config/theme";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { EssayStatus } from "enums/EssayStatus";
import { ExtraExerciseStatus } from "enums/ExtraExerciseStatus";
import HttpStatus from "enums/HttpStatus";
import { IExamKnowledgeAreas } from "interfaces/IExamKnowledgeAreas";
import { IRequestAction } from "interfaces/IRequestAction";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import getQuestionByNumber from "screens/ExerciseList/utils/getQuestionByNumber";
import getQuestionInKnowledgeArea from "screens/ExerciseList/utils/getQuestionInKnowledgeArea";
import api from "services/api";
import { ReduxAction } from "store";
import { ReduxStore } from "store/ducks";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as answerCardActions, IAnswerCardComposition } from "store/ducks/answerCard";
import { Creators as exerciseListActions, Types as ExerciseListTypes } from "store/ducks/exerciseList";
import { IGoToQuestionNumberPayload, ISaveExerciseListEssayPayload } from "store/ducks/exerciseList/types";
import { showAlertError } from "utils/showAlertError";
import { exerciseListSagaUtils, saveEssayExam } from "./utils";

const { getTotalQuestions, getCurrentQuestionNumber, checkShowEssay, showEssay, exibitionMiddleware, checkIsExam } = exerciseListSagaUtils;

function* getExerciseList(action: ReduxAction<number>) {
    try {
        const { data } = yield call(api.get, `/exerciselist/${action.payload}`, { headers: { "X-Relations": "subject" } });

        const havePaywall = theme.project.slug === "proenem" || theme.project.slug === "promilitares";

        const formatData = {
            ...data,
            items:
                data.items
                    ?.filter((item: any) => !!item.question)
                    .map((item: any, index: number) => ({ ...item, question: { ...item.question, number: index + 1 } })) || [],
            ...(!data && { blockContent: havePaywall })
        };

        if (formatData.subject === null) {
            delete formatData.subject;
        }

        yield put(exerciseListActions.getExerciseListSuccess(formatData));

        yield exibitionMiddleware();
    } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status !== HttpStatus.PAYMENT_REQUIRED) {
            yield put(alertActions.showAlert("Não foi possível obter a Lista de Exercicios.", "danger"));
        }

        yield put(exerciseListActions.getExerciseListFailure(error?.response?.data));
    }
}

function* getExtraExerciseListByStatus(action: { type: string; payload: { status?: ExtraExerciseStatus; page: number; subjectSlug: string } }) {
    try {
        const { status, page, subjectSlug } = action.payload;

        const endpoint = !!status ? `/student/exerciselist-v2/${subjectSlug}?status=${status}` : `/student/exerciselist-v2/${subjectSlug}`;

        const { data } = yield call(api.get, endpoint, {
            headers: {
                "X-Page": page
            }
        });

        if (!data?.items) {
            throw new Error();
        }

        yield put(exerciseListActions.getExtraExerciseListByStatusSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.getExtraExerciseListByStatusFailure(error?.response?.data));
    }
}

function formartKnowledgeAreas(knowledgeAreas: IExamKnowledgeAreas[]) {
    try {
        let questionNumber = 0;

        const format = knowledgeAreas
            .filter((knowledgeArea) => !!knowledgeArea.questions && !!knowledgeArea.questions.length)
            .map((knowledgeArea) => {
                return {
                    ...knowledgeArea,
                    questions: knowledgeArea.questions.map((question) => {
                        const newNumber = questionNumber + 1;
                        questionNumber++;

                        return { ...question, number: newNumber, originalNumber: (question as any)?.order || newNumber };
                    })
                };
            });

        return format;
    } catch (error) {
        return [];
    }
}

function* getExerciseListByExamDay(action: { type: string; payload: { examDayId: string } }) {
    try {
        const { examDayId } = action.payload;
        const { data } = yield call(api.get, `/student/examday/${examDayId}`);

        const knowledgeAreas = formartKnowledgeAreas(data.knowledgeAreas || []);

        const formatData = {
            ...data.exerciseList,
            title: data.name,
            knowledgeAreas,
            questionsTotal: data.questionsTotal,
            durationInMinutes: data.durationInMinutes || 0,
            isAnswerReleased: !!data.isAnswerReleased,
            essayTheme: data?.essayTheme,
            isExam: true
        };

        yield put(exerciseListActions.getExerciseListByExamDaySuccess(formatData));

        yield exibitionMiddleware();
    } catch (error) {
        console.log("error", error);

        yield put(alertActions.showAlert("Não foi possível obter a prova. Tente novamente.", "danger"));
        yield put(exerciseListActions.getExerciseListByExamDayFailure(error?.response?.data));
    }
}

function* getAllExtras(action: any) {
    try {
        const { data } = yield call(api.get, "/student/subject/totalextraexerciselist");

        yield put(exerciseListActions.getAllExtrasSuccess(data));
    } catch (error) {
        console.log("error", error);
        if (error.response && error.response.status !== HttpStatus.PAYMENT_REQUIRED) {
            yield put(alertActions.showAlert("Não foi possível obter a Lista de Exercicios.", "danger"));
        }

        yield put(exerciseListActions.getExerciseListFailure(error?.response?.data));
    }
}

function* getExerciseListSubject(action: IRequestAction<any>) {
    try {
        const { endpoint, headers } = action.payload;

        const { data } = yield call(api.get, endpoint, {
            headers
        });

        if (!data) {
            throw new Error();
        }

        yield put(exerciseListActions.getExerciseListSubjectSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.getExerciseListSubjectFailure());
    }
}

function* getExerciseListExam(action: IRequestAction<any>) {
    try {
        const { endpoint, headers } = action.payload;

        const { data } = yield call(api.get, endpoint, {
            headers
        });

        if (!data) {
            throw new Error();
        }

        yield put(exerciseListActions.getExerciseListExamSuccess(data));
    } catch (error) {
        yield put(exerciseListActions.getExerciseListExamFailure());
    }
}

function* getAvailableExtraExerciseList(action: { type: string; payload: { page: number; subjectSlug: string } }) {
    try {
        const { page = 1, subjectSlug } = action.payload;

        const { data } = yield call(api.get, `/student/exerciselist-v2/${subjectSlug}?status=${ExtraExerciseStatus.Available}`, {
            headers: {
                "X-Page": page
            }
        });

        if (!data?.items) {
            throw new Error();
        }

        yield put(exerciseListActions.getAvailableExtraExerciseListsBySlugSuccess(data));
    } catch (error) {
        console.log("error", error);

        yield put(exerciseListActions.getAvailableExtraExerciseListsBySlugFailure(error?.response?.data));
    }
}

function* saveEssay(action: ReduxAction<ISaveExerciseListEssayPayload>) {
    try {
        const {
            content = "",
            file,
            // canvasElement,
            onSuccessPatchAnswerCardPayload
        } = action.payload;

        const { idResolution, idComposition, idTheme, idAnswerCard, isFinished } = yield select(({ answerCard, exerciseList }: ReduxStore) => ({
            idResolution: answerCard?.answerCard?.examResolution?.id || action?.payload?.idResolution,
            idComposition:
                answerCard?.answerCard?.examResolution?.idComposition ||
                answerCard?.answerCard?.composition?.idComposition ||
                action?.payload?.idComposition,
            idTheme: exerciseList?.essayTheme?.idTheme || action?.payload?.idTheme,
            idAnswerCard: answerCard?.answerCard?.id || action?.payload?.idAnswerCard,
            isFinished: (answerCard?.answerCard.status as number) >= AnswerCardStatus.Done
        }));

        if (isFinished) {
            throw new Error();
        }

        // REMOVE SCREEN FREEZE
        yield delay(200);

        if (!file && !content) {
            yield put(exerciseListActions.saveExerciseListEssayFailure());

            return;
        }

        const fileData = yield saveEssayExam({ content, file });

        const body = {
            content,
            idTheme,
            status: EssayStatus.Draft,
            ...fileData,
            ...(!idComposition && {
                examResolution: {
                    id: idResolution
                }
            })
        };

        // REQUEST CREATE COMPOSITION
        const method = !!idComposition ? "put" : "post";
        const endpoint = !!idComposition ? `/student/essay/composition/${idComposition}` : "/student/essay/composition";

        // SHOW MODAL HERE

        const { data }: AxiosResponse<IAnswerCardComposition> = yield call(api[method], endpoint, body);

        // if (isDevelopment()) {
        console.log(
            `%cIMAGE -> ${data?.pathOriginal}`,
            "background: white; color: black; padding: 30px; line-height: 25px; border: 3px solid blue; margin: 5px;"
        );
        // }

        if (!data?.idComposition) {
            throw new Error();
        }

        // CLEAR SAVED TEXT
        localStorage.removeItem(`answerCardComposition:${idAnswerCard}`);

        yield put(exerciseListActions.saveExerciseListEssaySuccess());
        yield put(answerCardActions.saveAnswerCardEssay({ ...data, isSaved: true }));

        yield put(alertActions.showAlert("Redação salva com sucesso!", "success"));

        if (!!onSuccessPatchAnswerCardPayload) {
            yield put(answerCardActions.patchAnswerCardRequest(onSuccessPatchAnswerCardPayload));
        }
    } catch (error) {
        console.log("error", error);

        yield put(exerciseListActions.saveExerciseListEssayFailure());

        if (!!action?.payload?.onSuccessPatchAnswerCardPayload) {
            yield put(answerCardActions.patchAnswerCardFailure());
        }

        yield showAlertError(error);
    }
}

function* goToQuestioNumber(action: ReduxAction<IGoToQuestionNumberPayload>) {
    try {
        const { questionNumber, showResult = false } = action?.payload;

        if (!questionNumber || questionNumber <= 0) {
            throw new Error();
        }

        const hasToShowEssay = yield checkShowEssay(questionNumber);

        if (hasToShowEssay) {
            return yield showEssay();
        }

        yield put(exerciseListActions.showEssay(false));
        yield put(exerciseListActions.showResult(!!showResult));

        const isExam = yield checkIsExam();

        const { items = [], knowledgeAreas } = yield select(({ exerciseList }: ReduxStore) => exerciseList);

        yield put(exerciseListActions.showAnswerCard(false));

        if (isExam) {
            const questionAndKnowledgeArea = getQuestionInKnowledgeArea(knowledgeAreas, questionNumber);

            if (!questionAndKnowledgeArea) {
                throw new Error("knowledgeAreas not found");
            }

            yield put(exerciseListActions.setCurrentQuestion(questionAndKnowledgeArea?.question));
            yield put(exerciseListActions.getExerciseListSubjectSuccess(questionAndKnowledgeArea?.knowledgeArea));

            return;
        }

        if (!items?.length) {
            return;
        }

        const question = getQuestionByNumber(items, questionNumber);

        if (!question) {
            throw new Error("question not found");
        }

        yield put(exerciseListActions.setCurrentQuestion(question));
    } catch (error) {
        console.log(error);

        // yield showAlertError(error);
    }
}

function* goToNextQuestion() {
    try {
        const currentQuestionNumber = yield getCurrentQuestionNumber();
        const newQuestionNumber = currentQuestionNumber + 1;
        const totalQuestions = yield getTotalQuestions();

        if (newQuestionNumber > totalQuestions) {
            return yield put(exerciseListActions.goToQuestionNumber({ questionNumber: totalQuestions }));
        }

        yield put(exerciseListActions.goToQuestionNumber({ questionNumber: newQuestionNumber }));
    } catch (error) {
        console.log(error);
        yield showAlertError(error);
    }
}

function* goToPrevQuestion() {
    try {
        const isEssayVisible = yield select(({ exerciseList }: ReduxStore) => !!exerciseList?.showEssay);

        const totalQuestions = yield getTotalQuestions();

        const currentQuestionNumber = yield getCurrentQuestionNumber();
        const newQuestionNumber = isEssayVisible ? currentQuestionNumber || totalQuestions - 1 : currentQuestionNumber - 1;

        if (newQuestionNumber <= 0) {
            return yield put(exerciseListActions.goToQuestionNumber({ questionNumber: 1 }));
        }

        yield put(exerciseListActions.goToQuestionNumber({ questionNumber: newQuestionNumber }));
    } catch (error) {
        console.log(error);
        yield showAlertError(error);
    }
}

function* goToEssay() {
    try {
        yield showEssay();
    } catch (error) {
        console.log(error);
    }
}

export default [
    takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_REQUEST, getExerciseList),
    takeLatest(ExerciseListTypes.GET_ALL_EXTRAS_REQUEST, getAllExtras),
    takeLatest(ExerciseListTypes.GET_EXTRA_EXERCISE_LIST_BY_STATUS_REQUEST, getExtraExerciseListByStatus),
    takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_BY_EXAM_DAY_REQUEST, getExerciseListByExamDay),
    takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_SUBJECT_REQUEST, getExerciseListSubject),
    takeLatest(ExerciseListTypes.GET_EXERCISE_LIST_EXAM_REQUEST, getExerciseListExam),
    takeLatest(ExerciseListTypes.GET_AVAILABLE_EXTRA_EXERCISE_LISTS_BY_SLUG_REQUEST, getAvailableExtraExerciseList),
    takeLatest(ExerciseListTypes.SAVE_EXERCISE_LIST_ESSAY_REQUEST, saveEssay),
    takeLatest(ExerciseListTypes.GO_TO_QUESTION_NUMBER, goToQuestioNumber),
    takeLatest(ExerciseListTypes.GO_TO_NEXT_QUESTION, goToNextQuestion),
    takeLatest(ExerciseListTypes.GO_TO_PREV_QUESTION, goToPrevQuestion),
    takeLatest(ExerciseListTypes.GO_TO_ESSAY, goToEssay)
];
