import { TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { CardStyles } from "components/Card/styles";
import { theme } from "config/theme";
import styled from "styled-components";

export const ExamDayDarkBackground = styled.div`
    background: rgba(37, 39, 45, 0.5);
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    position: absolute;
    z-index: 999;
`;

export const ExamDayCard = styled.div`
    ${CardStyles};

    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-left: auto;
    border-radius: unset;
    overflow-y: auto;
    padding: 0;

    background-color: ${theme.colors.base[100]};
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        max-width: 400px;
    }
`;

interface IExamDayHeaderStyleProps {
    variant?: "brand" | "secondary" | "base";
}

export const ExamDayHeader = styled.header<IExamDayHeaderStyleProps>`
    width: 100%;
    height: 171px;
    background-color: ${({ variant = "base" }) => theme.colors[variant][300]};
    border-radius: 0px 0px 8px 8px;
    padding: 0 ${theme.spacing.large};

    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;

    h2 {
        ${TitleH3};
        color: ${theme.colors.base[400]};
        margin-bottom: ${theme.spacing.medium};
    }

    button {
        background: none;
        border: none;
        padding: 0;
        outline: none;

        svg {
            color: ${theme.colors.white};
            width: 20px;
            height: 20px;
        }
    }
`;
