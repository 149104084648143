import React, { useMemo, useCallback } from "react";
import getCurrentAnswer from "screens/ExerciseList/utils/getCurrentAnswer";
import ExerciseListKnowledgeArea from "components/KnowledgeArea";
import ExerciseListResultQuestion from "../ResultQuestion";
import getCurrentQuestionResolutions from "screens/ExerciseList/utils/getCurrentQuestionResolutions";
import { IExerciseListQuestionResolution } from "interfaces/IExerciseList";
import IExerciseListScreenresolutions from "screens/ExerciseList/interfaces/IExerciseListScreenresolutions";
import { IAnswerCardItem } from "store/ducks/answerCard";
import { IQuestion } from "interfaces/IQuestion";
import { ExerciseListResultKnowledgeAreaContainer } from "./styles";

interface IProps {
    questions: IQuestion[];
    name: string;
    questionsResolutions: IExerciseListScreenresolutions[];
    color?: string | null;
    onClickShowQuestion(questionNumber: number): void;
    onClickSeeResolution(data: { resolutions: IExerciseListQuestionResolution[]; question: IQuestion; answer: IAnswerCardItem }): void;
    answers: IAnswerCardItem[];
    showAnswers: boolean;
}

const ExerciseListResultKnowledgeArea = ({
    questions,
    answers,
    name,
    color,
    onClickSeeResolution,
    onClickShowQuestion,
    questionsResolutions,
    showAnswers
}: IProps) => {
    const currentResolutions = useCallback((questionId: number) => getCurrentQuestionResolutions({ questionsResolutions, questionId }), [
        questionsResolutions
    ]);

    const listQuestions = useMemo(() => {
        try {
            return questions.map((question, index) => {
                const currentAnswer = getCurrentAnswer({ answers, questionId: question.id });
                const resolutions = currentResolutions(question.id);

                const number = question?.originalNumber ?? question.number ?? index + 1;

                return (
                    <ExerciseListResultQuestion
                        key={question.id}
                        number={number}
                        question={question}
                        answer={currentAnswer}
                        onClickShowQuestion={() => onClickShowQuestion(question.number || index + 1)}
                        onClickSeeResolution={() => onClickSeeResolution({ resolutions, question, answer: currentAnswer })}
                        resolutions={resolutions}
                        showAnsers={showAnswers}
                        isExtra={false}
                    />
                );
            });
        } catch (error) {
            return null;
        }
    }, [answers, currentResolutions, onClickSeeResolution, onClickShowQuestion, questions, showAnswers]);

    return (
        <ExerciseListResultKnowledgeAreaContainer>
            <ExerciseListKnowledgeArea color={color} name={name} />

            {listQuestions}
        </ExerciseListResultKnowledgeAreaContainer>
    );
};

export default React.memo(ExerciseListResultKnowledgeArea);
