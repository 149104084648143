import { theme } from "config/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { RadioGroupContainer } from "components/RadioGroup/styles";
import { FilterGroupContainer } from "components/FilterGroup/styles";
import { Button2, Button3, CardContainerSmallStyle, TitleH2, TitleH5 } from "assets/styles/global";

export const EssayThemesContainer = styled.div`
    padding-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-bottom: ${theme.spacing.large};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${FilterGroupContainer} {
            padding: 0;
        }

        ${RadioGroupContainer} {
            border-bottom: 1px solid ${theme.colors.base[200]};
            margin-bottom: ${theme.spacing.medium};
            padding-bottom: ${theme.spacing.medium};
        }
    }
`;

export const EssayThemesTitle = styled.h1`
    ${TitleH2};
`;

export const EssayThemesRow = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    & div {
        margin-left: ${theme.spacing.large};
    }
`;

export const EssayThemesContent = styled.div`
    margin-bottom: ${theme.spacing.medium};
`;

export const EssayThemesContentTitle = styled(TitleH5)`
    color: ${theme.colors.base[400]};
`;

export const EssayThemesList = styled.div``;

export const EssayThemesListItem = styled.div`
    padding: ${theme.spacing.small};
    background: ${theme.colors.white};
`;

export const EssayThemeWrapper = styled.div`
    left: 0;
    position: sticky;
    top: 16px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        max-height: calc(100vh - 92px);
        overflow-y: auto;
    }
`;

export const EssayThemesButton = styled(Link)`
    ${Button3};
    ${CardContainerSmallStyle};
    align-items: center;
    color: ${theme.colors.base[500]};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.medium};
    margin-top: ${theme.spacing.largeX};
    padding: ${theme.spacing.small};
    text-align: left;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${Button2};
        font-weight: 700;

        svg {
            height: 12px;
            width: 6px;
        }
    }
`;

export const EssayThemeDropdown = styled.div`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: ${theme.spacing.smallX};
    background: ${theme.colors.system.info[100]};

    li {
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
        padding: ${theme.spacing.smallX};

        width: 160px;

        strong {
            font-weight: 600;
            color: ${theme.colors.base[500]};
        }
    }
`;
