import { store } from "store";
import HttpStatus from "enums/HttpStatus";
import { race, take } from "redux-saga/effects";
import { isBrandsWithSuspendedAccess } from "helpers/isBrandsWithSuspendedAccess";
import { theme } from "config/theme";

let errors: any[] = [];

export const monitorableRequestActions = (action: any) => action.type.includes("REQUEST");

const identifyAction = (action: any) =>
    action.type
        .split("_")
        .slice(0, -1)
        .join("_");

const getSuccessType = (action: any) => `${identifyAction(action)}_SUCCESS`;
const getFailType = (action: any) => `${identifyAction(action)}_FAILURE`;

export function* monitor(monitoredAction: { type: string; payload: any }) {
    const { fail } = yield race({
        success: take(getSuccessType(monitoredAction)),
        fail: take(getFailType(monitoredAction))
    });

    if (fail) {
        errors.push(monitoredAction);
    }

    if (isBrandsWithSuspendedAccess(theme.project.name)) {
        return store.dispatch({ type: "LOGOUT" });
    }

    if (fail?.payload?.status === HttpStatus.UNAUTHORIZED) {
        return store.dispatch({ type: "LOGOUT" });
    }

    // Init refresh token request
    // if (fail?.payload && fail.payload.status === HttpStatus.UNAUTHORIZED) {
    //     // Get current credentials
    //     const auth = yield select(getAuth);

    //     // Logout user if auth not exists
    //     if (!auth) {
    //         return store.dispatch({ type: "LOGOUT" });
    //     }

    //     const { refresh_token, refreshed, credentials } = auth;

    //     if (!refreshed) {
    //         // Send refresh token request
    //         yield put(
    //             authActions.refreshTokenRequest({
    //                 value: refresh_token,
    //                 idUser: credentials.id
    //             })
    //         );

    //         const { success } = yield race({
    //             success: take("REFRESH_TOKEN_SUCCESS"),
    //             fail: take("REFRESH_TOKEN_FAILURE")
    //         });

    //         // If success, retry last failed action
    //         if (success) {
    //             for (const item of errors) {
    //                 yield put(item);
    //             }

    //             // Clear errors
    //             errors = [];
    //         } else {
    //             // If fail, logout user
    //             yield put(authActions.logout());
    //         }
    //     }
    // }
}
