export enum DropdownFilterAvailableControls {
    text = "TEXT",
    checkbox = "CHECKBOX",
    category = "CATEGORY",
    list = "LIST"
}

export interface IDropdownFilterData {
    type: DropdownFilterAvailableControls;
    filterLabel: string;
    options: any[];
}
