// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, IconWrapperSkeleton, NoteWrapper, NoteValueSkeleton, NoteContentSkeleton } from "./performance-best-essay.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceBestEssaySkeleton: FunctionComponent = () => {
    return (
        <Container>
            <IconWrapperSkeleton>
                <Skeleton width="100%" height="100%" borderRadius="50%" />
            </IconWrapperSkeleton>

            <Spacing size={12} direction="horizontal" />

            <NoteWrapper>
                <NoteValueSkeleton>
                    <Skeleton width="160px" height="100%" borderRadius="4px" />
                </NoteValueSkeleton>

                <NoteContentSkeleton>
                    <Skeleton width="180px" height="100%" borderRadius="4px" />
                </NoteContentSkeleton>

                <NoteContentSkeleton>
                    <Skeleton width="180px" height="100%" borderRadius="4px" />
                </NoteContentSkeleton>
            </NoteWrapper>
        </Container>
    );
};
