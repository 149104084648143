// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityInputCardCompositionProps, ActivityInputCardProps } from "./activity-input-card.types";

// Styles
import {
    AdditionalTitleLabel,
    InputCardContainer,
    InputCardContent,
    InputCardInnerContent,
    Description,
    TextContentWrapper,
    InputCardTitle,
    TitleWrapper,
    UnderTitleAdditionalContentCompositionWrapper,
    InputCardCompositionContainer,
    InputCardCompositionTitle,
    InputCardCompositionsWrapper,
    ErrorMessageIcon,
    ErrorMessageLabel,
    UnderInputMessageWrapper
} from "./activity-input-card.styles";

// Components
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

// Assets
import fail from "assets/img/fail.svg";

export const ActivityInputCardComposition: FunctionComponent<ActivityInputCardCompositionProps> = ({ title, inputCardCompositions }) => {
    return (
        <InputCardCompositionContainer className="webapp-input-card-composition">
            <InputCardCompositionTitle>{title}</InputCardCompositionTitle>

            <InputCardCompositionsWrapper>{inputCardCompositions}</InputCardCompositionsWrapper>
        </InputCardCompositionContainer>
    );
};

export const ActivityInputCard: FunctionComponent<ActivityInputCardProps> = ({
    title,
    additionalTitleLabel,
    description,
    errorMessage,
    inputComposition,
    underTitleAdditionalContentComposition
}) => {
    return (
        <InputCardContainer className="webapp-input-card">
            <InputCardContent>
                <InputCardInnerContent>
                    <TextContentWrapper>
                        <TitleWrapper>
                            <InputCardTitle>{title}</InputCardTitle>

                            <ConditionallyRender
                                shouldRender={!!additionalTitleLabel}
                                content={<AdditionalTitleLabel>{additionalTitleLabel}</AdditionalTitleLabel>}
                            />
                        </TitleWrapper>

                        <ConditionallyRender shouldRender={!!description} content={<Description>{description}</Description>} />

                        <ConditionallyRender
                            shouldRender={!!underTitleAdditionalContentComposition}
                            content={
                                <UnderTitleAdditionalContentCompositionWrapper>
                                    {underTitleAdditionalContentComposition}
                                </UnderTitleAdditionalContentCompositionWrapper>
                            }
                        />
                    </TextContentWrapper>

                    {inputComposition}
                </InputCardInnerContent>
            </InputCardContent>

            <ConditionallyRender
                shouldRender={!!errorMessage}
                content={
                    <UnderInputMessageWrapper>
                        <ErrorMessageIcon src={fail} />

                        <ErrorMessageLabel>{errorMessage}</ErrorMessageLabel>
                    </UnderInputMessageWrapper>
                }
            />
        </InputCardContainer>
    );
};
