import { call, put } from "redux-saga/effects";

import { AxiosResponse } from "axios";
import { IProjectCategory } from "interfaces/IProject";
import { IProjectStudying } from "interfaces/IProjectDetails";
import find from "lodash/find";
import filter from "lodash/filter";
import get from "lodash/get";
import api from "services/api";
import { Creators as projectActions } from "store/ducks/project";
import { Creators as alertActions } from "../../ducks/alert";
import ProjectStatsNavigationType from "enums/ProjectStatsNavigationType";

const setNavigatorByProgress = (data: IProjectStudying) => {
    const { project } = data;

    if (!project.stats.navigation) {
        const currentModule = get(project, "modules[0]", undefined);
        const hasIntro = currentModule && (!!currentModule.introductionText || !!currentModule.video);

        return {
            currentModule: currentModule ? currentModule.id : -1,
            currentSection: currentModule && !hasIntro ? currentModule.sections[0] && currentModule.sections[0].id : -1,
            currentContent:
                currentModule && !hasIntro
                    ? currentModule.sections[0] && currentModule.sections[0].contents[0] && currentModule.sections[0].contents[0].id
                    : -1
        };
    }

    if (project.stats.navigation.type === ProjectStatsNavigationType.Introduction) {
        const module = find(project.modules, { id: project.stats.navigation.idContent });

        if (!module) {
            return {
                currentModule: project.modules[0].id,
                currentSection: -1,
                currentContent: -1
            };
        }

        return {
            currentModule: module.id,
            currentSection: module.sections[0] && module.sections[0].id,
            currentContent: module.sections[0] && module.sections[0].contents[0] && module.sections[0].contents[0].id
        };
    }

    const module = find(project.modules, { id: -1 });
    const section = module && find(module.sections, { id: -1 });
    const content = section && filter(section.contents, { isViewed: false });

    const isExistContentsWithoutVisualization = !content || !content.length;
    if (isExistContentsWithoutVisualization) {
        const nextModule = filter(project.modules, (item) => item.id !== (module && module.id));
        const hasNextModule = nextModule.length > 0;

        return {
            currentModule: hasNextModule ? nextModule[0].id : module && module.id,
            currentSection: hasNextModule && nextModule[0].sections[0] ? nextModule[0].sections[0].id : -1,
            currentContent:
                hasNextModule && nextModule[0].sections[0] && nextModule[0].sections[0].contents[0] ? nextModule[0].sections[0].contents[0].id : -1
        };
    }

    return {
        currentModule: -1,
        currentSection: -1,
        currentContent: -1
    };
};

export function* getAllProjects() {
    try {
        const { data }: AxiosResponse<IProjectCategory[]> = yield call(api.get, "/student/project");

        yield put(projectActions.getProjectsSuccess(data));
    } catch (error) {
        console.log("error", error);

        if (error?.response?.data?.status !== 401) {
            yield put(alertActions.showAlert("Não conseguimos carregar os projetos! 😔", "danger"));
        }

        yield put(projectActions.getProjectsFailure(error?.response?.data));
    }
}

export function* getProject(action: any) {
    try {
        const { data } = yield call(api.get, `/student/project/${action.payload.id}/detail`);

        yield put(projectActions.getProjectDetailSuccess(data));
    } catch (error) {
        console.log("error", error);
        yield put(alertActions.showAlert("Não conseguimos carregar o projeto! 😔", "danger"));

        return yield put(projectActions.getProjectDetailFailure(error?.response?.data));
    }
}

export function* getProjectForStudy(action: any) {
    try {
        const { data }: AxiosResponse<IProjectStudying> = yield call(api.get, `/student/project/${action.payload.id}`);

        const navigator = setNavigatorByProgress(data);

        yield put(projectActions.getProjectForStudySuccess({ project: data, navigator }));
    } catch (error) {
        console.log("error", error);

        if (error.response?.data?.status === 401) {
            return yield put(projectActions.getProjectForStudyFailure(error?.response?.data));
        }

        if (error.response?.data?.status === 403) {
            return yield put(projectActions.getProjectForStudyFailure({ message: "Você ainda não possui acesso à este projeto. 😔" }));
        }

        yield put(alertActions.showAlert("Não conseguimos carregar o projeto! 😔", "danger"));

        return yield put(projectActions.getProjectForStudyFailure({ message: "Desculpe. Não conseguimos encontrar o projeto que você procura. 😔" }));
    }
}

export function* setContentAsCompleted(action: any) {
    try {
        const { idProject, bodyRequest } = action.payload;

        const { data } = yield call(api.patch, `/student/projectprogress/${idProject}`, bodyRequest);

        yield put(projectActions.setContentAsCompletedSuccess({ ...bodyRequest, stats: data }));
    } catch (error) {
        yield put(alertActions.showAlert("Ocorre um erro ao calcular seu progresso", "danger"));
        yield put(projectActions.setContentAsCompletedFailure(error?.response?.data));
        console.log("error", error);
    }
}
