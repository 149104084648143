import React from "react";

// Assets
import * as S from "./styles";
import { BreakPoints } from "assets/styles/settings";

// Components
import { Grid } from "components/Grid";
import Button from "components/Button";
import ExerciseListDashboardBreadCrumb from "../BreadCrumb";

// Helpers
import useIsMobile from "hooks/use-is-mobile";

interface IProps {
    title: string;
    subject?: {
        name: string;
        slug: string;
    };
    blockContent: boolean;
    isExtra: boolean;
    previousExam?: { name: string; id: number };
    previousExamDay?: { title: string; id: number };
    onClickStartList(): void;
    project?: { name: string; id: number };
    onClickdownloadPDF?(): void;
    disableButtonPDF?: boolean;
}

const ExerciseListDashboardHeader = ({
    title,
    subject,
    onClickStartList,
    blockContent,
    isExtra,
    previousExam,
    previousExamDay,
    project,
    onClickdownloadPDF,
    disableButtonPDF
}: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);

    return (
        <S.ExerciseListDashboardHeaderContainer>
            <Grid>
                <S.ExerciseListDashboardHeaderContent id="ExerciseListDashboardHeaderContent">
                    <S.ExerciseListDashboardHeaderWrapp>
                        <ExerciseListDashboardBreadCrumb
                            isMobile={isMobile}
                            subject={subject}
                            previousExam={previousExam}
                            previousExamDay={previousExamDay}
                            project={project}
                        />

                        <h1>{title}</h1>
                    </S.ExerciseListDashboardHeaderWrapp>

                    <S.ContentButtons>
                        {!isMobile && !blockContent && !isExtra && (
                            <>
                                <Button
                                    id="download-pdf-button"
                                    variant="outline-primary"
                                    size="small"
                                    onClick={onClickdownloadPDF}
                                    data-test-id="exercise-list-download-pdf-button"
                                    disabled={disableButtonPDF}
                                >
                                    {disableButtonPDF ? "Exportando..." : `Imprimir ${!!previousExam ? "prova" : "lista"}`}
                                </Button>
                                <Button variant="primary" size="small" onClick={onClickStartList} data-test-id="exercise-list-start-button">
                                    Resolver {!!previousExam ? "prova" : "lista"}
                                </Button>
                            </>
                        )}
                    </S.ContentButtons>
                </S.ExerciseListDashboardHeaderContent>
            </Grid>
        </S.ExerciseListDashboardHeaderContainer>
    );
};

export default React.memo(ExerciseListDashboardHeader);
