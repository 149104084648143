import { fadein } from "assets/styles/animations";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

export const ContainerModal = styled.div`
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999992;
    background: ${theme.colors.base[100]};
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    padding: ${theme.spacing.medium} ${theme.spacing.small};
    overflow: auto;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        transition: none;
        animation: ${fadein} 0.15s linear;
        padding: 40px ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.large};
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        padding: 56px;
    }
`;

export const PositionTitle = styled.header`
    margin-bottom: ${theme.spacing.small};

    h3 {
        font-weight: bold;
        font-size: ${theme.typography.sizes.mediumXX};
        line-height: 28px;
        color: ${theme.colors.base[400]};

        ${MediaQueries.BIGGER_THAN_WIDE} {
            font-size: ${theme.typography.sizes.large};
            line-height: 36px;
        }
    }
`;

export const CloseButton = styled.button`
    width: 16px;
    height: 16px;
    margin-bottom: ${theme.spacing.small};

    svg {
        width: 16px;
        height: 16px;
        color: ${theme.colors.base[500]};
    }
`;
