// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

type WebpContentStepProps = {
    contentLabel: string;
    material: string;
    materialComposition: ReactNode;
    progressComposition: ReactNode;
};

export const WebpContentStep: FunctionComponent<WebpContentStepProps> = ({ contentLabel, material, materialComposition, progressComposition }) => {
    return (
        <Container>
            <div className="label-and-material">
                <div className="div-label">
                    <p className="content-label">{contentLabel}</p>
                    <div className="svg">
                        <svg width="4" height="9" viewBox="0 0 4 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.89857 4.59076C4.03376 4.74436 4.03382 4.97107 3.8987 5.12474L0.740814 8.71627C0.589522 8.88834 
                                    0.323717 8.90832 0.147122 8.76091C-0.0294735 8.6135 -0.0499865 8.35451 0.101306 8.18244L3.02439 4.85796L0.101441 
                                    1.53695C-0.0499382 1.36496 -0.0295568 1.10596 0.146964 0.958459C0.323484 0.810961 0.589299 0.83082 0.740678 1.00281L3.89857 
                                    4.59076Z"
                                fill="#25272D"
                            />
                        </svg>
                    </div>
                </div>

                <div className="div-material">
                    <p className="material">{material}</p>
                    {materialComposition}
                </div>

                <div className="progress">{progressComposition}</div>
            </div>
        </Container>
    );
};
