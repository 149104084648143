// Types
import { INavigation } from "interfaces/INavigation";

// Helpers
import { hasLessonPlanCustom } from "./has-lesson-plan-custom";

type MenuToReorder = {
    shouldReorder: boolean;
    route: string;
    position: number;
};

const checkPromedicinaMenuDisplay = (brandSlug: string, courseSlug: string, url: string) => {
    if (brandSlug === "promedicina") {
        const coursesWhereShouldRemoveMenuOtherThanLessonPlanAndSubjects =
            ["nivelamento-pro", "aprofundamento-medicina", "revisao-do-pro", "revisao-proenem"].includes(courseSlug) &&
            !["/app/meu-plano-de-estudos", "/app/materias"].includes(url);
        const shouldRemoveDesvendando1000Menu =
            ["intensivo-redacao", "desvendando-o-mil"].includes(courseSlug) &&
            !["/app/meu-plano-de-estudos", "/app/materias", "/app/redacoes"].includes(url);
        const shouldRemoveTurmaIntensiva2023Menu =
            ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(courseSlug) &&
            ["/app/arquivos", "/app/qr-code"].includes(url);
        const coursesWhereShouldRemoveMenuOtherThanSubjectsAndSimulados =
            ["especifico-biologia-med", "especifico-fisica-med", "especifico-matematica-med", "especifico-quimica-med"].includes(courseSlug) &&
            !["/app/materias", "/app/simulados"].includes(url);
        const shouldRemoveMedMaisMenu =
            ["med-mais"].includes(courseSlug) && !["/app/materias", "/app/simulados", "/app/plano-de-estudos"].includes(url);
        const coursesWhereShouldRemoveMenuQRCode =
            ["turma-intensiva-2024", "turma-intensiva-2024.2", "turma-intensiva-2024.3", "turma-intensiva-2024.4"].includes(courseSlug) &&
            ["/app/qr-code"].includes(url);

        const shouldRemoveMenu =
            coursesWhereShouldRemoveMenuOtherThanLessonPlanAndSubjects ||
            shouldRemoveDesvendando1000Menu ||
            shouldRemoveTurmaIntensiva2023Menu ||
            coursesWhereShouldRemoveMenuOtherThanSubjectsAndSimulados ||
            shouldRemoveMedMaisMenu ||
            coursesWhereShouldRemoveMenuQRCode;

        return !shouldRemoveMenu;
    }

    return true;
};

const checkPromilitaresMenuDisplay = (brandSlug: string, courseSlug: string, url: string) => {
    if (brandSlug === "promilitares") {
        const coursesWhereShouldRemoveProjectsAndAnswerQuestionsMenus =
            ["preparatorio-esa", "preparatorio-espcex"].includes(courseSlug) && ["/app/projetos", "/app/forum"].includes(url);
        const coursesWhereShouldRemoveMenuOtherThanEssayExamsExerciseListCustomAndPreviousExams =
            ["esaeear-aprovacao", "afaenefomm-aprovacao", "cnepcar-aprovacao", "espcex-aprovacao", "imeita-aprovacao", "eamcfn-aprovacao"].includes(
                courseSlug
            ) && !["/app/redacoes", "/app/simulados", "/app/banco-de-questoes", "/app/provas-anteriores"].includes(url);
        const shouldRemoveEEARIntensivo2023Menu =
            "eear-intensivo-2023-2" === courseSlug && ["/app/redacoes", "/app/projetos", "/app/arquivos"].includes(url);
        const coursesWhereShouldRemoveEssayAndLiveMenus =
            ["eam-2023", "cfn-2023", "eear-2023"].includes(courseSlug) && ["/app/redacoes", "/app/plano-de-estudos"].includes(url);
        const coursesWhereShouldRemoveClassScheduleMenu =
            [
                "teste-gratis-esa",
                "teste-gratis-eear",
                "teste-gratis-cn",
                "teste-gratis-espcex",
                "teste-gratis-afa",
                "pmsp-24-25",
                "barro-branco-24-25",
                "cbmerj-ofc-24-25",
                "espcex-23-24-abril-fx",
                "esa-23-24-abril-fx",
                "espcex-intensivo-23-24",
                "esa-intensivo-23-24"
            ].includes(courseSlug) && "/app/plano-de-estudos" === url;
        const shouldRemoveBombeirosRJMenu =
            courseSlug === "preparatorio-oficial-de-bombeiro-rj" && ["/app/redacoes", "/app/provas-anteriores"].includes(url);
        const shouldRemoveNivelamentoMenu = courseSlug === "pm-nivelamento" && url !== "/app/materias";
        const coursesWhereShouldRemoveMenuOtherThanModulesAndLessonPlan =
            [
                "pmsp-2023-nv",
                "espcex-2023-nv",
                "afa-2023-nv",
                "en-efomm-2023-nv",
                "cn-2023-nv",
                "epcar-2023-nv",
                "eam-2023-nv",
                "cfn-23-24-nv",
                "eear-2023-nv",
                "esa-2023-nv",
                "barro-branco-2023-nv",
                "cbmerj-ofc-2023-nv",
                "ime-ita-23-24-nv"
            ].includes(courseSlug) && !["/app/materias", "/app/meu-plano-de-estudos"].includes(url);
        const shouldRemoveEEARIntensivo2024Menu =
            "eear-intensivo-24-fx" === courseSlug && ["/app/plano-de-estudos", "/app/redacoes", "/app/projetos"].includes(url);
        const coursesWhereShouldRemovePreviousExamsMenu =
            ["cbmerj-ofc-2023-fx", "cbmerj-ofc-2023-al"].includes(courseSlug) && "/app/provas-anteriores" === url;
        const shouldRemoveFixedNewProductsMenu =
            ["eam-24-25-fx-tmp", "cfn-24-25-fx-tmp", "bombeiro-24-25-fx-tmp", "cn-24-25-fx-tmp", "epcar-24-25-fx-tmp"].includes(courseSlug) &&
            ["/app/projetos", "/app/plano-de-estudos", "/app/arquivos"].includes(url);
        const shouldRemoveFixedPrmProductsMenu =
            [
                "esa-fx",
                "espcex-fx",
                "eear-fx",
                "afa-fx",
                "efomm-fx",
                "cbmerj-ofc-fx",
                "cn-fx",
                "epcar-fx",
                "eam-fx",
                "cfn-fx",
                "pmsp-fx",
                "pmsp-fx-barro-branco"
            ].includes(courseSlug) && ["/app/projetos", "/app/arquivos"].includes(url);
        const shouldRemoveQRCodeMenu =
            ["esa-fx", "espcex-fx", "eear-fx", "afa-fx", "efomm-fx", "cbmerj-ofc-fx", "cn-fx", "epcar-fx", "ime-ita-24-25-fx-tmp"].includes(
                courseSlug
            ) && ["/app/qr-code"].includes(url);
        const shouldRemoveClassScheduleAndPressReleasesMenu = ["ime-ita-24-25-fx-tmp"].includes(courseSlug) && ["/app/arquivos"].includes(url);
        const shouldRemoveEssayMenu =
            ["eear-fx", "eam-24-25-fx-tmp", "cfn-24-25-fx-tmp", "bombeiro-24-25-fx-tmp", "eam-fx", "cfn-fx"].includes(courseSlug) &&
            ["/app/redacoes"].includes(url);
        const coursesWhereShouldRemoveMenuOtherThanProjects = ["taf-de-elite"].includes(courseSlug) && url !== "/app/projetos";
        const shouldRemovePrmRevisaoMenu =
            ["prm-or-produto-fixo-or-revisao-final-eear"].includes(courseSlug) &&
            !["/app/materias", "/app/provas-anteriores", "/app/arquivos"].includes(url);

        const shouldRemoveMenu =
            coursesWhereShouldRemoveProjectsAndAnswerQuestionsMenus ||
            coursesWhereShouldRemoveMenuOtherThanEssayExamsExerciseListCustomAndPreviousExams ||
            shouldRemoveEEARIntensivo2023Menu ||
            coursesWhereShouldRemoveEssayAndLiveMenus ||
            coursesWhereShouldRemoveClassScheduleMenu ||
            shouldRemoveBombeirosRJMenu ||
            shouldRemoveNivelamentoMenu ||
            coursesWhereShouldRemoveMenuOtherThanModulesAndLessonPlan ||
            shouldRemoveEEARIntensivo2024Menu ||
            coursesWhereShouldRemovePreviousExamsMenu ||
            shouldRemoveFixedNewProductsMenu ||
            shouldRemoveFixedPrmProductsMenu ||
            shouldRemoveQRCodeMenu ||
            shouldRemoveEssayMenu ||
            shouldRemoveClassScheduleAndPressReleasesMenu ||
            coursesWhereShouldRemoveMenuOtherThanProjects ||
            shouldRemovePrmRevisaoMenu;

        return !shouldRemoveMenu;
    }

    return true;
};

const checkProenemMenuDisplay = (brandSlug: string, courseSlug: string, url: string) => {
    if (brandSlug === "proenem") {
        const coursesWhereShouldRemoveMenuOtherThanLessonPlanAndSubjects =
            ["nivelamento-pro", "revisao-do-pro", "revisao-proenem"].includes(courseSlug) &&
            !["/app/meu-plano-de-estudos", "/app/materias"].includes(url);
        const shouldRemoveDesvendando1000Menu =
            ["intensivo-redacao", "desvendando-o-mil"].includes(courseSlug) &&
            !["/app/meu-plano-de-estudos", "/app/materias", "/app/redacoes"].includes(url);
        const shouldRemoveTesteGratisMenu = "pe-teste-gratis" === courseSlug && ["/app/plano-de-estudos"].includes(url);
        const shouldRemoveTurmaIntensiva2023Menu =
            ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(courseSlug) &&
            ["/app/arquivos", "/app/qr-code"].includes(url);
        const coursesWhereShouldRemoveMenuOtherThanSubjectsAndSimulados =
            ["especifico-biologia-med", "especifico-fisica-med", "especifico-matematica-med", "especifico-quimica-med"].includes(courseSlug) &&
            !["/app/materias", "/app/simulados"].includes(url);
        const shouldRemoveMedMaisMenu =
            ["med-mais"].includes(courseSlug) && !["/app/materias", "/app/simulados", "/app/plano-de-estudos"].includes(url);
        const coursesWhereShouldRemoveMenuQRCode =
            ["turma-intensiva-2024", "turma-intensiva-2024.2", "turma-intensiva-2024.3", "turma-intensiva-2024.4"].includes(courseSlug) &&
            ["/app/qr-code"].includes(url);

        const shouldRemoveMenu =
            coursesWhereShouldRemoveMenuOtherThanLessonPlanAndSubjects ||
            shouldRemoveDesvendando1000Menu ||
            shouldRemoveTesteGratisMenu ||
            shouldRemoveTurmaIntensiva2023Menu ||
            coursesWhereShouldRemoveMenuOtherThanSubjectsAndSimulados ||
            shouldRemoveMedMaisMenu ||
            coursesWhereShouldRemoveMenuQRCode;

        return !shouldRemoveMenu;
    }

    return true;
};

const checkProraizMenuDisplay = (brandSlug: string, courseSlug: string, url: string) => {
    if (brandSlug === "proraiz") {
        const shouldRemoveQRCodeMenu =
            ![
                "proraiz-espcex-2024",
                "proraiz-cfn-2024",
                "proraiz-afa-2024",
                "proraiz-en-efomm-2024",
                "proraiz-esa-2024",
                "proraiz-eear-2024",
                "proraiz-ime-ita-2024",
                "proraiz-eam-2024",
                "proraiz-cn-2024",
                "proraiz-epcar-2024",
                "proraiz-barro-branco-2024",
                "proraiz-bombeiro-2024",
                "proraiz-escolas-tecnicas-2024",
                "1a-serie-ensino-medio-2023",
                "2a-serie-ensino-medio-2024"
            ].includes(courseSlug) && ["/app/qr-code"].includes(url);
        const shouldRemoveMenuOtherThanSubjectAndActivities =
            [
                "efai-or-1o-ano",
                "efai-or-2o-ano",
                "efai-or-3o-ano",
                "efai-1o-ano-23",
                "efai-2o-ano-23",
                "efai-3o-ano-23",
                "efai-or-1o-ano-ef-programa-raizes",
                "efai-or-2o-ano-ef-programa-raizes",
                "efai-or-3o-ano-ef-programa-raizes",
                "efai-or-4o-ano-ef-programa-raizes",
                "efai-or-5o-ano-ef-programa-raizes",
                "efaf-or-6o-ano-ef-programa-raizes",
                "efaf-or-7o-ano-ef-programa-raizes",
                "efaf-or-8o-ano-ef-programa-raizes",
                "efaf-or-9o-ano-ef-programa-raizes",
                "em-or-1o-em-programa-raizes",
                "em-or-2o-em-programa-raizes",
                "em-or-3o-em-programa-raizes"
            ].includes(courseSlug) && !["/app/materias", "/app/atividades"].includes(url);
        const shouldRemoveMenuOtherThanSubjectLessonPlanAndActivities =
            ["efai-or-4o-ano", "efai-or-5o-ano", "efai-4o-ano-23", "efai-5o-ano-23"].includes(courseSlug) &&
            !["/app/materias", "/app/plano-de-estudos", "/app/atividades"].includes(url);
        const shouldRemoveElementaryCoursesMenu =
            [
                "efai-or-6o-ano",
                "efaf-or-7o-ano",
                "efaf-or-8o-ano",
                "efaf-or-9o-ano",
                "efai-6o-ano-23",
                "efaf-7o-ano-23",
                "efaf-8o-ano-3",
                "efaf-9o-ano-23",
                "up-efai-or-6o-ano",
                "up-efaf-or-7o-ano",
                "up-efaf-or-8o-ano",
                "up-efaf-or-9o-ano"
            ].includes(courseSlug) && !["/app/plano-de-estudos", "/app/materias", "/app/simulados", "/app/atividades"].includes(url);
        const shouldRemoveFileAndQrCodeMenu =
            [
                "up-em-or-1o-ano",
                "pv-proraiz-2024",
                "em-or-1o-ano",
                "em-or-2o-ano",
                "em-or-3o-ano",
                "em-1o-ano-23",
                "em-2o-ano-23",
                "em-3o-ano-23"
            ].includes(courseSlug) && ["/app/arquivos", "/app/qr-code"].includes(url);
        const shouldRemovePreviousTestMenu = ["escolas-tecnicas-2023"].includes(courseSlug) && ["/app/provas-anteriores"].includes(url);
        const shouldRemoveSimuladosAndPreviousTestMenu =
            ["proraiz-escolas-tecnicas-2024"].includes(courseSlug) && ["/app/simulados", "/app/provas-anteriores"].includes(url);
        const coursesWhereShouldRemoveClassScheduleMenu =
            ["1a-serie-ensino-medio-proenem1", "2a-serie-ensino-medio-proenem1"].includes(courseSlug) && url === "/app/plano-de-estudos";

        const shouldRemoveMenu =
            shouldRemoveQRCodeMenu ||
            shouldRemoveMenuOtherThanSubjectAndActivities ||
            shouldRemoveMenuOtherThanSubjectLessonPlanAndActivities ||
            shouldRemoveElementaryCoursesMenu ||
            shouldRemoveFileAndQrCodeMenu ||
            shouldRemovePreviousTestMenu ||
            shouldRemoveSimuladosAndPreviousTestMenu ||
            coursesWhereShouldRemoveClassScheduleMenu;

        return !shouldRemoveMenu;
    }

    return true;
};

const renameMenus = (brandSlug: string, courseSlug: string, menus: INavigation[]) => {
    const isProenemCoursesShouldRenameMenuToVirtualRoom =
        ["proenem"].includes(brandSlug) && ["turma-intensiva-2024", "turma-intensiva-2024.2"].includes(courseSlug);
    const isProenemOrPromedicinaCoursesShouldRenameMenuToVirtualRoom =
        ["promedicina", "proenem"].includes(brandSlug) && ["turma-intensiva-2023"].includes(courseSlug);
    const shouldRenameMenuToVirtualRoom = isProenemCoursesShouldRenameMenuToVirtualRoom || isProenemOrPromedicinaCoursesShouldRenameMenuToVirtualRoom;

    const isProenemOrPromedicinaCoursesShouldRenameMenuToMeetSpaceAndClassRoom =
        ["promedicina", "proenem"].includes(brandSlug) &&
        ["turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(courseSlug);
    const shouldRenameMenuToMeetSpaceAndClassRoom = isProenemOrPromedicinaCoursesShouldRenameMenuToMeetSpaceAndClassRoom;

    const isPromedicinaCoursesShouldRenameMenuToClasses = brandSlug === "promedicina" && ["med-22", "2022-or-promed-intensivo1"].includes(courseSlug);

    const isProraizCoursesShouldRenameMenuToLive =
        brandSlug === "proraiz" &&
        [
            "em-or-1o-ano",
            "em-or-2o-ano",
            "em-or-3o-ano",
            "em-1o-ano-23",
            "em-2o-ano-23",
            "em-3o-ano-23",
            "efai-or-4o-ano",
            "efai-or-5o-ano",
            "efai-or-6o-ano",
            "efaf-or-7o-ano",
            "efaf-or-8o-ano",
            "efaf-or-9o-ano",
            "efai-4o-ano-23",
            "efai-5o-ano-23",
            "efai-6o-ano-23",
            "efaf-7o-ano-23",
            "efaf-8o-ano-3",
            "efaf-9o-ano-23",
            "up-efai-or-6o-ano",
            "up-efaf-or-7o-ano",
            "up-efaf-or-8o-ano",
            "up-efaf-or-9o-ano",
            "up-em-or-1o-ano",
            "pv-proraiz-2024",
            "em-or-up-or-2o-ano-em",
            "up-em-or-3o-ano",
            "proraiz-espcex-2024",
            "proraiz-afa-2024",
            "proraiz-en-efomm-2024",
            "proraiz-esa-2024",
            "proraiz-eear-2024",
            "proraiz-ime-ita-2024",
            "proraiz-cfn-2024",
            "proraiz-eam-2024",
            "2a-serie-ensino-medio-2024",
            "proraiz-bombeiro-2024",
            "proraiz-nivelamento",
            "pv-prodigio-2024-or-pre-vestibular",
            "proraiz-epcar-2024",
            "proraiz-cn-2024",
            "proraiz-barro-branco-2024",
            "proraiz-escolas-tecnicas-2024",
            "1a-serie-ensino-medio-2024",
            "epcar-2023-fx"
        ].includes(courseSlug);
    const shouldRenameMenuToLive = isProraizCoursesShouldRenameMenuToLive;

    const renamedMenus = menus.map((menu) => {
        if (menu.url === "/app/plano-de-estudos" && shouldRenameMenuToVirtualRoom) {
            return {
                ...menu,
                name: "Sala virtual"
            };
        }

        if (menu.url === "/app/plano-de-estudos" && shouldRenameMenuToMeetSpaceAndClassRoom) {
            return {
                ...menu,
                name: "Espaço meet"
            };
        }

        if (menu.url === "/app/plano-de-estudos" && isPromedicinaCoursesShouldRenameMenuToClasses) {
            return {
                ...menu,
                name: "Aulas"
            };
        }

        if (menu.url === "/app/plano-de-estudos" && shouldRenameMenuToLive) {
            return {
                ...menu,
                name: "Ao vivo"
            };
        }

        if (menu.url === "/app/meu-plano-de-estudos" && shouldRenameMenuToMeetSpaceAndClassRoom) {
            return {
                ...menu,
                name: "Sala de aula"
            };
        }

        return menu;
    });

    return renamedMenus;
};

const reorderMenus = (brandSlug: string, courseSlug: string, menus: INavigation[], hasPlan: boolean) => {
    const isProenemOrPromedicinaTurmaIntensiva2023 =
        ["promedicina", "proenem"].includes(brandSlug) &&
        ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(courseSlug);
    const isPromedicinaEnemPro360 = brandSlug === "promedicina" && courseSlug === "enem-pro-360";

    const isProenemOrPromedicinaMedPro360 = ["promedicina", "proenem"].includes(brandSlug) && courseSlug === "med-pro-360";
    const isPromedicinaMedPro2024 = ["promedicina"].includes(brandSlug) && ["medpro-360", "medpro-360-24"].includes(courseSlug);
    const isProenemOrPromedicinaExtensiva2024 = ["promedicina", "proenem"].includes(brandSlug) && ["extensiva-2024"].includes(courseSlug);
    const shouldReorderMenuToFirstPosition =
        isProenemOrPromedicinaMedPro360 || isPromedicinaMedPro2024 || isProenemOrPromedicinaExtensiva2024 || isPromedicinaEnemPro360;

    const isMedExtensiva2024 = ["promedicina", "proenem"].includes(brandSlug) && ["extensiva-med-2024"].includes(courseSlug);
    const shouldReorderMenuToSecondPosition = isMedExtensiva2024;

    const menusToReorder: MenuToReorder[] = [
        { shouldReorder: isProenemOrPromedicinaTurmaIntensiva2023, route: "/app/meu-plano-de-estudos", position: 0 },
        { shouldReorder: shouldReorderMenuToFirstPosition, route: "/app/plano-de-estudos", position: 0 },
        { shouldReorder: shouldReorderMenuToSecondPosition, route: "/app/plano-de-estudos", position: 1 }
    ];

    let reorderedMenus = menus;

    menus.forEach((item) => {
        menusToReorder.forEach((menuToReorder) => {
            if (menuToReorder.shouldReorder && item.url === menuToReorder.route) {
                const reorderedItemsWithoutMenuToReorder = reorderedMenus.filter((reorderedMenu) => reorderedMenu.url !== item.url);

                reorderedMenus = [
                    ...reorderedItemsWithoutMenuToReorder.slice(0, menuToReorder.position),
                    item,
                    ...reorderedItemsWithoutMenuToReorder.slice(menuToReorder.position)
                ];
            }
        });
    }, []);

    return reorderedMenus;
};

export const menuFormatter = (brandSlug: string, courseSlug: string, navigation: INavigation[], isWeekPlanPublished: boolean) => {
    const isCustomPlanPublished = hasLessonPlanCustom(brandSlug, courseSlug);

    const filteredMenus = navigation.filter((item) => {
        const isPlanUnpublished = item.url === "/app/meu-plano-de-estudos" && !isWeekPlanPublished && !isCustomPlanPublished;

        const displayPromedicinaMenu = checkPromedicinaMenuDisplay(brandSlug, courseSlug, item.url);
        const displayPromilitaresMenu = checkPromilitaresMenuDisplay(brandSlug, courseSlug, item.url);
        const displayProenemMenu = checkProenemMenuDisplay(brandSlug, courseSlug, item.url);
        const displayProraizMenu = checkProraizMenuDisplay(brandSlug, courseSlug, item.url);

        return !isPlanUnpublished && displayPromedicinaMenu && displayPromilitaresMenu && displayProenemMenu && displayProraizMenu;
    });

    const renamedMenus = renameMenus(brandSlug, courseSlug, filteredMenus);

    const reorderedMenus = reorderMenus(brandSlug, courseSlug, renamedMenus, isWeekPlanPublished || isCustomPlanPublished);

    const formattedMenus = reorderedMenus.map((menu) => {
        const menuPath = menu.url.split("/app/")[1];

        return {
            ...menu,
            url: !!courseSlug ? `/app/curso/${courseSlug}/${menuPath}` : ""
        };
    });

    return formattedMenus;
};
