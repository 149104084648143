import { theme } from "config/theme";
import styled from "styled-components";

export const StudentAreaScreenSignedCoursesTitle = styled.strong`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};

    :before {
        background-color: ${theme.colors.brand[300]};
        content: "";
        width: 5px;
        height: 24px;
        border-radius: 5px;
        margin-right: ${theme.spacing.smallX};
    }
`;
