import React from "react";
import {
    ExerciseListFooterDropdown,
    ExerciseListFooterKeyboardDropdownIcons,
    ExerciseListFooterKeyboardDropdownContent,
    ExerciseListFooterKeyboardDropdownContentIcons
} from "./styles";
import Dropdown from "components/Dropdown";

import KeyboardFilledIcon from "prodigio-icons/web/filled/Keyboard";

import KeyboardLeftIcon from "prodigio-icons/web/filled/KeyboardLeft";
import KeyboardRightIcon from "prodigio-icons/web/filled/KeyboardRight";

import KeyboardAIcon from "prodigio-icons/web/filled/KeyboardA";
import KeyboardBIcon from "prodigio-icons/web/filled/KeyboardB";
import KeyboardCIcon from "prodigio-icons/web/filled/KeyboardC";
import KeyboardDIcon from "prodigio-icons/web/filled/KeyboardD";
import KeyboardEIcon from "prodigio-icons/web/filled/KeyboardE";
import KeyboardRIcon from "prodigio-icons/web/filled/KeyboardR";
import Tooltip from "components/Tooltip";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

const ExerciseListFooterKeyboardDropdown = () => {
    const isSmallerThanDesktop = useIsMobile(BreakPoints.medium);

    if (isSmallerThanDesktop) {
        return null;
    }

    return (
        <ExerciseListFooterDropdown id="ExerciseListFooterDropdown">
            <Dropdown
                direction="bottom"
                label={
                    <ExerciseListFooterKeyboardDropdownIcons>
                        <Tooltip rounded direction="right" variantColor={300} variant="danger">
                            Atalhos de teclado
                        </Tooltip>
                        <KeyboardFilledIcon width={28} height={12} className="filled-icon" />
                    </ExerciseListFooterKeyboardDropdownIcons>
                }
            >
                <ExerciseListFooterKeyboardDropdownContent>
                    <ExerciseListFooterKeyboardDropdownContentIcons>
                        <KeyboardLeftIcon />
                        <KeyboardRightIcon />
                    </ExerciseListFooterKeyboardDropdownContentIcons>
                    <p>Use as teclas de seta para esquerda ou direita para trocar a questão.</p>

                    <ExerciseListFooterKeyboardDropdownContentIcons>
                        <KeyboardAIcon />
                        <KeyboardBIcon />
                        <KeyboardCIcon />
                        <KeyboardDIcon />
                        <KeyboardEIcon />
                    </ExerciseListFooterKeyboardDropdownContentIcons>
                    <p>Marque uma alternativa com as letras do teclado “A”, “B”, “C”, “D” ou “E”.</p>

                    <ExerciseListFooterKeyboardDropdownContentIcons>
                        <KeyboardRIcon />
                    </ExerciseListFooterKeyboardDropdownContentIcons>
                    <p>Utilize a tecla “R” para exibir/ocultar o cartão de respostas ao longo da execução de exercícios.</p>
                </ExerciseListFooterKeyboardDropdownContent>
            </Dropdown>
        </ExerciseListFooterDropdown>
    );
};

export default React.memo(ExerciseListFooterKeyboardDropdown);
