import React, { memo } from "react";
import { ErrorUIContainer } from "./styles";
import SadIcon from "prodigio-icons/web/filled/Sad";
import Button from "components/Button";

interface IProps {
    title?: string;
    message?: string;
    onClick?(): void;
    withButton?: boolean;
}

const ErrorUI = memo(
    ({ title = "Ops...", message = "Desculpe, tivemos um problema.", onClick = () => window.location.reload(), withButton = true }: IProps) => {
        return (
            <ErrorUIContainer data-test-id="error-screen">
                <SadIcon width={55} height={55} />
                <h2>{title}</h2>
                <p>{message}</p>
                {!!withButton && (
                    <Button onClick={onClick} variant="outline-danger">
                        Tente novamente
                    </Button>
                )}
            </ErrorUIContainer>
        );
    }
);

export default ErrorUI;
