import styled from "styled-components";
import { theme } from "config/theme";

export const KnowledgeAreasResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const KnowledgeAreasResultsTitle = styled.strong`
    font-weight: 600;
    font-size: ${theme.typography.sizes.medium};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.small};
`;

export const KnowledgeAreasResultsWrap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const KnowledgeAreasResultsItem = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.base[100]};
    padding-bottom: ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};
`;

export const KnowledgeAreasResultsItemTitle = styled.p`
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[400]};
`;

export const KnowledgeAreasResultsLegends = styled.div`
    display: flex;
    flex-direction: column;
`;

export const KnowledgeAreasResultsLegendsItem = styled.div<{ variant: "success" | "danger" }>`
    display: flex;
    align-items: center;

    :not(:last-child) {
        margin-bottom: ${theme.spacing.smallX};
    }

    svg {
        margin-right: ${theme.spacing.smallX};
        color: ${({ variant }) => theme.colors.system[variant][300]};
    }

    p {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[400]};

        strong {
            color: ${({ variant }) => theme.colors.system[variant][300]};
            font-weight: 600;
        }
    }
`;
