import styled from "styled-components/macro";
import { theme } from "config/theme";
import { CardStyles } from "components/Card/styles";
import { hexToRgba } from "helpers";
import { MediaQueries } from "assets/styles/settings";

export const ExerciseListScreenEssayDialogueContainer = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    background: ${hexToRgba(theme.colors.base[500], 0.5)};
    width: 100%;
    height: 100%;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        background: unset;
        width: 350px;
        height: unset;
        bottom: 82px;
        right: 0;
    }
`;

export const ExerciseListScreenEssayDialogueCard = styled.div`
    ${CardStyles};
    width: 100%;
    margin: ${theme.spacing.small};
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.largeX} ${theme.spacing.medium};

    > svg {
        margin-bottom: 20px;
    }

    > p {
        text-align: center;
    }
`;

export const ExerciseListScreenEssayDialogueCloseButton = styled.button`
    position: absolute;
    top: ${theme.spacing.small};
    right: ${theme.spacing.small};
    padding: 0;
    margin: 0;
    background: transparent;
`;
