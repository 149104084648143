import { theme } from "config/theme";
import { Grid } from "components/Grid";
import { fadeInUp } from "assets/styles/animations";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { TextFieldWrapper } from "components/Form/TextField/styles";
import { CheckboxHelper } from "components/Form/Checkbox/styles";
import { CardContainerStyle, BodyText1, BodyText2, TitleH4, TitleH3, Micro, ErrorMessage } from "assets/styles/global";
import Select from "components/Select";

export const MigrationUserContainer = styled.section`
    background: ${theme.colors.system.info[100]};
    height: 100%;
    overflow-y: auto;
    padding-bottom: 24px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        ${Grid} {
            max-width: 544px;
            padding: 0;
        }

        ${ButtonWrapper} {
            margin: 0 auto;
            width: 352px;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${Grid} {
            max-width: 624px;
        }
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        justify-content: flex-start;
        padding: 24px 0;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        justify-content: center;

        ${Grid} {
            max-width: 736px;
        }
    }
`;

export const ProdigioLogos = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 36px 0;

    > img {
        max-width: 144px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 16px 32px;

        > img {
            max-width: 126px;
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 0 56px 40px;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        padding: 0 32px 40px;

        > img {
            max-width: 165px;
        }
    }
`;

export const ProdigioChildrens = styled.div`
    align-items: center;
    display: flex;
    margin-top: 24px;

    span {
        background: ${theme.colors.base[200]};
        height: 30px;
        margin: 0 19px;
        width: 1px;
    }

    img {
        &:first-child {
            max-width: 74px;
        }

        &:last-child {
            max-width: 95px;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: 0;

        span {
            height: 40px;
            margin: 0 24px;
        }

        img {
            &:first-child {
                max-width: 93px;
            }

            &:last-child {
                max-width: 110px;
            }
        }
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        span {
            height: 56px;
            margin: 0 32px;
        }

        img {
            &:first-child {
                max-width: 122px;
            }

            &:last-child {
                max-width: 150px;
            }
        }
    }
`;

export const StartTitle = styled.strong`
    ${TitleH4};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: 24px;
    text-align: center;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 32px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${TitleH3};
        margin-bottom: 24px;
    }
`;

export const FinishText = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[400]};

    strong {
        font-weight: 600;
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText1};

        strong {
            font-weight: 700;
        }
    }
`;

export const StartText = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[400]};

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText1};
    }
`;

export const StepWrapper = styled.div`
    ${CardContainerStyle};
    animation: ${fadeInUp} 0.2s linear;
    padding: 16px;

    > ${ErrorMessage} {
        display: block;
        margin-top: 16px;
        text-align: center;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 24px 32px 32px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 24px 48px 32px;
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        padding: 24px 40px 32px;
    }
`;

export const StepEdgesContainer = styled.div`
    ${CardContainerStyle};
    animation: ${fadeInUp} 0.2s linear;
    padding: 24px 16px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 40px 24px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 48px 32px;
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        padding: 48px 64px;
    }
`;

export const StepTitle = styled.p`
    ${Micro};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: 8px;
    text-align: center;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
        margin-bottom: 24px;
    }
`;

export const StepText = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    margin-bottom: 24px;

    a {
        color: ${theme.colors.system.info[400]};
        text-decoration: underline;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText1};
    }
`;

export const StepForm = styled.form``;

export const StepFormGroup = styled.div<{ isHorizontal?: boolean }>`
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-top: 24px;
    }

    ${TextFieldWrapper} {
        &:first-child {
            flex: 1;
        }

        &:last-child {
            flex: 2;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    ${({ isHorizontal }) => {
        if (!isHorizontal) {
            return css`
                flex-direction: column;
            `;
        }

        return css`
            align-items: flex-start;
            flex-direction: row;
        `;
    }}

    .react-select__control {
        border-radius: 5px;
        padding: 15.5px 20px;

        &--menu-is-open {
            .react-select__indicators {
                svg {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .react-select__indicators {
        svg {
            height: 4px;
            transition: transform 0.2s ease;
            width: 8px;
        }
    }

    .react-select__placeholder {
        font-size: 14px;
    }

    .react-select__value-container {
        padding: 0 8px 0 0;

        > input {
            position: absolute;
        }

        /* &--has-value {
            > div {
                &:last-child {
                    display: none;
                }
            }
        } */
    }

    .react-select__option {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .react-select-container {
        width: 150px;
    }

    .react-select__single-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        .react-select-container {
            width: 290px;
        }

        &:last-child {
            margin-top: 32px;
        }
    }
`;

export const StepFormGroupAlter = styled.div`
    border-bottom: 1px solid ${theme.colors.base[200]};
    margin-bottom: 16px;
    padding-bottom: 24px;

    ${StepText} {
        text-align: center;
    }

    ${CheckboxHelper} {
        height: 16px;
        width: 16px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 24px;
    }
`;

export const StepFormGroupWrapper = styled.div`
    flex: 1;
    margin-right: 16px;
`;

export const StepFormPasswordWrapper = styled.div`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin: 0 auto;
        width: 464px;
    }
`;

export const StepTermsBox = styled.div`
    background: ${theme.colors.base[100]};
    border: 1px solid ${theme.colors.base[200]};
    border-radius: ${theme.spacing.smallX};
    max-height: 250px;
    margin-bottom: ${theme.spacing.small};
    overflow-y: auto;
    padding: ${theme.spacing.small};

    p {
        ${BodyText2};
        color: ${theme.colors.base[400]};
    }
`;

export const SelectWrapper = styled(Select)`
    .react-select__control {
        border-radius: 5px;
        padding: 12px 8px;
    }
`;
