// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container, ActionCompositionWrapper, DescriptionCompositionWrapper } from "./styles";

export const FlatVariants = {
    PRIMARY: "primary",
    SECONDARY: "secondary"
} as const;

export type FlatVariant = typeof FlatVariants[keyof typeof FlatVariants];

type WebpModalKnowMoreProps = {
    titleSection: string;
    descriptionComposition: ReactNode;
    actionComposition: ReactNode;
    variant: FlatVariant;
    onClickButtonBack: () => void;
};

export const WebpModalKnowMore: FunctionComponent<WebpModalKnowMoreProps> = ({
    onClickButtonBack,
    titleSection,
    variant,
    actionComposition,
    descriptionComposition
}) => {
    const handleClick = () => {
        onClickButtonBack();
    };

    return (
        <Container variant={variant === "primary" ? "brand" : variant}>
            <div className="button-title">
                <button onClick={() => handleClick()}>{"<"}</button>
                <p>{titleSection}</p>
            </div>
            <DescriptionCompositionWrapper>{descriptionComposition}</DescriptionCompositionWrapper>

            <ActionCompositionWrapper>{actionComposition}</ActionCompositionWrapper>
        </Container>
    );
};
