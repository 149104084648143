import styled from "styled-components";
import { SpinnerWrap } from "components/Spinner/styles";
import { MediaQueriesManual } from "assets/styles/settings";

export const VideoBlocked = styled.div``;

export const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    padding: 0;
    overflow: hidden;
    background: #000;
    display: block;

    &::before {
        display: block;
        content: "";
        padding-top: calc((100% * 9) / 16);
    }

    p,
    ${SpinnerWrap}, ${VideoBlocked} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: rgba(255, 255, 255, 0.6);
        position: absolute;
        top: 0;
        left: 0;
    }

    iframe,
    #video-player,
    > video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    ${MediaQueriesManual(1024)} {
        border-radius: 16px 16px 0px 0px;
    }
`;
