import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import useIsMobile from "hooks/use-is-mobile";

import ApoioIcon from "prodigio-icons/web/filled/Apoio";
import DownloadIcon from "prodigio-icons/web/outlined/Download";
import TemplateSheetIcon from "prodigio-icons/web/filled/TemplateSheet";

import Button from "components/Button";
import Portal from "components/Portal";
import EssayPreview from "./essayPreview";

import EssayPaperModel from "static/folha-modelo-redacao.pdf";

import * as S from "./styles";

import { IReduxStore } from "interfaces/IReduxStore";

interface IEssayCardDetailsHelperProps {
    id: number;
    support: string;
    withButton?: boolean;
}

const EssayCardDetailsAttachment = ({ id, support, withButton = true }: IEssayCardDetailsHelperProps) => {
    const isMobile = useIsMobile();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [isOpen, setIsOpen] = useState(false);
    const [preview, setPreview] = useState<File>();

    const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (!files) {
            return;
        }

        setPreview(files[0]);
        setIsOpen(true);
    }, []);

    const handleClosePreview = useCallback(() => setIsOpen(false), []);

    return (
        <>
            <S.EssayCardDetailsFooterHelper>Downloads</S.EssayCardDetailsFooterHelper>
            <S.EssayCardDetailsFooterList>
                <S.EssayCardDetailsFooterListItem>
                    <S.EssayCardDetailsFooterListItemWrapper>
                        <ApoioIcon />
                        <span>Texto de apoio</span>
                    </S.EssayCardDetailsFooterListItemWrapper>
                    <a href={support} rel="noopener noreferrer" target="_blank" title="Download">
                        <DownloadIcon />
                    </a>
                </S.EssayCardDetailsFooterListItem>
                <S.EssayCardDetailsFooterListItem>
                    <S.EssayCardDetailsFooterListItemWrapper>
                        <TemplateSheetIcon />
                        <span>Folha modelo</span>
                    </S.EssayCardDetailsFooterListItemWrapper>
                    <a href={EssayPaperModel} rel="noopener noreferrer" target="_blank" title="Download">
                        <DownloadIcon />
                    </a>
                </S.EssayCardDetailsFooterListItem>
            </S.EssayCardDetailsFooterList>
            {withButton &&
                (isMobile ? (
                    <S.InputWrapper>
                        <input accept="image/x-png,image/gif,image/jpeg" type="file" onChange={handleFileChange} />
                        <Button variant="info" block={"true"} size="medium">
                            Enviar redação
                        </Button>
                    </S.InputWrapper>
                ) : (
                    <Button as={Link} to={`/app/curso/${courseSlug}/redacoes/temas/${id}/fazer`} variant="info" block={"true"} size="medium">
                        Enviar redação
                    </Button>
                ))}
            {withButton && <Portal portalId="essay-preview">{isOpen ? <EssayPreview file={preview} onClose={handleClosePreview} /> : null}</Portal>}
        </>
    );
};

export default EssayCardDetailsAttachment;
