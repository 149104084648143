import { IAnswerCardItem } from "store/ducks/answerCard";

interface IProps {
    answer?: IAnswerCardItem;
    correctAlternative?: any;
    showAnswer: boolean;
    isFinished: boolean;
    alternativeId: number;
}

/**
 * @returns an object of answer status
 * @param answer IAnswerCardItem
 * @param correctAlternative IQuestionAlternative
 * @param showAnswer boolean
 * @param alternativeId number
 */
const getAnswerStatus = ({ answer, correctAlternative, showAnswer, alternativeId, isFinished }: IProps) => {
    try {
        if (!alternativeId) {
            throw new Error();
        }

        if (!showAnswer && answer?.objectiveAnswer === alternativeId) {
            return {
                isMarked: true
            };
        }

        // Exibe o gabarito mesmo se o usuário nao tiver marcado (com lista finalizada)
        if (showAnswer && isFinished && (!answer || !answer.objectiveAnswer)) {
            return {
                isCorrectNotMarked: alternativeId === correctAlternative.id
            };
        }

        if (!!answer && showAnswer && !!correctAlternative && alternativeId === correctAlternative.id) {
            return {
                isCorrect: true
            };
        }

        if (showAnswer && answer?.objectiveAnswer === alternativeId && !!correctAlternative) {
            return {
                isCorrect: alternativeId === correctAlternative.id,
                isWrong: alternativeId !== correctAlternative.id
            };
        }

        if (answer?.objectiveAnswer === alternativeId) {
            return {
                isMarked: true
            };
        }

        throw new Error();
    } catch (error) {
        return undefined;
    }
};

export default getAnswerStatus;
