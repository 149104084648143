import React from "react";
import { createGlobalStyle } from "styled-components";
import ErrorUI from "components/ErrorUI";

const GlobalStyle = createGlobalStyle`#root, body, html {width: 100%; height: 100%;}`;

const ErrorScreen = () => {
    return (
        <>
            <GlobalStyle />
            <ErrorUI />
        </>
    );
};

export default React.memo(ErrorScreen);
