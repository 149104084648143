import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import QuestionImage from "assets/img/question.svg";
import Button from "components/Button";
import { Grid } from "components/Grid";
import Spinner from "../../../components/Spinner";
import { Creators as modalActions } from "store/ducks/modal";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
import { IReduxStore } from "../../../interfaces/IReduxStore";
import {
    ExerciseListCustomCard,
    ExerciseListCustomCardSubTitle,
    ExerciseListCustomCardTitle,
    ExerciseListCustomContainer,
    ExerciseListCustomHeader,
    ExerciseListCustomList,
    ExerciseListCustomListContainer,
    ExerciseListCustomListItem,
    ExerciseListCustomListTitle,
    ExerciseListCustomSubTitle,
    ExerciseListCustomTitle
} from "./styles";
import { isCourseFree } from "helpers/isCourseFree";

export function CustomExerciseListWelcome() {
    const history = useHistory();
    const dispatch = useDispatch();
    const exerciseListCustom = useSelector((state: IReduxStore) => state.exerciseListCustom);
    const { content, isLoading, courseSlug } = useSelector(({ lessonFreeCTA, course }: IReduxStore) => ({
        content: lessonFreeCTA?.content,
        isLoading: lessonFreeCTA?.isLoading,
        courseSlug: course?.slug
    }));

    const handleCardButtonClick = () => {
        if (isCourseFree(courseSlug)) {
            modalCTA();
            return;
        }
        history.push(`/app/curso/${courseSlug}/banco-de-questoes/novo`);
    };

    const modalCTA = useCallback(() => {
        dispatch(
            modalActions.openModal("callToAction", {
                type: "OPEN_MODAL",
                isCallToAction: true,
                linkHref: content.cta,
                imageUrl: content.bancoQuestoes
            })
        );
    }, [dispatch, content]);

    useEffect(() => {
        if (isCourseFree(courseSlug)) {
            modalCTA();
        }
    }, [modalCTA, courseSlug]);

    useEffect(() => {
        if (isLoading && isCourseFree(courseSlug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, courseSlug, isLoading]);

    return (
        <>
            {exerciseListCustom.isLoading ? (
                <Spinner />
            ) : (
                <Grid fluid>
                    <ExerciseListCustomContainer id="ExerciseListCustomContainer">
                        <ExerciseListCustomHeader>
                            <ExerciseListCustomTitle>Banco de questões</ExerciseListCustomTitle>
                        </ExerciseListCustomHeader>
                        <ExerciseListCustomSubTitle>
                            Bem-vindo ao banco de questões. Crie suas próprias listas de exercícios para estudar.
                        </ExerciseListCustomSubTitle>

                        <ExerciseListCustomCard>
                            <img src={QuestionImage} alt="Nova versão do simulado" />
                            <ExerciseListCustomCardTitle>Comece agora!</ExerciseListCustomCardTitle>
                            <ExerciseListCustomCardSubTitle>Monte a sua primeira lista de exercícios</ExerciseListCustomCardSubTitle>
                            <Button variant="primary" block={true} onClick={handleCardButtonClick}>
                                Criar lista
                            </Button>
                        </ExerciseListCustomCard>

                        <ExerciseListCustomListContainer>
                            <ExerciseListCustomListTitle>Vantagens de criar suas listas de exercícios</ExerciseListCustomListTitle>
                            <ExerciseListCustomList>
                                <ExerciseListCustomListItem>
                                    Mais de 50.000 questões
                                    <span>
                                        A melhor maneira de se preparar para uma prova é treinando! Tenha acesso a 50.000 questões para avaliar seus
                                        conhecimentos e chegar pronto na hora do vestibular.
                                    </span>
                                </ExerciseListCustomListItem>
                                <ExerciseListCustomListItem>
                                    Foque no que você precisa estudar
                                    <span>
                                        Você sabe quais são as suas maiores dificuldades? Agora você pode treinar criando listas por disciplina,
                                        tópico, subtópico, concurso e muito mais!
                                    </span>
                                </ExerciseListCustomListItem>
                                <ExerciseListCustomListItem>
                                    Exercite o que você precisa
                                    <span>
                                        Errou uma questão? Continua com dúvidas sobre um tópico específico? Agora você pode exercitar quantas vezes
                                        você quiser! Domine todos os assuntos e garanta a sua vaga
                                    </span>
                                </ExerciseListCustomListItem>
                            </ExerciseListCustomList>
                        </ExerciseListCustomListContainer>
                    </ExerciseListCustomContainer>
                </Grid>
            )}
        </>
    );
}
