import styled from "styled-components";
import Card from "components/Card";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const ExerciseListDashboardPreviousExamChartContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.small};
`;

export const ExerciseListDashboardPreviousExamChartHeader = styled.header`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.base[100]};
    padding-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
    }

    p {
        display: flex;
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
        margin-bottom: ${theme.spacing.smallXX};

        ${MediaQueries.BIGGER_THAN_SMALL} {
            margin-bottom: unset;
            flex: 1;
        }
    }

    span {
        color: ${theme.colors.base[300]};
        font-size: ${theme.typography.sizes.smallX};
    }

    strong {
        font-weight: 600;
    }
`;
