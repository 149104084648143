// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ActivityAnswersTitleWrapper,
    ActivityAnswersIcon,
    ActivityAnswersTitle,
    AnswerItemCompositionsWrapper,
    ActivityAnswerItemContainer,
    ActivityAnswerItemTextContentWrapper,
    ActivityAnswerItemIndicatorWrapper,
    ActivityAnswerItemIndicator,
    ActivityAnswerItemLabel,
    ActivityAnswersContainer
} from "./activity-answers.styles";

// Types
import { ActivityAnswerItemProps, ActivityAnswerItemVariant, ActivityAnswersProps } from "./activity-answers.types";

// Utils
import { ternary } from "utils/ternary";

export const ActivityAnswers: FunctionComponent<ActivityAnswersProps> = ({ icon, title, answerItemCompositions }) => {
    return (
        <ActivityAnswersContainer>
            <ActivityAnswersTitleWrapper>
                <ActivityAnswersIcon src={icon} />

                <ActivityAnswersTitle>{title}</ActivityAnswersTitle>
            </ActivityAnswersTitleWrapper>

            <AnswerItemCompositionsWrapper>{answerItemCompositions}</AnswerItemCompositionsWrapper>
        </ActivityAnswersContainer>
    );
};

export const ActivityAnswerItem: FunctionComponent<ActivityAnswerItemProps> = ({
    variant = "default",
    indicator,
    label,
    actionButtonComposition
}) => {
    const backgroundColor = ternary([
        [variant === "default", "#CED0DD"],
        [variant === "danger", "#F25555"],
        [variant === "success", "#1BA660"]
    ]) as ActivityAnswerItemVariant;

    const color = ternary([
        [variant === "default", "#4D4D63"],
        [variant === "danger", "#232326"],
        [variant === "success", "#232326"]
    ]) as ActivityAnswerItemVariant;

    return (
        <ActivityAnswerItemContainer className="webapp-activity-answer-item">
            <ActivityAnswerItemTextContentWrapper>
                <ActivityAnswerItemIndicatorWrapper backgroundColor={backgroundColor}>
                    <ActivityAnswerItemIndicator color={color}>{indicator}</ActivityAnswerItemIndicator>
                </ActivityAnswerItemIndicatorWrapper>

                <ActivityAnswerItemLabel>{label}</ActivityAnswerItemLabel>
            </ActivityAnswerItemTextContentWrapper>

            {actionButtonComposition}
        </ActivityAnswerItemContainer>
    );
};
