import React, { useCallback, useEffect, useRef } from "react";

// Redux
import { useDispatch } from "react-redux";

// Helpers
import useMathJaxParser from "hooks/use-math-jax-parser";

interface IProps {
    enunciation: string;
}

const Enunciation = ({ enunciation }: IProps) => {
    const dispatch = useDispatch();
    const contentRef = useRef<HTMLDivElement>(null);

    // Load parser
    useMathJaxParser(contentRef);

    const handleClickImage = useCallback(
        (image: any) => {
            const imageSrc = typeof image?.src === "object" ? JSON.stringify(image.src) : image.src;

            try {
                if (!!image?.src) {
                    dispatch({
                        type: "OPEN_MODAL",
                        element: "image",
                        action: { src: imageSrc }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        try {
            (window as any).showImage = handleClickImage;
        } catch (error) {
            console.log(error);
        }
    }, [handleClickImage]);

    if (!enunciation) {
        return null;
    }

    const enunciationImage = enunciation.replace(/<img/g, "<img onclick='window.showImage(this)'");

    return <div className="ck-content" dangerouslySetInnerHTML={{ __html: enunciationImage }} ref={contentRef} />;
};

export default React.memo(Enunciation);
