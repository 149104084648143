import React, { useMemo, memo } from "react";
import {
    ExamDayResultBasicInfosContainer,
    ExamDayResultBasicInfosLegend,
    ExamDayResultBasicInfosTime,
    ExamDayResultBasicInfosKnowledgeAreas
} from "./styles";

import TimerIcon from "prodigio-icons/web/filled/Timer";
import AnswerCardProgress from "components/AnswerCardProgress";
import { getInterval } from "utils/getInterval";
import Button from "components/Button";
import { DateTime } from "luxon";
import { IExamDayResultKnowledgeArea } from "interfaces/IExamDays";

interface IProps {
    loading: boolean;
    startDate: string;
    endDate: string;
    knowledgeAreas: IExamDayResultKnowledgeArea[];
    totalCorrects: number;
    totalQuestions: number;
    totalWrong: number;
    onClickFeedback(): void;
    isAnswerReleased: boolean;
}

const ExamDayResultBasicInfos = memo(
    ({
        startDate,
        endDate,
        loading,
        knowledgeAreas,
        totalWrong = 0,
        totalQuestions = 0,
        totalCorrects = 0,
        onClickFeedback,
        isAnswerReleased
    }: IProps) => {
        const memoTimer = useMemo(
            () => (
                <ExamDayResultBasicInfosTime>
                    <TimerIcon width={16} />
                    <p>Tempo de execução: {getInterval({ endDate, startDate })}</p>
                </ExamDayResultBasicInfosTime>
            ),
            [endDate, startDate]
        );

        const memoKnowledgeAreas = useMemo(
            () => (
                <ExamDayResultBasicInfosKnowledgeAreas>
                    {knowledgeAreas
                        .filter((item) => !!item.knowledgeArea)
                        .map((item, index) => (
                            <p key={index}>&bull; {item.knowledgeArea.name}</p>
                        ))}
                </ExamDayResultBasicInfosKnowledgeAreas>
            ),
            [knowledgeAreas]
        );

        return (
            <ExamDayResultBasicInfosContainer>
                <ExamDayResultBasicInfosLegend>
                    Realizado em {DateTime.fromISO(endDate).isValid ? DateTime.fromISO(endDate).toFormat("dd/MM/yyyy") : "-"}
                </ExamDayResultBasicInfosLegend>

                {memoKnowledgeAreas}

                <AnswerCardProgress
                    answersCorrect={totalCorrects}
                    answersWrong={totalWrong}
                    questionsTotal={totalQuestions}
                    totalAnswered={totalQuestions}
                    isFinished={true}
                />

                {memoTimer}

                <Button type="button" variant="primary" block={true} onClick={onClickFeedback}>
                    {isAnswerReleased ? "Ver gabarito" : "Ver respostas"}
                </Button>
            </ExamDayResultBasicInfosContainer>
        );
    }
);

export default ExamDayResultBasicInfos;
