// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SectionSpacing = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;

    @media (min-width: 768px) {
        padding: 0;
    }
`;

export const SectionInformationsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
    }
`;

export const SectionTitle = styled.div`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 768px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const SectionDescription = styled.div`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3a3e47;
`;

export const Divider = styled.div`
    width: 100%;
    height: 0px;
    border: 1px solid #f0f1f7;
`;

export const CommentsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    gap: 0 8px;
    padding: 0 16px 4px;

    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px) {
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        max-height: 430px;
        gap: 8px 0;
        padding: 0 16px 4px 0;

        ::-webkit-scrollbar {
            display: block;
        }
    }

    @media (min-width: 1024px) {
        padding-right: 24px;
    }
`;
