// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: 16px;
    border-radius: 8px;
    background: #ffffff;
`;

export const IconWrapper = styled.div`
    width: 40px;
    height: 40px;

    background-color: #571d5d;
    border-radius: 50%;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;

export const Icon = styled.img`
    width: 100%;
    height: 100%;
`;

export const NoteWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NoteValue = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #1e9369;

    @media (min-width: 1024px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
    }
`;

export const NoteContent = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #505462;

    @media (min-width: 1024px) {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
    }
`;

// ----------

export const IconWrapperSkeleton = styled.div`
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 48px;
        height: 48px;
    }
`;

export const NoteValueSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        height: 36px;
        padding: 6px 0;
    }
`;

export const NoteContentSkeleton = styled.div`
    height: 22px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        padding: 3px 0;
    }
`;
