import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import styled from "styled-components/macro";
import { Micro, CardContainerSmallStyle } from "assets/styles/global";

export const EssayDispatchRow = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ico-chevron-right {
        margin-left: ${theme.spacing.smallX};
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
        margin-bottom: ${theme.spacing.smallX};
        padding-bottom: ${theme.spacing.smallX};
    }
`;

export const EssayDispatchTxt = styled.span`
    ${Micro};
    color: ${theme.colors.base[300]};

    strong {
        color: ${theme.colors.base[500]};
    }
`;

export const EssayDispatchThemeTitle = styled(EssayDispatchTxt)`
    color: ${theme.colors.base[400]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const EssayDispatchContainer = styled.div`
    ${CardContainerSmallStyle};
    border-radius: ${theme.spacing.smallXX};
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.smallX} ${theme.spacing.small};

    > ${EssayDispatchTxt} {
        flex: 1;
    }
`;

export const EssayDispatchesContainer = styled.div`
    margin-bottom: ${theme.spacing.small};
`;
