import React, { useCallback, memo } from "react";

// Assets
import * as S from "./styles";

interface ISwitcherProps {
    activeIndex: number;
    options: string[];
    onChange(index: number): void;
}

const Switcher = ({ activeIndex, options, onChange }: ISwitcherProps) => {
    const handleChange = useCallback((index: number) => () => onChange(index), [onChange]);

    return (
        <S.SwitcherContainer>
            {options.map((option, index) => {
                const isActive = activeIndex === index;

                return (
                    <S.SwitcherItem key={index} isActive={isActive} onClick={handleChange(index)}>
                        {option}
                    </S.SwitcherItem>
                );
            })}
        </S.SwitcherContainer>
    );
};

export default memo(Switcher);
