import styled from "styled-components";
import { BreakPoints } from "../../../assets/styles/settings";

export const FilterScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    @media screen and (min-width: ${BreakPoints.medium}px) {
        height: 100%;
        justify-content: space-between;
    }
`;

export const FilterGrid = styled.div`
    display: grid;
    padding: 0 1rem;

    margin: 0 auto;
    width: 100%;

    grid-template-areas:
        "header"
        "form"
        "summary";
    @media screen and (min-width: ${BreakPoints.tablet}px) {
        padding: 0 1rem;
        column-gap: 2rem;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas:
            "header header header header header header"
            "form form form form listing listing"
            "summary summary summary summary summary summary";
    }

    @media screen and (min-width: ${BreakPoints.wide}px) {
        max-width: 1120px;
        grid-template-columns: auto auto auto 256px;
        grid-template-areas:
            "header header header header"
            "form form form listing"
            "form form form listing"
            "form form form summary";
    }
    @media screen and (min-width: 1432px) {
        padding: 0;
    }

    .filter-header {
        grid-area: header;
        text-align: center;

        @media screen and (min-width: ${BreakPoints.tablet}px) {
            text-align: left;
        }

        .section-title {
            margin-top: 1.5rem;
            font-weight: 700;
            @media screen and (min-width: ${BreakPoints.tablet}px) {
                margin-top: 3rem;
            }
        }
        .intro-text {
            margin: 1rem auto 2rem;
        }
    }

    .filter-form {
        grid-area: form;
    }

    .filter-listing {
        display: none;
        grid-area: listing;

        @media screen and (min-width: ${BreakPoints.tablet}px) {
            display: block;
        }
    }

    .filter-summary {
        grid-area: summary;
        margin: 2rem 0 0;
        @media screen and (min-width: ${BreakPoints.wide}px) {
            margin: 0;
            align-self: end;
        }
    }
`;
export const FooterBar = styled.div`
    padding: 1rem;
    width: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    background-color: white;

    margin-top: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: ${BreakPoints.tablet}px) {
        margin-top: 2rem;
        padding: 1.25rem;
    }

    @media screen and (min-width: ${BreakPoints.wide}px) {
        padding: 1.25rem;
    }

    .footer-bar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        flex-direction: column;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            flex-direction: row;
        }

        @media screen and (min-width: ${BreakPoints.wide}px) {
            max-width: 1120px;
        }
    }

    .form-group {
        display: flex;
        flex-shrink: 0;
        flex-grow: 1;
        order: 1;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            width: auto;
            order: 2;
            justify-content: flex-end;
            flex-direction: row;
        }
    }

    .create-input {
        width: 100%;
        max-height: 46.48px;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            max-width: 62%;
        }
        @media screen and (min-width: ${BreakPoints.wide}px) {
            max-width: 378px;
        }
    }

    .footer-btn {
        min-width: 60px;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            width: 28.51%;
        }
        @media screen and (min-width: ${BreakPoints.wide}px) {
            width: 162px;
        }
    }

    #go-back {
        margin-top: 1rem;
        width: 100%;
        order: 2;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-top: 0;
            order: 1;
            margin-right: 2rem;
            width: 162px;
        }
        @media screen and (min-width: ${BreakPoints.wide}px) {
            margin-right: 1rem;
        }
    }

    #create {
        min-width: 90px;
        margin-top: 1rem;
        @media screen and (min-width: ${BreakPoints.tablet}px) {
            margin-top: 0;
            margin-left: 1rem;
        }
        @media screen and (min-width: ${BreakPoints.wide}px) {
            margin-left: 1rem;
        }
    }
`;
