// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

export const FlatVariants = {
    PRIMARY: "primary",
    NEUTRAL: "neutral"
} as const;
export type FlatVariant = typeof FlatVariants[keyof typeof FlatVariants];

export type ChipSelectedPayload = { selected: boolean; label: string };

export type ChipCloseButtonClickedPayload = { label: string };

type WebpChipProps = {
    label: string;
    slot?: string;
    selected?: boolean;
    disabled?: boolean;
    wrapLine?: boolean;
    withCloseButton?: boolean;
    variant?: FlatVariant;
    onChipSelected?: (payload: ChipSelectedPayload) => void;
    onChipCloseButtonClicked?: (payload: ChipCloseButtonClickedPayload) => void;
};

export const WebpChip: FunctionComponent<WebpChipProps> = ({
    onChipCloseButtonClicked,
    onChipSelected,
    disabled = false,
    label,
    variant,
    withCloseButton,
    wrapLine,
    selected
}) => {
    const handleClick = (ev: any) => {
        if (withCloseButton || disabled) return;
        if (!!onChipSelected) {
            onChipSelected({ ...ev, detail: { selected: !selected, label: label } });
        }
    };

    const handleCloseButton = (ev: any) => {
        if (!!onChipCloseButtonClicked) {
            onChipCloseButtonClicked({ ...ev, detail: { label: label } });
        }
    };

    return (
        <Container
            className="webp-chip"
            aria-disabled={disabled}
            onClick={handleClick}
            variant={variant === "primary" ? "brand" : "base"}
            wrapLine={wrapLine}
            disabled={!!disabled}
            withCloseButton={withCloseButton}
            selected={!!selected}
        >
            <span className="label-text">{label}</span>
            {withCloseButton && (
                <span className="close-button" onClick={handleCloseButton}>
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.09871 1.09761C1.2289 0.967452 1.43995 0.967466 1.57012 1.09764L5.001 4.5287L8.4317 1.09829C8.56187 0.968126 8.77293 0.968126 8.9031 1.09829C9.03328 1.22845 9.03328 1.43949 8.9031 1.56966L5.47237 5.0001L8.90312 8.43103C9.03328 8.5612 9.03327 8.77224 8.90308 8.90239C8.7729 9.03255 8.56185 9.03253 8.43168 8.90236L5.00097 5.47147L1.56977 8.90238C1.43959 9.03254 1.22854 9.03254 1.09836 8.90238C0.968188 8.77221 0.968188 8.56118 1.09836 8.43101L4.52959 5.00007L1.09868 1.56897C0.968514 1.4388 0.968529 1.22776 1.09871 1.09761Z"
                            fill="currentColor"
                        />

                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.957355 0.956158C1.16565 0.747911 1.50333 0.747934 1.71159 0.956209L5.00106 4.24585L8.29033 0.95685C8.49861 0.748589 8.83629 0.74859 9.04457 0.95685C9.25285 1.16512 9.25285 1.5028 9.04457 1.71107L5.75526 5.0001L9.04459 8.2896C9.25286 8.49788 9.25284 8.83556 9.04454 9.04382C8.83625 9.25206 8.49857 9.25204 8.2903 9.04377L5.001 5.75429L1.71123 9.04379C1.50295 9.25205 1.16527 9.25205 0.956997 9.04379C0.74871 8.83552 0.748709 8.49784 0.956997 8.28957L4.2468 5.00005L0.957304 1.71038C0.749031 1.50209 0.749054 1.16441 0.957355 0.956158ZM1.42874 1.23904C1.37667 1.18697 1.29224 1.18697 1.24017 1.23903C1.1881 1.29109 1.1881 1.37548 1.24015 1.42754L4.67107 4.85864C4.70857 4.89615 4.72964 4.94702 4.72964 5.00006C4.72964 5.05311 4.70857 5.10398 4.67106 5.14148L1.23983 8.57243C1.18777 8.62448 1.18777 8.70888 1.23983 8.76094C1.2919 8.813 1.37633 8.813 1.4284 8.76094L4.8596 5.33003C4.93771 5.25192 5.06434 5.25193 5.14244 5.33004L8.57315 8.76093C8.62522 8.813 8.70965 8.81301 8.76173 8.76094C8.81379 8.70889 8.8138 8.62449 8.76174 8.57243L5.33099 5.1415C5.29349 5.104 5.27242 5.05312 5.27242 5.00008C5.27242 4.94704 5.29349 4.89617 5.331 4.85866L8.76173 1.42822C8.8138 1.37616 8.8138 1.29176 8.76173 1.23971C8.70966 1.18764 8.62523 1.18764 8.57316 1.2397L5.14246 4.67012C5.06435 4.74822 4.93772 4.74821 4.85962 4.67011L1.42874 1.23904Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
            )}
        </Container>
    );
};
