// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

// Assets
import Attention from "./assets/Attention.svg";

// Components
import { WebpText } from "components/WebpText";
import { WebpCard } from "components/WebpCard";

type WebpFilterListProps = {
    titleText?: string;
    descriptionText?: string;
    shouldDisplayEmptyState?: boolean;
    onClearButton?: () => void;
    filterItemComposition?: ReactNode;
    showClearButton: boolean;
    scrollableWidth?: number;
    formWrapperHeight?: number;
    type: "lesson-plan-custom" | "exercise-list-custom";
};

export const WebpFilterList: FunctionComponent<WebpFilterListProps> = ({
    onClearButton,
    descriptionText,
    titleText,
    filterItemComposition,
    shouldDisplayEmptyState,
    showClearButton,
    type
}) => {
    const handleClearButton = () => {
        if (!!onClearButton) {
            onClearButton();
        }
    };

    return (
        <Container type={type}>
            <WebpCard
                headerComposition={
                    <div className="header-container">
                        <WebpText xs="body-text-01" sm="title-xss" color="neutral" colorWeight={500}>
                            <h3>{titleText}</h3>
                        </WebpText>

                        {!!showClearButton && (
                            <button className="header-container-clean" onClick={() => handleClearButton()}>
                                <WebpText xs="body-text-02" sm="title-xss" color="neutral">
                                    <span>Limpar</span>
                                </WebpText>
                            </button>
                        )}
                    </div>
                }
                contentComposition={
                    <div>
                        {!!shouldDisplayEmptyState && <>{filterItemComposition}</>}

                        {!shouldDisplayEmptyState && (
                            <div className="description-container">
                                <img src={Attention} alt="Attention icon" />

                                <WebpText xs="body-text-02" color="neutral" colorWeight={500}>
                                    <p>{descriptionText}</p>
                                </WebpText>
                            </div>
                        )}
                    </div>
                }
            ></WebpCard>
        </Container>
    );
};
