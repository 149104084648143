import Card from "components/Card";
import styled from "styled-components";
import { theme } from "config/theme";
import { BadgeContainer } from "components/Badge/styles";
import { MediaQueries } from "assets/styles/settings";

export const FilesCardContainer = styled(Card)`
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin: ${theme.spacing.smallX};
    }
`;

export const FilesCardContent = styled.div`
    padding: ${theme.spacing.small};

    a {
        text-align: center;
    }
`;

export const FilesCardHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing.medium};

    ${BadgeContainer} {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: ${theme.spacing.smallX};
    }
`;

export const FilesCardHeaderDate = styled.div`
    display: flex;
    align-items: center;
    color: ${theme.colors.base[300]};

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    span {
        font-size: ${theme.typography.sizes.smallX};
    }
`;

export const FilesCardTitle = styled.strong`
    color: ${theme.colors.base[500]};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
`;

export const FilesCardSubtitle = styled.p`
    display: block;

    color: ${theme.colors.base[300]};
    font-size: ${theme.typography.sizes.small};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.3rem;
    height: calc(2.6rem);
    overflow: hidden;
`;
