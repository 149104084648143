import React from "react";
import {
    ExerciseListQuestionDiscursiveAnswerContainer,
    ExerciseListQuestionDiscursiveAnswerText,
    ExerciseListQuestionDiscursiveAnswerDivider,
    ExerciseListQuestionDiscursiveAnswerTeacherFeedback
} from "./styles";
import { IAnswerCardItem } from "store/ducks/answerCard";
import Badge from "components/Badge";

import AttentionIcon from "prodigio-icons/web/filled/Attention";
import ErrorIcon from "prodigio-icons/web/filled/Error";

interface IProps {
    answer: IAnswerCardItem;
    showAnswer: boolean;
}

const ExerciseListQuestionDiscursiveAnswer = ({ answer, showAnswer }: IProps) => {
    const { correctionComment, discursiveAnswer, points } = answer;

    return (
        <ExerciseListQuestionDiscursiveAnswerContainer>
            <ExerciseListQuestionDiscursiveAnswerDivider />

            {showAnswer && <Badge variant="info">Nota da questão: {points}</Badge>}

            <ExerciseListQuestionDiscursiveAnswerText>
                {!!discursiveAnswer && !!discursiveAnswer.trim().length ? (
                    <>
                        <span>Sua resposta:</span> {discursiveAnswer}
                    </>
                ) : (
                    <>
                        <ErrorIcon width={16} height={16} />
                        <span>Você não respondeu a questão.</span>
                    </>
                )}
            </ExerciseListQuestionDiscursiveAnswerText>

            {!!showAnswer && !!correctionComment && !!correctionComment.trim().length && (
                <>
                    <ExerciseListQuestionDiscursiveAnswerDivider />

                    <ExerciseListQuestionDiscursiveAnswerTeacherFeedback>
                        <AttentionIcon width={16} height={16} /> <span>Feedback do professor:</span>
                    </ExerciseListQuestionDiscursiveAnswerTeacherFeedback>

                    <ExerciseListQuestionDiscursiveAnswerText>
                        <span>{correctionComment}</span>
                    </ExerciseListQuestionDiscursiveAnswerText>
                </>
            )}
        </ExerciseListQuestionDiscursiveAnswerContainer>
    );
};

export default ExerciseListQuestionDiscursiveAnswer;
