// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceTopicProps } from "./performance-topic.types";

// Styles
import {
    PerformanceTopicWrapper,
    PerformanceTopicInfoWrapper,
    PerformanceTopicPositionWrapper,
    PerformanceTopicNumber,
    PerformanceTopicName,
    PerformanceTopicContentWrapper,
    PerformanceTopicQuestionsWrapper,
    PerformanceTopicQuestion,
    PerformanceTopicQuestionDivider,
    PerformanceTopicAssertivenessWrapper,
    PerformanceTopicAssertivenessIndicator,
    PerformanceTopicAssertiveness,
    Border
} from "./performance-topic.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";

// Helpers
import { leftPad } from "helpers/left-pad";

export const PerformanceTopic: FunctionComponent<PerformanceTopicProps> = ({
    informations,
    totalHits,
    totalQuestions,
    hitsPercentage,
    hitsPercentageIndicatorColor
}) => {
    const { name, position } = informations;

    return (
        <>
            <PerformanceTopicWrapper>
                <PerformanceTopicInfoWrapper>
                    <PerformanceTopicPositionWrapper backgroundColor={position.backgroundColor}>
                        <PerformanceTopicNumber color={position.color}>{position.number}</PerformanceTopicNumber>
                    </PerformanceTopicPositionWrapper>

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={4} />} />

                    <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="horizontal" size={24} />} />

                    <PerformanceTopicName>{name}</PerformanceTopicName>
                </PerformanceTopicInfoWrapper>

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={12} />} />

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="horizontal" size={32} />} />

                <PerformanceTopicContentWrapper>
                    <PerformanceTopicQuestionsWrapper>
                        <PerformanceTopicQuestion>Questões: {leftPad(totalQuestions, 2)}</PerformanceTopicQuestion>

                        <Spacing direction="horizontal" size={16} />

                        <PerformanceTopicQuestionDivider />

                        <Spacing direction="horizontal" size={16} />

                        <PerformanceTopicQuestion>Acertos: {leftPad(totalHits, 2)}</PerformanceTopicQuestion>

                        <Spacing direction="horizontal" size={16} />
                    </PerformanceTopicQuestionsWrapper>

                    <PerformanceTopicAssertivenessWrapper>
                        <PerformanceTopicAssertivenessIndicator color={hitsPercentageIndicatorColor}></PerformanceTopicAssertivenessIndicator>

                        <Spacing direction="horizontal" size={8} />

                        <PerformanceTopicAssertiveness>Aproveitamento: {leftPad(hitsPercentage, 2)}%</PerformanceTopicAssertiveness>
                    </PerformanceTopicAssertivenessWrapper>
                </PerformanceTopicContentWrapper>
            </PerformanceTopicWrapper>

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={768} content={<Spacing direction="vertical" size={12} />} />

            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={768} content={<Spacing direction="vertical" size={16} />} />

            <Border />
        </>
    );
};
