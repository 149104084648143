import React, { memo, useState, useCallback, useEffect, useMemo } from "react";

// Dependencies
import MenuIcon from "prodigio-icons/web/outlined/MenuRight";
// import SearchIcon from "prodigio-icons/web/outlined/Search";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

// Components
import If from "components/If";
// import Tooltip from "components/Tooltip";
import MenuProfileButton from "./components/MenuProfileButton";
import Notification from "./components/Notification";
import { WebpNotificationPubMural, WebpNotificationPub } from "webapp-components/dist/libs/webp-components-react";
// TODO: utilizar novo componente após refatorar o WebpNotificationPubMural
// import { WebpNotificationPub } from "components/WebpNotificationPub";

// Redux
import { connect } from "react-redux";
import { Creators as notificationAction } from "store/ducks/notification";
import { INotification, NotificationStatus } from "store/interfaces/INotification";
import { useDispatch, useSelector } from "react-redux";

// Helpers
import { theme } from "config/theme";
import { IReduxStore } from "interfaces/IReduxStore";
import { IAnnouncementState } from "store/ducks/announcement";
import history from "services/history";

// Assets
import { ActionButtonHeader, HeaderContainer, LeftContentHeader, RightContentHeader, ModalNotification, BGModalNotification } from "./styles";
export interface IHeaderProps {
    isMobile: boolean;
    isMenuExpanded: boolean;
    announcement?: IAnnouncementState;
    onMenuClick(): void;
    onAnnouncementClick(data: any): void;
    onSearchClick(): void;
}

const Header = ({ isMobile, isMenuExpanded, onMenuClick, onSearchClick }: IHeaderProps) => {
    const [notificationAll, setNotificationAll] = useState<INotification[]>([]);
    const [notificationNotRead, setNotificationNotRead] = useState<INotification[]>([]);
    const [isNotificationModalOpened, setIsNotificationModalOpened] = useState(false);
    const [activeClapping, setActiveClapping] = useState(false);

    const dispatch = useDispatch();
    const { notifications, courseSlug, isAuthenticatedByLayers } = useSelector((state: IReduxStore) => ({
        notifications: state.notification.notifications,
        courseSlug: state.course.slug,
        isAuthenticatedByLayers: state.auth.isAuthenticatedByLayers
    }));

    const isB2CBrands = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

    const hasNotificationsNotViewed = useMemo(() => !!notificationAll?.find((notification) => notification?.status === NotificationStatus.new), [
        notificationAll
    ]);

    const SIXTY_SECONDS = 60000;

    useEffect(() => {
        dispatch(notificationAction.getNotificationsRequest());
    }, [dispatch]);

    const changeStatusNotifications = useCallback((status: number) => {
        setNotificationAll((notifications) =>
            notifications?.map((notification) => {
                if (notification?.status === NotificationStatus.new) {
                    return {
                        ...notification,
                        status
                    };
                }
                return notification;
            })
        );

        setNotificationNotRead((notifications) =>
            notifications?.map((notification) => {
                if (notification?.status === NotificationStatus.new) {
                    return {
                        ...notification,
                        status
                    };
                }
                return notification;
            })
        );
    }, []);

    const changeStatusNotificationById = useCallback((id: string) => {
        setNotificationAll((notifications) =>
            notifications?.map((notification) => {
                if (notification?.id === id) {
                    return {
                        ...notification,
                        status: NotificationStatus.read
                    };
                }
                return notification;
            })
        );

        setNotificationNotRead((notifications) => notifications?.filter((notification) => notification.id !== id));
    }, []);

    const handleToggleModal = useCallback(() => {
        setIsNotificationModalOpened(!isNotificationModalOpened);

        if (!isNotificationModalOpened) {
            dispatch(notificationAction.setViewedAllNotificationsRequest());

            changeStatusNotifications(NotificationStatus.viewed);
        }
    }, [changeStatusNotifications, dispatch, isNotificationModalOpened]);

    useEffect(() => {
        setNotificationAll(notifications.all);
        setNotificationNotRead(notifications.notRead);
    }, [notifications]);

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(notificationAction.getNotificationsRequest());
        }, SIXTY_SECONDS);

        if (isNotificationModalOpened) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [dispatch, isNotificationModalOpened]);

    useEffect(() => {
        if (!notificationNotRead.length) {
            return setActiveClapping(true);
        }
        setActiveClapping(false);
    }, [notificationNotRead]);

    const cleanNotificationsNotRead = useCallback(() => {
        setActiveClapping(true);
        setNotificationNotRead([]);
        dispatch(notificationAction.setReadAllNotificationsRequest());
    }, [dispatch]);

    const onClickViewNotification = useCallback(
        (notification: INotification) => {
            const idVideo = notification?.metadata?.IdVideo;
            const idPostParent = notification?.metadata?.IdPostParent;

            const { id: notificationId } = notification;

            if (notificationId) {
                changeStatusNotificationById(notificationId);

                dispatch(notificationAction.setReadByIdNotificationRequest({ notificationId }));
            }

            if (idVideo) {
                history.push(`/app/curso/${courseSlug}/video/${idVideo}/notificacao/${idPostParent}`);
                setIsNotificationModalOpened(false);
            }
        },
        [changeStatusNotificationById, courseSlug, dispatch]
    );

    return (
        <HeaderContainer isMenuExpanded={isMenuExpanded}>
            <LeftContentHeader onClick={() => setIsNotificationModalOpened(false)}>
                <ActionButtonHeader id="hamburger-menu" onClick={onMenuClick}>
                    <MenuIcon />
                </ActionButtonHeader>

                {/* <ActionButtonHeader onClick={onSearchClick}>
                    <SearchIcon />
                    <Tooltip direction="bottom">Buscar conteúdo</Tooltip>
                </ActionButtonHeader> */}

                <If test={isMobile}>
                    <Link to="/">
                        <img src={theme.project.logoSmall} alt={theme.project.name} width="27" height="24" />
                    </Link>
                </If>
            </LeftContentHeader>

            <RightContentHeader>
                {isB2CBrands && <Notification onToggleModal={handleToggleModal} hasNotificationsNotViewed={hasNotificationsNotViewed} />}

                {!isAuthenticatedByLayers && (
                    <div onClick={() => setIsNotificationModalOpened(false)}>
                        <MenuProfileButton />
                    </div>
                )}
            </RightContentHeader>

            {isNotificationModalOpened && (
                <>
                    <BGModalNotification onClick={() => setIsNotificationModalOpened(false)} />
                    <ModalNotification>
                        <WebpNotificationPubMural
                            onClickClear={cleanNotificationsNotRead}
                            onClickCloseDropDown={() => setIsNotificationModalOpened(false)}
                            labelNotNotification="Você não tem novas notificações."
                            activeClapping={activeClapping}
                        >
                            {notificationAll?.map((notification: INotification) => (
                                <WebpNotificationPub
                                    slot="content"
                                    key={notification.id}
                                    badge={notification.badge}
                                    labelButton={notification.ctaLabel}
                                    message={notification.message}
                                    photoUser={notification.thumb}
                                    time={
                                        DateTime.fromISO(notification.date).isValid
                                            ? DateTime.fromISO(notification.date)
                                                  .toRelative()
                                                  ?.toString()
                                            : "-"
                                    }
                                    onClickLookAnswer={() => onClickViewNotification(notification)}
                                    onClickNotificationPub={() => onClickViewNotification(notification)}
                                    isNotRead={notification.status !== NotificationStatus.read}
                                />
                            ))}

                            {notificationNotRead?.map((notification: INotification) => (
                                <WebpNotificationPub
                                    slot="contentNew"
                                    key={notification.id}
                                    badge={notification.badge}
                                    labelButton={notification.ctaLabel}
                                    message={notification.message}
                                    photoUser={notification.thumb}
                                    time={
                                        DateTime.fromISO(notification.date).isValid
                                            ? DateTime.fromISO(notification.date)
                                                  .toRelative()
                                                  ?.toString()
                                            : "-"
                                    }
                                    onClickLookAnswer={() => onClickViewNotification(notification)}
                                    onClickNotificationPub={() => onClickViewNotification(notification)}
                                    isNotRead={notification.status !== NotificationStatus.read}
                                />
                            ))}
                        </WebpNotificationPubMural>
                    </ModalNotification>
                </>
            )}
        </HeaderContainer>
    );
};

const mapStateToProps = ({ announcement }: IReduxStore) => ({ announcement });

export default memo(connect(mapStateToProps)(Header));
