import { createActions, createReducer } from "reduxsauce";
import { IPreviousExamState, PreviousExamActionsType, IPreviousExamActionsCreators } from "store/interfaces/IPreviousExam";

export const { Creators, Types } = createActions<Record<PreviousExamActionsType, PreviousExamActionsType>, IPreviousExamActionsCreators>({
    getPreviousExamRequest: ["payload"],
    getPreviousExamSuccess: ["payload"],
    getPreviousExamFailure: ["payload"],

    getPreviousExamDetailRequest: ["payload"],
    getPreviousExamDetailSuccess: ["payload"],
    getPreviousExamDetailFailure: ["payload"],

    setCurrentExamDay: ["payload"],

    setDurationExamDay: ["payload"],

    clearPreviousExam: []
});

export const INITIAL_STATE: IPreviousExamState = {
    isLoading: false,
    items: [],
    relateds: [],
    item: {
        id: 0,
        name: "",
        slug: "",
        points: "",
        average: "",
        type: 1,
        showRanking: false,
        examDays: [],
        examPoints: {
            knowledgeAreas: [],
            days: [],
            total: {
                answers: []
            }
        },
        description: ""
    },
    currentExamDay: {
        id: 0,
        name: "",
        slug: "",
        startDate: "",
        endDate: "",
        duration: "",
        isOpen: false,
        isReleased: false,
        isAnswerReleased: false,
        isPaid: false,
        exerciseList: {}
    },
    durationExamDay: 0,
    page: 1,
    totalItems: 0,
    count: 1,
    totalPages: 1
};

const request = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const failure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

// Get All

const getExamSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    items: action.payload,
    isLoading: false
});

// Get by ID

const getExamDetailSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    item: action.payload.item,
    relateds: action.payload.relateds,
    isLoading: false
});

// ExamDay
const setCurrentExamDay = (state = INITIAL_STATE, action: any) => ({
    ...state,
    currentExamDay: action.payload
});

// Duration
const setDurationExamDay = (state = INITIAL_STATE, action: any) => ({
    ...state,
    durationExamDay: action.payload
});

const clearPreviousExam = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.GET_PREVIOUS_EXAM_REQUEST]: request,
    [Types.GET_PREVIOUS_EXAM_SUCCESS]: getExamSuccess,
    [Types.GET_PREVIOUS_EXAM_FAILURE]: failure,

    [Types.GET_PREVIOUS_EXAM_DETAIL_REQUEST]: request,
    [Types.GET_PREVIOUS_EXAM_DETAIL_SUCCESS]: getExamDetailSuccess,
    [Types.GET_PREVIOUS_EXAM_DETAIL_FAILURE]: failure,

    [Types.SET_CURRENT_EXAM_DAY]: setCurrentExamDay,

    [Types.SET_DURATION_EXAM_DAY]: setDurationExamDay,

    [Types.CLEAR_PREVIOUS_EXAM]: clearPreviousExam
});
