// Dependencies
import styled from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const Container = styled.div`
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    background-color: #f0f1f7;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        width: 320px;
    }

    ${MediaQueries.BIGGER_THAN_LARGE} {
        justify-content: flex-start;
        width: 140px;
    }
`;

export const BadgeIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;

export const BadgeLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: #045332;
    white-space: nowrap;
`;
