import React, { useCallback, useEffect, useRef, memo } from "react";

// Dependencies
import FileIcon from "prodigio-icons/web/filled/Apoio";

// Components
import Button from "components/Button";
import Filter from "components/Filter";
import FilesCard from "./components/Card";
import PageLoader from "components/PageLoader";

// Helpers
import { IFilesState } from "store/interfaces/IFile";
import { ISelectOption } from "interfaces/ISelectOption";

// Assets
import { Grid, Row } from "components/Grid";
import { FilesContainer, FilesHeader, FilesTitle, FilesGetMore, FilesEmpty } from "./styles";

interface IFilesProps extends IFilesState {
    onRequestFiles(page: number, category?: number): void;
    onOpenModal(linkHTML: string, title: string): void;
}

const Files = ({ categories, documents, isLoading, onOpenModal, onRequestFiles }: IFilesProps) => {
    const headerRef = useRef<HTMLDivElement>(null);

    const scrollToTop = useCallback(() => {
        try {
            if (!!headerRef.current) {
                headerRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [headerRef]);

    useEffect(() => {
        if (isLoading) {
            scrollToTop();
        }
    }, [isLoading, scrollToTop]);

    const handleLoadMore = useCallback((page: number) => () => onRequestFiles(page), [onRequestFiles]);
    const handleClickOpenModal = useCallback((linkHTML: string, title: string) => onOpenModal(linkHTML, title), [onOpenModal]);
    const handleChangeFilter = useCallback((option: ISelectOption) => onRequestFiles(1, (!!option && option.value) || ""), [onRequestFiles]);

    return (
        <>
            {isLoading && <PageLoader />}
            <Grid style={{ height: "100%" }}>
                <FilesContainer id="FilesContainer">
                    <FilesHeader ref={headerRef}>
                        <FilesTitle>Comunicados</FilesTitle>
                        <Filter options={categories} onChange={handleChangeFilter} />
                    </FilesHeader>
                    {!isLoading && (!documents || !documents.items || !documents.items.length) && (
                        <FilesEmpty>
                            <FileIcon width={32} height={32} />
                            <strong>Nenhum arquivo foi encontrado.</strong>
                        </FilesEmpty>
                    )}
                    {!!documents.items && Array.isArray(documents.items) && (
                        <Row>
                            {documents.items
                                .filter((item) => !!item.material)
                                .map((item) => (
                                    <FilesCard onOpenModal={handleClickOpenModal} item={item} key={item.id} />
                                ))}
                            {documents.totalPages > documents.page && (
                                <FilesGetMore>
                                    <Button variant="outline-primary" onClick={handleLoadMore(documents.page + 1)} size="medium">
                                        Carregar mais
                                    </Button>
                                </FilesGetMore>
                            )}
                        </Row>
                    )}
                </FilesContainer>
            </Grid>
        </>
    );
};

export default memo(Files);
