// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    width: max-content;
    background-color: transparent;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

export const FileInputField = styled.input`
    display: none;
`;
