// Dependencies
import React, { useState, useEffect, useCallback } from "react";

// Redux
import { IReduxStore } from "interfaces/IReduxStore";
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as ContentSearchActions } from "store/ducks/contentSearch";

// Components
import If from "components/If";
import { Row } from "components/Grid";
import ModalContentSearch from "./components/Modal";
import SearchField from "./components/SearchField";
import Separator from "./components/Separator";
import ContentCard from "./components/ContentCard";

// Helpers
import { IContentSearch } from "interfaces/IContentSearch";
import { IContentSearchAdvancedOption } from "interfaces/IContentSearchAdvancedOption";
import { getContentName, getSelectedAdvancedOptions, parseContent } from "./utils";

// Assets
import { CategoryColumn, CategoryContainer, CategoryTitleContainer, CategoryTitle, CategoryTotals } from "./styles";
import useDebounce from "hooks/use-debounce";

interface IProps {
    isOpen: boolean;
    contents: IContentSearch;
    advancedOptions: IContentSearchAdvancedOption[];
    contentSearchActions: {
        getContentRequest(payload: string): void;
        setOpenModal(open: boolean): void;
        setAdvancedOption(advancedOptions: IContentSearchAdvancedOption[]): void;
    };
}

const ContentSearch = ({ isOpen, contents, advancedOptions, contentSearchActions }: IProps) => {
    const [search, setSearch] = useState("");

    const debounceSearch = useDebounce(search, 500);

    const getContent = useCallback(() => {
        contentSearchActions.getContentRequest(debounceSearch);
    }, [contentSearchActions, debounceSearch]);

    useEffect(() => {
        if (!debounceSearch) {
            return;
        }

        getContent();
    }, [debounceSearch, getContent]);

    const onCloseModal = useCallback(() => {
        contentSearchActions.setOpenModal(false);
        setSearch("");
        contentSearchActions.getContentRequest("");
    }, [contentSearchActions]);

    const renderContentCards = (key: string, currentContents: any[]) => {
        const parsedContent = parseContent(key, currentContents);

        const mappedcurrentContents = parsedContent.map((content: any, index: number) => (
            <CategoryColumn xs={12} sm={6} lg={4} key={index}>
                <ContentCard
                    key={`${index}|${content.title}`}
                    link={content.link}
                    title={content.title}
                    badge={content.tag}
                    date={content.date}
                    breadcrumb={content.breadcrumbs}
                    onCloseModal={() => contentSearchActions.setOpenModal(false)}
                />
            </CategoryColumn>
        ));

        return mappedcurrentContents;
    };

    const renderFoundContents = () => {
        const selectedOptions = getSelectedAdvancedOptions(advancedOptions);

        const foundContents = Object.entries(contents)
            .filter(([, values]) => values.length > 0)
            .filter(([key]) => (selectedOptions.length > 0 ? selectedOptions.includes(key) : true))
            .map(([key, values]) => (
                <CategoryContainer key={key}>
                    <CategoryTitleContainer>
                        <CategoryTitle>{getContentName(key)}</CategoryTitle>
                        <If test={selectedOptions.length === 1}>
                            <CategoryTotals>{values.length}</CategoryTotals>
                        </If>
                    </CategoryTitleContainer>
                    <Row>{renderContentCards(key, values)}</Row>
                </CategoryContainer>
            ));

        return foundContents;
    };

    return (
        <If test={isOpen}>
            <ModalContentSearch title="Busca de conteúdo" onClose={onCloseModal}>
                <SearchField value={search} placeholder="Digite sua busca" onChange={(event) => setSearch(event.target.value)} />
                <Separator />
                {renderFoundContents()}
            </ModalContentSearch>
        </If>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    contentSearchActions: bindActionCreators(ContentSearchActions, dispatch)
});

const mapStateToProps = (state: IReduxStore) => ({
    isOpen: state.contentSearch.isOpen,
    contents: state.contentSearch.data,
    advancedOptions: state.contentSearch.advancedOptions
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ContentSearch as any);
