import { IExamDay } from "interfaces/IExamDays";
import React, { Fragment, useCallback, useEffect, useMemo } from "react";
import { Creators as examActions } from "store/ducks/exam";
import history from "services/history";
import { IReduxStore } from "interfaces/IReduxStore";
import { bindActionCreators, Dispatch } from "redux";
import Portal from "components/Portal";
import AnswerCardModal from "components/AnswerCardModal";
import { ExamDayStatus } from "enums/ExamDayStatus";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import { Creators as answerCardActions } from "store/ducks/answerCard";
import { connect } from "react-redux";
import { IExamActions } from "store/ducks/exam/types";
import { IAnswerCardActions } from "store/interfaces/IActions";

interface IProps {
    examDay: IExamDay;
    activeResultModal: boolean;
    answerCardId: number;
    isAnswerCardFinished: boolean;
    examActions: IExamActions;
    isAnswerCardLoading: boolean;
    answerCardActions: IAnswerCardActions;
    hasEssay: boolean;
    hasSentEssay: boolean;
    courseSlug: string;
}

const ExamResultModal = ({
    examDay,
    activeResultModal,
    answerCardId,
    isAnswerCardFinished,
    examActions,
    isAnswerCardLoading,
    answerCardActions,
    hasEssay,
    hasSentEssay,
    courseSlug
}: IProps) => {
    useEffect(() => {
        return () => answerCardActions.clearCurrentAnswerCard();
    }, [answerCardActions]);

    const handleClickSeeResultQuestion = useCallback(
        (questionNumber: number) => {
            if (!examDay?.id || !examDay?.exam?.id || !answerCardId) {
                return;
            }

            history.push(`/app/curso/${courseSlug}/simulados/${examDay.exam.id}/${examDay.id}/prova/${answerCardId}?questao=${questionNumber}`);
        },
        [answerCardId, courseSlug, examDay]
    );

    const isAvailable = useMemo(() => {
        const { status, exam } = examDay;

        if (!status) {
            return false;
        }

        return !!exam?.isAvailableForTests || !!exam?.isAvailable || status === ExamDayStatus.InProgress;
    }, [examDay]);

    return (
        <Portal portalId="exam-day-result">
            {activeResultModal ? (
                <AnswerCardModal
                    showAnswers={false}
                    showNotes={false}
                    isFinished={false}
                    onClickClose={() => examActions.closeExamResult()}
                    onClickSeeQuestion={handleClickSeeResultQuestion}
                    canSeeQuestion={isAvailable || !isAnswerCardFinished}
                    isLoading={isAnswerCardLoading}
                />
            ) : (
                <Fragment />
            )}
        </Portal>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    examActions: bindActionCreators(examActions, dispatch),
    answerCardActions: bindActionCreators(answerCardActions, dispatch) as any
});

const mapStateToProps = ({ exam, answerCard, course }: IReduxStore) => {
    return {
        activeResultModal: exam.activeResultModal,
        examDay: exam.day,
        answerCardId: answerCard?.answerCard?.id,
        isAnswerCardFinished: answerCard?.answerCard?.status >= AnswerCardStatus.Done,
        isAnswerCardLoading: answerCard.isLoading,
        hasEssay: !!exam?.day?.essayTheme?.idTheme,
        hasSentEssay: !!answerCard?.answerCard?.examResolution?.idComposition,
        courseSlug: course.slug
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExamResultModal);
