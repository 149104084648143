// Dependencies
import { Settings } from "luxon";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { theme } from "config/theme";

// Routes
import { Redirect, Route, RouteComponentProps, Switch, withRouter, useLocation } from "react-router";
import privateRoutes from "routes/privateRoutes";

// Redux
import { bindActionCreators, Dispatch } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { ICourseState } from "store/ducks/course";
import { Creators as weekPlansActions } from "store/ducks/weekPlans";
import { Creators as lessonPlanCustomActions } from "store/ducks/lessonPlanCustom";
import { Creators as subjectActions } from "store/ducks/subject";
import { Creators as modalActions } from "store/ducks/modal";
import { IAnnouncementActions, IContentSearchActions } from "store/interfaces/IActions";
import { ICourseActions } from "store/interfaces/ICourse";
import { ILessonPlanActions } from "store/interfaces/ILessonPlan";
import { IModalActions } from "store/interfaces/IModal";
import { IAuthActions } from "store/interfaces/IAuth";
import { Creators as ModalActions } from "store/ducks/modal";
import { Creators as CourseActions } from "store/ducks/course";
import { Creators as LessonPlanActions } from "store/ducks/lesson-plan";
import { Creators as AnnouncementActions } from "store/ducks/announcement";
import { Creators as ContentSearchActions } from "store/ducks/contentSearch";
import { Creators as AuthActions } from "store/ducks/auth";

// Services
import api from "services/api";

// HOCs
import withCheckSession from "hocs/auth";

// Helpers
import { isPromilitaresOldCourses } from "helpers/isPromilitaresOldCourses";
import getContext from "helpers/getContext";
import useIsLargeDesktop from "hooks/use-is-large-desktop";
import useIsMobile from "hooks/use-is-mobile";

// Components
import { Asset } from "component-library/utilities/asset";
import Header from "./components/Header";
import AnnouncementBar from "./components/announcement-bar";
import Menu from "./components/Menu";
import { Toast } from "components/Toast";
import AnnouncementToast from "components/AnnouncementToast";
import { Children, ChildrenWrapper, Content, ToastText, ToastButton } from "./styles";

// Assets
import { BreakPoints } from "assets/styles/settings";
import arrowRight from "assets/img/arrow-right.svg";
import smiley from "assets/img/smiley.svg";

export interface IProps {
    hasOrdersProblem: boolean;
    idCourse: number;
    idLessonPlan: number;
    zoneName: string;
    children?: any;
    courseActions: ICourseActions;
    lessonPlanActions: ILessonPlanActions;
    modalActions: IModalActions;
    contentSearchActions: IContentSearchActions;
    announcementActions: IAnnouncementActions;
    authActions: IAuthActions;
    weekPlansIsPublished: boolean;
    token: string;
}
// This array defines which school will have an external link in the menu
const BRANDS_WITH_EXTERNAL_SCHOOL_CLASS = ["escola-gente"];

const Shell = (props: IProps & RouteComponentProps<any>) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const { course, menu, userEmail, platformSearch } = useSelector(({ course, auth, credentials }: IReduxStore) => ({
        course,
        menu: auth.menu,
        userEmail: credentials.email,
        platformSearch: auth.platformSearch
    }));

    const isLargeDesktop = useIsLargeDesktop();
    const isSmallDesktopOrBigger = useIsLargeDesktop(BreakPoints.medium);
    const [expanded, setExpanded] = useState(isLargeDesktop);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [menuClassExpanded, setMenuClassExpanded] = useState(false);
    const [wasFormDeclinedOrAnswered, setWasFormDeclinedOrAnswered] = useState(true);
    const [wasToastDeclined, setWasToastDeclined] = useState(true);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [isPlatformMessagesDisplayed, setIsPlatformMessagesDisplayed] = useState(false);

    const wasFormDeclinedOrAnsweredKey = `was-form-declined-or-answered:${userEmail}`;
    const wasFormDeclinedOrAnsweredStorageValue = JSON.parse(localStorage.getItem(wasFormDeclinedOrAnsweredKey) || "[]");
    const wasToastDeclinedKey = `was-toast-declined:${userEmail}`;
    const wasToastDeclinedStorageValue = JSON.parse(localStorage.getItem(wasToastDeclinedKey) || "[]");

    const isB2CBrands = ["proenem", "promedicina", "promilitares"].includes(theme.project.slug);

    const platformMessages = useMemo(() => {
        return [
            {
                key: "notice-platform-under-maintenance",
                shouldDisplay: false,
                isValid: ["proenem", "promedicina"].includes(theme.project.slug),
                content: (
                    <p>
                        Este é um aviso para informá-lo sobre uma manutenção programada que nosso time de engenharia realizará na plataforma de cursos
                        no dia 10 de agosto, a partir das 00h. Durante este período, a plataforma estará temporariamente inacessível.
                        <br />
                        <br />
                        A manutenção é uma parte crucial para garantir a eficácia e segurança dos nossos serviços. Assim, solicitamos a sua
                        compreensão enquanto realizamos essas atualizações importantes.
                        <br />
                        <br />
                        Se tiver qualquer dúvida ou se encontrar algum problema, por favor, não hesite em entrar em contato conosco pelo chat entre as
                        09h e as 18h, ou pela central de ajuda. Nossa equipe de suporte estará pronta para ajudá-lo.
                        <br />
                        <br />
                        A previsão de retorno das atividades na plataforma é às 8h da manhã do dia 10 de agosto.
                        <br />
                        <br />
                        Agradecemos pela sua paciência e compreensão. O nosso objetivo é sempre proporcionar o melhor serviço para o seu sucesso!
                    </p>
                )
            },
            {
                key: "notice-essay-correction-time",
                shouldDisplay: false,
                isValid: ["proenem", "promedicina"].includes(theme.project.slug) && pathname.includes("/redacoes"),
                content: (
                    <p>
                        Oi, ProAlunos e ProAlunas!{" "}
                        <span role="img" aria-label="Hand">
                            🖐️
                        </span>
                        <br />
                        <br />
                        Devido ao{" "}
                        <span role="img" aria-label="Boom">
                            💥
                        </span>{" "}
                        boom de redações enviadas nessa reta final, as correções podem levar um pouquinho mais de tempo do que vocês estão habituados,
                        mas sempre dentro dos 10 dias previstos nos nossos termos de uso.
                        <br />
                        <br />
                        <span role="img" aria-label="Calendar">
                            📅
                        </span>{" "}
                        <strong>Dica de Ouro:</strong> Para garantir a correção <strong>antes da prova do ENEM</strong>, mande suas redações até o dia{" "}
                        <strong>20 de outubro</strong>. Após essa data, continuamos corrigindo, mas o feedback pode ficar em cima do laço ou nem ser
                        corrigida a tempo.
                        <br />
                        <br />
                        Obrigado pela compreensão e foco no ENEM! 🚀
                        <br />
                    </p>
                )
            },
            {
                key: "notice-promilitares-old-courses",
                shouldDisplay: false,
                isValid: isPromilitaresOldCourses(course.slug, theme.project.slug),
                content: (
                    <p>
                        Todos os anos realizamos atualizações em nosso conteúdo de acordo com os editais dos concursos militares, sempre com o
                        compromisso de entregar aos nossos alunos o que há de melhor e mais atual no quesito preparação.
                        <br />
                        <br />
                        Por isso, ao entrar na plataforma você vai perceber que disponibilizamos mais um curso, chamado de “2023/2024”, que contém
                        todo o novo conteúdo incluído recentemente.
                        <br />
                        <br />
                        Se você já tiver um planejamento definido no curso atual não precisa se preocupar, porque todo o planejamento pedagógico
                        estruturado em ambos os cursos foi pensado no compromisso de te entregar a melhor preparação.
                        <br />
                        <br />
                        Bons estudos!
                    </p>
                )
            }
        ];
    }, [course.slug, pathname]);

    useEffect(() => {
        if (isPlatformMessagesDisplayed) {
            return;
        }

        platformMessages.forEach((message) => {
            const formattedKey = `${message.key}:${userEmail}`;

            if (!!message.isValid && !Boolean(localStorage.getItem(formattedKey)) && !!message.shouldDisplay) {
                dispatch(
                    modalActions.openModal("confirmAction", {
                        type: "OPEN_MODAL",
                        hasCloseButton: false,
                        hasCancelButton: true,
                        widthContainer: "550",
                        alignText: "justify",
                        title: "Atenção",
                        text: message.content,
                        variant: "warning",
                        variantCancelButton: "outline-warning",
                        buttonText: "OK",
                        cancelButtonText: "Fechar e não mostrar novamente",
                        onCancel: () => localStorage.setItem(formattedKey, JSON.stringify(true))
                    })
                );
            }

            if (!message.shouldDisplay && !!Boolean(localStorage.getItem(formattedKey))) {
                localStorage.removeItem(formattedKey);
            }
        });

        setIsPlatformMessagesDisplayed(true);
    }, [dispatch, isPlatformMessagesDisplayed, platformMessages, userEmail]);

    const requestExternalSchoolClasses = useCallback(() => {
        if (!BRANDS_WITH_EXTERNAL_SCHOOL_CLASS.includes(theme.project.slug)) {
            return;
        }

        props.courseActions.getExternalSchoolClassRequest();
    }, [props.courseActions]);

    useEffect(() => {
        requestExternalSchoolClasses();
    }, [requestExternalSchoolClasses]);

    const handleToast = (submit = false) => {
        if (!submit) {
            localStorage.setItem(wasFormDeclinedOrAnsweredKey, JSON.stringify([...wasFormDeclinedOrAnsweredStorageValue, platformSearch.listId]));
        }

        setIsToastVisible(false);

        if (!!submit && !!platformSearch?.url) {
            dispatch(
                modalActions.openModal("showIFrame", {
                    type: "OPEN_MODAL",
                    hasCloseButton: false,
                    title: `Pesquisa de satisfação ${theme.project.name}`,
                    source: platformSearch.url
                })
            );
        }
    };

    const handleCloseButtonToast = () => {
        localStorage.setItem(wasToastDeclinedKey, JSON.stringify([...wasToastDeclinedStorageValue, platformSearch.listId]));
        setIsToastVisible(false);
    };

    useEffect(() => {
        if (!wasFormDeclinedOrAnswered && !!course?.slug) {
            props.authActions.platformSearchRequest({ courseSlug: course.slug });
        }
    }, [props.authActions, platformSearch.listId, course, wasFormDeclinedOrAnswered]);

    useEffect(() => {
        setWasFormDeclinedOrAnswered(!!wasFormDeclinedOrAnsweredStorageValue.includes(platformSearch.listId));
        setWasToastDeclined(!!wasToastDeclinedStorageValue.includes(platformSearch.listId));
    }, [platformSearch.listId, wasFormDeclinedOrAnsweredStorageValue, wasToastDeclinedStorageValue]);

    useEffect(() => {
        if (!!platformSearch.hasSearch && !!platformSearch.hasAnswered && !wasFormDeclinedOrAnswered) {
            localStorage.setItem(wasFormDeclinedOrAnsweredKey, JSON.stringify([...wasFormDeclinedOrAnsweredStorageValue, platformSearch.listId]));
            setWasFormDeclinedOrAnswered(true);
        }
    }, [
        wasFormDeclinedOrAnsweredStorageValue,
        platformSearch.hasAnswered,
        platformSearch.hasSearch,
        platformSearch.listId,
        wasFormDeclinedOrAnswered,
        wasFormDeclinedOrAnsweredKey,
        platformSearch,
        wasToastDeclined
    ]);

    useEffect(() => {
        setIsToastVisible(platformSearch.hasSearch && !platformSearch.hasAnswered && !wasFormDeclinedOrAnswered && isB2CBrands);
    }, [isB2CBrands, platformSearch.hasAnswered, platformSearch.hasSearch, wasFormDeclinedOrAnswered]);

    useEffect(() => {
        if (props.history) {
            props.history.listen(() => {
                api.defaults.headers.common["X-Context"] = getContext();
            });
        }
    }, [props.history]);

    useEffect(() => {
        Settings.defaultZoneName = props.zoneName.trim();
        Settings.defaultLocale = "pt-BR";
    }, [props.zoneName]);

    useEffect(() => {
        setExpanded(isLargeDesktop);
    }, [isLargeDesktop]);

    useEffect(() => {
        // Clear lesson plan when the current page is not 'plano-de-estudos'
        if (!props.location.pathname.includes("plano-de-estudos") && Boolean(props.idLessonPlan)) {
            props.lessonPlanActions.clearLessonPlan();
        }
    }, [props.location, props.lessonPlanActions, props.idLessonPlan]);

    const handleMenuClick = useCallback(() => {
        if (isSmallDesktopOrBigger) {
            setExpanded(!expanded);

            return;
        }

        if (!isMenuVisible) {
            setMenuClassExpanded(false);
        }

        setIsMenuVisible(!isMenuVisible);
    }, [expanded, isMenuVisible, isSmallDesktopOrBigger]);

    const handleClassBoxClick = useCallback(() => {
        if (isSmallDesktopOrBigger && !expanded) {
            setMenuClassExpanded(true);
            setExpanded(true);

            return;
        }

        setMenuClassExpanded(!menuClassExpanded);
    }, [expanded, isSmallDesktopOrBigger, menuClassExpanded]);

    const handleChangeCourse = useCallback(
        (course: ICourseState) => {
            props.courseActions.setCourse({ course });
            props.courseActions.getExternalSchoolClassRequest();

            if (props.idCourse !== course.id) {
                props.authActions.clearPlatformSearch();
                dispatch(weekPlansActions.getWeekPlansIsPublishedRequest());
                dispatch(weekPlansActions.clearWeekPlans());
                props.lessonPlanActions.clearLessonPlan();
                props.lessonPlanActions.clearLastVisitedDateUrl();
                dispatch(lessonPlanCustomActions.clearLessonPlanCustom());

                if (pathname.includes("/materias")) {
                    dispatch(subjectActions.clearSubject());

                    const payload = {
                        endpoint: "/student/subject-v2"
                    };

                    dispatch(subjectActions.getSubjectsRequest(payload));
                    dispatch(subjectActions.getSubjectProgressRequest());
                }

                localStorage.removeItem("date-start");
                localStorage.removeItem("date-end");
            }

            const [, ...routePath] = pathname.split("/curso/")[1].split("/");

            props.history.push(`/app/curso/${course.slug}/${routePath.join("/")}`);
        },
        [dispatch, pathname, props.authActions, props.courseActions, props.history, props.idCourse, props.lessonPlanActions]
    );

    const handleOpenModalAnnouncement = useCallback((data) => props.modalActions.openModal("announcement", { id: data.id, notification: data }), [
        props.modalActions
    ]);

    const handleOpenSearchModal = useCallback(() => props.contentSearchActions.setOpenModal(true), [props.contentSearchActions]);

    const showOrderAnnouncement = useMemo(() => !props.location.pathname.includes("assinatura"), [props.location.pathname]);

    const isProenemOrPromedicinaTurmaIntensiva2023 =
        ["turma-intensiva-2023", "turma-intensiva-23-v2", "turma-intensiva-23-v3", "turma-intensiva-23-v4"].includes(course.slug) &&
        ["proenem", "promedicina"].includes(theme.project.slug);

    if (pathname === "/app" && course.slug !== undefined && !isProenemOrPromedicinaTurmaIntensiva2023 && !!menu[0].url) {
        return <Redirect exact={true} from="/app" to={menu[0].url} />;
    }

    if (pathname === "/app" && course.slug !== undefined && isProenemOrPromedicinaTurmaIntensiva2023) {
        return <Redirect exact={true} from="/app" to={`/app/curso/${course.slug}/meu-plano-de-estudos`} />;
    }

    if (pathname === "/app") {
        return null;
    }

    return (
        <>
            <Content>
                <Toast
                    isVisible={isToastVisible}
                    handleClickClose={handleCloseButtonToast}
                    title="Queremos ouvir você!"
                    icon={smiley}
                    content={
                        <>
                            <ToastText>
                                Para entregar a melhor experiência aos nossos estudantes, preparamos uma pesquisa sobre comportamento de uso e
                                satisfação com o produto.
                            </ToastText>
                            <ToastText strong>Nos ajude a aprimorar o {theme.project.name}, sua opinião é muito importante para nós!</ToastText>
                        </>
                    }
                    buttons={[
                        {
                            label: (
                                <ToastButton>
                                    <Asset source={arrowRight} color="#FFFFFF" size={16} />
                                    Responder pesquisa
                                </ToastButton>
                            ),
                            variant: "info",
                            handleClick: () => handleToast(true)
                        },
                        ...(!!wasToastDeclined
                            ? [
                                  {
                                      label: "Não tenho interesse",
                                      variant: "outline-info" as any,
                                      handleClick: () => handleToast()
                                  }
                              ]
                            : [])
                    ]}
                />
                {(!isMobile || isMenuVisible) && (
                    <Menu
                        isMobile={isMobile}
                        visible={isMenuVisible}
                        expanded={expanded}
                        classExpanded={menuClassExpanded}
                        setClassExpanded={handleClassBoxClick}
                        onClose={handleMenuClick}
                        onChangeCourse={handleChangeCourse}
                    />
                )}
                <Children isMenuExpanded={expanded}>
                    <Header
                        isMobile={isMobile}
                        isMenuExpanded={expanded}
                        onAnnouncementClick={handleOpenModalAnnouncement}
                        onMenuClick={handleMenuClick}
                        onSearchClick={handleOpenSearchModal}
                    />
                    <ChildrenWrapper id="scrollable">
                        <Switch>
                            <Redirect from="/app/curso/:courseSlug/listas-extras/:slug" to="/app/curso/:courseSlug/avaliacoes/:slug" exact />
                            <Redirect from="/app/curso/:courseSlug/listas-extras/:slug/:id" to="/app/curso/:courseSlug/avaliacoes/:slug/:id" exact />
                            <Redirect from="/app/curso/:courseSlug/listas-extras" to="/app/curso/:courseSlug/avaliacoes" exact />
                            {privateRoutes.map((route, index) => (
                                <Route exact={route.exact} path={route.path} component={route.component} key={index} />
                            ))}
                        </Switch>
                    </ChildrenWrapper>
                    {showOrderAnnouncement && props.hasOrdersProblem && (
                        <AnnouncementBar
                            text={`No momento da sua compra o registro do seu endereço não foi finalizado... Complete as informações para envio do seu kit.`}
                            buttonLabel="Completar dados"
                            onAction={`/app/curso/${course.slug}/area-do-aluno/perfil/assinaturas`}
                        />
                    )}
                </Children>
            </Content>
            <AnnouncementToast announcementActions={props.announcementActions} onOpenModal={handleOpenModalAnnouncement} />
        </>
    );
};

const MemoizedShell = withRouter(memo(Shell));

const mapDispatchToProps = (dispatch: Dispatch) => ({
    announcementActions: bindActionCreators(AnnouncementActions, dispatch),
    contentSearchActions: bindActionCreators(ContentSearchActions, dispatch),
    courseActions: bindActionCreators(CourseActions, dispatch),
    lessonPlanActions: bindActionCreators(LessonPlanActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});

const mapStateToProps = ({ course, credentials, lessonPlan, studentArea }: IReduxStore) => ({
    idCourse: course.id,
    idLessonPlan: lessonPlan.id,
    zoneName: credentials.timezone.zoneName.trim(),
    hasOrdersProblem: credentials.hasOrdersProblem
});

export default withCheckSession(connect(mapStateToProps, mapDispatchToProps)(MemoizedShell as any));
