import React, { useMemo } from "react";

// Dependencies
import AttentionIcon from "prodigio-icons/web/outlined/Attention";

// Helpers
import { theme } from "config/theme";
import { ComplaintTypeEnum, IComplaint } from "store/interfaces/IForum";

// Assets
import * as C from "./styles";

const texts = new Map<ComplaintTypeEnum, string>([
    [ComplaintTypeEnum.DubiousLink, "Conteúdo denunciado por conter um link duvidoso"],
    [ComplaintTypeEnum.Offensive, "Conteúdo denunciado por conter termos ofensivos"],
    [ComplaintTypeEnum.Spam, "Conteúdo denunciado por ser um spam"],
    [ComplaintTypeEnum.WrongSubject, "Conteúdo denunciado por não pertencer à esta disciplina"]
]);

const Complaint: React.FC<IComplaint> = (props) => {
    const text = useMemo(() => {
        const item = texts.get(props.complaintType);

        if (!item) {
            return "Conteúdo removido por possuir uma denuncia";
        }

        return item;
    }, [props.complaintType]);

    return (
        <C.ComplaintContainer>
            <AttentionIcon width="20" height="20" color={theme.colors.base[300]} />
            <C.ComplaintText>{text}</C.ComplaintText>
        </C.ComplaintContainer>
    );
};

export default Complaint;
