import { IFilterGroup } from "interfaces/IFilterGroup";
import { filterDictionary } from "./filterDictionary";

const PLURAL_BLACK_LIST = ["status"];

function replaceStringPlural(str: string) {
    if (PLURAL_BLACK_LIST.includes(str)) {
        return str;
    }

    const lastChart = str.slice(-1);

    return lastChart === "s" ? str.slice(0, -1) : str;
}

export function formatResponseToFilter(response: any, replacePlural?: boolean): IFilterGroup[] {
    try {
        const keys = Object.keys(response);

        return keys.map((categoryKey, index) => {
            const key = replacePlural ? replaceStringPlural(categoryKey) : categoryKey;

            const filter: any = {
                id: response[categoryKey]?.id || index,
                title: filterDictionary[key] || key
            };

            const values = response[categoryKey];
            const haveObjValues = values.some((value: any) => typeof value === "object");

            if (!!haveObjValues) {
                filter.items = values.map((obj: any) => ({
                    value: obj.slug || obj.id,
                    label: obj.name || ""
                }));

                const haveSlug = values.some((obj: any) => !!obj.slug);

                if (haveSlug) {
                    filter.slug = `${key}.slug`;
                } else {
                    filter.slug = key;
                }
            } else {
                filter.slug = key;
                filter.items = values.map((value: any) => ({
                    value,
                    label: value
                }));
            }

            return filter;
        });
    } catch (error) {
        console.log("ERROR AO FORMATAR O FILTRO", error);
        return [];
    }
}
