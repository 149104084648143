import styled from "styled-components";
import { Link } from "react-router-dom";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";

export const CTA = styled.span`
    line-height: 16px;
    font-size: ${theme.typography.sizes.small};
    background: ${theme.colors.brand[300]};
    color: ${theme.colors.white};
    text-align: center;
    border-radius: 0 0 4px 4px;
    font-family: ${theme.typography.family.secondary};
    font-weight: bold;
    height: 30px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    width: 100%;
    display: flex;
    transition: background 0.2s ease, color 0.2s ease;
    flex-shrink: 0;

    &:hover {
        background: ${theme.colors.brand[400]};
    }
`;

export const Container = styled(Link)`
    display: flex;
    background: #fff;
    box-shadow: ${theme.shadow[1]};
    flex-direction: column;
    border-radius: 4px;
    width: 210px;
    height: 100%;
    position: relative;
    transition: box-shadow 0.2s ease;

    > * {
        position: relative;
        z-index: 2;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 193px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 256px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 16px;
`;

export const DetailsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
`;

export const SubjectCardName = styled.p`
    color: ${theme.colors.brand[300]};
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    line-height: 22px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    z-index: 2;
`;

export const SubjectCardTotalModules = styled.span`
    font-size: ${theme.typography.sizes.small};
    color: ${theme.colors.base[400]};
    font-weight: bold;
    font-family: "Nunito", sans-serif;
    z-index: 2;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: ${theme.spacing.smallXX};
    }
`;
