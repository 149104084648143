import { theme } from "config/theme";
import styled from "styled-components";

export const ContentHeader = styled.div`
    gap: 8px;
    margin: 40px auto 56px 0;
`;

export const Title = styled.h1`
    text-align: left;
    font-size: ${theme.typography.sizes.large};
    letter-spacing: 0em;
    color: ${theme.colors.base[500]};
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
`;

export const Subtitle = styled.p`
    text-align: left;
    color: ${theme.colors.base[400]};
    font-family: "Nunito Sans", sans-serif;
    font-size: ${theme.typography.sizes.medium};
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
`;
