import { put, select, call } from "redux-saga/effects";
import { Creators as exerciseListActions } from "store/ducks/exerciseList";
import { ReduxStore } from "store/ducks";
import queryStringLib from "query-string";
import { formatToNaturalNumber } from "utils/formatToNaturalNumber";
import { AnswerCardStatus } from "enums/AnswerCardStatus";
import html2canvas from "html2canvas";
import api from "services/api";
import { AxiosResponse } from "axios";
import { theme } from "config/theme";
import _get from "lodash/get";

export function* saveEssayExam(data: { content?: string; canvasElement?: any; file?: File }) {
    let fileData: { path: string; pathOriginal: string; content?: string };

    const { content, file } = data;

    if (!!content) {
        const fakeContainerElement = document.createElement("div");
        fakeContainerElement.id = "print-container";
        fakeContainerElement.setAttribute(
            "style",
            `
    pointer-events: none;
    all: unset;
    outline: none;
    resize: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999999; 
    overflow: auto;
    width: 780px;
    height: 896px;
    opacity: 0;
    `
        );

        const fakeElement = document.createElement("div");
        fakeElement.setAttribute(
            "style",
            `
    pointer-events: none;
    background: #fff;
    color: ${theme.colors.base[200]};
    font-family: "Droid Sans Mono", monospace;
    font-size: 15px;
    line-height: 30px;
    outline: none;
    resize: none;
    word-break: break-word;
    white-space: pre-wrap;
    width: 780px;
    height: 896px;
    padding-left: 30px;
    padding-right: 10px;
    overflow: auto;
    `
        );
        fakeElement.id = "print";
        fakeElement.innerHTML = data?.content || "";

        fakeContainerElement.appendChild(fakeElement);
        document.body.appendChild(fakeContainerElement);

        // yield delay(5000000);

        const element = _get(fakeContainerElement.children, "[0]");

        if (!element) {
            throw new Error("CANVAS ELEMENT NOT FOUND");
        }

        // GENERATE BASE64
        const canvas = yield html2canvas(element);

        const base64Image = canvas.toDataURL("image/png");

        document.body.removeChild(fakeContainerElement);

        if (!base64Image) {
            throw new Error();
        }

        const { data: createFileResponse }: AxiosResponse<{ file: string; full: string }> = yield call(
            api.post,
            "/student/essay/composition/create-image",
            { image: base64Image }
        );

        fileData = {
            path: createFileResponse.file,
            pathOriginal: createFileResponse.file
        };
    } else {
        if (!file) {
            throw new Error();
        }

        // UPLOAD IMAGE
        const formData = new FormData();
        formData.set("file", file);

        const { data: fileResponse }: AxiosResponse<{ file: string; full: string }> = yield call(api.post, "/student/essay/upload", formData, {
            headers: { "X-Uploadtype": "composition" }
        });

        fileData = {
            path: fileResponse.file,
            pathOriginal: fileResponse.file,
            content: "" // CLEAR TEXT
        };
    }

    return fileData;
}

function* checkIsFinished() {
    const isFinished = yield select(({ answerCard }: ReduxStore) => (answerCard?.answerCard.status as number) >= AnswerCardStatus.Done);

    return isFinished;
}

function* checkIsExam() {
    const isExam = yield select(({ exerciseList }: ReduxStore) => !!exerciseList?.isExam);
    return isExam;
}

function* getHasEssay() {
    const hasEssay = yield select(({ exerciseList }: ReduxStore) => !!exerciseList?.essayTheme?.idTheme);

    return hasEssay;
}

function* getTotalQuestions() {
    const questionsTotal = yield select(({ exerciseList }: ReduxStore) => exerciseList?.questionsTotal);

    const hasEssay = yield getHasEssay();

    return hasEssay ? questionsTotal + 1 : questionsTotal;
}

function* getCurrentQuestionNumber() {
    const currentQuestionNumber = yield select(({ exerciseList }: ReduxStore) => exerciseList?.currentQuestion?.number);

    return currentQuestionNumber;
}

function* checkShowEssay(newQuestionNumber?: number) {
    const isEssayVisible = yield select(({ exerciseList }: ReduxStore) => !!exerciseList?.showEssay);

    const currentQuestionNumber = yield newQuestionNumber ?? getCurrentQuestionNumber();
    const hasEssay = yield getHasEssay();
    const totalQuestions = yield getTotalQuestions();
    const isExam = yield checkIsExam();

    const showEssay = !isEssayVisible && !!isExam && !!hasEssay && currentQuestionNumber === totalQuestions;

    return showEssay;
}

function* showEssay() {
    yield put(exerciseListActions.showEssay(true));
    yield put(exerciseListActions.getExerciseListSubjectSuccess({ name: "Redação", color: "#FC9F5B" }));
    yield put(exerciseListActions.showAnswerCard(false));
    yield put(exerciseListActions.showResult(false));
}

interface IQueryString {
    questao?: string;
    redacao?: string;
}

function* exibitionMiddleware() {
    const isFinished = yield checkIsFinished();
    const queryString: IQueryString = queryStringLib.parse(window.location.search);

    if (!!queryString?.redacao) {
        return yield showEssay();
    }

    if (isFinished) {
        return yield put(exerciseListActions.showResult(true));
    }

    const questionNumber = formatToNaturalNumber(queryString?.questao);

    return yield put(exerciseListActions.goToQuestionNumber({ questionNumber: questionNumber || 1 }));
}

export const exerciseListSagaUtils = {
    getHasEssay,
    getTotalQuestions,
    getCurrentQuestionNumber,
    checkShowEssay,
    showEssay,
    exibitionMiddleware,
    checkIsExam
};
