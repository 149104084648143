import React, { useCallback, useRef, useState } from "react";

// Dependencies
import EditIcon from "prodigio-icons/web/outlined/Edit";
import DeleteIcon from "prodigio-icons/web/outlined/Delete";
import OptionsIcon from "prodigio-icons/web/outlined/Options";
import MegaphoneIcon from "prodigio-icons/web/outlined/Megaphone";

// Helpers
import { theme } from "config/theme";
import formatDate from "utils/formatDate";
import useOnClickOutside from "hooks/use-on-click-outside";

// Assets
import * as F from "./styles";

export interface IForumActions {
    onComplaint: (id: string) => void;
    onUpdate?: (id: string) => void;
    onDelete?: (id: string) => void;
}

interface IForumQuestionDetailsProps {
    date: string;
    id: string;
    actions: IForumActions;
    user?: string;
    subject?: string;
}

const ForumQuestionDetails: React.FC<IForumQuestionDetailsProps> = ({ actions, date, id, user, subject }) => {
    const ref = useRef(null);
    const [isNavOpen, setIsNavOpen] = useState(false);

    useOnClickOutside(ref, () => {
        if (isNavOpen) {
            setIsNavOpen(!isNavOpen);
        }
    });

    const handleToggleNav = useCallback(() => setIsNavOpen(!isNavOpen), [isNavOpen]);

    const handleAction = useCallback(
        (type: "onComplaint" | "onUpdate" | "onDelete") => () => {
            setIsNavOpen(false);

            const action = actions[type];

            if (!action) {
                return;
            }

            action(id);
        },
        [id, actions]
    );

    return (
        <F.ForumQuestionDetailsContainer>
            <F.ForumQuestionDetailsWrapper>
                <F.ForumQuestionDetailsHelper>
                    {user || ""} em {formatDate(date, "dd/LL/yyyy - HH:mm")} <br />
                </F.ForumQuestionDetailsHelper>
                {subject && <F.ForumQuestionDetailsHelper>Disciplina: {subject}</F.ForumQuestionDetailsHelper>}
            </F.ForumQuestionDetailsWrapper>
            <F.ForumQuestionDetailsNavWrapper ref={ref}>
                <button type="button" onClick={handleToggleNav}>
                    <OptionsIcon width="5" height="20" color="#202020" />
                </button>
                {isNavOpen && (
                    <F.ForumQuestionDetailsNav>
                        <button onClick={handleAction("onComplaint")}>
                            <div className="ico-wrapper">
                                <MegaphoneIcon width="18" height="15" color={theme.colors.base[300]} />
                            </div>
                            Denunciar
                        </button>
                        {actions.onUpdate && (
                            <button onClick={handleAction("onUpdate")}>
                                <div className="ico-wrapper">
                                    <EditIcon width="14" height="15" color={theme.colors.base[300]} />
                                </div>
                                Editar
                            </button>
                        )}
                        {actions.onDelete && (
                            <button onClick={handleAction("onDelete")}>
                                <div className="ico-wrapper">
                                    <DeleteIcon width="14" height="14" color={theme.colors.base[300]} />
                                </div>
                                Excluir
                            </button>
                        )}
                    </F.ForumQuestionDetailsNav>
                )}
            </F.ForumQuestionDetailsNavWrapper>
        </F.ForumQuestionDetailsContainer>
    );
};

export default ForumQuestionDetails;
