import React, { useMemo, useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Types as EssayTypes } from "store/ducks/essay";
import { useModal } from "hooks/use-modal";

import { Grid, Row, Col } from "components/Grid";
import PageHeader from "components/PageHeader";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";

import { IReduxStore } from "interfaces/IReduxStore";
import { NotFoundProject as ForbiddenContainer } from "screens/Projects/Details/styles";
import ForbiddenMessage from "components/ForbiddenMessage";

import MakeEssay from "../../theme/makeEssay";
import { EssayStatus } from "enums/EssayStatus";

import AppStore from "assets/img/logo-app-store.svg";
import GooglePlay from "assets/img/logo-google-play.svg";

import * as S from "./styles";

import EssayCardDetails from "../../theme/components/cardDetails";
import CardDetailsAttachment from "../../theme/components/cardDetailsAttachments";

const MyEssaysEditScreen = (props: RouteComponentProps<{ id: string }>) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile(BreakPoints.wide);
    const modal = useModal();
    const {
        themes: { theme },
        compositions: { composition },
        courseSlug
    } = useSelector(({ essay, course }: IReduxStore) => ({
        ...essay,
        courseSlug: course.slug
    }));

    const idComposition = props.match.params.id;

    useEffect(() => {
        if (idComposition) {
            dispatch({ type: EssayTypes.GET_ESSAY_COMPOSITION_REQUEST, payload: { id: idComposition, getTheme: true } });
        }
    }, [composition.idTheme, dispatch, idComposition]);

    const breadcrumb = useMemo(() => {
        return {
            current: {
                label: "Editar Redação"
            },
            parent: [
                {
                    label: "Redação",
                    url: `/app/curso/${courseSlug}/redacoes`
                },
                {
                    label: "Detalhe do redação",
                    url: `/app/curso/${courseSlug}/redacoes/envios/${idComposition}`
                }
            ]
        };
    }, [courseSlug, idComposition]);

    // Send file essay
    const handleSend = useCallback(
        (files) => {
            const data = {
                idTheme: theme.idTheme,
                title: theme.title || "",
                content: "",
                status: EssayStatus.Draft
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja <strong>salvar</strong> sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () =>
                    dispatch({ type: EssayTypes.CREATE_ESSAY_FILE_REQUEST, payload: { files, data, idComposition: composition.idComposition } })
            });
        },
        [composition.idComposition, dispatch, modal, theme.idTheme, theme.title]
    );

    // Discard essay
    const handleDiscard = useCallback(() => {
        modal.open("confirmAction", {
            title: "Atenção",
            text: (
                <>
                    Tem certeza que deseja <strong>Descartar</strong> sua redação?
                </>
            ),
            variant: "outline-warning",
            buttonText: "Confirmar",
            onConfirm: () =>
                dispatch({ type: EssayTypes.UPDATE_ESSAY_STATUS_REQUEST, payload: { id: composition.idComposition, status: EssayStatus.Discarded } })
        });
    }, [composition.idComposition, dispatch, modal]);

    // Send draft essay
    const handleDraft = useCallback(
        (content, image, status) => {
            const data = {
                idTheme: theme.idTheme,
                title: theme.title || "",
                content: content || "",
                status: status
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja salvar <strong>em rascunho</strong> sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () =>
                    dispatch({ type: EssayTypes.CREATE_ESSAY_IMAGE_REQUEST, payload: { image, data, idComposition: composition.idComposition } })
            });
        },
        [composition.idComposition, dispatch, modal, theme.idTheme, theme.title]
    );

    // Send pending essay
    const handleCorrection = useCallback(
        (content, image, status) => {
            const data = {
                idTheme: theme.idTheme,
                title: theme.title || "",
                content: content || "",
                status: status
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja usar <strong>1 crédito</strong> para enviar sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () => dispatch({ type: EssayTypes.CREATE_ESSAY_IMAGE_REQUEST, payload: { image, data } })
            });
        },
        [dispatch, modal, theme.idTheme, theme.title]
    );

    if (isMobile) {
        return (
            <ForbiddenContainer>
                <ForbiddenMessage text="Essa página só pode ser acessada através de um computador. Mas se deseja enviar sua redação através do seu smartphone, utilize o aplicativo do ProEnem." />
                <S.AppStoresContainer>
                    <a href="https://apps.apple.com/br/app/proenem/id1487334340?l=en" target="_blank" rel="noopener noreferrer">
                        <img src={AppStore} alt="Apple App Store" />
                    </a>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.prodigioeducacao.proapp.proenem&hl=pt"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={GooglePlay} alt="Google Play" />
                    </a>
                </S.AppStoresContainer>
            </ForbiddenContainer>
        );
    }

    return (
        <>
            <PageHeader.Simple breadcrumb={breadcrumb} />
            <S.MyEssaysEditContainer>
                <Grid fluid>
                    <Row>
                        <Col xs={12} sm={6} md={8}>
                            <EssayCardDetails withHelpers={isMobile} {...theme} />
                        </Col>
                        <Col xs={12} sm={6} md={4}>
                            <S.MyEssaysEditFooter>
                                <CardDetailsAttachment id={856} support={theme.pathFile} withButton={false} />
                            </S.MyEssaysEditFooter>
                        </Col>
                    </Row>
                    <Row>
                        <MakeEssay
                            handleSend={handleSend}
                            handleDiscard={handleDiscard}
                            handleDraft={handleDraft}
                            handleCorrection={handleCorrection}
                            content={composition.content}
                        />
                    </Row>
                </Grid>
            </S.MyEssaysEditContainer>
        </>
    );
};

export default MyEssaysEditScreen;
