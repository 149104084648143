import styled from "styled-components";
import { theme } from "config/theme";

export const ExerciseListAnswerCardKnowledgeAreaContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ExerciseListAnswerCardKnowledgeAreaQuestions = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};
`;
