import { createActions, createReducer } from "reduxsauce";
import ProjectStatsNavigationType from "enums/ProjectStatsNavigationType";
import { IProjectState, ProjectActionsType, IProjectActionsCreators } from "store/interfaces/IProject";

export const { Creators, Types } = createActions<Record<ProjectActionsType, ProjectActionsType>, IProjectActionsCreators>({
    getProjectsRequest: [],
    getProjectsSuccess: ["payload"],
    getProjectsFailure: ["payload"],

    getProjectDetailRequest: ["payload"],
    getProjectDetailSuccess: ["payload"],
    getProjectDetailFailure: ["payload"],

    getProjectForStudyRequest: ["payload"],
    getProjectForStudySuccess: ["payload"],
    getProjectForStudyFailure: ["payload"],

    setContentAsCompletedRequest: ["payload"],
    setContentAsCompletedSuccess: ["payload"],
    setContentAsCompletedFailure: ["payload"],

    setPlaybackRate: ["payload"],
    setNavigator: ["payload"],

    clearProjects: []
});

export const INITIAL_STATE: IProjectState = {
    isLoading: false,
    categories: [],
    currentProject: {
        id: 0,
        name: "",
        price: "",
        urlImage: "",
        resumes: [],
        tags: [],
        isPublished: true,
        video: {
            id: 0,
            code: "",
            description: "",
            videoKey: "",
            videoHash: "",
            title: "",
            provider: "",
            type: "",
            url: "",
            videoEmbed: ""
        },
        projectCategory: {
            id: 0,
            title: ""
        },
        modules: [],
        stats: {
            percent: 0
        }
    },
    navigator: {
        currentModule: -1,
        currentSection: -1,
        currentContent: -1
    },
    playerPlaybackRate: 1,
    error: {
        message: ""
    }
};

// Get All
const getProjectsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getProjectsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    categories: action.payload,
    error: INITIAL_STATE.error,
    isLoading: false
});

const getProjectsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

// Get by ID
const getProjectDetailRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });

const getProjectDetailSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    currentProject: {
        ...state.currentProject,
        ...action.payload
    },
    error: INITIAL_STATE.error,
    isLoading: false
});

const getProjectDetailFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false
});

// Get project for study
const getProjectForStudyRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: !action.payload.stopLoading ? true : false
});

const getProjectForStudySuccess = (state = INITIAL_STATE, action: any) => {
    const { project, navigator } = action.payload;

    return {
        ...state,
        currentProject: {
            ...project.project,
            student: project.student
        },
        error: INITIAL_STATE.error,
        navigator,
        isLoading: false
    };
};

const getProjectForStudyFailure = (state = INITIAL_STATE, action: any) => ({
    ...state,
    error: action.payload,
    isLoading: false
});

// Video Player
const setPlaybackRate = (state = INITIAL_STATE, action: any) => ({
    ...state,
    playerPlaybackRate: action.payload
});

const setNavigator = (state = INITIAL_STATE, action: any) => ({
    ...state,
    navigator: {
        ...state.navigator,
        ...action.payload
    }
});

// Set content as completed
const setContentAsCompletedRequest = (state = INITIAL_STATE, action: any) => ({ ...state });

const setContentAsCompletedSuccess = (state = INITIAL_STATE, action: any) => {
    const { idContent, type, isViewed, ...rest } = action.payload;
    const { currentProject } = state;

    const modules = currentProject.modules.map((module) => ({
        ...module,
        isViewed: type === ProjectStatsNavigationType.Introduction && idContent === module.id ? isViewed : module.isViewed,
        sections: module.sections.map((section) => ({
            ...section,
            contents: section.contents.map((content) => ({
                ...content,
                isViewed: type === ProjectStatsNavigationType.Content && idContent === content.id ? isViewed : content.isViewed
            }))
        }))
    }));

    return {
        ...state,
        currentProject: {
            ...state.currentProject,
            stats: rest.stats,
            modules
        },
        error: INITIAL_STATE.error
    };
};

const setContentAsCompletedFailure = (state = INITIAL_STATE, action: any) => ({ ...state });

const clearProjects = (state = INITIAL_STATE) => ({
    ...state,
    ...INITIAL_STATE
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_PROJECTS_REQUEST]: getProjectsRequest,
    [Types.GET_PROJECTS_SUCCESS]: getProjectsSuccess,
    [Types.GET_PROJECTS_FAILURE]: getProjectsFailure,

    [Types.GET_PROJECT_DETAIL_REQUEST]: getProjectDetailRequest,
    [Types.GET_PROJECT_DETAIL_SUCCESS]: getProjectDetailSuccess,
    [Types.GET_PROJECT_DETAIL_FAILURE]: getProjectDetailFailure,

    [Types.GET_PROJECT_FOR_STUDY_REQUEST]: getProjectForStudyRequest,
    [Types.GET_PROJECT_FOR_STUDY_SUCCESS]: getProjectForStudySuccess,
    [Types.GET_PROJECT_FOR_STUDY_FAILURE]: getProjectForStudyFailure,

    [Types.SET_CONTENT_AS_COMPLETED_REQUEST]: setContentAsCompletedRequest,
    [Types.SET_CONTENT_AS_COMPLETED_SUCCESS]: setContentAsCompletedSuccess,
    [Types.SET_CONTENT_AS_COMPLETED_FAILURE]: setContentAsCompletedFailure,

    [Types.SET_PLAYBACK_RATE]: setPlaybackRate,
    [Types.SET_NAVIGATOR]: setNavigator,

    [Types.CLEAR_PROJECTS]: clearProjects
});
