import styled from "styled-components";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import { FilterDropDown } from "components/Filter/styles";
import { ExerciseListAnswerCardQuestionContainer, ExerciseListAnswerCardQuestionNumber } from "components/AnswerCardQuestion/styles";
import { AnswerCardEssayStatusMessage } from "components/AnswerCardEssay/styles";

export const ExerciseListResultListQuestions = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
        margin-bottom: ${theme.spacing.medium};

        background-color: ${theme.colors.white};
        border-radius: 4px;
        border: 2px solid ${theme.colors.base[100]};
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    }

    ${ExerciseListAnswerCardQuestionContainer} {
        margin-bottom: unset;
        border-bottom: unset;

        ${ExerciseListAnswerCardQuestionNumber} {
            width: unset;
            border-right: unset;
            width: 30px;
            height: 30px;
            margin-right: ${theme.spacing.small};
        }
    }

    ${AnswerCardEssayStatusMessage} {
        padding: 0;
        display: flex;
        align-items: center;
        font-size: unset;
    }
`;

export const ExerciseListResultListQuestionsTitle = styled.p`
    color: ${theme.colors.base[500]};
    display: flex;
    align-items: center;
    font-weight: 600;

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const ExerciseListResultListQuestionsHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.spacing.medium};
    justify-content: space-between;

    ${FilterDropDown} {
        max-width: 50%;
    }
`;
