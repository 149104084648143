// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
    border: 1px solid #ced0dd;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        flex-direction: row;
        width: max-content;
    }
`;

export const PeriodWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
`;

export const SelectedPeriodWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media (min-width: 768px) {
        width: max-content;
    }
`;

export const ScheduleIconWrapper = styled.div`
    width: 40px;
    height: 40px;

    background-color: #ddd2df;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media (min-width: 768px) {
        width: 36px;
        height: 36px;
    }
`;

export const ScheduleIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const SelectedPeriodLabel = styled.p`
    font-family: "Nunito", sans-sefir;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #571d5d;

    @media (min-width: 768px) {
        font-size: 18px;
        line-height: 26px;
    }
`;

export const DayInformationsAssertiveness = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505462;
`;

export const Divider = styled.div`
    width: 100%;
    height: 0px;

    border-radius: 50%;
    border: 1px solid #ced0dd;

    @media (min-width: 768px) {
        height: 56px;
        width: 2px;
    }
`;

export const AssertivenessWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 768px) {
        width: unset;
    }
`;

export const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > .pdg-asset {
        transition: 0.2s all ease-in-out;
        cursor: pointer;
    }
`;

export const PeriodOptionsWrapper = styled.div`
    position: absolute;
    top: 94px;
    left: 0;
    z-index: 4;
    width: 194px;
`;

// ----------

export const ScheduleIconWrapperSkeleton = styled.div`
    width: 40px;
    height: 40px;

    @media (min-width: 768px) {
        width: 36px;
        height: 36px;
    }
`;

export const SelectedPeriodLabelSkeleton = styled.div`
    height: 22px;
    padding: 3px 0;

    @media (min-width: 768px) {
        height: 26px;
        padding: 4px 0;
    }
`;

export const DayInformationsAssertivenessSkeleton = styled.div`
    height: 20px;
    padding: 3px 0;
`;

export const IconActionWrapperSkeleton = styled.div`
    width: 16px;
    height: 16px;

    @media (min-width: 768px) {
        width: 20px;
        height: 20px;
    }
`;
