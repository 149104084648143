import React, { useCallback, useEffect, useRef } from "react";

import * as S from "./styles";
import RankingIcon from "prodigio-icons/web/filled/Ranking";
import Feedback from "../Feedback";
import { IExamScore } from "interfaces/IExam";

interface IProps {
    onClickScore(score: IExamScore): void;
    activeScoreId: number;
    scores: IExamScore[];
}

const ExamResultContestCard = ({ scores, onClickScore, activeScoreId }: IProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const activeCardRef = useRef<HTMLButtonElement>(null);

    const scrollToActiveCard = useCallback(() => {
        if (!activeScoreId) {
            return;
        }

        activeCardRef?.current?.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "center"
        });
    }, [activeScoreId]);

    useEffect(() => scrollToActiveCard(), [scrollToActiveCard]);

    const feedback = useCallback((aboveAverage: boolean) => {
        if (aboveAverage) {
            return <Feedback variant="success" legend="Sua nota está acima de 60% dos alunos" title="Parabéns!" />;
        }

        return <Feedback variant="warning" legend="O seu próximo simulado será melhor" title="Mantenha o foco!" />;
    }, []);

    return (
        <S.ExamResultContestCardContainer ref={containerRef}>
            <div>
                {scores?.map((score) => {
                    const isActive = score.id === activeScoreId;

                    return (
                        <S.ExamResultContestCardItem
                            onClick={() => onClickScore(score)}
                            key={score.id}
                            active={isActive}
                            {...(!!isActive && { ref: activeCardRef })}
                        >
                            <S.ExamResultContestCardTitle>{score.correctionEngine?.name}</S.ExamResultContestCardTitle>
                            <S.ExamResultContestCardLegends>
                                <S.ExamResultContestCardNote>
                                    <span>Sua nota: </span>
                                    <strong>{score?.points || 0}</strong>
                                </S.ExamResultContestCardNote>

                                <S.ExamResultContestCardRanking>
                                    <RankingIcon width={12} height={16} />
                                    <strong>{score?.rank || 0}° lugar</strong>
                                </S.ExamResultContestCardRanking>
                            </S.ExamResultContestCardLegends>

                            {feedback(!!score?.aboveAverage)}
                        </S.ExamResultContestCardItem>
                    );
                })}
            </div>
        </S.ExamResultContestCardContainer>
    );
};

export default ExamResultContestCard;
