// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

type ContainerStyleProps = {
    hasAction: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    background-color: #ffffff;

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-template-rows: auto;
    gap: 12px;
    grid-template-areas:
        "title title"
        "deadline deadline"
        "type type"
        "score chip";
    transition: 0.2s ease-in-out;

    ${({ hasAction }) =>
        !!hasAction &&
        css`
            cursor: pointer;

            &:hover {
                box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.15);
            }
        `}

    .title {
        grid-area: title;
    }

    .deadline {
        grid-area: deadline;
    }

    .type {
        grid-area: type;
    }

    .status-chip {
        grid-area: chip;
    }

    .score {
        grid-area: score;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        grid-template-columns: auto 168px 96px 150px 32px;
        grid-template-rows: 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 0;
        align-items: center;
        grid-template-areas:
            "title type deadline chip action"
            "title score deadline chip action";

        .action-icon {
            grid-area: action;
        }
    }

    ${MediaQueries.BIGGER_THAN_FULLHD} {
        padding: 24px;

        grid-template-columns: auto 96px 168px 96px 150px 32px;
        grid-template-rows: 1fr;
        grid-template-areas: "title score type deadline chip action";
    }
`;

export const HeaderContainer = styled.div`
    width: 100%;

    display: none;
    align-items: center;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: grid;
        grid-template-columns: auto 168px 96px 150px 32px;
        grid-column-gap: 16px;
        align-items: center;
        padding: 0 12px;
    }

    ${MediaQueries.BIGGER_THAN_FULLHD} {
        grid-template-columns: auto 96px 168px 96px 150px 32px;
        padding: 0 24px;
    }
`;

export const StatusChipCompositionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        justify-content: flex-start;
    }
`;

export const ActionIconElementWrapper = styled.div`
    display: none;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const LabelWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: flex-start;
`;

export const Title = styled.p`
    font-size: "Nunito", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #232326;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

type LabelStyleProps = {
    color: string;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;

    ${({ color }) =>
        color &&
        css`
            color: ${color};
        `}
`;

export const TypeLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #4d4d63;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        font-size: "Nunito", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #232326;
    }

    ${MediaQueries.BIGGER_THAN_FULLHD} {
        font-family: "Nunito Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #4d4d63;
    }
`;
