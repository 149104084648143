import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { ProjectContent, ProjectCurrentContentWrapper, ProjectPlaceholderWrapper, VideoWrapper } from "../../styles";

const ProjectStudyingContentPlaceholder = () => (
    <ProjectContent>
        <VideoWrapper>
            <Skeleton width="100%" height="100%" />
        </VideoWrapper>
        <ProjectPlaceholderWrapper>
            <ProjectCurrentContentWrapper>
                <Skeleton width="70%" height="20px" />
            </ProjectCurrentContentWrapper>
        </ProjectPlaceholderWrapper>
    </ProjectContent>
);

export default ProjectStudyingContentPlaceholder;
