import styled from "styled-components";
import { TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import { SelectContainer } from "components/Select/styles";
import { ButtonStyles } from "components/Button/styles";

export const SubjectTitle = styled.h3<{ subjectColor: string }>`
    ${TitleH3};
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;

    color: ${({ subjectColor }) => subjectColor};
`;

export const TextEmpty = styled.p`
    font-size: ${theme.spacing.small};
    color: ${theme.colors.base[200]};
    margin-bottom: ${theme.spacing.small};
`;

export const BackButton = styled.button`
    margin: ${theme.spacing.medium} ${theme.spacing.smallX} ${theme.spacing.small} ${theme.spacing.smallX};
    .ico-caret-left-2 {
        color: #202020;
    }
`;

export const ExtraCardListTitle = styled.h2`
    color: ${theme.colors.base[500]};
`;

export const ExtraCardListHeader = styled.div`
    display: flex;
    padding: ${theme.spacing.medium} 0;
    justify-content: space-between;
    align-items: center;

    ${ExtraCardListTitle} {
        flex: 1;
    }

    ${SelectContainer} {
        min-width: 120px;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            min-width: 250px;
        }
    }
`;

export const ExtraExerciseListLoadMoreButton = styled.button.attrs({
    type: "button",
    variant: "info",
    size: "small"
})`
    margin: ${theme.spacing.small} auto;

    ${ButtonStyles};
`;
