import removeTimezoneFromISOStringDate from "./removeTimezoneFromDate";

const splitDateFromHumanReadableToIsoStringFormat = (date: string) => {
    const [datePart, timePart] = date?.split(" ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes] = timePart.split(":").map(Number);
    const newDate = new Date(year, month - 1, day, hours, minutes);
    const isoDate = newDate.toISOString();

    return removeTimezoneFromISOStringDate(isoDate);
};

export default splitDateFromHumanReadableToIsoStringFormat;
