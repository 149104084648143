import React, { useCallback, useEffect } from "react";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as ModalActions } from "store/ducks/modal";

// Helpers
import { IModalActions } from "store/interfaces/IModal";

interface IPaywallContainerProps {
    modalActions: IModalActions;
}

const PaywallContainer = ({ modalActions }: IPaywallContainerProps) => {
    const showPaywall = useCallback(() => modalActions.openModal("paywall"), [modalActions]);

    useEffect(() => {
        showPaywall();
    }, [showPaywall]);

    return <div />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(null, mapDispatchToProps)(PaywallContainer);
