import React from "react";
import ForbiddenMessage from "components/ForbiddenMessage";
import { EssayAppStoresContainer, MakeEssayForbiddenContainer } from "./styles";
import AppStore from "assets/img/logo-app-store.svg";
import GooglePlay from "assets/img/logo-google-play.svg";

const MakeEssayForbidden: React.FC = () => {
    return (
        <MakeEssayForbiddenContainer>
            <ForbiddenMessage text="Essa página só pode ser acessada através de um computador. Mas se deseja enviar sua redação através do seu smartphone, utilize o aplicativo do ProEnem." />
            <EssayAppStoresContainer>
                <a href="https://apps.apple.com/br/app/proenem/id1487334340?l=en" target="_blank" rel="noopener noreferrer">
                    <img src={AppStore} alt="Apple App Store" />
                </a>
                <a
                    href="https://play.google.com/store/apps/details?id=com.prodigioeducacao.proapp.proenem&hl=pt"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={GooglePlay} alt="Google Play" />
                </a>
            </EssayAppStoresContainer>
        </MakeEssayForbiddenContainer>
    );
};

export default MakeEssayForbidden;
