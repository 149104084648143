import React, { memo, useCallback, useMemo } from "react";
import * as S from "./styles";

import history from "services/history";
import { Grid, Row, Col } from "components/Grid";

import ChevronRightIcon from "prodigio-icons/web/outlined/ChevronRight";
import ChevronLeftIcon from "prodigio-icons/web/outlined/ChevronLeft";
import InfoIcon from "prodigio-icons/web/outlined/Info";
import { ISubjectProgress } from "interfaces/ISubject";
import ProgressBar from "components/ProgressBar";
import formatPercent from "utils/formatPercent";
import Dropdown from "components/Dropdown";
import { theme } from "config/theme";
import SubjectScreenDetailsHeaderSkeletonProgress from "./skeleton";
import Skeleton from "components/Skeleton";
import { ISubjectInfo } from "store/interfaces/ISubject";
import Spinner from "components/Spinner";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    progress: ISubjectProgress;
    name: string;
    isLoading?: boolean;
    isLoadingProgress: boolean;
    onClickInfos(): void;
    infos?: ISubjectInfo;
    isLoadingInfos: boolean;
}

const SubjectScreenDetailsHeader = ({
    progress,
    name,
    isLoading = true,
    isLoadingProgress = true,
    infos,
    onClickInfos,
    isLoadingInfos = true
}: IProps) => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const handleClickBack = useCallback(() => history.push({ pathname: `/app/curso/${courseSlug}/materias` }), [courseSlug]);

    const haveProgress = useMemo(() => !!progress, [progress]);

    const progressPercentage = useMemo(() => {
        if (!haveProgress) {
            return 0;
        }

        return progress.percent ? formatPercent(progress.percent) : formatPercent((progress.completedItems * 100) / progress.totalItems);
    }, [haveProgress, progress.completedItems, progress.percent, progress.totalItems]);

    const dropdownInfos = useMemo(() => {
        return (
            <S.SubjectScreenDetailsHeaderDropdown>
                {isLoadingInfos ? (
                    <Spinner fixed={false} color={theme.colors.system.info[200]} size={20} />
                ) : (
                    <>
                        {infos?.totalModules! > 0 && (
                            <li>
                                <strong>{infos?.totalModules}</strong> Módulos
                            </li>
                        )}

                        {infos?.totalVideos! > 0 && (
                            <li>
                                <strong>{infos?.totalVideos}</strong> Vídeos Relacionados
                            </li>
                        )}

                        {infos?.totalExerciseLists! > 0 && (
                            <li>
                                <strong>{infos?.totalExerciseLists}</strong> Listas de Exercício
                            </li>
                        )}

                        {infos?.totalMentoring! > 0 && (
                            <li>
                                <strong>{infos?.totalMentoring}</strong> Monitorias
                            </li>
                        )}

                        {infos?.totalMaterials! > 0 && (
                            <li>
                                <strong>{infos?.totalMaterials}</strong> Materias Didáticos
                            </li>
                        )}
                    </>
                )}
            </S.SubjectScreenDetailsHeaderDropdown>
        );
    }, [infos, isLoadingInfos]);

    return (
        <S.SubjectScreenDetailsHeaderContainer>
            <Grid fluid>
                <Row>
                    <Col xs={12} sm={4} md={haveProgress ? 5 : 12} lg={haveProgress ? 5 : 12}>
                        <S.SubjectScreenDetailsHeaderWrapTexts>
                            <S.SubjectScreenDetailsHeaderBackButton onClick={handleClickBack}>
                                <ChevronLeftIcon className="chevron-left" width="8" height="14" />
                                {isLoading ? <Skeleton width="75px" height="27px" /> : name}
                            </S.SubjectScreenDetailsHeaderBackButton>
                            <S.SubjectScreenDetailsHeaderEntity onClick={handleClickBack}>
                                <p>Matéria</p>
                                <ChevronRightIcon className="chevron-right" width="10" height="8" />
                            </S.SubjectScreenDetailsHeaderEntity>
                            <S.SubjectScreenDetailsHeaderName>
                                {isLoading ? <Skeleton width="75px" height="27px" /> : name}
                            </S.SubjectScreenDetailsHeaderName>

                            <Dropdown
                                label={<InfoIcon width={16} height={16} color={theme.colors.brand[300]} />}
                                onClick={() => !infos && onClickInfos()}
                            >
                                {dropdownInfos}
                            </Dropdown>
                        </S.SubjectScreenDetailsHeaderWrapTexts>
                    </Col>
                    {haveProgress && (
                        <Col xs={12} sm={8} md={7} lg={7}>
                            {isLoadingProgress ? (
                                <SubjectScreenDetailsHeaderSkeletonProgress />
                            ) : (
                                <ProgressBar
                                    color={progressPercentage === 100 ? theme.colors.system.success[200] : theme.colors.system.info[200]}
                                    value={progressPercentage}
                                    responsive={true}
                                    label={
                                        <>
                                            <p>
                                                <strong data-test-id="subject-progress-legend">
                                                    {progress.completedItems}/{progress.totalItems}
                                                </strong>{" "}
                                                módulos concluídos
                                            </p>
                                            <p data-test-id="subject-progress-value">{progressPercentage}%</p>
                                        </>
                                    }
                                />
                            )}
                        </Col>
                    )}
                </Row>
            </Grid>
        </S.SubjectScreenDetailsHeaderContainer>
    );
};

export default memo(SubjectScreenDetailsHeader);
