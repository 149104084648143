import { IContentSearchAdvancedOption } from "interfaces/IContentSearchAdvancedOption";

const mappedContent = (contents: any[], link: string, urlParamsIsSlug: Boolean = false, optionalUrl?: string) => {
    const currentContents = contents.map((content) => ({
        link: `${link}/${urlParamsIsSlug ? content.slug : content.formatted.id}${optionalUrl || ""}`,
        ...content.formatted
    }));

    return currentContents;
};

const mappedContentWithDynamicRoute = (contents: any[], link: string) => {
    const currentContents = contents
        .filter((content) => !!content.subject && !!content.formatted)
        .map((content) => ({
            link: `${link}/${content.subject.slug}/${content.formatted.id}`,
            ...content.formatted
        }));

    return currentContents;
};

export const getSelectedAdvancedOptions = (advancedOptions: IContentSearchAdvancedOption[]) => {
    const selectedAdvancedOptions = advancedOptions.filter((option) => option.selected).map((option) => option.key);

    return selectedAdvancedOptions;
};

export const getContentName = (name: string) => {
    const names: { [key: string]: string } = {
        projects: "Projeto",
        subjects: "Matérias",
        exercisesList: "Lista de Exercícios",
        essayThemes: "Redação",
        extraExerciseList: "Lista de Exercícios Extra",
        previousExams: "Provas Anteriores",
        exams: "Simulado",
        module: "Módulos"
    };

    return names[name] || "";
};

export const parseContent = (key: string, contents: any[]) => {
    const contentType: any = {
        lives: mappedContent(contents, "/app/assistir/materia/ao-vivo"),
        mentorships: mappedContent(contents, "/app/assistir/materia/monitoria"),
        projects: mappedContent(contents, "/app/projetos"),
        subjects: mappedContent(contents, "/app/materias", true),
        exercisesList: mappedContent(contents, "/app/lista-de-exercicios"),
        essayThemes: mappedContent(contents, "/app/redacoes"),
        extraExerciseList: mappedContent(contents, "/app/lista-de-exercicios", false, "/app/lista-de-exercicios-extra"),
        previousExams: mappedContent(contents, "/app/provas-anteriores", true),
        exams: mappedContent(contents, "/app/simulados", true),
        module: mappedContentWithDynamicRoute(contents, "/app/materias")
    };

    return contentType[key] || [];
};
