import React, { memo, useState } from "react";

import * as S from "./styles";

import CloseIcon from "prodigio-icons/web/outlined/Close";
import InfoIcon from "prodigio-icons/web/filled/Info";

import { theme } from "config/theme";

const ExerciseListScreenEssayDialogue: React.FC = () => {
    const [isVisible, setIsVisible] = useState(true);

    if (!isVisible) {
        return null;
    }

    return (
        <S.ExerciseListScreenEssayDialogueContainer>
            <S.ExerciseListScreenEssayDialogueCard>
                <S.ExerciseListScreenEssayDialogueCloseButton onClick={() => setIsVisible(false)}>
                    <CloseIcon width={15} height={15} color={theme.colors.base[400]} />
                </S.ExerciseListScreenEssayDialogueCloseButton>
                <InfoIcon width={45} height={45} />
                <p>
                    O simulado possui redação!{" "}
                    <span role="img" aria-label="emoji">
                        😉
                    </span>
                </p>
            </S.ExerciseListScreenEssayDialogueCard>
        </S.ExerciseListScreenEssayDialogueContainer>
    );
};

export default memo(ExerciseListScreenEssayDialogue);
