import { theme } from "config/theme";
import React from "react";

interface IProps {
    width: number;
    height: number;
}

const Arrow = ({ width, height }: IProps) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 13L1 7l6-6" stroke={theme.colors.brand[300]} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2 7h19" stroke={theme.colors.brand[300]} strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default Arrow;
