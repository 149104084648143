// Dependecies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    min-width: 229px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    box-sizing: border-box;

    .label-and-material {
        width: 100%;
        box-sizing: border-box;
    }

    .div-label {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        p {
            font-size: 16px;
        }
    }

    .content-label {
        max-width: 270px;
        color: ${theme.colors.base[400]};
    }

    .svg {
        color: ${theme.colors.base[500]};
    }

    .div-material {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: ${theme.colors.base[300]};
        font-size: 14px;
        margin-bottom: 20px;
    }

    .progress {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin: 0;

        > .webp-progress-bar-label {
            width: 100%;
        }
    }

    @media only screen and (min-width: 1365px) {
        min-width: 464px;
        .content-label {
            max-width: 352px;
        }
    }

    @media only screen and (min-width: 500px) and (max-width: 767px) {
        .content-label {
            max-width: 201px;
        }

        .div-material {
            margin-bottom: 38px;
        }
    }
`;
