import { hexToRgba } from "helpers";
import { theme } from "config/theme";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { fadeInUp } from "assets/styles/animations";
import { MediaQueries } from "assets/styles/settings";
import { BodyText2, Button3, Micro } from "assets/styles/global";
import { EssayCardDetailsContainer } from "screens/Essay/theme/components/styles";

export const EssayCardContainer = styled(Link)<{ visible?: any }>`
    border-bottom: 1px solid ${hexToRgba(theme.colors.base[200], 0.2)};
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${theme.spacing.small};
    position: relative;
    text-align: left;
    transition: all 0.2s ease;
    width: 100%;

    .ico-difficulty {
        height: 12px;
        width: 14px;
    }

    &:before {
        background: ${theme.colors.secondary[200]};
        border-radius: 5px;
        content: "";
        display: ${({ visible }) => (visible ? "none" : "block")};
        height: 24px;
        margin-right: 8px;
        width: 4px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: relative;

        .ico-difficulty {
            height: 16px;
            width: 18px;
        }

        &:before {
            margin-right: 12px;
        }
    }
`;

export const EssayCardWrapper = styled.div`
    flex: 1;
`;

export const EssayCardHeader = styled.header`
    align-items: baseline;
    display: flex;
    margin: 0 0 ${theme.spacing.small};

    svg {
        color: ${theme.colors.base[500]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        svg {
            height: 12px;
            width: 6px;
        }
    }
`;

export const EssayCardTitle = styled.p`
    ${Button3};
    color: ${theme.colors.base[400]};
    flex: 1;
    margin-right: ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
        line-height: 22px;
        margin-right: 12px;
    }
`;

export const EssayCardFooter = styled.footer`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: flex;
    }
`;

export const EssayCardFooterText = styled.span`
    ${Micro};
    color: ${theme.colors.base[500]};

    &:not(:last-child) {
        &:after {
            content: "•";
            color: ${theme.colors.base[200]};
            font-size: 8px;
            margin: 0 ${theme.spacing.smallX};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};
        align-items: end;
        display: flex;

        > span {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const CardModalContainer = styled.div`
    background: rgba(37, 39, 45, 0.5);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999991;

    ${EssayCardDetailsContainer} {
        box-shadow: unset;
        filter: unset;
        padding-top: 0;
    }
`;

export const CardModalWrapper = styled.div`
    animation: ${fadeInUp} 0.25s linear;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.white};
    height: 100%;
    width: 100%;
`;

export const CardModalHeader = styled.header`
    button {
        padding: ${theme.spacing.medium};

        svg {
            display: block;
        }
    }
`;

export const CardModalContent = styled.div`
    flex: 1;
    overflow-y: auto;
`;
