import React from "react";

import EssaySliderButton from "../Button";

import ChevronLeft from "prodigio-icons/web/outlined/ChevronLeft";
import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";

interface IArrowProps {
    onClick: () => void;
    direction?: string;
}

const EssaySliderArrow = ({ direction = "left", onClick }: IArrowProps) => {
    return <EssaySliderButton onClick={onClick}>{direction === "left" ? <ChevronLeft /> : <ChevronRight />}</EssaySliderButton>;
};

export default EssaySliderArrow;
