import { TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled, { css } from "styled-components";
import { VideoWrapper } from "../../styles";
import { Link } from "react-router-dom";

export const NotFoundContent = styled(VideoWrapper)<{ bg: string }>`
    align-items: center;
    background: ${theme.colors.black} url(${({ bg }) => bg}) center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    > * {
        position: relative;
        z-index: 2;
    }

    p {
        color: ${theme.colors.white};
        font-style: italic;
        margin-bottom: ${theme.spacing.smallX};
    }

    &:before {
        background: rgba(0, 0, 0, 0.8);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
    }
`;

export const Attachment = styled.a`
    align-items: center;
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.brand[300]};
    border-radius: ${theme.spacing.smallXX};
    color: ${theme.colors.brand[300]};
    display: flex;
    font-size: ${theme.typography.sizes.small};
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    padding: 5px 8px;
    transition: all 0.2s ease;

    &:hover {
        background: ${theme.colors.brand[100]};
        border: 1px solid ${theme.colors.brand[500]};
        color: ${theme.colors.brand[500]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.smallX} ${theme.spacing.small};
    }
`;

export const ExerciseListCTA = styled(NotFoundContent)`
    strong {
        padding: 0 ${theme.spacing.small};
        text-align: center;
    }
`;

export const ContentTitle = styled.strong`
    ${TitleH3};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing.smallX};
`;

const ButtonStyles = css`
    align-items: center;
    background: ${theme.colors.system.info[400]};
    border: 0;
    border-radius: 4px;
    color: ${theme.colors.white};
    display: flex;
    font-size: 16px;
    padding: 8px 16px;
    transition: all 0.2s ease 0s;
    font-weight: 600;
    line-height: 1;
    justify-content: center;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: calc(24px / 2) 24px;
    }

    &:hover {
        background: ${theme.colors.system.info[400]};
    }
`;

export const ButtonAsLink = styled.a`
    ${ButtonStyles};
`;

export const ButtonAsRouterLink = styled(Link)`
    ${ButtonStyles};
`;
