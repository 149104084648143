import React, { useState, memo, useCallback } from "react";

// Dependencies
import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";
import AnimateHeight from "react-animate-height";

// Assets
import {
    AccordionButton,
    AccordionButtonWrapper,
    AccordionChevron,
    AccordionContainer,
    AccordionContent,
    AccordionIconWrapper,
    AccordionItem,
    AccordionLabel
} from "./styles";

export type AccordionOption = {
    label: {
        icon?: React.ReactNode;
        text: any;
        id?: string;
    };
    hasHtmlContent?: boolean;
    content: React.ReactNode;
};

export interface IAccordionProps {
    options: AccordionOption[];
    activeItem?: number | null;
}

const Accordion = ({ options, activeItem = null }: IAccordionProps) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(activeItem);

    const handleClick = useCallback((index: number) => setActiveIndex(activeIndex === index ? null : index), [activeIndex]);

    return (
        <AccordionContainer data-test-id="accordion">
            {options?.map((option, index) => {
                const isActive = activeIndex === index;

                return (
                    <AccordionItem id={option.label.id} key={index}>
                        <AccordionButton onClick={() => handleClick(index)}>
                            <AccordionButtonWrapper>
                                {option.label.icon && <AccordionIconWrapper>{option.label.icon}</AccordionIconWrapper>}
                                <AccordionLabel dangerouslySetInnerHTML={{ __html: option.label.text }} />
                            </AccordionButtonWrapper>
                            {!!option.content && (
                                <AccordionChevron active={activeIndex === index}>
                                    <ChevronDownIcon />
                                </AccordionChevron>
                            )}
                        </AccordionButton>
                        <AnimateHeight duration={200} height={isActive ? "auto" : 0}>
                            {option.hasHtmlContent ? (
                                <AccordionContent className="ck-content" dangerouslySetInnerHTML={{ __html: option.content as string }} />
                            ) : (
                                <AccordionContent>{option.content}</AccordionContent>
                            )}
                        </AnimateHeight>
                    </AccordionItem>
                );
            })}
        </AccordionContainer>
    );
};

export default memo(Accordion);
