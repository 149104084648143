import { theme } from "config/theme";
import styled from "styled-components";

export const PreviousExamCardContainer = styled.div`
    :not(:last-child) {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const PreviousExamCardTitle = styled.h2`
    font-weight: 400;
    font-size: ${theme.typography.sizes.medium};
    line-height: 22px;
    margin-bottom: ${theme.spacing.small};
    color: ${theme.colors.base[400]};
`;

export const PreviousExamCardListWrap = styled.div`
    display: flex;
    flex-direction: column;

    :not(:last-child) {
        margin-bottom: ${theme.spacing.small};
    }
`;
