// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    ContainerSkeleton,
    PositionWrapperSkeleton,
    CommentWrapper,
    Arrow,
    CommentContainer,
    CommentSkillHeader,
    CommentSkillWrapper,
    CommentSkillIndicatorSkeleton,
    CommentSkillSkeleton,
    CommentTitleSkeleton,
    AssetWrapperSkeleton
} from "./performance-comment.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceCommentSkeleton: FunctionComponent = () => {
    return (
        <ContainerSkeleton>
            <PositionWrapperSkeleton>
                <Skeleton width="100%" height="100%" borderRadius="8px" />
            </PositionWrapperSkeleton>

            <CommentWrapper>
                <Arrow />

                <CommentContainer>
                    <CommentSkillHeader>
                        <CommentSkillWrapper>
                            <CommentSkillIndicatorSkeleton>
                                <Skeleton width="100%" height="100%" borderRadius="5px" />
                            </CommentSkillIndicatorSkeleton>

                            <Spacing direction="horizontal" size={8} />

                            <CommentSkillSkeleton>
                                <Skeleton width="140px" height="100%" borderRadius="4px" />
                            </CommentSkillSkeleton>
                        </CommentSkillWrapper>

                        <AssetWrapperSkeleton>
                            <Skeleton width="100%" height="100%" borderRadius="4px" />
                        </AssetWrapperSkeleton>
                    </CommentSkillHeader>

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={12} />} />

                    <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                    <CommentTitleSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </CommentTitleSkeleton>
                </CommentContainer>
            </CommentWrapper>
        </ContainerSkeleton>
    );
};
