import { theme } from "config/theme";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { DatePickerContainer } from "./DatePicker/styles";
import { fadeInDown, fadePulse } from "assets/styles/animations";
import { BodyText1, BodyText2, Button3, Micro, TitleH2, TitleH3, TitleH4 } from "assets/styles/global";
import { Grid, Row, Col } from "components/Grid";

export const Title = styled.h1`
    ${TitleH3};
    margin-bottom: 24px;
    color: ${theme.colors.base[500]};
`;

export const WeekPickerContainer = styled.div`
    outline: none;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding-bottom: ${theme.spacing.smallX};
    }
`;

export const WeekPickerDetails = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 13px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-right: 0;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-bottom: 15px;
    }
`;

export const WeekPickerNumber = styled.strong`
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    line-height: 22px;
    margin-right: 7px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${TitleH4};
    }
`;

export const WeekPickerMonth = styled.span`
    ${BodyText1};
    color: ${theme.colors.base[300]};
    text-transform: capitalize;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
        font-weight: 600;
        line-height: 22px;
    }
`;

const FlexVerticalCenter = css`
    align-items: center;
    display: flex;
`;

export const WeekPickerNav = styled.nav`
    ${FlexVerticalCenter};
`;

export const WeekPickerNavButton = styled.button`
    svg {
        color: ${theme.colors.base[300]};
        display: block;

        :hover {
            color: ${theme.colors.brand[300]};
        }
    }

    &:not(:last-child) {
        margin-right: ${theme.spacing.large};
    }

    &:disabled {
        cursor: no-drop;

        svg {
            :hover {
                color: ${theme.colors.base[300]};
            }
        }
    }
`;

export const WeekPickerDays = styled.nav`
    display: flex !important;
    overflow-x: auto;
    padding: 12px 0 4px 0;
    justify-content: space-between;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 11px 0 2px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        align-items: center;
        background: ${theme.colors.white};
        border-radius: ${theme.spacing.smallXX};
        justify-content: center;
        overflow-x: unset;
        padding: 0;
    }
`;

export const LessonPlanContainer = styled.section`
    height: 100%;
    overflow-y: auto;
    position: relative;
    padding-top: 142px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-top: 215px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding-top: 0;
    }
`;

export const LessonPlanModulesTitle = styled.h1`
    ${TitleH3};

    position: relative;
    text-align: center;
    padding: 28px 0 16px;
    color: ${theme.colors.base[400]};

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    &:before {
        background: ${theme.colors.base[100]};
        border-radius: 10px;
        bottom: 0;
        height: 2px;
        left: 0;
        width: 100%;
    }

    &:after {
        border-left: 3.5px solid transparent;
        border-right: 3.5px solid transparent;
        border-top: 6px solid ${theme.colors.base[100]};
        bottom: -6px;
        height: 0;
        margin-left: -3.5px;
        left: 50%;
        width: 0;
    }
`;

export const LearningObjectListTitle = styled.h1`
    font-size: 1.2rem;
    margin: 10px 0;
`;

export const LessonPlanModulesList = styled.div`
    max-height: 100%;
`;

export const LessonPlanModulesListItem = styled.div`
    position: relative;

    &:not(:last-child) {
        padding-bottom: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding-bottom: 30px;
    }
`;

export const ModulePickerWrapper = styled.div`
    margin: auto 0;
    width: calc(100% - 100px);
`;

export const DesktopModulePicker = styled.div`
    background: ${theme.colors.base[100]};
    left: 0;
    padding-top: 12px;
    position: sticky;
    top: 0;
    z-index: 10;

    &:before,
    &:after {
        background: ${theme.colors.base[100]};
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 2px;
    }

    &:before {
        left: -2px;
    }

    &:after {
        right: -2px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 0;
    }
`;

export const DesktopDatePicker = styled.div`
    position: sticky;
    top: 13px;

    ${DatePickerContainer} {
        background: ${theme.colors.white};
        border-radius: 4px;
        padding: 8px ${theme.spacing.medium} 17px;
        width: 100%;

        > div {
            width: 100%;
        }
    }
`;

export const NotFoundItemsWrapper = styled.div`
    align-items: center;
    display: flex;
    height: 95px;
    justify-content: flex-start;
    width: 100%;
`;

export const NotFoundItems = styled.span`
    ${Micro};
    color: ${theme.colors.base[300]};
`;

export const Loader = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DayCardNumber = styled.strong`
    ${TitleH4};
    line-height: 1;
    margin-bottom: 1px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${TitleH2};
        line-height: 1;
    }
`;

export const DayCardWeekDay = styled.span`
    ${BodyText2};
    text-transform: uppercase;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${Button3};
        display: block;
        line-height: 1;
        margin-bottom: 2px;
        text-transform: capitalize;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${BodyText2};
        margin: 0;
    }
`;

export const DayCardContainer = styled(NavLink)`
    ${FlexVerticalCenter};
    border-radius: ${theme.spacing.smallX};
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    height: 72px;
    min-width: 56px;
    margin-right: ${theme.spacing.smallX};
    max-width: 56px;

    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};

    ${DayCardNumber},
    ${DayCardWeekDay} {
        color: ${theme.colors.base[300]};
    }

    &.active {
        background: ${theme.colors.brand[300]};

        ${DayCardNumber},
        ${DayCardWeekDay} {
            color: ${theme.colors.white};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: 80px;
        margin-right: 0;
        max-width: 72px;
        min-width: 72px;

        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        box-shadow: none;
        flex: 0;

        height: 51px;
        max-width: 40px;
        min-width: 40px;

        &.active {
            height: 80px;
            margin-bottom: -${theme.spacing.smallX};
            margin-top: -${theme.spacing.smallX};
            max-width: 72px;
            min-width: 72px;
        }

        &:not(:last-child) {
            margin-right: 36px;
        }
    }
`;

export const ExpandCalendarButton = styled.button`
    display: block;
    padding: 9px;
    width: 100%;

    &:before {
        content: "";
        background: ${theme.colors.base[200]};
        border-radius: 5px;
        display: block;
        height: 4px;
        margin: 0 auto;
        width: 46px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};

        &:before {
            width: 64px;
        }
    }
`;

export const LessonPlanMobileHeader = styled.header<{ isExpanded: boolean }>`
    background: ${theme.colors.white};
    border-radius: 0 0 ${theme.spacing.small} ${theme.spacing.small};
    box-shadow: ${theme.shadow[3]};
    left: 0;
    overflow: hidden;
    padding: 11px 0 0 13px;
    position: fixed;
    top: 50px;
    transition: height 0.3s ease;
    width: 100%;
    z-index: 3;

    ${DatePickerContainer},
    ${WeekPickerContainer} {
        animation: ${fadeInDown} 0.3s linear;
    }

    ${({ isExpanded }) => {
        if (!isExpanded) {
            return css`
                height: 142px;
            `;
        }

        return css`
            height: 375px;
            z-index: 99;
        `;
    }}

    ${MediaQueries.BIGGER_THAN_SMALL} {
        height: ${({ isExpanded }) => (isExpanded ? 415 : 191)}px;
        padding: 27px 0 0 0;
        top: 60px;

        > div {
            margin: 0 auto;
            max-width: 600px;
            padding-bottom: ${theme.spacing.smallX};

            > div {
                width: 100%;
            }
        }
    }
`;

export const LessonPlanModules = styled.div`
    @media (min-width: 1024px) {
        padding: 32px 16px 24px;
    }
`;

export const LessonPlanModulesContent = styled.div`
    width: 100%;
`;

export const LessonPlanModuleEventsWrapper = styled.div`
    &:not(:last-child) {
        margin-bottom: ${theme.spacing.small};
    }
`;

export const LiveBadge = styled.span`
    border: 1px solid ${theme.colors.brand[300]};
    border-radius: 2px;
    color: ${theme.colors.brand[300]};
    font-weight: bold;
    font-size: 10px;
    line-height: 1;
    padding: 2px ${theme.spacing.smallXX} 2px;
    text-transform: uppercase;
`;

export const CurrentTime = styled.div`
    animation: ${fadePulse} 3s linear infinite;
    background: ${theme.colors.system.success[200]};
    height: 2px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100% - 32px);
    z-index: 0;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: calc(960px - 32px);
    }

    ${MediaQueries.BIGGER_THAN_HUGE} {
        width: calc(1152px - 32px);
    }

    &:before {
        background: ${theme.colors.system.success[200]};
        border-radius: 50%;
        content: "";
        height: ${theme.spacing.smallX};
        left: 0;
        position: absolute;
        top: -3px;
        width: ${theme.spacing.smallX};
        z-index: 2;
    }
`;

export const SpinnerWrapper = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
`;

export const SelectPlanGrid = styled(Grid)`
    display: flex;
    /* height: 100%; */
    /* align-items: center;
    justify-content: center; */
    background: red;
`;

export const SelectPlanRow = styled(Row)`
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* align-content: center; */
    /* width: 100%; */

    background: green;
`;

export const SelectPlanCol = styled(Col)`
    display: flex;
    flex-direction: column;
    justify-content: center;

    background: blue;
`;
