import styled from "styled-components";
import { BreakPoints } from "../../../../../assets/styles/settings";

export const SummaryContainer = styled.div`
    display: block;
    @media screen and (min-width: ${BreakPoints.tablet}px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.5rem;
    }

    @media screen and (min-width: ${BreakPoints.wide}px) {
        display: block;
        padding: 0;
    }
`;

export const SummaryContainerRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & + & {
        margin-top: 0.5rem;
    }
    @media screen and (min-width: ${BreakPoints.tablet}px) {
        .webp-text {
            margin-right: 1.5rem;
        }
    }
    @media screen and (min-width: ${BreakPoints.wide}px) {
        .webp-text {
            margin-right: 0;
        }
    }
`;
