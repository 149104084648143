import { combineReducers } from "redux";

import auth from "./auth";
import exam from "./exam";
import files from "./file";
import alert from "./alert";
import essay from "./essay";
import modal from "./modal";
import video from "./video";
import watch from "./watch";
import forum from "./forum";
import course from "./course";
import update from "./update";
import project from "./project";
import subject from "./subject";
import answerCard from "./answerCard";
import mentorship from "./mentorship";
import resolution from "./resolution";
import lessonPlan from "./lesson-plan";
import lessonPlanCustom from "./lessonPlanCustom";
import credentials from "./credentials";
import studentArea from "./student-area";
import announcement from "./announcement";
import previousExam from "./previousExam";
import exerciseList from "./exerciseList";
import contentSearch from "./contentSearch";
import bookletModule from "./booklet-module";
import learningObject from "./learning-object";
import weekPlans from "./weekPlans";
import exerciseListCustom from "./exerciseListCustom";
import qrCode from "./qrCode";
import notification from "./notification";
import doubtPost from "./doubtPost";
import lessonFreeCTA from "./lessonFreeCTA";
import activity from "./activity";

const rootReducer = combineReducers({
    alert,
    answerCard,
    auth,
    bookletModule,
    contentSearch,
    course,
    credentials,
    exerciseList,
    files,
    forum,
    learningObject,
    lessonPlan,
    lessonPlanCustom,
    mentorship,
    modal,
    project,
    resolution,
    subject,
    video,
    previousExam,
    watch,
    essay,
    announcement,
    studentArea,
    update,
    exam,
    weekPlans,
    exerciseListCustom,
    qrCode,
    notification,
    doubtPost,
    lessonFreeCTA,
    activity
});

export type ReduxStore = ReturnType<typeof rootReducer>;

export default rootReducer;
