import { IGetTopicStatus } from "store/interfaces/IDoubtPost";

export function hasAnswerDoubts(courseSlug: string, brandSlug: string, topicStatus: IGetTopicStatus) {
    const proenemCoursesWithAnswerDoubts =
        [
            "enempro-360",
            "desvendando-o-mil",
            "especifico-biologia-med",
            "especifico-fisica-med",
            "especifico-matematica-med",
            "especifico-quimica-med",
            "turma-intensiva-2023",
            "turma-intensiva-23-v2",
            "turma-intensiva-23-v3",
            "turma-intensiva-23-v4",
            "revisao-proenem",
            "enem-pro-360",
            "med-pro-360",
            "medpro-360",
            "medpro-360-24",
            "nivelamento-pro",
            "turma-extensiva-2024",
            "extensiva-med-2024",
            "extensiva-2024",
            "turma-de-marco-2024",
            "turma-abril-2024-med",
            "turma-abril-2024-enem",
            "turma-abril-4-2024-enem",
            "turma-abril-3-2024-enem",
            "turma-abril-2-2024-enem",
            "enem-maio-24-turma-3",
            "enem-maio-24-turma-2",
            "sem-extensiva-med-2024",
            "enem-maio-24",
            "turma-intensiva-2024.4",
            "turma-intensiva-2024.3",
            "turma-intensiva-2024.2",
            "turma-intensiva-2024",
            "enem-junho-24.3",
            "enem-junho-24.2",
            "enem-junho-24.1"
        ].includes(courseSlug) && brandSlug === "proenem";
    const promedicinaCoursesWithAnswerDoubts =
        [
            "promedicina-23-marco",
            "medpro-360",
            "medpro-360-24",
            "desvendando-o-mil",
            "especifico-biologia-med",
            "especifico-fisica-med",
            "especifico-matematica-med",
            "especifico-quimica-med",
            "turma-intensiva-2023",
            "turma-intensiva-23-v2",
            "turma-intensiva-23-v3",
            "turma-intensiva-23-v4",
            "revisao-proenem",
            "enem-pro-360",
            "med-pro-360",
            "nivelamento-pro",
            "turma-extensiva-2024",
            "extensiva-med-2024",
            "extensiva-2024",
            "extensiva-med-24-marco",
            "turma-abril-2024-med",
            "turma-abril-2024-enem",
            "turma-abril-4-2024-enem",
            "turma-abril-3-2024-enem",
            "turma-abril-2-2024-enem",
            "enem-maio-24-turma-3",
            "enem-maio-24-turma-2",
            "sem-extensiva-med-2024",
            "enem-maio-24",
            "turma-intensiva-2024.4",
            "turma-intensiva-2024.3",
            "turma-intensiva-2024.2",
            "turma-intensiva-2024",
            "enem-junho-24.3",
            "enem-junho-24.2",
            "enem-junho-24.1"
        ].includes(courseSlug) && brandSlug === "promedicina";

    const isAnswerDoubtsVisible = [IGetTopicStatus.Active, IGetTopicStatus.OnlyReading].includes(topicStatus);

    return (proenemCoursesWithAnswerDoubts || promedicinaCoursesWithAnswerDoubts) && isAnswerDoubtsVisible;
}
