// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, BadgeIcon, BadgeLabel } from "./activity-score-badge.styles";

// Assets
import badge from "assets/img/badge.svg";

// Types
import { ActivityScoreBadgeProps } from "./activity-score-badge.types";

export const ActivityScoreBadge: FunctionComponent<ActivityScoreBadgeProps> = ({ pointsLabel }) => {
    return (
        <Container>
            <BadgeIcon src={badge} />
            <BadgeLabel>{pointsLabel}</BadgeLabel>
        </Container>
    );
};
