// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    ResumeEssayCardWrapper,
    ResumeEssayCardHeader,
    IconWrapperSkeleton,
    ResumeEssayCardContentSkeleton,
    ResumeEssayCardTitleSkeleton,
    ResumeEssayCardDeliveredSkeleton,
    ResumeEssaySeeDetailsElementWrapper
} from "./performance-essay-resume-card.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceEssayResumeCardSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <ResumeEssayCardWrapper>
                <ResumeEssayCardHeader>
                    <IconWrapperSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </IconWrapperSkeleton>

                    <Spacing direction="horizontal" size={16} />

                    <div>
                        <ResumeEssayCardTitleSkeleton>
                            <Skeleton width="200px" height="100%" borderRadius="4px" />
                        </ResumeEssayCardTitleSkeleton>

                        <Spacing direction="vertical" size={2} />

                        <ResumeEssayCardDeliveredSkeleton>
                            <Skeleton width="88px" height="100%" borderRadius="4px" />
                        </ResumeEssayCardDeliveredSkeleton>
                    </div>
                </ResumeEssayCardHeader>

                <Spacing direction="vertical" size={16} />

                <div>
                    <ResumeEssayCardContentSkeleton>
                        <Skeleton width="140px" height="100%" borderRadius="4px" />
                    </ResumeEssayCardContentSkeleton>

                    <ResumeEssayCardContentSkeleton>
                        <Skeleton width="180px" height="100%" borderRadius="4px" />
                    </ResumeEssayCardContentSkeleton>
                </div>

                <Spacing direction="vertical" size={16} />

                <ResumeEssaySeeDetailsElementWrapper>
                    <Skeleton width="100%" height="34px" borderRadius="4px" />
                </ResumeEssaySeeDetailsElementWrapper>
            </ResumeEssayCardWrapper>
        </Container>
    );
};
