// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

type WebpNavItemProps = {
    id?: string;
    label?: string;
    active: boolean;
    navType: "square" | "step";
    onNavItemClicked?: () => void;
    contentNavItemComposition?: ReactNode;
};

export const WebpNavItem: FunctionComponent<WebpNavItemProps> = ({ active, label, navType, contentNavItemComposition, onNavItemClicked, id }) => {
    const handleClick = () => {
        if (!!onNavItemClicked) {
            onNavItemClicked();
        }
    };

    return (
        <Container id={id} className="webp-nav-item" isActive={active} navType={navType} onClick={handleClick}>
            {label && <span>{label}</span>}

            {contentNavItemComposition}
        </Container>
    );
};
