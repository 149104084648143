// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { ActivityExerciseLayoutProps } from "./activity-exercise.types";

// Styles
import {
    Container,
    ContentWrapper,
    FooterWrapper,
    HeaderAndContentWrapper,
    HeaderWrapper,
    HeaderContent,
    FooterContent,
    LeftWrapper,
    RightWrapper
} from "./activity-exercise.styles";

// Components
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityExerciseLayout: FunctionComponent<ActivityExerciseLayoutProps> = ({
    activityType,
    shouldDisplayActivityCommentSection,
    breadcrumbsComposition,
    statementComposition,
    scoreComposition,
    progressComposition,
    answersComposition,
    statisticsComposition,
    activityCommentComposition,
    footerButtonsCompositions,
    teacherCommentComposition
}) => {
    return (
        <Container>
            <HeaderAndContentWrapper>
                <HeaderWrapper>
                    <HeaderContent>
                        {breadcrumbsComposition}

                        <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={progressComposition} />
                    </HeaderContent>
                </HeaderWrapper>

                <ContentWrapper activityType={activityType} hasScore={!!scoreComposition}>
                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={progressComposition} />

                    <LeftWrapper>
                        <ConditionallyRender shouldRender={!!statementComposition} content={statementComposition} />

                        <ConditionallyRender shouldRender={!!answersComposition} content={answersComposition} />

                        <ConditionallyRender
                            shouldRender={!!activityCommentComposition && !!shouldDisplayActivityCommentSection && activityType !== "exercise-list"}
                            content={activityCommentComposition}
                        />

                        <ConditionallyRender shouldRender={!!teacherCommentComposition} content={teacherCommentComposition} />
                    </LeftWrapper>

                    <ConditionallyRender
                        shouldRender={activityType === "exercise-list" || !!scoreComposition}
                        content={
                            <RightWrapper>
                                <ConditionallyRender shouldRender={!!scoreComposition} content={scoreComposition} />

                                <ConditionallyRender shouldRender={!!statisticsComposition} content={statisticsComposition} />

                                <ConditionallyRender
                                    shouldRender={
                                        !!activityCommentComposition && !!shouldDisplayActivityCommentSection && activityType === "exercise-list"
                                    }
                                    content={activityCommentComposition}
                                />
                            </RightWrapper>
                        }
                    />
                </ContentWrapper>
            </HeaderAndContentWrapper>

            <ConditionallyRender
                shouldRender={!!footerButtonsCompositions}
                content={
                    <FooterWrapper>
                        <FooterContent>{footerButtonsCompositions}</FooterContent>
                    </FooterWrapper>
                }
            />
        </Container>
    );
};
