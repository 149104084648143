import React from "react";

// Redux
import { useDispatch } from "react-redux";

// Assets
import { SelectModalListContainer, SelectModalListWrap } from "assets/styles/global";

interface IProps {
    data: {
        type: string;
        LO: any[];
    };
}

const SelectMaterial = ({ data }: IProps) => {
    const dispatch = useDispatch();

    return (
        <SelectModalListContainer>
            <h3>Escolha um material complementar</h3>
            <SelectModalListWrap>
                {data.LO.map((item) => {
                    if (!item.learningObject.material) {
                        return null;
                    }

                    return (
                        <a
                            href={item.learningObject.material.url}
                            key={item.id}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
                        >
                            {item.learningObject.material.title}
                        </a>
                    );
                })}
            </SelectModalListWrap>
        </SelectModalListContainer>
    );
};

export default SelectMaterial;
