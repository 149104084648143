// Utils
import { ternary } from "utils/ternary";
import { getInterval } from "utils/getInterval";

// Types
import { ActivitySubmissionAnswerCardQuestion, ActivitySubmission } from "store/interfaces/activity";

export const getQuestionResultData = (question: ActivitySubmissionAnswerCardQuestion, isTeacher: boolean, isFinished: boolean) => {
    const questionResultLabel = ternary([
        [!question.answerOrder, "Você não respondeu."],
        [!isTeacher && !isFinished, `Você respondeu ${question.answerOrder}.`],
        [!!question.answerOrder && question.answerOrder === question.correctionOrder, "Você acertou!"],
        [
            !!question.answerOrder && question.answerOrder !== question.correctionOrder,
            `Você respondeu ${question.answerOrder}, a correta é ${question.correctionOrder}.`
        ],
        [true, "Você não respondeu."]
    ]);

    const questionResultVariant = ternary([
        [!question.answerOrder, "default"],
        [!isTeacher && !isFinished, "default"],
        [!!question.answerOrder && question.answerOrder === question.correctionOrder, "success"],
        [!!question.answerOrder && question.answerOrder !== question.correctionOrder, "danger"],
        [true, "default"]
    ]);

    return { questionResultLabel, questionResultVariant };
};

export const getExerciseListAnswerCardData = (submission?: ActivitySubmission) => {
    if (!submission?.answerCard) {
        return {
            wrongAnswers: undefined,
            correctAnswers: undefined,
            executionTime: undefined
        };
    }

    const questionsQuantity = (submission?.answerCard?.questions?.length ?? 0) as number;
    const correctAnswers = (submission?.answerCard?.questions?.reduce((accumulator: number, item: any) => {
        if (!!item.hit) {
            return accumulator + 1;
        }

        return accumulator;
    }, 0) ?? 0) as number;

    return {
        wrongAnswers: questionsQuantity - correctAnswers,
        correctAnswers,
        executionTime: getInterval({ endDate: submission?.answerCard?.endDate, startDate: submission?.answerCard?.startDate }) ?? ""
    };
};
