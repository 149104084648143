import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { ProjectSectionContainer } from "./components/ModulesList/components/Section/styles";
import { ProjectModulesListContainer, ProjectModulesWrapper, ProjectSectionItem, ProjectSectionTitle } from "./components/ModulesList/styles";
import {
    AccordionContainer,
    AccordionItem,
    AccordionButton,
    AccordionButtonWrapper,
    AccordionLabel,
    AccordionContent,
    AccordionChevron
} from "components/Accordion/styles";

const ModulesListPlaceholder = () => (
    <ProjectModulesListContainer>
        <AccordionContainer>
            <AccordionItem>
                <AccordionButton isActive={false}>
                    <AccordionButtonWrapper>
                        <AccordionLabel>
                            <div className="accordion-header">
                                <Skeleton width="80%" height="14px" style={{ marginBottom: 5 }} />
                                <Skeleton width="40%" height="16px" />
                            </div>
                        </AccordionLabel>
                    </AccordionButtonWrapper>
                    <AccordionChevron active={false}>
                        <Skeleton width="8px" height="4px" />
                    </AccordionChevron>
                </AccordionButton>
                <AccordionContent>
                    <ProjectModulesWrapper>
                        {Array.from(Array(4)).map((_, index) => (
                            <ProjectSectionItem key={index}>
                                <ProjectSectionTitle>
                                    <Skeleton width="100%" height="16px" />
                                </ProjectSectionTitle>
                                {Array.from(Array(2)).map((_, ix) => (
                                    <ProjectSectionContainer active={false} key={ix}>
                                        <Skeleton width="80%" height="14px" />
                                    </ProjectSectionContainer>
                                ))}
                            </ProjectSectionItem>
                        ))}
                    </ProjectModulesWrapper>
                </AccordionContent>
            </AccordionItem>
        </AccordionContainer>
    </ProjectModulesListContainer>
);

export default ModulesListPlaceholder;
