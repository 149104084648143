// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    box-sizing: border-box;

    p {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.428;
        color: ${theme.colors.base[400]};
    }
`;
