export const hasLessonPlanCustom = (brandSlug: string, courseSlug: string) => {
    const isProenemCoursesWithLessonPlanCustom =
        brandSlug === "proenem" &&
        [
            "turma-extensiva-2022",
            "turma-abril",
            "turma-de-maio",
            "2022-or-turma-de-junho",
            "turma-intensiva-22",
            "extensiva-2023",
            "turma-extensiva-23",
            "pe-teste-gratis",
            "turma-marco-2023",
            "turma-de-abril-2023",
            "enempro-360",
            "viga-estudo",
            "viga-estudo-2",
            "viga-estudo-plano-de-estudos-personalizado",
            "turma-intensiva-2023",
            "turma-intensiva-23-v2",
            "turma-intensiva-23-v3",
            "turma-intensiva-23-v4",
            "enem-pro-360",
            "med-pro-360",
            "extensiva-med-2024",
            "turma-extensiva-2024",
            "medpro-360",
            "medpro-360-24",
            "extensiva-2024",
            "turma-de-marco-2024",
            "turma-abril-2024-med",
            "turma-abril-2024-enem",
            "turma-abril-4-2024-enem",
            "turma-abril-3-2024-enem",
            "turma-abril-2-2024-enem",
            "enem-maio-24-turma-3",
            "enem-maio-24-turma-2",
            "sem-extensiva-med-2024",
            "enem-maio-24",
            "enem-junho-24.3",
            "enem-junho-24.2",
            "enem-junho-24.1"
        ].includes(courseSlug);

    const isPromedicinaCoursesWithLessonPlanCustom =
        brandSlug === "promedicina" &&
        [
            "promed",
            "pmed-teste-gratis",
            "promed-2023",
            "promedicina-23-marco",
            "medpro-360",
            "medpro-360-24",
            "enempro-360",
            "viga-estudo",
            "viga-estudo-2",
            "viga-estudo-plano-de-estudos-personalizado",
            "turma-intensiva-2023",
            "turma-intensiva-23-v2",
            "turma-intensiva-23-v3",
            "turma-intensiva-23-v4",
            "med-pro-360",
            "extensiva-med-2024",
            "turma-extensiva-2024",
            "extensiva-2024",
            "enem-pro-360",
            "extensiva-med-24-marco",
            "turma-abril-2024-med",
            "turma-abril-2024-enem",
            "turma-abril-4-2024-enem",
            "turma-abril-3-2024-enem",
            "turma-abril-2-2024-enem",
            "enem-maio-24-turma-3",
            "enem-maio-24-turma-2",
            "sem-extensiva-med-2024",
            "enem-maio-24",
            "enem-junho-24.3",
            "enem-junho-24.2",
            "enem-junho-24.1"
        ].includes(courseSlug);

    const isPromilitaresCoursesWithLessonPlanCustom = brandSlug === "promilitares" && !["pm-nivelamento"].includes(courseSlug);

    return isPromilitaresCoursesWithLessonPlanCustom || isProenemCoursesWithLessonPlanCustom || isPromedicinaCoursesWithLessonPlanCustom;
};
