import React, { useState, useCallback, useMemo, memo, useEffect, useRef } from "react";

// Dependencies
import validate from "validate.js";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

// Components
import Arrow from "components/Arrow";
import Button from "components/Button";
import PageLoader from "components/PageLoader";
import TextField from "components/Form/TextField";

// Helpers
import Reaptcha from "reaptcha";
import { IAuthActions } from "store/interfaces/IAuth";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as AuthActions } from "store/ducks/auth";

// Assets
import RightContent from "components/SignScreen/RightContent";
import {
    SignBackToHome,
    SignButtonsWrap,
    SignForm,
    SignFormLabel,
    GeneralSignLeftContent,
    SignSubTitle,
    SignTitle,
    SignTitlesWrap,
    SignWrapContent,
    SignActionsItem,
    FieldWrapper
} from "components/SignScreen/styles";

interface IProps {
    authActions: IAuthActions;
    isLoading: boolean;
}

const RecoverPassword = ({ authActions, isLoading }: IProps) => {
    const captchaRef = useRef<Reaptcha>(null);

    const { code: codeParam } = useParams();
    const [code, setCode] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined);
    const [captchaReady, setCaptchaReady] = useState(false);

    useEffect(() => {
        if (captchaRef.current) {
            const interval = setInterval(() => {
                setRecaptchaToken(undefined);
            }, 90000);

            return () => interval && clearInterval(interval);
        }
    }, [setRecaptchaToken]);

    useEffect(() => {
        if (!recaptchaToken && captchaRef.current && captchaReady) {
            captchaRef.current.reset();

            captchaRef.current.execute();

            authActions.clearErrors();
        }
    }, [recaptchaToken, authActions, captchaReady]);

    useEffect(() => {
        if (codeParam) {
            setCode(codeParam);
        }
    }, [codeParam]);

    const handleVerify = useCallback((token) => setRecaptchaToken(token), [setRecaptchaToken]);

    const handleRecaptchaLoad = useCallback(() => {
        if (captchaRef && captchaRef.current) {
            setCaptchaReady(true);
            captchaRef.current.execute();
        }
    }, []);

    const isEmailValid = useMemo(() => !validate.single(email, { email: true }), [email]);

    const isPasswordValid = useMemo(() => {
        return !validate.single(newPassword, {
            length: {
                minimum: 5
            }
        });
    }, [newPassword]);

    const handleSetEmail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), []);
    const handleSetNewPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value), []);

    const isFormValid = useMemo(() => (code ? isEmailValid && isPasswordValid && code : isEmailValid && recaptchaToken), [
        code,
        isEmailValid,
        isPasswordValid,
        recaptchaToken
    ]);

    const handleRecoverPassword = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            authActions.recoverPasswordRequest({ recaptchaToken, email });

            setRecaptchaToken(undefined);
        },
        [authActions, email, recaptchaToken]
    );

    const handleNewPassword = useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();

            const payload = {
                code,
                email: email.trim(),
                newPassword: newPassword.trim()
            };

            authActions.resetPasswordRequest(payload);

            setRecaptchaToken(undefined);
        },
        [authActions, code, email, newPassword]
    );

    return (
        <SignWrapContent>
            {isLoading && <PageLoader />}
            <GeneralSignLeftContent>
                <Link className="back-to" to="/">
                    <SignBackToHome>
                        <Arrow width={20} height={15} />
                        Voltar
                    </SignBackToHome>
                </Link>
                <SignTitlesWrap>
                    <SignTitle>Esqueceu sua senha?</SignTitle>
                    <SignSubTitle>
                        {code ? "Digite seu email e uma nova senha." : "Digite seu email abaixo que nós te ajudamos."}{" "}
                        <span role="img" aria-label="">
                            😉
                        </span>
                    </SignSubTitle>
                </SignTitlesWrap>
                <SignForm onSubmit={code ? handleNewPassword : handleRecoverPassword}>
                    {!code && (
                        <FieldWrapper>
                            <Reaptcha
                                ref={captchaRef}
                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string}
                                size="invisible"
                                onRender={handleRecaptchaLoad}
                                onVerify={handleVerify}
                            />
                        </FieldWrapper>
                    )}
                    <FieldWrapper>
                        <TextField name="email" label="Seu e-mail" value={email} onChange={handleSetEmail} />
                    </FieldWrapper>
                    {code && (
                        <>
                            <FieldWrapper>
                                <TextField name="code" label="Código" value={code} disabled={true} />
                            </FieldWrapper>
                            <FieldWrapper>
                                <TextField
                                    type="password"
                                    name="newPassword"
                                    label="Digite uma nova senha"
                                    value={newPassword}
                                    onChange={handleSetNewPassword}
                                />
                            </FieldWrapper>
                        </>
                    )}
                    {code && (
                        <SignActionsItem>
                            <SignFormLabel>Código inválido ou expirado?</SignFormLabel>
                            <Link to="/recuperar-senha">Solicite um novo</Link>
                        </SignActionsItem>
                    )}
                    <SignButtonsWrap>
                        <Button
                            disabled={!isFormValid || isLoading || (!code && !recaptchaToken)}
                            type="submit"
                            variant="primary"
                            block={true}
                            size="medium"
                        >
                            {code ? "Criar nova senha" : "Recuperar senha"}
                        </Button>
                    </SignButtonsWrap>
                </SignForm>
            </GeneralSignLeftContent>
            <RightContent />
        </SignWrapContent>
    );
};

const MemoizedRecoverPassword = memo(RecoverPassword);

const mapStateToProps = ({ auth, credentials }: IReduxStore) => ({
    isLoading: credentials.isLoading || auth.isLoading
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoizedRecoverPassword);
