// Dependencies
import React, { useState } from "react";

// Components
import Enunciation from "components/Enunciation";
import Button from "components/Button";

// Styles
import { BookletModalContainer, BookletModalContent, BookletModalTitle, BookletModalHeader, Divider } from "./styles";

// Services
import api from "services/api";
import slugify from "helpers/slugify";

interface IBookletModalProps {
    action: {
        linkHTML: string;
        title: string;
        content?: string;
        id?: number;
    };
}

const BookletModal = ({ action }: IBookletModalProps) => {
    const [isMakingDownload, setIsMakingDownload] = useState(false);

    const handleDownload = async () => {
        if (!!action.id) {
            setIsMakingDownload(true);

            api.get(`/student/material/${action.id}/report`, {
                responseType: "blob"
            })
                .then((blob) => {
                    new File([blob.data], "file");

                    const url = window.URL.createObjectURL(blob.data);

                    const fakeLink = document.createElement("a");

                    fakeLink.href = url;

                    fakeLink.download = `${slugify(action.title)}.pdf`;

                    document.body.appendChild(fakeLink);

                    fakeLink.click();

                    fakeLink.remove();

                    setIsMakingDownload(false);
                })
                .catch(() => {
                    setIsMakingDownload(false);
                });
        }
    };

    return (
        <BookletModalContainer>
            <BookletModalHeader>
                <BookletModalTitle>{action.title}</BookletModalTitle>

                {!action?.linkHTML && !!action?.content && (
                    <Button variant="info" disabled={isMakingDownload} isLoading={isMakingDownload} onClick={handleDownload}>
                        Imprimir
                    </Button>
                )}
            </BookletModalHeader>

            <Divider />

            {!!action?.linkHTML && (
                <BookletModalContent>
                    <iframe allowFullScreen src={action.linkHTML} frameBorder="0" title={action.title}>
                        Seu navegador não suporta carregar esse conteúdo.
                    </iframe>
                </BookletModalContent>
            )}

            {!action?.linkHTML && !!action?.content && <Enunciation enunciation={action.content} />}

            {!action?.linkHTML && !action?.content && <p>Conteúdo não encontrado.</p>}
        </BookletModalContainer>
    );
};

export default BookletModal;
