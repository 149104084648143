import styled from "styled-components";
import { theme } from "config/theme";

export const ExerciseListAlternativeContainer = styled.div``;

export const ExerciseListAlternativeProgressLabel = styled.p`
    color: ${theme.colors.base[500]};
    font-family: "Nunito", sans-serif;
    font-weight: 700;
    line-height: ${theme.typography.sizes.smallX};
    font-size: ${theme.typography.sizes.smallX};
    margin-bottom: ${theme.spacing.smallXX};
    display: flex;
    align-items: center;

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    .error {
        stroke: ${theme.colors.system.danger[300]};
    }

    .success {
        stroke: ${theme.colors.system.success[300]};
    }

    span {
        color: ${theme.colors.system.danger[300]};
        cursor: pointer;
        margin-left: ${theme.spacing.smallXX};

        :hover {
            text-decoration: underline;
        }
    }
`;
