// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const Container = styled.div`
    display: flex;
    height: auto;
    width: 100%;
    align-items: center;

    .nameDay {
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: 0;
        line-height: 1.4;
        font-family: "Nunito", sans-serif;
        font-style: normal;
        color: ${theme.colors.base[300]};
        margin-right: 24px;
        width: 25px;
    }

    .contentButtons {
        display: flex;
        width: 100%;
        height: auto;
        justify-content: space-between;
    }

    @media (min-width: 768px) {
        height: 100%;
        flex-direction: column;

        .nameDay {
            margin-right: 0;
            margin-bottom: 16px;
            width: auto;
            font-size: 20px;
        }

        .contentButtons {
            flex-direction: column;
            height: 100%;
            align-items: center;
        }
    }
`;
