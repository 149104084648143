// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Overlay } from "./radial.styles";

// Utils
import { ternary } from "utils/ternary";

// Types
import { RadialProps } from "./radial.types";

export const Radial: FunctionComponent<RadialProps> = ({ progress, size = "small", appearance = "default" }) => {
    const firstGradientDegrees = ternary([
        [progress === 0, "left"],
        [progress === 50, "left"],
        [progress === 100, "right"],
        [progress > 0 && progress < 50, "left"],
        [progress > 50 && progress < 100, "right"]
    ]);
    const secondGradientDegrees = ternary([
        [progress === 0, "left"],
        [progress === 50, "right"],
        [progress === 100, "left"],
        [progress > 0 && progress < 50, `${360 - progress * 3.6}deg`],
        [progress > 50 && progress < 100, `${180 - (progress - 50) * 3.6}deg`]
    ]);

    return (
        <Container
            className="pdg-radial"
            firstGradientDegrees={firstGradientDegrees!}
            secondGradientDegrees={secondGradientDegrees!}
            progress={progress}
            size={size}
            appearance={appearance}
        >
            <Overlay size={size} />
        </Container>
    );
};
