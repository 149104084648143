// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    shouldDisplayExpandableButton: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    ${({ shouldDisplayExpandableButton }) =>
        shouldDisplayExpandableButton &&
        css`
            gap: 8px;
        `}
`;

type ContentStyleProps = {
    hasEllipsis: boolean;
    shouldDisplayExpandableButton: boolean;
};

export const Content = styled.p<ContentStyleProps>`
    width: 100%;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #4d4d63;

    ${({ hasEllipsis }) =>
        hasEllipsis &&
        css`
            overflow: hidden;
            text-overflow: ellipsis;
        `}

    ${({ shouldDisplayExpandableButton }) =>
        shouldDisplayExpandableButton &&
        css`
            font-size: 14px;
            line-height: 20px;
        `}
`;

export const SkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ActivityCommentButtonIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;
