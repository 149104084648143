import React, { useCallback, useEffect, useState } from "react";

// Dependencies
import { Route, Router, Switch } from "react-router";
import { ToastContainer } from "react-toastify";

// Components
import Icons from "components/Icons";
import Modal from "components/Modal";
import ErrorScreen from "screens/Error";
import PageLoader from "components/PageLoader";
import AlertUpdate from "components/Alert/update";
import AlertNetwork from "components/Alert/network";
import ErrorBoundary from "components/ErrorBoundary";
import SubjectWaves from "components/SubjectWave/waves";
import ContentSearch from "screens/ContentSearch";
import Maintenance from "screens/Maintenance";
import LayersPortal from "screens/LayersPortal";

// Redux
import { Provider } from "react-redux";
import { persistor, store } from "store";
import { PersistGate } from "redux-persist/integration/react";
import { Creators as updateActions } from "store/ducks/update";

// Helpers
import history from "services/history";
import mainRoutes from "routes/mainRoutes";
import { GridConfig } from "components/Grid";
import * as serviceWorkerRegistration from "./serviceWorker";
import { ThemeProvider } from "styled-components";

// Assets
import { GlobalStyles } from "assets/styles/global";
import { ProappTheme } from "assets/styles/proapp-theme";
import "react-toastify/dist/ReactToastify.min.css";
import "rc-slider/assets/index.css";

import { theme } from "config/theme";
const themeSlug = theme.project.slug || "proenem";

/*
 * Import technique that include file in the same chunk. Allowing dynamic imports.
 * Source: https://medium.com/front-end-weekly/webpack-and-dynamic-imports-doing-it-right-72549ff49234
 */
const isB2CBrand = ["proenem", "promilitares", "promedicina"].includes(theme.project.slug);

if (isB2CBrand) {
    import(`webapp-components/dist/libs/webp-components/themes/${themeSlug}.css`);
}

const init = () => store.dispatch(updateActions.init());
const updateApplication = (registration: any) => store.dispatch(updateActions.updateApplication(registration));

// Register service worker
serviceWorkerRegistration.register({
    onSuccess: init,
    onUpdate: updateApplication
});

const App = () => {
    const [status, setStatus] = useState("healthy");

    const getStatusHealth = useCallback(async () => {
        try {
            const response = await fetch(process.env.REACT_APP_HEALTHY!);
            const { status } = await response.json();

            setStatus(status);
        } catch (error) {
            setStatus("healthy");
        }
    }, []);

    useEffect(() => {
        getStatusHealth();
    }, [getStatusHealth]);

    if (status !== "healthy") {
        return <Maintenance onGetStatusHealth={getStatusHealth} />;
    }

    return (
        <ThemeProvider theme={GridConfig}>
            <ErrorBoundary component={<ErrorScreen />}>
                <Provider store={store}>
                    <PersistGate loading={<PageLoader />} persistor={persistor}>
                        {!isB2CBrand && <ProappTheme />}
                        <GlobalStyles />
                        <SubjectWaves />
                        <Icons />
                        <Router history={history}>
                            <Modal />
                            <ContentSearch />
                            <LayersPortal />
                            <Switch>
                                {mainRoutes.map((route, index) => (
                                    <Route path={route.path} component={route.component} exact={route.exact} key={index} />
                                ))}
                            </Switch>
                            <ToastContainer autoClose={8000} position="bottom-right" draggable={false} pauseOnHover hideProgressBar={false} />
                        </Router>
                        <AlertUpdate />
                        <AlertNetwork />
                    </PersistGate>
                </Provider>
            </ErrorBoundary>
        </ThemeProvider>
    );
};

export default App;
