import React, { useMemo, useEffect, useCallback } from "react";
import { ExamDayResultContainer } from "./styles";
import ExamDayResultBasicInfos from "./components/BasicInfos";
import { Grid, Row, Col } from "components/Grid";
import Card from "components/Card";
import KnowledgeAreasResults from "./components/KnowledgeAreasResults";
import { useParams } from "react-router";
import ErrorScreen from "screens/Error";
import Spinner from "components/Spinner";
import { IExamActions, IExamDayRelated } from "store/ducks/exam/types";
import { IExamDayResult } from "interfaces/IExamDays";
import { IPayloadRequest } from "interfaces/IRequestAction";
import history from "services/history";
import { formatToNaturalNumber } from "utils/formatToNaturalNumber";
import RelatedDays from "./components/RelatedDays";
import ExamDayModal from "components/ExamDayModal";
import { IAnswerCardActions } from "store/interfaces/IActions";
import PageHeader from "components/PageHeader";
import ExamResultEssay from "components/ExamResultEssay";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as examActions } from "store/ducks/exam";
import { Creators as answerCardActions } from "store/ducks/answerCard";
import { IReduxStore } from "interfaces/IReduxStore";

interface IProps {
    examDayResult: IExamDayResult;
    examActions: IExamActions;
    loading: boolean;
    error: boolean;
    loadingRelatedDays: boolean;
    relatedDays: IExamDayRelated[];
    answerCardActions: IAnswerCardActions;
}

const ExamDayResult = ({ examDayResult, examActions, loading, error, loadingRelatedDays = true, relatedDays = [], answerCardActions }: IProps) => {
    const { examId, examDayId, answerCardId } = useParams<{ examId?: string; examDayId?: string; answerCardId?: string }>();

    const { knowledgeAreas, examDay, totalCorrects = 0, startDate, endDate, examResolution } = examDayResult;

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const isAnswerReleased = !!examDay?.isAnswerReleased;

    const totalQuestions = useMemo(() => formatToNaturalNumber(knowledgeAreas?.reduce((acc, curr) => acc + curr.totalQuestions, 0)), [
        knowledgeAreas
    ]);

    useEffect(() => {
        return () => answerCardActions.clearAnswerCardResults();
    }, [answerCardActions]);

    useEffect(() => {
        return () => {
            examActions.clearExamDayResult();
        };
    }, [examActions]);

    const requestResult = useCallback(() => {
        if (!examDayId || !answerCardId) {
            return;
        }

        const payload: IPayloadRequest = {
            endpoint: `/student/examday/${examDayId}/realization/${answerCardId}`,
            method: "GET",
            body: {
                examId,
                examDayId
            }
        };

        examActions.getExamDayResultRequest(payload);
    }, [answerCardId, examActions, examDayId, examId]);

    useEffect(() => {
        requestResult();
    }, [requestResult]);

    const handleClickRelatedDay = useCallback((examDaySlug: string) => !!examDaySlug && examActions.openExamDayModal({ examDaySlug }), [examActions]);

    const memoBasicInfos = useMemo(() => {
        if (loading) {
            return <Spinner fixed={false} size={25} />;
        }

        const wrongAnswers = formatToNaturalNumber(totalQuestions - totalCorrects);

        return (
            <ExamDayResultBasicInfos
                startDate={startDate}
                endDate={endDate}
                loading={loading}
                knowledgeAreas={knowledgeAreas}
                totalCorrects={totalCorrects}
                totalQuestions={totalQuestions}
                totalWrong={wrongAnswers}
                onClickFeedback={() => history.push({ pathname: `/app/curso/${courseSlug}/simulados/${examId}/${examDayId}/prova/${answerCardId}` })}
                isAnswerReleased={isAnswerReleased}
            />
        );
    }, [answerCardId, courseSlug, endDate, examDayId, examId, isAnswerReleased, knowledgeAreas, loading, startDate, totalCorrects, totalQuestions]);

    const memoKnowledgeAreasResults = useMemo(() => {
        if (loading) {
            return <Spinner fixed={false} size={25} />;
        }

        return <KnowledgeAreasResults knowledgeAreas={knowledgeAreas} />;
    }, [knowledgeAreas, loading]);

    const handleClickEssay = useCallback(() => {
        history.push({ pathname: `/app/curso/${courseSlug}/simulados/${examId}/${examDayId}/prova/${answerCardId}?redacao=1` });
    }, [answerCardId, courseSlug, examDayId, examId]);

    const desktopView = useMemo(
        () => (
            <Row>
                <Col xs={12} sm={6}>
                    <Card>{memoBasicInfos}</Card>

                    <RelatedDays isLoading={loadingRelatedDays} relatedDays={relatedDays} onClickRelatedDay={handleClickRelatedDay} />
                </Col>

                <Col xs={12} sm={6}>
                    {!!examResolution?.composition && (
                        <Card>
                            <ExamResultEssay composition={examResolution?.composition} onClickEssay={handleClickEssay} />
                        </Card>
                    )}
                    <Card>{memoKnowledgeAreasResults}</Card>
                </Col>
            </Row>
        ),
        [examResolution, handleClickEssay, handleClickRelatedDay, loadingRelatedDays, memoBasicInfos, memoKnowledgeAreasResults, relatedDays]
    );

    if (error) {
        return (
            <ExamDayResultContainer>
                <ErrorScreen />
            </ExamDayResultContainer>
        );
    }

    return (
        <>
            <ExamDayModal />
            <ExamDayResultContainer>
                <PageHeader.Simple
                    breadcrumb={{
                        current: {
                            label: "Resultado"
                        },
                        parent: [
                            {
                                label: "Simulados",
                                url: `/app/curso/${courseSlug}/simulados`
                            },
                            {
                                label: examDay?.exam?.name,
                                url: `/app/curso/${courseSlug}/simulados/${examId}`
                            },
                            {
                                label: examDay?.name,
                                url: `/app/curso/${courseSlug}/simulados/${examId}/${examDayId}`
                            }
                        ]
                    }}
                />

                <Grid>{desktopView}</Grid>
            </ExamDayResultContainer>
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    examActions: bindActionCreators(examActions, dispatch),
    answerCardActions: bindActionCreators(answerCardActions, dispatch)
});

const mapStateToProps = ({ exam }: IReduxStore) => ({
    examDayResult: exam.dayResult,
    loading: exam.loading,
    error: exam.error,
    loadingRelatedDays: exam.loadingRelatedDays,
    relatedDays: exam.relatedDays
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ExamDayResult as any);
