// Dependencies
import styled, { css } from "styled-components";

export const Container = styled.div`
    background-color: #ffffff;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    padding: 16px 16px 32px 16px;
    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
`;

export const ActivityStepFormSelector = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 24px;
`;

type ActivityStepFormSelectorItemStyleProps = {
    variant: "current" | "default" | "checked";
};

export const ActivityStepFormSelectorItem = styled.div<ActivityStepFormSelectorItemStyleProps>`
    height: 4px;
    flex: 1 0 0;
    border-radius: 4px;

    ${({ variant }) => {
        if (variant === "current") {
            return css`
                background-color: #232326;
            `;
        }

        if (variant === "checked") {
            return css`
                background-color: #55dc99;
            `;
        }

        if (variant === "default") {
            return css`
                background-color: #ced0dd;
            `;
        }

        return css`
            background-color: #ced0dd;
        `;
    }}
`;

export const ActivityStepFormContentWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
`;

export const ActivityStepFormFooterButtonCompositionsWrapper = styled.div`
    width: 100%;
    padding: 24px 16px;
    border-top: 1px solid #ced0dd;
    background-color: #ffffff;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    z-index: 2;

    position: sticky;
    bottom: 0;
`;

export const ActivityStepFormInformationWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

export const ActivityStepFormCurrentStepIndicator = styled.p`
    color: #83899e;
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
`;

export const ActivityStepFormTitle = styled.p`
    color: #232326;
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;

export const ActivityStepFormDescription = styled.p`
    color: #232326;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
`;

export const ActivityStepFormCompositionsWrapper = styled.div`
    width: 100%;
`;

export type ActivityStepFormItemCompositionStyleProps = {
    isActive: boolean;
};

export const ActivityStepFormItemComposition = styled.div<ActivityStepFormItemCompositionStyleProps>`
    width: 100%;

    display: ${({ isActive }) => (isActive ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
`;

export const ActivityStepFormItemContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    > div {
        width: 100%;
    }
`;
