import * as React from "react";

import { RadioHelper, RadioInput, RadioLabel } from "./styles";
import { FlatVariant } from "utils/types/Variant";

interface IProps {
    inputName: string;
    isChecked: boolean;
    value: string | number | string[];
    variant?: FlatVariant | "brand" | "secondary";
    onChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const Radio = ({ inputName, isChecked, value, onChange, variant = "success" }: IProps) => (
    <RadioLabel variant={variant}>
        <RadioInput value={value} type="radio" checked={isChecked} name={inputName} onChange={onChange} />
        <RadioHelper />
    </RadioLabel>
);

export default Radio;
