import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { WebpInput } from "webapp-components/dist/libs/webp-components-react";
import { WebpText } from "components/WebpText";
import { WebpButton } from "components/WebpButton";
import useIsMobile from "../../../hooks/use-is-mobile";
import { CustomListFilterFormData, ExerciseListCustomStateKeys } from "../../../interfaces/IExerciseListCustom";
import { IReduxStore } from "../../../interfaces/IReduxStore";
import { Types } from "../../../store/ducks/exerciseListCustom";
import FilterForm from "./composites/FilterForm";
import FilterListing from "./composites/FilterListing";
import FilterSummary from "./composites/FilterSummary";
import { FilterGrid, FilterScreenWrapper, FooterBar } from "./styles";

export type handleFilterChangeType = (filterName: string, payload: any, currentState?: CustomListFilterFormData | boolean | any) => void;

function QuestionsFilter() {
    const { exerciseListCustomState, courseSlug } = useSelector((state: IReduxStore) => ({
        exerciseListCustomState: state.exerciseListCustom,
        courseSlug: state.course.slug
    }));
    const {
        subjects,
        topics,
        contest,
        contestYear,
        userListSize,
        listSizeLimit,
        subtopics,
        contestPhase,
        contestsWithAuthoredQuestions,
        isAuthored,
        isCancelled,
        isFromAnotherList,
        isFromContest,
        questionLevel,
        questionStatus,
        questionType
    } = exerciseListCustomState;

    const dispatch = useDispatch();

    const isMobile = useIsMobile();

    const history = useHistory();

    const [disableCreateButton, setDisableCreateButton] = useState(true);
    const [listName, setListName] = useState("");

    const handleFilterChange: handleFilterChangeType = (filterName, ev, currentState) => {
        if (!ev.detail) return;

        const payload = { dropdownData: ev.detail, currentState: currentState };

        switch (filterName) {
            case ExerciseListCustomStateKeys.SUBJECTS:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.SUBJECTS }
                });
                break;

            case ExerciseListCustomStateKeys.TOPICS:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.TOPICS }
                });
                break;

            case ExerciseListCustomStateKeys.SUBTOPICS:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.SUBTOPICS }
                });
                break;

            case ExerciseListCustomStateKeys.QUESTION_TYPE:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.QUESTION_TYPE }
                });
                break;

            case ExerciseListCustomStateKeys.IS_AUTHORED:
                dispatch({
                    type: Types.SET_BOOLEAN_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.IS_AUTHORED }
                });
                break;

            case ExerciseListCustomStateKeys.CONTESTS_WITH_AUTHORED_QUESTIONS:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.CONTESTS_WITH_AUTHORED_QUESTIONS }
                });
                break;

            case ExerciseListCustomStateKeys.IS_FROM_CONTEST:
                dispatch({
                    type: Types.SET_BOOLEAN_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.IS_FROM_CONTEST }
                });
                break;

            case ExerciseListCustomStateKeys.CONTEST:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.CONTEST }
                });
                break;

            case ExerciseListCustomStateKeys.CONTEST_YEAR:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.CONTEST_YEAR }
                });
                break;

            case ExerciseListCustomStateKeys.CONTEST_PHASE:
                dispatch({
                    type: Types.SET_SELECTED_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.CONTEST_PHASE }
                });
                break;

            case ExerciseListCustomStateKeys.QUESTION_STATUS:
                dispatch({
                    type: Types.SET_QUESTION_STATUS_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.QUESTION_STATUS }
                });
                break;

            case ExerciseListCustomStateKeys.QUESTION_LEVEL:
                dispatch({
                    type: Types.SET_QUESTION_LEVEL_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.QUESTION_LEVEL }
                });
                break;

            case ExerciseListCustomStateKeys.IS_CANCELLED:
                dispatch({
                    type: Types.SET_BOOLEAN_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.IS_CANCELLED }
                });
                break;

            case ExerciseListCustomStateKeys.IS_FROM_ANOTHER_LIST:
                dispatch({
                    type: Types.SET_BOOLEAN_FILTER_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.IS_FROM_ANOTHER_LIST }
                });
                break;

            case ExerciseListCustomStateKeys.USER_LIST_SIZE:
                dispatch({
                    type: Types.SET_USER_LIST_SIZE_REQUEST,
                    payload: { ...payload, stateName: ExerciseListCustomStateKeys.USER_LIST_SIZE }
                });
                break;

            default:
                throw new Error("ExerciseListCustomStateKey not found.");
        }
    };

    const getAllSubjects = useCallback(() => {
        dispatch({ type: Types.GET_ALL_SUBJECTS_REQUEST });
    }, [dispatch]);

    const getSubtopics = useCallback(() => {
        dispatch({ type: Types.GET_SUBTOPICS_REQUEST });
    }, [dispatch]);

    const getTopics = useCallback(() => {
        dispatch({ type: Types.GET_TOPICS_REQUEST });
        getSubtopics();
    }, [dispatch, getSubtopics]);

    const getQuestionType = useCallback(() => {
        dispatch({ type: Types.GET_QUESTION_TYPE_REQUEST });
    }, [dispatch]);

    const getContestsWithAuthoredQuestions = useCallback(() => {
        dispatch({ type: Types.GET_CONTEST_WITH_AUTHORED_QUESTIONS_REQUEST });
    }, [dispatch]);

    const getContest = useCallback(() => {
        dispatch({ type: Types.GET_CONTEST_REQUEST });
    }, [dispatch]);

    const getContestYear = useCallback(() => {
        dispatch({ type: Types.GET_CONTEST_YEAR_REQUEST });
    }, [dispatch]);

    const getContestPhase = useCallback(() => {
        dispatch({ type: Types.GET_CONTEST_PHASE_REQUEST });
    }, [dispatch]);

    const getListSize = useCallback(() => {
        dispatch({ type: Types.GET_LIST_SIZE_REQUEST });
    }, [dispatch]);

    const getInitialData = useCallback(() => {
        dispatch({ type: Types.CLEAR_STATE });
        getAllSubjects();
        getQuestionType();
        getListSize();
    }, [dispatch, getAllSubjects, getListSize, getQuestionType]);

    const handleListSubmit = (ev: FormEvent) => {
        ev.preventDefault();
        dispatch({ type: Types.SAVE_CUSTOM_EXERCISE_LIST_REQUEST, payload: { listName } });
    };

    useEffect(() => {
        getInitialData();
    }, [getInitialData]);

    // Preconditions to enable the create list button
    useEffect(() => {
        userListSize! <= listSizeLimit! && userListSize! > 0 && !!listName ? setDisableCreateButton(false) : setDisableCreateButton(true);
    }, [listName, listSizeLimit, userListSize]);

    useEffect(() => {
        getListSize();
    }, [
        getListSize,
        subjects,
        topics,
        contest,
        contestYear,
        listSizeLimit,
        subtopics,
        contestPhase,
        contestsWithAuthoredQuestions,
        isAuthored,
        isCancelled,
        isFromAnotherList,
        isFromContest,
        questionLevel,
        questionStatus,
        questionType
    ]);

    useEffect(() => {
        getTopics();
    }, [getTopics, subjects]);

    useEffect(() => {
        if (!!topics?.options.length) {
            getSubtopics();
        }
    }, [getSubtopics, topics, subjects]);

    useEffect(() => {
        getContestsWithAuthoredQuestions();
    }, [getContestsWithAuthoredQuestions, isAuthored]);

    useEffect(() => {
        getContest();
    }, [getContest, isFromContest]);

    useEffect(() => {
        getContestYear();
    }, [getContestYear, contest]);

    useEffect(() => {
        getContestPhase();
    }, [getContestPhase, contestYear, contest]);

    return (
        <>
            <FilterScreenWrapper>
                <FilterGrid>
                    <div className="filter-header">
                        <WebpText xs="title-sm" sm="title-md" color="neutral" colorWeight={500}>
                            <h1 className="section-title">Filtre as questões</h1>
                        </WebpText>
                        <WebpText xs="body-text-02" sm="body-text-01" color="neutral" colorWeight={400}>
                            <p className="intro-text">Selecione as questões que você quer que apareça na sua lista de exercício</p>
                        </WebpText>
                    </div>
                    <div className="filter-form">
                        <FilterForm exerciseListCustomState={exerciseListCustomState} handleFilterChange={handleFilterChange} />
                    </div>
                    <div className="filter-listing">
                        <FilterListing handleFilterChange={handleFilterChange} exerciseListCustomState={exerciseListCustomState} />
                    </div>
                    <div className="filter-summary">
                        <FilterSummary handleFilterChange={handleFilterChange} exerciseListCustomState={exerciseListCustomState} />
                    </div>
                </FilterGrid>
                <FooterBar>
                    <form className="footer-bar-container" onSubmit={(ev) => handleListSubmit(ev)}>
                        <WebpButton
                            variant="outline-primary"
                            size="medium"
                            className="footer-btn"
                            id="go-back"
                            onClick={() => history.push({ pathname: `/app/curso/${courseSlug}/banco-de-questoes` })}
                            contentButtonComposition={"Voltar"}
                            isFullyAdaptative={false}
                        ></WebpButton>

                        <div className="form-group">
                            <WebpInput
                                type="text"
                                placeholder="Nomeie a sua lista"
                                value={listName}
                                onWebpInputChange={(ev) => setListName(ev.detail.value as string)}
                                className="create-input"
                                invalid={!listName}
                            />

                            <WebpButton
                                size="medium"
                                id="create"
                                variant="primary"
                                type="submit"
                                disabled={disableCreateButton}
                                className="footer-btn"
                                isFullyAdaptative={false}
                                contentButtonComposition={`Criar ${!isMobile ? " lista" : ""}`}
                            ></WebpButton>
                        </div>
                    </form>
                </FooterBar>
            </FilterScreenWrapper>
        </>
    );
}

export default QuestionsFilter;
