// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container } from "./styles";

type WebpStepGroupProps = {
    orientationGroup: "horizontal" | "vertical";
};

export const WebpStepGroup: FunctionComponent<WebpStepGroupProps> = ({ orientationGroup, children }) => {
    return <Container orientationGroup={orientationGroup}>{children}</Container>;
};
