import React, { useEffect } from "react";

// Assets
import {
    AnnouncementModalContainer,
    AnnouncementModalContent,
    AnnouncementModalContentTitle,
    AnnouncementModalHeader,
    AnnouncementModalHeaderTeacher,
    AnnouncementModalHeaderTime
} from "./styles";

// Helpers
import { DateTime } from "luxon";
import { IAnnouncement } from "store/ducks/announcement";
import { IAnnouncementActions } from "store/interfaces/IActions";

interface IProps {
    data: IAnnouncement;
    announcementActions: IAnnouncementActions;
    onClose(): void;
}
const AnnouncementModal = ({ data, onClose, announcementActions }: IProps) => {
    //
    useEffect(() => {
        if (!!!data.viewed) {
            const payload = {
                endpoint: `/student/notification/${data.notification.id}`,
                body: {
                    ...data,
                    viewed: true
                }
            };
            announcementActions.createOrEditAnnouncementsRequest(payload);
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <AnnouncementModalContainer>
            <AnnouncementModalHeader>
                <AnnouncementModalHeaderTeacher>{data.notification.signature}</AnnouncementModalHeaderTeacher>
                <AnnouncementModalHeaderTime>
                    <small>&bull;</small>
                    {DateTime.fromISO(data.sentDate).toFormat("dd/MM/yyyy") || " "}
                </AnnouncementModalHeaderTime>
            </AnnouncementModalHeader>

            <AnnouncementModalContent>
                <AnnouncementModalContentTitle>{data.notification.title}</AnnouncementModalContentTitle>
                <span>{data.notification.description}</span>
            </AnnouncementModalContent>
        </AnnouncementModalContainer>
    );
};

export default AnnouncementModal;
