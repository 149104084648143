import * as React from "react";

import { AvatarContainer, InitialChar } from "./styles";

// Assets
export interface IProps {
    src?: string;
    size?: "small" | "medium" | "large";
    initialUserChar?: string;
    onClick?(): void;
}

const Avatar = ({ initialUserChar, onClick, src = "", size = "medium" }: IProps) => (
    <AvatarContainer className="bg-cover" style={{ backgroundImage: `url(${src})` }} size={size} onClick={onClick}>
        {!src && <InitialChar>{initialUserChar ? initialUserChar!.toUpperCase() : "A"}</InitialChar>}
    </AvatarContainer>
);

export default Avatar;
