import React, { memo, useMemo } from "react";
import {
    AnswerCardProgressContainer,
    ExerciseListDashboardAnswerCardResultTooltipContainer,
    ExerciseListDashboardAnswerCardResultTooltip,
    ExerciseListDashboardAnswerCardResultProgress,
    ExerciseListDashboardAnswerCardResultProgressBar,
    ExerciseListDashboardAnswerCardResultUnfinishedLabel
} from "./styles";
import pluralize from "utils/pluralize";
import formatPercent from "utils/formatPercent";

interface IProps {
    answersCorrect?: number;
    answersWrong?: number;
    questionsTotal?: number;
    totalAnswered?: number;
    isFinished: boolean;
}

const AnswerCardProgress = memo(({ answersCorrect = 0, answersWrong = 0, questionsTotal = 0, totalAnswered = 0, isFinished }: IProps) => {
    const percentageFinished = useMemo(() => formatPercent((answersCorrect / questionsTotal) * 100), [answersCorrect, questionsTotal]);
    const percentageRemaining = useMemo(() => formatPercent((totalAnswered / questionsTotal) * 100), [questionsTotal, totalAnswered]);

    const currentPercentage = useMemo(() => (isFinished ? percentageFinished : percentageRemaining), [
        isFinished,
        percentageFinished,
        percentageRemaining
    ]);

    return (
        <AnswerCardProgressContainer>
            {isFinished ? (
                <ExerciseListDashboardAnswerCardResultTooltipContainer>
                    {answersWrong !== questionsTotal && (
                        <ExerciseListDashboardAnswerCardResultTooltip>
                            {answersCorrect || 0} {pluralize(answersCorrect, "acertos", "acerto")}
                        </ExerciseListDashboardAnswerCardResultTooltip>
                    )}

                    {answersCorrect !== questionsTotal && (
                        <ExerciseListDashboardAnswerCardResultTooltip>
                            {answersWrong || 0} {pluralize(answersWrong, "erros", "erro")}
                        </ExerciseListDashboardAnswerCardResultTooltip>
                    )}
                </ExerciseListDashboardAnswerCardResultTooltipContainer>
            ) : (
                <ExerciseListDashboardAnswerCardResultUnfinishedLabel>
                    {totalAnswered}/{questionsTotal} questões respondidas
                </ExerciseListDashboardAnswerCardResultUnfinishedLabel>
            )}

            <ExerciseListDashboardAnswerCardResultProgress>
                <ExerciseListDashboardAnswerCardResultProgressBar variant="success" value={currentPercentage} />
            </ExerciseListDashboardAnswerCardResultProgress>
        </AnswerCardProgressContainer>
    );
});

export default AnswerCardProgress;
