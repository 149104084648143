import { BodyText1 } from "assets/styles/global";
import { AvatarContainer, InitialChar } from "components/Avatar/styles";
import { theme } from "config/theme";
import styled from "styled-components";
import { MediaQueries } from "assets/styles/settings";

export const StudentAreaScreenHomeContentContainer = styled.div`
    margin: 0 -${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin: unset;
    }
`;

export const StudentAreaScreenCardContainer = styled.div`
    ${MediaQueries.BIGGER_THAN_SMALL} {
        background: ${theme.colors.white};
        border-radius: 4px;
        box-shadow: ${theme.shadow[1]};
        height: 100%;
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const StudentAreaScreenProfileContainer = styled.div`
    position: relative;
    display: flex;
    border-radius: 0 0 ${theme.spacing.small} ${theme.spacing.small};
    margin-bottom: ${theme.spacing.small};
    background-color: ${theme.colors.brand[200]};
    width: 100%;

    .promilitares-shape {
        height: 238px;
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        background-color: unset;
        border-radius: unset;
        padding: ${theme.spacing.large} 0;
        margin-bottom: 0;
    }
`;

export const StudentAreaScreenProfileAvatarContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    z-index: 1;
    height: 100%;
    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: unset;
        flex-direction: column;
    }
`;

export const StudentAreaScreenAvatarWrapper = styled.div<{ isMobile: boolean }>`
    align-self: center;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: ${theme.spacing.small};

    ${AvatarContainer} {
        border: 11px solid ${({ isMobile }) => (isMobile ? "#fff" : theme.colors.base[100])};
        z-index: 1;
        width: ${theme.spacing.largeXXX};
        height: ${theme.spacing.largeXXX};
        display: flex;

        ${InitialChar} {
            align-self: center;
        }
    }
`;

export const StudentAreaScreenBackgroundWave = styled.div`
    padding-top: ${theme.spacing.largeXX};
    width: 100%;

    .ico-wave-descend {
        border-radius: 0 0 ${theme.spacing.small} ${theme.spacing.small};
        color: ${theme.colors.brand[100]};
    }
`;

export const StudentAreaScreenMyAccountContainer = styled.div`
    margin: 0 ${theme.spacing.small};
`;

export const StudentAreaScreenEditIconContainer = styled.label`
    cursor: pointer;
    background-color: ${theme.colors.base[100]};
    box-shadow: ${theme.shadow[2]};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-self: flex-end;
    justify-self: flex-end;
    display: flex;
    position: absolute;
    z-index: 999;
    svg {
        margin: auto;
    }
`;

export const StudentAreaScreenName = styled.h1`
    color: ${theme.colors.base[500]};
    letter-spacing: 0.005em;
    font-weight: bold;
    text-align: center;
    bottom: ${theme.spacing.medium};
    position: absolute;
    align-self: flex-end;
    justify-self: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: unset;
        align-self: unset;
    }
`;

export const StudentAreaScreenStudentButton = styled.button`
    display: flex;
    /* justify-content: space-between; */
    cursor: pointer;
    padding: ${theme.spacing.small} 0;
    background: none;
    border: none;
    align-items: center;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    svg {
        :first-child {
            margin-right: ${theme.spacing.small};
        }
    }
`;

export const StudentAreaScreenProfileTitle = styled.h1`
    font-size: ${theme.typography.sizes.medium};
    font-weight: 600;
    line-height: 22px;
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const StudentAreaScreenStudentButtonText = styled.p`
    ${BodyText1};

    flex: 1;
    text-align: left;
`;

export const StudentAreaScreenStudentButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
