// Dependencies
import styled, { css } from "styled-components";

type ContainerStyleProps = {
    isOpen: boolean;
};

export const Container = styled.div<ContainerStyleProps>`
    display: block;
    cursor: pointer;

    .conent-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 22px;
        position: relative;
        background-color: transparent;
    }

    .header-text {
        font-family: "Nunito", sans-serif;
        font-style: normal;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.375;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .header-button {
        border: none;
        background-color: transparent;
        padding: 1px 6px;
        transform: rotate(180deg);
        transition: all ease 0.75s;
    }

    .content {
        width: 100%;
        height: 0;
        transition: all ease 0.75s;
        opacity: 0;
        overflow: hidden;
    }

    ${({ isOpen }) =>
        !!isOpen &&
        css`
            .header-button {
                transform: rotate(0deg);
                transition: all ease 0.75s;
            }
            .content {
                height: auto;
                transition: all ease 0.75s;
                opacity: 1;
            }
        `}
`;
