// Dependencies
import React, { Fragment, FunctionComponent } from "react";

// Types
import { ActivityModalProps } from "./activity-modal.types";

// Styles
import { ActionButtonCompositionsWrapper, Container, Title, TitleWrapper, ContentWrapper } from "./activity-modal.styles";

// Components
import { ActivityOverlay } from "components/ActivityOverlay";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";

export const ActivityModal: FunctionComponent<ActivityModalProps> = ({
    title,
    titleAlignment = "start",
    modalWidth,
    isOpened,
    content,
    actionButtonCompositions,
    closeIconElement,
    handleOutsideClick
}) => {
    return (
        <ConditionallyRender
            shouldRender={!!isOpened}
            content={
                <Fragment>
                    <ActivityOverlay
                        handleOutsideClick={handleOutsideClick}
                        content={
                            <Container className="webapp-modal" width={modalWidth}>
                                <ContentWrapper>
                                    <TitleWrapper alignment={titleAlignment}>
                                        <Title>{title}</Title>

                                        <ConditionallyRender shouldRender={!!closeIconElement} content={closeIconElement} />
                                    </TitleWrapper>

                                    {content}

                                    <ConditionallyRender
                                        shouldRender={!!actionButtonCompositions}
                                        content={<ActionButtonCompositionsWrapper>{actionButtonCompositions}</ActionButtonCompositionsWrapper>}
                                    />
                                </ContentWrapper>
                            </Container>
                        }
                    />
                </Fragment>
            }
        />
    );
};
