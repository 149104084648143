import React from "react";

import { Field } from "./styles";

interface IProps {
    value: string;
    placeholder: string;
    onChange(event: any): void;
}

const SearchField = ({ value, placeholder, onChange }: IProps) => {
    return <Field value={value} placeholder={placeholder} onChange={onChange} autoFocus />;
};

export default SearchField;
