// Dependencies
import styled from "styled-components";

// Utils
import { theme } from "config/theme";

export const ToastWrapper = styled.div`
    padding: 24px;
    border-radius: 8px;
    background-color: #fff;
    border-right: 8px solid ${theme.colors.system.info[400]};
    box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    position: absolute;
    bottom: 16px;
    right: 16px;
    left: 16px;

    z-index: 4;

    @media (min-width: 768px) {
        width: 540px;
        margin: 16px;

        bottom: 8px;
        right: 8px;
        left: unset;
    }
`;

export const ToastHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ToastTitleWrapper = styled.div`
    width: fit-content;
    display: flex;
    gap: 4px;
`;

export const ToastIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;

    border-radius: 50%;
    background: ${theme.colors.system.info[200]};
`;

export const ToastIcon = styled.img`
    width: 16px;
    height: 16px;
    flex-shrink: 0;
`;

export const ToastTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 18px;
    font-style: normal;
    color: ${theme.colors.system.info[400]};
    font-weight: 600;
    line-height: 26px;

    @media (min-width: 768px) {
        font-size: 20px;
        font-weight: 700;
        line-height: 28px;
    }
`;

export const ToastCloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
`;

export const ToastButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media (min-width: 768px) {
        flex-direction: row;
        gap: 16px;
    }
`;
