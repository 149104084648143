// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, ContentWrapper, PageTitle, EvolutionCardsWrapper } from "./performance-area.styles";

// Types
import { PerformanceAreaProps } from "./performance-area.types";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceArea: FunctionComponent<PerformanceAreaProps> = ({
    pageTitle,
    backElement,
    navigatorComposition,
    evolutionCardCompositions,
    evolutionGraphicComposition
}) => {
    return (
        <Container>
            <ContentWrapper>
                <ScreenWidthRender
                    actionAfterRenderingWidth="show"
                    renderingWidth={768}
                    content={
                        <>
                            {backElement}

                            <Spacing direction="vertical" size={8} />
                        </>
                    }
                />

                <PageTitle>{pageTitle}</PageTitle>

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={24} />} />

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={16} />} />

                {navigatorComposition}

                <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={48} />} />

                <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={24} />} />

                <EvolutionCardsWrapper>{evolutionCardCompositions}</EvolutionCardsWrapper>

                <Spacing direction="vertical" size={24} />

                {evolutionGraphicComposition}
            </ContentWrapper>
        </Container>
    );
};
