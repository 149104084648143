// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { ContentWrapper, Description, Title, TitleWrapper } from "./activity-file-wrapper.styles";

// Types
import { ActivityFileUploadProps } from "./activity-file-wrapper.types";

export const ActivityFileWrapper: FunctionComponent<ActivityFileUploadProps> = ({ description, fileInputComposition }) => {
    return (
        <ContentWrapper>
            <TitleWrapper>
                <Title>Carregue arquivo clicando no botão abaixo</Title>

                <Description>{description}</Description>
            </TitleWrapper>

            {fileInputComposition}
        </ContentWrapper>
    );
};
