import React, { memo, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

import { Creators as modalActions } from "store/ducks/modal";
import { Creators as lessonFreeCTAActions } from "store/ducks/lessonFreeCTA";
// Dependencies
import { Link } from "react-router-dom";

// Components
import Empty from "components/Empty";
import Button from "components/Button";
import Filter from "components/Filter";
import Spinner from "components/Spinner";
import PageHeader from "components/PageHeader";
import RadioGroup from "components/RadioGroup";
import FilterGroup from "components/FilterGroup";
import { Col, Grid, Row } from "components/Grid";
import ForumQuestion from "./components/question";
import UserQuestions from "./components/user-questions";

// Helpers
import sortOptions from "./utils/sort";
import useIsMobile from "hooks/use-is-mobile";
import { IForumState } from "store/interfaces/IForum";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { ISelectOption } from "interfaces/ISelectOption";
import { theme } from "config/theme";
import { isCourseFree } from "helpers/isCourseFree";

// Assets
import * as F from "./styles";
import { Card } from "screens/Projects/Home/components/Card/styles";

interface IForumHomeProps extends Pick<IForumState, "questions" | "isLoadingForum" | "isResetedRequest"> {
    isFetched: boolean;
    isUserQuestions: boolean;
    filterOptions: IFilterGroup[];
    sort?: string | string[] | null;
    onChangeUserQuestions: (value: boolean) => void;
    onFetchQuestions: (page: number) => void;
    onSelectFilter: (options: IFilterGroup[]) => void;
    onSort: (value: string) => void;
}

const ForumHome: React.FC<IForumHomeProps> = ({
    isLoadingForum,
    questions,
    filterOptions,
    isFetched,
    isResetedRequest,
    isUserQuestions,
    sort,
    onChangeUserQuestions,
    onFetchQuestions,
    onSelectFilter,
    onSort
}: IForumHomeProps) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    const handlePagination = useCallback(() => onFetchQuestions(questions.page + 1), [questions.page, onFetchQuestions]);

    const handleSort = useCallback((option: ISelectOption<string>) => onSort(option.value), [onSort]);

    const { content, isLoading, courseSlug } = useSelector(({ lessonFreeCTA, course }: IReduxStore) => ({
        content: lessonFreeCTA?.content,
        isLoading: lessonFreeCTA?.isLoading,
        courseSlug: course?.slug
    }));

    const modalCTA = useCallback(() => {
        dispatch(
            modalActions.openModal("callToAction", {
                type: "OPEN_MODAL",
                isCallToAction: true,
                linkHref: content.cta,
                imageUrl: content.duvidas
            })
        );
    }, [dispatch, content]);

    const handleModal = () => {
        if (!isCourseFree(courseSlug)) return;

        modalCTA();
    };

    useEffect(() => {
        if (isLoading && isCourseFree(courseSlug)) {
            dispatch(lessonFreeCTAActions.getLessonFreeRequest());
        }
    }, [dispatch, courseSlug, isLoading]);

    return (
        <>
            <F.ForumContentText>
                <Card>
                    <span>
                        Seja bem-vindo(a) ao Tira-dúvidas do ProMilitares! Neste espaço, você poderá enviar dúvidas relacionadas ao conteúdo de cada
                        disciplina. A sua dúvida poderá ser respondida não só pela nossa equipe de especialistas, como também pelos demais alunos da
                        plataforma, que estão liberados para interagir com o conteúdo e assim contribuir para a preparação de todo o grupo! Caso você
                        precise de informações sobre a plataforma, seus produtos ou serviços, fale com o nosso suporte{" "}
                        <strong>
                            <a href="https://atendimento.promilitares.com.br/" target="_blank" rel="noopener noreferrer">
                                clicando aqui
                            </a>
                        </strong>
                        .
                    </span>
                </Card>
            </F.ForumContentText>

            <PageHeader.Complex
                title="Tira-dúvidas"
                modalOptions={filterOptions}
                buttonNew={
                    <Button
                        as={Link}
                        to={!isCourseFree(courseSlug) ? `/app/curso/${courseSlug}/forum/nova` : `/app/curso/${courseSlug}/forum`}
                        variant="primary"
                        size={isMobile ? "smallX" : "medium"}
                        onClick={handleModal}
                    >
                        Nova dúvida
                    </Button>
                }
                onFilter={onSelectFilter}
            />

            <F.ForumHomeContainer>
                <Grid>
                    <Row>
                        <Col xs={12} md={8}>
                            {isMobile && !isCourseFree(courseSlug) && <UserQuestions isActive={isUserQuestions} onChange={onChangeUserQuestions} />}
                            <F.ForumContent>
                                <F.ForumRow>
                                    <F.ForumContentTitle>Perguntas</F.ForumContentTitle>
                                    {isMobile && <Filter label="Ordenar" withIcon={false} options={sortOptions} onChange={handleSort} />}
                                </F.ForumRow>
                            </F.ForumContent>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8}>
                            {isFetched && !isLoadingForum && !questions?.totalItems ? (
                                <Empty message="Nenhuma pergunta encontrada" />
                            ) : (
                                <F.ForumCol>
                                    {(isLoadingForum && !questions?.totalItems) || isResetedRequest ? (
                                        <Spinner fixed={false} size={20} />
                                    ) : (
                                        <>
                                            {questions?.items?.map((question) => (
                                                <ForumQuestion key={question._id} {...question} />
                                            ))}
                                        </>
                                    )}
                                </F.ForumCol>
                            )}
                            {questions.page !== questions.totalPages && (
                                <F.ForumContentButton>
                                    {!!questions?.count && (
                                        <Button
                                            variant="outline-primary"
                                            disabled={false}
                                            style={{
                                                marginTop: theme.spacing.medium
                                            }}
                                            onClick={handlePagination}
                                        >
                                            {isLoadingForum ? <Spinner fixed={false} size={10} /> : "VER MAIS"}
                                        </Button>
                                    )}
                                </F.ForumContentButton>
                            )}
                        </Col>

                        <Col xs={!isMobile} md={4}>
                            {!isCourseFree(courseSlug) && <UserQuestions isActive={isUserQuestions} onChange={onChangeUserQuestions} />}

                            <RadioGroup
                                activeItem={sort || sortOptions[0].value}
                                items={sortOptions}
                                radioVariant="brand"
                                title="Ordenar por:"
                                onClick={onSort}
                            />
                            <FilterGroup options={filterOptions} onChange={onSelectFilter} withHeader={true} />
                        </Col>
                    </Row>
                </Grid>
            </F.ForumHomeContainer>
        </>
    );
};

export default memo(ForumHome);
