import React, { useMemo } from "react";

// Assets
import { Grid, Row, Col } from "components/Grid";
import SadIcon from "prodigio-icons/web/filled/Sad";
import { BreakPoints } from "assets/styles/settings";
import * as S from "./styles";

// Components
import ExerciseListDashboardMainChart from "./components/MainChart";
import Button from "components/Button";
import ExerciseListDashboardHeader from "./components/Header";

// Helpers
import { theme } from "config/theme";
import useIsMobile from "hooks/use-is-mobile";
import { IAnswerCard } from "store/ducks/answerCard";

interface IProps {
    isLoading: boolean;
    onClickStart(): void;
    listAnswerCards: React.ReactNode;
    blockContent: boolean;
    isExtra: boolean;
    questionsTotal: number;
    hasRealizations: boolean;
    title: string;
    realizationsTotal: number;
    userRealizations: IAnswerCard[];
    realizationsAverageHitRate: number;
    unfinishedUserLists: any[];
    subject?: { name: string; slug: string };
    project?: { name: string; id: number };
    onClickdownloadPDF(): void;
    disableButtonPDF: boolean;
}

const ExerciseListDashboard = ({
    onClickStart,
    listAnswerCards,
    blockContent,
    isLoading,
    isExtra,
    questionsTotal,
    hasRealizations,
    title,
    realizationsTotal,
    userRealizations = [],
    realizationsAverageHitRate,
    subject,
    unfinishedUserLists,
    project,
    onClickdownloadPDF,
    disableButtonPDF
}: IProps) => {
    const isMobile = useIsMobile(BreakPoints.small);

    const tipDescription = useMemo(() => {
        try {
            if (!hasRealizations && unfinishedUserLists?.length === 1) {
                return "Finalize essa lista de exercício para ver seus resultados.";
            }

            if (!hasRealizations) {
                return "Separe um momento do seu dia para testar seus conhecimentos e fixar o que você aprendeu.";
            }

            if (userRealizations.length === 1) {
                return "Refaça essa Lista de Exercício para comparar seus resultados.";
            }

            throw new Error();
        } catch (error) {
            return "Refaça essa lista de exercício sempre que achar necessário e acompanhe sua evolução.";
        }
    }, [hasRealizations, unfinishedUserLists, userRealizations.length]);

    return (
        <>
            <ExerciseListDashboardHeader
                isExtra={isExtra}
                title={title}
                subject={subject}
                onClickStartList={onClickStart}
                blockContent={blockContent}
                project={project}
                onClickdownloadPDF={onClickdownloadPDF}
                disableButtonPDF={disableButtonPDF}
            />

            <Grid>
                <Row>
                    <S.ContentButtons>
                        {isMobile && !blockContent && !isExtra && (
                            <Col>
                                <Button
                                    id="download-pdf-button"
                                    variant="outline-primary"
                                    block
                                    size="medium"
                                    onClick={onClickdownloadPDF}
                                    data-test-id="exercise-list-download-pdf-button"
                                    disabled={disableButtonPDF}
                                >
                                    {disableButtonPDF ? "Exportando..." : "Imprimir lista"}
                                </Button>
                                <Button variant="primary" onClick={onClickStart} block size="medium" style={{ marginBottom: theme.spacing.small }}>
                                    {hasRealizations ? "Refazer lista" : "Resolver lista"}
                                </Button>
                            </Col>
                        )}
                    </S.ContentButtons>

                    <Col xs={12} sm={hasRealizations ? 12 : 6} style={{ marginBottom: theme.spacing.small }}>
                        {!isLoading && (
                            <ExerciseListDashboardMainChart
                                realizationsTotal={realizationsTotal}
                                questionsTotal={questionsTotal}
                                userRealizations={userRealizations}
                                realizationsAverageHitRate={realizationsAverageHitRate}
                            />
                        )}
                    </Col>

                    {!hasRealizations && (
                        <Col xs={12} sm={6} style={{ marginBottom: theme.spacing.small }} data-test-id="exercise-list-empty">
                            <S.ExerciseRealizationsEmpty>
                                <SadIcon width={32} height={32} />
                                <p>Você ainda não realizou essa lista</p>
                            </S.ExerciseRealizationsEmpty>
                        </Col>
                    )}

                    {!isExtra && !isMobile && (
                        <Col xs={12}>
                            <S.ExerciseListDashboardTip>
                                <p>{tipDescription}</p>
                            </S.ExerciseListDashboardTip>
                        </Col>
                    )}
                </Row>

                <Row>{!blockContent && listAnswerCards}</Row>

                {!isExtra && isMobile && (
                    <S.ExerciseListDashboardTip>
                        <p>{tipDescription}</p>
                    </S.ExerciseListDashboardTip>
                )}
            </Grid>
        </>
    );
};

export default React.memo(ExerciseListDashboard);
