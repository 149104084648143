// Dependencies
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SectionInformationsAndOrdenatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0;
    }
`;

export const InformationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SactionTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 768px) {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
    }
`;

export const CorrectedSubmissions = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3a3e47;
`;

export const SubmissionsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    gap: 0 8px;
    padding: 4px 16px 14px;

    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
        flex: 2;
        overflow-x: auto;
        overflow-y: auto;
        padding: 4px 8px 14px;
    }

    @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
    }

    @media (min-width: 1440px) {
        grid-gap: 32px;
    }
`;

export const LoadMoreWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
`;
