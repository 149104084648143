import React, { memo, useMemo } from "react";
import Feedback from "../Feedback";
import { ExamResultFeedBackListContainer } from "./styles";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { IExamResultKnowledgeArea } from "interfaces/IExam";
import { HitPerformance } from "enums/HitPerformance";

const ExamResultFeedBackList = memo(() => {
    const knowledgeAreas = useSelector<IReduxStore, IExamResultKnowledgeArea[]>(({ exam }) => exam?.currentExamResult?.knowledgeAreas || []);

    const { goingWell, canBeBetter, normal } = useMemo(
        () => ({
            goingWell: knowledgeAreas?.find((item) => item?.hitPerformance === HitPerformance.GoingWell)?.knowledgeArea?.name,
            canBeBetter: knowledgeAreas?.find((item) => item?.hitPerformance === HitPerformance.CanBeBetter)?.knowledgeArea?.name,
            normal: knowledgeAreas?.find((item) => item?.hitPerformance === HitPerformance.Normal)?.knowledgeArea?.name
        }),
        [knowledgeAreas]
    );

    if (!knowledgeAreas?.length || (!canBeBetter && !goingWell && !normal)) {
        return null;
    }

    return (
        <ExamResultFeedBackListContainer column>
            {goingWell && <Feedback variant="success" legend={goingWell} title="Tá indo bem:" />}
            {normal && <Feedback variant="warning" legend={normal} title="Mantenha o foco:" />}
            {canBeBetter && <Feedback variant="danger" legend={canBeBetter} title="Pode melhorar:" />}
        </ExamResultFeedBackListContainer>
    );
});

export default ExamResultFeedBackList;
