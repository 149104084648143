// Dependencies
import React, { memo } from "react";
import { useDispatch } from "react-redux";

// Assets
import close from "assets/img/close.svg";

// Styles
import {
    AttentionModalContainer,
    AttentionTitleWrapper,
    AttentionCloseIcon,
    AttentionModalTitle,
    AttentionModalLegend,
    AttentionModalButtonsWrapper
} from "./styles";
import Button from "components/Button";

interface IAttentionModalProps {
    action: {
        title: string;
        text: string | React.ReactNode;
        widthContainer?: string;
        alignText?: string;
        hasMainButton?: boolean;
        mainButtonText?: string;
        hasCancelButton?: boolean;
        cancelButtonText?: string;
        handleCancelButton?: () => void;
        handleMainButton?: () => void;
    };
}

const AttentionModal = ({ action }: IAttentionModalProps) => {
    const dispatch = useDispatch();

    const {
        text,
        title,
        widthContainer,
        alignText = "center",
        hasMainButton = false,
        mainButtonText,
        handleMainButton,
        hasCancelButton = false,
        cancelButtonText,
        handleCancelButton
    } = action;

    const handleCloseModal = () => {
        dispatch({ type: "CLOSE_MODAL" });
    };

    const handleMainButtonClicked = () => {
        handleCloseModal();

        if (handleMainButton) {
            handleMainButton();
        }
    };

    const handleCancelButtonClicked = () => {
        handleCloseModal();

        if (handleCancelButton) {
            handleCancelButton();
        }
    };

    const hasButtons = !!hasMainButton || !!hasCancelButton;

    return (
        <AttentionModalContainer width={widthContainer} alignText={alignText} data-test-id="attention-modal">
            <AttentionTitleWrapper>
                <AttentionModalTitle>{title}</AttentionModalTitle>
                <AttentionCloseIcon src={close} onClick={handleCloseModal} />
            </AttentionTitleWrapper>

            <AttentionModalLegend>{text}</AttentionModalLegend>

            {hasButtons && (
                <AttentionModalButtonsWrapper>
                    {hasMainButton && (
                        <Button variant="primary" onClick={handleMainButtonClicked} size="medium">
                            {mainButtonText}
                        </Button>
                    )}

                    {hasCancelButton && (
                        <Button variant="outline-primary" onClick={handleCancelButtonClicked} size="medium">
                            {cancelButtonText}
                        </Button>
                    )}
                </AttentionModalButtonsWrapper>
            )}
        </AttentionModalContainer>
    );
};

export default memo(AttentionModal);
