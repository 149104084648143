// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEssayCommentsProps } from "./performance-essay-comments.types";

// Styles
import {
    Container,
    SectionSpacing,
    SectionInformationsWrapper,
    SectionTitle,
    SectionDescription,
    Divider,
    CommentsWrapper
} from "./performance-essay-comments.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceEssayComments: FunctionComponent<PerformanceEssayCommentsProps> = ({
    sectionTitle,
    sectionDescription,
    commentsCompositions
}) => {
    return (
        <Container>
            <SectionSpacing>
                <SectionInformationsWrapper>
                    <SectionTitle>{sectionTitle}</SectionTitle>

                    <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="horizontal" size={8} />} />

                    <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={8} />} />

                    <SectionDescription>{sectionDescription}</SectionDescription>
                </SectionInformationsWrapper>

                <Spacing direction="vertical" size={16} />

                <Divider />

                <Spacing direction="vertical" size={16} />
            </SectionSpacing>

            <CommentsWrapper>{commentsCompositions}</CommentsWrapper>
        </Container>
    );
};
