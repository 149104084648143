import React from "react";
import * as S from "./styles";

interface IProps {
    title: string;
    subtitle: string;
}

const Header = ({ subtitle, title }: IProps) => {
    return (
        <S.ContentHeader>
            <S.Title>{title}</S.Title>
            <S.Subtitle>{subtitle}</S.Subtitle>
        </S.ContentHeader>
    );
};

export default Header;
