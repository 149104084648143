import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";
import UserIcon from "assets/img/ico-user.svg";

export const ActionButtonProfile = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: ${theme.colors.base[400]};
        color: ${theme.colors.base[400]};
    }
`;

export const MenuProfileDropdown = styled.div`
    position: absolute;
    right: 0px;
    top: 125%;
    background: ${theme.colors.white};
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;
    border-radius: 4px;
    z-index: 11;

    button {
        color: ${theme.colors.base[400]};
        display: block;
        font-size: 14px;
        padding: 10px;
        text-align: left;
        white-space: nowrap;
        width: 100%;

        :hover {
            background: ${theme.colors.base[100]};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 100%;
    }
`;

export const AvatarMenuProfile = styled.figure<{ avatar?: string }>`
    background-color: ${theme.colors.brand[100]};
    background-image: ${({ avatar }) => `url(${avatar || UserIcon})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${({ avatar }) => (avatar ? "cover" : "50%")};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
        width: 8px;
        height: 8px;
        color: ${theme.colors.base[400]};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: 32px;
        height: 32px;

        svg {
            width: 10px;
            height: 10px;
        }
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }
`;

export const AvatarMenuProfileName = styled.p`
    display: none;
    margin: 0 ${theme.spacing.smallX};

    color: ${theme.colors.base[500]};
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.medium};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        display: unset;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    max-width: 180px;
`;

export const Version = styled.p`
    border-top: 1px solid ${theme.colors.base[100]};
    color: ${theme.colors.base[400]};
    font-size: 10px;
    line-height: 16px;
    text-align: right;
    padding: 5px;
`;
