import React from "react";

// Components
import ProdigioIcon from "components/Icons/prodigioIcon";
import { IProjectTag } from "interfaces/IProjectDetails";

// Assets
import { ProjectTagsItem } from "screens/Projects/Details/styles";
import { ProjectTagsContainer } from "./styles";

interface IProjectTagsProps {
    tags: IProjectTag[];
}

const ProjectTags = ({ tags }: IProjectTagsProps) => (
    <ProjectTagsContainer>
        {tags.map((tag: any) => {
            const [folder, name] = tag.icon.split("-");

            return (
                <ProjectTagsItem key={tag.id}>
                    {folder && name && <ProdigioIcon folder={folder} name={name} />}
                    <p>{tag.name}</p>
                </ProjectTagsItem>
            );
        })}
    </ProjectTagsContainer>
);

export default ProjectTags;
