import { ActionCreatorsMapObject, Action } from "redux";

export interface INotificationState {
    isLoading: boolean;
    notifications: INotifications;
}

export interface INotifications {
    all: INotification[];
    notRead: INotification[];
}

export interface INotification {
    id: string;
    message: string;
    date: string;
    ctaLabel: string;
    status: number;
    thumb?: string;
    badge?: string;
    metadata: IMetadata;
}

export interface IMetadata {
    IdPostParent: string;
    reference: string;
    referenceSlug: string;
    IdVideo: number;
}

export const NotificationStatus = {
    new: 1,
    viewed: 2,
    read: 3
};

export type NotificationActionsType =
    | "GET_NOTIFICATIONS_REQUEST"
    | "GET_NOTIFICATIONS_SUCCESS"
    | "GET_NOTIFICATIONS_FAILURE"
    | "SET_READ_ALL_NOTIFICATIONS_REQUEST"
    | "SET_READ_ALL_NOTIFICATIONS_FAILURE"
    | "SET_READ_ALL_NOTIFICATIONS_SUCCESS"
    | "SET_VIEWED_ALL_NOTIFICATIONS_REQUEST"
    | "SET_VIEWED_ALL_NOTIFICATIONS_FAILURE"
    | "SET_VIEWED_ALL_NOTIFICATIONS_SUCCESS"
    | "SET_READ_BY_ID_NOTIFICATION_REQUEST"
    | "SET_READ_BY_ID_NOTIFICATION_FAILURE"
    | "SET_READ_BY_ID_NOTIFICATION_SUCCESS";

export interface INotificationActions {
    getNotificationsRequest(): Action<void>;
    getNotificationsSuccess(payload: INotifications): Action<void>;
    getNotificationsFailure(): Action<void>;

    setReadAllNotificationsRequest(): Action<void>;
    setReadAllNotificationsSuccess(): Action<void>;
    setReadAllNotificationsFailure(): Action<void>;

    setViewedAllNotificationsRequest(): Action<void>;
    setViewedAllNotificationsSuccess(): Action<void>;
    setViewedAllNotificationsFailure(): Action<void>;

    setReadByIdNotificationRequest(payload: { notificationId: string }): Action<void>;
    setReadByIdNotificationSuccess(): Action<void>;
    setReadByIdNotificationFailure(): Action<void>;
}

export interface INotificationCreators extends INotificationActions, ActionCreatorsMapObject {}
