import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";

// Dependencies
import ReactDOM from "react-dom";

// Components
import App from "./App";

// Assets
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const render = (Component: any) => {
    return ReactDOM.render(<Component />, document.getElementById("root"));
};

render(App);

if ((module as any).hot) {
    (module as any).hot.accept("./App", () => {
        const NextApp = require("./App").default;
        render(NextApp);
    });
}
