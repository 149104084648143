// Dependencies
import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

// Components
import Spinner from "components/Spinner";

// Helpers
import { useQueryString } from "hooks/use-query-string";
import { Creators as authActions } from "store/ducks/auth";

const Layers = () => {
    const dispatch = useDispatch();

    const { layers_session, layers_user_id, layers_community_id } = useQueryString();

    useEffect(() => {
        dispatch(
            authActions.layersRequest({
                session: layers_session as string,
                user_id: layers_user_id as string,
                community_id: layers_community_id as string
            })
        );
    }, [dispatch, layers_community_id, layers_session, layers_user_id]);

    return <Spinner />;
};

export default memo(Layers);
