// Dependencies
import styled, { css } from "styled-components";

export const AreaCardWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    transition: 0.2s all ease-in-out;
`;

type ContainerStyleProps = {
    hoverColor: string;
};

export const Container = styled.div<ContainerStyleProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    :hover {
        ${AreaCardWrapper} {
            ${({ hoverColor }) => css`
                background-color: ${hoverColor};

                .pdg-radial > div {
                    background-color: ${hoverColor};
                }
            `}
        }
    }
`;

export const AreaCardHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

export const AreaCardHeader = styled.div`
    display: flex;
    align-items: center;
`;

type IconWrapperStyleProps = {
    backgroundColor: string;
};

export const IconWrapper = styled.div<IconWrapperStyleProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 40px;
    height: 40px;
    border-radius: 4px;

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}

    @media (min-width: 1024px) {
        width: 48px;
        height: 48px;
    }
`;

export const Icon = styled.img`
    width: 24px;
    height: 24px;

    @media (min-width: 1024px) {
        width: 32px;
        height: 32px;
    }
`;

export const AreaCardTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #3a3e47;

    @media (min-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

export const AreaCardContentWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const AreaCardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const AreaCardQuestionsInfoWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (min-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const AreaCardQuestionsInfo = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505462;
    white-space: nowrap;
`;

type BadgeTooltipStyleProps = {
    isVisible: boolean;
};

export const BadgeTooltip = styled.div<BadgeTooltipStyleProps>`
    display: ${({ isVisible }) => (isVisible ? "flex" : "none")};

    position: absolute;
    right: 24px;
    top: -16px;

    z-index: 2;

    @media (min-width: 768px) {
        top: unset;
        right: -37px;
        bottom: 18px;
    }
`;

export const Badge = styled.div`
    position: relative;

    @media (min-width: 1024px) {
        &:hover {
            ${BadgeTooltip} {
                display: flex;
            }
        }
    }
`;

type AreaCardBorderStyleProps = {
    backgroundColor: string;
};

export const AreaCardBorder = styled.div<AreaCardBorderStyleProps>`
    width: 100%;
    height: 8px;
    background: #4186a0;
    border-radius: 0px 0px 8px 8px;

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}
`;

// ----------

export const ContainerSkeleton = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const IconWrapperSkeleton = styled.div`
    flex-shrink: 0;
    width: 40px;
    height: 40px;

    @media (min-width: 1024px) {
        width: 48px;
        height: 48px;
    }
`;

export const RadialElementWrapperSkeleton = styled.div`
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    @media (min-width: 1024px) {
        width: 30px;
        height: 30px;
    }
`;

export const ActionIconWrapperSkeleton = styled.div`
    width: 16px;
    height: 16px;

    @media (min-width: 1024px) {
        width: 20px;
        height: 20px;
    }
`;

export const AreaCardTitleSkeleton = styled.div`
    height: 26px;
    padding: 4px 0;

    @media (min-width: 1024px) {
        height: 28px;
    }
`;

export const AreaCardQuestionsInfoSkeleton = styled.div`
    height: 20px;
    padding: 3px 0;
`;
