import React, { memo, useCallback } from "react";

// Dependencies
import _findIndex from "lodash/findIndex";
import { string, object } from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import getCep from "cep-promise";
import { UFOptions } from "utils/UFOptions";

// Components
import Button from "components/Button";
import TextField from "components/Form/TextField";
import ZipCodeDropdown from "components/Dropdown/zip";
import DropdownIndicator from "screens/MigrationUser/components/indicator";

// Helpers
import { MASK_ZIPCODE } from "helpers";
import { IAddress } from "interfaces/IAddress";

// Assets
import { Col, Row } from "components/Grid";
import { OrderAddressContainer, SelectWrapper } from "./styles";

export type AddressState = Omit<IAddress, "id" | "observation">;

interface IOrderAddressProps {
    isLoading: boolean;
    onSubmit: (address: AddressState) => void;
}

const ZIP_CODE_REGEX = /^\d{5}-\d{3}$/;

const schema = object().shape({
    city: string().required("Campo obrigatório"),
    zipCode: string()
        .matches(ZIP_CODE_REGEX, "CEP Inválido")
        .required("Campo obrigatório"),
    street: string().required("Campo obrigatório"),
    number: string().required("Campo obrigatório"),
    neighborhood: string().required("Campo obrigatório"),
    complement: string().nullable(),
    state: string().required("Campo obrigatório")
});

const OrderAddress = ({ isLoading, onSubmit }: IOrderAddressProps) => {
    const { control, handleSubmit, errors, formState, setValue, reset } = useForm<AddressState>({
        mode: "all",
        resolver: yupResolver(schema)
    });

    const handleSelectItem = useCallback((value: string) => {
        return _findIndex(UFOptions, ["value", value]);
    }, []);

    const handleGetAddress = useCallback(
        async (event: React.FocusEvent<HTMLInputElement>) => {
            const inputCep = event.target.value;

            if (ZIP_CODE_REGEX.test(inputCep)) {
                try {
                    const data = await getCep(inputCep);

                    setValue("city", data.city, { shouldValidate: true, shouldDirty: true });
                    setValue("neighborhood", data.neighborhood, { shouldValidate: true, shouldDirty: true });
                    setValue("street", data.street, { shouldValidate: true, shouldDirty: true });
                    setValue("state", data.state, { shouldValidate: true, shouldDirty: true });
                } catch (error) {
                    reset();
                    console.log(error);
                }
            }
        },
        [reset, setValue]
    );

    return (
        <OrderAddressContainer>
            <Row>
                <Col xs={12} sm={3}>
                    <Controller
                        control={control}
                        name="zipCode"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <TextField
                                label="CEP"
                                onBlur={handleGetAddress}
                                mask={MASK_ZIPCODE}
                                error={errors.zipCode?.message}
                                rightSideElement={<ZipCodeDropdown />}
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </Col>
                <Col xs={12} sm={9}>
                    <Controller
                        control={control}
                        name="street"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <TextField label="Logradouro" error={errors.street?.message} value={value} onChange={onChange} />
                        )}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Controller
                        control={control}
                        name="number"
                        defaultValue=""
                        render={({ onChange, value }) => <TextField label="Nº" error={errors.number?.message} value={value} onChange={onChange} />}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="complement"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <TextField label="Complemento" error={errors.complement?.message} value={value} onChange={onChange} />
                        )}
                    />
                </Col>
                <Col xs={12} sm={3}>
                    <Controller
                        control={control}
                        name="neighborhood"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <TextField label="Bairro" error={errors.neighborhood?.message} value={value} onChange={onChange} />
                        )}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="city"
                        defaultValue=""
                        render={({ onChange, value }) => <TextField label="Cidade" error={errors.city?.message} value={value} onChange={onChange} />}
                    />
                </Col>
                <Col xs={12} sm={6}>
                    <Controller
                        control={control}
                        name="state"
                        defaultValue=""
                        render={({ onChange, value }) => (
                            <SelectWrapper
                                name="state"
                                components={{ DropdownIndicator }}
                                error={errors.state?.message}
                                placeholder="UF"
                                options={UFOptions}
                                value={UFOptions[handleSelectItem(value)]}
                                onChange={(item: any) => onChange(item.value)}
                            ></SelectWrapper>
                        )}
                    />
                </Col>
                <Col xs={12}>
                    <Button
                        disabled={isLoading || !formState.isValid}
                        size="medium"
                        variant="info"
                        block={true}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {isLoading ? "Enviando..." : "Adicionar endereço"}
                    </Button>
                </Col>
            </Row>
        </OrderAddressContainer>
    );
};

export default memo(OrderAddress);
