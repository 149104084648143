import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled from "styled-components";

import playIcon from "static/playIcon.svg";
import { BodyText2 } from "assets/styles/global";

export const WatchScreenRelatedVideoContainer = styled.div``;

export const WatchScreenRelatedVideoItem = styled.button`
    font-size: ${theme.typography.sizes.smallX};
    display: flex;
    width: 100%;
    background: transparent;
    border: none;
    text-align: left;

    img {
        width: 100%;
    }

    strong {
        color: ${theme.colors.base[300]};
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};
    }
`;

export const WatchScreenRelatedVideoTitle = styled.strong`
    display: block;
    margin-bottom: ${theme.spacing.small};

    color: ${theme.colors.base[500]};
    font-weight: 600;
    font-size: ${theme.typography.sizes.mediumX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 0;
    }
`;

export const WatchScreenRelatedVideoDetails = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    > svg {
        position: absolute;
        right: 5px;
        top: 5px;
    }
`;

export const WatchScreenRelatedVideoName = styled.p`
    ${BodyText2};
    color: ${theme.colors.base[500]};
`;

export const WatchScreenRelatedSubjectName = styled.strong`
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: ${theme.spacing.smallX};
`;

export const WatchScreenRelatedVideoImage = styled.div`
    position: relative;
    display: block;
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: ${theme.colors.base[500]};
    margin-right: ${theme.spacing.smallX};
    width: 120px;

    &::before {
        background-size: 60%;
        background-position-x: calc(100% + 15px);
        background-position-y: -15px;
        display: block;
        content: "";
        padding-top: 56.25%;
        z-index: -1;
    }

    span {
        height: 100%;
        width: 100%;
        background: url(${playIcon}) no-repeat center center;
        right: 0;
        top: 0;
        position: absolute;
    }
`;

export const WatchScreenRelatedVideoWrapper = styled.div``;
