// Dependencies
import styled, { css } from "styled-components";
import { ActivityBreadcrumbItemSize } from "./activity-breadcrumbs.types";

export const BreadcrumbItemCompositionsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const BreadcrumbDividerIcon = styled.img`
    width: 12px;
    height: 12px;
    flex-shrink: 0;
`;

type BreadcrumbItemContainerStyleProps = {
    hasCursorPointer: boolean;
};

export const BreadcrumbItemContainer = styled.div<BreadcrumbItemContainerStyleProps>`
    display: flex;
    align-items: center;
    gap: 8px;

    ${({ hasCursorPointer }) =>
        !!hasCursorPointer &&
        css`
            cursor: pointer;
        `}
`;

type BreadcrumbItemIconStyleProps = {
    size: ActivityBreadcrumbItemSize;
};

export const BreadcrumbItemIcon = styled.img<BreadcrumbItemIconStyleProps>`
    ${({ size }) =>
        size === "small" &&
        css`
            width: 16px;
            height: 16px;
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            width: 24px;
            height: 24px;
        `}
`;

type BreadcrumbItemLabelStyleProps = {
    isActive: boolean;
    size: ActivityBreadcrumbItemSize;
};

export const BreadcrumbItemLabel = styled.p<BreadcrumbItemLabelStyleProps>`
    ${({ isActive }) =>
        !!isActive &&
        css`
            color: #24388c;
        `}

    ${({ isActive }) =>
        !isActive &&
        css`
            color: #4d4d63;
        `}

    ${({ size }) =>
        size === "small" &&
        css`
            font-family: "Nunito Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        `}

    ${({ size }) =>
        size === "large" &&
        css`
            font-family: "Nunito", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
        `}
`;
