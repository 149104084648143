import api from "../../services/api";
import _keys from "lodash/keys";
import _slice from "lodash/slice";
import _assign from "lodash/assign";
import history from "../../services/history";
import { Creators as EssayActions, Types as EssayTypes } from "store/ducks/essay";
import { Creators as alertActions } from "../ducks/alert";
import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { ParsedQuery } from "query-string";
import { ReduxAction } from "store";
import { AxiosResponse } from "axios";
import IPagination from "interfaces/IPagination";
import { IEssayFiltersItem, IEssayCreate, IEssayComposition } from "store/interfaces/IEssay";
import { EssayStatus } from "enums/EssayStatus";
import { IReduxStore } from "interfaces/IReduxStore";

function* getAllThemes(action: ReduxAction<{ page: number; query?: ParsedQuery<any>; isResetedRequest?: boolean; perPage?: number }>) {
    try {
        const { isResetedRequest, page, query, perPage } = action.payload;

        const whereClause = query || {};

        const where = _keys(whereClause)
            .filter((item) => item !== "sort")
            .filter((item) => item !== "idTheme")
            .map((item) => {
                if (item.includes("subject.id")) {
                    return `${item}=[${whereClause[item]}]`;
                }

                return `${item}=${whereClause[item]}`;
            })
            .join(";");

        const headers = {
            "X-Relations": "contest;genre;category;contest.competences;competences.categories;categories.competenceComments",
            "X-Page": page || 0,
            "X-PerPage": perPage || 10,
            "X-Order": query?.sort || "-idTheme",
            "X-Where": where,
            ...(!!query?.idTheme && {
                "X-Types": query.idTheme
            })
        };

        const { data: themes } = yield call(api.get, "/student/essay/theme", { headers });

        const { data: balance } = yield call(api.get, "/student/essay/balance");

        const data = _assign(themes, balance, { isResetedRequest });

        yield put(EssayActions.getEssayThemesSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter os temas disponíveis.", "danger"));
        yield put(EssayActions.getEssayThemesFailure(error?.response?.data));
    }
}

function* getTheme(action: ReduxAction<{ id: string }>) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const { data: theme } = yield call(api.get, `/student/essay/theme/${action.payload.id}`);
        const { data: stats } = yield call(api.get, `/student/essay/theme/${action.payload.id}/stats`);

        const data = _assign(theme, { stats: stats });

        yield put(EssayActions.getEssayThemeSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter o tema.", "danger"));
        yield put(EssayActions.getEssayThemeFailure(error?.response?.data));
    }
}

function* getAllCompositions(action: ReduxAction<{ page: number; query?: ParsedQuery<any>; isResetedRequest?: boolean; isFilterChart?: number }>) {
    try {
        const { page, query, isResetedRequest, isFilterChart } = action.payload;

        const whereClause = query || {};

        const where = _keys(whereClause)
            .filter((item) => item !== "sort")
            .map((item) => {
                if (item.includes("contest")) {
                    return `theme.contest.idContest=[${whereClause[item]}]`;
                }
                return `${item}=${whereClause[item]}`;
            })
            .join(";");

        const { data: compositions } = yield call(api.get, `/student/essay/composition`, {
            headers: {
                "x-Relations": "student;theme;revisions;theme.contest",
                "X-Page": page || 0,
                "X-Perpage": 21,
                "X-Where": where,
                "X-Order": query?.sort || "created"
            }
        });

        const { data: fiftyCompositions } = yield call(api.get, `/student/essay/composition`, {
            headers: {
                "x-Relations": "student;student.user;theme;theme.genre;theme.category;revisions",
                "X-Page": 0,
                "X-Perpage": 35,
                "X-Where": !!isFilterChart ? `theme.contest.idContest=[${isFilterChart}]` : {},
                "X-Order": "-created"
            }
        });

        const lastCompositions = fiftyCompositions?.items?.filter((composition: IEssayComposition) => composition.status === EssayStatus.Corrected);

        const data = _assign(compositions, { isResetedRequest }, { latestCompositions: _slice(lastCompositions, 0, 5) });

        yield put(EssayActions.getEssayCompositionsSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter os minhas redações.", "danger"));
        yield put(EssayActions.getEssayCompositionsFailure(error?.response?.data));
    }
}

function* getComposition(action: ReduxAction<{ id: string; getTheme: boolean }>) {
    try {
        if (!action?.payload?.id) {
            throw new Error();
        }

        const [composition, comments, competences]: Array<AxiosResponse> = yield all([
            call(api.get, `/student/essay/composition/${action.payload.id}`),
            call(api.get, `/student/essay/composition/${action.payload.id}/revision/comments`),
            call(api.get, `/student/essay/composition/${action.payload.id}/revision/competences`)
        ]);

        if (action?.payload?.getTheme) {
            yield put(EssayActions.getEssayThemeRequest({ id: composition.data.idTheme }));
        }

        const data = _assign(composition.data, comments.data, { competences: competences.data });

        yield put(EssayActions.getEssayCompositionSuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível obter minha redação.", "danger"));
        yield put(EssayActions.getEssayCompositionFailure(error?.response?.data));
    }
}

function* getFilters() {
    try {
        const [category, genre, year, contest]: Array<AxiosResponse<IPagination<IEssayFiltersItem>>> = yield all([
            call(api.get, "/student/essay/category", { headers: { "x-page": 1, "x-perpage": 1000 } }),
            call(api.get, "/student/essay/genre", { headers: { "x-page": 1, "x-perpage": 1000 } }),
            call(api.get, "/student/essay/year", { headers: { "x-page": 1, "x-perpage": 1000 } }),
            call(api.get, "/student/essay/contest", { headers: { "x-page": 0, "x-perpage": 1000 } })
        ]);

        const filters = [
            {
                id: 1,
                title: "Categorias",
                slug: "idCategory",
                items: category.data.items.map((item) => ({ value: item.id, label: item.name }))
            },
            {
                id: 2,
                title: "Gênero Textual",
                slug: "idGenre",
                items: genre.data.items.map((item) => ({ value: item.id, label: item.name }))
            },
            {
                id: 3,
                title: "Temas",
                slug: "idTheme",
                items: [
                    { value: 100, label: "Nunca Feitos" },
                    { value: 3, label: "Corrigidos" },
                    { value: 1, label: "Enviados" },
                    { value: 2, label: "Em Correção" }
                ]
            },
            {
                id: 4,
                title: "Ano",
                slug: "year",
                items: year.data.items.map((item) => ({ value: item.id, label: item.name }))
            },
            {
                id: 5,
                title: "Concurso",
                slug: "idContest",
                items: contest.data.items.map((item) => ({ value: item.id, label: item.name }))
            }
        ];

        yield put(EssayActions.getEssayFiltersSuccess(filters));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível os filtros de temas.", "danger"));
        yield put(EssayActions.getEssayFiltersFailure(error?.response?.data));
    }
}

function* createEssayImage(action: ReduxAction<{ image: string; data: IEssayCreate; idComposition: number }>) {
    try {
        const { data: Image }: AxiosResponse = yield call(api.post, "/student/essay/composition/create-image", { image: action.payload.image });

        const essay = {
            ...action.payload.data,
            pathOriginal: Image.file,
            path: Image.file
        };

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);

        if (~~action?.payload?.idComposition) {
            const { data: dataEssay }: AxiosResponse = yield call(api.put, `student/essay/composition/${action.payload.idComposition}`, essay);
            yield call(history.push, { pathname: `/app/curso/${courseSlug}/redacoes/envios/${dataEssay.idComposition}` });
        } else {
            const { data: dataEssay }: AxiosResponse = yield call(api.post, "student/essay/composition", essay);

            const url = essay.status === EssayStatus.Draft ? dataEssay.idComposition : "";
            yield call(history.push, { pathname: `/app/curso/${courseSlug}/redacoes/envios/${url}` });
        }

        yield put(EssayActions.createEssayImageSuccess(Image));
        yield put(alertActions.showAlert("Sua redação foi salva!", "success"));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar redação.", "danger"));
        yield put(EssayActions.createEssayImageFailure(error?.response?.data));
    }
}

function* createEssayFile(action: ReduxAction<{ files: File[]; data: IEssayCreate; idComposition: number }>) {
    try {
        const formData = new FormData();
        formData.append("file", action.payload.files[0]);

        const { data: fileUrl }: AxiosResponse = yield call(api.post, "/student/essay/upload", formData, {
            headers: { "X-Uploadtype": "composition" }
        });

        const essay = {
            ...action.payload.data,
            pathOriginal: fileUrl.file,
            path: fileUrl.file
        };

        const { idComposition } = action.payload;

        const { data: dataEssay } = yield api({
            method: !!idComposition ? "put" : "post",
            data: essay,
            url: `/student/essay/composition${!!idComposition ? `/${idComposition}` : ""}`
        });

        if (!dataEssay?.idComposition) {
            throw new Error();
        }

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, { pathname: `/app/curso/${courseSlug}/redacoes/envios/${dataEssay.idComposition}` });

        yield put(EssayActions.createEssayFileSuccess(Image));
        yield put(alertActions.showAlert("Sua redação foi salva!", "success"));
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível salvar redação.", "danger"));
        yield put(EssayActions.createEssayFileFailure(error?.response?.data));
    }
}

function* updateEssayStatus(action: ReduxAction<{ id: string; status: number }>) {
    try {
        const { id, status } = action.payload;

        yield call(api.patch, `/student/essay/composition/${id}/status/${status}`);

        yield put(EssayActions.updateEssayStatusSuccess());
        yield put(alertActions.showAlert("Sua redação foi atualizada!", "success"));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, { pathname: `/app/curso/${courseSlug}/redacoes/envios` });
    } catch (error) {
        yield put(alertActions.showAlert("Não foi possível atualizar redação.", "danger"));
        yield put(EssayActions.updateEssayStatusFailure(error?.response?.data));
    }
}

export default [
    takeLatest(EssayTypes.GET_ESSAY_THEMES_REQUEST, getAllThemes),
    takeLatest(EssayTypes.GET_ESSAY_THEME_REQUEST, getTheme),
    takeLatest(EssayTypes.GET_ESSAY_COMPOSITIONS_REQUEST, getAllCompositions),
    takeLatest(EssayTypes.GET_ESSAY_COMPOSITION_REQUEST, getComposition),
    takeLatest(EssayTypes.GET_ESSAY_FILTERS_REQUEST, getFilters),
    takeLatest(EssayTypes.CREATE_ESSAY_IMAGE_REQUEST, createEssayImage),
    takeLatest(EssayTypes.CREATE_ESSAY_FILE_REQUEST, createEssayFile),
    takeLatest(EssayTypes.UPDATE_ESSAY_STATUS_REQUEST, updateEssayStatus)
];
