import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const ContentModal = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.5);
`;
