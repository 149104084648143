import React, { useCallback, useState } from "react";

import {
    ExamResultDesktopResolutionsDropdownContainer as Container,
    ExamResultDesktopResolutionsDropdownActiveItem as ActiveItem,
    ExamResultDesktopResolutionsDropdownList as List,
    ExamResultDesktopResolutionsDropdownListItem as ListItem
} from "./styles";
import { IExamResolution } from "interfaces/IExam";
import { DateTime } from "luxon";

import ChevronDown from "prodigio-icons/web/outlined/ChevronDown";
import formatDate from "utils/formatDate";

interface IProps {
    resolutions: IExamResolution[];
    currentResolution?: IExamResolution;
    onChangeResolution(resolutionId: number): void;
}

const ExamResultDesktopResolutionsDropdown: React.FC<IProps> = ({ resolutions = [], currentResolution, onChangeResolution }) => {
    const [activeDropdown, setActiveDropdown] = useState(false);

    const formatResolutionDate = useCallback((date?: string) => {
        const defaultText = " - ";

        if (!date || !DateTime.fromISO(date).isValid) {
            return defaultText;
        }

        return formatDate(date, "dd/MM/yyyy");
    }, []);

    const formatResolutionText = useCallback(
        (resolution: IExamResolution) => `Realização ${resolution.attempt} - ${formatResolutionDate(resolution?.created)}`,
        [formatResolutionDate]
    );

    const handleClick = useCallback(
        (resolutionId: number) => {
            setActiveDropdown((old) => !old);

            onChangeResolution(resolutionId);
        },
        [onChangeResolution]
    );

    if (!currentResolution?.id) {
        return null;
    }

    const isSingleResolution = resolutions?.length === 1;

    return (
        <Container>
            <div>
                <ActiveItem onClick={() => setActiveDropdown((old) => !old)} active={activeDropdown}>
                    {formatResolutionText(currentResolution)} {!isSingleResolution && <ChevronDown width={8} height={4} />}
                </ActiveItem>

                {!isSingleResolution && activeDropdown && (
                    <List>
                        {resolutions
                            ?.filter(({ id }) => id !== currentResolution?.id)
                            ?.map((resolution) => (
                                <ListItem to="#" key={resolution.id} onClick={() => handleClick(resolution.id)}>
                                    {formatResolutionText(resolution)}
                                </ListItem>
                            ))}
                    </List>
                )}
            </div>
        </Container>
    );
};

export default ExamResultDesktopResolutionsDropdown;
