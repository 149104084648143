import { theme } from "config/theme";
import styled, { css } from "styled-components";
import { FlatVariant } from "utils/types/Variant";

type ColorVariants = 100 | 200 | 300 | 400 | 500;

interface ITooltipStyleProps {
    variant: FlatVariant | "base" | "secondary";
    variantColor: ColorVariants;
    direction: "top" | "right" | "left" | "bottom";
    rounded: boolean;
}

const getColor = (variant: FlatVariant | "base" | "secondary", color: ColorVariants) => {
    const colors = {
        success: theme.colors.system.success,
        danger: theme.colors.system.danger,
        info: theme.colors.system.info,
        warning: theme.colors.system.warning,
        base: theme.colors.base,
        secondary: theme.colors.secondary
    };

    return variant === "base" ? colors[variant][100] : colors[variant][color];
};

export const TooltipContainer = styled.span<ITooltipStyleProps>`
    background-color: ${({ variant, variantColor }) => getColor(variant, variantColor)};
    border-radius: 4px;
    color: ${({ variant }) => getColor(variant, 500)};
    left: 50%;
    letter-spacing: 0.2px;
    padding: ${theme.spacing.smallXX} ${theme.spacing.small};
    position: absolute;
    white-space: nowrap;
    transform: translateX(-50%);
    z-index: 2;
    text-align: center;
    box-shadow: ${theme.shadow[1]};

    font-size: ${theme.typography.sizes.small};
    font-weight: 600;

    &:after {
        content: "";
        height: 0;
        position: absolute;
        width: 0;
    }

    ${({ direction, variant, variantColor }) => {
        if (direction === "left") {
            return css`
                bottom: 50%;
                left: auto;
                right: calc(100% + 10px);
                transform: translateY(50%);

                &:after {
                    border-top: 7px solid transparent;
                    border-left: 5px solid ${getColor(variant, variantColor)};
                    border-bottom: 7px solid transparent;
                    right: -4px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                }
            `;
        }

        if (direction === "right") {
            return css`
                bottom: 50%;
                left: calc(100% + 10px);
                right: auto;
                transform: translateY(50%);

                &:after {
                    border-top: 7px solid transparent;
                    border-right: 5px solid ${getColor(variant, variantColor)};
                    border-bottom: 7px solid transparent;
                    left: -4px;
                    position: absolute;
                    transform: translateY(-50%);
                    top: 50%;
                }
            `;
        }

        if (direction === "bottom") {
            return css`
                left: 50%;
                transform: translateX(-50%);
                top: calc(100% + 10px);

                &:after {
                    border-left: 7px solid transparent;
                    border-right: 7px solid transparent;
                    border-bottom: 5px solid ${getColor(variant, variantColor)};
                    left: 50%;
                    position: absolute;

                    bottom: -4px;
                    transform: rotate(180deg) translateX(50%);
                }
            `;
        }

        return css`
            bottom: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);

            &:after {
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-top: 5px solid ${getColor(variant, variantColor)};
                bottom: -4px;
                left: 50%;
                position: absolute;
                transform: translateX(-50%);
            }
        `;
    }}
`;
