import { hexToRgba } from "helpers";
import ScrollArea from "react-scrollbar";
import { theme, menuColors } from "config/theme";
import styled, { css } from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { slideRight } from "assets/styles/animations";

interface IMenuStyleProps {
    visible?: boolean;
    expanded: boolean;
}

export const MenuWrapper = styled.aside<IMenuStyleProps>`
    background: ${hexToRgba(theme.colors.base[500], 0.32)};
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;

    ${({ expanded }) => css`
        ${MediaQueries.BIGGER_THAN_MEDIUM} {
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            position: relative;
            transition: width 0.5s;
            width: ${expanded ? "280px" : "70px"};
        }
    `};
`;

export const MenuContainer = styled.div`
    animation: ${slideRight} ease 0.2s;
    background: ${menuColors.background || theme.colors.white};
    height: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 80%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        width: 280px;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        position: unset;
        width: 100%;
    }
`;

type MenuHeaderStyleProps = {
    isExpanded: boolean;
};

export const MenuHeader = styled.header<MenuHeaderStyleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    padding: 32px;

    @media (min-width: 1024px) {
        ${({ isExpanded }) =>
            !isExpanded &&
            css`
                padding: 32px 8px;
            `}
    }
`;

type MenuLogoStyleProps = {
    isExpanded: boolean;
};

export const MenuLogo = styled.img<MenuLogoStyleProps>`
    overflow: auto;
    max-height: 56px;
    max-width: 216px;
    height: 100%;

    @media (min-width: 1024px) {
        ${({ isExpanded }) =>
            !isExpanded &&
            css`
                max-width: 54px;
            `}
    }
`;

export const MenuScrollable = styled(ScrollArea)`
    height: 100%;
    z-index: 5;

    .scrollarea-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
    }
`;

export const MenuContent = styled.div`
    flex: 1;
    overflow-y: overlay;
`;

export const MenuCloseHelper = styled.div`
    flex: 1;
`;
