// Dependencies
import React from "react";

// Styles
import { IconWrapper } from "components/SignScreen/styles";

interface IRocketProps {
    color: string;
}

export const Rocket = ({ color }: IRocketProps) => (
    <IconWrapper>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.56009 17.7397C5.51844 17.7397 4.47678 17.7468 3.43584 17.7376C2.60933 17.7297 2.13501 17.3554 2.03986 16.5813C2.00791 16.3192 2.07039 15.9974 2.20317 15.7694C3.19229 14.0733 4.17927 12.3737 5.23015 10.716C6.00341 9.49574 7.21193 9.01348 8.62636 9.00993C10.082 9.00638 11.5376 9.00354 12.9932 9.0149C13.2346 9.01703 13.3767 8.93961 13.5066 8.74003C14.517 7.19593 15.6581 5.75624 17.1677 4.6731C18.9371 3.40387 20.8777 2.50114 23.0618 2.20283C24.4131 2.01816 25.7693 1.92512 27.1298 2.07356C27.6325 2.12825 28.1381 2.21064 28.6266 2.33849C29.2635 2.5054 29.6285 2.99406 29.7343 3.6063C30.4173 7.5468 29.8166 11.243 27.526 14.5826C26.4808 16.1068 25.1026 17.3015 23.556 18.2908C23.1215 18.5693 22.9752 18.8654 22.9901 19.3704C23.0285 20.6482 22.9859 21.9281 23.0065 23.2065C23.0349 24.9509 22.3262 26.2464 20.7961 27.12C19.317 27.9645 17.8678 28.8609 16.4107 29.7416C16.0252 29.9746 15.619 30.0641 15.1887 29.9518C14.6114 29.802 14.2791 29.3282 14.2777 28.6684C14.2727 26.6555 14.277 24.6427 14.2749 22.6298C14.2727 20.4706 13.2389 18.874 11.2344 18.0835C10.6664 17.8597 10.0174 17.7887 9.39962 17.7539C8.45595 17.7006 7.5066 17.7397 6.56009 17.7397ZM22.9972 6.80529C21.761 6.81382 20.811 7.76272 20.8117 8.98933C20.8117 10.2081 21.82 11.2146 23.0242 11.1982C24.2334 11.1819 25.1991 10.2096 25.2098 8.99856C25.2211 7.76414 24.2455 6.79677 22.9979 6.80529H22.9972Z"
                fill="white"
            />
            <path
                d="M2.00012 27.8821C2.15562 26.2762 2.34591 24.6753 2.93384 23.1639C3.51822 21.661 4.44343 20.4493 6.01692 19.8328C8.30614 18.935 11.0846 20.0665 12.0482 22.3357C12.8683 24.2676 12.5594 26.0532 11.189 27.6151C10.5932 28.2948 9.78661 28.7401 8.93383 29.0491C7.20768 29.6755 5.41408 29.9589 3.58284 29.9909C2.62 30.0086 1.99088 29.3445 2.00012 28.3786C2.00154 28.2131 2.00012 28.0476 2.00012 27.8821ZM7.49739 23.002C6.75254 23.0154 6.25905 23.2953 5.95798 23.8955C5.66047 24.4899 5.54828 25.127 5.54757 25.7826C5.54757 26.1846 5.74283 26.4112 6.14331 26.441C6.91301 26.4985 7.65289 26.3522 8.31324 25.9438C8.90046 25.5802 9.14543 24.7918 8.9317 24.0979C8.72153 23.4146 8.14496 22.9991 7.49668 23.002H7.49739Z"
                fill={color}
            />
        </svg>
    </IconWrapper>
);
