import React, { useMemo, memo } from "react";

import { IEssayTheme } from "store/interfaces/IEssay";
import formatNumber from "utils/formatNumber";
import formatDate from "utils/formatDate";
import Difficulty from "../../themes/components/icoDifficulty";

import * as S from "./styles";

interface IEssayCardDetailsProps extends Omit<IEssayTheme, "essays"> {
    withHelpers?: boolean;
}

const EssayCardDetails = ({ withHelpers = true, ...rest }: IEssayCardDetailsProps) => {
    const creationDate = useMemo(() => formatDate(rest.created, "dd/LL/yyyy"), [rest.created]);

    const getStatsTotal = useMemo(() => {
        const { corrected, correctorPending, discarded, pending } = rest.stats;
        const total = corrected + correctorPending + discarded + pending;
        return formatNumber(total);
    }, [rest.stats]);

    return (
        <S.EssayCardDetailsContainer>
            <S.EssayCardDetailsHeader>
                <S.EssayCardDetailsHeaderWrapper>
                    <S.EssayCardDetailsHeaderTitle>Tema:</S.EssayCardDetailsHeaderTitle>
                    {withHelpers && <S.EssayCardDetailsHeaderHelper>Adicionado recentemente</S.EssayCardDetailsHeaderHelper>}
                </S.EssayCardDetailsHeaderWrapper>
                <S.EssayCardDetailsTitle>{rest.title}</S.EssayCardDetailsTitle>
            </S.EssayCardDetailsHeader>
            <S.EssayCardDetailsContent>
                <S.EssayCardDetailsList>
                    <S.EssayCardDetailsListItem>
                        <span>Cadastrado em: </span>
                        <strong>{creationDate}</strong>
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem>
                        <span>Gênero: </span>
                        <strong>{rest.genre.title}</strong>
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem>
                        <span>Concurso: </span>
                        <strong>{rest.contest.name}</strong>
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem>
                        <span>Categoria: </span>
                        <strong>{rest.category.title}</strong>
                    </S.EssayCardDetailsListItem>
                    <S.EssayCardDetailsListItem>
                        <span>Complexidade: </span>
                        <strong>
                            <Difficulty level={rest.complexity} />
                        </strong>
                    </S.EssayCardDetailsListItem>
                </S.EssayCardDetailsList>
                <S.EssayCardDetailsContentHelper>
                    Esse tema foi feito <strong>{getStatsTotal}</strong> vezes
                </S.EssayCardDetailsContentHelper>
            </S.EssayCardDetailsContent>
        </S.EssayCardDetailsContainer>
    );
};

export default memo(EssayCardDetails);
