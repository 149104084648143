import React, { useCallback, useRef, useEffect } from "react";

import EssaySliderButton from "./Button";
import EssaySliderArrow from "./Arrow";

import { IEssayComposition } from "store/interfaces/IEssay";

import * as S from "./styles";

interface ISliderProps {
    comment: number;
    onChange(index: number, comment: number): void;
    composition?: IEssayComposition;
}

const SWITCHER_CHANGE = 1;

function EssaySlider({ composition, comment, onChange }: ISliderProps) {
    const buttonRef = useRef<null | HTMLButtonElement>(null);

    const handleClickPrev = useCallback(() => {
        onChange(SWITCHER_CHANGE, comment <= 0 ? 0 : comment - 1);
    }, [comment, onChange]);

    const handleClickNext = useCallback(() => {
        const buttonsLength = composition?.comments?.length || 0;

        onChange(SWITCHER_CHANGE, comment >= buttonsLength - 1 ? buttonsLength - 1 : comment + 1);
    }, [comment, composition, onChange]);

    useEffect(() => {
        if (comment !== undefined && buttonRef.current) {
            buttonRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "center"
            });
        }
    }, [comment]);

    const handleChange = useCallback(
        (index: number) => {
            onChange(SWITCHER_CHANGE, index);
        },
        [onChange]
    );

    return (
        <S.EssaySliderContainer>
            {!!composition?.comments?.length && (
                <>
                    <EssaySliderArrow onClick={handleClickPrev} />
                    <S.EssaySliderListButtons>
                        <div>
                            {composition.comments.map((_, index) => {
                                const active = index === comment;

                                return (
                                    <EssaySliderButton
                                        key={index}
                                        active={active}
                                        onClick={() => handleChange(index)}
                                        {...(active && { ref: buttonRef })}
                                    >
                                        {index + 1}
                                    </EssaySliderButton>
                                );
                            })}
                        </div>
                    </S.EssaySliderListButtons>
                    <EssaySliderArrow direction="right" onClick={handleClickNext} />
                </>
            )}
        </S.EssaySliderContainer>
    );
}

export default EssaySlider;
