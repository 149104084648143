import { ISelectOption } from "interfaces/ISelectOption";
import dictionary from "helpers/dictionary";

const convertEnumToOptions = (enumValue: any): ISelectOption[] => {
    const keys = Object.values(enumValue).filter((value) => !isNaN(Number(value)));
    const options = keys.map((item, index) => ({
        value: item as any,
        label: dictionary[enumValue[keys[index] as any]] || enumValue[keys[index] as any]
    }));

    return options;
};

export default convertEnumToOptions;
