import React, { memo } from "react";

// Dependencies
import ProductIcon from "prodigio-icons/web/filled/Product";
import InfoIcon from "prodigio-icons/web/outlined/Info";

// Components
import BackTo from "components/BackTo";
import FilterModal from "components/FilterModal";
import Dropdown from "components/Dropdown";

// Helpers
import pluralize from "utils/pluralize";
import useIsMobile from "hooks/use-is-mobile";
import { IFilterGroup } from "interfaces/IFilterGroup";
import { theme } from "config/theme";

// Assets
import * as P from "./styles";
import { Grid, Row, Col } from "components/Grid";

interface IPageHeaderComplexProps {
    title: string;
    backTo?: {
        label: string;
        path: string;
    };
    buttonNew?: React.ReactNode;
    credit?: {
        amount: number;
        onClickBuy?(): void;
    };
    modalOptions?: IFilterGroup[];
    onFilter?(options: IFilterGroup[]): void;
    dropdown?: {
        icon?: React.ReactNode;
        content: React.ReactNode;
    };
    isHorizontal?: boolean;
}

const PageHeaderComplex = ({ backTo, credit, modalOptions, title, buttonNew, dropdown, isHorizontal = false, onFilter }: IPageHeaderComplexProps) => {
    const isMobile = useIsMobile();

    return (
        <P.PageHeaderContainerComplex>
            <Grid fluid>
                <Row>
                    {backTo && (
                        <Col xs={12}>
                            <P.PageHeaderBack>
                                <BackTo to={backTo.path}>{backTo.label}</BackTo>
                            </P.PageHeaderBack>
                        </Col>
                    )}
                    <Col xs={12}>
                        <P.PageHeaderContent isHorizontal={isHorizontal}>
                            <P.PageHeaderRow>
                                <P.PageHeaderTitle variant="complex">{title}</P.PageHeaderTitle>
                                <P.PageHeaderButtons>
                                    {buttonNew}
                                    {isMobile && modalOptions && onFilter && (
                                        <FilterModal size="large" withButtonLabel={false} options={modalOptions} onChange={onFilter} />
                                    )}
                                </P.PageHeaderButtons>
                            </P.PageHeaderRow>
                            {credit && (
                                <P.PageHeaderCreditWrapper isHorizontal={isHorizontal}>
                                    <P.PageHeaderCreditContent>
                                        <P.PageHeaderText>
                                            Você tem <span>{credit.amount}</span> {pluralize(credit.amount, "créditos", "crédito")}.
                                        </P.PageHeaderText>
                                        {dropdown && (
                                            <Dropdown label={dropdown?.icon || <InfoIcon width={16} height={16} color={theme.colors.base[300]} />}>
                                                {dropdown.content}
                                            </Dropdown>
                                        )}
                                    </P.PageHeaderCreditContent>

                                    <P.PageHeaderText>
                                        {credit?.onClickBuy && (
                                            <P.PageHeaderButton type="button" onClick={credit.onClickBuy}>
                                                Comprar mais
                                                {!isMobile && (
                                                    <P.PageHeaderButtonIcon>
                                                        <ProductIcon />
                                                    </P.PageHeaderButtonIcon>
                                                )}
                                            </P.PageHeaderButton>
                                        )}
                                    </P.PageHeaderText>
                                </P.PageHeaderCreditWrapper>
                            )}
                        </P.PageHeaderContent>
                    </Col>
                </Row>
            </Grid>
        </P.PageHeaderContainerComplex>
    );
};

export default memo(PageHeaderComplex);
