import { theme } from "config/theme";
import Calendar from "prodigio-icons/web/filled/Calendar";
import styled from "styled-components";

export const Card = styled.dl`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 4px;
    margin-top: ${theme.spacing.smallX};
`;

export const HeaderCard = styled.dt`
    padding: ${theme.spacing.small};
`;

export const PositionTitleAndBadge = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TitleCard = styled.h4`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.medium};
    line-height: 22px;
    color: ${theme.colors.base[400]};
`;

export const BadgeCard = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    background: ${theme.colors.system.info[200]};
    border-radius: 100px;
    padding: 0px ${theme.spacing.smallX};

    font-weight: bold;
    font-size: ${theme.typography.sizes.small};
    line-height: 16px;
    color: ${theme.colors.system.info[400]};
`;

export const DateContainerCard = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.smallXX};
`;

export const IconCalendarCard = styled(Calendar)`
    width: 16px;
    height: 16px;
    margin-right: ${theme.spacing.smallX};
`;

export const DateCard = styled.time`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.smallX};
    line-height: 20px;
    color: ${theme.colors.base[300]};
    margin-top: 2px;
`;

export const DescriptionCard = styled.dd`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.small};
`;

export const FindContentText = styled.p`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.smallX};
    line-height: 20px;
    color: ${theme.colors.base[400]};
`;

export const BreadCrumbCard = styled.span`
    font-family: ${theme.typography.family.secondary};
    font-size: ${theme.typography.sizes.smallX};
    line-height: 20px;
    color: ${theme.colors.base[400]};
    margin-top: ${theme.spacing.smallX};
`;
