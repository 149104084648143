import React from "react";

// Assets
import { SuccessMessageContainer } from "./styles";

const SuccessMessage = () => {
    return (
        <SuccessMessageContainer>
            <p>Endereço adicionado com sucesso</p>
        </SuccessMessageContainer>
    );
};

export default SuccessMessage;
