// Dependencies
import React, { Fragment, FunctionComponent, useEffect, useMemo } from "react";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";
import { Creators as activityActions } from "store/ducks/activity";

// Services
import history from "services/history";

// Components
import { ActivityBoard } from "components/ActivityBoard";
import { ActivityButton } from "components/ActivityButton";
import { ActivityList, ActivityListItem } from "components/ActivityList";
import { ActivityCard } from "components/ActivityCard";
import { ActivityChip } from "components/ActivityChip";
import { ActivityTeacherItem, ActivityTeacherItemSkeleton } from "components/ActivityTeacherItem";
import { ActivityModal } from "components/ActivityModal/";
import { ActivityCheckbox } from "components/ActivityCheckbox";
import { ActivityDatePicker } from "components/ActivityDatePicker";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { ConditionallyRender } from "component-library/utilities/conditionally-render";
// import { ActivityNotification } from "components/ActivityNotification";
import { ActivityBottomSheet } from "components/ActivityBottomSheet";
import { ActivityMenu, ActivityMenuItem } from "components/ActivityMenu";
import { Skeleton } from "component-library/utilities/skeleton";

// Styles
import {
    ActionButtonIcon,
    ActivityModalFilterClassCheckboxsWrapper,
    ActivityModalFilterContentTitle,
    ActivityModalFilterContentWrapper,
    ActivityModalFilterDeadlineInputsWrapper,
    ActivityModalFilterStatusChipsWrapper,
    ActivityTeacherItemsWrapper,
    FilterEmptyStateWrapper,
    FilterEmptyStateTitle,
    FilterEmptyStateDescription,
    ActivityCurrentActivityDeletedLabel
} from "./activity-teacher-dashboard.styles";

// Assets
import essay from "assets/img/background-essay.svg";
import filterIcon from "assets/img/activity-filter.svg";
import close from "assets/img/close.svg";

// Utils
import { useWindowDimensions } from "hooks/use-window-dimensions";
import { getActivityStatusColor, handleSelectFilterData, getMenuFloatContainerAlignment } from "./activity-teacher-dashboard.helpers";

// Types
import { ActivityStatusEnum, statusOptions } from "store/interfaces/activity";
import { Spacing } from "component-library/utilities/spacing";

export const ActivityTeacherDashboard: FunctionComponent = () => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();

    const { activities, filter, enrollments, currentActivityToBeDeleted } = useSelector(({ activity }: IReduxStore) => activity.teacherDashboard);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    useEffect(() => {
        dispatch(activityActions.getTeacherDashboardActivitiesRequest());
        dispatch(activityActions.getTeacherDashboardEnrollmentsRequest());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(activityActions.clearTeacherDashboard());
        };
    }, [dispatch]);

    const filterContent = (
        <Fragment>
            <ActivityModalFilterContentWrapper>
                <ActivityModalFilterContentTitle>Status</ActivityModalFilterContentTitle>

                <ActivityModalFilterStatusChipsWrapper>
                    {statusOptions.map((item) => {
                        const isSelected = !!filter.data.statuses.find((status) => status === item);

                        return (
                            <ActivityChip
                                key={`webapp-activity-modal-filter-status-${item}`}
                                label={ActivityStatusEnum[item]}
                                variant={isSelected ? "cta" : "default"}
                                size="large"
                                handleClick={() =>
                                    dispatch(
                                        activityActions.setTeacherDashboardFilterData({
                                            ...filter.data,
                                            statuses: handleSelectFilterData(item, filter.data.statuses)
                                        })
                                    )
                                }
                            />
                        );
                    })}
                </ActivityModalFilterStatusChipsWrapper>
            </ActivityModalFilterContentWrapper>

            <ActivityModalFilterContentWrapper hasMobileMarginTop>
                <ActivityModalFilterContentTitle>Turma</ActivityModalFilterContentTitle>

                <ActivityModalFilterClassCheckboxsWrapper>
                    <ConditionallyRender
                        shouldRender={enrollments.isLoading}
                        content={Array.from({ length: 4 }).map((_, index) => (
                            <Skeleton key={`activity-checkbox-${index}`} width="120px" height="20px" borderRadius="4px" />
                        ))}
                    />

                    <ConditionallyRender
                        shouldRender={!enrollments.isLoading}
                        content={enrollments.data?.map((item) => {
                            const isSelected = !!filter.data.classes.find((classItem) => classItem === item.value);

                            return (
                                <ActivityCheckbox
                                    key={item.value}
                                    label={item.label}
                                    isChecked={isSelected}
                                    handleClick={() =>
                                        dispatch(
                                            activityActions.setTeacherDashboardFilterData({
                                                ...filter.data,
                                                classes: handleSelectFilterData(item.value, filter.data.classes)
                                            })
                                        )
                                    }
                                />
                            );
                        })}
                    />
                </ActivityModalFilterClassCheckboxsWrapper>
            </ActivityModalFilterContentWrapper>

            <ActivityModalFilterContentWrapper hasBorderBottom hasMobileMarginTop>
                <ActivityModalFilterContentTitle>Prazo</ActivityModalFilterContentTitle>

                <ActivityModalFilterDeadlineInputsWrapper>
                    <ActivityDatePicker
                        placeholder="Data inicial"
                        value={filter.data.startDueDate}
                        isTimeEnabled
                        onChange={([newDate]) => {
                            dispatch(activityActions.setTeacherDashboardFilterData({ ...filter.data, startDueDate: newDate.toISOString() }));
                        }}
                    />

                    <ActivityDatePicker
                        placeholder="Data final"
                        value={filter.data.endDueDate}
                        isTimeEnabled
                        onChange={([newDate]) => {
                            dispatch(activityActions.setTeacherDashboardFilterData({ ...filter.data, endDueDate: newDate.toISOString() }));
                        }}
                    />
                </ActivityModalFilterDeadlineInputsWrapper>
            </ActivityModalFilterContentWrapper>
        </Fragment>
    );

    const filterActionButtons = (
        <Fragment>
            <ActivityButton
                label="Remover filtros"
                variantType="secondary"
                isFullyAdaptative={width < 768}
                size={width < 768 ? "large" : "small"}
                onClick={() => {
                    dispatch(activityActions.clearTeacherDashboardFilterData());
                    dispatch(activityActions.getTeacherDashboardActivitiesRequest());
                }}
            />

            <ActivityButton
                label="Filtrar"
                isFullyAdaptative={width < 768}
                size={width < 768 ? "large" : "small"}
                onClick={() => dispatch(activityActions.getTeacherDashboardActivitiesRequest(filter.data))}
            />
        </Fragment>
    );

    const shouldDisplayTeacherEmptyState = useMemo(
        () =>
            !activities.data?.length &&
            !filter.data.classes.length &&
            !filter.data.statuses.length &&
            !filter.data.startDueDate &&
            !filter.data.endDueDate &&
            !activities.isLoading,
        [
            activities.data,
            activities.isLoading,
            filter.data.classes.length,
            filter.data.endDueDate,
            filter.data.startDueDate,
            filter.data.statuses.length
        ]
    );

    const activityToBeDeletedContent = (
        <ActivityCurrentActivityDeletedLabel>
            Você tem certeza que deseja excluir a atividade <strong>{currentActivityToBeDeleted?.title}</strong>?
            <br />
            <small>Esta ação não poderá ser desfeita.</small>
        </ActivityCurrentActivityDeletedLabel>
    );

    const activityToBeDeletedActionButtons = (
        <Fragment>
            <ActivityButton
                label="Cancelar"
                variantType="secondary"
                isFullyAdaptative
                size="large"
                onClick={() => dispatch(activityActions.setTeacherDashboardCurrentActivityToBeDeleted())}
            />

            <ActivityButton
                label="Confirmar"
                isFullyAdaptative
                size="large"
                onClick={() => dispatch(activityActions.deleteTeacherDashboardActivityRequest(currentActivityToBeDeleted?.id!))}
            />
        </Fragment>
    );

    return (
        <Fragment>
            <ScreenWidthRender
                actionAfterRenderingWidth="show"
                renderingWidth={1024}
                content={
                    <ActivityModal
                        isOpened={!!filter.isOpened}
                        title="Filtros"
                        closeIconElement={
                            <ActionButtonIcon
                                src={close}
                                hasCursorPointer
                                onClick={() => dispatch(activityActions.setTeacherDashboardFilterIsOpened(false))}
                            />
                        }
                        content={filterContent}
                        actionButtonCompositions={filterActionButtons}
                        handleOutsideClick={() => dispatch(activityActions.setTeacherDashboardFilterIsOpened(false))}
                    />
                }
            />

            <ScreenWidthRender
                actionAfterRenderingWidth="hide"
                renderingWidth={1024}
                content={
                    <ConditionallyRender
                        shouldRender={!!filter.isOpened}
                        content={
                            <ActivityBottomSheet
                                label="Filtros"
                                handleOutsideClick={() => dispatch(activityActions.setTeacherDashboardFilterIsOpened(false))}
                                content={filterContent}
                                actionButtonCompositions={filterActionButtons}
                            />
                        }
                    />
                }
            />

            <ScreenWidthRender
                actionAfterRenderingWidth="show"
                renderingWidth={768}
                content={
                    <ActivityModal
                        isOpened={!!currentActivityToBeDeleted}
                        title="Confirmação de exclusão!"
                        titleAlignment="center"
                        modalWidth={420}
                        content={
                            <Fragment>
                                <Spacing direction="vertical" size={16} />

                                {activityToBeDeletedContent}
                            </Fragment>
                        }
                        actionButtonCompositions={activityToBeDeletedActionButtons}
                        handleOutsideClick={() => dispatch(activityActions.setTeacherDashboardCurrentActivityToBeDeleted())}
                    />
                }
            />

            <ScreenWidthRender
                actionAfterRenderingWidth="hide"
                renderingWidth={768}
                content={
                    <ConditionallyRender
                        shouldRender={!!currentActivityToBeDeleted}
                        content={
                            <ActivityBottomSheet
                                label="Confirmação de exclusão"
                                handleOutsideClick={() => dispatch(activityActions.setTeacherDashboardCurrentActivityToBeDeleted())}
                                content={activityToBeDeletedContent}
                                actionButtonCompositions={activityToBeDeletedActionButtons}
                            />
                        }
                    />
                }
            />

            <ActivityBoard
                sectionTitle="Atividades"
                shouldDisplayEmptyState={!activities.isLoading && !activities.data?.length}
                emptyStateComposition={
                    <Fragment>
                        <ConditionallyRender
                            shouldRender={
                                !!filter.data.classes.length ||
                                !!filter.data.statuses.length ||
                                !!filter.data.startDueDate ||
                                !!filter.data.endDueDate
                            }
                            content={
                                <FilterEmptyStateWrapper>
                                    <FilterEmptyStateTitle>Nenhuma atividade encontrada</FilterEmptyStateTitle>
                                    <FilterEmptyStateDescription>Revise os filtros selecionados para ampliar a pesquisa</FilterEmptyStateDescription>
                                </FilterEmptyStateWrapper>
                            }
                        />
                        <ConditionallyRender
                            shouldRender={
                                !filter.data.classes.length && !filter.data.statuses.length && !filter.data.startDueDate && !filter.data.endDueDate
                            }
                            content={
                                <Fragment>
                                    <ActivityCard
                                        title="Comece agora!"
                                        description="Elabore a sua primeira atividade"
                                        headerIcon={essay}
                                        actionButtonComposition={
                                            <ActivityButton
                                                isFullyAdaptative
                                                label="Criar atividade"
                                                onClick={() =>
                                                    history.push({
                                                        pathname: `/app/curso/${courseSlug}/atividades/criar`
                                                    })
                                                }
                                            />
                                        }
                                    />

                                    <ActivityList
                                        title="Trio de transformação educacional"
                                        activityListItemCompositions={
                                            <Fragment>
                                                <ActivityListItem
                                                    title="Aprendizado e retenção de conhecimento"
                                                    description="Potencialize o aprendizado! Criar atividades personalizadas promove uma compreensão profunda e duradoura, indo além da memorização. Explore conosco as vantagens de experiências de aprendizado que constroem conhecimento sólido e eficaz!"
                                                />

                                                <ActivityListItem
                                                    title="Engajamento e motivação remota"
                                                    description="Transforme o aprendizado remoto! A criação de atividades desperta o interesse e a motivação dos alunos, tornando o aprendizado uma jornada empolgante. Junte-se a nós para impulsionar o sucesso acadêmico através do engajamento!"
                                                />

                                                <ActivityListItem
                                                    title="Desenvolvimento integral dos alunos"
                                                    description="Revitalize o aprendizado! Criar atividades é fundamental para moldar mentes brilhantes, estimulando a criatividade e aprimorando habilidades de resolução de problemas. Não perca a chance de preparar os alunos para desafios futuros!"
                                                />
                                            </Fragment>
                                        }
                                    />
                                </Fragment>
                            }
                        />
                    </Fragment>
                }
                activitiesContentCompositions={
                    <ActivityTeacherItemsWrapper>
                        <ConditionallyRender
                            shouldRender={!!activities.isLoading}
                            content={Array.from({ length: 20 }).map((_, index) => (
                                <ActivityTeacherItemSkeleton key={`activity-teacher-item-skeleton-${index}`} />
                            ))}
                        />

                        <ConditionallyRender
                            shouldRender={!activities.isLoading}
                            content={activities.data?.map((activity, index) => {
                                return (
                                    <ActivityTeacherItem
                                        key={`activity-teacher-item-${activity.id}`}
                                        title={activity.title}
                                        referenceDate={activity.dueDate ?? "-"}
                                        referenceDateLabel="Prazo"
                                        score={`${activity.score ?? "-"}`}
                                        scoreLabel="Pontos"
                                        actionMenuComposition={
                                            <ActivityMenu
                                                floatContainerWidth={120}
                                                floatContainerAlignment={getMenuFloatContainerAlignment(width, index)}
                                                itemsCompositions={
                                                    <Fragment>
                                                        <ActivityMenuItem
                                                            label="Editar"
                                                            handleClick={() => {
                                                                history.push({
                                                                    pathname: `/app/curso/${courseSlug}/atividades/${activity.id}/editar`
                                                                });
                                                            }}
                                                        />
                                                        <ActivityMenuItem
                                                            label="Excluir"
                                                            handleClick={() =>
                                                                dispatch(activityActions.setTeacherDashboardCurrentActivityToBeDeleted(activity))
                                                            }
                                                        />
                                                    </Fragment>
                                                }
                                            />
                                        }
                                        statusChipComposition={
                                            <ActivityChip
                                                variant={getActivityStatusColor(activity.status)}
                                                label={ActivityStatusEnum[activity.status]}
                                            />
                                        }
                                        // notificationComposition={<ActivityNotification count={4} />}
                                        handleClick={() => {
                                            history.push({
                                                pathname: `/app/curso/${courseSlug}/atividades/${activity.id}/entregas`
                                            });
                                        }}
                                    />
                                );
                            })}
                        />
                    </ActivityTeacherItemsWrapper>
                }
                {...((!!activities.data?.length ||
                    !!filter.data.classes.length ||
                    !!filter.data.statuses.length ||
                    !!filter.data.startDueDate ||
                    !!filter.data.endDueDate) && {
                    fixedMobileContent: (
                        <ActivityButton
                            label="Cadastrar nova atividade"
                            size="large"
                            isFullyAdaptative
                            onClick={() =>
                                history.push({
                                    pathname: `/app/curso/${courseSlug}/atividades/criar`
                                })
                            }
                        />
                    ),
                    actionButtonsComposition: (
                        <Fragment>
                            <ActivityButton
                                label="Filtrar"
                                size="large"
                                iconElement={<ActionButtonIcon src={filterIcon} />}
                                variantType="secondary"
                                onClick={() => dispatch(activityActions.setTeacherDashboardFilterIsOpened(true))}
                            />
                            <ScreenWidthRender
                                renderingWidth={768}
                                actionAfterRenderingWidth="show"
                                content={
                                    <ActivityButton
                                        label="Cadastrar nova atividade"
                                        size="large"
                                        onClick={() =>
                                            history.push({
                                                pathname: `/app/curso/${courseSlug}/atividades/criar`
                                            })
                                        }
                                    />
                                }
                            />
                        </Fragment>
                    )
                })}
                {...(shouldDisplayTeacherEmptyState && { sectionDescription: "Explore a oportunidade de criar atividades personalizadas" })}
            />
        </Fragment>
    );
};
