import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { ForumPostContainer, ForumPostNav } from "../post/styles";
import { ForumAnswerReplyContainer } from "./components/reply/styles";
import { ForumQuestionContentText } from "screens/Forum/components/question-content/styles";
import { hexToRgba } from "helpers";

export const ForumAnswerContainer = styled.div`
    &:not(:last-child) {
        margin-bottom: ${theme.spacing.large};
    }

    ${ForumAnswerReplyContainer} {
        margin-top: ${theme.spacing.medium};

        ${ForumPostContainer} {
            margin: 0;
        }
    }

    ${ForumPostNav} {
        width: 100%;

        ${ButtonWrapper} {
            &:last-child {
                margin-left: auto;
            }
        }
    }

    ${MediaQueries.SMALL} {
        ${ForumPostNav} {
            justify-content: space-between;
            width: 100%;
        }
    }
`;

export const ForumAnswerContent = styled.div<{ color?: string }>`
    background: ${({ color }) => (color ? hexToRgba(color, 0.2) : theme.colors.base[100])};
    border-radius: ${theme.spacing.smallX};
    box-shadow: ${theme.shadow[1]};
    padding: ${theme.spacing.medium};

    ${ForumQuestionContentText} {
        display: block;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
    }
`;

export const ForumAnswerContentText = styled.div`
    margin-bottom: ${theme.spacing.medium};
`;

export const ForumAnswerReplies = styled.div`
    margin-top: ${theme.spacing.medium};
`;
