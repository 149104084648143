import { AnswerCardRealizationMode } from "enums/AnswerCardRealizationMode";
import { EssayStatus } from "enums/EssayStatus";
import { IExerciseListState } from "interfaces/IExerciseList";
import IPagination from "interfaces/IPagination";
import { createActions, createReducer } from "reduxsauce";
import QuestionType from "screens/ExerciseList/enums/QuestionType";
import { ReduxAction } from "store";
import { IEssayComposition } from "store/interfaces/IEssay";
import { paginationInitialData } from "utils/paginationInitialData";

export interface IAnswerCardItemQuestion {
    id: number;
    revisionComment: string | null;
    enunciation: string;
    questionType: QuestionType;
    objectiveAnswers: { id: number }[];
}

export interface IAnswerCardItem {
    id: number;
    hit: boolean;
    points: number;
    discursiveAnswer: string | null;
    objectiveAnswer: number | null;
    correctionComment: string | null;
    question: IAnswerCardItemQuestion;
}

export interface IAnswerCardComposition {
    idComposition: number;
    content: string;
    idTheme: number;
    path: string;
    pathOriginal: string;
    status: EssayStatus;
    isSaved: boolean;
}

export interface IAnswerCard {
    id: number;
    realizationMode: AnswerCardRealizationMode;
    status: number;
    duration: any;
    startDate: string;
    endDate: string | null;
    questionsTotal: number;
    answersCorrect: number;
    answers: IAnswerCardItem[];
    exerciseList?: IExerciseListState;
    points?: number;
    answersWrong?: number;
    totalAnswered?: number;
    examResolution?: {
        test: boolean;
        id?: number;
        idComposition?: number;
    };
    composition?: IAnswerCardComposition;
}

export interface IAnswerCardState {
    isLoading: boolean;
    items: IAnswerCard[];
    answerCard: IAnswerCard;
    error: boolean;
    results: IPagination<IAnswerCard>;
    isLoadingEssayCorrection: boolean;
    essayCorrection?: IEssayComposition;
}

export const { Creators, Types } = createActions({
    getAnswerCardsRequest: ["payload"],
    getAnswerCardsSuccess: ["payload"],
    getAnswerCardsFailure: ["payload"],

    getAnswerCardRequest: ["payload"],
    getAnswerCardSuccess: ["payload"],
    getAnswerCardFailure: ["payload"],

    patchAnswerCardRequest: ["payload"],
    patchAnswerCardSuccess: ["payload"],
    patchAnswerCardFailure: ["payload"],

    postAnswerCardRequest: ["payload"],
    postAnswerCardSuccess: ["payload"],
    postAnswerCardFailure: ["payload"],

    postAnswerCardQuestionRequest: ["payload"],
    postAnswerCardQuestionSuccess: ["payload"],
    postAnswerCardQuestionFailure: ["payload"],

    getResultCardsRequest: ["payload"],
    getResultCardsSuccess: ["payload"],
    getResultCardsFailure: ["payload"],

    saveAnswerCardEssay: ["payload"],

    clearAnswerCard: [],
    clearCurrentAnswerCard: [],
    clearAnswerCardResults: [],

    getAnswerCardEssayCorrectionRequest: [],
    getAnswerCardEssayCorrectionSuccess: ["payload"],
    getAnswerCardEssayCorrectionFailure: [],

    setOpenQuestionAnswerRequest: ["payload"],
    setOpenQuestionAnswerFailure: ["payload"],
    setOpenQuestionAnswerSuccess: ["payload"]
});

const INITIAL_STATE_CARD = {
    id: 0,
    realizationMode: AnswerCardRealizationMode.Study,
    status: 0,
    duration: null,
    startDate: "",
    endDate: null,
    questionsTotal: 1,
    answersCorrect: 0,
    answers: [],
    composition: undefined
};

export const INITIAL_STATE: IAnswerCardState = {
    isLoading: false,
    items: [],
    answerCard: INITIAL_STATE_CARD,
    error: false,
    results: paginationInitialData,
    essayCorrection: undefined,
    isLoadingEssayCorrection: false
};

const request = (state = INITIAL_STATE) => ({ ...state, isLoading: true, error: false });

const failure = (state = INITIAL_STATE) => ({ ...state, isLoading: false, error: true });

const getAnswerCardsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload,
    answerCard: INITIAL_STATE_CARD
});

const getAnswerCardSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    answerCard: { ...state.answerCard, ...action.payload },
    error: false
});

const patchAnswerCardSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    answerCard: {
        ...state.answerCard,
        ...action.payload
    },
    isLoading: false,
    error: false
});

const postAnswerCardSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    answerCard: action.payload,
    error: false
});

const postAnswerCardQuestionSuccess = (state = INITIAL_STATE, action: any) => {
    try {
        const { payload } = action;

        const hasThisAnswer = state.answerCard.answers
            .filter((answer) => !!answer.question)
            .some((answer) => answer.question.id === payload.question.id);

        const updateAnswer = hasThisAnswer
            ? state.answerCard.answers.map((answer) => (answer.question.id === payload.question.id ? payload : answer))
            : [...state.answerCard.answers, payload];

        return {
            ...state,
            isLoading: false,
            answerCard: {
                ...state.answerCard,
                answers: updateAnswer
            },
            error: false
        };
    } catch (error) {
        return {
            ...state,
            isLoading: false,
            error: false
        };
    }
};

const getResults = (state = INITIAL_STATE, action: any) => {
    return { ...state, isLoading: false, error: false, results: action.payload };
};

const clearAnswerCard = () => INITIAL_STATE;
const clearCurrentCard = (state = INITIAL_STATE) => {
    return { ...state, answerCard: INITIAL_STATE_CARD };
};

const clearResults = (state = INITIAL_STATE) => ({
    ...state,
    results: paginationInitialData
});

const saveEssay = (state = INITIAL_STATE, action: ReduxAction<IAnswerCardComposition>) => {
    return {
        ...state,
        answerCard: {
            ...state.answerCard,
            composition: {
                ...state.answerCard?.composition,
                ...action.payload
            }
        }
    };
};

// ESSAY CORRECTION

const getAnswerCardEssayCorrectionRequest = (state = INITIAL_STATE) => ({
    ...state,
    essayCorrection: undefined,
    isLoadingEssayCorrection: true
});

const getAnswerCardEssayCorrectionSuccess = (state = INITIAL_STATE, action: ReduxAction<IEssayComposition>) => ({
    ...state,
    essayCorrection: action.payload,
    isLoadingEssayCorrection: false
});

const getAnswerCardEssayCorrectionFailure = (state = INITIAL_STATE) => ({
    ...state,
    essayCorrection: undefined,
    isLoadingEssayCorrection: false
});

const setOpenQuestionAnswerSuccess = (state = INITIAL_STATE, action: any) => {
    const currentAnswers = state.answerCard.answers;

    const { id, hit, discursiveAnswer, question } = action.payload;

    const hasThisAnswer = currentAnswers.some((answer) => answer.id === id);

    const updateAnswer = hasThisAnswer
        ? state.answerCard.answers.map((answer) => (answer.id === id ? { ...answer, hit: hit } : answer))
        : [...state.answerCard.answers, { id, hit, discursiveAnswer, question }];

    return {
        ...state,
        isLoading: false,
        error: false,
        answerCard: {
            ...state.answerCard,
            answers: updateAnswer
        }
    };
};

export default createReducer(INITIAL_STATE, {
    [Types.GET_ANSWER_CARDS_REQUEST]: request,
    [Types.GET_ANSWER_CARDS_SUCCESS]: getAnswerCardsSuccess,
    [Types.GET_ANSWER_CARDS_FAILURE]: failure,
    [Types.GET_ANSWER_CARD_REQUEST]: request,
    [Types.GET_ANSWER_CARD_SUCCESS]: getAnswerCardSuccess,
    [Types.GET_ANSWER_CARD_FAILURE]: failure,
    [Types.PATCH_ANSWER_CARD_REQUEST]: request,
    [Types.PATCH_ANSWER_CARD_SUCCESS]: patchAnswerCardSuccess,
    [Types.PATCH_ANSWER_CARD_FAILURE]: failure,
    [Types.POST_ANSWER_CARD_REQUEST]: request,
    [Types.POST_ANSWER_CARD_SUCCESS]: postAnswerCardSuccess,
    [Types.POST_ANSWER_CARD_FAILURE]: failure,
    [Types.POST_ANSWER_CARD_QUESTION_REQUEST]: request,
    [Types.POST_ANSWER_CARD_QUESTION_SUCCESS]: postAnswerCardQuestionSuccess,
    [Types.POST_ANSWER_CARD_QUESTION_FAILURE]: failure,

    [Types.GET_RESULT_CARDS_REQUEST]: request,
    [Types.GET_RESULT_CARDS_SUCCESS]: getResults,
    [Types.GET_RESULT_CARDS_FAILURE]: failure,

    [Types.CLEAR_ANSWER_CARD]: clearAnswerCard,
    [Types.CLEAR_CURRENT_ANSWER_CARD]: clearCurrentCard,
    [Types.CLEAR_ANSWER_CARD_RESULTS]: clearResults,

    [Types.SAVE_ANSWER_CARD_ESSAY]: saveEssay,

    [Types.GET_ANSWER_CARD_ESSAY_CORRECTION_REQUEST]: getAnswerCardEssayCorrectionRequest,
    [Types.GET_ANSWER_CARD_ESSAY_CORRECTION_SUCCESS]: getAnswerCardEssayCorrectionSuccess,
    [Types.GET_ANSWER_CARD_ESSAY_CORRECTION_FAILURE]: getAnswerCardEssayCorrectionFailure,

    [Types.SET_OPEN_QUESTION_ANSWER_REQUEST]: request,
    [Types.SET_OPEN_QUESTION_ANSWER_FAILURE]: failure,
    [Types.SET_OPEN_QUESTION_ANSWER_SUCCESS]: setOpenQuestionAnswerSuccess
});
