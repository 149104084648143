import { call, put } from "redux-saga/effects";

import api from "services/api";

import { Creators as announcementActions } from "../ducks/announcement";

export function* getAnnouncements(action: any) {
    try {
        const { data } = yield call(api.get, "/student/notification");

        yield put(announcementActions.getAnnouncementsSuccess(data));
    } catch (error) {
        console.log(error);
        yield put(announcementActions.getAnnouncementsFailure(error?.response?.data));
    }
}

export function* createOrEditAnnouncement(action: any) {
    try {
        const { payload } = action;
        yield call(api.patch, payload.endpoint, { viewed: true });

        yield put(announcementActions.createOrEditAnnouncementsSuccess({ ...payload.body }));
    } catch (error) {
        console.log(error);
        yield put(announcementActions.createOrEditAnnouncementsFailure(error?.response?.data));
    }
}
