// Dependencies
import styled, { css } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #ffffff;
`;

export const GraphicWrapper = styled.div`
    position: relative;
    display: flex;
    padding: 24px;
`;

export const Graphic = styled.svg`
    border-radius: 8px 8px 0px 0px;
`;

export type GraphicIndicator = {
    hasMobile: boolean;
};

export const GraphicIndicator = styled.text<GraphicIndicator>`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    fill: #505462;

    ${({ hasMobile }) =>
        hasMobile &&
        css`
            display: none;

            @media (min-width: 1024px) {
                display: block;
            }
        `}
`;

export const MobileGraphicIndicator = styled.text`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    fill: #505462;

    @media (min-width: 1024px) {
        display: none;
    }
`;

export const ColumnSelectedContent = styled.text`
    display: none;
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    fill: #3a3e47;

    @media (min-width: 1024px) {
        display: block;
    }
`;

export const ColumnSelectedContentMobile = styled.text`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    fill: #3a3e47;

    @media (min-width: 1024px) {
        display: none;
    }
`;

export const ColumnSelectedSubContent = styled.text`
    display: none;
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    fill: #505462;

    @media (min-width: 1024px) {
        display: block;
    }
`;

export type ColumnStyleProps = {
    hasAction: boolean;
};

export const Column = styled.rect<ColumnStyleProps>`
    ${({ hasAction }) =>
        hasAction &&
        css`
            cursor: pointer;
        `}
`;

export const GraphicFooter = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 24px 32px;
    border-top: 2px solid #f0f1f7;
`;

export const SubtitlesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 32px;
`;

export const SubtitleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
`;

export const Subtitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #505462;
`;

type SubtitleIndicatorStyleProps = {
    color: string;
};

export const SubtitleIndicator = styled.span<SubtitleIndicatorStyleProps>`
    width: 8px;
    height: 8px;

    flex-shrink: 0;

    border-radius: 50%;

    ${({ color }) =>
        css`
            background-color: ${color};
        `}
`;

export const GraphicColumnsController = styled.div`
    display: flex;
`;

export const Divider = styled.div`
    display: flex;
    width: 2px;
    height: 64px;
    border-radius: 5px;
    background-color: #f0f1f7;
    flex-shrink: 0;
`;

export const AverageLine = styled.line`
    cursor: pointer;
`;

export type AverageNoteStyleProps = {
    isVisible: boolean;
};

export const AverageNote = styled.g<AverageNoteStyleProps>`
    display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

export const AverageLabel = styled.text`
    font-family: "Nunito Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    fill: #ffffff;
`;

// ----------

export const GraphicWrapperSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 424px;
    padding: 72px 24px 24px;
`;

export const SubtitleSkeleton = styled.div`
    height: 22px;
    padding: 4px 0;
`;

export const GraphicYValuesWrapperSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 24px;
    margin-top: -9.5px;
    padding-right: 2px;

    > * + * {
        margin-top: 37px;
    }

    @media (min-width: 768px) {
        width: 32px;
    }
`;

export const GraphicContainerSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const GraphicContentSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 280px;
    padding: 0 12px;
    border: 0.5px solid #f0f1f7;

    > * + * {
        margin-left: 12px;
    }

    @media (min-width: 768px) {
        padding: 0 24px;

        > * + * {
            margin-left: 24px;
        }
    }
`;

export const GraphicXValuesWrapperSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 2px 12px 0;

    > * + * {
        margin-left: 12px;
    }

    @media (min-width: 768px) {
        padding: 2px 24px 0;

        > * + * {
            margin-left: 24px;
        }
    }
`;

export const GraphicXValueContentSkeleton = styled.div`
    width: 100%;
    max-width: 42px;
`;
