// Dependencies
import styled from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;

    position: relative;

    ${MediaQueries.BIGGER_THAN_LARGE} {
        gap: 24px;
    }
`;

export const Content = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 8px;

    ${MediaQueries.BIGGER_THAN_TABLET} {
        gap: 124px;
        justify-content: space-between;
        flex-direction: row;
    }

    ${MediaQueries.BIGGER_THAN_LARGE} {
        gap: 24px;
        flex-direction: column;
        justify-content: unset;
    }
`;

export const DescriptionWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
`;

export const DescriptionLabelWrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
`;

export const Label = styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
    color: #232326;
`;
