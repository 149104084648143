// Dependencies
import styled from "styled-components";

export const StudentEmptyStateWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 92px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
`;

export const StudentEmptyStateImage = styled.img`
    width: 100%;
    height: auto;
    max-width: 420px;
`;

export const StudentEmptyStateTitleWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
`;

export const StudentEmptyStateTitle = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #232326;
`;

export const StudentEmptyStateSubtitle = styled.p`
    font-family: "Nunito Sans", sans-serif;
    color: #4d4d63;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
`;

export const ActivityStudentItemsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const ActionButtonIcon = styled.img`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
`;
