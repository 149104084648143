import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from "react";
import WatchScreenDoubtComponent from "components/WatchScreenDoubt";
import { useParams } from "react-router";
import scrollIntoView from "scroll-into-view";
import { theme } from "config/theme";

import { IReduxStore } from "interfaces/IReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { Creators as videoActions } from "store/ducks/video";
import { Creators as watchAction, IWatchScreenVideo } from "store/ducks/watch";
import { Creators as alertActions } from "store/ducks/alert";
import { Creators as modalActions } from "store/ducks/modal";
import { Creators as postActions } from "store/ducks/doubtPost";
import { LikeStatus } from "enums/LikeStatus";
import { IRelatedExerciseList, IRelatedVideo, IRelatedMaterial } from "interfaces/IRelatedContent";
import { IContentPerTypeEvent, IContentPerTypeItem } from "interfaces/IContentPerType";
import history from "services/history";
import HttpStatus from "enums/HttpStatus";

import Doubts from "components/WatchScreenDoubt/components/Doubt";
import { GetWidth, GetHeight } from "utils/windowsSize";
import { IGetTopicStatus } from "store/interfaces/IDoubtPost";
import { ternary } from "utils/ternary";
import { openLinkInBlankPage } from "utils/openLinkInBlankPage";
import { hasAnswerDoubts } from "helpers/has-answer-doubts";

const WatchScreenDoubt = () => {
    const [relatedExerciseLists, setRelatedExerciseLists] = useState<IRelatedExerciseList[]>([]);
    const [relatedMaterials, setRelatedMaterials] = useState<any[]>([]);
    const [relatedBooklets, setRelatedBooklets] = useState<any[]>([]);
    const [relatedVideos, setRelatedVideos] = useState<IRelatedVideo[]>();
    const dispatch = useDispatch();

    const modalWidth = ternary([
        [GetWidth < 768, "300"],
        [GetWidth < 1024, "500"],
        [true, "600"]
    ]);

    const { eventId, subjectSlug, moduleId, videoId, IdPostSpecific }: any = useParams();
    const { credentials, course, lessonPlan, video, watch, doubtPost } = useSelector(
        ({ credentials, course, lessonPlan, video, watch, doubtPost }: IReduxStore) => ({
            credentials,
            course,
            lessonPlan,
            video,
            watch,
            doubtPost
        })
    );

    const topicStatus = doubtPost.topic.data.status;
    const isWatchFromSubjects = !!subjectSlug && !!moduleId;

    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const answerQuestionsElementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        dispatch(videoActions.clearVideo());
    }, [dispatch]);

    // Answer questions - START

    useEffect(() => {
        if (video.learningObject?.id) {
            dispatch(postActions.getTopicRequest({ learningObjectId: video.learningObject?.id }));
        }

        return () => {
            dispatch(postActions.clearStateRequest());
        };
    }, [dispatch, video.learningObject]);

    const getPosts = useCallback(
        (justmine?: boolean, isRestarting?: boolean) => {
            const justmineHasChanged = justmine !== undefined && justmine !== doubtPost.posts.filters.justmine;

            if (video.learningObject?.id && (doubtPost.posts.hasMore || justmineHasChanged || isRestarting)) {
                if (!!intervalRef.current) {
                    clearInterval(intervalRef.current);

                    intervalRef.current = null;
                }

                return dispatch(
                    postActions.getPostsRequest({
                        ...doubtPost.posts.filters,
                        learningObjectId: video.learningObject?.id,
                        ...(justmine !== undefined && { justmine }),
                        ...((!!isRestarting || justmineHasChanged) && { isRestarting: true })
                    })
                );
            }
        },
        [dispatch, doubtPost.posts.filters, doubtPost.posts.hasMore, video.learningObject]
    );

    useEffect(() => {
        if (topicStatus !== IGetTopicStatus.Inactive && doubtPost.posts.items.length === 0) {
            getPosts();
        }
    }, [doubtPost.posts.items.length, topicStatus, getPosts]);

    useEffect(() => {
        if (
            !!intervalRef.current &&
            (doubtPost.posts.filters.justmine ||
                doubtPost.newPostsQuantity >= 10 ||
                doubtPost.posts.formattedItemsToCheckUpdates.length > 0 ||
                doubtPost.postsToUpdate.length > 0)
        ) {
            clearInterval(intervalRef.current);

            intervalRef.current = null;
        }
    }, [
        doubtPost.newPostsQuantity,
        doubtPost.posts.filters.justmine,
        doubtPost.posts.formattedItemsToCheckUpdates.length,
        doubtPost.postsToUpdate.length
    ]);

    useEffect(() => {
        if (
            !intervalRef.current &&
            !doubtPost.posts.filters.justmine &&
            !!video.learningObject?.id &&
            topicStatus === IGetTopicStatus.Active &&
            doubtPost.newPostsQuantity < 10
        ) {
            intervalRef.current = setInterval(() => {
                dispatch(
                    postActions.getHasUpdatedPostsRequest({
                        learningObjectId: video.learningObject?.id!,
                        posts: doubtPost.posts.formattedItemsToCheckUpdates
                    })
                );

                dispatch(
                    postActions.getHasNewPostsRequest({
                        learningObjectId: video.learningObject?.id!,
                        quantity: doubtPost.posts.filters.quantity
                    })
                );
            }, 20000);
        }
    }, [
        dispatch,
        doubtPost.newPostsQuantity,
        doubtPost.posts.filters.justmine,
        doubtPost.posts.filters.quantity,
        doubtPost.posts.formattedItemsToCheckUpdates,
        topicStatus,
        video.learningObject
    ]);

    useEffect(() => {
        return () => {
            if (!!intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (!!IdPostSpecific) {
            dispatch(postActions.getSpecificPostRequest({ specificPostId: IdPostSpecific }));
        }
    }, [IdPostSpecific, dispatch]);

    useEffect(() => {
        if (!!IdPostSpecific && !!doubtPost.specificPost.data) {
            dispatch(
                modalActions.openModal("confirmAction", {
                    text: <Doubts posts={[doubtPost.specificPost.data!]} isSpecificPostVisualization />,
                    widthContainer: modalWidth,
                    onConfirm: () => dispatch(modalActions.closeModal()),
                    variant: "info",
                    hasIcon: false,
                    buttonText: "Fechar"
                })
            );
        }

        if (!!IdPostSpecific && doubtPost.specificPost.hasError) {
            const anchorStyles = {
                fontWeight: "bold",
                color: `${theme.colors.system["warning"]["300"]}`
            };

            const noticeContent = (
                <p>
                    Infelizmente o conteúdo que você está tentando acessar não está mais disponível, por isso não foi possível exibi-lo. Se você
                    precisar de ajuda com isso pode acessar nossa{" "}
                    <a href={`https://atendimento.${theme.project.slug}.com.br/`} target="_blank" rel="noopener noreferrer" style={anchorStyles}>
                        central de ajuda
                    </a>{" "}
                    e falar com nosso time de relacionamento.
                </p>
            );

            dispatch(
                modalActions.openModal("confirmAction", {
                    type: "OPEN_MODAL",
                    hasCloseButton: false,
                    widthContainer: modalWidth,
                    alignText: "justify",
                    title: "Que pena!",
                    text: noticeContent,
                    variant: "warning",
                    buttonText: "OK",
                    onConfirm: () => dispatch(modalActions.closeModal())
                })
            );
        }
    }, [IdPostSpecific, dispatch, doubtPost.specificPost.data, doubtPost.specificPost.hasError, modalWidth]);

    useEffect(() => {
        if (doubtPost.postsToUpdate.length > 0) {
            dispatch(postActions.getUpdatedPostsRequest({ posts: doubtPost.postsToUpdate }));
        }
    }, [dispatch, doubtPost.postsToUpdate]);

    const handleRefreshPosts = () => {
        if (!!answerQuestionsElementRef.current) {
            const screenPercentageToScroll = 0.2;
            const videoHeight = (Number(GetWidth) / 16) * 9;
            const mobileScreenPercentageToScroll = videoHeight / Number(GetHeight) + screenPercentageToScroll;

            scrollIntoView(
                answerQuestionsElementRef.current,
                {
                    align: {
                        top: GetWidth < 1024 ? mobileScreenPercentageToScroll : screenPercentageToScroll
                    }
                },
                (type: string) => {
                    if (type === "complete") {
                        getPosts(undefined, true);
                    }
                }
            );
        }
    };

    // Answer questions - FINISH

    const requestLessonPlanEvent = useCallback(() => {
        if (!!eventId) {
            dispatch(watchAction.getWatchLessonPlanRequest({ eventId: Number(eventId) }));
        }
    }, [dispatch, eventId]);
    useEffect(() => requestLessonPlanEvent(), [requestLessonPlanEvent]);

    const getRequestBookletModule = useCallback(() => {
        if (isWatchFromSubjects && !!moduleId) {
            dispatch(watchAction.getWatchBookletModuleRequest({ moduleId: moduleId, subjectSlug: subjectSlug }));
        }
    }, [dispatch, isWatchFromSubjects, moduleId, subjectSlug]);
    useEffect(() => getRequestBookletModule(), [getRequestBookletModule]);

    const formatRelatedMaterials = useCallback(() => {
        try {
            if (!watch.materials || !watch.materials.length) {
                return;
            }

            formatMaterials(watch.materials);
        } catch (error) {
            console.log(error);
        }
    }, [watch.materials]);
    useEffect(() => formatRelatedMaterials(), [formatRelatedMaterials]);

    const formatRelatedBooklets = useCallback(() => {
        try {
            if (!watch.booklets || !watch.booklets.length) {
                return;
            }

            formatBooklets(watch.booklets);
        } catch (error) {
            console.log(error);
        }
    }, [watch.booklets]);
    useEffect(() => formatRelatedBooklets(), [formatRelatedBooklets]);

    const formatRelatedExerciseList = useCallback(() => {
        try {
            if (!watch.exerciseLists || !watch.exerciseLists.length) {
                return;
            }

            formatExerciseLists(watch.exerciseLists);
        } catch (error) {
            console.log(error);
        }
    }, [watch.exerciseLists]);
    useEffect(() => formatRelatedExerciseList(), [formatRelatedExerciseList]);

    const formatVideos = useCallback((contentVideo: IContentPerTypeItem[]) => {
        try {
            const videos: IWatchScreenVideo[] = contentVideo
                .filter((item) => !!item.learningObject.video)
                .map((item) => ({
                    ...item.learningObject.video!,
                    learningObject: { id: item.learningObject.id },
                    viewed: item.learningObject.viewed
                }));

            if (!videos || videos.length === 0) {
                throw new Error("Módulo sem video.");
            }

            setRelatedVideos(videos);

            throw new Error("Nenhum vídeo foi informado.");
        } catch (error) {
            console.log(error.message || error);
        }
    }, []);

    const formatLivesVideos = useCallback(
        (relatedLives: IContentPerTypeEvent[]) => {
            try {
                if (!relatedLives) {
                    throw new Error();
                }

                const formatLives = relatedLives
                    .filter((live) => !!live.video)
                    .map((live) => ({
                        eventId: live.id,
                        ...live.video,
                        learningObject: {
                            id: live.video.learningObject?.id,
                            viewed: !!live.video.learningObject?.viewed
                        }
                    }));

                const eventVideo = watch.video;

                const relatedlives = !!eventVideo && !!eventVideo.id ? [eventVideo, ...formatLives] : formatLives;
                setRelatedVideos(relatedlives);
            } catch (error) {
                console.log(error);
            }
        },
        [watch.video]
    );

    const getCorrectFormatVideos = useCallback(() => {
        try {
            if (!!watch.bookletModule && !!eventId) {
                const relatedLives = watch.bookletModule.contentPerType.find((item) => item.type === "Ao Vivo");

                if (!!relatedLives && !!relatedLives.events) {
                    return formatLivesVideos(relatedLives?.events || []);
                }
            }

            if (!!watch.relatedVideos && !!watch.relatedVideos.length) {
                return formatVideos(watch.relatedVideos);
            }
        } catch (error) {
            console.log(error);
        }
    }, [eventId, formatLivesVideos, formatVideos, watch.bookletModule, watch.relatedVideos]);
    useEffect(() => getCorrectFormatVideos(), [getCorrectFormatVideos]);

    const formatSubjectVideo = useCallback(() => {
        try {
            const { contentPerType } = watch.bookletModule;

            if (!contentPerType || contentPerType.length === 0) {
                throw new Error("Módulo sem conteúdo.");
            }

            const contentVideo = contentPerType.find((item) => item.type === "Video");
            const contentMaterial = contentPerType.find((item) => item.type === "Material");
            const contentBooklet = contentPerType.find((item) => item.type === "Apostila");
            const contentExerciseList = contentPerType.find((item) => item.type === "ExerciseList");

            if (!!contentMaterial && contentMaterial.items) {
                formatMaterials(contentMaterial.items);
            }

            if (!!contentExerciseList && contentExerciseList.items) {
                formatExerciseLists(contentExerciseList.items);
            }

            if (!!contentBooklet && contentBooklet.items) {
                formatBooklets(contentBooklet.items);
            }

            if (!contentVideo || contentVideo.items.length === 0) {
                throw new Error("Módulo sem video relacionado.");
            }

            formatVideos(contentVideo.items);
        } catch (error) {
            console.log(error?.message || error);
        }
    }, [formatVideos, watch.bookletModule]);

    const formatData = useCallback(() => {
        if (isWatchFromSubjects) {
            return formatSubjectVideo();
        }
    }, [formatSubjectVideo, isWatchFromSubjects]);
    useEffect(() => formatData(), [formatData]);

    const getVideoRequest = useCallback(() => {
        dispatch(videoActions.getVideoRequest(videoId));
    }, [dispatch, videoId]);
    useEffect(() => getVideoRequest(), [getVideoRequest]);

    const formatExerciseLists = (contentExerciseList: IContentPerTypeItem[]) => {
        const formatExerciseLists = contentExerciseList
            ?.filter((item) => !!item.learningObject.exerciseList)
            ?.map((item) => ({
                ...item.learningObject.exerciseList,
                learningObject: { id: item.learningObject.id }
            }));

        setRelatedExerciseLists(formatExerciseLists);
    };

    const formatMaterials = (contentMaterials: IContentPerTypeItem[]) => {
        const formatMaterials = contentMaterials
            ?.filter((item) => !!item.learningObject.material)
            ?.map((item) => ({
                ...item.learningObject.material,
                learningObject: { id: item.learningObject.id }
            }));

        setRelatedMaterials(formatMaterials);
    };

    const formatBooklets = (contentBooklets: IContentPerTypeItem[]) => {
        const formatBooklets = contentBooklets
            ?.filter((item) => !!item.learningObject.material)
            ?.map((item) => ({
                ...item.learningObject.material,
                learningObject: { id: item.learningObject.id }
            }));

        setRelatedBooklets(formatBooklets);
    };

    const handleMaterial = (material: IRelatedMaterial) => {
        if (!!material?.linkHTML) {
            dispatch(modalActions.openModal("booklet", { linkHTML: material?.linkHTML, title: material.title ?? "Visualizar Material" }));

            return;
        }

        openLinkInBlankPage(material?.url);
    };

    const handleExerciseList = (id?: number) => {
        try {
            if (!id) {
                return dispatch(
                    modalActions.openModal("selectContent", {
                        type: "exerciseList",
                        LO: relatedExerciseLists
                    })
                );
            }

            if (isWatchFromSubjects) {
                return history.push({ pathname: `/app/curso/${course.slug}/materias/${subjectSlug}/${moduleId}/lista-de-exercicios/${id}` });
            }

            return history.push({ pathname: `/app/curso/${course.slug}/lista-de-exercicios/${id}` });
        } catch (error) {
            console.log(error);
        }
    };

    const handleLike = useCallback(
        (id: number, status: LikeStatus) => {
            dispatch(watchAction.setWatchLikeRequest({ id, status }));
        },
        [dispatch]
    );

    const handleViewed = (id: number, status: boolean) => dispatch(videoActions.setVideoViewedRequest({ id, status }));

    const handleGetVideo = useCallback(
        (video: IWatchScreenVideo) => {
            try {
                if (!video) {
                    return;
                }

                // Formata a playlist pro padrao de video relacionado
                const formatData = {
                    ...video,
                    ...(video.playlist && {
                        playlist: video.playlist.map((item: any) => ({
                            ...item.video,
                            viewed: item.viewed,
                            isLiked: item.isLiked
                        }))
                    })
                };

                dispatch(watchAction.getWatchVideoSuccess(formatData));

                const { teachers } = video;

                if (!!teachers && !!teachers.length) {
                    dispatch(watchAction.setWatch({ teachers }));
                }
            } catch (error) {
                console.log(error);

                if (error.response && error.response.status !== HttpStatus.PAYMENT_REQUIRED) {
                    dispatch(alertActions.showAlert("Vídeo não encontrado!", "danger"));
                }

                dispatch(watchAction.getWatchVideoFailure(error));
            }
        },
        [dispatch]
    );

    const currentVideo = useMemo(() => {
        if (!!videoId) {
            return video as any;
        }

        return watch.video;
    }, [video, videoId, watch.video]);

    return (
        <WatchScreenDoubtComponent
            eventStartDate={watch.eventStartDate}
            eventEndDate={watch.eventEndDate}
            video={currentVideo}
            isLoading={lessonPlan.isLoading || watch.isLoading}
            isLoadingAnswerDoubts={doubtPost.topic.isLoading}
            relatedExerciseLists={relatedExerciseLists}
            relatedMaterials={relatedMaterials}
            relatedBooklets={relatedBooklets}
            teachers={watch.teachers}
            relatedVideos={relatedVideos || watch.video.playlist}
            onClickMaterial={handleMaterial}
            onClickExerciseList={handleExerciseList}
            onClickLike={handleLike}
            onClickViewed={handleViewed}
            {...(!!watch.subjectObj && { subjectObj: watch.subjectObj })}
            category={watch.category}
            zoneName={credentials.timezone.zoneName.trim()}
            {...(!!watch.module && { lessonPlanModule: watch.module })}
            hasForum={!!watch?.hasForum}
            hasAnswerDoubts={hasAnswerDoubts(course.slug, theme.project.slug, topicStatus)}
            eventTitle={watch.title}
            routeParams={useParams()}
            onGetVideo={handleGetVideo}
            bookletModule={watch.bookletModule}
            justminePosts={doubtPost.posts.filters.justmine}
            doubtPosts={doubtPost.posts.items}
            handleFetchNextPosts={() => getPosts()}
            newPostsQuantity={doubtPost.newPostsQuantity}
            handleJustmineFilter={(justmine: boolean) => getPosts(justmine)}
            handleRefreshPosts={handleRefreshPosts}
            topicStatus={topicStatus}
            answerQuestionsElementRef={answerQuestionsElementRef}
        />
    );
};

export default memo(WatchScreenDoubt);
