import styled from "styled-components";
import Card from "components/Card";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";

export const ExerciseListQuestionContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    width: 100%;
    margin-bottom: ${theme.spacing.large};

    border-radius: 4px;
    border: 2px solid ${theme.colors.base[100]};
    background: ${theme.colors.white};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        border-left: 8px solid ${theme.colors.brand[300]};
    }
`;

export const ExerciseListQuestionContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.small} ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: ${theme.spacing.small} ${theme.spacing.medium};
    }
`;

export const ExerciseListQuestionEnunciation = styled.div`
    margin-bottom: ${theme.spacing.small};
    position: relative;
    display: flex;
    align-items: center;
`;

export const ExerciseListQuestionCanceled = styled.div`
    box-shadow: ${theme.shadow[1]};
    background: ${theme.colors.base[200]};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.smallX};
    text-align: center;
    letter-spacing: 0.5em;
    margin: 0;
    border-radius: 4px 4px 0 0;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small};
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        border-radius: 0 4px 0 0;
    }

    p {
        font-size: ${theme.typography.sizes.small};
        color: ${theme.colors.base[400]};
        font-weight: bold;
    }
`;

export const ExerciseListQuestionAttachment = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: ${theme.spacing.small};
    padding-top: ${theme.spacing.smallX};
    margin-bottom: ${theme.spacing.small};
    justify-content: space-between;
    border-bottom: 1px solid ${theme.colors.base[100]};

    p {
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
        flex: 1;
        font-weight: 600;

        ${MediaQueries.BIGGER_THAN_SMALL} {
            font-size: ${theme.typography.sizes.medium};
        }
    }

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        text-transform: none;
        color: ${theme.colors.system.info[300]};

        span {
            margin-left: ${theme.spacing.smallX};
            margin-right: ${theme.spacing.smallXX};
        }
    }
`;
