import React, { useEffect, memo, useCallback } from "react";

// Components
import Files from "./files";
import { useSelector, useDispatch } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

const FilesScreen = () => {
    const dispatch = useDispatch();
    const files = useSelector(({ files }: IReduxStore) => files);

    const getFilesCategories = useCallback(() => dispatch({ type: "GET_FILES_CATEGORIES_REQUEST" }), [dispatch]);
    const getFiles = useCallback((page = 1, category?: number) => dispatch({ type: "GET_FILES_REQUEST", payload: { page, category } }), [dispatch]);

    useEffect(() => {
        return () => {
            dispatch({ type: "CLEAR_FILES" });
        };
    }, [dispatch]);

    useEffect(() => {
        getFilesCategories();
    }, [getFilesCategories]);

    useEffect(() => {
        getFiles();
    }, [getFiles]);

    const handleModal = useCallback(
        (linkHTML: string, title: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "booklet",
                action: {
                    linkHTML,
                    title
                }
            });
        },
        [dispatch]
    );

    return <Files {...files} onOpenModal={handleModal} onRequestFiles={getFiles} />;
};

export default memo(FilesScreen);
