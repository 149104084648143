import React, { lazy, memo, Suspense, useMemo, useCallback } from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import history from "services/history";
import _orderBy from "lodash/orderBy";
import _slice from "lodash/slice";

import useIsMobile from "hooks/use-is-mobile";
import { useModal } from "hooks/use-modal";

import Empty from "components/Empty";
import PageHeader from "components/PageHeader";
import EssayDispatchesList from "./components/essayDispatches";
import EssayCardDetailsPlaceholder from "./components/cardDetailsPlaceholder";
import CardDetailsAttachmentsPlaceholder from "./components/cardDetailsAttachmentsPlaceholder";
import { EssayCardDetailsFooter } from "./components/styles";
import { Grid, Row, Col } from "components/Grid";
import MakeEssay from "components/MakeEssay";

import { IReduxStore } from "interfaces/IReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { Types as EssayTypes } from "store/ducks/essay";

import PerformanceChart from "../MyEssays/components/performanceChart";

import { IEssayTheme, IEssayComposition } from "store/interfaces/IEssay";
import { EssayStatus } from "enums/EssayStatus";

import * as S from "./styles";

const EssayCardDetails = lazy(() => import("./components/cardDetails"));
const CardDetailsAttachment = lazy(() => import("./components/cardDetailsAttachments"));

interface IThemeProps extends IEssayTheme, RouteComponentProps<{ id: string }> {}

const EssayTheme = (props: IThemeProps & { isLoadingTheme: boolean }) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const modal = useModal();

    const { themes, courseSlug } = useSelector(({ essay, course }: IReduxStore) => ({
        themes: essay.themes,
        courseSlug: course.slug
    }));

    const { compositions } = props;

    const isMakePage = useMemo(() => props.location.pathname.includes("fazer"), [props.location.pathname]);

    const compositionsFilter = useMemo(() => compositions?.filter((item) => item.status !== EssayStatus.Discarded), [compositions]);

    const chartComponent = useMemo(() => {
        const compositionsDesc = _orderBy(compositionsFilter, ["compositions", "created"], ["asc", "desc"]);
        const lastCompositions = compositionsDesc?.filter((composition: IEssayComposition) => composition.status === EssayStatus.Corrected);
        return _slice(lastCompositions, 0, 5);
    }, [compositionsFilter]);

    const breadcrumb = useMemo(() => {
        if (isMakePage) {
            return {
                current: {
                    label: "Envio de Redação"
                },
                parent: [
                    {
                        label: "Redações",
                        url: `/app/curso/${courseSlug}/redacoes`
                    },
                    {
                        label: "Detalhes do tema",
                        url: `/app/curso/${courseSlug}/redacoes/temas/${props.idTheme}`
                    }
                ]
            };
        }

        return {
            current: {
                label: "Detalhes do tema"
            },
            parent: [
                {
                    label: "Redações",
                    url: `/app/curso/${courseSlug}/redacoes`
                }
            ]
        };
    }, [courseSlug, isMakePage, props.idTheme]);

    // Send file essay
    const handleSend = useCallback(
        (files) => {
            const data = {
                idTheme: props.idTheme,
                title: themes.theme.title || "",
                content: "",
                status: EssayStatus.Draft
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja <strong>salvar</strong> sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () => dispatch({ type: EssayTypes.CREATE_ESSAY_FILE_REQUEST, payload: { files, data } })
            });
        },
        [dispatch, modal, props.idTheme, themes.theme.title]
    );

    // Discard essay
    const handleDiscard = useCallback(() => {
        modal.open("confirmAction", {
            title: "Atenção",
            text: (
                <>
                    Tem certeza que deseja <strong>Descartar</strong> sua redação?
                </>
            ),
            variant: "outline-warning",
            buttonText: "Confirmar",
            onConfirm: () => history.push({ pathname: `/app/curso/${courseSlug}/redacoes` })
        });
    }, [courseSlug, modal]);

    // Send draft essay
    const handleDraft = useCallback(
        (content, image, status) => {
            const data = {
                idTheme: props.idTheme,
                title: themes.theme.title || "",
                content: content || "",
                status: status
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja salvar <strong>em rascunho</strong> sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () => dispatch({ type: EssayTypes.CREATE_ESSAY_IMAGE_REQUEST, payload: { image, data } })
            });
        },
        [dispatch, modal, props.idTheme, themes.theme.title]
    );

    // Send pending essay
    const handleCorrection = useCallback(
        (content, image, status) => {
            const data = {
                idTheme: props.idTheme,
                title: themes.theme.title || "",
                content: content || "",
                status: status
            };

            modal.open("confirmAction", {
                title: "Atenção",
                text: (
                    <>
                        Tem certeza que deseja usar <strong>1 crédito</strong> para enviar sua redação?
                    </>
                ),
                variant: "outline-warning",
                buttonText: "Confirmar",
                onConfirm: () => dispatch({ type: EssayTypes.CREATE_ESSAY_IMAGE_REQUEST, payload: { image, data } })
            });
        },
        [dispatch, modal, props.idTheme, themes.theme.title]
    );

    return (
        <>
            <PageHeader.Simple breadcrumb={breadcrumb} />
            <S.EssayThemeContainer>
                <Grid fluid>
                    {!props.idTheme && !props.isLoadingTheme ? (
                        <Empty message="Tema não encontrado" />
                    ) : (
                        <>
                            <Row>
                                <Col xs={12} sm={6} md={8}>
                                    <Suspense fallback={<EssayCardDetailsPlaceholder />}>
                                        <EssayCardDetails withHelpers={isMobile} {...props} />
                                    </Suspense>
                                </Col>
                                <Col xs={12} sm={6} md={4}>
                                    <EssayCardDetailsFooter>
                                        <Suspense fallback={<CardDetailsAttachmentsPlaceholder />}>
                                            <CardDetailsAttachment id={props.idTheme} support={props.pathFile} withButton={!isMakePage} />
                                        </Suspense>
                                    </EssayCardDetailsFooter>
                                </Col>
                            </Row>
                            {!!chartComponent?.length && (
                                <Route exact={true} path="/app/curso/courseSlug:/redacoes/temas/:id">
                                    <Row>
                                        <Col xs={12}>
                                            <S.EssayThemeMyPerformanceTitle>Desempenho em redação</S.EssayThemeMyPerformanceTitle>
                                            <S.EssayThemeMyPerformance>
                                                <PerformanceChart compositions={chartComponent} />
                                            </S.EssayThemeMyPerformance>
                                        </Col>
                                    </Row>
                                </Route>
                            )}
                            <Row>
                                <Switch>
                                    <Route exact={true} path="/app/curso/courseSlug:/redacoes/temas/:id">
                                        <EssayDispatchesList compositions={compositionsFilter} />
                                    </Route>
                                    <Route exact={true} path="/app/curso/courseSlug:/redacoes/temas/:id/fazer">
                                        <Col sm={12}>
                                            <S.MakeEssayCanvasWrapper>
                                                <MakeEssay
                                                    handleSendFile={handleSend}
                                                    handleDiscard={handleDiscard}
                                                    handleDraft={handleDraft}
                                                    handleSendCorrection={handleCorrection}
                                                    isBlockedOnMobile
                                                />
                                            </S.MakeEssayCanvasWrapper>
                                        </Col>
                                    </Route>
                                </Switch>
                            </Row>
                        </>
                    )}
                </Grid>
            </S.EssayThemeContainer>
        </>
    );
};

export default withRouter(memo(EssayTheme));
