import React from "react";

// Assets
import * as E from "./styles";

interface IEmptyProps {
    message: string;
}

const Empty = ({ message }: IEmptyProps) => {
    return (
        <E.EmptyContainer>
            <E.EmptyText>{message}</E.EmptyText>
        </E.EmptyContainer>
    );
};

export default Empty;
