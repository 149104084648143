// Dependencies
import { all, takeEvery, takeLatest } from "redux-saga/effects";

// Redux Types
import { Types as AnnouncementTypes } from "../ducks/announcement";

import { Types as ContentSearchTypes } from "../ducks/contentSearch";
import { Types as LearningObjectTypes } from "../ducks/learning-object";
import { Types as LessonPlanTypes } from "../ducks/lesson-plan";
import { Types as ProjectTypes } from "../ducks/project";
import { Types as ResolutionTypes } from "../ducks/resolution";
import { Types as VideoTypes } from "../ducks/video";
import { Types as WatchTypes } from "../ducks/watch";

// Sagas
import auth from "./auth";
import exam from "./exam";
import file from "./file";
import essay from "./essay";
import forum from "./forum";
import course from "./course";
import subject from "./subject";
import answerCard from "./answerCard";
import mentorship from "./mentorship";
import credentials from "./credentials";
import studentArea from "./student-area";
import previousExam from "./previousExam";
import exerciseList from "./exerciseList";
import bookletmodules from "./bookletmodule";
import weekPlan from "./weekPlan";
import lessonPlanCustom from "./lessonPlanCustom";
import qrCode from "./qrCode";
import alert from "./alert";
import exerciseListCustom from "./exerciseListCustom";
import notification from "./notification";
import doubtPost from "./doubtPost";
import lessonFreeCTA from "./lessonFreeCTA";
import activity from "./activity";
import { createOrEditAnnouncement, getAnnouncements } from "./announcements";
import { getAllContents } from "./contentSearch";
import { errorHandler } from "./errorHandler";
import { patchLike as patchLikeLearningObject, patchView as patchViewLearningObject } from "./learning-object";
import {
    getLessonPlan,
    getLessonPlanEvent,
    getLessonPlanModule,
    setModule,
    getLessonPlanEventMeetingRoomRequest,
    getLessonPlanServerDateRequest
} from "./lesson-plan";
import { getAllProjects, getProject, getProjectForStudy, setContentAsCompleted } from "./project";
import { getById as getResolution } from "./resolution";
import { getWatchBookletModule, getWatchLessonPlan, getWatchVideo, setWatchLike } from "./watch";
import { monitor, monitorableRequestActions } from "./monitor";
import { getVideo, getVideoById, createVideoView, getVideoUserIp, saveVideoProgress, markVideoView, setVideoViewed, reportVideoError } from "./video";

const monitorableAction = (action: any) => action.type.includes("FAILURE");

export default function* rootSaga() {
    yield all([
        ...activity,
        ...alert,
        ...answerCard,
        ...auth,
        ...bookletmodules,
        ...course,
        ...credentials,
        ...doubtPost,
        ...essay,
        ...exerciseList,
        ...exerciseListCustom,
        ...exam,
        ...file,
        ...forum,
        ...lessonFreeCTA,
        ...lessonPlanCustom,
        ...mentorship,
        ...notification,
        ...previousExam,
        ...qrCode,
        ...studentArea,
        ...subject,
        ...weekPlan,
        takeLatest(AnnouncementTypes.GET_ANNOUNCEMENTS_REQUEST, getAnnouncements),
        takeLatest(AnnouncementTypes.CREATE_OR_EDIT_ANNOUNCEMENTS_REQUEST, createOrEditAnnouncement),
        takeLatest(ContentSearchTypes.GET_CONTENT_REQUEST, getAllContents),
        takeLatest(LearningObjectTypes.PATCH_LIKE_LEARNING_OBJECT_REQUEST, patchLikeLearningObject),
        takeLatest(LearningObjectTypes.PATCH_VIEW_LEARNING_OBJECT_REQUEST, patchViewLearningObject),
        takeLatest(LessonPlanTypes.SET_MODULE_REQUEST, setModule),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_REQUEST, getLessonPlan),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_MODULE_REQUEST, getLessonPlanModule),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_EVENT_REQUEST, getLessonPlanEvent),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_EVENT_MEETING_ROOM_REQUEST, getLessonPlanEventMeetingRoomRequest),
        takeLatest(LessonPlanTypes.GET_LESSON_PLAN_SERVER_DATE_REQUEST, getLessonPlanServerDateRequest),
        takeLatest(ProjectTypes.GET_PROJECTS_REQUEST, getAllProjects),
        takeLatest(ProjectTypes.GET_PROJECT_DETAIL_REQUEST, getProject),
        takeLatest(ProjectTypes.GET_PROJECT_FOR_STUDY_REQUEST, getProjectForStudy),
        takeLatest(ProjectTypes.SET_CONTENT_AS_COMPLETED_REQUEST, setContentAsCompleted),
        takeLatest(ResolutionTypes.SET_RESOLUTION_REQUEST, getResolution),
        takeLatest(VideoTypes.GET_VIDEO_REQUEST, getVideo),
        takeLatest(VideoTypes.GET_VIDEO_BY_PLAYER_REQUEST, getVideoById),
        takeLatest(VideoTypes.CREATE_VIDEO_VIEW_REQUEST, createVideoView),
        takeLatest(VideoTypes.GET_VIDEO_USER_IP_REQUEST, getVideoUserIp),
        takeLatest(VideoTypes.SAVE_VIDEO_PROGRESS_REQUEST, saveVideoProgress),
        takeLatest(VideoTypes.MARK_VIDEO_VIEW_REQUEST, markVideoView),
        takeLatest(VideoTypes.REPORT_VIDEO_ERROR_REQUEST, reportVideoError),
        takeLatest(VideoTypes.SET_VIDEO_VIEWED_REQUEST, setVideoViewed),
        takeLatest(WatchTypes.GET_WATCH_BOOKLET_MODULE_REQUEST, getWatchBookletModule),
        takeLatest(WatchTypes.GET_WATCH_VIDEO_REQUEST, getWatchVideo),
        takeLatest(WatchTypes.SET_WATCH_LIKE_REQUEST, setWatchLike),
        takeLatest(WatchTypes.GET_WATCH_LESSON_PLAN_REQUEST, getWatchLessonPlan),
        takeEvery(monitorableRequestActions, monitor),
        takeEvery(monitorableAction, errorHandler)
    ]);
}
