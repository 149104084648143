import { ExamDayStatus } from "enums/ExamDayStatus";
import { theme } from "config/theme";
import formatDate from "utils/formatDate";

export function getExamDayStatus(data: { status?: ExamDayStatus; startDate: string; endDate: string; isAvailable?: boolean }) {
    const { status, startDate, endDate, isAvailable = false } = data;

    const caseStatus = isAvailable || status;

    switch (caseStatus) {
        case isAvailable:
            return {
                text: "Liberado para execução sem nota",
                color: theme.colors.system.success[300]
            };

        case ExamDayStatus.CommingSoon:
            return {
                text: `Em Breve | ${formatDate(startDate, "dd/MM")} a ${formatDate(endDate, "dd/MM")}`,
                color: theme.colors.system.warning[300]
            };

        case ExamDayStatus.InProgress:
            return {
                text: `Em aplicação | ${formatDate(startDate, "dd/MM")} a ${formatDate(endDate, "dd/MM")}`,
                color: theme.colors.system.success[300]
            };

        case ExamDayStatus.WaitingFinish:
            return {
                text: "Prova realizada - Aguardando encerramento da aplicação",
                color: theme.colors.base[300]
            };

        case ExamDayStatus.Realized:
            return {
                text: `Aplicado em ${formatDate(endDate, "dd/MM/yyyy")} ${theme.project.slug === "proenem" ? " - Aguardando correção" : ""}`,
                color: theme.colors.system.info[300]
            };

        default:
            return null;
    }
}
