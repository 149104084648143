import { theme } from "config/theme";
import styled from "styled-components";
import UserIcon from "assets/img/ico-user.svg";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
`;
export const Content = styled.div`
    display: flex;
    gap: 12px;
`;

export const AvatarProfile = styled.figure<{ avatar?: string }>`
    width: 40px;
    height: 40px;
    border-radius: 400px;
    background-color: ${theme.colors.brand[100]};
    background-image: ${({ avatar }) => `url(${avatar ?? UserIcon})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: ${({ avatar }) => (avatar ? "cover" : "50%")};
`;

export const Textarea = styled.textarea<{ height: any }>`
    width: 100%;
    min-height: 78px;
    height: ${({ height }) => `${height}px`};
    resize: none;
    padding: 14px 10px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.base[100]};
    overflow: hidden;
    color: ${theme.colors.base[400]};
`;
