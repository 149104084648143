// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityQuestionOptionActionButtonAlignment } from "./activity-questions.types";

export const QuestionsContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
`;

export const QuestionOptionContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 12px;
`;

export const QuestionOptionLabel = styled.p`
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: #232326;

    @media (min-width: 1024px) {
        font-weight: 600;
    }

    img {
        max-width: 100%;
    }
`;

export const QuestionOptionContentCompositionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
`;

type QuestionOptionActionButtonCompositionsWrapperStyleProps = {
    alignment: ActivityQuestionOptionActionButtonAlignment;
};

export const QuestionOptionActionButtonCompositionsWrapper = styled.div<QuestionOptionActionButtonCompositionsWrapperStyleProps>`
    width: 100%;

    display: flex;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;

    > div {
        flex-shrink: 0;
    }

    ${({ alignment }) =>
        alignment === "under" &&
        css`
            justify-content: flex-start;
        `}

    ${({ alignment }) =>
        alignment === "side" &&
        css`
            justify-content: flex-end;
        `}
`;
