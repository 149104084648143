// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceDashboardProps } from "./performance-dashboard.types";

// Styles
import { Container, ContentWrapper } from "./performance-dashboard.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";

export const PerformanceDashboard: FunctionComponent<PerformanceDashboardProps> = ({ bannerSection, resumeSection, evolutionSection }) => {
    const sectionDivider = (
        <>
            <ScreenWidthRender actionAfterRenderingWidth="show" renderingWidth={1024} content={<Spacing direction="vertical" size={48} />} />

            <ScreenWidthRender actionAfterRenderingWidth="hide" renderingWidth={1024} content={<Spacing direction="vertical" size={32} />} />
        </>
    );

    return (
        <Container>
            <ContentWrapper>
                {bannerSection}

                {sectionDivider}

                {resumeSection}

                {sectionDivider}

                {evolutionSection}
            </ContentWrapper>
        </Container>
    );
};
