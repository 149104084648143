import React, { memo, useContext, useCallback, useMemo, useEffect } from "react";

// Components
import Start from "./components/start";
import Wizard from "components/Wizard";
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Finish from "./components/finish";

// Helpers
import { MigrationUserContext } from "./context";
import { MigrationUserStepSlugType } from "./types";
import { ICredentials } from "store/interfaces/ICredentials";

// Assets
import { Grid } from "components/Grid";
import LogoProenem from "static/proenem/logo.svg";
import LogoProdigio from "static/logo-prodigio.svg";
import LogoPromilitares from "static/promilitares/logo.svg";
import { MigrationUserContainer, ProdigioChildrens, ProdigioLogos } from "./styles";

// Redux
import { useSelector, useDispatch } from "react-redux";

// Helpers
import { IReduxStore } from "interfaces/IReduxStore";
import { MigrationUserProvider } from "screens/MigrationUser/context";

type MigrationUserProps = {
    credentials: ICredentials;
};

const steps = new Map<MigrationUserStepSlugType, React.FC>([
    ["START", Start],
    ["USER_DETAILS", Step1],
    ["ADDRESS", Step2],
    ["CONFIRM_DATA", Step3],
    ["FINISH", Finish]
]);

const MigrationUser = ({ credentials }: MigrationUserProps) => {
    const { state, dispatch } = useContext(MigrationUserContext);

    useEffect(() => {
        if (Boolean(credentials.id) && !Boolean(state.credentials)) {
            dispatch({ type: "SET_CREDENTIALS", payload: credentials });
        }
    }, [credentials, dispatch, state.credentials]);

    const handleChangeWizardStep = useCallback(
        (slug) => {
            const isCompleted = state.completeds.includes(slug);

            if (!isCompleted) {
                return;
            }

            const step = state.steps.find((item) => item.slug === slug);

            dispatch({ type: "SET_CURRENT_STEP", payload: step });
        },
        [dispatch, state.completeds, state.steps]
    );

    const wizzardSteps = useMemo(() => state.steps.filter((step) => step.slug !== "START" && step.slug !== "FINISH"), [state.steps]);

    const Step = useMemo(() => {
        const Element = steps.get(state.currentWizardStep.slug);

        if (!Element) {
            return null;
        }

        return <Element />;
    }, [state.currentWizardStep.slug]);

    const hideNavigation = useMemo(() => {
        if (state.currentWizardStep.slug !== "START" && state.currentWizardStep.slug !== "FINISH") {
            return false;
        }

        return true;
    }, [state.currentWizardStep.slug]);

    return (
        <MigrationUserContainer>
            <Grid fluid>
                <ProdigioLogos>
                    <img src={LogoProdigio} alt="Prodígio Educação" />
                    <ProdigioChildrens>
                        <img src={LogoProenem} alt="Proenem" />
                        <span />
                        <img src={LogoPromilitares} alt="Promilitares" />
                    </ProdigioChildrens>
                </ProdigioLogos>
                <Wizard<MigrationUserStepSlugType>
                    completeds={state.completeds}
                    current={state.currentWizardStep}
                    hideNavigation={hideNavigation}
                    options={wizzardSteps}
                    onChangeStep={handleChangeWizardStep}
                >
                    {Step}
                </Wizard>
            </Grid>
        </MigrationUserContainer>
    );
};

const MemoizedMigrationUser = memo(MigrationUser);

const MigrationUserWrapper = () => {
    const { auth, credentials } = useSelector(({ auth, credentials }: IReduxStore) => ({ auth, credentials }));
    const dispatch = useDispatch();

    const checkUserSession = useCallback(() => {
        if (!auth.token) {
            dispatch({ type: "LOGOUT" });
        }
    }, [auth.token, dispatch]);

    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    return (
        <MigrationUserProvider>
            <MemoizedMigrationUser credentials={credentials} />
        </MigrationUserProvider>
    );
};

export default memo(MigrationUserWrapper);
