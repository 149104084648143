import { theme } from "config/theme";
import styled from "styled-components/macro";
import { Button3, Micro } from "assets/styles/global";

export const UploaderContainer = styled.div`
    overflow: hidden;
    text-align: center;
`;

export const UploaderHelper = styled.span`
    ${Button3};
    color: ${theme.colors.base[400]};
    display: block;
    margin-top: ${theme.spacing.small};
    text-align: center;
`;

export const UploaderTxt = styled.span`
    ${Micro};
    color: ${theme.colors.base[200]};
    display: block;
    margin-top: ${theme.spacing.small};
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const UploaderFileName = styled(UploaderTxt as any)`
    color: ${theme.colors.base[400]};
    margin: ${theme.spacing.smallX} 0;
`;

export const CloseButton = styled.button`
    padding: ${theme.spacing.smallX};
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
`;

export const InputWrapper = styled.div`
    position: relative;

    > input {
        appearance: none;
        cursor: pointer;
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
    }
`;
