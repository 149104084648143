// Dependencies
import styled, { css } from "styled-components";

// Types
import { ActivityHeaderTitleVariant } from "./activity-header.types";

export const Container = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;

    > .webapp-activity-button {
        flex-shrink: 0;
    }
`;

export const TitleWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

type TitleStyleProps = {
    variant: ActivityHeaderTitleVariant;
};

export const Title = styled.p<TitleStyleProps>`
    font-family: "Nunito", sans-serif;
    font-style: normal;
    color: #232326;

    ${({ variant }) =>
        variant === "small" &&
        css`
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
        `}

    ${({ variant }) =>
        variant === "default" &&
        css`
            font-size: 18px;
            line-height: 26px;
            font-weight: 600;
        `}

    ${({ variant }) =>
        variant === "large" &&
        css`
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
        `}
`;

export const Description = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #232326;
`;
