import React, { useState } from "react";
import { WebpButton } from "components/WebpButton";
import { WebpNewCardSelected } from "components/WebpNewCardSelected";
import { ContentTitle, Wrapper } from "./styles";
import { WebpModalKnowMore } from "components/WebpModalKnowMore";
import { WebpCard } from "components/WebpCard";

import { dataCardSelected, dataKnowMore } from "./assets/data";

interface IProps {
    onHandleSelectedPlan: (slug: string) => void;
    selectedPlan: string;
}

export const SelectedStartedCareer = ({ onHandleSelectedPlan, selectedPlan }: IProps) => {
    const [visibleModalKnow, setVisibleModalKnow] = useState<boolean>(true);
    const [numberKnowMore, setnNumberKnowMore] = useState<number>(0);

    const handleClickCard = (slug: string) => {
        onHandleSelectedPlan(slug);
    };

    const handleKnowMore = (position: number) => {
        setnNumberKnowMore(position);
        setVisibleModalKnow(false);
    };

    return (
        <Wrapper>
            <WebpCard
                headerComposition={
                    visibleModalKnow ? (
                        <ContentTitle>
                            <h2 className="title-section">Como você pretende estudar?</h2>
                        </ContentTitle>
                    ) : (
                        <div className="modal-knowMore">
                            <WebpModalKnowMore
                                titleSection={dataKnowMore[numberKnowMore].titleSection}
                                variant={dataKnowMore[numberKnowMore].variant as any}
                                onClickButtonBack={() => setVisibleModalKnow(true)}
                                descriptionComposition={<div dangerouslySetInnerHTML={{ __html: dataKnowMore[numberKnowMore].description }}></div>}
                                actionComposition={
                                    <WebpButton
                                        variant="outline-info"
                                        onClick={() => window.open(dataKnowMore[numberKnowMore].action, "_blank")}
                                        contentButtonComposition={"Dúvidas? Fale com a gente"}
                                    ></WebpButton>
                                }
                            />
                        </div>
                    )
                }
                {...(visibleModalKnow && {
                    contentComposition: (
                        <div className="content-card">
                            {dataCardSelected.map((item, n) => (
                                <WebpNewCardSelected
                                    key={item.id}
                                    img={item.image}
                                    labelTitle={item.labelTitle}
                                    desc={item.description}
                                    labelButton={item.labelButton}
                                    variant={item.variant as any}
                                    active={item.slug === selectedPlan}
                                    buttonKnowMore={item.buttonKnowMore}
                                    onClickButton={() => handleClickCard(item.slug)}
                                    onClickButtonKnowMore={() => handleKnowMore(n)}
                                />
                            ))}
                        </div>
                    )
                })}
            ></WebpCard>
        </Wrapper>
    );
};
