import { IResolution } from "interfaces/IResolution";
import { createActions, createReducer } from "reduxsauce";

export interface IResolutionState {
    isLoading: boolean;
    items: IResolution[];
}

export const { Creators, Types } = createActions({
    resetResolution: [],
    setResolutionRequest: ["payload"],
    setResolutionSuccess: ["payload"],
    setResolutionFailure: ["payload"]
});

export const INITIAL_STATE: IResolutionState = {
    isLoading: false,
    items: []
};

const setResolutionRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true,
    items: []
});

const setResolutionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload.data
});

const setResolutionFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer(INITIAL_STATE, {
    [Types.SET_RESOLUTION_REQUEST]: setResolutionRequest,
    [Types.SET_RESOLUTION_SUCCESS]: setResolutionSuccess,
    [Types.SET_RESOLUTION_FAILURE]: setResolutionFailure
});
