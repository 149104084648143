import React from "react";

// Dependencies

// Assets
import { BackToIcon, BackToContainer } from "./styles";

interface IProps {
    to: string;
    children: React.ReactNode;
}

const BackTo = ({ to, children }: IProps) => (
    <BackToContainer to={to}>
        <BackToIcon width="8" height="14" />
        {children}
    </BackToContainer>
);

export default BackTo;
