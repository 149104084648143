import styled from "styled-components";
import { WebpFilterList } from "components/WebpFilterList";

export const FilterListWrapper = styled(WebpFilterList)`
    overflow-y: auto;
    overflow-x: hidden;
    max-width: 100%;

    height: 509px;
    @media (min-width: 1200px) {
        height: 300px;
        width: 256px;
    }
`;
