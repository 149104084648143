// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

type WebpStudyPlanPeriodDayProps = {
    labelDay: "Seg" | "Ter" | "Qua" | "Qui" | "Sex" | "Sáb" | "Dom";
    buttonIconComposition: ReactNode;
};

export const WebpStudyPlanPeriodDay: FunctionComponent<WebpStudyPlanPeriodDayProps> = ({ labelDay, buttonIconComposition }) => {
    return (
        <Container className="webp-study-plan-period-day">
            <h2 className="nameDay">{labelDay}</h2>
            <div className="contentButtons">{buttonIconComposition}</div>
        </Container>
    );
};
