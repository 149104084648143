import { Action, ActionCreatorsMapObject } from "redux";
import { createActions, createReducer } from "reduxsauce";
import { CreditType } from "enums/CreditType";

export interface IStudent {
    id: number;
    slug: string;
    name: string;
    lastName: string | null;
    gender?: any;
    cpf: string;
    birthDate?: string;
    phone: string;
    imageProfile?: any;
    contactEmail?: string;
    observation?: any;
    addresses: IStudentAddresses[];
    timezone: {
        id: number;
        countryCode: string;
        zoneName: string;
        utcOffset: string;
        utcDstOffset: string;
    };
    subscriptions: ISubscription[];
    financialData: { transactions: any; creditCards: any };
}

export interface IStudentAddresses {
    id: number;
    label: string;
    address: {
        id: number;
        zipCode: string;
        complement: string;
        street: string;
        number: string;
        city: string;
        state: string;
        neighborhood: string;
    };
}

export enum PeriodicityType {
    Subscription = 1,
    Month = 2,
    Week = 3
}

export interface ISubscriptionCredit {
    course?: {
        id: number;
        name: string;
        slug: string;
    };
    project?: {
        id: number;
        isPublished: boolean;
        name: string;
    };
    credit: {
        id: number;
        description: string;
        type: CreditType;
    };

    startDate: string;
    endDate: string;
    periodicity: PeriodicityType;
    id: number;
    quantity: number;
}

export interface ISubscription {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    isActive: boolean;
    credits: ISubscriptionCredit[];
}

export interface IOrderAddressLess {
    id: number;
    code: string;
    details: Array<{
        id: number;
        product: {
            name: string;
        };
    }>;
}

export interface IStudentAreaState extends IStudent {
    isLoading: boolean;
    orderAddressLess: IOrderAddressLess[];
    ordersFixeds: string[];
    email: string;
    code: string;
    error: boolean;
    isSuccess: boolean;
    confirmEmail: string;
}

export const INITIAL_STATE: IStudentAreaState = {
    isLoading: true,
    id: 0,
    name: "",
    lastName: "",
    slug: "",
    cpf: "",
    phone: "",
    contactEmail: "",
    imageProfile: "",
    addresses: [
        {
            id: 0,
            label: "",
            address: {
                id: 0,
                zipCode: "",
                complement: "",
                street: "",
                number: "",
                city: "",
                state: "",
                neighborhood: ""
            }
        }
    ],
    timezone: {
        id: 0,
        countryCode: "",
        zoneName: "",
        utcOffset: "",
        utcDstOffset: ""
    },
    subscriptions: [],
    financialData: { transactions: [], creditCards: [] },
    orderAddressLess: [],
    ordersFixeds: [],
    email: "",
    code: "",
    error: false,
    isSuccess: false,
    confirmEmail: ""
};

export type StudentState = typeof INITIAL_STATE;

interface IStudentTypes {
    STUDENT_REQUEST: "STUDENT_REQUEST";
    STUDENT_SUCCESSED: "STUDENT_SUCCESSED";
    STUDENT_FAILURE: "STUDENT_FAILURE";

    STUDENT_SAVE_REQUEST: "STUDENT_SAVE_REQUEST";
    STUDENT_SAVE_FAILURE: "STUDENT_SAVE_FAILURE";

    STUDENT_SUBSCRIPTIONS_REQUEST: "STUDENT_SUBSCRIPTIONS_REQUEST";
    STUDENT_SUBSCRIPTIONS_SUCCESSED: "STUDENT_SUBSCRIPTIONS_SUCCESSED";
    STUDENT_SUBSCRIPTIONS_FAILURE: "STUDENT_SUBSCRIPTIONS_FAILURE";

    STUDENT_FINANCIAL_DATA_REQUEST: "STUDENT_FINANCIAL_DATA_REQUEST";
    STUDENT_FINANCIAL_DATA_SUCCESSED: "STUDENT_FINANCIAL_DATA_SUCCESSED";
    STUDENT_FINANCIAL_DATA_FAILURE: "STUDENT_FINANCIAL_DATA_FAILURE";

    STUDENT_PHOTO_UPLOAD_REQUEST: "STUDENT_PHOTO_UPLOAD_REQUEST";
    STUDENT_PHOTO_UPLOAD_SUCCESSED: "STUDENT_PHOTO_UPLOAD_SUCCESSED";
    STUDENT_PHOTO_UPLOAD_FAILURE: "STUDENT_PHOTO_UPLOAD_FAILURE";

    STUDENT_SAVE_PASSWORD_REQUEST: "STUDENT_SAVE_PASSWORD_REQUEST";
    STUDENT_SAVE_PASSWORD_SUCCESSED: "STUDENT_SAVE_PASSWORD_SUCCESSED";
    STUDENT_SAVE_PASSWORD_FAILURE: "STUDENT_SAVE_PASSWORD_FAILURE";

    STUDENT_SAVE_PASSWORD_CONFIRM_REQUEST: "STUDENT_SAVE_PASSWORD_CONFIRM_REQUEST";
    STUDENT_SAVE_PASSWORD_CONFIRM_SUCCESS: "STUDENT_SAVE_PASSWORD_CONFIRM_SUCCESS";
    STUDENT_SAVE_PASSWORD_CONFIRM_FAILURE: "STUDENT_SAVE_PASSWORD_CONFIRM_FAILURE";

    GET_ORDER_ADDRESSLESS_REQUEST: "GET_ORDER_ADDRESSLESS_REQUEST";
    GET_ORDER_ADDRESSLESS_SUCCESS: "GET_ORDER_ADDRESSLESS_SUCCESS";
    GET_ORDER_ADDRESSLESS_FAILURE: "GET_ORDER_ADDRESSLESS_FAILURE";

    CREATE_ORDER_ADDRESS_REQUEST: "CREATE_ORDER_ADDRESS_REQUEST";
    CREATE_ORDER_ADDRESS_SUCCESS: "CREATE_ORDER_ADDRESS_SUCCESS";
    CREATE_ORDER_ADDRESS_FAILURE: "CREATE_ORDER_ADDRESS_FAILURE";

    CLEAR_STUDENT_AREA: "CLEAR_STUDENT_AREA";
}

export interface IChangePassword {
    oldPassword: string;
    newPassword: string;
    passwordConfirmation: string;
}

export interface IStudentStatusCode {
    status: number;
}

export interface IConfirmChangePassword {
    email?: string;
    code?: string;
}

export interface IStudentActionCreators extends ActionCreatorsMapObject {
    studentRequest(state?: StudentState): any;
    studentSuccessed(action: any, state?: StudentState): any;
    studentFailure(state?: StudentState): any;

    studentSaveRequest(action: any, state?: StudentState): any;
    studentSaveFailure(state?: StudentState): any;

    studentSubscriptionsRequest(state?: StudentState): any;
    studentSubscriptionsSuccessed(action: any, state?: StudentState): any;
    studentSubscriptionsFailure(state?: StudentState): any;

    studentFinancialDataRequest(state?: StudentState): any;
    studentFinancialDataSuccessed(action: any, state?: StudentState): any;
    studentFinancialDataFailure(state?: StudentState): any;

    studentPhotoUploadRequest(state?: FormData): any;
    studentPhotoUploadSuccessed(state?: FormData): any;
    studentPhotoUploadFailure(state?: FormData): any;

    studentSavePasswordRequest(action: IChangePassword, state?: StudentState): Action<StudentState>;
    studentSavePasswordSuccessed(action: string, state?: StudentState): any;
    studentSavePasswordFailure(state?: StudentState): Action<StudentState>;

    studentSavePasswordConfirmRequest(action: IConfirmChangePassword, state?: StudentState): Action<StudentState>;
    studentSavePasswordConfirmSuccess(action: IConfirmChangePassword, state?: StudentState): any;
    studentSavePasswordConfirmFailure(state?: StudentState): Action<StudentState>;
}

export const { Creators, Types } = createActions<IStudentTypes, IStudentActionCreators>({
    studentRequest: ["payload"],
    studentSuccessed: ["payload"],
    studentFailure: ["payload"],

    studentSaveRequest: ["payload"],
    studentSaveFailure: ["payload"],

    studentSubscriptionsRequest: ["payload"],
    studentSubscriptionsSuccessed: ["payload"],
    studentSubscriptionsFailure: ["payload"],

    studentFinancialDataRequest: ["payload"],
    studentFinancialDataSuccessed: ["payload"],
    studentFinancialDataFailure: ["payload"],

    studentPhotoUploadRequest: ["payload"],
    studentPhotoUploadSuccessed: ["payload"],
    studentPhotoUploadFailure: ["payload"],

    studentSavePasswordRequest: ["payload"],
    studentSavePasswordSuccessed: ["payload"],
    studentSavePasswordFailure: ["payload"],

    studentSavePasswordConfirmRequest: ["payload"],
    studentSavePasswordConfirmSuccess: ["payload"],
    studentSavePasswordConfirmFailure: ["payload"],

    getOrderAddresslessRequest: [],
    getOrderAddresslessSuccess: ["payload"],
    getOrderAddresslessFailure: ["payload"],

    createOrderAddressRequest: ["payload"],
    createOrderAddressSuccess: ["payload"],
    createOrderAddressFailure: ["payload"],

    clearStudentArea: []
});

const studentRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const studentSuccessed = (state = INITIAL_STATE, action: any): StudentState => {
    const { id, name, lastName, cpf, phone, imageProfile, contactEmail, addresses } = action.payload;

    return {
        ...state,
        id,
        name,
        lastName,
        cpf,
        phone,
        imageProfile,
        contactEmail,
        addresses,
        isLoading: false
    };
};
const studentFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });
const studentSaveRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const studentSaveFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });
const studentSubscriptionsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const studentSubscriptionsSuccessed = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        subscriptions: action.payload,
        isLoading: false
    };
};
const studentSubscriptionsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});
const studentFinancialDataRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});
const studentFinancialDataSuccessed = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        financialData: action.payload.data,
        isLoading: false
    };
};
const studentFinancialDataFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});
const studentPhotoUploadRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});
const studentPhotoUploadSuccessed = (state = INITIAL_STATE, action: any) => ({
    ...state,
    imageProfile: action.payload.data,
    isLoading: false
});
const studentPhotoUploadFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});
const studentSavePasswordRequest = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: true
});
const studentSavePasswordSuccessed = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    isSuccess: true,
    confirmEmail: action.payload
});
const studentSavePasswordFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});
const studentSavePasswordConfirmRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true
});
const studentSavePasswordConfirmSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    email: action.payload.data,
    code: action.payload.data,
    isLoading: false
});
const studentSavePasswordConfirmFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: true,
    error: true
});
const getOrderAddresslessSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    orderAddressLess: action.payload
});

const createAddressSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    ordersFixeds: [...state.ordersFixeds, action.payload.code]
});

const clearStudentArea = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

export default createReducer(INITIAL_STATE, {
    [Types.STUDENT_REQUEST]: studentRequest,
    [Types.STUDENT_SUCCESSED]: studentSuccessed,
    [Types.STUDENT_FAILURE]: studentFailure,

    [Types.STUDENT_SAVE_REQUEST]: studentSaveRequest,
    [Types.STUDENT_SAVE_FAILURE]: studentSaveFailure,

    [Types.STUDENT_SUBSCRIPTIONS_REQUEST]: studentSubscriptionsRequest,
    [Types.STUDENT_SUBSCRIPTIONS_SUCCESSED]: studentSubscriptionsSuccessed,
    [Types.STUDENT_SUBSCRIPTIONS_FAILURE]: studentSubscriptionsFailure,

    [Types.STUDENT_FINANCIAL_DATA_REQUEST]: studentFinancialDataRequest,
    [Types.STUDENT_FINANCIAL_DATA_SUCCESSED]: studentFinancialDataSuccessed,
    [Types.STUDENT_FINANCIAL_DATA_FAILURE]: studentFinancialDataFailure,

    [Types.STUDENT_PHOTO_UPLOAD_REQUEST]: studentPhotoUploadRequest,
    [Types.STUDENT_PHOTO_UPLOAD_SUCCESSED]: studentPhotoUploadSuccessed,
    [Types.STUDENT_PHOTO_UPLOAD_FAILURE]: studentPhotoUploadFailure,

    [Types.STUDENT_SAVE_PASSWORD_REQUEST]: studentSavePasswordRequest,
    [Types.STUDENT_SAVE_PASSWORD_SUCCESSED]: studentSavePasswordSuccessed,
    [Types.STUDENT_SAVE_PASSWORD_FAILURE]: studentSavePasswordFailure,

    [Types.STUDENT_SAVE_PASSWORD_CONFIRM_REQUEST]: studentSavePasswordConfirmRequest,
    [Types.STUDENT_SAVE_PASSWORD_CONFIRM_SUCCESS]: studentSavePasswordConfirmSuccess,
    [Types.STUDENT_SAVE_PASSWORD_CONFIRM_FAILURE]: studentSavePasswordConfirmFailure,

    [Types.GET_ORDER_ADDRESSLESS_REQUEST]: studentRequest,
    [Types.GET_ORDER_ADDRESSLESS_SUCCESS]: getOrderAddresslessSuccess,
    [Types.GET_ORDER_ADDRESSLESS_FAILURE]: studentFailure,

    [Types.CREATE_ORDER_ADDRESS_REQUEST]: studentRequest,
    [Types.CREATE_ORDER_ADDRESS_SUCCESS]: createAddressSuccess,
    [Types.CREATE_ORDER_ADDRESS_FAILURE]: studentFailure,

    [Types.CLEAR_STUDENT_AREA]: clearStudentArea
});
