// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { OptionListProps } from "./option-list.types";

// Styles
import { Container } from "./option-list.styles";

export const OptionList: FunctionComponent<OptionListProps> = ({ spacingAround = "large", content }) => {
    return <Container spacingAround={spacingAround}>{content}</Container>;
};
