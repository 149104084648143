import React from "react";

// Dependencies
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Helpers
import { ISubject } from "interfaces/ISubject";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { SelectModalListContainer, SelectModalListWrap } from "assets/styles/global";

interface IProps {
    data: {
        idBookletModule?: number;
        type: string;
        LO: any[];
        subject: ISubject;
    };
}

const SelectContent = ({ data }: IProps) => {
    const dispatch = useDispatch();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const generateUrl = (item: any) => {
        if (data.type === "video") {
            return `/app/curso/${courseSlug}/materias/${data.subject.slug}/${data.idBookletModule || 0}/video/${item.learningObject.video.id}`;
        }

        if (data.type === "exerciseList") {
            // TODO: id do módulo não adicionado pelo modal desse tipo não ser utilizado
            return `/app/curso/${courseSlug}/materias/${data.subject.slug}/lista-de-exercicios/${item.learningObject.exerciseList.id}`;
        }

        return "";
    };

    return (
        <SelectModalListContainer>
            <h3>Escolha um conteúdo</h3>
            <SelectModalListWrap>
                {data.LO.filter((item) => !!item.learningObject[data.type]).map((item) => (
                    <Link
                        to={generateUrl(item)}
                        key={item.id}
                        title={item.learningObject[data.type].title}
                        onClick={() => dispatch({ type: "CLOSE_MODAL" })}
                    >
                        {item.learningObject[data.type].title}
                    </Link>
                ))}
            </SelectModalListWrap>
        </SelectModalListContainer>
    );
};

export default SelectContent;

//
