// Utils
import { ternary } from "utils/ternary";

// Types
import { ActivityStatusOptions } from "store/interfaces/activity";
import { ActivityMenuProps } from "components/ActivityMenu";

export const getActivityStatusColor = (status: ActivityStatusOptions) => {
    return ternary([
        [status === "AwaitingRelease", "disabled"],
        [status === "Released", "warning"],
        [status === "Finished", "success"],
        [true, "disabled"]
    ]) as any;
};

export const handleSelectFilterData = (selectedItem: string, data: any[]) => {
    const statusAlreadySelected = !!data.find((dataItem) => dataItem === selectedItem);

    let updatedData: string[] = [];

    if (statusAlreadySelected) {
        updatedData = data.filter((dataItem) => dataItem !== selectedItem);
    } else {
        updatedData = [...data, selectedItem];
    }

    return updatedData;
};

export const getMenuFloatContainerAlignment = (width: number, index: number) => {
    return ternary([
        [width >= 1560 && (index + 1) % 4 === 0, "left"],
        [width >= 1560 && (index + 1) % 4 !== 0, "right"],
        [width >= 1024 && (index + 1) % 3 === 0, "left"],
        [width >= 1024 && (index + 1) % 3 !== 0, "right"],
        [width >= 768 && (index + 1) % 2 === 0, "left"],
        [width >= 768 && (index + 1) % 2 !== 0, "right"],
        [true, "left"]
    ]) as ActivityMenuProps["floatContainerAlignment"];
};
