// Dependencies
import styled, { css, keyframes } from "styled-components";

// Utils
import { theme } from "config/theme";

export const ProgressAnimation = (progress: string) => keyframes`
    from {
        width: 0%;
    }

    to {
        width: ${progress};
    }
`;

type ContainerStyleProps = {
    filled: boolean;
    bg: string;
    bgOnCompletelyFilled: string;
    progress: string;
};

export const Container = styled.div<ContainerStyleProps>`
    height: 8px;
    width: 100%;
    background: ${theme.colors.base[200]};
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    .bar {
        height: 100%;
        width: 0;
        border-radius: inherit;
        transition: background 0.5s;

        ${({ progress }) =>
            !!progress &&
            css`
                animation: ${ProgressAnimation(progress)} 0.5s forwards;
            `}

        ${({ bg }) =>
            !!bg &&
            css`
                background: ${bg};
            `}

        ${({ filled, bgOnCompletelyFilled }) =>
            !!filled &&
            css`
                background: ${bgOnCompletelyFilled};
            `}
    }
`;
