import React, { useCallback, useState, useMemo, useEffect } from "react";

// Assets
import { AlertFeedbackContainer, AlertFeedbackText } from "./styles";

const AlertNetwork = () => {
    const [isClosed, setIsClosed] = useState(false);
    const [networkStatus, setNetworkStatus] = useState<"ONLINE" | "OFFLINE">();

    useEffect(() => {
        if (networkStatus === "ONLINE") {
            setTimeout(() => setIsClosed(true), 5000);
        }

        return () => setIsClosed(false);
    }, [networkStatus]);

    const handleNetworkStatus = useCallback((isOnline: boolean) => {
        if (!isOnline) {
            setIsClosed(false);
            return setNetworkStatus("OFFLINE");
        }

        return setNetworkStatus("ONLINE");
    }, []);

    useEffect(() => {
        window.addEventListener("offline", () => handleNetworkStatus(navigator.onLine));
        window.addEventListener("online", () => handleNetworkStatus(navigator.onLine));

        return () => {
            window.removeEventListener("offline", () => handleNetworkStatus);
            window.removeEventListener("online", () => handleNetworkStatus);
        };
    }, [handleNetworkStatus]);

    const renderIconContent = useMemo(() => {
        if (networkStatus !== "ONLINE") {
            return (
                <path d="M1 1l22 22M16.72 11.06A10.94 10.94 0 0119 12.55M5 12.55a10.94 10.94 0 015.17-2.39M10.71 5.05A16 16 0 0122.58 9M1.42 9a15.91 15.91 0 014.7-2.88M8.53 16.11a6 6 0 016.95 0M12 20h.01" />
            );
        }

        return <path d="M5 12.55a11 11 0 0114.08 0M1.42 9a16 16 0 0121.16 0M8.53 16.11a6 6 0 016.95 0M12 20h.01" />;
    }, [networkStatus]);

    const feedbackMessage = useMemo(
        () => (networkStatus !== "ONLINE" ? `Ops. Não encontramos uma conexão. <br /> Alguns recursos podem estar indisponíveis` : `Voltamos! 💪`),
        [networkStatus]
    );

    if ((networkStatus === "ONLINE" && isClosed) || (!networkStatus && !isClosed)) {
        return null;
    }

    return (
        <AlertFeedbackContainer variant={networkStatus !== "ONLINE" ? "warning" : "success"}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="ico ico--network"
            >
                {renderIconContent}
            </svg>
            <AlertFeedbackText dangerouslySetInnerHTML={{ __html: feedbackMessage }} />
        </AlertFeedbackContainer>
    );
};

export default AlertNetwork;
