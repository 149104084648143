import React, { memo, useCallback, useState, useRef, useMemo, useEffect } from "react";

import Switcher from "components/Switcher";
import EssayAttach from "./attach";
import EssayCanvas from "./canvas";

import { KeyboardKeys } from "enums/Keys";

import { MakeEssaySwitcherWrapper } from "./styles";
import useIsMobile from "hooks/use-is-mobile";
import { BreakPoints } from "assets/styles/settings";
import MakeEssayForbidden from "./forbidden";
import validateEssayText from "utils/validateEssayText";

interface IMakeEssayProps {
    handleSendFile?(file: File[]): void;
    handleSendCorrection?(content: string, image: string, status: number): void;
    handleDiscard?(): void;
    handleDraft?(content: string, image: string, status: number): void;
    content?: string;
    isBlockedOnMobile?: boolean;
    fileImage?: string;
    onClearFileImage?(): void;
}

const MakeEssay = ({
    handleSendFile,
    handleDiscard,
    handleDraft,
    handleSendCorrection,
    content = "",
    isBlockedOnMobile = false,
    fileImage,
    onClearFileImage
}: IMakeEssayProps) => {
    const isMobile = useIsMobile(BreakPoints.wide);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [files, setFiles] = useState<File[]>([]);
    const [activeIndex, setActiveIndex] = useState(isMobile ? 0 : content ? 1 : 0);
    const [canvasValue, setCanvasValue] = useState("");

    useEffect(() => {
        setCanvasValue(content);
    }, [content]);

    const handleCanvasChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.currentTarget;
        const canWrite = validateEssayText(event, textareaRef.current);

        if (!canWrite) {
            return;
        }

        setCanvasValue(value);
    }, []);

    const handleKeyUp = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const { value } = event.currentTarget;
        const canWrite = validateEssayText(event, textareaRef.current);

        if (!canWrite && event.keyCode !== KeyboardKeys.KEY_ENTER) {
            return setCanvasValue(value.substr(0, value.length - 1));
        }

        return setCanvasValue(value);
    }, []);

    const handleUpload = useCallback((files: File[]) => setFiles(files), []);

    const handleClearFiles = useCallback(() => setFiles([]), []);

    const MakeEssayMode = useMemo(() => {
        if (activeIndex) {
            return (
                <EssayCanvas
                    value={canvasValue}
                    onChange={handleCanvasChange}
                    onKeyUp={handleKeyUp}
                    ref={textareaRef}
                    handleDiscard={handleDiscard}
                    handleDraft={handleDraft}
                    handleCorrection={handleSendCorrection}
                />
            );
        }

        return (
            <EssayAttach
                fileImage={fileImage}
                files={files}
                onClearFiles={handleClearFiles}
                onUpload={handleUpload}
                onSend={handleSendFile}
                onClearFileImage={onClearFileImage}
            />
        );
    }, [
        activeIndex,
        canvasValue,
        fileImage,
        files,
        handleCanvasChange,
        handleClearFiles,
        handleDiscard,
        handleDraft,
        handleKeyUp,
        handleSendCorrection,
        handleSendFile,
        handleUpload,
        onClearFileImage
    ]);

    const handleSwitcherChange = useCallback((index: number) => setActiveIndex(index), []);

    if (isBlockedOnMobile && isMobile) {
        return <MakeEssayForbidden />;
    }

    return (
        <>
            {!isMobile && (
                <MakeEssaySwitcherWrapper>
                    <Switcher activeIndex={activeIndex} options={["Anexar", "Escrever"]} onChange={handleSwitcherChange} />
                </MakeEssaySwitcherWrapper>
            )}

            {MakeEssayMode}
        </>
    );
};

export default memo(MakeEssay);
