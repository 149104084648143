import React, { memo, useState, useMemo, useCallback } from "react";
import * as S from "./styles";
import Donuts from "../Donuts";
import SubjectScreenDetailsMobileSelectFrontSkeleton from "./skeleton";
import { ISelectSubjectFront } from "../SelectFront";
import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import { ISubjectFront } from "store/interfaces/ISubject";

const SubjectScreenDetailsMobileSelectFront = memo(
    ({ isLoadingFronts = false, fronts = [], currentFront, isLoadingProgress = true, onClickFront, subjectProgress }: ISelectSubjectFront) => {
        const [isActive, setIsActive] = useState(false);

        const formatFronts = useMemo(() => {
            if (!fronts?.length) {
                return [];
            }

            if (!!currentFront?.id) {
                return fronts.filter((front) => front.id !== currentFront.id);
            }

            return fronts;
        }, [currentFront, fronts]);

        const handleClickFront = useCallback(
            (clickedFront: ISubjectFront) => {
                setIsActive(false);

                if (clickedFront.id === currentFront?.id) {
                    return;
                }

                onClickFront(clickedFront);
            },
            [currentFront, onClickFront]
        );

        const progress = useMemo(() => {
            if (currentFront?.id === null) {
                return subjectProgress;
            }

            return currentFront?.progress;
        }, [currentFront, subjectProgress]);

        if (isLoadingFronts) {
            return <SubjectScreenDetailsMobileSelectFrontSkeleton />;
        }

        return (
            <>
                <S.SubjectScreenDetailsMobileSelectFrontContainer>
                    <S.SubjectScreenDetailsMobileSelectFrontButton selected onClick={() => setIsActive(!isActive)}>
                        {isLoadingProgress ? (
                            <Skeleton width="50px" height="50px" style={{ borderRadius: "100%", marginRight: theme.spacing.smallX }} />
                        ) : (
                            <Donuts
                                size="50px"
                                value={progress?.percent || 0}
                                showPercentage
                                lineColor={(progress?.percent || 0) === 100 ? theme.colors.system.success[200] : theme.colors.system.info[200]}
                                ringColor={theme.colors.base[200]}
                            />
                        )}

                        <S.SubjectScreenDetailsMobileSelectFrontTexts column>
                            <S.SubjectScreenDetailsMobileSelectFrontTitle>{currentFront?.title}</S.SubjectScreenDetailsMobileSelectFrontTitle>

                            <S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                                {isLoadingProgress ? (
                                    <Skeleton width="70%" height="16px" />
                                ) : (
                                    `${progress?.completedItems || 0}/${progress?.totalItems || 0} módulos concluídos`
                                )}
                            </S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                        </S.SubjectScreenDetailsMobileSelectFrontTexts>

                        <S.SubjectScreenDetailsMobileSelectFrontChevronDown width={10} isactive={isActive} />
                    </S.SubjectScreenDetailsMobileSelectFrontButton>

                    {isActive &&
                        formatFronts.map((front, index) => (
                            <S.SubjectScreenDetailsMobileSelectFrontButton onClick={() => handleClickFront(front)} key={`front_${index}`}>
                                <Donuts
                                    size="20px"
                                    value={(front.id === null ? subjectProgress?.percent : front?.progress?.percent) || 0}
                                    lineColor={
                                        (front.id === null ? subjectProgress?.percent : front?.progress?.percent) === 100
                                            ? theme.colors.system.success[200]
                                            : theme.colors.system.info[200]
                                    }
                                    ringColor={theme.colors.base[200]}
                                />

                                <S.SubjectScreenDetailsMobileSelectFrontTexts>
                                    <S.SubjectScreenDetailsMobileSelectFrontTitle>{front.title}</S.SubjectScreenDetailsMobileSelectFrontTitle>
                                    <S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                                        {(front.id === null ? subjectProgress?.completedItems : front?.progress?.completedItems) || 0}/
                                        {(front.id === null ? subjectProgress?.totalItems : front?.progress?.totalItems) || 0} concluídos
                                    </S.SubjectScreenDetailsMobileSelectFrontSubTitle>
                                </S.SubjectScreenDetailsMobileSelectFrontTexts>
                            </S.SubjectScreenDetailsMobileSelectFrontButton>
                        ))}
                </S.SubjectScreenDetailsMobileSelectFrontContainer>

                <S.SubjectScreenDetailsMobileSelectFrontModuleTitle>
                    Módulos de {currentFront?.title}
                </S.SubjectScreenDetailsMobileSelectFrontModuleTitle>
            </>
        );
    }
);

export default SubjectScreenDetailsMobileSelectFront;
