import Skeleton from "components/Skeleton";
import React, { memo } from "react";
import CloseIcon from "prodigio-icons/web/outlined/Close";
import { ExamDayHeader } from "./styles";

interface IProps {
    onClose(): void;
}

export const ExamDayHeaderSkeleton = memo(({ onClose }: IProps) => {
    return (
        <ExamDayHeader>
            <button type="button" onClick={onClose}>
                <CloseIcon />
            </button>

            <h2>
                <Skeleton height={"28px"} width={"65%"} />
            </h2>
        </ExamDayHeader>
    );
});
