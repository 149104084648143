import { useMemo } from "react";

interface IProps {
    alternatives: any[];
}

const useCorrectAlternative = ({ alternatives }: IProps) => {
    const correctAlternative = useMemo(() => {
        try {
            if (!alternatives || !alternatives.length) {
                throw new Error();
            }

            return alternatives.find((alternative) => !!alternative.isCorrection);
        } catch (error) {
            return null;
        }
    }, [alternatives]);

    return correctAlternative;
};

export default useCorrectAlternative;
