// Depedencies
import { Action, ActionCreatorsMapObject } from "redux";

export type FileTypeType = "Documento" | "Imagem" | "Vídeo" | "Áudio";

export type FileType = {
    allowedExtensions: string[];
    name: string;
    type: FileTypeType;
};

export type ActivitySupportMaterial = {
    id: string;
    name: string;
    progress: number;
    source: string;
    cancelTokenSource: any;
};

export type ActivityAuthor = {
    name: string;
    email: string;
};

export enum ActivityStatusEnum {
    "AwaitingRelease" = "Agendada",
    "Released" = "Em andamento",
    "Finished" = "Encerrada"
}

export const statusOptions = ["AwaitingRelease", "Released", "Finished"] as const;
export type ActivityStatusOptions = typeof statusOptions[number];

export enum ActivityTypeEnum {
    "ExerciseList" = "Lista de exercícios",
    "Composition" = "Escrita livre",
    "Upload" = "Envio de arquivos"
}

export type ActivityTypeOptions = "ExerciseList" | "Composition" | "Upload";

export enum ActivitySubmissionStatusEnum {
    "Unrealized" = "Não realizada",
    "Started" = "Iniciada",
    "WaitingCorrection" = "Aguardando correção",
    "Finished" = "Finalizada"
}

export type ActivitySubmissionStatusOptions = "Unrealized" | "Started" | "WaitingCorrection" | "Finished";

export type ActivitySubmissionUser = {
    id: string;
    name: string;
    email: string;
};

export type ActivitySubmissionAnswerCardQuestion = {
    hit: boolean;
    order: number;
    answerOrder: string;
    correctionOrder: string;
    enunciation: string;
};

export type ActivitySubmissionAnswerCard = {
    id: number;
    startDate: string;
    endDate: string;
    questions: ActivitySubmissionAnswerCardQuestion[];
};

export type ActivitySubmission = {
    id: string;
    files?: ActivitySupportMaterial[];
    compose?: string;
    reference?: string;
    studentComment?: string;
    teacherComment?: string;
    score?: number;
    status: ActivitySubmissionStatusOptions;
    submitDate?: string;
    lastUpdate?: string;
    user: ActivitySubmissionUser;
    answerCard?: ActivitySubmissionAnswerCard;
    selectedFileModal?: ActivitySupportMaterial;
};

export type ActivityClass = {
    id: string;
    name: string;
    reference: string;
};

export type Activity = {
    id: string;
    dueDate?: string;
    releaseDate: string;
    classes?: ActivityClass[];
    score?: number;
    status: ActivityStatusOptions;
    title: string;
    author: ActivityAuthor;
    enunciation: string;
    type: ActivityTypeOptions;
    reference?: string;
    wordsLimit?: number;
    allowedFileTypes?: FileType[];
    supportMaterials?: ActivitySupportMaterial[];
    submission?: ActivitySubmission;
    releaseResult?: boolean;
};

export type TeacherEnrollment = {
    label: string;
    value: string;
};

export type ActivityType = "exercise-list" | "discursive" | "send-file";

export type TeacherCreateMobileStep = "activity-class-data" | "activity-type-data" | "activity-answer-data" | "activity-files-data";

export type ActivityExerciseListType = "pre-created" | "custom";

export type TeacherActivityType = {
    data?: ActivityType;
    exerciseListType?: ActivityExerciseListType;
};

export type TeacherCreateQuestionsBankExerciseList = {
    label: string;
    value: string;
};

export type ActivityStateTeacherDashboardFilterData = {
    startDueDate?: string;
    endDueDate?: string;
    classes: string[];
    statuses: string[];
};

export type ActivityStateTeacherDashboardFilter = {
    isOpened: boolean;
    data: ActivityStateTeacherDashboardFilterData;
};

export type ActivityStateTeacherCreateSupportMaterial = {
    name: string;
    id: string;
    progress: number;
    source: string;
    cancelTokenSource: any;
};

export type TeacherCreateActivityExerciseListQuestionAlternative = {
    id?: number;
    order?: string;
    statement?: string;
    isCorrect?: boolean;
    isEditing?: boolean;
    isCreating?: boolean;
};

export type TeacherCreateActivityExerciseListQuestion = {
    questionId?: number;
    exerciseListItemId?: number;
    order?: number;
    subject?: TeacherSubject;
    statement?: string;
    alternatives?: TeacherCreateActivityExerciseListQuestionAlternative[];
    isEditing?: boolean;
    alternativeRemoved?: TeacherCreateActivityExerciseListQuestionAlternative;
    currentAlternative?: TeacherCreateActivityExerciseListQuestionAlternative;
    isCurrentQuestionModalOpened?: boolean;
    isCurrentAlternativeBottomSheetOpened?: boolean;
    isCurrentAlternativeCorrectAnswer?: boolean;
    undoRemoveAlternativeInSeconds?: number;
    isShownUndoRemoveAlternative?: boolean;
};

export type TeacherCreateActivityExerciseList = {
    shouldReleaseResultForStudent?: boolean;
    score?: number;
    listId?: number;
    questions?: TeacherCreateActivityExerciseListQuestion[];
};

export type TeacherCreateActivity = {
    exerciseList: TeacherCreateActivityExerciseList;
};

export type TeacherCreateActivityData = TeacherCreateActivity;

export type ActivityData<T> = {
    isLoading: boolean;
    data?: T;
};

export type ActivityStateStudentDashboard = {
    activities: ActivityData<Activity[]>;
};

export type ActivityStateStudentExecution = {
    activityDetails: ActivityData<Activity>;
    answerCardId: ActivityData<string>;
    isRequestingFile: boolean;
    isCreating: boolean;
};

export type TeacherSubject = {
    label: string;
    value: string;
};

export type ActivityStateTeacherCreate = {
    subjects: ActivityData<TeacherSubject[]>;
    details: ActivityData<Activity>;
    enrollments: ActivityData<TeacherEnrollment[]>;
    questionsBankExerciseLists: ActivityData<TeacherCreateQuestionsBankExerciseList[]>;
    fileTypes: ActivityData<FileType[]>;
    mobileStep: TeacherCreateMobileStep;
    isCreating: boolean;
    supportMaterials: ActivityData<ActivityStateTeacherCreateSupportMaterial[]>;
    activityType: TeacherActivityType;
    currentActivity?: TeacherCreateActivityData;
    currentQuestion?: TeacherCreateActivityExerciseListQuestion;
    currentQuestionAccordion?: TeacherCreateActivityExerciseListQuestion;
};

export type ActivityStateTeacherDashboard = {
    activities: ActivityData<Activity[]>;
    enrollments: ActivityData<TeacherEnrollment[]>;
    filter: ActivityStateTeacherDashboardFilter;
    currentActivityToBeDeleted?: Activity;
};

export type ActivityStateTeacherActivitySubmissions = {
    data: ActivityData<ActivitySubmission[]>;
    activityDetails: ActivityData<Activity>;
};

export type ActivityStateActivitySubmission = {
    details: ActivityData<Activity>;
    submission: ActivityData<ActivitySubmission>;
    isTeacherCorrecting: boolean;
};

export type ActivityState = {
    studentDashboard: ActivityStateStudentDashboard;
    studentExecution: ActivityStateStudentExecution;
    teacherCreate: ActivityStateTeacherCreate;
    teacherDashboard: ActivityStateTeacherDashboard;
    teacherActivitySubmissions: ActivityStateTeacherActivitySubmissions;
    activitySubmission: ActivityStateActivitySubmission;
};

export type ActivityActions = {
    clearStudentDashboard(): Action<void>;
    getStudentDashboardActivitiesRequest(): Action<void>;
    getStudentDashboardActivitiesSuccess(payload: Activity[]): Action<void>;
    getStudentDashboardActivitiesFailure(payload: any): Action<void>;
    clearStudentExecution(): Action<void>;
    getStudentExecutionActivityDetailsRequest(payload: any): Action<void>;
    getStudentExecutionActivityDetailsSuccess(payload: Activity): Action<void>;
    getStudentExecutionActivityDetailsFailure(payload: any): Action<void>;
    getStudentExecutionAnswerCardIdRequest(payload: string): Action<void>;
    getStudentExecutionAnswerCardIdSuccess(payload: string): Action<void>;
    getStudentExecutionAnswerCardIdFailure(payload: any): Action<void>;
    cerateStudentExecutionActivitySubmissionRequest(payload: {
        activityId: string;
        submissionId: string;
        finish?: boolean;
        files?: string[];
        compose?: string;
        comment?: string;
    }): Action<void>;
    cerateStudentExecutionActivitySubmissionSuccess(payload: any): Action<void>;
    cerateStudentExecutionActivitySubmissionFailure(payload: any): Action<void>;
    cerateStudentExecutionActivitySubmissionFileRequest(payload: { activityId: string; files: File[] }): Action<void>;
    cerateStudentExecutionActivitySubmissionFileSuccess(payload: any): Action<void>;
    cerateStudentExecutionActivitySubmissionFileFailure(payload: any): Action<void>;
    deleteStudentExecutionActivitySubmissionFileRequest(payload: { activityId: string; fileId: string }): Action<void>;
    deleteStudentExecutionActivitySubmissionFileSuccess(payload: any): Action<void>;
    deleteStudentExecutionActivitySubmissionFileFailure(payload: any): Action<void>;
    setStudentExecutionActivitySubmissionFileModal(payload?: any): Action<void>;
    cancelStudentExecutionActivitySubmissionFile(payload: any): Action<void>;
    clearTeacherCreate(): Action<void>;
    getTeacherCreateSubjectsRequest(): Action<void>;
    getTeacherCreateSubjectsSuccess(payload: any[]): Action<void>;
    getTeacherCreateSubjectsFailure(payload: any): Action<void>;
    getTeacherActivityDetailsRequest(): Action<void>;
    getTeacherActivityDetailsSuccess(payload: any[]): Action<void>;
    getTeacherActivityDetailsFailure(payload: any): Action<void>;
    getTeacherCreateEnrollmentsRequest(): Action<void>;
    getTeacherCreateEnrollmentsSuccess(payload: any[]): Action<void>;
    getTeacherCreateEnrollmentsFailure(payload: any): Action<void>;
    getTeacherCreateQuestionsBankExerciseListsRequest(): Action<void>;
    getTeacherCreateQuestionsBankExerciseListsSuccess(payload: any): Action<void>;
    getTeacherCreateQuestionsBankExerciseListsFailure(payload: any): Action<void>;
    createTeacherCreateActivityRequest(payload: any): Action<void>;
    createTeacherCreateActivitySuccess(): Action<void>;
    createTeacherCreateActivityFailure(payload: any): Action<void>;
    getTeacherCreateFileTypesRequest(): Action<void>;
    getTeacherCreateFileTypesSuccess(payload: any[]): Action<void>;
    getTeacherCreateFileTypesFailure(payload: any): Action<void>;
    createTeacherCreateSupportMaterialsRequest(payload: any): Action<void>;
    createTeacherCreateSupportMaterialsSuccess(payload: any): Action<void>;
    createTeacherCreateSupportMaterialsFailure(payload: any): Action<void>;
    deleteTeacherCreateSupportMaterialRequest(payload: any): Action<void>;
    deleteTeacherCreateSupportMaterialSuccess(payload: any): Action<void>;
    deleteTeacherCreateSupportMaterialFailure(payload: any): Action<void>;
    cancelTeacherCreateSupportMaterialUpload(payload: any): Action<void>;
    setTeacherCreateMobileStep(payload?: TeacherCreateMobileStep): Action<void>;
    setTeacherCreateActivityType(payload?: TeacherActivityType): Action<void>;
    setTeacherCreateCurrentActivity(payload: any): Action<void>;
    setTeacherCreateIsCurrentQuestionModalOpened(payload: boolean): Action<void>;
    setTeacherCreateIsCurrentAlternativeBottomSheetOpened(payload: boolean): Action<void>;
    setTeacherCreateIsCurrentAlternativeAccordionOpened(payload?: TeacherCreateActivityExerciseListQuestion): Action<void>;
    setTeacherCreateIsCurrentAlternativeCorrectAnswer(payload: boolean): Action<void>;
    setTeacherCreateShowUndoRemoveAlternativeAlert(payload: boolean): Action<void>;
    setTeacherCreateIntervalToUndoRemoveAlternative(payload: number): Action<void>;
    setTeacherCreateCurrentQuestion(): Action<void>;
    setTeacherCreateSaveAlternative(payload: any): Action<void>;
    setTeacherCreateCurrentAlternativeStatementChange(payload: { value: string; alternativeIndex: number }): Action<void>;
    setTeacherCreateCurrentQuestionSubjectAndStatement(payload: { field: string; value: any }): Action<void>;
    setTeacherCreateCurrentQuestionCorrectAlternative(payload: { alternative: TeacherCreateActivityExerciseListQuestionAlternative }): Action<void>;
    setTeacherCreateCurrentQuestionSetCurrentAlternativeToEdit(payload: TeacherCreateActivityExerciseListQuestionAlternative): Action<void>;
    setTeacherCreateSaveCurrentQuestion(payload: any): Action<void>;
    editTeacherCreateCurrentQuestion(payload: any): Action<void>;
    editTeacherCreateCurrentQuestionAlternative(payload: {
        alternative: TeacherCreateActivityExerciseListQuestionAlternative;
        shouldCancel: boolean;
    }): Action<void>;
    cancelTeacherCreateCurrentQuestionAlternativeEdit(payload: any): Action<void>;
    undoTeacherCreateRemoveAlternative(): Action<void>;
    deleteTeacherCreateCurrentQuestionAlternative(payload: any): Action<void>;
    deleteTeacherCreateCurrentQuestion(payload: any): Action<void>;
    clearTeacherCreateCurrentQuestion(): Action<void>;
    clearTeacherDashboard(): Action<void>;
    getTeacherDashboardActivitiesRequest(payload?: any): Action<void>;
    getTeacherDashboardActivitiesSuccess(payload: Activity[]): Action<void>;
    getTeacherDashboardActivitiesFailure(payload: any): Action<void>;
    deleteTeacherDashboardActivityRequest(payload: string): Action<void>;
    deleteTeacherDashboardActivitySuccess(payload: any): Action<void>;
    deleteTeacherDashboardActivityFailure(payload: any): Action<void>;
    getTeacherDashboardEnrollmentsRequest(): Action<void>;
    getTeacherDashboardEnrollmentsSuccess(payload: any[]): Action<void>;
    getTeacherDashboardEnrollmentsFailure(payload: any): Action<void>;
    setTeacherDashboardFilterIsOpened(payload: boolean): Action<void>;
    setTeacherDashboardFilterData(payload: ActivityStateTeacherDashboardFilterData): Action<void>;
    setTeacherDashboardCurrentActivityToBeDeleted(payload?: Activity): Action<void>;
    clearTeacherDashboardFilterData(): Action<void>;
    clearTeacherActivitySubmissions(): Action<void>;
    getTeacherActivitySubmissionsDataRequest(payload: string): Action<void>;
    getTeacherActivitySubmissionsDataSuccess(payload: ActivitySubmission[]): Action<void>;
    getTeacherActivitySubmissionsDataFailure(payload: any): Action<void>;
    getTeacherActivitySubmissionsActivityDetailsRequest(payload: string): Action<void>;
    getTeacherActivitySubmissionsActivityDetailsSuccess(payload: Activity): Action<void>;
    getTeacherActivitySubmissionsActivityDetailsFailure(payload: any): Action<void>;
    clearActivitySubmission(): Action<void>;
    getActivitySubmissionDetailsRequest(payload: string): Action<void>;
    getActivitySubmissionDetailsSuccess(payload: Activity): Action<void>;
    getActivitySubmissionDetailsFailure(payload: any): Action<void>;
    getActivitySubmissionSubmissionRequest(payload: { activityId: string; submissionId: string }): Action<void>;
    getActivitySubmissionSubmissionSuccess(payload: ActivitySubmission): Action<void>;
    getActivitySubmissionSubmissionFailure(payload: any): Action<void>;
    createActivitySubmissionTeacherCorrectionRequest(payload: {
        activityId: string;
        submissionId: string;
        score?: number;
        comment?: string;
    }): Action<void>;
    createActivitySubmissionTeacherCorrectionSuccess(payload: any): Action<void>;
    createActivitySubmissionTeacherCorrectionFailure(payload: any): Action<void>;
};

export type ActivityActionsType =
    | "CLEAR_STUDENT_DASHBOARD"
    | "GET_STUDENT_DASHBOARD_ACTIVITIES_REQUEST"
    | "GET_STUDENT_DASHBOARD_ACTIVITIES_SUCCESS"
    | "GET_STUDENT_DASHBOARD_ACTIVITIES_FAILURE"
    | "CLEAR_STUDENT_EXECUTION"
    | "GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_REQUEST"
    | "GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_SUCCESS"
    | "GET_STUDENT_EXECUTION_ACTIVITY_DETAILS_FAILURE"
    | "GET_STUDENT_EXECUTION_ANSWER_CARD_ID_REQUEST"
    | "GET_STUDENT_EXECUTION_ANSWER_CARD_ID_SUCCESS"
    | "GET_STUDENT_EXECUTION_ANSWER_CARD_ID_FAILURE"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_REQUEST"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_SUCCESS"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FAILURE"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_SUCCESS"
    | "CREATE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_FAILURE"
    | "DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_REQUEST"
    | "DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_SUCCESS"
    | "DELETE_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_FAILURE"
    | "SET_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE_MODAL"
    | "CANCEL_STUDENT_EXECUTION_ACTIVITY_SUBMISSION_FILE"
    | "CLEAR_TEACHER_CREATE"
    | "GET_TEACHER_CREATE_ACTIVITY_DETAILS_REQUEST"
    | "GET_TEACHER_CREATE_ACTIVITY_DETAILS_SUCCESS"
    | "GET_TEACHER_CREATE_ACTIVITY_DETAILS_FAILURE"
    | "GET_TEACHER_CREATE_ENROLLMENTS_REQUEST"
    | "GET_TEACHER_CREATE_ENROLLMENTS_SUCCESS"
    | "GET_TEACHER_CREATE_ENROLLMENTS_FAILURE"
    | "GET_TEACHER_CREATE_SUBJECTS_REQUEST"
    | "GET_TEACHER_CREATE_SUBJECTS_SUCCESS"
    | "GET_TEACHER_CREATE_SUBJECTS_FAILURE"
    | "GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_REQUEST"
    | "GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_SUCCESS"
    | "GET_TEACHER_CREATE_QUESTIONS_BANK_EXERCISE_LISTS_FAILURE"
    | "CREATE_TEACHER_CREATE_ACTIVITY_REQUEST"
    | "CREATE_TEACHER_CREATE_ACTIVITY_SUCCESS"
    | "CREATE_TEACHER_CREATE_ACTIVITY_FAILURE"
    | "GET_TEACHER_CREATE_FILE_TYPES_REQUEST"
    | "GET_TEACHER_CREATE_FILE_TYPES_SUCCESS"
    | "GET_TEACHER_CREATE_FILE_TYPES_FAILURE"
    | "CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_REQUEST"
    | "CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_SUCCESS"
    | "CREATE_TEACHER_CREATE_SUPPORT_MATERIALS_FAILURE"
    | "DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_REQUEST"
    | "DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_SUCCESS"
    | "DELETE_TEACHER_CREATE_SUPPORT_MATERIAL_FAILURE"
    | "CANCEL_TEACHER_CREATE_SUPPORT_MATERIAL_UPLOAD"
    | "SET_TEACHER_CREATE_MOBILE_STEP"
    | "SET_TEACHER_CREATE_ACTIVITY_TYPE"
    | "SET_TEACHER_CREATE_CURRENT_ACTIVITY"
    | "SET_TEACHER_CREATE_IS_CURRENT_QUESTION_MODAL_OPENED"
    | "SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_BOTTOM_SHEET_OPENED"
    | "SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_ACCORDION_OPENED"
    | "SET_TEACHER_CREATE_IS_CURRENT_ALTERNATIVE_CORRECT_ANSWER"
    | "SET_TEACHER_CREATE_CURRENT_QUESTION"
    | "SET_TEACHER_CREATE_SAVE_ALTERNATIVE"
    | "SET_TEACHER_CREATE_SHOW_UNDO_REMOVE_ALTERNATIVE_ALERT"
    | "SET_TEACHER_CREATE_INTERVAL_TO_UNDO_REMOVE_ALTERNATIVE"
    | "SET_TEACHER_CREATE_CURRENT_ALTERNATIVE_STATEMENT_CHANGE"
    | "SET_TEACHER_CREATE_CURRENT_QUESTION_SUBJECT_AND_STATEMENT"
    | "SET_TEACHER_CREATE_CURRENT_QUESTION_CORRECT_ALTERNATIVE"
    | "SET_TEACHER_CREATE_CURRENT_QUESTION_SET_CURRENT_ALTERNATIVE_TO_EDIT"
    | "CANCEL_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE_EDIT"
    | "SET_TEACHER_CREATE_SAVE_CURRENT_QUESTION"
    | "EDIT_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE"
    | "EDIT_TEACHER_CREATE_CURRENT_QUESTION"
    | "UNDO_TEACHER_CREATE_REMOVE_ALTERNATIVE"
    | "DELETE_TEACHER_CREATE_CURRENT_QUESTION_ALTERNATIVE"
    | "DELETE_TEACHER_CREATE_CURRENT_QUESTION"
    | "CLEAR_TEACHER_CREATE_CURRENT_QUESTION"
    | "CLEAR_TEACHER_DASHBOARD"
    | "GET_TEACHER_DASHBOARD_ACTIVITIES_REQUEST"
    | "GET_TEACHER_DASHBOARD_ACTIVITIES_SUCCESS"
    | "GET_TEACHER_DASHBOARD_ACTIVITIES_FAILURE"
    | "DELETE_TEACHER_DASHBOARD_ACTIVITY_REQUEST"
    | "DELETE_TEACHER_DASHBOARD_ACTIVITY_SUCCESS"
    | "DELETE_TEACHER_DASHBOARD_ACTIVITY_FAILURE"
    | "GET_TEACHER_DASHBOARD_ENROLLMENTS_REQUEST"
    | "GET_TEACHER_DASHBOARD_ENROLLMENTS_SUCCESS"
    | "GET_TEACHER_DASHBOARD_ENROLLMENTS_FAILURE"
    | "SET_TEACHER_DASHBOARD_FILTER_IS_OPENED"
    | "SET_TEACHER_DASHBOARD_FILTER_DATA"
    | "SET_TEACHER_DASHBOARD_CURRENT_ACTIVITY_TO_BE_DELETED"
    | "CLEAR_TEACHER_DASHBOARD_FILTER_DATA"
    | "CLEAR_TEACHER_ACTIVITY_SUBMISSIONS"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_REQUEST"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_SUCCESS"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_DATA_FAILURE"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_REQUEST"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_SUCCESS"
    | "GET_TEACHER_ACTIVITY_SUBMISSIONS_ACTIVITY_DETAILS_FAILURE"
    | "CLEAR_ACTIVITY_SUBMISSION"
    | "GET_ACTIVITY_SUBMISSION_DETAILS_REQUEST"
    | "GET_ACTIVITY_SUBMISSION_DETAILS_SUCCESS"
    | "GET_ACTIVITY_SUBMISSION_DETAILS_FAILURE"
    | "GET_ACTIVITY_SUBMISSION_SUBMISSION_REQUEST"
    | "GET_ACTIVITY_SUBMISSION_SUBMISSION_SUCCESS"
    | "GET_ACTIVITY_SUBMISSION_SUBMISSION_FAILURE"
    | "CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_REQUEST"
    | "CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_SUCCESS"
    | "CREATE_ACTIVITY_SUBMISSION_TEACHER_CORRECTION_FAILURE";

export type ActivityActionsCreators = ActivityActions & ActionCreatorsMapObject;
