import { useCallback } from "react";

import { Creators } from "store/ducks/alert";
import { useDispatch } from "react-redux";

export function useAlert() {
    const dispatch = useDispatch();

    const show = useCallback((message: string, type: "info" | "success" | "warning" | "danger") => dispatch(Creators.showAlert(message, type)), [
        dispatch
    ]);

    return {
        show
    };
}
