import styled from "styled-components";
import { theme } from "config/theme";
import { TitleH3 } from "assets/styles/global";
import { ButtonWrapper } from "components/Button/styles";

export const RaizExamModalMessageContainer = styled.div`
    width: 500px;
    max-width: 100%;
    padding: ${theme.spacing.large};
    display: flex;
    flex-direction: column;

    svg {
        margin-bottom: ${theme.spacing.medium};
    }

    p {
        margin-bottom: ${theme.spacing.smallX};
        color: ${theme.colors.base[400]};
    }

    ${ButtonWrapper} {
        margin: 0 auto;
    }
`;

export const RaizExamModalMessageTitle = styled.strong`
    ${TitleH3};

    margin-bottom: ${theme.spacing.small};
`;
