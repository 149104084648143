import React, { useState, useRef, memo, useCallback } from "react";

// Dependencies
import ChevronUpIcon from "prodigio-icons/web/outlined/ChevronUp";
import ChevronDownIcon from "prodigio-icons/web/outlined/ChevronDown";

// Components
import If from "components/If";

// Redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Creators as AuthActions } from "store/ducks/auth";

// Helpers
import history from "services/history";
import useIsMobile from "hooks/use-is-mobile";
import { IReduxStore } from "interfaces/IReduxStore";
import { IAuthActions } from "store/interfaces/IAuth";
import { version } from "../../../../../../../package.json";
import useOnClickOutside from "hooks/use-on-click-outside";
import { BreakPoints } from "assets/styles/settings";

// Assets
import { ActionButtonProfile, AvatarMenuProfile, AvatarMenuProfileName, MenuProfileDropdown, Version } from "./styles";

interface IMenuProfileButton {
    authActions: IAuthActions;
    user: {
        name: string;
        imageProfile: string;
    };
}

const MenuProfileButton = ({ authActions, user }: IMenuProfileButton) => {
    const ref = useRef(null);
    const isMobile = useIsMobile(BreakPoints.small);
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const [openMenu, setOpenMenu] = useState(false);

    const handleMenuAction = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

    const redirectProfile = useCallback(
        () => history.push(!isMobile ? `/app/curso/${courseSlug}/area-do-aluno/perfil/edicao` : `/app/curso/${courseSlug}/area-do-aluno/perfil`),
        [courseSlug, isMobile]
    );
    const logout = useCallback(() => authActions.logout(), [authActions]);

    useOnClickOutside(ref, () => {
        if (openMenu) {
            setOpenMenu(!openMenu);
        }
    });

    return (
        <div ref={ref}>
            <ActionButtonProfile onClick={handleMenuAction}>
                <AvatarMenuProfile avatar={user.imageProfile} />
                <AvatarMenuProfileName>{user.name}</AvatarMenuProfileName>
                <If test={!isMobile}>{openMenu ? <ChevronUpIcon width="12" height="6" /> : <ChevronDownIcon width="12" height="6" />}</If>
            </ActionButtonProfile>
            <If test={openMenu}>
                <MenuProfileDropdown>
                    <button type="button" onClick={redirectProfile}>
                        Meu Perfil
                    </button>
                    <button type="button" onClick={logout}>
                        Sair
                    </button>
                    <Version>Versão {version}</Version>
                </MenuProfileDropdown>
            </If>
        </div>
    );
};

const mapStateToProps = ({ credentials }: IReduxStore) => ({
    user: {
        name: credentials.name,
        imageProfile: credentials.imageProfile
    }
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(MenuProfileButton));
