import { theme } from "config/theme";
import styled from "styled-components/macro";
import { TitleH2 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { ButtonWrapper } from "components/Button/styles";
import { DropzoneContainer } from "components/Dropzone/styles";

export const ComplaintModalContainer = styled.div`
    background: ${theme.colors.white};
    border-radius: ${theme.spacing.smallX};

    ${MediaQueries.SMALL} {
        max-width: 100%;
        min-width: unset;
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        max-width: 100%;
        min-width: unset;
    }
`;

export const ComplaintModalHeader = styled.header`
    border-bottom: 1px solid #eaeaea;
    padding: ${theme.spacing.small};
`;

export const ComplaintModalContent = styled.div`
    border-bottom: 1px solid #eaeaea;
    padding: ${theme.spacing.large};

    ${MediaQueries.SMALL} {
        padding: 0;

        ${DropzoneContainer} {
            border: unset;
            width: 320px;
        }
    }

    ${MediaQueries.TABLET_PORTRAIT} {
        padding: 0;

        ${DropzoneContainer} {
            border: unset;
            width: 600px;
        }
    }
`;

export const ComplaintModalTitle = styled.strong`
    ${TitleH2};
    color: ${theme.colors.base[500]};
    display: block;
    text-align: center;
`;

export const ComplaintModalFooter = styled.footer`
    display: flex;
    padding: ${theme.spacing.small};

    ${ButtonWrapper} {
        &:not(:last-child) {
            margin-right: ${theme.spacing.small};
        }
    }
`;
