import React, { forwardRef } from "react";

import * as S from "./styles";
import SubjectScreenDetailsModule from "../Module";
import SubjectScreenDetailsModulesSkeleton from "./skeleton";
import { IBookletModuleItem } from "store/interfaces/IBookletModule";

interface IProps {
    bookletModules: IBookletModuleItem[];
    onClickModule(module: IBookletModuleItem): void;
    activeModuleId?: number;
    isLoading: boolean;
}

const SubjectScreenDetailsModules = forwardRef(({ bookletModules, onClickModule, activeModuleId, isLoading = true }: IProps, ref: any) => {
    if (isLoading) {
        return <SubjectScreenDetailsModulesSkeleton />;
    }

    return (
        <S.SubjectScreenDetailsModulesContainer>
            {bookletModules?.map((bookletModule, index) => {
                const { id, name, subject, progress, subjectFront } = bookletModule;
                const currentModuleActive = id === activeModuleId ? bookletModule : undefined;

                return (
                    <SubjectScreenDetailsModule
                        key={id}
                        id={id}
                        name={name}
                        progress={progress}
                        onClick={() => onClickModule(bookletModule)}
                        ref={(el) => ((ref.current[index] as any) = el)}
                        module={currentModuleActive}
                        subjectName={subject?.name}
                        subjectFront={subjectFront}
                    />
                );
            })}
        </S.SubjectScreenDetailsModulesContainer>
    );
});

export default SubjectScreenDetailsModules;
