// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container, ContentCompositionWrapper } from "./styles";

type WebpCardProps = {
    headerComposition?: ReactNode;
    contentComposition?: ReactNode;
    footerComposition?: ReactNode;
    contentAlignment?: "start" | "center";
};

export const WebpCard: FunctionComponent<WebpCardProps> = ({
    footerComposition,
    headerComposition,
    contentComposition,
    contentAlignment = "start"
}) => {
    return (
        <Container contentAlignment={contentAlignment} className="webp-card">
            <div className="container">
                <div className="header">{headerComposition}</div>
                {!!contentComposition && (
                    <ContentCompositionWrapper contentAlignment={contentAlignment} className="content">
                        {contentComposition}
                    </ContentCompositionWrapper>
                )}

                {!!footerComposition && <div className="footer">{footerComposition}</div>}
            </div>
        </Container>
    );
};
