import React, { ErrorInfo } from "react";

interface IProps {
    component: any;
}

interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
    public state: IState = {
        hasError: false
    };

    public componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
        // if (process.env.NODE_ENV === "production") {
        //   // SOME ERROR API INTEGRATION
        // }

        // Display fallback UI
        this.setState({ hasError: true });
    };

    public render() {
        const { hasError } = this.state;
        const { component } = this.props;

        if (hasError) {
            return component;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
