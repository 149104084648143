import styled from "styled-components";
import { theme } from "config/theme";

export const ExerciseListKnowledgeAreaContainer = styled.div<{ color: string }>`
    display: flex;
    align-items: center;
    color: ${theme.colors.base[500]};
    font-size: ${theme.typography.sizes.medium};
    margin-bottom: ${theme.spacing.small};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    :before {
        content: "";
        width: 4px;
        height: 24px;
        background: ${({ color }) => color};
        border-radius: 5px;
        margin-right: ${theme.spacing.smallX};
    }
`;
