import { theme } from "config/theme";
import { TitleH5 } from "assets/styles/global";
import styled, { css } from "styled-components/macro";

export const AccordionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionLabel = styled(TitleH5)`
    align-items: center;
    display: flex;
    flex: 1;

    p {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};
    }

    br {
        display: none;
    }
`;

export const AccordionItem = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AccordionListDecorationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
`;

export const AccordionListDecoration = styled.span`
    width: 6px;
    height: 6px;
    flex-shrink: 0;

    color: ${theme.colors.brand[300]};
`;

export const AccordionIconWrapper = styled.figure`
    display: flex;
    justify-content: center;
    margin-right: ${theme.spacing.small};
    width: 20px;

    > span {
        margin: 0;
    }
`;

export const AccordionContent = styled.div``;

interface IAccordionButtonProps {
    isActive?: boolean;
}

export const AccordionButton = styled.button<IAccordionButtonProps>`
    align-items: center;
    border: none;
    color: ${theme.colors.base[500]};
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 12px;
    outline: none;

    &:focus {
        outline: none;
    }
`;

export const AccordionButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
`;

export const AccordionChevron = styled.div<{ active: boolean }>`
    display: flex;

    svg {
        height: 4px;
        transition: transform 0.2s ease;
        width: 8px;
    }

    ${({ active }) =>
        active &&
        css`
            svg {
                color: ${theme.colors.brand[300]};
                transform: rotate(180deg);
            }
        `}
`;
