// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import {
    Container,
    PeriodWrapper,
    SelectedPeriodWrapper,
    ScheduleIconWrapperSkeleton,
    SelectedPeriodLabelSkeleton,
    DayInformationsAssertivenessSkeleton,
    Divider,
    AssertivenessWrapper,
    ActionsWrapper,
    IconActionWrapperSkeleton
} from "./performance-navigator.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";
import { ScreenWidthRender } from "component-library/utilities/screen-width-render";
import { Skeleton } from "component-library/utilities/skeleton";

export const PerformanceNavigatorSkeleton: FunctionComponent = () => {
    return (
        <Container>
            <PeriodWrapper>
                <SelectedPeriodWrapper>
                    <ScheduleIconWrapperSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </ScheduleIconWrapperSkeleton>

                    <Spacing size={8} direction="horizontal" />

                    <SelectedPeriodLabelSkeleton>
                        <Skeleton width="104px" height="100%" borderRadius="4px" />
                    </SelectedPeriodLabelSkeleton>
                </SelectedPeriodWrapper>

                <ScreenWidthRender
                    renderingWidth={768}
                    actionAfterRenderingWidth="show"
                    content={
                        <>
                            <Spacing size={4} direction="vertical" />

                            <DayInformationsAssertivenessSkeleton>
                                <Skeleton width="293px" height="100%" borderRadius="4px" />
                            </DayInformationsAssertivenessSkeleton>
                        </>
                    }
                />
            </PeriodWrapper>

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="vertical" />} />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="show" content={<Spacing size={26} direction="horizontal" />} />

            <Divider />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="vertical" />} />

            <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="show" content={<Spacing size={16} direction="horizontal" />} />

            <AssertivenessWrapper>
                <ScreenWidthRender
                    renderingWidth={768}
                    actionAfterRenderingWidth="hide"
                    content={
                        <>
                            <DayInformationsAssertivenessSkeleton>
                                <Skeleton width="150px" height="100%" borderRadius="4px" />
                            </DayInformationsAssertivenessSkeleton>

                            <DayInformationsAssertivenessSkeleton>
                                <Skeleton width="140px" height="100%" borderRadius="4px" />
                            </DayInformationsAssertivenessSkeleton>
                        </>
                    }
                />

                <ScreenWidthRender renderingWidth={768} actionAfterRenderingWidth="hide" content={<Spacing size={16} direction="horizontal" />} />

                <ActionsWrapper>
                    <IconActionWrapperSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </IconActionWrapperSkeleton>

                    <Spacing size={24} direction="horizontal" />

                    <IconActionWrapperSkeleton>
                        <Skeleton width="100%" height="100%" borderRadius="4px" />
                    </IconActionWrapperSkeleton>
                </ActionsWrapper>
            </AssertivenessWrapper>
        </Container>
    );
};
