import { DateTime } from "luxon";

const formatDate = (date: string, format: string) => {
    if (!DateTime.fromISO(date).isValid) {
        return "-";
    }

    return DateTime.fromISO(date).toFormat(format);
};

export default formatDate;
