import { createActions, createReducer } from "reduxsauce";
import { ILessonPlanCustomState, LessonPlanCustomActionsType, ILessonPlanCustomActionsCreators } from "store/interfaces/ILessonPlanCustom";

export const { Creators, Types } = createActions<Record<LessonPlanCustomActionsType, LessonPlanCustomActionsType>, ILessonPlanCustomActionsCreators>({
    getLessonPlanCustomRequest: [],
    getLessonPlanCustomSuccess: ["payload"],
    getLessonPlanCustomFailure: ["payload"],

    getLessonPlanCustomWeeksRequest: ["payload"],
    getLessonPlanCustomWeeksSuccess: ["payload"],
    getLessonPlanCustomWeeksFailure: ["payload"],

    getLessonPlanCustomSectionRequest: ["payload"],
    getLessonPlanCustomSectionSuccess: ["payload"],
    getLessonPlanCustomSectionFailure: ["payload"],

    getLessonPlanCustomSectionWeekRequest: ["payload"],
    getLessonPlanCustomSectionWeekSuccess: ["payload"],
    getLessonPlanCustomSectionWeekFailure: ["payload"],

    getLessonPlanCustomSectionWeekListRequest: ["payload"],
    getLessonPlanCustomSectionWeekListSuccess: ["payload"],
    getLessonPlanCustomSectionWeekListFailure: ["payload"],

    getLessonPlanCustomSectionWeekListItemsRequest: ["payload"],
    getLessonPlanCustomSectionWeekListItemsSuccess: ["payload"],
    getLessonPlanCustomSectionWeekListItemsFailure: ["payload"],

    getLessonPlanCustomSectionWeekPlanListRequest: ["payload"],
    getLessonPlanCustomSectionWeekPlanListSuccess: ["payload"],
    getLessonPlanCustomSectionWeekPlanListFailure: ["payload"],

    postLessonPlanCustomRequest: ["payload"],
    postLessonPlanCustomSuccess: [],
    postLessonPlanCustomFailure: ["payload"],

    getLessonPlanCustomCareersRequest: [],
    getLessonPlanCustomCareersSuccess: ["payload"],
    getLessonPlanCustomCareersFailure: ["payload"],

    setLessonPlanCustomCareerRequest: ["payload"],
    setLessonPlanCustomCareerSuccess: ["payload"],
    setLessonPlanCustomCareerFailure: ["payload"],

    setLessonPlanCustomTotalHoursWeekTimeRequest: ["payload"],
    setLessonPlanCustomTotalHoursWeekTimeSuccess: ["payload"],
    setLessonPlanCustomTotalHoursWeekTimeFailure: ["payload"],

    getLessonPlanCustomMinimumHoursRecommendedRequest: [],
    getLessonPlanCustomMinimumHoursRecommendedSuccess: ["payload"],
    getLessonPlanCustomMinimumHoursRecommendedFailure: ["payload"],

    getLessonPlanSelectionRequest: [],
    getLessonPlanSelectionSuccess: ["payload"],
    getLessonPlanSelectionFailure: ["payload"],

    postLessonPlanSelectionRequest: ["payload"],
    postLessonPlanSelectionSuccess: ["payload"],
    postLessonPlanSelectionFailure: ["payload"],

    setLessonPlanCustomResetPlanRequest: ["payload"],
    setLessonPlanCustomResetPlanSuccess: ["payload"],
    setLessonPlanCustomResetPlanFailure: ["payload"],

    clearLessonPlanSelection: [],
    clearLessonPlanCustom: [],

    markTextMaterialDoneRequest: ["payload"],
    markTextMaterialDoneSuccess: ["payload"],
    markTextMaterialDoneFailure: ["payload"]
});

export const INITIAL_STATE: ILessonPlanCustomState = {
    isLoading: false,
    isLoadingLessonPlanCustom: true,
    isLoadingWeeks: true,
    isLoadingSection: false,
    isLoadingSectionWeek: false,
    isLoadingWeekListItems: false,
    isLoadingWeekPlanList: false,
    isLoadingLessonPlanSelection: true,
    isLoadingSetTotalHoursWeek: false,
    isDisabledSchedule: false,
    careers: [],
    career: undefined,
    scheduleDays: {
        days: []
    },
    totalHoursWeekTime: 0,
    minimumHoursRecommended: 0,
    lessonPlanCustom: [],
    lessonPlanWeeks: [],
    lessonPlanSection: [],
    lessonPlanSectionWeek: [],
    lessonPlanSectionWeekList: [],
    lessonPlanSectionWeekListItems: {
        contentPerType: []
    },
    lessonPlanSectionWeekPlanList: [],
    lessonPlanSelection: 0,
    resetPlan: false
};

const getLessonPlanCustomRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingLessonPlanCustom: true });
const getLessonPlanCustomSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanCustom: action.payload,
    isLoadingLessonPlanCustom: false
});
const getLessonPlanCustomFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingLessonPlanCustom: false,
    isLoadingLessonPlanSelection: false,
    isDisabledSchedule: false
});

const getLessonPlanCustomWeeksRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingWeeks: true });
const getLessonPlanCustomWeeksSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanWeeks: [...action.payload],
    isLoadingWeeks: false
});
const getLessonPlanCustomWeeksFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingWeeks: false
});

const getLessonPlanCustomSectionRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingSection: true });
const getLessonPlanCustomSectionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSection: [...action.payload],
    isLoadingSection: false,
    lessonPlanSectionWeek: [],
    isLoadingSectionWeek: false,
    lessonPlanSectionWeekListItems: {
        contentPerType: []
    },
    isLoadingWeekListItems: false
});
const getLessonPlanCustomSectionFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingSection: false });

const getLessonPlanCustomSectionWeekRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingSectionWeek: true });
const getLessonPlanCustomSectionWeekSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSectionWeek: [...action.payload],
    isLoadingSectionWeek: false,
    lessonPlanSectionWeekListItems: {
        contentPerType: []
    },
    isLoadingWeekListItems: false
});
const getLessonPlanCustomSectionWeekFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingSectionWeek: false });

const getLessonPlanCustomSectionWeekListItemsRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingWeekListItems: true });
const getLessonPlanCustomSectionWeekListItemsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSectionWeekListItems: action.payload,
    isLoadingWeekListItems: false
});
const getLessonPlanCustomSectionWeekListItemsFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingWeekListItems: false });

const getLessonPlanCustomSectionWeekListRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getLessonPlanCustomSectionWeekListSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        lessonPlanSectionWeekList: [...state.lessonPlanSectionWeekList, action.payload]
    };
};
const getLessonPlanCustomSectionWeekListFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const postLessonPlanCustomRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const postLessonPlanCustomSuccess = (state = INITIAL_STATE) => ({ ...state, isPlanCreated: true, isLoading: false });
const postLessonPlanCustomFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getLessonPlanCustomCareersRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getLessonPlanCustomCareersSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    careers: [...action.payload],
    isLoading: false
});
const getLessonPlanCustomCareersFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const setLessonPlanCustomCareerRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const setLessonPlanCustomCareerSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        career: action.payload,
        isLoading: false
    };
};
const setLessonPlanCustomCareerFailure = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: false
    };
};

const setLessonPlanCustomTotalHoursWeekTimeRequest = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingSetTotalHoursWeek: true,
    isDisabledSchedule: true
});
const setLessonPlanCustomTotalHoursWeekTimeSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        totalHoursWeekTime: action.payload.totalHoursWeekTime,
        scheduleDays: { days: action.payload.data.days },
        isLoadingSetTotalHoursWeek: false,
        isDisabledSchedule: false
    };
};
const setLessonPlanCustomTotalHoursWeekTimeFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoadingSetTotalHoursWeek: false,
    isDisabledSchedule: false
});

const getLessonPlanCustomSectionWeekPlanListRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingWeekPlanList: true });
const getLessonPlanCustomSectionWeekPlanListSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSectionWeekPlanList: [...action.payload],
    isLoadingWeekPlanList: false
});
const getLessonPlanCustomSectionWeekPlanListFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingWeekPlanList: false });

const getLessonPlanCustomMinimumHoursRecommendedRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getLessonPlanCustomMinimumHoursRecommendedSuccess = (state = INITIAL_STATE, action: any) => {
    return {
        ...state,
        minimumHoursRecommended: action.payload
        // isLoading: false
    };
};
const getLessonPlanCustomMinimumHoursRecommendedFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const getLessonPlanSelectionRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingLessonPlanSelection: true });
const getLessonPlanSelectionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSelection: action.payload.selection,
    isLoadingLessonPlanSelection: false
});
const getLessonPlanSelectionFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingLessonPlanSelection: false });

const postLessonPlanSelectionRequest = (state = INITIAL_STATE) => ({ ...state, isLoadingLessonPlanSelection: true });
const postLessonPlanSelectionSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    lessonPlanSelection: action?.payload?.selection,
    isLoadingLessonPlanSelection: false
});
const postLessonPlanSelectionFailure = (state = INITIAL_STATE) => ({ ...state, isLoadingLessonPlanSelection: false });

const setLessonPlanCustomResetPlanRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const setLessonPlanCustomResetPlanSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    resetPlan: action?.payload?.resetPlan
    // isLoading: false
});
const setLessonPlanCustomResetPlanFailure = (state = INITIAL_STATE) => ({ ...state, isLoading: false });

const clearLessonPlanSelection = (state = INITIAL_STATE) => ({
    ...state,
    lessonPlanSelection: 0,
    isLoadingLessonPlanSelection: false
});

const clearLessonPlanCustom = () => INITIAL_STATE;

const markTextMaterialDoneRequest = (state = INITIAL_STATE) => ({ ...state });
const markTextMaterialDoneSuccess = (state = INITIAL_STATE, action: any) => {
    const { contentPerType } = state.lessonPlanSectionWeekListItems;

    const formattedLessonPlanSectionWeekListItemsContentPerType = contentPerType.map((contentPerTypeItem) => {
        const contentPerTypeItemChildrenIndex = contentPerTypeItem.items.findIndex((item) => item.id === action.payload);

        if (contentPerTypeItemChildrenIndex === -1) {
            return contentPerTypeItem;
        }

        const currentItem = contentPerTypeItem.items[contentPerTypeItemChildrenIndex];

        if (!!currentItem.done) {
            return contentPerTypeItem;
        }

        const { completedItems, totalItems } = contentPerTypeItem.progress;

        return {
            ...contentPerTypeItem,
            items: contentPerTypeItem.items.map((item, index) => (contentPerTypeItemChildrenIndex === index ? { ...item, done: true } : item)),
            progress: {
                totalItems,
                completedItems: completedItems + 1,
                percent: Math.round(((completedItems + 1) * 100) / totalItems)
            }
        };
    });

    return {
        ...state,
        lessonPlanSectionWeekListItems: {
            contentPerType: formattedLessonPlanSectionWeekListItemsContentPerType
        }
    };
};
const markTextMaterialDoneFailure = (state = INITIAL_STATE) => ({ ...state });

export default createReducer(INITIAL_STATE, {
    [Types.GET_LESSON_PLAN_CUSTOM_REQUEST]: getLessonPlanCustomRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SUCCESS]: getLessonPlanCustomSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_FAILURE]: getLessonPlanCustomFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_WEEKS_REQUEST]: getLessonPlanCustomWeeksRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_WEEKS_SUCCESS]: getLessonPlanCustomWeeksSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_WEEKS_FAILURE]: getLessonPlanCustomWeeksFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_REQUEST]: getLessonPlanCustomSectionRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_SUCCESS]: getLessonPlanCustomSectionSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_FAILURE]: getLessonPlanCustomSectionFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_REQUEST]: getLessonPlanCustomSectionWeekRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_SUCCESS]: getLessonPlanCustomSectionWeekSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_FAILURE]: getLessonPlanCustomSectionWeekFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_REQUEST]: getLessonPlanCustomSectionWeekListRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_SUCCESS]: getLessonPlanCustomSectionWeekListSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_FAILURE]: getLessonPlanCustomSectionWeekListFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_ITEMS_REQUEST]: getLessonPlanCustomSectionWeekListItemsRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_ITEMS_SUCCESS]: getLessonPlanCustomSectionWeekListItemsSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_LIST_ITEMS_FAILURE]: getLessonPlanCustomSectionWeekListItemsFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_PLAN_LIST_REQUEST]: getLessonPlanCustomSectionWeekPlanListRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_PLAN_LIST_SUCCESS]: getLessonPlanCustomSectionWeekPlanListSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_SECTION_WEEK_PLAN_LIST_FAILURE]: getLessonPlanCustomSectionWeekPlanListFailure,

    [Types.POST_LESSON_PLAN_CUSTOM_REQUEST]: postLessonPlanCustomRequest,
    [Types.POST_LESSON_PLAN_CUSTOM_SUCCESS]: postLessonPlanCustomSuccess,
    [Types.POST_LESSON_PLAN_CUSTOM_FAILURE]: postLessonPlanCustomFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_CAREERS_REQUEST]: getLessonPlanCustomCareersRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_CAREERS_SUCCESS]: getLessonPlanCustomCareersSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_CAREERS_FAILURE]: getLessonPlanCustomCareersFailure,

    [Types.SET_LESSON_PLAN_CUSTOM_CAREER_REQUEST]: setLessonPlanCustomCareerRequest,
    [Types.SET_LESSON_PLAN_CUSTOM_CAREER_SUCCESS]: setLessonPlanCustomCareerSuccess,
    [Types.SET_LESSON_PLAN_CUSTOM_CAREER_FAILURE]: setLessonPlanCustomCareerFailure,

    [Types.SET_LESSON_PLAN_CUSTOM_TOTAL_HOURS_WEEK_TIME_REQUEST]: setLessonPlanCustomTotalHoursWeekTimeRequest,
    [Types.SET_LESSON_PLAN_CUSTOM_TOTAL_HOURS_WEEK_TIME_SUCCESS]: setLessonPlanCustomTotalHoursWeekTimeSuccess,
    [Types.SET_LESSON_PLAN_CUSTOM_TOTAL_HOURS_WEEK_TIME_FAILURE]: setLessonPlanCustomTotalHoursWeekTimeFailure,

    [Types.GET_LESSON_PLAN_CUSTOM_MINIMUM_HOURS_RECOMMENDED_REQUEST]: getLessonPlanCustomMinimumHoursRecommendedRequest,
    [Types.GET_LESSON_PLAN_CUSTOM_MINIMUM_HOURS_RECOMMENDED_SUCCESS]: getLessonPlanCustomMinimumHoursRecommendedSuccess,
    [Types.GET_LESSON_PLAN_CUSTOM_MINIMUM_HOURS_RECOMMENDED_FAILURE]: getLessonPlanCustomMinimumHoursRecommendedFailure,

    [Types.GET_LESSON_PLAN_SELECTION_REQUEST]: getLessonPlanSelectionRequest,
    [Types.GET_LESSON_PLAN_SELECTION_SUCCESS]: getLessonPlanSelectionSuccess,
    [Types.GET_LESSON_PLAN_SELECTION_FAILURE]: getLessonPlanSelectionFailure,

    [Types.SET_LESSON_PLAN_CUSTOM_RESET_PLAN_REQUEST]: setLessonPlanCustomResetPlanRequest,
    [Types.SET_LESSON_PLAN_CUSTOM_RESET_PLAN_SUCCESS]: setLessonPlanCustomResetPlanSuccess,
    [Types.SET_LESSON_PLAN_CUSTOM_RESET_PLAN_FAILURE]: setLessonPlanCustomResetPlanFailure,

    [Types.POST_LESSON_PLAN_SELECTION_REQUEST]: postLessonPlanSelectionRequest,
    [Types.POST_LESSON_PLAN_SELECTION_SUCCESS]: postLessonPlanSelectionSuccess,
    [Types.POST_LESSON_PLAN_SELECTION_FAILURE]: postLessonPlanSelectionFailure,

    [Types.CLEAR_LESSON_PLAN_SELECTION]: clearLessonPlanSelection,
    [Types.CLEAR_LESSON_PLAN_CUSTOM]: clearLessonPlanCustom,

    [Types.MARK_TEXT_MATERIAL_DONE_REQUEST]: markTextMaterialDoneRequest,
    [Types.MARK_TEXT_MATERIAL_DONE_SUCCESS]: markTextMaterialDoneSuccess,
    [Types.MARK_TEXT_MATERIAL_DONE_FAILURE]: markTextMaterialDoneFailure
});
