import React, { useState, useCallback, useEffect, useMemo } from "react";
import { ExerciseListDiscursiveInputContainer } from "./styles";
import Textarea from "components/Form/Textarea";
import Button from "components/Button";

interface IProps {
    discursiveAnswer?: string;
    onSubmit(text: string): void;
}

const ExerciseListDiscursiveInput = ({ onSubmit, discursiveAnswer }: IProps) => {
    const [text, setText] = useState("");

    const setAnsweredText = useCallback(() => {
        if (!!discursiveAnswer) {
            setText(discursiveAnswer);
        }
    }, [discursiveAnswer]);

    useEffect(() => setAnsweredText(), [setAnsweredText]);

    const handleSubmit = useCallback(() => {
        if (!text.trim().length) {
            return;
        }

        onSubmit(text);
    }, [text, onSubmit]);

    const disableButton = useMemo(() => {
        if (!text.trim().length) {
            return true;
        }

        if (!!discursiveAnswer && discursiveAnswer === text) {
            return true;
        }

        return false;
    }, [discursiveAnswer, text]);

    return (
        <ExerciseListDiscursiveInputContainer>
            <Textarea minRows={6} onChange={(event) => setText(event.target.value)} value={text} placeholder="Escreva sua resposta..." />

            <Button variant="info" type="button" onClick={handleSubmit} size="medium" disabled={disableButton}>
                {!!discursiveAnswer ? "Atualizar" : "Salvar"}
            </Button>
        </ExerciseListDiscursiveInputContainer>
    );
};

export default React.memo(ExerciseListDiscursiveInput);
