// Dependecies
import styled, { css } from "styled-components";

// Utils
import { theme } from "config/theme";

type ContainerStyleProps = {
    buttonSize: "small" | "medium" | "large" | "full";
    buttonVariant: "primary" | "secondary" | "outline-primary" | "outline-secondary" | "outline-info";
    isActive: boolean;
    isDisabled: boolean;
    isFullyAdaptative: boolean;
};

export const Container = styled.button<ContainerStyleProps>` 
    font-size: 1rem;
    letter-spacing: 0.005em;
    line-height: 1.375;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 700;
    border: none;
    outline: 0;
    min-width: 120px;
    border-radius: 4px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:focus {
        outline: none;
    }

    ${({ isDisabled }) =>
        !!isDisabled &&
        css`
            background: ${theme.colors.base[200]};
            color: ${theme.colors.base[300]};
            cursor: no-drop;
            border: none;

            &:hover {
                background: ${theme.colors.base[200]};
                color: ${theme.colors.base[300]};
                border: none;
            }
        `}

    ${({ buttonVariant, isDisabled }) =>
        !isDisabled &&
        buttonVariant === "primary" &&
        css`
            background-color: ${theme.colors.brand[300]};
            color: ${theme.colors.white};
            &:hover {
                background-color: ${theme.colors.brand[400]};
            }
        `}

    ${({ buttonVariant, isDisabled }) =>
        !isDisabled &&
        buttonVariant === "secondary" &&
        css`
            background-color: ${theme.colors[buttonVariant][300]};
            color: ${theme.colors.white};
            &:hover,
            &:active {
                background-color: ${theme.colors[buttonVariant][400]};
            }
        `}

    ${({ buttonVariant, isDisabled }) =>
        !isDisabled &&
        buttonVariant === "outline-primary" &&
        css`
            background-color: transparent;
            border: 1px solid ${theme.colors.brand[300]};
            color: ${theme.colors.brand[300]};

            &:hover {
                background-color: ${theme.colors.brand[100]};
                color: ${theme.colors.brand[500]};
                border: 1px solid ${theme.colors.brand[500]};
            }

            &:active {
                background-color: ${theme.colors.brand[100]};
                color: ${theme.colors.brand[500]};
                border: 1px solid ${theme.colors.brand[500]};
            }
        `}

    ${({ buttonVariant, isDisabled }) =>
        !isDisabled &&
        buttonVariant === "outline-secondary" &&
        css`
            background-color: transparent;
            border: 1px solid ${theme.colors.secondary[300]};
            color: ${theme.colors.secondary[300]};

            &:hover {
                background-color: ${theme.colors.secondary[100]};
                color: ${theme.colors.secondary[500]};
                border: 1px solid ${theme.colors.secondary[500]};
            }

            &:active {
                background-color: ${theme.colors.secondary[100]};
                color: ${theme.colors.secondary[500]};
                border: 1px solid ${theme.colors.secondary[500]};
            }
        `}

    ${({ buttonVariant, isDisabled }) =>
        !isDisabled &&
        buttonVariant === "outline-info" &&
        css`
            background-color: transparent;
            border: 1px solid ${theme.colors.system.info[300]};
            color: ${theme.colors.system.info[300]};

            &:hover {
                background-color: ${theme.colors.system.info[100]};
                color: ${theme.colors.system.info[500]};
                border: 1px solid ${theme.colors.system.info[500]};
            }

            &:active {
                background-color: ${theme.colors.system.info[100]};
                color: ${theme.colors.system.info[500]};
                border: 1px solid ${theme.colors.system.info[500]};
            }
        `}


    ${({ buttonSize }) =>
        buttonSize === "small" &&
        css`
            font-size: 0.75rem;
            height: 2rem;
        `}

    ${({ buttonSize }) =>
        buttonSize === "medium" &&
        css`
            font-size: 1rem;
            height: 2.5rem;
        `}

    ${({ buttonSize }) =>
        buttonSize === "large" &&
        css`
            font-size: 1.5rem;
            padding: 0.667em 1.334em;
        `}

    ${({ buttonSize }) =>
        buttonSize === "full" &&
        css`
            padding: 0;
            width: 100%;
            height: 100%;
            min-width: auto;
        `}

    ${({ isFullyAdaptative }) =>
        !!isFullyAdaptative &&
        css`
            width: 100%;
        `}

    ${({ isActive, buttonVariant }) =>
        !!isActive &&
        buttonVariant === "outline-primary" &&
        css`
            background-color: ${theme.colors.brand[100]};
            color: ${theme.colors.brand[500]};
            border: 1px solid ${theme.colors.brand[500]};
        `}
`;
