import styled from "styled-components/macro";
import { MediaQueriesManual } from "assets/styles/settings";
import { theme } from "config/theme";

export const LessonPlanCustomSchedulesWrapper = styled.div`
    height: 100%;

    .webp-card {
        padding: 16px 13px;
        margin-bottom: 24px;
        height: 100%;
    }

    ${MediaQueriesManual(768)} {
        .webp-card {
            padding: 24px 32px 31px;
        }
    }

    ${MediaQueriesManual(1024)} {
        .webp-card {
            padding: 24px 16px;
        }
    }
`;

export const LessonPlanCustomSchedulesHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    flex-direction: column;
    width: 100%;

    .labelLesson {
        padding-bottom: 16px;
        border-bottom: 1px solid #ced0dd;
        margin-bottom: 16px;
        width: 100%;
        text-align: center;
        color: ${theme.colors.base[500]};
    }

    .labelTitle {
        width: 232px;
        line-height: 22px;
        width: 100%;
        text-align: center;

        margin-bottom: 32px;
        color: ${theme.colors.base[500]};
    }

    .webp-total-number {
        width: 100%;
        text-align: center;
    }

    .divider {
        display: none;
    }

    ${MediaQueriesManual(768)} {
        flex-direction: inherit;
        height: 44px;

        .labelLesson {
            display: none;
        }

        .labelTitle {
            width: 232px;
            text-align: left;
            margin-bottom: 0px;
        }

        .webp-total-number {
            width: 232px;
            text-align: right;
        }

        .divider {
            display: flex;
            width: 0px;
            height: 100%;
            border: 1px solid #ced0dd;
        }
    }
`;

export const LessonPlanCustomSchedulesCalendar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 262px;
    height: 543.85px;
    margin: auto;

    ${MediaQueriesManual(768)} {
        flex-direction: inherit;
        max-width: 100%;
        width: 537px;
        height: 286.18px;
    }

    ${MediaQueriesManual(1024)} {
        width: 100%;
        height: auto;
        flex-flow: row wrap;
        justify-content: center;

        .webp-study-plan-period-day {
            width: 75px;
            height: 265px;

            &:nth-child(2) {
                margin-bottom: 18px;
            }
        }
    }

    ${MediaQueriesManual(1104)} {
        justify-content: space-between;

        .webp-study-plan-period-day {
            width: 64px;
        }
    }
`;
