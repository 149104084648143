import React, { memo } from "react";
import { SubjectScreenContainer, SubjectsHeader } from "./styles";
import SubjectGroup from "components/SubjectGroup";
import { Grid } from "components/Grid";
import { theme } from "config/theme";

const SubjectsSkeleton = memo(() => {
    return (
        <SubjectScreenContainer data-test-id="subject-container">
            <Grid>
                <SubjectsHeader>
                    <h1>{theme.project.slug !== "promilitares" ? "Matérias" : "Módulos"}</h1>
                </SubjectsHeader>
                <SubjectGroup isLoading subjectGroup={{ color: theme.colors.base[300], created: "", id: 0, name: "" }} />
                <SubjectGroup isLoading subjectGroup={{ color: theme.colors.base[300], created: "", id: 0, name: "" }} />
            </Grid>
        </SubjectScreenContainer>
    );
});

export default SubjectsSkeleton;
