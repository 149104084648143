import IPagination from "interfaces/IPagination";
import { IFilterGroup } from "interfaces/IFilterGroup";

export enum ComplaintTypeEnum {
    Spam = 1,
    Offensive,
    WrongSubject,
    DubiousLink
}

export interface ILikePayload {
    method: "POST" | "DELETE";
    endpoint: string;
    id?: string;
}

export interface IComplaintPayload {
    complaintType: number;
    answer?: string;
    comment?: string;
    question?: string;
}

export interface IComplaint {
    _id: string;
    complaintType: ComplaintTypeEnum;
    user: string;
    updated: string;
    created: string;
    id: string;
    student: IForumStudent;
    answer?: string;
    comment?: string;
    question?: string;
}

export interface IForumStudent {
    _id: string;
    idStudent: number;
    name: string;
    id: string;
}

export interface IForumExternalEntity {
    _id: string;
    idExternal: number;
    name: string;
    id: string;
    color: string;
}

export interface IForumAttachment {
    filename: string;
    originalFilename: string;
    url: string;
    _id: string;
}

export interface IForumComment {
    attachmentsTotal: number;
    content: string;
    created: string;
    complaint?: IComplaint;
    isLiked: boolean;
    likesTotal: number;
    updated: string;
    _id: string;
    attachments?: IForumAttachment[];
    user?: IForumUser;
}

export interface IForumQuestionAnswer {
    _id: string;
    attachmentsTotal: number;
    likesTotal: number;
    commentsTotal: number;
    content: string;
    updated: string;
    created: string;
    id: string;
    complaint?: IComplaint;
    isLiked: boolean;
    attachments?: IForumAttachment[];
    comments?: IForumComment[];
    user?: IForumUser;
}

export interface IForumUser {
    id: string;
    name: string;
    username: string;
    _id: string;
}

export interface IForumQuestion {
    _id: string;
    content: string;
    created: string;
    complaint?: IComplaint;
    isLiked: boolean;
    updated: string;
    answersTotal: number;
    attachmentsTotal: number;
    likesTotal: number;
    answers?: IForumQuestionAnswer[];
    attachments?: IForumAttachment[];
    hasError?: boolean;
    subject?: IForumExternalEntity & { color?: string };
    subjectFront?: IForumExternalEntity;
    subjectModule?: IForumExternalEntity;
    tags?: any[];
    user?: IForumUser;
}

export interface IForumItem {
    _id: string;
    answersTotal: number;
    attachmentsTotal: number;
    content: string;
    created: string;
    id: string;
    likesTotal: 0;
    updated: string;
    subject?: IForumExternalEntity;
    user?: IForumUser;
}

export interface IForumSubject {
    id: number;
    slug: string;
    name: string;
    color: string;
    hasForum: boolean;
    image: string | null;
    order: number;
    isPublished: boolean;
}

export type UploadStatus = "loading" | "success" | "error";
export type UploadType = "answer" | "comment" | "question";

export interface IForumUpload {
    id: number;
    status: UploadStatus;
    file: File;
    attachment: string;
}

export interface IForumState {
    isAnswerCreated: boolean;
    isCommentCreated: boolean;
    isQuestionCreated: boolean;
    isLoadingAnswer: boolean;
    isLoadingComment: boolean;
    isLoadingForum: boolean;
    isLoadingQuestion: boolean;
    isLoadingUpload: boolean;
    isLoadingForumSubjects: boolean;
    isResetedRequest: boolean;
    filters: IFilterGroup[];
    questions: IPagination<IForumItem>;
    question: IForumQuestion;
    uploads: {
        [key in UploadType]: IForumUpload[];
    };
}

export type ForumActionsType =
    | "GET_ALL_QUESTIONS_REQUEST"
    | "GET_ALL_QUESTIONS_SUCCESS"
    | "GET_ALL_QUESTIONS_FAILURE"
    | "GET_QUESTION_REQUEST"
    | "GET_QUESTION_SUCCESS"
    | "GET_QUESTION_FAILURE"
    | "GET_FORUM_SUBJECTS_REQUEST"
    | "GET_FORUM_SUBJECTS_SUCCESS"
    | "GET_FORUM_SUBJECTS_FAILURE"
    | "CREATE_OR_UPDATE_QUESTION_REQUEST"
    | "CREATE_OR_UPDATE_QUESTION_SUCCESS"
    | "CREATE_OR_UPDATE_QUESTION_FAILURE"
    | "UPLOAD_ATTACHMENTS_REQUEST"
    | "UPLOAD_ATTACHMENTS_SUCCESS"
    | "UPLOAD_ATTACHMENTS_FAILURE"
    | "RETRY_UPLOAD_ATTACHMENTS_REQUEST"
    | "RETRY_UPLOAD_ATTACHMENTS_SUCCESS"
    | "RETRY_UPLOAD_ATTACHMENTS_FAILURE"
    | "LIKE_REQUEST"
    | "LIKE_SUCCESS"
    | "LIKE_FAILURE"
    | "CREATE_OR_UPDATE_ANSWER_REQUEST"
    | "CREATE_OR_UPDATE_ANSWER_SUCCESS"
    | "CREATE_OR_UPDATE_ANSWER_FAILURE"
    | "CREATE_OR_UPDATE_COMMENT_REQUEST"
    | "CREATE_OR_UPDATE_COMMENT_SUCCESS"
    | "CREATE_OR_UPDATE_COMMENT_FAILURE"
    | "CREATE_COMPLAINT_REQUEST"
    | "CREATE_COMPLAINT_SUCCESS"
    | "CREATE_COMPLAINT_FAILURE"
    | "DELETE_ATTACHMENT"
    | "DELETE_QUESTION_REQUEST"
    | "DELETE_QUESTION_SUCCESS"
    | "DELETE_QUESTION_FAILURE"
    | "DELETE_COMMENT_REQUEST"
    | "DELETE_COMMENT_SUCCESS"
    | "DELETE_COMMENT_FAILURE"
    | "DELETE_ANSWER_REQUEST"
    | "DELETE_ANSWER_SUCCESS"
    | "DELETE_ANSWER_FAILURE"
    | "CLEAR_FORUM"
    | "CLEAR_FORUM_CREATED"
    | "CLEAR_FORUM_QUESTION";
