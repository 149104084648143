import queryString from "query-string";
import { useLocation } from "react-router";

export function useQueryString() {
    const location = useLocation().search;

    const query = queryString.parse(location);

    return query;
}
