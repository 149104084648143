import React, { useMemo } from "react";

// Helpers
import pluralize from "utils/pluralize";
import formatDate from "utils/formatDate";

// Assets
import * as F from "./styles";

interface IForumMetadataProps {
    answers?: number;
    attachments?: number;
    likes?: number;
    subject?: string;
    user?: string;
    created: string;
    color?: string;
}

const ForumMetadata: React.FC<IForumMetadataProps> = ({ answers, attachments, likes, color, subject, created, user }) => {
    const date = useMemo(() => formatDate(created, "dd/LL/yyyy - HH:mm"), [created]);

    return (
        <F.ForumMetadataContainer isVertical={Boolean(subject)}>
            <F.ForumMetadataTop>
                {user && <F.ForumMetadataAuthor>Autor(a): {user}</F.ForumMetadataAuthor>}
                {subject && <F.ForumMetadataSubject colorText={color}> Disciplina: {subject}</F.ForumMetadataSubject>}
            </F.ForumMetadataTop>
            <F.ForumMetadataBottom>
                <span>Criada em {date}</span>
                {typeof answers !== "undefined" && answers > 0 ? (
                    <span>{`${answers} ${pluralize(answers!, "respostas", "resposta")}`}</span>
                ) : (
                    <span>Nenhuma resposta</span>
                )}
                {typeof likes !== "undefined" && likes > 0 ? (
                    <span>{`${likes} ${pluralize(likes!, "curtidas", "curtida")}`}</span>
                ) : (
                    <span>Nenhuma curtida</span>
                )}
                {typeof attachments === "number" && attachments > 0 && <span>{`${attachments} ${pluralize(attachments!, "anexos", "anexo")}`}</span>}
            </F.ForumMetadataBottom>
        </F.ForumMetadataContainer>
    );
};

export default ForumMetadata;
