import React, { useMemo } from "react";

// Assets
import {
    ExerciseContentListContainer,
    ExerciseContentListItem,
    ExerciseDetailItem,
    ExerciseDetailsWrap,
    ExerciseMainChartCard,
    ExerciseMainHeader,
    ExerciseMainWrap,
    ExerciseSeparator,
    ExerciseListDashboardInfo
} from "./styles";

// Components
import If from "components/If";
import ExerciseListDashboardPlatformChart from "../PlatformChart";
import ExerciseListDashboardUserChart from "../UserChart";
import ExerciseListDashboardNotDone from "../NotDone";

// Helpers
import useIsMobile from "hooks/use-is-mobile";
import pluralize from "utils/pluralize";
import { IAnswerCard } from "store/ducks/answerCard";

interface IProps {
    realizationsTotal: number;
    questionsTotal: number;
    realizationsAverageHitRate: number;
    userRealizations: IAnswerCard[];
}

const ExerciseListDashboardMainChart = ({
    realizationsTotal = 0,
    questionsTotal = 0,
    userRealizations = [],
    realizationsAverageHitRate = 0
}: IProps) => {
    const isMobile = useIsMobile();

    const renderCurrentComponent = useMemo(() => {
        try {
            if (!realizationsTotal) {
                return <ExerciseListDashboardNotDone questionsTotal={questionsTotal} />;
            }

            if (!!userRealizations && !!userRealizations.length) {
                return <ExerciseListDashboardUserChart realizationsAverageHitRate={realizationsAverageHitRate} userRealizations={userRealizations} />;
            }

            return <ExerciseListDashboardPlatformChart questionsTotal={questionsTotal} realizationsAverageHitRate={realizationsAverageHitRate} />;
        } catch (error) {
            return null;
        }
    }, [realizationsTotal, userRealizations, questionsTotal, realizationsAverageHitRate]);

    const exerciseListLegend = useMemo(() => {
        const initalSentence = "Essa lista foi feita";
        try {
            if (!realizationsTotal) {
                return "Essa lista de exercício ainda não foi feita.";
            }

            if (!isMobile) {
                return `${initalSentence} ${realizationsTotal} ${pluralize(realizationsTotal, "vezes", "vez")} por ${pluralize(
                    realizationsTotal,
                    "outros alunos",
                    "outro aluno"
                )}.`;
            }

            return `${initalSentence} ${realizationsTotal} ${pluralize(realizationsTotal, "vezes", "vez")}.`;
        } catch (error) {
            return `${initalSentence} por algumas pessoas.`;
        }
    }, [realizationsTotal, isMobile]);

    return (
        <ExerciseMainChartCard>
            <ExerciseMainHeader>
                <ExerciseListDashboardInfo>{exerciseListLegend}</ExerciseListDashboardInfo>

                <If test={!!userRealizations && !!userRealizations.length}>
                    <If test={!isMobile}>
                        <ExerciseDetailsWrap>
                            <ExerciseDetailItem>{questionsTotal} questões</ExerciseDetailItem>
                        </ExerciseDetailsWrap>
                    </If>

                    <If test={isMobile}>
                        <h3>Quantidade total de questões:</h3>
                        <ExerciseContentListContainer>
                            <ExerciseContentListItem>&bull; {questionsTotal} Questões</ExerciseContentListItem>
                        </ExerciseContentListContainer>
                        <ExerciseSeparator id="ExerciseSeparator" />
                    </If>
                </If>
            </ExerciseMainHeader>

            <ExerciseMainWrap isMobile={isMobile}>{renderCurrentComponent}</ExerciseMainWrap>
        </ExerciseMainChartCard>
    );
};

export default React.memo(ExerciseListDashboardMainChart);
