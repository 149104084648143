// Dependencies
import React, { FunctionComponent, ReactNode } from "react";

// Styles
import { Container } from "./styles";

type WebpNavProps = {
    orientation?: "vertical" | "horizontal";
    contentLayout: "space-between" | "flex-start";
    contentNavComposition: ReactNode;
};

export const WebpNav: FunctionComponent<WebpNavProps> = ({ contentLayout = "flex-start", orientation = "horizontal", contentNavComposition }) => {
    return (
        <Container className="webp-nav" contentLayout={contentLayout} orientation={orientation}>
            {contentNavComposition}
        </Container>
    );
};
