// Dependencies
import React, { FunctionComponent } from "react";

// Styles
import { Container, Label } from "./activity-question-indicator.styles";

// Types
import { ActivityQuestionIndicatorProps } from "./activity-question-indicator.types";

export const ActivityQuestionIndicator: FunctionComponent<ActivityQuestionIndicatorProps> = ({ text, variant = "default" }) => {
    return (
        <Container variant={variant}>
            <Label variant={variant}>{text}</Label>
        </Container>
    );
};
