import styled from "styled-components";
import { theme } from "config/theme";

export const CallToActionContainer = styled.div<{ maxWidth?: string }>`
    max-width: ${(props) => props.maxWidth}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        margin-bottom: ${theme.spacing.medium};
    }

    img {
        width: 100%;
        display: flex;
    }
`;

export const CallToActionContent = styled.div<{ alignText: string }>`
    text-align: ${(props) => props.alignText};
    color: ${theme.colors.base[400]};
    font-size: ${theme.typography.sizes.medium};
`;
