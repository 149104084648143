import axios, { AxiosRequestConfig } from "axios";
import { theme } from "config/theme";

import apiErrorHandling from "./errorHandling";

const XBrand = theme.project.brand;

export const headers = {
    headers: {
        "Content-Type": "application/json",
        "X-Brand": XBrand,
        "X-Platform": "web"
    }
};

const isOurProject =
    theme.project.slug === "proenem" ||
    theme.project.slug === "promilitares" ||
    theme.project.slug === "proraiz" ||
    theme.project.slug === "promedicina";

export const baseURL = isOurProject ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_URL_WHITELABEL;

export const config: AxiosRequestConfig = {
    baseURL,
    headers: headers.headers
};

const api = axios.create(config);

api.interceptors.response.use(
    (response) => response,
    async (error) => apiErrorHandling(error)
);

export default api;
