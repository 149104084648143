import React from "react";

// Components
import Skeleton from "components/Skeleton";

// Assets
import { ProjectOverview } from "../../styles";
import { ProjectTitle } from "screens/Projects/Home/styles";

const OverviewPlaceholder = () => (
    <ProjectOverview>
        <ProjectTitle>
            <Skeleton width="70%" height="20px" />
        </ProjectTitle>
    </ProjectOverview>
);

export default OverviewPlaceholder;
