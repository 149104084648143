import React, { useCallback, useEffect, useState, useRef, memo, Suspense, lazy } from "react";

// Dependencies
import find from "lodash/find";
import { DateTime } from "luxon";
import capitalize from "lodash/capitalize";
import { animateScroll } from "react-scroll";
import { Col, Grid, Row } from "components/Grid";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

// Components
import ForbiddenMessage from "components/ForbiddenMessage";
import DatePickerPlaceholder from "./DatePicker/placeholder";
import ModulesListPlaceholder from "./ModulesList/placeholder";
import ModulePickerPlaceholder from "./ModulePicker/placeholder";

// Helpers
import history from "services/history";
import useIsMobile from "hooks/use-is-mobile";
import { getIntervalBetweenDates } from "helpers";
import { RouteComponentProps } from "react-router-dom";
import { ILessonPlanActions, ILessonPlanState } from "store/interfaces/ILessonPlan";
import { NotFoundProject as ForbiddenContainer } from "screens/Projects/Details/styles";

// Assets
import * as S from "./styles";

type Details = {
    day: string;
    weekday: string;
    events: any[];
};

interface IProps extends RouteComponentProps<{ idModule?: string; day?: string }, {}, { day: string }> {
    lessonPlan: ILessonPlanState;
    zoneName: string;
    lessonPlanActions: ILessonPlanActions;
}

const DatePicker = lazy(() => import("./DatePicker"));
const ModulesList = lazy(() => import("./ModulesList"));
const ModulePicker = lazy(() => import("./ModulePicker"));

const INITIAL_DETAILS = {
    day: "",
    events: [],
    weekday: ""
};

const LessonPlan = ({ lessonPlan, lessonPlanActions, match, zoneName }: IProps) => {
    const isMobile = useIsMobile();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isExpandable, setIsExpandable] = useState(false);
    const [details, setDetails] = useState<Details>(INITIAL_DETAILS);

    const { auth, courseSlug } = useSelector(({ auth, course }: IReduxStore) => ({ auth, courseSlug: course.slug }));

    const pageTitle = auth.menu.find((item) => item.url === `/app/curso/${courseSlug}/plano-de-estudos`)?.name;

    const loadLessonPlan = useCallback(() => {
        return lessonPlanActions.getLessonPlanRequest();
    }, [lessonPlanActions]);

    useEffect(() => {
        loadLessonPlan();
    }, [loadLessonPlan]);

    useEffect(() => {
        if (containerRef?.current) {
            animateScroll.scrollToTop({
                containerId: "plano-de-estudos-container",
                delay: 200,
                duration: 300
            });
        }
    }, [lessonPlan.day]);

    const loadModule = useCallback(() => {
        if (!match.params.idModule) return;

        if (+match.params.idModule > 0) {
            lessonPlanActions.getLessonPlanModuleRequest({ id: +match.params.idModule });
        }
    }, [lessonPlanActions, match.params.idModule]);

    // Get current module
    useEffect(() => {
        loadModule();
    }, [loadModule]);

    const generateDayDetails = useCallback(
        (items: any[]) => {
            if (!lessonPlan.day) {
                return;
            }

            const currentDay = DateTime.fromISO(lessonPlan.day).toFormat("yyyyLLdd");

            const currentDayModules = items.find((item) => {
                const day = Object.keys(item);

                if (day[0] === currentDay) {
                    return item;
                }

                return undefined;
            });

            const day = DateTime.fromISO(currentDay).setLocale("pt-BR");
            const weekday = day.toFormat("EEEE, dd");

            setDetails({
                day: day.toFormat("dd-LL-yyyy"),
                weekday,
                events: currentDayModules ? currentDayModules[currentDay] : []
            });
        },
        [lessonPlan.day]
    );

    const parseItems = useCallback(
        (items: any) => {
            if (!items.length) {
                return setDetails(INITIAL_DETAILS);
            }

            const formattedItems = items.reduce((acc: any, curr: any) => {
                const date = Object.keys(curr)[0];

                const currentDay = curr[date];

                const filteredDays = currentDay.filter((hour: any, index: number) => !!hour[index]);

                curr[date] = filteredDays;

                return [...acc, curr];
            }, []);

            generateDayDetails(formattedItems);
        },
        [generateDayDetails]
    );

    // Parse events and set state
    useEffect(() => {
        parseItems(lessonPlan.events);
    }, [parseItems, lessonPlan.events]);

    const handleDayChangeState = useCallback(
        (day: string) => {
            // if (match.params.day === day) {
            //     return;
            // }

            if (!lessonPlan.module.id) {
                return;
            }

            const formattedDate = DateTime.fromFormat(day, "dd-LL-yyyy").toISO();

            lessonPlanActions.setLessonPlanDay(formattedDate);

            history.push({ pathname: `/app/curso/${courseSlug}/plano-de-estudos/${lessonPlan.module.id}/${day}` });
        },
        [courseSlug, lessonPlan.module.id, lessonPlanActions]
    );

    const handleModuleChange = useCallback(
        (moduleId: any, day?: string) => {
            const module = find(lessonPlan.modules, { id: moduleId });

            if (module) {
                if (module.id === +match.params.idModule!) {
                    return;
                }

                if (!module.id) {
                    return;
                }

                lessonPlanActions.setModuleSuccess(module);

                if (day) {
                    const newDay = DateTime.fromFormat(day, "dd-LL-yyyy").toISO();

                    lessonPlanActions.setLessonPlanDay(newDay);
                } else {
                    lessonPlanActions.setLessonPlanDay(module.startDate);
                }

                const newStartDate = DateTime.fromISO(module.startDate).toFormat("dd-LL-yyyy");

                return history.push({ pathname: `/app/curso/${courseSlug}/plano-de-estudos/${module.id}/${day || newStartDate}` });
            }
        },
        [lessonPlan.modules, match.params.idModule, lessonPlanActions, courseSlug]
    );

    const handleDayChange = useCallback(
        (day: Date) => {
            const interval = getIntervalBetweenDates(lessonPlan.module.startDate, lessonPlan.module.endDate).map((date) => date.toJSDate());

            const dateOnModule = interval.find((date) => {
                return DateTime.fromJSDate(date).hasSame(DateTime.fromJSDate(day), "day");
            });

            const dayDT = DateTime.fromJSDate(day);
            const dayFormatted = dayDT.toFormat("dd-LL-yyyy");

            if (dateOnModule) {
                handleDayChangeState(dayFormatted);
            } else {
                const externalModule = lessonPlan.modules.find((_module) => {
                    const interval = getIntervalBetweenDates(_module.startDate, _module.endDate).map((date) => date.toJSDate());
                    const dateOnModule = interval.find((date) => {
                        return DateTime.fromJSDate(date).hasSame(DateTime.fromJSDate(day), "day");
                    });

                    return !!dateOnModule;
                });

                if (externalModule) {
                    handleModuleChange(externalModule.id, dayFormatted);
                }

                setDetails(INITIAL_DETAILS);
            }
        },
        [handleDayChangeState, lessonPlan.module.endDate, handleModuleChange, lessonPlan.module.startDate, lessonPlan.modules]
    );

    const handleCalendarExpand = useCallback(() => setIsExpandable(!isExpandable), [isExpandable]);

    return (
        <S.LessonPlanContainer ref={containerRef} id="plano-de-estudos-container">
            {isMobile && (
                <S.LessonPlanMobileHeader isExpanded={isExpandable}>
                    {isExpandable ? (
                        <Suspense fallback={<DatePickerPlaceholder />}>
                            <DatePicker
                                current={match.params.day}
                                modules={lessonPlan.modules}
                                currentModule={lessonPlan.module}
                                onChange={handleDayChange}
                            />
                        </Suspense>
                    ) : (
                        <Suspense fallback={<ModulePickerPlaceholder />}>
                            <ModulePicker
                                currentModule={lessonPlan.module}
                                onModuleChange={handleModuleChange}
                                modules={lessonPlan.modules.sort((prev, next) => prev.order - next.order)}
                                onClickDay={handleDayChangeState}
                            />
                        </Suspense>
                    )}
                    <S.ExpandCalendarButton onClick={handleCalendarExpand} />
                </S.LessonPlanMobileHeader>
            )}
            <S.LessonPlanModules>
                <Grid fluid>
                    {!isMobile && <S.Title>{pageTitle ?? "Agenda de aulas"}</S.Title>}
                    <Row>
                        <Col xs={12} md={8} lg={8}>
                            {lessonPlan.isLoading && (
                                <>
                                    <ModulePickerPlaceholder />
                                    <ModulesListPlaceholder />
                                </>
                            )}
                            {!isMobile && (
                                <Suspense fallback={<ModulePickerPlaceholder />}>
                                    <S.DesktopModulePicker>
                                        <ModulePicker
                                            currentModule={lessonPlan.module}
                                            modules={lessonPlan.modules.sort((prev, next) => prev.order - next.order)}
                                            onModuleChange={handleModuleChange}
                                            onClickDay={handleDayChangeState}
                                        />
                                    </S.DesktopModulePicker>
                                </Suspense>
                            )}
                            {lessonPlan.isLoadingModule && <ModulesListPlaceholder />}
                            {Boolean(details.events.length) && (
                                <S.LessonPlanModulesList>
                                    <S.LessonPlanModulesListItem>
                                        <Suspense fallback={<ModulesListPlaceholder />}>
                                            <S.LessonPlanModulesTitle>{capitalize(details.weekday)}</S.LessonPlanModulesTitle>
                                            <ModulesList
                                                currentDay={details.day}
                                                events={details.events}
                                                moduleSlug={lessonPlan.module.slug}
                                                zoneName={zoneName}
                                            />
                                        </Suspense>
                                    </S.LessonPlanModulesListItem>
                                </S.LessonPlanModulesList>
                            )}
                            {!lessonPlan.isLoadingModule && Boolean(lessonPlan.module.id) && !Boolean(details.events.length) && (
                                <ForbiddenContainer>
                                    <ForbiddenMessage text="Não há eventos para este período" />
                                </ForbiddenContainer>
                            )}
                        </Col>
                        <Col xs={false} md={4} lg={4}>
                            <S.DesktopDatePicker>
                                {lessonPlan.isLoading ? (
                                    <DatePickerPlaceholder />
                                ) : (
                                    <Suspense fallback={<DatePickerPlaceholder />}>
                                        <DatePicker
                                            current={match.params.day}
                                            currentModule={lessonPlan.module}
                                            modules={lessonPlan.modules}
                                            onChange={handleDayChange}
                                        />
                                    </Suspense>
                                )}
                            </S.DesktopDatePicker>
                        </Col>
                    </Row>
                </Grid>
            </S.LessonPlanModules>
        </S.LessonPlanContainer>
    );
};

export default memo(LessonPlan);
