import { theme } from "config/theme";
import { TitleH5 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import styled, { css } from "styled-components/macro";

export interface ICustomHeaderButtonProps {
    direction: string;
}

const DayBullet = css`
    align-items: center;
    background: transparent;
    border-radius: 50%;
    display: flex;
    font-size: ${theme.typography.sizes.small};
    height: 32px;
    justify-content: center;
    margin: 0 auto;
    transition: background 0.2s ease-in;
    width: 32px;
`;

export const DatePickerContainer = styled.div`
    padding: 0 ${theme.spacing.small};

    .react-datepicker__month-container {
        margin: 0 auto;
        display: block;
    }

    .react-datepicker__current-month {
        width: 100%;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        text-transform: uppercase;
    }

    .react-datepicker__month {
        display: block;
        width: 100%;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
    }

    .react-datepicker__header {
        margin-bottom: ${theme.spacing.small};
    }

    .react-datepicker__week {
        &:not(:last-child) {
            margin-bottom: ${theme.spacing.small};
        }
    }

    .react-datepicker__day-name {
        color: ${theme.colors.base[500]};
        font-weight: bold;
        ${DayBullet};
    }

    .react-datepicker__day {
        flex: 1;
        outline: none !important;

        .react-datepicker__day-helper {
            ${DayBullet};
            color: ${theme.colors.base[400]};
            font-family: ${theme.typography.family.secondary};
        }

        &--excluded,
        &--outside-month {
            .react-datepicker__day-helper {
                color: ${theme.colors.base[200]} !important;
            }
        }

        &--excluded {
            cursor: no-drop;
            position: relative;

            &:after {
                align-items: center;
                content: "🥳";
                display: flex;
                justify-content: center;
                left: 0;
                position: absolute;
                height: 100%;
                top: 0;
                width: 100%;
            }
        }

        &--highlighted {
            position: relative;

            &:before {
                content: " ";
                position: absolute;
                left: 50%;
                bottom: 0px;
                margin-left: -3px;
                width: 5px;
                height: 5px;
                border-radius: 100%;
            }

            &.current-module {
                background: ${theme.colors.brand[100]};
                border-radius: 0px;

                &:after {
                    background: ${theme.colors.brand[100]};
                    border-radius: 0;
                    content: "";
                    display: none;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    width: 50%;
                    z-index: 1;
                }
            }

            &.current-module--start {
                border-radius: 20px 0 0 20px;
            }

            &.current-module--end {
                border-radius: 0 20px 20px 0;
            }
        }

        &--selected {
            .react-datepicker__day-helper {
                background: ${theme.colors.brand[300]} !important;
                border-radius: 50% !important;
                color: ${theme.colors.white} !important;
                font-size: ${theme.typography.sizes.medium} !important;
                font-weight: bold;
                position: relative;
                z-index: 2;
            }

            &.current-module--start {
                background: transparent;
                position: relative;

                &:after {
                    display: block;
                    right: 0;
                }
            }

            &.current-module--end {
                background: transparent;
                position: relative;

                &:after {
                    display: block;
                    left: 0;
                }
            }
        }

        &:hover {
            opacity: 0.8;
            cursor: pointer;
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        .react-datepicker__day-name {
            color: ${theme.colors.base[300]};
            font-size: ${theme.typography.sizes.medium};
            font-weight: 600;
        }

        .react-datepicker__week {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        .react-datepicker__day {
            .react-datepicker__day-helper {
                font-size: 14px;
            }

            &--highlighted {
                .react-datepicker__day-helper {
                    color: ${theme.colors.base[500]};
                }
            }
        }
    }
`;

export const DatePickerHeaderContainer = styled.header`
    align-items: center;
    border-bottom: 1px solid ${theme.colors.base[200]};
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
    padding: ${theme.spacing.small} 0;
`;

export const DatePickerArrowButton = styled.button`
    padding: 7px;

    svg {
        color: ${theme.colors.base[300]};
        display: block;
    }
`;

export const DatePickerMonth = styled(TitleH5)`
    color: ${theme.colors.brand[300]};
    display: block;
    line-height: 1;
    text-transform: uppercase;
`;
