import Card from "components/Card";
import { theme } from "config/theme";
import styled, { css } from "styled-components/macro";
import { ButtonWrapper } from "components/Button/styles";

export const AnimatedContainer = styled.div``;

export const StudentAreaScreenSignedCoursesCardWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};
`;

export const StudentAreaScreenSignedCoursesCardName = styled.h5`
    font-weight: 600;
    font-size: ${theme.spacing.small};
    line-height: 22px;
    color: ${theme.colors.base[500]};
    /* margin-bottom: ${theme.spacing.smallX}; */
`;

export const StudentAreaScreenSignedCoursesCardLegend = styled.p`
    color: ${theme.colors.base[300]};
    font-size: ${theme.typography.sizes.small};
`;

export const StudentAreaScreenSignedCoursesCardHeader = styled.div<{ isCardExpanded?: boolean }>`
    display: flex;
    align-items: center;

    ${({ isCardExpanded }) =>
        isCardExpanded &&
        css`
            padding-bottom: ${theme.spacing.small};
            margin-bottom: ${theme.spacing.small};
            border-bottom: 1px solid ${theme.colors.base[100]};
        `};
`;

export const StudentAreaScreenSignedCoursesCardTexts = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

export const StudentAreaScreenSignedCoursesCardIconWrapper = styled.div<{ isCardExpanded: boolean }>`
    width: 10px;
    transition: transform 0.2s ease-in-out;

    ${({ isCardExpanded }) =>
        isCardExpanded &&
        css`
            transform: rotate(180deg);
        `};
`;

export const StudentAreaScreenSignedCoursesCardDescription = styled.p`
    font-weight: 600;
    font-size: ${theme.typography.sizes.smallX};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
    align-self: stretch;
`;

export const StudentAreaScreenSignedCoursesCardFooter = styled.div`
    display: flex;
    border-top: 1px solid ${theme.colors.base[100]};
    padding-top: ${theme.spacing.small};

    ${ButtonWrapper} {
        padding: 0;
        border: none;

        :hover {
            background: transparent;
        }
    }

    svg {
        margin-left: ${theme.spacing.smallX};
    }

    ${StudentAreaScreenSignedCoursesCardDescription} {
        margin-bottom: 0;
        flex: 1;
    }
`;
