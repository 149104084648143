import { useEffect, useCallback } from "react";
import { KeyboardKeys } from "enums/Keys";
export interface IKeyDownEvent {
    key: KeyboardKeys;
    callBack(): void;
}

interface IKeyboardKeyDownProps {
    key?: KeyboardKeys;
    events?: IKeyDownEvent[];
    callBack?(): void;
}

// default key is ESC
const useKeyboardKeyDown = ({ callBack = () => null, key = KeyboardKeys.KEY_ESC, events }: IKeyboardKeyDownProps) => {
    const onKeyPress = useCallback(
        (event) => {
            try {
                const { keyCode } = event;

                if (!keyCode) {
                    return;
                }

                if (!!events && Array.isArray(events)) {
                    const currentEvent = events.find((keyItem) => keyItem.key === event.keyCode);

                    return !!currentEvent && currentEvent.callBack();
                }

                if (event.keyCode === key) {
                    return callBack();
                }
            } catch (error) {
                console.log(error);
            }
        },
        [callBack, key, events]
    );

    const addListener = useCallback(() => {
        document.addEventListener("keydown", onKeyPress);

        return () => {
            document.removeEventListener("keydown", onKeyPress);
        };
    }, [onKeyPress]);

    useEffect(() => addListener(), [addListener]);
};

export default useKeyboardKeyDown;
