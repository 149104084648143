import { slideBottomToUp } from "assets/styles/animations";
import { BodyText1, BodyText2, Micro, TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { AccordionButton, AccordionContent, AccordionItem } from "components/Accordion/styles";
import { ButtonWrapper } from "components/Button/styles";
import VideoPlayer from "components/VideoPlayer";
import { theme } from "config/theme";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { ProjectPrice } from "../Home/components/Card/styles";
import { ProjectContainer } from "../Home/styles";
import { Grid } from "react-styled-flexboxgrid";

export const ProjectDetailsContainer = styled(ProjectContainer)`
    padding: 0 !important;
`;

export const ProjectDetailsHeader = styled.header`
    display: flex;
    margin-bottom: ${theme.spacing.medium};
    width: 100%;

    ${Grid} {
        margin: 0;
        width: 100%;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-bottom: 0;
        padding: ${theme.spacing.large} 0 ${theme.spacing.medium} 0;
    }
`;

export const BreadCrumbWrapper = styled.div`
    margin-bottom: 11px;
`;

export const ProjectTitle = styled.h1`
    color: ${theme.colors.base[500]};
    font-weight: 600;
    font-size: ${theme.typography.sizes.medium};
    line-height: 22px;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${TitleH3};
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ProjectResume = styled.div`
    ul,
    li {
        list-style: disc;
        list-style-position: inside;
    }

    li,
    p {
        ${BodyText2};
        color: ${theme.colors.base[400]};

        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }
`;

export const ProjectDetailsContent = styled.section`
    padding: 0 ${theme.spacing.smallX} ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: ${theme.spacing.small} 0;
    }
`;

export const ProjectDetailsSubtitle = styled.h3`
    ${BodyText1};
    color: ${theme.colors.base[500]};
    margin-bottom: ${theme.spacing.smallX};
`;

export const ProjectDetailsPrice = styled.div`
    background: ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    border-radius: 4px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: -90px;
        overflow: hidden;
        position: sticky;
        right: 0;
        top: 20px;
    }
`;

export const ProjectDetailsPriceWrapper = styled.div`
    padding: ${theme.spacing.small};

    ${ProjectTitle} {
        font-weight: 600;
        font-size: ${theme.typography.sizes.medium};
        line-height: 22px;
    }

    > ${ButtonWrapper} {
        font-size: ${theme.typography.sizes.medium};
        font-weight: bold;
        padding: ${theme.spacing.smallX};
    }
`;

export const TextAnnouncement = styled.h3`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #3d3d3d;
`;

export const VideoAnnouncement = styled(VideoPlayer)`
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
`;

export const ContainerVideoPlayerMobile = styled.div`
    margin-left: -${theme.spacing.smallX};
    position: relative;
    width: calc(100% + ${theme.spacing.small});
`;

export const ContainerBackButton = styled(Link)`
    position: absolute;
    top: 20px;
    left: 15px;
    z-index: 10;

    svg {
        fill: #fff;
        transform: rotate(-90deg);
    }
`;

export const ProjectPriceDetails = styled(ProjectPrice)`
    font-size: ${theme.typography.sizes.large};
    font-weight: bold;

    span {
        font-size: ${theme.typography.sizes.mediumX};
    }
`;

export const ProjectPriceBar = styled.div<{ isVisible?: boolean }>`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${ButtonWrapper} {
        padding: ${theme.spacing.smallX} ${theme.spacing.large};
    }

    ${({ isVisible }) => {
        if (!isVisible) {
            return undefined;
        }

        return css`
            animation: ${slideBottomToUp} 0.2s linear;
            background: ${theme.colors.white};
            box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
            bottom: 0;
            left: 0;
            padding: ${theme.spacing.smallX} ${theme.spacing.small};
            position: fixed;
        `;
    }}

    ${MediaQueries.BIGGER_THAN_WIDE} {
        ${ProjectPriceDetails} {
            flex: 1;
        }

        ${ButtonWrapper} {
            flex: 2;
        }
    }
`;

export const ProjectMetaData = styled.div`
    padding: ${theme.spacing.small} ${theme.spacing.smallX} 0;

    > ${ButtonWrapper} {
        font-size: ${theme.typography.sizes.medium};
        font-weight: bold;
        padding: ${theme.spacing.smallX};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        padding: 0;

        ${ProjectPriceBar} {
            display: none;
        }
    }
`;

export const ProjectTagsItem = styled.li`
    ${Micro};
    align-items: center;
    color: ${theme.colors.base[500]};
    display: flex;
    white-space: nowrap;

    p {
        text-transform: lowercase;
    }

    svg {
        height: 1rem;
        margin: 0 6px 0 0 !important;
        position: static !important;
        width: 1rem;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText2};

        svg {
            height: 21px;
            width: 21px;
        }

        &:not(:last-child) {
            margin-right: 22px;
        }
    }
`;

export const ProjectDetailsTxt = styled.span`
    ${BodyText2};
    color: ${theme.colors.base[400]};
    display: block;
    margin-bottom: 13px;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        ${BodyText1};
        margin-bottom: ${theme.spacing.small};
    }
`;

export const ProjectDetailsResumes = styled.div`
    background: ${theme.colors.white};
    border-radius: ${theme.spacing.smallXX};

    ${AccordionButton} {
        background: ${theme.colors.white};
        border-bottom: 1px solid ${theme.colors.base[100]};
        padding: 11px 9px;
    }

    ${AccordionContent} {
        ${BodyText2};
        color: ${theme.colors.base[400]};
        padding: 12px ${theme.spacing.smallX};
    }

    ul,
    li {
        list-style: disc;
        list-style-position: inside;
    }

    p,
    li {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    ${AccordionItem} {
        &:not(:last-child) {
            ${AccordionContent} {
                border-bottom: 1px solid ${theme.colors.base[100]};
            }
        }

        &:last-child {
            ${AccordionButton} {
                border-bottom: none;
            }

            ${AccordionContent} {
                border-top: 1px solid ${theme.colors.base[100]};
            }
        }
    }

    ${MediaQueries.BIGGER_THAN_WIDE} {
        ${AccordionButton} {
            padding: 14px 9px;
        }
    }
`;

export const NotFoundResume = styled.p`
    color: ${theme.colors.base[200]};
    font-style: italic;
    padding: ${theme.spacing.smallX};
`;

export const NotFoundProject = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    p {
        margin-bottom: ${theme.spacing.smallX};
        text-align: center;
    }
`;
