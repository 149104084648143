// Helpers
import { Gender } from "enums/Gender";

export const step2Config = {
    street: {
        label: "Logradouro",
        disabled: true
    },
    number: {
        label: "Número",
        disabled: false
    },
    complement: {
        label: "Complemento",
        disabled: false
    },
    neighborhood: {
        label: "Bairro",
        disabled: true
    },
    city: {
        label: "Cidade",
        disabled: true
    },
    state: {
        label: "Estado",
        disabled: true
    }
};

export const genderOptions = [
    {
        value: Gender.Female,
        label: "Feminino"
    },
    {
        value: Gender.Male,
        label: "Masculino"
    },
    {
        value: Gender.Other,
        label: "Prefiro não informar"
    }
];
