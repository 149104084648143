export enum ReportVideoErrorModalVideoErrorType {
    Freezing = 1,
    WrongAudio,
    IncompleteVideo
}

export enum ReportVideoErrorModalDevices {
    Desktop = "desktop",
    Tablet = "tablet",
    CellPhone = "mobile"
}
