import styled from "styled-components";
import { theme } from "config/theme";

export const ReportVideoErrorModalContainer = styled.div`
    width: 800px;
    max-width: 100%;

    label {
        color: ${theme.colors.base[400]};
    }

    .react-select__control {
        border-radius: 5px !important;
        height: 51px !important;
    }
`;

export const ReportVideoErrorModalInputContainer = styled.div`
    margin-bottom: 25px;
`;

export const ReportVideoErrorModalContent = styled.div`
    border-bottom: 1px solid #eaeaea;
    padding: ${theme.spacing.large};
    margin-bottom: ${theme.spacing.large};
`;
