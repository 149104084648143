import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import styled, { css } from "styled-components";
import { CardContainer } from "components/Card/styles";

export const ExerciseStartSwitchWrap = styled.div`
    margin: ${theme.spacing.large};
`;

export const ExerciseStartSwitchItem = styled.button<{ active: boolean }>`
    width: 50%;

    border-radius: 8px;

    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.08px;
    font-size: ${theme.typography.sizes.medium};
    text-align: center;

    transition: all 0.3s ease-in-out;

    ${({ active }) =>
        active
            ? css`
                  height: 100%;
                  background-color: ${theme.colors.system.info[300]};
                  color: ${theme.colors.white};

                  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
              `
            : css`
                  height: 87.5%;
                  background-color: ${theme.colors.base[200]};
                  color: ${theme.colors.base[400]};
              `};
`;

export const ExerciseStartSwitch = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 40px;
    color: ${theme.colors.base[100]};
    border-radius: ${theme.spacing.smallX};
    position: relative;
    cursor: pointer;
`;

export const ExerciseStartLegendWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: ${theme.spacing.medium};
`;

export const ExerciseStartLegend = styled.div`
    display: flex;
    align-items: center;
    padding: ${theme.spacing.small} 0;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.base[100]};
    }

    svg {
        margin-right: ${theme.spacing.smallX};
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: ${theme.typography.family.secondary};
        font-size: ${theme.typography.sizes.smallX};
        color: ${theme.colors.base[500]};
    }
`;

export const ExerciseStartFooterToogle = styled.label`
    cursor: pointer;
    display: flex;

    input {
        display: none;
    }

    small {
        display: inline-block;
        width: 35px;
        height: 15px;
        background: ${theme.colors.base[200]};
        border-radius: 30px;
        position: relative;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background: ${theme.colors.base[100]};
            box-shadow: ${theme.shadow[1]};
            border-radius: 50%;
            top: -2px;
            left: -1px;
            transition: 0.3s;
        }
    }

    input:checked ~ small {
        background: ${theme.colors.system.success[200]};
        transition: 0.3s;
    }

    input:checked ~ small:before {
        transform: translate(16px, 0px);
        transition: 0.3s;
        background: ${theme.colors.system.success[300]};
        right: -2px;
    }
`;

export const ExerciseStartClose = styled.button`
    width: 12px;
    height: 12px;
    top: ${theme.spacing.small};
    right: ${theme.spacing.small};
    position: absolute;

    svg {
        stroke: ${theme.colors.base[500]};
        fill: ${theme.colors.base[500]};
    }
`;

export const ExerciseStartCard = styled(CardContainer)`
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: auto; */
    /* margin: 0; */
    padding: ${theme.spacing.small};
    position: fixed;
    bottom: 0;
    z-index: 9999999;
    border-top-left-radius: ${theme.spacing.small};
    border-top-right-radius: ${theme.spacing.small};
    padding-top: ${theme.spacing.large};
    width: 100%;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        position: relative;
        border-radius: 4px;
        width: 360px;
        height: auto;
    }
`;
