import styled from "styled-components/macro";

export const QrCodeContainer = styled.div`
    background: black;
    width: 100%;

    @media (min-width: 768px) {
        border-radius: 4px;
    }

    div {
        @media (min-width: 768px) {
            padding-top: 286px !important;
        }

        svg {
            path {
                stroke-width: 2;
            }
        }
    }
`;
