// Types
import ProdigioTheme from "prodigio-themes";

export const generateThemeData = (prodigioTheme: ProdigioTheme, brandColors: { brand: any; secondary: any }) => {
    const { project, shadow, spacing, typography } = prodigioTheme;

    return {
        project,
        shadow,
        spacing,
        typography,
        colors: {
            black: "#000000",
            white: "#FFFFFF",
            base: {
                100: "#F0F1F7",
                200: "#CED0DD",
                300: "#83899E",
                400: "#4D4D63",
                500: "#232326"
            },
            system: {
                danger: {
                    100: "#FF8888",
                    200: "#F25555",
                    300: "#E40000",
                    400: "#B80000",
                    500: "#840000"
                },
                info: {
                    100: "#A1B6FF",
                    200: "#6B8BFB",
                    300: "#3E68D2",
                    400: "#132D71",
                    500: "#0B1F53"
                },
                success: {
                    100: "#55DC99",
                    200: "#1BA660",
                    300: "#00874F",
                    400: "#0F6E42",
                    500: "#045332"
                },
                warning: {
                    100: "#FFBE62",
                    200: "#FF9F18",
                    300: "#D17F0C",
                    400: "#9D5F07",
                    500: "#794A07"
                }
            },
            ...brandColors
        }
    };
};
