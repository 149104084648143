import React, { useEffect, useState, memo } from "react";

// Components
import TabRow from "components/TabRow";
import ProjectsList from "./components/List";
import PageLoader from "components/PageLoader";

// Helpers
import { IProjectCategory } from "interfaces/IProject";

// Redux
import { connect } from "react-redux";
import { bindActionCreators, compose, Dispatch } from "redux";
import { Creators as ProjectActions } from "store/ducks/project";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { Col, Grid, Row } from "components/Grid";
import { ProjectContainer, ProjectTitle } from "./styles";
import { IProjectActions } from "store/interfaces/IProject";
import { ProjectsListContainer } from "./components/List/styles";

interface IOption {
    id: number;
    value: string;
}

interface IProjectScreenHomeProps {
    isLoading: boolean;
    categories: IProjectCategory[];
    projectActions: IProjectActions;
}

const ProjectScreenHome = ({ isLoading, categories, projectActions }: IProjectScreenHomeProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [tabOptions, setTabOptions] = useState<IOption[]>([]);

    useEffect(() => {
        if (categories.length > 0) {
            const options = categories.map((category) => ({
                id: category.id,
                value: category.title
            }));

            setTabOptions(options);
        }
    }, [categories]);

    useEffect(() => {
        return () => projectActions.clearProjects();
    }, [projectActions]);

    return (
        <ProjectContainer>
            {isLoading && <PageLoader />}
            <Grid fluid>
                <ProjectTitle>Projetos</ProjectTitle>
                <Row>
                    <Col xs={12} md={12}>
                        <TabRow items={tabOptions} activeIndex={activeIndex} onChangeTab={setActiveIndex} />
                        {categories.map((category, index) => {
                            const isActive = index === activeIndex;

                            if (!isActive) {
                                return null;
                            }

                            return category.projects.length > 0 ? (
                                <ProjectsListContainer key={category.id}>
                                    <ProjectsList projects={category.projects} />
                                </ProjectsListContainer>
                            ) : (
                                <p key={category.id}>Esta categoria não possui nenhum projeto cadastrado.</p>
                            );
                        })}
                    </Col>
                </Row>
            </Grid>
        </ProjectContainer>
    );
};

const memoFn = (prev: IProjectScreenHomeProps, next: IProjectScreenHomeProps) => {
    if (prev.categories.length === next.categories.length && prev.isLoading === next.isLoading) {
        return true;
    }

    return false;
};

const MemoizedProjectScreenHome = memo(ProjectScreenHome, memoFn);

interface IProjectHomeContainerProps {
    brandSlug: string;
    courseSlug: string;
    isLoading: boolean;
    categories: IProjectCategory[];
    projectActions: IProjectActions;
}

const ProjectScreenHomeWrapper = (props: IProjectHomeContainerProps) => {
    const [requested, setRequested] = useState(false);

    useEffect(() => {
        if (!requested) {
            setRequested(true);
            props.projectActions.getProjectsRequest();
        }
    }, [requested, props.projectActions]);

    return <MemoizedProjectScreenHome {...props} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(ProjectActions, dispatch)
});

const mapStateToProps = ({ credentials, course, project }: IReduxStore) => ({
    brandSlug: credentials.student.brand.slug,
    courseSlug: course.slug,
    categories: project.categories,
    isLoading: project.isLoading
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(ProjectScreenHomeWrapper);
