import React from "react";

import history from "services/history";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

const SelectExamVersion: React.FC = () => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);
    // const isMobile = useIsMobile();

    // const newExamImage = isMobile ? NewExamMobile : NewExamDesk;
    // const oldExamImage = isMobile ? OldExamMobile : OldExamDesk;

    // if (theme.project.slug !== "proenem") {
    //     history.push("/app/simulados");

    //     return null;
    // }

    // return (
    //     <PageContainer>
    //         <Content>
    //             <Grid fluid>
    //                 <S.SelectExamVersionContainer>
    //                     <S.SelectExamVersionTitle>Qual versão do simulado você gostaria de acessar?</S.SelectExamVersionTitle>

    //                     <Row>
    //                         <Col xs={12} md={6}>
    //                             <S.SelectExamVersionCard borderBottom>
    //                                 <img src={newExamImage} alt="Nova versão do simulado" />
    //                                 <S.SelectExamVersionCardLegend>
    //                                     A partir de agora o seu simulado também está disponível em uma interface nova e mais fácil de usar. Gostaria
    //                                     de conhecer?
    //                                 </S.SelectExamVersionCardLegend>

    //                                 <Button type="button" variant="info" size="medium" onClick={() => history.push("/app/simulados")}>
    //                                     Usar a versão nova (beta)
    //                                 </Button>
    //                             </S.SelectExamVersionCard>
    //                         </Col>

    //                         <Col xs={12} md={6}>
    //                             <S.SelectExamVersionCard>
    //                                 <img src={oldExamImage} alt="Versão atual do simulado" />
    //                                 <S.SelectExamVersionCardLegend>
    //                                     Está acostumado com a versão antiga e por hora não deseja conhecer a nova versão? Sem problemas, utilize esta.
    //                                 </S.SelectExamVersionCardLegend>

    //                                 <Button type="button" variant="outline-info" size="medium" onClick={() => history.push("/app/simulados-legado")}>
    //                                     Usar a versão atual
    //                                 </Button>
    //                             </S.SelectExamVersionCard>
    //                         </Col>
    //                     </Row>
    //                 </S.SelectExamVersionContainer>
    //             </Grid>
    //         </Content>
    //     </PageContainer>
    // );

    history.push(`/app/curso/${courseSlug}/simulados`);

    return null;
};

export default SelectExamVersion;
