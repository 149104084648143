import React, { memo, useCallback } from "react";
import { ExamScreenContainer, ExamScreenVersionButton } from "./styles";
import styled from "styled-components";
import { TitleH3 } from "assets/styles/global";
import { MediaQueries } from "assets/styles/settings";
import { theme } from "config/theme";
import { Grid, Col, Row } from "components/Grid";
import IframeForLegacy from "components/IframeForLegacy";
import history from "services/history";

import ChevronRight from "prodigio-icons/web/outlined/ChevronRight";
import { useSelector } from "react-redux";
import { IReduxStore } from "interfaces/IReduxStore";

const Content = styled.div`
    padding: 0 ${theme.spacing.smallX};

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 0;
    }
`;

const Header = styled.div`
    margin: ${theme.spacing.medium} 0;

    display: flex;
    flex-direction: column;

    > h1 {
        ${TitleH3};
        margin-bottom: ${theme.spacing.medium};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        > h1 {
            margin-bottom: unset;
        }
    }
`;

const ExamScreen = memo(() => {
    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    const handleClickNewVersion = useCallback(() => {
        history.push(`/app/curso/${courseSlug}/simulados`);
    }, [courseSlug]);

    return (
        <ExamScreenContainer>
            <Content>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <Header>
                                <h1>Simulados (versão antiga)</h1>
                                <ExamScreenVersionButton onClick={handleClickNewVersion}>
                                    Versão nova <ChevronRight width={10} height={10} />
                                </ExamScreenVersionButton>
                            </Header>
                            <IframeForLegacy origin="simulado" />
                        </Col>
                    </Row>
                </Grid>
            </Content>
        </ExamScreenContainer>
    );
});

export default ExamScreen;
