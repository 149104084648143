import { MediaQueries } from "assets/styles/settings";
import { CardContainer } from "components/Card/styles";
import { theme } from "config/theme";
import styled from "styled-components";

import { Grid } from "components/Grid";

export const AnnouncementScreenContainer = styled.div``;

export const AnnouncementScreenHeader = styled.header`
    position: sticky;
    width: 100%;
    top: 0px;
    z-index: 1;
    background: #d5d6dc;
    display: flex;
    padding: 40px 0;

    h1 {
        flex: 1;
        color: #fff;
        font-weight: bold;
        font-size: ${theme.typography.sizes.mediumXX};
        padding: 0 10px;
    }

    ${MediaQueries.SMALL} {
        padding: 20px 0;
    }
`;

export const AnnouncementScreenHeaderContainer = styled(Grid)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AnnouncementScreenWrapp = styled.div`
    margin: 0 10px;
`;

export const AnnouncementScreenCard = styled(CardContainer)`
    border-radius: 5px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
`;

export const AnnouncementScreenCardContent = styled.span`
    font-size: ${theme.typography.sizes.medium};
    font-weight: 300;
    margin: 11px 0;

    ${MediaQueries.SMALL} {
        font-size: ${theme.typography.sizes.smallX};
    }

    i {
        font-size: ${theme.typography.sizes.smallX};
        color: #7c8195;
        text-decoration: underline;
        opacity: 0.8;
        margin-left: 3px;

        &:hover {
            opacity: 1;
        }
    }
`;

export const AnnouncementScreenCardHeader = styled.div`
    display: flex;
    width: 100%;

    strong {
        flex: 1;
        color: ${theme.colors.base[500]};
        font-size: ${theme.typography.sizes.mediumX};

        ${MediaQueries.SMALL} {
            font-size: ${theme.typography.sizes.medium};
        }
    }
`;

export const AnnouncementScreenCardHeaderBadge = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c4c4c4;
    color: #000;
    /* border-radius: 7px; */
    /* padding: 0 6px; */
    font-size: 0.62rem;

    border-radius: 15px;
    padding: 0 10px;

    ${MediaQueries.SMALL} {
        font-size: 0.55rem;
    }
`;

export const AnnouncementScreenCardFooter = styled.div`
    display: flex;

    ${MediaQueries.SMALL} {
        font-size: ${theme.typography.sizes.small};
    }
`;

export const AnnouncementScreenCardFooterTeacher = styled.div`
    flex: 1;
    color: ${theme.colors.base[200]};
    font-size: 0.7rem;
    font-style: italic;
    display: flex;
    font-weight: 500;

    span {
        margin-left: 4px;
    }

    ${MediaQueries.SMALL} {
        font-size: ${theme.typography.sizes.small};
    }
`;

export const AnnouncementScreenCardFooterTime = styled.span`
    color: ${theme.colors.base[200]};
    font-size: 0.7rem;
    font-weight: 600;

    ${MediaQueries.SMALL} {
        font-size: ${theme.typography.sizes.small};
    }
`;

export const AnnouncementScreenEmpty = styled.p`
    color: ${theme.colors.base[200]};
`;

export const AnnouncementScreenSearch = styled.div`
    padding: 0 10px;
`;

export const AnnouncementScreenSearchButton = styled.button`
    background: #f2f2f4;
    border-radius: 100%;
    padding: 7px;

    svg {
        fill: #7c8195;
    }
`;
