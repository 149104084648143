import { call, delay, put, takeLatest, all, takeEvery, select } from "redux-saga/effects";
import history from "services/history";
import { Creators as alertActions } from "store/ducks/alert";

import api from "services/api";
import { Creators as ExamActions, Types as ExamTypes } from "store/ducks/exam";
import { Creators as answerCardActions } from "store/ducks/answerCard";
import { Creators as exerciseListActions } from "store/ducks/exerciseList";
import { IExamList, IExamResolution, IExamResult, IExamScore } from "interfaces/IExam";
import { IPayloadRequest, IRequestAction } from "interfaces/IRequestAction";
import { AxiosResponse } from "axios";
import { showAlertError } from "utils/showAlertError";
import { ReduxAction } from "store";
import { IReduxStore } from "interfaces/IReduxStore";

function* requestCompletedResolution(exam: IExamList, index: number) {
    yield delay(index * 100);

    yield put(ExamActions.getExamCompletedResolutionIdRequest({ examId: exam.id }));
}

function* getAllExams(action: IRequestAction<any>) {
    try {
        const { headers, endpoint } = action.payload;

        const { data }: AxiosResponse<IExamList[]> = yield call(api.get, endpoint, {
            headers
        });

        if (!data || !Array.isArray(data)) {
            throw new Error();
        }

        yield put(ExamActions.getAllExamsSuccess(data));

        yield all(data.map((exam, index) => call(requestCompletedResolution, exam, index)));

        yield put(ExamActions.getExamCreditsRequest());
    } catch (error) {
        console.log("error", error);

        yield put(ExamActions.getAllExamsFailure(error?.response?.data));
    }
}

function* getExamFilters(action: IRequestAction<any>) {
    try {
        const { endpoint } = action.payload;

        const { data } = yield call(api.get, endpoint);

        if (!data) {
            throw new Error();
        }

        yield put(ExamActions.getExamFiltersSuccess(data));
    } catch (error) {
        yield put(ExamActions.getExamFiltersFailure(error?.response?.data));
    }
}

function* getExamDay(action: IRequestAction<any>) {
    try {
        const { endpoint, headers } = action.payload;

        const { data } = yield call(api.get, endpoint, {
            headers
        });

        yield put(ExamActions.getExamDaySuccess(data));
    } catch (error) {
        yield put(alertActions.showAlert("Desculpe, não encontramos essa prova.", "danger"));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, {
            pathname: `/app/curso/${courseSlug}/simulados`
        });

        yield put(ExamActions.getExamDayFailure(error?.response?.data));
        yield put(ExamActions.closeExamDayModal());
    }
}

function* closeExamResult() {
    try {
        yield put(answerCardActions.clearCurrentAnswerCard());
        yield put(exerciseListActions.clearExerciseList());
        yield put(ExamActions.openExamResultFailure());
    } catch (error) {
        yield put(alertActions.showAlert("Desculpe, tivemos um erro.", "danger"));
    }
}

function* openExamResult(action: { type: any; payload: { answerCardId: number; examDayId: string } }) {
    try {
        const { answerCardId, examDayId } = action.payload;

        if (!answerCardId || !examDayId) {
            throw new Error();
        }

        yield put(answerCardActions.getAnswerCardRequest({ id: answerCardId }));
        yield put(exerciseListActions.getExerciseListByExamDayRequest({ examDayId }));

        yield put(ExamActions.openExamResultSuccess());
    } catch (error) {
        yield put(alertActions.showAlert("Desculpe, não conseguimos obter o resultado.", "danger"));
        yield put(ExamActions.closeExamResult());
    }
}

function* getExamDayResult(action: IRequestAction<{ examId: string; examDayId: string }>) {
    try {
        const { endpoint, headers } = action.payload;

        const { data } = yield call(api.get, endpoint, {
            headers
        });

        if (!data) {
            throw new Error();
        }

        yield put(ExamActions.getExamDayResultSuccess(data));

        yield put(ExamActions.getExamDayRelatedDaysRequest({ examDaySlug: data.examDay.slug }));
    } catch (error) {
        const { body } = action.payload;

        yield put(alertActions.showAlert("Desculpe, não encontramos essa prova.", "danger"));

        yield put(ExamActions.getExamDayResultFailure(error?.response?.data));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);

        if (!body.examId || !body.examDayId) {
            yield call(history.push, {
                pathname: `/app/curso/${courseSlug}/simulados`
            });

            return;
        }

        yield call(history.push, {
            pathname: `/app/curso/${courseSlug}/simulados/${body.examId}/${body.examDayId}`
        });
    }
}

function* getExamBySlug(action: ReduxAction<{ slug: string; resolutionId: string }>) {
    try {
        const { slug, resolutionId } = action.payload;

        if (!slug) {
            return;
        }

        const { currentExam, courseSlug } = yield select(({ exam, course }: IReduxStore) => ({
            currentExam: exam?.slug === slug ? exam : undefined,
            courseSlug: course.slug
        }));

        let exam: any;

        if (currentExam) {
            exam = currentExam;
        } else {
            const { data }: AxiosResponse<IExamResult> = yield call(api.get, `/student/exam/${slug}/resolution`);

            exam = data;
        }

        if (!exam?.id) {
            throw new Error();
        }

        if (!exam?.resolutions?.length) {
            throw new Error("Nenhuma resolução encontrada para esse simulado.");
        }

        const [firstResolution] = exam?.resolutions || [];

        const resolution = resolutionId || firstResolution?.id;

        if (!resolution) {
            throw new Error();
        }

        yield put(ExamActions.getExamSuccess(exam));

        yield call(history.push, {
            pathname: `/app/curso/${courseSlug}/simulados/${slug}/resultado/${resolution}`
        });

        yield put(ExamActions.getExamResolutionRequest({ id: ~~resolution }));
    } catch (error) {
        yield showAlertError(error);
        yield put(ExamActions.getExamFailure(error?.response?.data));

        const courseSlug = yield select((state: IReduxStore) => state.course.slug);
        yield call(history.push, {
            pathname: `/app/curso/${courseSlug}/simulados`
        });
    }
}

function* getExamDayRelateds(action: { type: string; payload: { examDaySlug: string } }) {
    try {
        const { examDaySlug } = action.payload;

        if (!examDaySlug) {
            return;
        }

        const { data } = yield call(api.get, `/student/examday/${examDaySlug}/related`);

        if (!data) {
            return;
        }

        yield put(ExamActions.getExamDayRelatedDaysSuccess(data));
    } catch (error) {
        yield put(ExamActions.getExamDayRelatedDaysFailure());
    }
}

function* openExamDayModal(action: { type: string; payload: { examDaySlug: string } }) {
    try {
        const { examDaySlug } = action.payload;

        if (!examDaySlug) {
            throw new Error();
        }

        const payload: IPayloadRequest = {
            method: "GET",
            endpoint: `/student/examday/${examDaySlug}/summary`
        };

        yield put(ExamActions.getExamDayRequest(payload));
    } catch (error) {
        yield put(ExamActions.closeExamDayModal());
    }
}

function* getExamCompletedResolutionIdRequest(action: { type: string; payload: { examId: number } }) {
    const examId = action.payload?.examId || 0;

    try {
        if (!examId) {
            throw new Error();
        }

        const { data: resolutionId }: AxiosResponse<number> = yield call(api.get, `/student/exam/${examId}/completeresolution`);

        if (!resolutionId) {
            throw new Error();
        }

        yield put(ExamActions.getExamCompletedResolutionIdSuccess({ examId, resolutionId }));
    } catch (error) {
        yield put(ExamActions.getExamCompletedResolutionIdFailure({ examId }));
    }
}

function* getResolution(action: ReduxAction<IExamResolution | { id: number }>) {
    try {
        const { id } = action.payload;

        const { data: scores }: AxiosResponse<IExamScore[]> = yield call(api.get, `/student/exam/resolution/${id}/score`);

        yield put(ExamActions.getExamResolutionSuccess(scores));

        const [firstScore] = scores || [];

        yield put(ExamActions.getExamResultScoreRequest(firstScore));
    } catch (error) {
        yield put(ExamActions.getExamResolutionFailure());
    }
}

function* getExamResultScore(action: { type: string; payload?: IExamScore }) {
    try {
        const id = action.payload?.id;

        const resolutionId = yield select(({ exam }: IReduxStore) => exam?.currentExamResult?.currentResolution?.id);

        const endpoint = id ? `/student/exam/resolution/${resolutionId}/result/${id}` : `/student/exam/resolution/${resolutionId}/result`;

        const { data } = yield call(api.get, endpoint);

        if (!data) {
            throw new Error();
        }

        yield put(ExamActions.getExamResultScoreSuccess(data));
    } catch (error) {
        yield showAlertError(error);

        yield put(ExamActions.getExamResultScoreFailure());
    }
}

function* getTotalCredits(_: ReduxAction<undefined>) {
    try {
        const { data }: AxiosResponse<{ balance?: number }> = yield call(api.get, "/student/exam/balance");

        yield put(ExamActions.getExamCreditsSuccess(~~(data?.balance || 0)));
    } catch (error) {
        yield put(ExamActions.getExamCreditsFailure());
    }
}

export default [
    // RESULT PAGE (RANKING)
    takeLatest(ExamTypes.GET_EXAM_REQUEST, getExamBySlug),
    takeEvery(ExamTypes.GET_EXAM_RESOLUTION_REQUEST, getResolution),
    takeLatest(ExamTypes.GET_EXAM_RESULT_SCORE_REQUEST, getExamResultScore),

    //
    takeLatest(ExamTypes.GET_ALL_EXAMS_REQUEST, getAllExams),
    takeLatest(ExamTypes.GET_EXAM_FILTERS_REQUEST, getExamFilters),
    takeLatest(ExamTypes.GET_EXAM_DAY_REQUEST, getExamDay),
    takeLatest(ExamTypes.OPEN_EXAM_RESULT_REQUEST, openExamResult),
    takeLatest(ExamTypes.CLOSE_EXAM_RESULT, closeExamResult),
    takeLatest(ExamTypes.GET_EXAM_DAY_RESULT_REQUEST, getExamDayResult),
    takeLatest(ExamTypes.GET_EXAM_DAY_RELATED_DAYS_REQUEST, getExamDayRelateds),
    takeLatest(ExamTypes.OPEN_EXAM_DAY_MODAL, openExamDayModal),
    takeEvery(ExamTypes.GET_EXAM_COMPLETED_RESOLUTION_ID_REQUEST, getExamCompletedResolutionIdRequest),
    takeEvery(ExamTypes.GET_EXAM_CREDITS_REQUEST, getTotalCredits)
];
