import Skeleton from "components/Skeleton";
import { theme } from "config/theme";
import React, { memo } from "react";

import * as S from "./styles";

const RelatedDaysSkeleton = memo(() => {
    return (
        <S.ExamDayResultRelatedDaysContainer>
            <Skeleton width="70%" height="20px" style={{ marginBottom: theme.spacing.small }} />

            <Skeleton width="100%" height="41px" />
        </S.ExamDayResultRelatedDaysContainer>
    );
});

export default RelatedDaysSkeleton;
