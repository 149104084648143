import styled from "styled-components/macro";
import { MediaQueriesManual } from "assets/styles/settings";

export const Wrapper = styled.div`
    margin: 0 auto;

    .webp-card {
        padding: 24px;
        margin: 0 0 24px;
    }

    .webp-new-card-selected {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ${MediaQueriesManual(768)} {
        .webp-card {
            padding: 24px 18px 20px;
        }

        .content-card {
            display: flex;
            gap: 16px;
        }

        .webp-new-card-selected {
            flex: 1;
            margin: 0;
        }
    }
    ${MediaQueriesManual(1024)} {
        .webp-card {
            padding: 24px 6px;
            margin: 0;
        }
    }

    ${MediaQueriesManual(1366)} {
        .webp-card {
            padding: 24px 16px;
        }
    }
`;

export const ContentTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-section {
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;

        color: #25272d;
        margin: 0 0 24px;
    }

    ${MediaQueriesManual(768)} {
        align-items: start;

        .title-select-start,
        .divider {
            display: none;
        }

        .title-section {
            font-size: 16px;
            line-height: 22px;
        }
    }
`;
