// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEvolutionProps } from "./performance-evolution.types";

// Styles
import { Container, Title, Description, EvolutionCardsWrapper } from "./performance-evolution.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

export const PerformanceEvolution: FunctionComponent<PerformanceEvolutionProps> = ({
    title,
    description,
    navigatorComposition,
    evolutionCardCompositions,
    evolutionGraphicComposition
}) => {
    return (
        <Container>
            <Title>{title}</Title>

            <Spacing size={8} direction="vertical" />

            <Description>{description}</Description>

            <Spacing size={24} direction="vertical" />

            {navigatorComposition}

            <Spacing size={24} direction="vertical" />

            <EvolutionCardsWrapper>{evolutionCardCompositions}</EvolutionCardsWrapper>

            <Spacing size={24} direction="vertical" />

            {evolutionGraphicComposition}
        </Container>
    );
};
