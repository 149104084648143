import { theme } from "config/theme";
import styled from "styled-components/macro";
import { MediaQueries } from "assets/styles/settings";
import { BodyText1, PageContainer } from "assets/styles/global";

export const ChevronIconContainer = styled.div`
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: ${theme.spacing.small};

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-top: 0;
        margin-bottom: ${theme.spacing.medium};
    }
`;

export const ScreenTitle = styled.p<{ isMobile?: boolean }>`
    ${BodyText1}
    margin: ${({ isMobile }) => (isMobile ? `${theme.spacing.small} 0` : `0 0 ${theme.spacing.small}`)};

    align-items: center;
    display: flex;
    cursor: pointer;

    svg {
        margin-right: ${theme.spacing.smallX};
    }
`;

export const StudentAreaScreenContainer = styled(PageContainer)`
    padding: ${theme.spacing.large} 0;
`;
