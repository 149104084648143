// Dependencies
import React from "react";

// Styles
import {
    BenefitsContainer,
    BenefitsTitle,
    BenefitsSubtitle,
    BenefitsContentWrapper,
    BenefitsMoreLabel,
    BenefitItemWrapper,
    BenefitItemLabel
} from "../styles";

// Icons
import { Files } from "./icons/files";
import { Quest } from "./icons/quest";
import { Rocket } from "./icons/rocket";
import { Archives } from "./icons/archives";

// Utils
import { theme } from "config/theme";

export const Benefits: React.FC = () => {
    const color = theme.colors.brand[200];

    return (
        <BenefitsContainer>
            <BenefitsTitle>Novo visual, mesmo compromisso:</BenefitsTitle>
            <BenefitsSubtitle>entregar sempre o melhor conteúdo para você.</BenefitsSubtitle>

            <BenefitsContentWrapper>
                <BenefitItemWrapper>
                    <Files color={color} />
                    <BenefitItemLabel>
                        Banco de questões <span>+50 mil exercícios</span>
                    </BenefitItemLabel>
                </BenefitItemWrapper>

                <BenefitItemWrapper>
                    <Quest color={color} />
                    <BenefitItemLabel>
                        Simulados com <span>nota TRI</span>
                    </BenefitItemLabel>
                </BenefitItemWrapper>

                <BenefitItemWrapper>
                    <Rocket color={color} />
                    <BenefitItemLabel>
                        Projetos com <span>conteúdo extracurricular</span>
                    </BenefitItemLabel>
                </BenefitItemWrapper>

                <BenefitItemWrapper>
                    <Archives color={color} />
                    <BenefitItemLabel>
                        Provas de <span>vestibulares e concursos corrigidas</span>
                    </BenefitItemLabel>
                </BenefitItemWrapper>
            </BenefitsContentWrapper>

            <BenefitsMoreLabel>E muito mais!</BenefitsMoreLabel>
        </BenefitsContainer>
    );
};
