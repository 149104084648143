import React from "react";

import { Col, Row, Grid } from "components/Grid";
import EssayResume from "../resume";
import EssaySended from "components/essayCorrection/components/sended";

import { IEssayComposition } from "store/interfaces/IEssay";
import { EssayStatus } from "enums/EssayStatus";
interface IEssayDraftProps {
    composition: IEssayComposition;
}

const EssayDraft = ({ composition }: IEssayDraftProps) => {
    return (
        <Grid fluid>
            <Row>
                <Col sm={8} md={8}>
                    <EssaySended composition={composition} />
                </Col>
                {composition.status === EssayStatus.Draft && (
                    <Col xs={5} sm={4} md={4}>
                        <EssayResume composition={composition} />
                    </Col>
                )}
            </Row>
        </Grid>
    );
};

export default EssayDraft;
