import React, { memo, useMemo, useCallback, useEffect } from "react";

// Dependencies
import { connect } from "react-redux";

// Components
import MenuClassList from "./components/MenuClassList";
import MenuList from "./components/MenuList";

// Helpers
import { theme } from "config/theme";
import { IReduxStore } from "interfaces/IReduxStore";
import { useDispatch } from "react-redux";
import { ICourseState } from "store/ducks/course";
import { Creators as weekPlansActions } from "store/ducks/weekPlans";

// Assets
import { MenuContainer, MenuHeader, MenuScrollable, MenuWrapper, MenuCloseHelper, MenuContent, MenuLogo } from "./styles";

interface IMenuProps {
    brandSlug: string;
    course: ICourseState;
    courses: ICourseState[];
    visible: boolean;
    isMobile: boolean;
    expanded: boolean;
    classExpanded: boolean;
    setClassExpanded(): void;
    onClose(): void;
    onChangeCourse(e: ICourseState): void;
}

const Menu = (props: IMenuProps) => {
    const dispatch = useDispatch();

    const getCurrentLogo = useMemo(() => {
        if (!props.isMobile && !props.expanded) {
            return theme.project.logoSmall;
        }

        return theme.project.logo;
    }, [props.expanded, props.isMobile]);

    const handleOutsideClick = useCallback(() => props.visible && props.onClose(), [props]);
    const handleCloseMenu = useCallback(() => props.isMobile && props.onClose(), [props]);

    const requestIsPublished = useCallback(() => {
        dispatch(weekPlansActions.getWeekPlansIsPublishedRequest());
    }, [dispatch]);

    useEffect(() => {
        requestIsPublished();
    }, [requestIsPublished]);

    return (
        <MenuWrapper id="MenuWrapper" expanded={props.expanded || props.visible} visible={props.visible}>
            <MenuContainer>
                <MenuScrollable>
                    <MenuHeader isExpanded={props.expanded}>
                        <MenuLogo src={getCurrentLogo} alt={theme.project.name} isExpanded={props.expanded} />
                    </MenuHeader>

                    <MenuContent>
                        <MenuClassList
                            menuExpanded={props.expanded || props.visible}
                            classExpanded={props.classExpanded}
                            setClassExpanded={props.setClassExpanded}
                            courses={props.courses}
                            course={props.course}
                            onChangeCourse={props.onChangeCourse}
                        />

                        <MenuList
                            expanded={props.expanded || props.visible}
                            onClose={handleCloseMenu}
                            externalSchoolClasses={props?.course?.externalSchoolClasses ?? []}
                        />
                    </MenuContent>
                </MenuScrollable>
            </MenuContainer>
            {props.isMobile && <MenuCloseHelper onClick={handleOutsideClick} />}
        </MenuWrapper>
    );
};

const mapStateToProps = ({ course, credentials }: IReduxStore) => ({
    brandSlug: credentials.student.brand.slug,
    course,
    courses: credentials.courses
});

export default memo(connect(mapStateToProps)(Menu));
