import { theme } from "config/theme";
import { Col } from "components/Grid";
import styled from "styled-components";

export const CategoryColumn = styled(Col)`
    margin-top: ${theme.spacing.smallX};
`;

export const CategoryContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing.medium};
`;

export const CategoryTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${theme.spacing.smallXX};
`;

export const CategoryTitle = styled.h3`
    font-weight: bold;
    font-size: ${theme.typography.sizes.mediumX};
    line-height: 26px;
    color: ${theme.colors.base[500]};
    margin-right: ${theme.spacing.smallX};
`;

export const CategoryTotals = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: ${theme.colors.system.info[400]};
    border-radius: 100%;

    font-weight: bold;
    font-size: ${theme.typography.sizes.small};
    line-height: 16px;
    color: ${theme.colors.white};
`;
