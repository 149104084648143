import * as React from "react";

// Helpers
import { FlatVariant } from "utils/types/Variant";

// Assets
import { TooltipContainer } from "./styles";

type ColorVariants = 100 | 200 | 300 | 400 | 500;

interface IProps {
    label?: string;
    children?: any;
    rounded?: boolean;
    variant?: FlatVariant | "base" | "secondary";
    variantColor?: ColorVariants;
    direction?: "top" | "right" | "left" | "bottom";
}

const Tooltip = ({ label, children, rounded = false, variant = "base", direction = "top", variantColor = 300 }: IProps) => (
    <TooltipContainer variant={variant} rounded={rounded} direction={direction} variantColor={variantColor}>
        {label || children}
    </TooltipContainer>
);

export default Tooltip;
