import React, { useCallback, useEffect, useState } from "react";

// Dependencies
import { useDispatch } from "react-redux";
import ReplyIcon from "prodigio-icons/web/outlined/Reply";

// Components
import ForumPost from "../post";
import Complaint from "../complaint";
import ForumAnswerReply from "./components/reply";
import ForumQuestionContent from "screens/Forum/components/question-content";

// Helpers
import { Types } from "store/ducks/forum";
import { validExtensions } from "screens/Forum/new";
import sanitizeString from "helpers/sanitize-string";
import { IForumQuestionAnswer, IForumUpload, UploadType } from "store/interfaces/IForum";

// Assets
import * as F from "./styles";
import { ForumAnswerReplyContainer, ForumAnswerReplyWrapper } from "./components/reply/styles";

type Artefact = "question" | "answer" | "comment";

interface IForumAnswerProps extends IForumQuestionAnswer {
    color?: string;
    isLoading: boolean;
    isCreated: boolean;
    disabledActions?: boolean;
    question: string;
    uploads: {
        [key in Artefact]: IForumUpload[];
    };
    userName: string;
    onAttachments: () => void;
    onToggleLike: (key: UploadType, isLiked: boolean, id: string) => void;
    onRemoveAttachment: (id: number) => void;
    onRetryUpload: (attachment: IForumUpload) => void;
}

const ForumAnswer: React.FC<IForumAnswerProps> = (props) => {
    const dispatch = useDispatch();
    const [isEditing, setIsEditing] = useState(false);
    const [isForumPostVisible, setIsForumPostVisible] = useState(false);

    const handleShowForumPost = useCallback(() => {
        setIsForumPostVisible(!isForumPostVisible);
        setIsEditing(false);
    }, [isForumPostVisible]);

    useEffect(() => {
        if (props.isCreated && isForumPostVisible) {
            setIsForumPostVisible(false);
            dispatch({ type: Types.CLEAR_FORUM_CREATED, payload: { key: "isCommentCreated" } });
        }
    }, [dispatch, handleShowForumPost, isForumPostVisible, props.isCreated]);

    const handlePostComment = useCallback(
        (content: string) => {
            setIsForumPostVisible(false);

            const hasInvalidFiles = props.uploads.comment.some((item) => {
                const extension = item.file.name.split(".").pop();

                return !validExtensions.includes(extension as string);
            });

            if (hasInvalidFiles) {
                return alert(`Você possui arquivos não suportados na lista de anexos. Extensões suportadas: ${validExtensions.join(", ")}`);
            }

            const attachments = props.uploads.comment.filter((item) => item.status === "success").map((item) => item.attachment || item.id);

            if (isEditing) {
                setIsEditing(false);

                const payload = {
                    content: sanitizeString(content, "&nbsp;"),
                    id: props._id,
                    attachments: attachments || []
                };

                return dispatch({ type: Types.CREATE_OR_UPDATE_ANSWER_REQUEST, payload });
            }

            const payload = {
                content: sanitizeString(content, "&nbsp;"),
                answer: props._id,
                attachments: attachments || []
            };

            return dispatch({ type: Types.CREATE_OR_UPDATE_COMMENT_REQUEST, payload });
        },
        [dispatch, isEditing, props._id, props.uploads.comment]
    );

    const handleToggleLike = useCallback((key: UploadType) => (isLiked: boolean, id: string) => props.onToggleLike(key, isLiked, id), [props]);

    const handleUpdateAnswer = useCallback(
        (id: string) => {
            const attachments = props.attachments?.map((item) => ({
                id: item._id,
                status: "success",
                file: {
                    name: item.originalFilename,
                    path: item.originalFilename
                },
                attachment: item._id
            }));

            dispatch({ type: Types.UPLOAD_ATTACHMENTS_SUCCESS, payload: { key: "comment", attachments: attachments || [] } });

            setIsForumPostVisible(true);
            setIsEditing(true);
        },
        [dispatch, props.attachments]
    );

    const handleDelete = useCallback(
        (id: string) => dispatch({ type: Types.DELETE_ANSWER_REQUEST, payload: { id, artefact: "answer", idArtefact: props._id } }),
        [dispatch, props._id]
    );

    const handleConfirmDelete = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "confirmAction",
                action: {
                    title: "Atenção",
                    text: "Tem certeza que deseja apagar este conteúdo?",
                    variant: "outline-warning",
                    buttonText: "Confirmar",
                    onConfirm: () => handleDelete(id)
                }
            });
        },
        [dispatch, handleDelete]
    );

    const handleComplaintSubmit = useCallback(
        (id: string) => (value: number) => dispatch({ type: Types.CREATE_COMPLAINT_REQUEST, payload: { complaintType: value, answer: id } }),
        [dispatch]
    );

    const handleComplaint = useCallback(
        (id: string) => {
            dispatch({
                type: "OPEN_MODAL",
                element: "complaint",
                action: {
                    onComplaint: handleComplaintSubmit(id)
                }
            });
        },
        [dispatch, handleComplaintSubmit]
    );

    if (props.complaint) {
        return (
            <F.ForumAnswerContainer>
                <F.ForumAnswerContent color={props.color}>
                    <Complaint {...props.complaint} />
                </F.ForumAnswerContent>
            </F.ForumAnswerContainer>
        );
    }

    return (
        <F.ForumAnswerContainer>
            <F.ForumAnswerContent color={props.color}>
                <ForumQuestionContent
                    disabledActions={props.disabledActions}
                    actions={{
                        onComplaint: handleComplaint,
                        onUpdate: props.userName === props.user?.username && !props.comments?.length ? handleUpdateAnswer : undefined,
                        onDelete: props.userName === props.user?.username && !props.comments?.length ? handleConfirmDelete : undefined
                    }}
                    answersTotal={props.commentsTotal}
                    attachments={props.attachments}
                    attachmentsTotal={props.attachmentsTotal}
                    color={props.color}
                    id={props._id}
                    isLiked={props.isLiked}
                    likesTotal={props.likesTotal}
                    created={props.created}
                    content={props.content}
                    user={props.user?.name}
                    onShowForumPost={handleShowForumPost}
                    onToggleLike={handleToggleLike("answer")}
                />
            </F.ForumAnswerContent>
            {isForumPostVisible && (
                <ForumAnswerReplyContainer>
                    <ReplyIcon className="ico-reply" width="23" height="23" color={props.color || "#7C8195"} />
                    <ForumAnswerReplyWrapper>
                        <ForumPost
                            content={props.content}
                            isCreated={props.isCreated}
                            isCreatedKey="isCommentCreated"
                            isEditing={isEditing}
                            isLoading={props.isLoading}
                            attachments={props.uploads.comment}
                            onAttachments={props.onAttachments}
                            onCancel={handleShowForumPost}
                            onPost={handlePostComment}
                            onRemoveAttachment={props.onRemoveAttachment}
                            onRetryUpload={props.onRetryUpload}
                        />
                    </ForumAnswerReplyWrapper>
                </ForumAnswerReplyContainer>
            )}
            {Boolean(props.comments?.length) && (
                <F.ForumAnswerReplies>
                    {props.comments!.map((comment) => (
                        <ForumAnswerReply
                            color={props.color}
                            idAnswer={props._id}
                            userName={props.userName}
                            isCreated={props.isCreated}
                            uploads={props.uploads.comment}
                            onAttachments={props.onAttachments}
                            onToggleLike={handleToggleLike("comment")}
                            onRemoveAttachment={props.onRemoveAttachment}
                            onRetryUpload={props.onRetryUpload}
                            key={comment._id}
                            {...comment}
                        />
                    ))}
                </F.ForumAnswerReplies>
            )}
        </F.ForumAnswerContainer>
    );
};

export default ForumAnswer;
