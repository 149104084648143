import React, { useCallback } from "react";

// Dependencies
import IconLike from "prodigio-icons/web/filled/Like";
import IconLikeOutline from "prodigio-icons/web/outlined/Like";

// Assets
import * as L from "./styles";

interface ILikeProps {
    count: number;
    id: string;
    isLiked: boolean;
    disabled?: boolean;
    onToggleLike: (isLiked: boolean, id: string) => void;
    color?: string;
}

const Like = ({ color, count, id, isLiked, onToggleLike, disabled = false }: ILikeProps) => {
    const handleToggleLike = useCallback(() => {
        onToggleLike(isLiked, id);
    }, [id, isLiked, onToggleLike]);

    return (
        <L.LikeContainer disabled={disabled} onClick={handleToggleLike}>
            {isLiked ? <IconLike width="16px" height="16px" /> : <IconLikeOutline width="16px" height="16px" color={color} />}
            <L.LikeCount color={color}>{count}</L.LikeCount>
        </L.LikeContainer>
    );
};

export default Like;
