// Dependencies
import styled, { css } from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const ActivityStudentGridContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    border-radius: 8px;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        padding: 24px;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);

        gap: 0;
    }
`;

type ActivityStudentGridRowContainerStyleProps = {
    hasBorderBottom: boolean;
    hasCursorPointer: boolean;
};

export const ActivityStudentGridRowContainer = styled.div<ActivityStudentGridRowContainerStyleProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;

    @media (min-width: 1024px) {
        display: grid;
        grid-gap: 16px;
        grid-template-columns: auto 96px 148px 120px;
        justify-content: unset;
        padding: 24px 0;
    }

    @media (min-width: 1920px) {
        grid-gap: 32px;
        /* grid-template-columns: auto 160px 96px 148px 120px; */
    }

    ${({ hasCursorPointer }) =>
        !!hasCursorPointer &&
        css`
            cursor: pointer;
        `}

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        background-color: transparent;
        border-radius: unset;

        ${({ hasBorderBottom }) =>
            !!hasBorderBottom &&
            css`
                border-bottom: 1px solid #f1f1f7;
            `}
    }
`;

type ActivityStudentGridCellContainerStyleProps = {
    isFullyAdaptative: boolean;
    renderingWidth?: number;
};

export const ActivityStudentGridCellContainer = styled.div<ActivityStudentGridCellContainerStyleProps>`
    ${({ isFullyAdaptative }) =>
        !!isFullyAdaptative &&
        css`
            width: 100%;
        `}

    ${({ renderingWidth }) =>
        !!renderingWidth &&
        css`
            display: none;

            @media (min-width: ${renderingWidth}px) {
                display: block;
            }
        `}
`;

export const ActivityStudentGridCellText = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #232326;
    white-space: nowrap;
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    @media (min-width: 1024px) {
        gap: 0;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #83899e;
`;
