// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { PerformanceEssayEvolutionProps } from "./performance-essay-evolution.types";

// Styles
import { Container, SectionTitle, Divider } from "./performance-essay-evolution.styles";

// Components
import { Spacing } from "component-library/utilities/spacing";

export const PerformanceEssayEvolution: FunctionComponent<PerformanceEssayEvolutionProps> = ({ sectionTitle, graphicComposition }) => {
    return (
        <Container>
            <SectionTitle>{sectionTitle}</SectionTitle>

            <Spacing direction="vertical" size={16} />

            <Divider />

            <Spacing direction="vertical" size={16} />

            {graphicComposition}
        </Container>
    );
};
