import React from "react";

// Dependencies
import CloseIcon from "prodigio-icons/web/outlined/Close";

// Helpers
import { IForumAttachment } from "store/interfaces/IForum";

// Assets
import * as A from "./styles";

interface IAttachmentsProps {
    attachments: IForumAttachment[];
    isEditing?: boolean;
}

const Attachments: React.FC<IAttachmentsProps> = ({ attachments, isEditing = false }) => {
    return (
        <A.AttachmentsContainer>
            <A.AttachmentsHelper>ANEXO(S):</A.AttachmentsHelper>
            <A.AttachmentsContent>
                {attachments.map((attachment) => (
                    <A.AttachmentsContentItem key={attachment._id}>
                        {isEditing && (
                            <A.AttachmentsContentButton>
                                <CloseIcon width="12" height="12" />
                            </A.AttachmentsContentButton>
                        )}
                        <A.AttachmentsContentLink href={attachment.url} target="_blank" bg={attachment.url} />
                    </A.AttachmentsContentItem>
                ))}
            </A.AttachmentsContent>
        </A.AttachmentsContainer>
    );
};

export default Attachments;
