import { createActions, createReducer } from "reduxsauce";

export interface IAnnouncementItem {
    id: number;
    status: number;
    type: number;
    title: string;
    description: string;
    signature: string;
    url: string;
    file: string;
    preview: string;
}

export interface IAnnouncement {
    id: number;
    viewed: number;
    sentDate: string;
    viewedDate: string;
    status: number;
    notification: IAnnouncementItem;
}

export interface IAnnouncementState {
    isLoading: boolean;
    items: IAnnouncement[];
}

export const { Creators, Types } = createActions({
    getAnnouncementsRequest: ["payload"],
    getAnnouncementsSuccess: ["payload"],
    getAnnouncementsFailure: ["payload"],

    createOrEditAnnouncementsRequest: ["payload"],
    createOrEditAnnouncementsSuccess: ["payload"],
    createOrEditAnnouncementsFailure: ["payload"]
});

export const INITIAL_STATE: IAnnouncementState = {
    isLoading: false,
    items: []
};

// GET
const getAnnouncementsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const getAnnouncementsSuccess = (state = INITIAL_STATE, action: any) => ({
    ...state,
    isLoading: false,
    items: action.payload
});
const getAnnouncementsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

const createOrEditAnnouncementsRequest = (state = INITIAL_STATE) => ({ ...state, isLoading: true });
const createOrEditAnnouncementsSuccess = (state = INITIAL_STATE, action: any) => {
    const { payload } = action;

    const haveThisNotification = state.items.some((item) => item.id === payload.id);

    const updateNotifications = haveThisNotification
        ? state.items.map((item) => (item.id === payload.id ? payload : item))
        : [payload, ...state.items];

    return {
        ...state,
        isLoading: false,
        items: updateNotifications
    };
};
const createOrEditAnnouncementsFailure = (state = INITIAL_STATE) => ({
    ...state,
    isLoading: false
});

export default createReducer(INITIAL_STATE, {
    [Types.GET_ANNOUNCEMENTS_REQUEST]: getAnnouncementsRequest,
    [Types.GET_ANNOUNCEMENTS_SUCCESS]: getAnnouncementsSuccess,
    [Types.GET_ANNOUNCEMENTS_FAILURE]: getAnnouncementsFailure,

    [Types.CREATE_OR_EDIT_ANNOUNCEMENTS_REQUEST]: createOrEditAnnouncementsRequest,
    [Types.CREATE_OR_EDIT_ANNOUNCEMENTS_SUCCESS]: createOrEditAnnouncementsSuccess,
    [Types.CREATE_OR_EDIT_ANNOUNCEMENTS_FAILURE]: createOrEditAnnouncementsFailure
});
