import styled from "styled-components";
import { theme } from "config/theme";
import { MediaQueries } from "assets/styles/settings";
import Button from "components/Button";
import { TooltipContainer } from "components/Tooltip/styles";
import { DropdownContent, DropdownButton } from "components/Dropdown/styles";
import { ButtonWrapper } from "components/Button/styles";

export const ExerciseListFooterContainer = styled.div`
    background: ${theme.colors.white};
    width: 100%;
    padding: ${theme.spacing.small};
    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;
    position: relative;
`;

export const ExerciseListFooterAnswerCardButton = styled(Button).attrs({ variant: "outline-info" })`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: ${theme.spacing.smallX};
    box-shadow: ${theme.shadow[1]};
    font-size: 0;
    color: ${theme.colors.brand[300]};

    &:hover {
        color: ${theme.colors.brand[300]};
        background-color: ${theme.colors.brand[100]};
        border-color: ${theme.colors.brand[300]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        border: 1px solid ${theme.colors.system.info[400]};
        padding: ${theme.spacing.smallX} ${theme.spacing.small};
        font-size: ${theme.typography.sizes.medium};

        svg {
            margin-right: ${theme.spacing.smallX};
        }
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        width: auto;
        border: 1px solid ${theme.colors.white};
        box-shadow: ${theme.shadow[1]};
    }
`;

export const ExerciseListFooterNavigationButton = styled.button`
    padding: ${theme.spacing.smallX};
    border: 1px solid ${theme.colors.white};
    box-shadow: ${theme.shadow[1]};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: none;
    border-radius: 4px;
    font-size: 0;
    font-weight: bold;
    color: ${theme.colors.brand[300]};

    &:first-child {
        margin-right: ${theme.spacing.smallX};
        color: ${theme.colors.base[300]};
    }

    :disabled {
        opacity: 0.5;
        color: ${theme.colors.base[300]};
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        font-size: ${theme.typography.sizes.medium};

        :first-child {
            svg {
                margin-right: ${theme.spacing.smallX};
            }
        }

        :not(:first-child) {
            svg {
                margin-left: ${theme.spacing.smallX};
            }
        }
    }
`;

export const ExerciseListFooterNavigation = styled.div`
    display: flex;

    ${MediaQueries.BIGGER_THAN_SMALL} {
        margin-left: ${theme.spacing.largeX};
        flex: 1;
        justify-content: flex-end;
    }

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        margin-left: 0;
        flex: unset;
    }
`;

export const ExerciseListFooterContent = styled.div`
    display: flex;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        ${ButtonWrapper} {
            width: unset;
        }
    }

    button {
        :not(:last-child) {
            margin-right: ${theme.spacing.smallX};
        }
    }

    ${MediaQueries.BIGGER_THAN_SMALL} {
        justify-content: flex-end;

        button {
            :not(:last-child) {
                margin-right: ${theme.spacing.small};
            }
        }
    }
`;

export const ExerciseListFooterDropdown = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    justify-content: flex-start;

    ${DropdownButton} {
        margin-right: 0 !important;
    }

    ${DropdownContent} {
        left: 0;
        right: unset;

        width: 240px;
        border-top: none;

        :before {
            right: unset;
            left: 8px;
        }
    }
`;

export const ExerciseListFooterKeyboardDropdownContent = styled.div`
    padding: ${theme.spacing.smallX};
    display: flex;
    flex-direction: column;

    p {
        color: ${theme.colors.base[400]};
        font-size: ${theme.typography.sizes.smallX};

        :not(:last-child) {
            margin-bottom: ${theme.spacing.small};
        }
    }
`;

export const ExerciseListFooterKeyboardDropdownContentIcons = styled.div`
    display: flex;
    margin-bottom: ${theme.spacing.smallXX};

    svg {
        width: 20px;
        height: 20px;

        :not(:last-child) {
            margin-right: ${theme.spacing.smallX};
        }
    }
`;

export const ExerciseListFooterKeyboardDropdownIcons = styled.span`
    ${TooltipContainer} {
        display: none;
        color: ${theme.colors.white};
    }

    svg {
        width: 26px;
        height: 16px;
    }

    :hover {
        ${TooltipContainer} {
            display: unset;
        }
    }
`;
