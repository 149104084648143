import React from "react";

// Dependencies
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Helpers
import { lightenDarkenColor } from "helpers";
import { ISubject } from "interfaces/ISubject";
import { IReduxStore } from "interfaces/IReduxStore";

// Assets
import { SelectModalListContainer, SelectModalListWrap } from "assets/styles/global";

interface IProps {
    data: {
        type: string;
        LO: any[];
        subject: ISubject;
    };
}

const SelectEvent = ({ data }: IProps) => {
    const dispatch = useDispatch();

    const courseSlug = useSelector(({ course }: IReduxStore) => course.slug);

    return (
        <SelectModalListContainer>
            <h3>Escolha um vídeo</h3>
            <SelectModalListWrap>
                {data.LO.filter((item) => !!item.module).map((item) => (
                    <Link
                        key={item.id}
                        to={`/app/curso/${courseSlug}/plano-de-estudos/${item.module.slug}/${DateTime.fromISO(item.startDate).toFormat(
                            "dd-LL-yyyy"
                        )}/${data.type}/${item.id}`}
                        title={item.title}
                        onClick={() => dispatch({ type: "CLOSE_MODAL" })}
                    >
                        <strong style={{ color: data.subject ? lightenDarkenColor(data.subject.color, -30) : "inherit" }}>
                            {DateTime.fromISO(item.startDate).toFormat("dd/LL/yyyy - HH:mm")}
                        </strong>
                        {item.title}
                    </Link>
                ))}
            </SelectModalListWrap>
        </SelectModalListContainer>
    );
};

export default SelectEvent;
