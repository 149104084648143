// Dependencies
import styled, { css } from "styled-components";

export const Container = styled.div`
    width: fit-content;

    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
`;

type ActivityCheckboxWrapperStyleProps = {
    isChecked: boolean;
    isDisabled: boolean;
};

export const ActivityCheckboxWrapper = styled.div<ActivityCheckboxWrapperStyleProps>`
  width: 18px;
  height: 18px;
  border: 1.5px solid #232326;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 200ms ease-in-out;

  ${({ isDisabled }) =>
      isDisabled &&
      css`
          background-color: #83899e;
      `}

  ${({ isDisabled, isChecked }) =>
      !isDisabled &&
      isChecked &&
      css`
          background-color: #162152;
          border-color: #162152;
      `}

  ${({ isDisabled, isChecked }) =>
      !isDisabled &&
      !isChecked &&
      css`
          &:hover {
              border-color: #162152;
          }
      `}
`;

type ActivityCheckboxActiveContentStyleProps = {
    isChecked: boolean;
    isDisabled: boolean;
};

export const ActivityCheckboxActiveContent = styled.div<ActivityCheckboxActiveContentStyleProps>`
    display: none;

    padding: 4px;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            display: none;
        `}

    ${({ isChecked, isDisabled }) =>
        !isDisabled &&
        isChecked &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}
`;

export const Icon = styled.img`
    width: 12px;
    height: 12px;
    color: #fff;
`;

type LabelStyleProps = {
    isChecked: boolean;
};

export const Label = styled.p<LabelStyleProps>`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #000;
`;
