export function isPromilitaresOldCourses(courseSlug: string, brandSlug: string) {
    const oldCoursesSlugs = [
        "pmsp-2023-al",
        "afa-2023-al",
        "en-efomm-2023-al",
        "cn-2023-al",
        "epcar-2023-al",
        "eam-2023-al",
        "cfn-2023-al",
        "eear-2023-al",
        "esa-2023-al",
        "ime-2023-al",
        "ita-2023-al",
        "barro-branco-2023-al",
        "espcex-2023-al",
        "cbmerj-ofc-2023-al",
        "ime-ita-2023-al"
    ];

    if (brandSlug === "promilitares") {
        return oldCoursesSlugs.some((course) => course === courseSlug);
    }
}
