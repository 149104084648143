// Dependencies
import React, { FunctionComponent } from "react";

// Types
import { SpacingProps } from "./spacing.types";

// Styles
import { Container } from "./spacing.styles";

export const Spacing: FunctionComponent<SpacingProps> = ({ size, direction }) => {
    return <Container size={size} direction={direction} />;
};
