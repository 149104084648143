import React, { useCallback, memo, useMemo } from "react";

// Helpers
import { IFilterGroup } from "interfaces/IFilterGroup";
import { theme } from "config/theme";

// Assets
import {
    FilterGroupItems,
    FilterGroupContainer,
    FilterGroupWrapper,
    FilterGroupTitle,
    FilterButton,
    FilterGroupHeader,
    FilterModalTitle,
    FilterGroupHeaderButton,
    FilterGroupItemContainer
} from "./styles";

interface IProps {
    options: IFilterGroup[];
    onChange(options: IFilterGroup[]): void;
    withHeader?: boolean;
}

const FilterGroup = ({ options = [], onChange, withHeader = false }: IProps) => {
    const handleClearOptions = useCallback(() => {
        const updateOptions = options.map((option) => ({ ...option, items: option.items.map((item) => ({ ...item, checked: false })) }));

        onChange(updateOptions);
    }, [onChange, options]);

    const handleSelectItem = useCallback(
        (categoryId: number, value: any) => () => {
            const updateOptions = options.map((option) => {
                if (option.id !== categoryId) {
                    return option;
                }

                return {
                    ...option,
                    items: option.items.map((item) => ({
                        ...item,
                        checked: item.value === value ? !item.checked : item.checked
                    }))
                };
            });

            onChange(updateOptions);
        },
        [onChange, options]
    );

    const listItems = useMemo(
        () => (option: IFilterGroup) => {
            return option.items.map((item, index) => {
                const pipeColor = item.checked ? theme.colors.white : theme.colors.brand[300];

                return (
                    <FilterButton
                        isActive={item.checked}
                        pipeColor={pipeColor}
                        key={`item_${index}`}
                        onClick={handleSelectItem(option.id, item.value)}
                    >
                        {item.label}
                    </FilterButton>
                );
            });
        },
        [handleSelectItem]
    );

    const hasSelectedItems = useMemo(() => options.some((option) => option.items.some((item) => item.checked)), [options]);

    const memoHeader = useMemo(
        () => (
            <FilterGroupHeader>
                <FilterModalTitle>Filtrar por:</FilterModalTitle>
                {hasSelectedItems && (
                    <FilterGroupHeaderButton type="button" onClick={handleClearOptions} data-test-id="filter-group-clean">
                        Limpar
                    </FilterGroupHeaderButton>
                )}
            </FilterGroupHeader>
        ),
        [handleClearOptions, hasSelectedItems]
    );

    return (
        <FilterGroupContainer>
            {withHeader && memoHeader}
            <FilterGroupItems data-test-id="filter-group">
                {options.map((option) => (
                    <FilterGroupItemContainer data-test-id="checkbox-group" key={option.id}>
                        <FilterGroupTitle data-test-id="checkbox-group-title">{option.title}</FilterGroupTitle>
                        <FilterGroupWrapper>{listItems(option)}</FilterGroupWrapper>
                    </FilterGroupItemContainer>
                ))}
            </FilterGroupItems>
        </FilterGroupContainer>
    );
};

export default memo(FilterGroup);
