// Dependencies
import styled from "styled-components";

// Utils
import { MediaQueries } from "assets/styles/settings";

export const ActivityAnswerSheetContainer = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;

    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;

    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`;

export const AnswerSheetItemCompositionsWrapper = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
`;

export const AnswerSheetItemContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 8px;
`;

export const AnswerSheetItemLabel = styled.p`
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #232326;
`;

export const AnswerSheetItemContentWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex-direction: column;

    ${MediaQueries.BIGGER_THAN_MEDIUM} {
        flex-direction: row;
        gap: 40px;
    }
`;

export const AnswerSheetItemAnswers = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
`;

export const AnswerSheetItemAnswerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const AnswerSheetItemAnswerSeparator = styled.div`
    width: 1px;
    height: 24px;
    background-color: #ced0dd;
    border-radius: 2px;
`;
