// Dependencies
import React from "react";

// Components
import CloseIcon from "prodigio-icons/web/outlined/Close";
import Button from "components/Button";

// Assets
import {
    ToastIconWrapper,
    ToastIcon,
    ToastTitleWrapper,
    ToastTitle,
    ToastHeader,
    ToastButtonsWrapper,
    ToastCloseButton,
    ToastWrapper
} from "./styles";

// Types
import { ToastProps } from "./types";

export const Toast = ({ isVisible, icon, title, content, buttons, handleClickClose }: ToastProps) => {
    if (!isVisible) {
        return null;
    }

    return (
        <ToastWrapper>
            <ToastHeader>
                <ToastTitleWrapper>
                    {icon && (
                        <ToastIconWrapper>
                            <ToastIcon src={icon} />
                        </ToastIconWrapper>
                    )}

                    <ToastTitle>{title}</ToastTitle>
                </ToastTitleWrapper>

                <ToastCloseButton onClick={handleClickClose}>
                    <CloseIcon width="16" height="16" />
                </ToastCloseButton>
            </ToastHeader>

            <div>{content}</div>

            <ToastButtonsWrapper>
                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        variant={button.variant}
                        size="medium"
                        {...(window.innerWidth < 768 && {
                            block: true
                        })}
                        onClick={button.handleClick}
                    >
                        {button.label}
                    </Button>
                ))}
            </ToastButtonsWrapper>
        </ToastWrapper>
    );
};
